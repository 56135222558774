import { Injectable } from '@angular/core';
import { DatatableOutputParameterInterface } from '../../component/datatable/datatable.model';
import { DatatableInterface } from './datatable.model';

@Injectable({
  providedIn: 'root',
})
export class DatatableService {
  public formatOutputParameters(parameters: DatatableOutputParameterInterface): DatatableInterface {
    const query: DatatableInterface = {
      page: 1,
      pageSize: 10,
    };

    if (parameters.page) {
      query.page = parameters.page;
    }

    if (parameters.rowsPerPage) {
      query.pageSize = parameters.rowsPerPage;
    }

    if (parameters.search) {
      query.search = parameters.search;
    }

    if (parameters.sort && parameters.sort.type !== 'none') {
      query.orderBy = parameters.sort.column;
      query.orderDesc = parameters.sort.type === 'descending';
    }

    if (parameters.sort && parameters.sort.type === 'none') {
      query.orderBy = 'id';
      query.orderDesc = true;
    }

    return query;
  }
}
