import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, switchMap } from 'rxjs/operators';
import { of } from 'rxjs';
import * as ObjectActions from './iot-device-report-device-type.actions';
import { generateHttpOption } from '../../../shared/helper/app-helper';
import { IotDeviceReportService } from '../../../shared/service/iot-device-report/iot-device-report.service';

@Injectable()
export class FilterIotDeviceReportDeviceTypeEffects {
  loadFilterIIotDeviceReportDeviceTypes$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ObjectActions.FilterIotDeviceReportDeviceTypeActionTypes.StartLoadFilterIotDeviceReportDeviceTypes),
      switchMap((objectData: ObjectActions.StartLoadFilterIotDeviceReportDeviceTypes) => {
        const params = generateHttpOption(objectData.options);
        return this.iotDeviceReportsService.getIotDeviceReportTypeData(params).pipe(
          switchMap((response) => {
            const { data } = response;

            return of(new ObjectActions.FilterIotDeviceReportDeviceTypesLoaded(data));
          }),
          catchError((e) => {
            return of(new ObjectActions.FilterIotDeviceReportDeviceTypesLoaded([]));
          }),
        );
      }),
      catchError(() => {
        return of(new ObjectActions.FilterIotDeviceReportDeviceTypesLoaded([]));
      }),
    ),
  );

  constructor(
    private actions$: Actions<ObjectActions.FilterIotDeviceReportDeviceTypeActions>,
    public iotDeviceReportsService: IotDeviceReportService,
  ) {}
}
