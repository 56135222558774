import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import * as oeeAppReducer from '../../oee.reducer';
import * as ObjectActions from '../job-performance/job-performance.actions';
import { catchError, switchMap } from 'rxjs/operators';
import * as AppActions from '../../app/actions';
import { of } from 'rxjs';
import {
  BaseOneResponseInterface,
  GetManyResponseInterface,
} from '../../../shared/model/interface/crud-response-interface.model';
import { JobPerformanceService } from './job-performance.service';
import { HttpParams } from '@angular/common/http';
import { IWorkOrder, IWorkOrderLaborAssetInformation } from './job-performance.model';
import { ActivityLogsResponseInterface } from '../root-cause-analysis/root-cause-analysis.model';
import { IOeeCalculationResultFormatted } from '../../../shared/model/interface/generic-api-response.model';
import { EntityTranslatorService } from '../../../shared/service/entity-translator/entity-translator.service';
import { ChartValues, IFormattedOeeResult, IOeeResponse } from '../deep-dive-analysis/deep-dive-analysis.model';
import { DecimalHelper } from '../../../shared/helper/decimal/decimal-helper';
import { HelperService } from '../../../shared/service/helper.service';
import { emptyAction } from '../../../../constants';
import { IJobOeeResult } from '../../layout-plan-view/layout-plan-view.model';

@Injectable()
export class JobPerformanceEffects {
  constructor(
    private readonly store: Store<oeeAppReducer.OeeAppState>,
    private readonly actions: Actions,
    private readonly decimalHelper: DecimalHelper,
    private readonly entityTranslatorService: EntityTranslatorService,
    private readonly service: JobPerformanceService,
  ) {}

  getWorkOrdersOfJob = createEffect(() =>
    this.actions.pipe(
      ofType(ObjectActions.GET_WORK_ORDERS_OF_JOB),
      switchMap((payload: ObjectActions.GetWorkOrdersOfJob) => {
        this.store.dispatch(new AppActions.ShowLoader());

        let httpParams = new HttpParams()
          .append('join', 'site||name')
          .append('join', 'product||productId,unit')
          .append('join', 'product.unitType||name')
          .set('limit', '1000');

        if (payload.jobId) {
          httpParams = httpParams.set('s', JSON.stringify({ jobId: { $eq: payload.jobId } }));
        }

        return this.service.getWorkOrders(httpParams).pipe(
          switchMap((response: GetManyResponseInterface<IWorkOrder>) => {
            return of(new ObjectActions.GetWorkOrdersOfJobCompleted(response), new AppActions.HideLoader());
          }),
          catchError((errorRes) => {
            return of(new ObjectActions.FetchError(errorRes), new AppActions.HideLoader());
          }),
        );
      }),
      catchError((errorRes) => {
        return of(new ObjectActions.FetchError(errorRes), new AppActions.HideLoader());
      }),
    ),
  );

  getWorkOrderTableData = createEffect(() =>
    this.actions.pipe(
      ofType(ObjectActions.GET_TABLE_DATA),
      switchMap((payload: ObjectActions.GetTableData) => {
        this.store.dispatch(new AppActions.ShowLoader());

        let httpParams = new HttpParams()
          .append('join', 'site||name')
          .append('page', String(payload.tableQuery?.page || 1))
          .append('limit', String(payload.tableQuery?.pageSize || 10));

        if (payload.tableQuery?.orderBy && payload.tableQuery?.orderDesc !== undefined) {
          httpParams = httpParams.set(
            'sort',
            `${payload.tableQuery.orderBy},${payload.tableQuery.orderDesc ? 'DESC' : 'ASC'}`,
          );
        } else {
          httpParams = httpParams.set('sort', 'id,DESC');
        }

        if (payload.workOrderIds) {
          httpParams = httpParams.set('s', JSON.stringify({ id: { $in: payload.workOrderIds } }));
        }

        return this.service.getWorkOrdersWithLastLine(httpParams).pipe(
          switchMap((response: GetManyResponseInterface<IWorkOrder>) => {
            return of(new ObjectActions.GetTableDataCompleted(response), new AppActions.HideLoader());
          }),
          catchError((errorRes) => {
            return of(new ObjectActions.FetchError(errorRes), new AppActions.HideLoader());
          }),
        );
      }),
      catchError((errorRes) => {
        return of(new ObjectActions.FetchError(errorRes), new AppActions.HideLoader());
      }),
    ),
  );

  getOeeSelectedWorkOrders = createEffect(() =>
    this.actions.pipe(
      ofType(ObjectActions.GET_OEE_SELECTED_WORK_ORDERS),
      switchMap((payload: ObjectActions.GetOeeSelectedWorkOrders) => {
        this.store.dispatch(new AppActions.ShowLoader());

        return this.service.getOeeSelectedWorkOrders(payload.workOrderIds, payload.siteId).pipe(
          switchMap((response: GetManyResponseInterface<IOeeCalculationResultFormatted>) => {
            return of(new ObjectActions.GetOeeSelectedWorkOrdersCompleted(response), new AppActions.HideLoader());
          }),
          catchError((errorRes) => {
            return of(new ObjectActions.FetchError(errorRes), new AppActions.HideLoader());
          }),
        );
      }),
      catchError((errorRes) => {
        return of(new ObjectActions.FetchError(errorRes), new AppActions.HideLoader());
      }),
    ),
  );

  getOeeOfJob = createEffect(() =>
    this.actions.pipe(
      ofType(ObjectActions.GET_OEE_OF_JOB),
      switchMap((payload: ObjectActions.GetOeeOfJob) => {
        return this.service.getOeeOfJob(payload.jobId).pipe(
          switchMap((response: IJobOeeResult) => {
            return of(new ObjectActions.GetOeeOfJobCompleted(response), new AppActions.HideLoader());
          }),
          catchError((errorRes) => {
            return of(new ObjectActions.FetchError(errorRes), new AppActions.HideLoader());
          }),
        );
      }),
      catchError((errorRes) => {
        return of(new ObjectActions.FetchError(errorRes), new AppActions.HideLoader());
      }),
    ),
  );

  getActivityLogs = createEffect(() =>
    this.actions.pipe(
      ofType(ObjectActions.GET_ACTIVITY_LOG),
      switchMap((payload: ObjectActions.GetActivityLog) => {
        this.store.dispatch(new AppActions.ShowLoader());

        return this.service.getActivityLogData(payload.workOrderIds).pipe(
          switchMap((response: BaseOneResponseInterface<ActivityLogsResponseInterface>) => {
            return of(new ObjectActions.GetActivityLogCompleted(response), new AppActions.HideLoader());
          }),
          catchError((errorRes) => {
            return of(new ObjectActions.FetchError(errorRes), new AppActions.HideLoader());
          }),
        );
      }),
      catchError((errorRes) => {
        return of(new ObjectActions.FetchError(errorRes), new AppActions.HideLoader());
      }),
    ),
  );

  getWorkOrderLaborInformation = createEffect(() =>
    this.actions.pipe(
      ofType(ObjectActions.GET_LABOR_INFORMATION),
      switchMap((objectData: ObjectActions.GetLaborInformation) => {
        this.store.dispatch(new AppActions.ShowLoader());

        return this.service.getLaborInformation(objectData.workOrderIds).pipe(
          switchMap((response: BaseOneResponseInterface<IWorkOrderLaborAssetInformation[]>) => {
            response.data.forEach((workOrderLaborAssetInformation: IWorkOrderLaborAssetInformation) =>
              this.entityTranslatorService.translate(workOrderLaborAssetInformation),
            );

            return of(new ObjectActions.GetLaborInformationCompleted(response.data), new AppActions.HideLoader());
          }),
          catchError((err) => {
            return of(new ObjectActions.FetchError(err));
          }),
        );
      }),
      catchError((err) => {
        return of(new ObjectActions.FetchError(err));
      }),
    ),
  );

  getDailyOeeResult = createEffect(() =>
    this.actions.pipe(
      ofType(ObjectActions.GET_OEE_BY_SQL_MODE),
      switchMap((payload: ObjectActions.GetOeeBySqlMode) => {
        this.store.dispatch(new AppActions.ShowLoader());

        return this.service.fetchOeeResultByTimeSelection(payload.params).pipe(
          switchMap((response: GetManyResponseInterface<IOeeResponse>) => {
            const loadedAction: Action = new ObjectActions.GetOeeBySqlModeCompleted(
              response.data.map((hourlyResult: IOeeResponse): IFormattedOeeResult => {
                const unplannedDt: string = this.decimalHelper.divide(
                  String(hourlyResult[ChartValues.totalDownTimeUnplannedDuration]),
                  '60',
                );
                return {
                  ...hourlyResult,
                  [ChartValues.totalRunTimeDuration]: String(hourlyResult[ChartValues.totalRunTimeDuration]),
                  [ChartValues.totalDownTimeDuration]: String(hourlyResult[ChartValues.totalDownTimeDuration]),
                  [ChartValues.totalDownTimeUnplannedDuration]: unplannedDt,
                  [ChartValues.totalDownTimePlannedDuration]: this.decimalHelper.subtract(
                    String(hourlyResult[ChartValues.totalDownTimeDuration]),
                    unplannedDt,
                  ),
                  [ChartValues.totalIdleTimeDuration]: String(hourlyResult[ChartValues.totalIdleTimeDuration]),
                };
              }),
            );
            return of(...[loadedAction], new AppActions.HideLoader());
          }),
          catchError((errorRes) => {
            return of(new ObjectActions.FetchError(errorRes), new AppActions.HideLoader());
          }),
        );
      }),
      catchError((errorRes) => {
        return of(new ObjectActions.FetchError(errorRes), new AppActions.HideLoader());
      }),
    ),
  );

  downloadTableExcel = createEffect(() =>
    this.actions.pipe(
      ofType(ObjectActions.DOWNLOAD_JOB_PERFORMANCE_TABLE_EXCEL),
      switchMap((objectData: ObjectActions.DownloadJobPerformanceTableExcel) => {
        this.service.downloadExcel(...HelperService.argumentClone(objectData.headers, objectData.workOrdersIds));
        return emptyAction;
      }),
      catchError((errorRes) => {
        return of(new ObjectActions.FetchError(errorRes), new AppActions.HideLoader());
      }),
    ),
  );
}
