import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ScwMatCarouselPickerComponent } from './scw-mat-carousel-picker.component';
import { NgbCarouselModule } from '@ng-bootstrap/ng-bootstrap';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
  declarations: [ScwMatCarouselPickerComponent],
  imports: [CommonModule, NgbCarouselModule, MatProgressBarModule, TranslateModule],
  exports: [ScwMatCarouselPickerComponent],
})
export class ScwMatCarouselPickerModule {}
