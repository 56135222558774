import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { FilterProductActions } from '../product/product.actions';
import { catchError, switchMap } from 'rxjs/operators';
import { generateHttpOption } from '../../../shared/helper/app-helper';
import { from, of } from 'rxjs';
import {
  ProductCRUDResponseInterface,
  ProductFamilyCRUDResponseInterface,
} from '../../../shared/service/filter/service.class';
import { ProductService } from '../../../shared/service/filter/product.service';
import { EntityTranslatorService } from '../../../shared/service/entity-translator/entity-translator.service';
import {
  FilterProductOrProductFamilyActionTypes,
  FilterProductOrProductFamilyLoaded,
  StartLoadFilterProductOrProductFamily,
} from './product-or-product-family.actions';
import { EProductOrFamilyFilterType } from '../../../shared/component/filter/filterable-objects.class';
import { HttpOptionInterface } from '../filter.model';
import { HttpParams } from '@angular/common/http';

@Injectable()
export class FilterProductOrProductFamilyEffects {
  loadFilterProductOrProductFamily$ = createEffect(() =>
    this.actions$.pipe(
      ofType(FilterProductOrProductFamilyActionTypes.StartLoadFilterProductOrProductFamily),
      switchMap((objectData: StartLoadFilterProductOrProductFamily) => {
        const options: HttpOptionInterface = this.productService.adjustOptionsJson(
          objectData.options,
          objectData.filterType,
        );
        const params: HttpParams = generateHttpOption(options);
        return from(this.productService.getProductOrProductFamilyData(params, objectData.filterType)).pipe(
          switchMap((response: ProductCRUDResponseInterface | ProductFamilyCRUDResponseInterface) => {
            if (objectData.filterType === EProductOrFamilyFilterType.Product) {
              response.data.forEach((product) => this.entityTranslator.translate(product));
            }

            return of(
              new FilterProductOrProductFamilyLoaded(response.data, response.total, objectData?.isFetchMoreRequest),
            );
          }),
          catchError(() => {
            return of(new FilterProductOrProductFamilyLoaded([]));
          }),
        );
      }),
      catchError(() => {
        return of(new FilterProductOrProductFamilyLoaded([]));
      }),
    ),
  );

  constructor(
    private actions$: Actions<FilterProductActions>,
    public productService: ProductService,
    private readonly entityTranslator: EntityTranslatorService,
  ) {}
}
