import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ScwMatModalSectionTitleComponent } from './scw-mat-modal-section-title.component';
import { MatFormFieldModule } from '@angular/material/form-field';

@NgModule({
  declarations: [ScwMatModalSectionTitleComponent],
  exports: [ScwMatModalSectionTitleComponent],
  imports: [CommonModule, MatFormFieldModule],
})
export class ScwMatModalSectionTitleModule {}
