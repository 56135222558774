import { Action } from '@ngrx/store';
import { IFloorPlan, IFloorPlanTarget, IJobOeeResult } from './layout-plan-view.model';
import { WorkOrderScheduleInterface } from '../work-order-schedule/work-order-schedule.model';
import { Moment } from 'moment';
import { SiteViewDataInterface } from '../global-view/site-view/site-view.model';

export const FLOOR_PLAN_LOADING = '[JOB LAYOUT PLAN] FLOOR PLAN LOADING';
export const FLOOR_PLAN_LOADED = '[JOB LAYOUT PLAN] FLOOR PLAN LOADED';
export const JOB_OEE_LOADING = '[JOB LAYOUT PLAN] JOB OEE LOADING';
export const JOB_OEE_LOADED = '[JOB LAYOUT PLAN] JOB OEE LOADED';
export const WORK_ORDERS_LOADING = '[JOB LAYOUT PLAN] WORK ORDERS LOADING';
export const WORK_ORDERS_LOADED = '[JOB LAYOUT PLAN] WORK ORDERS LOADED';
export const FLOOR_PLAN_OEE_TARGETS_LOADING = '[JOB LAYOUT PLAN] FLOOR PLAN OEE TARGETS LOADING';
export const FLOOR_PLAN_OEE_TARGETS_LOADED = '[JOB LAYOUT PLAN] FLOOR PLAN OEE TARGETS LOADED';
export const FLOOR_PLAN_VIEW_DATA_LOADING = '[JOB LAYOUT PLAN] FLOOR PLAN VIEW DATA LOADING';
export const FLOOR_PLAN_VIEW_DATA_LOADED = '[JOB LAYOUT PLAN] FLOOR PLAN VIEW DATA LOADED';
export const FETCH_ERROR = '[JOB LAYOUT PLAN] FETCH ERROR';

export class FloorPlanLoading implements Action {
  readonly type = FLOOR_PLAN_LOADING;

  constructor(public id: number) {}
}

export class FloorPlanLoaded implements Action {
  readonly type = FLOOR_PLAN_LOADED;

  constructor(public payload: IFloorPlan) {}
}

export class JobOeeLoading implements Action {
  readonly type = JOB_OEE_LOADING;

  constructor(public jobId: number) {}
}

export class JobOeeLoaded implements Action {
  readonly type = JOB_OEE_LOADED;

  constructor(public payload: IJobOeeResult) {}
}

export class WorkOrdersLoading implements Action {
  readonly type = WORK_ORDERS_LOADING;

  constructor(public jobId: number) {}
}

export class WorkOrdersLoaded implements Action {
  readonly type = WORK_ORDERS_LOADED;

  constructor(public payload: WorkOrderScheduleInterface[]) {}
}

export class FloorPlanOeeTargetsLoading implements Action {
  readonly type = FLOOR_PLAN_OEE_TARGETS_LOADING;

  constructor(public floorPlanId: number, public currentDay: Moment) {}
}

export class FloorPlanOeeTargetsLoaded implements Action {
  readonly type = FLOOR_PLAN_OEE_TARGETS_LOADED;

  constructor(public payload: IFloorPlanTarget[]) {}
}

export class FloorPlanViewDataLoading implements Action {
  readonly type = FLOOR_PLAN_VIEW_DATA_LOADING;

  constructor(public siteId: number, public lineIds: number[]) {}
}

export class FloorPlanViewDataLoaded implements Action {
  readonly type = FLOOR_PLAN_VIEW_DATA_LOADED;

  constructor(public payload: SiteViewDataInterface) {}
}

export class FetchError implements Action {
  readonly type = FETCH_ERROR;

  constructor(public payload: object) {}
}

export type LayoutPlanViewActions =
  | FloorPlanLoading
  | FloorPlanLoaded
  | JobOeeLoading
  | JobOeeLoaded
  | WorkOrdersLoading
  | WorkOrdersLoaded
  | FloorPlanOeeTargetsLoading
  | FloorPlanOeeTargetsLoaded
  | FloorPlanViewDataLoading
  | FloorPlanViewDataLoaded
  | FetchError;
