import { Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Store } from '@ngrx/store';
import { OeeAppState } from '../../../../store/oee.reducer';
import {
  EWidgetType,
  WidgetConfigurationInterface
} from '../../../../shared/component/page-configuration/page-configuration.model';
import {
  ECheckInSourceType,
  ILaborAssetViewData,
  ILaborAssetViewState,
} from '../../../../store/dashboards/labor-asset-view/labor-asset-view.model';
import { Subscription } from 'rxjs';
import * as _ from 'lodash';
import { DecimalHelper } from '../../../../shared/helper/decimal/decimal-helper';
import { ELaborAssetViewWidget } from '../labor-asset-view.model';
import { DECIMAL_DEFAULT_SCALE_LIMIT } from '../../../../../constants';

@Component({
  selector: 'scw-labor-asset-view-kpi-card',
  templateUrl: './labor-asset-view-kpi-card.component.html',
  styleUrls: ['./labor-asset-view-kpi-card.component.scss'],
})
export class LaborAssetViewKpiCardComponent implements OnInit, OnChanges, OnDestroy {
  @Input() laborAssetViewData: ILaborAssetViewData[] = [];
  @Input() laborAssetViewWidgets: WidgetConfigurationInterface<EWidgetType.KPI_CARD>[] = [];

  private readonly subscriptions: Subscription[] = [];
  private readonly shortHour: string = this.translate.instant('general.shortHour');
  public laborAssetViewWidgetData: WidgetConfigurationInterface<EWidgetType.KPI_CARD>[] = [];
  public isDataLoading$: boolean = false;

  constructor(
    public translate: TranslateService,
    private readonly store: Store<OeeAppState>,
    private readonly decimalHelper: DecimalHelper,
  ) {}

  public ngOnInit(): void {
    this.subscriptions.push(
      this.store.select('laborAssetViewStore').subscribe((state: ILaborAssetViewState): void => {
        this.isDataLoading$ = state.laborAssetViewDataLoading;
      }),
    );
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if (
      (changes.hasOwnProperty('laborAssetViewWidgets') || changes.hasOwnProperty('laborAssetViewData')) &&
      Array.isArray(this.laborAssetViewWidgets) &&
      Array.isArray(this.laborAssetViewData)
    ) {
      this.prepareWidgetData();
    }
  }

  private prepareWidgetData(): void {
    this.laborAssetViewWidgetData = _.cloneDeep(this.laborAssetViewWidgets).map(
      (widget: WidgetConfigurationInterface<EWidgetType.KPI_CARD>): WidgetConfigurationInterface<EWidgetType.KPI_CARD> => {
        if (!this.laborAssetViewData.length) {
          return {
            ...widget,
          };
        }

        switch (widget.name) {
          case ELaborAssetViewWidget.TOTAL_CHECK_IN:
            widget.content.body[0].value = `${LaborAssetViewKpiCardComponent.totalUniqueCheckIns(this.laborAssetViewData)}`;
            break;
          case ELaborAssetViewWidget.AVAILABLE:
          case ELaborAssetViewWidget.AVAILABLE_SHORT:
            widget.content.body[0].value = `${LaborAssetViewKpiCardComponent.availableUserCount(this.laborAssetViewData)}`;
            break;
          case ELaborAssetViewWidget.HOURS:
            widget.content.body[0].value = `${this.calculateTotalCheckInHours(this.laborAssetViewData)} ${this.shortHour}`;
            break;
          case ELaborAssetViewWidget.LABOR:
            widget.content.body[0].value = `${LaborAssetViewKpiCardComponent.userUniqueCheckIns(this.laborAssetViewData)}`;
            break;
          case ELaborAssetViewWidget.LABOR_HOURS:
            widget.content.body[0].value = `${this.calculateUserCheckInHours(this.laborAssetViewData)} ${this.shortHour}`;
            break;
          case ELaborAssetViewWidget.ASSET:
            widget.content.body[0].value = `${LaborAssetViewKpiCardComponent.assetUniqueCheckIns(this.laborAssetViewData)}`;
            break;
          case ELaborAssetViewWidget.ASSET_HOURS:
            widget.content.body[0].value = `${this.calculateAssetCheckInHours(this.laborAssetViewData)} ${this.shortHour}`;
            break;
        }

        return widget;
      },
    );
  }

  private static totalUniqueCheckIns(laborAssetViewData: ILaborAssetViewData[]): number {
    return Object.keys(_.uniqBy(laborAssetViewData, LaborAssetViewKpiCardComponent.generateCompareSourceObject)).length;
  }

  private static availableUserCount(laborAssetViewData: ILaborAssetViewData[]): number {
    const availableLabors: ILaborAssetViewData[] = _.filter(laborAssetViewData, {
      sourceTypeId: ECheckInSourceType.USER,
      isAvailable: true,
      isOffline: false,
    });
    const availableAssets: ILaborAssetViewData[] = _.filter(laborAssetViewData, {
      sourceTypeId: ECheckInSourceType.EQUIPMENT,
      isOffline: false,
    });

    return Object.keys(
      _.uniqBy(availableLabors.concat(availableAssets), LaborAssetViewKpiCardComponent.generateCompareSourceObject),
    ).length;
  }

  private calculateTotalCheckInHours(laborAssetViewData: ILaborAssetViewData[]): string {
    let totalHoursInSeconds: number = 0;

    laborAssetViewData.forEach((dataRow: ILaborAssetViewData) => {
      totalHoursInSeconds += _.sumBy(dataRow.checkIns, 'duration');
    });

    const totalHours: string = DecimalHelper.divide(String(totalHoursInSeconds), '3600');

    return this.decimalHelper.toFixedValue(totalHours, DECIMAL_DEFAULT_SCALE_LIMIT);
  }

  private static userUniqueCheckIns(laborAssetViewData: ILaborAssetViewData[]): number {
    return Object.keys(
      _.uniqBy(
        _.filter(laborAssetViewData, { sourceTypeId: ECheckInSourceType.USER }),
        LaborAssetViewKpiCardComponent.generateCompareSourceObject,
      ),
    ).length;
  }

  private calculateUserCheckInHours(laborAssetViewData: ILaborAssetViewData[]): string {
    return this.calculateTotalCheckInHours(_.filter(laborAssetViewData, { sourceTypeId: ECheckInSourceType.USER }));
  }

  private static assetUniqueCheckIns(laborAssetViewData: ILaborAssetViewData[]): number {
    return Object.keys(
      _.uniqBy(
        _.filter(laborAssetViewData, { sourceTypeId: ECheckInSourceType.EQUIPMENT }),
        LaborAssetViewKpiCardComponent.generateCompareSourceObject,
      ),
    ).length;
  }

  private calculateAssetCheckInHours(laborAssetViewData: ILaborAssetViewData[]): string {
    return this.calculateTotalCheckInHours(
      _.filter(laborAssetViewData, { sourceTypeId: ECheckInSourceType.EQUIPMENT }),
    );
  }

  private static generateCompareSourceObject(value: ILaborAssetViewData) {
    return `${value.sourceTypeId}|${value.sourceObjectId}`;
  }

  public ngOnDestroy(): void {
    this.subscriptions.forEach((subscription: Subscription) => {
      subscription.unsubscribe();
    });
  }
}
