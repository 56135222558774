import * as Actions from './work-order-performance.actions';
import { IWorkOrderPerformanceState } from './work-order-performance.model';

export const workOrderPerformanceInitialState: IWorkOrderPerformanceState = {
  isWorkOrderInformationLoading: false,
  isWorkOrderInformationLoaded: false,
  isWorkOrderActivityLogsLoading: false,
  isWorkOrderActivityLogsLoaded: false,
  isWorkOrderOeeResultLoading: false,
  isWorkOrderOeeResultLoaded: false,
  isWorkOrderPhaseInformationLoading: false,
  isWorkOrderPhaseInformationLoaded: false,
  isWorkOrderLaborInformationLoading: false,
  isWorkOrderLaborInformationLoaded: false,
  isWorkOrderPeriodicOeeCalculationsLoading: false,
  isWorkOrderPeriodicOeeCalculationsLoaded: false,
  isProductExpectedSpeedLoading: false,
  isProductExpectedSpeedLoaded: false,
  isCommentsLoading: false,
  isCommentsLoaded: false,
  productExpectedSpeedInformation: null,
  workOrderInformation: null,
  activityLog: null,
  oeeResult: null,
  phaseInformation: null,
  laborInformation: null,
  workOrderComments: null,
  periodicOeeCalculations: null,
};

export function workOrderPerformanceReducer(
  state: IWorkOrderPerformanceState = workOrderPerformanceInitialState,
  action: Actions.WorkOrderPerformanceActions,
): IWorkOrderPerformanceState {
  switch (action.type) {
    case Actions.WORK_ORDER_INFORMATION_LOADING:
      return {
        ...state,
        isWorkOrderInformationLoading: true,
        isWorkOrderInformationLoaded: false,
      };

    case Actions.WORK_ORDER_INFORMATION_LOADED:
      return {
        ...state,
        isWorkOrderInformationLoaded: true,
        isWorkOrderInformationLoading: false,
        workOrderInformation: action.payload,
      };

    case Actions.WORK_ORDER_ACTIVITY_LOGS_LOADING:
      return {
        ...state,
        isWorkOrderActivityLogsLoading: true,
        isWorkOrderActivityLogsLoaded: false,
      };

    case Actions.WORK_ORDER_ACTIVITY_LOGS_LOADED:
      return {
        ...state,
        isWorkOrderActivityLogsLoaded: true,
        isWorkOrderActivityLogsLoading: false,
        activityLog: action.payload,
      };

    case Actions.WORK_ORDER_OEE_RESULT_LOADING:
      return {
        ...state,
        isWorkOrderOeeResultLoading: true,
        isWorkOrderOeeResultLoaded: false,
      };

    case Actions.WORK_ORDER_OEE_RESULT_LOADED:
      return {
        ...state,
        isWorkOrderOeeResultLoaded: true,
        isWorkOrderOeeResultLoading: false,
        oeeResult: action.payload,
      };

    case Actions.WORK_ORDER_PHASE_INFORMATION_LOADING:
      return {
        ...state,
        isWorkOrderPhaseInformationLoading: true,
        isWorkOrderPhaseInformationLoaded: false,
      };

    case Actions.WORK_ORDER_PHASE_INFORMATION_LOADED:
      return {
        ...state,
        isWorkOrderPhaseInformationLoaded: true,
        isWorkOrderPhaseInformationLoading: false,
        phaseInformation: action.payload,
      };

    case Actions.WORK_ORDER_LABOR_INFORMATION_LOADING:
      return {
        ...state,
        isWorkOrderLaborInformationLoading: true,
        isWorkOrderLaborInformationLoaded: false,
      };

    case Actions.WORK_ORDER_LABOR_INFORMATION_LOADED:
      return {
        ...state,
        isWorkOrderLaborInformationLoaded: true,
        isWorkOrderLaborInformationLoading: false,
        laborInformation: action.payload,
      };

    case Actions.WORK_ORDER_PERIODIC_OEE_CALCULATIONS_LOADING:
      return {
        ...state,
        isWorkOrderPeriodicOeeCalculationsLoading: true,
        isWorkOrderPeriodicOeeCalculationsLoaded: false,
      };

    case Actions.WORK_ORDER_PERIODIC_OEE_CALCULATIONS_LOADED:
      return {
        ...state,
        isWorkOrderPeriodicOeeCalculationsLoaded: true,
        isWorkOrderPeriodicOeeCalculationsLoading: false,
        periodicOeeCalculations: action.payload,
      };

    case Actions.COMMENTS_LOADING:
      return {
        ...state,
        isCommentsLoading: true,
        isCommentsLoaded: false,
      };

    case Actions.COMMENTS_LOADED:
      return {
        ...state,
        isCommentsLoading: false,
        isCommentsLoaded: true,
        workOrderComments: action.payload,
      };

    case Actions.FETCH_DATA_ERROR:
      return {
        ...state,
        isWorkOrderInformationLoaded: true,
        isWorkOrderInformationLoading: false,
        isWorkOrderActivityLogsLoaded: true,
        isWorkOrderActivityLogsLoading: false,
        isWorkOrderOeeResultLoaded: true,
        isWorkOrderOeeResultLoading: false,
        isWorkOrderPhaseInformationLoaded: true,
        isWorkOrderPhaseInformationLoading: false,
        isWorkOrderLaborInformationLoaded: true,
        isWorkOrderLaborInformationLoading: false,
        isProductExpectedSpeedLoading: false,
        isProductExpectedSpeedLoaded: true,
        isCommentsLoading: false,
        isCommentsLoaded: true,
      };

    default:
      return state;
  }
}
