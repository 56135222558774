import { Action } from '@ngrx/store';
import {
  BaseOneResponseInterface,
  BulkResponseDataInterface,
  GetManyResponseInterface,
} from '../../../shared/model/interface/crud-response-interface.model';
import { IAddEditProductFamily, IProductFamily, IProductFamilyTableQuery } from './product-family.model';
import { DownloadExcelFiltersInterface } from '../../../shared/service/excel/excel-helper.service';

export const PRODUCT_FAMILY_DATA_LOADING = '[PRODUCT_FAMILY] PRODUCT_FAMILY DATA LOADING';
export const PRODUCT_FAMILY_DATA_LOADED = '[PRODUCT_FAMILY] PRODUCT_FAMILY DATA LOADED';
export const ADD_PRODUCT_FAMILY = '[PRODUCT_FAMILY] ADD PRODUCT_FAMILY';
export const ADD_PRODUCT_FAMILY_COMPLETED = '[PRODUCT_FAMILY] ADD PRODUCT_FAMILY COMPLETED';
export const EDIT_PRODUCT_FAMILY = '[PRODUCT_FAMILY] EDIT PRODUCT_FAMILY';
export const EDIT_PRODUCT_FAMILY_COMPLETED = '[PRODUCT_FAMILY] EDIT PRODUCT_FAMILY COMPLETED';
export const DELETE_PRODUCT_FAMILY = '[PRODUCT_FAMILY] DELETE PRODUCT_FAMILY';
export const DELETE_PRODUCT_FAMILY_COMPLETED = '[PRODUCT_FAMILY] DELETE PRODUCT_FAMILY COMPLETED';
export const BULK_DELETE_PRODUCT_FAMILY = '[PRODUCT_FAMILY] BULK DELETE PRODUCT FAMILY';
export const BULK_DELETE_PRODUCT_FAMILIES_COMPLETED = '[PRODUCT_FAMILY] BULK DELETE PRODUCT FAMILIES COMPLETED';
export const DOWNLOAD_PRODUCT_FAMILY_EXCEL = '[PRODUCT_FAMILY] DOWNLOAD PRODUCT_FAMILY EXCEL';
export const DOWNLOAD_PRODUCT_FAMILY_EXCEL_COMPLETED = '[PRODUCT_FAMILY] DOWNLOAD PRODUCT_FAMILY EXCEL COMPLETED';
export const UPLOAD_PRODUCT_FAMILY_EXCEL = '[PRODUCT_FAMILY] UPLOAD PRODUCT FAMILY EXCEL';
export const UPLOAD_PRODUCT_FAMILY_EXCEL_COMPLETED = '[PRODUCT_FAMILY] UPLOAD PRODUCT FAMILY EXCEL COMPLETED';
export const PRODUCT_FAMILY_COUNT_LOAD = '[PRODUCT_FAMILY] PRODUCT FAMILY COUNT LOAD';
export const PRODUCT_FAMILY_COUNT_LOADED = '[PRODUCT_FAMILY] PRODUCT FAMILY COUNT LOADED';
export const FETCH_ERROR = '[PRODUCT_FAMILY] FETCH ERROR';

export class ProductFamilyDataLoading implements Action {
  readonly type = PRODUCT_FAMILY_DATA_LOADING;

  constructor(public tableQuery: IProductFamilyTableQuery) {}
}

export class ProductFamilyDataLoaded implements Action {
  readonly type = PRODUCT_FAMILY_DATA_LOADED;

  constructor(public payload: GetManyResponseInterface<IProductFamily>) {}
}

export class AddProductFamily implements Action {
  readonly type = ADD_PRODUCT_FAMILY;

  constructor(public productFamily: IAddEditProductFamily) {}
}

export class AddProductFamilyCompleted implements Action {
  readonly type = ADD_PRODUCT_FAMILY_COMPLETED;

  constructor(public payload: BaseOneResponseInterface<IAddEditProductFamily>) {}
}

export class EditProductFamily implements Action {
  readonly type = EDIT_PRODUCT_FAMILY;

  constructor(public productFamily: IAddEditProductFamily, public id: number) {}
}

export class EditProductFamilyCompleted implements Action {
  readonly type = EDIT_PRODUCT_FAMILY_COMPLETED;

  constructor(public payload: BaseOneResponseInterface<IAddEditProductFamily>) {}
}

export class DeleteProductFamily implements Action {
  readonly type = DELETE_PRODUCT_FAMILY;

  constructor(public payload: number) {}
}

export class DeleteProductFamilyCompleted implements Action {
  readonly type = DELETE_PRODUCT_FAMILY_COMPLETED;

  constructor(public payload: BulkResponseDataInterface) {}
}

export class BulkDeleteProductFamily implements Action {
  readonly type = BULK_DELETE_PRODUCT_FAMILY;

  constructor(public productFamilyIds: number[]) {}
}

export class BulkDeleteProductFamiliesCompleted implements Action {
  readonly type = BULK_DELETE_PRODUCT_FAMILIES_COMPLETED;

  constructor(public payload: BulkResponseDataInterface) {}
}

export class DownloadProductFamilyExcel implements Action {
  readonly type = DOWNLOAD_PRODUCT_FAMILY_EXCEL;
  constructor(public withData: boolean = false, public filters: DownloadExcelFiltersInterface) {}
}

export class DownloadProductFamilyExcelCompleted implements Action {
  readonly type = DOWNLOAD_PRODUCT_FAMILY_EXCEL_COMPLETED;
}

export class UploadProductFamilyExcel implements Action {
  readonly type = UPLOAD_PRODUCT_FAMILY_EXCEL;
  constructor(public productFamily: IProductFamily[]) {}
}

export class UploadProductFamilyExcelCompleted implements Action {
  readonly type = UPLOAD_PRODUCT_FAMILY_EXCEL_COMPLETED;
  constructor(public payload: (IProductFamily & { errorMessages?: string })[]) {}
}

export class ProductFamilyCountLoad implements Action {
  readonly type = PRODUCT_FAMILY_COUNT_LOAD;

  constructor(public siteId: number, public limit?: number) {}
}

export class ProductFamilyCountLoaded implements Action {
  readonly type = PRODUCT_FAMILY_COUNT_LOADED;

  constructor(public payload: IProductFamily[]) {}
}

export class FetchError implements Action {
  readonly type = FETCH_ERROR;

  constructor(public payload: object) {}
}

export type ProductFamilyActions =
  | ProductFamilyDataLoading
  | ProductFamilyDataLoaded
  | AddProductFamily
  | AddProductFamilyCompleted
  | EditProductFamily
  | EditProductFamilyCompleted
  | DeleteProductFamily
  | DeleteProductFamilyCompleted
  | BulkDeleteProductFamily
  | BulkDeleteProductFamiliesCompleted
  | DownloadProductFamilyExcel
  | DownloadProductFamilyExcelCompleted
  | UploadProductFamilyExcel
  | UploadProductFamilyExcelCompleted
  | ProductFamilyCountLoad
  | ProductFamilyCountLoaded
  | FetchError;
