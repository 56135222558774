<div class="radio-group-wrapper">
  <label *ngIf="groupLabel" class="radio-group-label">{{ groupLabel }}</label>
  <mat-radio-group [(ngModel)]="inputModel" (ngModelChange)="onNgModelChange()">
    <div *ngFor="let option of options">
      <mat-radio-button
        style="--customMargin: {{ customMargin ?? '0px' }}; --customScale: {{ customScale ?? 1.0 }};"
        [ngClass]="isAnyError ? 'has-error' : 'valid-input'"
        class="scw-mat-radio-button"
        [class.vertical-alignment]="alignment === 'vertical'"
        [disabled]="option.disabled || false"
        [value]="option.value"
        [labelPosition]="labelPosition"
      >
        {{ option.text }}
      </mat-radio-button>
      <ng-template class="error-message-required" [ngIf]="option.onSelectWarning && inputModel === option.value">
        <mat-hint class="radio-option-error" [class.error]="option.onSelectWarning">
          <em class="sub-text-icon fas" [class.fa-exclamation-circle]="option.onSelectWarning"></em>
          <ng-template [ngIf]="option.onSelectWarning">{{ option.onSelectWarning }}</ng-template>
        </mat-hint>
      </ng-template>
    </div>
  </mat-radio-group>
  <ng-template class="error-message-required" [ngIf]="errorText">
    <mat-hint [class.error]="errorText">
      <em class="sub-text-icon fas" [class.fa-exclamation-circle]="errorText"></em>
      <ng-template [ngIf]="errorText">{{ errorText }}</ng-template>
    </mat-hint>
  </ng-template>
</div>
