import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, mergeMap, switchMap } from 'rxjs/operators';
import * as ObjectActions from './production-view.actions';
import { of } from 'rxjs';
import { ProductionViewService } from './production-view.service';
import {
  BaseOneResponseInterface,
  GetManyResponseInterface,
} from '../../../shared/model/interface/crud-response-interface.model';
import { EntityTranslatorService } from '../../../shared/service/entity-translator/entity-translator.service';
import * as AppActions from '../../app/actions';
import { IProductionViewData } from './production-view.model';
import { IActivityLog } from '../../reports/root-cause-analysis/root-cause-analysis.model';
import { IMaintenanceModeData } from '../../app/model';
import { TagInterface } from '../../settings/tags/tags.model';

@Injectable()
export class ProductionViewEffects {
  constructor(
    private readonly actions$: Actions,
    private readonly service: ProductionViewService,
    private readonly entityTranslatorService: EntityTranslatorService,
  ) {}

  getProductionViewData = createEffect(() =>
    this.actions$.pipe(
      ofType(ObjectActions.GET_PRODUCTION_VIEW_DATA),
      mergeMap((objectData: ObjectActions.GetProductionViewData) => {
        return this.service.getProductionViewData(objectData.lineId).pipe(
          mergeMap((response: BaseOneResponseInterface<IProductionViewData>) => {
            if (response.data.activities && response.data.activities.rows) {
              response.data.activities.rows = response.data.activities.rows.map((activityLog: IActivityLog) => {
                this.entityTranslatorService.translate(activityLog);
                return {
                  ...activityLog,
                };
              });
            }

            return of(new ObjectActions.GetProductionViewDataCompleted(response.data));
          }),
          catchError((err) => {
            return of(new ObjectActions.ProductionViewFetchError(err), new AppActions.HideTopLoader());
          }),
        );
      }),
    ),
  );

  getGetMaintenanceModeData$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ObjectActions.GET_TAGS_DATA),
      switchMap((params: ObjectActions.GetTagsData) => {
        return this.service.getTags(params.siteIds).pipe(
          switchMap((response: GetManyResponseInterface<TagInterface>) => {
            return of(new ObjectActions.GetTagsDataCompleted(response.data));
          }),
          catchError((error) => {
            return of(new ObjectActions.ProductionViewFetchError(error));
          }),
        );
      }),
      catchError((error) => {
        return of(new ObjectActions.ProductionViewFetchError(error));
      }),
    ),
  );
}
