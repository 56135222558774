<div
  *ngIf="isWorkOrderMissing"
  (click)="onWorkOrderActivityHistoryModalOpen()"
  class="mr-2 d-flex align-items-center justify-content-center missing-data-badge"
>
  <i class="fas fa-exclamation-triangle missing-data-badge__icon"></i>
  <span class="missing-data-badge__text">{{ 'general.missingDataText' | translate }}</span>
</div>

<ng-template #activity_history_modal let-modal>
  <div class="modal-header scw-modal-header">
    <h3 class="modal-title scw-header">
      {{ 'workOrderSchedule.modals.activityHistory.header' | translate }}
    </h3>
    <button type="button" class="close" onfocus="blur()" aria-label="Close" (click)="onClickCancelButton()">
      <span aria-hidden="true">
        <em class="fas fa-times"></em>
      </span>
    </button>
  </div>
  <div class="modal-body">
    <div
      class="col-md-12 info-message"
      *ngIf="workOrderActivityHistoryModalData.completed || workOrderActivityHistoryModalData.canceled"
    >
      <div class="row">
        <div class="col-xs-1">
          <em class="fas fa-info-circle"></em>
        </div>
        <div class="col-md-11">
          <p>
            <ng-container *ngIf="workOrderActivityHistoryModalData.completed; else canceled">
              {{ 'workOrderSchedule.messages.workOrderIsCompleted' | translate }}
            </ng-container>
            <ng-template #canceled>
              {{ 'workOrderSchedule.messages.workOrderIsCanceledActivityHistory' | translate }}
            </ng-template>
          </p>
        </div>
      </div>
    </div>
    <scw-mat-button-group
      class="mb-3"
      [disabled]="isLoading"
      [buttons]="buttonGroup"
      [(inputModel)]="selectedGroupButtonOption"
      (inputModelChange)="onButtonGroupChange($event)"
    ></scw-mat-button-group>
    <app-activity-history-table
      [hasMissingData]="selectedGroupButtonOption"
      [configuration]="activityHistoryTableSettings"
      [calledFrom]="activityHistoryTableCalledFrom.WORK_ORDERS"
      [reloadLineViewAndMissingDataEmitter]="reloadLineViewAndMissingData"
    ></app-activity-history-table>
  </div>
  <div class="modal-footer">
    <div class="buttons-right">
      <scw-mat-button type="standard" (onClick)="onClickCancelButton()">
        {{ 'general.close' | translate }}
      </scw-mat-button>
    </div>
  </div>
</ng-template>
