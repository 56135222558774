export interface IProductCloneReviewData {
  masterData?: IProductCloneMasterData;
  productSpeed?: IProductCloneSpeedData[];
  productMultiplier?: IProductCloneMultiplierData[];
  unitMultiplier?: IProductCloneUnitMultiplierData[];
  productLineConfiguration?: IProductCloneLineConfigurationData[];
  productStationConfiguration?: IProductCloneStationConfigurationData[];
}

export interface IProductCloneMasterData {
  packageSize: number | null;
  unitName: string;
  standardSpeed: string | null;
  minWaitingDuration: string | null;
  maxWaitingDuration: string | null;
  customerName: string | null;
  productFamilyName: string | null;
  materialTypeId?: number;
  materialTypeName?: string;
  planningTypeId?: number;
  planningTypeName?: string;
  planningGroupName: string | null;
}

export interface IProductCloneSpeedData {
  lineName: string;
  productSpeed: string;
}

export interface IProductCloneMultiplierData {
  lineName: string;
  sensorTypeName: string;
  multiplierFactor: string;
}

export interface IProductCloneUnitMultiplierData {
  baseUnitName: string;
  referenceUnitName: string;
  baseUnitRawInput: string;
  referenceUnitRawInput: string;
}

export interface IProductCloneLineConfigurationData {
  path: string;
  lineName: string;
  pathLineSpeed: string;
}

export interface IProductCloneStationConfigurationData {
  lineName: string;
  linePath: string;
  productSpeed: string;
  stations?: IProductCloneStationSensorMultiplier[];
}

export interface IProductCloneStationSensorMultiplier {
  id: number;
  location: EProductCloneStationLocation;
  stationName: string;
  sensorTypeName: string;
  multiplierFactor: string;
}

export enum EProductCloneStationLocation {
  START = 'start',
  MID = 'mid',
  END = 'end',
}
