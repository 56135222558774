import { Inject, Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import {
  IEquipmentGroupTreeItem,
  ITaskGroupTreeItem,
  ITaskTreeItem,
  ITaskTreeNode,
  ITaskTreeResponse,
} from './equipment-task.model';
import { Observable } from 'rxjs';
import * as _ from 'lodash';
import {
  ENodeType,
  ETaskListMode,
  INodeGroup,
} from '../../shared/component/activity-buttons/task-selection/task-selection.model';
import * as ObjectActions from './equipment-task.actions';
import { BaseOneResponseInterface } from '../../shared/model/interface/crud-response-interface.model';

@Injectable({
  providedIn: 'root',
})
export class EquipmentTaskService {
  private readonly EQUIPMENT_TASK = {
    GET: {
      DATA_URL: `${this.baseUrl}/tasks/tree`,
    },
  };

  constructor(
    private readonly http: HttpClient,
    @Inject('API_BASE_URL')
    private readonly baseUrl: string,
  ) {}

  public getEquipmentsAndTasks(
    params: ObjectActions.NodeDataLoad,
  ): Observable<BaseOneResponseInterface<ITaskTreeResponse>> {
    let httpParams: HttpParams = new HttpParams()
      .append('lineId', params.rootAddress.lineId)
      .append('activityId', params.rootAddress.activityId)
      .append('mode', params.mode);
    const search = params.search?.trim();

    if (params.equipmentId !== undefined) {
      httpParams = httpParams.append('equipmentId', String(params.equipmentId));
    }

    if (params.taskTreeParentId !== undefined && !search) {
      httpParams = httpParams.append('taskTreeParentId', String(params.taskTreeParentId));
    }

    if (search) {
      httpParams = httpParams.append('search', search);
    }

    if (params.skipSingleOption) {
      httpParams = httpParams.append('skipSingleOption', 'true');
    }

    if (params.excludeHiddenFromHome) {
      httpParams = httpParams.append('excludeHiddenFromHome', 'true');
    }

    return this.http.get<BaseOneResponseInterface<ITaskTreeResponse>>(this.EQUIPMENT_TASK.GET.DATA_URL, {
      params: httpParams,
    });
  }

  public static getNodeGroups(
    nodes: ITaskTreeNode[],
    listMode: ETaskListMode = ETaskListMode.EQUIPMENT_BASED,
    selectedNode?: IEquipmentGroupTreeItem | ITaskGroupTreeItem,
  ): INodeGroup[] {
    const groups: INodeGroup[] = [];
    const cloneNodes: ITaskTreeNode[] = _.cloneDeep(nodes);
    const folders: ITaskTreeNode[] = cloneNodes.filter((node: ITaskTreeNode) => node.type === ENodeType.Folder);
    const equipments: ITaskTreeNode[] = nodes.filter((node: ITaskTreeNode) => node.type === ENodeType.Equipment);
    const tasks: ITaskTreeItem[] = cloneNodes.filter(
      (node: ITaskTreeNode) => node.type === ENodeType.Task,
    ) as ITaskTreeItem[];
    let basedGroups: INodeGroup[] = [];

    if (selectedNode) {
      const virtualGroup: INodeGroup | undefined = EquipmentTaskService.getVirtualNodeGroup(cloneNodes, selectedNode);
      return [virtualGroup];
    }

    if (folders.length > 0) {
      groups.push({
        name: 'main.equipmentTaskSelection.folders',
        nodes: folders,
      });
    }

    switch (listMode) {
      case ETaskListMode.EQUIPMENT_BASED:
        basedGroups = EquipmentTaskService.getEquipmentBasedGroups(tasks, equipments);
        break;

      case ETaskListMode.TASK_BASED:
        basedGroups = EquipmentTaskService.getTaskBasedGroups(tasks);
        break;
    }

    return groups.concat(basedGroups);
  }

  private static getEquipmentBasedGroups(tasks: ITaskTreeItem[], equipments: ITaskTreeNode[]): INodeGroup[] {
    const groups: INodeGroup[] = [];
    const tasksWithoutEquipment = tasks.filter((node) => !node.customProperties.equipmentId);
    const tasksWithEquipment = tasks.filter((node) => node.customProperties.equipmentId);

    if (equipments.length > 0) {
      groups.push({
        name: 'main.equipmentTaskSelection.equipments',
        nodes: _.uniqBy(equipments, 'title'),
      });
    }

    if (tasksWithoutEquipment.length > 0) {
      groups.push({
        name: 'main.equipmentTaskSelection.tasks',
        nodes: tasksWithoutEquipment,
      });
    }

    if (tasksWithEquipment.length > 0) {
      groups.push({
        name: 'main.equipmentTaskSelection.tasks',
        nodes: tasksWithEquipment,
      });
    }

    return groups;
  }

  private static getTaskBasedGroups(tasks: ITaskTreeItem[]): INodeGroup[] {
    const groups: INodeGroup[] = [];
    const taskGroups: Record<string, ITaskTreeItem[]> = _.groupBy(tasks, 'title');
    const virtualGroups: ITaskGroupTreeItem[] = [];
    let others: ITaskTreeItem[] = [];

    for (const group of Object.values(taskGroups)) {
      if (group.length > 1) {
        const item: ITaskTreeItem = _.head(group);
        virtualGroups.push({ title: item.title, type: ENodeType.TaskGroup });
        continue;
      }

      others = others.concat(group);
    }

    if (virtualGroups.length > 0) {
      groups.push({
        name: 'main.equipmentTaskSelection.taskGroups',
        nodes: virtualGroups,
      });
    }

    if (others.length > 0) {
      groups.push({
        name: 'main.equipmentTaskSelection.tasks',
        nodes: others,
      });
    }

    return groups;
  }

  private static getVirtualNodeGroup(
    nodes: ITaskTreeNode[],
    selectedNode: IEquipmentGroupTreeItem | ITaskGroupTreeItem,
  ): INodeGroup | undefined {
    if (selectedNode.type === ENodeType.Equipment && nodes.length > 0) {
      return {
        nodes,
        name: 'main.equipmentTaskSelection.tasks',
      };
    }

    if (selectedNode.type === ENodeType.TaskGroup) {
      const selectedTasks = nodes.filter(
        (node: ITaskTreeNode) => node.type === ENodeType.Task && node.title === selectedNode.title,
      );

      if (selectedTasks.length > 0) {
        return {
          name: 'main.equipmentTaskSelection.tasks',
          nodes: selectedTasks,
        };
      }
    }

    return undefined;
  }
}
