import { Observable } from 'rxjs';
import { GetManyResponseInterface } from '../../../shared/model/interface/crud-response-interface.model';
import { IProductConfigurationsData } from '../../../view/settings/product-configurations/station-product-configurations/product-configurations.model';
import { IProductMultipliers } from '../product-multiplier/product-multiplier.model';
import { ProductInterface } from '../products/products.model';
import { IUnitConversion } from '../unit-conversion/unit-conversion.model';
import { ILookupData } from '../../lookups/lookups.model';
import { EProductScope } from '../../../view/settings/product-settings/products-new-design/product-clone/product-clone.model';

export type TProductCloneReviewObservableList = [
  Observable<GetManyResponseInterface<ProductInterface> | null>,
  Observable<GetManyResponseInterface<IProductMultipliers> | null>,
  Observable<GetManyResponseInterface<IUnitConversion> | null>,
  Observable<GetManyResponseInterface<IProductConfigurationsData> | null>,
  Observable<GetManyResponseInterface<ILookupData | null>>,
];

export enum EProductCloneReviewObservableFunction {
  GET_SOURCE_PRODUCT_INFORMATION = 0,
  GET_SOURCE_PRODUCT_LINE_MULTIPLIERS = 1,
  GET_SOURCE_PRODUCT_UNIT_MULTIPLIERS = 2,
  GET_SOURCE_PRODUCT_STATION_CONFIGURATIONS = 3,
  GET_LOOKUPS = 4,
}

export interface IProductCloneEndpointFunctions {
  [EProductCloneReviewObservableFunction.GET_SOURCE_PRODUCT_INFORMATION]: (
    sourceProductId: number,
  ) => Observable<GetManyResponseInterface<ProductInterface>>;
  [EProductCloneReviewObservableFunction.GET_SOURCE_PRODUCT_LINE_MULTIPLIERS]: (
    sourceProductId: number,
  ) => Observable<GetManyResponseInterface<IProductMultipliers>>;
  [EProductCloneReviewObservableFunction.GET_SOURCE_PRODUCT_UNIT_MULTIPLIERS]: (
    sourceProductId: number,
  ) => Observable<GetManyResponseInterface<IUnitConversion>>;
  [EProductCloneReviewObservableFunction.GET_SOURCE_PRODUCT_STATION_CONFIGURATIONS]: (
    sourceProductId: number,
  ) => Observable<GetManyResponseInterface<IProductConfigurationsData>>;
  [EProductCloneReviewObservableFunction.GET_LOOKUPS]: () => Observable<GetManyResponseInterface<ILookupData>>;
  emptyObservableFunc: () => Observable<null>;
}

export interface IProductClonePayload {
  searchForProductCloneTargets: any[];
  siteId: number;
  productToClone: number;
  scopes: EProductScope[];
}
