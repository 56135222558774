<div class="border-coloring-container">
  <div
    [ngClass]="['border-coloring-stroke', 'border-coloring-stroke-' + type]"
    [ngStyle]="{ backgroundColor: customColor }"
  ></div>
  <ng-container *ngIf="hyperlink.length; else noLink">
    <a class="cursor-pointer link-style border-coloring-text"
       [routerLink]="hyperlink"
       target="_blank">{{ text }}</a>
  </ng-container>
  <ng-template class="border-coloring-text" #noLink>{{ text }}</ng-template>
</div>
