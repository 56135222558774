import { Action } from '@ngrx/store';
import {
  IScheduleAdherenceData,
  IScheduleAdherenceFilters,
  IScheduleAdherenceJobData,
  IScheduleAdherenceJobTableRow,
  IScheduleAdherenceTableRow,
} from './schedule-adherence.model';
import { TabRowInterface } from '../../../shared/component/side-config-bar/side-config-bar.model';
import { ICustomConfigurationDbEntry } from '../../../shared/component/page-configuration/page-configuration.model';
import { ITableHeader } from '../../../../constants.model';
import { IScheduleAdherenceExcelFormattedFilterData } from '../../../view/reports/schedule-adherence/schedule-adherence-table/schedule-adherence-table.model';

export const SCHEDULE_ADHERENCE_DATA_LOADING = '[SCHEDULE_ADHERENCE] SCHEDULE ADHERENCE DATA LOADING';
export const SCHEDULE_ADHERENCE_DATA_LOADED = '[SCHEDULE_ADHERENCE] SCHEDULE ADHERENCE DATA LOADED';
export const SCHEDULE_ADHERENCE_FETCH_ERROR = '[SCHEDULE_ADHERENCE] SCHEDULE ADHERENCE FETCH ERROR';
export const SET_SELECTED_FILTERS = '[SCHEDULE_ADHERENCE] SET SELECTED FILTERS';
export const SET_TABLE_SETTINGS = '[SCHEDULE_ADHERENCE] SET TABLE SETTINGS';
export const SCHEDULE_ADHERENCE_DOWNLOAD_EXCEL = '[SCHEDULE_ADHERENCE] DOWNLOAD EXCEL';
export const SCHEDULE_ADHERENCE_DOWNLOAD_EXCEL_COMPLETED = '[SCHEDULE_ADHERENCE] DOWNLOAD EXCEL COMPLETED';
export const SCHEDULE_ADHERENCE_JOB_DOWNLOAD_EXCEL = '[SCHEDULE_ADHERENCE] DOWNLOAD JOB EXCEL';
export const SCHEDULE_ADHERENCE_JOB_DOWNLOAD_EXCEL_COMPLETED = '[SCHEDULE_ADHERENCE] DOWNLOAD JOB EXCEL COMPLETED';
export const SCHEDULE_ADHERENCE_JOB_DATA_LOADING = '[SCHEDULE_ADHERENCE] SCHEDULE ADHERENCE JOB DATA LOADING';
export const SCHEDULE_ADHERENCE_JOB_DATA_LOADED = '[SCHEDULE_ADHERENCE] SCHEDULE ADHERENCE JOB DATA LOADED';
export const CLEAR_STATES = '[SCHEDULE_ADHERENCE] CLEAR_STATES';

export class ScheduleAdherenceDataLoading implements Action {
  readonly type = SCHEDULE_ADHERENCE_DATA_LOADING;

  constructor(public filters: IScheduleAdherenceFilters) {}
}

export class ScheduleAdherenceDataLoaded implements Action {
  readonly type = SCHEDULE_ADHERENCE_DATA_LOADED;

  constructor(public payload: IScheduleAdherenceData) {}
}

export class ScheduleAdherenceJobsDataLoading implements Action {
  readonly type = SCHEDULE_ADHERENCE_JOB_DATA_LOADING;

  constructor(public filters: IScheduleAdherenceFilters) {}
}

export class ScheduleAdherenceJobsDataLoaded implements Action {
  readonly type = SCHEDULE_ADHERENCE_JOB_DATA_LOADED;

  constructor(public payload: IScheduleAdherenceJobData) {}
}

export class SetSelectedFilters implements Action {
  readonly type = SET_SELECTED_FILTERS;

  constructor(public filters: IScheduleAdherenceFilters) {}
}

export class SetTableSettings implements Action {
  readonly type = SET_TABLE_SETTINGS;

  constructor(
    public payload: TabRowInterface[],
    public setAsDefault: boolean,
    public customSections: ICustomConfigurationDbEntry[],
  ) {}
}

export class ScheduleAdherenceDownloadExcel implements Action {
  readonly type = SCHEDULE_ADHERENCE_DOWNLOAD_EXCEL;

  constructor(public data: IScheduleAdherenceTableRow[], public headers: ITableHeader[]) {}
}

export class ScheduleAdherenceDownloadExcelCompleted implements Action {
  readonly type = SCHEDULE_ADHERENCE_DOWNLOAD_EXCEL_COMPLETED;
}

export class ScheduleAdherenceJobDownloadExcel implements Action {
  readonly type = SCHEDULE_ADHERENCE_JOB_DOWNLOAD_EXCEL;

  constructor(
    public filterData: IScheduleAdherenceExcelFormattedFilterData,
    public jobData: IScheduleAdherenceJobTableRow[],
    public jobHeaders: ITableHeader[],
    public workOrdersTableDataForExcel: IScheduleAdherenceTableRow[],
    public workOrderHeaders: ITableHeader[],
  ) {}
}

export class ScheduleAdherenceJobDownloadExcelCompleted implements Action {
  readonly type = SCHEDULE_ADHERENCE_JOB_DOWNLOAD_EXCEL_COMPLETED;
}

export class ScheduleAdherenceClearStates implements Action {
  readonly type = CLEAR_STATES;
}

export class ScheduleAdherenceFetchError implements Action {
  readonly type = SCHEDULE_ADHERENCE_FETCH_ERROR;

  constructor(public payload: object) {}
}

export type ScheduleAdherenceActions =
  | ScheduleAdherenceDataLoading
  | ScheduleAdherenceDataLoaded
  | SetSelectedFilters
  | SetTableSettings
  | ScheduleAdherenceDownloadExcel
  | ScheduleAdherenceDownloadExcelCompleted
  | ScheduleAdherenceFetchError
  | ScheduleAdherenceJobsDataLoading
  | ScheduleAdherenceJobsDataLoaded
  | ScheduleAdherenceJobDownloadExcel
  | ScheduleAdherenceJobDownloadExcelCompleted
  | ScheduleAdherenceClearStates;
