import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, switchMap } from 'rxjs/operators';
import * as ObjectActions from './check-in-log-elastic.actions';
import { from, of } from 'rxjs';
import { CheckInLogElasticService } from './check-in-log-elastic.service';
import {
  CheckInLogElasticCardDataInterface,
  CheckInLogElasticTableResponseInterface,
  CheckInTableGroupedByNoneInterface,
} from './check-in-log-elastic.model';
import * as AppActions from '../../app/actions';
import { Store } from '@ngrx/store';
import * as oeeAppReducer from '../../oee.reducer';
import { ErrorMessageService } from '../../../shared/service/error-message.service';
import { ExcelHelperService } from '../../../shared/service/excel/excel-helper.service';
import {
  BaseOneResponseInterface,
  BulkResponseDataInterface,
} from '../../../shared/model/interface/crud-response-interface.model';
import { ElasticDataInterface } from '../../../shared/model/interface/generic-api-response.model';
import moment from 'moment';
import { HelperService } from '../../../shared/service/helper.service';
import { emptyAction } from '../../../../constants';

@Injectable()
export class CheckInLogElasticEffects {
  constructor(
    private readonly actions$: Actions,
    private readonly store: Store<oeeAppReducer.OeeAppState>,
    private readonly errorMessageService: ErrorMessageService,
    private readonly excelHelperService: ExcelHelperService,
    private readonly helperService: HelperService,
    public service: CheckInLogElasticService,
  ) {}


  getCheckInLogCardsData = createEffect(() => this.actions$.pipe(
    ofType(ObjectActions.START_CHECK_IN_LOG_ELASTIC_CARDS_DATA_LOADING),
    switchMap((objectData: ObjectActions.StartCheckInLogElasticCardsDataLoading) => {
      return from(this.service.getCheckInCardData(objectData.query)).pipe(
        switchMap((response: CheckInLogElasticCardDataInterface) => {
          return of(new ObjectActions.CheckInLogElasticCardsDataLoaded(response));
        }),
        catchError((err) => {
          return of(new ObjectActions.FetchDataError(err));
        }),
      );
    }),
  ));


  getCheckInLogTableData = createEffect(() => this.actions$.pipe(
    ofType(ObjectActions.START_CHECK_IN_LOG_ELASTIC_TABLE_DATA_LOADING),
    switchMap((objectData: ObjectActions.StartCheckInLogElasticTableDataLoading) => {
      return from(this.service.getCheckInTableData(objectData.query, objectData.groupedBy)).pipe(
        map(
          (
            payload: BaseOneResponseInterface<ElasticDataInterface<CheckInLogElasticTableResponseInterface>[]>,
          ): CheckInTableGroupedByNoneInterface[] => {
            const response: CheckInTableGroupedByNoneInterface[] = [];

            payload.data.forEach((hit: ElasticDataInterface<CheckInLogElasticTableResponseInterface>) => {
              const source: CheckInLogElasticTableResponseInterface = hit._source;

              source.users.forEach((userInfo) => {
                const duration: number = moment(userInfo.checkIn.checkOutTime ?? '').diff(
                  moment(userInfo.checkIn.checkInTime),
                  'minutes',
                );

                response.push({
                  duration,
                  line: source.line.title,
                  station: source.lineStation.name,
                  startDate: userInfo.checkIn.checkInTime,
                  calculatedStartDate: userInfo.checkIn.checkInTime,
                  endDate: userInfo.checkIn.checkOutTime,
                  calculatedEndDate: userInfo.checkIn.checkOutTime,
                  sourceTypeId: source.sourceType.id,
                  sourceObjectId: userInfo.id,
                  sourceObjectName: userInfo.fullName,
                  sourceObjectShortName: userInfo.fullName,
                  sourceObjectViewName: userInfo.fullName,
                  destinationTypeId: source.destinationType.id,
                  destinationObjectId: source.destinationObject.id,
                  isOngoing: userInfo.checkIn.checkOutTime === null,
                  formattedDuration: this.helperService.formatDuration(duration),
                  status: 1, // missing
                });
              });
            });

            return response;
          },
        ),
        switchMap((response: CheckInTableGroupedByNoneInterface[]) => {
          return of(new ObjectActions.CheckInLogElasticTableDataLoaded(response), new AppActions.HideLoader());
        }),
        catchError((err) => {
          return of(new ObjectActions.FetchDataError(err), new AppActions.HideLoader());
        }),
      );
    }),
  ));


  $loadLines = createEffect(() => this.actions$.pipe(
    ofType(ObjectActions.LOAD_LINES),
    switchMap(() => {
      return this.service.loadLines().pipe(
        switchMap((response) => {
          return of(new ObjectActions.LinesLoaded(response));
        }),
        catchError((error) => {
          return of(new ObjectActions.FetchDataError(error));
        }),
      );
    }),
  ));


  $submitCheckInLog = createEffect(() => this.actions$.pipe(
    ofType(ObjectActions.SUBMIT_CHECK_IN_LOG_ELASTIC),
    switchMap((objectData: ObjectActions.SubmitCheckInLogElastic) => {
      this.store.dispatch(new AppActions.ShowTopLoader());

      return this.service.submitCheckInLog(objectData.formType, objectData.checkInLogElasticForm).pipe(
        switchMap((response) => {
          return of(
            new ObjectActions.SubmitCheckInLogElasticCompleted(objectData.formType, response),
            new AppActions.HideTopLoader(),
          );
        }),
        catchError((error) => {
          return of(new ObjectActions.FetchDataError(error), new AppActions.HideTopLoader());
        }),
      );
    }),
  ));


  $deleteCheckInLog = createEffect(() => this.actions$.pipe(
    ofType(ObjectActions.DELETE_CHECK_IN_LOG_ELASTIC),
    switchMap((objectData: ObjectActions.DeleteCheckInLogElastic) => {
      this.store.dispatch(new AppActions.ShowTopLoader());

      return this.service.deleteCheckInLog(objectData.id).pipe(
        switchMap((response) => {
          return of(new ObjectActions.DeleteCheckInLogElasticCompleted(response), new AppActions.HideTopLoader());
        }),
        catchError((error) => {
          return of(new ObjectActions.FetchDataError(error), new AppActions.HideTopLoader());
        }),
      );
    }),
  ));


  $bulkDeleteCheckInLog = createEffect(() => this.actions$.pipe(
    ofType(ObjectActions.BULK_DELETE_CHECK_IN_LOG_ELASTIC),
    switchMap((objectData: ObjectActions.BulkDeleteCheckInLogElastic) => {
      this.store.dispatch(new AppActions.ShowLoader());

      return this.service.bulkDeleteCheckInLog(objectData.checkIns).pipe(
        switchMap((response: BulkResponseDataInterface) => {
          this.errorMessageService.getTranslatedErrorMessage(response.data);
          const mergedArray = this.excelHelperService.mergeBulkResponseWithRequestData(
            response,
            objectData.checkIns.map((item) => {
              return { id: item };
            }),
          );
          return of(new ObjectActions.BulkDeleteCheckInLogElasticCompleted(mergedArray), new AppActions.HideLoader());
        }),
        catchError((error) => {
          return of(new ObjectActions.FetchDataError(error), new AppActions.HideLoader());
        }),
      );
    }),
    catchError((error) => {
      return of(new ObjectActions.FetchDataError(error), new AppActions.HideLoader());
    }),
  ));


  downloadExcel = createEffect(() => this.actions$.pipe(
    ofType(ObjectActions.DOWNLOAD_CHECK_IN_LOG_ELASTIC_EXCEL),
    switchMap((objectData: ObjectActions.DownloadCheckInLogsElasticExcel) => {
      const filters = {
        ...objectData.filters,
        ...{
          page: 1,
          offset: 0,
          pageSize: 1000,
        },
      };
      this.service.downloadExcel(filters, objectData.groupedBy);
      return emptyAction;
    }),
  ));
}
