import { HttpClient, HttpParams } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { SensorTypeEnum } from '../../../../../constants';
import * as moment from 'moment-timezone';
import { mysqlTimestampFormat } from '../../../helper/date';
import { IGetSensorsOfLinesResponse } from '../../../../view/settings/home-page-display-settings/home-mini-metrics/home-mini-metrics-settings-container.types';
import { GetManyResponseInterface } from '../../../../shared/model/interface/crud-response-interface.model';

@Injectable({ providedIn: 'root' })
export class HomeMiniKpiSettingsService {
  private readonly routes = {
    getSensorList: '/sensor-list',
  };
  constructor(private readonly http: HttpClient, @Inject('API_BASE_URL') private readonly apiURL: string) {}

  public getLineSensors(
    lineIds: number[],
    sensorTypes: SensorTypeEnum[],
    timezone: string,
  ): Observable<GetManyResponseInterface<IGetSensorsOfLinesResponse>> {
    const url: string = `${this.apiURL}${this.routes.getSensorList}`;

    let params: HttpParams = new HttpParams();

    params = params.set(
      's',
      JSON.stringify({
        $and: [
          { lineId: { $in: lineIds } },
          { sensorType: { $in: sensorTypes } },
          { startDate: { $lte: moment().tz(timezone).format(mysqlTimestampFormat) } },
          { endDate: { $gte: moment().tz(timezone).format(mysqlTimestampFormat) } },
        ],
      }),
    );

    return this.http.get<GetManyResponseInterface<IGetSensorsOfLinesResponse>>(url, { params });
  }
}
