import { Action } from '@ngrx/store';
import {
  ActivityEventsResponseDataInterface,
  GetWorkOrderGoodCountLoadedInterface,
  SaveUpdateOrDeleteActivityEventDataInterface,
  WorkOrderResponseDataInterface,
  WorkOrdersUpdateGoodAndScrapInterface,
  ActivityAddOrEditResponseInterface,
  OverlappedActivitiesDataInterface,
  OverlappingDataInterface,
  FixedAndCurrentVersionInterface,
  OngoingActivityValidationDataInterface,
  ILineViewParams,
  EventListInterface,
  ILineViewAdvancedFilterParams,
} from './activity-history.model';
import { ActivityHistoryBulkUpdateRequestModel } from '../../shared/component/activity-form/activity-form.model';
import { IActivityHistory } from '../../shared/service/activity-history/activity-history.model';
import { IAdvancedFilterOutput } from '../../shared/component/filter/advanced-filter/advanced-filter.model';
import { BulkResponseDataInterface } from '../../shared/model/interface/crud-response-interface.model';

export enum ActivityHistoryActionTypes {
  GetWorkOrders = '[ActivityHistory] Load Activity Histories',
  GetWorkOrdersLoaded = '[ActivityHistory] Load Activity Histories Loaded',
  GetActivityEvents = '[ActivityHistory] Load Activity Events',
  GetActivityEventsLoaded = '[ActivityHistory] Load Activity Events Loaded',
  GetActivityEventMissingData = '[ActivityHistory] Get Activity Event Missing Data',
  GetActivityEventMissingDataLoaded = '[ActivityHistory] Get Activity Event Missing Data Loaded',
  GetActivityEventData = '[ActivityHistory] Get Activity Event Data',
  GetActivityEventDataCompleted = '[ActivityHistory] Get Activity Event Data Completed',
  FlushActivityEventData = '[ActivityHistory] Flush Activity Event Data',
  WorkOrdersUpdateGoodAndScrap = '[ActivityHistory] Work Orders Update Good and Scrap',
  GetWorkOrderGoodCount = '[ActivityHistory] Get Work Order Good Count',
  GetWorkOrderGoodCountLoaded = '[ActivityHistory] Get Work Order Good Count Loaded',
  SaveOrUpdateBulkActivityEventData = '[ActivityHistory] Save Or Update Bulk Activity Event Data',
  SaveOrUpdateBulkActivityEventDataCompleted = '[ActivityHistory] Save Or Update Bulk Activity Event Data Completed',
  SaveUpdateOrDeleteActivityEventData = '[ActivityHistory] Save Update Or Delete Activity Event Data',
  SaveUpdateOrDeleteActivityEventDataCompleted = '[ActivityHistory] Save Update Or Delete Activity Event Data Completed',
  ClearFormDataStatus = '[ActivityHistory] Clear Form Data Status',
  FetchError = '[ActivityHistory] Fetch Error',
  ValidateActivityOverlap = '[ActivityHistory] Validate Activity Overlap',
  ValidateActivityOverlapCompleted = '[ActivityHistory] Validate Activity Overlap Completed',
  ValidateOngoingActivityOverlap = '[ActivityHistory] Validate Ongoing Activity Overlap',
  ValidateOngoingActivityOverlapCompleted = '[ActivityHistory] Validate Ongoing Activity Overlap Completed',
  SetOverlapActivityParametersDefault = '[ActivityHistory] Set Overlap Activity Parameters Default',
  AcceptFixedVersionForActivityOverlap = '[ActivityHistory] Accept Fixed Version For Activity Overlap',
  AcceptFixedVersionForActivityOverlapCompleted = '[ActivityHistory] Accept Fixed Version For Activity Overlap Completed',
  DeleteManyActivityHistoryData = '[ActivityHistory] Delete Many Activity History Data',
  DeleteManyActivityHistoryDataCompleted = '[ActivityHistory] Delete Many Activity History Data Completed',
  SetSelectedFilters = '[ActivityHistory] Set Selected Filters',
  GetInProgressWorkOrders = '[ActivityHistory] Load In Progress Work Orders',
  GetInProgressWorkOrdersLoaded = '[ActivityHistory] Load In Progress Work Orders Completed',
  ValidateErrorActivityOverlap = '[ActivityHistory] Validate Error Activity Overlap',
  ValidateErrorOngoingActivityOverlap = '[ActivityHistory] Validate Error Ongoing Activity Overlap',
}

export class GetWorkOrders implements Action {
  readonly type = ActivityHistoryActionTypes.GetWorkOrders;
  constructor(
    public page: number,
    public pageSize: number,
    public action: string,
    public orderDesc?: number | string,
    public orderBy?: string,
    public search?: string,
    public completed?: number | null,
    public canceled?: number | null,
  ) {}
}

export class GetWorkOrdersLoaded implements Action {
  readonly type = ActivityHistoryActionTypes.GetWorkOrdersLoaded;
  constructor(public payload: WorkOrderResponseDataInterface) {}
}

export class GetActivityEvents implements Action {
  readonly type = ActivityHistoryActionTypes.GetActivityEvents;
  constructor(
    public page: number,
    public pageSize: number,
    public orderDesc?: number | string,
    public orderBy?: string,
    public search?: string,
    public advancedFilter?: IAdvancedFilterOutput,
    public workOrderId?: number,
  ) {}
}

export class GetActivityEventsLoaded implements Action {
  readonly type = ActivityHistoryActionTypes.GetActivityEventsLoaded;
  constructor(public payload: ActivityEventsResponseDataInterface) {}
}

export class GetActivityEventMissingData implements Action {
  readonly type = ActivityHistoryActionTypes.GetActivityEventMissingData;
  constructor(
    public page: number,
    public pageSize: number,
    public orderDesc?: number | string,
    public orderBy?: string,
    public search?: string,
    public advancedFilter?: IAdvancedFilterOutput,
    public workOrderId?: number,
  ) {}
}

export class GetActivityEventMissingDataLoaded implements Action {
  readonly type = ActivityHistoryActionTypes.GetActivityEventMissingDataLoaded;
  constructor(public payload: ActivityEventsResponseDataInterface) {}
}

export class GetActivityEventData implements Action {
  readonly type = ActivityHistoryActionTypes.GetActivityEventData;
  constructor(public id: number) {}
}

export class GetActivityEventDataCompleted implements Action {
  readonly type = ActivityHistoryActionTypes.GetActivityEventDataCompleted;
  constructor(public payload: IActivityHistory) {}
}

export class FlushActivityEventData implements Action {
  readonly type = ActivityHistoryActionTypes.FlushActivityEventData;
}

export class WorkOrdersUpdateGoodAndScrap implements Action {
  readonly type = ActivityHistoryActionTypes.WorkOrdersUpdateGoodAndScrap;
  constructor(public payload: WorkOrdersUpdateGoodAndScrapInterface) {}
}

export class GetWorkOrderGoodCount implements Action {
  readonly type = ActivityHistoryActionTypes.GetWorkOrderGoodCount;
  constructor(public workOrderId: number) {}
}

export class GetWorkOrderGoodCountLoaded implements Action {
  readonly type = ActivityHistoryActionTypes.GetWorkOrderGoodCountLoaded;
  constructor(public payload: GetWorkOrderGoodCountLoadedInterface) {}
}

export class SaveUpdateOrDeleteActivityEventData implements Action {
  readonly type = ActivityHistoryActionTypes.SaveUpdateOrDeleteActivityEventData;
  constructor(public payload: SaveUpdateOrDeleteActivityEventDataInterface = null, public deleteId: number = null) {}
}

export class SaveOrUpdateBulkActivityEventData implements Action {
  readonly type = ActivityHistoryActionTypes.SaveOrUpdateBulkActivityEventData;
  constructor(public data: ActivityHistoryBulkUpdateRequestModel) {}
}

export class SaveOrUpdateBulkActivityEventDataCompleted implements Action {
  readonly type = ActivityHistoryActionTypes.SaveOrUpdateBulkActivityEventDataCompleted;

  constructor(public response: BulkResponseDataInterface) {}
}

export class SaveOrUpdateActivityEventDataCompleted implements Action {
  readonly type = ActivityHistoryActionTypes.SaveUpdateOrDeleteActivityEventDataCompleted;
  constructor(public payload: ActivityAddOrEditResponseInterface) {}
}

export class ClearFormDataStatus implements Action {
  readonly type = ActivityHistoryActionTypes.ClearFormDataStatus;
}
export class FetchError implements Action {
  readonly type = ActivityHistoryActionTypes.FetchError;

  constructor(public payload: any[], public actionType?: ActivityHistoryActionTypes) {}
}

export class ValidateActivityOverlap implements Action {
  readonly type = ActivityHistoryActionTypes.ValidateActivityOverlap;

  constructor(
    public payload: OverlappingDataInterface,
    public mainActivity: FixedAndCurrentVersionInterface,
    public stallLoader: boolean = false,
  ) {}
}

export class ValidateActivityOverlapCompleted implements Action {
  readonly type = ActivityHistoryActionTypes.ValidateActivityOverlapCompleted;

  constructor(public payload: OverlappedActivitiesDataInterface) {}
}

export class ValidateOngoingActivityOverlap implements Action {
  readonly type = ActivityHistoryActionTypes.ValidateOngoingActivityOverlap;

  constructor(
    public lineId: number,
    public activityInfo: OngoingActivityValidationDataInterface,
    public mainActivity: FixedAndCurrentVersionInterface,
  ) {}
}

export class ValidateOngoingActivityOverlapCompleted implements Action {
  readonly type = ActivityHistoryActionTypes.ValidateOngoingActivityOverlapCompleted;

  constructor(public payload: OverlappedActivitiesDataInterface) {}
}

export class SetOverlapActivityParametersDefault implements Action {
  readonly type = ActivityHistoryActionTypes.SetOverlapActivityParametersDefault;
}

export class AcceptFixedVersionForActivityOverlap implements Action {
  readonly type = ActivityHistoryActionTypes.AcceptFixedVersionForActivityOverlap;
  constructor(public data: ActivityHistoryBulkUpdateRequestModel) {}
}

export class AcceptFixedVersionForActivityOverlapCompleted implements Action {
  readonly type = ActivityHistoryActionTypes.AcceptFixedVersionForActivityOverlapCompleted;
}

export class DeleteManyActivityHistoryData implements Action {
  readonly type = ActivityHistoryActionTypes.DeleteManyActivityHistoryData;

  constructor(public data: number[]) {}
}

export class DeleteManyActivityHistoryDataCompleted implements Action {
  readonly type = ActivityHistoryActionTypes.DeleteManyActivityHistoryDataCompleted;

  constructor(public response: BulkResponseDataInterface) {}
}

export class SetSelectedFilters implements Action {
  readonly type = ActivityHistoryActionTypes.SetSelectedFilters;

  constructor(public response: ILineViewAdvancedFilterParams) {}
}

export class GetInProgressWorkOrders implements Action {
  readonly type = ActivityHistoryActionTypes.GetInProgressWorkOrders;
}

export class GetInProgressWorkOrdersLoaded implements Action {
  readonly type = ActivityHistoryActionTypes.GetInProgressWorkOrdersLoaded;
  constructor(public payload: WorkOrderResponseDataInterface) {}
}

export class ValidateErrorActivityOverlap implements Action {
  readonly type = ActivityHistoryActionTypes.ValidateErrorActivityOverlap;
  constructor() {}
}

export class ValidateErrorOngoingActivityOverlap implements Action {
  readonly type = ActivityHistoryActionTypes.ValidateErrorOngoingActivityOverlap;
  constructor() {}
}

export type ActivityHistoryActions =
  | GetWorkOrders
  | GetWorkOrdersLoaded
  | GetActivityEvents
  | GetActivityEventsLoaded
  | GetActivityEventMissingData
  | GetActivityEventMissingDataLoaded
  | GetActivityEventData
  | GetActivityEventDataCompleted
  | FlushActivityEventData
  | WorkOrdersUpdateGoodAndScrap
  | GetWorkOrderGoodCount
  | GetWorkOrderGoodCountLoaded
  | SaveOrUpdateBulkActivityEventData
  | SaveOrUpdateBulkActivityEventDataCompleted
  | SaveUpdateOrDeleteActivityEventData
  | SaveOrUpdateActivityEventDataCompleted
  | ClearFormDataStatus
  | FetchError
  | ValidateErrorActivityOverlap
  | ValidateErrorOngoingActivityOverlap
  | ValidateActivityOverlap
  | ValidateActivityOverlapCompleted
  | ValidateOngoingActivityOverlap
  | ValidateOngoingActivityOverlapCompleted
  | SetOverlapActivityParametersDefault
  | AcceptFixedVersionForActivityOverlap
  | AcceptFixedVersionForActivityOverlapCompleted
  | DeleteManyActivityHistoryData
  | DeleteManyActivityHistoryDataCompleted
  | SetSelectedFilters
  | GetInProgressWorkOrders
  | GetInProgressWorkOrdersLoaded;
