<scw-mat-form (scwMatFormSubmit)="submitForm($event)">
  <div class="modal-body">
    <div class="row" *ngIf="allFieldsDisableStatus && !defaultData.isGapActivity">
      <div class="col-md-12 warning-message">
        <em class="fas fa-info-circle fa-lg float-left"></em>
        <p class="m-t-5 float-left">{{ 'activityHistory.form.warningMessage' | translate }}</p>
      </div>
    </div>
    <div class="row" *ngIf="isBulkEditForm && defaultData?.bulkEdit?.woMissingData && !defaultData.isGapActivity">
      <div class="col-md-12 warning-message">
        <em class="fas fa-info-circle fa-lg float-left mr-2"></em>
        <p class="float-left">
          {{ 'activityReview.messages.missingWorkOrder' | translate }}
        </p>
      </div>
    </div>
    <div class="row" *ngIf="isBulkEditForm && defaultData?.bulkEdit?.taskMissingData && !defaultData.isGapActivity">
      <div class="col-md-12 warning-message">
        <em class="fas fa-info-circle fa-lg float-left"></em>
        <p class="float-left">
          {{ 'activityReview.messages.missingTask' | translate }}
        </p>
      </div>
    </div>
    <div class="row m-0" [ngClass]="helperService.isTouchDevice ? 'margin-left-unset' : ''">
      <div class="col-md-6">
        <div class="row" *ngIf="!isBulkEditForm">
          <div [ngClass]="defaultData?.isGapActivity ? 'col-md-6 time-picker-disabled' : 'col-md-6'">
            <div
              scwDynamicComponent
              [context]="startDateContext"
              (componentInstance)="startDatepickerEventListener($event)"
              data-automation-id="start-date-field"
            ></div>
          </div>
          <div [ngClass]="defaultData?.isGapActivity ? 'col-md-6 time-picker-disabled' : 'col-md-6'">
            <div
              scwDynamicComponent
              [context]="endDateContext"
              (componentInstance)="endDatepickerEventListener($event)"
              data-automation-id="end-date-field"
            ></div>
          </div>
        </div>
        <div class="row" *ngIf="shownFields.activity">
          <scw-mat-checkbox
            class="mr-2"
            *ngIf="isBulkEditForm && !defaultData.isGapActivity"
            [(inputModel)]="activityForm.activityName.isEnabled"
            (inputModelChange)="onBulkEditCheckboxChange($event, 'activityName', [activityName])"
            [disabled]="isTaskMissingDataBulk || allFieldsDisableStatus"
          >
          </scw-mat-checkbox>
          <div [ngClass]="isBulkEditForm ? 'col-md-11' : 'col-md-12'">
            <div class="d-flex">
              <scw-mat-picker
                class="flex-grow-1"
                label="{{ 'activityHistory.form.activity' | translate }}*"
                (onClick)="activityUpdateModal(activity_change_modal)"
                [(inputModel)]="activityPickerInputModel"
                [plannedDownTimeActivityColors]="plannedDownTimeActivityInputColors$"
                [rules]="activityForm.activityName.rules"
                [disabled]="
                  (isBulkEditForm && !activityForm.activityName.isEnabled) ||
                  (!isBulkEditForm && !activityForm.line.value.length) ||
                  allFieldsDisableStatus
                "
                #activityName
                data-automation-id="select-activity-btn"
              >
              </scw-mat-picker>
              <app-comment-feed-button
                class="ml-4 mt-4"
                [activityName]="defaultData?.activity?.name"
                maxWidth="180px"
                [objectIds]="
                  isBulkEditForm ? defaultData?.bulkEdit?.selectedBulkActivities ?? [] : [defaultData?.id ?? undefined]
                "
                [disabled]="defaultData?.isGapActivity"
                objectType="activity_history"
                [(inMemoryComments)]="inMemoryComments"
                text="{{ 'commentFeedButton.title' | translate }}"
              ></app-comment-feed-button>
            </div>
          </div>
        </div>
        <div class="row" *ngIf="shownFields.task">
          <scw-mat-checkbox
            *ngIf="isBulkEditForm && !defaultData.isGapActivity"
            class="mr-2"
            [(inputModel)]="activityForm.taskName.isEnabled"
            (inputModelChange)="onBulkEditCheckboxChange($event, 'taskName', [taskName])"
            [disabled]="true"
          >
          </scw-mat-checkbox>
          <div [ngClass]="isBulkEditForm ? 'col-md-11' : 'col-md-12'">
            <scw-mat-picker
              label="{{ 'activityHistory.form.selectTask' | translate }}*"
              (onClick)="taskUpdateModal(task_selection)"
              [(inputModel)]="taskPickerInputModel"
              [rules]="activityForm.taskName.rules"
              [disabled]="
                selectedActivityType === null ||
                (isBulkEditForm && !activityForm.taskName.isEnabled) ||
                allFieldsDisableStatus ||
                !activityForm.activityName.value?.length
              "
              #taskName
              data-automation-id="select-task-btn"
            >
            </scw-mat-picker>
          </div>
        </div>
        <div class="row" [class.d-none]="!shownFields.workOrderNumber">
          <scw-mat-checkbox
            *ngIf="isBulkEditForm && !defaultData.isGapActivity"
            class="mr-2"
            [(inputModel)]="activityForm.workOrder.isEnabled"
            (inputModelChange)="onBulkEditCheckboxChange($event, 'workOrder', [workOrder])"
            [disabled]="isBulkRunTime || missingWorkOrderBulk || allFieldsDisableStatus"
          >
          </scw-mat-checkbox>
          <div [ngClass]="isBulkEditForm ? 'col-md-11' : 'col-md-12'">
            <div class="d-flex">
              <scw-mat-picker
                class="flex-grow-1"
                label="{{
                  selectedActivityType !== activityTypes.IDLE_TIME && !runWithoutWorkOrder
                    ? workOrderPickerLabelTranslation + '*'
                    : workOrderPickerLabelTranslation
                }}"
                [disabled]="
                  (isBulkEditForm && !activityForm.workOrder.isEnabled) ||
                  (!isBulkEditForm && !activityForm.line.value.length) ||
                  allFieldsDisableStatus ||
                  (calledFrom === activityHistoryTableCalledFrom.WORK_ORDERS && !enabledFields['workOrder'])
                "
                [(inputModel)]="workOrderPickerInputModel"
                [rules]="
                  selectedActivityType !== activityTypes.IDLE_TIME && !runWithoutWorkOrder
                    ? activityForm.workOrder.rules
                    : []
                "
                (onClick)="openSelectWorkOrderModal(work_order_edit)"
                #workOrder
                data-automation-id="select-work-order-btn"
              ></scw-mat-picker>
              <scw-mat-button
                *ngIf="
                  workOrderPickerInputModel?.value &&
                  (!isBulkEditForm || activityForm.workOrder.isEnabled) &&
                  (isBulkEditForm || activityForm.line.value.length) &&
                  !allFieldsDisableStatus &&
                  (calledFrom !== activityHistoryTableCalledFrom.WORK_ORDERS || enabledFields['workOrder'])
                "
                class="ml-4 mt-4"
                type="standard"
                [iconButton]="true"
                size="md"
                (onClick)="clearWorkOrder(true)"
              >
                <em class="fas fa-times"></em>
              </scw-mat-button>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-6">
        <scw-mat-select
          *ngIf="shownFields.site"
          [data]="sites"
          [disabled]="
            sites.length === 0 ||
            allFieldsDisableStatus ||
            (calledFrom === activityHistoryTableCalledFrom.WORK_ORDERS && !enabledFields['site']) ||
            defaultData?.isGapActivity
          "
          [(inputModel)]="activityForm.site.value"
          [enableSearchFilter]="true"
          [searchBehavior]="'live'"
          [rules]="activityFormRules.site"
          (inputModelChange)="siteChange.next(activityForm.site.value[0]?.id); getLines(true)"
          label="{{ 'activityHistory.form.site' | translate }}*"
          data-automation-id="activity-form-site-field"
        >
        </scw-mat-select>
        <scw-mat-select
          *ngIf="shownFields.line"
          [data]="lines"
          [(inputModel)]="activityForm.line.value"
          [enableSearchFilter]="true"
          [searchBehavior]="'live'"
          [rules]="activityFormRules.line"
          (inputModelChange)="lineSelected()"
          [disabled]="
            !activityForm.site.value.length ||
            allFieldsDisableStatus ||
            (calledFrom === activityHistoryTableCalledFrom.WORK_ORDERS && !enabledFields['line']) ||
            defaultData?.isGapActivity
          "
          label="{{ 'activityHistory.form.line' | translate }}*"
        >
        </scw-mat-select>
        <div class="row">
          <div *ngIf="shownFields.user" class="col-md-6">
            <scw-mat-input
              label="{{ 'activityHistory.form.startedBy' | translate }}*"
              [rules]="activityFormRules.user"
              [disabled]="true"
              [(inputModel)]="activityForm.startedByUser.value"
            >
            </scw-mat-input>
          </div>
          <div *ngIf="shownFields.user" class="col-md-6">
            <scw-mat-input
              label="{{ 'activityHistory.form.endedBy' | translate }}*"
              [disabled]="true"
              [(inputModel)]="activityForm.endedByUser.value"
            >
            </scw-mat-input>
          </div>
        </div>
        <div class="row">
          <div *ngIf="shownFields.user" class="col-md-6">
            <app-phase-selection
              [lineId]="activityForm.line.value.length ? activityForm.line?.value[0]?.id : null"
              [(phaseId)]="activityForm.phaseId.value"
              [isRequired]="true"
              [disabled]="allFieldsDisableStatus"
            ></app-phase-selection>
          </div>
          <scw-mat-checkbox
            *ngIf="isBulkEditForm"
            class="mr-2"
            [(inputModel)]="activityForm.crewSize.isEnabled"
            (inputModelChange)="onBulkEditCheckboxChange($event, 'crewSize', [crewSize])"
          >
          </scw-mat-checkbox>
          <div [ngClass]="isBulkEditForm ? 'col-md-11 pr-0' : 'col-md-6'">
            <div class="d-flex">
              <scw-mat-input
                [(inputModel)]="activityForm.crewSize.value"
                [disabled]="(isBulkEditForm && !activityForm.crewSize.isEnabled) || allFieldsDisableStatus"
                [rules]="activityFormRules.crew"
                label="{{ 'activityHistory.form.crewSize' | translate }}"
                #crewSize
                class="flex-grow-1 crewSizeInput"
              ></scw-mat-input>
              <scw-mat-button
                *ngIf="defaultData && defaultData.auditLogUuid"
                (onClick)="openActivityHistoryAuditLogModal()"
                [className]="['mt-4', 'ml-4']"
                [iconButton]="true"
                [type]="'standard'"
              >
                <i class="fas fa-history icon-font-size"></i>
              </scw-mat-button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <div class="buttons-left">
      <scw-mat-button
        *ngIf="defaultData !== null && !isBulkEditForm"
        [type]="'danger'"
        [disabled]="
          deleteInProgress ||
          selectedActivityDataLoading$ ||
          activityFormDataSaveLoading$ ||
          bulkEditLoading$ ||
          (defaultData && !isBulkEditForm && defaultData?.workOrder?.completed === 1) ||
          defaultData.isGapActivity
        "
        (onClick)="deleteActivityHistoryModal(delete_modal)"
      >
        {{ 'activityHistory.listView.deleteActivity' | translate }}
      </scw-mat-button>
    </div>
    <div class="buttons-right">
      <scw-mat-button
        type="standard"
        [disabled]="
          deleteInProgress || selectedActivityDataLoading$ || activityFormDataSaveLoading$ || bulkEditLoading$
        "
        (onClick)="cancelEmitter()"
      >
        {{ 'general.cancel' | translate }}
      </scw-mat-button>
      <scw-mat-button
        [disabled]="
          (isBulkEditForm && areAnyOfTheBulkEditFormSelectBoxesSelected === undefined) ||
          (defaultData && !isBulkEditForm && defaultData?.workOrder?.completed === 1)
        "
        [isSubmitButton]="true"
        data-automation-id="done-btn"
      >
        {{ 'general.saveChanges' | translate }}
      </scw-mat-button>
    </div>
  </div>
</scw-mat-form>
<ng-template #activity_change_modal let-modal>
  <div class="modal-header scw-modal-header">
    <h3 class="modal-title scw-header">{{ 'activityCards.selectActivity' | translate }}</h3>
    <button type="button" class="close" onfocus="blur()" aria-label="Close" (click)="modal.dismiss()">
      <span aria-hidden="true">
        <em class="fas fa-times"></em>
      </span>
    </button>
  </div>
  <div class="modal-body">
    <app-activity-selection-button
      (editedActivityButtonEvent)="editedActivityButton($event, workOrder)"
      [lineId]="activityForm.line.value.length ? activityForm.line.value[0]?.id : lineId"
      [siteId]="activityForm.site.value.length ? activityForm.site.value[0]?.id : siteId"
      [action]="'edit'"
      [allSites]="allSites$"
      [isCalledFromActivityHistory]="true"
    >
    </app-activity-selection-button>
  </div>
</ng-template>

<ng-template #task_selection let-modal>
  <div class="modal-header scw-modal-header">
    <h3 class="modal-title scw-header">
      {{ 'main.activityButtons.selectTaskTitle' | translate }}
    </h3>
    <button type="button" class="close" onfocus="blur()" aria-label="Close" (click)="modal.dismiss()">
      <span aria-hidden="true">
        <em class="fas fa-times"></em>
      </span>
    </button>
  </div>
  <div class="modal-body">
    <task-selection
      [rootAddress]="taskTreeRootAddress"
      (selectedTask)="editedEquipmentAndTask($event)"
    ></task-selection>
  </div>
  <div class="modal-footer">
    <div class="buttons-left">
      <scw-mat-button type="standard" (click)="modal.dismiss()">
        {{ 'general.back' | translate }}
      </scw-mat-button>
    </div>
  </div>
</ng-template>

<ng-template #work_order_edit let-modal>
  <div class="modal-header scw-modal-header">
    <h3 class="modal-title scw-header">
      <i class="far fa-list-alt"></i>
      {{ 'main.workOrder.selectWorkOrder' | translate }}
    </h3>
    <button type="button" class="close" onfocus="blur()" aria-label="Close" (click)="modal.dismiss()">
      <span aria-hidden="true">
        <em class="fas fa-times"></em>
      </span>
    </button>
  </div>
  <app-select-work-order
    (selectWoLineConfirmed)="afterSelectWorkOrder($event)"
    (onAddAndUpdateSubmit)="afterSelectWorkOrder($event.genericOutput, $event.workOrderModal)"
    (closeThisModal)="selectWorkOrderModalRef.dismiss()"
    (completedWoOnAddConfirmed)="afterSelectWorkOrder($event)"
    [workOrderHeader]="workOrderHeader"
    [configuration]="selectWorkOrderModalConfigurations"
    [workOrderCardLayout]="workOrderCardLayout"
  ></app-select-work-order>
</ng-template>

<ng-template #overlapping_activity_warning_modal let-modal>
  <div class="modal-header scw-modal-header">
    <h3 class="modal-title scw-header">
      {{ 'overlappingActivity.form.overlappingActivities' | translate }}
    </h3>
    <button type="button" class="close" onfocus="blur()" aria-label="Close" (click)="closeOverlappingActivityModal()">
      <span aria-hidden="true">
        <em class="fas fa-times"></em>
      </span>
    </button>
  </div>
  <app-overlapping-activity-modal
    [customColorParams]="customColorParams$"
    (sendOverlapActivityData)="sendData()"
    [closeAllModals]="closeAllModals"
    (backToActivityEdit)="closeOverlappingActivityModal()"
  ></app-overlapping-activity-modal>
</ng-template>

<ng-template #delete_modal let-modal>
  <div class="modal-header scw-modal-header">
    <h3 class="modal-title scw-header">
      <ng-template [ngIf]="isBulkEditForm" [ngIfElse]="singleDeleteHeader">
        {{ 'modal.header.deleteActivityHistories' | translate }}
      </ng-template>
      <ng-template #singleDeleteHeader>
        {{ 'modal.header.deleteActivityHistory' | translate }}
      </ng-template>
    </h3>
    <button type="button" class="close" onfocus="blur()" aria-label="Close" (click)="modal.dismiss()">
      <span aria-hidden="true">
        <em class="fas fa-times"></em>
      </span>
    </button>
  </div>
  <div class="modal-body">
    <div class="row">
      <div class="col-md-12">
        <h6 class="scw-header">
          <em class="fas fa-exclamation-circle"></em>&nbsp;
          <ng-template [ngIf]="isBulkEditForm" [ngIfElse]="singleDelete">
            {{
              'modal.activityHistory.deleteBulkInfo.label'
                | translate: { count: defaultData?.bulkEdit?.selectedBulkActivities?.length }
            }}
          </ng-template>
          <ng-template #singleDelete>
            {{ 'modal.activityHistory.deleteInfo.label' | translate }}
          </ng-template>
        </h6>
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <div class="buttons-right">
      <scw-mat-button type="standard" (onClick)="modal.dismiss()">
        {{ 'general.cancel' | translate }}
      </scw-mat-button>
      <scw-mat-button type="danger" (onClick)="deleteData()">
        {{ 'general.delete' | translate }}
      </scw-mat-button>
    </div>
  </div>
</ng-template>
