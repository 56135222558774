import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { mod } from 'mathjs';
import { EWeekday, days, defaultFirstWeekday } from 'src/app/store/user/model';

@Pipe({ name: 'selectedDaysSummary', standalone: true })
export class SelectedDaysSummaryPipe implements PipeTransform {
  public readonly labels: string[] = days.map((day: string) => this.translate.instant(`general.days.${day}.abbrev3`));

  constructor(private readonly translate: TranslateService) {}

  public transform(value: readonly EWeekday[], firstDay: EWeekday | undefined): string {
    if (value.length === this.labels.length) {
      return this.translate.instant('general.days.qualifier.all');
    }

    return [...value]
      .sort((a, b) => this.dayRank(a, firstDay) - this.dayRank(b, firstDay))
      .map((day: EWeekday) => this.labels[day])
      .join(', ');
  }

  private dayRank(day: EWeekday, firstDay: EWeekday): number {
    return mod(day - (firstDay ?? defaultFirstWeekday), days.length);
  }
}
