import { DropdownComponent } from '../../../shared/component/filter/dropdown/dropdown.component';
import {
  Equipment,
  FilterCardUser,
  Level,
  Line,
  Site,
} from '../../../shared/component/filter/filterable-objects.class';
import { EFilterDropdownElements } from '../../../store/filter/filter.model';
import { SqlOperators } from '../../../shared/component/filter/advanced-filter/advanced-filter.model';
import { UserLevels } from '../../../store/user/model';
import { ButtonGroupComponent } from '../../../shared/component/filter/button-group/button-group.component';
import {
  EGroupBy,
  ELaborAssetViewDataType,
  ELaborAssetViewType,
  EQueryType,
  GroupByDropdownOption,
} from './labor-asset-view.model';
import { FilterCardOptionInterface } from '../../../shared/component/filter/filter.class';
import { TranslateService } from '@ngx-translate/core';
import { DropdownOptionInterface } from '../../../shared/component/scw-mat-ui/scw-mat-select/scw-mat-select.model';

export class LaborAssetViewUtilities {
  public static getFilterOptions(translate: TranslateService): FilterCardOptionInterface {
    const filterOptionsCommonClass: string = 'col-xl-3 col-lg-4 col-md-3 m-t-5 m-b-5 ';

    return {
      rows: [
        [
          {
            type: DropdownComponent,
            cls: filterOptionsCommonClass,
            object: Site,
            elementId: EFilterDropdownElements.siteSingleSelectDropdown,
            outputOptions: {
              filterObjectId: 'site',
              filterObjectProp: 'id',
              returnFilterObjectAllProp: false,
            },
            options: {
              text: translate.instant('filterCard.site.dropdownPlaceHolder'),
              isRequired: true,
              singleSelection: true,
            },
          },
          {
            type: DropdownComponent,
            cls: filterOptionsCommonClass,
            object: Line,
            elementId: EFilterDropdownElements.lineMultiSelectDropdown,
            outputOptions: {
              filterObjectId: 'lines',
              filterObjectProp: 'id',
              returnFilterObjectAllProp: false,
            },
            options: {
              text: translate.instant('filterCard.line.dropdownPlaceHolder'),
              isRequired: false,
              getInitData: true,
            },
          },
          {
            elementId: EFilterDropdownElements.userMultiSelectDropdown,
            type: DropdownComponent,
            cls: filterOptionsCommonClass,
            object: FilterCardUser,
            outputOptions: {
              filterObjectId: 'userIds',
              filterObjectProp: 'sourceObjectId',
              returnFilterObjectAllProp: false,
            },
            options: {
              isRequired: false,
              limitSelection: 10,
              text: translate.instant('filterCard.user.dropdownPlaceHolder'),
              noDataLabel: translate.instant('filterCard.dropdown.labelForUserDepends'),
              primaryKey: 'sourceObjectId',
              getInitData: true,
            },
            dropdownDepends: [
              {
                property: 'destinationObjectId',
                parentElementId: EFilterDropdownElements.lineMultiSelectDropdown,
                parentProperty: 'id',
                conditionType: SqlOperators.$in,
                isDataLocatedAtCurrent: true,
              },
            ],
          },
          {
            type: DropdownComponent,
            cls: filterOptionsCommonClass,
            object: Level,
            filter: {
              condition: (element: { id: UserLevels }): boolean => element.id !== UserLevels.PLANNER,
            },
            elementId: EFilterDropdownElements.userLevelMultiSelectDropdown,
            outputOptions: {
              filterObjectId: 'userTypes',
              filterObjectProp: 'id',
              returnFilterObjectAllProp: false,
            },
            options: {
              text: translate.instant('filterCard.userLevel.dropdownPlaceHolder'),
              isRequired: false,
              isStaticDropdown: true,
              getInitData: true,
            },
          },
          {
            elementId: EFilterDropdownElements.equipmentMultiSelectDropdown,
            type: DropdownComponent,
            cls: filterOptionsCommonClass,
            object: Equipment,
            outputOptions: {
              filterObjectId: 'assetIds',
              filterObjectProp: 'id',
              returnFilterObjectAllProp: false,
            },
            options: {
              isRequired: false,
              enableServerSideSearch: true,
              clearSearchOnOpen: true,
              searchProps: [
                {
                  prop: 'equipmentName',
                  condition: '$cont',
                },
              ],
              badgeShowLimit: 1,
              text: translate.instant('filterCard.asset.dropdownPlaceHolder'),
              noDataLabel: translate.instant('filterCard.dropdown.labelForEquipmentDepends'),
              getInitData: true,
            },
            dependProperties: ['id'],
            dropdownDepends: [
              {
                property: 'siteId',
                parentElementId: EFilterDropdownElements.siteSingleSelectDropdown,
                parentProperty: 'id',
                conditionType: SqlOperators.$in,
              },
            ],
          },
          {
            type: ButtonGroupComponent,
            cls: filterOptionsCommonClass.concat('button-group-flex'),
            elementId: 'timeToggle',
            outputOptions: {
              filterObjectId: 'timeToggle',
              returnFilterObjectAllProp: false,
            },
            options: {
              isRequired: false,
              buttons: [
                {
                  text: translate.instant('general.ongoing'),
                  value: EQueryType.ONGOING,
                },
                {
                  text: translate.instant('general.last24Hours'),
                  value: EQueryType.LAST_24_HOURS,
                },
              ],
              value: EQueryType.ONGOING,
            },
          },
          {
            type: ButtonGroupComponent,
            cls: filterOptionsCommonClass.replace('lg-4', 'lg-6').concat('button-group-flex'),
            elementId: 'typeToggle',
            outputOptions: {
              filterObjectId: 'typeToggle',
              returnFilterObjectAllProp: false,
            },
            options: {
              isRequired: false,
              buttons: [
                {
                  text: translate.instant('general.all'),
                  value: ELaborAssetViewDataType.ALL,
                },
                {
                  text: translate.instant('schedulerScenario.laborSchedule.button.title'),
                  value: ELaborAssetViewDataType.LABOR,
                },
                {
                  text: translate.instant('globalView.infoCard.asset'),
                  value: ELaborAssetViewDataType.ASSET,
                },
              ],
              value: ELaborAssetViewDataType.ALL,
            },
          },
          {
            type: ButtonGroupComponent,
            cls: filterOptionsCommonClass.replace('lg-4', 'lg-6').concat('button-group-flex'),
            elementId: 'viewTypeToggle',
            outputOptions: {
              filterObjectId: 'viewTypeToggle',
              returnFilterObjectAllProp: false,
            },
            options: {
              isRequired: false,
              buttons: [
                {
                  text: translate.instant('laborAssetView.pageViewTypes.listView'),
                  value: ELaborAssetViewType.LIST_VIEW,
                },
                {
                  text: translate.instant('laborAssetView.pageViewTypes.cardView'),
                  value: ELaborAssetViewType.CARD_VIEW,
                },
              ],
              value: ELaborAssetViewType.CARD_VIEW,
            },
          },
        ],
      ],
    };
  }

  public static getGroupByOptions(translate: TranslateService): GroupByDropdownOption[] {
    return [
      {
        id: EGroupBy.STATION,
        name: translate.instant('laborAssetView.groupBy', { groupBy: translate.instant('general.station') }),
        titleValue: 'stationName',
        link: 'home/station/',
        linkId: 'stationId',
      },
      {
        id: EGroupBy.LINE,
        name: translate.instant('laborAssetView.groupBy', { groupBy: translate.instant('general.line') }),
        titleValue: 'lineName',
        link: 'home',
      },
      {
        id: EGroupBy.LINE_PATH,
        name: translate.instant('laborAssetView.groupBy', { groupBy: translate.instant('general.linePath') }),
        titleValue: 'linePathName',
      },
      {
        id: EGroupBy.DEPARTMENT,
        name: translate.instant('laborAssetView.groupBy', { groupBy: translate.instant('general.department') }),
        titleValue: 'lineType',
      },
      {
        id: EGroupBy.WORK_ORDER,
        name: translate.instant('laborAssetView.groupBy', { groupBy: translate.instant('general.workOrder') }),
        titleValue: 'woNumber',
        link: '/reports/work-order-performance/',
        linkId: 'workOrderId',
      },
      {
        id: EGroupBy.PRODUCT,
        name: translate.instant('laborAssetView.groupBy', { groupBy: translate.instant('general.product') }),
        titleValue: 'productId',
      },
      {
        id: EGroupBy.JOB,
        name: translate.instant('laborAssetView.groupBy', { groupBy: translate.instant('general.job') }),
        titleValue: 'jobName',
        link: '/reports/job-performance/',
        linkId: 'jobId',
      },
    ];
  }

  public static getFilterNamesToReset(): string[] {
    return [
      'typeToggle',
      EFilterDropdownElements.equipmentMultiSelectDropdown,
      EFilterDropdownElements.userMultiSelectDropdown,
      EFilterDropdownElements.userLevelMultiSelectDropdown,
    ];
  }
}
