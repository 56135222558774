<div class="page-body d-flex flex-column" [style.height]="viewHeight" #pageBody>
  <div id="filter-card" [hidden]="!isFilterCardVisible">
    <filter-card
      [options]="filterOptions"
      [defaultDropdownSelectionSubject]="filterDefaultDropdownSelectionSubject"
      [autoApply]="true"
      [strictControlForSelected]="true"
      [enableSetAsDefault]="true"
      (filterChange)="filterChange($event)"
      (setAsDefault)="onFilterSetAsDefault($event)"
      (filterEventListener)="filterEventListener()"
      [enableFilterUpdateCard]="isFilterUpdateCardEnabled"
      [mainGridWide]="filterCardMainGridWidth"
      [additionalGridWide]="filterCardAdditionalGridWidth"
    >
    </filter-card>
  </div>
  <warning-card
    *ngIf="!isAdvancedLine && lineViewState$?.isInitialFilterDataLoaded && !noFloorPlan; else lineView"
    [configuration]="warningCardConfiguration"
  ></warning-card>
  <ng-template #lineView>
    <app-card
      *ngIf="lineViewState$?.isInitialFilterDataLoaded && noFloorPlan"
      [class.no-data-available-card]="!isFilterCardVisible"
    >
      <ng-container>
        <span>{{ 'datatable.noData' | translate }}</span>
      </ng-container>
    </app-card>
    <ng-container *ngIf="lineViewState$?.isInitialFilterDataLoaded && !noFloorPlan">
      <div>
        <mat-progress-bar
          [color]="'primary'"
          [hidden]="!homeMetricSetItemsLoading"
          class="progress-bar"
          mode="indeterminate"
        ></mat-progress-bar>
        <app-home-metrics
          [siteLineInfoSetter]="homeMetricSiteLineInfo"
          [activityInfoSetter]="lineViewData$?.activityInfo"
          [workOrderInfoSetter]="lineViewData$?.workOrderInfo"
          [plannedDownTimeActivityColorsStream]="plannedDownTimeActivityColors"
          [lineViewMode]="true"
        ></app-home-metrics>
      </div>
      <ng-container *ngIf="selectedViewType === eLinePlanViewTypeEnum.mapView">
        <div id="line-view-content" class="d-flex flex-grow-1 flex-shrink-1 h-100" *ngIf="!noMapFloorPlan">
          <app-site-and-line-view-floor-plan-map
            *ngIf="floorPlanConfiguration"
            class="w-100 h-100 flex-grow-1 flex-shrink-1 p-0 m-r-10"
            [mapData]="lineViewData$?.lineViewStationData"
            [floorPlanData]="floorPlanConfiguration"
            [plannedDownTimeActivityColorsStream]="plannedDownTimeActivityColors"
            [viewFor]="FloorPlanCategoryForIdEnum.FLOOR_PLAN_FOR_LINE"
          ></app-site-and-line-view-floor-plan-map>
          <app-card-carousel
            *ngIf="lineViewData$?.lineViewStationData.length && anyStationUsedInProduction"
            id="line-view-card-carousel"
            class="card-carousel h-100 flex-grow-0 flex-shrink-0"
            [cardCarouselConfiguration]="cardCarouselConfiguration"
          >
            <ng-container
              *ngFor="let stationDatum of lineViewData$.lineViewStationData; trackBy: stationCardTrackByFunction"
            >
              <app-line-view-info-card
                *ngIf="stationDatum && stationDatum.isUsedInProduction"
                [id]="'_' + stationDatum.id + '-card'"
                [stationCardInfoStream]="stationDatum"
                [plannedDownTimeActivityColorsStream]="plannedDownTimeActivityColors"
                [userLocale]="userLocale"
                [userDecimalSeparator]="userDecimalSeparator"
                [timezone]="lineViewData$.timezone"
                [isLaborTrackerActive]="lineViewData$.isLaborTrackerModuleActive"
                [isAssetTrackerActive]="lineViewData$.isAssetTrackerModuleActive"
              ></app-line-view-info-card>
            </ng-container>
          </app-card-carousel>
        </div>
        <app-card *ngIf="noMapFloorPlan">
          <ng-container>
            <span>{{ 'datatable.noData' | translate }}</span>
          </ng-container>
        </app-card>
      </ng-container>
      <div
        class="card-view-content"
        *ngIf="selectedViewType !== eLinePlanViewTypeEnum.mapView && lineViewData$?.lineViewStationData?.length"
      >
        <app-line-card-view
          [floorPlan]="floorPlanConfiguration"
          [stations]="lineViewData$?.lineViewStationData"
          [plannedDownTimeActivityColorsStream]="plannedDownTimeActivityColors"
        ></app-line-card-view>
      </div>
    </ng-container>
  </ng-template>
</div>
