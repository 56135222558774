import { Inject, Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import {
  BaseOneResponseInterface,
  GetManyResponseInterface,
} from '../../../shared/model/interface/crud-response-interface.model';

import { Observable } from 'rxjs';
import {
  AddCustomerInterface,
  BulkEditCustomerInterface,
  CustomerBulkResponse,
  CustomerDeleteResponseInterface,
} from './customers.model';
import { CustomerInterface } from '../../../view/settings/customer-settings/customers/customers.model';

@Injectable({
  providedIn: 'root',
})
export class CustomersService {
  private readonly CUSTOMERS = {
    CUSTOMER_URL: `${this.baseUrl}/customers`,
    BULK_EDIT_URL: `${this.baseUrl}/customers/bulk/edit`,
    BULK_DELETE_URL: `${this.baseUrl}/customers/bulk/delete`,
  };

  constructor(
    private readonly http: HttpClient,
    @Inject('API_BASE_URL')
    private readonly baseUrl: string,
  ) {}

  public getCustomers(params: HttpParams): Observable<GetManyResponseInterface<CustomerInterface>> {
    return this.http.get<GetManyResponseInterface<CustomerInterface>>(this.CUSTOMERS.CUSTOMER_URL, { params });
  }

  public addCustomer(customer: AddCustomerInterface): Observable<BaseOneResponseInterface<AddCustomerInterface>> {
    return this.http.post<BaseOneResponseInterface<AddCustomerInterface>>(this.CUSTOMERS.CUSTOMER_URL, customer);
  }

  public editCustomer(
    customer: AddCustomerInterface,
    id: number,
  ): Observable<BaseOneResponseInterface<AddCustomerInterface>> {
    return this.http.patch<BaseOneResponseInterface<AddCustomerInterface>>(
      `${this.CUSTOMERS.CUSTOMER_URL}/${id}`,
      customer,
    );
  }

  public bulkEditCustomer(
    customers: BulkEditCustomerInterface[],
  ): Observable<BaseOneResponseInterface<CustomerBulkResponse[]>> {
    return this.http.patch<BaseOneResponseInterface<CustomerBulkResponse[]>>(`${this.CUSTOMERS.BULK_EDIT_URL}`, {
      customers,
    });
  }

  public deleteCustomers(customer: number[] | number): Observable<CustomerDeleteResponseInterface> {
    if (Array.isArray(customer) && customer.length > 1) {
      const httpOptions = {
        headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
        body: {
          customers: customer,
        },
      };
      return this.http.delete<CustomerDeleteResponseInterface>(`${this.CUSTOMERS.BULK_DELETE_URL}`, httpOptions);
    }
    return this.http.delete<CustomerDeleteResponseInterface>(`${this.CUSTOMERS.CUSTOMER_URL}/${customer[0]}`);
  }
}
