<ng-template #arrowTemplatePreview>
  <svg class="px-3 w-100 h-100 position-absolute svg-container" style="left: 0; top: 0">
    <line class="arrow" id="color-changing-line" stroke-width="3" stroke="#3580f9" marker-end="url(#arrow)" />
    <marker markerWidth="6" markerHeight="6" refY="3" orient="auto" markerUnits="strokeWidth">
      <path d="M0,0 L6,3 L0,6 Z" fill="#3580f9" />
    </marker>
  </svg>
</ng-template>

<div class="modal-header scw-modal-header">
  <h3 class="modal-title scw-header">
    {{ modalTitle }}
  </h3>
  <button type="button" class="close" aria-label="Close" onfocus="blur()" (click)="closeModal()">
    <span aria-hidden="true">
      <em class="fas fa-times"></em>
    </span>
  </button>
</div>

<div class="line-view-type-group-buttons mt-4 mx-4 mb-2">
  <scw-mat-button-group
    size="xs"
    [stateless]="true"
    [buttons]="zoomButtonGroup"
    (inputModelChange)="
      viewType === ELinePlanViewTypeEnum.cardView ? previewZoomForCardView($event) : applyZoom($event)
    "
  >
  </scw-mat-button-group>

  <scw-mat-button-group
    *ngIf="canChangeModes"
    size="xs"
    [buttons]="floorPlanPreviewModalGroupButtons"
    [(inputModel)]="viewType"
    (inputModelChange)="this.onPreviewPlanViewTypeChange()"
  ></scw-mat-button-group>
</div>

<ng-template [ngIf]="viewType === ELinePlanViewTypeEnum.cardView" [ngIfElse]="mapView">
  <div #cardViewPreviewBox class="draggable-box preview-box-card-view">
    <div
      class="dragged-element side-list-item d-flex preview-item"
      *ngFor="let node of modifiedNodes"
      [class.selected-node-box-border]="node.isSelected"
      [attr.id]="node.id"
      [cdkDragFreeDragPosition]="node.position"
      [cdkDragDisabled]="true"
      cdkDrag
    >
      <em class="fas fa-sign-in-alt initial-line-icon" [class.d-inline-block]="node.isInitialCountFocused"></em>
      <em class="fas fa-sign-out-alt good-line-icon" [class.d-inline-block]="node.isGoodCountFocused"></em>
      <span class="d-inline-block w-100 my-auto">{{ node.name }}</span>
    </div>
  </div>
</ng-template>

<ng-template #mapView>
  <div class="mx-4 mb-4">
    <div class="loader-block" *ngIf="!isSelectedFloorPlanImageLoaded">
      <svg id="loader2" viewBox="0 0 100 100">
        <circle id="circle-loader2" cx="50" cy="50" r="45"></circle>
      </svg>
    </div>

    <div id="floorPlanPreviewCard" class="card mb-0" [hidden]="!isSelectedFloorPlanImageLoaded"></div>
  </div>
</ng-template>

<div class="modal-footer">
  <div class="buttons-right">
    <scw-mat-button type="standard" (onClick)="closeModal()">
      {{ 'general.close' | translate }}
    </scw-mat-button>
  </div>
</div>
