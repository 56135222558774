import { Action } from '@ngrx/store';
import { GenericCrudRequestConstructionParameters } from '../../../shared/model/interface/generic-api-request.model';
import {
  BaseOneResponseInterface,
  GetManyResponseInterface,
  IErrorMessageWitObjectId,
} from '../../../shared/model/interface/crud-response-interface.model';
import {
  IGetJobsForModalParams,
  IGetWorkOrdersForModalParams,
  IJob,
  IJobGetManyWithDetailsParams,
  IJobsDownloadExcelFilter,
} from './jobs.model';
import { WorkOrderInterface } from '../../work-order-schedule/work-order-schedule.model';

export const JOBS_LIST_DATA_LOADING = '[JOBS] JOBS DATA LOADING';
export const JOBS_LIST_DATA_LOADED = '[JOBS] JOBS DATA LOADED';
export const JOBS_FETCH_ERROR = '[JOBS] JOBS FETCH ERROR';
export const DOWNLOAD_JOB_EXCEL = '[JOBS] DOWNLOAD JOB EXCEL';
export const DOWNLOAD_JOB_EXCEL_COMPLETED = '[JOBS] DOWNLOAD JOB EXCEL COMPLETED';
export const ADD_JOB = '[JOBS] ADD JOB';
export const ADD_JOB_COMPLETED = '[JOBS] ADD JOB COMPLETED';
export const EDIT_JOB = '[JOBS] EDIT JOB';
export const EDIT_JOB_COMPLETED = '[JOBS] EDIT JOB COMPLETED';
export const DELETE_JOB = '[JOBS] DELETE JOB';
export const DELETE_JOB_COMPLETED = '[JOBS] DELETE JOB COMPLETED';
export const UPLOAD_JOB_EXCEL = '[JOBS] UPLOAD JOB EXCEL';
export const UPLOAD_JOB_EXCEL_COMPLETED = '[JOBS] UPLOAD JOB EXCEL COMPLETED';
export const GET_WORK_ORDERS_FOR_MODAL = '[JOBS] GET WORK ORDERS FOR MODAL';
export const GET_WORK_ORDERS_FOR_MODAL_COMPLETED = '[JOBS] GET WORK ORDERS FOR MODAL COMPLETED';
export const GET_JOBS_FOR_MODAL = '[JOBS] GET JOBS FOR MODAL';
export const GET_JOBS_FOR_MODAL_COMPLETED = '[JOBS] GET JOBS FOR MODAL COMPLETED';
export const JOBS_COUNT_LOAD = '[JOBS] JOBS COUNT LOAD';
export const JOBS_COUNT_LOADED = '[JOBS] JOBS COUNT LOADED';

export class JobsListDataLoading implements Action {
  readonly type = JOBS_LIST_DATA_LOADING;

  constructor(public params: IJobGetManyWithDetailsParams) {}
}

export class JobsListDataLoaded implements Action {
  readonly type = JOBS_LIST_DATA_LOADED;

  constructor(public payload: GetManyResponseInterface<IJob>) {}
}

export class FetchError implements Action {
  readonly type = JOBS_FETCH_ERROR;
  constructor(public payload: Record<string, any>) {}
}

export class DownloadJobExcel implements Action {
  readonly type = DOWNLOAD_JOB_EXCEL;

  constructor(
    public withData: boolean = false,
    public filters: IJobsDownloadExcelFilter,
    public tableQuery: GenericCrudRequestConstructionParameters,
  ) {}
}

export class DownloadJobExcelCompleted implements Action {
  readonly type = DOWNLOAD_JOB_EXCEL_COMPLETED;
}

export class AddJob implements Action {
  readonly type = ADD_JOB;

  constructor(
    public payload: {
      data: Omit<IJob, 'id'>;
    },
    public isAddForDeleteModal: boolean = false,
  ) {}
}
export class AddJobCompleted implements Action {
  readonly type = ADD_JOB_COMPLETED;

  constructor(public payload: BaseOneResponseInterface<IJob>, public isAddForDeleteModal: boolean = false) {}
}
export class EditJob implements Action {
  readonly type = EDIT_JOB;

  constructor(
    public payload: {
      data: Omit<IJob, 'id'>;
      id: number;
    },
  ) {}
}
export class EditJobCompleted implements Action {
  readonly type = EDIT_JOB_COMPLETED;

  constructor(public payload: BaseOneResponseInterface<IJob>) {}
}
export class DeleteJob implements Action {
  readonly type = DELETE_JOB;

  constructor(public jobId: number, public targetJobId?: number) {}
}
export class DeleteJobCompleted implements Action {
  readonly type = DELETE_JOB_COMPLETED;

  constructor(public payload: IErrorMessageWitObjectId[]) {}
}

export class UploadJobExcel implements Action {
  readonly type = UPLOAD_JOB_EXCEL;

  constructor(
    public payload: {
      jobs: IJob[];
    },
  ) {}
}

export class UploadJobExcelCompleted implements Action {
  readonly type = UPLOAD_JOB_EXCEL_COMPLETED;

  constructor(public payload: (IJob & { errorMessages?: string })[]) {}
}

export class GetWorkOrdersForModal implements Action {
  readonly type = GET_WORK_ORDERS_FOR_MODAL;

  constructor(public params: IGetWorkOrdersForModalParams) {}
}

export class GetWorkOrdersForModalCompleted implements Action {
  readonly type = GET_WORK_ORDERS_FOR_MODAL_COMPLETED;

  constructor(public response: GetManyResponseInterface<WorkOrderInterface>) {}
}

export class GetJobsForModal implements Action {
  readonly type = GET_JOBS_FOR_MODAL;

  constructor(public params: IGetJobsForModalParams) {}
}

export class GetJobsForModalCompleted implements Action {
  readonly type = GET_JOBS_FOR_MODAL_COMPLETED;

  constructor(public response: GetManyResponseInterface<IJob>) {}
}

export class JobsCountLoad implements Action {
  readonly type = JOBS_COUNT_LOAD;

  constructor(public siteId: number, public limit?: number) {}
}

export class JobsCountLoaded implements Action {
  readonly type = JOBS_COUNT_LOADED;

  constructor(public payload: IJob[]) {}
}

export type JobsActions =
  | JobsListDataLoading
  | JobsListDataLoaded
  | DownloadJobExcel
  | DownloadJobExcelCompleted
  | AddJob
  | AddJobCompleted
  | EditJob
  | EditJobCompleted
  | DeleteJob
  | DeleteJobCompleted
  | UploadJobExcel
  | UploadJobExcelCompleted
  | GetWorkOrdersForModal
  | GetWorkOrdersForModalCompleted
  | GetJobsForModal
  | GetJobsForModalCompleted
  | JobsCountLoad
  | JobsCountLoaded
  | FetchError;
