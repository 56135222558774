import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  TemplateRef,
  ViewChild,
  ViewChildDecorator,
} from '@angular/core';
import { ScwModalSize } from '../../scw-mat-ui/scw-mat-modal/scw-mat-modal.model';
import { NgbModal, NgbModalRef, NgbSlideEventSource } from '@ng-bootstrap/ng-bootstrap';
import { ActionsSubject, Store } from '@ngrx/store';
import { OeeAppState } from '../../../../store/oee.reducer';
import * as FileUploadActions from '../../../../store/file-upload/file-upload.actions';
import { Subscription } from 'rxjs';
import { ofType } from '@ngrx/effects';
import { smallModalScrollable } from '../../../../../constants';
import { OnDestroyDecorator } from '../../../decorator/on-destroy-decorator';
import { EFileType, IDeleteFileParams, IFile, IGetFileResponse } from '../../../../store/file-upload/file-upload.model';
import * as _ from 'lodash';
import { EFileUploadSource } from '../file-upload.model';
import { DecimalHelper } from '../../../helper/decimal/decimal-helper';
import { FileHelperService } from '../../../helper/file-helper.service';

@OnDestroyDecorator
@Component({
  selector: 'scw-image-preview-modal',
  templateUrl: './image-preview-modal.component.html',
  styleUrls: ['./image-preview-modal.component.scss'],
})
export class ImagePreviewModalComponent implements OnInit, OnDestroy {
  @ViewChild('image_preview_modal') imagePreviewModalTemplateRef: ViewChildDecorator;

  @Input() siteId: number;
  @Input() isOpenedFromCommentLogs: boolean = false;

  @Output() deletedFile: EventEmitter<IFile> = new EventEmitter<IFile>();
  @Output() deleteAllButtonClicked: EventEmitter<boolean> = new EventEmitter<boolean>();

  public readonly EFileType = EFileType;
  private readonly storeSubscriptions: Subscription[] = [];

  public cameraModalRef: NgbModalRef;
  public isPreviewModalOpenClicked: boolean = false;
  public deleteConfirmationModalRef: NgbModalRef;
  public sortedFiles: IFile[] = [];
  public pdfFiles: IFile[] = [];
  public newFiles: IFile[] = [];
  public fileNumber: number;
  private activeSlideIndex: number = 0;
  private folderId: number;
  private fileToBeDeleted: IFile;
  private isDownloadPdfProcessStarted: boolean = false;

  constructor(
    private readonly ngbModal: NgbModal,
    private readonly store: Store<OeeAppState>,
    private readonly actions: ActionsSubject,
    private readonly decimalHelper: DecimalHelper,
  ) {}

  public openImagePreviewModal(folderId?: number, files?: IFile[], fileNumber?: number): void {
    this.folderId = folderId ? folderId : files.find((file: IFile) => !_.isNil(file.folderId))?.folderId ?? undefined;

    this.isPreviewModalOpenClicked = true;
    this.fileNumber = fileNumber;
    this.newFiles = files?.length ? _.cloneDeep(files).filter((file: IFile) => _.isNil(file.folderId)) : [];

    if (this.folderId) {
      this.store.dispatch(
        new FileUploadActions.GetFilesByFolderIdLoading({
          folderId: this.folderId,
          isThumbnail: 0,
          entityName: EFileUploadSource.COMMENTS,
          siteId: this.siteId,
        }),
      );
      return;
    }

    this.sortedFiles = _.cloneDeep(this.newFiles);

    const cutElements: IFile[] = this.sortedFiles.splice(0, fileNumber);
    this.sortedFiles.push(...cutElements);
    this.sortedFiles = this.sortedFiles.filter((item: IFile) => item.type !== EFileType.PDF);

    this.cameraModalRef = this.ngbModal.open(this.imagePreviewModalTemplateRef, {
      keyboard: false,
      backdrop: 'static',
      windowClass: ScwModalSize.medium,
    });
    this.newFiles = [];
  }

  public showDeleteItemModal(content: TemplateRef<any>, file?: IFile): void {
    this.fileToBeDeleted = file;
    this.deleteConfirmationModalRef = this.ngbModal.open(content, smallModalScrollable);
  }

  public onDelete(file?: IFile): void {
    if (_.isNil(this.fileToBeDeleted)) {
      this.fileToBeDeleted = file ? file : this.sortedFiles[this.activeSlideIndex];
    }

    if (this.fileToBeDeleted?.filePath) {
      this.store.dispatch(
        new FileUploadActions.DeleteFileLoading({
          fileName: this.fileToBeDeleted.filePath,
          entityName: EFileUploadSource.COMMENTS,
          siteId: this.siteId,
        }),
      );
    }

    this.deletedFile.emit(this.fileToBeDeleted);

    if (this.isOpenedFromCommentLogs) {
      if (this.fileToBeDeleted.type === EFileType.PDF) {
        _.remove(this.pdfFiles, (pdf: IFile): boolean => pdf.filePath === this.fileToBeDeleted.filePath);
      } else {
        _.remove(this.sortedFiles, (image: IFile): boolean => image.filePath === this.fileToBeDeleted.filePath);
      }
    }

    this.activeSlideIndex = 0;
    this.deleteConfirmationModalRef?.close();
    this.fileToBeDeleted = null;

    if (!this.isOpenedFromCommentLogs || (!this.pdfFiles.length && !this.sortedFiles.length)) {
      this.cameraModalRef?.close();
    }
  }

  public onBulkDelete(): void {
    const imagesToBeDeleted: IDeleteFileParams[] = this.sortedFiles.map((file: IFile) => {
      return {
        fileName: file.filePath,
        entityName: EFileUploadSource.COMMENTS,
        siteId: this.siteId,
      };
    });

    const documentsToBeDeleted: IDeleteFileParams[] = this.pdfFiles.map((file: IFile) => {
      return {
        fileName: file.filePath,
        entityName: EFileUploadSource.COMMENTS,
        siteId: this.siteId,
      };
    });

    const filesToBeDeleted: IDeleteFileParams[] = [...documentsToBeDeleted, ...imagesToBeDeleted];

    if (filesToBeDeleted?.length) {
      this.store.dispatch(new FileUploadActions.DeleteManyFileLoading(filesToBeDeleted));
    }

    this.deleteAllButtonClicked.emit(true);
    this.deleteConfirmationModalRef?.close();
    this.cameraModalRef?.close();
  }

  public ngOnInit(): void {
    this.storeSubscriptions.push(
      this.actions
        .pipe(ofType(FileUploadActions.GET_FILES_BY_FOLDER_ID_LOADED))
        .subscribe((data: FileUploadActions.GetFilesByFolderIdLoaded) => {
          if (this.isPreviewModalOpenClicked && this.folderId === _.get(data.response, '0.folderId', null)) {
            this.sortedFiles = data.response.map((item: IGetFileResponse) => {
              return {
                ...item,
                original: !_.isNil(item.base64Data) ? `data:image/jpeg;base64,${item.base64Data}` : null,
                type: item.filePath.includes(EFileType.PDF) ? EFileType.PDF : EFileType.IMAGE,
              };
            });

            if (this.newFiles.length) {
              this.sortedFiles = _.concat(this.sortedFiles, this.newFiles);
            }

            const cutElements: IFile[] = this.sortedFiles.splice(0, this.fileNumber);

            this.sortedFiles.push(...cutElements);
            this.pdfFiles = this.sortedFiles
              .filter((item: IFile) => item.type === EFileType.PDF)
              .map((pdf: IFile) => {
                const itemSizeAsMb: number = pdf.size / (1024 * 1024);
                pdf.size = this.decimalHelper.decimalToNumberFormatter(
                  this.decimalHelper.toFixedValue(
                    itemSizeAsMb.toString(),
                    this.decimalHelper.isLessThan(itemSizeAsMb.toString(), '0.01') ? 3 : 2,
                  ),
                );
                return pdf;
              });

            this.sortedFiles = this.sortedFiles.filter(
              (item: IFile) => item.type !== EFileType.PDF && item.original !== null,
            );

            this.cameraModalRef = this.ngbModal.open(this.imagePreviewModalTemplateRef, {
              keyboard: false,
              backdrop: 'static',
              windowClass: ScwModalSize.medium,
            });
          }
        }),
      this.actions
        .pipe(ofType(FileUploadActions.GET_FILE_LOADED))
        .subscribe((payload: FileUploadActions.GetFileLoaded) => {
          if (this.isDownloadPdfProcessStarted) {
            const blobURL: string = FileHelperService.byteStringToBlobUrl(
              payload.response.base64Data,
              'application/pdf',
            );
            window.open(blobURL, '_blank');
          }

          this.isDownloadPdfProcessStarted = false;
        }),
    );
  }

  public onCloseModal(): void {
    this.isPreviewModalOpenClicked = false;
    this.fileToBeDeleted = null;
    this.cameraModalRef?.close();
  }

  public ngOnDestroy(): void {
    this.fileToBeDeleted = null;
    this.storeSubscriptions.forEach((subscription: Subscription) => {
      subscription?.unsubscribe();
    });
  }

  public slideChange(event): void {
    if (event.source === NgbSlideEventSource.ARROW_LEFT) {
      this.activeSlideIndex = this.activeSlideIndex - 1;
    }

    if (event.source === NgbSlideEventSource.ARROW_RIGHT) {
      this.activeSlideIndex = this.activeSlideIndex + 1;
    }
  }

  public onCloseDeleteModal(): void {
    this.fileToBeDeleted = null;
    this.deleteConfirmationModalRef?.close();
  }

  public downloadPdf(file: IFile): void {
    if (file.id) {
      this.isDownloadPdfProcessStarted = true;
      this.store.dispatch(
        new FileUploadActions.GetFileLoading({
          folderId: this.folderId,
          isThumbnail: 0,
          entityName: EFileUploadSource.COMMENTS,
          siteId: this.siteId,
          fileId: file.id,
        }),
      );
      return;
    }
  }
}
