import { Component, ElementRef, Input, OnChanges, OnDestroy, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { HelperService } from '../../service/helper.service';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { PhaseFieldInterface } from './work-order-phase-comment-modal/work-order-phase-comment-modal.model';
import * as HomeActions from '../../../store/home/home.actions';
import { take } from 'rxjs/operators';
import { Line } from '../../../store/line/model';
import { Store } from '@ngrx/store';
import { OeeAppState } from '../../../store/oee.reducer';
import {
  HomeStateInterface,
  PhaseCommentInterface,
  PhaseCommentsEnteredStatusInterface,
} from '../../../store/home/home.model';
import { Subscription } from 'rxjs';
import * as AppActions from '../../../store/app/actions';
import { PhaseOptionNames } from '../../model/enum/phase-options';
import { PhaseDurationsInterface } from '../../../view/home/home-metrics/common-phase-duration-metric/common-phase-duration-metric.model';
import * as _ from 'lodash';
import { DecimalHelper } from '../../helper/decimal/decimal-helper';
import { OnDestroyDecorator } from '../../decorator/on-destroy-decorator';

@OnDestroyDecorator
@Component({
  selector: 'progress-bar-metric',
  templateUrl: './progress-bar-metric.component.html',
  styleUrls: ['./progress-bar-metric.component.scss'],
})
export class ProgressBarMetricComponent implements OnInit, OnDestroy, OnChanges {
  @Input() actualValue: string | number = null;
  @Input() actualValueUnit: string | number = '';
  @Input() expectedValueUnit: string = '';
  @Input() expectedValue: string | number = null;
  @Input() progressBarTitle: string = '--';
  @Input() progressBarRateCapped: number = null;
  @Input() progressBarRate: string = null;
  @Input() showProgressBar: boolean = true;
  @Input() actualValueClass: string = '';
  @Input() actualValueColor: string = null;
  @Input() progressBarClass: string = 'bg-c-grey';
  @Input() showExpectedValue: boolean = true;
  @Input() isPhaseMetric: boolean = false;
  @Input() phaseText: string | null = null;
  @Input() durationType: string;
  @Input() dashboardMode: boolean = false;
  @ViewChild('work_order_phase_comment', { static: false }) workOrderPhaseCommentModalContent: ElementRef;
  @Input() icons: string[] = null;
  @Input() showMetricInfo: boolean = false;
  @Input() actualValueInfoText: string = '';
  @Input() expectedValueInfoText: string = '';
  @Input() titleBracketInfo: string = '';

  public phaseField: PhaseFieldInterface;
  public phaseComment$: PhaseCommentInterface | null = null;
  public isPhaseCommentEntered$: boolean = null;
  private phaseCommentsEnteredStatus$: PhaseCommentsEnteredStatusInterface = null;
  private phaseDurations$: PhaseDurationsInterface = null;
  private workOrderPhaseCommentModalRef: NgbModalRef;
  private subscription: Subscription;
  private openModal: boolean = false;
  private isImageDelete: boolean = false;

  constructor(
    public helperService: HelperService,
    private ngbModal: NgbModal,
    private store: Store<OeeAppState>,
    private readonly decimalHelper: DecimalHelper,
  ) {}

  public ngOnChanges(changes: SimpleChanges): void {
    if (
      'progressBarRate' in changes &&
      !_.isEqual(changes['progressBarRate'].currentValue, changes['progressBarRate'].previousValue)
    ) {
      this.progressBarRate = this.progressBarRate?.toString()
        ? this.decimalHelper.removeThousandSeparator(this.progressBarRate.toString())
        : null;
    }
  }

  public ngOnInit(): void {
    this.subscription = this.store.select('homeStore').subscribe((state: HomeStateInterface) => {
      this.phaseComment$ = null;
      this.phaseCommentsEnteredStatus$ = state.phaseCommentsEnteredStatus;
      this.phaseDurations$ = state.phaseDurations;
      this.isPhaseCommentEntered$ = null;

      if (this.phaseCommentsEnteredStatus$ !== null) {
        switch (this.durationType) {
          case 'preRun':
            this.isPhaseCommentEntered$ = this.phaseCommentsEnteredStatus$.isPreRunCommentEntered;
            break;
          case 'run':
            this.isPhaseCommentEntered$ = this.phaseCommentsEnteredStatus$.isRunCommentEntered;
            break;
          case 'postRun':
            this.isPhaseCommentEntered$ = this.phaseCommentsEnteredStatus$.isPostRunCommentEntered;
            break;
          default:
            this.isPhaseCommentEntered$ = null;
            break;
        }
      }

      if (state.phaseComments[this.durationType] !== undefined) {
        this.phaseComment$ = state.phaseComments[this.durationType];
      }

      if (this.openModal && state.phaseCommentsLoaded && !state.phaseCommentsLoading) {
        this.openModal = false;
        this.store.dispatch(new AppActions.HideTopLoader());
        this.workOrderPhaseCommentModalRef = this.ngbModal.open(
          this.workOrderPhaseCommentModalContent,
          {
            keyboard: false,
            backdrop: 'static',
            windowClass: 'scw-modal-sm',
          },
        );
      }

      if (state.phaseCommentSubmitLoading && !state.phaseCommentSubmitLoaded && this.workOrderPhaseCommentModalRef && !this.isImageDelete) {
        this.workOrderPhaseCommentModalRef.dismiss();
      }
      this.isImageDelete = false;
    });
  }

  public getFormattedValue(value: number | string, unit: string = ''): string {
    return value === null ? `-- ${unit}` : `${value} ${unit}`;
  }

  public metricIsClicked(): void {
    if (!this.isPhaseMetric || this.dashboardMode) {
      return;
    }

    this.openModal = true;

    this.store
      .select('line')
      .pipe(take(1))
      .subscribe((state: Line) => {
        if (state.wOTableId) {
          this.store.dispatch(new AppActions.ShowTopLoader());
          this.store.dispatch(new HomeActions.PhaseCommentsLoading(state.wOTableId));
        }
      });

    this.phaseField = {
      phaseText: this.phaseText,
      actual: this.actualValue !== null ? this.actualValue.toString() : null,
      target: this.expectedValue !== null ? this.expectedValue.toString() : null,
    };

    this.store.dispatch(
      new HomeActions.PhaseDurationsLoaded(this.phaseDurations$, PhaseOptionNames[this.durationType]),
    );
  }

  public isSubmitFromDelete(event: boolean): void {
    this.isImageDelete = event;
  }

  public ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }
}
