import { ECellTypes, EExcelSheetType } from './excel.enum';
import { DataValidation, Style, ValueType, Workbook, Worksheet, Column } from 'exceljs';

export const EXCEL_DEFAULT_DATE_FORMAT = 'DD-MM-YYYY';
export const EXCEL_DEFAULT_TIME_FORMAT = 'HH:MM';
export const CELL_ADDRESS_CONSTANT = '$_CELLADDRESS_$';
export const twoDigitNumberFormat = '0.00############################';
export const EXCEL_FONT_FAMILY = { arial: { name: 'Arial Black', value: 2 } };
export const EXCEL_UPLOAD_EVENT_TELEMETRY = { name: 'OEE Excel Upload' };

export interface IExcelDropdownSettings {
  data: any[];
  prop: string;
  dataProperty: string;
  dataId: string;
  sheetName?: string;
  primaryKeyColumnWidth?: number;
}

export interface IExcelColumnDefinition extends Partial<Column> {
  header: string;
  key: string;
  width: number;
  type: ValueType;
  dataValidation: Partial<DataValidation> & Pick<DataValidation, 'type'>;
  dropdownOptions?: IExcelDropdownSettings;
  hidden?: boolean;
  style?: Partial<Style>;
  maxLength?: number;
  allowPunctuation?: boolean;
  number?: number;
  isAdditional?: boolean;
  specificDateFormat?: EExcelDateFormatOption;
  removePropertyIfNull?: boolean;
  isRequired?: boolean;
  isDecimalNumber?: boolean;
  isReadOnly?: boolean;
}

export interface ICreateExcel {
  data?: unknown[];
  columns: IExcelColumnDefinition[];
}

export interface ICreateExcelSheet {
  sheetTitle: string;
  sheetType?: EExcelSheetType;
  params?: ICreateExcel;
  messages?: IExcelReadmeSheet;
  withData?: boolean;
  isDisabledColumnsFirstLine?: boolean;
  addDateTimeFormula?: boolean;
  excelRowFormatLimit?: number;
  isProtected?: boolean;
}

export interface IExcelReadmeSheet {
  cells: IExcelCellConfiguration[];
  defaultCellStyle?: Partial<Style>;
  defaultColumnStyle?: Partial<IExcelColumnDefinition>;
}

export interface IExcelCellConfiguration {
  column: string;
  row: number;
  value: any;
  style?: Partial<Style>;
  columnStyle?: Partial<Style>;
}

export interface IExcelColumnKeys {
  [header: string]: {
    key: string;
    type: ValueType;
    dataValidationType: ECellTypes;
    isDateTimeFormat?: boolean;
    removePropertyIfNull?: boolean;
    isDecimalNumber?: boolean;
  };
}

export interface IDownloadExcelFilters {
  siteId: number;
  selectedDownloadOffset: string | null;
  limit: number;
}

export interface IDataWithExcelId {
  excelId: string;
  excelLabel: string;
}

export interface IDependentColumnConfiguration<T = object> {
  data: T[];
  key: keyof T;
  label: keyof T;
  relatesToField?: Extract<keyof T, string>;
}

export interface IEnumeratedDropdownOption {
  id: string;
  name: string;
}

export interface IExcelDateFormatInformation {
  timeFormat$: string;
  dateFormat$: string;
  timezone: string;
  locale$?: string;
  dateFormatRaw$?: string;
  dateTimeFormatRaw$?: string;
}

export interface IExcelColumnInfoConfiguration {
  dateTimeFields?: string[];
  dateFields?: string[];
  integerFields?: string[];
  decimalFields?: string[];
}

export enum EExcelDateFormatOption {
  Date = 0,
  Datetime = 1,
}

export interface IOEEExportLog {
  name: string;
  siteId?: number;
  withData?: boolean;
  type?: string;
  asExcel?: boolean;
  asCsv?: boolean;
  asPdf?: boolean;
}

export interface IOEEExcelUploadRow {
  id?: number;
  siteId?: number;
}
