import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, switchMap } from 'rxjs/operators';
import { from, of } from 'rxjs';
import * as ObjectActions from './customer-segment.actions';
import { generateHttpOption } from '../../../shared/helper/app-helper';
import { CustomerSegmentsService } from '../../../shared/service/settings/customer-settings/customer-segments.service';

@Injectable()
export class FilterCustomerSegmentEffects {
  loadFilterCustomerSegments$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ObjectActions.FilterCustomerSegmentActionTypes.StartLoadFilterCustomerSegments),
      switchMap((objectData: ObjectActions.StartLoadFilterCustomerSegments) => {
        const params = generateHttpOption(objectData.options);
        return from(this.customerSegmentsService.getCustomerSegments(params)).pipe(
          switchMap((response) => {
            const { data } = response;

            return of(new ObjectActions.FilterCustomerSegmentsLoaded(data));
          }),
          catchError((e) => {
            return of(new ObjectActions.FilterCustomerSegmentsLoaded([]));
          }),
        );
      }),
      catchError(() => {
        return of(new ObjectActions.FilterCustomerSegmentsLoaded([]));
      }),
    ),
  );

  constructor(
    private actions$: Actions<ObjectActions.FilterCustomerSegmentActions>,
    public customerSegmentsService: CustomerSegmentsService,
  ) {}
}
