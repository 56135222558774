import { Action } from '@ngrx/store';
import { IProductVersion, IProductVersionRequestPayload } from './product-versions.model';
import {
  BulkResponseDataInterface,
  GetManyResponseInterface,
} from '../../../../../shared/model/interface/crud-response-interface.model';
import { GenericCrudRequestConstructionParameters } from '../../../../../shared/model/interface/generic-api-request.model';

export enum EProductVersionsAction {
  GET_VERSIONS_DATA = '[VERSIONS] GET VERSIONS DATA',
  GET_VERSIONS_DATA_COMPLETED = '[VERSIONS] GET VERSIONS DATA COMPLETED',
  GET_DEFAULT_VERSION_DATA = '[VERSIONS] GET DEFAULT VERSION DATA',
  GET_DEFAULT_VERSION_DATA_COMPLETED = '[VERSIONS] GET DEFAULT VERSION DATA COMPLETED',
  ADD_VERSION = '[VERSIONS] ADD VERSION',
  ADD_VERSION_COMPLETED = '[VERSIONS] ADD VERSION COMPLETED',
  EDIT_VERSION = '[VERSIONS] EDIT VERSION',
  EDIT_VERSION_COMPLETED = '[VERSIONS] EDIT VERSION COMPLETED',
  DELETE_VERSION = '[VERSIONS] DELETE VERSION',
  DELETE_VERSION_COMPLETED = '[VERSIONS] DELETE VERSION COMPLETED',
  DELETE_VERSIONS = '[VERSIONS] DELETE VERSIONS',
  DELETE_VERSIONS_COMPLETED = '[VERSIONS] DELETE VERSIONS COMPLETED',
  FETCH_ERROR = '[VERSIONS] FETCH ERROR',
}

export class GetVersionsData implements Action {
  readonly type: EProductVersionsAction.GET_VERSIONS_DATA = EProductVersionsAction.GET_VERSIONS_DATA;

  constructor(public params: GenericCrudRequestConstructionParameters) {}
}

export class GetVersionsDataCompleted implements Action {
  readonly type: EProductVersionsAction.GET_VERSIONS_DATA_COMPLETED =
    EProductVersionsAction.GET_VERSIONS_DATA_COMPLETED;

  constructor(public payload: GetManyResponseInterface<IProductVersion>) {}
}

export class GetDefaultVersionData implements Action {
  readonly type: EProductVersionsAction.GET_DEFAULT_VERSION_DATA = EProductVersionsAction.GET_DEFAULT_VERSION_DATA;

  constructor(public params: GenericCrudRequestConstructionParameters) {}
}

export class GetDefaultVersionDataCompleted implements Action {
  readonly type: EProductVersionsAction.GET_DEFAULT_VERSION_DATA_COMPLETED =
    EProductVersionsAction.GET_DEFAULT_VERSION_DATA_COMPLETED;

  constructor(public payload: GetManyResponseInterface<IProductVersion>) {}
}

export class AddVersion implements Action {
  readonly type: EProductVersionsAction.ADD_VERSION = EProductVersionsAction.ADD_VERSION;

  constructor(public body: IProductVersionRequestPayload) {}
}

export class AddVersionCompleted implements Action {
  readonly type: EProductVersionsAction.ADD_VERSION_COMPLETED = EProductVersionsAction.ADD_VERSION_COMPLETED;

  constructor(public payload: IProductVersion, public success: boolean) {}
}

export class EditVersion implements Action {
  readonly type: EProductVersionsAction.EDIT_VERSION = EProductVersionsAction.EDIT_VERSION;

  constructor(public productVersionId: number, public payload: IProductVersionRequestPayload) {}
}

export class EditVersionCompleted implements Action {
  readonly type: EProductVersionsAction.EDIT_VERSION_COMPLETED = EProductVersionsAction.EDIT_VERSION_COMPLETED;

  constructor(public success: boolean) {}
}

export class DeleteVersion implements Action {
  readonly type: EProductVersionsAction.DELETE_VERSION = EProductVersionsAction.DELETE_VERSION;

  constructor(public versionsId: number) {}
}

export class DeleteVersionCompleted implements Action {
  readonly type: EProductVersionsAction.DELETE_VERSION_COMPLETED = EProductVersionsAction.DELETE_VERSION_COMPLETED;

  constructor(public success: boolean) {}
}

export class DeleteVersions implements Action {
  readonly type: EProductVersionsAction.DELETE_VERSIONS = EProductVersionsAction.DELETE_VERSIONS;

  constructor(public versionsIdList: number[]) {}
}

export class DeleteVersionsCompleted implements Action {
  readonly type: EProductVersionsAction.DELETE_VERSIONS_COMPLETED = EProductVersionsAction.DELETE_VERSIONS_COMPLETED;

  constructor(public payload: BulkResponseDataInterface) {}
}

export class FetchError implements Action {
  readonly type: EProductVersionsAction.FETCH_ERROR = EProductVersionsAction.FETCH_ERROR;

  constructor(public payload: object) {}
}

export type ProductVersionsActions =
  | AddVersion
  | AddVersionCompleted
  | EditVersion
  | EditVersionCompleted
  | DeleteVersion
  | DeleteVersionCompleted
  | DeleteVersions
  | DeleteVersionsCompleted
  | GetVersionsData
  | GetVersionsDataCompleted
  | GetDefaultVersionData
  | GetDefaultVersionDataCompleted
  | FetchError;
