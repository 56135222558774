import * as Actions from './product-family.actions';
import { IProductFamilyState } from './product-family.model';

export const productFamilyState: IProductFamilyState = {
  productFamilyDataLoading: false,
  productFamilyDataLoaded: false,
  productFamilyData: null,
  addProductFamilyLoading: false,
  addProductFamilyLoaded: false,
  editProductFamilyLoading: false,
  editProductFamilyLoaded: false,
  productFamilyDeleteLoading: false,
  productFamilyDeleteLoaded: false,
  productFamilyBulkDeleteLoading: false,
  productFamilyBulkDeleteLoaded: false,
  productFamilyExcelDownloading: false,
  productFamilyExcelDownloaded: false,
  productFamilyExcelUploading: false,
  productFamilyExcelUploaded: false,
  productFamilyCountLoaded: false,
  productFamilyCountLoading: false,
  productFamilyCountForExcel: null,
  excelUploadResult: [],
};

export function productFamilyReducer(
  state: IProductFamilyState = productFamilyState,
  action: Actions.ProductFamilyActions,
): IProductFamilyState {
  switch (action.type) {
    case Actions.PRODUCT_FAMILY_DATA_LOADING:
      return {
        ...state,
        ...{
          productFamilyDataLoading: true,
          productFamilyDataLoaded: false,
        },
      };
    case Actions.PRODUCT_FAMILY_DATA_LOADED:
      return {
        ...state,
        ...{
          productFamilyData: action.payload,
          productFamilyDataLoading: false,
          productFamilyDataLoaded: true,
        },
      };
    case Actions.ADD_PRODUCT_FAMILY:
      return {
        ...state,
        addProductFamilyLoading: true,
        addProductFamilyLoaded: false,
      };
    case Actions.ADD_PRODUCT_FAMILY_COMPLETED:
      return {
        ...state,
        addProductFamilyLoading: false,
        addProductFamilyLoaded: true,
      };
    case Actions.EDIT_PRODUCT_FAMILY:
      return {
        ...state,
        editProductFamilyLoading: true,
        editProductFamilyLoaded: false,
      };
    case Actions.EDIT_PRODUCT_FAMILY_COMPLETED:
      return {
        ...state,
        editProductFamilyLoading: false,
        editProductFamilyLoaded: true,
      };
    case Actions.DELETE_PRODUCT_FAMILY:
      return {
        ...state,
        productFamilyDeleteLoading: true,
        productFamilyDeleteLoaded: false,
      };
    case Actions.DELETE_PRODUCT_FAMILY_COMPLETED:
      return {
        ...state,
        productFamilyDeleteLoading: false,
        productFamilyDeleteLoaded: true,
      };
    case Actions.BULK_DELETE_PRODUCT_FAMILY:
      return {
        ...state,
        productFamilyBulkDeleteLoading: true,
        productFamilyBulkDeleteLoaded: false,
      };
    case Actions.BULK_DELETE_PRODUCT_FAMILIES_COMPLETED:
      return {
        ...state,
        productFamilyBulkDeleteLoading: false,
        productFamilyBulkDeleteLoaded: true,
      };
    case Actions.DOWNLOAD_PRODUCT_FAMILY_EXCEL:
      return {
        ...state,
        ...{
          productFamilyExcelDownloading: true,
          productFamilyExcelDownloaded: false,
        },
      };
    case Actions.DOWNLOAD_PRODUCT_FAMILY_EXCEL_COMPLETED:
      return {
        ...state,
        ...{
          productFamilyExcelDownloading: false,
          productFamilyExcelDownloaded: true,
        },
      };
    case Actions.UPLOAD_PRODUCT_FAMILY_EXCEL:
      return {
        ...state,
        ...{
          productFamilyExcelUploading: true,
          productFamilyExcelUploaded: false,
        },
      };
    case Actions.UPLOAD_PRODUCT_FAMILY_EXCEL_COMPLETED:
      return {
        ...state,
        ...{
          excelUploadResult: action.payload,
          productFamilyExcelUploading: false,
          productFamilyExcelUploaded: true,
        },
      };
    case Actions.PRODUCT_FAMILY_COUNT_LOAD:
      return {
        ...state,
        ...{
          productFamilyCountLoading: true,
          productFamilyCountLoaded: false,
        },
      };
    case Actions.PRODUCT_FAMILY_COUNT_LOADED:
      return {
        ...state,
        ...{
          productFamilyCountForExcel: action.payload.length,
          productFamilyCountLoading: false,
          productFamilyCountLoaded: true,
        },
      };
    default:
      return state;
  }
}
