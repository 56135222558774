import { Injectable } from '@angular/core';
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { EMPTY, catchError, map, of, switchMap } from 'rxjs';
import { ELoadStatus } from 'src/constants.model';
import * as CommentTypesActions from './comment-types.actions';
import { selectCommentTypesLoadStatus } from './comment-types.selectors';
import { CommentTypesService } from './comment-types.service';
import { HttpParams } from '@angular/common/http';
import { ICommentType } from './comment-types.model';

@Injectable()
export class CommentTypesEffects {
  loadCommentTypes$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CommentTypesActions.loadIfNotLoaded),
      concatLatestFrom(() => this.store.select(selectCommentTypesLoadStatus)),
      switchMap(([, status]) => {
        if (status === ELoadStatus.Success) {
          return EMPTY;
        }

        return this.commentTypesService
          .getAll<ICommentType>(new HttpParams({ fromObject: { fields: 'id,category' } }))
          .pipe(
            map((response) => CommentTypesActions.loaded({ commentTypes: response.data })),
            catchError(() => of(CommentTypesActions.errored())),
          );
      }),
      catchError(() => of(CommentTypesActions.errored())),
    ),
  );

  constructor(
    private readonly actions$: Actions,
    private readonly commentTypesService: CommentTypesService,
    private readonly store: Store,
  ) {}
}
