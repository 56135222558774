<datatable
  noDataText="{{ 'datatable.noData' | translate }}"
  [clientSide]="true"
  [headers]="laborAssetViewTableHeaders"
  [items]="laborAssetViewTableData"
  [itemsCount]="laborAssetViewTableData.length"
  [customSorts]="customSorts"
  [currentPage]="tableQuery.page"
  [rowsPerPage]="tableQuery.pageSize"
  [isLoading]="isTableDataLoading$"
  (onClickPagination)="onDataRequestHandler($event)"
  (onDataRequest)="onDataRequestHandler($event)"
  (onClickSort)="onClickSortHandler()"
>
  <ng-template let-item let-i="index">
    <td
      *ngFor="let header of laborAssetViewTableHeaders; index as i"
      [class.sticky-left-col]="i === 0"
      [class.is-status]="header.value === 'statusName'"
    >
      <scw-mat-checkbox
        *ngIf="i === 0 && isLaborAssetViewTableCheckboxVisible; else defaultTemplate"
        [id]="checkboxIdPrefix + item.id"
        [value]="item.id"
        [disabled]="item.isOffline || item.disabled"
        [(inputModel)]="item.selected"
        (onChange)="onChangeTableCheckboxes($event, item)"
      >
      </scw-mat-checkbox>
      <ng-template #defaultTemplate>
        <ng-container *ngIf="header.value === 'statusName'; else else2">
          <div class="border-container">
            <div
              class="border-color"
              [style.background-color]="getStatusColor(item.type, item.status, item.tagColor)"
            ></div>
            {{ item[header.value] | translate }}
          </div>
        </ng-container>
        <ng-template #else2>
          {{ item[header.value] }}
        </ng-template>
      </ng-template>
    </td>
  </ng-template>
</datatable>
