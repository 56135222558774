import * as Actions from './check-in-log-elastic.actions';
import { CheckInLogElasticStateInterface } from './check-in-log-elastic.model';

export const checkInLogInitialState: CheckInLogElasticStateInterface = {
  isCardDataLoading: false,
  isCardDataLoaded: false,
  isTableDataLoading: false,
  isTableDataLoaded: false,
  isLinesLoading: false,
  isLinesLoaded: false,
  lines: [],
  isSubmitCheckInLogElasticPending: false,
  isSubmitCheckInLogElasticCompleted: false,
  isDeleteCheckInLogElasticPending: false,
  isDeleteCheckInLogElasticCompleted: false,
  cardData: {
    objectCount: 0,
    objectMinutes: 0,
  },
  tableData: [],
  filters: {
    siteIds: '-1',
    objectIds: '-1',
    shiftIds: '-1',
    startDate: '',
    endDate: '',
    sourceTypeId: 1,
    destinationTypeId: JSON.stringify([1, 2]),
    limit: 10,
    offset: 0,
  },
  tableSettings: [],
};

export function checkInLogElasticReducer(
  state: CheckInLogElasticStateInterface = checkInLogInitialState,
  action: Actions.CheckInLogElasticActions,
) {
  switch (action.type) {
    case Actions.START_CHECK_IN_LOG_ELASTIC_CARDS_DATA_LOADING:
      return {
        ...state,
        isCardDataLoading: true,
      };

    case Actions.CHECK_IN_LOG_ELASTIC_CARDS_DATA_LOADED:
      return {
        ...state,
        isCardDataLoaded: true,
        isCardDataLoading: false,
        cardData: { ...action.payload },
      };

    case Actions.START_CHECK_IN_LOG_ELASTIC_TABLE_DATA_LOADING:
      return {
        ...state,
        isTableDataLoading: true,
      };

    case Actions.CHECK_IN_LOG_ELASTIC_TABLE_DATA_LOADED:
      return {
        ...state,
        isTableDataLoaded: true,
        isTableDataLoading: false,
        tableData: action.payload,
      };

    case Actions.LOAD_LINES:
      return {
        ...state,
        isLinesLoading: true,
        isLinesLoaded: false,
      };

    case Actions.LINES_LOADED:
      return {
        ...state,
        isLinesLoading: false,
        isLinesLoaded: true,
        lines: action.payload.data,
      };

    case Actions.SUBMIT_CHECK_IN_LOG_ELASTIC:
      return {
        ...state,
        isSubmitCheckInLogPending: true,
        isSubmitCheckInLogCompleted: false,
      };

    case Actions.SUBMIT_CHECK_IN_LOG_ELASTIC_COMPLETED:
      return {
        ...state,
        isSubmitCheckInLogPending: false,
        isSubmitCheckInLogCompleted: true,
      };

    case Actions.DELETE_CHECK_IN_LOG_ELASTIC:
      return {
        ...state,
        isDeleteCheckInLogPending: true,
        isDeleteCheckInLogCompleted: false,
      };

    case Actions.DELETE_CHECK_IN_LOG_ELASTIC_COMPLETED:
      return {
        ...state,
        isDeleteCheckInLogPending: false,
        isDeleteCheckInLogCompleted: true,
      };

    case Actions.SET_TABLE_SETTINGS:
      return {
        ...state,
        tableSettings: action.payload,
      };

    case Actions.FETCH_DATA_ERROR:
      return {
        ...state,
        isTableDataLoaded: true,
        isTableDataLoading: false,
        isCardDataLoaded: true,
        isCardDataLoading: false,
      };
    default:
      return state;
  }
}
