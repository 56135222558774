import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import * as TagActions from './tag.actions';
import { from, of, switchMap } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { TagsService } from '../../settings/tags/tags.service';
import { GetManyResponseInterface } from '../../../shared/model/interface/crud-response-interface.model';
import { TagInterface } from '../../settings/tags/tags.model';

@Injectable()
export class TagFilterEffects {
  getTags$ = createEffect(() =>
    this.actions$.pipe(
      ofType(TagActions.ActionTypes.GetTags),
      switchMap((actionPayload: TagActions.GetTags) => {
        return from(this.tagService.getFilterTags(actionPayload.params)).pipe(
          switchMap((response: GetManyResponseInterface<TagInterface>) => {
            return of(new TagActions.GetTagsLoaded(response));
          }),
          catchError(() => {
            return of(new TagActions.FetchError(TagActions.ActionTypes.GetTags));
          }),
        );
      }),
    ),
  );

  constructor(private readonly actions$: Actions<TagActions.TagActions>, private readonly tagService: TagsService) {}
}
