import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, switchMap } from 'rxjs/operators';
import { HttpClient, HttpErrorResponse, HttpParams } from '@angular/common/http';
import { Store } from '@ngrx/store';
import * as ObjectActions from './labor-performance.actions';
import * as oeeAppReducer from '../../oee.reducer';
import { of } from 'rxjs';
import { LaborPerformanceChartDataInterface, LaborPerformanceResponseInterface } from './labor-performance.model';
import { LaborPerformanceService } from '../../../shared/service/labor-performance/labor-performance.service';
import { EntityTranslatorService } from '../../../shared/service/entity-translator/entity-translator.service';

@Injectable()
export class LaborPerformanceEffects {
  constructor(
    private readonly actions$: Actions,
    private readonly http: HttpClient,
    private readonly store: Store<oeeAppReducer.OeeAppState>,
    private readonly getLaborPerformanceService: LaborPerformanceService,
    private readonly entityTranslation: EntityTranslatorService,
  ) {}

  getLaborPerformanceData = createEffect(() =>
    this.actions$.pipe(
      ofType(ObjectActions.LaborPerformanceActionTypes.StartLaborPerformanceDataLoading),
      switchMap((objectData: ObjectActions.StartLaborPerformanceDataLoading) => {
        const param = new HttpParams()
          .set('startDate', objectData.payload.startDate)
          .set('endDate', objectData.payload.endDate)
          .set('siteIds', objectData.payload.siteIds)
          .set('lineIds', objectData.payload.lineIds)
          .set('userIds', objectData.payload.userIds);

        return this.getLaborPerformanceService.getLaborPerformanceData(param).pipe(
          switchMap((response: LaborPerformanceResponseInterface) => {
            response.data.basedOnLineChartData.forEach(
              (laborPerformanceChartData: LaborPerformanceChartDataInterface) => {
                this.entityTranslation.translate(laborPerformanceChartData);
              },
            );

            return of(new ObjectActions.LaborPerformanceDataLoaded(response));
          }),
          catchError((err: HttpErrorResponse) => {
            return of(new ObjectActions.FetchError(err));
          }),
        );
      }),
      catchError((err) => {
        return of(new ObjectActions.FetchError(err));
      }),
    ),
  );
}
