import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {ScwMatMissingDataComponent} from './scw-mat-missing-data.component';
import { RouterModule } from '@angular/router';

@NgModule({
  imports: [CommonModule, RouterModule],
  exports: [ScwMatMissingDataComponent],
  declarations: [ScwMatMissingDataComponent],
})
export class ScwMatMissingDataModule {
}
