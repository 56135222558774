import * as Actions from './multi-line-change-activity.actions';
import { IMultiLineChangeActivityState } from './multi-line-change-activity.model';

const initialState: IMultiLineChangeActivityState = {
  ongoingActivitiesOfLinesDataLoading: false,
  ongoingActivitiesOfLinesDataLoaded: false,
  ongoingActivitiesOfLinePath: null,
};

export function multiLineChangeActivityReducer(
  state: IMultiLineChangeActivityState = initialState,
  action: Actions.MultiLineChangeActivityActions,
): IMultiLineChangeActivityState {
  switch (action.type) {
    case Actions.EMultiLineChangeActivityAction.LOAD_ONGOING_ACTIVITIES_OF_LINE_PATH:
      return {
        ...state,
        ongoingActivitiesOfLinesDataLoading: true,
        ongoingActivitiesOfLinesDataLoaded: false,
      };
    case Actions.EMultiLineChangeActivityAction.LOADED_ONGOING_ACTIVITIES_OF_LINE_PATH:
      return {
        ...state,
        ongoingActivitiesOfLinesDataLoading: false,
        ongoingActivitiesOfLinesDataLoaded: true,
        ongoingActivitiesOfLinePath: action.data,
      };
    default:
      return state;
  }
}
