import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import * as ObjectActions from './product-versions.actions';
import { catchError, switchMap } from 'rxjs/operators';
import { from, of } from 'rxjs';
import * as AppActions from '../../../../app/actions';
import { HttpParams } from '@angular/common/http';
import { Store } from '@ngrx/store';
import * as oeeAppReducer from '../../../../oee.reducer';
import {
  BaseOneResponseInterface,
  BulkResponseDataInterface,
  GetManyResponseInterface,
} from '../../../../../shared/model/interface/crud-response-interface.model';
import { ProductVersionsService } from './product-versions.service';
import { IProductVersion } from './product-versions.model';
import { HTTPHelperService } from '../../../../../shared/service/http/http.helper.service';

@Injectable()
export class ProductVersionsEffects {
  constructor(
    private readonly actions$: Actions,
    private readonly service: ProductVersionsService,
    private readonly httpHelperService: HTTPHelperService,
    private readonly store: Store<oeeAppReducer.OeeAppState>,
  ) {}


  getVersionsData = createEffect(() => this.actions$.pipe(
    ofType(ObjectActions.EProductVersionsAction.GET_VERSIONS_DATA),
    switchMap((payload: ObjectActions.GetVersionsData) => {
      const params: HttpParams = this.httpHelperService.insertGenericCrudRequestParameters(payload.params);

      return from(this.service.getVersionsData(params)).pipe(
        switchMap((response: GetManyResponseInterface<IProductVersion>) => {
          return of(new ObjectActions.GetVersionsDataCompleted(response));
        }),
        catchError((errorRes) => {
          return of(new ObjectActions.FetchError(errorRes), new AppActions.HideLoader());
        }),
      );
    }),
    catchError((errorRes) => {
      return of(new ObjectActions.FetchError(errorRes), new AppActions.HideLoader());
    }),
  ));

  getDefaultVersionData = createEffect(() => this.actions$.pipe(
    ofType(ObjectActions.EProductVersionsAction.GET_DEFAULT_VERSION_DATA),
    switchMap((payload: ObjectActions.GetDefaultVersionData) => {
      const params: HttpParams = this.httpHelperService.insertGenericCrudRequestParameters({
        ...payload.params,
        additionalCustomSearch: [{ default: { $eq: true } }],
      });

      return from(this.service.getVersionsData(params)).pipe(
        switchMap((response: GetManyResponseInterface<IProductVersion>) => {
          return of(new ObjectActions.GetDefaultVersionDataCompleted(response));
        }),
        catchError((errorRes) => {
          return of(new ObjectActions.FetchError(errorRes), new AppActions.HideLoader());
        }),
      );
    }),
    catchError((errorRes) => {
      return of(new ObjectActions.FetchError(errorRes), new AppActions.HideLoader());
    }),
  ));

  addVersions = createEffect(() => this.actions$.pipe(
    ofType(ObjectActions.EProductVersionsAction.ADD_VERSION),
    switchMap((payload: ObjectActions.AddVersion) => {
      this.store.dispatch(new AppActions.ShowLoader());

      return from(this.service.addProductVersion(payload.body)).pipe(
        switchMap((response: BaseOneResponseInterface<IProductVersion>) => {
          return of(
            new ObjectActions.AddVersionCompleted(response.data, response.success),
            new AppActions.HideLoader(),
          );
        }),
        catchError((errorRes) => {
          return of(new ObjectActions.FetchError(errorRes), new AppActions.HideLoader());
        }),
      );
    }),
    catchError((errorRes) => {
      return of(new ObjectActions.FetchError(errorRes), new AppActions.HideLoader());
    }),
  ));


  editProductVersion = createEffect(() => this.actions$.pipe(
    ofType(ObjectActions.EProductVersionsAction.EDIT_VERSION),
    switchMap((payload: ObjectActions.EditVersion) => {
      this.store.dispatch(new AppActions.ShowLoader());

      return from(this.service.editProductVersion(payload.productVersionId, payload.payload)).pipe(
        switchMap((response: BaseOneResponseInterface<IProductVersion>) => {
          return of(new ObjectActions.EditVersionCompleted(response.success), new AppActions.HideLoader());
        }),
        catchError((errorRes) => {
          return of(new ObjectActions.FetchError(errorRes), new AppActions.HideLoader());
        }),
      );
    }),
    catchError((errorRes) => {
      return of(new ObjectActions.FetchError(errorRes), new AppActions.HideLoader());
    }),
  ));


  deleteProductVersion = createEffect(() => this.actions$.pipe(
    ofType(ObjectActions.EProductVersionsAction.DELETE_VERSION),
    switchMap((payload: ObjectActions.DeleteVersion) => {
      this.store.dispatch(new AppActions.ShowLoader());

      return from(this.service.deleteProductVersion(payload.versionsId)).pipe(
        switchMap(() => {
          return of(new ObjectActions.DeleteVersionCompleted(true), new AppActions.HideLoader());
        }),
        catchError((errorRes) => {
          return of(
            new ObjectActions.FetchError(errorRes),
            new ObjectActions.DeleteVersionCompleted(false),
            new AppActions.HideLoader(),
          );
        }),
      );
    }),
    catchError((errorRes) => {
      return of(new ObjectActions.FetchError(errorRes), new AppActions.HideLoader());
    }),
  ));


  deleteProductVersionsBulk = createEffect(() => this.actions$.pipe(
    ofType(ObjectActions.EProductVersionsAction.DELETE_VERSIONS),
    switchMap((payload: ObjectActions.DeleteVersions) => {
      this.store.dispatch(new AppActions.ShowLoader());

      return from(this.service.deleteProductVersions(payload.versionsIdList)).pipe(
        switchMap((response: BulkResponseDataInterface) => {
          return of(new ObjectActions.DeleteVersionsCompleted(response), new AppActions.HideLoader());
        }),
        catchError((errorRes) => {
          return of(new ObjectActions.FetchError(errorRes), new AppActions.HideLoader());
        }),
      );
    }),
    catchError((errorRes) => {
      return of(new ObjectActions.FetchError(errorRes), new AppActions.HideLoader());
    }),
  ));
}
