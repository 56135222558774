import { Component, Inject } from '@angular/core';
import { Store } from '@ngrx/store';
import { OeeAppState } from '../../../../store/oee.reducer';
import { Observable } from 'rxjs';
import { DecimalHelper } from '../../../../shared/helper/decimal/decimal-helper';
import { OnDestroyDecorator } from '../../../../shared/decorator/on-destroy-decorator';
import { map } from 'rxjs/operators';
import { IOeeMetricApq } from '../../../../store/home/home.model';
import {
  EOeeMetric,
  IOEEMetricMultiselectMeta,
} from '../../../settings/home-page-display-settings/home-metrics/home-metrics.model';
import { PROPERTIES } from '../home-metrics.component';

@OnDestroyDecorator
@Component({
  selector: 'app-shift-oee-metric',
  templateUrl: './shift-oee-metric.component.html',
})
export class ShiftOeeMetricComponent {
  public currentShiftName$: Observable<string> = this.store.select('line', 'quantities', 'currentShiftName');
  public shiftBasedOee$: Observable<IOeeMetricApq | null> = this.store
    .select('line', 'quantities', 'shiftBasedOee')
    .pipe(
      map((shiftBasedOee: IOeeMetricApq | null) => {
        return (
          shiftBasedOee && {
            [EOeeMetric.OEE]: this.decimalHelper.toFixedValue(shiftBasedOee[EOeeMetric.OEE], 2),
            [EOeeMetric.A]: this.decimalHelper.toFixedValue(shiftBasedOee[EOeeMetric.A], 2),
            [EOeeMetric.P]: this.decimalHelper.toFixedValue(shiftBasedOee[EOeeMetric.P], 2),
            [EOeeMetric.Q]: this.decimalHelper.toFixedValue(shiftBasedOee[EOeeMetric.Q], 2),
          }
        );
      }),
    );

  constructor(
    private readonly decimalHelper: DecimalHelper,
    private readonly store: Store<OeeAppState>,
    @Inject(PROPERTIES) public readonly properties: IOEEMetricMultiselectMeta,
  ) {}
}
