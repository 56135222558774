export enum ECellTypes {
  LIST = 'list',
  WHOLE = 'whole',
  DECIMAL = 'decimal',
  DATE = 'date',
  TEXTLENGTH = 'textLength',
  CUSTOM = 'custom',
  READONLY = 'readOnly',
}

export enum EExcelSheetState {
  VISIBLE = 'visible',
  HIDDEN = 'hidden',
  VERY_HIDDEN = 'veryHidden',
}

export enum EExcelSheetType {
  README = 'readme',
  TABLE = 'table',
}

export enum EDependentBehavior {
  MULTIPLY = 'multiply',
  MAP = 'map',
}

export enum EExcelColumnWidth {
  BOOLEAN = 10,
  PHONE = 15,
  PIN = 15,
  DEFAULT = 20,
  CONTACT_TYPE = 25,
  PHASE = 25,
  DECIMAL = 30,
  EMAIL = 35,
  DATE_TIME = 40,
  ERROR = 255,
}
