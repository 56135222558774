import { TranslateService } from '@ngx-translate/core';
import {
  CellTypes,
  CreateExcelInterface,
  CreateExcelSheetInterface,
  ExcelColumnDefinitionInterface,
  ExcelColumnInfoConfigurationInterface,
  ExcelColumnWidthEnum,
  ExcelDateFormatInformationInterface,
  ExcelHelperService,
  ExcelSheetTypeEnum,
} from '../../../shared/service/excel/excel-helper.service';
import * as oeeAppReducer from '../../oee.reducer';
import { Inject, Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { Store } from '@ngrx/store';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import {
  ChartValues,
  DeepDiveAnalysisResponse, ICommonOeeData,
  EStackChartGroupBy,
  IDeepDiveFilters,
  IFormattedOeeResult,
  IMonthlyOeeFilter,
  IOeeResponse,
  ITableOeeResult,
} from './deep-dive-analysis.model';
import * as AppActions from '../../app/actions';
import * as moment from 'moment';
import * as ObjectActions from './deep-dive-analysis.actions';
import { takeUntil } from 'rxjs/operators';
import { excelDateFormat, excelTimeFormat } from '../../../shared/model/enum/excel-date-format';
import { mysqlDateFormat } from '../../../shared/helper/date';
import { DatatableHeaderInterface } from '../../../shared/component/datatable/datatable.model';
import { ValueType } from 'exceljs';
import { HelperService } from '../../../shared/service/helper.service';
import { DecimalHelper } from '../../../shared/helper/decimal/decimal-helper';
import { DECIMAL_DEFAULT_SCALE_LIMIT } from 'src/constants';
import { User } from '../../user/model';
import * as _ from 'lodash';
import {
  BaseOneResponseInterface,
  GetManyResponseInterface,
} from '../../../shared/model/interface/crud-response-interface.model';
import { filter } from 'rxjs/operators';
import { FilterHelperService } from '../../../shared/service/filter/filter.helper.service';

@Injectable({
  providedIn: 'root',
})
export class DeepDiveAnalysisService {

  private readonly routes = {
    lines: `${this.baseUrl}/lines`,
    oeeCalculation: `${this.baseUrl}/oee-calculation`,
  };
  private readonly destroySubject: Subject<boolean> = new Subject<boolean>();
  private dateFormatInformation: ExcelDateFormatInformationInterface;
  public decimalScale$: number = DECIMAL_DEFAULT_SCALE_LIMIT;

  constructor(
    private readonly store: Store<oeeAppReducer.OeeAppState>,
    public http: HttpClient,
    @Inject('API_BASE_URL') private readonly baseUrl: string,
    private readonly excelHelper: ExcelHelperService,
    private readonly translate: TranslateService,
    private readonly helperService: HelperService,
    private readonly decimalHelper: DecimalHelper,
  ) {
    let timezone: string = 'utc';
    let dateFormat$: string;
    let timeFormat$: string;
    let locale$: string;
    let dateFormatRaw$: string;
    let dateTimeFormatRaw$: string;

    this.store
      .select('user')
      .pipe(takeUntil(this.destroySubject))
      .subscribe((state) => {
        if (state.isUserLoaded) {
          timezone = state.timezone;

          if (state.locale !== '') {
            dateFormat$ = excelDateFormat[state.locale];
            timeFormat$ = excelTimeFormat[state.locale];
          }

          dateFormatRaw$ = state.dateFormat;
          dateTimeFormatRaw$ = state.dateTimeFormat;
          locale$ = state.locale;
          this.decimalScale$ = state.decimalScaleLimit;

          this.dateFormatInformation = {
            timezone,
            dateFormat$,
            timeFormat$,
            locale$,
            dateFormatRaw$,
            dateTimeFormatRaw$,
          };
          this.destroySubject.next(true);
          this.destroySubject.complete();
        }
      });
  }

  public fetchDeepDiveAnalysisData(params: HttpParams): Observable<BaseOneResponseInterface<DeepDiveAnalysisResponse>> {
    return this.http.get<BaseOneResponseInterface<DeepDiveAnalysisResponse>>(
      `${this.routes.lines}/deep-dive-analysis`,
      {
        params,
      },
    );
  }

  public fetchOeeResultByTimeSelection(body: IMonthlyOeeFilter): Observable<GetManyResponseInterface<IOeeResponse>> {
    return this.http.post<GetManyResponseInterface<IOeeResponse>>(`${this.routes.oeeCalculation}/calculate-oee`, body, {
      headers: new HttpHeaders({ 'X-HTTP-Method': 'GET' }),
    });
  }

  public downloadExcel(data: ITableOeeResult[], headers: DatatableHeaderInterface[], siteId: number): void {
    this.store.dispatch(new AppActions.ShowLoader());
    const sheetTitle: string = this.translate.instant('pageTitles.deep_dive_analysis_report');
    const excelName: string = `${sheetTitle} ${moment()
      .tz(this.dateFormatInformation.timezone)
      .format(this.dateFormatInformation.dateFormat$)}`;

    const excelTemplateFormatOptions: CreateExcelInterface = {
      data,
      columns: this.getExcelColumnsFromTableHeaders(headers),
    };
    const excelDataAnalysisFormatOptions: CreateExcelInterface = {
      data,
      columns: this.getExcelColumnsFromTableHeaders(headers, true),
    };

    excelTemplateFormatOptions.data = this.formatOeeDeepDiveExcelRows(data, false);
    excelDataAnalysisFormatOptions.data = this.formatOeeDeepDiveExcelRows(data, false);

    const worksheets: CreateExcelSheetInterface[] = [
      {
        sheetTitle: this.translate.instant('activityLogs.excel.readme.worksheetName'),
        sheetType: ExcelSheetTypeEnum.README,
      },
      {
        sheetTitle: this.translate.instant('activityLogs.excel.readme.templateFormatTitle'),
        withData: true,
        sheetType: ExcelSheetTypeEnum.TABLE,
        params: excelTemplateFormatOptions,
        isDisabledColumnsFirstLine: true,
        addDateTimeFormula: undefined,
      },
      {
        sheetTitle: this.translate.instant('activityLogs.excel.readme.dataAnalysisFormatTitle'),
        withData: true,
        sheetType: ExcelSheetTypeEnum.TABLE,
        params: excelDataAnalysisFormatOptions,
        isDisabledColumnsFirstLine: true,
        addDateTimeFormula: undefined,
      },
    ];

    this.excelHelper
      .createExcel(
        excelName,
        { siteId, name: 'deepDiveAnalysis', withData: true },
        worksheets,
        this.dateFormatInformation.timezone,
        this.dateFormatInformation.dateFormat$,
        this.dateFormatInformation.timeFormat$,
        false,
      )
      .then(
        () => {
          this.store.dispatch(new ObjectActions.DeepDiveDownloadExcelCompleted());
          this.store.dispatch(new AppActions.HideLoader());
        },
        () => {
          this.store.dispatch(new ObjectActions.FetchError({}));
          this.store.dispatch(new AppActions.HideLoader());
        },
      );
  }

  public formatWeeklyOeeResultRows(
    oeeData: IFormattedOeeResult,
    siteDecimalScaleLimit: number,
    isProductGroup: boolean = false,
  ): ITableOeeResult {
    const destroySubject: Subject<boolean> = new Subject<boolean>();
    let userScaleLimit: number = DECIMAL_DEFAULT_SCALE_LIMIT;

    this.store
      .select('user')
      .pipe(takeUntil(destroySubject))
      .subscribe((state: User) => {
        if (state.isUserLoaded) {
          userScaleLimit = state.decimalScaleLimit;

          destroySubject.next(true);
          destroySubject.complete();
        }
      });

    const minuteToHourDivider: string = '60';
    return {
      weekNo: isProductGroup ? oeeData.productName : oeeData.intervalName,
      staffedTime: this.handleDecimalNumbers(
        this.decimalHelper.divide(
          this.decimalHelper.add(
            this.decimalHelper.convertToDecimal(oeeData.totalRunTimeDuration?.toString() ?? '0'),
            oeeData[ChartValues.totalDownTimeDuration],
          ),
          minuteToHourDivider,
        ),
        userScaleLimit,
      ),
      totalRunTimeDuration: this.handleDecimalNumbers(
        this.decimalHelper.divide(
          this.decimalHelper.convertToDecimal(oeeData.totalRunTimeDuration),
          minuteToHourDivider,
        ),
        userScaleLimit,
      ),
      totalGoodCountEffectiveDuration: this.handleDecimalNumbers(
        this.decimalHelper.divide(
          this.decimalHelper.convertToDecimal(oeeData.totalGoodCountEffectiveDuration),
          minuteToHourDivider,
        ),
        userScaleLimit,
      ),
      totalInitialCountEffectiveDuration: this.handleDecimalNumbers(
        this.decimalHelper.divide(
          this.decimalHelper.convertToDecimal(oeeData.totalInitialCountEffectiveDuration),
          minuteToHourDivider,
        ),
        userScaleLimit,
      ),
      totalGoodCount: this.handleDecimalNumbers(
        this.decimalHelper.convertToDecimal(oeeData.totalGoodCount),
        siteDecimalScaleLimit,
      ),
      totalInitialCount: this.handleDecimalNumbers(
        this.decimalHelper.convertToDecimal(oeeData.totalInitialCount),
        siteDecimalScaleLimit,
      ),
      [ChartValues.totalDownTimeDuration]: this.handleDecimalNumbers(
        this.decimalHelper.divide(oeeData[ChartValues.totalDownTimeDuration], minuteToHourDivider),
        userScaleLimit,
      ),
      [ChartValues.totalDownTimePlannedDuration]: this.handleDecimalNumbers(
        this.decimalHelper.divide(oeeData[ChartValues.totalDownTimePlannedDuration], minuteToHourDivider),
        userScaleLimit,
      ),
      [ChartValues.totalDownTimeUnplannedDuration]: this.handleDecimalNumbers(
        this.decimalHelper.divide(oeeData[ChartValues.totalDownTimeUnplannedDuration], minuteToHourDivider),
        userScaleLimit,
      ),
      totalIdleTimeDuration: this.handleDecimalNumbers(
        this.decimalHelper.divide(
          this.decimalHelper.convertToDecimal(oeeData.totalIdleTimeDuration),
          minuteToHourDivider,
        ),
        userScaleLimit,
      ),
      totalScrapCount: this.handleDecimalNumbers(
        this.decimalHelper.convertToDecimal(oeeData.totalScrapCount),
        siteDecimalScaleLimit,
      ),
      availabilityPerInterval: this.handleDecimalNumbers(
        this.decimalHelper.multiply(oeeData.availabilityPerInterval, '100'),
      ),
      qualityPerInterval: this.handleDecimalNumbers(this.decimalHelper.multiply(oeeData.qualityPerInterval, '100')),
      performancePerInterval: this.handleDecimalNumbers(
        this.decimalHelper.multiply(oeeData.performancePerInterval, '100'),
      ),
      overallOEE: this.handleDecimalNumbers(this.decimalHelper.multiply(oeeData.overallOEE, '100')),
      potentialQuantityBasedOnProductSpeedxActualRunTime: this.handleDecimalNumbers(
        this.decimalHelper.convertToDecimal(oeeData.potentialQuantityBasedOnProductSpeedxActualRunTime),
        siteDecimalScaleLimit,
      ),
      totalDuration: this.handleDecimalNumbers(
        this.decimalHelper.divide(this.decimalHelper.convertToDecimal(oeeData.totalDuration), minuteToHourDivider),
        userScaleLimit,
      ),
      potentialQuantityBasedOnProductSpeedxActualRunAndDownTime: this.handleDecimalNumbers(
        this.decimalHelper.convertToDecimal(oeeData[ChartValues.staffedTimeExpectedOutput]),
        siteDecimalScaleLimit,
      ),
    };
  }

  public handleDecimalNumbers(number: string, scale: number = DECIMAL_DEFAULT_SCALE_LIMIT): string {
    return this.decimalHelper.toFixedValue(number ?? '0', scale);
  }

  public formatDeepDiveAnalysisParams(params: IDeepDiveFilters): HttpParams {
    return new HttpParams()
      .set('sites', params.sites.join(','))
      .set('lines', params.lines.join(','))
      .set('start', moment(params.start).format(mysqlDateFormat))
      .set('end', moment(params.end).format(mysqlDateFormat))
      .set('shifts', params.shifts.join(','))
      .set('products', params.products.join(','));
  }

  public formatHourlyOeeRequestBody(params: IDeepDiveFilters): IMonthlyOeeFilter {
    return {
      sites: params.sites,
      lines: params.lines,
      startDate: moment(params.start).format(mysqlDateFormat),
      endDate: moment(params.end)
        .add(1, 'day')
        .format(mysqlDateFormat),
      shifts: params.shifts,
      sqlMode: 'HOUR',
    };
  }

  public formatDailyOeeRequestBody(params: IDeepDiveFilters): IMonthlyOeeFilter {
    return {
      sites: params.sites,
      lines: params.lines,
      startDate: moment(params.start).format(mysqlDateFormat),
      products: params.products,
      endDate: moment(params.end)
        .add(1, 'day')
        .format(mysqlDateFormat),
      shifts: params.shifts,
      sqlMode: 'DAY',
    };
  }

  public formatMonthlyOeeRequestBody(params: IDeepDiveFilters): IMonthlyOeeFilter {
    return {
      sites: params.sites,
      lines: params.lines,
      startDate: moment(params.start).format(mysqlDateFormat),
      products: params.products,
      endDate: moment(params.end)
        .add(1, 'day')
        .format(mysqlDateFormat),
      shifts: params.shifts,
      sqlMode: 'MONTH',
    };
  }

  public formatProductGroupOeeRequestBody(params: IDeepDiveFilters): IMonthlyOeeFilter {
    return {
      sites: params.sites,
      lines: params.lines,
      startDate: moment(params.start).format(mysqlDateFormat),
      products: params.products,
      endDate: moment(params.end).add(1, 'day').format(mysqlDateFormat),
      shifts: params.shifts,
      sqlMode: 'PRODUCT',
    };
  }

  private getExcelColumnsFromTableHeaders(
    headers: DatatableHeaderInterface[],
    isDataAnalysisFormat: boolean = false,
  ): ExcelColumnDefinitionInterface[] {
    const excelColumnInfoConfiguration: ExcelColumnInfoConfigurationInterface = {
      decimalFields: [
        'staffedTime',
        'totalRunTimeDuration',
        'totalDownTimeDuration',
        'totalIdleTimeDuration',
        'potentialQuantityBasedOnProductSpeedxActualRunTime',
        'totalGoodCount',
        'totalInitialCount',
        'totalScrapCount',
        'totalGoodCountEffectiveDuration',
        'totalInitialCountEffectiveDuration',
        'availabilityPerInterval',
        'performancePerInterval',
        'qualityPerInterval',
        'overallOEE',
        'totalDuration',
      ],
    };

    return headers.reduce((excelColumns: any[], column: DatatableHeaderInterface) => {
      excelColumns.push({
        header: column.name,
        key: column.value,
        width: ExcelColumnWidthEnum.DEFAULT,
        type: ValueType.String,
        style: { numFmt: '@' },
        dataValidation: {
          type: CellTypes.CUSTOM,
          formulae: [],
          showErrorMessage: false,
          showInputMessage: false,
        },
        ...(isDataAnalysisFormat
          ? this.excelHelper.getExcelColumnInfo(column.value, excelColumnInfoConfiguration)
          : {}),
      });
      return excelColumns;
    }, []);
  }

  private formatOeeDeepDiveExcelRows(
    excelRows: ITableOeeResult[] = [],
    isDataAnalysisFormat: boolean = false,
  ): ITableOeeResult[] {
    const formattedExcelValues: ITableOeeResult[] = _.cloneDeep(excelRows);
    formattedExcelValues.forEach((row: ITableOeeResult) => {
      row.staffedTime =
        row.staffedTime && this.decimalHelper.formatDecimalValueForExcel(row.staffedTime, isDataAnalysisFormat);
      row.totalRunTimeDuration =
        row.totalRunTimeDuration &&
        this.decimalHelper.formatDecimalValueForExcel(row.totalRunTimeDuration, isDataAnalysisFormat);
      row[ChartValues.totalDownTimePlannedDuration] =
        row[ChartValues.totalDownTimePlannedDuration] &&
        this.decimalHelper.formatDecimalValueForExcel(
          row[ChartValues.totalDownTimePlannedDuration],
          isDataAnalysisFormat,
        );
      row[ChartValues.totalDownTimeUnplannedDuration] =
        row[ChartValues.totalDownTimeUnplannedDuration] &&
        this.decimalHelper.formatDecimalValueForExcel(
          row[ChartValues.totalDownTimeUnplannedDuration],
          isDataAnalysisFormat,
        );
      row.totalIdleTimeDuration =
        row.totalIdleTimeDuration &&
        this.decimalHelper.formatDecimalValueForExcel(row.totalIdleTimeDuration, isDataAnalysisFormat);
      row.potentialQuantityBasedOnProductSpeedxActualRunTime =
        row.potentialQuantityBasedOnProductSpeedxActualRunTime &&
        this.decimalHelper.formatDecimalValueForExcel(
          row.potentialQuantityBasedOnProductSpeedxActualRunTime,
          isDataAnalysisFormat,
        );
      row.totalInitialCountEffectiveDuration =
        row.totalInitialCountEffectiveDuration &&
        this.decimalHelper.formatDecimalValueForExcel(row.totalInitialCountEffectiveDuration, isDataAnalysisFormat);
      row.totalGoodCountEffectiveDuration =
        row.totalGoodCountEffectiveDuration &&
        this.decimalHelper.formatDecimalValueForExcel(row.totalGoodCountEffectiveDuration, isDataAnalysisFormat);
      row.availabilityPerInterval =
        row.availabilityPerInterval &&
        this.decimalHelper.formatDecimalValueForExcel(row.availabilityPerInterval, isDataAnalysisFormat);
      row.performancePerInterval =
        row.performancePerInterval &&
        this.decimalHelper.formatDecimalValueForExcel(row.performancePerInterval, isDataAnalysisFormat);
      row.qualityPerInterval =
        row.qualityPerInterval &&
        this.decimalHelper.formatDecimalValueForExcel(row.qualityPerInterval, isDataAnalysisFormat);
      row.overallOEE =
        row.overallOEE && this.decimalHelper.formatDecimalValueForExcel(row.overallOEE, isDataAnalysisFormat);
      row.totalDuration =
        row.totalDuration && this.decimalHelper.formatDecimalValueForExcel(row.totalDuration, isDataAnalysisFormat);
    });

    return formattedExcelValues;
  }
}
