import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import * as StationHomeKpiActions from './kpi-card.actions';
import { catchError, map, of, switchMap, tap } from 'rxjs';
import { LineStationService } from '../../../shared/service/line-station/line-station.service';
import { TFetchStationQuantitiesPayload, TFetchStationQuantitiesSuccessData } from './kpi-card.model';
import { BaseOneResponseInterface } from '../../../shared/model/interface/crud-response-interface.model';
import { Store } from '@ngrx/store';
import * as AppActions from '../../app/actions';
import * as oeeAppReducer from '../../oee.reducer';

@Injectable()
export class StationHomeKpiCardComponentEffects {
  constructor(
    private readonly actions$: Actions,
    private readonly store: Store<oeeAppReducer.OeeAppState>,
    private readonly lineStationService: LineStationService,
  ) {}

  getLineStationQuantities$ = createEffect(() =>
    this.actions$.pipe(
      ofType(StationHomeKpiActions.fetchStationQuantities),
      tap(() => this.store.dispatch(new AppActions.ShowLoader())),
      switchMap((payload: TFetchStationQuantitiesPayload) =>
        this.lineStationService.getLineStationQuantities(payload.stationID).pipe(
          tap(() => this.store.dispatch(new AppActions.HideLoader())),
          map((item: BaseOneResponseInterface<TFetchStationQuantitiesSuccessData>) => {
            return StationHomeKpiActions.fetchStationQuantitiesSuccess(item.data);
          }),
          catchError((error) => of(StationHomeKpiActions.fetchStationQuantitiesError({ error }))),
        ),
      ),
    ),
  );
}
