import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { switchMap } from 'rxjs/operators';
import * as ObjectActions from './page-header.actions';
import { of } from 'rxjs';
import { createAction } from '@ngrx/store';
import { MonitoringService } from '../../shared/service/error-service/monitoring.service';
const noOpAction = createAction('[Page Header] No Operation');

@Injectable()
export class PageHeaderEffects {
  loadPageHeaders$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ObjectActions.RUN_PRINT_FUNCTIONALITY),
      switchMap((objectData: ObjectActions.RunPrintFunctionality) => {
        if (objectData.logInformation.name !== '') {
          this.monitoringService.logEvent({ name: 'OEE Export' }, { ...objectData.logInformation, asPdf: true });
        }

        return of(noOpAction());
      }),
    ),
  );

  constructor(private readonly actions$: Actions, private readonly monitoringService: MonitoringService) {}
}
