import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { catchError, switchMap } from 'rxjs/operators';
import { map, of } from 'rxjs';
import { OeeAdherenceService } from './oee-adherence.service';
import * as oeeAppReducer from '../../oee.reducer';
import * as OeeAdherenceActions from './oee-adherence.actions';
import * as AppActions from '../../../store/app/actions';
import { HttpParams } from '@angular/common/http';
import {
  BaseOneResponseInterface,
  GetManyResponseInterface,
} from '../../../shared/model/interface/crud-response-interface.model';
import {
  IGroupByWeekResponse,
  IGroupByWeekTableResult,
  IWoAdherenceOeeWithinIntervalResponse,
} from './oee-adherence.model';
import { mysqlDateFormat } from '../../../shared/helper/date';
import _ from 'lodash';

@Injectable()
export class OeeAdherenceEffects {
  constructor(
    private readonly actions$: Actions,
    private readonly store: Store<oeeAppReducer.OeeAppState>,
    private readonly oeeAdherenceService: OeeAdherenceService,
  ) {}

  getOeeAdherenceWeekData$ = createEffect(() =>
    this.actions$.pipe(
      ofType(OeeAdherenceActions.OEE_ADHERENCE_WEEK_DATA_LOADING),
      switchMap((objectData: OeeAdherenceActions.OeeAdherenceWeekDataLoading) => {
        const httpParams: HttpParams = new HttpParams()
          .set('sites', String(objectData.params.sites))
          .set('lines', String(objectData.params.lines))
          .set('start', objectData.params.dateRange.startDate.format(mysqlDateFormat))
          .set('end', objectData.params.dateRange.endDate.format(mysqlDateFormat))
          .set('productFamilies', String(objectData.params.productFamilies));

        this.store.dispatch(new AppActions.ShowLoader());

        return this.oeeAdherenceService.getOeeAdherenceWeekData(httpParams).pipe(
          map((payload: BaseOneResponseInterface<IGroupByWeekResponse>): IGroupByWeekResponse => {
            return {
              ...payload.data,
              weeklyResult: _.get(payload.data, 'weeklyResult').map(
                (weeklyRows: IGroupByWeekTableResult): IGroupByWeekTableResult => {
                  return this.oeeAdherenceService.formatOeeAdherenceWeekData(weeklyRows);
                },
              ),
            };
          }),
          switchMap((payload: IGroupByWeekResponse) => {
            return of(new OeeAdherenceActions.OeeAdherenceWeekDataLoaded(payload));
          }),
          catchError((error) => {
            return of(new OeeAdherenceActions.WeekDataFetchError(error));
          }),
        );
      }),
      catchError((error) => {
        return of(new OeeAdherenceActions.WeekDataFetchError(error));
      }),
    ),
  );

  getOeeAdherenceWoData$ = createEffect(() =>
    this.actions$.pipe(
      ofType(OeeAdherenceActions.OEE_ADHERENCE_WO_DATA_LOADING),
      switchMap((objectData: OeeAdherenceActions.OeeAdherenceWoDataLoading) => {
        const httpParams: HttpParams = new HttpParams()
          .set('sites', String(objectData.params.sites))
          .set('lines', String(objectData.params.lines))
          .set('start', objectData.params.dateRange.startDate.format(mysqlDateFormat))
          .set('end', objectData.params.dateRange.endDate.format(mysqlDateFormat))
          .set('productFamilies', String(objectData.params.productFamilies))
          .set('isOeeWoData', true);
        this.store.dispatch(new AppActions.ShowLoader());

        return this.oeeAdherenceService.getOeeAdherenceWoData(httpParams).pipe(
          map((payload: GetManyResponseInterface<IWoAdherenceOeeWithinIntervalResponse>) => {
            return _.get(payload.data, 'woTableData.data').map(
              (row: IWoAdherenceOeeWithinIntervalResponse): IWoAdherenceOeeWithinIntervalResponse => {
                return this.oeeAdherenceService.formatOeeAdherenceWoData(row);
              },
            );
          }),
          switchMap((payload: IWoAdherenceOeeWithinIntervalResponse[]) => {
            return of(new OeeAdherenceActions.OeeAdherenceWoDataLoaded(payload));
          }),
          catchError((error) => {
            return of(new OeeAdherenceActions.WoDataFetchError(error), new AppActions.HideLoader());
          }),
        );
      }),
      catchError((error) => {
        return of(new OeeAdherenceActions.WoDataFetchError(error), new AppActions.HideLoader());
      }),
    ),
  );
}
