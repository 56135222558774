<a
  class="d-block f-16 light ripple top-menu-item f-w-400 padding-right-only"
  (click)="onToggleListItem()"
  href="javascript:"
>
  <em
    *ngIf="(localStore.error$ | async) === true"
    class="notification-badge fas fa-exclamation-circle text-danger bg-white rounded-circle position-absolute ml-2 mt-2"
    ngbTooltip="{{ 'notificationFeed.message.error' | translate }}"
  ></em>
  <div
    *ngIf="(localStore.unopenedNotifications$ | async)?.length as unopenedNotificationCount"
    class="badge notification-count"
    [class.f-10]="unopenedNotificationCount > badgeMaxNumber"
  >
    {{ unopenedNotificationCount > badgeMaxNumber ? badgeMaxNumber + '+' : unopenedNotificationCount }}
  </div>
  <em class="fas fa-bell f-20 icon-font-size"></em>
  <em class="sub-icon feather icon-chevron-down sub-icon-padding-left"></em>
</a>

<section
  [@notificationBottom]="animationState"
  class="router-dropdown show-notification notification-sheet"
  [ngClass]="animationClass"
>
  <ng-container *ngIf="(localStore.error$ | async) !== true; else error">
    <div class="row px-3 pt-3 mx-0">
      <div>
        <h5>{{ 'notificationFeed.title' | translate }}</h5>
      </div>
    </div>
    <hr />
    <ng-container>
      <div class="d-flex flex-wrap justify-content-between px-3 text-muted">
        <scw-mat-checkbox
          class="mb-2"
          labelPosition="after"
          size="sm"
          [inputModel]="localStore.onlyShowUnread$ | async"
          (inputModelChange)="localStore.setOnlyShowUnread($event)"
        >
          <span class="f-12">{{ 'notificationFeed.filter.unread' | translate }}</span>
        </scw-mat-checkbox>
        <scw-mat-button
          [className]="['mb-2', 'text-button']"
          [hidden]="!(localStore.unreadNotificationsOfVisibleNotifications$ | async)?.length"
          type="ghost"
          size="xs"
          (onClick)="localStore.markAllAsRead()"
        >
          <span class="align-top font-weight-normal text-muted">
            {{ 'notificationFeed.action.markAllAsRead' | translate }}
          </span>
        </scw-mat-button>
      </div>
      <div *ngIf="(localStore.visibleNotifications$ | async)?.length; else empty" class="scroll-area">
        <ng-container *ngFor="let section of sections">
          <ng-container *ngIf="section.notifications$ | async as notifications">
            <section [hidden]="!notifications.length">
              <h6
                class="px-3 mt-3 pb-2 text-muted text-uppercase section-header"
                lang="{{ localStore.userLanguage$ | async }}"
              >
                {{ section.titleKey | translate }}
              </h6>
              <article
                *ngFor="let notificationFeedItem of notifications; trackBy: trackByDocId"
                (click)="onItemClicked(notificationFeedItem.docId)"
              >
                <app-notification-feed-item
                  [item]="notificationFeedItem"
                  [timeOnly]="section.timeOnly"
                  (setRead)="localStore.setRead(notificationFeedItem, $event)"
                ></app-notification-feed-item>
              </article>
            </section>
          </ng-container>
        </ng-container>
      </div>
    </ng-container>
    <div
      *ngIf="localStore.showLoadMoreButton && (localStore.onlyShowUnread$ | async) === false"
      class="my-2 text-center"
    >
      <scw-mat-button type="standard" size="sm" (click)="localStore.loadMoreNotifications($event)">
        {{ 'filterCard.dropdown.loadMore' | translate }}
      </scw-mat-button>
    </div>
  </ng-container>
  <ng-container
    *ngIf="
      Notification &&
      Notification.permission !== EPushNotificationStatus.granted &&
      (localStore.error$ | async) !== true
    "
  >
    <hr class="mt-0 mb-1" />
    <div class="pr-2 p-b-6 pl-3 f-10">
      <ng-container *ngIf="Notification.permission === EPushNotificationStatus.default">
        <em class="fas fa-info-circle alert-warning"></em>
        <span class="btn-link cursor-pointer f-10" (click)="openPushNotificationPermissionModal()">
          {{ 'pushNotification.permission.notificationFeed.defaultInfo' | translate }}
        </span>
        <br />
        <strong>
          {{ 'pushNotification.permission.notificationFeed.notAffected' | translate }}
        </strong>
      </ng-container>
      <ng-container *ngIf="Notification.permission === EPushNotificationStatus.denied">
        <em class="fas fa-exclamation-circle alert-danger"></em>
        {{ 'pushNotification.permission.notificationFeed.blockedInfo' | translate }}
        <strong>
          {{ 'pushNotification.permission.notificationFeed.notAffected' | translate }}
        </strong>
      </ng-container>
    </div>
  </ng-container>
</section>

<ng-template #empty>
  <div class="row my-3 mx-3">
    <div class="col">
      <p class="text-center">
        <span class="fa fa-bell oee-orange h4" aria-hidden></span>
        <br aria-hidden />
        <span class="gray-text">
          {{ 'notificationFeed.message.empty' | translate : { numDays: service.numberOfDaysOfHistory } }}
        </span>
      </p>
    </div>
  </div>
</ng-template>

<ng-template #error>
  <div class="row mt-3" aria-hidden>
    <div class="col text-center">
      <em class="fas fa-exclamation-circle text-danger h3"></em>
    </div>
  </div>
  <div class="row mb-3 px-4">
    <div class="col text-center">
      <p class="h6">{{ 'notificationFeed.message.error' | translate }}</p>
    </div>
  </div>
</ng-template>
