<progress-bar-metric
  [actualValue]="actualValue | toFixed : siteDecimalScaleLimit"
  [actualValueUnit]="actualValueUnit"
  [expectedValue]="expectedValue | toFixed : siteDecimalScaleLimit"
  [expectedValueUnit]="expectedValueUnit"
  [progressBarTitle]="progressBarTitle"
  [progressBarRateCapped]="progressBarRateCapped"
  [actualValueClass]="actualValueClass"
  [progressBarClass]="progressBarClass"
  [progressBarRate]="progressBarRate"
  [icons]="iconClasses"
  [titleBracketInfo]="titleBracketInfo"
>
</progress-bar-metric>
