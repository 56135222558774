<div class="menu-wrapper" role="navigation">
  <ul class="nav" role="menubar">
    <li *ngFor="let menuItem of newMenuItems" (mouseover)="mouseOver(menuItem.key)">
      <span
        [class.navigation-link]="
          (!megaMenuKeys.includes(menuItem.key) && !singleRowMenuKeys.includes(menuItem.key)) || menuItem.link !== '#'
        "
        (click)="
          ((!megaMenuKeys.includes(menuItem.key) && !singleRowMenuKeys.includes(menuItem.key)) ||
            menuItem.link !== '#') &&
            goLink(menuItem)
        "
      >
        <em [ngClass]="menuItem.icon"></em> {{ menuItem.name }}
        <ng-template [ngIf]="menuItem.isUpgradeBadgeEnabled">
          <div class="badge upgrade">
            {{ 'general.upgrade' | translate }}
          </div>
        </ng-template>
      </span>
      <ng-template [ngIf]="megaMenuKeys.includes(menuItem.key)">
        <div class="mega-menu" aria-hidden="true" role="menu">
          <div class="mega-menu-inner_container">
            <i class="mega-menu-icon" id="mega-menu-icon-{{ menuItem.key }}" [ngClass]="menuItem.icon"></i>
            <div class="row" id="mega-menu-{{ menuItem.key }}">
              <div *ngFor="let megaMenuItem of menuItem.main[0].children" class="nav-column col-3">
                <ng-template
                  [ngIf]="megaMenuItem.key === customReportsKey || megaMenuItem.key === myReportsKey"
                  [ngIfElse]="megaMenuLayout"
                >
                  <div class="button-menu" (click)="goLink(megaMenuItem)">
                    <span>{{ megaMenuItem.name }}</span>
                  </div>
                </ng-template>
                <ng-template #megaMenuLayout>
                  <div class="mega-menu-item">
                    <h3 [class.disabled-menu-item]="megaMenuItem.inProgress">
                      {{ megaMenuItem.name }}
                    </h3>
                    <ng-template [ngIf]="megaMenuItem.inProgress">
                      <div class="badge soon">
                        {{ 'general.soon' | translate }}
                      </div>
                    </ng-template>
                    <ng-template [ngIf]="megaMenuItem.isNew">
                      <div class="badge new">
                        {{ 'general.new' | translate }}
                      </div>
                    </ng-template>
                    <ng-template [ngIf]="megaMenuItem.isUpgradeBadgeEnabled">
                      <div class="badge upgrade">
                        {{ 'general.upgrade' | translate }}
                      </div>
                    </ng-template>
                  </div>
                  <ul>
                    <li *ngFor="let megaMenuItemLink of megaMenuItem.children">
                      <div
                        class="mega-menu-item"
                        [class.disabled-menu-item]="megaMenuItemLink.inProgress"
                        (click)="goLink(megaMenuItemLink)"
                      >
                        {{ megaMenuItemLink.name }}
                        <ng-template [ngIf]="megaMenuItemLink.inProgress">
                          <div class="badge soon">
                            {{ 'general.soon' | translate }}
                          </div>
                        </ng-template>
                        <ng-template [ngIf]="megaMenuItemLink.isNew">
                          <div class="badge new">
                            {{ 'general.new' | translate }}
                          </div>
                        </ng-template>
                        <ng-template [ngIf]="megaMenuItemLink.isUpgradeBadgeEnabled">
                          <div class="badge upgrade">
                            {{ 'general.upgrade' | translate }}
                          </div>
                        </ng-template>
                      </div>
                    </li>
                  </ul>
                </ng-template>
              </div>
            </div>
          </div>
        </div>
      </ng-template>
      <ng-template [ngIf]="singleRowMenuKeys.includes(menuItem.key)">
        <div class="single-line-menu" aria-hidden="true" role="menu">
          <div class="single-line-menu-inner_container">
            <div
              [ngClass]="menuItem.key === 'navigation_dashboards' ? 'row col-12' : 'single-line-menu-row-alignment'"
              class="single-line-menu_row"
            >
              <div
                *ngFor="let singleRowMenuItem of menuItem.main[0].children"
                [ngClass]="{ 'col-3 d-flex justify-content-center': menuItem.key === 'navigation_dashboards' }"
                class="single-line-menu_col"
              >
                <h3 (click)="goLink(singleRowMenuItem)">
                  {{ singleRowMenuItem.name }}
                  <ng-template [ngIf]="singleRowMenuItem.inProgress">
                    <div class="badge soon" [class.soon]="singleRowMenuItem.inProgress">
                      {{ 'general.soon' | translate }}
                    </div>
                  </ng-template>
                  <ng-template [ngIf]="singleRowMenuItem.isNew">
                    <div class="badge new">
                      {{ 'general.new' | translate }}
                    </div>
                  </ng-template>
                  <ng-template [ngIf]="singleRowMenuItem.isUpgradeBadgeEnabled">
                    <div class="badge upgrade">
                      {{ 'general.upgrade' | translate }}
                    </div>
                  </ng-template>
                </h3>
              </div>
            </div>
          </div>
        </div>
      </ng-template>
    </li>
  </ul>
</div>
<div class="mobile-menu">
  <div *ngFor="let asideItems of newMenuItems" class="d-color">
    <ul
      class="menu-main-item pcoded-item pcoded-left-item"
      [attr.item-border]="itemBorder"
      [attr.item-border-style]="itemBorderStyle"
      [attr.subitem-border]="subItemBorder"
      *ngFor="let asideItem of asideItems.main"
      appAccordionLink
      group="{{ asideItem.state }}"
    >
      <li
        [routerLinkActive]="['active']"
        *ngIf="asideItem.type === 'external'"
        appAccordionLink
        group="{{ asideItem.state }}"
      >
        <a
          [routerLinkActive]="['active']"
          class="ripple"
          href="../../../../../index.php"
          target="{{ asideItem.target ? '_blank' : '_self' }}"
          appAccordionToggle
        >
          <span class="pcoded-micon">
            <em class="{{ asideItem.icon }}"></em>
            <strong>{{ asideItem.short_label }}</strong></span
          >
          <span class="pcoded-mtext">{{ asideItem.name }}</span>
          <span *ngFor="let asideBadge of asideItem.badge" class="pcoded-badge label label-{{ asideBadge.type }}">{{
            asideBadge.value
          }}</span>
          <span class="pcoded-mcaret"></span>
        </a>
      </li>
      <li
        [routerLinkActive]="['active']"
        *ngIf="asideItem.type === 'link'"
        appAccordionLink
        group="{{ asideItem.state }}"
      >
        <a
          [routerLinkActive]="['active']"
          class="ripple"
          (click)="!asideItem.inProgress && goLink(asideItem)"
          appAccordionToggle
          *ngIf="asideItem.main_state; else mainContent"
        >
          <span class="pcoded-micon">
            <em class="{{ asideItem.icon }}"></em>
            <strong>{{ asideItem.short_label }}</strong>
          </span>
          <span class="pcoded-mtext">{{ asideItem.name }}</span>
          <span *ngIf="asideItem.inProgress" class="nav-in-progress">
            <br />
            ({{ 'general.inProgress' | translate }})
          </span>
          <span *ngFor="let asideBadge of asideItem.badge" class="pcoded-badge label label-{{ asideBadge.type }}">{{
            asideBadge.value
          }}</span>
          <span class="pcoded-mcaret"></span>
        </a>
        <ng-template #mainContent>
          <a
            [routerLinkActive]="['active']"
            class="ripple"
            (click)="!asideItem.inProgress && goLink(asideItem)"
            appAccordionToggle
          >
            <span class="pcoded-micon">
              <em class="{{ asideItem.icon }}"></em>
              <strong>{{ asideItem.short_label }}</strong>
            </span>
            <span class="pcoded-mtext">{{ asideItem.name }}</span>
            <span *ngIf="asideItem.inProgress" class="nav-in-progress">
              <br />
              ({{ 'general.inProgress' | translate }})
            </span>
            <span *ngFor="let asideBadge of asideItem.badge" class="pcoded-badge label label-{{ asideBadge.type }}">{{
              asideBadge.value
            }}</span>
            <span class="pcoded-mcaret"></span>
          </a>
        </ng-template>
      </li>
      <li
        (mouseleave)="fireClickLeave($event)"
        [routerLinkActive]="['active']"
        *ngIf="asideItem.type === 'sub'"
        class="pcoded-hasmenu"
        [attr.dropdown-icon]="dropDownIcon"
        [attr.subitem-icon]="subItemIcon"
        appAccordionLink
        group="{{ asideItem.state }}"
      >
        <a
          (mouseover)="fireClick($event)"
          class="main-click-trigger"
          [routerLinkActive]="['active']"
          href="javascript:;"
          appAccordionToggle
        >
          <div class="ripple menu-ripple"></div>
          <span class="pcoded-micon">
            <em class="{{ asideItem.icon }}"></em>
            <strong>{{ asideItem.short_label }}</strong>
          </span>
          <span class="pcoded-mtext">{{ asideItem.name }}</span>
          <span *ngIf="asideItem.inProgress" class="nav-in-progress">
            <br />
            ({{ 'general.inProgress' | translate }})
          </span>
          <span *ngFor="let asideBadge of asideItem.badge" class="pcoded-badge label label-{{ asideBadge.type }}">{{
            asideBadge.value
          }}</span>
          <span class="pcoded-mcaret"></span>
        </a>
        <ul class="pcoded-submenu">
          <ng-template ngFor let-asideChildren [ngForOf]="asideItem.children">
            <li [routerLinkActive]="['active']" *ngIf="asideChildren.type !== 'sub'">
              <a
                [routerLinkActive]="['active']"
                href="../../../../../index.php"
                target="{{ asideChildren.target ? '_blank' : '_self' }}"
                *ngIf="asideChildren.type === 'external'; else mainContentExternal"
              >
                <div class="ripple menu-ripple"></div>
                <span class="pcoded-micon">
                  <em class="icon-angle-right"></em>
                </span>
                <span class="pcoded-mtext">{{ asideChildren.name }}</span>
                <span *ngIf="asideChildren.inProgress" class="nav-in-progress">
                  <br />
                  ({{ 'general.inProgress' | translate }})
                </span>
                <span
                  *ngFor="let asideChildrenBadge of asideChildren.badge"
                  class="pcoded-badge label label-{{ asideChildrenBadge.type }}"
                  >{{ asideChildrenBadge.value }}</span
                >
                <span class="pcoded-mcaret"></span>
              </a>
              <ng-template #mainContentExternal>
                <a [routerLinkActive]="['active']" (click)="!asideChildren.inProgress && goLink(asideChildren)">
                  <div class="ripple menu-ripple"></div>
                  <span class="pcoded-micon"><em class="icon-angle-right"></em></span>
                  <span class="pcoded-mtext">{{ asideChildren.name }}</span>
                  <span *ngIf="asideChildren.inProgress" class="nav-in-progress">
                    <br />
                    ({{ 'general.inProgress' | translate }})
                  </span>
                  <span
                    *ngFor="let asideChildrenBadge of asideChildren.badge"
                    class="pcoded-badge label label-{{ asideChildrenBadge.type }}"
                    >{{ asideChildrenBadge.value }}</span
                  >
                  <span class="pcoded-mcaret"></span>
                </a>
              </ng-template>
            </li>

            <li
              (mouseleave)="fireClickLeave($event)"
              [routerLinkActive]="['active']"
              class="pcoded-hasmenu"
              [attr.dropdown-icon]="dropDownIcon"
              [attr.subitem-icon]="subItemIcon"
              *ngIf="asideChildren.type === 'sub'"
              appAccordionLink
              group="sub-toggled"
            >
              <a (mouseover)="fireClick($event)" [routerLinkActive]="['active']" href="javascript:;" appAccordionToggle>
                <div class="ripple menu-ripple"></div>
                <span class="pcoded-micon"><em class="icon-direction-alt"></em></span>
                <span class="pcoded-mtext">{{ asideChildren.name }}</span>
                <span *ngIf="asideChildren.inProgress" class="nav-in-progress">
                  <br />
                  ({{ 'general.inProgress' | translate }})
                </span>
                <span
                  *ngFor="let asideChildrenBadge of asideChildren.badge"
                  class="pcoded-badge label label-{{ asideChildrenBadge.type }}"
                  >{{ asideChildrenBadge.value }}</span
                >
                <span class="pcoded-mcaret"></span>
              </a>
              <ul class="pcoded-submenu">
                <ng-template ngFor let-asideChildrenSub [ngForOf]="asideChildren.children">
                  <li [routerLinkActive]="['active']" *ngIf="asideChildrenSub.type !== 'sub'">
                    <a
                      [routerLinkActive]="['active']"
                      class="inprogress"
                      (click)="!asideChildrenSub.inProgress && goLink(asideChildrenSub)"
                    >
                      <div class="ripple menu-ripple"></div>
                      <span class="pcoded-micon">
                        <em class="icon-angle-right"></em>
                      </span>
                      <span class="pcoded-mtext">{{ asideChildrenSub.name }}</span>
                      <span *ngIf="asideChildrenSub.inProgress" class="nav-in-progress">
                        <br />
                        ({{ 'general.inProgress' | translate }})
                      </span>
                      <span
                        *ngFor="let asideChildrenSubBadge of asideChildrenSub.badge"
                        class="pcoded-badge label label-{{ asideChildrenSubBadge.type }}"
                        >{{ asideChildrenSubBadge.value }}</span
                      >
                      <span class="pcoded-mcaret"></span>
                    </a>
                  </li>
                  <li
                    (mouseleave)="fireClickLeave($event)"
                    [routerLinkActive]="['active']"
                    class="pcoded-hasmenu"
                    [attr.dropdown-icon]="dropDownIcon"
                    [attr.subitem-icon]="subItemIcon"
                    *ngIf="asideChildrenSub.type === 'sub'"
                    appAccordionLink
                    group="sub-end-toggled"
                  >
                    <a
                      (mouseover)="fireClick($event)"
                      [routerLinkActive]="['active']"
                      href="javascript:;"
                      appAccordionToggle
                    >
                      <div class="ripple menu-ripple"></div>
                      <span class="pcoded-micon">
                        <em class="icon-direction-alt"></em>
                      </span>
                      <span class="pcoded-mtext"> {{ asideChildrenSub.name }}</span>
                      <span *ngIf="asideChildrenSub.inProgress" class="nav-in-progress">
                        <br />
                        ({{ 'general.inProgress' | translate }})
                      </span>
                      <span
                        *ngFor="let asideChildrenSub of asideChildrenSub.badge"
                        class="pcoded-badge label label-{{ asideChildrenSub.type }}"
                        >{{ asideChildrenSub.value }}</span
                      >
                      <span class="pcoded-mcaret"></span>
                    </a>
                    <ul class="pcoded-submenu">
                      <ng-template ngFor let-asideChildrenSubEnd [ngForOf]="asideChildrenSub.children">
                        <li [routerLinkActive]="['active']">
                          <a
                            [routerLinkActive]="['active']"
                            (click)="!asideChildrenSubEnd.inProgress && goLink(asideChildrenSubEnd)"
                          >
                            <div class="ripple menu-ripple"></div>
                            <span class="pcoded-micon">
                              <em class="icon-angle-right"></em>
                            </span>
                            <span class="pcoded-mtext">{{ asideChildrenSubEnd.name }}</span>
                            <span *ngIf="asideChildrenSubEnd.inProgress" class="nav-in-progress">
                              <br />
                              ({{ 'general.inProgress' | translate }})
                            </span>
                            <span
                              *ngFor="let asideChildrenSubBadgeEnd of asideChildrenSubEnd.badge"
                              class="pcoded-badge label label-{{ asideChildrenSubBadgeEnd.type }}"
                              >{{ asideChildrenSubBadgeEnd.value }}</span
                            >
                            <span class="pcoded-mcaret"></span>
                          </a>
                        </li>
                      </ng-template>
                    </ul>
                  </li>
                </ng-template>
              </ul>
            </li>
          </ng-template>
        </ul>
      </li>
    </ul>
  </div>
</div>
