import { Inject, Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import {
  IDeleteFileByFilePathParams,
  IDeleteFileParams,
  IDeleteFileResponse,
  IGetFileParams,
  IGetFileResponse,
  IUploadFileParams,
  IUploadFileResponse,
} from './file-upload.model';
import {
  BaseCrudResponse,
  BaseOneResponseInterface,
  BulkResponseDataInterface,
} from '../../shared/model/interface/crud-response-interface.model';
import { FileHelperService } from '../../shared/helper/file-helper.service';

@Injectable({
  providedIn: 'root',
})
export class FileUploadService {
  constructor(
    @Inject('FILE_UPLOAD_URL') private readonly fileUploadUrl: string,
    @Inject('API_BASE_URL') private readonly baseUrl: string,
    private readonly http: HttpClient,
    private readonly fileHelperService: FileHelperService,
  ) {}

  public getOneFile(params: IGetFileParams): Observable<BaseOneResponseInterface<IGetFileResponse>> {
    return this.http.post<BaseOneResponseInterface<IGetFileResponse>>(`${this.fileUploadUrl}file/${params.fileId}`, {
      entityName: params.entityName,
      siteId: params.siteId,
    });
  }
  public getFileByFolderId(params: IGetFileParams): Observable<BaseOneResponseInterface<IGetFileResponse[]>> {
    return this.http.post<BaseOneResponseInterface<IGetFileResponse[]>>(
      `${this.fileUploadUrl}file/folder/${params.folderId}`,
      {
        isThumbnail: params.isThumbnail,
        entityName: params.entityName,
        siteId: params.siteId,
      },
    );
  }

  public getFiles(params: IGetFileParams[]): Observable<BaseOneResponseInterface<IGetFileResponse[]>> {
    return this.http.post<BaseOneResponseInterface<IGetFileResponse[]>>(`${this.fileUploadUrl}file`, [...params]);
  }

  public uploadManyFile(params: IUploadFileParams): Observable<BaseOneResponseInterface<IUploadFileResponse[]>> {
    const formData: FormData = this.fileHelperService.getFilesFormData(params.files);

    formData.append('siteId', params.siteId.toString());
    formData.append('entityName', params.entityName.toString());

    if (params.folderId) {
      formData.append('folderId', params.folderId.toString());
    }

    return this.http.post<BaseOneResponseInterface<IUploadFileResponse[]>>(
      `${this.fileUploadUrl}file/bulk/upload`,
      formData,
    );
  }

  public deleteFile(params: IDeleteFileParams): Observable<IDeleteFileResponse> {
    return this.http.delete<IDeleteFileResponse>(`${this.fileUploadUrl}file/delete`, {
      body: {
        ...params,
      },
    });
  }

  public deleteManyFile(params: IDeleteFileParams[]): Observable<BaseCrudResponse> {
    return this.http.delete<BaseCrudResponse>(`${this.fileUploadUrl}file/bulk/delete`, {
      body: {
        files: params,
      },
    });
  }

  public deleteManyFileByFilePath(dto: IDeleteFileByFilePathParams): Observable<BulkResponseDataInterface> {
    return this.http.delete<BulkResponseDataInterface>(`${this.baseUrl}/files/delete-files-by-file-path`, {
      body: dto,
      headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
    });
  }
}
