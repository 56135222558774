import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, switchMap } from 'rxjs/operators';
import * as ObjectActions from './performance-deep-dive.actions';
import { of } from 'rxjs';
import { PerformanceDeepDiveService } from './performance-deep-dive.service';
import { BaseOneResponseInterface } from '../../../shared/model/interface/crud-response-interface.model';
import { ActivityLogsResponseInterface, IActivityLog } from '../root-cause-analysis/root-cause-analysis.model';
import {
  IProductionPerformanceDeepDiveResponse,
  IProductionPerformanceDeepDiveShiftDayResponse, IRootCauseAnalysisData,
} from './performance-deep-dive.model';
import { EntityTranslatorService } from 'src/app/shared/service/entity-translator/entity-translator.service';
import { ActivityLogsService } from '../activity-logs/activity-logs.service';

@Injectable()
export class PerformanceDeepDiveEffects {
  constructor(
    private readonly actions$: Actions,
    public service: PerformanceDeepDiveService,
    private readonly activityLogsService: ActivityLogsService,
    private readonly entityTranslatorService: EntityTranslatorService,
  ) {}

  getActivityLogs = createEffect(() =>
    this.actions$.pipe(
      ofType(ObjectActions.PerformanceDeepDiveActionTypes.PerformanceDeepDiveActivityLogsLoading),
      switchMap((objectData: ObjectActions.PerformanceDeepDiveActivityLogsLoading) => {
        return this.service.getActivityLogs(objectData?.params).pipe(
          switchMap((response: BaseOneResponseInterface<ActivityLogsResponseInterface>) => {
            response.data.rows.forEach((activityLog: IActivityLog) => {
              this.entityTranslatorService.translate(activityLog);
              this.activityLogsService.setActivityLogProductInfo(activityLog);
              this.activityLogsService.setActivityLogTaskName(activityLog);
            });
            return of(new ObjectActions.PerformanceDeepDiveActivityLogsLoaded(response.data));
          }),
          catchError((err) => {
            return of(new ObjectActions.FetchError(err));
          }),
        );
      }),
      catchError((err) => {
        return of(new ObjectActions.FetchError(err));
      }),
    ),
  );

  getProductionDeepDiveData = createEffect(() =>
    this.actions$.pipe(
      ofType(ObjectActions.PerformanceDeepDiveActionTypes.ProductionPerformanceDeepDiveDataLoading),
      switchMap((objectData: ObjectActions.ProductionPerformanceDeepDiveDataLoading) => {
        return this.service.getProductionPerformanceDeepDiveData(objectData.lineId, objectData?.params).pipe(
          switchMap((response: BaseOneResponseInterface<IProductionPerformanceDeepDiveResponse>) => {
            response.data.rootCauseData.forEach((rootCauseAnalysisData: IRootCauseAnalysisData) => this.entityTranslatorService.translate(rootCauseAnalysisData));
            return of(new ObjectActions.ProductionPerformanceDeepDiveDataLoaded(response.data));
          }),
          catchError((err) => {
            return of(new ObjectActions.FetchError(err));
          }),
        );
      }),
      catchError((err) => {
        return of(new ObjectActions.FetchError(err));
      }),
    ),
  );

  getProductionDeepDiveShiftDayData = createEffect(() =>
    this.actions$.pipe(
      ofType(ObjectActions.PerformanceDeepDiveActionTypes.ProductionPerformanceDeepDiveShiftDayDataLoading),
      switchMap((objectData: ObjectActions.ProductionPerformanceDeepDiveShiftDayDataLoading) => {
        return this.service.getProductionPerformanceDeepDiveShiftDayData(objectData.lineId, objectData?.params).pipe(
          switchMap((response: BaseOneResponseInterface<IProductionPerformanceDeepDiveShiftDayResponse[]>) => {
            return of(new ObjectActions.ProductionPerformanceDeepDiveShiftDayDataLoaded(response.data));
          }),
          catchError((err) => {
            return of(new ObjectActions.FetchError(err));
          }),
        );
      }),
      catchError((err) => {
        return of(new ObjectActions.FetchError(err));
      }),
    ),
  );
}
