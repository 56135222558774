import { Action } from '@ngrx/store';
import { IProductionViewData } from './production-view.model';
import { TagInterface } from '../../settings/tags/tags.model';

export const GET_PRODUCTION_VIEW_DATA = '[PRODUCTION VIEW] GET PRODUCTION VIEW DATA';
export const GET_PRODUCTION_VIEW_DATA_COMPLETED = '[PRODUCTION VIEW] GET PRODUCTION VIEW DATA COMPLETED';
export const GET_TAGS_DATA = '[PRODUCTION VIEW] GET TAGS DATA';
export const GET_TAGS_DATA_COMPLETED = '[PRODUCTION VIEW] GET TAGS DATA COMPLETED';
export const PRODUCTION_VIEW_FETCH_ERROR = '[PRODUCTION VIEW] Fetch Error';

export class GetProductionViewData implements Action {
  readonly type = GET_PRODUCTION_VIEW_DATA;

  constructor(public lineId: number) {}
}

export class GetProductionViewDataCompleted implements Action {
  readonly type = GET_PRODUCTION_VIEW_DATA_COMPLETED;

  constructor(public data: IProductionViewData) {}
}

export class GetTagsData implements Action {
  readonly type = GET_TAGS_DATA;

  constructor(public siteIds: number[]) {}
}

export class GetTagsDataCompleted implements Action {
  readonly type = GET_TAGS_DATA_COMPLETED;

  constructor(public data: TagInterface[]) {}
}

export class ProductionViewFetchError implements Action {
  readonly type = PRODUCTION_VIEW_FETCH_ERROR;

  constructor(public payload: any[]) {}
}
export type ProductionPitchViewActions =
  | GetProductionViewData
  | GetProductionViewDataCompleted
  | GetTagsData
  | GetTagsDataCompleted
  | ProductionViewFetchError;
