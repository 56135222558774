import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { HelperService } from '../../../../shared/service/helper.service';
import { TranslateService } from '@ngx-translate/core';
import { Store } from '@ngrx/store';
import { OeeAppState } from '../../../../store/oee.reducer';
import { Subscription } from 'rxjs';
import { DecimalHelper } from '../../../../shared/helper/decimal/decimal-helper';
import { DECIMAL_DEFAULT_SCALE_LIMIT } from '../../../../../constants';
import { PROPERTIES } from '../home-metrics.component';
import { IActivitiesTotalDuration } from '../../../settings/home-page-display-settings/home-metrics/home-metrics.model';
import { Line } from '../../../../store/line/model';
import { EUnitOfTime } from '../../../settings/home-page-display-settings/home-page-display-settings.constants';
import * as _ from 'lodash';

@Component({
  selector: 'activities-total-duration-metric',
  templateUrl: './activities-total-duration-metric.component.html',
})
export class ActivitiesTotalDurationMetricComponent implements OnInit, OnDestroy {
  private readonly subscriptions: Subscription[] = [];
  public properties: IActivitiesTotalDuration = null;
  public runTimeDuration: string = null;
  public downTimeUnplannedDuration: string = null;
  public downTimePlannedDuration: string = null;
  public idleTimeDuration: string = null;
  public unit: number = null;

  constructor(
    public helperService: HelperService,
    public decimalHelper: DecimalHelper,
    private translate: TranslateService,
    private store: Store<OeeAppState>,
    @Inject(PROPERTIES) private readonly metricProperties: IActivitiesTotalDuration,
  ) {
    this.properties = metricProperties;
  }

  public ngOnInit(): void {
    this.subscriptions.push(
      this.store.select('line').subscribe((state: Line) => {
        const totalDownTimeDuration: string = _.isNil(state.quantities.downDuration)
          ? '0'
          : this.decimalHelper.toFixedValue(
              HelperService.cloneDeep(state.quantities.downDuration),
              DECIMAL_DEFAULT_SCALE_LIMIT,
              false,
            );

        const downTimeUnplannedDuration: string = _.isNil(state.quantities.unplannedDownTimeDuration)
          ? '0'
          : this.decimalHelper.divide(
              HelperService.cloneDeep(state.quantities.unplannedDownTimeDuration.toString()),
              '60',
              DECIMAL_DEFAULT_SCALE_LIMIT,
            );

        const downTimePlannedDuration: string = this.decimalHelper.subtract(
          totalDownTimeDuration,
          downTimeUnplannedDuration,
        );

        const totalDuration: string = this.decimalHelper.multiply(state.quantities.totalDuration, '60');

        this.runTimeDuration = this.durationCalculationAccordingToUnitOfTime(
          state.quantities.runDuration,
          totalDuration,
          this.properties.unitOfTime,
        );

        this.downTimePlannedDuration = this.durationCalculationAccordingToUnitOfTime(
          downTimePlannedDuration,
          totalDuration,
          this.properties.unitOfTime,
        );

        this.downTimeUnplannedDuration = this.durationCalculationAccordingToUnitOfTime(
          downTimeUnplannedDuration,
          totalDuration,
          this.properties.unitOfTime,
        );

        this.idleTimeDuration = this.durationCalculationAccordingToUnitOfTime(
          state.quantities.idleDuration,
          totalDuration,
          this.properties.unitOfTime,
        );
      }),
    );
  }

  public durationCalculationAccordingToUnitOfTime(
    duration: string,
    totalDuration: string,
    unitOfTime: EUnitOfTime,
  ): string {
    const percentage: string = unitOfTime === EUnitOfTime.PERCENTAGE ? '%' : '';
    let clonedDuration = _.isNil(duration) ? '0' : HelperService.cloneDeep(duration);

    switch (unitOfTime) {
      case EUnitOfTime.PERCENTAGE:
        clonedDuration = this.decimalHelper.divide(clonedDuration, totalDuration);
        clonedDuration = this.decimalHelper.multiply(clonedDuration, '100');
        break;
      case EUnitOfTime.HOUR:
        clonedDuration = this.decimalHelper.divide(clonedDuration, '60');
        break;
    }

    return this.decimalHelper.toFixedValue(clonedDuration, DECIMAL_DEFAULT_SCALE_LIMIT) + percentage;
  }

  public ngOnDestroy(): void {
    this.subscriptions.forEach((value: Subscription) => {
      value.unsubscribe();
    });
  }
}
