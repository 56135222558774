import { createAction, props } from '@ngrx/store';
import {
  TFetchWorkOrderSetParameters,
  TFetchWorkOrdersForSchedulerRules,
  TFetchWorkOrdersParameters,
  TGetManyWorkOrders,
  TGetManyWorkOrderSet,
} from './scheduler-work-order-set.model';
import { FormattedWorkOrdersResponseInterface } from '../work-order-schedule/work-order-schedule.model';

export const fetchWorkOrderSet = createAction(
  '[Work Order Set] Fetch Work Order Set',
  props<TFetchWorkOrderSetParameters>(),
);

export const fetchWorkOrderSetSuccess = createAction(
  '[Work Order Set] Fetch Work Order Set Success',
  props<TGetManyWorkOrderSet>(),
);

export const fetchWorkOrdersForSchedulerRules = createAction(
  '[Work Order Set] Fetch Work Orders For Scheduler Rules',
  props<TFetchWorkOrdersForSchedulerRules>(),
);

export const fetchWorkOrdersForSchedulerRulesSuccess = createAction(
  '[Work Order Set] Fetch Work Orders For Scheduler Rules Success',
  props<TGetManyWorkOrderSet>(),
);

export const fetchWorkOrderSetError = createAction(
  '[Work Order Set] Fetch Work Order Set Error',
  props<{ error: any }>(),
);

export const fetchWorkOrders = createAction('[Work Order Set] Fetch Work Orders', props<TFetchWorkOrdersParameters>());

export const fetchWorkOrdersSuccess = createAction(
  '[Work Order Set] Fetch Work Orders Success',
  props<FormattedWorkOrdersResponseInterface>(),
);

export const fetchWorkOrdersError = createAction('[Work Order Set] Fetch Work Orders Error', props<{ error: any }>());
