import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import * as oeeAppReducer from '../../oee.reducer';
import * as ObjectActions from '../deep-dive-analysis/deep-dive-analysis.actions';
import { catchError, switchMap } from 'rxjs/operators';
import * as AppActions from '../../app/actions';
import { of } from 'rxjs';
import { DeepDiveAnalysisService } from './deep-dive-analysis.service';
import {
  ChartValues,
  DeepDiveAnalysisResponse,
  ICommonOeeData,
  IFormattedOeeResult,
 IMonthlyOeeFilter, IOeeResponse,
} from './deep-dive-analysis.model';
import { DecimalHelper } from '../../../shared/helper/decimal/decimal-helper';
import {
  BaseOneResponseInterface,
  GetManyResponseInterface,
} from '../../../shared/model/interface/crud-response-interface.model';
import * as _ from 'lodash';

@Injectable()
export class DeepDiveAnalysisEffects {
  constructor(
    private readonly actions$: Actions,
    private readonly service: DeepDiveAnalysisService,
    private readonly store: Store<oeeAppReducer.OeeAppState>,
    private readonly decimalHelper: DecimalHelper,
  ) {}

  getDeepDiveAnalysisData = createEffect(() =>
    this.actions$.pipe(
      ofType(ObjectActions.DEEP_DIVE_DATA_LOADING),
      switchMap((payload: ObjectActions.DeepDiveDataLoading) => {
        if (payload.isShowLoader) {
          this.store.dispatch(new AppActions.ShowLoader());
        }

        const queryParams = this.service.formatDeepDiveAnalysisParams(payload.params);
        return this.service.fetchDeepDiveAnalysisData(queryParams).pipe(
          switchMap((response: BaseOneResponseInterface<DeepDiveAnalysisResponse>) => {
            if (_.isNil(response.data.oeeResult)) {
              return of(
                ...[
                  new ObjectActions.DeepDiveDataLoaded(null),
                  ...(payload.isShowLoader ? [new AppActions.HideLoader()] : []),
                ],
              );
            }

            const unplannedDt: string = this.decimalHelper.divide(
              String(response.data.oeeResult[ChartValues.totalDownTimeUnplannedDuration]),
              '60',
            );
            const loadedAction: Action = new ObjectActions.DeepDiveDataLoaded({
              ...response.data,
              oeeResult: {
                ...response.data.oeeResult,
                [ChartValues.totalRunTimeDuration]: String(response.data.oeeResult[ChartValues.totalRunTimeDuration]),
                [ChartValues.totalDownTimeDuration]: String(response.data.oeeResult[ChartValues.totalDownTimeDuration]),
                [ChartValues.totalDownTimeUnplannedDuration]: unplannedDt,
                [ChartValues.totalDownTimePlannedDuration]: this.decimalHelper.subtract(
                  String(response.data.oeeResult[ChartValues.totalDownTimeDuration]),
                  unplannedDt,
                ),
                [ChartValues.totalIdleTimeDuration]: String(response.data.oeeResult[ChartValues.totalIdleTimeDuration]),
              },
              weeklyResult: response.data.weeklyResult.map((weeklyResult: IOeeResponse): IFormattedOeeResult => {
                const unplannedDt: string = this.decimalHelper.divide(
                  String(weeklyResult[ChartValues.totalDownTimeUnplannedDuration]),
                  '60',
                );
                return {
                  ...weeklyResult,
                  [ChartValues.totalRunTimeDuration]: String(weeklyResult[ChartValues.totalRunTimeDuration]),
                  [ChartValues.totalDownTimeDuration]: String(weeklyResult[ChartValues.totalDownTimeDuration]),
                  [ChartValues.totalDownTimeUnplannedDuration]: unplannedDt,
                  [ChartValues.totalDownTimePlannedDuration]: this.decimalHelper.subtract(
                    String(weeklyResult[ChartValues.totalDownTimeDuration]),
                    unplannedDt,
                  ),
                  [ChartValues.totalIdleTimeDuration]: String(weeklyResult[ChartValues.totalIdleTimeDuration]),
                };
              }),
            });

            return of(...[loadedAction, ...(payload.isShowLoader ? [new AppActions.HideLoader()] : [])]);
          }),
          catchError((errorRes) => {
            return payload.isShowLoader
              ? of(new ObjectActions.FetchError(errorRes), new AppActions.HideLoader())
              : of(new ObjectActions.FetchError(errorRes));
          }),
        );
      }),
      catchError((errorRes) => {
        return of(new ObjectActions.FetchError(errorRes), new AppActions.HideLoader());
      }),
    ),
  );

  getMonthlyOeeResult = createEffect(() =>
    this.actions$.pipe(
      ofType(ObjectActions.MONTHLY_OEE_DATA_LOADING),
      switchMap((payload: ObjectActions.MonthlyOeeDataLoading) => {
        if (payload.isShowLoader) {
          this.store.dispatch(new AppActions.ShowLoader());
        }

        const body = this.service.formatMonthlyOeeRequestBody(payload.params);
        return this.service.fetchOeeResultByTimeSelection(body).pipe(
          switchMap((response: GetManyResponseInterface<IOeeResponse>) => {
            const loadedAction: Action = new ObjectActions.MonthlyOeeDataLoaded(
              response.data.map((monthlyResult: IOeeResponse): IFormattedOeeResult => {
                const unplannedDt: string = this.decimalHelper.divide(
                  String(monthlyResult[ChartValues.totalDownTimeUnplannedDuration]),
                  '60',
                );
                return {
                  ...monthlyResult,
                  [ChartValues.totalRunTimeDuration]: String(monthlyResult[ChartValues.totalRunTimeDuration]),
                  [ChartValues.totalDownTimeDuration]: String(monthlyResult[ChartValues.totalDownTimeDuration]),
                  [ChartValues.totalDownTimeUnplannedDuration]: unplannedDt,
                  [ChartValues.totalDownTimePlannedDuration]: this.decimalHelper.subtract(
                    String(monthlyResult[ChartValues.totalDownTimeDuration]),
                    unplannedDt,
                  ),
                  [ChartValues.totalIdleTimeDuration]: String(monthlyResult[ChartValues.totalIdleTimeDuration]),
                };
              }),
            );

            return of(...[loadedAction, ...(payload.isShowLoader ? [new AppActions.HideLoader()] : [])]);
          }),
          catchError((errorRes) => {
            return payload.isShowLoader
              ? of(new ObjectActions.FetchError(errorRes), new AppActions.HideLoader())
              : of(new ObjectActions.FetchError(errorRes));
          }),
        );
      }),
      catchError((errorRes) => {
        return of(new ObjectActions.FetchError(errorRes), new AppActions.HideLoader());
      }),
    ),
  );

  getProductGroupOeeResult = createEffect(() =>
    this.actions$.pipe(
      ofType(ObjectActions.PRODUCT_GROUP_OEE_DATA_LOADING),
      switchMap((payload: ObjectActions.ProductGroupOeeDataLoading) => {
        if (payload.isShowLoader) {
          this.store.dispatch(new AppActions.ShowLoader());
        }

        const body: IMonthlyOeeFilter = this.service.formatProductGroupOeeRequestBody(payload.params);
        return this.service.fetchOeeResultByTimeSelection(body).pipe(
          switchMap((response: GetManyResponseInterface<IOeeResponse>) => {
            const loadedAction: Action = new ObjectActions.ProductGroupOeeDataLoaded(
              response.data.map((productGroupResult: IOeeResponse): IFormattedOeeResult => {
                const unplannedDt: string = this.decimalHelper.divide(
                  String(productGroupResult[ChartValues.totalDownTimeUnplannedDuration]),
                  '60',
                );
                return {
                  ...productGroupResult,
                  [ChartValues.totalRunTimeDuration]: String(productGroupResult[ChartValues.totalRunTimeDuration]),
                  [ChartValues.totalDownTimeDuration]: String(productGroupResult[ChartValues.totalDownTimeDuration]),
                  [ChartValues.totalDownTimeUnplannedDuration]: unplannedDt,
                  [ChartValues.totalDownTimePlannedDuration]: this.decimalHelper.subtract(
                    String(productGroupResult[ChartValues.totalDownTimeDuration]),
                    unplannedDt,
                  ),
                  [ChartValues.totalIdleTimeDuration]: String(productGroupResult[ChartValues.totalIdleTimeDuration]),
                };
              }),
            );

            return of(...[loadedAction, ...(payload.isShowLoader ? [new AppActions.HideLoader()] : [])]);
          }),
          catchError((errorRes) => {
            return payload.isShowLoader
              ? of(new ObjectActions.FetchError(errorRes), new AppActions.HideLoader())
              : of(new ObjectActions.FetchError(errorRes));
          }),
        );
      }),
      catchError((errorRes) => {
        return of(new ObjectActions.FetchError(errorRes), new AppActions.HideLoader());
      }),
    ),
  );

  getDailyOeeResult = createEffect(() =>
    this.actions$.pipe(
      ofType(ObjectActions.DAILY_OEE_DATA_LOADING),
      switchMap((payload: ObjectActions.DailyOeeDataLoading) => {
        this.store.dispatch(new AppActions.ShowLoader());

        const body = this.service.formatDailyOeeRequestBody(payload.params);
        return this.service.fetchOeeResultByTimeSelection(body).pipe(
          switchMap((response: GetManyResponseInterface<IOeeResponse>) => {
            const loadedAction: Action = new ObjectActions.DailyOeeDataLoaded(
              response.data.map((hourlyResult: IOeeResponse): IFormattedOeeResult => {
                const unplannedDt: string = this.decimalHelper.divide(
                  String(hourlyResult[ChartValues.totalDownTimeUnplannedDuration]),
                  '60',
                );
                return {
                  ...hourlyResult,
                  [ChartValues.totalRunTimeDuration]: String(hourlyResult[ChartValues.totalRunTimeDuration]),
                  [ChartValues.totalDownTimeDuration]: String(hourlyResult[ChartValues.totalDownTimeDuration]),
                  [ChartValues.totalDownTimeUnplannedDuration]: unplannedDt,
                  [ChartValues.totalDownTimePlannedDuration]: this.decimalHelper.subtract(
                    String(hourlyResult[ChartValues.totalDownTimeDuration]),
                    unplannedDt,
                  ),
                  [ChartValues.totalIdleTimeDuration]: String(hourlyResult[ChartValues.totalIdleTimeDuration]),
                };
              }),
            );
            return of(...[loadedAction], new AppActions.HideLoader());
          }),
          catchError((errorRes) => {
            return of(new ObjectActions.FetchError(errorRes), new AppActions.HideLoader());
          }),
        );
      }),
      catchError((errorRes) => {
        return of(new ObjectActions.FetchError(errorRes), new AppActions.HideLoader());
      }),
    ),
  );
}
