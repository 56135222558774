import { Inject, Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import {
  GroupedByTypes,
  CheckInLogElasticCardDataInterface,
  CheckInLogElasticCardResponseInterface,
  CheckInLogElasticInterface,
  CheckInLogElasticTableResponseInterface,
} from './check-in-log-elastic.model';
import { forkJoin, Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import {
  BaseCrudResponse,
  BaseOneResponseInterface,
  BulkResponseDataInterface,
  GetManyResponseInterface,
} from '../../../shared/model/interface/crud-response-interface.model';
import { LineCRUDInterface } from '../../../shared/component/filter/filter.class';
import {
  CheckInLogFormRequestPayloadInterface,
  CheckInLogFormTypes,
  CheckInLogReportTypes,
  CheckInTableElasticParams,
  CheckInTableQueryParams,
} from '../../../view/reports/check-in-logs-elastic/check-in-logs-elastic.model';
import * as moment from 'moment-timezone';
import {
  CellTypes,
  CreateExcelInterface,
  ExcelHelperService,
} from '../../../shared/service/excel/excel-helper.service';
import * as _ from 'lodash';
import { TranslateService } from '@ngx-translate/core';
import { ValueType } from 'exceljs';
import { Store } from '@ngrx/store';
import * as oeeAppReducer from '../../oee.reducer';
import { excelDateFormat, excelTimeFormat } from '../../../shared/model/enum/excel-date-format';
import { minutesToHm } from '../../../shared/helper/date';
import { ElasticHelperService } from '../../../shared/service/elastic-helper.service';
import { ElasticDataInterface } from '../../../shared/model/interface/generic-api-response.model';

@Injectable({
  providedIn: 'root',
})
export class CheckInLogElasticService {
  private timezone: string = 'utc';
  private dateFormat$: string;
  private timeFormat$: string;
  private readonly destroySubject: Subject<boolean> = new Subject<boolean>();

  constructor(
    public http: HttpClient,
    @Inject('API_BASE_URL') private readonly baseUrl: string,
    @Inject('PROXY_URL') private readonly proxy: string,
    private readonly store: Store<oeeAppReducer.OeeAppState>,
    private readonly translate: TranslateService,
    private readonly excelHelper: ExcelHelperService,
    private readonly elasticHelper: ElasticHelperService,
  ) {
    this.store
      .select('user')
      .pipe(takeUntil(this.destroySubject))
      .subscribe((state) => {
        if (state.isUserLoaded) {
          this.timezone = state.timezone;
          if (state.locale !== '') {
            this.dateFormat$ = excelDateFormat[state.locale];
            this.timeFormat$ = excelTimeFormat[state.locale];
          }
          this.destroySubject.next(true);
          this.destroySubject.complete();
        }
      });
  }

  private readonly api: string = this.baseUrl;
  private readonly lines: string = `${this.api}/lines`;
  private readonly userCheckIn: string = `${this.api}/check-ins`;
  private readonly userCheckInBulkDelete: string = `${this.api}/check-ins/bulk/delete`;
  private readonly CHECK_IN_LOG = {
    GET: {
      CHECK_INS: '/check-ins/all',
      CARDS_DATA: `${this.userCheckIn}/card-data`,
      USER_CHECK_INS_GROUPED_BY: {
        none: `${this.userCheckIn}/object-check-ins`,
        object: `${this.userCheckIn}/grouped-by-object`,
        line: `${this.userCheckIn}/grouped-by-line`,
      },
    },
  };
  private readonly dataProperty: string = 'data';

  getCheckInCardData(data: CheckInTableQueryParams): Promise<CheckInLogElasticCardDataInterface> {
    return new Promise((resolve, reject) => {
      const options = this.formatCheckInParams(data, false);
      this.http
        .get(this.CHECK_IN_LOG.GET.CARDS_DATA, { params: options })
        .subscribe((response: CheckInLogElasticCardResponseInterface) => {
          if (response.hasOwnProperty(this.dataProperty)) {
            resolve(response.data);
            return response.data;
          }
          reject();
          return null;
        });
    });
  }

  public formatCheckInParams(params: CheckInTableQueryParams, isLimitOffset: boolean): HttpParams {
    let httpParams = new HttpParams();

    if (isLimitOffset) {
      httpParams = httpParams.append('offset', String(params.offset)).append('limit', String(params.pageSize));
    }

    httpParams = httpParams
      .set('siteIds', params.siteIds)
      .set('objectIds', params.objectIds)
      .set('shiftIds', params.shiftIds)
      .set('sourceObjectIds', params.sourceObjectIds)
      .set('destinationTypeId', params.destinationTypeId)
      .set('sourceTypeId', params.sourceTypeId)
      .set('startDate', params.startDate)
      .set('endDate', params.endDate);

    if (params.status !== undefined) {
      httpParams = httpParams.set('status', params.status);
    }

    if (params.orderDesc !== undefined && isLimitOffset) {
      const direction = params.orderDesc ? 'DESC' : 'ASC';
      httpParams = httpParams.set('sort', `${params.orderBy},${direction}`);
    }

    return httpParams;
  }

  getCheckInTableData(
    data: CheckInTableQueryParams,
    groupedBy: GroupedByTypes,
  ): Observable<BaseOneResponseInterface<ElasticDataInterface<CheckInLogElasticTableResponseInterface>[]>> {
    const elasticParams: CheckInTableElasticParams = {
      sourceTypeId: data.sourceTypeId,
      sourceObjectIds: Number(data.sourceObjectIds.split(',')),
      siteIds: Number(data.siteIds.split(',')),
      objectIds: Number(data.objectIds.split(',')),
      shiftIds: Number(data.shiftIds.split(',')),
      startDate: data.startDate,
      endDate: data.endDate,
      status: data.status,
      ...(data.destinationTypeId && { destinationTypeIds: Number(data.destinationTypeId.split(',')) }),
      ...(data.destinationObjectId && { destinationObjectIds: null }),
    };
    const body = this.elasticHelper.getElasticQueryBodyOfCheckIns(elasticParams, this.timezone);

    return this.http.post<BaseOneResponseInterface<ElasticDataInterface<CheckInLogElasticTableResponseInterface>[]>>(
      `${this.proxy}${this.CHECK_IN_LOG.GET.CHECK_INS}`,
      { search: body },
    );
  }

  public loadLines(): Observable<GetManyResponseInterface<LineCRUDInterface>> {
    const baseHttpParams = new HttpParams().append('limit', '1000');
    const options = {
      params: baseHttpParams,
    };

    return this.http.get<GetManyResponseInterface<LineCRUDInterface>>(this.lines, options);
  }

  public submitCheckInLog(
    formType: CheckInLogFormTypes,
    checkInLogForm: CheckInLogFormRequestPayloadInterface,
  ): Observable<BaseOneResponseInterface<CheckInLogElasticInterface>> {
    const { id, ...requestPayload } = checkInLogForm;
    const requestType: 'post' | 'patch' = formType === CheckInLogFormTypes.Edit ? 'patch' : 'post';
    const requestURL: string = formType === CheckInLogFormTypes.Edit ? `${this.userCheckIn}/${id}` : this.userCheckIn;

    return this.http[requestType]<BaseOneResponseInterface<CheckInLogElasticInterface>>(requestURL, requestPayload);
  }

  public deleteCheckInLog(id: number): Observable<BaseCrudResponse> {
    return this.http.delete<BaseCrudResponse>(`${this.userCheckIn}/${id}`);
  }

  public bulkDeleteCheckInLog(checkIns: number[]): Observable<BulkResponseDataInterface> {
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
      body: {
        checkIns,
      },
    };
    return this.http.delete<BulkResponseDataInterface>(`${this.userCheckInBulkDelete}`, httpOptions);
  }

  public downloadExcel(filters: CheckInTableQueryParams, groupedBy: GroupedByTypes, data?: any[]): void {
    const observables: Observable<CheckInLogElasticTableResponseInterface>[] = [];

    if (filters.sourceTypeId === 1) {
      observables.push(
        this.translate.get('excel.laborLogs.excelName', {
          date: moment()
            .tz(this.timezone)
            .format(this.dateFormat$),
        }),
      );
    } else {
      observables.push(
        this.translate.get('excel.assetLogs.excelName', {
          date: moment()
            .tz(this.timezone)
            .format(this.dateFormat$),
        }),
      );
    }

    // if (!data) {
    //   observables.push(this.getCheckInTableData(filters, groupedBy));
    // }

    forkJoin(observables).subscribe((responseList) => {
      const checkInLogsTitle =
        filters.sourceTypeId === 1
          ? this.translate.instant('excel.laborLogs.excelSheetName')
          : this.translate.instant('excel.assetLogs.excelSheetName');
      let excelName: string = _.get(responseList, '0', checkInLogsTitle);
      const excelData: any[] = _.get(responseList, '1.data', []);

      const excelOptions: CreateExcelInterface = this.getCheckInItemsExcelColumns(filters.sourceTypeId, groupedBy);
      excelOptions.data = this.formatExcelValues(excelData, filters.sourceTypeId);

      if (excelName === undefined) {
        excelName = checkInLogsTitle;
      }
    });
  }

  private getCheckInItemsExcelColumns(
    sourceObjectType: CheckInLogReportTypes,
    groupedBy: GroupedByTypes,
  ): CreateExcelInterface {
    const lineHeaderTranslation: string = this.translate.instant('reports.checkInLogs.tableColumn.lineName');
    const laborTrackerTranslation: string = this.translate.instant('cico.modules.laborTracker.name');
    const assetTrackerTranslation: string = this.translate.instant('cico.modules.assetTracker.name');
    const durationTranslation: string = this.translate.instant('reports.checkInLogs.tableColumn.duration');
    const numFmt: string = '0.00############################';
    let checkInLogsColumns: CreateExcelInterface;
    switch (groupedBy) {
      case 'none':
        checkInLogsColumns = {
          columns: [
            {
              header: lineHeaderTranslation,
              key: 'line',
              width: 20,
              type: ValueType.String,
              dataValidation: {
                type: CellTypes.CUSTOM,
                allowBlank: false,
                formulae: [],
                showErrorMessage: true,
                showInputMessage: true,
              },
            },
            {
              header: this.translate.instant('reports.checkInLogs.tableColumn.stationName'),
              key: 'station',
              width: 20,
              type: ValueType.String,
              style: { numFmt: '@' },
              dataValidation: {
                type: CellTypes.CUSTOM,
                allowBlank: false,
                formulae: [],
                showErrorMessage: true,
                showInputMessage: true,
              },
            },
            {
              header: sourceObjectType === 1 ? laborTrackerTranslation : assetTrackerTranslation,
              key: 'sourceObjectViewName',
              width: 20,
              type: ValueType.String,
              style: { numFmt: '@' },
              dataValidation: {
                type: CellTypes.CUSTOM,
                allowBlank: false,
                formulae: [],
                showErrorMessage: true,
                showInputMessage: true,
              },
            },
            {
              header: this.translate.instant('reports.checkInLogs.tableColumn.status'),
              key: 'status',
              width: 20,
              type: ValueType.String,
              style: { numFmt: '@' },
              dataValidation: {
                type: CellTypes.CUSTOM,
                allowBlank: false,
                formulae: [],
                showErrorMessage: true,
                showInputMessage: true,
              },
            },
            {
              header: this.translate.instant('reports.checkInLogs.tableColumn.startDate'),
              key: 'startDate',
              width: 20,
              type: ValueType.Date,
              isDateTimeFormat: true,
              style: { numFmt: '@' },
              dataValidation: {
                type: CellTypes.CUSTOM,
                allowBlank: false,
                showErrorMessage: true,
                formulae: [null],
                showInputMessage: true,
              },
            },
            {
              header: this.translate.instant('reports.checkInLogs.tableColumn.endDate'),
              key: 'endDate',
              width: 20,
              type: ValueType.Date,
              isDateTimeFormat: true,
              style: { numFmt: '@' },
              dataValidation: {
                type: CellTypes.CUSTOM,
                allowBlank: false,
                showErrorMessage: true,
                formulae: [null],
                showInputMessage: true,
              },
            },
            {
              header: this.translate.instant('reports.checkInLogs.tableColumn.calculatedStartDate'),
              key: 'calculatedStartDate',
              width: 20,
              type: ValueType.Date,
              isDateTimeFormat: true,
              style: { numFmt: '@' },
              dataValidation: {
                type: CellTypes.CUSTOM,
                allowBlank: false,
                showErrorMessage: true,
                formulae: [null],
                showInputMessage: true,
              },
            },
            {
              header: this.translate.instant('reports.checkInLogs.tableColumn.calculatedEndDate'),
              key: 'calculatedEndDate',
              width: 20,
              type: ValueType.Date,
              isDateTimeFormat: true,
              style: { numFmt: '@' },
              dataValidation: {
                type: CellTypes.CUSTOM,
                allowBlank: false,
                showErrorMessage: true,
                formulae: [null],
                showInputMessage: true,
              },
            },
            {
              header: durationTranslation,
              key: 'duration',
              width: 20,
              type: ValueType.Number,
              style: { numFmt },
              maxLength: 11,
              allowPunctuation: true,
              dataValidation: {
                type: CellTypes.CUSTOM,
                allowBlank: false,
                showErrorMessage: true,
                formulae: [],
                showInputMessage: true,
              },
            },
          ],
        };
        break;
      case 'object':
        checkInLogsColumns = {
          columns: [
            {
              header: sourceObjectType === 1 ? laborTrackerTranslation : assetTrackerTranslation,
              key: 'sourceObjectViewName',
              width: 20,
              type: ValueType.String,
              style: { numFmt: '@' },
              dataValidation: {
                type: CellTypes.CUSTOM,
                allowBlank: false,
                formulae: [],
                showErrorMessage: true,
                showInputMessage: true,
              },
            },
            {
              header: lineHeaderTranslation,
              key: 'line',
              width: 20,
              type: ValueType.String,
              dataValidation: {
                type: CellTypes.CUSTOM,
                allowBlank: false,
                formulae: [],
                showErrorMessage: true,
                showInputMessage: true,
              },
            },
            {
              header: durationTranslation,
              key: 'duration',
              width: 20,
              type: ValueType.Number,
              style: { numFmt },
              maxLength: 11,
              allowPunctuation: true,
              dataValidation: {
                type: CellTypes.CUSTOM,
                allowBlank: false,
                showErrorMessage: true,
                formulae: [],
                showInputMessage: true,
              },
            },
          ],
        };
        break;
      case 'line':
        checkInLogsColumns = {
          columns: [
            {
              header: lineHeaderTranslation,
              key: 'line',
              width: 20,
              type: ValueType.String,
              dataValidation: {
                type: CellTypes.CUSTOM,
                allowBlank: false,
                formulae: [],
                showErrorMessage: true,
                showInputMessage: true,
              },
            },
            {
              header: durationTranslation,
              key: 'duration',
              width: 20,
              type: ValueType.Number,
              style: { numFmt },
              maxLength: 11,
              allowPunctuation: true,
              dataValidation: {
                type: CellTypes.CUSTOM,
                allowBlank: false,
                showErrorMessage: true,
                formulae: [],
                showInputMessage: true,
              },
            },
            {
              header:
                sourceObjectType === 1
                  ? this.translate.instant('reports.checkInLogs.tableColumn.userCount')
                  : this.translate.instant('reports.checkInLogs.tableColumn.assetCount'),
              key: 'objectCount',
              width: 20,
              type: ValueType.Number,
              style: { numFmt },
              maxLength: 11,
              allowPunctuation: true,
              dataValidation: {
                type: CellTypes.CUSTOM,
                allowBlank: false,
                showErrorMessage: true,
                formulae: [],
                showInputMessage: true,
              },
            },
            {
              header:
                sourceObjectType === 1
                  ? this.translate.instant('reports.checkInLogs.tableColumn.users')
                  : this.translate.instant('reports.checkInLogs.tableColumn.assets'),
              key: 'sourceObjectViewName',
              width: 20,
              type: ValueType.String,
              style: { numFmt: '@' },
              dataValidation: {
                type: CellTypes.CUSTOM,
                allowBlank: false,
                formulae: [],
                showErrorMessage: true,
                showInputMessage: true,
              },
            },
          ],
        };
        break;
      default:
        break;
    }
    return checkInLogsColumns;
  }

  public formatExcelValues(excelData: any[] = [], sourceTypeId: number): any[] {
    const available = this.translate.instant('general.available');
    const onGoing = this.translate.instant('general.ongoing');
    let busy;
    if (sourceTypeId === 1) {
      busy = this.translate.instant('general.busy');
    } else {
      busy = this.translate.instant('general.unavailable');
    }

    excelData.forEach((data) => {
      data.status = data.status === 0 ? busy : available;
      data.endDate = data.endDate ? data.endDate : onGoing;
      data.duration = minutesToHm(_.toInteger(data.duration));
    });
    return excelData;
  }
}
