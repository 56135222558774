import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, switchMap, take } from 'rxjs/operators';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Store } from '@ngrx/store';
import * as moment from 'moment-timezone';
import * as ObjectActions from './production-review.actions';
import * as ProductionReviewListViewActions from './production-review-list-view.actions';
import * as oeeAppReducer from '../oee.reducer';
import { forkJoin, Observable, of } from 'rxjs';
import { iosTimestampFormat, mysqlDateFormat } from '../../shared/helper/date';
import * as AppActions from '../app/actions';
import * as ActivityHistoryActions from '../activity-history/activity-history.actions';
import { User } from '../user/model';
import { HelperService } from '../../shared/service/helper.service';
import { ProductionReviewService } from './production-review.service';
import {
  BaseOneResponseInterface,
  GetManyResponseInterface,
} from '../../shared/model/interface/crud-response-interface.model';
import {
  ICurrentAndPreviousShift,
  IDateShifts,
  IExpectedSpeedAndUnit,
  IProductionReviewData,
  IProductionReviewDataInterface,
  IProductionReviewDataResponse,
  IProductionReviewWorkOrderSummaryInterface,
} from './production-review.model';
import { ResponseInterface } from '../../shared/model/interface/generic-api-response.model';
import { IActivityHistory } from '../../shared/service/activity-history/activity-history.model';
import { ActivityHistoryService } from '../../shared/service/activity-history/activity-history.service';
import {
  ProductionReviewCommentCRUDInterface,
  ProductionReviewTargetCRUDInterface,
} from './production-review-list-view.model';
import { WorkOrderSummaryDataInterface } from './production-review-wo-summary.model';
import { EntityTranslatorService } from '../../shared/service/entity-translator/entity-translator.service';
import * as _ from 'lodash';

@Injectable()
export class ProductionReviewEffects {
  private selectedDate: string;
  private previousShiftEndTime: string;
  private dateTimeFormat: string;
  private timeZone: string;
  private locale$: string;
  private dateFormat$: string;

  constructor(
    private actions$: Actions,
    private http: HttpClient,
    private store: Store<oeeAppReducer.OeeAppState>,
    private readonly helperService: HelperService,
    private readonly activityHistoryService: ActivityHistoryService,
    private readonly service: ProductionReviewService,
    private readonly entityTranslator: EntityTranslatorService,
  ) {}

  private PRODUCTION_REVIEW = {
    GET: {
      DATA_URL: '/api/get-production-review-data',
      SHIFT_DATA_URL: '/api/productionreviewlist',
      WORK_ORDER_SUMMARY_DATA: '/api/getproductionreviewworkordersummary',
      NEXT_SHIFT: '/api/getnextshift',
    },
  };

  fetchProductionReviewData = createEffect(() =>
    this.actions$.pipe(
      ofType(ObjectActions.START_PRODUCTION_REVIEW_DATA_LOADING),
      switchMap((objectData: ObjectActions.StartProductionReviewDataLoading) => {
        return this.service.getCurrentAndPreviousShift(objectData.lineId).pipe(
          switchMap((resData: BaseOneResponseInterface<ICurrentAndPreviousShift>) => {
            return of(new ObjectActions.ProductionReviewDataLoaded(resData.data));
          }),
          catchError(() => {
            return of(new AppActions.HideLoader(), new AppActions.HideTopLoader());
          }),
        );
      }),
      catchError(() => {
        return of(new AppActions.HideLoader(), new AppActions.HideTopLoader());
      }),
    ),
  );

  getShiftByDate = createEffect(() =>
    this.actions$.pipe(
      ofType(ObjectActions.GET_SHIFTS_BY_DATE),
      switchMap((objectData: ObjectActions.GetShiftsByDate) => {
        this.store
          .select('user')
          .pipe(take(1))
          .subscribe((state: User) => {
            this.dateTimeFormat = state.dateTimeFormat;
            this.dateFormat$ = state.dateFormat;
            this.timeZone = state.timezone;
            this.locale$ = state.locale;
          });

        if (objectData.payload.selectedDate === undefined) {
          this.selectedDate = moment().tz(this.timeZone).startOf('day').format(mysqlDateFormat);
        } else {
          this.selectedDate = moment(objectData.payload.selectedDate).startOf('day').format(mysqlDateFormat);
        }

        let params = new HttpParams();

        if (this.selectedDate !== '') {
          params = params.set('startDate', this.selectedDate).set('byBusinessDate', String(true));
        }

        return this.service.getShiftsByDate(objectData.payload.lineId, params).pipe(
          switchMap((resData: GetManyResponseInterface<IDateShifts>) => {
            return of(new ObjectActions.ShiftsLoadedByDate(resData.data));
          }),
          catchError(() => {
            return of(new AppActions.HideLoader(), new AppActions.HideTopLoader());
          }),
        );
      }),
      catchError(() => {
        return of(new AppActions.HideLoader(), new AppActions.HideTopLoader());
      }),
    ),
  );

  getNextShiftData = createEffect(() =>
    this.actions$.pipe(
      ofType(ObjectActions.START_NEXT_SHIFT_LOADING),
      switchMap(() => {
        this.store
          .select('productionReview')
          .pipe(take(1))
          .subscribe((productionReview) => {
            let endTime: string;
            endTime = moment(productionReview.previousShift['formatted_end_date']).format(iosTimestampFormat);
            this.previousShiftEndTime = endTime;
          });
        const options = {
          params: new HttpParams().set('end_date', this.previousShiftEndTime),
        };
        return this.http.get<any>(this.PRODUCTION_REVIEW.GET.NEXT_SHIFT, options).pipe(
          switchMap((resData) => {
            return of(new ObjectActions.NextShiftLoaded(resData.data[0]));
          }),
          catchError(() => {
            return of(new AppActions.HideLoader(), new AppActions.HideTopLoader());
          }),
        );
      }),
      catchError(() => {
        return of(new AppActions.HideLoader(), new AppActions.HideTopLoader());
      }),
    ),
  );

  getActivityData = createEffect(() =>
    this.actions$.pipe(
      ofType(ProductionReviewListViewActions.START_ACTIVITY_DATA_LOADING),
      switchMap((objectData: ProductionReviewListViewActions.StartActivityDataLoading) => {
        this.store.dispatch(new AppActions.ShowTopLoader());
        return this.activityHistoryService.getActivity(objectData.id).pipe(
          switchMap((response: ResponseInterface<IActivityHistory>) => {
            this.entityTranslator.translate(response.data);
            return of(
              new ProductionReviewListViewActions.SetActivityDataLoaded(response.data),
              new ActivityHistoryActions.GetActivityEventDataCompleted(response.data),
              new AppActions.HideTopLoader(),
            );
          }),
          catchError(() => {
            return of(new AppActions.HideLoader(), new AppActions.HideTopLoader());
          }),
        );
      }),
    ),
  );

  createProductionReviewTarget = createEffect(() =>
    this.actions$.pipe(
      ofType(ProductionReviewListViewActions.CREATE_PRODUCTION_REVIEW_TARGET_LOADING),
      switchMap((objectData: ProductionReviewListViewActions.CreateProductionReviewTargetLoading) => {
        this.store.dispatch(new AppActions.ShowTopLoader());
        return this.service.createProductionReviewTarget(objectData.data).pipe(
          switchMap((response: BaseOneResponseInterface<ProductionReviewTargetCRUDInterface>) => {
            return of(
              new ProductionReviewListViewActions.CreateProductionReviewTargetLoaded(response),
              new AppActions.HideTopLoader(),
            );
          }),
          catchError(() => {
            return of(new AppActions.HideLoader(), new AppActions.HideTopLoader());
          }),
        );
      }),
    ),
  );

  createProductionReviewComment = createEffect(() =>
    this.actions$.pipe(
      ofType(ProductionReviewListViewActions.CREATE_PRODUCTION_REVIEW_COMMENT_LOADING),
      switchMap((objectData: ProductionReviewListViewActions.CreateProductionReviewCommentLoading) => {
        this.store.dispatch(new AppActions.ShowTopLoader());
        return this.service.createProductionReviewComment(objectData.data).pipe(
          switchMap((response: BaseOneResponseInterface<ProductionReviewCommentCRUDInterface>) => {
            return of(
              new ProductionReviewListViewActions.CreateProductionReviewCommentLoaded(response),
              new AppActions.HideTopLoader(),
            );
          }),
          catchError(() => {
            return of(new AppActions.HideTopLoader());
          }),
        );
      }),
      catchError(() => {
        return of(new AppActions.HideTopLoader());
      }),
    ),
  );

  updateProductionReviewComment = createEffect(() =>
    this.actions$.pipe(
      ofType(ProductionReviewListViewActions.UPDATE_PRODUCTION_REVIEW_COMMENT_LOADING),
      switchMap((objectData: ProductionReviewListViewActions.UpdateProductionReviewCommentLoading) => {
        this.store.dispatch(new AppActions.ShowTopLoader());
        return this.service.updateProductionReviewComment(objectData.data).pipe(
          switchMap((response: BaseOneResponseInterface<ProductionReviewCommentCRUDInterface>) => {
            return of(
              new ProductionReviewListViewActions.UpdateProductionReviewCommentLoaded(response),
              new AppActions.HideTopLoader(),
            );
          }),
          catchError(() => {
            return of(new AppActions.HideTopLoader());
          }),
        );
      }),
      catchError(() => {
        return of(new AppActions.HideTopLoader());
      }),
    ),
  );

  updateProductionReviewTarget = createEffect(() =>
    this.actions$.pipe(
      ofType(ProductionReviewListViewActions.UPDATE_PRODUCTION_REVIEW_TARGET_LOADING),
      switchMap((objectData: ProductionReviewListViewActions.UpdateProductionReviewTargetLoading) => {
        this.store.dispatch(new AppActions.ShowTopLoader());
        return this.service.updateProductionReviewTarget(objectData.target).pipe(
          switchMap((response: BaseOneResponseInterface<ProductionReviewTargetCRUDInterface>) => {
            return of(
              new ProductionReviewListViewActions.UpdateProductionReviewTargetLoaded(response),
              new AppActions.HideTopLoader(),
            );
          }),
          catchError(() => {
            return of(new AppActions.HideLoader(), new AppActions.HideTopLoader());
          }),
        );
      }),
    ),
  );

  deleteProductionReviewTarget = createEffect(() =>
    this.actions$.pipe(
      ofType(ProductionReviewListViewActions.DELETE_PRODUCTION_REVIEW_TARGET_LOADING),
      switchMap((objectData: ProductionReviewListViewActions.DeleteProductionReviewTargetLoading) => {
        this.store.dispatch(new AppActions.ShowTopLoader());
        return this.service.deleteProductionReviewTarget(objectData.id).pipe(
          switchMap(() => {
            return of(
              new ProductionReviewListViewActions.DeleteProductionReviewTargetLoaded(objectData.workOrderHeader),
              new AppActions.HideTopLoader(),
            );
          }),
          catchError(() => {
            return of(new AppActions.HideLoader(), new AppActions.HideTopLoader());
          }),
        );
      }),
      catchError(() => {
        return of(new AppActions.HideLoader(), new AppActions.HideTopLoader());
      }),
    ),
  );

  bulkSaveProductionReviewTarget = createEffect(() =>
    this.actions$.pipe(
      ofType(ProductionReviewListViewActions.BULK_SAVE_PRODUCTION_REVIEW_TARGET_LOADING),
      switchMap((objectData: ProductionReviewListViewActions.BulkSaveProductionReviewTargetLoading) => {
        this.store.dispatch(new AppActions.ShowTopLoader());
        return this.service.bulkSaveProductionReviewTarget(objectData.createOrUpdateParameters).pipe(
          switchMap(() => {
            return of(
              new ProductionReviewListViewActions.BulkSaveProductionReviewTargetLoaded(),
              new AppActions.HideTopLoader(),
            );
          }),
          catchError(() => {
            return of(new AppActions.HideLoader(), new AppActions.HideTopLoader());
          }),
        );
      }),
      catchError(() => {
        return of(new AppActions.HideLoader(), new AppActions.HideTopLoader());
      }),
    ),
  );

  bulkUpdateProductionReviewTarget = createEffect(() =>
    this.actions$.pipe(
      ofType(ProductionReviewListViewActions.BULK_UPDATE_PRODUCTION_REVIEW_TARGET_LOADING),
      switchMap((objectData: ProductionReviewListViewActions.BulkUpdateProductionReviewTargetLoading) => {
        this.store.dispatch(new AppActions.ShowTopLoader());
        return this.service.bulkUpdateProductionReviewTargets(objectData.updateParameters).pipe(
          switchMap(() => {
            return of(
              new ProductionReviewListViewActions.BulkUpdateProductionReviewTargetLoaded(),
              new AppActions.HideTopLoader(),
            );
          }),
          catchError(() => {
            return of(new AppActions.HideLoader(), new AppActions.HideTopLoader());
          }),
        );
      }),
      catchError(() => {
        return of(new AppActions.HideLoader(), new AppActions.HideTopLoader());
      }),
    ),
  );

  expectedSpeedAndUnit = createEffect(() =>
    this.actions$.pipe(
      ofType(ObjectActions.EXPECTED_SPEED_AND_UNIT_LOADING),
      switchMap((objectData: ObjectActions.GetExpectedSpeedAndUnitLoading) => {
        return this.service.getExpectedSpeedAndUnit(objectData.lineId).pipe(
          switchMap((resData: BaseOneResponseInterface<IExpectedSpeedAndUnit>) => {
            return of(new ObjectActions.GetExpectedSpeedAndUnitLoaded(resData.data));
          }),
          catchError(() => {
            return of(new AppActions.HideTopLoader());
          }),
        );
      }),
    ),
  );

  fetchData = createEffect(() =>
    this.actions$.pipe(
      ofType(ObjectActions.PRODUCTION_REVIEW_FETCH_DATA),
      switchMap((objectData: ObjectActions.ProductionReviewFetchData) => {
        this.store.dispatch(new AppActions.ShowLoader());
        this.store.dispatch(new AppActions.ShowTopLoader());

        const params = new HttpParams()
          .set('shiftDay', String(objectData.payload.shiftDay));

        const observables: Observable<
          | GetManyResponseInterface<IProductionReviewDataInterface>
          | GetManyResponseInterface<IProductionReviewWorkOrderSummaryInterface>
        >[] = [
          this.service.getProductionReviewData(
            objectData.payload.lineId,
            _.cloneDeep(params).set('shiftId', String(objectData.payload.shiftId)),
          ),
          this.service.getProductionReviewWorkOrderSummary(
            objectData.payload.lineId,
            _.cloneDeep(params).set('shiftId', String(objectData.payload.shiftId)),
          ),
        ];

        return forkJoin(observables).pipe(
          switchMap(
            (
              response: (
                | GetManyResponseInterface<IProductionReviewDataInterface>
                | GetManyResponseInterface<IProductionReviewWorkOrderSummaryInterface>
              )[],
            ) => {
              response[0].data.forEach((productionReviewData) => {
                productionReviewData.ganttData.forEach((ganttData: IProductionReviewDataInterface) =>
                  this.entityTranslator.translate(ganttData),
                );
                this.entityTranslator.translate(productionReviewData);
              });
              response[1].data.forEach((productionReviewWorkOrderSummary) =>
                this.entityTranslator.translate(productionReviewWorkOrderSummary),
              );
              const formattedProductionReviewData: IProductionReviewData = this.service.getModifiedProductionReviewData(
                response[0].data as IProductionReviewDataInterface[],
              );

              const formattedProductionReviewWorkOrderSummaryData: WorkOrderSummaryDataInterface[] =
                this.service.getModifiedProductionReviewWorkOrderSummaryData(
                  response[1].data as IProductionReviewWorkOrderSummaryInterface[],
                );

              const concatData: IProductionReviewDataResponse = {
                productionReviewData: formattedProductionReviewData,
                productionReviewWorkOrderSummaryData: formattedProductionReviewWorkOrderSummaryData,
              };

              return of(
                new ObjectActions.ProductionReviewFetchDataLoaded(concatData),
                new AppActions.HideLoader(),
                new AppActions.HideTopLoader(),
              );
            },
          ),
          catchError(() => {
            return of(new AppActions.HideLoader(), new AppActions.HideTopLoader());
          }),
        );
      }),
      catchError(() => {
        return of(new AppActions.HideLoader(), new AppActions.HideTopLoader());
      }),
    ),
  );
}
