import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { UnitConversionService } from './unit-conversion.service';
import { Store } from '@ngrx/store';
import * as oeeAppReducer from '../../oee.reducer';
import * as ObjectActions from './unit-conversion.actions';
import * as AppActions from '../../app/actions';
import { catchError, switchMap } from 'rxjs/operators';
import { of } from 'rxjs';
import {
  BulkResponseDataInterface,
  GetManyResponseInterface,
} from '../../../shared/model/interface/crud-response-interface.model';
import { IUnitConversion } from './unit-conversion.model';

@Injectable()
export class UnitConversionEffects {
  constructor(
    private readonly actions$: Actions,
    private readonly service: UnitConversionService,
    private readonly store: Store<oeeAppReducer.OeeAppState>,
  ) {}

  getUnitConversionData = createEffect(() =>
    this.actions$.pipe(
      ofType(ObjectActions.UNIT_CONVERSION_DATA_LOADING),
      switchMap((objectData: ObjectActions.UnitConversionDataLoading) => {
        this.store.dispatch(new AppActions.ShowLoader());

        return this.service.getUnitConversion(objectData.id, objectData.fromLine).pipe(
          switchMap((response: GetManyResponseInterface<IUnitConversion>) => {
            return of(new ObjectActions.UnitConversionDataLoaded(response.data), new AppActions.HideLoader());
          }),
          catchError((error) => {
            return of(new ObjectActions.FetchError(error), new AppActions.HideLoader());
          }),
        );
      }),
      catchError((errorRes) => {
        return of(new ObjectActions.FetchError(errorRes), new AppActions.HideLoader());
      }),
    ),
  );

  addUnitConversion = createEffect(() =>
    this.actions$.pipe(
      ofType(ObjectActions.ADD_UNIT_CONVERSION_LOADING),
      switchMap((objectData: ObjectActions.AddUnitConversionLoading) => {
        this.store.dispatch(new AppActions.ShowLoader());

        return this.service.addUnitConversion(objectData.payload).pipe(
          switchMap((response) => {
            return of(new ObjectActions.AddUnitConversionLoaded(response), new AppActions.HideLoader());
          }),
          catchError((error) => {
            return of(new ObjectActions.FetchError(error), new AppActions.HideLoader());
          }),
        );
      }),
      catchError((errorRes) => {
        return of(new ObjectActions.FetchError(errorRes), new AppActions.HideLoader());
      }),
    ),
  );

  editUnitConversion = createEffect(() =>
    this.actions$.pipe(
      ofType(ObjectActions.EDIT_UNIT_CONVERSION_LOADING),
      switchMap((objectData: ObjectActions.EditUnitConversionLoading) => {
        this.store.dispatch(new AppActions.ShowLoader());

        return this.service.editUnitConversion(objectData.payload).pipe(
          switchMap((response: BulkResponseDataInterface) => {
            return of(new ObjectActions.EditUnitConversionLoaded(response), new AppActions.HideLoader());
          }),
          catchError((error) => {
            return of(new ObjectActions.FetchError(error), new AppActions.HideLoader());
          }),
        );
      }),
      catchError((errorRes) => {
        return of(new ObjectActions.FetchError(errorRes), new AppActions.HideLoader());
      }),
    ),
  );
}
