import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, switchMap } from 'rxjs/operators';
import * as ObjectActions from './product-family.actions';
import { SiteService } from '../../../shared/service/filter/site.service';
import { from, of } from 'rxjs';
import * as AppActions from '../../app/actions';
import { HttpParams } from '@angular/common/http';
import { Store } from '@ngrx/store';
import * as oeeAppReducer from '../../oee.reducer';
import { ErrorMessageService } from '../../../shared/service/error-message.service';
import * as _ from 'lodash';
import { ProductFamilyService } from './product-family.service';
import {
  BaseOneResponseInterface,
  BulkResponseDataInterface,
  GetManyResponseInterface,
} from '../../../shared/model/interface/crud-response-interface.model';
import { IAddEditProductFamily, IProductFamily } from './product-family.model';
import { emptyAction } from '../../../../constants';
import { ExcelHelperService } from '../../../shared/service/excel/excel-helper.service';

@Injectable()
export class ProductFamilyEffects {
  constructor(
    private readonly actions$: Actions,
    private readonly service: ProductFamilyService,
    private readonly siteService: SiteService,
    private readonly excelHelperService: ExcelHelperService,
    private readonly store: Store<oeeAppReducer.OeeAppState>,
    private readonly errorMessageService: ErrorMessageService,
  ) {}

  getProductFamilyData = createEffect(() =>
    this.actions$.pipe(
      ofType(ObjectActions.PRODUCT_FAMILY_DATA_LOADING),
      switchMap((payload: ObjectActions.ProductFamilyDataLoading) => {
        let httpParams: HttpParams = new HttpParams()
          .append('join', 'site||name')
          .append('page', String(payload.tableQuery.page))
          .append('limit', String(payload.tableQuery.rowsPerPage || 1000))
          .append('sort', 'id,DESC');

        if (
          payload.tableQuery.search !== undefined ||
          (payload.tableQuery.siteIds !== undefined && payload.tableQuery.siteIds !== -1)
        ) {
          httpParams = httpParams.append(
            's',
            JSON.stringify({
              $and: [
                payload.tableQuery.siteIds !== -1
                  ? {
                      siteId: {
                        $in: payload.tableQuery.siteIds,
                      },
                    }
                  : {},
                {
                  $or: [
                    {
                      name: {
                        $cont: payload.tableQuery.search,
                      },
                    },
                  ],
                },
              ],
            }),
          );
        }

        if (!_.isEmpty(payload.tableQuery.sort.column)) {
          httpParams = httpParams.set('sort', `${payload.tableQuery.sort.column},${payload.tableQuery.sort.type}`);
        }

        return this.service.getProductFamily(httpParams).pipe(
          switchMap((response: GetManyResponseInterface<IProductFamily>) => {
            return of(new ObjectActions.ProductFamilyDataLoaded(response));
          }),
          catchError((errorRes) => {
            return of(new ObjectActions.FetchError(errorRes));
          }),
        );
      }),
      catchError((errorRes) => {
        return of(new ObjectActions.FetchError(errorRes));
      }),
    ),
  );

  addProductFamily = createEffect(() =>
    this.actions$.pipe(
      ofType(ObjectActions.ADD_PRODUCT_FAMILY),
      switchMap((objectData: ObjectActions.AddProductFamily) => {
        this.store.dispatch(new AppActions.ShowLoader());

        return this.service.addProductFamily(objectData.productFamily).pipe(
          switchMap((response: BaseOneResponseInterface<IAddEditProductFamily>) => {
            return of(new ObjectActions.AddProductFamilyCompleted(response), new AppActions.HideLoader());
          }),
          catchError((error) => {
            return of(new ObjectActions.FetchError(error), new AppActions.HideLoader());
          }),
        );
      }),
      catchError((errorRes) => {
        return of(new ObjectActions.FetchError(errorRes), new AppActions.HideLoader());
      }),
    ),
  );

  editProductFamily = createEffect(() =>
    this.actions$.pipe(
      ofType(ObjectActions.EDIT_PRODUCT_FAMILY),
      switchMap((objectData: ObjectActions.EditProductFamily) => {
        this.store.dispatch(new AppActions.ShowLoader());

        return this.service.editProductFamily(objectData.productFamily, objectData.id).pipe(
          switchMap((response: BaseOneResponseInterface<IAddEditProductFamily>) => {
            return of(new ObjectActions.EditProductFamilyCompleted(response), new AppActions.HideLoader());
          }),
          catchError((error) => {
            return of(new ObjectActions.FetchError(error), new AppActions.HideLoader());
          }),
        );
      }),
    ),
  );

  deleteProductFamily = createEffect(() =>
    this.actions$.pipe(
      ofType(ObjectActions.DELETE_PRODUCT_FAMILY),
      switchMap((objectData: ObjectActions.DeleteProductFamily) => {
        this.store.dispatch(new AppActions.ShowLoader());
        return this.service.deleteProductFamily(objectData.payload).pipe(
          switchMap((response: BulkResponseDataInterface) => {
            this.errorMessageService.getTranslatedErrorMessage(response.data);
            return of(new ObjectActions.DeleteProductFamilyCompleted(response), new AppActions.HideLoader());
          }),
          catchError((error) => {
            return of(new ObjectActions.FetchError(error), new AppActions.HideLoader());
          }),
        );
      }),
    ),
  );

  deleteProductFamilies = createEffect(() =>
    this.actions$.pipe(
      ofType(ObjectActions.BULK_DELETE_PRODUCT_FAMILY),
      switchMap((objectData: ObjectActions.BulkDeleteProductFamily) => {
        this.store.dispatch(new AppActions.ShowLoader());

        return from(this.service.bulkDeleteProductFamily(objectData.productFamilyIds)).pipe(
          switchMap((response: BulkResponseDataInterface) => {
            return of(new ObjectActions.BulkDeleteProductFamiliesCompleted(response), new AppActions.HideLoader());
          }),
          catchError((error) => {
            return of(new ObjectActions.FetchError(error), new AppActions.HideLoader());
          }),
        );
      }),
      catchError((error) => {
        return of(new ObjectActions.FetchError(error), new AppActions.HideLoader());
      }),
    ),
  );

  downloadProductFamilyExcel = createEffect(() =>
    this.actions$.pipe(
      ofType(ObjectActions.DOWNLOAD_PRODUCT_FAMILY_EXCEL),
      switchMap((objectData: ObjectActions.DownloadProductFamilyExcel) => {
        this.store.dispatch(new AppActions.ShowLoader());
        this.service.downloadExcel(objectData.withData, objectData.filters);
        return emptyAction;
      }),
      catchError((error) => {
        return of(new ObjectActions.FetchError(error), new AppActions.HideLoader());
      }),
    ),
  );

  uploadProductFamilyExcel = createEffect(() =>
    this.actions$.pipe(
      ofType(ObjectActions.UPLOAD_PRODUCT_FAMILY_EXCEL),
      switchMap((objectData: ObjectActions.UploadProductFamilyExcel) => {
        this.store.dispatch(new AppActions.ShowLoader());

        return this.service.uploadExcel(objectData.productFamily).pipe(
          switchMap((response: BulkResponseDataInterface) => {
            this.errorMessageService.getTranslatedErrorMessage(response.data);
            const mergedArray = this.excelHelperService.mergeBulkResponseWithRequestData<any>(
              response,
              objectData.productFamily,
            );
            return of(new ObjectActions.UploadProductFamilyExcelCompleted(mergedArray), new AppActions.HideLoader());
          }),
          catchError((error) => {
            return of(new ObjectActions.FetchError(error), new AppActions.HideLoader());
          }),
        );
      }),
      catchError((error) => {
        return of(new ObjectActions.FetchError(error), new AppActions.HideLoader());
      }),
    ),
  );

  getProductFamilyDataForCount = createEffect(() =>
    this.actions$.pipe(
      ofType(ObjectActions.PRODUCT_FAMILY_COUNT_LOAD),
      switchMap((payload: ObjectActions.ProductFamilyCountLoad) => {
        const httpParams: HttpParams = new HttpParams()
          .set('s', JSON.stringify({ siteId: { $eq: payload.siteId } }))
          .append('limit', String(payload.limit || 1000));
        return this.service.getProductFamily(httpParams).pipe(
          switchMap((response: GetManyResponseInterface<IProductFamily>) => {
            return of(new ObjectActions.ProductFamilyCountLoaded(response.data));
          }),
          catchError((errorRes) => {
            return of(new ObjectActions.FetchError(errorRes), new AppActions.HideLoader());
          }),
        );
      }),
      catchError((errorRes) => {
        return of(new ObjectActions.FetchError(errorRes), new AppActions.HideLoader());
      }),
    ),
  );
}
