import { Component } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateModule } from '@ngx-translate/core';
import { ScwMatButtonModule } from '../../../shared/component/scw-mat-ui/scw-mat-button/scw-mat-button.module';

export enum EApplyChangesModalResult {
  Apply,
  DoNotApply,
}

@Component({
  selector: 'app-apply-changes-modal',
  standalone: true,
  imports: [ScwMatButtonModule, TranslateModule],
  templateUrl: './apply-changes-modal.component.html',
  styleUrls: ['./apply-changes-modal.component.scss'],
})
export class ApplyChangesModalComponent {
  public readonly Result = EApplyChangesModalResult;

  constructor(public activeModal: NgbActiveModal) {}
}
