import * as Actions from './bill-of-materials.actions';
import { IBillOfMaterialsState } from './bill-of-materials.model';

export const billOfMaterialsState: IBillOfMaterialsState = {
  billOfMaterialsDataLoading: false,
  billOfMaterialsDataLoaded: false,
  billOfMaterialsData: null,
  product: null,
  productsLoading: false,
  productsLoaded: false,
  products: null,
  addBillOfMaterialsLoading: false,
  addBillOfMaterialsLoaded: false,
  addBillOfMaterialsItemLoading: false,
  addBillOfMaterialsItemLoaded: false,
  editBillOfMaterialsLoading: false,
  editBillOfMaterialsLoaded: false,
  deleteBillOfMaterialsLoading: false,
  deleteBillOfMaterialsLoaded: false,
  deleteBillOfMaterialsBulkLoading: false,
  deleteBillOfMaterialsBulkLoaded: false,
};

export function billOfMaterialsReducer(
  state: IBillOfMaterialsState = billOfMaterialsState,
  action: Actions.BillOfMaterialsActions,
): IBillOfMaterialsState {
  switch (action.type) {
    case Actions.EBillOfMaterialsAction.GET_BILL_OF_MATERIALS_DATA:
      return {
        ...state,
        billOfMaterialsDataLoading: true,
        billOfMaterialsDataLoaded: false,
      };
    case Actions.EBillOfMaterialsAction.GET_BILL_OF_MATERIALS_DATA_COMPLETED:
      return {
        ...state,
        billOfMaterialsData: action.payload.billOfMaterials,
        product: action.payload.product.data,
        billOfMaterialsDataLoading: false,
        billOfMaterialsDataLoaded: true,
      };
    case Actions.EBillOfMaterialsAction.GET_PRODUCTS:
      return {
        ...state,
        productsLoading: true,
        productsLoaded: false,
      };
    case Actions.EBillOfMaterialsAction.GET_PRODUCTS_COMPLETED:
      return {
        ...state,
        products: action.payload,
        productsLoading: false,
        productsLoaded: true,
      };
    case Actions.EBillOfMaterialsAction.ADD_BILL_OF_MATERIALS:
      return {
        ...state,
        addBillOfMaterialsLoading: true,
        addBillOfMaterialsLoaded: false,
      };
    case Actions.EBillOfMaterialsAction.ADD_BILL_OF_MATERIALS_COMPLETED:
      return {
        ...state,
        addBillOfMaterialsLoading: false,
        addBillOfMaterialsLoaded: true,
      };
    case Actions.EBillOfMaterialsAction.ADD_BILL_OF_MATERIALS_ITEM:
      return {
        ...state,
        addBillOfMaterialsItemLoading: true,
        addBillOfMaterialsItemLoaded: false,
      };
    case Actions.EBillOfMaterialsAction.ADD_BILL_OF_MATERIALS_ITEM_COMPLETED:
      return {
        ...state,
        addBillOfMaterialsItemLoading: false,
        addBillOfMaterialsItemLoaded: true,
      };
    case Actions.EBillOfMaterialsAction.EDIT_BILL_OF_MATERIALS:
      return {
        ...state,
        editBillOfMaterialsLoading: true,
        editBillOfMaterialsLoaded: false,
      };
    case Actions.EBillOfMaterialsAction.EDIT_BILL_OF_MATERIALS_COMPLETED:
      return {
        ...state,
        editBillOfMaterialsLoading: false,
        editBillOfMaterialsLoaded: true,
      };
    case Actions.EBillOfMaterialsAction.DELETE_BILL_OF_MATERIALS:
      return {
        ...state,
        deleteBillOfMaterialsLoading: true,
        deleteBillOfMaterialsLoaded: false,
      };
    case Actions.EBillOfMaterialsAction.DELETE_BILL_OF_MATERIALS_COMPLETED:
      return {
        ...state,
        deleteBillOfMaterialsLoading: false,
        deleteBillOfMaterialsLoaded: true,
      };
    case Actions.EBillOfMaterialsAction.DELETE_BILL_OF_MATERIALS_BULK:
      return {
        ...state,
        deleteBillOfMaterialsBulkLoading: true,
        deleteBillOfMaterialsBulkLoaded: false,
      };
    case Actions.EBillOfMaterialsAction.DELETE_BILL_OF_MATERIALS_BULK_COMPLETED:
      return {
        ...state,
        deleteBillOfMaterialsBulkLoading: false,
        deleteBillOfMaterialsBulkLoaded: true,
      };
    case Actions.EBillOfMaterialsAction.FETCH_ERROR:
      return {
        ...state,
        billOfMaterialsDataLoading: false,
        billOfMaterialsDataLoaded: false,
        productsLoading: false,
        productsLoaded: false,
      };
    default:
      return state;
  }
}
