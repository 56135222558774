import * as _ from 'lodash';
import { ScwMatInput } from '../../component/scw-mat-ui/scw-mat-input/scw-mat-input.model';
import {
  DropdownOptionInterface,
  ScwMatRulesInterface,
} from '../../component/scw-mat-ui/scw-mat-select/scw-mat-select.model';
import {
  ComponentNamesForUserConfiguration,
  IComponentConfiguration,
  IUserConfiguration,
  UserConfigurationStateInterface,
} from '../../../store/user-configuration/user-configuration.model';
import { TabRowInterface } from '../../component/side-config-bar/side-config-bar.model';
import { ICustomConfigurationDbEntry } from '../../component/page-configuration/page-configuration.model';
import { filter, map, shareReplay, take } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { OeeAppState } from '../../../store/oee.reducer';
import * as UserConfigurationActions from '../../../store/user-configuration/user-configuration.actions';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { BaseCrudResponse } from '../../model/interface/crud-response-interface.model';
import { IBulkErrorCountAndData } from './datatable.model';

@Injectable({ providedIn: 'root' })
export class DataTableHelperService {
  constructor(private readonly store: Store<OeeAppState>) {}

  public static onDatatableRowClicked($event, componentName: string): void {
    const { event, item } = $event;
    const index: number = Array.from(event.target.parentElement.children).indexOf(event.target);

    if (
      index !== 0 ||
      event.target.classList.contains('mat-checkbox-inner-container') ||
      event.target.tagName !== 'TD' ||
      DataTableHelperService.preventDatatableRowClick(event)
    ) {
      return;
    }

    _.head(document.getElementById(`${componentName}-checkbox-${item.id}`)?.getElementsByTagName('input'))?.click();
  }

  public static onBulkEditCheckboxChange(
    inputParameters: {
      value: boolean;
      index: string;
      formComponentInstance: ScwMatInput;
      defaultValue?: any;
    },
    inputFormParameters: {
      form: any;
      rules: ScwMatRulesInterface;
      areAnyOfTheBulkEditFormSelectBoxesSelected: string;
      isBulk: boolean;
      dropdownOptions: { [dropdownName: string]: DropdownOptionInterface[] };
    },
    dependentInputParameters?: {
      index: string;
      formComponentInstance: ScwMatInput;
    }[],
  ): void {
    if (inputParameters.value) {
      _.set(
        inputFormParameters.form,
        `${inputParameters.index}.rules`,
        _.get(inputFormParameters.rules, inputParameters.index),
      );

      if (inputParameters.defaultValue !== undefined) {
        _.set(inputFormParameters.form, `${inputParameters.index}.value`, inputParameters.defaultValue);
      }
    } else {
      inputParameters.formComponentInstance.reset();
      _.set(inputFormParameters.form, `${inputParameters.index}.rules`, []);
      _.set(
        inputFormParameters.form,
        `${inputParameters.index}.value`,
        Array.isArray(_.get(inputFormParameters.form, `${inputParameters.index}.value`)) ? [] : null,
      );

      for (const dependentInput of dependentInputParameters ?? []) {
        dependentInput.formComponentInstance.reset();
        _.set(inputFormParameters.form, `${dependentInput.index}.rules`, []);
        _.set(
          inputFormParameters.form,
          `${dependentInput.index}.value`,
          Array.isArray(_.get(inputFormParameters.form, `${dependentInput.index}.value`)) ? [] : null,
        );
        _.set(inputFormParameters.form, `${dependentInput.index}.isEnabled`, false);
        _.set(inputFormParameters.dropdownOptions, dependentInput.index, []);
      }
    }

    inputFormParameters.areAnyOfTheBulkEditFormSelectBoxesSelected = _.findKey(inputFormParameters.form, (item) => {
      return item.isEnabled;
    });
  }

  public onSetAsDefault(
    pageStore: keyof OeeAppState,
    componentName: ComponentNamesForUserConfiguration,
    columnKeyPropertyName: string = 'name',
  ): void {
    const dashboardModeAvailablePages = [
      {
        regularName: ComponentNamesForUserConfiguration.WorkOrderSchedule,
        dashboardName: ComponentNamesForUserConfiguration.WorkOrderScheduleDashboardMode,
      },
      {
        regularName: ComponentNamesForUserConfiguration.ProductionReviewRegularMode,
        dashboardName: ComponentNamesForUserConfiguration.ProductionReviewDashboardMode,
      },
      {
        regularName: ComponentNamesForUserConfiguration.KpiViewComponent,
        dashboardName: ComponentNamesForUserConfiguration.KpiViewComponentDashboardMode,
      },
    ];
    const pagesWithGroupedColumns: string[] = [ComponentNamesForUserConfiguration.ShiftSummary];
    let selectedColumns: TabRowInterface[] = [];
    let selectedGroups: TabRowInterface[] = [];
    let customConfiguration: ICustomConfigurationDbEntry[] = [];

    this.store
      .select(pageStore)
      .pipe(take(1))
      .subscribe((state) => {
        selectedColumns = state['tableSettings'];
        selectedGroups = state['tableGroupSettings'];
        customConfiguration = state['customConfiguration'] ?? [];
      });

    const configuration: IUserConfiguration = {
      [componentName]: [
        {
          name: 'selectedColumns',
          value: selectedColumns.reduce((filtered, column) => {
            if (column.selected) {
              filtered.push({ name: column[columnKeyPropertyName], type: column.type });
            }

            return filtered;
          }, []),
        },
      ],
    };

    if (pagesWithGroupedColumns.includes(componentName)) {
      configuration[componentName].push({
        name: 'selectedGroups',
        value: selectedGroups.reduce((filtered, column) => {
          if (column.selected) {
            filtered.push({ name: column[columnKeyPropertyName], type: column.type });
          }

          return filtered;
        }, []),
      });
    }

    configuration[componentName] = configuration[componentName].concat(customConfiguration);

    for (const page of dashboardModeAvailablePages) {
      if (componentName !== page.regularName && componentName !== page.dashboardName) {
        continue;
      }

      let oldConfiguration: IComponentConfiguration[] = [];
      this.store
        .select('userConfigurationStore')
        .pipe(take(1))
        .subscribe((state: UserConfigurationStateInterface) => {
          oldConfiguration = _.cloneDeep(state.userConfigurationData[page.regularName] ?? []);
          _.remove(oldConfiguration, { name: 'isDashboardModeDefault' });

          if (componentName === page.regularName) {
            oldConfiguration = configuration[page.regularName];
          }
        });

      configuration[page.regularName] = [
        ...oldConfiguration,
        {
          name: 'isDashboardModeDefault',
          value: componentName === page.dashboardName,
        },
      ];

      break;
    }

    this.store.dispatch(new UserConfigurationActions.UpdateUserConfigurationLoading(configuration));
  }

  public static onChangeTableCheckBox<T, F>(event: MatCheckboxChange, items: number[], pageData: T[]): F {
    const inputValue: number = Number(event?.source?.value);

    if (event.checked) {
      items.push(inputValue);
    } else {
      items.splice(items.indexOf(inputValue), 1);
    }

    return _.find(pageData, { id: items[0] });
  }

  public updateSelectedItems(event: MatCheckboxChange, items: number[]): void {
    const inputValue: number = Number(event?.source?.value);

    if (event.checked) {
      items.push(inputValue);
    } else {
      items.splice(items.indexOf(inputValue), 1);
    }
  }

  public getDeleteFailedRowsObservable<T extends { id: number }>(
    deleteResults$: Observable<BaseCrudResponse[]>,
    datatableRows: () => T[],
    selectedItems: () => number[],
  ): Observable<IBulkErrorCountAndData<T>> {
    return deleteResults$.pipe(
      filter((response: BaseCrudResponse[]) => Boolean(response.length)),
      map((response: BaseCrudResponse[]) => {
        return response.reduce(
          (reducePayload, { success }, index): IBulkErrorCountAndData<T> => {
            return {
              count: reducePayload.count + Number(!success),
              rows: [...reducePayload.rows, datatableRows().find((row) => row.id === selectedItems()[index])],
            };
          },
          { count: 0, rows: [] } as IBulkErrorCountAndData<T>,
        );
      }),
      shareReplay(1),
    );
  }

  private static preventDatatableRowClick(event): boolean {
    let tagName = event.target.tagName;
    let element = event.target;
    let prevent = false;

    while (tagName !== 'TD' && element) {
      tagName = element.parentElement?.tagName;
      element = element.parentElement;

      if (element?.classList.contains('prevent-datatable-row-click')) {
        prevent = true;
        break;
      }
    }

    if (element.firstElementChild?.classList?.contains('prevent-datatable-row-click')) {
      prevent = true;
    }

    return prevent;
  }
}
