import { Actions, createEffect, ofType } from '@ngrx/effects';
import * as ObjectActions from './lines.actions';
import { catchError, switchMap } from 'rxjs/operators';
import { HttpParams } from '@angular/common/http';
import { from, of } from 'rxjs';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import * as oeeAppReducer from '../../../oee.reducer';
import { LinesService } from './lines.service';
import { ResponseArrayInterface } from '../../../../shared/model/interface/generic-api-response.model';
import { ILines } from './lines.model';
import { LineAvailabilityPlanDataInterface } from '../../../line-availability/line-availability.model';
import { ILayout } from '../../home-page-display-settings/home-page-display-settings.model';
import * as AppActions from '../../../app/actions';
import {
  BaseOneResponseInterface,
  BulkResponseDataInterface,
} from '../../../../shared/model/interface/crud-response-interface.model';
import { ErrorMessageService } from '../../../../shared/service/error-message.service';
import { ExcelHelperService } from '../../../../shared/service/excel/excel-helper.service';
import { LinesExcelService } from './lines-excel.service';
import { LineCRUDInterface, TaskFilterCRUDInterface } from '../../../../shared/component/filter/filter.class';
import { emptyAction } from '../../../../../constants';
import { HelperService } from '../../../../shared/service/helper.service';
import { TaskService } from '../../../../shared/service/filter/task.service';
import { EntityTranslatorService } from '../../../../shared/service/entity-translator/entity-translator.service';

@Injectable()
export class LinesEffects {
  constructor(
    private readonly actions$: Actions,
    private readonly store: Store<oeeAppReducer.OeeAppState>,
    private readonly linesService: LinesService,
    private readonly taskService: TaskService,
    private entityTranslator: EntityTranslatorService,
    private readonly linesExcelService: LinesExcelService,
    private readonly errorMessageService: ErrorMessageService,
    private readonly excelHelperService: ExcelHelperService,
  ) {}

  getLines = createEffect(() =>
    this.actions$.pipe(
      ofType(ObjectActions.LINES_DATA_LOAD),
      switchMap((object: ObjectActions.LinesDataLoad) => {
        const andConditions = [];
        let httpParams = new HttpParams()
          .append('join', 'layoutAssignment')
          .append('join', 'layoutAssignment.layout')
          .append('join', 'lineTypeName')
          .append('join', 'shiftPlan')
          .append('join', 'schedulerShiftPlanHistory')
          .append('join', 'schedulerShiftPlanHistory.schedulerShiftPlan')
          .append('page', String(object.tableQuery.page))
          .append('limit', String(object.tableQuery.pageSize || 1000));

        if (object.tableQuery.orderBy && object.tableQuery.orderDesc !== undefined) {
          httpParams = httpParams.set(
            'sort',
            `${object.tableQuery.orderBy},${object.tableQuery.orderDesc ? 'DESC' : 'ASC'}`,
          );
        }

        if (object.tableQuery.search) {
          andConditions.push({ title: { $cont: object.tableQuery.search } });
        }

        if (object.tableQuery.siteIds && object.tableQuery.siteIds.length > 0) {
          andConditions.push({ siteId: { $in: object.tableQuery.siteIds } });
        }

        if (andConditions.length > 0) {
          httpParams = httpParams.set('s', JSON.stringify({ $and: andConditions }));
        }

        return this.linesService.getLines(httpParams).pipe(
          switchMap((response: ResponseArrayInterface<ILines>) => {
            return of(new ObjectActions.LinesDataLoaded(response));
          }),
          catchError((error) => {
            return of(new ObjectActions.FetchError(error), new AppActions.HideLoader());
          }),
        );
      }),
      catchError((error) => {
        return of(new ObjectActions.FetchError(error), new AppActions.HideLoader());
      }),
    ),
  );

  getShiftPlans = createEffect(() =>
    this.actions$.pipe(
      ofType(ObjectActions.SHIFT_PLAN_DATA_LOAD),
      switchMap((object: ObjectActions.ShiftPlanDataLoad) => {
        return this.linesService.getShiftPlans(...HelperService.argumentClone(object.httpParams)).pipe(
          switchMap((response: ResponseArrayInterface<LineAvailabilityPlanDataInterface>) => {
            return of(new ObjectActions.ShiftPlanDataLoaded(response));
          }),
          catchError((error) => {
            return of(new ObjectActions.FetchError(error), new AppActions.HideLoader());
          }),
        );
      }),
      catchError((error) => {
        return of(new ObjectActions.FetchError(error), new AppActions.HideLoader());
      }),
    ),
  );

  getTasks = createEffect(() =>
    this.actions$.pipe(
      ofType(ObjectActions.TASK_DATA_LOAD),
      switchMap((object: ObjectActions.TasksDataLoad) => {
        return from(this.taskService.getData(...HelperService.argumentClone(object.httpParams))).pipe(
          switchMap((data: TaskFilterCRUDInterface[]) => {
            data.forEach((taskFilter: TaskFilterCRUDInterface) => {
              this.taskService.setTaskOriginalTitle(taskFilter);
              this.entityTranslator.translate(taskFilter);
            });

            return of(new ObjectActions.TasksDataLoaded(data));
          }),
          catchError(() => {
            return of(new ObjectActions.TasksDataLoaded([]));
          }),
        );
      }),
      catchError((error) => {
        return of(new ObjectActions.FetchError(error), new AppActions.HideLoader());
      }),
    ),
  );

  getLayouts = createEffect(() =>
    this.actions$.pipe(
      ofType(ObjectActions.LAYOUT_DATA_LOAD),
      switchMap((object: ObjectActions.LayoutDataLoad) => {
        return this.linesService.getLayouts(...HelperService.argumentClone(object.httpParams)).pipe(
          switchMap((response: ResponseArrayInterface<ILayout>) => {
            return of(new ObjectActions.LayoutDataLoaded(response));
          }),
          catchError((error) => {
            return of(new ObjectActions.FetchError(error), new AppActions.HideLoader());
          }),
        );
      }),
      catchError((error) => {
        return of(new ObjectActions.FetchError(error), new AppActions.HideLoader());
      }),
    ),
  );

  downloadLineExcel = createEffect(() =>
    this.actions$.pipe(
      ofType(ObjectActions.DOWNLOAD_LINES_EXCEL),
      switchMap((objectData: ObjectActions.DownloadLinesExcel) => {
        this.store.dispatch(new AppActions.ShowLoader());
        this.linesExcelService.downloadExcel(objectData.withData, objectData.filters);
        return emptyAction;
      }),
      catchError((error) => {
        return of(new ObjectActions.FetchError(error), new AppActions.HideLoader());
      }),
    ),
  );

  uploadLineExcel = createEffect(() =>
    this.actions$.pipe(
      ofType(ObjectActions.UPLOAD_LINES_EXCEL),
      switchMap((objectData: ObjectActions.UploadLinesExcel) => {
        this.store.dispatch(new AppActions.ShowLoader());

        return this.linesExcelService.uploadExcel(objectData.lines).pipe(
          switchMap((response: BulkResponseDataInterface) => {
            this.errorMessageService.getTranslatedErrorMessage(response.data);
            const mergedArray = this.excelHelperService.mergeBulkResponseWithRequestData<any>(
              response,
              objectData.lines,
            );
            return of(new ObjectActions.UploadLinesExcelCompleted(mergedArray), new AppActions.HideLoader());
          }),
          catchError((error) => {
            return of(new ObjectActions.FetchError(error), new AppActions.HideLoader());
          }),
        );
      }),
      catchError((error) => {
        return of(new ObjectActions.FetchError(error), new AppActions.HideLoader());
      }),
    ),
  );

  getLineDataForCount = createEffect(() =>
    this.actions$.pipe(
      ofType(ObjectActions.LINES_COUNT_LOAD),
      switchMap((payload: ObjectActions.LinesCountLoad) => {
        const httpParams: HttpParams = new HttpParams()
          .set('s', JSON.stringify({ siteId: { $eq: payload.siteId } }))
          .append('limit', String(payload.limit || 1000));
        return this.linesService.getLines(httpParams).pipe(
          switchMap((response: ResponseArrayInterface<ILines>) => {
            return of(new ObjectActions.LinesCountLoaded(response.data));
          }),
          catchError((errorRes) => {
            return of(new ObjectActions.FetchError(errorRes), new AppActions.HideLoader());
          }),
        );
      }),
      catchError((errorRes) => {
        return of(new ObjectActions.FetchError(errorRes), new AppActions.HideLoader());
      }),
    ),
  );

  addLine = createEffect(() =>
    this.actions$.pipe(
      ofType(ObjectActions.ADD_LINE),
      switchMap((objectData: ObjectActions.AddLine) => {
        this.store.dispatch(new AppActions.ShowLoader());

        return this.linesService.addLine(objectData.line).pipe(
          switchMap((response: BaseOneResponseInterface<LineCRUDInterface>) => {
            return of(new ObjectActions.AddLineCompleted(response), new AppActions.HideLoader());
          }),
          catchError((error) => {
            return of(new ObjectActions.FetchError(error), new AppActions.HideLoader());
          }),
        );
      }),
      catchError((error) => {
        return of(new ObjectActions.FetchError(error), new AppActions.HideLoader());
      }),
    ),
  );

  editLine = createEffect(() =>
    this.actions$.pipe(
      ofType(ObjectActions.EDIT_LINE),
      switchMap((objectData: ObjectActions.EditLine) => {
        this.store.dispatch(new AppActions.ShowLoader());

        return this.linesService.editLine(objectData.line, objectData.id).pipe(
          switchMap((response: BaseOneResponseInterface<LineCRUDInterface>) => {
            return of(new ObjectActions.EditLineCompleted(response), new AppActions.HideLoader());
          }),
          catchError((error) => {
            return of(new ObjectActions.FetchError(error), new AppActions.HideLoader());
          }),
        );
      }),
      catchError((error) => {
        return of(new ObjectActions.FetchError(error), new AppActions.HideLoader());
      }),
    ),
  );

  deleteLine = createEffect(() =>
    this.actions$.pipe(
      ofType(ObjectActions.DELETE_LINE),
      switchMap((objectData: ObjectActions.DeleteLine) => {
        this.store.dispatch(new AppActions.ShowLoader());

        return this.linesService.deleteLine(objectData.id).pipe(
          switchMap((response: BaseOneResponseInterface<LineCRUDInterface>) => {
            return of(new ObjectActions.DeleteLineCompleted(response), new AppActions.HideLoader());
          }),
          catchError((error) => {
            return of(new ObjectActions.FetchError(error), new AppActions.HideLoader());
          }),
        );
      }),
      catchError((error) => {
        return of(new ObjectActions.FetchError(error), new AppActions.HideLoader());
      }),
    ),
  );

  deleteLines = createEffect(() =>
    this.actions$.pipe(
      ofType(ObjectActions.BULK_DELETE_LINES),
      switchMap((objectData: ObjectActions.DeleteLines) => {
        this.store.dispatch(new AppActions.ShowLoader());

        return this.linesService.deleteLines(objectData.ids).pipe(
          switchMap((response: BaseOneResponseInterface<LineCRUDInterface>) => {
            return of(new ObjectActions.DeleteLinesCompleted(response), new AppActions.HideLoader());
          }),
          catchError((error) => {
            return of(new ObjectActions.FetchError(error), new AppActions.HideLoader());
          }),
        );
      }),
      catchError((error) => {
        return of(new ObjectActions.FetchError(error), new AppActions.HideLoader());
      }),
    ),
  );

  editLines = createEffect(() =>
    this.actions$.pipe(
      ofType(ObjectActions.BULK_EDIT_LINES),
      switchMap((objectData: ObjectActions.EditLines) => {
        this.store.dispatch(new AppActions.ShowLoader());

        return this.linesService.editLines(objectData.lines).pipe(
          switchMap((response: BaseOneResponseInterface<LineCRUDInterface>) => {
            return of(new ObjectActions.EditLinesCompleted(response), new AppActions.HideLoader());
          }),
          catchError((error) => {
            return of(new ObjectActions.FetchError(error), new AppActions.HideLoader());
          }),
        );
      }),
      catchError((error) => {
        return of(new ObjectActions.FetchError(error), new AppActions.HideLoader());
      }),
    ),
  );
}
