import { TranslateService } from '@ngx-translate/core';
import { ITableHeader, PageConfigurationTypes } from '../../../../constants.model';
import {
  EScheduleAdherenceKpiNames,
  TScheduleAdherenceKpiIcons,
  TScheduleAdherenceKpiTitle,
  TScheduleAdherenceKpiTooltipMessage,
} from './schedule-adherence-kpi-cards/schedule-adherence-kpi-cards.model';
import {
  EWidgetType,
  WidgetConfigurationInterface,
} from '../../../shared/component/page-configuration/page-configuration.model';
import { EScheduleAdherenceAnalysisMode } from '../../../store/reports/schedule-adherence/schedule-adherence.model';
import { EKpiCardContentType } from '../../../shared/component/scw-mat-ui/scw-mat-kpi-card/scw-mat-kpi-card.model';

export class ScheduleAdherenceUtilities {
  public static getScheduleAdherencePageConfigHeaders(translate: TranslateService): ITableHeader[] {
    return [
      {
        value: 'jobName',
        name: translate.instant('pageConfiguration.modal.scheduleAdherence.table.job.job'),
        sortable: true,
        selected: true,
        disabled: false,
        type: PageConfigurationTypes.TABLE,
      },
      {
        value: 'jobCoverage',
        name: translate.instant('pageConfiguration.modal.scheduleAdherence.table.nestedWorkOrders.jobCoverage'),
        sortable: true,
        selected: true,
        disabled: false,
        type: PageConfigurationTypes.TABLE,
      },
      {
        value: 'assignedLinePathName',
        name: translate.instant('pageConfiguration.modal.scheduleAdherence.table.nestedWorkOrders.assignedLinePath'),
        sortable: true,
        selected: true,
        disabled: false,
        type: PageConfigurationTypes.TABLE,
      },
      {
        value: 'assignedLineWithinLinePathName',
        name: translate.instant('pageConfiguration.modal.scheduleAdherence.table.nestedWorkOrders.assignedLineWithinLinePath'),
        sortable: true,
        selected: true,
        disabled: false,
        type: PageConfigurationTypes.TABLE,
      },
      {
        value: 'scheduledLinePathName',
        name: translate.instant('pageConfiguration.modal.scheduleAdherence.table.nestedWorkOrders.scheduledLinePath'),
        sortable: true,
        selected: true,
        disabled: false,
        type: PageConfigurationTypes.TABLE,
      },
      {
        value: 'actualLine',
        name: translate.instant('scheduleAdherence.table.actualLine'),
        sortable: true,
        selected: true,
        disabled: false,
        type: PageConfigurationTypes.TABLE,
      },
      {
        value: 'scheduledLine',
        name: translate.instant('scheduleAdherence.table.scheduledLine'),
        sortable: true,
        selected: true,
        disabled: false,
        type: PageConfigurationTypes.TABLE,
      },
      {
        value: 'workOrder',
        name: translate.instant('scheduleAdherence.table.workOrder'),
        sortable: true,
        selected: true,
        disabled: false,
        type: PageConfigurationTypes.TABLE,
      },
      {
        value: 'productName',
        name: translate.instant('scheduleAdherence.table.productId'),
        sortable: true,
        selected: true,
        disabled: false,
        type: PageConfigurationTypes.TABLE,
      },
      {
        value: 'productDescription',
        name: translate.instant('scheduleAdherence.table.productDescription'),
        sortable: true,
        selected: true,
        disabled: false,
        type: PageConfigurationTypes.TABLE,
      },
      {
        value: 'productFamilyName',
        name: translate.instant('scheduleAdherence.table.productFamily'),
        sortable: true,
        selected: true,
        disabled: false,
        type: PageConfigurationTypes.TABLE,
      },
      {
        value: 'scheduledPreRun',
        name: translate.instant('scheduleAdherence.table.scheduledPreRun'),
        sortable: true,
        selected: true,
        disabled: false,
        type: PageConfigurationTypes.TABLE,
      },
      {
        value: 'actualPreRun',
        name: translate.instant('scheduleAdherence.table.actualPreRun'),
        sortable: true,
        selected: true,
        disabled: false,
        type: PageConfigurationTypes.TABLE,
      },
      {
        value: 'varianceOfPreRun',
        name: translate.instant('scheduleAdherence.table.varianceOfPreRun'),
        sortable: true,
        selected: true,
        disabled: false,
        type: PageConfigurationTypes.TABLE,
      },
      {
        value: 'scheduledRun',
        name: translate.instant('scheduleAdherence.table.scheduledRun'),
        sortable: true,
        selected: true,
        disabled: false,
        type: PageConfigurationTypes.TABLE,
      },
      {
        value: 'actualRun',
        name: translate.instant('scheduleAdherence.table.actualRun'),
        sortable: true,
        selected: true,
        disabled: false,
        type: PageConfigurationTypes.TABLE,
      },
      {
        value: 'varianceOfRun',
        name: translate.instant('scheduleAdherence.table.varianceOfRun'),
        sortable: true,
        selected: true,
        disabled: false,
        type: PageConfigurationTypes.TABLE,
      },
      {
        value: 'scheduledPostRun',
        name: translate.instant('scheduleAdherence.table.scheduledPostRun'),
        sortable: true,
        selected: true,
        disabled: false,
        type: PageConfigurationTypes.TABLE,
      },
      {
        value: 'actualPostRun',
        name: translate.instant('scheduleAdherence.table.actualPostRun'),
        sortable: true,
        selected: true,
        disabled: false,
        type: PageConfigurationTypes.TABLE,
      },
      {
        value: 'varianceOfPostRun',
        name: translate.instant('scheduleAdherence.table.varianceOfPostRun'),
        sortable: true,
        selected: true,
        disabled: false,
        type: PageConfigurationTypes.TABLE,
      },
      {
        value: 'dueDate',
        name: translate.instant('pageConfiguration.modal.scheduleAdherence.table.dueDateWorkOrdersJobs'),
        sortable: true,
        selected: true,
        disabled: false,
        type: PageConfigurationTypes.TABLE,
      },
      {
        value: 'scheduledStartDate',
        name: translate.instant('scheduleAdherence.table.scheduledStartDate'),
        sortable: true,
        selected: true,
        disabled: false,
        type: PageConfigurationTypes.TABLE,
      },
      {
        value: 'actualStartDate',
        name: translate.instant('scheduleAdherence.table.actualStartDate'),
        sortable: true,
        selected: true,
        disabled: false,
        type: PageConfigurationTypes.TABLE,
      },
      {
        value: 'scheduledEndDate',
        name: translate.instant('scheduleAdherence.table.scheduledEndDate'),
        sortable: true,
        selected: true,
        disabled: false,
        type: PageConfigurationTypes.TABLE,
      },
      {
        value: 'actualEndDate',
        name: translate.instant('scheduleAdherence.table.actualEndDate'),
        sortable: true,
        selected: true,
        disabled: false,
        type: PageConfigurationTypes.TABLE,
      },
      {
        value: 'goodCount',
        name: translate.instant('scheduleAdherence.table.goodCount'),
        sortable: true,
        selected: false,
        disabled: false,
        type: PageConfigurationTypes.TABLE,
      },
      {
        value: 'scrapCount',
        name: translate.instant('scheduleAdherence.table.scrapCount'),
        sortable: true,
        selected: false,
        disabled: false,
        type: PageConfigurationTypes.TABLE,
      },
      {
        value: 'quantityOrdered',
        name: translate.instant('scheduleAdherence.table.quantityOrdered'),
        sortable: true,
        selected: false,
        disabled: false,
        type: PageConfigurationTypes.TABLE,
      },
    ];
  }

  public static getScheduleAdherenceWorkOrdersTableHeaders(translate: TranslateService): ITableHeader[] {
    return [
      {
        value: 'actualLine',
        name: translate.instant('scheduleAdherence.table.actualLine'),
        sortable: true,
        selected: true,
        disabled: false,
        type: PageConfigurationTypes.TABLE,
      },
      {
        value: 'scheduledLine',
        name: translate.instant('scheduleAdherence.table.scheduledLine'),
        sortable: true,
        selected: true,
        disabled: false,
        type: PageConfigurationTypes.TABLE,
      },
      {
        value: 'workOrder',
        name: translate.instant('scheduleAdherence.table.workOrder'),
        sortable: true,
        selected: true,
        disabled: false,
        type: PageConfigurationTypes.TABLE,
      },
      {
        value: 'scheduledPreRun',
        name: translate.instant('scheduleAdherence.table.scheduledPreRun'),
        sortable: true,
        selected: true,
        disabled: false,
        type: PageConfigurationTypes.TABLE,
      },
      {
        value: 'actualPreRun',
        name: translate.instant('scheduleAdherence.table.actualPreRun'),
        sortable: true,
        selected: true,
        disabled: false,
        type: PageConfigurationTypes.TABLE,
      },
      {
        value: 'varianceOfPreRun',
        name: translate.instant('scheduleAdherence.table.varianceOfPreRun'),
        sortable: true,
        selected: true,
        disabled: false,
        type: PageConfigurationTypes.TABLE,
      },
      {
        value: 'scheduledRun',
        name: translate.instant('scheduleAdherence.table.scheduledRun'),
        sortable: true,
        selected: true,
        disabled: false,
        type: PageConfigurationTypes.TABLE,
      },
      {
        value: 'actualRun',
        name: translate.instant('scheduleAdherence.table.actualRun'),
        sortable: true,
        selected: true,
        disabled: false,
        type: PageConfigurationTypes.TABLE,
      },
      {
        value: 'varianceOfRun',
        name: translate.instant('scheduleAdherence.table.varianceOfRun'),
        sortable: true,
        selected: true,
        disabled: false,
        type: PageConfigurationTypes.TABLE,
      },
      {
        value: 'scheduledPostRun',
        name: translate.instant('scheduleAdherence.table.scheduledPostRun'),
        sortable: true,
        selected: true,
        disabled: false,
        type: PageConfigurationTypes.TABLE,
      },
      {
        value: 'actualPostRun',
        name: translate.instant('scheduleAdherence.table.actualPostRun'),
        sortable: true,
        selected: true,
        disabled: false,
        type: PageConfigurationTypes.TABLE,
      },
      {
        value: 'varianceOfPostRun',
        name: translate.instant('scheduleAdherence.table.varianceOfPostRun'),
        sortable: true,
        selected: true,
        disabled: false,
        type: PageConfigurationTypes.TABLE,
      },
      {
        value: 'dueDate',
        name: translate.instant('scheduleAdherence.table.woDueDate'),
        sortable: true,
        selected: true,
        disabled: false,
        type: PageConfigurationTypes.TABLE,
      },
      {
        value: 'scheduledStartDate',
        name: translate.instant('scheduleAdherence.table.scheduledStartDate'),
        sortable: true,
        selected: true,
        disabled: false,
        type: PageConfigurationTypes.TABLE,
      },
      {
        value: 'actualStartDate',
        name: translate.instant('scheduleAdherence.table.actualStartDate'),
        sortable: true,
        selected: true,
        disabled: false,
        type: PageConfigurationTypes.TABLE,
      },
      {
        value: 'scheduledEndDate',
        name: translate.instant('scheduleAdherence.table.scheduledEndDate'),
        sortable: true,
        selected: true,
        disabled: false,
        type: PageConfigurationTypes.TABLE,
      },
      {
        value: 'actualEndDate',
        name: translate.instant('scheduleAdherence.table.actualEndDate'),
        sortable: true,
        selected: true,
        disabled: false,
        type: PageConfigurationTypes.TABLE,
      },
      {
        value: 'goodCount',
        name: translate.instant('scheduleAdherence.table.goodCount'),
        sortable: true,
        selected: false,
        disabled: false,
        type: PageConfigurationTypes.TABLE,
      },
      {
        value: 'scrapCount',
        name: translate.instant('scheduleAdherence.table.scrapCount'),
        sortable: true,
        selected: false,
        disabled: false,
        type: PageConfigurationTypes.TABLE,
      },
      {
        value: 'quantityOrdered',
        name: translate.instant('scheduleAdherence.table.quantityOrdered'),
        sortable: true,
        selected: false,
        disabled: false,
        type: PageConfigurationTypes.TABLE,
      },
      {
        value: 'productName',
        name: translate.instant('scheduleAdherence.table.productId'),
        sortable: true,
        selected: false,
        disabled: false,
        type: PageConfigurationTypes.TABLE,
      },
      {
        value: 'productDescription',
        name: translate.instant('scheduleAdherence.table.productDescription'),
        sortable: true,
        selected: false,
        disabled: false,
        type: PageConfigurationTypes.TABLE,
      },
      {
        value: 'productFamilyName',
        name: translate.instant('scheduleAdherence.table.productFamily'),
        sortable: true,
        selected: false,
        disabled: false,
        type: PageConfigurationTypes.TABLE,
      },
    ];
  }

  public static getScheduleAdherenceJobTableHeaders(translate: TranslateService): ITableHeader[] {
    return [
      {
        value: 'jobName',
        name: translate.instant('scheduleAdherence.table.job.job'),
        selected: true,
        disabled: true,
        type: PageConfigurationTypes.TABLE,
      },
      {
        value: 'jobCoverage',
        name: translate.instant('scheduleAdherence.table.job.jobCoverage'),
        sortable: true,
        selected: false,
        disabled: false,
        type: PageConfigurationTypes.TABLE,
      },
      {
        value: 'scheduledPreRun',
        name: translate.instant('scheduleAdherence.table.scheduledPreRun'),
        sortable: true,
        selected: true,
        disabled: false,
        type: PageConfigurationTypes.TABLE,
      },
      {
        value: 'actualPreRun',
        name: translate.instant('scheduleAdherence.table.actualPreRun'),
        sortable: true,
        selected: true,
        disabled: false,
        type: PageConfigurationTypes.TABLE,
      },
      {
        value: 'varianceOfPreRun',
        name: translate.instant('scheduleAdherence.table.varianceOfPreRun'),
        sortable: true,
        selected: true,
        disabled: false,
        type: PageConfigurationTypes.TABLE,
      },
      {
        value: 'scheduledRun',
        name: translate.instant('scheduleAdherence.table.scheduledRun'),
        sortable: true,
        selected: true,
        disabled: false,
        type: PageConfigurationTypes.TABLE,
      },
      {
        value: 'actualRun',
        name: translate.instant('scheduleAdherence.table.actualRun'),
        sortable: true,
        selected: true,
        disabled: false,
        type: PageConfigurationTypes.TABLE,
      },
      {
        value: 'varianceOfRun',
        name: translate.instant('scheduleAdherence.table.varianceOfRun'),
        sortable: true,
        selected: true,
        disabled: false,
        type: PageConfigurationTypes.TABLE,
      },
      {
        value: 'scheduledPostRun',
        name: translate.instant('scheduleAdherence.table.scheduledPostRun'),
        sortable: true,
        selected: true,
        disabled: false,
        type: PageConfigurationTypes.TABLE,
      },
      {
        value: 'actualPostRun',
        name: translate.instant('scheduleAdherence.table.actualPostRun'),
        sortable: true,
        selected: true,
        disabled: false,
        type: PageConfigurationTypes.TABLE,
      },
      {
        value: 'varianceOfPostRun',
        name: translate.instant('scheduleAdherence.table.varianceOfPostRun'),
        sortable: true,
        selected: true,
        disabled: false,
        type: PageConfigurationTypes.TABLE,
      },
      {
        value: 'dueDate',
        name: translate.instant('scheduleAdherence.table.job.jobDueDate'),
        sortable: true,
        selected: true,
        disabled: false,
        type: PageConfigurationTypes.TABLE,
      },
      {
        value: 'scheduledStartDate',
        name: translate.instant('scheduleAdherence.table.scheduledStartDate'),
        sortable: true,
        selected: true,
        disabled: false,
        type: PageConfigurationTypes.TABLE,
      },
      {
        value: 'actualStartDate',
        name: translate.instant('scheduleAdherence.table.actualStartDate'),
        sortable: true,
        selected: true,
        disabled: false,
        type: PageConfigurationTypes.TABLE,
      },
      {
        value: 'scheduledEndDate',
        name: translate.instant('scheduleAdherence.table.scheduledEndDate'),
        sortable: true,
        selected: true,
        disabled: false,
        type: PageConfigurationTypes.TABLE,
      },
      {
        value: 'actualEndDate',
        name: translate.instant('scheduleAdherence.table.actualEndDate'),
        sortable: true,
        selected: true,
        disabled: false,
        type: PageConfigurationTypes.TABLE,
      },
    ];
  }

  public static getScheduleAdherenceJobNestedWorkOrdersTableHeaders(translate: TranslateService): ITableHeader[] {
    return [
      {
        value: 'actualLine',
        name: translate.instant('scheduleAdherence.table.actualLine'),
        sortable: true,
        selected: true,
        disabled: false,
        type: PageConfigurationTypes.TABLE,
      },
      {
        value: 'scheduledLine',
        name: translate.instant('scheduleAdherence.table.scheduledLine'),
        sortable: true,
        selected: true,
        disabled: false,
        type: PageConfigurationTypes.TABLE,
      },
      {
        value: 'workOrder',
        name: translate.instant('scheduleAdherence.table.workOrder'),
        sortable: true,
        selected: true,
        disabled: false,
        type: PageConfigurationTypes.TABLE,
      },
      {
        value: 'scheduledPreRun',
        name: translate.instant('scheduleAdherence.table.scheduledPreRun'),
        sortable: true,
        selected: true,
        disabled: false,
        type: PageConfigurationTypes.TABLE,
      },
      {
        value: 'actualPreRun',
        name: translate.instant('scheduleAdherence.table.actualPreRun'),
        sortable: true,
        selected: true,
        disabled: false,
        type: PageConfigurationTypes.TABLE,
      },
      {
        value: 'varianceOfPreRun',
        name: translate.instant('scheduleAdherence.table.varianceOfPreRun'),
        sortable: true,
        selected: true,
        disabled: false,
        type: PageConfigurationTypes.TABLE,
      },
      {
        value: 'scheduledRun',
        name: translate.instant('scheduleAdherence.table.scheduledRun'),
        sortable: true,
        selected: true,
        disabled: false,
        type: PageConfigurationTypes.TABLE,
      },
      {
        value: 'actualRun',
        name: translate.instant('scheduleAdherence.table.actualRun'),
        sortable: true,
        selected: true,
        disabled: false,
        type: PageConfigurationTypes.TABLE,
      },
      {
        value: 'varianceOfRun',
        name: translate.instant('scheduleAdherence.table.varianceOfRun'),
        sortable: true,
        selected: true,
        disabled: false,
        type: PageConfigurationTypes.TABLE,
      },
      {
        value: 'scheduledPostRun',
        name: translate.instant('scheduleAdherence.table.scheduledPostRun'),
        sortable: true,
        selected: true,
        disabled: false,
        type: PageConfigurationTypes.TABLE,
      },
      {
        value: 'actualPostRun',
        name: translate.instant('scheduleAdherence.table.actualPostRun'),
        sortable: true,
        selected: true,
        disabled: false,
        type: PageConfigurationTypes.TABLE,
      },
      {
        value: 'varianceOfPostRun',
        name: translate.instant('scheduleAdherence.table.varianceOfPostRun'),
        sortable: true,
        selected: true,
        disabled: false,
        type: PageConfigurationTypes.TABLE,
      },
      {
        value: 'dueDate',
        name: translate.instant('scheduleAdherence.table.woDueDate'),
        sortable: true,
        selected: true,
        disabled: false,
        type: PageConfigurationTypes.TABLE,
      },
      {
        value: 'scheduledStartDate',
        name: translate.instant('scheduleAdherence.table.scheduledStartDate'),
        sortable: true,
        selected: true,
        disabled: false,
        type: PageConfigurationTypes.TABLE,
      },
      {
        value: 'actualStartDate',
        name: translate.instant('scheduleAdherence.table.actualStartDate'),
        sortable: true,
        selected: true,
        disabled: false,
        type: PageConfigurationTypes.TABLE,
      },
      {
        value: 'scheduledEndDate',
        name: translate.instant('scheduleAdherence.table.scheduledEndDate'),
        sortable: true,
        selected: true,
        disabled: false,
        type: PageConfigurationTypes.TABLE,
      },
      {
        value: 'actualEndDate',
        name: translate.instant('scheduleAdherence.table.actualEndDate'),
        sortable: true,
        selected: true,
        disabled: false,
        type: PageConfigurationTypes.TABLE,
      },
      {
        value: 'goodCount',
        name: translate.instant('scheduleAdherence.table.goodCount'),
        sortable: true,
        selected: false,
        disabled: false,
        type: PageConfigurationTypes.TABLE,
      },
      {
        value: 'scrapCount',
        name: translate.instant('scheduleAdherence.table.scrapCount'),
        sortable: true,
        selected: false,
        disabled: false,
        type: PageConfigurationTypes.TABLE,
      },
      {
        value: 'quantityOrdered',
        name: translate.instant('scheduleAdherence.table.quantityOrdered'),
        sortable: true,
        selected: false,
        disabled: false,
        type: PageConfigurationTypes.TABLE,
      },
      {
        value: 'assignedLinePathName',
        name: translate.instant('scheduleAdherence.table.nestedWorkOrders.assignedLinePath'),
        sortable: true,
        selected: false,
        disabled: false,
        type: PageConfigurationTypes.TABLE,
      },
      {
        value: 'assignedLineWithinLinePathName',
        name: translate.instant('scheduleAdherence.table.nestedWorkOrders.assignedLineWithinLinePath'),
        sortable: true,
        selected: false,
        disabled: false,
        type: PageConfigurationTypes.TABLE,
      },
      {
        value: 'scheduledLinePathName',
        name: translate.instant('scheduleAdherence.table.nestedWorkOrders.scheduledLinePath'),
        sortable: true,
        selected: false,
        disabled: false,
        type: PageConfigurationTypes.TABLE,
      },
      {
        value: 'productName',
        name: translate.instant('scheduleAdherence.table.productId'),
        sortable: true,
        selected: false,
        disabled: false,
        type: PageConfigurationTypes.TABLE,
      },
      {
        value: 'productDescription',
        name: translate.instant('scheduleAdherence.table.productDescription'),
        sortable: true,
        selected: false,
        disabled: false,
        type: PageConfigurationTypes.TABLE,
      },
      {
        value: 'productFamilyName',
        name: translate.instant('scheduleAdherence.table.productFamily'),
        sortable: true,
        selected: false,
        disabled: false,
        type: PageConfigurationTypes.TABLE,
      },
    ];
  }

  public static getKpiCardsTooltipMessagesForWorkOrderBased(
    translate: TranslateService,
  ): TScheduleAdherenceKpiTooltipMessage {
    return {
      [EScheduleAdherenceKpiNames.OVERDUE_WORK_ORDERS_JOBS]: translate.instant(
        'scheduleAdherence.informationModal.overdueWorkOrders.label',
      ),
      [EScheduleAdherenceKpiNames.BEHIND_SCHEDULE_WORK_ORDERS_JOBS]: translate.instant(
        'scheduleAdherence.informationModal.behindScheduleWorkOrders.label',
      ),
      [EScheduleAdherenceKpiNames.SCHEDULE_COMPLIANCE]: `
        ${translate.instant('scheduleAdherence.informationModal.scheduleCompliance.label.1')}.
        ${translate.instant('scheduleAdherence.informationModal.scheduleCompliance.label.2')}
    `,
      [EScheduleAdherenceKpiNames.SCHEDULE_ADHERENCE]: translate.instant(
        'scheduleAdherence.informationModal.scheduleAdherence.label',
      ),
      [EScheduleAdherenceKpiNames.SCHEDULE_ATTAINMENT]: translate.instant(
        'scheduleAdherence.informationModal.scheduleAttainment.label',
      ),
      [EScheduleAdherenceKpiNames.WORK_ORDER_OR_JOB_ON_TIME_COMPLETION]: translate.instant(
        'scheduleAdherence.informationModal.workOrderOnTimeCompletion.label',
      ),
      [EScheduleAdherenceKpiNames.PLANNED_VS_UNPLANNED]: translate.instant(
        'scheduleAdherence.informationModal.plannedVsUnplanned.label',
      ),
      [EScheduleAdherenceKpiNames.PLANNED_AND_NOT_STARTED]: translate.instant(
        'scheduleAdherence.informationModal.plannedAndNotStarted.label',
      ),
    };
  }

  public static getKpiCardsTooltipMessagesForJobBased(
    translate: TranslateService,
  ): TScheduleAdherenceKpiTooltipMessage {
    return {
      [EScheduleAdherenceKpiNames.OVERDUE_WORK_ORDERS_JOBS]: translate.instant(
        'scheduleAdherence.informationModal.job.overdueJobs.label',
      ),
      [EScheduleAdherenceKpiNames.BEHIND_SCHEDULE_WORK_ORDERS_JOBS]: translate.instant(
        'scheduleAdherence.informationModal.job.behindScheduleWorkOrders.label',
      ),
      [EScheduleAdherenceKpiNames.SCHEDULE_COMPLIANCE]: `
        ${translate.instant('scheduleAdherence.informationModal.scheduleCompliance.label.1')}.
        ${translate.instant('scheduleAdherence.informationModal.scheduleCompliance.label.2')}
    `,
      [EScheduleAdherenceKpiNames.SCHEDULE_ADHERENCE]: translate.instant(
        'scheduleAdherence.informationModal.job.scheduleAdherence.label',
      ),
      [EScheduleAdherenceKpiNames.SCHEDULE_ATTAINMENT]: translate.instant(
        'scheduleAdherence.informationModal.job.scheduleAttainment.label',
      ),
      [EScheduleAdherenceKpiNames.WORK_ORDER_OR_JOB_ON_TIME_COMPLETION]: translate.instant(
        'scheduleAdherence.informationModal.job.workOrderOnTimeCompletion.label',
      ),
      [EScheduleAdherenceKpiNames.PLANNED_VS_UNPLANNED]: translate.instant(
        'scheduleAdherence.informationModal.job.plannedVsUnplanned.label',
      ),
      [EScheduleAdherenceKpiNames.PLANNED_AND_NOT_STARTED]: translate.instant(
        'scheduleAdherence.informationModal.job.plannedAndNotStarted.label',
      ),
    };
  }

  public static getKpiCardsTitles(
    translate: TranslateService,
    analysisMode: EScheduleAdherenceAnalysisMode,
  ): TScheduleAdherenceKpiTitle {
    return {
      [EScheduleAdherenceKpiNames.OVERDUE_WORK_ORDERS_JOBS]: translate.instant(
        `scheduleAdherence.informationModal.${analysisMode}.overdueWorkOrdersOrJobs.title`,
      ),
      [EScheduleAdherenceKpiNames.BEHIND_SCHEDULE_WORK_ORDERS_JOBS]: translate.instant(
        `scheduleAdherence.informationModal.${analysisMode}.behindScheduleWorkOrdersJobs.title`,
      ),
      [EScheduleAdherenceKpiNames.SCHEDULE_COMPLIANCE]: translate.instant(
        `scheduleAdherence.informationModal.${analysisMode}.scheduleCompliance.title`,
      ),
      [EScheduleAdherenceKpiNames.SCHEDULE_ADHERENCE]: translate.instant(
        `scheduleAdherence.informationModal.${analysisMode}.scheduleAdherence.title`,
      ),
      [EScheduleAdherenceKpiNames.SCHEDULE_ATTAINMENT]: translate.instant(
        `scheduleAdherence.informationModal.${analysisMode}.scheduleAttainment.title`,
      ),
      [EScheduleAdherenceKpiNames.WORK_ORDER_OR_JOB_ON_TIME_COMPLETION]: translate.instant(
        `scheduleAdherence.informationModal.${analysisMode}.workOrderJobOnTimeCompletion.title`,
      ),
      [EScheduleAdherenceKpiNames.PLANNED_VS_UNPLANNED]: translate.instant(
        `scheduleAdherence.informationModal.${analysisMode}.plannedVsUnplannedStartedWorkOrderComparison.title`,
      ),
      [EScheduleAdherenceKpiNames.PLANNED_AND_NOT_STARTED]: translate.instant(
        `scheduleAdherence.informationModal.${analysisMode}.plannedAndNotStartedWoJobs.title`,
      ),
    };
  }

  public static getScheduleAdherenceWidgets(translate: TranslateService): WidgetConfigurationInterface<EWidgetType.KPI_CARD>[] {
    const kpiCardsTooltipMessages: TScheduleAdherenceKpiTooltipMessage = {
      [EScheduleAdherenceKpiNames.OVERDUE_WORK_ORDERS_JOBS]: translate.instant(
        'scheduleAdherence.informationModal.overdueWorkOrders.label',
      ),
      [EScheduleAdherenceKpiNames.BEHIND_SCHEDULE_WORK_ORDERS_JOBS]: translate.instant(
        'scheduleAdherence.informationModal.behindScheduleWorkOrders.label',
      ),
      [EScheduleAdherenceKpiNames.SCHEDULE_COMPLIANCE]: `
        ${translate.instant('scheduleAdherence.informationModal.scheduleCompliance.label.1')}.
        ${translate.instant('scheduleAdherence.informationModal.scheduleCompliance.label.2')}
    `,
      [EScheduleAdherenceKpiNames.SCHEDULE_ADHERENCE]: translate.instant(
        'scheduleAdherence.informationModal.scheduleAdherence.label',
      ),
      [EScheduleAdherenceKpiNames.SCHEDULE_ATTAINMENT]: translate.instant(
        'scheduleAdherence.informationModal.scheduleAttainment.label',
      ),
      [EScheduleAdherenceKpiNames.WORK_ORDER_OR_JOB_ON_TIME_COMPLETION]: translate.instant(
        'scheduleAdherence.informationModal.workOrderOnTimeCompletion.label',
      ),
      [EScheduleAdherenceKpiNames.PLANNED_VS_UNPLANNED]: translate.instant(
        'scheduleAdherence.informationModal.plannedVsUnplanned.label',
      ),
      [EScheduleAdherenceKpiNames.PLANNED_AND_NOT_STARTED]: translate.instant(
        'scheduleAdherence.informationModal.plannedAndNotStarted.label',
      ),
    };

    const kpiCardsIcons: TScheduleAdherenceKpiIcons = {
      [EScheduleAdherenceKpiNames.OVERDUE_WORK_ORDERS_JOBS]: 'fas fa-exclamation-triangle',
      [EScheduleAdherenceKpiNames.BEHIND_SCHEDULE_WORK_ORDERS_JOBS]: 'fas fa-calendar-minus',
      [EScheduleAdherenceKpiNames.SCHEDULE_COMPLIANCE]: 'fas fa-clipboard-check',
      [EScheduleAdherenceKpiNames.SCHEDULE_ADHERENCE]: 'fas fa-calendar',
      [EScheduleAdherenceKpiNames.SCHEDULE_ATTAINMENT]: 'fas fa-calendar-check',
      [EScheduleAdherenceKpiNames.WORK_ORDER_OR_JOB_ON_TIME_COMPLETION]: 'fas fa-clock',
      [EScheduleAdherenceKpiNames.PLANNED_VS_UNPLANNED]: 'fas fa-map-pin',
      [EScheduleAdherenceKpiNames.PLANNED_AND_NOT_STARTED]: 'fas fa-minus-circle',
    };

    return [
      {
        name: 'scheduleCompliance',
        type: PageConfigurationTypes.WIDGET,
        selected: true,
        disabled: false,
        content: {
          type: EWidgetType.KPI_CARD,
          pageConfigurationTitle: translate.instant(
            'pageConfiguration.modal.scheduleAdherence.widget.scheduleComplianceForWorkOrderBased',
          ),
          header: [
            {
              type: EKpiCardContentType.TEXT,
              value: translate.instant('pageConfiguration.modal.scheduleAdherence.widget.scheduleCompliance'),
            },
            {
              type: EKpiCardContentType.ICON,
              value: kpiCardsIcons['scheduleCompliance'],
            },
          ],
          body: [
            {
              key: 'scheduleAdherenceBodyValue',
              type: EKpiCardContentType.PROGRESS_BAR,
              value: null,
              class: 'col-10 p-0',
            },
            {
              type: EKpiCardContentType.ICON,
              value: 'fas fa-info-circle',
              iconTooltipText: kpiCardsTooltipMessages['scheduleCompliance'],
              class: 'col-2 p-0 d-flex justify-content-end align-items-end',
              style: {
                color: '#AEB0B2',
                fontSize: '20px',
              },
            },
          ],
        },
      },
      {
        name: 'scheduleAdherence',
        type: PageConfigurationTypes.WIDGET,
        selected: true,
        disabled: false,
        content: {
          type: EWidgetType.KPI_CARD,
          pageConfigurationTitle: translate.instant(
            'pageConfiguration.modal.scheduleAdherence.widget.scheduleAdherence',
          ),
          header: [
            {
              key: 'scheduleAdherenceHeaderValue',
              type: EKpiCardContentType.TEXT,
              value: translate.instant('pageConfiguration.modal.scheduleAdherence.widget.scheduleAdherence'),
            },
            {
              type: EKpiCardContentType.ICON,
              value: kpiCardsIcons['scheduleAdherence'],
            },
          ],
          body: [
            {
              key: 'scheduleAdherenceBodyValue',
              type: EKpiCardContentType.PROGRESS_BAR,
              value: null,
              class: 'col-10 p-0',
            },
            {
              type: EKpiCardContentType.ICON,
              value: 'fas fa-info-circle',
              iconTooltipText: kpiCardsTooltipMessages['scheduleAdherence'],
              class: 'col-2 p-0 d-flex justify-content-end align-items-end',
              style: {
                color: '#AEB0B2',
                fontSize: '20px',
              },
            },
          ],
        },
      },
      {
        name: 'scheduleAttainment',
        type: PageConfigurationTypes.WIDGET,
        selected: true,
        disabled: false,
        content: {
          type: EWidgetType.KPI_CARD,
          pageConfigurationTitle: translate.instant(
            'pageConfiguration.modal.scheduleAdherence.widget.scheduleAttainment',
          ),
          header: [
            {
              key: 'scheduleAdherenceHeaderValue',
              type: EKpiCardContentType.TEXT,
              value: translate.instant('pageConfiguration.modal.scheduleAdherence.widget.scheduleAttainment'),
            },
            {
              type: EKpiCardContentType.ICON,
              value: kpiCardsIcons['scheduleAttainment'],
            },
          ],
          body: [
            {
              key: 'scheduleAdherenceBodyValue',
              type: EKpiCardContentType.PROGRESS_BAR,
              value: null,
              class: 'col-10 p-0',
            },
            {
              type: EKpiCardContentType.ICON,
              value: 'fas fa-info-circle',
              iconTooltipText: kpiCardsTooltipMessages['scheduleAttainment'],
              class: 'col-2 p-0 d-flex justify-content-end align-items-end',
              style: {
                color: '#AEB0B2',
                fontSize: '20px',
              },
            },
          ],
        },
      },
      {
        name: 'plannedVsUnplannedStartedWorkOrderComparison',
        type: PageConfigurationTypes.WIDGET,
        selected: true,
        disabled: false,
        content: {
          type: EWidgetType.KPI_CARD,
          pageConfigurationTitle: translate.instant(
            'pageConfiguration.modal.scheduleAdherence.widget.plannedVsUnplannedStartedWorkOrderComparison',
          ),
          header: [
            {
              key: 'scheduleAdherenceHeaderValue',
              type: EKpiCardContentType.TEXT,
              value: translate.instant(
                'pageConfiguration.modal.scheduleAdherence.widget.plannedVsUnplannedStartedWorkOrderComparison',
              ),
            },
            {
              type: EKpiCardContentType.ICON,
              value: kpiCardsIcons['plannedVsUnplannedStartedWorkOrderComparison'],
            },
          ],
          body: [
            {
              key: 'scheduleAdherenceBodyValue',
              type: EKpiCardContentType.TEXT,
              value: null,
              class: 'col-10 p-0',
            },
            {
              type: EKpiCardContentType.ICON,
              value: 'fas fa-info-circle',
              iconTooltipText: kpiCardsTooltipMessages['plannedVsUnplannedStartedWorkOrderComparison'],
              class: 'col-2 p-0 d-flex justify-content-end align-items-end',
              style: {
                color: '#AEB0B2',
                fontSize: '20px',
              },
            },
          ],
        },
      },
      {
        name: 'workOrderOrJobOnTimeCompletion',
        type: PageConfigurationTypes.WIDGET,
        selected: true,
        disabled: false,
        content: {
          type: EWidgetType.KPI_CARD,
          pageConfigurationTitle: translate.instant(
            'pageConfiguration.modal.scheduleAdherence.widget.workOrderJobOnTimeCompletion',
          ),
          header: [
            {
              key: 'scheduleAdherenceHeaderValue',
              type: EKpiCardContentType.TEXT,
              value: translate.instant('pageConfiguration.modal.scheduleAdherence.widget.workOrderOnTimeCompletion'),
            },
            {
              type: EKpiCardContentType.ICON,
              value: kpiCardsIcons['workOrderOrJobOnTimeCompletion'],
            },
          ],
          body: [
            {
              key: 'scheduleAdherenceBodyValue',
              type: EKpiCardContentType.PROGRESS_BAR,
              value: null,
              class: 'col-10 p-0',
            },
            {
              type: EKpiCardContentType.ICON,
              value: 'fas fa-info-circle',
              iconTooltipText: kpiCardsTooltipMessages['workOrderOrJobOnTimeCompletion'],
              class: 'col-2 p-0 d-flex justify-content-end align-items-end',
              style: {
                color: '#AEB0B2',
                fontSize: '20px',
              },
            },
          ],
        },
      },
      {
        name: 'overdueWorkOrdersJobs',
        type: PageConfigurationTypes.WIDGET,
        selected: true,
        disabled: false,
        content: {
          type: EWidgetType.KPI_CARD,
          pageConfigurationTitle: translate.instant(
            'pageConfiguration.modal.scheduleAdherence.widget.overdueWorkOrdersJobs',
          ),
          header: [
            {
              key: 'scheduleAdherenceHeaderValue',
              type: EKpiCardContentType.TEXT,
              value: translate.instant('pageConfiguration.modal.scheduleAdherence.widget.overdueWorkOrders'),
            },
            {
              type: EKpiCardContentType.ICON,
              value: kpiCardsIcons['overdueWorkOrdersJobs'],
            },
          ],
          body: [
            {
              key: 'scheduleAdherenceBodyValue',
              type: EKpiCardContentType.TEXT,
              value: null,
              class: 'col-10 p-0',
            },
            {
              type: EKpiCardContentType.ICON,
              value: 'fas fa-info-circle',
              iconTooltipText: kpiCardsTooltipMessages['overdueWorkOrdersJobs'],
              class: 'col-2 p-0 d-flex justify-content-end align-items-end',
              style: {
                color: '#AEB0B2',
                fontSize: '20px',
              },
            },
          ],
        },
      },
      {
        name: 'behindScheduleWorkOrdersOrJobs',
        type: PageConfigurationTypes.WIDGET,
        selected: false,
        disabled: false,
        content: {
          type: EWidgetType.KPI_CARD,
          pageConfigurationTitle: translate.instant(
            'pageConfiguration.modal.scheduleAdherence.widget.behindScheduleWorkOrdersJobs',
          ),
          header: [
            {
              key: 'scheduleAdherenceHeaderValue',
              type: EKpiCardContentType.TEXT,
              value: translate.instant('pageConfiguration.modal.scheduleAdherence.widget.behindScheduleWorkOrders'),
            },
            {
              type: EKpiCardContentType.ICON,
              value: kpiCardsIcons['behindScheduleWorkOrdersOrJobs'],
            },
          ],
          body: [
            {
              key: 'scheduleAdherenceBodyValue',
              type: EKpiCardContentType.TEXT,
              value: null,
              class: 'col-10 p-0',
            },
            {
              type: EKpiCardContentType.ICON,
              value: 'fas fa-info-circle',
              iconTooltipText: kpiCardsTooltipMessages['behindScheduleWorkOrdersOrJobs'],
              class: 'col-2 p-0 d-flex justify-content-end align-items-end',
              style: {
                color: '#AEB0B2',
                fontSize: '20px',
              },
            },
          ],
        },
      },
      {
        name: 'plannedAndNotStartedWorkOrdersOrJobs',
        type: PageConfigurationTypes.WIDGET,
        selected: false,
        disabled: false,
        content: {
          type: EWidgetType.KPI_CARD,
          pageConfigurationTitle: translate.instant(
            'pageConfiguration.modal.scheduleAdherence.widget.plannedAndNotStartedWoJobs',
          ),
          header: [
            {
              key: 'scheduleAdherenceHeaderValue',
              type: EKpiCardContentType.TEXT,
              value: translate.instant(
                'pageConfiguration.modal.scheduleAdherence.widget.plannedAndNotStartedWorkOrders',
              ),
            },
            {
              type: EKpiCardContentType.ICON,
              value: kpiCardsIcons['plannedAndNotStartedWorkOrdersOrJobs'],
            },
          ],
          body: [
            {
              key: 'scheduleAdherenceBodyValue',
              type: EKpiCardContentType.TEXT,
              value: null,
              class: 'col-10 p-0',
            },
            {
              type: EKpiCardContentType.ICON,
              value: 'fas fa-info-circle',
              iconTooltipText: kpiCardsTooltipMessages['plannedAndNotStartedWorkOrdersOrJobs'],
              class: 'col-2 p-0 d-flex justify-content-end align-items-end',
              style: {
                color: '#AEB0B2',
                fontSize: '20px',
              },
            },
          ],
        },
      },
    ];
  }
}
