import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, switchMap } from 'rxjs/operators';
import { of } from 'rxjs';
import * as ObjectActions from './notification-scope.actions';
import { Store } from '@ngrx/store';
import * as oeeAppReducer from '../../oee.reducer';
import * as AppActions from '../../app/actions';
import {
  BaseOneResponseInterface,
  BulkResponseDataInterface,
  GetManyResponseInterface,
} from '../../../shared/model/interface/crud-response-interface.model';
import {
  NotificationScopeCRUDInterface,
  NotificationScopeFormDropdownData,
  SimplifiedSiteCRUDInterface,
} from './notification-scope.model';
import { NotificationScopeService } from '../../../shared/service/notification-scope/notification-scope.service';
import { HttpParams } from '@angular/common/http';

@Injectable()
export class NotificationScopeEffects {
  constructor(
    private readonly store: Store<oeeAppReducer.OeeAppState>,
    private readonly actions$: Actions<ObjectActions.NotificationScopeActions>,
    private readonly service: NotificationScopeService,
  ) {}

  $loadNotificationScopes = createEffect(() =>
    this.actions$.pipe(
      ofType(ObjectActions.NotificationScopeActionTypes.LoadNotificationScopes),
      switchMap((objectData: ObjectActions.LoadNotificationScopes) => {
        return this.service.loadNotificationScopes(objectData.parameters).pipe(
          switchMap((response: GetManyResponseInterface<NotificationScopeCRUDInterface>) => {
            return of(new ObjectActions.NotificationScopesLoaded(response));
          }),
          catchError((error) => {
            return of(new ObjectActions.FetchError(error));
          }),
        );
      }),
    ),
  );

  $loadFormDropdownData = createEffect(() =>
    this.actions$.pipe(
      ofType(ObjectActions.NotificationScopeActionTypes.LoadFormDropdownData),
      switchMap(() => {
        this.store.dispatch(new AppActions.ShowLoader());
        return this.service.loadFormData().pipe(
          switchMap((response: NotificationScopeFormDropdownData) => {
            return of(new ObjectActions.FormDropdownDataLoaded(response), new AppActions.HideLoader());
          }),
          catchError(() => {
            return of(new AppActions.HideLoader());
          }),
        );
      }),
    ),
  );

  $createNotificationScope = createEffect(() =>
    this.actions$.pipe(
      ofType(ObjectActions.NotificationScopeActionTypes.CreateNotificationScope),
      switchMap((objectData: ObjectActions.CreateNotificationScope) => {
        this.store.dispatch(new AppActions.ShowLoader());
        return this.service.createNotificationScope(objectData.requestParameters).pipe(
          switchMap((response: BaseOneResponseInterface<NotificationScopeCRUDInterface>) => {
            return of(new ObjectActions.CreateNotificationScopeCompleted(response));
          }),
          catchError(() => {
            return of(new AppActions.HideLoader());
          }),
        );
      }),
    ),
  );

  $updateNotificationScope = createEffect(() =>
    this.actions$.pipe(
      ofType(ObjectActions.NotificationScopeActionTypes.UpdateNotificationScope),
      switchMap((objectData: ObjectActions.UpdateNotificationScope) => {
        this.store.dispatch(new AppActions.ShowLoader());
        return this.service.updateNotificationScope(objectData.id, objectData.requestParameters).pipe(
          switchMap((response: BaseOneResponseInterface<NotificationScopeCRUDInterface>) => {
            return of(new ObjectActions.UpdateNotificationScopeCompleted(response));
          }),
          catchError(() => {
            return of(new AppActions.HideLoader());
          }),
        );
      }),
    ),
  );

  $deleteNotificationScope = createEffect(() =>
    this.actions$.pipe(
      ofType(ObjectActions.NotificationScopeActionTypes.DeleteNotificationScope),
      switchMap((objectData: ObjectActions.DeleteNotificationScope) => {
        this.store.dispatch(new AppActions.ShowLoader());
        return this.service.deleteNotificationScope(objectData.id).pipe(
          switchMap(() => {
            return of(new ObjectActions.DeleteNotificationScopeCompleted());
          }),
          catchError(() => {
            return of(new AppActions.HideLoader());
          }),
        );
      }),
    ),
  );

  $bulkDeleteNotificationScope = createEffect(() =>
    this.actions$.pipe(
      ofType(ObjectActions.NotificationScopeActionTypes.BulkDeleteNotificationScope),
      switchMap((objectData: ObjectActions.BulkDeleteNotificationScope) => {
        this.store.dispatch(new AppActions.ShowLoader());
        return this.service.bulkDeleteNotificationScope(objectData.ids).pipe(
          switchMap((response: BulkResponseDataInterface) => {
            return of(new ObjectActions.BulkDeleteNotificationScopeCompleted(response));
          }),
          catchError(() => {
            return of(new AppActions.HideLoader());
          }),
        );
      }),
    ),
  );

  $getShiftAndTemplateData = createEffect(() =>
    this.actions$.pipe(
      ofType(ObjectActions.NotificationScopeActionTypes.ShiftAndTemplateDataLoading),
      switchMap((objectData: ObjectActions.ShiftAndTemplateDataLoading) => {
        this.store.dispatch(new AppActions.ShowLoader());
        let params: HttpParams = new HttpParams();
        params = params
          .append('fields', 'name,schedulerShiftsSiteAssignment.schedulerShift.name')
          .append('join', 'schedulerShiftsSiteAssignment')
          .append('join', 'schedulerShiftsSiteAssignment.schedulerShift')
          .append('join', 'notificationEmailTemplate');

        return this.service.getShiftAndTemplateData(objectData.siteId, params).pipe(
          switchMap((response: BaseOneResponseInterface<SimplifiedSiteCRUDInterface>) => {
            return of(new ObjectActions.ShiftAndTemplateDataLoaded(response), new AppActions.HideLoader());
          }),
          catchError(() => {
            return of(new AppActions.HideLoader());
          }),
        );
      }),
    ),
  );
}
