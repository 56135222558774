import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ScwMatButtonModule } from 'src/app/shared/component/scw-mat-ui/scw-mat-button/scw-mat-button.module';
import { ScwMatCheckboxModule } from 'src/app/shared/component/scw-mat-ui/scw-mat-checkbox/scw-mat-checkbox.module';
import { INotificationFeedItem } from 'src/app/shared/service/notification-feed/notification-feed.model';
import { NotificationFeedService } from 'src/app/shared/service/notification-feed/notification-feed.service';
import { NotificationFeedStore } from './notification-feed-modal.store';
import { CommonModule } from '@angular/common';
import { NotificationFeedItemComponent } from '../../notification-feed-item/notification-feed-item.component';
import { TranslateModule } from '@ngx-translate/core';
import { ClickOutsideModule } from 'ng-click-outside';
import { LayoutAnimations } from '../../../layout/animations';
import { NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { Observable } from 'rxjs';
import { PushNotificationService } from '../../../store/push-notification/push-notification.service';
import { EPushNotificationStatus } from '../../../store/push-notification/push-notification.model';

@Component({
  animations: [LayoutAnimations.notificationBottom],
  selector: 'app-notification-feed-modal',
  standalone: true,
  imports: [
    CommonModule,
    NgbTooltipModule,
    NotificationFeedItemComponent,
    ScwMatButtonModule,
    ScwMatCheckboxModule,
    TranslateModule,
    ClickOutsideModule,
  ],
  providers: [NotificationFeedStore],
  templateUrl: './notification-feed-modal.component.html',
  styleUrls: ['./notification-feed-modal.component.scss'],
})
export class NotificationFeedModalComponent {
  @Output() clickOutside = new EventEmitter<void>();
  @Output() toggleListItem = new EventEmitter<void>();
  @Output() clickListItem = new EventEmitter<string>();
  @Input() animationState: string = 'an-off';
  @Input() animationClass: string = 'an-off';

  public readonly sections: {
    titleKey: string;
    notifications$: Observable<readonly INotificationFeedItem[]>;
    timeOnly: boolean;
  }[] = [
    {
      titleKey: 'notificationFeed.label.today',
      notifications$: this.localStore.todayNotifications$,
      timeOnly: true,
    },
    {
      titleKey: 'notificationFeed.label.yesterday',
      notifications$: this.localStore.yesterdayNotifications$,
      timeOnly: false,
    },
    {
      titleKey: 'notificationFeed.label.older',
      notifications$: this.localStore.olderNotifications$,
      timeOnly: false,
    },
  ];
  public readonly EPushNotificationStatus: typeof EPushNotificationStatus = EPushNotificationStatus;
  public readonly Notification: typeof Notification | undefined;

  public readonly badgeMaxNumber: number = 9;

  constructor(public readonly localStore: NotificationFeedStore, public readonly service: NotificationFeedService) {
    this.Notification = PushNotificationService.isNotificationSupported() ? Notification : undefined;
  }

  public onToggleListItem(): void {
    this.localStore.setModalOpen(this.isClosed());
    this.toggleListItem.emit();

    this.localStore.loadMoreNotifications();
  }

  public onItemClicked(docId: string): void {
    this.clickListItem.emit(docId);
  }

  public trackByDocId(index: number, notification: INotificationFeedItem): string {
    return notification.docId;
  }

  public openPushNotificationPermissionModal(): void {
    this.service.openPushNotificationPermissionModal();
  }

  private isClosed(): boolean {
    return this.animationClass !== 'show';
  }
}
