import { EquipmentTaskActions, EquipmentTaskActionTypes } from './equipment-task.actions';
import { EquipmentTaskStateInterface } from './equipment-task.model';
import { ELoadStatus } from '../../../constants.model';

export const equipmentTaskInitialState: EquipmentTaskStateInterface = {
  nodes: [],
  breadcrumb: [],
  nodesDataStatus: ELoadStatus.Initial,
  errors: [],
};

export function equipmentTaskReducer(
  state: EquipmentTaskStateInterface = equipmentTaskInitialState,
  action: EquipmentTaskActions,
): EquipmentTaskStateInterface {
  switch (action.type) {
    case EquipmentTaskActionTypes.NodeDataLoading:
      return {
        ...state,
        nodesDataStatus: ELoadStatus.Loading,
      };
    case EquipmentTaskActionTypes.NodeDataLoaded:
      return {
        ...state,
        nodesDataStatus: ELoadStatus.Success,
        nodes: action.payload,
        lastLoadedEquipment: action.lastLoadedEquipment,
        breadcrumb: action.breadcrumb,
      };
    case EquipmentTaskActionTypes.FetchError:
      return {
        ...state,
        nodesDataStatus: ELoadStatus.Failure,
        errors: action.payload,
      };
    default:
      return state;
  }
}
