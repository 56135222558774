<div class="modal-header scw-modal-header">
  <h4 class="modal-title">{{ 'general.warning' | translate }}</h4>

  <button type="button" class="close" aria-label="Close" onfocus="blur()" (click)="activeModal.dismiss()">
    <i aria-hidden class="fas fa-times"></i>
  </button>
</div>

<div class="modal-body">
  <i aria-hidden class="fas fa-exclamation-circle icon"></i>

  <div class="content">
    <p>{{ 'modal.info.discardChanges' | translate }}</p>
  </div>
</div>

<div class="modal-footer">
  <div class="buttons-left">
    <scw-mat-button type="danger" (onClick)="activeModal.close(Result.DoNotApply)">
      {{ 'general.dontApply' | translate }}
    </scw-mat-button>
  </div>

  <div class="buttons-right">
    <scw-mat-button type="standard" (onClick)="activeModal.dismiss()">
      {{ 'general.cancel' | translate }}
    </scw-mat-button>

    <scw-mat-button type="cta" (onClick)="activeModal.close(Result.Apply)">
      {{ 'general.apply' | translate }}
    </scw-mat-button>
  </div>
</div>
