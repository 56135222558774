import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import * as _ from 'lodash';
import { BulkResponseDataInterface } from '../../store/work-order-schedule/work-order-schedule.model';
import { BaseCrudResponse, ValidationErrorInterface } from '../model/interface/crud-response-interface.model';

@Injectable({
  providedIn: 'root',
})
export class ErrorMessageService {
  constructor(public translate: TranslateService) {}
  public getTranslatedErrorMessage(data: BulkResponseDataInterface[]): void {
    try {
      data.forEach((message) => {
        if (!message.success) {
          const messages: string[] = [];
          message.message.forEach((errorMessage) => {
            if (errorMessage.constraints.hasOwnProperty('isNotEmpty')) {
              this.appendApiError(errorMessage, ['isNotEmpty'], messages);
            } else {
              this.appendApiError(errorMessage, Object.keys(errorMessage.constraints), messages);
            }
          });
          _.set(message, 'message', messages);
        }
      });
    } catch (error) {
      if (error instanceof TypeError) {
        return;
      }
      throw error;
    }
  }

  public getOption(errorKey: string, errorMessage: ValidationErrorInterface): string {
    if (errorKey === 'whitelistValidation') {
      const whitelistReason = Object.keys(_.get(errorMessage, `contexts.${errorKey}`, {}))[0] || null;
      const whitelistReasonKey = `apiErrorMessages.${whitelistReason}`;

      return whitelistReason ? `${this.translate.instant(whitelistReasonKey)} ` : '';
    }

    const compareProperty = _.get(errorMessage, `contexts.${errorKey}.compareProperty`, false);

    if (compareProperty) {
      return this.translate.instant(`apiErrorMessages.properties.${compareProperty}`);
    }

    const translateOption = _.get(errorMessage, `contexts.${errorKey}.translateOption`, false);

    if (translateOption) {
      if (_.get(errorMessage, 'contexts.isSoftAccessDenied', false)) {
        return this.translate.instant(translateOption);
      }

      return this.translate.instant(`apiErrorMessages.translateOption.${translateOption}`);
    }

    return _.get(errorMessage, `contexts.${errorKey}.option]`, '');
  }

  public appendApiError(errorMessage: ValidationErrorInterface, errorKeys: string[], messages: string[]): void {
    errorKeys.forEach((errorKey) => {
      this.translate.get(`apiErrorMessages.properties.${errorMessage.property}`).subscribe((property: string) => {
        this.translate
          .get(`apiErrorMessages.${errorKey}`, {
            property,
            option: this.getOption(errorKey, errorMessage),
          })
          .subscribe((translatedErrorMessage: string) => {
            messages.push(translatedErrorMessage);
          });
      });
    });
  }

  public additionalOptions(errorKey: string, errorMessage: ValidationErrorInterface): { [key: string]: string } {
    const additionalOptions = {};
    for (const [key, value] of Object.entries(_.get(errorMessage, `contexts.${errorKey}`, {}))) {
      const translateString = `apiErrorMessages.additionalOptions.${errorKey}.${key}`;
      const translatedResult = this.translate.instant(translateString);

      _.set(additionalOptions, key, translateString === translatedResult ? value : translatedResult);
    }

    return additionalOptions;
  }

  public replaceErrorMessageProperties(
    data: BaseCrudResponse[],
    propertiesToReplace: string[],
    propertiesToReplaceWith: string[],
  ): void {
    const duplicatePropertiesToReplaceWithIndexes: Record<string, number[]> = propertiesToReplaceWith.reduce(
      (propertyNames: Record<string, number[]>, propertyName: string, index: number) => {
        if (!propertyNames[propertyName]) {
          return { ...propertyNames, [propertyName]: [index] };
        }

        propertyNames[propertyName].push(index);

        return propertyNames;
      },
      {},
    );

    data.forEach((row: BaseCrudResponse) => {
      propertiesToReplace.forEach((propertyName: string, index: number) => {
        const errorObjectIndex: number = _.findIndex(row.message, { property: propertyName });
        const newPropertyName: string = propertiesToReplaceWith[index];

        if (errorObjectIndex === -1) {
          return;
        }

        if (duplicatePropertiesToReplaceWithIndexes[newPropertyName].indexOf(index) !== 0) {
          row.message.splice(errorObjectIndex, 1);

          return;
        }

        row.message[errorObjectIndex].property = newPropertyName;
      });
    });
  }
}
