import { Inject, Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { ProductInterface, ProductTableQueryParams } from '../products/products.model';
import { GetManyResponseInterface } from '../../../shared/model/interface/crud-response-interface.model';
import { Observable } from 'rxjs';
import { IProductMultipliers } from '../product-multiplier/product-multiplier.model';
import { IUnitConversion } from '../unit-conversion/unit-conversion.model';
import { IProductConfigurationsData } from '../../../view/settings/product-configurations/station-product-configurations/product-configurations.model';
import { IProductClonePayload } from './product-clone.model';
import { ProductsService } from '../products/products.service';

@Injectable({
  providedIn: 'root',
})
export class ProductCloneService {
  private readonly PRODUCTS = {
    PRODUCT_URL: `${this.baseUrl}/products`,
    PRODUCT_LINE_MULTIPLIER_URL: `${this.baseUrl}/product-multipliers`,
    PRODUCT_UNIT_CONVERSION_URL: `${this.baseUrl}/unit-conversion`,
    PRODUCT_STATION_CONFIGURATION_URL: `${this.baseUrl}/products/product-configurations`,
    PRODUCT_CLONE: `${this.baseUrl}/products/clone`,
  };

  constructor(
    private readonly http: HttpClient,
    @Inject('API_BASE_URL')
    private readonly baseUrl: string,
    private readonly productsService: ProductsService,
  ) {}

  public getSourceProductInformation(sourceProductId: number): Observable<GetManyResponseInterface<ProductInterface>> {
    const fieldsToSelect: (keyof ProductInterface)[] = [
      'packageSize',
      'unit',
      'productSpeed',
      'minimumWaitingDuration',
      'maximumWaitingDuration',
      'customer',
      'productFamily',
      'materialType',
      'planningType',
      'planningGroup',
      'productSpeedTable',
      'linePathProductConfigurations',
    ];

    const params = new HttpParams()
      .set('s', JSON.stringify({ id: { $eq: sourceProductId } }))
      .set('fields', fieldsToSelect.join(','))
      .set('join', 'productSpeedTable')
      .append('join', 'productSpeedTable.line||id,title')
      .append('join', 'linePathProductConfigurations')
      .append('join', 'linePathProductConfigurations.line||id,title')
      .append('join', 'linePathProductConfigurations.linePath||id,name')
      .append('join', 'productFamily||id,name')
      .append('join', 'customer||id,name')
      .append('join', 'unitType||id,name,type');

    return this.http.get<GetManyResponseInterface<ProductInterface>>(this.PRODUCTS.PRODUCT_URL, { params });
  }

  public getSourceProductLineMultipliers(
    sourceProductId: number,
  ): Observable<GetManyResponseInterface<IProductMultipliers>> {
    const params = new HttpParams()
      .set('s', JSON.stringify({ productId: { $eq: sourceProductId } }))
      .set('join', 'line||id,title');

    return this.http.get<GetManyResponseInterface<IProductMultipliers>>(this.PRODUCTS.PRODUCT_LINE_MULTIPLIER_URL, {
      params,
    });
  }

  public getSourceProductUnitMultipliers(
    sourceProductId: number,
  ): Observable<GetManyResponseInterface<IUnitConversion>> {
    const params = new HttpParams()
      .append('join', 'baseUnitLookup||id,name,type')
      .append('join', 'referenceUnitLookup||id,name,type')
      .set('s', JSON.stringify({ productId: { $eq: sourceProductId } }));

    return this.http.get<GetManyResponseInterface<IUnitConversion>>(this.PRODUCTS.PRODUCT_UNIT_CONVERSION_URL, {
      params,
    });
  }

  public getSourceProductStationConfigurations(
    sourceProductId: number,
  ): Observable<GetManyResponseInterface<IProductConfigurationsData>> {
    const params = new HttpParams().append('productIds', sourceProductId).append('page', '1').append('offset', '100');

    return this.http.get<GetManyResponseInterface<IProductConfigurationsData>>(
      this.PRODUCTS.PRODUCT_STATION_CONFIGURATION_URL,
      { params },
    );
  }

  public cloneProduct(payload: IProductClonePayload): Observable<any> {
    return this.http.post(this.PRODUCTS.PRODUCT_CLONE, payload);
  }

  public getProductSearchParams(tableQuery: ProductTableQueryParams): string {
    return this.productsService.getProductSearchParams(tableQuery);
  }
}
