import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges } from '@angular/core';
import {
  ECheckInDestinationType,
  ECheckInSourceType,
  ILaborAssetViewData,
} from '../../../../store/dashboards/labor-asset-view/labor-asset-view.model';
import {
  ECicoAvailability,
  ECicoDestinationType,
  ECicoType,
  ICicoData,
} from '../../../../shared/component/cico/cico.model';
import { TranslateService } from '@ngx-translate/core';
import { EGroupBy, EQueryType, GroupByDropdownOption, IGroupedData, IGroupedDataInfo } from '../labor-asset-view.model';
import * as _ from 'lodash';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { OeeAppState } from '../../../../store/oee.reducer';
import * as UserActions from '../../../../store/user/actions';
import * as HomeActions from '../../../../store/home/home.actions';
import { DecimalHelper } from '../../../../shared/helper/decimal/decimal-helper';
import { DECIMAL_DEFAULT_SCALE_LIMIT } from '../../../../../constants';
import { ofType } from '@ngrx/effects';
import { ActionsSubject } from '@ngrx/store';
import { Subscription } from 'rxjs';

@Component({
  selector: 'scw-labor-asset-view-card-view',
  templateUrl: './labor-asset-view-card-view.component.html',
  styleUrls: ['./labor-asset-view-card-view.component.scss'],
})
export class LaborAssetViewCardViewComponent implements OnInit, OnChanges, OnDestroy {
  @Input() laborAssetViewData: ILaborAssetViewData[] = [];
  @Input() timeToggle!: EQueryType;
  @Input() isExpanded: boolean = false;
  @Input() selectMode: boolean = false;
  @Input() showWorkOrderRow: boolean = true;
  @Input() isMatMenuVisible: boolean = true;
  @Input() groupBy: GroupByDropdownOption[] = [];
  @Input() isCollapsedGroups: boolean = false;
  @Output() onChangeSelectedItems: EventEmitter<number[]> = new EventEmitter<number[]>();
  private readonly subscriptions: Subscription[] = [];
  public groupedData: IGroupedData = {};
  public groupedDataKeys: string[] = [];
  private readonly shortHour: string = this.translate.instant('general.shortHour');

  private readonly userDefaultIconCls: string = 'fas fa-user';
  private readonly assetDefaultIconCls: string = 'fas fa-cube';
  public cicoItems: ICicoData[] = [];
  public progressBarText: string = this.translate.instant('cico.operatingTimeAndShiftDuration');
  public collapsedStates: boolean[] = [];
  public link: string = null;

  constructor(
    private readonly translate: TranslateService,
    private readonly router: Router,
    private readonly store: Store<OeeAppState>,
    private readonly decimalHelper: DecimalHelper,
    private readonly storeActions: ActionsSubject,
  ) {}

  public ngOnInit(): void {
    this.subscriptions.push(
      this.storeActions
        .pipe(ofType(UserActions.UPDATE_CURRENT_USER_LOADED))
        .subscribe((payload: UserActions.UpdateCurrentUserLoaded) => {
          if (payload.navigateToHomeAfterwards) {
            this.router.navigate([this.link]);
          }
        }),
    );
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes.hasOwnProperty('laborAssetViewData') && Array.isArray(this.laborAssetViewData)) {
      this.progressBarText = this.translate.instant(
        this.timeToggle === EQueryType.ONGOING ? 'cico.operatingTimeAndShiftDuration' : 'cico.operatingTimeAnd24Hours',
      );

      this.prepareCicoData();
    }

    if (changes['groupBy'] && !changes['groupBy'].isFirstChange()) {
      this.groupCicoData();
    }

    if (changes['isCollapsedGroups'] && !changes['isCollapsedGroups'].isFirstChange()) {
      this.collapsedStates = new Array(this.groupedDataKeys.length).fill(this.isCollapsedGroups);
    }
  }

  private prepareCicoData(): void {
    this.cicoItems = this.laborAssetViewData.map((data: ILaborAssetViewData): ICicoData => {
      return {
        id: (_.find(data.checkIns, { checkOutTime: null }) ?? _.last(data.checkIns)).checkInId,
        fullName: data.fullName,
        status: data.isOffline
          ? ECicoAvailability.offline
          : data.isAvailable
          ? ECicoAvailability.available
          : ECicoAvailability.busy,
        tagId: data.tagId,
        tagName: data.tagName,
        tagColor: data.tagColor,
        avatarPath: data.avatarPath,
        avatarIsLoading: false,
        avatar: null,
        destination: {
          type:
            data.destinationTypeId === ECheckInDestinationType.LINE
              ? ECicoDestinationType.LINE
              : ECicoDestinationType.STATION,
          object: {
            objectId: data.destinationObjectId,
            objectName:
              data.destinationTypeId === ECheckInDestinationType.LINE_STATION ? data.stationName : data.lineName,
            lineName: data.lineName,
          },
        },
        lineId: data.lineId,
        lineName: data.lineName,
        siteId: data.siteId,
        checkInTime: data.minCalculatedCheckInTime,
        actualCheckInTime: data.minCheckInTime,
        checkOutTime: data.maxCalculatedCheckOutTime,
        actualCheckOutTime: data.maxCheckOutTime,
        type: data.sourceTypeId === ECheckInSourceType.USER ? ECicoType.LABOR : ECicoType.ASSET,
        iconCls: data.sourceTypeId === ECheckInSourceType.USER ? this.userDefaultIconCls : this.assetDefaultIconCls,
        scheduledCheckInTime: data.scheduledCheckInTime,
        scheduledCheckOutTime: data.scheduledCheckOutTime,
        scheduledDuration: data.scheduledDuration,
        scheduledDestination: {
          type:
            data.scheduledDestinationTypeId === ECheckInDestinationType.LINE
              ? ECicoDestinationType.LINE
              : ECicoDestinationType.STATION,
          object: {
            objectId: data.scheduledDestinationObjectId,
            objectName: data.scheduledDestinationName,
          },
        },
        shiftDuration: data.shiftDuration,
        shiftStartDate: data.shiftStartDate,
        shiftEndDate: data.shiftEndDate,
        meta: {
          levelId: data.levelId,
          woNumber: data.woNumber,
        },
        checkIns: data.checkIns,
        tagIds: data.tagIds,
        sourceObjectId: data.sourceObjectId,
        woNumber: data.woNumber,
        workOrderId: data.workOrderId,
        lineTypeId: data.lineTypeId,
        lineType: data.lineType,
        stationName: data.stationName,
        stationId: data.stationId,
        productTableId: data.productTableId,
        productId: data.productId,
        jobId: data.jobId,
        jobName: data.jobName,
        linePathName: data.linePathName,
        linePathId: data.linePathId,
        sourceTypeId: data.sourceTypeId,
        isAvailable: data.isAvailable,
        isOffline: data.isOffline,
      };
    });
    this.groupCicoData();
  }

  public onCicoItemSelectionChange(selectedItems: number[]): void {
    this.onChangeSelectedItems.emit(selectedItems);
  }

  public groupCicoData(): void {
    this.groupedData = {};

    if (!this.groupBy || this.groupBy.length === 0) {
      this.groupedData[''] = {
        titleValue: '',
        value: this.cicoItems,
        link: '',
        info: null,
      };
    } else {
      this.cicoItems.forEach((item: ICicoData) => {
        const groupBy: GroupByDropdownOption = this.groupBy[0];
        const groupKey: string = item[groupBy?.id]?.toString() || 'other';
        const groupTitle: string = item[groupBy?.titleValue as string] || this.translate.instant('general.other');

        const link =
          groupKey !== 'other' && !_.isNil(groupBy?.link)
            ? groupBy.linkId
              ? `${groupBy.link}${item[groupBy.linkId]?.toString()}`
              : groupBy.link
            : null;

        if (!this.groupedData[groupKey]) {
          this.groupedData[groupKey] = {
            titleValue: groupTitle,
            link: link,
            value: [],
            info: null,
          };
        }

        this.groupedData[groupKey].value.push(item);
      });
    }

    this.sortGroupedData();
    this.updateGroupedDataInfo();
  }

  private updateGroupedDataInfo(): void {
    for (let key of this.groupedDataKeys) {
      this.groupedData[key].info = this.getCheckInInfo(this.groupedData[key].value);
    }
  }

  private getCheckInInfo(value: any): IGroupedDataInfo {
    const formatResult = (fn: (val: any) => number | string) =>
      fn(value) ? fn(value).toString() : this.translate.instant('general.N/A');

    return {
      totalCheckIns: formatResult(LaborAssetViewCardViewComponent.totalUniqueCheckIns),
      availableLaborAsset: formatResult(LaborAssetViewCardViewComponent.availableUserCount),
      laborAssetHours: formatResult((val) =>
        LaborAssetViewCardViewComponent.calculateTotalCheckInHours(val, this.decimalHelper, this.shortHour),
      ),
    };
  }

  private static calculateTotalCheckInHours(
    laborAssetViewData: ICicoData[],
    decimalHelper: DecimalHelper,
    hour: string,
  ): string {
    let totalHoursInSeconds: number = 0;

    laborAssetViewData.forEach((dataRow: ICicoData) => {
      totalHoursInSeconds += _.sumBy(dataRow.checkIns, 'duration');
    });

    const totalHours: string = decimalHelper.divide(String(totalHoursInSeconds), '3600');

    return `${decimalHelper.toFixedValue(totalHours, DECIMAL_DEFAULT_SCALE_LIMIT)} ${hour}`;
  }

  private static availableUserCount(laborAssetViewData: ICicoData[]): number {
    const availableLabors: ILaborAssetViewData[] = _.filter(laborAssetViewData, {
      sourceTypeId: ECheckInSourceType.USER,
      isAvailable: true,
      isOffline: false,
    });
    const availableAssets: ILaborAssetViewData[] = _.filter(laborAssetViewData, {
      sourceTypeId: ECheckInSourceType.EQUIPMENT,
      isOffline: false,
    });

    return Object.keys(
      _.uniqBy(availableLabors.concat(availableAssets), LaborAssetViewCardViewComponent.generateCompareSourceObject),
    ).length;
  }

  private static totalUniqueCheckIns(laborAssetViewData: ICicoData[]): number {
    return Object.keys(_.uniqBy(laborAssetViewData, LaborAssetViewCardViewComponent.generateCompareSourceObject))
      .length;
  }

  private static generateCompareSourceObject(value: ICicoData) {
    return `${value.sourceTypeId}|${value.sourceObjectId}`;
  }

  public toggleCollapse(index: number): void {
    this.collapsedStates[index] = !this.collapsedStates[index];
  }

  public navigateToPage(groupKey: string, link: string): void {
    if (link === null) {
      return;
    }

    if (this.groupBy[0] && [EGroupBy.STATION, EGroupBy.LINE].includes(this.groupBy[0].id)) {
      this.link = link;
      this.store.dispatch(
        new UserActions.UpdateCurrentUser(
          Number(this.groupedData[groupKey].value[0].siteId),
          Number(this.groupedData[groupKey].value[0].lineId),
          true,
          false,
        ),
      );
      this.store.dispatch(new HomeActions.HomeMetricSetItemsSetDefaultState());
      this.store.dispatch(new HomeActions.ResetPhaseDurationOnSiteLineSelection());
    } else {
      this.router.navigate([link]);
    }
  }

  public sortGroupedData(): void {
    this.groupedDataKeys = Object.keys(this.groupedData).sort((a: string, b: string) => {
      if (a === 'other') return 1;
      if (b === 'other') return -1;

      const lengthA = this.groupedData[a].value.length;
      const lengthB = this.groupedData[b].value.length;

      if (lengthB === lengthA) {
        const titleA = this.groupedData[a].titleValue;
        const titleB = this.groupedData[b].titleValue;
        return titleA.localeCompare(titleB);
      }

      return lengthB - lengthA;
    });
    this.collapsedStates = new Array(this.groupedDataKeys.length).fill(this.isCollapsedGroups);
  }

  public ngOnDestroy(): void {
    this.subscriptions.forEach((subscription: Subscription): void => {
      subscription.unsubscribe();
    });
  }
}
