<progress-bar-metric
  [actualValue]="actualDuration"
  [actualValueUnit]="'general.shortHour' | translate"
  [expectedValue]="targetDuration"
  [expectedValueUnit]="'general.shortHour' | translate"
  [progressBarTitle]="'stationHomeKpiCard.workOrderDuration.label' | translate"
  [progressBarRateCapped]="targetRateCapped"
  [progressBarRate]="targetRate"
>
</progress-bar-metric>
