import * as Actions from './push-notification.actions';
import { IPushNotificationState } from './push-notification.model';

export const pushNotificationState: IPushNotificationState = {
  subscribeToUserUniqueTopic: false,
  subscribeToUserUniqueTopicCompleted: false,
};

export function pushNotificationReducer(
  state: IPushNotificationState = pushNotificationState,
  action: Actions.PushNotificationActions,
): IPushNotificationState {
  switch (action.type) {
    case Actions.SUBSCRIBE_TO_USER_UNIQUE_TOPIC:
      return {
        ...state,
        subscribeToUserUniqueTopic: true,
        subscribeToUserUniqueTopicCompleted: false,
      };
    case Actions.SUBSCRIBE_TO_USER_UNIQUE_TOPIC_COMPLETED:
      return {
        ...state,
        subscribeToUserUniqueTopic: false,
        subscribeToUserUniqueTopicCompleted: true,
      };
    default:
      return state;
  }
}
