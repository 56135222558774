import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { ScwMatButtonModule } from '../../shared/component/scw-mat-ui/scw-mat-button/scw-mat-button.module';
import { TranslateModule } from '@ngx-translate/core';
import { ScwMatModalSeparatorModule } from '../../shared/component/scw-mat-ui/scw-mat-modal/scw-mat-modal-separator/scw-mat-modal-separator.module';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ESpeedName } from './speed-info-modal.types';

@Component({
  standalone: true,
  templateUrl: './speed-info-modal.component.html',
  styleUrls: ['./speed-info-modal.component.scss'],
  imports: [CommonModule, ScwMatModalSeparatorModule, ScwMatButtonModule, TranslateModule],
})
export class SpeedInfoModalComponent {
  @Input() public selectedSpeedName: ESpeedName | null = null;

  public readonly speedNamesInOrder: ESpeedName[] = [
    ESpeedName.WORK_ORDER_BASED_TARGET_SPEED,
    ESpeedName.PATH_PRODUCT_LINE_SPEED,
    ESpeedName.PRODUCT_LINE_SPEED,
    ESpeedName.PRODUCT_SPEED,
    ESpeedName.LINE_SPEED,
  ];

  constructor(private readonly activeModal: NgbActiveModal) {}

  public closeModal(): void {
    this.activeModal.close();
  }
}
