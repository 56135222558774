export interface PhaseDurationsInterface {
  preRunDuration: number;
  preRunTarget: string;
  preRunActivity: string;
  runDuration: number;
  runTarget: string;
  runRunActivity: string;
  postRunDuration: number;
  postRunTarget: string;
  postRunActivity: string;
  totalDuration: number;
  totalDurationTarget: number;
  selectedPhase: number;
}

export interface PhaseDurationMetricInterface {
  phaseTimer: string;
  phaseText: string;
  phaseDurationText: string;
  phaseDurationRateCapped: number;
  phaseDurationRate: string;
  phaseDurationTextClass: string;
  roundedTarget: string;
  progressBarClass: string;
  unit: string;
}

export interface PhaseDurationInterface {
  phaseName: string;
  phaseDuration: string;
  phaseTargetDuration: string;
  isCurrent: boolean;
  activityName: string;
  phaseDurationType: PhaseDurationTypeEnum;
}

export enum PhaseDurationTypeEnum {
  preRunDuration = 'preRunDuration',
  runDuration = 'runDuration',
  postRunDuration = 'postRunDuration',
  totalDuration = 'totalDuration',
}
