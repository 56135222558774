import { Action } from '@ngrx/store';
import {
  GetQuantityFromSensorDataInterface,
  IOngoingActivityTotalDuration,
  ManualCountEntrySettingsInterface,
  SetActivityCardDataActionInterface,
  SetWorkOrderCardsActionInterface,
} from './model';

export const SET_MISSING_DATA_COUNT = '[LINE] Set missing data count';
export const SET_WORK_ORDER_CARDS = '[LINE] Set work order card info';
export const SET_ACTIVITY_CARD_DATA = '[LINE] Set activity card data';
export const SET_QUANTITY_FROM_SENSOR = '[LINE] Set quantity from sensor';
export const UPDATE_ACTIVITY = '[LINE] Update activity';
export const SET_MANUAL_COUNT_ENTRY_SETTINGS = '[LINE] Set manual count entry settings';
export const SET_LINE_TYPE = '[LINE] Set Line Type';
export const CLOSE_MISSING_ACTIVITY_MODE = '[LINE] Close Missing Activity Mode';
export const ONGOING_ACTIVITY_TOTAL_DURATION_LOADING = '[ACTIVITY_BUTTON] ONGOING ACTIVITY TOTAL DURATION LOADING';
export const ONGOING_ACTIVITY_TOTAL_DURATION_LOADED = '[ACTIVITY_BUTTON] ONGOING ACTIVITY TOTAL DURATION LOADED';
export const SET_LINE_CHANGED = '[LINE] Set Line Changed';
export const SET_QUANTITIES = '[LINE] Set Quantities';

export class SetMissingDataCount implements Action {
  readonly type = SET_MISSING_DATA_COUNT;

  constructor(public missingDataCount: number) {}
}

export class SetWorkOrderCards implements Action {
  readonly type = SET_WORK_ORDER_CARDS;

  constructor(public payload: SetWorkOrderCardsActionInterface) {}
}

export class SetActivityCardData implements Action {
  readonly type = SET_ACTIVITY_CARD_DATA;

  constructor(public payload: SetActivityCardDataActionInterface) {}
}

export class SetQuantityFromSensor implements Action {
  readonly type = SET_QUANTITY_FROM_SENSOR;

  constructor(public payload: object) {}
}

export class UpdateActivity implements Action {
  readonly type = UPDATE_ACTIVITY;

  constructor(public payload: object) {}
}

export class SetManualCountEntrySettings implements Action {
  readonly type = SET_MANUAL_COUNT_ENTRY_SETTINGS;

  constructor(public payload: ManualCountEntrySettingsInterface) {}
}

export class SetLineType implements Action {
  readonly type = SET_LINE_TYPE;

  constructor(public lineType: number) {}
}

export class CloseMissingActivityMode implements Action {
  readonly type = CLOSE_MISSING_ACTIVITY_MODE;
}

export class OngoingActivityTotalDurationLoading implements Action {
  readonly type = ONGOING_ACTIVITY_TOTAL_DURATION_LOADING;

  constructor(public lineId: number, public stationId?: number) {}
}

export class OngoingActivityTotalDurationLoaded implements Action {
  readonly type = ONGOING_ACTIVITY_TOTAL_DURATION_LOADED;

  constructor(public payload: IOngoingActivityTotalDuration) {}
}

export class SetLineChanged implements Action {
  readonly type = SET_LINE_CHANGED;

  constructor(public lineChanged: boolean) {}
}

export class SetQuantities implements Action {
  readonly type = SET_QUANTITIES;

  constructor(public quantities: GetQuantityFromSensorDataInterface) {}
}

export type LineActions =
  | SetMissingDataCount
  | SetWorkOrderCards
  | SetActivityCardData
  | SetQuantityFromSensor
  | UpdateActivity
  | SetManualCountEntrySettings
  | SetLineType
  | CloseMissingActivityMode
  | OngoingActivityTotalDurationLoading
  | OngoingActivityTotalDurationLoaded
  | SetLineChanged
  | SetQuantities;
