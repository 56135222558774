<div class="d-flex justify-content-end col-max-width">
  <span class="flex-grow-1 overflow-hidden text-truncate">{{ lastComment }}</span>
  <span class="ml-3">
    <app-comment-feed-button
      [activityName]="activityLog.activityName"
      [objectIds]="[activityLog.id]"
      [objectType]="(activityLog.isLine.toString() === '1') ? 'lines' : 'activity_history'"
      size="xs"
      text="{{ 'activityCommentModal.actions.seeAll' | translate }} ({{
        totalComments > seeAllMaxNumber ? seeAllMaxNumber + '+' : totalComments
      }})"
      (onModalCloseEvent)="onCommentModalClosed($event)"
    ></app-comment-feed-button>
  </span>
</div>
