import {
  Component,
  ContentChild,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  TemplateRef,
  ViewChild,
  TrackByFunction,
  OnDestroy,
} from '@angular/core';
import { NgForm } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import * as _ from 'lodash';
import moment from 'moment';
import { Subscription, merge } from 'rxjs';
import { InputLimit } from '../../model/enum/input-limit';
import {
  AddButtonCustomizerModel,
  DataTableButtonInterface,
  DatatableHeaderInterface,
  DatatableOutputParameterInterface,
  DatatableOutputSortInterface,
  DatatableSortInterface,
  ICustomSort,
  IDatatableItem,
  IDatatableOnClickRow,
  SortOrderType,
  TTableHeightMode,
} from './datatable.model';
import { DecimalHelper } from '../../helper/decimal/decimal-helper';
import { ComponentUtilities } from '../../helper/component-utilities';

@Component({
  selector: 'datatable',
  templateUrl: './datatable.component.html',
  styleUrls: ['./datatable.component.scss'],
})
export class DatatableComponent implements OnInit, OnChanges, OnDestroy {
  @ViewChild('scrollingView') scrollingView: ElementRef;
  @Input() headers: DatatableHeaderInterface[];
  @Input() items: IDatatableItem[];
  @Input() rowsPerPage: number = 10;
  @Input() rowsPerPageItems = [
    { text: '10', value: 10 },
    { text: '25', value: 25 },
    { text: '50', value: 50 },
    { text: '100', value: 100 },
  ];
  @Input() serverSide: boolean = false;
  @Input() clientSide: boolean = false;
  @Input() elasticDatatable: boolean = false;
  @Input() elasticDatatableTotalItemCount: number = 0;
  @Input() customSorts: ICustomSort = {};
  @Input() itemsCount: number = 0;
  @Input() currentPage: number = 1;
  @Input() isLoading: boolean = false;
  @Input() search: boolean = false;
  @Input() searchDelay: number = 600;
  @Input() searchPlaceholder: string = this.translate.instant('datatable.search');
  @Input() addButton: boolean = false;
  @Input() addButtonCustomizer: AddButtonCustomizerModel = {
    buttonClass: 'btn btn-primary btn-sm icon-btn ripple light',
    disabled: false,
    iconClass: 'fas fa-plus',
    text: '',
  };
  @Input() theme: 'default' | 'gray' = 'default'; // 'theme' parameter has been deprecated since SCW UI Kit.
  @Input() noDataText: string;
  @Input() paginationSize: 'sm' | 'lg' = 'lg'; // 'paginationSize' parameter has been deprecated since SCW UI Kit.
  @Output() onClickRow = new EventEmitter<IDatatableOnClickRow>();
  @Output() onDataRequest = new EventEmitter<DatatableOutputParameterInterface>();
  @Output() clientSideRequest = new EventEmitter<DatatableOutputParameterInterface>();
  @Output() onAddButtonClick = new EventEmitter<object>();
  @Output() onClickHeaderButton = new EventEmitter<DataTableButtonInterface>();
  @ContentChild(TemplateRef, { static: false }) templateRef: TemplateRef<any>;
  @Input() shouldPaginate: boolean = true;
  @Input() tableStyle: { [klass: string]: any; };
  @Input() topRightContent: TemplateRef<any>;
  @Input() topLeftContent: TemplateRef<any>;
  @Input() searchValue: string = '';
  @Input() headerContent: TemplateRef<any>;
  @Input() footerContent: TemplateRef<any>;
  @Input() justDataTable: boolean = false;
  @Input() stickyLeadColumn: boolean = false;
  @Input() stickyLastColumn: boolean = false;
  @Input() nestedTable: boolean = false;
  /**
   * Determines the behavior of the table height. Possible values are:
   * - `null` (default): The table is as tall as its contents.
   * - `'fill'`: The table attempts to fill all the available space of the viewport. In this mode, **inner scroll is
   *   enabled.**
   * - `'fixed'`: The table always shows exactly 10 rows. In this mode, **inner scroll is enabled**.
   */
  @Input() heightMode: TTableHeightMode = null;
  @Input() trackByProperty?: string | number;

  /**
   * For to be mainly used with clientSide mode
   */
  @Output() onClickPagination = new EventEmitter<DatatableOutputParameterInterface>();
  @Output() onClickSort = new EventEmitter<void>();
  @Output() onHeaderCheckboxClick = new EventEmitter<{ event: any; header: any }>();

  @ViewChild('tableHead') private readonly tableHead: ElementRef;

  public readonly datatableHeaderTrackBy = ComponentUtilities.datatableHeaderTrackBy;

  public pending: boolean = false;
  public paginationCustomizer: string = 'scw-theme-pagination';
  public tableCustomizer: string = 'scw-theme-table';
  protected delayTimer: ReturnType<typeof setTimeout>;
  protected readonly sortOrder: DatatableSortInterface[] = [
    {
      type: 'none',
      sortIconClass: 'none-type',
    },
    {
      type: 'ascending',
      sortIconClass: 'asc',
    },
    {
      type: 'descending',
      sortIconClass: 'desc',
    },
  ];
  protected readonly sortMap = {
    none: 'ascending',
    ascending: 'descending',
    descending: 'none',
  };
  public sortedHeader: DatatableHeaderInterface;
  public currentSort: SortOrderType = 'none';
  public sortedItems: IDatatableItem[];
  protected EMIT_PAGE: number;
  protected EMIT_ROWS_PER_PAGE: number;
  protected EMIT_SORT: DatatableOutputSortInterface;
  protected EMIT_SEARCH: string;

  private scrollToTopSubscription: Subscription | undefined;

  public readonly InputLimit: typeof InputLimit = InputLimit;
  private changeEvent: EventEmitter<undefined> = new EventEmitter<undefined>();
  public entryRangeLowerBound: number = 1;
  public entryRangeUpperBound: number = 1;
  public totalNumberOfItems: number = 1;

  constructor(private readonly translate: TranslateService, private readonly decimalHelper: DecimalHelper) {
    if (this.serverSide) {
      this.pending = true;
    }
  }

  ngOnInit(): void {
    this.scrollToTopSubscription = merge(this.onClickPagination, this.onClickSort, this.changeEvent).subscribe(() => {
      this.scrollingView.nativeElement.scrollTop = 0;

      if (this.heightMode !== null) {
        this.refreshTableHeadStickiness();
      }
    });
  }

  public ngOnChanges(changes: SimpleChanges): void {
    this.pending = false;

    (this.headers ?? []).forEach((item: DatatableHeaderInterface, index: number) => {
      if (typeof this.headers[index].sort === 'undefined') {
        this.headers[index].sort = this.sortOrder[0];
      }

      this.headers[index].pointerEvents = 'all';
    });

    if (this.clientSide && this.sortedHeader && this.currentSort !== 'none' && changes.hasOwnProperty('items')) {
      const sortType: 'desc' | 'asc' = this.currentSort === 'descending' ? 'desc' : 'asc';

      if (DatatableComponent.isHeaderCustomSortEnabled(this.customSorts, this.sortedHeader)) {
        this.customSort(this.sortedHeader, sortType);
      } else {
        this.standardSort(this.sortedHeader.value, sortType);
      }
    }

    if (
      changes['items']?.previousValue !== changes['items']?.currentValue ||
      changes['currentPage']?.previousValue !== changes['currentPage']?.currentValue
    ) {
      this.calculateEntryRangeBounds();
    }

    this.changeEvent.emit();
  }

  ngOnDestroy(): void {
    this.scrollToTopSubscription?.unsubscribe();
  }

  public onChangeDataSearch(tableFilter: NgForm): void {
    if (!tableFilter.form.valid) {
      return;
    }

    this.EMIT_ROWS_PER_PAGE = this.rowsPerPage;
    this.EMIT_PAGE = this.currentPage;

    if (this.serverSide || this.clientSide) {
      clearTimeout(this.delayTimer);
      this.delayTimer = setTimeout(() => {
        this.pending = true;
        this.EMIT_SEARCH = this.searchValue;

        this.onDataRequest.emit({
          page: this.EMIT_PAGE,
          rowsPerPage: this.EMIT_ROWS_PER_PAGE,
          search: this.EMIT_SEARCH,
          sort: this.EMIT_SORT,
        });
      }, this.searchDelay);
    }
  }

  public onChangeDataSort(header: DatatableHeaderInterface, index: number): void {
    if (header.sortable === false) {
      return;
    }

    const requestedSort: DatatableSortInterface = this.sortOrder.find(
      (sort: DatatableSortInterface) => sort.type === this.sortMap[header.sort.type ?? 'none'],
    );

    this.currentSort = requestedSort.type;
    this.sortedHeader = header;

    this.headers.forEach((header: DatatableHeaderInterface, headerIndex: number) => {
      this.headers[headerIndex].pointerEvents = 'none';

      if (headerIndex !== index) {
        this.headers[headerIndex].sort = this.sortOrder[0];
      }
    });

    this.headers[index].sort = requestedSort;

    if (this.serverSide && !this.clientSide) {
      this.EMIT_ROWS_PER_PAGE = this.rowsPerPage;
      this.EMIT_SEARCH = this.searchValue;
      this.EMIT_PAGE = this.currentPage;
      this.EMIT_SORT = { column: header.value, type: requestedSort.type };
      this.pending = true;
      this.onDataRequest.emit({
        page: this.EMIT_PAGE,
        rowsPerPage: this.EMIT_ROWS_PER_PAGE,
        search: this.EMIT_SEARCH,
        sort: this.EMIT_SORT,
      });
    } else if (this.clientSide && this.currentSort !== 'none') {
      const sortType: 'desc' | 'asc' = requestedSort.type === 'descending' ? 'desc' : 'asc';

      if (DatatableComponent.isHeaderCustomSortEnabled(this.customSorts, header)) {
        this.customSort(header, sortType);
      } else {
        this.standardSort(header.value, sortType);
      }
    }

    if (this.currentSort === 'none') {
      this.sortedItems = null;
    }

    this.onClickSort.emit();
  }

  /**
   * Refreshes the sticky style of the table's head row.
   *
   * This is only needed to fix a bug in Safari where `position: sticky` on the head row breaks the alignment of head
   * cells with body cells. Once the table is scrolled in any direction, all cells realign again.
   *
   * This issue was not observed in Chrome.
   *
   * The function temporarily resets the head row's position, then sets it again to `sticky` after a timeout.
   */
  private refreshTableHeadStickiness(): void {
    this.tableHead.nativeElement.style.position = 'initial';
    setTimeout(() => {
      this.tableHead.nativeElement.style.position = 'sticky';
    }, 100);
  }

  private standardSort(header: string, sortType: 'desc' | 'asc'): void {
    this.sortedItems = _.orderBy(
      this.items,
      [
        (item: IDatatableItem) => {
          const property: string | null | undefined = _.get(item, header, '');

          if (_.isNil(property)) {
            return '';
          }

          return String(property).toLowerCase();
        },
      ],
      [sortType],
    );
  }

  private static isHeaderCustomSortEnabled(customSorts: ICustomSort, header: DatatableHeaderInterface): boolean {
    return Boolean(_.get(customSorts, header.value, undefined) || _.get(customSorts, `${header.value}.type`));
  }

  private customSort(header: DatatableHeaderInterface, sortType: 'desc' | 'asc'): void {
    const headerToBeSortedWith: string = this.customSorts[header.value]['sortColumnId'];
    const customSort = this.customSorts[header.value];

    switch (customSort.type) {
      case 'number':
        this.sortedItems = _.orderBy(
          this.items,
          [(item: IDatatableItem): number => {
            const value: string | number | undefined = _.get(item, headerToBeSortedWith ?? header.value, -1);

            if (_.isNil(value)) {
              return -1;
            }

            return typeof value === 'number' ? value : Number(this.decimalHelper.sanitizeString(this.decimalHelper.removeThousandSeparator(String(value))));
          }],
          [sortType],
        );
        break;

      case 'date':
        this.sortedItems = _.orderBy(
          this.items,
          [
            (item: IDatatableItem): Date => {

            if (item[headerToBeSortedWith] !== undefined && !item[headerToBeSortedWith]) {
              return new Date(-1);
            }

            return moment(
                _.get(item, headerToBeSortedWith ?? header.value, moment()),
                _.get(this.customSorts, `${header.value}.dateFormat`, undefined),
              ).toDate();
            }
          ],
          [sortType],
        );
        break;

      case 'duration':
        this.sortedItems = _.orderBy(
          this.items,
          [
            (item: IDatatableItem): number => {
              const hourMinuteArray: string[] = _.get(item, headerToBeSortedWith ?? header.value, '00:00').split(':');

              return Number(hourMinuteArray[0]) * 60 + Number(hourMinuteArray[1]);
            },
          ],
          [sortType],
        );
        break;

      case 'custom':
        this.sortedItems = _.orderBy(
          this.items,
          [
            (item: IDatatableItem) => {
              const value: string | number | undefined = _.get(item, headerToBeSortedWith ?? header.value, -1);
              const dateFormat: string | undefined = _.get(this.customSorts, `${header.value}.dateFormat`, undefined);
              const sortFunction: (value: any | undefined, dateFormat?: string) => any = _.get(
                this.customSorts,
                `${header.value}.customSortFunction`,
                undefined,
              );

              if (sortFunction) {
                return sortFunction(customSort.useItemForSort ? item : value, dateFormat);
              }

              return item;
            },
          ],
          [sortType],
        );
        break;

      case 'string':
      default:
        this.standardSort(headerToBeSortedWith ?? header.value, sortType);
        break;
    }
  }

  public onChangePaginationTrigger(page: number): void {
    this.EMIT_PAGE = page;
    this.EMIT_ROWS_PER_PAGE = this.rowsPerPage;
    this.EMIT_SEARCH = this.searchValue;

    if (this.serverSide || !this.clientSide) {
      this.pending = true;
      this.onDataRequest.emit({
        page: this.EMIT_PAGE,
        rowsPerPage: this.EMIT_ROWS_PER_PAGE,
        search: this.EMIT_SEARCH,
        sort: this.EMIT_SORT,
      });
    }

    this.onClickPagination.emit({
      page: this.EMIT_PAGE,
      rowsPerPage: this.EMIT_ROWS_PER_PAGE,
      search: this.EMIT_SEARCH,
      sort: this.EMIT_SORT,
    });

    if (this.clientSide) {
      this.clientSideRequest.emit({
        page: this.EMIT_PAGE,
        rowsPerPage: this.EMIT_ROWS_PER_PAGE,
        search: this.EMIT_SEARCH,
        sort: this.EMIT_SORT,
      });
    }
    this.rowsPerPage = this.EMIT_ROWS_PER_PAGE;
    this.calculateEntryRangeBounds();
  }

  public onClickRowTrigger(data: IDatatableOnClickRow): void {
    this.onClickRow.emit(data);
  }

  public onAddButtonClickEvent(): void {
    this.onAddButtonClick.emit();
  }

  public datatableItemTrackBy: TrackByFunction<any> = (index: number, item: any) => {
    return this.trackByProperty ? item[this.trackByProperty] : item;
  };

  private calculateEntryRangeBounds(): void {
    this.totalNumberOfItems = this.serverSide ? this.itemsCount : this.items.length;
    this.entryRangeLowerBound = this.rowsPerPage * (this.currentPage - 1) + 1;
    this.entryRangeUpperBound = Math.min(this.totalNumberOfItems, this.rowsPerPage * this.currentPage);
  }

  public onHeaderCheckboxClickEvent(event: boolean, header: string): void {
    this.onHeaderCheckboxClick.emit({ event, header });
  }
}
