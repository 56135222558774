import { Inject, Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import {
  IActivityLog,
  IActivityHistoryData,
  IActivityHistoryRequestParamMap,
  ILineData,
  RootCauseAnalysisFilterInterface,
  IRequestBody,
  IRootCauseAnalysisChartNode,
  IRootCauseAnalysisChartRequest,
  DatePickerInterFace,
  DropdownType,
  IOptimizedActivityLogsBody,
  TRootCauseAnalysisObservables,
} from './root-cause-analysis.model';
import { mysqlDateFormat, mysqlTimestampFormat } from '../../../shared/helper/date';
import { Store } from '@ngrx/store';
import * as oeeAppReducer from '../../oee.reducer';
import { ActivityTypes } from '../../../shared/model/enum/activity-types';
import { ResponseArrayInterface, ResponseInterface } from '../../../shared/model/interface/generic-api-response.model';
import moment from 'moment';
import * as momentTz from 'moment-timezone';
import { HelperService } from '../../../shared/service/helper.service';
import { ShiftService } from '../../../shared/service/filter/shift.service';
import { ICurrentShift } from '../../../shared/service/filter/service.class';
import { SitesService } from '../../../shared/service/settings/sites/sites.service';
import { GetManyResponseInterface } from '../../../shared/model/interface/crud-response-interface.model';
import { SiteCRUDInterface } from '../../../shared/component/filter/filter.class';
import * as _ from 'lodash';
import { DropdownOptionInterface } from '../../../shared/component/scw-mat-ui/scw-mat-select/scw-mat-select.model';
import { EComparisonGroupType } from '../../comparison-filter-card/comparison-filter-card.model';

@Injectable({
  providedIn: 'root',
})
export class RootCauseAnalysisService {
  constructor(
    public http: HttpClient,
    @Inject('API_BASE_URL') private readonly api: string,
    public store: Store<oeeAppReducer.OeeAppState>,
    private readonly helperService: HelperService,
    private readonly shiftService: ShiftService,
    private readonly sitesService: SitesService,
  ) {}

  private readonly routes = {
    activityHistory: `${this.api}/activity-histories`,
    optimizedActivityLogs: `${this.api}/activity-histories/optimized-logs`,
    rootCauseAnalysis: `${this.api}/activity-histories/root-cause-analysis`,
    lines: `${this.api}/lines`,
  };

  public getRootCauseAnalysisChartData(
    params: RootCauseAnalysisFilterInterface,
  ): Observable<ResponseInterface<IRootCauseAnalysisChartNode[]>> {
    const body: IRootCauseAnalysisChartRequest = RootCauseAnalysisService.getQueryBodyOfRootCauseAnalysisChart(params);

    return this.http.post<ResponseInterface<IRootCauseAnalysisChartNode[]>>(`${this.routes.rootCauseAnalysis}`, body, {
      headers: new HttpHeaders({ 'X-HTTP-Method': 'GET' }),
    });
  }

  public getActivityHistoryData(
    params: RootCauseAnalysisFilterInterface,
    page: number,
    limit: number,
    filterActivityType: boolean,
  ): Observable<ResponseArrayInterface<IActivityHistoryData>> {
    const body: IRequestBody = RootCauseAnalysisService.getQueryBodyOfActivityHistory(
      params,
      page,
      limit,
      filterActivityType,
    );

    return this.http.post<ResponseArrayInterface<IActivityHistoryData>>(`${this.routes.activityHistory}`, body, {
      headers: new HttpHeaders({ 'X-HTTP-Method': 'GET' }),
    });
  }

  public getLineData(
    params: RootCauseAnalysisFilterInterface,
    filterActivityType: boolean,
  ): Observable<ResponseArrayInterface<ILineData>> {
    const body: IRequestBody = RootCauseAnalysisService.getQueryBodyOfLine(params, filterActivityType);
    return this.http.post<ResponseArrayInterface<ILineData>>(`${this.routes.lines}`, body, {
      headers: new HttpHeaders({ 'X-HTTP-Method': 'GET' }),
    });
  }

  public static getQueryBodyOfLine(
    params: RootCauseAnalysisFilterInterface,
    filterActivityType: boolean,
  ): IRequestBody {
    const startDate = moment(params.dateRange.startDate).format(mysqlTimestampFormat);
    const endDate = (
      params.isBusinessDate ? moment(params.dateRange.endDate).add(1, 'day') : moment(params.dateRange.endDate)
    ).format(mysqlTimestampFormat);
    const requestParamMap: IActivityHistoryRequestParamMap[] = [
      {
        property: 'siteIds',
        condition: '$in',
        entityProperty: 'siteId',
      },
      {
        property: 'lineIds',
        condition: '$in',
        entityProperty: 'id',
      },
      {
        property: 'activityIds',
        condition: '$in',
        entityProperty: 'selectedActivity',
      },
      {
        property: 'productIds',
        condition: '$in',
        entityProperty: 'currentWorkOrder.productId',
      },
      {
        property: 'rootCauseGroupIds',
        condition: '$in',
        entityProperty: 'currentTask.rootCauseGroupId',
      },
    ];
    const activityTypeFilter: object = {
      'currentActivity.activityType': {
        $in: [ActivityTypes.DOWN_TIME, ActivityTypes.DOWN_TIME_PLANNED, ActivityTypes.IDLE_TIME, ActivityTypes.RUN_TIME],
      },
    };
    const andConditions: object[] = [
      {
        timer: { $lte: endDate },
      },
      ...(filterActivityType ? [activityTypeFilter] : []),
    ];

    if (!params.isBusinessDate) {
      andConditions.push({
        timer: { $gte: startDate },
      });
    }

    for (const reqParam of requestParamMap) {
      const data = params[reqParam.property];

      if (data !== -1) {
        andConditions.push({ [reqParam.entityProperty]: { [reqParam.condition]: data } });
      }
    }

    return {
      offset: 1000,
      search: {
        $and: andConditions,
      },
      fields: [
        'id',
        'siteId',
        'title',
        'activeUser',
        'timer',
        'selectedActivity',
        'selectedTaskId',
        'selectedCrewSize',
        'selectedDescription',
        'selectedPhaseId',
      ],
      join: [
        { field: 'lineTypeName', select: ['lineType'] },
        { field: 'currentActivity', select: ['name', 'activityType'] },
        { field: 'currentWorkOrder', select: ['woNumber', 'jobNumber', 'processOrder', 'quantityOrdered'] },
        { field: 'currentWorkOrder.product', select: ['productId', 'description'] },
        { field: 'currentWorkOrder.job', select: ['jobName'] },
        { field: 'currentTask', select: ['title', 'ucl', 'lcl', 'target'] },
        { field: 'currentTask.rootCauseGroup', select: ['name'] },
        { field: 'currentTask.equipment', select: ['equipmentName'] },
        { field: 'user', select: ['userName', 'fullName'] },
      ],
    };
  }

  private static getQueryBodyOfRootCauseAnalysisChart(
    params: RootCauseAnalysisFilterInterface,
  ): IRootCauseAnalysisChartRequest {
    const output: IRootCauseAnalysisChartRequest = {
      start: null,
      end: null,
      isBusinessDate: null,
    };

    for (const param of Object.keys(params)) {
      const data: DatePickerInterFace | DropdownType | boolean | DropdownOptionInterface[] = params[param];

      if (param === 'dateRange') {
        const dateRange: DatePickerInterFace = data as DatePickerInterFace;
        output.start = moment(dateRange.startDate).format(mysqlTimestampFormat);
        output.end = moment(dateRange.endDate).format(mysqlTimestampFormat);
      } else if (param === 'groupName' && Array.isArray(data) && data.length) {
        switch (_.get(data, '0.id')) {
          case 'siteIds':
            output[param] = EComparisonGroupType.SITE;
            break;
          case 'department':
            output[param] = EComparisonGroupType.DEPARTMENT;
            break;
          case 'lineIds':
            output[param] = EComparisonGroupType.LINE;
            break;
          case 'shiftIds':
            output[param] = EComparisonGroupType.SHIFT;
            break;
          case 'productIds':
            output[param] = EComparisonGroupType.PRODUCT;
            break;
        }
      } else if (param === 'isBusinessDate' || data !== -1) {
        output[param] = data;
      }
    }

    return output;
  }

  private static getQueryBodyOfActivityHistory(
    params: RootCauseAnalysisFilterInterface,
    page: number,
    limit: number,
    filterActivityType: boolean,
  ): IRequestBody {
    const datePickerFormat: string = params.isBusinessDate ? mysqlDateFormat : mysqlTimestampFormat;
    const startDate = moment(params.dateRange.startDate).format(datePickerFormat);
    const endDate = moment(params.dateRange.endDate).format(datePickerFormat);
    const requestParamMap: IActivityHistoryRequestParamMap[] = [
      {
        property: 'siteIds',
        condition: '$in',
        entityProperty: 'siteId',
      },
      {
        property: 'lineIds',
        condition: '$in',
        entityProperty: 'lineId',
      },
      {
        property: 'activityIds',
        condition: '$in',
        entityProperty: 'activityId',
      },
      {
        property: 'productIds',
        condition: '$in',
        entityProperty: 'workOrder.productId',
      },
      {
        property: 'rootCauseGroupIds',
        condition: '$in',
        entityProperty: 'task.rootCauseGroupId',
      },
      {
        property: 'shiftIds',
        condition: '$in',
        entityProperty: 'shiftId',
      },
    ];
    const activityTypeFilter: object = {
      'activity.activityType': {
        $in: [ActivityTypes.DOWN_TIME, ActivityTypes.DOWN_TIME_PLANNED, ActivityTypes.IDLE_TIME, ActivityTypes.RUN_TIME],
      },
    };
    const andConditions: object[] = [
      {
        [params.isBusinessDate ? 'shiftDay' : 'start']: { $lte: endDate },
      },
      {
        [params.isBusinessDate ? 'shiftDay' : 'end']: { [params.isBusinessDate ? '$gte' : '$gt']: startDate },
      },
      ...(filterActivityType ? [activityTypeFilter] : []),
    ];

    for (const reqParam of requestParamMap) {
      const data = params[reqParam.property];

      if (data !== -1) {
        andConditions.push({ [reqParam.entityProperty]: { [reqParam.condition]: data } });
      }
    }

    return {
      page,
      useReplicaForGetIfAvailable: params.useReplicaForGetIfAvailable,
      offset: limit,
      search: {
        $and: andConditions,
      },
      fields: [
        'siteId',
        'lineId',
        'userId',
        'start',
        'end',
        'activityId',
        'taskId',
        'crewSize',
        'description',
        'shiftDay',
        'shiftId',
        'location',
        'phaseId',
      ],
      join: [
        { field: 'line', select: ['title'] },
        { field: 'line.lineTypeName', select: ['lineType'] },
        { field: 'activity', select: ['name', 'activityType'] },
        { field: 'workOrder', select: ['woNumber', 'jobNumber', 'processOrder', 'quantityOrdered'] },
        { field: 'workOrder.product', select: ['productId', 'description'] },
        { field: 'task', select: ['title', 'ucl', 'lcl', 'target'] },
        { field: 'task.rootCauseGroup', select: ['name'] },
        { field: 'task.equipment', select: ['equipmentName'] },
        { field: 'shift', select: ['name'] },
        { field: 'user', select: ['userName'] },
      ],
    };
  }

  public getOptimizedActivityLogsObservables(params: RootCauseAnalysisFilterInterface): TRootCauseAnalysisObservables {
    return [
      this.sitesService.getSites(
        new HttpParams().set('limit', 5000).append('fields', 'preRunPhaseName,runPhaseName,postRunPhaseName'),
      ),
      this.getOptimizedActivityLogs(params),
    ];
  }

  public getRootCauseAnalysisObservables(
    params: RootCauseAnalysisFilterInterface,
    totalCount: number,
    filterActivityType: boolean,
  ): Observable<
    | ResponseArrayInterface<ICurrentShift>
    | ResponseArrayInterface<ILineData>
    | ResponseArrayInterface<IActivityHistoryData>
    | GetManyResponseInterface<SiteCRUDInterface>
  >[] {
    const partCount: number = 5000;
    const observables: Observable<
      | ResponseArrayInterface<ICurrentShift>
      | ResponseArrayInterface<ILineData>
      | ResponseArrayInterface<IActivityHistoryData>
      | GetManyResponseInterface<SiteCRUDInterface>
    >[] = [
      this.shiftService.getCurrentShifts(new HttpParams().append('useReplica', true)),
      this.getLineData(params, filterActivityType),
      this.sitesService.getSites(
        new HttpParams().set('limit', 5000).append('fields', 'preRunPhaseName,runPhaseName,postRunPhaseName'),
      ),
    ];
    const observablesCountOfActivityHistory: number = Math.ceil(totalCount / partCount);

    Array.from(Array(observablesCountOfActivityHistory)).map((value, index) => {
      const page = index + 1;

      observables.push(this.getActivityHistoryData(params, page, partCount, filterActivityType));
    });

    if (observablesCountOfActivityHistory > 1) {
      this.helperService.showWaitMessage();
    }

    return observables;
  }

  public formatLineData(
    activities: ILineData[],
    currentShifts: ICurrentShift[],
    params: RootCauseAnalysisFilterInterface,
    timezone: string,
  ): IActivityLog[] {
    const formattedActivities: IActivityLog[] = [];
    const shiftMap: { [key: number]: ICurrentShift } = {};

    currentShifts.map((shift: ICurrentShift) => {
      shiftMap[shift.lineId] = shift;
    });

    for (const activity of activities) {
      const now: string = momentTz().tz(timezone).format(mysqlTimestampFormat);
      const duration: number = moment(now).diff(moment(activity.timer), 'seconds');
      const shift: ICurrentShift = shiftMap[activity.id] ? shiftMap[activity.id] : undefined;

      const formattedActivity: IActivityLog = {
        id: activity.id,
        siteId: activity.siteId,
        lineId: activity.id,
        lineTitle: activity.title,
        lineType: activity?.lineTypeName?.lineType,
        lineTypeId: activity?.lineTypeName?.id,
        start: activity.timer,
        end: null,
        duration: duration >= 0 ? duration : 0,
        activityId: activity.selectedActivity,
        activityName: activity.currentActivity?.name,
        activityTypeText: activity.currentActivity?.activityType,
        taskId: activity.selectedTaskId,
        taskName: activity.currentTask?.title,
        workOrderNumber: activity.currentWorkOrder?.woNumber,
        productId: activity?.currentWorkOrder?.product?.productId,
        ucl: activity.currentTask?.ucl,
        lcl: activity.currentTask?.lcl,
        equipmentId: activity.currentTask?.equipment?.id ?? null,
        equipmentName: activity.currentTask?.equipment?.equipmentName ?? null,
        crewSize: activity.selectedCrewSize ? activity.selectedCrewSize.toString() : null,
        description: activity.selectedDescription,
        userId: activity.activeUser,
        userName: activity.user?.userName,
        userFullName: activity.user?.fullName,
        shiftDay: shift ? moment(shift.formattedStartDate).format(mysqlDateFormat) : null,
        shiftId: shift ? shift.shiftId : null,
        shiftName: shift ? shift.shiftName : null,
        siteName: null,
        primaryKey: null,
        durationWithUserFormat: null,
        durationMin: null,
        isLine: 1,
        location: null,
        phaseId: (activity as any).selectedPhaseId,
        preRunPhaseName: (activity as any).preRunPhaseName,
        postRunPhaseName: (activity as any).postRunPhaseName,
        runPhaseName: (activity as any).runPhaseName,
        rootCauseGroupId: activity.currentTask?.rootCauseGroup?.id ?? 0,
        rootCauseGroupName: activity.currentTask?.rootCauseGroup?.name ?? null,
        productInfo: activity?.currentWorkOrder?.product
          ? `${activity?.currentWorkOrder?.product?.productId} - ${activity?.currentWorkOrder?.product?.description}`
          : '',
        targetDuration: activity?.currentTask?.target,
        jobNumber: activity?.currentWorkOrder?.job?.jobName,
        processOrder: activity?.currentWorkOrder?.processOrder,
        quantityOrdered: activity.currentWorkOrder?.quantityOrdered,
        workOrderId: activity.currentWorkOrder?.id,
      };

      if (!params.isBusinessDate) {
        formattedActivities.push(formattedActivity);
        continue;
      }

      const isBetween: boolean = moment(formattedActivity.shiftDay).isBetween(
        moment(params.dateRange.startDate.format(mysqlTimestampFormat)),
        moment(params.dateRange.endDate.format(mysqlTimestampFormat)),
        'day',
        '[]',
      );

      if (isBetween && (params.shiftIds === -1 || _.includes(params.shiftIds, formattedActivity.shiftId))) {
        formattedActivities.push(formattedActivity);
      }
    }

    return formattedActivities;
  }

  public formatActivityHistoryData(activityHistories: IActivityHistoryData[]): IActivityLog[] {
    const formattedActivities: IActivityLog[] = [];

    for (const activity of activityHistories) {
      const duration: number = moment(activity.end).diff(moment(activity.start), 'seconds');

      const formattedActivity: IActivityLog = {
        id: activity.id,
        siteId: activity.siteId,
        lineId: activity.lineId,
        lineTitle: activity.line?.title,
        lineType: activity.line?.lineTypeName?.lineType,
        lineTypeId: activity.line?.lineTypeName?.id,
        start: activity.start,
        end: activity.end,
        duration: duration >= 0 ? duration : 0,
        activityId: activity.activityId,
        activityName: activity.activity?.name,
        activityTypeText: activity.activity?.activityType,
        taskId: activity.taskId,
        taskName: activity.task?.title,
        workOrderNumber: activity.workOrder?.woNumber,
        productId: activity?.workOrder?.product?.productId,
        productTableId: activity?.workOrder?.product?.id,
        ucl: activity.task?.ucl,
        lcl: activity.task?.lcl,
        equipmentId: activity.task?.equipment?.id ?? null,
        equipmentName: activity.task?.equipment?.equipmentName ?? null,
        crewSize: activity.crewSize ? activity.crewSize.toString() : null,
        description: activity.description,
        userId: activity.userId,
        userName: activity.user?.userName,
        userFullName: activity.user?.fullName,
        shiftDay: activity.shiftDay,
        shiftId: activity.shiftId ? activity.shiftId : null,
        shiftName: activity?.shift?.name,
        isLine: 0,
        siteName: null,
        primaryKey: null,
        durationWithUserFormat: null,
        durationMin: null,
        location: null,
        phaseId: activity.phaseId,
        preRunPhaseName: activity.preRunPhaseName,
        postRunPhaseName: activity.postRunPhaseName,
        runPhaseName: activity.runPhaseName,
        productInfo: activity?.workOrder?.product?.productId
          ? `${activity?.workOrder?.product?.productId} - ${activity?.workOrder?.product?.description}`
          : '',
        targetDuration: activity?.task?.target,
        jobNumber: activity?.workOrder?.jobNumber,
        processOrder: activity?.workOrder?.processOrder,
        rootCauseGroupId: activity?.task?.rootCauseGroup?.id ?? 0,
        rootCauseGroupName: activity?.task?.rootCauseGroup?.name ?? null,
        quantityOrdered: activity?.workOrder?.quantityOrdered ? activity?.workOrder?.quantityOrdered.toString() : undefined,
      };

      formattedActivities.push(formattedActivity);
    }

    return formattedActivities;
  }

  public filterFormattedDataWithSelectedChartData(
    activityLogs: IActivityLog[],
    selectedActivityName: string,
    selectedEquipmentName: string,
    selectedTaskNames: string[],
  ): IActivityLog[] {
    return activityLogs.filter((data: IActivityLog) => {
      const isNoEquipmentSelected: boolean = selectedEquipmentName === null;
      const isOtherTasksSelected: boolean = selectedTaskNames?.length > 1;

      const activityFilterCondition: boolean =
        _.isNil(selectedActivityName) || selectedActivityName === data.activityName;

      const equipmentFilterCondition: boolean =
        selectedEquipmentName === undefined ||
        (isNoEquipmentSelected && data.equipmentId === null) ||
        (!isNoEquipmentSelected && selectedEquipmentName === data.equipmentName);

      const taskFilterCondition: boolean =
        !selectedTaskNames?.length ||
        (isOtherTasksSelected && !selectedTaskNames?.includes(data.taskName)) ||
        (!isOtherTasksSelected && selectedTaskNames?.includes(data.taskName));

      return activityFilterCondition && equipmentFilterCondition && taskFilterCondition;
    });
  }

  private getOptimizedActivityLogs(
    params: RootCauseAnalysisFilterInterface,
  ): Observable<ResponseInterface<IActivityLog[]>> {
    const datePickerFormat: string = params.isBusinessDate ? mysqlDateFormat : mysqlTimestampFormat;
    const start = moment(params.dateRange.startDate).format(datePickerFormat);
    const end = moment(params.dateRange.endDate).format(datePickerFormat);

    const body: IOptimizedActivityLogsBody = {
      activityIds: params.activityIds,
      activityTypes: [ActivityTypes.DOWN_TIME, ActivityTypes.DOWN_TIME_PLANNED, ActivityTypes.IDLE_TIME, ActivityTypes.RUN_TIME],
      isBusinessDate: params.isBusinessDate,
      lineIds: params.lineIds,
      productIds: params.productIds,
      rootCauseGroupIds: params.rootCauseGroupIds,
      shiftIds: params.shiftIds,
      siteIds: params.siteIds,
      start,
      end,
      tagIds: params.tagIds ?? -1,
      useReplicaForGetIfAvailable: true,
    };

    return this.http.post<ResponseInterface<IActivityLog[]>>(`${this.routes.optimizedActivityLogs}`, body, {
      headers: new HttpHeaders({ 'X-HTTP-Method': 'GET' }),
    });
  }
}
