import { Inject, Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Store } from '@ngrx/store';
import * as oeeAppReducer from '../../oee.reducer';
import { Observable } from 'rxjs';
import { BaseOneResponseInterface } from '../../../shared/model/interface/crud-response-interface.model';
import {
  DailyProductionPerformanceActivityLogsInterface,
  DailyProductionPerformanceDataInterface,
  DailyProductionPerformanceFilterInterface,
} from './daily-production-performance.model';
import {
  ActivityLogsResponseInterface,
  IActivityLog,
  IActivityLogsRequest,
} from '../../reports/root-cause-analysis/root-cause-analysis.model';
import moment from 'moment';
import { mysqlDateFormat } from '../../../shared/helper/date';

@Injectable({
  providedIn: 'root',
})
export class DailyProductionPerformanceService {
  constructor(
    public http: HttpClient,
    @Inject('API_BASE_URL') private readonly api: string,
    public store: Store<oeeAppReducer.OeeAppState>,
  ) {}

  private readonly routes = {
    dailyProductionPerformance: '/lines/:lineId/daily-production-performance',
    activityLogs: '/activity-histories/activity-logs',
  };

  public getDailyProductionPerformanceData(
    lineId: number,
  ): Observable<BaseOneResponseInterface<DailyProductionPerformanceDataInterface>> {
    const url: string = `${this.api}${this.routes.dailyProductionPerformance.replace(':lineId', String(lineId))}`;

    return this.http.get<BaseOneResponseInterface<DailyProductionPerformanceDataInterface>>(url);
  }

  public getWorkOrderActivityLogs(
    params: DailyProductionPerformanceFilterInterface,
  ): Observable<BaseOneResponseInterface<ActivityLogsResponseInterface>> {
    const body: IActivityLogsRequest = {
      start: moment().startOf('day').format(mysqlDateFormat),
      end: moment().format(mysqlDateFormat),
      isBusinessDate: true,
      sites: params.siteId,
      lines: params.lineId,
      lineTypes: -1,
      activities: -1,
      products: -1,
      rootCauseGroups: -1,
      shifts: -1,
      advancedFilterPage: 'daily-production-performance',
      advancedFilterParams: JSON.stringify({ $and: [] }),
      workOrders: [-1],
    };

    return this.http.post<BaseOneResponseInterface<ActivityLogsResponseInterface>>(
      `${this.api}${this.routes.activityLogs}`,
      body,
      {
        headers: new HttpHeaders({ 'X-HTTP-Method': 'GET' }),
      },
    );
  }

  public formatActivityLogs(activityLogsResponse: IActivityLog[]): DailyProductionPerformanceActivityLogsInterface[] {
    return activityLogsResponse.map((item: IActivityLog) => {
      return {
        activityId: item.activityId,
        activityName: item.activityName,
        activityTypeText: item.activityTypeText,
        taskName: item.taskName,
        start: item.start,
        end: item.end,
        duration: item.duration,
        productId: item.productId,
        workOrderNumber: item.workOrderNumber,
        siteConfigurationCustomColor: item?.siteConfiguration?.colors.activity.plannedDownTime.button,
        entityTranslations: item.entityTranslations,
      };
    });
  }
}
