import { Store } from '@ngrx/store';
import { OeeAppState } from '../oee.reducer';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { SchedulerWorkOrderSetService } from '../../shared/service/scheduler-work-order-set/scheduler-work-order-set.service';
import * as SchedulerWorkOrderSetActions from './scheduler-work-order-set.actions';
import { of, switchMap } from 'rxjs';
import { catchError } from 'rxjs/operators';
import {
  TFetchWorkOrdersForSchedulerRules,
  TFetchWorkOrdersParameters,
  TGetManyWorkOrderSet,
} from './scheduler-work-order-set.model';
import { WorkOrderScheduleService } from '../../shared/service/work-order-schedule/work-order-schedule.service';
import {
  FormattedWorkOrdersResponseInterface,
  WorkOrderInterface,
} from '../work-order-schedule/work-order-schedule.model';
import { EntityTranslatorService } from '../../shared/service/entity-translator/entity-translator.service';
import { Injectable } from '@angular/core';
import { SchedulerHelperService } from '../../shared/service/scheduler/scheduler-helper.service';

@Injectable()
export class SchedulerWorkOrderSetEffects {
  constructor(
    private readonly actions$: Actions,
    private readonly store: Store<OeeAppState>,
    private readonly schedulerWorkOrderSetService: SchedulerWorkOrderSetService,
    private readonly workOrderScheduleService: WorkOrderScheduleService,
    private readonly entityTranslator: EntityTranslatorService,
    private readonly schedulerHelperService: SchedulerHelperService,
  ) {}

  getSchedulerWorkOrders = createEffect(() =>
    this.actions$.pipe(
      ofType(SchedulerWorkOrderSetActions.fetchWorkOrders),
      switchMap((objectData: TFetchWorkOrdersParameters) =>
        this.schedulerWorkOrderSetService.loadUnplannedWorkOrderData(objectData).pipe(
          switchMap((response: FormattedWorkOrdersResponseInterface) => {
            const formattedResponse: FormattedWorkOrdersResponseInterface = this.schedulerHelperService.formatResponse(
              this.workOrderScheduleService.responseSuitableForDecimalFormatting(response),
            );

            formattedResponse.data.forEach((workOrder: WorkOrderInterface) => {
              this.entityTranslator.translate(workOrder);
            });

            return of(SchedulerWorkOrderSetActions.fetchWorkOrdersSuccess(formattedResponse));
          }),
          catchError((error) => of(SchedulerWorkOrderSetActions.fetchWorkOrdersError({ error }))),
        ),
      ),
      catchError((error) => of(SchedulerWorkOrderSetActions.fetchWorkOrdersError({ error }))),
    ),
  );

  getWorkOrdersForSchedulerRules = createEffect(() =>
    this.actions$.pipe(
      ofType(SchedulerWorkOrderSetActions.fetchWorkOrdersForSchedulerRules),
      switchMap((payload: TFetchWorkOrdersForSchedulerRules) =>
        this.schedulerWorkOrderSetService.getWorkOrdersForSchedulerRules(payload).pipe(
          switchMap((response: TGetManyWorkOrderSet) => {
            return of(SchedulerWorkOrderSetActions.fetchWorkOrdersForSchedulerRulesSuccess(response));
          }),
          catchError((error) => of(SchedulerWorkOrderSetActions.fetchWorkOrderSetError({ error }))),
        ),
      ),
      catchError((error) => of(SchedulerWorkOrderSetActions.fetchWorkOrderSetError({ error }))),
    ),
  );
}
