import { Action } from '@ngrx/store';
import {
  IBillOfMaterials,
  IBillOfMaterialsData,
  IBillOfMaterialsEditRequestPayload,
  IBillOfMaterialsItemRequestPayload,
  IBillOfMaterialsRequestPayload,
} from './bill-of-materials.model';
import { GenericCrudRequestConstructionParameters } from '../../../../../shared/model/interface/generic-api-request.model';
import { ProductCRUDInterface } from '../../../../../shared/component/filter/filter.class';
import { BulkResponseDataInterface } from '../../../../../shared/model/interface/crud-response-interface.model';

export enum EBillOfMaterialsAction {
  GET_BILL_OF_MATERIALS_DATA = '[BILL OF MATERIALS] GET BILL OF MATERIALS DATA',
  GET_BILL_OF_MATERIALS_DATA_COMPLETED = '[BILL OF MATERIALS] GET BILL OF MATERIALS DATA COMPLETED',
  GET_PRODUCTS = '[BILL OF MATERIALS] GET PRODUCTS',
  GET_PRODUCTS_COMPLETED = '[BILL OF MATERIALS] GET PRODUCTS COMPLETED',
  ADD_BILL_OF_MATERIALS = '[BILL OF MATERIALS] ADD BILL OF MATERIALS',
  ADD_BILL_OF_MATERIALS_COMPLETED = '[BILL OF MATERIALS] ADD BILL OF MATERIALS COMPLETED',
  ADD_BILL_OF_MATERIALS_ITEM = '[BILL OF MATERIALS] ADD BILL OF MATERIALS ITEM',
  ADD_BILL_OF_MATERIALS_ITEM_COMPLETED = '[BILL OF MATERIALS] ADD BILL OF MATERIALS ITEM COMPLETED',
  EDIT_BILL_OF_MATERIALS = '[BILL OF MATERIALS] EDIT BILL OF MATERIALS',
  EDIT_BILL_OF_MATERIALS_COMPLETED = '[BILL OF MATERIALS] EDIT BILL OF MATERIALS COMPLETED',
  DELETE_BILL_OF_MATERIALS = '[BILL OF MATERIALS] DELETE BILL OF MATERIALS',
  DELETE_BILL_OF_MATERIALS_COMPLETED = '[BILL OF MATERIALS] DELETE BILL OF MATERIALS COMPLETED',
  DELETE_BILL_OF_MATERIALS_BULK = '[BILL OF MATERIALS] DELETE BILL OF MATERIALS BULK',
  DELETE_BILL_OF_MATERIALS_BULK_COMPLETED = '[BILL OF MATERIALS] DELETE BILL OF MATERIALS BULK COMPLETED',
  FETCH_ERROR = '[BILL OF MATERIALS] FETCH ERROR',
}

export class GetBillOfMaterialsData implements Action {
  readonly type: EBillOfMaterialsAction.GET_BILL_OF_MATERIALS_DATA = EBillOfMaterialsAction.GET_BILL_OF_MATERIALS_DATA;

  constructor(public params: GenericCrudRequestConstructionParameters, public productId: number) {}
}

export class GetBillOfMaterialsDataCompleted implements Action {
  readonly type: EBillOfMaterialsAction.GET_BILL_OF_MATERIALS_DATA_COMPLETED =
    EBillOfMaterialsAction.GET_BILL_OF_MATERIALS_DATA_COMPLETED;

  constructor(public payload: IBillOfMaterialsData) {}
}

export class GetProducts implements Action {
  readonly type: EBillOfMaterialsAction.GET_PRODUCTS = EBillOfMaterialsAction.GET_PRODUCTS;

  constructor(public params: GenericCrudRequestConstructionParameters, public excludedProductIds: number[]) {}
}

export class GetProductsCompleted implements Action {
  readonly type: EBillOfMaterialsAction.GET_PRODUCTS_COMPLETED = EBillOfMaterialsAction.GET_PRODUCTS_COMPLETED;

  constructor(public payload: ProductCRUDInterface[]) {}
}

export class AddBillOfMaterials implements Action {
  readonly type: EBillOfMaterialsAction.ADD_BILL_OF_MATERIALS = EBillOfMaterialsAction.ADD_BILL_OF_MATERIALS;

  constructor(public body: IBillOfMaterialsRequestPayload) {}
}

export class AddBillOfMaterialsCompleted implements Action {
  readonly type: EBillOfMaterialsAction.ADD_BILL_OF_MATERIALS_COMPLETED =
    EBillOfMaterialsAction.ADD_BILL_OF_MATERIALS_COMPLETED;

  constructor(public payload: IBillOfMaterials) {}
}

export class AddBillOfMaterialsItem implements Action {
  readonly type: EBillOfMaterialsAction.ADD_BILL_OF_MATERIALS_ITEM = EBillOfMaterialsAction.ADD_BILL_OF_MATERIALS_ITEM;

  constructor(public body: IBillOfMaterialsItemRequestPayload[]) {}
}

export class AddBillOfMaterialsItemCompleted implements Action {
  readonly type: EBillOfMaterialsAction.ADD_BILL_OF_MATERIALS_ITEM_COMPLETED =
    EBillOfMaterialsAction.ADD_BILL_OF_MATERIALS_ITEM_COMPLETED;

  constructor(public payload: BulkResponseDataInterface) {}
}

export class EditBillOfMaterials implements Action {
  readonly type: EBillOfMaterialsAction.EDIT_BILL_OF_MATERIALS = EBillOfMaterialsAction.EDIT_BILL_OF_MATERIALS;

  constructor(public params: IBillOfMaterialsEditRequestPayload) {}
}

export class EditBillOfMaterialsCompleted implements Action {
  readonly type: EBillOfMaterialsAction.EDIT_BILL_OF_MATERIALS_COMPLETED =
    EBillOfMaterialsAction.EDIT_BILL_OF_MATERIALS_COMPLETED;
}

export class DeleteBillOfMaterials implements Action {
  readonly type: EBillOfMaterialsAction.DELETE_BILL_OF_MATERIALS = EBillOfMaterialsAction.DELETE_BILL_OF_MATERIALS;

  constructor(public billOfMaterialsId: number) {}
}

export class DeleteBillOfMaterialsCompleted implements Action {
  readonly type: EBillOfMaterialsAction.DELETE_BILL_OF_MATERIALS_COMPLETED =
    EBillOfMaterialsAction.DELETE_BILL_OF_MATERIALS_COMPLETED;

  constructor(public success: boolean) {}
}

export class DeleteBillOfMaterialsBulk implements Action {
  readonly type: EBillOfMaterialsAction.DELETE_BILL_OF_MATERIALS_BULK =
    EBillOfMaterialsAction.DELETE_BILL_OF_MATERIALS_BULK;

  constructor(public billOfMaterialsIds: number[]) {}
}

export class DeleteBillOfMaterialsBulkCompleted implements Action {
  readonly type: EBillOfMaterialsAction.DELETE_BILL_OF_MATERIALS_BULK_COMPLETED =
    EBillOfMaterialsAction.DELETE_BILL_OF_MATERIALS_BULK_COMPLETED;

  constructor(public payload: BulkResponseDataInterface) {}
}

export class FetchError implements Action {
  readonly type: EBillOfMaterialsAction.FETCH_ERROR = EBillOfMaterialsAction.FETCH_ERROR;

  constructor(public payload: object) {}
}

export type BillOfMaterialsActions =
  | GetBillOfMaterialsData
  | GetBillOfMaterialsDataCompleted
  | GetProducts
  | GetProductsCompleted
  | AddBillOfMaterials
  | AddBillOfMaterialsCompleted
  | AddBillOfMaterialsItem
  | AddBillOfMaterialsItemCompleted
  | EditBillOfMaterials
  | EditBillOfMaterialsCompleted
  | DeleteBillOfMaterials
  | DeleteBillOfMaterialsCompleted
  | DeleteBillOfMaterialsBulk
  | DeleteBillOfMaterialsBulkCompleted
  | FetchError;
