<ng-template #work_order_select_product_version_modal let-modal let-c="close" let-d="dismiss">
  <div class="modal-header scw-modal-header">
    <h3 class="modal-title scw-header">
      {{ 'main.workOrder.selectProductVersion' | translate }}
    </h3>
    <button type="button" class="close" onfocus="blur()" aria-label="Close" (click)="modal.dismiss()">
      <span aria-hidden="true">
        <em class="fas fa-times"></em>
      </span>
    </button>
  </div>
  <div class="modal-body">
    <div class="row">
      <div class="col-sm-8">
        <h3 *ngIf="modalHeader" class="m-b-15">
          {{ modalHeader.productName ?? '' }} - {{ modalHeader.productDescription }} {{ modalHeader.currentVersion ? ' - '+modalHeader.currentVersion : '' }}
        </h3>
      </div>
    </div>
    <div
      class="row lazy-loading-results m-t-20"
      infiniteScroll
      [infiniteScrollDistance]="0"
      [infiniteScrollThrottle]="0"
      [scrollWindow]="false"
      (scrolled)="onScroll()"
    >
      <div *ngFor="let productVersion of productVersions$" class="col-xs-12 col-sm-6 col-md-4 col-lg-3 col-xl-2 m-b-15">
        <scw-mat-button
          type="standard"
          size="xl"
          [block]="true"
          [className]="['button-group']"
          (onClick)="onSelectProductVersion(productVersion)"
        >
          <h5 class="product-button-text product-button-product-text">{{ productVersion.name }}</h5>
        </scw-mat-button>
      </div>
      <div *ngIf="isLazyLoadingProgress" class="lazy-loading">
        <svg id="loader2" viewBox="0 0 100 100">
          <circle id="circle-loader2" class="stroke-white loader-primary" cx="50" cy="50" r="45"></circle>
        </svg>
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <div class="buttons-left">
      <scw-mat-button type="standard" (onClick)="modal.dismiss()">
        {{ 'general.back' | translate }}
      </scw-mat-button>
    </div>
  </div>
</ng-template>
