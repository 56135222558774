import { createSelector } from '@ngrx/store';
import { OeeAppState } from '../oee.reducer';
import { ICommentTypesState } from './comment-types.model';

export const selectCommentTypesLoadStatus = createSelector(
  (state: OeeAppState) => state.commentTypes,
  (state: ICommentTypesState) => state.commentTypesLoadStatus,
);

export const selectCommentTypes = createSelector(
  (state: OeeAppState) => state.commentTypes,
  (state: ICommentTypesState) => state.commentTypes,
);
