import { Component, Input, OnInit } from '@angular/core';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import {
  EOeeMetric,
  IOEEMetricMultiselectMeta,
} from '../../../view/settings/home-page-display-settings/home-metrics/home-metrics.model';
import { Store } from '@ngrx/store';
import { OeeAppState } from '../../../store/oee.reducer';
import { map } from 'rxjs/operators';
import { Observable, combineLatest } from 'rxjs';
import { AsyncPipe, NgClass, NgForOf, NgIf, NgStyle } from '@angular/common';
import { IOeeMetricApq } from '../../../store/home/home.model';
import { sortBy } from 'lodash';

@Component({
  selector: 'oee-metric-apq',
  templateUrl: './oee-metric-apq.component.html',
  styleUrls: ['./oee-metric-apq.component.scss'],
  standalone: true,
  imports: [NgClass, NgIf, TranslateModule, AsyncPipe, NgForOf, NgStyle],
})
export class OeeMetricApqComponent implements OnInit {
  @Input() cardTitleKey: string = '';
  @Input() subtitle: string;
  @Input() oeeValues$: Observable<IOeeMetricApq>;
  @Input() properties: IOEEMetricMultiselectMeta;

  public oeeValuesFormatted$: Observable<Required<IOeeMetricApq>>;
  public sortedMetricLayouts: EOeeMetric[] = [];

  constructor(public translate: TranslateService, private readonly store: Store<OeeAppState>) {}

  public ngOnInit(): void {
    this.oeeValuesFormatted$ = combineLatest({
      oeeFormula: this.store.select('line', 'oeeFormula').pipe(map((formula) => formula ?? 'apq')),
      oeeValues: this.oeeValues$,
    }).pipe(
      map(({ oeeFormula, oeeValues }) => ({
        [EOeeMetric.OEE]: this.formatApq(oeeValues, EOeeMetric.OEE, oeeFormula),
        [EOeeMetric.A]: this.formatApq(oeeValues, EOeeMetric.A, oeeFormula),
        [EOeeMetric.P]: this.formatApq(oeeValues, EOeeMetric.P, oeeFormula),
        [EOeeMetric.Q]: this.formatApq(oeeValues, EOeeMetric.Q, oeeFormula),
      })),
    );

    this.sortedMetricLayouts = sortBy(this.properties.metricLayout, [
      (layout: EOeeMetric): boolean => layout === EOeeMetric.Q,
      (layout: EOeeMetric): boolean => layout === EOeeMetric.P,
      (layout: EOeeMetric): boolean => layout === EOeeMetric.A,
      (layout: EOeeMetric): boolean => layout === EOeeMetric.OEE,
    ]);
  }

  private formatApq(oeeValues: IOeeMetricApq | null, metric: EOeeMetric, formula: string): string {
    return (metric === EOeeMetric.OEE || formula.includes(metric)) && oeeValues?.[metric]
      ? `${oeeValues?.[metric]}%`
      : '-';
  }
}
