import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import * as ObjectActions from './opc-tag.actions';
import { OpcMessageInspectionService } from '../../reports/opc-message-inspection/opc-message-inspection.service';
import { from, of, switchMap} from 'rxjs';
import { catchError } from 'rxjs/operators';
import { IOpcTagListResponse } from '../../../shared/service/filter/service.class';
import { generateHttpOption } from '../../../shared/helper/app-helper';

@Injectable()
export class FilterOpcTagEffects {
  loadFilterOpcTags = createEffect(() =>
    this.actions$.pipe(
      ofType(ObjectActions.FilterOpcTagActionTypes.StartLoadFilterOpcTags),
      switchMap((objectData: ObjectActions.StartLoadFilterOpcTag) => {
        const params = generateHttpOption(objectData.options);
        return from(this.service.getOpcTagList(params)).pipe(
          switchMap((response: IOpcTagListResponse) => {
            return of(new ObjectActions.FilterOpcTagsLoaded(response.data));
          }),
          catchError((err) => {
            return of(new ObjectActions.FilterOpcTagsLoaded(null));
          }),
        );
      }),
      catchError(() => {
        return of(new ObjectActions.FilterOpcTagsLoaded(null));
      }),
    ),
  );

  constructor(
    private actions$: Actions<ObjectActions.FilterOpcTagActions>,
    public service: OpcMessageInspectionService,
  ) {}
}
