import { ActivityTypes } from '../../../../shared/model/enum/activity-types';
import { IActivityLog, RootCauseAnalysisFilterInterface } from '../../../../store/reports/root-cause-analysis/root-cause-analysis.model';
import moment from 'moment';

export enum EActivityEquipmentTaskChart {
  BAR = 'bar',
  PIE = 'pie',
  VERTICAL_BAR = 'verticalBar',
}

export enum EActivitiesEquipmentChartLayout {
  ROW = 'row',
  COLUMN = 'column',
}

export enum EStackChartGroupBy {
  PRODUCT = 'productId',
  PRODUCT_TABLE_ID = 'productTableId',
  ROOT_CAUSE_GROUP = 'rootCauseGroupId',
  LINE = 'lineId',
  DEPARTMENT = 'lineTypeId',
  DAY = 'day',
  WEEK = 'week',
  MONTH = 'month',
  SHIFT = 'shift',
  ALL_TIME = 'allTime',
  PRODUCTIDID = 'productIdId',
}

export enum EStackChartGroupByName {
  lineId = 'lineTitle',
  lineTypeId = 'lineType',
  productId = 'productId',
  rootCauseGroupId = 'rootCauseGroupName',
  productTableId = 'productTableId',
}

export enum EStackChartDropdown {
  TIME = 'time',
  LOCATION = 'location',
  PRODUCT = 'product',
  LINE = 'line',
  ROOT_CAUSE_GROUP = 'rootCauseGroup',
}

export interface IStackChartDropdownOptions {
  id: EStackChartDropdown;
  name: string;
}

export interface IStackChartAxisLabelData {
  id?: number | string;
  value?: string;
}

export interface IFilterOptions {
  siteIds: number[];
  isBusinessDate: boolean;
  start: moment.Moment;
  end: moment.Moment;
}

export type TGroupedDataOptionGroup = {
  [key: string]: IActivityLog;
};

export type TGroupedData = {
  [key in ActivityTypes]?: TGroupedDataOptionGroup;
};

export interface IChartLoader {
  type: string;
  options: { text: string };
}

export type TOptionGroupAndDurations = {
  [key: string]: IOptionGroupAndDuration;
};

export interface IOptionGroupAndDuration {
  id: string | number;
  value: string;
  duration: number;
}

export type TActivityTypeOptionGroupDurations = {
  [key in ActivityTypes]?: TActivityTypeOptionGroupDuration;
};

export type TActivityTypeOptionGroupDuration = {
  [key: string]: string | undefined;
};

export interface IPdfChartObject {
  deletedChart: number[]
  isVerticalBars: boolean;
}
