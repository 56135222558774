import { TranslateService } from '@ngx-translate/core';
import { EWidgetType, WidgetConfigurationInterface } from './page-configuration.model';
import { PageConfigurationTypes } from '../../../../constants.model';
import { KpiViewComponents } from '../../../store/dashboards/kpi-view/kpi-view.model';
import { EKpiCardContentType } from '../scw-mat-ui/scw-mat-kpi-card/scw-mat-kpi-card.model';

export class PageConfigurationUtilities {
  public static getLineViewWidgets(translate: TranslateService): WidgetConfigurationInterface<EWidgetType.KPI_CARD>[] {
    return [
      {
        name: 'runningLines',
        type: PageConfigurationTypes.WIDGET,
        selected: true,
        disabled: false,
        content: {
          type: EWidgetType.KPI_CARD,
          pageConfigurationTitle: translate.instant('lineView.runningLines'),
          header: [
            {
              type: EKpiCardContentType.TEXT,
              value: translate.instant('lineView.runningLines'),
            },
            {
              type: EKpiCardContentType.ICON,
              value: 'fas fa-play-circle',
              style: {
                color: '#6FC764',
              },
            },
          ],
          body: [
            {
              key: 'runTime$',
              type: EKpiCardContentType.TEXT,
              value: null,
              style: {
                color: '#6FC764',
              },
            },
          ],
        },
      },
      {
        name: 'downLines',
        type: PageConfigurationTypes.WIDGET,
        selected: true,
        disabled: false,
        content: {
          type: EWidgetType.KPI_CARD,
          pageConfigurationTitle: translate.instant('lineView.downLines'),
          header: [
            {
              type: EKpiCardContentType.TEXT,
              value: translate.instant('lineView.downLines'),
            },
            {
              type: EKpiCardContentType.ICON,
              value: 'fas fa-stop-circle',
              style: {
                color: '#DD332B',
              },
            },
          ],
          body: [
            {
              key: 'downTime$',
              type: EKpiCardContentType.TEXT,
              value: null,
              style: {
                color: '#DD332B',
              },
            },
          ],
        },
      },
      {
        name: 'idleLines',
        type: PageConfigurationTypes.WIDGET,
        selected: true,
        disabled: false,
        content: {
          type: EWidgetType.KPI_CARD,
          pageConfigurationTitle: translate.instant('lineView.idleLines'),
          header: [
            {
              type: EKpiCardContentType.TEXT,
              value: translate.instant('lineView.idleLines'),
            },
            {
              type: EKpiCardContentType.ICON,
              value: 'fas fa-pause-circle',
            },
          ],
          body: [
            {
              key: 'idleTime$',
              type: EKpiCardContentType.TEXT,
              value: null,
            },
          ],
        },
      },
      {
        name: 'todayOEE',
        type: PageConfigurationTypes.WIDGET,
        selected: true,
        disabled: false,
        content: {
          type: EWidgetType.KPI_CARD,
          pageConfigurationTitle: translate.instant('lineView.todaysOEE'),
          header: [
            {
              type: EKpiCardContentType.TEXT,
              value: translate.instant('lineView.todaysOEE'),
            },
            {
              type: EKpiCardContentType.ICON,
              value: 'fas fa-stopwatch',
            },
          ],
          body: [
            {
              key: 'runTimeAndDownTimeOee$',
              type: EKpiCardContentType.TEXT,
              value: null,
            },
          ],
        },
      },
      {
        name: 'totalNumberOfUsers',
        type: PageConfigurationTypes.WIDGET,
        selected: false,
        disabled: false,
        content: {
          type: EWidgetType.KPI_CARD,
          pageConfigurationTitle: translate.instant('lineView.totalNumberOfUsers'),
          header: [
            {
              type: EKpiCardContentType.TEXT,
              value: translate.instant('lineView.totalNumberOfUsers'),
            },
            {
              type: EKpiCardContentType.ICON,
              value: 'fas fa-users',
            },
          ],
          body: [
            {
              key: 'totalNumberOfUsers$',
              type: EKpiCardContentType.TEXT,
              value: null,
            },
          ],
        },
      },
      {
        name: 'totalNumberOfAssets',
        type: PageConfigurationTypes.WIDGET,
        selected: false,
        disabled: false,
        content: {
          type: EWidgetType.KPI_CARD,
          pageConfigurationTitle: translate.instant('lineView.totalNumberOfAssets'),
          header: [
            {
              type: EKpiCardContentType.TEXT,
              value: translate.instant('lineView.totalNumberOfAssets'),
            },
            {
              type: EKpiCardContentType.ICON,
              value: 'fas fa-cube',
            },
          ],
          body: [
            {
              key: 'totalNumberOfAssets$',
              type: EKpiCardContentType.TEXT,
              value: null,
            },
          ],
        },
      },
    ];
  }

  public static getProductionReviewWidgets(
    translate: TranslateService,
  ): WidgetConfigurationInterface<EWidgetType.WIDGET>[] {
    return [
      {
        name: 'workOrderSummaryTable',
        type: PageConfigurationTypes.WIDGET,
        selected: true,
        disabled: false,
        content: {
          type: EWidgetType.WIDGET,
          pageConfigurationTitle: translate.instant('productionReview.widget.productionRoundupTable'),
          componentName: 'app-wo-summary-table',
        },
      },
      {
        name: 'homeMetrics',
        type: PageConfigurationTypes.WIDGET,
        selected: false,
        disabled: false,
        content: {
          type: EWidgetType.WIDGET,
          pageConfigurationTitle: translate.instant('productionReview.widget.kpiCards'),
          componentName: 'app-home-metrics',
        },
      },
    ];
  }

  public static getWorkOrderWidgets(): WidgetConfigurationInterface<EWidgetType.WIDGET>[] {
    return [
      {
        type: PageConfigurationTypes.WIDGET,
        name: 'kpiCards',
        selected: true,
        disabled: false,
        content: {
          type: EWidgetType.WIDGET,
          pageConfigurationTitle: 'KPI Cards',
          componentName: 'app-work-orders-new-design',
        },
      },
    ];
  }

  public static getKpiViewWidgets(translate: TranslateService): WidgetConfigurationInterface<EWidgetType.KPI_CARD>[] {
    return [
      {
        name: KpiViewComponents.oeeAndTarget,
        type: PageConfigurationTypes.WIDGET,
        selected: true,
        disabled: true,
        content: {
          type: EWidgetType.KPI_CARD,
          pageConfigurationTitle: translate.instant('kpiView.widget.oeeAndTarget.label'),
        },
      },
      {
        name: KpiViewComponents.workOrderCountAndTarget,
        type: PageConfigurationTypes.WIDGET,
        selected: true,
        disabled: false,
        content: {
          type: EWidgetType.KPI_CARD,
          pageConfigurationTitle: translate.instant('kpiView.widget.workOrderCountAndTarget.label'),
        },
      },
      {
        name: KpiViewComponents.goodCountAndTarget,
        type: PageConfigurationTypes.WIDGET,
        selected: true,
        disabled: false,
        content: {
          type: EWidgetType.KPI_CARD,
          pageConfigurationTitle: translate.instant('kpiView.widget.goodCountAndTarget.label'),
        },
      },
      {
        name: KpiViewComponents.overtimeAndTarget,
        type: PageConfigurationTypes.WIDGET,
        selected: true,
        disabled: false,
        content: {
          type: EWidgetType.KPI_CARD,
          pageConfigurationTitle: translate.instant('kpiView.widget.overtimeAndTarget.label'),
        },
      },
    ];
  }
}
