import { Injectable } from '@angular/core';
import { HttpParams } from '@angular/common/http';
import { GetManyResponseInterface } from '../../model/interface/crud-response-interface.model';
import { KpiTargetsService } from '../../../store/settings/kpi-targets/kpi-targets.service';
import { map } from 'rxjs/operators';
import { KpiTargetInterface } from '../../../store/settings/kpi-targets/kpi-targets.model';
import * as _ from 'lodash';
import { Observable } from 'rxjs';
import { IOeeCalendarChartColorData } from '../../../store/reports/oee-calendar/oee-calendar.model';
import { ColorService } from '../color/color.service';
import { DecimalHelper } from '../../helper/decimal/decimal-helper';
import { InterpolateColorStop } from '../color/color.model';

export enum EOeeColor {
  IDLE_TIME = '#BDBDBD',
  GREEN = '#8FD386',
  YELLOW = '#FFD600',
  RED = '#FF726B',
  LOWEST_RED = '#A3211B',
  HIGHEST_GREEN = '#4CAE3F',
  SECONDARY_YELLOW = '#EDC500',
}

@Injectable({
  providedIn: 'root',
})
export class OeeService {
  private readonly minPercentage: number = 0;
  private readonly maxPercentage: number = 100;
  private readonly defaultUpperLimit: number = 60;
  private readonly defaultLowerLimit: number = 40;

  constructor(
    private kpiTargetsService: KpiTargetsService,
    private colorServiceHelper: ColorService,
    private decimalHelper: DecimalHelper,
  ) {}

  public getAverageOeeTargetData(params: HttpParams): Observable<number> {
    return this.kpiTargetsService.getKpiTargets(params).pipe(
      map((response: GetManyResponseInterface<KpiTargetInterface>): number => {
        return _.mean(
          response.data.map((kpiTarget: KpiTargetInterface) => {
            return this.decimalHelper.decimalToNumberFormatter(kpiTarget.oeeTarget);
          }),
        );
      }),
    );
  }

  public getOeeCalendarChartColorData(params: HttpParams): Observable<IOeeCalendarChartColorData[]> {
    return this.getAverageOeeTargetData(params).pipe(
      map((averageOeeTarget: number): IOeeCalendarChartColorData[] => {
        const upperLimit: number = averageOeeTarget ? averageOeeTarget : this.defaultUpperLimit;
        const lowerLimit: number = averageOeeTarget ? (upperLimit * 80) / 100 : this.defaultLowerLimit;
        const midValue: number = (upperLimit + lowerLimit) / 2;

        const colorStops: InterpolateColorStop[] = [
          { value: this.minPercentage - 1, color: EOeeColor.LOWEST_RED },
          { value: lowerLimit, color: EOeeColor.RED },
          { value: midValue - (upperLimit - lowerLimit) / 4, color: EOeeColor.SECONDARY_YELLOW },
          { value: midValue, color: EOeeColor.YELLOW },
          { value: midValue + (upperLimit - lowerLimit) / 4, color: EOeeColor.SECONDARY_YELLOW },
          { value: upperLimit, color: EOeeColor.GREEN },
          { value: this.maxPercentage + 1, color: EOeeColor.HIGHEST_GREEN },
        ];
        const visualMapColors: IOeeCalendarChartColorData[] = [];
        visualMapColors.push({min: -1, max: -1, color: EOeeColor.IDLE_TIME});

        for (let i: number = this.minPercentage; i < this.maxPercentage; i++) {
          visualMapColors.push({ min: i, max: i + 1, color: this.colorServiceHelper.interpolateColor(i, colorStops) });
        }
        return visualMapColors;
      }),
    );
  }
}
