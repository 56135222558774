import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import * as NotificationLogsActions from './notification-logs.actions';
import { catchError, concatMap, switchMap } from 'rxjs/operators';
import { of } from 'rxjs';
import { HttpParams } from '@angular/common/http';
import {
  BaseOneResponseInterface,
  GetManyResponseInterface,
} from '../../../shared/model/interface/crud-response-interface.model';
import { NotificationLogsService } from './notification-logs.service';
import { INotificationLogsDatatableRow, TNotificationDataOption } from './notification-logs.model';
import { HTTPHelperService } from '../../../shared/service/http/http.helper.service';
import { Store } from '@ngrx/store';
import * as AppActions from '../../app/actions';
import { OeeAppState } from '../../oee.reducer';

@Injectable()
export class NotificationLogsEffects {
  constructor(
    private readonly actions$: Actions,
    private readonly service: NotificationLogsService,
    private readonly store: Store<OeeAppState>,
  ) {}

  getNotificationLogs = createEffect(() =>
    this.actions$.pipe(
      ofType(NotificationLogsActions.NotificationLogsActionTypes.GetNotificationLogsData),
      switchMap((objectData: NotificationLogsActions.GetNotificationLogsData) => {
        const httpParams: HttpParams = HTTPHelperService.createHttpParamsWithObject(
          this.service.formatNotificationLogsRequestParams(objectData.params),
        );

        return this.service.getNotificationLogsData(httpParams).pipe(
          switchMap((response: GetManyResponseInterface<INotificationLogsDatatableRow>) => {
            return of(new NotificationLogsActions.GetNotificationLogsDataCompleted(response));
          }),
          catchError((err) => {
            return of(new NotificationLogsActions.FetchError(err));
          }),
        );
      }),
      catchError((error) => {
        return of(new NotificationLogsActions.FetchError(error));
      }),
    ),
  );

  getNotificationLogsPreview = createEffect(() =>
    this.actions$.pipe(
      ofType(NotificationLogsActions.NotificationLogsActionTypes.GetNotificationLogsPreview),
      concatMap((objectData: NotificationLogsActions.GetNotificationLogsPreview) => {
        this.store.dispatch(new AppActions.ShowTopLoader());
        return this.service.getNotificationLogsPreview(objectData.id).pipe(
          concatMap((response: BaseOneResponseInterface<TNotificationDataOption>) => {
            return of(
              new NotificationLogsActions.GetNotificationLogsPreviewCompleted(response.data),
              new AppActions.HideTopLoader(),
            );
          }),
          catchError((err) => {
            return of(new NotificationLogsActions.FetchError(err), new AppActions.HideTopLoader());
          }),
        );
      }),
      catchError((error) => {
        return of(new NotificationLogsActions.FetchError(error), new AppActions.HideTopLoader());
      }),
    ),
  );
}
