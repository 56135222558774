import { Action } from '@ngrx/store';
import { GetManyResponseInterface } from '../../../shared/model/interface/crud-response-interface.model';
import { IFormattedOtifAnalysisTableData, IOtifAnalysisFilters, IOtifAnalysisResponse } from './otif-analysis.model';
import { DatatableHeaderInterface } from '../../../shared/component/datatable/datatable.model';

export enum OtifAnalysisActionTypes {
  OtifAnalysisDataLoading = '[Otif Analysis] Otif Analysis Data Loading',
  OtifAnalysisDataLoaded = '[Otif Analysis] Otif Analysis Data Loaded',
  DownloadOtifAnalysisExcel = '[Otif Analysis] Download Otif Analysis Excel',
  DownloadOtifAnalysisExcelCompleted = '[Otif Analysis] Download Otif Analysis Excel Completed',
  FetchError = '[Otif Analysis] Fetch Error',
}

export class OtifAnalysisDataLoading implements Action {
  readonly type = OtifAnalysisActionTypes.OtifAnalysisDataLoading;

  constructor(public params: IOtifAnalysisFilters) {}
}

export class OtifAnalysisDataLoaded implements Action {
  readonly type = OtifAnalysisActionTypes.OtifAnalysisDataLoaded;

  constructor(public payload: GetManyResponseInterface<IOtifAnalysisResponse>) {}
}

export class DownloadOtifAnalysisExcel implements Action {
  readonly type = OtifAnalysisActionTypes.DownloadOtifAnalysisExcel;

  constructor(public data: IFormattedOtifAnalysisTableData[], public tableHeaders: DatatableHeaderInterface[]) {}
}

export class DownloadOtifAnalysisExcelCompleted implements Action {
  readonly type = OtifAnalysisActionTypes.DownloadOtifAnalysisExcelCompleted;
}

export class FetchError implements Action {
  readonly type = OtifAnalysisActionTypes.FetchError;

  constructor(public payload: object) {}
}

export type OtifAnalysisActions =
  | OtifAnalysisDataLoading
  | OtifAnalysisDataLoaded
  | DownloadOtifAnalysisExcel
  | DownloadOtifAnalysisExcelCompleted
  | FetchError;
