import { ILineViewState } from './line-view.model';
import * as Actions from './line-view.actions';

export const lineViewInitialState: ILineViewState = {
  isFilterDataGenerated: false,
  isStationDataLoaded: false,
  isStationDataLoading: false,
  isSiteFilterLoaded: false,
  isSiteFilterLoading: false,
  lineViewData: null,
  selectedSiteFilters: [],
  sitesFilter: [],
  selectedSiteId: null,
  selectedLineId: null,
  isInitialFilterDataLoaded: false,
};

export function lineViewReducer(
  state: ILineViewState = lineViewInitialState,
  action: Actions.LineViewActions,
): ILineViewState {
  switch (action.type) {
    case Actions.LINE_VIEW_SELECT_SITE_AND_LINE:
      return {
        ...state,
        selectedSiteId: action.siteId,
        selectedLineId: action.lineId,
      };
    case Actions.LINE_VIEW_INITIAL_FILTER_DATA_LOADED:
      return {
        ...state,
        isInitialFilterDataLoaded: true,
      };
    case Actions.LINE_VIEW_STATION_DATA_LOADING:
      return {
        ...state,
        isStationDataLoaded: false,
        isStationDataLoading: true,
      };
    case Actions.LINE_VIEW_STATION_DATA_LOADED:
      return {
        ...state,
        isStationDataLoaded: true,
        isStationDataLoading: false,
        lineViewData: action.payload
      };
    case Actions.LINE_VIEW_RESET_STORE_TO_DEFAULT:
      return {
        ...lineViewInitialState,
      };
    default:
      return state;
  }
}
