import { CommonModule } from '@angular/common';
import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { SharedModule } from '../../../../../../shared/shared.module';
import { Store } from '@ngrx/store';
import * as oeeAppReducer from '../../../../../../store/oee.reducer';
import { Subscription } from 'rxjs';
import { IStationHomeKPIState } from '../../../../../../store/station-home/kpi-card/kpi-card.model';
import { DecimalHelper } from '../../../../../../shared/helper/decimal/decimal-helper';

@Component({
  selector: 'scw-station-home-counts-metric-card',
  standalone: true,
  imports: [CommonModule, SharedModule],
  templateUrl: './counts-metric-card.component.html',
})
export class CountsMetricCardComponent implements OnInit, OnDestroy {
  @Input() public goodCountUnit: string;
  @Input() public scrapCountUnit: string;

  public totalCount: string;
  public goodCount: string;
  public scrapCount: string = '0';

  private siteDecimalScaleLimit: number = 0;
  private subscriptions: Subscription[] = [];

  constructor(
    private readonly store: Store<oeeAppReducer.OeeAppState>,
    private readonly decimalHelper: DecimalHelper,
  ) {}

  public ngOnInit(): void {
    this.subscriptions.push(
      this.store.select('stationHomeKPIStore').subscribe((state: IStationHomeKPIState) => {
        this.goodCount = this.decimalHelper.toFixedValue(state.goodCount ?? '0', this.siteDecimalScaleLimit);
        this.totalCount = this.decimalHelper.toFixedValue(state.totalCount ?? '0', this.siteDecimalScaleLimit);
        this.scrapCount = this.decimalHelper.toFixedValue(
          this.decimalHelper.subtract(state.totalCount, state.goodCount, this.siteDecimalScaleLimit),
          this.siteDecimalScaleLimit,
          true,
        );
      }),
    );
  }

  public ngOnDestroy(): void {
    this.subscriptions.forEach((subscription) => subscription.unsubscribe());
  }
}
