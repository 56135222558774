import { Component, ElementRef, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { ScwMatPickerReturnInterface, ScwMatPickerRule, ScwMatPickerType } from './scw-mat-picker.model';
import * as _ from 'lodash';
import { TranslateService } from '@ngx-translate/core';
import { ComponentColors } from 'src/app/shared/service/color/color.model';

@Component({
  selector: 'scw-mat-picker',
  templateUrl: './scw-mat-picker.component.html',
  styleUrls: ['./scw-mat-picker.component.scss'],
})
export class ScwMatPickerComponent implements OnInit, OnChanges {
  @Input() inputModel: ScwMatPickerReturnInterface;
  @Input() isValid: boolean = false;
  @Input() label: string = null;
  @Input() placeholder: string = null;
  @Input() hint: string = null;
  @Input() disabled: boolean = false;
  @Input() block: boolean = false;
  @Input() className:
    | string[]
    | {
        [klass: string]: any;
      };
  @Input() hasErrors: boolean = false;
  @Input() errorText: string;
  @Input() rules: ScwMatPickerRule[] = [];
  @Input() plannedDownTimeActivityColors: ComponentColors;

  @Output() inputModelChange: EventEmitter<ScwMatPickerReturnInterface> =
    new EventEmitter<ScwMatPickerReturnInterface>();
  @Output() isValidChange: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() onClick: EventEmitter<void> = new EventEmitter<void>();

  public pickerType: ScwMatPickerType = 'default';
  private isAnyError: boolean = false;

  constructor(private translate: TranslateService, private hostElement: ElementRef) {}

  private typeDecider(): void {
    this.pickerType = this.inputModel?.type ?? 'default';
  }

  public onClickEmitter(): void {
    this.onClick.emit();
  }

  public reset(): void {
    this.inputModel = null;
    this.clearErrorMessage();
  }

  private isValidEqualizer(isValid: boolean): void {
    this.isValid = isValid;
    this.isValidChange.emit(this.isValid);
  }

  private showErrorMessage(message: string): void {
    this.isValidEqualizer(false);
    this.isAnyError = true;
    this.hasErrors = true;
    this.errorText = message ? message : '';
  }

  public clearErrorMessage(): void {
    this.isValidEqualizer(true);
    this.isAnyError = false;
    this.hasErrors = false;
    this.errorText = null;
  }

  private requiredRule(rule: ScwMatPickerRule): void {
    if (_.isNil(this.inputModel) || _.isNil(this.inputModel?.value)) {
      this.showErrorMessage(rule.message ?? this.translate.instant('scwMatForm.validation.required'));
    }
  }

  private customRule(rule: ScwMatPickerRule): void {
    if (this.inputModel && rule.custom && !rule.validator(this.inputModel)) {
      this.showErrorMessage(rule.message);
    }
  }

  public checkRules(): void {
    if (this.rules.length === 0) {
      this.isValidEqualizer(true);
      return;
    }

    this.isAnyError = false;

    for (const rule of this.rules) {
      if (this.isAnyError) {
        return;
      }

      switch (true) {
        case 'required' in rule:
          this.requiredRule(rule);
          break;
        case 'custom' in rule:
          this.customRule(rule);
          break;
        default:
          return;
      }
    }

    if (this.isAnyError) {
      return;
    }

    this.clearErrorMessage();
  }

  public onNgModelChange(): void {
    this.inputModelChange.emit(this.inputModel);
    this.checkRules();
    this.typeDecider();
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if (
      'inputModel' in changes &&
      !changes?.['inputModel']?.isFirstChange() &&
      changes?.['inputModel']?.previousValue !== changes?.['inputModel']?.currentValue
    ) {
      this.onNgModelChange();
    }
    if (changes['plannedDownTimeActivityColors'] && this.plannedDownTimeActivityColors) {
      this.hostElement.nativeElement.style.setProperty(
        '--planned-down-time-color',
        this.plannedDownTimeActivityColors.background,
      );
      this.hostElement.nativeElement.style.setProperty(
        '--planned-down-time-color-text',
        this.plannedDownTimeActivityColors.text,
      );
    }
  }

  public ngOnInit(): void {
    this.typeDecider();
  }
}
