import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import * as ObjectActions from './products.actions';
import { catchError, map, switchMap } from 'rxjs/operators';
import { SiteService } from '../../../shared/service/filter/site.service';
import { from, of } from 'rxjs';
import * as AppActions from '../../app/actions';
import { ProductsService } from './products.service';
import { HttpParams } from '@angular/common/http';
import { Store } from '@ngrx/store';
import * as oeeAppReducer from '../../oee.reducer';
import { ErrorMessageService } from '../../../shared/service/error-message.service';
import {
  AddProductResponseInterface,
  CustomerInterface,
  EditProductResponseInterface, ProductFamilyInterface,
  ProductInterface,
} from './products.model';
import {
  BaseCrudResponse,
  BulkResponseDataInterface,
  GetManyResponseInterface,
} from '../../../shared/model/interface/crud-response-interface.model';
import { ExcelHelperService } from '../../../shared/service/excel/excel-helper.service';
import { MergedArrayDataInterface } from '../../../../constants.model';
import { CustomersService } from '../customers/customers.service';
import { DecimalHelper } from 'src/app/shared/helper/decimal/decimal-helper';
import { EntityTranslatorService } from 'src/app/shared/service/entity-translator/entity-translator.service';
import { emptyAction } from '../../../../constants';
import {ProductFamilyService} from "../product-family/product-family.service";

@Injectable()
export class ProductsEffects {
  constructor(
    private readonly actions$: Actions,
    private readonly service: ProductsService,
    private readonly siteService: SiteService,
    private readonly store: Store<oeeAppReducer.OeeAppState>,
    private readonly errorMessageService: ErrorMessageService,
    private readonly excelHelperService: ExcelHelperService,
    private readonly customersService: CustomersService,
    private readonly decimalHelper: DecimalHelper,
    private translatorService: EntityTranslatorService,
    private readonly productFamilyService: ProductFamilyService,
  ) {}

  getSiteData = createEffect(() =>
    this.actions$.pipe(
      ofType(ObjectActions.PRODUCTS_SITES_DATA_LOADING),
      switchMap((payload: ObjectActions.ProductsSiteDataLoading) => {
        const httpParams: HttpParams = new HttpParams().set('limit', '1000');

        return from(this.siteService.getData(httpParams)).pipe(
          switchMap((response) => {
            return of(new ObjectActions.ProductsSiteDataLoaded(response));
          }),
          catchError((errorRes) => {
            return of(new ObjectActions.FetchError(errorRes), new AppActions.HideLoader());
          }),
        );
      }),
      catchError((errorRes) => {
        return of(new ObjectActions.FetchError(errorRes), new AppActions.HideLoader());
      }),
    ),
  );

  downloadProductExcel = createEffect(() =>
    this.actions$.pipe(
      ofType(ObjectActions.DOWNLOAD_PRODUCT_EXCEL),
      switchMap((objectData: ObjectActions.DownloadProductExcel) => {
        this.store.dispatch(new AppActions.ShowLoader());
        this.service.downloadProductExcel(
          objectData.withData,
          objectData.filters,
          this.service.prepareProductsHttpParams(objectData.tableQuery),
        );
        return emptyAction;
      }),
    ),
  );

  uploadExcel = createEffect(() =>
    this.actions$.pipe(
      ofType(ObjectActions.UPLOAD_PRODUCT_EXCEL),
      switchMap((objectData: ObjectActions.UploadProductExcel) => {
        this.store.dispatch(new AppActions.ShowLoader());

        return this.service.uploadExcel(objectData.products).pipe(
          switchMap((response: BulkResponseDataInterface) => {
            this.errorMessageService.getTranslatedErrorMessage(response.data);
            const mergedArray = this.excelHelperService.mergeBulkResponseWithRequestData<ProductInterface>(
              response,
              objectData.products.products,
            );
            return of(new ObjectActions.UploadProductExcelCompleted(mergedArray), new AppActions.HideLoader());
          }),
          catchError((error) => {
            return of(new ObjectActions.FetchError(error), new AppActions.HideLoader());
          }),
        );
      }),
    ),
  );

  getProducts = createEffect(() =>
    this.actions$.pipe(
      ofType(ObjectActions.PRODUCTS_DATA_LOADING),
      switchMap((payload: ObjectActions.ProductsDataLoading) => {
        return this.service.getProducts(this.service.prepareProductsHttpParams(payload.query)).pipe(
          map((response: GetManyResponseInterface<ProductInterface>) => {
            response.data.forEach((product: ProductInterface) => this.translatorService.translate(product));
            response.data = response.data.map((product: ProductInterface) => {
              return {
                ...product,
                siteName: product.site?.name,
                customerName: product.customer?.name,
                formattedProductSpeed: this.decimalHelper.toFixedValue(
                  product.productSpeed,
                  product.site?.decimalScaleLimit,
                ),
                formattedMinimumWaitingDuration: this.decimalHelper.toFixedValue(
                  product.minimumWaitingDuration,
                  product.site?.decimalScaleLimit,
                ),
                formattedMaximumWaitingDuration: this.decimalHelper.toFixedValue(
                  product.maximumWaitingDuration,
                  product.site?.decimalScaleLimit,
                ),
              };
            });

            return response;
          }),
          switchMap((response) => {
            return of(new ObjectActions.ProductsDataLoaded(response));
          }),
          catchError((errorRes) => {
            return of(new ObjectActions.FetchError(errorRes));
          }),
        );
      }),
      catchError((errorRes) => {
        return of(new ObjectActions.FetchError(errorRes));
      }),
    ),
  );

  deleteProducts = createEffect(() =>
    this.actions$.pipe(
      ofType(ObjectActions.DELETE_PRODUCTS),
      switchMap((objectData: ObjectActions.DeleteProducts) => {
        this.store.dispatch(new AppActions.ShowLoader());
        return this.service.deleteProducts(objectData.payload).pipe(
          switchMap((response: BulkResponseDataInterface | BaseCrudResponse) => {
            const responseMessages = 'data' in response && Array.isArray(response.data) ? response.data : [response];
            this.errorMessageService.getTranslatedErrorMessage(responseMessages);
            const mergedArray: MergedArrayDataInterface[] = this.excelHelperService.mergeBulkResponseWithRequestData(
              { ...response, data: responseMessages },
              objectData.payload.map((item) => {
                return { id: item };
              }),
              true,
            );
            return of(
              new ObjectActions.DeleteProductsCompleted(objectData.payload.length > 1, mergedArray),
              new AppActions.HideLoader(),
            );
          }),
          catchError((error) => {
            return of(new ObjectActions.FetchError(error), new AppActions.HideLoader());
          }),
        );
      }),
    ),
  );

  addProduct = createEffect(() =>
    this.actions$.pipe(
      ofType(ObjectActions.ADD_PRODUCT),
      switchMap((objectData: ObjectActions.AddProduct) => {
        this.store.dispatch(new AppActions.ShowLoader());

        return this.service.addProduct(objectData.product).pipe(
          switchMap((response: AddProductResponseInterface) => {
            return of(new ObjectActions.AddProductCompleted(response), new AppActions.HideLoader());
          }),
          catchError((error) => {
            return of(new ObjectActions.FetchError(error), new AppActions.HideLoader());
          }),
        );
      }),
    ),
  );

  editProduct = createEffect(() =>
    this.actions$.pipe(
      ofType(ObjectActions.EDIT_PRODUCT),
      switchMap((objectData: ObjectActions.EditProduct) => {
        this.store.dispatch(new AppActions.ShowLoader());

        return this.service.editProduct(objectData.product, objectData.productId).pipe(
          switchMap((response: EditProductResponseInterface) => {
            return of(new ObjectActions.EditProductCompleted(response), new AppActions.HideLoader());
          }),
          catchError((error) => {
            return of(new ObjectActions.FetchError(error), new AppActions.HideLoader());
          }),
        );
      }),
    ),
  );

  bulkEditProduct = createEffect(() =>
    this.actions$.pipe(
      ofType(ObjectActions.BULK_EDIT_PRODUCT),
      switchMap((objectData: ObjectActions.BulkEditProduct) => {
        this.store.dispatch(new AppActions.ShowLoader());

        return this.service.bulkEditProduct(objectData.products).pipe(
          switchMap((response: BulkResponseDataInterface) => {
            const responseMessages: BaseCrudResponse[] =
              'data' in response && Array.isArray(response.data) ? response.data : [response];
            this.errorMessageService.getTranslatedErrorMessage(responseMessages);
            const mergedArray: MergedArrayDataInterface[] = this.excelHelperService.mergeBulkResponseWithRequestData(
              { ...response, data: responseMessages },
              objectData.products.map((item) => {
                return { id: item.id };
              }),
              true,
            );
            return of(new ObjectActions.BulkEditProductCompleted(mergedArray), new AppActions.HideLoader());
          }),
          catchError((error) => {
            return of(new ObjectActions.FetchError(error), new AppActions.HideLoader());
          }),
        );
      }),
    ),
  );

  getCustomerData = createEffect(() =>
    this.actions$.pipe(
      ofType(ObjectActions.PRODUCTS_CUSTOMER_DATA_LOADING),
      switchMap((objectData: ObjectActions.ProductsCustomerDataLoading) => {
        this.store.dispatch(new AppActions.ShowLoader());
        const httpParams: HttpParams = new HttpParams()
          .set('limit', '1000')
          .set('join', 'customerSegment||segmentId,name')
          .set('s', JSON.stringify({ siteId: { $eq: objectData.siteId } }));

        return this.customersService.getCustomers(httpParams).pipe(
          switchMap((response: GetManyResponseInterface<CustomerInterface>) => {
            return of(new ObjectActions.ProductsCustomerDataLoaded(response), new AppActions.HideLoader());
          }),
          catchError((errorRes) => {
            return of(new ObjectActions.FetchError(errorRes), new AppActions.HideLoader());
          }),
        );
      }),
      catchError((errorRes) => {
        return of(new ObjectActions.FetchError(errorRes), new AppActions.HideLoader());
      }),
    ),
  );

  getProductFamilyData = createEffect(() =>
    this.actions$.pipe(
      ofType(ObjectActions.PRODUCTS_PRODUCTS_FAMILY_DATA_LOADING),
      switchMap((objectData: ObjectActions.ProductsProductFamilyDataLoading) => {
        this.store.dispatch(new AppActions.ShowLoader());
        const httpParams: HttpParams = new HttpParams()
          .set('limit', '1000')
          .set('s', JSON.stringify({ siteId: { $eq: objectData.siteId } }));
        return this.productFamilyService.getProductFamily(httpParams).pipe(
          switchMap((response: GetManyResponseInterface<ProductFamilyInterface>) => {
            return of(new ObjectActions.ProductsProductFamilyDataLoaded(response), new AppActions.HideLoader());
          }),
          catchError((errorRes) => {
            return of(new ObjectActions.FetchError(errorRes), new AppActions.HideLoader());
          }),
        );
      }),
      catchError((errorRes) => {
        return of(new ObjectActions.FetchError(errorRes), new AppActions.HideLoader());
      }),
    ),
  );
}
