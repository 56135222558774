import { Inject, Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { Store } from '@ngrx/store';
import * as oeeAppReducer from '../../oee.reducer';
import {
  BaseOneResponseInterface,
  GetManyResponseInterface,
} from '../../../shared/model/interface/crud-response-interface.model';
import { ProductionViewComponent } from '../../../view/dashboards/production-view/production-view.component';
import { map, mergeMap } from 'rxjs/operators';
import {
  ActivityLogsResponseInterface,
  IActivityLogsRequest,
  IProductionViewData,
  IWorkOrderCommentsRequest,
  IWorkOrderCommentsResponse,
} from './production-view.model';
import * as moment from 'moment';
import { mysqlDateFormat } from '../../../shared/helper/date';
import { User } from '../../user/model';
import { TagInterface } from '../../settings/tags/tags.model';
import { HelperService } from '../../../shared/service/helper.service';

@Injectable({
  providedIn: 'root',
})
export class ProductionViewService {
  public component: ProductionViewComponent;
  public timeZone: string = 'utc';
  public workOrderId: number = null;
  public siteId: number = null;

  constructor(
    public http: HttpClient,
    public helperService: HelperService,
    @Inject('API_BASE_URL') private readonly api: string,
    public store: Store<oeeAppReducer.OeeAppState>,
  ) {
    this.store.select('user').subscribe((state: User) => {
      if (state.isUserLoaded && !state.isUserLoading) {
        this.timeZone = state.timezone;
      }
    });
  }

  private readonly routes = {
    productionView: '/lines/:lineId/production-view',
    activityLogs: '/activity-histories/activity-logs',
    workOrderComments: '/comments/work-order-comments',
    tags: `/tags`,
  };

  public getProductionViewData(lineId: number): Observable<BaseOneResponseInterface<IProductionViewData>> {
    const url: string = `${this.api}${this.routes.productionView.replace(':lineId', String(lineId))}`;

    return this.http.get<BaseOneResponseInterface<IProductionViewData>>(url).pipe(
      mergeMap((response: BaseOneResponseInterface<IProductionViewData>) => {
        let body: IActivityLogsRequest = {
          isBusinessDate: true,
          sites: [response.data.siteId],
          lines: [lineId],
          lineTypes: -1,
          activities: -1,
          products: -1,
          rootCauseGroups: -1,
          shifts: -1,
          advancedFilterPage: 'production-view',
          advancedFilterParams: JSON.stringify({ $and: [] }),
          workOrders: response.data.workOrderId ? [response.data.workOrderId] : -1,
        };

        if (!response.data.workOrderId) {
          const currentShiftDay: string = response.data.currentShiftDay ?? moment.utc().format(mysqlDateFormat);
          body = {
            ...body,
            start: currentShiftDay,
            end: currentShiftDay,
          };
        }

        return this.http
          .post<BaseOneResponseInterface<ActivityLogsResponseInterface>>(
            `${this.api}${this.routes.activityLogs}`,
            body,
            {
              headers: new HttpHeaders({ 'X-HTTP-Method': 'GET' }),
            },
          )
          .pipe(
            mergeMap((responseSecond: BaseOneResponseInterface<ActivityLogsResponseInterface>) => {
              response.data['activities'] = responseSecond?.data ?? null;

              if (!response.data.workOrderId) {
                response.data['comments'] = [];

                return of(response);
              }

              const body: IWorkOrderCommentsRequest = {
                workOrderId: response.data.workOrderId,
                siteId: response.data.siteId,
              };

              return this.http
                .post<GetManyResponseInterface<IWorkOrderCommentsResponse>>(
                  `${this.api}${this.routes.workOrderComments}`,
                  body,
                  {
                    headers: new HttpHeaders({ 'X-HTTP-Method': 'GET' }),
                  },
                )
                .pipe(
                  map((responseThird: GetManyResponseInterface<IWorkOrderCommentsResponse>) => {
                    responseThird.data = responseThird.data.map((item: IWorkOrderCommentsResponse) => {
                      return {
                        ...item,
                        tagIds: JSON.parse(item.tagIds as string),
                      };
                    });
                    response.data['comments'] = responseThird?.data ?? [];

                    return response;
                  }),
                );
            }),
          );
      }),
    );
  }

  public getTags(siteIds: number[]): Observable<GetManyResponseInterface<TagInterface>> {
    let params: HttpParams = new HttpParams()
      .set(
        's',
        JSON.stringify({
          $or: [
            {
              siteId: { $in: siteIds },
            },
            {
              siteId: { $eq: null },
            },
          ],
        }),
      )
      .set('page', '1')
      .set('limit', '500');

    return this.http.get<GetManyResponseInterface<TagInterface>>(`${this.api}${this.routes.tags}`, {
      params,
    });
  }
}
