import * as Actions from './daily-production-performance.actions';
import { DailyProductionPerformanceStateInterface } from './daily-production-performance.model';

const initialState: DailyProductionPerformanceStateInterface = {
  dailyProductionPerformanceDataLoading: false,
  dailyProductionPerformanceDataLoaded: false,
  dailyProductionPerformanceActivityLogsLoading: false,
  dailyProductionPerformanceActivityLogsLoaded: false,
  dailyProductionPerformanceData: null,
  dailyProductionPerformanceActivityLogsData: null,
  selectedSiteId: null,
  selectedLineId: null,
  isBusinessDate: false,
};

export function dailyProductionPerformanceReducer(
  state: DailyProductionPerformanceStateInterface = initialState,
  action: Actions.DailyProductionPerformanceActions,
): DailyProductionPerformanceStateInterface {
  switch (action.type) {
    case Actions.DAILY_PRODUCTION_PERFORMANCE_DATA_LOADING:
      return {
        ...state,
        dailyProductionPerformanceDataLoading: true,
        dailyProductionPerformanceDataLoaded: false,
      };
    case Actions.DAILY_PRODUCTION_PERFORMANCE_DATA_LOADED:
      return {
        ...state,
        dailyProductionPerformanceDataLoading: false,
        dailyProductionPerformanceDataLoaded: true,
        dailyProductionPerformanceData: action.data,
      };
    case Actions.DAILY_PRODUCTION_PERFORMANCE_ACTIVITY_LOGS_LOADING:
      return {
        ...state,
        dailyProductionPerformanceActivityLogsLoading: true,
        dailyProductionPerformanceActivityLogsLoaded: false,
      };
    case Actions.DAILY_PRODUCTION_PERFORMANCE_ACTIVITY_LOGS_LOADED:
      return {
        ...state,
        dailyProductionPerformanceActivityLogsLoaded: true,
        dailyProductionPerformanceActivityLogsLoading: false,
        dailyProductionPerformanceActivityLogsData: action.payload,
      };
    case Actions.DAILY_PRODUCTION_PERFORMANCE_CLEAR_STATE:
      return {
        ...initialState,
      };
    default:
      return state;
  }
}
