import * as Actions from './site-view.actions';
import { SiteViewStateInterface } from './site-view.model';

export const siteViewInitialState: SiteViewStateInterface = {
  isFilterDataGenerated: false,
  isLineDataLoaded: false,
  isLineDataLoading: false,
  isSiteFilterLoaded: false,
  isSiteFilterLoading: false,
  siteViewData: null,
  selectedSiteFilters: [],
  sitesFilter: [],
  selectedSiteId: null,
  selectedLineIds: null,
  isInitialFilterDataLoaded: false,
}

export function siteViewReducer(
  state: SiteViewStateInterface = siteViewInitialState,
  action: Actions.SiteViewActions,
): SiteViewStateInterface {
  switch (action.type) {
    case Actions.SITE_VIEW_FILTER_DATA_LOADING:
      return {
        ...state,
        isSiteFilterLoading: true,
        isSiteFilterLoaded: false,
        isFilterDataGenerated: false,
      }
    case Actions.SITE_VIEW_FILTER_DATA_LOADED:
      return {
        ...state,
        isSiteFilterLoading: false,
        isSiteFilterLoaded: true,
      }
    case Actions.SITE_VIEW_FILTER_DATA_GENERATED:
      return {
        ...state,
        isFilterDataGenerated: true,
      }
    case Actions.SITE_VIEW_SET_FILTER_SETTINGS:
      return {
        ...state,
        sitesFilter: action.siteIds
      }
    case Actions.SITE_VIEW_LINE_DATA_LOADING:
      return {
        ...state,
        isLineDataLoading: true,
        isLineDataLoaded: false,
      }
    case Actions.SITE_VIEW_LINE_DATA_LOADED:
      return {
        ...state,
        isLineDataLoading: false,
        isLineDataLoaded: true,
        siteViewData: action.payload,
      }
    case Actions.SITE_VIEW_SELECT_SITE_AND_LINE:
      return {
        ...state,
        selectedSiteId: action.siteId,
        selectedLineIds: action.lineIds,
      };
    case Actions.SITE_VIEW_INITIAL_FILTER_DATA_LOADED:
      return  {
        ...state,
        isInitialFilterDataLoaded: true,
      }
    case Actions.SITE_VIEW_RESET_STORE_TO_DEFAULT:
      return {
        ...siteViewInitialState
      };
    default:
      return state;

  }
}
