import { Inject, Injectable } from '@angular/core';
import {
  BaseOneResponseInterface,
  GetManyResponseInterface,
} from '../../shared/model/interface/crud-response-interface.model';
import {
  ICreateManualCount,
  IManualCount,
  ISetHourlyCount,
  ICommentsResponse,
  IWorkOrderManualCountComment,
  IDistributionResponse,
  IDistributionData,
  IManualCountResponse,
} from '../../view/home/work-order/work-order-manual-count/work-order-manual-count.model';
import { Observable } from 'rxjs';
import { BulkResponse, ResponseInterface } from '../../shared/model/interface/generic-api-response.model';
import { HttpClient, HttpParams } from '@angular/common/http';

@Injectable({
  providedIn: 'root',
})
export class ManualCountService {
  private readonly routes = {
    productionCount: '/production-counts',
    setHourlyCount: 'set-hourly-count',
    saveComment: '/comments/bulk/save',
    distribution: '/production-counts/distribution',
  };

  constructor(private readonly http: HttpClient, @Inject('API_BASE_URL') private readonly baseUrl: string) {}

  public getHourlyCounts(
    workOrderId: number,
    lineId: number,
  ): Observable<BaseOneResponseInterface<IManualCountResponse>> {
    const httpParams = new HttpParams()
      .append('lineId', lineId)
      .append('pageLimit', 25)
      .append('pageOffset', 0)
      .append('mode', 'calculated');
    return this.http.get<BaseOneResponseInterface<IManualCountResponse>>(
      `${this.baseUrl}${this.routes.productionCount}/${workOrderId}`,
      {
        params: httpParams,
      },
    );
  }

  public createManualCount(manualCount: ICreateManualCount): Observable<BaseOneResponseInterface<IManualCount>> {
    return this.http.post<BaseOneResponseInterface<IManualCount>>(
      `${this.baseUrl}${this.routes.productionCount}`,
      manualCount,
    );
  }

  public setHourlyCount(manualCount: ISetHourlyCount): Observable<BaseOneResponseInterface<IManualCount>> {
    return this.http.post<BaseOneResponseInterface<IManualCount>>(
      `${this.baseUrl}${this.routes.productionCount}/${this.routes.setHourlyCount}`,
      manualCount,
    );
  }

  public setBulkHourlyCount(manualCounts: ISetHourlyCount[]): Observable<ResponseInterface<BulkResponse[]>> {
    return this.http.post<ResponseInterface<BulkResponse[]>>(
      `${this.baseUrl}${this.routes.productionCount}/bulk/${this.routes.setHourlyCount}`,
      { hourlyCounts: manualCounts },
    );
  }

  public saveComments(
    comments: IWorkOrderManualCountComment[],
  ): Observable<GetManyResponseInterface<ICommentsResponse>> {
    return this.http.post<GetManyResponseInterface<ICommentsResponse>>(`${this.baseUrl}${this.routes.saveComment}`, {
      comments,
    });
  }

  public getDistribution(httpParams: HttpParams): Observable<GetManyResponseInterface<IDistributionResponse>> {
    return this.http.get<GetManyResponseInterface<IDistributionResponse>>(
      `${this.baseUrl}${this.routes.distribution}`,
      { params: httpParams },
    );
  }

  public setDistribution(params: IDistributionData): Observable<GetManyResponseInterface<IDistributionResponse>> {
    return this.http.post<GetManyResponseInterface<IDistributionResponse>>(
      `${this.baseUrl}${this.routes.distribution}`,
      params,
    );
  }
}
