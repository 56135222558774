import { Pipe, PipeTransform } from '@angular/core';
import { DecimalHelper } from '../../helper/decimal/decimal-helper';

@Pipe({
  name: 'sanitizeString',
  standalone: true,
})
export class SanitizeStringPipe implements PipeTransform {
  constructor(private readonly decimalHelper: DecimalHelper) {}

  transform(value: string, removeThousandSeparator: boolean): string {
    let formattedValue: string = null;

    if (removeThousandSeparator) {
      formattedValue = this.decimalHelper.removeThousandSeparator(value);
    }

    return this.decimalHelper.sanitizeString(formattedValue);
  }
}
