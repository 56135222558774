import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import * as NotificationRecipientUserActions from './notification-recipient-user.actions';
import { catchError, switchMap } from 'rxjs/operators';
import { generateHttpOption } from '../../../shared/helper/app-helper';
import { HttpParams } from '@angular/common/http';
import { from, of } from 'rxjs';
import { NotificationRecipientUserCRUDInterface } from '../../../shared/component/filter/filter.class';
import { NotificationRecipientUserService } from '../../../shared/service/filter/notification-recipient-user.service';

@Injectable()
export class FilterNotificationRecipientUserEffects {
  constructor(
    private actions$: Actions<NotificationRecipientUserActions.FilterNotificationRecipientUserActions>,
    public notificationRecipientUserService: NotificationRecipientUserService,
  ) {}

  loadFilterNotificationRecipientUsers$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        NotificationRecipientUserActions.FilterNotificationRecipientUserActionTypes
          .FilterNotificationRecipientUsersLoading,
      ),
      switchMap((objectData: NotificationRecipientUserActions.FilterNotificationRecipientUsersLoading) => {
        const params: HttpParams = generateHttpOption(objectData.options);
        return from(this.notificationRecipientUserService.getData(params)).pipe(
          switchMap((response: NotificationRecipientUserCRUDInterface[]) => {
            return of(new NotificationRecipientUserActions.FilterNotificationRecipientUsersLoaded(response));
          }),
          catchError(() => {
            return of(new NotificationRecipientUserActions.FilterNotificationRecipientUsersLoaded([]));
          }),
        );
      }),
      catchError(() => {
        return of(new NotificationRecipientUserActions.FilterNotificationRecipientUsersLoaded([]));
      }),
    ),
  );
}
