import * as Actions from './oee-adherence.actions';
import { IOeeAdherenceState } from './oee-adherence.model';

export const oeeAdherenceInitialState: IOeeAdherenceState = {
  isOeeAdherenceWeekDataLoading: false,
  isOeeAdherenceWoDataLoading: false,
  isOeeAdherenceWeekDataLoaded: false,
  isOeeAdherenceWoDataLoaded: false,
  oeeAdherenceWeekData: null,
  oeeAdherenceWoData: null,
  oeeAdherenceExcelWoData: null,
};

export function oeeAdherenceReducer(
  state = oeeAdherenceInitialState,
  action: Actions.OeeAdherenceActions,
): IOeeAdherenceState {
  switch (action.type) {
    case Actions.OEE_ADHERENCE_WEEK_DATA_LOADING:
      return {
        ...state,
        isOeeAdherenceWeekDataLoading: true,
        isOeeAdherenceWeekDataLoaded: false,
      };

    case Actions.OEE_ADHERENCE_WO_DATA_LOADING:
      return {
        ...state,
        isOeeAdherenceWoDataLoading: true,
        isOeeAdherenceWoDataLoaded: false,
      };

    case Actions.OEE_ADHERENCE_WEEK_DATA_LOADED:
      return {
        ...state,
        isOeeAdherenceWeekDataLoading: false,
        isOeeAdherenceWeekDataLoaded: true,
        oeeAdherenceWeekData: action.payload,
      };

    case Actions.OEE_ADHERENCE_WO_DATA_LOADED:
      return {
        ...state,
        isOeeAdherenceWoDataLoading: false,
        isOeeAdherenceWoDataLoaded: true,
        oeeAdherenceWoData: action.payload,
      };

    case Actions.WEEK_DATA_FETCH_ERROR:
      return {
        ...state,
        isOeeAdherenceWeekDataLoading: false,
        isOeeAdherenceWeekDataLoaded: true,
      };

    case Actions.WO_DATA_FETCH_ERROR:
      return {
        ...state,
        isOeeAdherenceWoDataLoading: false,
        isOeeAdherenceWoDataLoaded: true,
      };

    default:
      return state;
  }
}
