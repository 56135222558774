import { Action } from '@ngrx/store';
import {
  IProductResourceData,
  IProductResourceRequestPayload,
  IProductResourceSet,
  IResourceEditRequestPayload,
  IResourceItemRequestPayload,
  ResourceGroup,
} from './resources.model';
import { GenericCrudRequestConstructionParameters } from '../../../../../shared/model/interface/generic-api-request.model';
import { BulkResponseDataInterface } from '../../../../../shared/model/interface/crud-response-interface.model';

export enum ResourcesActionTypes {
  LOAD_RESOURCE_GROUPS = '[Resources Page] Load Resource Groups',
  RESOURCE_GROUPS_LOADED_SUCCESS = '[Resources API] Resource Groups Loaded Success',
  RESOURCE_GROUPS_LOADED_ERROR = '[Resources API] Resource Groups Loaded Error',
  RESET_RESOURCE_GROUPS_SELECTION = '[PRODUCT RESOURCES] RESET RESOURCE GROUPS SELECTION',
  GET_MANY_PRODUCT_RESOURCES_LOADING = '[PRODUCT RESOURCES] GET MANY PRODUCT RESOURCES LOADING',
  GET_MANY_PRODUCT_RESOURCES_LOADED = '[PRODUCT RESOURCES] GET MANY PRODUCT RESOURCES LOADED',
  ADD_PRODUCT_RESOURCE = '[PRODUCT RESOURCES] ADD PRODUCT RESOURCE',
  ADD_PRODUCT_RESOURCE_COMPLETED = '[PRODUCT RESOURCES] ADD PRODUCT RESOURCE COMPLETED',
  ADD_PRODUCT_RESOURCE_ITEM = '[PRODUCT RESOURCES] ADD PRODUCT RESOURCE ITEM',
  ADD_PRODUCT_RESOURCE_ITEM_COMPLETED = '[PRODUCT RESOURCES] ADD PRODUCT RESOURCE ITEM COMPLETED',
  EDIT_PRODUCT_RESOURCE = '[PRODUCT RESOURCES] EDIT PRODUCT RESOURCES',
  EDIT_PRODUCT_RESOURCE_COMPLETED = '[PRODUCT RESOURCES] EDIT PRODUCT RESOURCE COMPLETED',
  DELETE_PRODUCT_RESOURCE = '[PRODUCT RESOURCES] DELETE PRODUCT RESOURCE',
  DELETE_PRODUCT_RESOURCE_COMPLETED = '[PRODUCT RESOURCES] DELETE PRODUCT RESOURCE COMPLETED',
  DELETE_PRODUCT_RESOURCES = '[PRODUCT RESOURCES] DELETE PRODUCT RESOURCES',
  DELETE_PRODUCT_RESOURCES_COMPLETED = '[PRODUCT RESOURCES] DELETE PRODUCT RESOURCES COMPLETED',
  FETCH_ERROR = '[PRODUCT RESOURCES] FETCH ERROR',
}

export class GetManyProductResourcesLoading implements Action {
  readonly type = ResourcesActionTypes.GET_MANY_PRODUCT_RESOURCES_LOADING;
  constructor(public parameters: GenericCrudRequestConstructionParameters, public productId: number) {}
}

export class GetManyProductResourcesLoaded implements Action {
  readonly type = ResourcesActionTypes.GET_MANY_PRODUCT_RESOURCES_LOADED;
  constructor(public payload: IProductResourceData) {}
}

export class LoadResourceGroups implements Action {
  readonly type = ResourcesActionTypes.LOAD_RESOURCE_GROUPS;
  constructor(public readonly payload: { siteId: number }) {}
}

export class ResourceGroupsLoadedError implements Action {
  readonly type = ResourcesActionTypes.RESOURCE_GROUPS_LOADED_ERROR;
}

export class ResourceGroupsLoadedSuccess implements Action {
  readonly type = ResourcesActionTypes.RESOURCE_GROUPS_LOADED_SUCCESS;
  constructor(public readonly payload: ResourceGroup[]) {}
}

export class ResetResourceGroupsSelection implements Action {
  readonly type = ResourcesActionTypes.RESET_RESOURCE_GROUPS_SELECTION;
}

export class AddProductResource implements Action {
  readonly type: ResourcesActionTypes.ADD_PRODUCT_RESOURCE = ResourcesActionTypes.ADD_PRODUCT_RESOURCE;

  constructor(public body: IProductResourceRequestPayload) {}
}

export class AddProductResourceCompleted implements Action {
  readonly type: ResourcesActionTypes.ADD_PRODUCT_RESOURCE_COMPLETED =
    ResourcesActionTypes.ADD_PRODUCT_RESOURCE_COMPLETED;

  constructor(public payload: IProductResourceSet) {}
}

export class AddProductResourceItem implements Action {
  readonly type: ResourcesActionTypes.ADD_PRODUCT_RESOURCE_ITEM = ResourcesActionTypes.ADD_PRODUCT_RESOURCE_ITEM;

  constructor(public body: IResourceItemRequestPayload[]) {}
}

export class AddProductResourceItemCompleted implements Action {
  readonly type: ResourcesActionTypes.ADD_PRODUCT_RESOURCE_ITEM_COMPLETED =
    ResourcesActionTypes.ADD_PRODUCT_RESOURCE_ITEM_COMPLETED;

  constructor(public payload: BulkResponseDataInterface) {}
}

export class EditProductResource implements Action {
  readonly type: ResourcesActionTypes.EDIT_PRODUCT_RESOURCE = ResourcesActionTypes.EDIT_PRODUCT_RESOURCE;

  constructor(public params: IResourceEditRequestPayload) {}
}

export class EditProductResourceCompleted implements Action {
  readonly type: ResourcesActionTypes.EDIT_PRODUCT_RESOURCE_COMPLETED =
    ResourcesActionTypes.EDIT_PRODUCT_RESOURCE_COMPLETED;
}

export class DeleteProductResource implements Action {
  readonly type: ResourcesActionTypes.DELETE_PRODUCT_RESOURCE = ResourcesActionTypes.DELETE_PRODUCT_RESOURCE;

  constructor(public productResourcesId: number) {}
}

export class DeleteProductResourceCompleted implements Action {
  readonly type: ResourcesActionTypes.DELETE_PRODUCT_RESOURCE_COMPLETED =
    ResourcesActionTypes.DELETE_PRODUCT_RESOURCE_COMPLETED;

  constructor(public success: boolean) {}
}

export class DeleteProductResources implements Action {
  readonly type: ResourcesActionTypes.DELETE_PRODUCT_RESOURCES = ResourcesActionTypes.DELETE_PRODUCT_RESOURCES;

  constructor(public productResourcesIds: number[]) {}
}

export class DeleteProductResourcesCompleted implements Action {
  readonly type: ResourcesActionTypes.DELETE_PRODUCT_RESOURCES_COMPLETED =
    ResourcesActionTypes.DELETE_PRODUCT_RESOURCES_COMPLETED;

  constructor(public payload: BulkResponseDataInterface) {}
}

export class FetchError implements Action {
  readonly type: ResourcesActionTypes.FETCH_ERROR = ResourcesActionTypes.FETCH_ERROR;

  constructor(public payload: object) {}
}

export type ResourcesActions =
  | GetManyProductResourcesLoading
  | GetManyProductResourcesLoaded
  | LoadResourceGroups
  | ResourceGroupsLoadedError
  | ResourceGroupsLoadedSuccess
  | ResetResourceGroupsSelection
  | AddProductResource
  | AddProductResourceCompleted
  | AddProductResourceItem
  | AddProductResourceItemCompleted
  | EditProductResource
  | EditProductResourceCompleted
  | DeleteProductResource
  | DeleteProductResourceCompleted
  | DeleteProductResources
  | DeleteProductResourcesCompleted
  | FetchError;
