export interface IClientSiteButtonListSection<ListItem> {
  header?: string;
  items: ListItem[];
}

export type KeysWithTypeOfString<T> = {
  [K in keyof T]: T[K] extends string ? K : never;
}[keyof T];

export enum EClientSideButtonListConfirmMethods {
  onListItemClick,
  onConfirmButtonClick,
}
