import { Inject, Injectable } from '@angular/core';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { SelectWorkOrderLineModalComponent } from '../../../standalone/select-work-order-line-modal/select-work-order-line-modal.component';
import { xlModal } from '../../../../constants';
import { forkJoin, Observable, Subscription, throwError } from 'rxjs';
import { HttpClient, HttpParams } from '@angular/common/http';
import { catchError, take } from 'rxjs/operators';
import {
  ECardAssignmentObjectTypeEnum,
  IDataParams,
  IRfidActivity,
  IRfidJob,
  IRfidLine,
  IRfidWorkOrder,
  IWorkOrderAdditionalData,
} from './rfid-card-reader.model';
import { Router } from '@angular/router';
import { cloneDeep, isEqual } from 'lodash';
import {
  BaseOneResponseInterface,
  GetManyResponseInterface,
} from '../../model/interface/crud-response-interface.model';
import { ActivityTypes } from '../../model/enum/activity-types';
import * as HomeActions from '../../../store/home/home.actions';
import * as UserActions from '../../../store/user/actions';
import { ActionsSubject, Store } from '@ngrx/store';
import { OeeAppState } from 'src/app/store/oee.reducer';
import { ofType } from '@ngrx/effects';
import { ToastHelperService } from '../toast/toast.helper.service';
import { TranslateService } from '@ngx-translate/core';
import * as LineActions from './../../../store/line/actions';
import * as AppActions from '../../../store/app/actions';

@Injectable({
  providedIn: 'root',
})
export class RfidCardReaderService {
  private readonly identicalDataRefreshWaitDurationInMs: number = 10000;
  private modalRef: NgbModalRef | null = null;
  private lines$: IRfidLine[] = [];
  private activities$: IRfidActivity[] = [];
  private workOrders$: IRfidWorkOrder[] = [];
  private job$: IRfidJob = null;
  private inProcessRequestData: IWorkOrderAdditionalData | null = null;
  private updateCurrentUserLoadedSubscription: Subscription;

  constructor(
    @Inject('API_BASE_URL')
    private readonly baseUrl: string,
    private readonly http: HttpClient,
    private readonly ngbModal: NgbModal,
    private readonly router: Router,
    private readonly store: Store<OeeAppState>,
    private readonly actionsSubject: ActionsSubject,
    private readonly toastHelper: ToastHelperService,
    private readonly translate: TranslateService,
  ) {}

  public processBroadcastData(broadcastAdditionalData: IWorkOrderAdditionalData): void {
    if (!broadcastAdditionalData?.siteId || isEqual(this.inProcessRequestData, broadcastAdditionalData)) {
      return;
    }

    this.inProcessRequestData = cloneDeep(broadcastAdditionalData);

    const params: IDataParams = {
      siteId: broadcastAdditionalData.siteId,
      jobId:
        broadcastAdditionalData.assignedObjectType === ECardAssignmentObjectTypeEnum.JOB
          ? broadcastAdditionalData.assignedObjectId
          : undefined,
      workOrderId:
        broadcastAdditionalData.assignedObjectType === ECardAssignmentObjectTypeEnum.WORK_ORDER
          ? broadcastAdditionalData.assignedObjectId
          : undefined,
    };

    this.toastHelper.showToastMessage(true, undefined, this.translate.instant('modal.selectWorkOrderLine.cardScanned'));

    try {
      this.processRfidCardData(params, broadcastAdditionalData);
    } catch (error) {
      this.inProcessRequestData = null;
      this.displayCardScannedError();
    }
  }

  public goToHome(siteId: number, lineId: number): void {
    if (siteId === null || lineId === null) {
      return;
    }

    this.updateCurrentUserLoadedSubscription?.unsubscribe();
    this.createUpdateCurrentUserLoadedSubscription();

    this.inProcessRequestData = null;

    this.store.dispatch(new UserActions.UpdateCurrentUser(siteId, lineId, false, false));
    this.store.dispatch(new HomeActions.HomeMetricSetItemsSetDefaultState());
    this.store.dispatch(new HomeActions.ResetPhaseDurationOnSiteLineSelection());
  }

  private processRfidCardData(params: IDataParams, broadcastAdditionalData: IWorkOrderAdditionalData): void {
    this.fetchData(params)
      .pipe(
        take(1),
        catchError((error) => {
          this.displayCardScannedError();
          this.inProcessRequestData = null;

          return throwError(() => error);
        }),
      )
      .subscribe((response): void => {
        this.lines$ = response.lines?.data;
        this.activities$ = response.activities?.data;
        this.workOrders$ = response.workOrders?.data;
        this.job$ = response.job?.data;

        const usableWorkOrders: IRfidWorkOrder[] = this.getUsableWorkOrders();
        const ongoingLine: IRfidLine | null = this.getOngoingLine(usableWorkOrders);

        if (ongoingLine) {
          this.modalRef?.close();
          this.goToHome(ongoingLine.siteId, ongoingLine.id);

          return;
        }

        const workOrderExistsWithoutUsableWorkOrders: boolean =
          usableWorkOrders.length === 0 && this.workOrders$.length > 0;

        if (this.workOrders$.length === 0) {
          this.toastHelper.showToastMessage(
            true,
            undefined,
            this.translate.instant('modal.selectWorkOrderLine.noWorkOrderAssigned'),
          );
        } else if (usableWorkOrders.length > 0) {
          this.openWorkOrderLineSelectModal(broadcastAdditionalData.assignedObjectType, usableWorkOrders);
        } else if (
          workOrderExistsWithoutUsableWorkOrders &&
          broadcastAdditionalData.assignedObjectType === ECardAssignmentObjectTypeEnum.JOB
        ) {
          this.toastHelper.showToastMessage(
            true,
            undefined,
            this.translate.instant('modal.workOrdersAlreadyFinalized.label.workOrdersFinalized'),
          );
        } else if (
          workOrderExistsWithoutUsableWorkOrders &&
          broadcastAdditionalData.assignedObjectType === ECardAssignmentObjectTypeEnum.WORK_ORDER
        ) {
          this.toastHelper.showToastMessage(
            true,
            undefined,
            this.translate.instant('modal.workOrdersAlreadyFinalized.label.workOrderFinalized'),
          );
        }

        setTimeout(() => {
          this.inProcessRequestData = null;
        }, this.identicalDataRefreshWaitDurationInMs);
      });
  }

  private getUsableWorkOrders(): IRfidWorkOrder[] {
    const lineTypes: number[] = this.lines$.map((line: IRfidLine) => line.lineType);

    return this.workOrders$.reduce((acc: IRfidWorkOrder[], workOrder: IRfidWorkOrder) => {
      if ((!lineTypes.includes(workOrder.departmentId) && workOrder.departmentId) || workOrder.completed) {
        return acc;
      }

      acc.push(workOrder);

      return acc;
    }, []);
  }

  private createUpdateCurrentUserLoadedSubscription(): void {
    this.updateCurrentUserLoadedSubscription = this.actionsSubject
      .pipe(ofType(UserActions.UPDATE_CURRENT_USER_LOADED))
      .subscribe(() => {
        this.store.dispatch(new AppActions.HideLoader());
        this.updateCurrentUserLoadedSubscription?.unsubscribe();
        this.modalRef?.close();
        this.ngbModal.dismissAll();

        if (this.router.url === '/home') {
          this.store.dispatch(new LineActions.UpdateActivity({ lineChanged: true }));

          return;
        }

        this.router.navigate(['/home']);
      });
  }

  private getOngoingLine(usableWorkOrders: IRfidWorkOrder[]): IRfidLine | null {
    for (const workOrder of usableWorkOrders) {
      if (workOrder.completed || !workOrder.hasOnGoingActivity) {
        continue;
      }

      const ongoingLine: IRfidLine = this.lines$.find((line: IRfidLine) => line.workOrderId === workOrder.id);

      if (ongoingLine) {
        return ongoingLine;
      }
    }

    return null;
  }

  private openWorkOrderLineSelectModal(
    cardAssignmentObjectType: ECardAssignmentObjectTypeEnum,
    selectableWorkOrders: IRfidWorkOrder[],
  ): void {
    this.modalRef?.close();
    this.modalRef = this.ngbModal.open(SelectWorkOrderLineModalComponent, xlModal);
    this.modalRef.result.then(() => {
      this.inProcessRequestData = null;
    });

    this.modalRef.componentInstance.modalData = {
      cardAssignmentObjectType,
      lines: this.lines$,
      activities: this.activities$,
      workOrders: selectableWorkOrders,
      job: this.job$,
    };
  }

  private fetchData(params: IDataParams): Observable<{
    lines: GetManyResponseInterface<IRfidLine>;
    activities: GetManyResponseInterface<IRfidActivity>;
    workOrders: GetManyResponseInterface<IRfidWorkOrder>;
    job?: BaseOneResponseInterface<IRfidJob>;
  }> {
    const { siteId, jobId, workOrderId } = params;
    const httpParams: HttpParams = new HttpParams().set('limit', '1000');
    const lineParams: HttpParams = httpParams
      .set('fields', 'title,activityIds,lineType,workOrderId,siteId')
      .set('sort', 'workOrderId,ASC')
      .set('s', JSON.stringify({ siteId, statusId: 1 }))
      .append('join', 'currentWorkOrder||woNumber');
    const activityParams: HttpParams = httpParams
      .set('fields', 'activityType')
      .set('s', JSON.stringify({ active: true, activityType: ActivityTypes.RUN_TIME }));
    const workOrderParams: HttpParams = httpParams
      .set('fields', 'woNumber,completed,departmentId,siteId')
      .set('sort', 'woNumber,ASC')
      .set(
        's',
        JSON.stringify({
          $and: [
            { siteId: { $eq: siteId } },
            ...(jobId ? [{ jobId: { $eq: jobId } }] : []),
            ...(workOrderId ? [{ id: { $eq: workOrderId } }] : []),
          ],
        }),
      )
      .append('join', 'childWorkOrderSplitNumbers');
    const jobParams: HttpParams = httpParams.set('fields', 'jobName');

    return forkJoin({
      lines: this.getLines(lineParams),
      activities: this.getActivities(activityParams),
      workOrders: this.getWorkOrders(workOrderParams),
      ...(jobId ? { job: this.getJob(jobId, jobParams) } : {}),
    });
  }

  private displayCardScannedError(): void {
    this.toastHelper.showToastMessage(
      false,
      undefined,
      this.translate.instant('modal.selectWorkOrderLine.cardScanned'),
    );
  }

  private getLines(params: HttpParams): Observable<GetManyResponseInterface<IRfidLine>> {
    return this.http.get<GetManyResponseInterface<IRfidLine>>(`${this.baseUrl}/lines`, { params });
  }

  private getActivities(params: HttpParams): Observable<GetManyResponseInterface<IRfidActivity>> {
    return this.http.get<GetManyResponseInterface<IRfidActivity>>(`${this.baseUrl}/activities`, { params });
  }

  private getWorkOrders(params: HttpParams): Observable<GetManyResponseInterface<IRfidWorkOrder>> {
    return this.http.get<GetManyResponseInterface<IRfidWorkOrder>>(`${this.baseUrl}/work-orders`, { params });
  }

  private getJob(id: number, params: HttpParams): Observable<BaseOneResponseInterface<IRfidJob>> {
    return this.http.get<BaseOneResponseInterface<IRfidJob>>(`${this.baseUrl}/jobs/${id}`, { params });
  }
}
