import { Action } from '@ngrx/store';
import {
  ResponseArrayInterface,
  ResponseInterface,
} from '../../../../shared/model/interface/generic-api-response.model';
import {
  ITableQuery,
  FloorPlanInterface,
  LineInterface,
  SiteInterface,
  StationInterface,
  IFloorPlanWithPathConfiguration,
  ILinePathConfiguration,
} from './floor-plans.model';
import {
  BaseOneResponseInterface,
  BulkResponseDataInterface,
} from '../../../../shared/model/interface/crud-response-interface.model';

export const FLOOR_PLANS_FLOOR_PLAN_DATA_LOADING = '[FLOOR_PLANS] FLOOR_PLAN DATA LOADING';
export const FLOOR_PLANS_FLOOR_PLAN_DATA_LOADED = '[FLOOR_PLANS] FLOOR_PLAN DATA LOADED';
export const FLOOR_PLANS_SITE_DATA_LOADING = '[FLOOR_PLANS] SITE DATA LOADING';
export const FLOOR_PLANS_SITE_DATA_LOADED = '[FLOOR_PLANS] SITE DATA LOADED';
export const FLOOR_PLANS_LINE_DATA_LOADING = '[FLOOR_PLANS] LINE DATA LOADING';
export const FLOOR_PLANS_LINE_DATA_LOADED = '[FLOOR_PLANS] LINE DATA LOADED';
export const FLOOR_PLANS_STATION_DATA_LOADING = '[FLOOR_PLANS] STATION DATA LOADING';
export const FLOOR_PLANS_STATION_DATA_LOADED = '[FLOOR_PLANS] STATION DATA LOADED';
export const FLOOR_PLANS_CREATE_FLOOR_PLAN_STARTED = '[FLOOR_PLANS] CREATE FLOOR_PLAN STARTED';
export const FLOOR_PLANS_CREATE_FLOOR_PLAN_COMPLETED = '[FLOOR_PLANS] CREATE FLOOR_PLAN COMPLETED';
export const FLOOR_PLANS_UPDATE_FLOOR_PLAN_STARTED = '[FLOOR_PLANS] UPDATE FLOOR_PLAN STARTED';
export const FLOOR_PLANS_UPDATE_FLOOR_PLAN_COMPLETED = '[FLOOR_PLANS] UPDATE FLOOR_PLAN COMPLETED';
export const FLOOR_PLANS_DELETE_FLOOR_PLAN_STARTED = '[FLOOR_PLANS] DELETE FLOOR_PLAN STARTED';
export const FLOOR_PLANS_DELETE_FLOOR_PLAN_COMPLETED = '[FLOOR_PLANS] DELETE FLOOR_PLAN COMPLETED';
export const FLOOR_PLANS_BULK_DELETE_FLOOR_PLANS_STARTED = '[FLOOR_PLANS] BULK DELETE FLOOR_PLANS STARTED';
export const FLOOR_PLANS_BULK_DELETE_FLOOR_PLANS_COMPLETED = '[FLOOR_PLANS] BULK DELETE FLOOR_PLANS COMPLETED';
export const FLOOR_PLANS_GET_FLOOR_PLAN_IMAGE_LOADING = '[FLOOR_PLAN] GET FLOOR_PLAN IMAGE LOADING';
export const FLOOR_PLANS_GET_FLOOR_PLAN_IMAGE_LOADED = '[FLOOR_PLAN] GET FLOOR_PLAN IMAGE LOADED';
export const FLOOR_PLANS_UPLOAD_FLOOR_PLAN_IMAGE_LOADING = '[FLOOR_PLAN] UPLOAD FLOOR_PLAN IMAGE LOADING';
export const FLOOR_PLANS_UPLOAD_FLOOR_PLAN_IMAGE_LOADED = '[FLOOR_PLAN] UPLOAD FLOOR_PLAN IMAGE LOADED';
export const FLOOR_PLANS_RESET_STORE_TO_DEFAULT = '[FLOOR_PLAN] RESET STORE TO DEFAULT';
export const FLOOR_PLANS_DELETE_FLOOR_PLAN_IMAGE_STARTED = '[FLOOR PLAN] FLOOR PLANS DELETE FLOOR PLAN IMAGE STARTED';
export const FLOOR_PLANS_DELETE_FLOOR_PLAN_IMAGE_COMPLETED =
  '[FLOOR PLAN] FLOOR PLANS DELETE FLOOR PLAN IMAGE COMPLETED';
export const FLOOR_PLANS_FETCH_ERROR = '[FLOOR_PLANS] FETCH ERROR';
export const FLOOR_PLANS_STATION_PATH_DATA_LOADING = '[FLOOR_PLANS] STATION PATH DATA LOADING';
export const FLOOR_PLANS_STATION_PATH_DATA_LOADED = '[FLOOR_PLANS] STATION PATH DATA LOADED';
export const FLOOR_PLANS_LINE_PATH_DATA_LOADING = '[FLOOR_PLANS] LINE PATH DATA LOADING';
export const FLOOR_PLANS_LINE_PATH_DATA_LOADED = '[FLOOR_PLANS] LINE PATH DATA LOADED';

export class FloorPlanDataLoading implements Action {
  readonly type = FLOOR_PLANS_FLOOR_PLAN_DATA_LOADING;

  constructor(public tableQuery: ITableQuery) {}
}

export class FloorPlanDataLoaded implements Action {
  readonly type = FLOOR_PLANS_FLOOR_PLAN_DATA_LOADED;

  constructor(public response: ResponseArrayInterface<FloorPlanInterface>) {}
}

export class SiteDataLoading implements Action {
  readonly type = FLOOR_PLANS_SITE_DATA_LOADING;
}

export class SiteDataLoaded implements Action {
  readonly type = FLOOR_PLANS_SITE_DATA_LOADED;

  constructor(public response: ResponseInterface<SiteInterface[]>) {}
}

export class LineDataLoading implements Action {
  readonly type = FLOOR_PLANS_LINE_DATA_LOADING;

  constructor(public siteId: number) {}
}

export class LineDataLoaded implements Action {
  readonly type = FLOOR_PLANS_LINE_DATA_LOADED;

  constructor(public response: ResponseInterface<LineInterface[]>) {}
}

export class StationDataLoading implements Action {
  readonly type = FLOOR_PLANS_STATION_DATA_LOADING;

  constructor(public lineId: number) {}
}

export class StationDataLoaded implements Action {
  readonly type = FLOOR_PLANS_STATION_DATA_LOADED;

  constructor(public response: ResponseInterface<StationInterface[]>) {}
}

export class CreateFloorPlan implements Action {
  readonly type = FLOOR_PLANS_CREATE_FLOOR_PLAN_STARTED;

  constructor(public payload: FloorPlanInterface) {}
}

export class CreateFloorPlanCompleted implements Action {
  readonly type = FLOOR_PLANS_CREATE_FLOOR_PLAN_COMPLETED;

  constructor(public response: BaseOneResponseInterface<FloorPlanInterface>) {}
}

export class UpdateFloorPlan implements Action {
  readonly type = FLOOR_PLANS_UPDATE_FLOOR_PLAN_STARTED;

  constructor(public floorPlanId: number, public payload: FloorPlanInterface) {}
}

export class UpdateFloorPlanCompleted implements Action {
  readonly type = FLOOR_PLANS_UPDATE_FLOOR_PLAN_COMPLETED;

  constructor(public response: BaseOneResponseInterface<FloorPlanInterface>) {}
}

export class DeleteFloorPlan implements Action {
  readonly type = FLOOR_PLANS_DELETE_FLOOR_PLAN_STARTED;

  constructor(public floorPlanId: number) {}
}

export class DeleteFloorPlanCompleted implements Action {
  readonly type = FLOOR_PLANS_DELETE_FLOOR_PLAN_COMPLETED;

  constructor(public response: BulkResponseDataInterface) {}
}

export class BulkDeleteFloorPlans implements Action {
  readonly type = FLOOR_PLANS_BULK_DELETE_FLOOR_PLANS_STARTED;

  constructor(public floorPlanIds: number[]) {}
}

export class BulkDeleteFloorPlansCompleted implements Action {
  readonly type = FLOOR_PLANS_BULK_DELETE_FLOOR_PLANS_COMPLETED;

  constructor(public response: BulkResponseDataInterface) {}
}

export class GetFloorPlanImageLoading implements Action {
  readonly type = FLOOR_PLANS_GET_FLOOR_PLAN_IMAGE_LOADING;

  constructor(public imagePath: string) {}
}

export class GetFloorPlanImageLoaded implements Action {
  readonly type = FLOOR_PLANS_GET_FLOOR_PLAN_IMAGE_LOADED;

  constructor(public response: BaseOneResponseInterface<string>, public path: string) {}
}

export class UploadFloorPlanImageLoading implements Action {
  readonly type = FLOOR_PLANS_UPLOAD_FLOOR_PLAN_IMAGE_LOADING;

  constructor(public id: number, public base64ImageContent: string) {}
}

export class UploadFloorPlanImageLoaded implements Action {
  readonly type = FLOOR_PLANS_UPLOAD_FLOOR_PLAN_IMAGE_LOADED;
}

export class DeleteFloorPlanImageStarted implements Action {
  readonly type = FLOOR_PLANS_DELETE_FLOOR_PLAN_IMAGE_STARTED;

  constructor(public id: number) {}
}

export class DeleteFloorPlanImageCompleted implements Action {
  readonly type = FLOOR_PLANS_DELETE_FLOOR_PLAN_IMAGE_COMPLETED;
}

export class ResetStoreToDefault implements Action {
  readonly type = FLOOR_PLANS_RESET_STORE_TO_DEFAULT;
}

export class FetchError implements Action {
  readonly type = FLOOR_PLANS_FETCH_ERROR;

  constructor(public response: object) {}
}

export class FloorPlansStationPathDataLoading implements Action {
  readonly type = FLOOR_PLANS_STATION_PATH_DATA_LOADING;

  constructor(public lineId: number) {}
}

export class FloorPlansStationPathDataLoaded implements Action {
  readonly type = FLOOR_PLANS_STATION_PATH_DATA_LOADED;

  constructor(public response: ResponseInterface<IFloorPlanWithPathConfiguration>) {}
}

export class FloorPlansLinePathDataLoading implements Action {
  readonly type = FLOOR_PLANS_LINE_PATH_DATA_LOADING;

  constructor(public floorPlanId: number) {}
}

export class FloorPlansLinePathDataLoaded implements Action {
  readonly type = FLOOR_PLANS_LINE_PATH_DATA_LOADED;

  constructor(public payload: ILinePathConfiguration[]) {}
}

export type FloorPlanActions =
  | FloorPlanDataLoading
  | FloorPlanDataLoaded
  | SiteDataLoading
  | SiteDataLoaded
  | LineDataLoading
  | LineDataLoaded
  | StationDataLoading
  | StationDataLoaded
  | CreateFloorPlan
  | CreateFloorPlanCompleted
  | UpdateFloorPlan
  | UpdateFloorPlanCompleted
  | DeleteFloorPlan
  | DeleteFloorPlanCompleted
  | BulkDeleteFloorPlans
  | BulkDeleteFloorPlansCompleted
  | GetFloorPlanImageLoading
  | GetFloorPlanImageLoaded
  | UploadFloorPlanImageLoading
  | UploadFloorPlanImageLoaded
  | ResetStoreToDefault
  | FetchError
  | FloorPlansStationPathDataLoading
  | FloorPlansStationPathDataLoaded
  | DeleteFloorPlanImageStarted
  | DeleteFloorPlanImageCompleted
  | FloorPlansLinePathDataLoading
  | FloorPlansLinePathDataLoaded;
