import { ScheduleAdherenceStateInterface } from './schedule-adherence.model';
import * as Actions from './schedule-adherence.actions';

export const initState: ScheduleAdherenceStateInterface = {
  scheduleAdherenceDataLoading: false,
  scheduleAdherenceDataLoaded: false,
  scheduleAdherenceJobDataLoading: false,
  scheduleAdherenceJobDataLoaded: false,
  currentData: null,
  schedulerJobData: null,
  filters: null,
  tableSettings: [],
  customConfiguration: [],
};

export function scheduleAdherenceReducer(
  state: ScheduleAdherenceStateInterface = initState,
  action: Actions.ScheduleAdherenceActions,
): ScheduleAdherenceStateInterface {
  switch (action.type) {
    case Actions.SCHEDULE_ADHERENCE_DATA_LOADING:
      return {
        ...state,
        scheduleAdherenceDataLoading: true,
        scheduleAdherenceDataLoaded: false,
        currentData: null,
      };
    case Actions.SCHEDULE_ADHERENCE_DATA_LOADED:
      return {
        ...state,
        scheduleAdherenceDataLoading: false,
        scheduleAdherenceDataLoaded: true,
        currentData: action.payload,
      };
    case Actions.SCHEDULE_ADHERENCE_FETCH_ERROR:
      return {
        ...state,
        scheduleAdherenceDataLoading: false,
        scheduleAdherenceDataLoaded: true,
        currentData: null,
      };
    case Actions.SET_TABLE_SETTINGS:
      return {
        ...state,
        tableSettings: action.payload,
        customConfiguration: action.customSections,
      };
    case Actions.SET_SELECTED_FILTERS:
      return {
        ...state,
        filters: action.filters,
      };
    case Actions.SCHEDULE_ADHERENCE_JOB_DATA_LOADING:
      return {
        ...state,
        scheduleAdherenceJobDataLoading: true,
        scheduleAdherenceJobDataLoaded: false,
        schedulerJobData: null,
      };
    case Actions.SCHEDULE_ADHERENCE_JOB_DATA_LOADED:
      return {
        ...state,
        scheduleAdherenceJobDataLoading: false,
        scheduleAdherenceJobDataLoaded: true,
        schedulerJobData: action.payload,
      };
    case Actions.CLEAR_STATES:
      return {
        ...state,
        scheduleAdherenceDataLoading: false,
        scheduleAdherenceDataLoaded: false,
        scheduleAdherenceJobDataLoading: false,
        scheduleAdherenceJobDataLoaded: false,
        schedulerJobData: null,
        currentData: null,
      };
    default:
      return state;
  }
}
