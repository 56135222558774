import { Component, EventEmitter, Input, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BaseModalComponent } from '../base-modal/base-modal.component';
import { ScwMatButtonModule } from '../../../shared/component/scw-mat-ui/scw-mat-button/scw-mat-button.module';
import { ScwMatSearchModule } from '../../../shared/component/scw-mat-ui/scw-mat-search/scw-mat-search.module';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { ScwMatRadioGroupModule } from '../../../shared/component/scw-mat-ui/scw-mat-radio-group/scw-mat-radio-group.module';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ScwMatRadioGroupOption } from '../../../shared/component/scw-mat-ui/scw-mat-radio-group/scw-mat-radio-group.model';
import { IButtonConfiguration } from './radio-select-modal.model';

@Component({
  selector: 'scw-radio-select-modal',
  standalone: true,
  imports: [
    CommonModule,
    BaseModalComponent,
    ScwMatButtonModule,
    ScwMatSearchModule,
    TranslateModule,
    ScwMatRadioGroupModule,
  ],
  templateUrl: './radio-select-modal.component.html',
  styleUrls: ['./radio-select-modal.component.scss'],
})
export class RadioSelectModalComponent<RadioOption extends ScwMatRadioGroupOption = ScwMatRadioGroupOption> {
  @Input() modal: NgbActiveModal;
  @Input() options: RadioOption[] = [];
  @Input() modalHeader: string;
  @Input() message: string;
  @Input() confirmButtonConfiguration: IButtonConfiguration = {
    type: 'cta',
    text: this.translate.instant('general.confirm'),
  };
  @Input() set defaultOption(defaultOption: RadioOption['value']) {
    if (!this.selectedOption && defaultOption) {
      this.selectedOption = defaultOption;
    }
  }

  @Output() onCancel: EventEmitter<void> = new EventEmitter();
  @Output() onConfirm: EventEmitter<RadioOption['value']> = new EventEmitter<RadioOption['value']>();

  public selectedOption: RadioOption['value'];

  constructor(private readonly translate: TranslateService) {}

  public cancel(): void {
    this.onCancel.emit();
  }

  public confirm(): void {
    this.onConfirm.emit(this.selectedOption);
  }
}
