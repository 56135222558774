import * as Actions from './changeover.actions';
import { IChangeoverState } from './changeover.model';

export const changeoverInitialState: IChangeoverState = {
  getChangeoverKpiInformationLoading: false,
  getChangeoverKpiInformationCompleted: false,
  getChangeoverChartDataLoading: false,
  getChangeoverChartDataCompleted: false,
  changeoverKpiData: null,
  changeoverChartData: null,
};

export function changeoverReducer(state = changeoverInitialState, action: Actions.ChangeoverActions): IChangeoverState {
  switch (action.type) {
    case Actions.GET_CHANGEOVER_KPI_INFORMATION:
      return {
        ...state,
        getChangeoverKpiInformationLoading: true,
        getChangeoverKpiInformationCompleted: false,
      };
    case Actions.GET_CHANGEOVER_KPI_INFORMATION_COMPLETED:
      return {
        ...state,
        getChangeoverKpiInformationLoading: false,
        getChangeoverKpiInformationCompleted: true,
        changeoverKpiData: action.data,
      };
    case Actions.GET_CHANGEOVER_CHART_DATA:
      return {
        ...state,
        getChangeoverChartDataLoading: true,
        getChangeoverChartDataCompleted: false,
      };
    case Actions.GET_CHANGEOVER_CHART_DATA_COMPLETED:
      return {
        ...state,
        getChangeoverChartDataLoading: false,
        getChangeoverChartDataCompleted: true,
        changeoverChartData: action.data,
      }
    case Actions.FETCH_ERROR:
      return changeoverInitialState;
    default:
      return state;
  }
}
