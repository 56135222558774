import { Inject, Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { TCreateIssueItemRequestPayload } from '../../../store/action-tracker/action-tracker.types';

@Injectable({
  providedIn: 'root',
})
export class ActionTrackerService {
  private readonly routes = {
    getBoards: `/action-tracker/boards`,
    getBoardMembers: `/action-tracker/:id/members`,
    createOneItem: `/action-tracker/items`,
    uploadBulkAttachments: `/action-tracker/items/attachments`,
    getCurrentActionTrackerUser: '/action-tracker/current-user',
    getRecommendedBoard: '/action-tracker/recommended-board',
  };

  constructor(public http: HttpClient, @Inject('API_BASE_URL') private readonly apiURL: string) {}

  public getBoards(searchKeyword?: string): Observable<any> {
    const url: string = `${this.apiURL}${this.routes.getBoards}`;
    let params: HttpParams = new HttpParams();

    if (searchKeyword) {
      params = params.set('s', `{"name": {"$contL":"${searchKeyword}"}}`);
    }

    return this.http.get(url, { params });
  }

  public getBoardMembers(boardId: number, searchKeyword?: string): Observable<any> {
    const url: string = `${this.apiURL}${this.routes.getBoardMembers.replace(':id', boardId.toString())}`;

    let params: HttpParams = new HttpParams();

    if (searchKeyword) {
      params = params.set('search', searchKeyword);
    }

    return this.http.get(url, { params });
  }

  public createOneItem(payload: TCreateIssueItemRequestPayload): Observable<any> {
    const url: string = `${this.apiURL}${this.routes.createOneItem}`;

    return this.http.post(url, payload);
  }

  public uploadBulkAttachments(boardId: number, itemId: number, files: File[]) {
    const url: string = `${this.apiURL}${this.routes.uploadBulkAttachments}`;

    const formData: FormData = new FormData();

    formData.append('boardId', boardId.toString());
    formData.append('itemId', itemId.toString());

    files.forEach((file) => {
      formData.append('files', file, file.name);
    });

    return this.http.post(url, formData);
  }

  public getCurrentActionTrackerUser(): Observable<any> {
    const url: string = `${this.apiURL}${this.routes.getCurrentActionTrackerUser}`;

    return this.http.get(url);
  }

  public getRecommendedBoard(): Observable<any> {
    const url: string = `${this.apiURL}${this.routes.getRecommendedBoard}`;

    return this.http.get(url);
  }
}
