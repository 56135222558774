<div class="bg-c-lightgrey metric-item d-flex flex-column h-100 p-2 oee-metric-apq">
  <div class="row h-100 m-0">
    <div class="col-12 d-flex justify-content-center">
      <div class="nowrap text-center">
        <p class="head-text-2 three-dot c-title m-b-0">{{ 'kpiCards.metrics.' + this.cardTitleKey | translate }}</p>
        <p *ngIf="subtitle" class="head-text-2 three-dot c-title m-b-0">
          {{ subtitle }}
        </p>
      </div>
    </div>
    <div
      *ngFor="let oeeMetric of this.sortedMetricLayouts; let index = index"
      class="unit-card"
      [ngClass]="{
        'col-6':
          index === 0
            ? this.properties.metricLayout.length === 4
            : index > 1 || this.properties.metricLayout.length > 2,
        'col-12':
          index === 0
            ? this.properties.metricLayout.length !== 4
            : index === 1 && this.properties.metricLayout.length === 2
      }"
    >
      <div class="w-100 h-100 d-grid">
        <p class="head-text-3 c-title m-0 align-self-center text-truncate">
          {{ 'homeScreenMetric.properties.metricLayout.' + oeeMetric | translate }}
        </p>
        <p class="head-text m-0 align-self-center text-truncate">
          {{ (oeeValuesFormatted$ | async)?.[oeeMetric] }}
        </p>
      </div>
    </div>
  </div>
</div>
