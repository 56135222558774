import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, switchMap } from 'rxjs/operators';
import { from, of } from 'rxjs';
import * as AppActions from '../app/actions';
import * as ObjectActions from './scheduler-scenario.actions';
import * as SchedulerActions from '../../store/scheduler/scheduler.actions';
import { Store } from '@ngrx/store';
import * as oeeAppReducer from '../oee.reducer';
import { SchedulerScenarioService } from '../../shared/service/scheduler-scenario/scheduler-scenario.service';
import { ScenarioInterface } from './scheduler-scenario.model';
import { HttpParams } from '@angular/common/http';
import { SiteService } from '../../shared/service/filter/site.service';
import { UserService } from '../../shared/service/user/user.service';
import {
  BaseCrudResponse,
  BaseOneResponseInterface,
  BulkResponseDataInterface,
  GetManyResponseInterface,
} from '../../shared/model/interface/crud-response-interface.model';
import { UserGetManyCRUDDataInterface } from '../../shared/service/user/user.model';
import { SchedulerResourceSettingService } from '../../shared/service/scheduler-resource-setting/scheduler-resource-setting.service';
import { ISchedulerResourceSetting } from '../../view/scheduler-resource-settings/scheduler-resource-settings.model';
import { ErrorMessageService } from '../../shared/service/error-message.service';
import { ExcelHelperService } from '../../shared/service/excel/excel.helper.service';
import { MergedArrayDataInterface } from '../../../constants.model';

@Injectable()
export class SchedulerScenarioEffects {
  constructor(
    private readonly store: Store<oeeAppReducer.OeeAppState>,
    private readonly actions$: Actions<ObjectActions.SchedulerScenarioActions>,
    private readonly schedulerScenarioService: SchedulerScenarioService,
    private readonly schedulerResourceSettingService: SchedulerResourceSettingService,
    private readonly siteService: SiteService,
    private readonly userService: UserService,
    private readonly errorMessageService: ErrorMessageService,
  ) {}

  $loadScenarios = createEffect(() =>
    this.actions$.pipe(
      ofType(ObjectActions.SchedulerScenarioActionTypes.LoadSchedulerScenarios),
      switchMap((objectData: ObjectActions.LoadSchedulerScenarios) => {
        return this.schedulerScenarioService.loadScenarios(objectData.params).pipe(
          switchMap((response) => {
            return of(new ObjectActions.SchedulerScenariosLoaded(response));
          }),
          catchError((error) => {
            return of(new ObjectActions.FetchError(error));
          }),
        );
      }),
      catchError((error) => {
        return of(new ObjectActions.FetchError(error), new AppActions.HideLoader());
      }),
    ),
  );

  $getUserData = createEffect(() =>
    this.actions$.pipe(
      ofType(ObjectActions.SchedulerScenarioActionTypes.LoadScenarioUsers),
      switchMap((objectData: ObjectActions.LoadScenarioUsers) => {
        const queryParams: HttpParams = this.schedulerScenarioService.getUserHttpParams(
          objectData.userId,
          objectData.searchText,
          objectData.siteId,
        );

        return from(this.userService.getUsersRequest(queryParams)).pipe(
          switchMap((response: UserGetManyCRUDDataInterface) => {
            return of(new ObjectActions.ScenarioUsersLoaded(response));
          }),
          catchError((errorRes) => {
            return of(new ObjectActions.FetchError(errorRes), new AppActions.HideLoader());
          }),
        );
      }),
      catchError((errorRes) => {
        return of(new ObjectActions.FetchError(errorRes), new AppActions.HideLoader());
      }),
    ),
  );

  $getSiteData = createEffect(() =>
    this.actions$.pipe(
      ofType(ObjectActions.SchedulerScenarioActionTypes.SchedulerSiteDataLoading),
      switchMap((payload: ObjectActions.SchedulerSiteDataLoading) => {
        const httpParams: HttpParams = new HttpParams().set('limit', '1000');

        return from(this.siteService.getData(httpParams)).pipe(
          switchMap((response) => {
            return of(new ObjectActions.SchedulerSiteDataLoaded(response));
          }),
          catchError((errorRes) => {
            return of(new ObjectActions.FetchError(errorRes), new AppActions.HideLoader());
          }),
        );
      }),
      catchError((errorRes) => {
        return of(new ObjectActions.FetchError(errorRes), new AppActions.HideLoader());
      }),
    ),
  );

  $createScenario = createEffect(() =>
    this.actions$.pipe(
      ofType(ObjectActions.SchedulerScenarioActionTypes.CreateSchedulerScenario),
      switchMap((objectData: ObjectActions.CreateSchedulerScenario) => {
        this.store.dispatch(new AppActions.ShowLoader());

        return this.schedulerScenarioService.createScenario(objectData.scenario).pipe(
          switchMap((response: BaseOneResponseInterface<ScenarioInterface>) => {
            return of(new AppActions.HideLoader(), new ObjectActions.SchedulerScenarioCreated(response));
          }),
          catchError((error) => {
            return of(
              new AppActions.HideLoader(),
              new ObjectActions.FetchError(error),
              new ObjectActions.ScenarioError(),
            );
          }),
        );
      }),
      catchError((error) => {
        return of(new ObjectActions.FetchError(error), new AppActions.HideLoader());
      }),
    ),
  );

  $updateScenario = createEffect(() =>
    this.actions$.pipe(
      ofType(ObjectActions.SchedulerScenarioActionTypes.UpdateSchedulerScenario),
      switchMap((objectData: ObjectActions.UpdateSchedulerScenario) => {
        this.store.dispatch(new AppActions.ShowLoader());

        return this.schedulerScenarioService.updateScenario(objectData.id, objectData.scenario).pipe(
          switchMap((response: BaseOneResponseInterface<ScenarioInterface>) => {
            return of(
              ...[
                new AppActions.HideLoader(),
                new ObjectActions.SchedulerScenarioUpdated(response, objectData.isFromResourceSetting),
                new SchedulerActions.UpdateScenarioDetails(response.data),
                new SchedulerActions.ScenarioPrepared(false),
                new SchedulerActions.ScenarioItemsLoadedToGanttChart(false),
                new SchedulerActions.ClearApplyData(),
                new SchedulerActions.ClearSchedulerData(),
                ...(objectData.isProductionScheduler ? [new SchedulerActions.KpiMetricInfoLoad(objectData.id)] : []),
              ],
            );
          }),
          catchError((error) => {
            return of(
              new AppActions.HideLoader(),
              new ObjectActions.FetchError(error),
              new ObjectActions.ScenarioError(),
            );
          }),
        );
      }),
      catchError((error) => {
        return of(new ObjectActions.FetchError(error), new AppActions.HideLoader());
      }),
    ),
  );

  $bulkEditScenario = createEffect(() =>
    this.actions$.pipe(
      ofType(ObjectActions.SchedulerScenarioActionTypes.BulkEditScenario),
      switchMap((objectData: ObjectActions.BulkEditSchedulerScenario) => {
        this.store.dispatch(new AppActions.ShowLoader());
        return this.schedulerScenarioService.bulkEditScenarios(objectData.scenarios).pipe(
          switchMap((response: BulkResponseDataInterface) => {
            const responseMessages: BaseCrudResponse[] =
              'data' in response && Array.isArray(response.data) ? response.data : [response];
            this.errorMessageService.getTranslatedErrorMessage(responseMessages);
            const mergedArray: MergedArrayDataInterface[] = ExcelHelperService.mergeBulkResponseWithRequestData(
              { ...response, data: responseMessages },
              objectData.scenarios.map((item) => {
                return { id: item.id };
              }),
              true,
            );

            return of(new ObjectActions.BulkEditSchedulerScenarioCompleted(mergedArray), new AppActions.HideLoader());
          }),
          catchError((error) => {
            return of(new ObjectActions.FetchError(error), new AppActions.HideLoader());
          }),
        );
      }),
      catchError((error) => {
        return of(new ObjectActions.FetchError(error), new AppActions.HideLoader());
      }),
    ),
  );

  $cloneScenario = createEffect(() =>
    this.actions$.pipe(
      ofType(ObjectActions.SchedulerScenarioActionTypes.CloneSchedulerScenario),
      switchMap((objectData: ObjectActions.CloneSchedulerScenario) => {
        this.store.dispatch(new AppActions.ShowLoader());

        return this.schedulerScenarioService.cloneScenario(objectData.id).pipe(
          switchMap((response: BaseOneResponseInterface<ScenarioInterface>) => {
            return of(new AppActions.HideLoader(), new ObjectActions.SchedulerScenarioCloned(response));
          }),
          catchError((error) => {
            return of(
              new AppActions.HideLoader(),
              new ObjectActions.FetchError(error),
              new ObjectActions.ScenarioError(),
            );
          }),
        );
      }),
      catchError((error) => {
        return of(new ObjectActions.FetchError(error), new AppActions.HideLoader());
      }),
    ),
  );

  $deleteScenarios = createEffect(() =>
    this.actions$.pipe(
      ofType(ObjectActions.SchedulerScenarioActionTypes.DeleteSchedulerScenario),
      switchMap((objectData: ObjectActions.DeleteSchedulerScenario) => {
        this.store.dispatch(new AppActions.ShowLoader());
        const isBulk: boolean = Array.isArray(objectData.payload);

        return (
          isBulk
            ? this.schedulerScenarioService.deleteScenarios(objectData.payload as number[])
            : this.schedulerScenarioService.deleteScenario(objectData.payload as number)
        ).pipe(
          switchMap((response: BaseCrudResponse | BulkResponseDataInterface) => {
            const responseMessages = 'data' in response && Array.isArray(response.data) ? response.data : [response];
            this.errorMessageService.getTranslatedErrorMessage(responseMessages);

            return of(
              new AppActions.HideLoader(),
              new ObjectActions.DeleteSchedulerScenarioCompleted(
                ExcelHelperService.mergeBulkResponseWithRequestData(
                  { ...response, data: responseMessages },
                  ((isBulk ? objectData.payload : [objectData.payload]) as number[]).map((item) => {
                    return { id: item };
                  }),
                  true,
                ),
              ),
            );
          }),
          catchError((error) => {
            return of(
              new ObjectActions.FetchError(error),
              new AppActions.HideLoader(),
              new ObjectActions.ScenarioError(),
            );
          }),
        );
      }),
      catchError((error) => {
        return of(new ObjectActions.FetchError(error), new AppActions.HideLoader());
      }),
    ),
  );

  $loadSchedulerResourceSettings = createEffect(() =>
    this.actions$.pipe(
      ofType(ObjectActions.SchedulerScenarioActionTypes.LoadSchedulerResourceSettings),
      switchMap((objectData: ObjectActions.LoadSchedulerResourceSettings) => {
        this.store.dispatch(new AppActions.ShowLoader());
        const httpParams: HttpParams = new HttpParams()
          .append('join', 'schSettingLine')
          .append('limit', '500')
          .set('s', JSON.stringify({ siteId: { $eq: objectData.siteId } }));

        return this.schedulerResourceSettingService.getResourceSettings(httpParams).pipe(
          switchMap((response: GetManyResponseInterface<ISchedulerResourceSetting>) => {
            return of(new AppActions.HideLoader(), new ObjectActions.SchedulerResourceSettingsLoaded(response.data));
          }),
          catchError((error) => {
            return of(
              new ObjectActions.FetchError(error),
              new AppActions.HideLoader(),
              new ObjectActions.ScenarioError(),
            );
          }),
        );
      }),
      catchError((error) => {
        return of(new ObjectActions.FetchError(error), new AppActions.HideLoader());
      }),
    ),
  );
}
