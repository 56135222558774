import { environment } from '../environments/environment';

export function getApiUrl(): string {
  const origin = location.origin;
  const possibleSearchValues = {
    oee: 'digitalfactory-api',
    scheduler: 'digitalfactory-api',
    digitalfactory: 'digitalfactory-api',
  };
  const regExp = new RegExp(Object.keys(possibleSearchValues).join('|'), 'gi');
  const returnUrl = origin.replace(regExp, (match) => {
    return possibleSearchValues[match.toLowerCase()];
  });

  if (environment.production) {
    return returnUrl;
  }

  return `${returnUrl}/api`;
}

export function getProxyUrl(): string {
  const stagingProxyKey: string = 'staging-es';
  const elasticSearchKey: string = 'search';

  if (!['PROD', 'TRAINING'].includes(environment.ssoEnv)) {
    return environment.esProxyUrl.replace(elasticSearchKey, stagingProxyKey);
  }

  return environment.esProxyUrl;
}

export function getFileUploadServiceUrl(): string {
  return environment.fileUploadUrl;
}

export function formatDurationFromSecond(duration: number): string {
  let secondDuration = duration;
  let negative = '';

  if (secondDuration < 0) {
    secondDuration = secondDuration * -1;
    negative = '-';
  }

  const hours = Math.floor(secondDuration / 3600);
  const minutes = Math.floor((secondDuration - hours * 3600) / 60);
  const seconds = parseInt((secondDuration - hours * 3600 - minutes * 60).toFixed(0), 10);

  let hoursDt = hours.toString();

  if (hours < 10) {
    hoursDt = `0${hoursDt}`;
  }

  let minutesDt = minutes.toString();

  if (minutes < 10) {
    minutesDt = `0${minutesDt}`;
  }

  let secondsDt = seconds.toString();

  if (seconds < 10) {
    secondsDt = `0${secondsDt}`;
  }

  return `${negative + hoursDt}:${minutesDt}:${secondsDt}`;
}
