import { IActivityButtonMultiLineState } from './activity-button-multi-line.model';
import { ELoadStatus } from '../../../constants.model';
import { ActionTypes, ActivityButtonMultiLineActions } from './activity-button-multi-line.actions';

const initialState: IActivityButtonMultiLineState = {
  fetchStatus: ELoadStatus.Initial,
  activitiesByLines: {},
};

export function activityButtonMultiLineReducer(
  state: IActivityButtonMultiLineState = initialState,
  action: ActivityButtonMultiLineActions,
): IActivityButtonMultiLineState {
  switch (action.type) {
    case ActionTypes.FetchActivitiesOfLine:
      return {
        ...state,
        fetchStatus: ELoadStatus.Loading,
      };
    case ActionTypes.FetchActivitiesOfLineCompleted:
      return {
        ...state,
        fetchStatus: ELoadStatus.Success,
        activitiesByLines: {
          ...state.activitiesByLines,
          [action.lineId]: action.activities,
        },
      };
    default:
      return { ...state };
  }
}
