import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, TemplateRef, ViewChild } from '@angular/core';
import { ActionsSubject, Store } from '@ngrx/store';
import { AppState } from 'src/app/store/app.state';
import * as HomeActions from '../../../../store/home/home.actions';
import { OnDestroyDecorator } from 'src/app/shared/decorator/on-destroy-decorator';
import { Subscription } from 'rxjs';
import { ofType } from '@ngrx/effects';
import { HomeActionTypes } from 'src/app/store/home/home.actions';
import { IProductVersion } from 'src/app/store/settings/products/products.model';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { ProductCRUDInterface } from '../../filter/filter.class';
import { TWorkOrderProductVersionHeader } from './work-order-product-version-modal.model';

@OnDestroyDecorator
@Component({
  selector: 'app-work-order-product-version-modal',
  templateUrl: './work-order-product-version-modal.component.html',
  styleUrls: ['./work-order-product-version-modal.component.scss', '../../../../view/home/home.component.scss'],
})
export class WorkOrderProductVersionModalComponent implements OnInit, OnDestroy {
  @ViewChild('work_order_select_product_version_modal') workOrderSelectProductVersionModalRef: TemplateRef<WorkOrderProductVersionModalComponent>;

  @Input() productID: number | null;

  @Input() modalHeader?: TWorkOrderProductVersionHeader;

  @Output() selectedProductVersionEvent: EventEmitter<IProductVersion> = new EventEmitter<IProductVersion>();

  private readonly subscriptions: Subscription[] = [];

  public productVersions$: IProductVersion[] = [];

  public isLazyLoadingProgress: boolean = false;

  public workOrderSelectProductVersionModal?: NgbModalRef;


  constructor(private readonly store: Store<AppState>, private readonly actions: ActionsSubject, private readonly modalService: NgbModal) {}

  public ngOnInit(): void {
    this.addSubscription(
      this.actions
        .pipe(ofType<HomeActions.ProductVersionsLoaded>(HomeActionTypes.ProductVersionsLoaded))
        .subscribe((state: HomeActions.ProductVersionsLoaded) => {
          this.productVersions$ = state.payload;
        }),
    );
  }

  public onSelectProductVersion(productVersion: IProductVersion): void {
    this.selectedProductVersionEvent.emit(productVersion);
    this.closeModal();
  }

  public showModal(): void {
    this.fetchProductVersionsOfCurrentProduct();
    this.workOrderSelectProductVersionModal = this.modalService.open(this.workOrderSelectProductVersionModalRef, {
      windowClass: 'scw-modal-xl',
      keyboard: false,
      backdrop: 'static',
    });
  }

  public closeModal(): void {
    this.workOrderSelectProductVersionModal.close();
  }

  public onScroll(): void {
    this.isLazyLoadingProgress = true;
  }

  public ngOnDestroy(): void {
    this.unsubscribeFromAllSubscriptions();
  }

  private fetchProductVersionsOfCurrentProduct(): void {
    if (this.productID !== null) {
      this.store.dispatch(new HomeActions.ProductVersionsLoad(this.productID));
    }
  }

  private addSubscription(subscription: Subscription): void {
    this.subscriptions.push(subscription);
  }

  private unsubscribeFromAllSubscriptions(): void {
    this.subscriptions.forEach((subscription: Subscription) => subscription.unsubscribe());
  }
}
