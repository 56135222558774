<div class="row activity-button-container">
  <div *ngFor="let button of buttonsData" class="activity col-xs-12 col-sm-6 col-md-4 col-lg-2">
    <button
      class="btn btn-block {{ button.class }} ripple light activity-button"
      [disabled]="activityIsChanging"
      [title]="button.name"
      (click)="selectActivity(button, task_selection)"
      [attr.data-automation-activity-name]="button.automationActivityName"
    >
      <div
        [fittext]="true"
        class="mb-2 activity-name"
        [activateOnResize]="true"
        [delay]="100"
        [minFontSize]="14"
        [maxFontSize]="21"
      >
        {{ button.name }}
      </div>
      <div class="icon">
        <i [class]="button.icon ? button.icon.activityCardIconClass : null"></i>
      </div>
      <div class="head-text-6 nowrap mt-2 type-title">
        {{ 'general.activityType.' + button.typeTitle | translate }}
      </div>
    </button>
  </div>
</div>
<ng-template #task_selection let-modal let-c="close" let-d="dismiss">
  <div class="modal-header scw-modal-header">
    <h3 class="modal-title scw-header">
      {{ 'main.activityButtons.selectTaskTitle' | translate }}
    </h3>
    <button type="button" class="close" onfocus="blur()" aria-label="Close" (click)="modal.dismiss()">
      <span aria-hidden="true">
        <em class="fas fa-times"></em>
      </span>
    </button>
  </div>
  <div class="modal-body">
    <task-selection
      [rootAddress]="taskTreeRootAddress"
      [ignoreHidden]="ignoreHidden"
      (selectedTask)="editedEquipmentAndTask($event, workOrderTableId)"
    ></task-selection>
  </div>
  <div class="modal-footer">
    <div class="buttons-left">
      <scw-mat-button type="standard" (onClick)="modal.dismiss()">
        {{ 'general.close' | translate }}
      </scw-mat-button>
    </div>
  </div>
</ng-template>
<ng-template #workOrderPhaseComment let-modal>
  <div class="modal-header scw-modal-header">
    <h3 class="modal-title scw-header">
      {{ 'homeScreenMetrics.phaseComment.title' | translate }}
    </h3>
    <button type="button" class="close" onfocus="blur()" aria-label="Close" (click)="onPhaseCommentModalClosedByUser()">
      <span aria-hidden="true">
        <em class="fas fa-times"></em>
      </span>
    </button>
  </div>
  <work-order-phase-comment-modal
    [phase]="phase"
    [phaseType]="phaseType"
    [phaseComment]="phaseComment"
    (cancelButtonClicked)="onPhaseCommentModalClosedByUser()"
  ></work-order-phase-comment-modal>
</ng-template>
<ng-template #pleaseSelectWorkOrderModal let-modal>
  <div class="modal-header scw-modal-header">
    <h3 class="modal-title scw-header">
      {{ 'activitySelection.changeActivityError.title' | translate }}
    </h3>
    <button type="button" class="close" onfocus="blur()" aria-label="Close" (click)="modal.close()">
        <span aria-hidden="true">
          <em class="fas fa-times"></em>
        </span>
    </button>
  </div>
  <div class="modal-body">
    <div class="row">
      <div class="col-md-1">
        <em class="fas fa-exclamation-circle warning-modal-icon"></em>
      </div>
      <div class="col-md-11">
        <h6 class="scw-header warning-message">
          {{ 'activitySelection.changeActivityError.message' | translate }}
        </h6>
      </div>
    </div>
  </div>
  <div *ngIf="action !== 'edit'"  class="modal-footer">
    <div class="buttons-right">
      <scw-mat-button type="standard" (onClick)="openWorkOrderModal()">{{
        'main.workOrder.selectWorkOrder' | translate
      }}</scw-mat-button>
    </div>
  </div>
</ng-template>
<ng-template #work_order_finalizer let-modal>
  <div class="modal-header scw-modal-header">
    <h3 class="modal-title scw-header">
      {{ 'main.workOrderFinalizerModal.title' | translate }}
    </h3>
    <button type="button" class="close" onfocus="blur()" aria-label="Close" (click)="modal.close()">
        <span aria-hidden="true">
          <em class="fas fa-times"></em>
        </span>
    </button>
  </div>
  <div class="modal-body">
    <div class="row">
      <div class="col-md-1">
        <em class="fas fa-exclamation-circle"></em>
      </div>
      <div class="col-md-11">
        <h6 class="scw-header warning-message">
          {{ 'main.workOrderFinalizerModal.info' | translate: {
            currentActivity: currentActivity.activityName,
            newActivity: selectedActivityName,
            workOrder: workOrderCloseData.workOrderNumber
        } }}
        </h6>
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <div class="buttons-left">
      <scw-mat-button type="standard" (onClick)="changeActivityWithoutFinalizing()" data-automation-id="wo-finalizer-change-activity-btn">
        {{ 'main.workOrderFinalizerModal.changeActivity' | translate }}
      </scw-mat-button>
    </div>
    <div class="buttons-right">
      <scw-mat-button (onClick)="continueFinalizer()" data-automation-id="wo-finalizer-finalize-btn">
        {{ 'general.workOrderButton.finalize' | translate }}
      </scw-mat-button>
    </div>
  </div>
</ng-template>

<ng-template #work_order_edit let-modal>
  <div class="modal-header scw-modal-header">
    <h3 class="modal-title scw-header">
      <i class="far fa-list-alt"></i>
      {{ 'main.workOrder.selectWorkOrder' | translate }}
    </h3>
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss()">
      <span aria-hidden="true">
        <em class="fas fa-times"></em>
      </span>
    </button>
  </div>
  <app-select-work-order
    (selectWoLineConfirmed)="workOrderSave($event.newWorkOrderHeader.id)"
    (onAddAndUpdateSubmit)="updateCurrentWoIntoNew($event)"
    (closeThisModal)="closeSelectWorkOrderModal()"
    (completedWoOnAddConfirmed)="workOrderSave($event.newWorkOrderHeader.id)"
    [workOrderHeader]="workOrderHeader"
    [configuration]="selectWorkOrderModalConfigurations$"
    [homeStationId]="homeStation.id"
  ></app-select-work-order>
</ng-template>
