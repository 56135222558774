import { Action } from '@ngrx/store';
import {
  CreateTaskGroupResponseInterface,
  DeleteTaskGroupResponseInterface,
  MoveTaskResponseInterface, SiteDataInterface,
  SiteDropdownDataResponseInterface,
  TaskDataInterface,
  TaskGroupsAndRemainingTasksDataResponseInterface,
  UpdateTaskGroupResponseInterface,
} from './task-groups.model';
import { HttpErrorResponse } from '@angular/common/http';
import { GetManyResponseInterface } from '../../shared/model/interface/crud-response-interface.model';

export enum TaskGroupsActionTypes {
  LoadSiteDropdownData = '[TaskGroups] Load Site Dropdown Data',
  SiteDropdownDataLoaded = '[TaskGroups] Site Dropdown Data Loaded',
  LoadTaskGroupsAndRemainingTasksData = '[TaskGroups] Load Task Groups And Remaining Tasks Data',
  TaskGroupsAndRemainingTasksDataLoaded = '[TaskGroups] Task Groups And Remaining Tasks Data Loaded',
  DeleteTaskGroup = '[TaskGroups] Delete Task Group',
  DeleteTaskGroupCompleted = '[TaskGroups] Delete Task Group Completed',
  UpdateTaskGroup = '[TaskGroups] Update Task Group',
  UpdateTaskGroupCompleted = '[TaskGroups] Update Task Group Completed',
  CreateTaskGroup = '[TaskGroups] Create Task Group',
  CreateTaskGroupCompleted = '[TaskGroups] Create Task Group Completed',
  MoveTask = '[TaskGroups] Move Task',
  MoveTaskCompleted = '[TaskGroups] Move Task Completed',
  ClearAllData = '[TaskGroups] Clear All Data',
  FetchError = '[TaskGroups] Fetch Error',
}

export class LoadSiteDropdownData implements Action {
  readonly type = TaskGroupsActionTypes.LoadSiteDropdownData;
}

export class SiteDropdownDataLoaded implements Action {
  readonly type = TaskGroupsActionTypes.SiteDropdownDataLoaded;

  constructor(public payload: GetManyResponseInterface<SiteDataInterface>) {}
}

export class LoadTaskGroupsAndRemainingTasksData implements Action {
  readonly type = TaskGroupsActionTypes.LoadTaskGroupsAndRemainingTasksData;
  constructor(public siteId: number) {}
}

export class TaskGroupsAndRemainingTasksDataLoaded implements Action {
  readonly type = TaskGroupsActionTypes.TaskGroupsAndRemainingTasksDataLoaded;
  constructor(public payload: TaskGroupsAndRemainingTasksDataResponseInterface) {}
}

export class DeleteTaskGroup implements Action {
  readonly type = TaskGroupsActionTypes.DeleteTaskGroup;
  constructor(public id: number) {}
}

export class DeleteTaskGroupCompleted implements Action {
  readonly type = TaskGroupsActionTypes.DeleteTaskGroupCompleted;
  constructor(public payload: DeleteTaskGroupResponseInterface, public id: number) {}
}

export class UpdateTaskGroup implements Action {
  readonly type = TaskGroupsActionTypes.UpdateTaskGroup;
  constructor(public id: number, public name: string) {}
}

export class UpdateTaskGroupCompleted implements Action {
  readonly type = TaskGroupsActionTypes.UpdateTaskGroupCompleted;
  constructor(public payload: UpdateTaskGroupResponseInterface) {}
}

export class CreateTaskGroup implements Action {
  readonly type = TaskGroupsActionTypes.CreateTaskGroup;
  constructor(public siteId: number, public name: string) {}
}

export class CreateTaskGroupCompleted implements Action {
  readonly type = TaskGroupsActionTypes.CreateTaskGroupCompleted;
  constructor(public payload: CreateTaskGroupResponseInterface) {}
}

export class MoveTask implements Action {
  readonly type = TaskGroupsActionTypes.MoveTask;
  constructor(
    public siteId: number,
    public taskGroupId: number,
    public activityId: number,
    public name: string,
    public previousContainerData: TaskDataInterface[],
    public currentContainerData: TaskDataInterface[],
    public previousIndex: number,
    public currentIndex: number,
    public equipmentId: number,
  ) {}
}

export class MoveTaskCompleted implements Action {
  readonly type = TaskGroupsActionTypes.MoveTaskCompleted;
  constructor(
    public payload: MoveTaskResponseInterface,
    public previousContainerData: TaskDataInterface[],
    public currentContainerData: TaskDataInterface[],
    public previousIndex: number,
    public currentIndex: number,
  ) {}
}

export class ClearAllData implements Action {
  readonly type = TaskGroupsActionTypes.ClearAllData;
}

export class FetchError implements Action {
  readonly type = TaskGroupsActionTypes.FetchError;
  constructor(public payload: HttpErrorResponse) {}
}

export type TaskGroupsActions =
  | LoadSiteDropdownData
  | SiteDropdownDataLoaded
  | LoadTaskGroupsAndRemainingTasksData
  | TaskGroupsAndRemainingTasksDataLoaded
  | DeleteTaskGroup
  | DeleteTaskGroupCompleted
  | UpdateTaskGroup
  | UpdateTaskGroupCompleted
  | CreateTaskGroup
  | CreateTaskGroupCompleted
  | MoveTask
  | MoveTaskCompleted
  | ClearAllData
  | FetchError;
