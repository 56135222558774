import { Inject, Injectable } from '@angular/core';
import { FilterServiceInterface, LineStationCRUDResponseInterface } from './service.class';
import { HttpClient, HttpParams } from '@angular/common/http';
import { LineStationCRUDInterface } from '../../component/filter/filter.class';

@Injectable({
  providedIn: 'root',
})
export class LineStationService implements FilterServiceInterface {
  constructor(public http: HttpClient, @Inject('API_BASE_URL') private baseUrl: string) {}

  getData(options?: HttpParams): Promise<LineStationCRUDInterface[]> {
    return new Promise((resolve, reject): void => {
      this.http
        .get(`${this.baseUrl}/line-stations?join=line||title,siteId`, { params: options })
        .subscribe((response: LineStationCRUDResponseInterface): void => {
          if (response.hasOwnProperty('data')) {
            resolve(response.data);
          } else {
            reject();
          }
        });
    });
  }
}
