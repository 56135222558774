import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, switchMap } from 'rxjs/operators';
import { of } from 'rxjs';
import { FilterUserActionTypes, FilterUserActions, FilterUsersLoaded, StartLoadFilterUsers } from './user.actions';
import { UserService } from '../../../shared/service/filter/user.service';
import { HelperService } from '../../../shared/service/helper.service';
import { generateHttpOption } from '../../../shared/helper/app-helper';

@Injectable()
export class FilterUserEffects {
  constructor(private actions$: Actions<FilterUserActions>, public service: UserService) {}

  loadFilterUsers$ = createEffect(() =>
    this.actions$.pipe(
      ofType(FilterUserActionTypes.StartLoadFilterUsers),
      switchMap((objectData: StartLoadFilterUsers) => {
        return this.service.getUsers(HelperService.cloneDeep(generateHttpOption(objectData.options))).pipe(
          switchMap((response) => {
            return of(new FilterUsersLoaded(response.data, response.total, objectData?.isFetchMoreRequest));
          }),
          catchError(() => {
            return of(new FilterUsersLoaded([]));
          }),
        );
      }),
    ),
  );
}
