import { Moment } from 'moment/moment';
import { CheckInSourceTypeEnum } from '../../home/cico/cico.model';
import { EventModel, EventStore, ResourceModel, ResourceStore } from '@bryntum/schedulerpro';
import * as _ from 'lodash';
import { DurationUnit } from '../../line-availability/line-availability.model';

export interface CheckInTimelineGanttTreeEventInterface extends ICheckInTimeline {
  name: string;
  resourceId: string;
}

export interface ICheckInTimeline {
  id: number;
  startDate: string;
  endDate: string;
  startedAt: string;
  endedAt: string;
  originalStartedAt: string;
  originalEndedAt: string;
  sourceId: number;
  sourceName: string;
  destinationName: string;
  stationLineName?: string;
  lineId: number;
  barTitle?: string;
  eventColor?: string;
  style?: string;
  name: string;
  avatarPath?: string;
  avatar?: string;
  iconClass?: string;
}

export class BaseEventModel extends EventModel {
  static override get defaults() {
    return {
      durationUnit: DurationUnit.HOUR,
    };
  }
}

export class GanttCheckInTimelineItem extends BaseEventModel {
  constructor(data: object) {
    const name = _.get(data, 'barTitle', null);
    _.set(data, 'sourceId', _.get(data, 'sourceId', null));
    _.set(data, 'sourceName', _.get(data, 'sourceName', null));
    _.set(data, 'name', name);
    super(data);
  }

  static override get fields() {
    return [
      { name: 'id', dataSource: 'id' },
      { name: 'resourceId', dataSource: 'sourceId' },
      { name: 'destinationName', dataSource: 'destinationName' },
    ];
  }
}

export class GanttCheckInTimelineItemStore extends EventStore {
  static get defaultConfig() {
    return {
      modelClass: GanttCheckInTimelineItem,
    };
  }
}

export class SourceCheckInTimelineResourceEvent extends ResourceModel {
  static override get fields() {
    return [
      { name: 'id', dataSource: 'id' },
      { name: 'sourceName', dataSource: 'sourceName' },
      { name: 'avatar', dataSource: 'avatar' },
    ];
  }
}

export class SourceCheckInTimelineResourceEventStore extends ResourceStore {
  static get defaultConfig() {
    return {
      modelClass: SourceCheckInTimelineResourceEvent,
    };
  }
}

export interface CheckInTimelineEventTooltipDataInterface {
  eventRecord: GanttCheckInTimelineItem;
  tip: {
    tools: {
      [key: number]: {
        hidden: boolean;
      };
    };
  };
}

export interface CheckInTimelineGanttTreeResourceAsObjectInterface {
  id: string;
  name: string;
  sourceName: string;
  expanded: boolean;
  cls?: string;
  iconCls?: string;
}

export interface CheckInTimelineGanttTreeResourceDepartmentAsObjectInterface {
  [sourceId: string]: CheckInTimelineGanttTreeResourceAsObjectInterface;
}

export interface IDateOfFilterRequestByModeParameters {
  startDate: Moment;
  endDate: Moment;
  timePeriod: string;
}

export interface IFilterRequestDate {
  startDate: string;
  endDate: string;
}

export interface IFilterRequestParameter {
  sourceTypeId: CheckInSourceTypeEnum;
  startDate: string;
  endDate: string;
  siteId?: number;
  lineIds?: number[];
  sourceObjectIds?: number[];
  timePeriod?: string;
}

export interface IPresetHeader {
  unit: string;
  align: string;
  dateFormat: string;
  increment?: number;
}

export enum EChartMode {
  SHOW_ALL_LINES = 0,
  HIGHLIGHT_FILTERED_LINES = 1,
  SHOW_ONLY_FILTERED_LINES = 2,
}

export enum ECheckInSourceType {
  LABOR = 'labor',
  ASSET = 'asset',
}
