<progress-bar-metric
  [actualValue]="phaseDurationMetric.phaseTimer"
  [progressBarTitle]="phaseDurationMetric.phaseDurationText"
  [progressBarRateCapped]="phaseDurationMetric.phaseDurationRateCapped"
  [progressBarRate]="phaseDurationMetric.phaseDurationRate"
  [actualValueClass]="phaseDurationMetric.phaseDurationTextClass"
  [actualValueUnit]="phaseDurationMetric.unit"
  [expectedValue]="phaseDurationMetric.roundedTarget"
  [expectedValueUnit]="phaseDurationMetric.unit"
  [progressBarClass]="phaseDurationMetric.progressBarClass"
  [isPhaseMetric]="phaseDataLoaded"
  [phaseText]="phaseDurationMetric.phaseText"
  [durationType]="phaseDurationType"
  [dashboardMode]="mode"
>
</progress-bar-metric>
