import { IEmailAttachment, INotificationLogsState } from './notification-logs.model';
import * as Actions from './notification-logs.actions';
import { ELoadStatus } from '../../../../constants.model';
import { get } from 'lodash';

export const notificationLogsInitialState: INotificationLogsState = {
  getNotificationLogsStatus: ELoadStatus.Initial,
  previewLoadStatus: ELoadStatus.Initial,
  notificationLogsData: { data: [], total: 0 },
  previewData: { title: null, html: null },
};

export function notificationLogsReducer(
  state = notificationLogsInitialState,
  action: Actions.NotificationLogsActions,
): INotificationLogsState {
  switch (action.type) {
    case Actions.NotificationLogsActionTypes.GetNotificationLogsData:
      return {
        ...state,
        getNotificationLogsStatus: ELoadStatus.Loading,
      };
    case Actions.NotificationLogsActionTypes.GetNotificationLogsDataCompleted:
      return {
        ...state,
        getNotificationLogsStatus: ELoadStatus.Success,
        notificationLogsData: action.payload,
      };
    case Actions.NotificationLogsActionTypes.GetNotificationLogsPreview:
      return {
        ...state,
        previewLoadStatus: ELoadStatus.Loading,
        previewData: notificationLogsInitialState.previewData,
      };
    case Actions.NotificationLogsActionTypes.GetNotificationLogsPreviewCompleted:
      return {
        ...state,
        previewLoadStatus: ELoadStatus.Success,
        previewData: {
          url: get(action, 'payload.url'),
          title: getPreviewTitle(action),
          html: ('attachments' in action.payload ? action.payload.attachments ?? [] : []).reduce(
            (substitutedString: string, attachment: IEmailAttachment) =>
              substitutedString.replace(
                new RegExp(`cid:${attachment.fileName}`, 'g'),
                `data:image/png;base64,${attachment.base64}`,
              ),
            'body' in action.payload ? action.payload.body : action.payload.text,
          ),
        },
      };
    default:
      return state;
  }
}

function getPreviewTitle(action: Actions.GetNotificationLogsPreviewCompleted): string {
  let title: string | null = null;

  if ('title' in action.payload) {
    title = action.payload.title;
  } else if ('subject' in action.payload) {
    title = action.payload.subject;
  }

  return title;
}
