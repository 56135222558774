export enum ECardAssignmentObjectTypeEnum {
  // USER = 1,
  // EQUIPMENT = 2,
  JOB = 3,
  WORK_ORDER = 4,
}

export interface IRfidCardAssignmentListObject {
  objectType: ECardAssignmentObjectTypeEnum,
  siteId: number;
  macAddress: string;
}
