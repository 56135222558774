<div class="card widget-statstic-card order-card">
  <div class="card-block-hid">
    <div class="card-block">
      <div class="sub-card-content">
        <div class="row no-gutters metrics">
          <div class="overflow-hidden col element-behind">
            <div class="w-100 h-100 p-025 work-order-duration-metric">
              <scw-work-order-duration-metric-card> </scw-work-order-duration-metric-card>
            </div>
          </div>
          <div class="overflow-hidden col element-behind">
            <div class="w-100 h-100 p-025">
              <scw-station-home-counts-metric-card
                [goodCountUnit]="productUnit ? productUnit.symbol : ''"
                [scrapCountUnit]="productUnit ? productUnit.symbol : ''"
              ></scw-station-home-counts-metric-card>
            </div>
          </div>
          <div class="overflow-hidden col element-behind" *ngIf="isVirtualSensorsDefined">
            <div class="w-100 h-100 p-025">
              <scw-semi-manual-count-button-card
                [stationID]="stationID"
                [hasMissingWorkOrderActivity]="!(hasOngoingWorkOrder && hasSelectedActivity)"
                (hasPendingCounts)="onSemiManualCountButtonComponentPendingCountsChange($event)"
              >
              </scw-semi-manual-count-button-card>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
