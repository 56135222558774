import { ITaskTreeNode } from '../../../../store/equipment-task/equipment-task.model';

export enum ENodeType {
  Line = 'line',
  Activity = 'activity',
  Folder = 'folder',
  Equipment = 'equipment',
  Task = 'task',
  TaskGroup = 'taskGroup',
}

export enum ETaskListMode {
  TASK_BASED = 'task_based',
  EQUIPMENT_BASED = 'equipment_based',
}

export interface INodeGroup {
  name: string;
  nodes: ITaskTreeNode[];
}
