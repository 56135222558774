import { Inject, Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { forkJoin, Observable } from 'rxjs';
import { HelperService } from '../helper.service';
import {
  BaseOneResponseInterface,
  GetManyResponseInterface,
} from '../../model/interface/crud-response-interface.model';
import {
  IActivityAndLine,
  ISimplifiedActivityPhaseAndTask,
  IStationActivityHistoryCrudResponse,
  IStationDropdownResponse,
  IUpdateOngoingStationActivityHistoryRequest,
  NoActivityStation,
} from '../../../store/station-activity-history/station-activity-history.model';
import { map } from 'rxjs/operators';
import { ActivitiesInterface } from '../../model/interface/activities.model';
import { LineCRUDInterface } from '../../component/filter/filter.class';

@Injectable({
  providedIn: 'root',
})
export class StationActivityHistoryService {
  private readonly routes = {
    stationActivityHistoryCrud: '/station-activity-history',
    stationActivityHistoryCrudOngoingUpdate: '/station-activity-history/ongoing/:stationId',
    stationActivityHistoryChangeActivity: '/station-activity-history/change-activity/:stationId',
    stationsCrud: '/line-stations',
    stationCrud: '/line-stations/:id',
    lineCrud: '/lines/:id',
    activityCrud: '/activities/:id',
  };

  constructor(
    public http: HttpClient,
    @Inject('API_BASE_URL') private readonly api: string,
    public helperService: HelperService,
  ) {}

  public loadStationDropdownItems(params: HttpParams): Observable<GetManyResponseInterface<IStationDropdownResponse>> {
    return this.http.get<GetManyResponseInterface<IStationDropdownResponse>>(`${this.api}${this.routes.stationsCrud}`, {
      params,
    });
  }

  public getManyStationActivityHistories(
    params: HttpParams,
  ): Observable<GetManyResponseInterface<IStationActivityHistoryCrudResponse>> {
    return this.http.get<GetManyResponseInterface<IStationActivityHistoryCrudResponse>>(
      `${this.api}${this.routes.stationActivityHistoryCrud}`,
      { params },
    );
  }

  public updateOngoingStationActivityHistory(
    stationId: number,
    requestData: IUpdateOngoingStationActivityHistoryRequest,
  ): Observable<BaseOneResponseInterface<IStationActivityHistoryCrudResponse>> {
    return this.http.patch<BaseOneResponseInterface<IStationActivityHistoryCrudResponse>>(
      `${this.api}${this.routes.stationActivityHistoryCrudOngoingUpdate}`.replace(':stationId', String(stationId)),
      requestData,
    );
  }

  public stationActivityHistoryActivityChange(
    stationId: number,
    requestData: IUpdateOngoingStationActivityHistoryRequest,
  ): Observable<BaseOneResponseInterface<ISimplifiedActivityPhaseAndTask>> {
    return this.http.patch<BaseOneResponseInterface<ISimplifiedActivityPhaseAndTask>>(
      `${this.api}${this.routes.stationActivityHistoryChangeActivity}`.replace(':stationId', String(stationId)),
      requestData,
    );
  }

  public getActivityAndLine(lineId: number, activityId?: number): Observable<IActivityAndLine> {
    const observables = {
      line: this.http.get<BaseOneResponseInterface<LineCRUDInterface>>(
        `${this.api}${this.routes.lineCrud.replace(':id', String(lineId))}`,
      ),
      ...(activityId
        ? {
            activity: this.http.get<BaseOneResponseInterface<ActivitiesInterface>>(
              `${this.api}${this.routes.activityCrud.replace(':id', String(activityId))}`,
            ),
          }
        : {}),
    };

    return forkJoin(observables).pipe(
      map(
        (resolvedObservables: {
          line: BaseOneResponseInterface<LineCRUDInterface>;
          activity?: BaseOneResponseInterface<ActivitiesInterface>;
        }) => {
          return {
            line: resolvedObservables.line.data,
            ...(activityId ? { activity: resolvedObservables.activity.data } : {}),
          };
        },
      ),
    );
  }

  public getOneStation(
    stationId: number,
    params: HttpParams,
  ): Observable<BaseOneResponseInterface<NoActivityStation>> {
    return this.http.get<BaseOneResponseInterface<NoActivityStation>>(
      `${this.api}${this.routes.stationCrud.replace(':id', String(stationId))}`,
      {
        params,
      },
    );
  }
}
