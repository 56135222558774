import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LaborAssetViewComponent } from './labor-asset-view.component';
import { LaborAssetViewRoutingModule } from './labor-asset-view-routing.module';
import { SharedModule } from '../../../shared/shared.module';
import { LaborAssetViewKpiCardComponent } from './labor-asset-view-kpi-card/labor-asset-view-kpi-card.component';
import { LaborAssetViewListViewComponent } from './labor-asset-view-list-view/labor-asset-view-list-view.component';
import { LaborAssetViewCardViewComponent } from './labor-asset-view-card-view/labor-asset-view-card-view.component';

@NgModule({
  declarations: [
    LaborAssetViewComponent,
    LaborAssetViewKpiCardComponent,
    LaborAssetViewListViewComponent,
    LaborAssetViewCardViewComponent,
  ],
  imports: [CommonModule, LaborAssetViewRoutingModule, SharedModule],
  exports: [LaborAssetViewKpiCardComponent, LaborAssetViewListViewComponent, LaborAssetViewCardViewComponent],
})
export class LaborAssetViewModule {}
