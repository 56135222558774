<div class="star-rating">
  <ng-template ngFor let-star [ngForOf]="starCollection">
    <i
      class="star fa-star"
      [ngClass]="{
        far:
          (hoverValue === null && star.rank > inputModel) ||
          (hoverValue !== null && star.rank > hoverValue) ||
          (!inputModel && hoverValue === null),
        fas: (hoverValue === null && star.rank <= inputModel) || (hoverValue !== null && star.rank <= hoverValue)
      }"
      (click)="onStarClick(star)"
      (mouseenter)="onStarHover(star)"
      (mouseleave)="onStarHoverLeave()"
    ></i>
  </ng-template>
</div>
