import * as Actions from './root-cause-analysis-elastic.actions';
import {
  RootCauseAnalysisElasticStateInterface,
} from './root-cause-analysis-elastic.model';

export const rootCauseAnalysisInitialState: RootCauseAnalysisElasticStateInterface = {
  isRootCauseAnalysisElasticDataLoading: false,
  isRootCauseAnalysisElasticDataLoaded: false,
  isChartActivityDataLoading: false,
  isChartActivityDataLoaded: false,
  isChartEquipmentDataLoading: false,
  isChartEquipmentDataLoaded: false,
  isChartTaskDataLoading: false,
  isChartTaskDataLoaded: false,
  showManyDataWarning: false,
  rootCauseAnalysisElasticData: [],
  chartActivityData: [],
  chartEquipmentData: [],
  chartTaskData: [],
  elasticTableParameters: null,
  isStackChartDataLoading: false,
  isStackChartDataLoaded: false,
  stackChartData: [],
  isRootCauseAnalysisEquipmentChartVisible: false,
  isRootCauseAnalysisStackChartVisible: false,
  comparisonData: [],
  isComparisonDataLoading: false,
  isComparisonDataLoaded: false,
  comparisonStackData: [],
  isComparisonStackDataLoading: false,
  isComparisonStackDataLoaded: false,
  elasticLineData: [],
  isElasticLineDataLoading: false,
  isElasticLineDataLoaded: false,
  selectedActivityName: undefined,
  selectedEquipmentName: undefined,
  selectedTaskName: undefined,
};

export function rootCauseAnalysisElasticReducer(
  state: RootCauseAnalysisElasticStateInterface = rootCauseAnalysisInitialState,
  action: Actions.RootCauseAnalysisElasticActions,
): RootCauseAnalysisElasticStateInterface {
  switch (action.type) {
    case Actions.START_ROOT_CAUSE_ANALYSIS_ELASTIC_DATA_LOADING:
      return {
        ...state,
        showManyDataWarning: false,
        rootCauseAnalysisElasticData: [],
        isRootCauseAnalysisElasticDataLoading: true,
        isRootCauseAnalysisElasticDataLoaded: false,
      };

    case Actions.ROOT_CAUSE_ANALYSIS_ELASTIC_DATA_LOADED:
      return {
        ...state,
        rootCauseAnalysisElasticData: action.data,
        elasticTableParameters: action.tableResponseParameter,
        showManyDataWarning: false,
        isRootCauseAnalysisElasticDataLoaded: true,
        isRootCauseAnalysisElasticDataLoading: false,
      };

    case Actions.ROOT_CAUSE_ANALYSIS_STACK_CHART_DATA_LOADING:
      return {
        ...state,
        showManyDataWarning: false,
        stackChartData: [],
        isStackChartDataLoading: true,
        isStackChartDataLoaded: false,
      };

    case Actions.ROOT_CAUSE_ANALYSIS_STACK_CHART_DATA_LOADED:
      return {
        ...state,
        stackChartData: action.data,
        showManyDataWarning: false,
        isStackChartDataLoading: false,
        isStackChartDataLoaded: true,
      };

    case Actions.ROOT_CAUSE_ANALYSIS_ELASTIC_CHART_ACTIVITY_DATA_LOADING:
      return {
        ...state,
        isChartActivityDataLoading: true,
        isChartActivityDataLoaded: false,
      };

    case Actions.ROOT_CAUSE_ANALYSIS_ELASTIC_CHART_ACTIVITY_DATA_LOADED:
      return {
        ...state,
        chartActivityData: action.data,
        isChartActivityDataLoaded: true,
        isChartActivityDataLoading: false,
      };

    case Actions.ROOT_CAUSE_ANALYSIS_ELASTIC_CHART_EQUIPMENT_DATA_LOADING:
      return {
        ...state,
        isChartEquipmentDataLoading: true,
        isChartEquipmentDataLoaded: false,
      };

    case Actions.ROOT_CAUSE_ANALYSIS_ELASTIC_CHART_EQUIPMENT_DATA_LOADED:
      return {
        ...state,
        chartEquipmentData: action.data,
        isChartEquipmentDataLoaded: true,
        isChartEquipmentDataLoading: false,
      };

    case Actions.ROOT_CAUSE_ANALYSIS_ELASTIC_CHART_TASK_DATA_LOADING:
      return {
        ...state,
        isChartTaskDataLoading: true,
        isChartTaskDataLoaded: false,
      };

    case Actions.ROOT_CAUSE_ANALYSIS_ELASTIC_CHART_TASK_DATA_LOADED:
      return {
        ...state,
        chartTaskData: action.data,
        isChartTaskDataLoaded: true,
        isChartTaskDataLoading: false,
      };

    case Actions.ROOT_CAUSE_ANALYSIS_ELASTIC_MANY_DATA_WARNING_SHOW:
      return {
        ...state,
        showManyDataWarning: true,
        rootCauseAnalysisElasticData: [],
        isRootCauseAnalysisElasticDataLoaded: true,
        isRootCauseAnalysisElasticDataLoading: false,
      };

    case Actions.ROOT_CAUSE_ANALYSIS_ELASTIC_FETCH_ERROR:
      return {
        ...state,
        showManyDataWarning: false,
        isRootCauseAnalysisElasticDataLoaded: true,
        isRootCauseAnalysisElasticDataLoading: false,
        rootCauseAnalysisElasticData: [],
      };

    case Actions.ROOT_CAUSE_ANALYSIS_ELASTIC_CHART_ACTIVITY_FETCH_ERROR:
      return {
        ...state,
        isChartActivityDataLoaded: true,
        isChartActivityDataLoading: false,
        chartActivityData: [],
      };

    case Actions.ROOT_CAUSE_ANALYSIS_ELASTIC_CHART_EQUIPMENT_FETCH_ERROR:
      return {
        ...state,
        isChartEquipmentDataLoaded: true,
        isChartEquipmentDataLoading: false,
        chartEquipmentData: [],
      };

    case Actions.ROOT_CAUSE_ANALYSIS_ELASTIC_CHART_TASK_FETCH_ERROR:
      return {
        ...state,
        isChartTaskDataLoaded: true,
        isChartTaskDataLoading: false,
        chartTaskData: [],
      };

    case Actions.ROOT_CAUSE_ANALYSIS_ELASTIC_COMPARISON_MODE_CHART_VISIBILITY:
      return {
        ...state,
        isRootCauseAnalysisEquipmentChartVisible: action.isRootCauseAnalysisEquipmentChartVisible,
        isRootCauseAnalysisStackChartVisible: action.isRootCauseAnalysisStackChartVisible,
        isRootCauseAnalysisElasticDataLoading: false,
        isRootCauseAnalysisElasticDataLoaded: false,
      };

    case Actions.ROOT_CAUSE_ANALYSIS_ELASTIC_COMPARISON_MODE_DATA_LOADING:
      return {
        ...state,
        isComparisonDataLoading: true,
        isComparisonDataLoaded: false,
      };

    case Actions.ROOT_CAUSE_ANALYSIS_ELASTIC_COMPARISON_MODE_DATA_LOADED:
      return {
        ...state,
        comparisonData: action.data,
        isComparisonDataLoading: false,
        isComparisonDataLoaded: true,
      };

    case Actions.ROOT_CAUSE_ANALYSIS_ELASTIC_COMPARISON_MODE_STACK_DATA_LOADING:
      return {
        ...state,
        comparisonStackData: [],
        isComparisonStackDataLoading: true,
        isComparisonStackDataLoaded: false,
      };

    case Actions.ROOT_CAUSE_ANALYSIS_ELASTIC_COMPARISON_MODE_STACK_DATA_LOADED:
      return {
        ...state,
        comparisonStackData: action.data,
        isComparisonStackDataLoading: false,
        isComparisonStackDataLoaded: true,
      };

    case Actions.ROOT_CAUSE_ANALYSIS_ELASTIC_LINE_DATA_LOADING:
      return {
        ...state,
        elasticLineData: [],
        isElasticLineDataLoading: true,
        isElasticLineDataLoaded: false,
      };

    case Actions.ROOT_CAUSE_ANALYSIS_ELASTIC_LINE_DATA_LOADED:
      return {
        ...state,
        elasticLineData: action.data,
        isElasticLineDataLoading: false,
        isElasticLineDataLoaded: true,
      };

    case Actions.ROOT_CAUSE_ANALYSIS_ELASTIC_SET_SELECTED_CHART_DATA:
      return {
        ...state,
        selectedActivityName: action.activityName,
        selectedEquipmentName: action.equipmentName,
        selectedTaskName: action.taskName,
      };

    default:
      return state;
  }
}
