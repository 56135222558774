import { Inject, Injectable } from '@angular/core';
import { forkJoin, Observable, Subject } from 'rxjs';
import { HttpClient, HttpParams } from '@angular/common/http';
import {
  CreateExcelInterface,
  CreateExcelSheetInterface,
  ExcelHelperService,
  ExcelSheetTypeEnum,
} from '../../../shared/service/excel/excel-helper.service';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { map, take, takeUntil } from 'rxjs/operators';
import { GetManyResponseInterface } from '../../../shared/model/interface/crud-response-interface.model';
import {
  IComponentConfiguration,
  UserConfigurationStateInterface,
} from '../../user-configuration/user-configuration.model';
import { PageFiltersAndSettingsService } from '../../../shared/service/page-filters-and-settings/page-filters-and-settings.service';
import * as moment from 'moment-timezone';
import * as ObjectActions from '../../work-order-schedule/work-order-schedule.actions';
import * as AppActions from '../../app/actions';
import { OeeAppState } from '../../oee.reducer';
import { IFilterSettings, ILinesReport, IFilterData, ILine, ISite } from './line-view-enhanced.model';
import { CustomDependFilterKeysEnum } from '../../../shared/service/work-order-schedule/work-order-schedule.model';

export const phaseTargetColumnNames = {
  preRunPercentage: 'preRunVersusTargetPercentage',
  runPercentage: 'runVersusTargetPercentage',
  postRunPercentage: 'postRunVersusTargetPercentage',
  preRunNumerical: 'preRunVersusTargetNumerical',
  runNumerical: 'runVersusTargetNumerical',
  postRunNumerical: 'postRunVersusTargetNumerical',
};

@Injectable({
  providedIn: 'root',
})
export class LineViewEnhancedService {
  private readonly LINE_VIEW = {
    GET: {
      SITE_URL: `${this.baseUrl}/sites`,
      LINE_URL: `${this.baseUrl}/lines`,
      LINE_REPORT_URL: `${this.baseUrl}/lines/reportsExtend`,
    },
  };
  private readonly defaultTable: string[] = [
    'siteName',
    'departmentName',
    'lineName',
    'lineState',
    'activityName',
    'activityStartTime',
    'activityDuration',
    'workOrderName',
    'workOrderPhase',
    'workOrderPhaseDuration',
    'workOrderGoodCount',
    'averageSpeed',
    'workOrderDuration',
    'runTimeAverageSpeed',
    'scrapQuantity',
    'activitySpeed',
    'workOrderSpeed',
    'workOrderScrapCount',
    'oeeCalculationStatus',
  ];
  private readonly tableOptions: string[] = [
    ...this.defaultTable,
    ...Object.values(phaseTargetColumnNames),
    'workOrderPhaseStart',
    'operatorName',
    'taskIssueName',
    'todayOEE',
    'availability',
    'performance',
    'quality',
    'productId',
    'productDescription',
    'PreRunDuration',
    'RunDuration',
    'PostRunDuration',
    'nameOfCheckInUsers',
    'numberOfCheckInUsers',
    'nameOfCheckInAssets',
    'numberOfCheckInAssets',
    'nameOfCheckInMaintenanceUsers',
  ];
  private readonly defaultWidgets: string[] = ['runningLines', 'downLines', 'idleLines', 'todayOEE'];
  private readonly widgetOptions: string[] = [...this.defaultWidgets, 'totalNumberOfUsers', 'totalNumberOfAssets'];
  private timezone: string = 'utc';
  private dateFormat: string;
  private timeFormat: string;
  private readonly destroySubject: Subject<boolean> = new Subject<boolean>();

  constructor(
    public http: HttpClient,
    @Inject('API_BASE_URL') private readonly baseUrl: string,
    private readonly excelHelper: ExcelHelperService,
    private readonly store: Store<OeeAppState>,
    private readonly translate: TranslateService,
  ) {
    this.store
      .select('user')
      .pipe(takeUntil(this.destroySubject))
      .subscribe((state) => {
        if (state.isUserLoaded) {
          this.timezone = state.timezone;
          this.dateFormat = state.dateFormat;
          this.timeFormat = state.timeFormat;
          this.destroySubject.next(true);
          this.destroySubject.complete();
        }
      });
  }

  public getFiltersAndSettings(): Observable<{ data: IFilterData }> {
    const baseHttpParams: HttpParams = new HttpParams().append('limit', '1000');
    const observables: Observable<GetManyResponseInterface<ISite> | GetManyResponseInterface<ILine>>[] = [
      this.http.get<GetManyResponseInterface<ISite>>(this.LINE_VIEW.GET.SITE_URL, {
        params: baseHttpParams.append('s', JSON.stringify({ status: 1 })),
      }),
      this.http.get<GetManyResponseInterface<ILine>>(this.LINE_VIEW.GET.LINE_URL, {
        params: baseHttpParams.append('join', 'lineTypeName').append('s', JSON.stringify({ status: 1 })),
      }),
    ];

    return forkJoin(observables).pipe(
      map((array: [GetManyResponseInterface<ISite>, GetManyResponseInterface<ILine>]) => {
        let configuration: IComponentConfiguration[] | undefined = [];
        this.store
          .select('userConfigurationStore')
          .pipe(take(1))
          .subscribe((state: UserConfigurationStateInterface) => {
            configuration = state.userConfigurationData.LineViewEnhancedComponent;
          });
        const [sitesResponse, linesResponse]: [GetManyResponseInterface<ISite>, GetManyResponseInterface<ILine>] =
          array;

        let filterObject: IFilterSettings = {
          table: this.defaultTable,
          widgets: this.defaultWidgets,
        };

        if (configuration !== undefined) {
          const filters: IComponentConfiguration = configuration.find(
            (conf: IComponentConfiguration) => conf.name === 'filters',
          );

          const sitesConfiguration: IComponentConfiguration = filters?.children?.find(
            (conf: IComponentConfiguration) => conf.name === CustomDependFilterKeysEnum.SITE_IDS,
          );

          const linesConfiguration: IComponentConfiguration = filters?.children?.find(
            (conf: IComponentConfiguration) => conf.name === CustomDependFilterKeysEnum.LINE_IDS,
          );

          const activityConfiguration: IComponentConfiguration = filters?.children?.find(
            (conf: IComponentConfiguration) => conf.name === CustomDependFilterKeysEnum.ACTIVITY_IDS,
          );

          const widgetSettings: IComponentConfiguration = configuration.find(
            (conf: IComponentConfiguration) => conf.name === 'widgetSettings',
          );

          const tableColumns: IComponentConfiguration = configuration.find(
            (conf: IComponentConfiguration) => conf.name === 'tableColumns',
          );

          filterObject = Object.assign(filterObject, {
            filterLineId: linesConfiguration === undefined ? null : linesConfiguration.value,
            filterSiteId: sitesConfiguration === undefined ? null : sitesConfiguration.value,
            filterActivityId: activityConfiguration === undefined ? null : activityConfiguration.value,
            table: tableColumns === undefined ? this.defaultTable : tableColumns.value,
            widgets: widgetSettings === undefined ? this.defaultWidgets : widgetSettings.value,
          });
        } else {
          filterObject = Object.assign(filterObject, {
            filterLineId: linesResponse.data.map((line: ILine) => line.id),
            filterSiteId: sitesResponse.data.map((site: ISite) => site.id),
            filterActivityId: [],
            table: this.defaultTable,
            widgets: this.defaultWidgets,
          });
        }

        return {
          data: {
            sites: filterObject.filterSiteId,
            lines: filterObject.filterLineId,
            activities: filterObject.filterActivityId,
            table: PageFiltersAndSettingsService.getObjectConfigurationOfUser(filterObject.table, this.tableOptions),
            widgets: PageFiltersAndSettingsService.getObjectConfigurationOfUser(
              filterObject.widgets,
              this.widgetOptions,
            ),
          },
        };
      }),
    );
  }

  public getLineViewEnhancedData(params: HttpParams): Observable<ILinesReport> {
    return this.http.get<ILinesReport>(this.LINE_VIEW.GET.LINE_REPORT_URL, {
      params,
    });
  }

  public downloadLineViewEnhancedExcel(excelCreateOptions: CreateExcelInterface, mode: string): void {
    const title = this.translate.instant('pageTitles.line_view');
    const fileName: string = `${title} ${moment().tz(this.timezone).format(this.dateFormat)}`;

    const worksheets: CreateExcelSheetInterface[] = [
      {
        sheetTitle: title,
        sheetType: ExcelSheetTypeEnum.TABLE,
        params: excelCreateOptions,
        withData: true,
      },
    ];

    this.excelHelper
      .createExcel(fileName, { name: 'lineViewEnhanced' }, worksheets, this.timezone, this.dateFormat, this.timeFormat, mode === 'csv')
      .then(
        () => {
          this.store.dispatch(new ObjectActions.DownloadExcelCompleted());
          this.store.dispatch(new AppActions.HideLoader());
        },
        () => {
          this.store.dispatch(new ObjectActions.FetchError({}));
          this.store.dispatch(new AppActions.HideLoader());
        },
      );
  }
}
