import { Inject, Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import {catchError, finalize, find, map, switchMap, take, takeUntil} from 'rxjs/operators';
import { forkJoin, from, of, Subject } from 'rxjs';
import * as ObjectActions from './home.actions';
import * as AppActions from '../app/actions';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Store } from '@ngrx/store';
import { OeeAppState } from '../oee.reducer';
import * as LineActions from '../line/actions';
import * as HomeActions from '../home/home.actions';
import { activityStyles } from '../../shared/helper/home-helper';
import {
  GetProductPerformanceResponseInterface,
  GetQuantityFromSensorInterface,
  GetWorkOrderResponseInterface,
  HomeActivityButtonInterface,
  HomeInformationInterface,
  PhaseCommentCrudDataInterface,
  PhaseCommentCrudUpdateResponseInterface,
  PhaseCommentsResponseInterface,
  PhaseCommentStoreDataInterface,
  PhaseCommentStoreUpdateDataInterface,
  ProductSearchDispatchAreas,
  UpdateCurrentActivityResponseInterface,
  UpdateCurrentActivityResponseModel,
  UpdateWorkOrderResponseInterface,
  ILineInfoThatWorkOrder,
  IRemoveWorkOrderRequestBody,
  CurrentProductSpeedInterface,
} from './home.model';
import { HomeActivityTreeChartService } from './home-activity-tree-chart.service';
import {
  ChangeActivityResponseDataInterface,
  ChangeActivityResponseInterface,
  IOngoingActivityTotalDuration,
  SetActivityCardDataActionInterface,
  SetWorkOrderCardsActionInterface,
} from '../line/model';
import { LineService } from '../../shared/service/line/line.service';
import { ShiftSummaryCommentObjectPropertyTypes } from '../../shared/model/enum/shift-summary-comment-object-property-types';
import { User } from '../user/model';
import { HelperService } from '../../shared/service/helper.service';
import * as _ from 'lodash';
import * as SensorStatusesActions from '../sensor-statuses/sensor-statuses.actions';
import { WorkOrderCloseFeedbackResponseInterface } from '../work-order/work-order.model';
import { WorkOrderScheduleService } from '../../shared/service/work-order-schedule/work-order-schedule.service';
import { ProductCRUDResponseInterface } from '../../shared/service/filter/service.class';
import {
  BaseOneResponseInterface,
  BulkResponseDataInterface,
  GetManyResponseInterface,
} from '../../shared/model/interface/crud-response-interface.model';
import { EquipmentListService } from '../../shared/service/equipment-list/equipment-list.service';
import {
  EquipmentListGetManyCRUDDataInterface,
  EquipmentListGetOneCRUDDataInterface,
} from '../../shared/service/equipment-list/equipment-list.model';
import { UserService } from '../../shared/service/user/user.service';
import { UserGetManyCRUDDataInterface } from '../../shared/service/user/user.model';
import { WorkOrderScheduleInterface } from '../work-order-schedule/work-order-schedule.model';
import { ResponseArrayInterface } from '../../shared/model/interface/generic-api-response.model';
import { KPI_METRICS_DECIMAL_COUNT } from '../../../constants';
import { DecimalHelper } from 'src/app/shared/helper/decimal/decimal-helper';
import { EntityTranslatorService } from '../../shared/service/entity-translator/entity-translator.service';
import { ProductCRUDInterface, SiteCRUDInterface } from '../../shared/component/filter/filter.class';
import { environment } from '../../../environments/environment';
import { ProductService } from 'src/app/shared/service/filter/product.service';
import { IProductVersion } from '../settings/products/products.model';
import { IJob } from '../settings/jobs/jobs.model';
import { JobsService } from '../settings/jobs/jobs.service';
import { PhaseDurationsInterface } from '../../view/home/home-metrics/common-phase-duration-metric/common-phase-duration-metric.model';
import { ILayout } from '../settings/home-page-display-settings/home-page-display-settings.model';
import { IActivityHistory } from '../../shared/service/activity-history/activity-history.model';
import { ActivityHistoryService } from '../../shared/service/activity-history/activity-history.service';
import { IGetProductTransitionMatrix } from '../settings/product-transition-matrix/product-transition-matrix.model';
import { ProductTransitionMatrixService } from '../settings/product-transition-matrix/product-transition-matrix.service';
import { HTTPHelperService } from '../../shared/service/http/http.helper.service';
import { SiteService } from '../../shared/service/filter/site.service';
import { IPredictedWorkOrderInfo } from '../../view/home/work-order/work-order.model';

@Injectable()
export class HomeEffects {
  private userLanguage$: string = '';
  private readonly destroySubject: Subject<boolean> = new Subject<boolean>();

  constructor(
    private actions$: Actions<ObjectActions.HomeActions>,
    private http: HttpClient,
    private store: Store<OeeAppState>,
    private homeActivityTreeChartService: HomeActivityTreeChartService,
    private workOrderScheduleService: WorkOrderScheduleService,
    private lineService: LineService,
    private readonly equipmentListService: EquipmentListService,
    private readonly userService: UserService,
    @Inject('API_BASE_URL') private readonly baseUrl: string,
    public helperService: HelperService,
    private readonly decimalHelper: DecimalHelper,
    private readonly productService: ProductService,
    private readonly jobService: JobsService,
    private readonly translatorService: EntityTranslatorService,
    private readonly activityHistoryService: ActivityHistoryService,
    private readonly entityTranslation: EntityTranslatorService,
    private readonly productTransitionMatrixService: ProductTransitionMatrixService,
    private readonly httpHelper: HTTPHelperService,
    private readonly siteService: SiteService,
  ) {}

  private HOME_URLS = {
    GET: {
      GET_HOME_INFORMATION: `${this.baseUrl}/lines/:lineId/get-home-information`,
      GET_QUANTITY_FROM_SENSOR: 'lines/:id/quantities',
      GET_ACTIVITY_TREE_GRAPH: `${this.baseUrl}/lines/:lineId/activity-task-tree`,
      GENERATE_AUTH_KEY_AND_GRANT: '/api/generate-auth-key-and-grant',
      GET_CHECK_IN_LIST: '/api/check-in-list',
      SEARCH_TASK_BY_ACTIVITY_ID: '/api/tasks/all',
      GET_WORK_ORDER: `${this.baseUrl}/work-orders`,
      GET_VERSION_DETAIL: `${this.baseUrl}/product-versions`,
      GET_PRODUCT_PERFORMANCE: '/product-performance',
      PRODUCT_SEARCH: `${this.baseUrl}/products`,
      GET_WORK_ORDER_NUMBERS: `${this.baseUrl}/work-orders`,
      SEARCH_USERS_BY_FILTER: '/api/authorized-users',
    },
    POST: {
      SAVE_WORK_ORDER_FEEDBACK: `${this.baseUrl}/work-order-close-feedbacks`,
      UPDATE_GOOD_AND_SCRAP: `${this.baseUrl}/work-orders/update-good-and-scrap`,
      CHANGE_ACTIVITY: 'activity-histories/change-activity',
      UPDATE_CURRENT_ACTIVITY:
        // eslint-disable-next-line max-len
        'lines/:id/activity?join=currentActivity&join=currentActivity.activitySubType&join=currentTask&join=currentWorkOrder&join=currentWorkOrder.product&join=currentWorkOrder.product.productSpeedTable',
      SPLIT_CURRENT_WORK_ORDER: 'lines/:lineId/split-current-work-order',
    },
    PATCH: {
      UPDATE_WORK_ORDER: `${this.baseUrl}/work-orders/update-work-order-status`,
    },
  };

  loadHomes$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ObjectActions.HomeActionTypes.StartLoadHomeInformation),
      switchMap((objectData: ObjectActions.StartLoadHomeInformation) => {
        this.store.dispatch(new AppActions.ShowLoader());

      let url = this.HOME_URLS.GET.GET_HOME_INFORMATION;
      this.store
        .select('user')
        .pipe(take(1))
        .subscribe((state: User) => {
          if (!_.isNil(state.lineId) || !_.isNil(objectData.lineID)) {
            url = url.replace(':lineId', objectData.lineID?.toString() ?? state.lineId.toString());
          }
        });
      return this.http.get<any>(url, {}).pipe(
        switchMap((resDataReadOnly: HomeInformationInterface) => {
            const resData: HomeInformationInterface = HelperService.cloneDeep(resDataReadOnly);
            const siteDecimalScaleLimit: number = resData.data.line.siteDecimalScaleLimit;

            resData.data.line = {
              ...resData.data.line,
              activityCard: {
                ...resData.data.line.activityCard,
                activityAverageSpeed: this.decimalHelper.toFixedValue(
                  resData.data.line.activityCard.activityAverageSpeed,
                  siteDecimalScaleLimit,
                ),
                goodCount: this.decimalHelper.toFixedValue(
                  resData.data.line.activityCard.goodCount,
                  siteDecimalScaleLimit,
                ),
                initialCount: this.decimalHelper.toFixedValue(
                  resData.data.line.activityCard.initialCount,
                  siteDecimalScaleLimit,
                ),
                scrapCount: this.decimalHelper.toFixedValue(
                  resData.data.line.activityCard.scrapCount,
                  siteDecimalScaleLimit,
                ),
                workOrderDuration: this.decimalHelper.toFixedValue(resData.data.line.activityCard.workOrderDuration
              ),
              activityCardLayout: resData.data.line.workOrderCard.activityCardLayout,
            },
            workOrderCard: {
              ...resData.data.line.workOrderCard,
                workOrderDuration: this.decimalHelper.toFixedValue(resData.data.line.workOrderCard.workOrderDuration),
                workOrderGoodCount: this.decimalHelper.toFixedValue(
                  resData.data.line.workOrderCard.workOrderGoodCount,
                  siteDecimalScaleLimit,
                ),
                workOrderQuantityOrdered: resData.data.line.workOrderCard.workOrderQuantityOrdered,
                expectedSpeed: this.decimalHelper.toFixedValue(
                  resData.data.line.workOrderCard.expectedSpeed,
                  siteDecimalScaleLimit,
                ),
                averageSpeed: this.decimalHelper.toFixedValue(
                  resData.data.line.workOrderCard.averageSpeed,
                  siteDecimalScaleLimit,
                ),
                goodSpeed: this.decimalHelper.toFixedValue(
                  resData.data.line.workOrderCard.goodSpeed,
                  siteDecimalScaleLimit,
                ),
                woSpeed: this.decimalHelper.toFixedValue(
                  resData.data.line.workOrderCard.woSpeed,
                  siteDecimalScaleLimit,
                ),
                quantityWithCommentCount: resData.data.line.workOrderCard.quantityWithCommentCount,
              workOrderLayout: resData.data.line.workOrderCard.workOrderLayout,
            },
            distributionCountData: {
              rowGoodCount: resData.data.line.activityCard.goodCount,
              rowScrapCount: resData.data.line.activityCard.scrapCount,
            },
            auditLogUuid: resData.data.line.activityCard.auditLogUuid,
          };
          return of(
            new ObjectActions.HomeInformationLoaded(resData, objectData.page, objectData.lineID),
            new ObjectActions.UpdateIsAutomatedPhaseCommentsActive(resData.data.line.isAutomatedPhaseCommentsActive),
            new HomeActions.UpdatePhaseCommentsEnteredStatus(
              resData.data.line.workOrderCard.phaseCommentsEnteredStatus,
            ),
            new LineActions.SetQuantities(resData.data.line.quantities),
            new LineActions.SetManualCountEntrySettings(resData.data.line.manualCountEntrySettings),
            );
          }),
          catchError((errorRes) => {
            return of(new ObjectActions.FetchError(errorRes), new AppActions.HideLoader());
          }),
        );
      }),
    ),
  );

  getQuantityFromSensor$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ObjectActions.HomeActionTypes.GetQuantityFromSensor),
      switchMap((objectData: ObjectActions.GetQuantityFromSensor) => {
        let url: string = `${this.baseUrl}/${this.HOME_URLS.GET.GET_QUANTITY_FROM_SENSOR}`;
        let siteDecimalScaleLimit: number = KPI_METRICS_DECIMAL_COUNT;
        this.store
          .select('user')
          .pipe(take(1))
          .subscribe((state: User) => {
            url = url.replace(':id', objectData.lineID?.toString() ?? state.lineId.toString());
            siteDecimalScaleLimit = state.siteDecimalScaleLimit;
          });

      return this.http.get<any>(url, {}).pipe(
        switchMap((resData: GetQuantityFromSensorInterface) => {
          return of(
            ...[
              new LineActions.SetQuantities(resData.data),
              ...(objectData.hideLoaderAfterwards ? [new AppActions.HideLoader()] : []),
            ...(resData?.data.isShowTaskTargetExceedError ? [new HomeActions.ShowTaskTargetExceedErrorMessage()] : [new HomeActions.HideTaskTargetExceedErrorMessage()]),],
          );
        }),
        catchError((errorRes) => {
          return of(new ObjectActions.FetchError(errorRes));
        }),
      );
    }),
  ));

  homeInformationLoaded$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ObjectActions.HomeActionTypes.HomeInformationLoaded),
      switchMap((objectData: ObjectActions.HomeInformationLoaded) => {
        this.translatorService.translate(objectData.payload.data.line.activityCard);
        this.translatorService.translate(objectData.payload.data.line.workOrderCard);

        let lineId;
        this.store
          .select('user')
          .pipe(take(1))
          .subscribe((state: User) => {
            lineId = objectData.lineID ?? state.lineId;
          });
        return of(
          new LineActions.SetMissingDataCount(objectData.payload.data.line.missingDataCount),
          new LineActions.SetWorkOrderCards(
            objectData.payload.data.line.workOrderCard as SetWorkOrderCardsActionInterface,
          ),
          new LineActions.SetActivityCardData({
            ...objectData.payload.data.line.activityCard,
            originalActivityEntityProperty: objectData.payload.data.line.activityCard.originalEntityProperty,
          } as SetActivityCardDataActionInterface),
          new LineActions.UpdateActivity({
            workOrderGoodCount: objectData.payload.data.line.workOrderCard.workOrderGoodCount,
            workOrderDuration: objectData.payload.data.line.workOrderCard.workOrderDuration,
            averageSpeed: objectData.payload.data.line.workOrderCard.averageSpeed,
            goodCount: objectData.payload.data.line.activityCard.goodCount,
            scrapCount: objectData.payload.data.line.activityCard.scrapCount,
            initialCount: objectData.payload.data.line.activityCard.initialCount,
            activityAverageSpeed: objectData.payload.data.line.activityCard.activityAverageSpeed,
            woSpeed: objectData.payload.data.line.workOrderCard.woSpeed,
            goodSpeed: objectData.payload.data.line.workOrderCard.goodSpeed,
            oeeFormula: objectData.payload.data.line.oeeFormula,
            rowGoodCount: objectData.payload.data.line.distributionCountData.rowGoodCount,
            rowScrapCount: objectData.payload.data.line.distributionCountData.rowScrapCount,
            auditLogUuid: objectData.payload.data.line.auditLogUuid,
          }),
          new AppActions.SetShowTour(objectData.payload.data.user.isSiteTourViewed === 0),
          new LineActions.SetLineType(objectData.payload.data.line.lineType),
          new ObjectActions.HomeMetricSetItemsLoading(lineId, objectData.page),
          new SensorStatusesActions.GetSensorStatuses(lineId),
          new HomeActions.IsOpcIntegrationInterrupted(objectData.payload.data.line.isOpcIntegrationInterrupted),
          ...(objectData.payload.data.line.isShowTaskTargetExceedError ? [new HomeActions.ShowTaskTargetExceedErrorMessage()] : [new HomeActions.HideTaskTargetExceedErrorMessage()]),
          new AppActions.HideLoader(),
        );
      }),
      catchError((errorRes) => {
        return of(new ObjectActions.FetchError(errorRes), new AppActions.HideLoader());
      }),
    ),
  );

  getActivityTreeGraph$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ObjectActions.HomeActionTypes.GetActivityTreeGraph),
      switchMap((objectData: ObjectActions.GetActivityTreeGraph) => {
        let params: HttpParams = new HttpParams();

        if (objectData.activityType !== undefined) {
          params = params.set('activityType', objectData.activityType);
        }

        return this.http
          .get<any>(this.HOME_URLS.GET.GET_ACTIVITY_TREE_GRAPH.replace(':lineId', String(objectData.lineId)), {
            params,
          })
          .pipe(
            switchMap((resData) => {
              return of(new ObjectActions.ActivityTreeGraphDataLoaded(resData), new AppActions.HideLoader());
            }),
            catchError((errorRes) => {
              return of(new ObjectActions.FetchError(errorRes), new AppActions.HideLoader());
            }),
          );
      }),
    ),
  );

  activityTreeGraphDataLoaded$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ObjectActions.HomeActionTypes.ActivityTreeGraphDataLoaded),
      map((objectData: ObjectActions.ActivityTreeGraphDataLoaded) => {
        this.homeActivityTreeChartService.onNotify(HelperService.cloneDeep(objectData.payload));
        return new AppActions.HideLoader();
      }),
      catchError((errorRes) => {
        return of(new ObjectActions.FetchError(errorRes), new AppActions.HideLoader());
      }),
    ),
  );

  saveWorkOrderFeedback$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ObjectActions.HomeActionTypes.SaveWorkOrderFeedback),
      switchMap((objectData: ObjectActions.SaveWorkOrderFeedback) => {
        return this.workOrderScheduleService.saveWorkOrderCloseFeedback(objectData.workOrderCloseFeedbackData).pipe(
          switchMap((response: WorkOrderCloseFeedbackResponseInterface) => {
            return of(
              new ObjectActions.SaveWorkOrderFeedbackLoaded(response),
              new ObjectActions.ClearWorkOrderFeedbackForm(),
            );
          }),
          catchError((errorRes) => {
            return of(new ObjectActions.FetchError(errorRes), new AppActions.HideLoader());
          }),
        );
      }),
    ),
  );

  changeActivity$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ObjectActions.HomeActionTypes.ChangeActivity),
      switchMap((objectDataRO: ObjectActions.ChangeActivity) => {
        const objectData = HelperService.cloneDeep(objectDataRO);
        this.store.dispatch(new AppActions.ShowLoader());
        const url = `${this.baseUrl}/${this.HOME_URLS.POST.CHANGE_ACTIVITY}`;

        if (objectData.overwriteSiteAndLineIds) {
          this.store
            .select('user')
            .pipe(take(1))
            .subscribe((state: User) => {
              objectData.requestData.siteId = state.siteId;
              objectData.requestData.lineId = state.lineId;
            });
        }

        return this.http
          .patch<ChangeActivityResponseInterface>(url, {
            ...objectData.requestData,
          })
          .pipe(
            switchMap((response: ChangeActivityResponseInterface) => {
              return of(
                new ObjectActions.StartLoadHomeInformation(),
                new ObjectActions.ChangeActivityCompleted(response, !!objectData.requestData.finalizeWorkOrder),
                new ObjectActions.HomeMetricSetItemsLoading(objectData.requestData.lineId),
                new AppActions.HideLoader(),
              );
            }),
            catchError((errorRes) => {
              return of(
                new ObjectActions.FetchError(errorRes),
                new AppActions.HideLoader(),
                new ObjectActions.ChangeActivityError(),
                new ObjectActions.StartLoadHomeInformation(),
                new ObjectActions.HomeMetricSetItemsLoading(objectData.requestData.lineId),
              );
            }),
          );
      }),
    ),
  );

  multipleChangeActivity$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ObjectActions.HomeActionTypes.MultipleChangeActivity),
      switchMap((objectDataRO: ObjectActions.MultipleChangeActivity) => {
        const objectData = HelperService.cloneDeep(objectDataRO);
        this.store.dispatch(new AppActions.ShowLoader());
        const url = `${this.baseUrl}/${this.HOME_URLS.POST.CHANGE_ACTIVITY}`;
        const [requestDataForCurrentLine] = objectData.requestData;

        const multipleRequests = objectData.requestData.map((requestData) =>
          this.http.patch<BaseOneResponseInterface<ChangeActivityResponseDataInterface>>(url, requestData),
        );
        return forkJoin(multipleRequests).pipe(
          switchMap((responses: BaseOneResponseInterface<ChangeActivityResponseDataInterface>[]) => {
            const [currentLineResponse] = responses;
            return of(
              new ObjectActions.StartLoadHomeInformation(),
              new ObjectActions.MultipleChangeActivityDone(),
              new ObjectActions.ChangeActivityCompleted(
                currentLineResponse,
                Boolean(requestDataForCurrentLine.finalizeWorkOrder),
              ),
              new ObjectActions.HomeMetricSetItemsLoading(requestDataForCurrentLine.lineId),
              new AppActions.HideLoader(),
            );
          }),
          catchError((errorRes) => {
            return of(
              new ObjectActions.FetchError(errorRes),
              new AppActions.HideLoader(),
              new ObjectActions.ChangeActivityError(),
              new ObjectActions.StartLoadHomeInformation(),
              new ObjectActions.HomeMetricSetItemsLoading(requestDataForCurrentLine.lineId),
            );
          }),
        );
      }),
    ),
  );

  searchTaskByActivityId$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ObjectActions.HomeActionTypes.SearchTaskByActivityId),
      switchMap((objectData: ObjectActions.SearchTaskByActivityId) => {
        let queryParams = new HttpParams();

        queryParams = queryParams.set('activityId', encodeURI(String(objectData.activityId)));
        if (objectData.equipmentSearch !== undefined && objectData.equipmentSearch !== null) {
          queryParams = queryParams.set('equipmentSearch', encodeURI(String(objectData.equipmentSearch)));
        }
        if (objectData.search !== undefined && objectData.search !== null) {
          queryParams = queryParams.set('search', encodeURI(objectData.search));
        }
        if (objectData.siteId !== undefined && objectData.siteId !== null) {
          queryParams = queryParams.set('siteId', encodeURI(objectData.siteId));
        }
        if (objectData.lineId !== undefined && objectData.lineId !== null) {
          queryParams = queryParams.set('lineId', encodeURI(objectData.lineId));
        }

        const options = {
          params: queryParams,
        };
        return this.http
        .get<any>(`${this.HOME_URLS.GET.SEARCH_TASK_BY_ACTIVITY_ID}`, { ...options })
        .pipe(
          switchMap((resData) => {
            return of(new ObjectActions.SearchTaskByActivityIdCompleted(resData), new AppActions.HideLoader());
          }),
          catchError((errorRes) => {
            return of(new ObjectActions.FetchError(errorRes), new AppActions.HideLoader());
          }),
        );
      }),
    ),
  );

  getTasksByActivityId$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ObjectActions.HomeActionTypes.GetTasksByActivityId),
      switchMap((objectData: ObjectActions.GetTasksByActivityId) => {
        let queryParams = new HttpParams();
        queryParams = queryParams.set('activityId', encodeURI(String(objectData.activityId)));
        const options = {
          params: queryParams,
        };
        return this.http
        .get<any>(`${this.HOME_URLS.GET.SEARCH_TASK_BY_ACTIVITY_ID}`, { ...options })
        .pipe(
          switchMap((resData) => {
            return of(
              new ObjectActions.GetTasksByActivityIdCompleted(resData, objectData.action),
              new AppActions.HideLoader(),
            );
          }),
          catchError((errorRes) => {
            return of(new ObjectActions.FetchError(errorRes), new AppActions.HideLoader());
          }),
        );
      }),
    ),
  );

  updateCurrentActivity$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ObjectActions.HomeActionTypes.UpdateCurrentActivity),
      switchMap((objectData: ObjectActions.UpdateCurrentActivity) => {
        let lineId;
        let url = `${this.baseUrl}/${this.HOME_URLS.POST.UPDATE_CURRENT_ACTIVITY}`;
        this.store
          .select('user')
          .pipe(take(1))
          .subscribe((state: User) => {
            url = url.replace(':id', state.lineId.toString());
            lineId = state.lineId;
          });

        return this.http.patch<UpdateCurrentActivityResponseInterface>(url, objectData.payload).pipe(
          switchMap((resData: UpdateCurrentActivityResponseInterface) => {
            const clonedResponse = HelperService.cloneDeep(resData);
            this.translatorService.translate(clonedResponse.data);
            const result: UpdateCurrentActivityResponseModel = clonedResponse.data;
            const expectedSpeed: string = this.getExpectedSpeed(result, lineId);
            let workOrderTarget: string = null;

            if (
              result.currentWorkOrder !== null &&
              (result.currentWorkOrder.scheduledPreDuration !== null ||
                result.currentWorkOrder.scheduledPostDuration !== null ||
                result.currentWorkOrder.scheduledRunDuration !== null)
            ) {
              workOrderTarget = this.decimalHelper.add(
                this.decimalHelper.add(
                  _.get(result.currentWorkOrder, 'scheduledPreDuration', '0'),
                  _.get(result.currentWorkOrder, 'scheduledPostDuration', '0'),
                ),
                _.get(result.currentWorkOrder, 'scheduledRunDuration', '0'),
              );
            }

            return of(
              new LineActions.UpdateActivity({
                workOrderTarget,
                expectedSpeed,
                activityCrewSize: result.selectedCrewSize,
                activityDescription: result.selectedDescription,
                activityId: result.selectedActivity,
                activityStart: result.timer,
                selectedPhase: result.selectedPhaseId,
                activityName: _.get(result.currentActivity, 'name', null),
                activityType: _.get(result.currentActivity, 'activityType', null),
                activitySubType: _.get(result.currentActivity, 'activitySubType.type', null),
                activityTarget: _.get(result.currentTask, 'target', null),
                batchFinalizer: _.get(result.currentTask, 'batchFinalizer', 0) === 1,
                ignoreSensor: _.get(result.currentTask, 'ignoreSensor', 0) === 1,
                ignorePhase: _.get(result.currentTask, 'ignorePhase', 0) === 1,
                taskName: _.get(result.currentTask, 'title', null),
                productDescription: _.get(result.currentWorkOrder, 'product.description', null),
                productId: _.get(result.currentWorkOrder, 'product.productId', null),
                productTableId: _.get(result.currentWorkOrder, 'product.id', 0).toString(),
                wOTableId: _.get(result.currentWorkOrder, 'id', 0).toString(),
                woDueDate: _.get(result.currentWorkOrder, 'woDueDate', null),
                workOrderId: _.get(result.currentWorkOrder, 'woNumber', null),
                versionId: _.get(result.currentWorkOrder, 'productVersionInfo.id', null),
                versionName: _.get(result.currentWorkOrder, 'productVersionInfo.name', null),
                workOrderQuantityOrdered: _.get(result.currentWorkOrder, 'quantityOrdered', 0),
                isMissingData: _.get(result.currentTask, 'isMissingData', false),
                missingActivityMode: _.get(result.currentTask, 'isMissingData', false),
                isCommentRequired: _.get(result.currentTask, 'isCommentRequired', false),
                auditLogUuid: result.auditLogUuid,
              }),
              new ObjectActions.UpdateCurrentActivityCompleted({
                ...clonedResponse,
              }),
              new ObjectActions.HomeMetricSetItemsLoading(lineId, objectData.page),
              result.currentWorkOrder === null
                ? new AppActions.HideLoader()
                : new HomeActions.UpdatePhaseCommentsEnteredStatus(result.currentWorkOrder.phaseCommentsEnteredStatus),
            );
          }),
          catchError((errorRes) => {
            return of(
              new ObjectActions.FetchError(errorRes, ObjectActions.HomeActionTypes.UpdateCurrentActivityCompleted),
              new AppActions.HideLoader(),
            );
          }),
        );
      }),
    ),
  );

  checkWorkOrder$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ObjectActions.HomeActionTypes.CheckWorkOrder),
      switchMap((objectData: ObjectActions.CheckWorkOrder) => {
        const searchQuery = {
          woNumber: {
            $eq: objectData.payload.workOrder,
          },
          siteId: {
            $eq: objectData.payload.siteId,
          },
        };
        let params = new HttpParams();
        params = params.set('s', JSON.stringify(searchQuery)).set('limit', '1');

        return this.http
          .get<ResponseArrayInterface<WorkOrderScheduleInterface>>(
            `${this.HOME_URLS.GET.GET_WORK_ORDER_NUMBERS}?join=scheduledLineDetail||title`,
            {
              params,
            },
          )
          .pipe(
            switchMap((response) => {
              return of(new ObjectActions.CheckWorkOrderCompleted(response.data), new AppActions.HideLoader());
            }),
            catchError((errorRes) => {
              return of(new ObjectActions.FetchError(errorRes), new AppActions.HideLoader());
            }),
          );
      }),
    ),
  );

  updateWorkOrder$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ObjectActions.HomeActionTypes.UpdateWorkOrder),
      switchMap((objectData: ObjectActions.UpdateWorkOrder) => {
        this.store.dispatch(new AppActions.ShowTopLoader());
        let lineId: number = null;

      if (objectData.isSiteLineIncluded) {
        this.store
          .select('user')
          .pipe(take(1))
          .subscribe((state: User) => {
            lineId = state.lineId;
          });
      }
      return this.http
        .patch<UpdateWorkOrderResponseInterface>(
          `${this.HOME_URLS.PATCH.UPDATE_WORK_ORDER}/${objectData.workOrderId}`,
          {
            ...objectData.payload,
          },
          lineId
            ? {
                params: new HttpParams().set('lineId', lineId),
              }
            : {},
        )
        .pipe(
          switchMap((response) => {
            return of(new ObjectActions.UpdateWorkOrderCompleted(response), new AppActions.HideTopLoader());
          }),
          catchError((errorRes) => {
            return of(new ObjectActions.FetchError(errorRes), new AppActions.HideTopLoader());
          }),
        );
    }),),
  );

  getWorkOrder$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ObjectActions.HomeActionTypes.GetWorkOrder),
      switchMap((objectData: ObjectActions.GetWorkOrder) => {
        return this.http
          .get<GetWorkOrderResponseInterface>(
            // eslint-disable-next-line max-len
            `${this.HOME_URLS.GET.GET_WORK_ORDER}/${objectData.payload.workOrderTableId}?join=product||productId,description&join=workOrderSplitNumber||id&join=workOrderSplitNumber.parentWorkOrder||woNumber&join=productVersionInfo&join=job||jobName`,
          )
          .pipe(
            switchMap((response: GetWorkOrderResponseInterface) => {
              this.translatorService.translate(response['data']);
              return of(new ObjectActions.GetWorkOrderCompleted(response['data']), new AppActions.HideLoader());
            }),
            catchError((errorRes) => {
              return of(new ObjectActions.FetchError(errorRes), new AppActions.HideLoader());
            }),
          );
      }),
    ),
  );

  workOrderStatus$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ObjectActions.HomeActionTypes.WorkOrderStatus),
      switchMap((objectData: ObjectActions.WorkOrderStatus) => {
        let queryParams: HttpParams = new HttpParams();
        queryParams = queryParams.append('join', 'product');
        queryParams = queryParams.append('join', 'scheduledLineDetail');
        return this.http
          .get<BaseOneResponseInterface<WorkOrderScheduleInterface>>(
            `${this.HOME_URLS.GET.GET_WORK_ORDER}/${objectData.payload.id}`,
            {
              params: queryParams,
            },
          )
          .pipe(
            switchMap((response: BaseOneResponseInterface<WorkOrderScheduleInterface>) => {
              this.translatorService.translate(response.data);
              return of(new ObjectActions.WorkOrderStatusCompleted(response.data), new AppActions.HideLoader());
            }),
            catchError((errorRes) => {
              return of(new ObjectActions.FetchError(errorRes), new AppActions.HideLoader());
            }),
          );
      }),
    ),
  );

  getProductPerformance$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ObjectActions.HomeActionTypes.GetProductPerformance),
      switchMap((objectData: ObjectActions.GetProductPerformance) => {
        const productPerformanceUrl: string = `${this.HOME_URLS.GET.PRODUCT_SEARCH}/${objectData.payload.id}${this.HOME_URLS.GET.GET_PRODUCT_PERFORMANCE}`;

        const queryParams: HttpParams = new HttpParams()
          .set('siteId', objectData.payload.siteId)
          .set('lineId', objectData.payload.lineId);

        return this.http
          .get<GetProductPerformanceResponseInterface>(productPerformanceUrl, {
            params: queryParams,
          })
          .pipe(
            switchMap((response) => {
              return of(new ObjectActions.GetProductPerformanceCompleted(response), new AppActions.HideLoader());
            }),
            catchError((errorRes) => {
              return of(new ObjectActions.FetchError(errorRes), new AppActions.HideLoader());
            }),
          );
      }),
    ),
  );

  productSearch$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ObjectActions.HomeActionTypes.ProductSearch),
      switchMap((objectData: ObjectActions.ProductSearch) => {
        let queryParams = new HttpParams();
        let searchText = null;
        let siteFilter = null;
        let searchParameterValue = null;
        queryParams = queryParams.set('limit', '50');

        if (objectData.filter) {
          this.store
            .select('user')
            .pipe(takeUntil(this.destroySubject))
            .subscribe((state: User) => {
              if (state.isUserLoaded) {
                this.userLanguage$ = state.language;
              }
              this.destroySubject.next(true);
              this.destroySubject.complete();
            });
          searchText = {
            $or: [{ description: { $cont: objectData.filter } }, { productId: { $cont: objectData.filter } }],
          };
        }

        if (objectData.page) {
          queryParams = queryParams.set('page', String(objectData.page));
        }

        if (objectData.siteId) {
          siteFilter = { siteId: { $eq: objectData.siteId } };
        }

        if (searchText !== null && siteFilter !== null) {
          searchParameterValue = {
            $and: [searchText, siteFilter],
          };
          queryParams = queryParams.set('s', JSON.stringify(searchParameterValue));
        } else if (searchText !== null || siteFilter !== null) {
          searchParameterValue = searchText !== null ? searchText : siteFilter;
          queryParams = queryParams.set('s', JSON.stringify(searchParameterValue));
        }

      queryParams = queryParams.append('join', 'productVersion');
      if (!_.isNil(objectData.filter) && objectData.filter !== '') {
        queryParams = queryParams.append('searchText', String(objectData.filter));
      }

      return this.http
        .get<ProductCRUDResponseInterface>(this.HOME_URLS.GET.PRODUCT_SEARCH, {
          params: queryParams,
        })
        .pipe(
          switchMap((response) => {
            response.data.forEach((product: ProductCRUDInterface) => this.translatorService.translate(product));
            return of(new ObjectActions.ProductSearchCompleted(response));
          }),
          catchError((errorRes) => {
            return of(new ObjectActions.FetchError(errorRes), new AppActions.HideLoader());
          }),
          finalize(() => {
            if (
              [ProductSearchDispatchAreas.OpenProductModal, ProductSearchDispatchAreas.OnScroll].indexOf(
                objectData.dispatchedBy,
              ) !== -1
            ) {
              this.store.dispatch(new ObjectActions.ProductSearchFinalized());
            }
          }),
        );
    }),
  ));

  private getSearchQuery(objectData: ObjectActions.GetWorkOrderNumbers): string {
    const searchQuery: any = {
      $and: [
        {
          scheduledLine: {
            $or: {
              $isnull: true,
              $eq: objectData.payload?.selectWorkOrderModalConfigurations?.lineId,
            },
          },
        },
        {
          completed: {
            $isnull: true,
          },
        },
        {
          canceled: {
            $eq: false,
          },
        },
        {
          siteId: {
            $eq: objectData.payload?.selectWorkOrderModalConfigurations?.siteId,
          },
        },
      ],
    };

    const orConditions: object[] = [];

    const decimalFields: string[] = [
      'scheduledPreDuration',
      'scheduledRunDuration',
      'scheduledPostDuration',
      'scheduledPreRunLaborHours',
      'scheduledRunLaborHours',
      'scheduledPostRunLaborHours',
      'quantityOrdered',
    ];

    if (objectData.payload?.searchFields) {
      objectData.payload.searchFields.forEach((item: string) => {
        let decimalSearch: number | null = null;

        if (decimalFields.includes(item) && this.decimalHelper.isValid(objectData.payload.search)) {
          decimalSearch = this.decimalHelper.decimalToNumberFormatter(objectData.payload.search);
        }
        orConditions.push({
          [this.getFieldKey(item)]: { $cont: decimalSearch ? String(decimalSearch) : objectData.payload.search },
        });
      });
    }

    if (objectData.payload.search && objectData.payload.workOrderFilter === 'all') {
      searchQuery.$and.splice(0, 1);
      orConditions.length
        ? searchQuery.$and.push({
            $or: orConditions,
          })
        : searchQuery.$and.push({
            woNumber: {
              $cont: objectData.payload.search,
            },
          });
      return JSON.stringify(searchQuery);
    }

    if (objectData.payload.search) {
      orConditions.length
        ? searchQuery.$and.push({
            $or: orConditions,
          })
        : searchQuery.$and.push({
            woNumber: {
              $cont: objectData.payload.search,
            },
          });
      return JSON.stringify(searchQuery);
    }

    if (objectData.payload.workOrderFilter === 'all') {
      searchQuery.$and.splice(0, 1);
      return JSON.stringify(searchQuery);
    }

    return JSON.stringify(searchQuery);
  }

  private getFieldKey(item: string): string {
    switch (item) {
      case 'productName':
        return 'product.productId';
      case 'productDescription':
        return 'product.description';
      default:
        return item;
    }
  }

  getWorkOrderNumbers$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ObjectActions.HomeActionTypes.GetWorkOrderNumbers),
      switchMap((objectData: ObjectActions.GetWorkOrderNumbers) => {
        let params = new HttpParams();
        params = params.append('join', 'product');
        params = params.append('join', 'job');

        params = params.set('s', this.getSearchQuery(objectData)).set('limit', '50');

        if (objectData.payload.page) {
          params = params.set('page', objectData.payload.page.toString());
        }

        if (objectData.payload.search) {
          params = params.set('s', this.getSearchQuery(objectData));
        }

        if (objectData.payload.workOrderFilter === 'all') {
          params = params.set('s', this.getSearchQuery(objectData));
          params = params.set('lineType', objectData.payload?.selectWorkOrderModalConfigurations?.lineType?.toString());
          params = params.set('siteId', objectData.payload?.selectWorkOrderModalConfigurations?.siteId?.toString());
        }

        if (objectData.payload.search && objectData.payload.workOrderFilter === 'all') {
          params = params.set('s', this.getSearchQuery(objectData));
          params = params.set('lineType', objectData.payload?.selectWorkOrderModalConfigurations?.lineType?.toString());
          params = params.set('siteId', objectData.payload?.selectWorkOrderModalConfigurations?.siteId?.toString());
        }

        if (objectData.payload.workOrderListOrderBy) {
          params = params.set('workOrderListOrderBy', 'true');
        }

        if (objectData.homeStationId) {
          params = params.set('homeStationId', String(objectData.homeStationId));
        }

        params = params.set('lineId', String(objectData.payload?.selectWorkOrderModalConfigurations?.lineId));

        return this.http
          .get<GetManyResponseInterface<WorkOrderScheduleInterface>>(this.HOME_URLS.GET.GET_WORK_ORDER_NUMBERS, {
            params,
          })
          .pipe(
            switchMap((response) => {
              return of(new ObjectActions.GetWorkOrderNumbersCompleted(response.data, objectData.payload.dispatchedBy));
            }),
            catchError((errorRes) => {
              return of(new ObjectActions.FetchError(errorRes), new AppActions.HideLoader());
            }),
          );
      }),
    ),
  );

  searchUsersByFilter$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ObjectActions.HomeActionTypes.SearchUsersByFilter),
      switchMap((objectData: ObjectActions.SearchUsersByFilter) => {
        return from(
          this.userService.getLineUsers(
            objectData.lineId,
            objectData.filter,
            objectData.userId,
            objectData.forCheckOutAll,
            objectData.excludedIds,
            objectData.excludeNonCheckables,
          ),
        ).pipe(
          switchMap((response: UserGetManyCRUDDataInterface) => {
            return of(new ObjectActions.SearchUsersByFilterCompleted(response.data, objectData.forCheckOutAll));
          }),
          catchError((err) => {
            return of(new ObjectActions.FetchError(err));
          }),
        );
      }),
    ),
  );

  searchAssetsByFilter$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ObjectActions.HomeActionTypes.SearchAssetsByFilter),
      switchMap((objectData: ObjectActions.SearchAssetsByFilter) => {
        return from(
          this.equipmentListService.getEquipmentList(objectData.siteId, objectData.equipmentId, objectData.searchText),
        ).pipe(
          switchMap((response: EquipmentListGetManyCRUDDataInterface) => {
            response.data.forEach((equipmentList: EquipmentListGetOneCRUDDataInterface) =>
              this.translatorService.translate(equipmentList),
            );
            return of(new ObjectActions.SearchAssetsByFilterCompleted(response.data));
          }),
          catchError((err) => {
            return of(new ObjectActions.FetchError(err));
          }),
        );
      }),
    ),
  );

  getHomeScreenMetricItems = createEffect(() =>
    this.actions$.pipe(
      ofType(ObjectActions.HomeActionTypes.HomeMetricSetItemsLoading),
      switchMap((objectData: ObjectActions.HomeMetricSetItemsLoading) => {
        const params: HttpParams = new HttpParams()
          .append('join', 'layoutAssignment')
          .append('join', 'homeMetricSet')
          .append('join', 'homeMetricSet.homeMetricSetItems')
        .append('join', 'homeMetricSet.homeMetricSetItems.homeMetricSetProperties')
          .append(
            's',
            JSON.stringify({
              'homeMetricSet.page': {
                $eq: objectData.page,
              },
              'layoutAssignment.lineId': {
                $eq: objectData.lineId,
              },
            }),
          )
          .set('sort', 'homeMetricSet.homeMetricSetItems.order,ASC')
          .set('limit', '1');
        return this.homeActivityTreeChartService.getLayouts(params).pipe(
          switchMap((resData: GetManyResponseInterface<ILayout>) => {
            return of(new ObjectActions.HomeMetricSetItemsLoaded(resData.data));
          }),
        );
      }),
    ),
  );

  getPhaseDurations = createEffect(() =>
    this.actions$.pipe(
      ofType(ObjectActions.HomeActionTypes.PhaseDurationsLoading),
      switchMap((objectData: ObjectActions.PhaseDurationsLoading) => {
        return this.homeActivityTreeChartService.getPhaseDuration(objectData.lineId).pipe(
          switchMap((resData: BaseOneResponseInterface<PhaseDurationsInterface>) => {
            return of(new ObjectActions.PhaseDurationsLoaded(resData.data, objectData.phase));
          }),
        );
      }),
    ),
  );

  getPhaseComments = createEffect(() =>
    this.actions$.pipe(
      ofType(ObjectActions.HomeActionTypes.PhaseCommentsLoading),
      switchMap((objectData: ObjectActions.PhaseCommentsLoading) => {
        return this.homeActivityTreeChartService.getPhaseComments(objectData.workOrderId).pipe(
          switchMap((resData: BaseOneResponseInterface<PhaseCommentsResponseInterface>) => {
            const newComments: PhaseCommentStoreDataInterface = {
              preRun: null,
              run: null,
              postRun: null,
            };

            for (const comment of resData.data.comments) {
              const newComment = {
                id: comment.commentId,
                message: comment.commentMessage,
                folderId: comment.folderId,
              };

              switch (comment.objectProperty) {
                case ShiftSummaryCommentObjectPropertyTypes.PRE:
                  newComments.preRun = newComment;
                  break;
                case ShiftSummaryCommentObjectPropertyTypes.RUN:
                  newComments.run = newComment;
                  break;
                case ShiftSummaryCommentObjectPropertyTypes.POST:
                  newComments.postRun = newComment;
                  break;
                default:
                  break;
              }
            }
            return of(
              new ObjectActions.PhaseCommentsLoaded(newComments),
              new ObjectActions.UpdatePhaseCommentsEnteredStatus(resData.data.phaseCommentsEnteredStatus),
            );
          }),
        );
      }),
    ),
  );

  updatePhaseComments = createEffect(() =>
    this.actions$.pipe(
      ofType(ObjectActions.HomeActionTypes.UpdatePhaseComment),
      switchMap((objectData: ObjectActions.UpdatePhaseComment) => {
        return this.homeActivityTreeChartService
          .updatePhaseComment(objectData.id, objectData.message, objectData.folderId)
          .pipe(
            switchMap((resData: PhaseCommentCrudUpdateResponseInterface) => {
              const commentUpdate = HomeEffects.formatUpdatedPhaseComment(resData.data);
              return of(new ObjectActions.UpdatePhaseCommentCompleted(commentUpdate));
            }),
            catchError(() => {
              return of(new ObjectActions.UpdatePhaseCommentCompleted(null));
            }),
          );
      }),
      catchError(() => {
        return of(new ObjectActions.UpdatePhaseCommentCompleted(null));
      }),
    ),
  );

  deletePhaseComments = createEffect(() =>
    this.actions$.pipe(
      ofType(ObjectActions.HomeActionTypes.DeletePhaseComment),
      switchMap((objectData: ObjectActions.DeletePhaseComment) => {
        return this.homeActivityTreeChartService.deletePhaseComment(objectData.id).pipe(
          switchMap(() => {
            return of(new ObjectActions.DeletePhaseCommentCompleted(objectData.id));
          }),
        );
      }),
    ),
  );

  createPhaseComments = createEffect(() =>
    this.actions$.pipe(
      ofType(ObjectActions.HomeActionTypes.CreatePhaseComment),
      switchMap((objectData: ObjectActions.CreatePhaseComment) => {
        return this.homeActivityTreeChartService
          .createPhaseComment(objectData.siteId, objectData.workOrderId, objectData.message, objectData.phaseType, objectData.folderId)
          .pipe(
            switchMap((resData: PhaseCommentCrudUpdateResponseInterface) => {
              const commentUpdate = HomeEffects.formatUpdatedPhaseComment(resData.data);
              return of(new ObjectActions.CreatePhaseCommentCompleted(commentUpdate));
            }),
          );
      }),
      catchError(() => {
        return of(new ObjectActions.CreatePhaseCommentCompleted(null));
      }),
    ),
  );

  private getExpectedSpeed(data: UpdateCurrentActivityResponseModel, lineId: number): string {
    let output: string = data.standardSpeed;
    let productSpeed: CurrentProductSpeedInterface = null;

    if (!_.isNil(data.currentWorkOrder?.product?.productSpeedTable)) {
       productSpeed = _.find(data.currentWorkOrder.product.productSpeedTable, { lineId: lineId });
    }

    if (data.currentWorkOrder === null || data.currentWorkOrder.product === null) {
      output = '';
    } else if (data.currentWorkOrder.product.productSpeedTable !== null && !_.isNil(productSpeed)) {
      output = productSpeed.speed;
    } else if (data.currentWorkOrder.product.productSpeed !== null) {
      output = this.decimalHelper.toFixedValue(data.currentWorkOrder.product.productSpeed.toString());
    }

    return output;
  }

  private static formatUpdatedPhaseComment(
    comment: PhaseCommentCrudDataInterface,
  ): PhaseCommentStoreUpdateDataInterface {
    const commentUpdate: PhaseCommentStoreUpdateDataInterface = {};
    const newComment = {
      id: comment.id,
      message: comment.commentMessage,
    };

    switch (comment.objectProperty) {
      case ShiftSummaryCommentObjectPropertyTypes.PRE:
        commentUpdate.preRun = newComment;
        break;
      case ShiftSummaryCommentObjectPropertyTypes.RUN:
        commentUpdate.run = newComment;
        break;
      case ShiftSummaryCommentObjectPropertyTypes.POST:
        commentUpdate.postRun = newComment;
        break;
      default:
        break;
    }
    return commentUpdate;
  }

  private static calculateHomeActivityButtons(buttonData): HomeActivityButtonInterface[] {
    const activityButtons = [];

    for (let i = 0; i < buttonData.length; i = i + 1) {
      let className;

      switch (buttonData[i].activityType) {
        case 'runTime':
          className = 'btn-success btn-outline-success';
          break;
        case 'downTime':
        case 'downTimePlanned':
          className = 'btn-danger btn-outline-danger';
          break;
        case 'idleTime':
          className = 'btn-light btn-outline-light';
          break;
        default:
          className = '';
      }

      activityButtons.push({
        id: buttonData[i].id,
        name: buttonData[i].activityName,
        type: buttonData[i].activityType,
        typeTitle: buttonData[i].activityTypeTitle,
        class: className,
        icon: activityStyles(buttonData[i].activityType, buttonData[i].activitySubType),
      });
    }
    return activityButtons;
  }

  getWorkOrderFeedback$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ObjectActions.HomeActionTypes.GetWorkOrderFeedbackLoading),
      switchMap((objectData: ObjectActions.GetWorkOrderFeedback) => {
        const searchQuery = {
          workOrderId: {
            $eq: objectData.workOrderScheduleTableId,
          },
        };
        let params = new HttpParams();
        params = params.set('s', JSON.stringify(searchQuery));

        return this.workOrderScheduleService.getWorkOrderCloseFeedback(params).pipe(
          switchMap((resData: WorkOrderCloseFeedbackResponseInterface) => {
            return of(new ObjectActions.GetWorkOrderFeedbackLoaded(resData.data[0]), new AppActions.HideLoader());
          }),
        );
      }),
    ),
  );

  updateWorkOrderFeedback$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ObjectActions.HomeActionTypes.UpdateWorkOrderFeedbackLoading),
      switchMap((objectData: ObjectActions.UpdateWorkOrderFeedback) => {
        return this.workOrderScheduleService.updateWorkOrderCloseFeedback(objectData.workOrderCloseFeedbackData).pipe(
          switchMap((response: WorkOrderCloseFeedbackResponseInterface) => {
            return of(new ObjectActions.UpdateWorkOrderFeedbackCompleted(response));
          }),
          catchError((errorRes) => {
            return of(new ObjectActions.FetchError(errorRes), new AppActions.HideLoader());
          }),
        );
      }),
    ),
  );

  getLineInfoOfSelectedWorkOrder = createEffect(() =>
    this.actions$.pipe(
      ofType(ObjectActions.HomeActionTypes.GetLineInfoThatSelectedWorkOrder),
      switchMap((objectData) => {
        this.store.dispatch(new AppActions.ShowLoader());

        return this.homeActivityTreeChartService
          .getLineInfoOfSelectedWorkOrder(objectData.id, objectData.lineId, objectData.additionalParams)
          .pipe(
            switchMap((response: BaseOneResponseInterface<ILineInfoThatWorkOrder>) => {
              return of(new ObjectActions.GetLineInfoThatWorkOrderLoaded(response.data), new AppActions.HideLoader());
            }),
            catchError((errorRes) => {
              return of(new ObjectActions.FetchError(errorRes), new AppActions.HideLoader());
            }),
          );
      }),
    ),
  );

  updateLineInformation = createEffect(() =>
    this.actions$.pipe(
      ofType(ObjectActions.HomeActionTypes.UpdateLineInformation),
      switchMap((objectData: { id: number; body: IRemoveWorkOrderRequestBody }) => {
        this.store.dispatch(new AppActions.ShowLoader());

        return this.lineService.updateLine(objectData.id, objectData.body).pipe(
          switchMap((response: BulkResponseDataInterface) => {
            return of(
              new ObjectActions.UpdateLineInformationCompleted(response),
              new ObjectActions.StartLoadHomeInformation(),
              new AppActions.HideLoader(),
            );
          }),
          catchError((errorRes) => {
            return of(new ObjectActions.FetchError(errorRes), new AppActions.HideLoader());
          }),
        );
      }),
      catchError((errorRes) => {
        return of(new ObjectActions.FetchError(errorRes), new AppActions.HideLoader());
      }),
    ),
  );

  splitWorkOrderOnLine = createEffect(() =>
    this.actions$.pipe(
      ofType(ObjectActions.HomeActionTypes.SplitWorkOrderOnLineLoad),
      switchMap((objectData) => {
        this.store.dispatch(new AppActions.ShowLoader());

        return this.homeActivityTreeChartService.splitWorkOrderOnLine(objectData.id, objectData.copyTargetSpeed).pipe(
          switchMap(() => {
            return !environment.production ? of(new ObjectActions.StartLoadHomeInformation()) : of();
          }),
          catchError((errorRes) => {
            return of(new ObjectActions.FetchError(errorRes), new AppActions.HideLoader());
          }),
        );
      }),
      catchError((errorRes) => {
        return of(new ObjectActions.FetchError(errorRes), new AppActions.HideLoader());
      }),
    ),
  );

  getSplitWorkOrderOnLinePredictedWorkOrderNumberLoad = createEffect(() =>
    this.actions$.pipe(
      ofType(ObjectActions.HomeActionTypes.SplitWorkOrderOnLinePredictedWorkOrderNumberLoad),
      switchMap((objectData: ObjectActions.SplitWorkOrderOnLinePredictedWorkOrderNumberLoad) => {
        this.store.dispatch(new AppActions.ShowLoader());

        return this.homeActivityTreeChartService.getSplitWorkOrderOnLinePredictedWorkOrderNumber(objectData.id).pipe(
          switchMap((response: BaseOneResponseInterface<IPredictedWorkOrderInfo>) => {
            return of(
              new ObjectActions.SplitWorkOrderOnLinePredictedWorkOrderNumberLoaded(response.data),
              new AppActions.HideLoader(),
            );
          }),
          catchError((errorRes) => {
            return of(new ObjectActions.FetchError(errorRes), new AppActions.HideLoader());
          }),
        );
      }),
      catchError((errorRes) => {
        return of(new ObjectActions.FetchError(errorRes), new AppActions.HideLoader());
      }),
    ),
  );


  getVersionsOfProduct = createEffect(() => this.actions$.pipe(
    ofType(ObjectActions.HomeActionTypes.ProductVersionsLoad),
    switchMap((objectData: ObjectActions.ProductVersionsLoad) => {
      this.store.dispatch(new AppActions.ShowLoader());

      return this.productService.getProductVersions(objectData.productId).pipe(
        switchMap((response: GetManyResponseInterface<IProductVersion>) => {
          return of(new ObjectActions.ProductVersionsLoaded(response.data), new AppActions.HideLoader());
        }),
        catchError((errorResponse) => {
          return of(new ObjectActions.FetchError(errorResponse), new AppActions.HideLoader());
        }),
      );
    }),
    catchError((errorResponse) => {
      return of(new ObjectActions.FetchError(errorResponse), new AppActions.HideLoader());
    }),
  ));


  getJobs = createEffect(() => this.actions$.pipe(
    ofType<ObjectActions.GetJobs>(ObjectActions.HomeActionTypes.GetJobs),
    switchMap((objectData: ObjectActions.GetJobs) => {
      this.store.dispatch(new AppActions.ShowLoader());
      let httpParams = new HttpParams().set('limit', '50');
      const filter: any = {
        $and: [{ siteId: { $eq: String(objectData.siteId) } }],
      };
      const getJobs = {
        ...filter,
        $and: [...filter.$and, { id: { $ne: objectData.jobId ?? 'null' } }],
      };
      const selectedJob = {
        ...filter,
        $and: [...filter.$and, { id: objectData.jobId }],
      };

      if (objectData.searchText !== null) {
        getJobs.$and.push({ jobName: { $cont: objectData.searchText } });
        selectedJob.$and.push({ jobName: { $cont: objectData.searchText } });
      }

      const getJobsHttpParams = httpParams.set('s', JSON.stringify(getJobs));
      const selectedJobHttpParams = httpParams.set('s', JSON.stringify(selectedJob));

      return forkJoin({
        jobs: this.jobService.getJobs(getJobsHttpParams),
        selectedJob: objectData.jobId ? this.jobService.getJobs(selectedJobHttpParams) : of(null),
      }).pipe(
        switchMap((response) => {
          response.jobs.data = [...(response.selectedJob?.data ?? []), ...response.jobs.data];

          return of(new ObjectActions.GetJobsCompleted(response.jobs), new AppActions.HideLoader());
        }),
        catchError((error) => {
          return of(new ObjectActions.FetchError(error), new AppActions.HideLoader());
        }),
      );
    }),
    catchError((error) => {
      return of(new ObjectActions.FetchError(error), new AppActions.HideLoader());
    }),
  ));


  createJob = createEffect(() => this.actions$.pipe(
    ofType(ObjectActions.HomeActionTypes.CreateJob),
    switchMap((objectData: ObjectActions.CreateJob) => {
      return this.jobService.createJob(objectData.job).pipe(
        switchMap((response: BaseOneResponseInterface<IJob>) => {
          return of(new ObjectActions.CreateJobCompleted(response.data), new AppActions.HideLoader());
        }),
        catchError((error) => {
          return of(new ObjectActions.FetchError(error), new AppActions.HideLoader());
        }),
      );
    }),
    catchError((error) => {
      return of(new ObjectActions.FetchError(error), new AppActions.HideLoader());
    }),
  ));

   getOngoingActivityTotalDuration = createEffect(() =>
    this.actions$.pipe(
      ofType(LineActions.ONGOING_ACTIVITY_TOTAL_DURATION_LOADING),
      switchMap((objectData: LineActions.OngoingActivityTotalDurationLoading) => {
        this.store.dispatch(new AppActions.ShowLoader());
        const httpParams: HttpParams = new HttpParams().set('stationId', objectData.stationId);

        return this.lineService
          .getOngoingActivityTotalDuration(objectData.lineId, objectData.stationId ? httpParams : null)
          .pipe(
            switchMap((response: BaseOneResponseInterface<IOngoingActivityTotalDuration>) => {
              return of(new LineActions.OngoingActivityTotalDurationLoaded(response.data), new AppActions.HideLoader());
            }),
            catchError((error) => {
              return of(new ObjectActions.FetchError(error), new AppActions.HideLoader());
            }),
          );
      }),
      catchError((error) => {
        return of(new ObjectActions.FetchError(error), new AppActions.HideLoader());
      }),
    ),
  );
   getProductTransitionMatrix = createEffect(() =>
    this.actions$.pipe(
      ofType(ObjectActions.HomeActionTypes.GetProductTransitionMatrix),
      switchMap((objectData: ObjectActions.GetProductTransitionMatrix) => {

        return this.productTransitionMatrixService.getProductTransitionMatrixOfWorkOrder(objectData.payload).pipe(
          switchMap((response: BaseOneResponseInterface<IGetProductTransitionMatrix>) => {
            return of(new ObjectActions.GetProductTransitionMatrixCompleted(response.data),
              new AppActions.HideLoader());
          }),
          catchError((error) => {
            return of(new ObjectActions.FetchError(error), new AppActions.HideLoader());
          }),
        );
      }),
    ),
  );

   getPreviousWorkOrder = createEffect(() =>
    this.actions$.pipe(
      ofType(ObjectActions.HomeActionTypes.GetPreviousWorkOrder),
      switchMap((objectData: ObjectActions.GetPreviousWorkOrder) => {
        const search: object = {
          $and: [{ siteId: { $eq: objectData?.siteId } }, { lineId: { $eq: objectData?.lineId } }],
        };

        return this.activityHistoryService.getActivities(search, 1,1, 'end', true).pipe(
          switchMap((response: ResponseArrayInterface<IActivityHistory>) => {
            return of(new ObjectActions.GetPreviousWorkOrderCompleted(response.data),
              new AppActions.HideLoader());
          }),
          catchError((error) => {
            return of(new ObjectActions.FetchError(error), new AppActions.HideLoader());
          }),
        );
      }),
    ),
  );

  getIsLinePathUtilized = createEffect(() =>
    this.actions$.pipe(
      ofType(ObjectActions.HomeActionTypes.GetIsLinePathUtilized),
      switchMap((objectData: ObjectActions.GetIsLinePathUtilized) => {
        const httpParams: HttpParams = this.httpHelper.insertGenericCrudRequestParameters({ join: ['linePaths'] });

        return this.siteService.getOneSite(httpParams, objectData.siteId).pipe(
          switchMap((response: BaseOneResponseInterface<SiteCRUDInterface>) => {
            return of(new ObjectActions.GetIsLinePathUtilizedCompleted(Boolean(response.data.linePaths?.length)));
          }),
          catchError((error) => {
            return of(new ObjectActions.FetchError(error), new AppActions.HideLoader());
          }),
        );
      }),
    ),
  );

  assignWoLinePathJob = createEffect(() =>
    this.actions$.pipe(
      ofType(ObjectActions.HomeActionTypes.AssignWoLinePathJob),
      switchMap((objectData: ObjectActions.AssignWoLinePathJob) => {
        this.store.dispatch(new AppActions.ShowLoader());

        return this.workOrderScheduleService
          .assignWoLinePathJob(objectData.workOrderId, objectData.linePathId, objectData.homeLineId)
          .pipe(
            switchMap((response) => {
              return of(new ObjectActions.AssignWoLinePathJobCompleted(objectData.method, response.data), new AppActions.HideLoader());
            }),
            catchError((error) => {
              return of(new ObjectActions.FetchError(error), new AppActions.HideLoader());
            }),
          );
      }),
    ),
  );

  getActivityHistoryData = createEffect(() =>
    this.actions$.pipe(
      ofType(ObjectActions.HomeActionTypes.StartActivityHistoryDataLoading),
      switchMap((objectData: ObjectActions.StartActivityHistoryDataLoading) => {
        this.store.dispatch(new AppActions.ShowLoader());

        return this.activityHistoryService
          .getActivities(
            objectData.searchObject,
            objectData.tableQuery.page,
            objectData.tableQuery.pageSize,
            objectData.tableQuery.orderBy,
            objectData.tableQuery.orderDesc,
          )
          .pipe(
            switchMap((response: ResponseArrayInterface<IActivityHistory>) => {
              response.data.forEach((activityHistory: IActivityHistory) => {
                this.entityTranslation.translate(activityHistory);
              });

              return of(new ObjectActions.ActivityHistoryDataLoaded(response), new AppActions.HideLoader());
            }),
            catchError(() => {
              return of(new AppActions.HideLoader(), new AppActions.HideTopLoader());
            }),
          );
      }),
      catchError(() => {
        return of(new AppActions.HideLoader(), new AppActions.HideTopLoader());
      }),
    ),
  );

  workOrderMissingControl = createEffect(() =>
    this.actions$.pipe(
      ofType(ObjectActions.HomeActionTypes.WorkOrderMissingControl),
      switchMap((objectData: ObjectActions.WorkOrderMissingControl) => {
        this.store.dispatch(new AppActions.ShowLoader());

        return this.workOrderScheduleService.workOrderMissingControl(objectData.params).pipe(
          switchMap((response) => {
            return of(new ObjectActions.WorkOrderMissingControlCompleted(response.data), new AppActions.HideLoader());
          }),
          catchError((error) => {
            return of(new ObjectActions.FetchError(error), new AppActions.HideLoader());
          }),
        );
      }),
      catchError((error) => {
        return of(new ObjectActions.FetchError(error), new AppActions.HideLoader());
      }),
    ),
  );
}
