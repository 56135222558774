import { ICommentsResponse } from '../../view/home/work-order/work-order-manual-count/work-order-manual-count.model';

export interface ManualCountModel {
  createManualCountLoading: boolean;
  createManualCountLoaded: boolean;
  setManualCountLoading: boolean;
  setManualCountLoaded: boolean;
  isManualCountCommentsSaveStarted: boolean;
  isManualCountCommentsSaveCompleted: boolean;
  manualCountCommentSaveResponse: ICommentsResponse[];
  isManualCountDataLoading: boolean;
  isManualCountDataLoaded: boolean;
}

export enum ECountMode {
  all = 'all',
  hourly = 'hourly',
  calculated = 'calculated',
}
