import * as Actions from './lines.actions';
import { LinesStateInterface } from './lines.model';

export const linesState: LinesStateInterface = {
  linesDataLoaded: false,
  linesDataLoading: false,
  lines: [],
  linesTotal: null,
  shiftPlansDataLoaded: false,
  shiftPlansDataLoading: false,
  shiftPlans: [],
  lineExcelDownloading: false,
  lineExcelDownloaded: false,
  lineExcelUploading: false,
  lineExcelUploaded: false,
  excelUploadResult: [],
  linesCountLoaded: false,
  linesCountLoading: false,
  lineCountForExcel: null,
  lineAddLoading: false,
  lineAddLoaded: false,
  lineEditLoading: false,
  lineEditLoaded: false,
  lineDeleteLoading: false,
  lineDeleteLoaded: false,
  lineBulkDeleteLoading: false,
  lineBulkDeleteLoaded: false,
  lineBulkEditLoading: false,
  lineBulkEditLoaded: false,
  layoutsDataLoaded: false,
  layoutsDataLoading: false,
  layouts: [],
  tasksDataLoaded: false,
  tasksDataLoading: false,
  tasks: [],
};

export function linesReducer(
  state: LinesStateInterface = linesState,
  action: Actions.LinesActions,
): LinesStateInterface {
  switch (action.type) {
    case Actions.LINES_DATA_LOAD:
      return {
        ...state,
        ...{
          linesDataLoading: true,
          linesDataLoaded: false,
        },
      };
    case Actions.LINES_DATA_LOADED:
      return {
        ...state,
        ...{
          lines: action.payload.data,
          linesTotal: action.payload.total,
          linesDataLoading: false,
          linesDataLoaded: true,
        },
      };
    case Actions.SHIFT_PLAN_DATA_LOAD:
      return {
        ...state,
        ...{
          shiftPlansDataLoading: true,
          shiftPlansDataLoaded: false,
        },
      };
    case Actions.SHIFT_PLAN_DATA_LOADED:
      return {
        ...state,
        ...{
          shiftPlans: action.payload.data,
          shiftPlansDataLoading: false,
          shiftPlansDataLoaded: true,
        },
      };
    case Actions.TASK_DATA_LOAD:
      return {
        ...state,
        ...{
          tasksDataLoading: true,
          tasksDataLoaded: false,
        },
      };
    case Actions.TASK_DATA_LOADED:
      return {
        ...state,
        ...{
          tasks: action.payload,
          tasksDataLoading: false,
          tasksDataLoaded: true,
        },
      };
    case Actions.LAYOUT_DATA_LOAD:
      return {
        ...state,
        ...{
          layoutsDataLoading: true,
          layoutsDataLoaded: false,
        },
      };
    case Actions.LAYOUT_DATA_LOADED:
      return {
        ...state,
        ...{
          layouts: action.payload.data,
          layoutsDataLoading: false,
          layoutsDataLoaded: true,
        },
      };
    case Actions.DOWNLOAD_LINES_EXCEL:
      return {
        ...state,
        ...{
          lineExcelDownloading: true,
          lineExcelDownloaded: false,
        },
      };
    case Actions.DOWNLOAD_LINES_EXCEL_COMPLETED:
      return {
        ...state,
        ...{
          lineExcelDownloading: false,
          lineExcelDownloaded: true,
        },
      };
    case Actions.UPLOAD_LINES_EXCEL:
      return {
        ...state,
        ...{
          lineExcelUploading: true,
          lineExcelUploaded: false,
        },
      };
    case Actions.UPLOAD_LINES_EXCEL_COMPLETED:
      return {
        ...state,
        ...{
          excelUploadResult: action.payload,
          lineExcelUploading: false,
          lineExcelUploaded: true,
        },
      };
    case Actions.LINES_COUNT_LOAD:
      return {
        ...state,
        ...{
          linesCountLoading: true,
          linesCountLoaded: false,
        },
      };
    case Actions.LINES_COUNT_LOADED:
      return {
        ...state,
        ...{
          lineCountForExcel: action.payload.length,
          linesCountLoading: false,
          linesCountLoaded: true,
        },
      };

    case Actions.ADD_LINE:
      return {
        ...state,
        lineAddLoading: true,
        lineAddLoaded: false,
      };
    case Actions.ADD_LINE_COMPLETED:
      return {
        ...state,
        lineAddLoading: false,
        lineAddLoaded: true,
      };
    case Actions.EDIT_LINE:
      return {
        ...state,
        lineEditLoading: true,
        lineEditLoaded: false,
      };
    case Actions.EDIT_LINE_COMPLETED:
      return {
        ...state,
        lineEditLoading: false,
        lineEditLoaded: true,
      };
    case Actions.DELETE_LINE:
      return {
        ...state,
        lineDeleteLoading: true,
        lineDeleteLoaded: false,
      };
    case Actions.DELETE_LINE_COMPLETED:
      return {
        ...state,
        lineDeleteLoading: false,
        lineDeleteLoaded: true,
      };
    case Actions.BULK_DELETE_LINES:
      return {
        ...state,
        lineBulkDeleteLoading: true,
        lineBulkDeleteLoaded: false,
      };
    case Actions.BULK_DELETE_LINES_COMPLETED:
      return {
        ...state,
        lineBulkDeleteLoading: false,
        lineBulkDeleteLoaded: true,
      };
    case Actions.BULK_EDIT_LINES:
      return {
        ...state,
        lineBulkEditLoading: true,
        lineBulkEditLoaded: false,
      };
    case Actions.BULK_EDIT_LINES_COMPLETED:
      return {
        ...state,
        lineBulkEditLoading: false,
        lineBulkEditLoaded: true,
      };
    default:
      return state;
  }
}
