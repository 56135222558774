import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import {
  ApplyChangesModalComponent,
  EApplyChangesModalResult,
} from 'src/app/standalone/modals/apply-changes-modal/apply-changes-modal.component';
import { ScwModalSize } from '../component/scw-mat-ui/scw-mat-modal/scw-mat-modal.model';

export async function showApplyChangesModal(params: {
  dirty: boolean;
  modalService: NgbModal;
  apply: () => void;
}): Promise<boolean> {
  const { dirty, modalService, apply } = params;

  if (!dirty) {
    return true;
  }

  const applyChangesModal = modalService.open(ApplyChangesModalComponent, { windowClass: ScwModalSize.small });

  try {
    const result: EApplyChangesModalResult = await applyChangesModal.result;

    if (result === EApplyChangesModalResult.DoNotApply) {
      return true;
    }

    apply();
  } catch (ignored) {}

  return false;
}
