import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, switchMap } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { OeeAppState } from '../../oee.reducer';
import { HttpParams } from '@angular/common/http';
import { LaborAssetHomeService } from './labor-asset-home.service';
import { GetManyResponseInterface } from '../../../shared/model/interface/crud-response-interface.model';
import { ILaborAssetViewData } from '../../dashboards/labor-asset-view/labor-asset-view.model';
import { of } from 'rxjs';
import * as _ from 'lodash';
import * as AppActions from '../../app/actions';
import * as LaborAssetHomeActions from './labor-asset-home.actions';

@Injectable()
export class LaborAssetHomeEffects {
  constructor(
    private actions$: Actions<LaborAssetHomeActions.HomeActions>,
    private store: Store<OeeAppState>,
    private service: LaborAssetHomeService,
  ) {}

  getLaborAssetHomeData$ = createEffect(() =>
    this.actions$.pipe(
      ofType(LaborAssetHomeActions.ActionTypes.GET_LABOR_ASSET_HOME_DATA),
      switchMap((objectData: LaborAssetHomeActions.GetLaborAssetHomeData) => {
        let httpParams: HttpParams = new HttpParams()
          .set('userTypes', String(objectData.params.userTypes))
          .set('queryType', objectData.params.timeToggle)
          .set('dashboardType', objectData.params.typeToggle);

        if (objectData.params.stationId) {
          httpParams = httpParams.append('stationId', objectData.params.stationId);
        }

        this.store.dispatch(new AppActions.ShowTopLoader());

        return this.service.getLaborAssetHomeData(objectData.lineId, httpParams).pipe(
          map(
            (
              response: GetManyResponseInterface<ILaborAssetViewData>,
            ): GetManyResponseInterface<ILaborAssetViewData> => {
              response.data = response.data.map((data: ILaborAssetViewData): ILaborAssetViewData => {
                return {
                  ...data,
                  checkIns: _.orderBy(data.checkIns, 'calculatedCheckInTime'),
                };
              });

              return response;
            },
          ),
          switchMap((response: GetManyResponseInterface<ILaborAssetViewData>) => {
            return of(
              new LaborAssetHomeActions.GetLaborAssetHomeDataCompleted(response.data, objectData.siteChanged),
              new AppActions.HideTopLoader(),
            );
          }),
          catchError((err: unknown) => {
            return of(new LaborAssetHomeActions.FetchError(err));
          }),
        );
      }),
      catchError((err: unknown) => {
        return of(new LaborAssetHomeActions.FetchError(err));
      }),
    ),
  );
}
