export enum MessageAction {
  GET_HOME_INFORMATION = 1,
  CHECK_IN_LIST = 2,
  PAGE_RELOAD = 3,
  TOAST_MESSAGE = 4,
  MAINTENANCE = 5,
  SCENARIO = 6,
  GET_ACTIVITIES = 7,
  LINE_STATION_UPDATE = 8,
  SITE_CHECK_IN_LIST = 9,
  SITE_CARD_READ = 10,
  SITE_CARD_ASSIGNMENT = 11,
}
