import { Inject, Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Store } from '@ngrx/store';
import * as oeeAppReducer from '../../oee.reducer';
import {
  DurationVarianceType,
  IScheduleAdherenceData,
  IScheduleAdherenceFilters,
  IScheduleAdherenceResponseData,
  IScheduleAdherenceTableRow,
  IWoDurationsChartNode,
  IScheduleAdherenceJobData,
  IScheduleAdherenceJobResponseData,
  IScheduleAdherenceJobTableRow,
  jobsPlanType,
  WorkOrderRunType,
  ICommonScheduleAdherenceChartNode,
  ICommonNumberChart,
} from './schedule-adherence.model';
import { Observable, Subject } from 'rxjs';
import { ResponseInterface } from '../../../shared/model/interface/generic-api-response.model';
import { transformDurationType } from '../../../shared/helper/date';
import * as _ from 'lodash';
import { DatatableHeaderInterface } from '../../../shared/component/datatable/datatable.model';
import * as moment from 'moment';
import {
  CellTypes,
  CreateExcelInterface,
  CreateExcelSheetInterface,
  ExcelColumnDefinitionInterface,
  ExcelColumnWidthEnum,
  ExcelHelperService,
  ExcelSheetTypeEnum,
} from '../../../shared/service/excel/excel-helper.service';
import * as ObjectActions from '../schedule-adherence/schedule-adherence.actions';
import { ValueType } from 'exceljs';
import { TranslateService } from '@ngx-translate/core';
import { filter, takeUntil } from 'rxjs/operators';
import * as AppActions from '../../app/actions';
import { ITableHeader } from '../../../../constants.model';
import { ICreateExcel, IExcelColumnDefinition } from '../../../shared/service/excel/excel.helper';
import { ECellTypes, EExcelColumnWidth } from '../../../shared/service/excel/excel.enum';
import {
  IScheduleAdherenceExcelFormattedFilterData,
} from '../../../view/reports/schedule-adherence/schedule-adherence-table/schedule-adherence-table.model';

@Injectable({
  providedIn: 'root',
})
export class ScheduleAdherenceService {
  private readonly destroySubject: Subject<boolean> = new Subject<boolean>();
  private timezone: string = 'utc';
  private dateFormat$: string;
  private timeFormat$: string;

  constructor(
    public http: HttpClient,
    @Inject('API_BASE_URL') private readonly api: string,
    public store: Store<oeeAppReducer.OeeAppState>,
    private readonly translate: TranslateService,
    private readonly excelHelper: ExcelHelperService,
  ) {
    this.store
      .select('user')
      .pipe(takeUntil(this.destroySubject))
      .subscribe((state) => {
        if (state.isUserLoaded) {
          this.timezone = state.timezone;
          this.dateFormat$ = state.dateFormat;
          this.timeFormat$ = state.dateTimeFormat;

          this.destroySubject.next(true);
          this.destroySubject.complete();
        }
      });
  }

  private readonly routes = {
    schedulerAdherence: `${this.api}/work-orders/schedule-adherence`,
    schedulerAdherenceJobs: `${this.api}/work-orders/schedule-adherence-jobs`,
  };

  public getScheduleAdherenceData(
    params: IScheduleAdherenceFilters,
  ): Observable<ResponseInterface<IScheduleAdherenceResponseData[]>> {
    const httpParams: HttpParams = new HttpParams()
      .append('intervalStartDate', params.intervalStartDate)
      .append('intervalEndDate', params.intervalEndDate)
      .append('siteId', params.siteId)
      .append('lineIds', params.lineIds.toString())
      .append('includeAllActivities', params.includeAllActivities);

    return this.http.get<ResponseInterface<IScheduleAdherenceResponseData[]>>(`${this.routes.schedulerAdherence}`, {
      params: httpParams,
    });
  }

  public getScheduleAdherenceJobData(
    params: IScheduleAdherenceFilters,
  ): Observable<ResponseInterface<IScheduleAdherenceJobResponseData[]>> {
    const httpParams: HttpParams = new HttpParams()
      .append('intervalStartDate', params.intervalStartDate)
      .append('intervalEndDate', params.intervalEndDate)
      .append('siteId', params.siteId)
      .append('lineIds', params.lineIds.toString())
      .append('includeAllActivities', params.includeAllActivities);

    return this.http.get<ResponseInterface<IScheduleAdherenceJobResponseData[]>>(
      `${this.routes.schedulerAdherenceJobs}`,
      {
        params: httpParams,
      },
    );
  }

  public formatScheduleAdherenceData(
    responseData: IScheduleAdherenceResponseData[],
    isForJobData: boolean = false,
  ): IScheduleAdherenceData {
    let overdueWorkOrdersCount: number = 0;
    let behindWoScheduleWorkOrdersCount: number = 0;
    let plannedAndStartedWorkOrdersCount: number = 0;
    let plannedAndNotStartedWorkOrdersCount: number = 0;
    let unplannedAndStartedWorkOrdersCount: number = 0;

    let scheduleComplianceCount: number = 0;
    let scheduleComplianceTotal: number = 0;

    let onTimeCompletionCounts: number = 0;

    const woDurationsChartNodes: IWoDurationsChartNode[] = [];
    const tableData: IScheduleAdherenceTableRow[] = [];
    const numberChartData: ICommonNumberChart[] = [];

    responseData.map((data: IScheduleAdherenceResponseData) => {
      if (data.overdue) {
        overdueWorkOrdersCount++;
      }

      if (data.behindScheduleWorkOrder) {
        behindWoScheduleWorkOrdersCount++;
      }

      switch (data.runType) {
        case WorkOrderRunType.PLANNED_AND_STARTED:
          plannedAndStartedWorkOrdersCount++;
          break;
        case WorkOrderRunType.PLANNED_AND_NOT_STARTED:
          plannedAndNotStartedWorkOrdersCount++;
          break;
        case WorkOrderRunType.UNPLANNED_AND_STARTED:
          unplannedAndStartedWorkOrdersCount++;
          break;
      }

      if (data.isOnTimeCompletion) {
        onTimeCompletionCounts++;
      }

      if (!_.isNil(data.compliancePercentage)) {
        scheduleComplianceTotal += data.compliancePercentage;
        scheduleComplianceCount++;
      }

      woDurationsChartNodes.push({
        title: data.woNumber,
        phases: {
          noPhase: {
            title: 'noPhase',
            actualDuration: this.durationSecondsToHour(data.actualNoPhaseDuration),
            scheduledDuration: 0,
          },
          preRun: {
            title: 'preRun',
            actualDuration: this.durationSecondsToHour(data.actualPreRunPhaseDuration),
            scheduledDuration: this.durationSecondsToHour(data.scheduledPreDuration),
          },
          run: {
            title: 'run',
            actualDuration: this.durationSecondsToHour(data.actualRunPhaseDuration),
            scheduledDuration: this.durationSecondsToHour(data.scheduledRunDuration),
          },
          postRun: {
            title: 'postRun',
            actualDuration: this.durationSecondsToHour(data.actualPostRunPhaseDuration),
            scheduledDuration: this.durationSecondsToHour(data.scheduledPostDuration),
          },
        },
      });

      tableData.push({
        actualLine: data.actualLineName ?? '',
        scheduledLine: data.scheduledLineName ?? '',
        workOrder: data.woNumber,
        scheduledPreRun: this.durationSecondsToHour(data.scheduledPreDuration).toString(),
        actualPreRun: this.durationSecondsToHour(data.actualPreRunPhaseDuration).toString(),
        varianceOfPreRun: this.durationSecondsToHour(data.varianceOfPreRunDuration).toString(),
        scheduledRun: this.durationSecondsToHour(data.scheduledRunDuration).toString(),
        actualRun: this.durationSecondsToHour(data.actualRunPhaseDuration).toString(),
        varianceOfRun: this.durationSecondsToHour(data.varianceOfRunDuration).toString(),
        scheduledPostRun: this.durationSecondsToHour(data.scheduledPostDuration).toString(),
        actualPostRun: this.durationSecondsToHour(data.actualPostRunPhaseDuration).toString(),
        varianceOfPostRun: this.durationSecondsToHour(data.varianceOfPostRunDuration).toString(),
        scheduledStartDate: data.scheduledRunDate,
        scheduledEndDate: data.scheduledEndDate,
        actualStartDate: data.actualMinStart,
        actualEndDate: data.actualMaxEnd,
        dueDate: data.woDueDate,
        varianceStatus: {
          varianceOfPreRun: this.getVarianceResult(data.varianceOfPreRunDuration),
          varianceOfRun: this.getVarianceResult(data.varianceOfRunDuration),
          varianceOfPostRun: this.getVarianceResult(data.varianceOfPostRunDuration),
        },
        isOverdue: data.overdue,
        goodCount: data.totalGoodCount,
        scrapCount: data.totalScrapCount,
        quantityOrdered: data.quantityOrdered,
        workOrderId: data.workOrderId,
        scheduledLinePathName: isForJobData ? data.scheduledLinePathName ?? '-' : '',
        assignedLinePathName: isForJobData ? data.assignedLinePathName ?? '-' : '',
        assignedLineWithinLinePathName: isForJobData ? data.assignedLineWithinLinePathName ?? '-' : '',
        productName: data.productName,
        productDescription: data.productDescription,
        productFamilyName: data.productFamilyName,
      });

      numberChartData.push({
        scheduledStart: data.scheduledRunDate,
        scheduledEnd: data.scheduledEndDate,
        actualStart: data.actualMinStart,
        actualEnd: data.actualMaxEnd,
      });
    });

    let scheduleAdherence: number = -1;
    let scheduleAttainment: number = -1;
    let scheduleCompliance: number = -1;
    const plannedWorkOrderCounts: number = plannedAndStartedWorkOrdersCount + plannedAndNotStartedWorkOrdersCount;
    const startedWorkOrderCounts: number = plannedAndStartedWorkOrdersCount + unplannedAndStartedWorkOrdersCount;

    if (plannedWorkOrderCounts !== 0) {
      scheduleAttainment = (startedWorkOrderCounts / plannedWorkOrderCounts) * 100;
      scheduleAdherence = (plannedAndStartedWorkOrdersCount / plannedWorkOrderCounts) * 100;
    }

    const workOrderOnTimeCompletionRatio: number =
      responseData.length === 0
        ? -1
        : plannedWorkOrderCounts !== 0
          ? (onTimeCompletionCounts / plannedWorkOrderCounts) * 100
          : 0;

    if (scheduleComplianceCount !== 0) {
      scheduleCompliance = (scheduleComplianceTotal / scheduleComplianceCount);
    }

    return {
      kpiCardsData: {
        overDue: overdueWorkOrdersCount,
        behindSchedule: behindWoScheduleWorkOrdersCount,
        scheduleAdherence: scheduleAdherence,
        scheduleAttainment: scheduleAttainment,
        scheduleCompliance: scheduleCompliance,
        onTimeCompletion: workOrderOnTimeCompletionRatio,
        plannedAndStarted: plannedAndStartedWorkOrdersCount,
        unplannedAndStarted: unplannedAndStartedWorkOrdersCount,
        plannedAndNotStarted: plannedAndNotStartedWorkOrdersCount,
      },
      chartNodes: woDurationsChartNodes,
      tableData: tableData,
      rawData: responseData,
      numberChartData: numberChartData,
    };
  }

  public formatScheduleAdherenceJobData(responseData: IScheduleAdherenceJobResponseData[]): IScheduleAdherenceJobData {
    let overdueJobsCount: number = 0;
    let behindScheduleJobCount: number = 0;
    let plannedAndStartedJobsCount: number = 0;
    let plannedAndNotStartedJobsCount: number = 0;
    let unplannedAndStartedJobsCount: number = 0;

    let onTimeCompletionCounts: number = 0;

    const jobDurationsChartNodes: ICommonScheduleAdherenceChartNode[] = [];
    const tableData: IScheduleAdherenceJobTableRow[] = [];
    const numberChartData: ICommonNumberChart[] = [];

    responseData.map((data: IScheduleAdherenceJobResponseData): void => {
      if (data.jobOverdue) {
        overdueJobsCount++;
      }

      if (data.jobBehindSchedule) {
        behindScheduleJobCount++;
      }

      switch (data.jobsPlanType) {
        case jobsPlanType.PLANNED_AND_STARTED:
          plannedAndStartedJobsCount++;
          break;
        case jobsPlanType.PLANNED_AND_NOT_STARTED:
          plannedAndNotStartedJobsCount++;
          break;
        case jobsPlanType.UNPLANNED_AND_STARTED:
          unplannedAndStartedJobsCount++;
          break;
        default:
          break;
      }

      if (data.jobIsOnTimeCompletion) {
        onTimeCompletionCounts++;
      }

      jobDurationsChartNodes.push({
        title: data.jobName,
        phases: {
          noPhase: {
            title: 'noPhase',
            actualDuration: this.durationSecondsToHour(data.jobActualNoPhaseDuration),
            scheduledDuration: 0,
          },
          preRun: {
            title: 'preRun',
            actualDuration: this.durationSecondsToHour(data.jobActualPreRunDuration),
            scheduledDuration: this.durationSecondsToHour(data.jobScheduledPreRunDuration),
          },
          run: {
            title: 'run',
            actualDuration: this.durationSecondsToHour(data.jobActualRunDuration),
            scheduledDuration: this.durationSecondsToHour(data.jobScheduledRunDuration),
          },
          postRun: {
            title: 'postRun',
            actualDuration: this.durationSecondsToHour(data.jobActualPostDuration),
            scheduledDuration: this.durationSecondsToHour(data.jobScheduledPostDuration),
          },
        },
      });

      tableData.push({
        jobName: data.jobName ?? '',
        scheduledPreRun: this.durationSecondsToHour(data.jobScheduledPreRunDuration).toString(),
        actualPreRun: this.durationSecondsToHour(data.jobActualPreRunDuration).toString(),
        varianceOfPreRun: this.durationSecondsToHour(data.jobVarianceOfPreRunDuration).toString(),
        scheduledRun: this.durationSecondsToHour(data.jobScheduledRunDuration).toString(),
        actualRun: this.durationSecondsToHour(data.jobActualRunDuration).toString(),
        varianceOfRun: this.durationSecondsToHour(data.jobVarianceOfRunDuration).toString(),
        scheduledPostRun: this.durationSecondsToHour(data.jobScheduledPostDuration).toString(),
        actualPostRun: this.durationSecondsToHour(data.jobActualPostDuration).toString(),
        varianceOfPostRun: this.durationSecondsToHour(data.jobVarianceOfPostRunDuration).toString(),
        scheduledStartDate: data.jobScheduledStartDate,
        scheduledEndDate: data.jobScheduledEndDate,
        actualStartDate: data.jobActualStartTime,
        actualEndDate: data.jobActualEndTime,
        dueDate: data.jobDueDate,
        workOrdersTableData: this.formatScheduleAdherenceData(data.workOrders, true),
        varianceStatus: {
          varianceOfPreRun: this.getVarianceResult(data.jobVarianceOfPreRunDuration),
          varianceOfRun: this.getVarianceResult(data.jobVarianceOfRunDuration),
          varianceOfPostRun: this.getVarianceResult(data.jobVarianceOfPostRunDuration),
        },
        isOverdue: data.jobOverdue,
        jobCoverage: data.jobCoverage
          ? this.translate.instant('scheduleAdherence.table.value.partial')
          : this.translate.instant('scheduleAdherence.table.value.complete'),
        jobId: data.jobId,
      });

      numberChartData.push({
        scheduledStart: data.jobScheduledStartDate,
        scheduledEnd: data.jobScheduledEndDate,
        actualStart: data.jobActualStartTime,
        actualEnd: data.jobActualEndTime,
      });
    });

    let scheduleAdherence: number = -1;
    let scheduleAttainment: number = -1;
    const plannedJobsCounts: number = plannedAndStartedJobsCount + plannedAndNotStartedJobsCount;
    const startedWorkOrderCounts: number = plannedAndStartedJobsCount + unplannedAndStartedJobsCount;

    if (plannedJobsCounts !== 0) {
      scheduleAttainment = (startedWorkOrderCounts / plannedJobsCounts) * 100;
      scheduleAdherence = (plannedAndStartedJobsCount / plannedJobsCounts) * 100;
    }

    const jobOnTimeCompletionRatio: number =
      responseData.length === 0 ? -1 : plannedJobsCounts !== 0 ? (onTimeCompletionCounts / plannedJobsCounts) * 100 : 0;

    return {
      kpiCardsData: {
        overDue: overdueJobsCount,
        behindSchedule: behindScheduleJobCount,
        scheduleCompliance: -1,
        scheduleAdherence: scheduleAdherence,
        scheduleAttainment: scheduleAttainment,
        onTimeCompletion: jobOnTimeCompletionRatio,
        plannedAndStarted: plannedAndStartedJobsCount,
        unplannedAndStarted: unplannedAndStartedJobsCount,
        plannedAndNotStarted: plannedAndNotStartedJobsCount,
      },
      chartNodes: jobDurationsChartNodes,
      tableData: tableData,
      rawData: responseData,
      numberChartData: numberChartData,
    };
  }

  public downloadExcel(data: IScheduleAdherenceTableRow[], headers: DatatableHeaderInterface[]): void {
    this.store.dispatch(new AppActions.ShowLoader());

    const sheetTitle: string = this.translate.instant('excel.items.scheduleAdherenceExcel');
    const excelName: string = `${sheetTitle} ${moment().tz(this.timezone).format(this.dateFormat$)}`;

    const excelTemplateFormatOptions: CreateExcelInterface = {
      data,
      columns: this.getExcelColumnsFromTableHeaders(headers),
    };
    const excelDataAnalysisFormatOptions: CreateExcelInterface = {
      data,
      columns: this.getExcelColumnsFromTableHeaders(headers, true),
    };

    const worksheets: CreateExcelSheetInterface[] = [
      {
        sheetTitle: this.translate.instant('activityLogs.excel.readme.worksheetName'),
        sheetType: ExcelSheetTypeEnum.README,
      },
      {
        sheetTitle: this.translate.instant('activityLogs.excel.readme.templateFormatTitle'),
        sheetType: ExcelSheetTypeEnum.TABLE,
        params: excelTemplateFormatOptions,
        withData: true,
        addDateTimeFormula: undefined,
      },
      {
        sheetTitle: this.translate.instant('activityLogs.excel.readme.dataAnalysisFormatTitle'),
        sheetType: ExcelSheetTypeEnum.TABLE,
        params: excelDataAnalysisFormatOptions,
        withData: true,
        addDateTimeFormula: undefined,
      },
    ];

    this.excelHelper
      .createExcel(
        excelName,
        {
          name: 'scheduleAdherence',
          withData: true,
        },
        worksheets,
        this.timezone,
        this.dateFormat$,
        this.timeFormat$,
      ).then(
      () => {
        this.store.dispatch(new ObjectActions.ScheduleAdherenceDownloadExcelCompleted());
        this.store.dispatch(new AppActions.HideLoader());
      },
      () => {
        this.store.dispatch(new ObjectActions.ScheduleAdherenceFetchError({}));
        this.store.dispatch(new AppActions.HideLoader());
      },
    );
  }

  private getExcelColumnsFromTableHeaders(
    headers: DatatableHeaderInterface[],
    isDataAnalysisFormat: boolean = false,
  ): ExcelColumnDefinitionInterface[] {
    return headers.reduce((excelColumns: any[], column: DatatableHeaderInterface) => {
      excelColumns.push({
        header: column.name,
        key: column.value,
        width: ExcelColumnWidthEnum.DEFAULT,
        type: ValueType.String,
        style: { numFmt: '@' },
        dataValidation: {
          type: CellTypes.CUSTOM,
          formulae: [],
          showErrorMessage: false,
          showInputMessage: false,
        },
        ...(isDataAnalysisFormat ? this.excelHelper.getExcelColumnInfo(column.value, { decimalFields: [] }) : {}),
      });
      return excelColumns;
    }, []);
  }

  private getVarianceResult(duration: number): DurationVarianceType {
    const value = this.durationSecondsToHour(duration);

    if (value > 0) {
      return DurationVarianceType.POSITIVE;
    } else if (value < 0) {
      return DurationVarianceType.NEGATIVE;
    }

    return DurationVarianceType.ZERO;
  }

  private durationSecondsToHour(duration: number): number {
    return transformDurationType(duration, 'seconds', 'hours');
  }

  public downloadJobExcel(
    filterData: IScheduleAdherenceExcelFormattedFilterData,
    jobData: IScheduleAdherenceJobTableRow[],
    jobHeaders: ITableHeader[],
    workOrdersTableData: IScheduleAdherenceTableRow[],
    workOrderHeaders: ITableHeader[],
  ): void {
    this.store.dispatch(new AppActions.ShowLoader());
    const pageTitle: string = this.translate.instant('excel.items.scheduleAdherenceExcel');
    const excelName: string = `${pageTitle} ${moment().tz(this.timezone).format(this.dateFormat$)}`;

    const filterExcelOptions: ICreateExcel = {
      data: [filterData],
      columns: this.getFiltersColumnsForExcel(),
    };

    const jobExcelOptions: ICreateExcel = {
      data: jobData,
      columns: this.getExcelColumnsFromTableHeaders(jobHeaders),
    };

    const workOrdersExcelOptions: ICreateExcel = {
      data: workOrdersTableData,
      columns: this.getExcelColumnsFromTableHeaders(workOrderHeaders),
    };

    const worksheets: CreateExcelSheetInterface[] = [
      {
        sheetTitle: this.translate.instant('scheduleAdherence.excel.sheetName.filters'),
        withData: true,
        sheetType: ExcelSheetTypeEnum.TABLE,
        params: filterExcelOptions,
      },
      {
        sheetTitle: this.translate.instant('scheduleAdherence.excel.sheetName.jobs'),
        withData: true,
        sheetType: ExcelSheetTypeEnum.TABLE,
        params: jobExcelOptions,
      },
      {
        sheetTitle: this.translate.instant('scheduleAdherence.excel.sheetName.workOrders'),
        withData: true,
        sheetType: ExcelSheetTypeEnum.TABLE,
        params: workOrdersExcelOptions,
      },
    ];

    this.excelHelper
      .createExcel(
        excelName,
        {
          name: 'scheduleAdherenceJob',
          withData: true,
        },
        worksheets,
        this.timezone,
        this.dateFormat$,
        this.timeFormat$,
      ).then(
      () => {
        this.store.dispatch(new ObjectActions.ScheduleAdherenceJobDownloadExcelCompleted());
        this.store.dispatch(new AppActions.HideLoader());
      },
      () => {
        this.store.dispatch(new ObjectActions.ScheduleAdherenceFetchError({}));
        this.store.dispatch(new AppActions.HideLoader());
      },
    );
  }

  private getFiltersColumnsForExcel(): IExcelColumnDefinition[] {
    return [
      {
        header: this.translate.instant('waterfallAnalysis.excel.startDate.header'),
        key: 'startDate',
        width: EExcelColumnWidth.DEFAULT,
        type: ValueType.String,
        style: { numFmt: '@' },
        dataValidation: {
          type: ECellTypes.DATE,
        },
      },
      {
        header: this.translate.instant('waterfallAnalysis.excel.endDate.header'),
        key: 'endDate',
        width: EExcelColumnWidth.DEFAULT,
        type: ValueType.String,
        style: { numFmt: '@' },
        dataValidation: {
          type: ECellTypes.DATE,
        },
      },
      {
        header: this.translate.instant('waterfallAnalysis.excel.siteName.header'),
        key: 'siteName',
        width: EExcelColumnWidth.DEFAULT,
        type: ValueType.String,
        style: { numFmt: '@' },
        dataValidation: null,
      },
      {
        header: this.translate.instant('waterfallAnalysis.excel.lineNames.header'),
        key: 'lineNames',
        width: EExcelColumnWidth.DEFAULT,
        type: ValueType.String,
        style: { numFmt: '@' },
        dataValidation: null,
      },
    ];
  }
}
