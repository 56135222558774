import { DatatableInterface } from '../../../../shared/service/datatable/datatable.model';
import { ScwMatInputRule } from '../../../../shared/component/scw-mat-ui/scw-mat-input/scw-mat-input.model';
import { ScwMatSelectRule } from '../../../../shared/component/scw-mat-ui/scw-mat-select/scw-mat-select.model';
import { ActivityTypes } from '../../../../shared/model/enum/activity-types';
import { IDepartment } from '../departments/departments.model';
import { BaseTimestampInterface } from '../../../../shared/model/interface/crud-response-interface.model';
import { IColorPaletteOption } from '../../../../shared/service/color/color.model';
import { IPoint } from '@swimlane/ngx-charts/lib/models/coordinates.model';
import { IStationCountData } from '../../../global-view/site-view/line-view/line-view.model';
import { IProductStationPathAssignment } from '../../../../view/settings/product-configurations/station-product-configurations/product-configurations.model';

export interface FloorPlanStateInterface {
  isFloorPlanDataLoading: boolean;
  isFloorPlanDataLoaded: boolean;
  isSiteDataLoading: boolean;
  isSiteDataLoaded: boolean;
  isLineDataLoading: boolean;
  isLineDataLoaded: boolean;
  isStationDataLoading: boolean;
  isStationDataLoaded: boolean;
  isStationPathDataLoading: boolean;
  isStationPathDataLoaded: boolean;
  isLinePathDataLoading: boolean;
  isLinePathDataLoaded: boolean;
  isFloorPlanImageLoading: boolean;
  isFloorPlanImageLoaded: boolean;
  isCreateFloorPlanStarted: boolean;
  isCreateFloorPlanCompleted: boolean;
  isUpdateFloorPlanStarted: boolean;
  isUpdateFloorPlanCompleted: boolean;
  isDeleteFloorPlanStarted: boolean;
  isDeleteFloorPlanCompleted: boolean;
  isBulkDeleteFloorPlansStarted: boolean;
  isBulkDeleteFloorPlansCompleted: boolean;
  siteData: SiteInterface[];
  lineData: LineInterface[];
  floorPlanData: FloorPlanInterface[];
  stationData: StationInterface[];
  stationPathData?: IFloorPlanWithPathConfiguration;
}

export interface FloorPlanInterface extends BaseTimestampInterface {
  id?: number;
  name: string;
  siteName?: string;
  siteId: number;
  site?: {
    id: number;
    name: string;
  };
  categoryForId: number;
  lineId?: number;
  configuration: ILineViewConfiguration;
  imagePath?: string;
  image?: string;
  line?: IFloorPlanLine;
  lineName?: string;
  planFor?: string;
  floorPlanLines?: IFloorPlanLineEntity[];
}

export interface IFloorPlanLineEntity {
  id: number;
  floorPlanId: number;
  lineId: number;
}

export interface IFloorPlanLine {
  id: number;
  name: string;
  title?: string;
}

export enum EFloorPlanCategoryForId {
  FLOOR_PLAN_FOR_SITE = 1,
  FLOOR_PLAN_FOR_LINE = 2,
}

export enum FloorPlanModalTypesEnum {
  CREATE_FLOOR_PLAN_MODAL = 'create_floor_plan_modal',
  EDIT_FLOOR_PLAN_MODAL = 'edit_floor_plan_modal',
  DELETE_FLOOR_PLAN_MODAL = 'delete_floor_plan_modal',
  CROP_IMAGE_MODAL = 'crop_image_modal',
  PREVIEW_FLOOR_PLAN_MODAL = 'preview_floor_plan_modal',
}

export interface FloorPlanConfigurationInterface {
  itemId: number;
  itemName: string;
  xOffset: number;
  yOffset: number;
  points: PointInterface[];
  activityType?: ActivityTypes;
  isUsedInProduction?: boolean;
}

export interface FloorPlanFormInterface {
  floorPlanName: {
    isEnabled: boolean;
    value: string;
    rules?: ScwMatInputRule[];
  };
  floorPlanCategory: {
    isEnabled: boolean;
    value: DropdownOptionsInterface[];
    rules?: ScwMatSelectRule[];
  };
  site: {
    isEnabled: boolean;
    value: { id: number; name: string }[];
    rules?: ScwMatSelectRule[];
  };
  image: {
    value: string;
    rules?: ScwMatInputRule[];
  };
  line: {
    isEnabled: boolean;
    value: { id: number; name: string }[];
    rules?: ScwMatSelectRule[];
  };
  pathName?: {
    isEnabled: boolean;
    value: string;
    rules?: ScwMatInputRule[];
  };
}

export interface PointInterface {
  x: number;
  y: number;
}

export interface SiteInterface {
  id: number;
  name: string;
  lines: LineInterface[];
}

export interface LineInterface {
  id: number;
  title: string;
  lineType: number;
  colorSetting?: IColorPaletteOption;
  position?: IPoint;
}

export interface DropdownOptionsInterface {
  id: number;
  name: string;
}

export interface Point {
  x: number;
  y: number;
}

export enum ZoomEvents {
  ZOOM_IN = 'zoomIn',
  ZOOM_OUT = 'zoomOut',
  SAVE = 'save',
}

export interface PointDistanceData extends Point {
  distance: number;
}

export interface PointDistanceDataWithIndex extends PointDistanceData {
  pointIndex: number;
}

export interface ITableQuery extends DatatableInterface {
  siteIds?: number[];
  floorPlanId?: string;
  lineIds?: number[];
}

export interface PlanItemFieldGElementDatumInterface {
  itemId: number;
  itemName: string;
  x: number;
  y: number;
  bbox?: DOMRect;
}

export interface FloorPlanBulkResponseInterface {
  success: boolean;
  error?: any;
}

export enum MouseButtonTypeEnum {
  leftClick = 0,
  middleClick = 1,
  rightClick = 2,
}

export interface DepartmentWithNameInterface extends IDepartment {
  name: string;
}

export interface StationInterface {
  id: number;
  name: string;
  colorSetting?: IColorPaletteOption;
  position: IPoint;
  defaultPosition?: IPoint;
  dragFlag?: boolean;
  isNotDraggable: boolean;
  activityType?: ActivityTypes;
  isUsedInProduction?: boolean;
  countData?: IStationCountData;
  activityColor?: string;
}

export interface ISideListItem {
  id: number;
  name: string;
  colorSetting?: IColorPaletteOption;
  position: IPoint;
  defaultPosition?: IPoint;
  dragFlag?: boolean;
  isNotDraggable: boolean;
  activityType?: ActivityTypes;
  isUsedInProduction?: boolean;
  countData?: IStationCountData;
  activityColor?: string;
  lineType?: number;
}

export interface SideListInterface {
  id: number;
  name: string;
  colorSetting?: IColorPaletteOption;
  position: IPoint;
  dragFlag?: boolean;
  isNotDraggable: boolean;
}

export enum ELinePlanViewTypeEnum {
  cardView = 0,
  mapView = 1,
}

export interface IArrowPosition {
  pathNodeId?: string;
  startX: number;
  startY: number;
  endX: number;
  endY: number;
  arrowPosition?: number;
}

export interface IDrawArrowProperties {
  startBoxId: string;
  endBoxId: string;
}

export interface IPositionOutside {
  x1: boolean;
  x2: boolean;
  y1: boolean;
  y2: boolean;
}

export enum ECursorType {
  GRAB = 'grab',
  GRABBING = 'grabbing',
  POINTER = 'pointer',
}

export interface IPositionElement {
  top?: number;
  bottom?: number;
  right?: number;
  left?: number;
}

export interface IStaticToleranceValues {
  zoomChange: IArrowPosition;
  defaultChange: IArrowPosition;
  mouseChange: IArrowPosition;
  dashboardChange: IArrowPosition;
}

export const staticToleranceValues: IStaticToleranceValues = {
  zoomChange: { startX: 37, startY: 55, endX: 35, endY: 55 },
  dashboardChange: { startX: 37, startY: 35, endX: 35, endY: 35 },
  defaultChange: { startX: -130, startY: -179, endX: -30, endY: -279 },
  mouseChange: { startX: -130, startY: -179, endX: -78, endY: -227 },
};

export interface ICardViewConfiguration {
  zoomLevel: number;
  screenSize: number;
  boxPositions: IBoxPositionsWithId[];
  drawArrowIds: IDrawArrowProperties[];
  pathConfiguration: IStationPathConfiguration[] | ILinePathConfiguration[];
}

export interface IBoxPositionsWithId {
  position: IPoint;
  boxId: number;
}

export type TStationPathSettings = IBasePathSettings & IStationPathConfiguration;
export type TLinePathSettings = IBasePathSettings & ILinePathConfiguration;
export type TLineAndStationPathSettings = TLinePathSettings & TStationPathSettings;

export interface IBasePathSettings {
  isSelected: boolean;
  isDuplicate: boolean;
  colorSettings: IColorPaletteOption;
  errorSettings: IPathErrorSettings;
}

export interface IFloorPlanWithPathConfiguration {
  floorPlan: FloorPlanInterface;
  stationPaths: IStationPathConfiguration[];
  linePaths: ILinePathConfiguration[];
}

export interface IBasePathConfiguration {
  id: number | null;
  name: string;
  isDefaultPath: boolean;
  selectedNodeIds?: number[];
}

export interface IStationPathConfiguration extends IBasePathConfiguration {
  stationPathOrderDetails?: IStationPathOrderDetail[];
  lineProductStationPathAssignments?: IProductStationPathAssignment[];
  initialCountStations?: DropdownOptionsInterface[];
  goodCountStations?: DropdownOptionsInterface[];
}

export interface ILinePathConfiguration extends IBasePathConfiguration {
  initialCountLineIds?: number[];
  goodCountLineIds?: number[];
  initialCountLines?: DropdownOptionsInterface[];
  goodCountLines?: DropdownOptionsInterface[];
  linePathOrderDetails?: ILinePathOrderDetail[];
  linePathProductConfigurations?: IProductStationPathAssignment[];
}

export interface IStationPathOrderDetail {
  stationId: number;
  isInitialCounter: boolean;
  isMidCounter: boolean;
  isGoodCounter: boolean;
}

export interface ILinePathOrderDetail {
  lineId: number;
  locationType: ELinePathOrderDetailLocationType;
  order: number;
  siteId?: number;
  pathId?: number;
}

export interface IPathErrorSettings {
  pathNameHasError: boolean;
  selectedPathNodeHasError: boolean;
  selectedPathJobInitialCountHasError?: boolean;
  selectedPathJobGoodCountHasError?: boolean;
  selectedPathWorkOrderInitialCountHasError?: boolean;
  selectedPathWorkOrderGoodCountHasError?: boolean;
}

export interface ILineViewConfiguration {
  mapViewConfiguration: FloorPlanConfigurationInterface[];
  cardViewConfiguration: ICardViewConfiguration;
}

export enum ECardViewModalType {
  REMOVE_PATH_NODES = 'removePathNodes',
  DISCARD_CHANGES = 'discardChanges',
}

export enum ECardViewDashboardFontSizes {
  value = 7,
  valueName = 5.75,
  name = 8.25,
}

export enum ELinePathOrderDetailLocationType {
  START = 'start',
  MIDDLE = 'middle',
  END = 'end',
}
