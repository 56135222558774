import { FilterOpcTagActions, FilterOpcTagActionTypes } from './opc-tag.actions';
import { IOpcTagList } from '../../../shared/component/filter/filter.class';

export interface FilterOpcTagState {
  isLoading: boolean;
  isLoaded: boolean;
  data: IOpcTagList[];
}

export const initialFilterOpcTagState: FilterOpcTagState = {
  isLoaded: false,
  isLoading: false,
  data: [],
};

export function filterOpcTagReducer(
  state: FilterOpcTagState = initialFilterOpcTagState,
  action: FilterOpcTagActions,
): FilterOpcTagState {
  switch (action.type) {
    case FilterOpcTagActionTypes.StartLoadFilterOpcTags:
      return { ...state, isLoading: true };
    case FilterOpcTagActionTypes.FilterOpcTagsLoaded:
      return { ...state, data: action.data, isLoaded: true, isLoading: false };
    case FilterOpcTagActionTypes.ResetOpcTagStore:
      return { ...state, data: [], isLoaded: false, isLoading: false };
    default:
      return state;
  }
}
