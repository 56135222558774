import { inject, Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { Store } from '@ngrx/store';
import { OeeAppState } from '../../store/oee.reducer';
import { filter, take, takeWhile } from 'rxjs/operators';
import { isNil } from 'lodash';
import { MainStateInterface, MenuInterface } from '../../store/main/main.model';
import { lastNavigationInformation } from '../../../constants';
import { IGenericObject } from '../model/interface/generic.model';

@Injectable({
  providedIn: 'root',
})
export class RouteGuard implements CanActivate {
  constructor(private readonly store: Store<OeeAppState>, public router: Router) {}
  public static canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return inject(RouteGuard).canActivate(route, state);
  }
  public canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    if (!(isNil(route.routeConfig?.redirectTo) && RouteGuard.checkIfRouteConfigIsLeaf(route))) {
      return true;
    }

    return new Observable<boolean | UrlTree>((observer) => {
      this.store
        .select('mainStore')
        .pipe(
          filter(
            ({ getNavigationMenuLoaded, getActiveSitesLoaded }) => getNavigationMenuLoaded && getActiveSitesLoaded,
          ),
          take(1),
        )
        .subscribe((mainStoreState) => {
          const menuItemFromActivatedRoute = RouteGuard.getMenuItemFromActivatedRoute(route, mainStoreState);
          const menuItem = menuItemFromActivatedRoute.menuItem ?? menuItemFromActivatedRoute.menuItemFallback;

          if (
            menuItem &&
            this.checkAccessToParentMenuItemsRecursively(
              (menuItemFromActivatedRoute.menuItem ?? menuItemFromActivatedRoute.menuItemFallback)?.id,
              mainStoreState.menuItemHash,
            ) &&
            !menuItem.isUpgradeBadgeEnabled
          ) {
            observer.next(true);
          } else if (menuItem && menuItem.isUpgradeBadgeEnabled) {
            observer.next(this.router.createUrlTree(['/book-a-demo'], { queryParams: { module: menuItem.name } }));
          } else {
            observer.next(this.router.createUrlTree(['/error/forbidden']));
          }

          observer.complete();
        });
    });
  }

  private checkAccessToParentMenuItemsRecursively(menuItemId: number, menuItemHash: IGenericObject<MenuInterface>) {
    const menuItem = menuItemHash[menuItemId];

    if (!menuItem) {
      return false;
    }

    if (menuItem?.parentId) {
      return this.checkAccessToParentMenuItemsRecursively(menuItem.parentId, menuItemHash);
    }

    return true;
  }
  private static checkIfRouteConfigIsLeaf(route: ActivatedRouteSnapshot) {
    return Boolean(
      !isNil(route.routeConfig?.path) &&
        isNil(route.firstChild) &&
        (route.routeConfig?.component || route.routeConfig?.loadComponent),
    );
  }

  private static getFinalRouteOfActivatedRouteRecursively(
    route: ActivatedRouteSnapshot,
  ): ActivatedRouteSnapshot | undefined {
    if (this.checkIfRouteConfigIsLeaf(route)) {
      return route;
    } else if (route.firstChild) {
      return this.getFinalRouteOfActivatedRouteRecursively(route.firstChild);
    } else {
      return undefined;
    }
  }

  public static getMenuItemFromActivatedRoute(route: ActivatedRouteSnapshot, state: MainStateInterface) {
    const finalRoute = this.getFinalRouteOfActivatedRouteRecursively(route);
    const fullPathFromRouteConfig = (finalRoute?.pathFromRoot ?? []).reduce(
      (total, routeFromRoot) => {
        if (routeFromRoot.routeConfig?.path) {
          total.urlFromConfig.push(routeFromRoot?.routeConfig?.path);
          total.urlFromRoute.push((routeFromRoot?.url ?? []).map((urlTree) => urlTree.path).join('/'));
        }

        return total;
      },
      {
        urlFromConfig: [],
        urlFromRoute: [],
      },
    );
    const configUrl = decodeURIComponent(fullPathFromRouteConfig.urlFromConfig.join('/'));
    const currentUrl = decodeURIComponent(fullPathFromRouteConfig.urlFromRoute.join('/'));

    let menuItem: MenuInterface | undefined;
    let menuItemFallback: MenuInterface | undefined;

    for (const stateMenuItem of state.menu.menus) {
      if (menuItem && menuItemFallback) {
        break;
      }

      const stateMenuLink = decodeURIComponent(stateMenuItem.link);

      if (!menuItem && (stateMenuLink === configUrl || stateMenuLink === currentUrl)) {
        menuItem = stateMenuItem;
      }

      if (!menuItemFallback && stateMenuItem.name === finalRoute.data?.['relatedModule']) {
        menuItemFallback = stateMenuItem;
      }
    }

    const result = { configUrl, currentUrl, menuItem, menuItemFallback, routeConfig: finalRoute.routeConfig?.data };

    lastNavigationInformation.next(result);

    return result;
  }
}
