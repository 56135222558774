import { Action } from '@ngrx/store';
import { HttpOptionInterface } from '../filter.model';
import { ApiServer } from '../../../shared/component/filter/dropdown/dropdown.model';
import { IIotDeviceType } from '../../../shared/service/iot-device-report/iot-device-report.model';

export enum FilterIotDeviceReportDeviceTypeActionTypes {
  StartLoadFilterIotDeviceReportDeviceTypes = '[Filter IotDeviceReportDeviceType] Load Filter IotDeviceReportDeviceTypes',
  FilterIotDeviceReportDeviceTypesLoaded = '[Filter IotDeviceReportDeviceType] Filter IotDeviceReportDeviceTypes Loaded',
}

export class StartLoadFilterIotDeviceReportDeviceTypes implements Action {
  readonly type = FilterIotDeviceReportDeviceTypeActionTypes.StartLoadFilterIotDeviceReportDeviceTypes;
  constructor(public options: HttpOptionInterface, public server: ApiServer = ApiServer.NestJS) {}
}

export class FilterIotDeviceReportDeviceTypesLoaded implements Action {
  readonly type = FilterIotDeviceReportDeviceTypeActionTypes.FilterIotDeviceReportDeviceTypesLoaded;
  constructor(public data: Partial<IIotDeviceType>[]) {}
}

export type FilterIotDeviceReportDeviceTypeActions =
  | StartLoadFilterIotDeviceReportDeviceTypes
  | FilterIotDeviceReportDeviceTypesLoaded;
