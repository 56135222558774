import {
  BaseCrudResponse,
  BaseOneResponseInterface,
  GetManyResponseInterface,
} from '../../model/interface/crud-response-interface.model';
import { Inject, Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { IIotDeviceReport, IIotDeviceReportInfo, IIotDeviceStatus, IIotDeviceType } from './iot-device-report.model';
import { IDeleteFileByFilePathParams } from '../../../store/file-upload/file-upload.model';

@Injectable({
  providedIn: 'root',
})
export class IotDeviceReportService {
  private readonly rolesBaseUrl = `${this.baseUrl}/iot-device-portal`;

  constructor(private readonly http: HttpClient, @Inject('API_BASE_URL') private readonly baseUrl: string) {}

  public getIotDeviceReportData(params: HttpParams): Observable<BaseOneResponseInterface<IIotDeviceReport>> {
    return this.http.get<BaseOneResponseInterface<IIotDeviceReport>>(`${this.rolesBaseUrl}/devices`, { params });
  }

  public getIotDeviceReportStatusData(params: HttpParams): Observable<BaseOneResponseInterface<IIotDeviceStatus[]>> {
    return this.http.get<BaseOneResponseInterface<IIotDeviceStatus[]>>(`${this.rolesBaseUrl}/device-statuses`, {
      params,
    });
  }

  public getIotDeviceReportTypeData(params: HttpParams): Observable<BaseOneResponseInterface<IIotDeviceType[]>> {
    return this.http.get<BaseOneResponseInterface<IIotDeviceType[]>>(`${this.rolesBaseUrl}/device-types`, {
      params,
    });
  }

  public getIotDeviceReportInfo(params: HttpParams): Observable<GetManyResponseInterface<IIotDeviceReportInfo>> {
    return this.http.get<GetManyResponseInterface<IIotDeviceReportInfo>>(this.rolesBaseUrl, { params });
  }

  public createIotDeviceReportInfo(payload: Partial<IIotDeviceReportInfo>) {
    return this.http.post<BaseOneResponseInterface<IIotDeviceReport>>(`${this.rolesBaseUrl}`, payload);
  }

  public updateIotDeviceReportInfo(id: number, payload: Partial<IIotDeviceReportInfo>) {
    return this.http.patch<BaseOneResponseInterface<IIotDeviceReport>>(`${this.rolesBaseUrl}/${id}`, payload);
  }

  public deleteIotDeviceReportInfo(id: number) {
    return this.http.delete<BaseCrudResponse>(`${this.rolesBaseUrl}/${id}`);
  }

  public deleteManyFileByFilePath(dto: IDeleteFileByFilePathParams): Observable<BaseOneResponseInterface<boolean>> {
    return this.http.delete<BaseOneResponseInterface<boolean>>(
      `${this.rolesBaseUrl}/delete/many-device-status-attachment-files-by-file-path`,
      {
        body: dto,
      },
    );
  }

  public getIotDeviceList(id: number) {
    return this.http.get<{ id: number; macAddress: string }[]>(`${this.rolesBaseUrl}/devices/${id}`);
  }
}
