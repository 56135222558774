import { PaginationInterface } from '../../shared/service/filter/service.class';
import { BaseTimestampInterface } from '../../shared/model/interface/crud-response-interface.model';
import { IMenuPermissions } from '../../../constants';
import { GetCurrentUserClientConfigurationDto } from '../app/model';
import { FloorPlanInterface } from '../settings/departments-lines-stations/floor-plans/floor-plans.model';

export interface SiteLineSelectionInterface {
  isSitesOfSiteLinesSelectionLoading: boolean;
  isLinesOfSiteLinesSelectionLoading: boolean;
  isLinesOfSiteLinesSelectionLoaded: boolean;
  isSitesOfSiteLinesSelectionLoaded: boolean;
  sites: SiteInterface[];
  linesAndFloorPlans: ILinesAndFloorPlans;
  errors: any[];
}

export interface GetManySiteCrudResponseInterface extends PaginationInterface {
  data: SiteInterface[];
}

export interface GetManyLineCrudResponseInterface extends PaginationInterface {
  data: LineInterface[];
}

export interface SiteInterface extends BaseTimestampInterface {
  id: number;
  name: string;
  contactPerson?: string;
  email?: string;
  address?: string;
  location?: string;
  web?: string;
  tel?: string;
  description?: string;
  menuPermissions?: IMenuPermissions;
  configuration?: GetCurrentUserClientConfigurationDto;
  weekStartDay?: number;
}

export interface LineTypeInterface extends BaseTimestampInterface {
  id: number;
  order: number;
  lineType: string;
  productionUnit?: number;
  description?: string;
  status: number;
}

export interface LineInterface extends BaseTimestampInterface {
  id: number;
  title: string;
  siteId: number;
  activityIds?: string;
  statusId: number;
  created?: string;
  description?: string;
  lineType: number;
  standardSpeed?: number;
  timer?: string;
  selectedActivity?: number;
  selectedTaskId?: number;
  selectedPhaseId?: number;
  selectedDescription?: string;
  activeUser?: number;
  selectedCrewSize?: string;
  scheduledHourMonday?: string;
  scheduledHourTuesday?: string;
  scheduledHourWednesday?: string;
  scheduledHourThursday?: string;
  scheduledHourFriday?: string;
  scheduledHourSaturday?: string;
  scheduledHourSunday?: string;
  snoozeUntil?: string;
  shiftPlanId?: number;
  lineTypeName: LineTypeInterface;
}

export interface SiteLineSelectionDataInterface extends LineTypeInterface {
  line: LineInterface[];
}

export interface ILinesAndFloorPlans {
  linesAndLineTypes: LineInterface[];
  floorPlans: FloorPlanInterface[];
}

export enum ESiteLineSelectionMode {
  LINES = 'lines',
  FLOOR_PLANS = 'floorPlans',
}
