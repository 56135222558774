export interface IFileUploadState {
  getFileLoading: boolean;
  getFileLoaded: boolean;
  getFilesByFolderIdLoading: boolean;
  getFilesByFolderIdLoaded: boolean;
  bulkUploadFileLoading: boolean;
  bulkUploadFileLoaded: boolean;
  deleteFileLoading: boolean;
  deleteFileLoaded: boolean;
  deleteFileByPathLoading: boolean;
  deleteFileByPathLoaded: boolean;
  deletedFile: IDeleteFileResponse;
  uploadedFile: IUploadFileResponse;
}

export interface IGetFileParams {
  folderId?: number;
  isThumbnail: number;
  entityName: string;
  siteId: number;
  fileId?: number;
}

export interface IGetFileResponse {
  id: number;
  filePath: string;
  base64Data: string;
  fileOriginalName?: string;
  folderId: number;
  type: EFileType[];
  original?: string;
  size?: number;
  fileType?: EFileType;
}

export interface IUploadFileParams {
  thumbnailFile?: string;
  entityName: string;
  siteId: number;
  files?: IFile[];
  folderId?: number;
}

export interface IUploadFileResponse {
  folderId: number;
  fileName: string;
}

export interface IDeleteFileParams {
  fileName: string;
  entityName: string;
  siteId: number;
}

export interface IFile {
  id?: number;
  original?: string;
  thumbnail?: string;
  type: EFileType;
  folderId?: number;
  filePath?: string;
  size?: number;
  base64Data?: string;
  fileOriginalName?: string;
}

export enum EFileType {
  IMAGE = 'image',
  PDF = 'pdf',
}

export interface IDeleteFileByFilePathParams {
  filePath: string;
}

export interface IDeleteFileResponse {
  success: boolean;
  deletedFilePath: string;
}
