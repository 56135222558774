<div class="bg-c-lightGrey metric-item d-flex flex-column h-100 p-2 activities-total-duration-metric">
  <div class="row h-100 m-0">
    <div class="col-12 d-flex justify-content-center">
      <div class="nowrap text-center">
        <p class="head-text-2 three-dot c-title m-b-0">{{ 'kpiCards.metrics.activities_total_duration' | translate }}</p>
        <br *ngIf="!unit" />
        <p *ngIf="unit" class="head-text-2 three-dot c-title m-b-0">
          ({{ 'homeScreenMetric.properties.unitOfTime.' + unit | translate }})
        </p>
      </div>
    </div>
    <div class="col-6 p-0" [ngClass]="clickable ? 'from-home' : 'from-settings'">
      <scw-mat-button
        [style.pointer-events]="clickable ? 'all' : 'none'"
        [className]="['w-100', 'h-100']"
        [colors]="colors.runTimeDuration"
        (click)="onClick(activities, 'runTime')"
        type="standard"
      >
        <i class="fas fa-running icon"></i>
        <p class="three-dot m-0 align-self-center">
          {{ workOrderId === null ? '--' : runTimeDuration }}
        </p>
      </scw-mat-button>
    </div>
    <div class="col-6 p-0" [ngClass]="clickable ? 'from-home' : 'from-settings'">
      <scw-mat-button
        [style.pointer-events]="clickable ? 'all' : 'none'"
        [className]="['w-100', 'h-100']"
        [colors]="colors.downTimeUnplannedDuration"
        (click)="onClick(activities, 'downTime')"
        type="standard"
      >
        <i class="fa fa-wrench icon"></i>
        <p class="three-dot m-0 align-self-center">
          {{ workOrderId === null ? '--' : downTimeUnplannedDuration }}
        </p>
      </scw-mat-button>
    </div>
    <div class="col-6 p-0" [ngClass]="clickable ? 'from-home' : 'from-settings'">
      <scw-mat-button
        [style.pointer-events]="clickable ? 'all' : 'none'"
        [className]="['w-100', 'h-100']"
        [colors]="colors.idleTimeDuration"
        (click)="onClick(activities, 'idleTime')"
        type="standard"
      >
        <i class="fas fa-pause-circle icon"></i>
        <p class="three-dot m-0 align-self-center">
          {{ workOrderId === null ? '--' : idleTimeDuration }}
        </p>
      </scw-mat-button>
    </div>
    <div class="col-6 p-0" [ngClass]="clickable ? 'from-home' : 'from-settings'">
      <scw-mat-button
        [style.pointer-events]="clickable ? 'all' : 'none'"
        [className]="['w-100', 'h-100']"
        [colors]="colors.downTimePlannedDuration"
        (click)="onClick(activities, 'downTimePlanned')"
        type="standard"
      >
        <i class="fa-calendar fas icon"></i>
        <p class="three-dot m-0 align-self-center">
          {{ workOrderId === null ? '--' : downTimePlannedDuration }}
        </p>
      </scw-mat-button>
    </div>
  </div>
</div>

<ng-template #activities let-modal>
  <div class="modal-header scw-modal-header">
    <h3 class="modal-title scw-header">
      {{ 'kpiCardInformation.activitiesTotalDuration.modal.title' | translate }}
    </h3>
    <button type="button" class="close" onfocus="blur()" aria-label="Close" (click)="modal.dismiss()">
      <span aria-hidden="true">
        <em class="fas fa-times"></em>
      </span>
    </button>
  </div>
  <div class="modal-body custom-body">
    <datatable
      noDataText="{{ 'datatable.noData' | translate }}"
      [serverSide]="true"
      [headers]="activitiesTotalDurationTableHeaders"
      [items]="activitiesTotalDurationData"
      [isLoading]="activitiesTotalDurationDataLoading"
      [itemsCount]="activitiesTotalDurationDataCount"
      [currentPage]="tableQuery.page"
      [rowsPerPage]="tableQuery.pageSize"
      (onDataRequest)="onDataRequestHandler($event)"
      heightMode="fill"
    >
      <ng-template let-item>
        <td
          class="nowrap"
          [ngClass]="item.activity.activityType !== 'idleTime' && item.workOrderId === null ? 'padding-unset' : ''"
        >
          <scw-mat-missing-data
            [text]="item.batchNumber"
            [isIdleActivity]="item.activity.activityType === 'idleTime'"
            [isWithoutWorkOrder]="item.withoutWorkOrder"
          ></scw-mat-missing-data>
        </td>
        <td class="nowrap" [ngClass]="item.activityId === null || item.activityId === '' ? 'padding-unset' : ''">
          <scw-mat-missing-data text="{{ item.activity?.name }}"></scw-mat-missing-data>
        </td>
        <td
          class="nowrap"
          [ngClass]="
            ((item.taskId === null || item.taskId === '' || item.task.isMissingData === 1) &&
              item.activity.activityType !== 'runTime') ||
            (!item.workOrder?.product?.description && item.activity.activityType === 'runTime')
              ? 'padding-unset'
              : ''
          "
        >
          <scw-mat-missing-data
            text="{{ item.taskName }}"
            [isMissingData]="item.task?.isMissingData"
          ></scw-mat-missing-data>
        </td>
        <td class="nowrap" [ngClass]="item.start === null || item.start === '' ? 'padding-unset' : ''">
          <scw-mat-missing-data text="{{ item.start | momentDate : datetimeFormatWithSecond$ }}"></scw-mat-missing-data>
        </td>
        <td class="nowrap">
          {{ item.duration }}
        </td>
      </ng-template>
    </datatable>
  </div>
</ng-template>
