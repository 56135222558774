import { Action } from '@ngrx/store';
import { DownloadExcelFiltersInterface } from '../../../../shared/service/excel/excel-helper.service';
import {
  ILinePathProductConfigurationExcel,
  ILinePathProductConfigurations,
} from './line-path-product-configuration.model';
import { GetManyResponseInterface } from '../../../../shared/model/interface/crud-response-interface.model';

export const DOWNLOAD_LINE_PATH_PRODUCT_CONFIGURATION_EXCEL =
  '[LINE PATH PRODUCT CONFIGURATION] DOWNLOAD LINE PATH PRODUCT CONFIGURATION EXCEL';
export const DOWNLOAD_LINE_PATH_PRODUCT_CONFIGURATION_EXCEL_COMPLETED =
  '[LINE PATH PRODUCT CONFIGURATION] DOWNLOAD LINE PATH PRODUCT CONFIGURATION EXCEL COMPLETED';
export const UPLOAD_LINE_PATH_PRODUCT_CONFIGURATION_EXCEL =
  '[LINE PATH PRODUCT CONFIGURATION] UPLOAD LINE PATH PRODUCT CONFIGURATION EXCEL';
export const UPLOAD_LINE_PATH_PRODUCT_CONFIGURATION_EXCEL_COMPLETED =
  '[LINE PATH PRODUCT CONFIGURATION] UPLOAD LINE PATH PRODUCT CONFIGURATION EXCEL COMPLETED';
export const LINE_PATH_PRODUCT_CONFIGURATION_COUNT_LOAD =
  '[LINE PATH PRODUCT CONFIGURATION] LINE PATH PRODUCT CONFIGURATION COUNT LOAD';
export const LINE_PATH_PRODUCT_CONFIGURATION_COUNT_LOADED =
  '[LINE PATH PRODUCT CONFIGURATION] LINE PATH PRODUCT CONFIGURATION COUNT LOADED';
export const FETCH_ERROR = '[LINE PATH] FETCH ERROR';

export class DownloadLinePathProductConfigurationExcel implements Action {
  readonly type = DOWNLOAD_LINE_PATH_PRODUCT_CONFIGURATION_EXCEL;
  constructor(public withData: boolean = false, public filters: DownloadExcelFiltersInterface) {}
}

export class DownloadLinePathProductConfigurationExcelCompleted implements Action {
  readonly type = DOWNLOAD_LINE_PATH_PRODUCT_CONFIGURATION_EXCEL_COMPLETED;
}

export class UploadLinePathProductConfigurationExcel implements Action {
  readonly type = UPLOAD_LINE_PATH_PRODUCT_CONFIGURATION_EXCEL;
  constructor(public linePathProductConfiguration: ILinePathProductConfigurationExcel) {}
}

export class UploadLinePathProductConfigurationExcelCompleted implements Action {
  readonly type = UPLOAD_LINE_PATH_PRODUCT_CONFIGURATION_EXCEL_COMPLETED;
  constructor(public payload: (ILinePathProductConfigurationExcel & { errorMessages?: string })[]) {}
}

export class LinePathProductConfigurationCountLoad implements Action {
  readonly type = LINE_PATH_PRODUCT_CONFIGURATION_COUNT_LOAD;

  constructor(public siteId: number, public limit?: number) {}
}

export class LinePathProductConfigurationCountLoaded implements Action {
  readonly type = LINE_PATH_PRODUCT_CONFIGURATION_COUNT_LOADED;

  constructor(public payload: GetManyResponseInterface<ILinePathProductConfigurations>) {}
}

export class FetchError implements Action {
  readonly type = FETCH_ERROR;
  constructor(public payload: object) {}
}

export type LinePathProductConfigurationActions =
  | DownloadLinePathProductConfigurationExcel
  | DownloadLinePathProductConfigurationExcelCompleted
  | UploadLinePathProductConfigurationExcel
  | UploadLinePathProductConfigurationExcelCompleted
  | LinePathProductConfigurationCountLoad
  | LinePathProductConfigurationCountLoaded
  | FetchError;
