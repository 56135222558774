import * as Actions from './file-upload.actions';
import { IFileUploadState } from './file-upload.model';

export const fileUploadInitialState: IFileUploadState = {
  getFileLoading: false,
  getFileLoaded: false,
  getFilesByFolderIdLoading: false,
  getFilesByFolderIdLoaded: false,
  deleteFileLoading: false,
  deleteFileLoaded: false,
  deletedFile: null,
  uploadedFile: null,
  bulkUploadFileLoading: false,
  bulkUploadFileLoaded: false,
  deleteFileByPathLoaded: false,
  deleteFileByPathLoading: false,
};

export function fileUploadReducer(
  state: IFileUploadState = fileUploadInitialState,
  action: Actions.FileUploadActions,
): IFileUploadState {
  switch (action.type) {
    case Actions.GET_FILE_LOADING:
      return {
        ...state,
        getFileLoading: true,
        getFileLoaded: false,
      };
    case Actions.GET_FILE_LOADED:
      return {
        ...state,
        getFileLoading: false,
        getFileLoaded: true,
      };
    case Actions.GET_FILES_BY_FOLDER_ID_LOADING:
      return {
        ...state,
        getFilesByFolderIdLoading: true,
        getFilesByFolderIdLoaded: false,
      };
    case Actions.GET_FILES_BY_FOLDER_ID_LOADED:
      return {
        ...state,
        getFilesByFolderIdLoading: false,
        getFilesByFolderIdLoaded: true,
      };
    case Actions.BULK_UPLOAD_FILE_LOADING:
      return {
        ...state,
        bulkUploadFileLoading: true,
        bulkUploadFileLoaded: false,
      };
    case Actions.BULK_UPLOAD_FILE_LOADED:
      return {
        ...state,
        bulkUploadFileLoading: false,
        bulkUploadFileLoaded: true,
      };
    case Actions.DELETE_FILE_LOADING:
      return {
        ...state,
        deleteFileLoading: true,
        deleteFileLoaded: false,
      };
    case Actions.DELETE_FILE_LOADED:
      return {
        ...state,
        deleteFileLoading: false,
        deleteFileLoaded: true,
        deletedFile: action.payload,
      };
    case Actions.DELETE_MANY_FILE_BY_FILE_PATH_LOADING:
      return {
        ...state,
        deleteFileByPathLoading: true,
        deleteFileByPathLoaded: false,
      };
    case Actions.DELETE_MANY_FILE_BY_FILE_PATH_LOADED:
      return {
        ...state,
        deleteFileByPathLoading: false,
        deleteFileByPathLoaded: true,
      };
    case Actions.CLEAR_STATE:
      return {
        ...fileUploadInitialState,
      };
    case Actions.FETCH_ERROR:
      return fileUploadInitialState;
    default:
      return state;
  }
}
