import { WorkorderNumbersDataInterface } from '../../store/activity-history/activity-history.model';
import { TemplateRef } from '@angular/core';

export interface WorkOrderQuantity {
  id: number;
  finalQuantity: string;
  finalScrap: string;
}

export interface OpenManualCountEntryModalDataInterface {
  waitingForGetWorkOrderCount: boolean;
  content: TemplateRef<any>;
  data: WorkorderNumbersDataInterface;
}

export interface IShownFieldsForActivity {
  start: boolean;
  end: boolean;
  user: boolean;
  site: boolean;
  line: boolean;
  activity: boolean;
  task: boolean;
  workOrderNumber: boolean;
  crewSize: boolean;
  workOrderSize: boolean;
  description: boolean;
}

export const ACTIVITY_HISTORY_SINGLE_EDIT_ENTRY_VISIBLE_PROPERTY: IShownFieldsForActivity = {
  start: true,
  end: true,
  user: true,
  site: true,
  line: true,
  activity: true,
  task: true,
  workOrderNumber: true,
  crewSize: true,
  workOrderSize: true,
  description: true,
};

export const ACTIVITY_HISTORY_BULK_EDIT_ENTRY_VISIBLE_PROPERTY: IShownFieldsForActivity = {
  start: false,
  end: false,
  user: false,
  site: false,
  line: false,
  activity: true,
  task: true,
  workOrderNumber: true,
  crewSize: true,
  workOrderSize: false,
  description: true,
};

export enum EActivityHistoryTableTypes {
  LINE_VIEW = 'lineView',
  MISSING_DATA = 'missingData',
}

export interface IActivityHistoryTableConfiguration {
  workOrderId: number;
  isWorkOrderFinalized?: boolean;
  isWorkOrderCanceled?: boolean;
  workOrder?: IActivityHistoryWorkOrderData;
}

export interface IActivityHistoryWorkOrderData {
  id: number;
  woNumber: string;
  quantityOrdered: string;
  product: {
    id: number;
    productId: string;
    description: string;
  };
  lineId?: number;
}
