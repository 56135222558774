import { Inject, Injectable } from '@angular/core';
import { ProductCRUDInterface } from '../../../../../shared/component/filter/filter.class';
import { DatatableHeaderInterface } from '../../../../../shared/component/datatable/datatable.model';
import { TranslateService } from '@ngx-translate/core';
import { EColumnWidth } from '../../../../../shared/service/datatable/datatable.model';
import { Observable } from 'rxjs';
import {
  BaseOneResponseInterface,
  BulkResponseDataInterface,
  GetManyResponseInterface,
} from '../../../../../shared/model/interface/crud-response-interface.model';
import {
  IBillOfMaterials,
  IBillOfMaterialsForm,
  IBillOfMaterialsRequestPayload,
  IBillOfMaterialsItemRequestPayload,
} from './bill-of-materials.model';
import { HttpClient, HttpParams } from '@angular/common/http';
import { GenericCrudRequestConstructionParameters } from '../../../../../shared/model/interface/generic-api-request.model';
import { FormHelperService } from '../../../../../shared/service/form/form.helper.service';
import { DecimalHelper } from '../../../../../shared/helper/decimal/decimal-helper';
import { ProductInterface } from '../../products.model';

@Injectable({
  providedIn: 'root',
})
export class BillOfMaterialsService {
  public readonly tableQuery: GenericCrudRequestConstructionParameters = {
    page: 1,
    perPage: 10,
    sort: [
      {
        column: 'id',
        type: 'descending',
      },
    ],
    join: [
      'productVersion||id',
      'productBomItem||productId,name,quantity',
      'productBomItem.product||productId,description,unit',
    ],
    search: {
      searchText: '',
      searchedFields: ['name'],
    },
  };
  public readonly productQuery: GenericCrudRequestConstructionParameters = {
    page: 1,
    perPage: 50,
    limit: 50,
    search: {
      searchText: '',
      searchedFields: ['productId', 'description'],
    },
    filters: [
      {
        field: 'siteId',
        ids: [],
      },
    ],
  };

  private readonly routes: {
    billOfMaterials: string;
    billOfMaterialsBulkDelete: string;
    billOfMaterialsItemBulkCreate: string;
    billOfMaterialsItemBulkEdit: string;
    billOfMaterialsItemBulkDelete: string;
    products: string;
  } = {
    billOfMaterials: `${this.baseUrl}/product-boms`,
    billOfMaterialsBulkDelete: `${this.baseUrl}/product-boms/bulk/delete`,
    billOfMaterialsItemBulkCreate: `${this.baseUrl}/product-bom-items/bulk/create`,
    billOfMaterialsItemBulkEdit: `${this.baseUrl}/product-bom-items/bulk/edit`,
    billOfMaterialsItemBulkDelete: `${this.baseUrl}/product-bom-items/bulk/delete`,
    products: `${this.baseUrl}/products`,
  };

  constructor(@Inject('API_BASE_URL') private readonly baseUrl: string, private readonly http: HttpClient) {}

  public getTableHeaders(translate: TranslateService): DatatableHeaderInterface[] {
    return [
      {
        value: null,
        name: '',
        sortable: false,
        width: EColumnWidth.checkbox,
      },
      {
        value: 'productId',
        name: translate.instant('products.listView.headers.productId'),
      },
      {
        value: 'productDescription',
        name: translate.instant('lineView.tableHeaders.productDescription'),
      },
      {
        value: 'name',
        name: translate.instant('settings.products.productStructure.billOfMaterial.bomName'),
      },
      {
        value: 'quantity',
        name: translate.instant('settings.products.productStructure.billOfMaterial.baseQuantity'),
      },
      {
        value: 'unit',
        name: translate.instant('products.listView.headers.unit'),
        sortable: false,
      },
      {
        value: 'materials',
        name: translate.instant('settings.products.productStructure.billOfMaterial.materials'),
        sortable: false,
      },
    ];
  }

  public getBaseForm(formHelperService: FormHelperService, decimalHelper: DecimalHelper): IBillOfMaterialsForm {
    return {
      form: {
        name: null,
        baseQuantity: null,
        productMaterial: null,
        quantity: null,
      },
      rules: {
        name: [formHelperService.getRequiredFormRule(), formHelperService.getMaxLengthFormRule(255)],
        baseQuantity: [formHelperService.getRequiredFormRule(), decimalHelper.getDecimalNonZeroInputRule()],
        productMaterial: [formHelperService.getRequiredFormRule()],
        quantity: [formHelperService.getRequiredFormRule(), decimalHelper.getDecimalNonZeroInputRule()],
      },
    };
  }

  public getBillOfMaterialsData(params: HttpParams): Observable<GetManyResponseInterface<IBillOfMaterials>> {
    return this.http.get<GetManyResponseInterface<IBillOfMaterials>>(this.routes.billOfMaterials, {
      params,
    });
  }

  public getProduct(productId: number, params: HttpParams): Observable<BaseOneResponseInterface<ProductInterface>> {
    return this.http.get<BaseOneResponseInterface<ProductInterface>>(`${this.routes.products}/${productId}`, {
      params,
    });
  }

  public addBillOfMaterials(
    body: IBillOfMaterialsRequestPayload,
  ): Observable<BaseOneResponseInterface<IBillOfMaterials>> {
    return this.http.post<BaseOneResponseInterface<IBillOfMaterials>>(this.routes.billOfMaterials, body);
  }

  public editBillOfMaterials(
    billOfMaterialsId: number,
    body: IBillOfMaterialsRequestPayload,
  ): Observable<BaseOneResponseInterface<IBillOfMaterials>> {
    return this.http.patch<BaseOneResponseInterface<IBillOfMaterials>>(
      `${this.routes.billOfMaterials}/${billOfMaterialsId}`,
      body,
    );
  }

  public deleteBillOfMaterials(billOfMaterialsId: number): Observable<BaseOneResponseInterface<IBillOfMaterials>> {
    return this.http.delete<BaseOneResponseInterface<IBillOfMaterials>>(
      `${this.routes.billOfMaterials}/${billOfMaterialsId}`,
    );
  }

  public deleteBillOfMaterialsBulk(billOfMaterialsIds: number[]): Observable<BulkResponseDataInterface> {
    return this.http.delete<BulkResponseDataInterface>(this.routes.billOfMaterialsBulkDelete, {
      body: { productBoms: billOfMaterialsIds },
    });
  }

  public addBillOfMaterialsItem(body: IBillOfMaterialsItemRequestPayload[]): Observable<BulkResponseDataInterface> {
    return this.http.post<BulkResponseDataInterface>(this.routes.billOfMaterialsItemBulkCreate, {
      productBomItems: body,
    });
  }

  public editBillOfMaterialsItem(body: IBillOfMaterialsItemRequestPayload[]): Observable<BulkResponseDataInterface> {
    return this.http.patch<BulkResponseDataInterface>(this.routes.billOfMaterialsItemBulkEdit, {
      productBomItems: body,
    });
  }

  public deleteBillOfMaterialsItem(billOfMaterialsItemIds: number[]): Observable<BulkResponseDataInterface> {
    return this.http.delete<BulkResponseDataInterface>(this.routes.billOfMaterialsItemBulkDelete, {
      body: { productBomItems: billOfMaterialsItemIds },
    });
  }

  public getProducts(params: HttpParams): Observable<GetManyResponseInterface<ProductCRUDInterface>> {
    return this.http.get<GetManyResponseInterface<ProductCRUDInterface>>(this.routes.products, {
      params,
    });
  }
}
