import { FilterWorkOrderActions, FilterWorkOrderActionTypes } from './work-order.actions';
import { WorkOrderCRUDInterface } from '../../../shared/component/filter/filter.class';
import * as _ from 'lodash';

export interface FilterWorkOrderState {
  isLoading: boolean;
  isLoaded: boolean;
  data: WorkOrderCRUDInterface[];
}

export const initialFilterWorkOrderState: FilterWorkOrderState = {
  isLoaded: false,
  isLoading: false,
  data: [],
};

export function filterWorkOrderReducer(
  state = initialFilterWorkOrderState,
  action: FilterWorkOrderActions,
): FilterWorkOrderState {
  switch (action.type) {
    case FilterWorkOrderActionTypes.StartLoadFilterWorkOrders:
      return { ...state, isLoading: true, isLoaded: false };

    case FilterWorkOrderActionTypes.FilterWorkOrdersLoaded:
      return { ...state, data: action.data, isLoaded: true, isLoading: false };

    case FilterWorkOrderActionTypes.AppendWorkOrder:
      if (!state.data.find((workOrder: WorkOrderCRUDInterface): boolean => workOrder.id === action.workOrder.id)) {
        return { ...state, data: [...state.data, action.workOrder] };
      }

      return state;

    case FilterWorkOrderActionTypes.AppendWorkOrders:
      return {
        ...state,
        data: [..._.cloneDeep(state.data), ..._.differenceBy(action.workOrder, state.data, 'id')]
      };

    case FilterWorkOrderActionTypes.ResetWorkOrderStore:
      return {
        ...state,
        isLoaded: false,
        isLoading: false,
        data: [],
      };
    default:
      return state;
  }
}
