import { SiteLineSelectionActions, SiteLineSelectionActionTypes } from './site-line-selection.actions';
import { SiteLineSelectionInterface } from './site-line-selection.model';

export const initialState: SiteLineSelectionInterface = {
  isSitesOfSiteLinesSelectionLoading: false,
  isSitesOfSiteLinesSelectionLoaded: false,
  isLinesOfSiteLinesSelectionLoading: false,
  isLinesOfSiteLinesSelectionLoaded: false,
  sites: [],
  linesAndFloorPlans: null,
  errors: [],
};

export function siteLineSelectionReducer(
  state = initialState,
  action: SiteLineSelectionActions,
): SiteLineSelectionInterface {
  switch (action.type) {
    case SiteLineSelectionActionTypes.LOAD_SITES_LINES:
      return {
        ...state,
        isSitesOfSiteLinesSelectionLoading: true,
      };
    case SiteLineSelectionActionTypes.SITES_OF_SITE_LINE_SELECTION_LOADED:
      return {
        ...state,
        isSitesOfSiteLinesSelectionLoaded: true,
        isSitesOfSiteLinesSelectionLoading: false,
      };
    case SiteLineSelectionActionTypes.LOAD_LINES_OF_SITE_LINE_SELECTION:
      return {
        ...state,
        isLinesOfSiteLinesSelectionLoading: true,
        sites: action.sites,
      };
    case SiteLineSelectionActionTypes.SITES_LINES_LOADED:
      return {
        ...state,
        isLinesOfSiteLinesSelectionLoading: false,
        isLinesOfSiteLinesSelectionLoaded: true,
        linesAndFloorPlans: action.linesAndFloorPlans,
      };
    case SiteLineSelectionActionTypes.FETCH_ERROR:
      return {
        ...state,
        errors: action.payload,
      };

    default:
      return state;
  }
}
