import { FilterTaskActions, FilterTaskActionTypes } from './task.actions';
import { HttpErrorResponse } from '@angular/common/http';
import { TaskFilterCRUDInterface } from '../../../shared/component/filter/filter.class';
import { EFilterJobsActionTypes } from '../job/job.action';

export interface FilterTaskState {
  isLoading: boolean;
  isLoaded: boolean;
  searchProcess: boolean;
  data: TaskFilterCRUDInterface[];
  originalData: TaskFilterCRUDInterface[];
  dataToBeAppended: TaskFilterCRUDInterface[];
  errors: HttpErrorResponse[];
}

export const initialFilterTaskState: FilterTaskState = {
  isLoaded: false,
  isLoading: false,
  searchProcess: false,
  data: [],
  originalData: [],
  dataToBeAppended: [],
  errors: [],
};

export function filterTaskReducer(
  state: FilterTaskState = initialFilterTaskState,
  action: FilterTaskActions,
): FilterTaskState {
  switch (action.type) {
    case FilterTaskActionTypes.StartLoadFilterTasks:
      return { ...state, isLoaded: false, isLoading: true, searchProcess: action.searchProcess };

    case FilterTaskActionTypes.FilterTasksLoaded:
      const formattedData: TaskFilterCRUDInterface[] =
        !state.searchProcess && state.dataToBeAppended.length
          ? state.dataToBeAppended
              .concat(action.data)
              .filter(
                (obj: TaskFilterCRUDInterface, index: number, self: TaskFilterCRUDInterface[]) =>
                  index === self.findIndex((task: TaskFilterCRUDInterface) => task.title === obj.title),
              )
          : action.data;

      return {
        ...state,
        data: formattedData,
        originalData: action.data,
        isLoaded: true,
        isLoading: false,
      };

    case FilterTaskActionTypes.ResetTaskStore:
      return { ...state, data: [], originalData: [], dataToBeAppended: [], isLoaded: false, isLoading: false };

    case FilterTaskActionTypes.AppendTaskData:
      return { ...state, dataToBeAppended: [] };

    case FilterTaskActionTypes.AppendedTaskData:
      return { ...state, dataToBeAppended: action.data };

    case FilterTaskActionTypes.ClearAppendedTaskData:
      return { ...state, dataToBeAppended: [] };

    case FilterTaskActionTypes.FetchError:
      const errors = [...state.errors, action.payload];

      return { ...state, errors, isLoaded: false, isLoading: false };

    default:
      return state;
  }
}
