import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Subscription } from 'rxjs';
import { Store } from '@ngrx/store';
import { OeeAppState } from '../../../../store/oee.reducer';
import { Line } from '../../../../store/line/model';
import { IBaseSensorInfo, ITemperatureSensorInfo } from './home-mini-metrics.model';
import { TMetricReading, TTemperatureMetricReading } from '../mini-metrics/mini-metrics.model';
import { MiniMetricsComponent } from '../mini-metrics/mini-metrics.component';

@Component({
  selector: 'app-home-mini-metrics',
  standalone: true,
  imports: [CommonModule, MiniMetricsComponent],
  templateUrl: './home-mini-metrics.component.html',
  styleUrls: ['./home-mini-metrics.component.scss'],
})
export class HomeMiniMetricsComponent implements OnInit, OnDestroy {
  @Input() public temperatureSensors: ITemperatureSensorInfo[] = [];
  @Input() public humiditySensors: IBaseSensorInfo[] = [];

  public temperatureValues: TTemperatureMetricReading[] = [];
  public humidityValues: TMetricReading[] = [];

  private readonly subscriptions: Subscription[] = [];

  constructor(private readonly store: Store<OeeAppState>) {}

  public ngOnInit(): void {
    this.subscriptions.push(
      this.store.select('line').subscribe((state: Line) => {
        this.temperatureValues = this.temperatureSensors.map((sensor) => {
          const temperature = state.quantities.temperatures.find((t) => t.sensorId === sensor.sensorId);

          return {
            sensorId: sensor.sensorId,
            scale: sensor.scale,
            value: temperature?.value ?? null,
            isNormalized: false,
          };
        });

        this.humidityValues = this.humiditySensors.map((sensor) => {
          const humidity = state.quantities.humidities.find((h) => h.sensorId === sensor.sensorId);

          return {
            sensorId: sensor.sensorId,
            value: humidity?.value ?? null,
            isNormalized: false,
          };
        });
      }),
    );
  }

  public ngOnDestroy(): void {
    this.subscriptions.forEach((subscription) => subscription?.unsubscribe());
  }
}
