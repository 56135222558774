<div class="row" style="height: 100%">
  <div class="col-lg-6">
    <scw-mat-button
      (onClick)="onAddScrapCountButtonClick()"
      [className]="['w-100', 'h-100', 'tbtn']"
      [colors]="addScrapCountButtonColors"
      type="standard"
    >
      <p style="font-size: 18px">{{ 'stationHomeKpiCard.semiManualCountButtons.addScrapCount' | translate }}</p>
      <p style="font-size: 18px">(+{{ defaultScrapValuePerClick }})</p>
    </scw-mat-button>
  </div>
  <div class="col-lg-6">
    <scw-mat-button
      (onClick)="onAddGoodCountButtonClick()"
      [className]="['w-100', 'h-100']"
      [colors]="addGoodCountButtonColors"
      type="standard"
    >
      <p style="font-size: 18px">{{ 'stationHomeKpiCard.semiManualCountButtons.addGoodCount' | translate }}</p>
      <p style="font-size: 18px">(+{{ defaultGoodValuePerClick }})</p>
    </scw-mat-button>
  </div>
</div>
