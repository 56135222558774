import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { Store } from '@ngrx/store';
import { OeeAppState } from '../../../../store/oee.reducer';
import { HelperService } from '../../../../shared/service/helper.service';
import { TranslateService } from '@ngx-translate/core';
import { DecimalHelper } from '../../../../shared/helper/decimal/decimal-helper';
import { User } from '../../../../store/user/model';
import * as _ from 'lodash';
import { DECIMAL_DEFAULT_SCALE_LIMIT } from '../../../../../constants';
import { Line } from '../../../../store/line/model';
import { HomePageDisplaySettingsUtilities } from '../../../settings/home-page-display-settings/home-page-display-settings.utilities';
import { ICountMeta } from '../../../settings/home-page-display-settings/home-metrics/home-metrics.model';
import { ICountMetricCalculationValues, IPreDefinedKPIMetricsWithProperties } from './count-metric.model';
import * as moment from 'moment-timezone';
import { OnDestroyDecorator } from '../../../../shared/decorator/on-destroy-decorator';
import { PROPERTIES } from '../home-metrics.component';
import { mysqlTimestampFormat } from '../../../../shared/helper/date';
import { ECountType, ELayout } from '../../../settings/home-page-display-settings/home-page-display-settings.constants';
import { IOeeCalculationResult } from '../../../../store/reports/work-order-performance/work-order-performance.model';

@OnDestroyDecorator
@Component({
  selector: 'count-metric',
  templateUrl: './count-metric.component.html',
})
export class CountMetricComponent implements OnInit, OnDestroy {
  private readonly subscriptions: Subscription[] = [];
  public actualValue: string = '0';
  public expectedValue: string = '0';
  public actualValueUnit: string = null;
  public expectedValueUnit: string = null;
  private siteDecimalScaleLimit$: number;
  private timezone: string;
  public progressBarTitle: string = '';
  public progressBarRateCapped: number = null;
  public progressBarRate: string = null;
  public properties: ICountMeta = null;
  public titleBracketInfo: string = '';
  public selectedMetric: IPreDefinedKPIMetricsWithProperties;
  private metricCalculationValues: ICountMetricCalculationValues = {
    expectedSpeed: null,
    initialSensorCount: null,
    initialCount: null,
    initialCountForOneHour: null,
    initialCountForTwoHour: null,
    initialCountForThreeHour: null,
    goodSensorCount: null,
    goodCount: null,
    goodCountForOneHour: null,
    goodCountForTwoHour: null,
    goodCountForThreeHour: null,
    scrapCount: null,
    scrapCountForOneHour: null,
    scrapCountForTwoHour: null,
    scrapCountForThreeHour: null,
    quantityOrdered: null,
    currentShiftInitialCount: null,
    currentShiftGoodCount: null,
    currentActivityDuration: null,
    shiftCount: null,
  };

  constructor(
    public helperService: HelperService,
    private store: Store<OeeAppState>,
    private translate: TranslateService,
    private readonly decimalHelper: DecimalHelper,
    @Inject(PROPERTIES) private readonly metricProperties: ICountMeta,
  ) {
    this.properties = metricProperties;
  }

  public ngOnInit(): void {
    this.subscriptions.push(
      this.store.select('user').subscribe((state: User) => {
        this.siteDecimalScaleLimit$ = state.siteDecimalScaleLimit;
        this.timezone = state.timezone;
      }),

      this.store.select('line').subscribe((state: Line) => {
        this.metricCalculationValues.initialSensorCount = state.quantities.initialSensorCount;
        this.metricCalculationValues.initialCount = state.quantities.initialCount;
        this.metricCalculationValues.goodSensorCount = state.quantities.goodSensorCount;
        this.metricCalculationValues.goodCount = state.quantities.goodCount;
        this.metricCalculationValues.scrapCount = this.decimalHelper.subtract(
          state.quantities.initialCount,
          state.quantities.goodCount,
        );
        this.metricCalculationValues.quantityOrdered = state.workOrderQuantityOrdered;
        this.metricCalculationValues.expectedSpeed = this.decimalHelper.removeThousandSeparator(state.expectedSpeed);
        this.metricCalculationValues.expectedSpeed = this.decimalHelper.sanitizeString(
          this.metricCalculationValues.expectedSpeed,
        );
        this.metricCalculationValues.currentShiftInitialCount = state.quantities.currentShiftInitialCount;
        this.metricCalculationValues.currentShiftGoodCount = state.quantities.currentShiftGoodCount;
        this.metricCalculationValues.shiftCount = state.quantities.shiftCount;
        const now: string = moment().tz(this.timezone).format(mysqlTimestampFormat);
        this.metricCalculationValues.currentActivityDuration = moment(now)
          .diff(moment(moment(state.activityStart).format(mysqlTimestampFormat)), 'minutes')
          .toString();
        this.calculateHourlyData(state.quantities.hourlyOee);

        const countMetricInfo: IPreDefinedKPIMetricsWithProperties[] =
          HomePageDisplaySettingsUtilities.getPreDefinedCountMetricsWithProperties(
            this.translate,
            this.decimalHelper,
            this.metricCalculationValues,
          );

        const unitValue: string = _.camelCase(state.unitName);
        this.actualValueUnit =
          unitValue === '' ? '' : this.translate.instant(`homePageDisplaySettings.unit.${unitValue}.formula`);
        this.expectedValueUnit =
          unitValue === '' ? '' : this.translate.instant(`homePageDisplaySettings.unit.${unitValue}.formula`);

        if (this.properties.layout === ELayout.MULTIPLE_UNITS) {
          this.selectedMetric = this.setMultipleUnitMetricValue();
        } else {
          this.selectedMetric = _.find(countMetricInfo, { meta: this.properties });
        }

        this.titleBracketInfo = HomePageDisplaySettingsUtilities.setTitleBracketInfo(
          this.translate,
          this.selectedMetric.meta.dataSource,
          this.selectedMetric.meta.countType,
          this.selectedMetric.meta.timeSpan,
        );
        this.progressBarTitle = this.selectedMetric.name;
        this.actualValue = this.selectedMetric.formula.actualValue;
        this.expectedValue = this.selectedMetric.formula.expectedValue;

        this.progressBarRate = this.decimalHelper.calculateRate(
          this.actualValue,
          this.expectedValue,
          DECIMAL_DEFAULT_SCALE_LIMIT,
        );

        this.progressBarRateCapped = this.decimalHelper.decimalToNumberFormatter(
          this.decimalHelper.calculateRate(
            this.actualValue,
            this.expectedValue,
            DECIMAL_DEFAULT_SCALE_LIMIT,
            false,
            true,
            true,
          ),
        );

        this.actualValue = this.decimalHelper.toFixedValue(this.actualValue, this.siteDecimalScaleLimit$);
        this.expectedValue = this.decimalHelper.toFixedValue(this.expectedValue, this.siteDecimalScaleLimit$);
      }),
    );
  }

  public calculateHourlyData(hourly: IOeeCalculationResult[]): void {
    this.metricCalculationValues.initialCountForOneHour = '0';
    this.metricCalculationValues.initialCountForTwoHour = '0';
    this.metricCalculationValues.initialCountForThreeHour = '0';
    this.metricCalculationValues.goodCountForOneHour = '0';
    this.metricCalculationValues.goodCountForTwoHour = '0';
    this.metricCalculationValues.goodCountForThreeHour = '0';
    this.metricCalculationValues.scrapCountForOneHour = '0';
    this.metricCalculationValues.scrapCountForTwoHour = '0';
    this.metricCalculationValues.scrapCountForThreeHour = '0';

    for (let i = 0; i < 3; i = i + 1) {
      const hourlyGoodCount: string = _.isNil(hourly[i]?.totalScrapCount) ? '0' : hourly[i]?.totalScrapCount;
      const hourlyScrapCount: string = _.isNil(hourly[i]?.totalScrapCount) ? '0' : hourly[i]?.totalScrapCount;
      const hourlyInitialCount: string = this.decimalHelper.add(hourlyGoodCount, hourlyScrapCount);

      if (i < 1) {
        this.metricCalculationValues.initialCountForOneHour = this.decimalHelper.add(
          this.metricCalculationValues.initialCountForOneHour,
          hourlyInitialCount,
        );

        this.metricCalculationValues.goodCountForOneHour = this.decimalHelper.add(
          this.metricCalculationValues.goodCountForOneHour,
          hourlyGoodCount,
        );

        this.metricCalculationValues.scrapCountForOneHour = this.decimalHelper.add(
          this.metricCalculationValues.scrapCountForOneHour,
          hourlyScrapCount,
        );
      }

      if (i < 2) {
        this.metricCalculationValues.initialCountForTwoHour = this.decimalHelper.add(
          this.metricCalculationValues.initialCountForTwoHour,
          hourlyInitialCount,
        );

        this.metricCalculationValues.goodCountForTwoHour = this.decimalHelper.add(
          this.metricCalculationValues.goodCountForTwoHour,
          hourlyGoodCount,
        );

        this.metricCalculationValues.scrapCountForTwoHour = this.decimalHelper.add(
          this.metricCalculationValues.scrapCountForTwoHour,
          hourlyScrapCount,
        );
      }

      if (i < 3) {
        this.metricCalculationValues.initialCountForThreeHour = this.decimalHelper.add(
          this.metricCalculationValues.initialCountForThreeHour,
          hourlyInitialCount,
        );

        this.metricCalculationValues.goodCountForThreeHour = this.decimalHelper.add(
          this.metricCalculationValues.goodCountForThreeHour,
          hourlyGoodCount,
        );

        this.metricCalculationValues.scrapCountForThreeHour = this.decimalHelper.add(
          this.metricCalculationValues.scrapCountForThreeHour,
          hourlyScrapCount,
        );
      }
    }
  }

  public setMultipleUnitMetricValue(): IPreDefinedKPIMetricsWithProperties {
    let count: string = null;

    switch (this.properties.countType) {
      case ECountType.INITIAL:
        count = this.metricCalculationValues?.initialCount;
        break;
      case ECountType.GOOD:
        count = this.metricCalculationValues?.goodCount;
        break;
      case ECountType.SCRAP:
        count = this.metricCalculationValues?.scrapCount;
        break;
    }

    return {
      name: this.translate.instant('kpiCards.metrics.work_order_count'),
      formula: {
        actualValue: count,
        expectedValue: '0',
      },
      meta: {
        layout: this.properties.layout,
        dataSource: this.properties.dataSource,
        countType: this.properties.countType,
        timeSpan: this.properties.timeSpan,
        unitOfCount: this.properties.unitOfCount,
      },
    };
  }

  public ngOnDestroy(): void {
    this.subscriptions.forEach((value: Subscription) => {
      value.unsubscribe();
    });
  }
}
