import { Inject, Injectable } from '@angular/core';
import {
  MaintenancePerformanceFormattedQueryParams,
  MaintenancePerformanceQueryParams,
} from '../../../view/reports/maintenance-performance/maintenance-performance.model';
import { HelperService } from '../../../shared/service/helper.service';
import { mysqlDateFormat } from '../../../shared/helper/date';
import * as _ from 'lodash';
import { forkJoin, from, Observable, Subject } from 'rxjs';
import { ValueType } from 'exceljs';
import {
  CellTypes,
  CreateExcelInterface,
  CreateExcelSheetInterface,
  ExcelHelperService,
  ExcelSheetTypeEnum,
} from '../../../shared/service/excel/excel-helper.service';
import * as ObjectActions from '../maintenance-performance/maintenance-performance.actions';
import { Store } from '@ngrx/store';
import * as oeeAppReducer from '../../oee.reducer';
import {
  MaintenancePerformanceReportCommonProperties,
  MaintenancePerformanceReportInterface,
} from './maintenance-performance.model';
import { TranslateService } from '@ngx-translate/core';
import { takeUntil } from 'rxjs/operators';
import { excelDateFormat, excelTimeFormat } from '../../../shared/model/enum/excel-date-format';
import { AdvancedFilterService } from '../../../shared/component/filter/advanced-filter/advanced-filter.service';
import {
  QueryTypes,
} from '../../../shared/component/filter/advanced-filter/advanced-filter.model';
import { BaseOneResponseInterface } from '../../../shared/model/interface/crud-response-interface.model';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import * as moment from 'moment-timezone';
import * as AppActions from '../../app/actions';
import { DecimalHelper } from '../../../shared/helper/decimal/decimal-helper';

@Injectable({
  providedIn: 'root',
})
export class MaintenancePerformanceService {
  private timezone: string = 'utc';
  private dateFormat$: string;
  private timeFormat$: string;
  private readonly destroySubject: Subject<boolean> = new Subject<boolean>();

  private readonly routes = {
    activityHistory: `${this.baseUrl}/activity-histories`,
  };

  constructor(
    private readonly store: Store<oeeAppReducer.OeeAppState>,
    private readonly translate: TranslateService,
    private readonly excelHelper: ExcelHelperService,
    private readonly advancedFilterService: AdvancedFilterService,
    private readonly decimalHelper: DecimalHelper,
    @Inject('API_BASE_URL') private readonly baseUrl: string,
    public http: HttpClient,
  ) {
    this.store
      .select('user')
      .pipe(takeUntil(this.destroySubject))
      .subscribe((state) => {
        if (state.isUserLoaded) {
          this.timezone = state.timezone;
          if (state.locale !== '') {
            this.dateFormat$ = excelDateFormat[state.locale];
            this.timeFormat$ = excelTimeFormat[state.locale];
          }
          this.destroySubject.next(true);
          this.destroySubject.complete();
        }
      });
  }

  public getMaintenancePerformanceData(
    params: MaintenancePerformanceFormattedQueryParams,
  ): Observable<BaseOneResponseInterface<MaintenancePerformanceReportInterface>> {
    return this.http.post<BaseOneResponseInterface<MaintenancePerformanceReportInterface>>(
      `${this.routes.activityHistory}/maintenance-performance`,
      params,
      { headers: new HttpHeaders({ 'X-HTTP-Method': 'GET' }) },
    );
  }

  public formatMaintenancePerformanceData(
    params: MaintenancePerformanceQueryParams,
  ): MaintenancePerformanceFormattedQueryParams {
    const data: MaintenancePerformanceFormattedQueryParams = {
      page: params.page,
      ignoreIdleActivities: params?.ignoreIdleActivities,
      offset: params.offset ? Number(params.offset) : 10,
      sites: HelperService.formatDropdownFilterOptionOutput(params?.sites),
      lines: HelperService.formatDropdownFilterOptionOutput(params?.lines),
      tasks: params?.tasks,
      start: params.dateRange?.startDate.format(mysqlDateFormat),
      end: params.dateRange?.endDate.format(mysqlDateFormat),
      advancedFilterPage: params.advancedFilterPage ?? params.advancedFilter?.page,
      ...(params.sort?.length ? { sort: `${params.sort[0].field},${params.sort[0].order}` } : undefined),
    };

    const advancedFilterParams: { $and: any[] } = {
      $and: [],
    };

    if (params.advancedFilter?.filters) {
      for (const filter of params.advancedFilter.filters) {
        if (filter.queryType !== QueryTypes.withinAdvancedFilterParams) continue;

        advancedFilterParams.$and.push(
          this.advancedFilterService.generateQuery(
            filter.path,
            filter.type,
            filter.operator.name,
            filter.operator.type,
            params.advancedFilter.target,
            filter.value,
            filter.isDbPath,
          ),
        );
      }

      _.set(
        data,
        this.advancedFilterService.getRequestQueryParameterName(params.advancedFilter.target),
        JSON.stringify(advancedFilterParams),
      );
    }
    return data;
  }

  public getMaintenancePerformanceItemsExcelColumns(): CreateExcelInterface {
    const maintenancePerformanceColumns: CreateExcelInterface = {
      columns: [
        {
          header: this.translate.instant('workOrderSchedule.listView.headers.site'),
          key: 'siteNames',
          width: 20,
          type: ValueType.String,
          dataValidation: {
            type: CellTypes.CUSTOM,
            allowBlank: false,
            formulae: [],
            showErrorMessage: true,
            showInputMessage: true,
          },
        },
        {
          header: this.translate.instant('general.dataTable.header.departmentName'),
          key: 'departmentName',
          width: 20,
          type: ValueType.String,
          dataValidation: {
            type: CellTypes.CUSTOM,
            allowBlank: false,
            formulae: [],
            showErrorMessage: true,
            showInputMessage: true,
          },
        },
        {
          header: this.translate.instant('general.dataTable.header.lineName'),
          key: 'lineName',
          width: 20,
          type: ValueType.String,
          dataValidation: {
            type: CellTypes.CUSTOM,
            allowBlank: false,
            formulae: [],
            showErrorMessage: true,
            showInputMessage: true,
          },
        },
        {
          header: this.translate.instant('general.dataTable.header.maintenance_performance.number_of_failures'),
          key: 'numberOfFailures',
          width: 20,
          type: ValueType.String,
          dataValidation: {
            type: CellTypes.CUSTOM,
            allowBlank: false,
            formulae: [],
            showErrorMessage: true,
            showInputMessage: true,
          },
        },
        {
          header: this.translate.instant('general.dataTable.header.maintenance_performance.mttf'),
          key: 'MTTF',
          width: 20,
          type: ValueType.String,
          dataValidation: {
            type: CellTypes.CUSTOM,
            allowBlank: false,
            formulae: [],
            showErrorMessage: true,
            showInputMessage: true,
          },
        },
        {
          header: this.translate.instant('general.dataTable.header.maintenance_performance.mttr'),
          key: 'MTTR',
          width: 20,
          type: ValueType.String,
          dataValidation: {
            type: CellTypes.CUSTOM,
            allowBlank: false,
            formulae: [],
            showErrorMessage: true,
            showInputMessage: true,
          },
        },
        {
          header: this.translate.instant('general.dataTable.header.maintenance_performance.mtbf'),
          key: 'MTBF',
          width: 20,
          type: ValueType.String,
          dataValidation: {
            type: CellTypes.CUSTOM,
            allowBlank: false,
            formulae: [],
            showErrorMessage: true,
            showInputMessage: true,
          },
        },
      ],
    };
    return maintenancePerformanceColumns;
  }

  public downloadExcel(filters: MaintenancePerformanceQueryParams): void {
    this.store.dispatch(new AppActions.ShowLoader());
    const observables: Observable<BaseOneResponseInterface<MaintenancePerformanceReportInterface>>[] = [];

    observables.push(
      this.translate.get('excel.maintenancePerformance.excelName', {
        date: moment().tz(this.timezone).format(this.dateFormat$),
      }),
    );

    observables.push(from(this.getMaintenancePerformanceData(this.formatMaintenancePerformanceData(filters))));

    forkJoin(observables).subscribe((responseList) => {
      const maintenancePerformanceTitle: string = this.translate.instant('pageTitles.maintenance_performance');
      const excelName: string = _.get(responseList, '0', maintenancePerformanceTitle);
      const excelData: MaintenancePerformanceReportInterface = _.get(responseList, '1.data', []);

      const excelOptions: CreateExcelInterface = this.getMaintenancePerformanceItemsExcelColumns();
      excelOptions.data = this.formatExcelData(excelData.rows);

      const worksheets: CreateExcelSheetInterface[] = [
        {
          sheetTitle: maintenancePerformanceTitle,
          sheetType: ExcelSheetTypeEnum.TABLE,
          params: excelOptions,
          withData: true,
        },
      ];

      this.excelHelper
        .createExcel(
          excelName,
          { siteId: _.get(filters, 'sites.0'), name: 'maintenancePerformance', withData: true },
          worksheets,
          this.timezone,
          this.dateFormat$,
          this.timeFormat$,
        )
        .then(
          () => {
            this.store.dispatch(new ObjectActions.DownloadExcelCompleted());
            this.store.dispatch(new AppActions.HideLoader());
          },
          () => {
            this.store.dispatch(new ObjectActions.FetchError({}));
          },
        );
    });
  }

  private formatExcelData(
    excelData: MaintenancePerformanceReportCommonProperties[],
  ): MaintenancePerformanceReportCommonProperties[] {
    return excelData.map((data) => {
      return {
        ...data,
        ...(data?.MTTF && { MTTF: this.decimalHelper.toFixedValue(data.MTTF.toString()) as string }),
        ...(data?.MTBF && { MTBF: this.decimalHelper.toFixedValue(data.MTBF.toString()) as string }),
        ...(data?.MTTR && { MTTR: this.decimalHelper.toFixedValue(data.MTTR.toString()) as string }),
      };
    });
  }
}
