import { Action } from '@ngrx/store';
import { HttpOptionInterface } from '../filter.model';
import { LineStationCRUDInterface } from '../../../shared/component/filter/filter.class';

export enum FilterLineStationActionTypes {
  START_LOAD_FILTER_LINE_STATIONS = '[FILTER LINE STATION] LOAD FILTER LINE STATIONS',
  FILTER_LINE_STATIONS_LOADED = '[FILTER LINE STATION] FILTER LINE STATIONS LOADED',
  RESET_LINE_STATION_STORE = '[FILTER LINE STATION] RESET LINE STATION STORE',
}

export class StartLoadFilterLineStations implements Action {
  readonly type = FilterLineStationActionTypes.START_LOAD_FILTER_LINE_STATIONS;

  constructor(public options: HttpOptionInterface) {}
}

export class FilterLineStationsLoaded implements Action {
  readonly type = FilterLineStationActionTypes.FILTER_LINE_STATIONS_LOADED;

  constructor(public data: LineStationCRUDInterface[]) {}
}

export class ResetLineStationStore implements Action {
  readonly type = FilterLineStationActionTypes.RESET_LINE_STATION_STORE;

  constructor() {}
}

export type FilterLineStationActions =
  StartLoadFilterLineStations |
  FilterLineStationsLoaded |
  ResetLineStationStore;
