import { HttpClient, HttpParams } from '@angular/common/http';
import {
  NotificationAppendToInterface,
  NotificationGroupInterface,
  NotificationInterface, NotificationItemInterface,
  NotificationTemplateInterface,
} from './iot-device-report.model';
import { Inject, Injectable } from '@angular/core';
import {
  BaseOneResponseInterface,
  GetManyResponseInterface,
} from '../../shared/model/interface/crud-response-interface.model';
import { catchError, Observable, of, switchMap } from 'rxjs';
import { HelperService } from '../../shared/service/helper.service';
import { map } from 'rxjs/operators';
import { IAddNotification } from '../../view/reports/iot-device-report/iot-device-report.model';
import * as _ from 'lodash';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root',
})
export class IotDeviceReportService {
  private readonly NOTIFICATION_GROUP = {
    SITE_URL: `${this.baseUrl}/sites`,
    NOTIFICATION_GROUP_URL: `${this.baseUrl}/notifications/mail-groups`,
    NOTIFICATION_TEMPLATES_URL: `${this.baseUrl}/notifications/email-templates`,
    NOTIFICATION_URL: `${this.baseUrl}/iot-device-portal-notifications`,
    NOTIFICATION_ITEM_URL: `${this.baseUrl}/iot-device-portal-notification-items`,
    NOTIFICATION_ITEM_BULK_CREATE_URL: `${this.baseUrl}/iot-device-portal-notification-items/bulk/create`,
    NOTIFICATION_ITEM_BULK_UPDATE_URL: `${this.baseUrl}/iot-device-portal-notification-items/bulk/update`,
  };

  constructor(
    public http: HttpClient,
    @Inject('API_BASE_URL') private readonly baseUrl: string,
    public helperService: HelperService,
    private readonly translate: TranslateService,
  ) {}

  public getNotificationGroups(params: HttpParams): Observable<GetManyResponseInterface<NotificationGroupInterface>> {
    return this.http.get<GetManyResponseInterface<NotificationGroupInterface>>(
      this.NOTIFICATION_GROUP.NOTIFICATION_GROUP_URL,
      {
        params,
      },
    );
  }

  public getNotificationTemplates(
    params: HttpParams,
  ): Observable<GetManyResponseInterface<NotificationTemplateInterface>> {
    return this.http.get<GetManyResponseInterface<NotificationTemplateInterface>>(
      this.NOTIFICATION_GROUP.NOTIFICATION_TEMPLATES_URL,
      {
        params,
      },
    );
  }

  public getNotifications(params: HttpParams): Observable<GetManyResponseInterface<NotificationInterface>> {
    return this.http.get<GetManyResponseInterface<NotificationInterface>>(this.NOTIFICATION_GROUP.NOTIFICATION_URL, {
      params,
    });
  }

  public addNotification(
    notification: Partial<IAddNotification>,
  ): Observable<BaseOneResponseInterface<IAddNotification>> {
    let notificationHead = _.cloneDeep(notification);
    delete notificationHead.selectedHexboxes;
    if (notification.selectedHexboxes.length === 0) {
      throw new Error(this.translate.instant('iotDeviceReport.notification.modal.noHexbox'));
    }
    return this.http
      .post<BaseOneResponseInterface<IAddNotification>>(this.NOTIFICATION_GROUP.NOTIFICATION_URL, notificationHead)
      .pipe(
        catchError((err) => {
          return of(err.error);
        }),
        switchMap((notificationHeadResponse): Observable<BaseOneResponseInterface<IAddNotification>> => {
          let iotDevicePortalNotificationId = null;
          if (!notificationHeadResponse.success) {
            return of(notificationHeadResponse);
          }
          try {
            iotDevicePortalNotificationId = notificationHeadResponse.data.id;
          } catch (e) {}
          return this.http
            .post<BaseOneResponseInterface<IAddNotification>>(
              `${this.NOTIFICATION_GROUP.NOTIFICATION_ITEM_BULK_CREATE_URL}`,
              {
                selectedHexboxes: notification.selectedHexboxes,
                iotDevicePortalNotificationId,
              },
            )
            .pipe(
              catchError((err) => {
                return of(err.error);
              }),
              map((notificationItemResponse): BaseOneResponseInterface<IAddNotification> => {
                return notificationItemResponse;
              }),
            );
        }),
      );
  }

  public appendToNotification(
    payload: Partial<NotificationAppendToInterface>,
  ): Observable<BaseOneResponseInterface<NotificationAppendToInterface>> {
    return this.http.patch<BaseOneResponseInterface<NotificationAppendToInterface>>(
      `${this.NOTIFICATION_GROUP.NOTIFICATION_ITEM_BULK_UPDATE_URL}`,
      {
        selectedHexboxes: payload.selectedHexboxes,
        iotDevicePortalNotificationId: payload.iotDevicePortalNotificationId,
      },
    );
  }

  public deleteNotification(
    notificationId: number,
  ): Observable<BaseOneResponseInterface<NotificationInterface>> {
    return this.http.delete<BaseOneResponseInterface<NotificationInterface>>(
      `${this.NOTIFICATION_GROUP.NOTIFICATION_URL}/${notificationId}`,
    );
  }

  public deleteNotificationItem(
    notificationItemId: number,
  ): Observable<BaseOneResponseInterface<NotificationItemInterface>> {
    return this.http.delete<BaseOneResponseInterface<NotificationItemInterface>>(
      `${this.NOTIFICATION_GROUP.NOTIFICATION_ITEM_URL}/${notificationItemId}`,
    );
  }

}
