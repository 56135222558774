import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { ScwMatSearchBehavior, ScwMatSearchButtonPosition, ScwMatSearchEvent } from './scw-mat-search.model';
import { ScwMatInputRule, ScwMatInputSize } from '../scw-mat-input/scw-mat-input.model';
import { ScwMatInputComponent } from '../scw-mat-input/scw-mat-input.component';

@Component({
  selector: 'scw-mat-search',
  templateUrl: './scw-mat-search.component.html',
  styleUrls: ['./scw-mat-search.component.scss'],
  host: { class: 'scw-mat-search' },
})
export class ScwMatSearchComponent implements OnInit {
  @ViewChild('inputComponent') inputComponent!: ScwMatInputComponent
  @Input() inputModel: string = null;
  @Input() disabled: boolean = false;
  @Input() block: boolean = false;
  @Input() className: string[] | {
    [klass: string]: any;
  };
  @Input() label: string = null;
  @Input() placeholder: string = null;
  @Input() buttonPosition: ScwMatSearchButtonPosition = 'right';
  @Input() behavior: ScwMatSearchBehavior = 'live';
  @Input() searchDelay: number = 600;
  @Input() minLength: number = 3;
  @Input() maxLength: number = 1000;
  @Input() size: ScwMatInputSize = 'md';
  @Input() preventSameSearchString: boolean = true;
  @Input() rules: ScwMatInputRule[] = [];
  @Input() hasErrors: boolean = false;
  @Output() inputModelChange: EventEmitter<string> = new EventEmitter<string>();
  @Output() onSearch: EventEmitter<string> = new EventEmitter<string>();

  private readonly defaultSearchClass: string = 'scw-mat-input-search-input';
  private searchTimeout: any;
  private lastEmittedSearchString: string;
  public lastValidSearch: string;
  public maxlengthString: string = '1000';

  constructor() {}

  public onSearchEvent(event: ScwMatSearchEvent): void {
    if (!this.isSearchStringValid()) {
      return;
    }

    this.lastValidSearch = this.inputModel;
    this.inputComponent.onNgModelChange();
    this.hasErrors = this.inputComponent.hasErrors;

    if (this.behavior === 'live' || (this.behavior === 'onSubmit' && ['onEnter', 'onClickButton'].includes(event))) {
      this.emitSearchString();
    }

    if (this.behavior === 'delayed') {
      clearTimeout(this.searchTimeout);

      this.searchTimeout = setTimeout(() => {
        if (this.inputModel === this.lastValidSearch) {
          clearTimeout(this.searchTimeout);
          this.emitSearchString();
        }
      }, this.searchDelay);
    }
  }

  private isSearchStringValid(): boolean {
    return !(
      this.inputModel === undefined ||
      this.inputModel === null ||
      this.inputModel.length > this.maxLength ||
      this.inputModel.length < this.minLength ||
      (this.preventSameSearchString && this.lastEmittedSearchString === this.inputModel)
    );
  }

  public emitSearchString(): void {
    this.onSearch.emit(this.lastValidSearch);
    this.lastEmittedSearchString = this.lastValidSearch;
    return;
  }

  private addInitialClassNames(): void {
    if (Array.isArray(this.className)) {
      this.className.push(this.defaultSearchClass, this.buttonPosition, this.size === 'xs' ? 'scw-mat-xs-search' : '');
    } else {
      this.className = {
        ...this.className,
        ...{
          'scw-mat-input-search-input': true,
          'scw-mat-xs-search': this.size === 'xs',
          right: this.buttonPosition === 'right',
          left: this.buttonPosition === 'left',
        },
      };
    }
  }

  public onInputModelChange(): void {
    this.inputModelChange.emit(this.inputModel);
  }

  public onNgModelChange(): void {
    this.maxlengthString = String(this.maxLength);
    this.addInitialClassNames();
  }

  public ngOnInit(): void {
    this.maxlengthString = String(this.maxLength);
    this.lastEmittedSearchString = this.inputModel;
    this.addInitialClassNames();
  }
}
