import { ManualCountsReviewModel } from './work-order-count-review.model';
import * as Actions from './work-order-count-review.actions';

const initialState: ManualCountsReviewModel = {
  isChartDataLoading: false,
  isChartDataLoaded: false,
  chartData: null,
};

export function manualCountReviewReducer(
  state: ManualCountsReviewModel = initialState,
  action: Actions.ManualCountReviewActions,
): ManualCountsReviewModel {
  switch (action.type) {
    case Actions.WORK_ORDER_COUNT_REVIEW_FETCH_DATA_LOADING:
      return {
        ...state,
        isChartDataLoading: true,
        isChartDataLoaded: false,
      };
    case Actions.WORK_ORDER_COUNT_REVIEW_FETCH_DATA_LOADED:
      return {
        ...state,
        isChartDataLoading: false,
        isChartDataLoaded: true,
        chartData: action.payload,
      };

    case Actions.WORK_ORDER_COUNT_REVIEW_CLEAR_STATE:
      return { ...initialState };
    default:
      return state;
  }
}
