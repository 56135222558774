import { Inject, Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { HttpClient } from '@angular/common/http';
import { Store } from '@ngrx/store';
import * as oeeAppReducer from '../../oee.reducer';
import * as DailyProductionPerformanceActions from './daily-production-performance.actions';
import * as AppActions from '../../app/actions';
import { catchError, switchMap } from 'rxjs/operators';
import { of } from 'rxjs';
import { DailyProductionPerformanceService } from './daily-production-performance.service';
import { BaseOneResponseInterface } from '../../../shared/model/interface/crud-response-interface.model';
import {
  DailyProductionPerformanceActivityLogsInterface,
  DailyProductionPerformanceDataInterface,
} from './daily-production-performance.model';
import {
  ActivityLogsResponseInterface,
  IActivityLog,
} from '../../reports/root-cause-analysis/root-cause-analysis.model';
import { EntityTranslatorService } from '../../../shared/service/entity-translator/entity-translator.service';

@Injectable()
export class DailyProductionPerformanceEffects {
  constructor(
    @Inject('API_BASE_URL') private readonly baseUrl: string,
    private readonly actions$: Actions,
    private readonly http: HttpClient,
    private readonly service: DailyProductionPerformanceService,
    private store: Store<oeeAppReducer.OeeAppState>,
    private readonly entityTranslatorService: EntityTranslatorService,
  ) {}

  getDailyProductionPerformanceData = createEffect(() =>
    this.actions$.pipe(
      ofType(DailyProductionPerformanceActions.DAILY_PRODUCTION_PERFORMANCE_DATA_LOADING),
      switchMap((objectData: DailyProductionPerformanceActions.DailyProductionPerformanceDataLoading) => {
        this.store.dispatch(new AppActions.ShowLoader());

        return this.service.getDailyProductionPerformanceData(objectData.params.lineId[0]).pipe(
          switchMap((response: BaseOneResponseInterface<DailyProductionPerformanceDataInterface>) => {
            return of(
              new DailyProductionPerformanceActions.DailyProductionPerformanceDataLoaded(response.data),
              new AppActions.HideLoader(),
            );
          }),
          catchError((error) => {
            return of(
              new DailyProductionPerformanceActions.DailyProductionPerformanceFetchError(error),
              new AppActions.HideLoader(),
            );
          }),
        );
      }),
      catchError((error) => {
        return of(
          new DailyProductionPerformanceActions.DailyProductionPerformanceFetchError(error),
          new AppActions.HideLoader(),
        );
      }),
    ),
  );

  getWorkOrderActivityLogs = createEffect(() =>
    this.actions$.pipe(
      ofType(DailyProductionPerformanceActions.DAILY_PRODUCTION_PERFORMANCE_ACTIVITY_LOGS_LOADING),
      switchMap((objectData: DailyProductionPerformanceActions.DailyProductionPerformanceActivityLogsLoading) => {
        this.store.dispatch(new AppActions.ShowLoader());

        return this.service.getWorkOrderActivityLogs(objectData.params).pipe(
          switchMap((response: BaseOneResponseInterface<ActivityLogsResponseInterface>) => {
            const dailyProductionPerformanceActivityLogs: DailyProductionPerformanceActivityLogsInterface[] =
              this.service.formatActivityLogs(response.data.rows);
            dailyProductionPerformanceActivityLogs.forEach((activityLog: IActivityLog) => {
              this.entityTranslatorService.translate(activityLog);
            });
            return of(
              new DailyProductionPerformanceActions.DailyProductionPerformanceActivityLogsLoaded(
                dailyProductionPerformanceActivityLogs,
              ),
              new AppActions.HideLoader(),
            );
          }),
          catchError((error) => {
            return of(new DailyProductionPerformanceActions.DailyProductionPerformanceFetchError(error));
          }),
        );
      }),
      catchError((error) => {
        return of(
          new DailyProductionPerformanceActions.DailyProductionPerformanceFetchError(error),
          new AppActions.HideLoader(),
        );
      }),
    ),
  );
}
