import { Inject, Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import {
  BaseCrudResponse,
  BaseOneResponseInterface,
  GetManyResponseInterface,
} from '../../model/interface/crud-response-interface.model';
import { ISchedulerResourceSetting } from '../../../view/scheduler-resource-settings/scheduler-resource-settings.model';

@Injectable({
  providedIn: 'root',
})
export class SchedulerResourceSettingService {
  constructor(public http: HttpClient, @Inject('API_BASE_URL') private baseUrl: string) {}

  private readonly routes = {
    resourceSettings: '/scheduler/settings',
  };

  public getResourceSettings(httpParams: HttpParams): Observable<GetManyResponseInterface<ISchedulerResourceSetting>> {
    return this.http.get<GetManyResponseInterface<ISchedulerResourceSetting>>(
      `${this.baseUrl}${this.routes.resourceSettings}`,
      {
        params: httpParams,
      },
    );
  }

  public getResourceSetting(
    id: number,
    httpParams: HttpParams,
  ): Observable<BaseOneResponseInterface<ISchedulerResourceSetting>> {
    return this.http.get<BaseOneResponseInterface<ISchedulerResourceSetting>>(
      `${this.baseUrl}${this.routes.resourceSettings}/${id}`,
      {
        params: httpParams,
      },
    );
  }

  public deleteResourceSetting(id: number): Observable<BaseCrudResponse> {
    return this.http.delete<BaseCrudResponse>(`${this.baseUrl}${this.routes.resourceSettings}/${id}`);
  }

  public cloneResourceSetting(id: number): Observable<BaseOneResponseInterface<ISchedulerResourceSetting>> {
    return this.http.put<BaseOneResponseInterface<ISchedulerResourceSetting>>(
      `${this.baseUrl}${this.routes.resourceSettings}/${id}/clone`,
      {},
    );
  }
}
