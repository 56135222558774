import { Inject, Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import {
  BaseOneResponseInterface,
  GetManyResponseInterface,
} from '../../shared/model/interface/crud-response-interface.model';
import { Observable } from 'rxjs';
import { IFloorPlan, IFloorPlanTarget, IJobOeeResult } from './layout-plan-view.model';
import { WorkOrderScheduleInterface } from '../work-order-schedule/work-order-schedule.model';

@Injectable({
  providedIn: 'root',
})
export class LayoutPlanViewService {
  private readonly URLS = {
    FLOOR_PLANS: `${this.baseUrl}/floor-plans`,
    OEE: `${this.baseUrl}/oee-calculation/job/`,
    WORK_ORDERS: `${this.baseUrl}/work-orders`,
    TARGET: `${this.baseUrl}/targets/floor-plan/`,
  };

  constructor(
    private readonly http: HttpClient,
    @Inject('API_BASE_URL')
    private readonly baseUrl: string,
  ) {}

  public getFloorPlan(id: number, params?: HttpParams): Observable<BaseOneResponseInterface<IFloorPlan>> {
    return this.http.get<BaseOneResponseInterface<IFloorPlan>>(`${this.URLS.FLOOR_PLANS}/${id}`, { params });
  }

  public calculateJobOee(jobId: number): Observable<IJobOeeResult> {
    return this.http.get<IJobOeeResult>(`${this.URLS.OEE}${jobId}`);
  }

  public getWorkOrders(jobId?: number): Observable<GetManyResponseInterface<WorkOrderScheduleInterface>> {
    const params: HttpParams = new HttpParams()
      .set('fields', 'id,assignedLinePathId')
      .set('s', JSON.stringify({ jobId: { $eq: jobId } }))
      .set('limit', '5000');

    return this.http.get<GetManyResponseInterface<WorkOrderScheduleInterface>>(`${this.URLS.WORK_ORDERS}`, {
      params,
    });
  }

  public getFloorPlanOeeTargets(
    floorPlanId: number,
    params: HttpParams,
  ): Observable<GetManyResponseInterface<IFloorPlanTarget>> {
    return this.http.get<GetManyResponseInterface<IFloorPlanTarget>>(`${this.URLS.TARGET}${floorPlanId}`, { params });
  }

  public getDefaultFloorPlanParams(): HttpParams {
    return new HttpParams()
      .append('join', 'site||name,configuration')
      .append('join', 'linePaths||id')
      .append('join', 'linePaths.linePathOrderDetails')
      .append('join', 'floorPlanLines.line')
      .append('join', 'floorPlanLines.line.currentWorkOrder')
      .append('join', 'floorPlanLines.line.currentWorkOrder.job');
  }
}
