import { FloorPlanCRUDInterface } from '../../../shared/component/filter/filter.class';
import { FilterFloorPlanActions, FilterFloorPlanActionTypes } from './floor-plan.actions';

export interface FilterFloorPlanState {
  isLoading: boolean;
  isLoaded: boolean;
  data: FloorPlanCRUDInterface[];
}

export const initialFilterFloorPlanState: FilterFloorPlanState = {
  isLoaded: false,
  isLoading: false,
  data: [],
};

export function filterFloorPlanReducer(
  state: FilterFloorPlanState = initialFilterFloorPlanState,
  action: FilterFloorPlanActions,
): FilterFloorPlanState {
  switch (action.type) {
    case FilterFloorPlanActionTypes.FilterFloorPlansLoading:
      return { ...state, isLoading: true };
    case FilterFloorPlanActionTypes.FilterFloorPlansLoaded:
      return { ...state, data: action.data, isLoaded: true, isLoading: false };
    case FilterFloorPlanActionTypes.ResetFloorPlanStore:
      return initialFilterFloorPlanState;
    default:
      return state;
  }
}
