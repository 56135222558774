<button
  type="button"
  class="btn btn-icon-sm"
  data-toggle="tooltip"
  data-placement="left"
  title
  [disabled]="isDisabled"
  (click)="onRefreshPageClicked()"
>
  <span class="countdown-text" #countdownText>{{ countdown }}</span>
</button>
