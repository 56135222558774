export interface DatatableInterface {
  page: number;
  pageSize: number;
  search?: string;
  orderBy?: string;
  orderDesc?: boolean;
}

export enum EColumnWidth {
  checkbox = '39px',
}

export enum ETableModalTypes {
  add = 'add',
  edit = 'edit',
  bulkEdit = 'bulk-edit',
  delete = 'delete',
  bulkDelete = 'bulk-delete',
  recalculate = 'recalculate',
  summary = 'summary',
}

export interface IBulkErrorCountAndData<T> {
  count: number;
  rows: T[];
}
