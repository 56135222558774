import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { IScwMatRatingStarCollection } from './scw-mat-rating.model';

@Component({
  selector: 'scw-mat-rating',
  templateUrl: './scw-mat-rating.component.html',
  styleUrls: ['./scw-mat-rating.component.scss'],
})
export class ScwMatRatingComponent implements OnInit {
  @Input() inputModel: number = null;
  @Input() startCount: number = 5;

  @Output() inputModelChange: EventEmitter<number> = new EventEmitter<number>();

  public starCollection: IScwMatRatingStarCollection[] = [];
  public hoverValue: number = null;

  public onStarClick(star: IScwMatRatingStarCollection): void {
    this.inputModel = star.rank === this.inputModel ? null : star.rank;

    this.inputModelChange.emit(this.inputModel);
  }

  public onStarHover(star: IScwMatRatingStarCollection): void {
    this.hoverValue = star.rank;
  }

  public onStarHoverLeave(): void {
    this.hoverValue = null;
  }

  public ngOnInit(): void {
    for (let i = 1; i <= this.startCount; i += 1) {
      this.starCollection.push({
        rank: i,
      });
    }
  }
}
