import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import * as ObjectActions from './lookups.actions';
import { catchError, switchMap } from 'rxjs/operators';
import { LookupsService } from './lookups.service';
import { GetManyResponseInterface } from '../../shared/model/interface/crud-response-interface.model';
import { ILookupData } from './lookups.model';
import { of } from 'rxjs';
import { HttpParams } from '@angular/common/http';

@Injectable()
export class LookupsEffect {
  constructor(private readonly actions$: Actions, public service: LookupsService) {}


  getLookupsData = createEffect(() => this.actions$.pipe(
    ofType(ObjectActions.START_LOOKUPS_DATA_LOADING),
    switchMap((objectData: ObjectActions.StartLookupsDataLoading) => {
      let params: HttpParams = new HttpParams();

      if (objectData.id) {
        params = params.set('s', JSON.stringify({ id: objectData.id }));
      }

      return this.service.getLookups(params).pipe(
        switchMap((payload: GetManyResponseInterface<ILookupData>) => {
          return of(new ObjectActions.LookupsDataLoaded(payload.data));
        }),
        catchError((err: any) => {
          return of(new ObjectActions.FetchDataError(err));
        }),
      );
    }),
  ));
}
