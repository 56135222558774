<div class="bg-c-lightgrey metric-item d-flex flex-column h-100 p-2">
  <div class="nowrap text-center">
    <p
      [ngStyle]="{ color: color }"
      [ngClass]="{ 'm-b-0': subtitle }"
      class="head-text-2 c-title">
      {{ cardTitle }}
    </p>
    <p
      *ngIf="subtitle !== ''"
      class="head-text-2 c-title m-b-5 three-dot"
      [ngClass]="{ 'm-b-5': subtitle }">
      {{ subtitle }}
    </p>
    <p class="head-text" [ngClass]="{'mb-14px': !hideTarget}">
      {{ oee ? oee : '0' }}%
    </p>
    <p class="metric-info" *ngIf="showMetricInfo">{{ oeeInfoText }}</p>
    <div *ngIf="!hideTarget" class="d-flex justify-content-center">
      <div
        [ngStyle]="{ backgroundColor: color, borderColor: color }"
        class="oee-bar">
      <span
        [ngStyle]="{ color: targetColor }"
        class="head-text-2">
        {{ 'general.target' | translate }}: {{ target }}
      </span>
      </div>
    </div>
  </div>
</div>
