import { FilterCustomerSegmentActions, FilterCustomerSegmentActionTypes } from './customer-segment.actions';
import { ICustomerSegment } from '../../../shared/service/settings/customer-settings/customer-segments.model';

export interface FilterCustomerSegmentState {
  isLoading: boolean;
  isLoaded: boolean;
  data: Partial<ICustomerSegment>[];
}

export const initialFilterCustomerSegmentState: FilterCustomerSegmentState = {
  isLoaded: false,
  isLoading: false,
  data: [],
};

export function filterCustomerSegmentReducer(
  state = initialFilterCustomerSegmentState,
  action: FilterCustomerSegmentActions,
): FilterCustomerSegmentState {
  switch (action.type) {
    case FilterCustomerSegmentActionTypes.StartLoadFilterCustomerSegments:
      return { ...state, isLoading: true, isLoaded: false, data: [] };

    case FilterCustomerSegmentActionTypes.FilterCustomerSegmentsLoaded:
      return { ...state, data: action.data, isLoaded: true, isLoading: false };

    default:
      return state;
  }
}
