<div class="w-100 h-100 mb-1 text-center p-3 br-5" [class.center-text]="!bottomText && !bottomTextAlt" [style.background-color]="backgroundColour">
  <p class="top-text three-dot m-0 pb-3" [style.color]="textColour">
    {{ topText }}
  </p>
  <p *ngIf="bottomText" class="bottom-text three-dot m-0" [style.color]="textColour">
    {{ bottomText }}
  </p>
  <p *ngIf="bottomTextAlt" class="bottom-text three-dot m-0" [style.color]="textColour">
    {{ bottomTextAlt }}
  </p>
</div>
