import { Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'scw-simple-list',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './simple-list.component.html',
  styleUrls: ['simple-list.component.scss'],
})
export class SimpleListComponent {
  @Input() topLabel: string = '';
  @Input() list: { name: string }[] = [];
}
