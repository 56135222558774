import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LaborAssetHomeComponent } from './labor-asset-home.component';
import { SharedModule } from '../../../shared/shared.module';
import { LaborAssetViewModule } from '../../dashboards/labor-asset-view/labor-asset-view.module';

@NgModule({
  imports: [CommonModule, SharedModule, LaborAssetViewModule],
  declarations: [LaborAssetHomeComponent],
  exports: [LaborAssetHomeComponent],
})
export class LaborAssetHomeModule {}
