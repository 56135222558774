import * as Actions from './production-view.actions';
import { IProductionViewState } from './production-view.model';

export const productionViewState: IProductionViewState = {
  isProductionDataLoading: false,
  isProductionDataLoaded: false,
  data: [],
  isTagDataLoading: false,
  isTagDataLoaded: false,
  tags: [],
};

export function productionViewReducer(
  state: IProductionViewState = productionViewState,
  action: Actions.ProductionPitchViewActions,
): IProductionViewState {
  switch (action.type) {
    case Actions.GET_PRODUCTION_VIEW_DATA:
      return {
        ...state,
        isProductionDataLoading: true,
        isProductionDataLoaded: false,
      };
    case Actions.GET_PRODUCTION_VIEW_DATA_COMPLETED:
      return {
        ...state,
        isProductionDataLoading: false,
        isProductionDataLoaded: true,
        data: [action.data],
      };
    case Actions.GET_TAGS_DATA:
      return {
        ...state,
        isTagDataLoading: true,
        isTagDataLoaded: false,
      };
    case Actions.GET_TAGS_DATA_COMPLETED:
      return {
        ...state,
        isTagDataLoading: false,
        isTagDataLoaded: true,
        tags: action.data,
      };
    default:
      return state;
  }
}
