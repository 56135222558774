import * as Actions from './job-performance.actions';
import { IJobPerformanceState } from './job-performance.model';

export const JobPerformanceState: IJobPerformanceState = {
  getWorkOrdersOfJobLoading: false,
  getWorkOrdersOfJobLoaded: false,
  getTableDataLoading: false,
  getTableDataLoaded: false,
  getOeeSelectedWorkOrdersLoading: false,
  getOeeSelectedWorkOrdersLoaded: false,
  getOeeOfJobLoading: false,
  getOeeOfJobLoaded: false,
  getActivityLogLoading: false,
  getActivityLogLoaded: false,
  getLaborInformationLoading: false,
  getLaborInformationLoaded: false,
  getOeeBySqlModeLoading: false,
  getOeeBySqlModeLoaded: false,
  downloadJobPerformanceTableExcel: false,
  downloadJobPerformanceTableExcelCompleted: false,
  activityLogs: null,
  workOrders: [],
  selectedWorkOrders: [],
  selectedWorkOrderIds: [],
  oee: [],
  tableData: null,
  laborInformation: [],
  oeeBySqlMode: [],
  jobCountOee: [],
};

export function jobPerformanceReducer(
  state: IJobPerformanceState = JobPerformanceState,
  action: Actions.JobPerformanceActions,
): IJobPerformanceState {
  switch (action.type) {
    case Actions.GET_WORK_ORDERS_OF_JOB:
      return {
        ...state,
        ...{
          getWorkOrdersOfJobLoading: true,
          getWorkOrdersOfJobLoaded: false,
        },
      };
    case Actions.GET_WORK_ORDERS_OF_JOB_COMPLETED:
      return {
        ...state,
        ...{
          getWorkOrdersOfJobLoading: false,
          getWorkOrdersOfJobLoaded: true,
          workOrders: action.payload.data,
        },
      };
    case Actions.SET_SELECTED_WORK_ORDERS:
      return {
        ...state,
        ...{
          selectedWorkOrders: action.workOrders,
          selectedWorkOrderIds: action.workOrderIds,
        },
      };
    case Actions.GET_TABLE_DATA:
      return {
        ...state,
        ...{
          getTableDataLoading: true,
          getTableDataLoaded: false,
        },
      };
    case Actions.GET_TABLE_DATA_COMPLETED:
      return {
        ...state,
        ...{
          getTableDataLoading: false,
          getTableDataLoaded: true,
          tableData: action.payload,
        },
      };
    case Actions.GET_OEE_SELECTED_WORK_ORDERS:
      return {
        ...state,
        ...{
          getOeeOfJobLoading: false,
          getOeeOfJobLoaded: false,
          getOeeSelectedWorkOrdersLoading: true,
          getOeeSelectedWorkOrdersLoaded: false,
        },
      };
    case Actions.GET_OEE_SELECTED_WORK_ORDERS_COMPLETED:
      return {
        ...state,
        ...{
          getOeeSelectedWorkOrdersLoading: false,
          getOeeSelectedWorkOrdersLoaded: true,
          oee: action.payload.data,
          jobCountOee: [],
        },
      };
    case Actions.GET_OEE_OF_JOB:
      return {
        ...state,
        ...{
          getOeeSelectedWorkOrdersLoading: false,
          getOeeSelectedWorkOrdersLoaded: false,
          getOeeOfJobLoading: true,
          getOeeOfJobLoaded: false,
        },
      };
    case Actions.GET_OEE_OF_JOB_COMPLETED:
      return {
        ...state,
        ...{
          getOeeOfJobLoading: false,
          getOeeOfJobLoaded: true,
          oee: [action.payload.data.jobOee],
          jobCountOee: action.payload.data.jobCountOee,
        },
      };
    case Actions.GET_LABOR_INFORMATION:
      return {
        ...state,
        getLaborInformationLoading: true,
        getLaborInformationLoaded: false,
      };

    case Actions.GET_LABOR_INFORMATION_COMPLETED:
      return {
        ...state,
        getLaborInformationLoading: true,
        getLaborInformationLoaded: false,
        laborInformation: action.payload,
      };
    case Actions.GET_OEE_BY_SQL_MODE:
      return {
        ...state,
        getOeeBySqlModeLoading: true,
        getOeeBySqlModeLoaded: false,
      };

    case Actions.GET_OEE_BY_SQL_MODE_COMPLETED:
      return {
        ...state,
        getOeeBySqlModeLoading: true,
        getOeeBySqlModeLoaded: false,
        oeeBySqlMode: action.payload,
      };
    case Actions.GET_ACTIVITY_LOG:
      return {
        ...state,
        ...{
          getActivityLogLoading: true,
          getActivityLogLoaded: false,
        },
      };
    case Actions.GET_ACTIVITY_LOG_COMPLETED:
      return {
        ...state,
        ...{
          getActivityLogLoading: false,
          getActivityLogLoaded: true,
          activityLogs: action.payload.data,
        },
      };
    case Actions.DOWNLOAD_JOB_PERFORMANCE_TABLE_EXCEL:
      return {
        ...state,
        ...{
          downloadJobPerformanceTableExcel: true,
          downloadJobPerformanceTableExcelCompleted: false,
        },
      };
    case Actions.DOWNLOAD_JOB_PERFORMANCE_TABLE_EXCEL_COMPLETED:
      return {
        ...state,
        ...{
          downloadJobPerformanceTableExcel: false,
          downloadJobPerformanceTableExcelCompleted: true,
        },
      };
    case Actions.CLEAR_STATE:
      return {
        ...JobPerformanceState,
      };
    case Actions.FETCH_ERROR:
      return {
        ...state,
        ...{
          JobPerformanceState,
        },
      };
    default:
      return state;
  }
}
