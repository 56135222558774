import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import * as ObjectActions from './file-upload.actions';
import { of, switchMap } from 'rxjs';
import { FileUploadService } from './file-upload.service';
import { catchError } from 'rxjs/operators';
import * as AppActions from '../app/actions';
import {
  BaseOneResponseInterface,
  BulkResponseDataInterface,
} from '../../shared/model/interface/crud-response-interface.model';
import { IDeleteFileResponse, IGetFileResponse, IUploadFileResponse } from './file-upload.model';
import { Store } from '@ngrx/store';
import { OeeAppState } from '../oee.reducer';

@Injectable()
export class FileUploadEffects {
  constructor(
    private actions$: Actions,
    private service: FileUploadService,
    private readonly store: Store<OeeAppState>,
  ) {}

  getOneFile = createEffect(() =>
    this.actions$.pipe(
      ofType(ObjectActions.GET_FILE_LOADING),
      switchMap((objectData: ObjectActions.GetFileLoading) => {
        this.store.dispatch(new AppActions.ShowLoader());

        return this.service.getOneFile(objectData.params).pipe(
          switchMap((resData: BaseOneResponseInterface<IGetFileResponse>) => {
            return of(new ObjectActions.GetFileLoaded(resData.data), new AppActions.HideLoader());
          }),
          catchError(() => {
            return of(new AppActions.HideLoader(), new AppActions.HideTopLoader());
          }),
        );
      }),
      catchError(() => {
        return of(new AppActions.HideLoader(), new AppActions.HideTopLoader());
      }),
    ),
  );

  getFileByFolderId = createEffect(() =>
    this.actions$.pipe(
      ofType(ObjectActions.GET_FILES_BY_FOLDER_ID_LOADING),
      switchMap((objectData: ObjectActions.GetFilesByFolderIdLoading) => {
        this.store.dispatch(new AppActions.ShowLoader());

        return this.service.getFileByFolderId(objectData.params).pipe(
          switchMap((resData: BaseOneResponseInterface<IGetFileResponse[]>) => {
            return of(new ObjectActions.GetFilesByFolderIdLoaded(resData.data), new AppActions.HideLoader());
          }),
          catchError(() => {
            return of(new AppActions.HideLoader(), new AppActions.HideTopLoader());
          }),
        );
      }),
      catchError(() => {
        return of(new AppActions.HideLoader(), new AppActions.HideTopLoader());
      }),
    ),
  );

  getFiles = createEffect(() =>
    this.actions$.pipe(
      ofType(ObjectActions.GET_FILES_LOADING),
      switchMap((objectData: ObjectActions.GetFilesLoading) => {
        return this.service.getFiles(objectData.params).pipe(
          switchMap((resData: BaseOneResponseInterface<IGetFileResponse[]>) => {
            return of(new ObjectActions.GetFilesLoaded(resData.data));
          }),
          catchError(() => {
            return of(new AppActions.HideLoader(), new AppActions.HideTopLoader());
          }),
        );
      }),
      catchError(() => {
        return of(new AppActions.HideLoader(), new AppActions.HideTopLoader());
      }),
    ),
  );

  uploadManyFile = createEffect(() =>
    this.actions$.pipe(
      ofType(ObjectActions.BULK_UPLOAD_FILE_LOADING),
      switchMap((objectData: ObjectActions.BulkUploadFileLoading) => {
        this.store.dispatch(new AppActions.ShowLoader());

        return this.service.uploadManyFile(objectData.params).pipe(
          switchMap((response: BaseOneResponseInterface<IUploadFileResponse[]>) => {
            return of(new ObjectActions.BulkUploadFileLoaded(response), new AppActions.HideLoader());
          }),
          catchError(() => {
            return of(new AppActions.HideLoader(), new AppActions.HideTopLoader());
          }),
        );
      }),
      catchError(() => {
        return of(new AppActions.HideLoader(), new AppActions.HideTopLoader());
      }),
    ),
  );

  deleteFile = createEffect(() =>
    this.actions$.pipe(
      ofType(ObjectActions.DELETE_FILE_LOADING),
      switchMap((objectData: ObjectActions.DeleteFileLoading) => {
        this.store.dispatch(new AppActions.ShowLoader());

        return this.service.deleteFile(objectData.params).pipe(
          switchMap((response: IDeleteFileResponse) => {
            return of(new ObjectActions.DeleteFileLoaded(response), new AppActions.HideLoader());
          }),
          catchError(() => {
            return of(new AppActions.HideLoader(), new AppActions.HideTopLoader());
          }),
        );
      }),
      catchError(() => {
        return of(new AppActions.HideLoader(), new AppActions.HideTopLoader());
      }),
    ),
  );

  deleteManyFile = createEffect(() =>
    this.actions$.pipe(
      ofType(ObjectActions.DELETE_MANY_FILE_LOADING),
      switchMap((objectData: ObjectActions.DeleteManyFileLoading) => {
        this.store.dispatch(new AppActions.ShowLoader());
        return this.service.deleteManyFile(objectData.params).pipe(
          switchMap((response: ObjectActions.DeleteManyFileLoaded) => {
            return of(new ObjectActions.DeleteManyFileLoaded(response.success), new AppActions.HideLoader());
          }),
          catchError(() => {
            return of(new AppActions.HideLoader(), new AppActions.HideTopLoader());
          }),
        );
      }),
      catchError(() => {
        return of(new AppActions.HideLoader(), new AppActions.HideTopLoader());
      }),
    ),
  );

  deleteManyFileByFilePath = createEffect(() =>
    this.actions$.pipe(
      ofType(ObjectActions.DELETE_MANY_FILE_BY_FILE_PATH_LOADING),
      switchMap((objectData: ObjectActions.DeleteManyFileByFilePathLoading) => {
        this.store.dispatch(new AppActions.ShowLoader());

        return this.service.deleteManyFileByFilePath(objectData.params).pipe(
          switchMap((response: BulkResponseDataInterface) => {
            return of(new ObjectActions.DeleteManyFileByFilePathLoaded(response), new AppActions.HideLoader());
          }),
          catchError(() => {
            return of(new AppActions.HideLoader(), new AppActions.HideTopLoader());
          }),
        );
      }),
      catchError(() => {
        return of(new AppActions.HideLoader(), new AppActions.HideTopLoader());
      }),
    ),
  );
}
