import * as Actions from './sensor-statuses.actions';
import { ISensorStatusState } from './sensor-statuses.model';

export const sensorStatusesInitialState: ISensorStatusState = {
  sensorStatusDetails: [],
  sensorStatusLoaded: false,
  sensorStatusLoading: false,
};

export function sensorStatusesReducer(
  state: ISensorStatusState = sensorStatusesInitialState,
  action: Actions.SensorStatusesActions,
): ISensorStatusState {
  switch (action.type) {
    case Actions.GET_SENSOR_STATUSES:
      return {
        ...state,
        sensorStatusLoading: true,
        sensorStatusLoaded: false,
      };
    case Actions.GET_SENSOR_STATUSES_COMPLETED:
      return {
        ...state,
        sensorStatusDetails: action.payload,
        sensorStatusLoading: false,
        sensorStatusLoaded: true,
      };
    default:
      return state;
  }
}
