import { Inject, Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, switchMap } from 'rxjs/operators';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Store } from '@ngrx/store';
import * as ObjectActions from './sites.actions';
import * as oeeAppReducer from '../../oee.reducer';
import { from, of } from 'rxjs';
import { ISitesCrudResponse } from './sites.model';
import * as AppActions from '../../app/actions';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';
import { ErrorMessageService } from '../../../shared/service/error-message.service';
import { ExcelHelperService } from '../../../shared/service/excel/excel-helper.service';
import { SiteCRUDInterface } from '../../../shared/component/filter/filter.class';
import { GetManyResponseInterface } from '../../../shared/model/interface/crud-response-interface.model';
import { SitesService } from '../../../shared/service/settings/sites/sites.service';

@Injectable()
export class SitesEffects {
  constructor(
    @Inject('API_BASE_URL') private readonly baseUrl: string,
    private readonly actions$: Actions,
    private readonly http: HttpClient,
    private readonly service: SitesService,
    private readonly toast: ToastrService,
    private readonly translate: TranslateService,
    private readonly errorMessageService: ErrorMessageService,
    private readonly excelHelperService: ExcelHelperService,
    private readonly store: Store<oeeAppReducer.OeeAppState>,
  ) {}

  getSitesData = createEffect(() =>
    this.actions$.pipe(
      ofType(ObjectActions.LOAD_SITES_DATA),
      switchMap((objectData: ObjectActions.LoadSitesData) => {
        let params: HttpParams = new HttpParams()
          .set('limit', objectData.requestParams.pageSize)
          .set('page', objectData.requestParams.page);

        if (objectData.getFileSizeLimit) {
          params = params.append('getFileSizeLimit', true);
        }

        if (objectData.requestParams.sort) {
          const direction: 'DESC' | 'ASC' = objectData.requestParams.sort.type === 'descending' ? 'DESC' : 'ASC';
          params = params.set('sort', `${objectData.requestParams.sort.column},${direction}`);
        }

        return from(this.service.getSites(params)).pipe(
          switchMap((response: GetManyResponseInterface<SiteCRUDInterface>) => {
            return of(new ObjectActions.LoadedSitesData(response));
          }),
          catchError((err) => {
            return of(new ObjectActions.SitesFetchError(err));
          }),
        );
      }),
    ),
  );

  editSingleSitesData = createEffect(() =>
    this.actions$.pipe(
      ofType(ObjectActions.SINGLE_EDIT_SITES_DATA),
      switchMap((objectData: ObjectActions.SingleEditSitesData) => {
        this.store.dispatch(new AppActions.ShowLoader());

        return from(this.service.patchSiteData(objectData.siteId, objectData.payload)).pipe(
          switchMap((response: ISitesCrudResponse) => {
            return of(new ObjectActions.EditedSingleEditSitesData(response), new AppActions.HideLoader());
          }),
          catchError((err) => {
            return of(new ObjectActions.SitesFetchError(err), new AppActions.HideLoader());
          }),
        );
      }),
    ),
  );

  editSitesData = createEffect(() =>
    this.actions$.pipe(
      ofType(ObjectActions.EDIT_SITES_DATA),
      switchMap((objectData: ObjectActions.EditSitesData) => {
        this.store.dispatch(new AppActions.ShowLoader());

        return from(this.service.patchSitesData(objectData.payload)).pipe(
          switchMap((response) => {
            return of(new ObjectActions.EditedSitesData(response), new AppActions.HideLoader());
          }),
          catchError((err) => {
            return of(new ObjectActions.SitesFetchError(err), new AppActions.HideLoader());
          }),
        );
      }),
    ),
  );

  createSite = createEffect(() =>
    this.actions$.pipe(
      ofType(ObjectActions.CREATE_SITE),
      switchMap((objectData: ObjectActions.CreateSite) => {
        this.store.dispatch(new AppActions.ShowLoader());

        return from(this.service.createSite(objectData.payload)).pipe(
          switchMap((response: ISitesCrudResponse) => {
            return of(new ObjectActions.CreatedSite(response), new AppActions.HideLoader());
          }),
          catchError((err) => {
            return of(new ObjectActions.SitesFetchError(err), new AppActions.HideLoader());
          }),
        );
      }),
    ),
  );

  deleteSite = createEffect(() =>
    this.actions$.pipe(
      ofType(ObjectActions.DELETE_SITE),
      switchMap((objectData: ObjectActions.DeleteSite) => {
        this.store.dispatch(new AppActions.ShowLoader());

        return from(this.service.deleteSite(objectData.siteId)).pipe(
          switchMap((response: ISitesCrudResponse) => {
            return of(new ObjectActions.DeletedSite(response), new AppActions.HideLoader());
          }),
          catchError((err) => {
            return of(new ObjectActions.SitesFetchError(err), new AppActions.HideLoader());
          }),
        );
      }),
    ),
  );

  deleteSites = createEffect(() =>
    this.actions$.pipe(
      ofType(ObjectActions.DELETE_SITES),
      switchMap((objectData: ObjectActions.DeleteSites) => {
        this.store.dispatch(new AppActions.ShowLoader());

        return from(this.service.deleteSites(objectData.siteIds)).pipe(
          switchMap((response) => {
            return of(new ObjectActions.DeletedSites(response), new AppActions.HideLoader());
          }),
          catchError((err) => {
            return of(new ObjectActions.SitesFetchError(err), new AppActions.HideLoader());
          }),
        );
      }),
    ),
  );
}
