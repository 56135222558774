import { Component, ElementRef, EventEmitter, Input, OnDestroy, Output } from '@angular/core';
import { Subject, Subscription } from 'rxjs';
import { takeWhile } from 'rxjs/operators';
import * as _ from 'lodash';
import { EWidgetType, WidgetConfigurationInterface } from '../../page-configuration/page-configuration.model';
import { ComponentUtilities } from '../../../helper/component-utilities';
import { IScwMatKpiCard } from '../scw-mat-kpi-card/scw-mat-kpi-card.model';

@Component({
  selector: 'scw-mat-kpi-card-wrapper',
  templateUrl: './scw-mat-kpi-card-wrapper.component.html',
  styleUrls: ['./scw-mat-kpi-card-wrapper.component.scss'],
})
export class ScwMatKpiCardWrapperComponent implements OnDestroy {
  @Input() configurations: WidgetConfigurationInterface<EWidgetType.KPI_CARD>[] = [];
  @Input() loading: boolean = false;
  @Input() isPrintable: boolean = false;
  @Output() inputModelChange: EventEmitter<any> = new EventEmitter<any>();
  private resizeSubscription: Subscription;
  public readonly datatableHeaderTrackBy = ComponentUtilities.genericTrackByItemFunction;
  public cardClassResizeObserverSubject: Subject<Element> = new Subject<Element>();
  public relatedWorkOrderCardResizeObserver = new ResizeObserver((value: ResizeObserverEntry[]) => {
    if (this.configurations && value?.[0]?.target) {
      this.cardClassResizeObserverSubject.next(value[0].target);
    }
  });

  constructor(private elementRef: ElementRef) {}

  public ngAfterViewInit(): void {
    this.relatedWorkOrderCardResizeObserver.observe(this.elementRef.nativeElement);

    this.resizeSubscription = this.cardClassResizeObserverSubject
      .asObservable()
      .pipe(takeWhile(() => !_.isNil(this.configurations)))
      .subscribe(this.setClassesAgain.bind(this));
  }

  public selectBoxInputModelChange(event): void {
    this.inputModelChange.emit(event);
  }

  public setKpiCardClasses = (cardClass: string, index: number) => {
    return this.calculateClass(index);
  };

  public updateContent = (content: IScwMatKpiCard, selected: boolean) => {
    return { ...content, disabled: selected !== undefined && !selected };
  };

  public setClassesAgain(): void {
    this.configurations = this.configurations.map(
      (configuration: WidgetConfigurationInterface<EWidgetType.KPI_CARD>, index: number) => {
        if (configuration.content) {
          return {
            ...configuration,
            content: {
              ...configuration.content,
              class: this.calculateClass(index),
            },
          };
        }

        return configuration;
      },
    );
  }

  public calculateClass(index: number): string {
    const cardsCount: number = this.configurations.length;
    const widthValue: number = 12 / cardsCount;
    let cardClass: string;

    if (cardsCount === 6) {
      cardClass = `col-xl-${widthValue} col-lg-4 col-sm-4 col-xs-12`;
    } else if (cardsCount === 5) {
      cardClass = index < 3 ? 'col-xl col-sm-4 col-xs-12' : 'col-xl col-sm-6 col-xs-12';
    } else if (cardsCount === 4) {
      cardClass = `col-xl col-lg col-md-6 col-xs-12`;
    } else {
      cardClass = `col-xl-${widthValue} col-lg-${widthValue} col-md-${widthValue} col-sm-${widthValue} col-sm-12`;
    }

    return cardClass;
  }

  public ngOnDestroy(): void {
    if (this.resizeSubscription) {
      this.resizeSubscription.unsubscribe();
    }

    if (this.relatedWorkOrderCardResizeObserver) {
      this.relatedWorkOrderCardResizeObserver.disconnect();
    }
  }
}
