import { Inject, Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import {
  DoNotDisturbScheduleCreateRequestBody,
  DoNotDisturbScheduleUpdateRequestBody,
  IDoNotDisturbScheduleCrudData,
  TDoNotDisturbScheduleBulkUpdateEntry,
} from '../../standalone/do-not-disturb-schedule/do-not-disturb-schedule.model';
import {
  BaseOneResponseInterface,
  BulkResponseDataInterface,
  GetManyResponseInterface,
} from '../../shared/model/interface/crud-response-interface.model';

@Injectable({ providedIn: 'root' })
export class DoNotDisturbScheduleService {
  private static readonly apiPath: string = '/scw-notification/notification-dnd-schedules';

  constructor(@Inject('API_BASE_URL') private readonly api: string, private readonly http: HttpClient) {}

  public bulkUpdateDoNotDisturbSchedule(
    notificationDoNotDisturbSchedules: readonly TDoNotDisturbScheduleBulkUpdateEntry[],
  ): Observable<BulkResponseDataInterface> {
    return this.http.patch<BulkResponseDataInterface>(`${this.api}${DoNotDisturbScheduleService.apiPath}/bulk/edit`, {
      notificationDoNotDisturbSchedules,
    });
  }

  public createDoNotDisturbSchedule(
    params: DoNotDisturbScheduleCreateRequestBody,
  ): Observable<BaseOneResponseInterface<IDoNotDisturbScheduleCrudData>> {
    return this.http.post<BaseOneResponseInterface<IDoNotDisturbScheduleCrudData>>(
      `${this.api}${DoNotDisturbScheduleService.apiPath}`,
      {
        ...params,
        isActive: true,
      },
    );
  }

  public updateDoNotDisturbSchedule(
    id: number,
    params: DoNotDisturbScheduleUpdateRequestBody,
  ): Observable<BaseOneResponseInterface<IDoNotDisturbScheduleCrudData>> {
    return this.http.patch<BaseOneResponseInterface<IDoNotDisturbScheduleCrudData>>(
      `${this.api}${DoNotDisturbScheduleService.apiPath}/${id}`,
      params,
    );
  }

  public deleteDoNotDisturbSchedule(id: number): Observable<void> {
    return this.http.delete<void>(`${this.api}${DoNotDisturbScheduleService.apiPath}/${id}`);
  }

  public getDoNotDisturbSchedules(
    httpParams: HttpParams,
  ): Observable<GetManyResponseInterface<IDoNotDisturbScheduleCrudData>> {
    return this.http.get<GetManyResponseInterface<IDoNotDisturbScheduleCrudData>>(
      `${this.api}${DoNotDisturbScheduleService.apiPath}`,
      { params: httpParams },
    );
  }
}
