import { ITagFilterState } from './tag.model';
import { ELoadStatus } from '../../../../constants.model';
import { ActionTypes, TagActions } from './tag.actions';

export const initialState: ITagFilterState = {
  loadStatus: ELoadStatus.Initial,
  data: [],
};

export function tagFilterReducer(state: ITagFilterState = initialState, action: TagActions): ITagFilterState {
  switch (action.type) {
    case ActionTypes.GetTags:
      return { ...state, loadStatus: ELoadStatus.Loading };
    case ActionTypes.GetTagsLoaded:
      return { ...state, loadStatus: ELoadStatus.Success, data: action.response.data };
    case ActionTypes.FetchError:
      return { ...state, loadStatus: ELoadStatus.Failure };
    case ActionTypes.ResetStore:
      return initialState;
    default:
      return state;
  }
}
