import * as Actions from './line-station.actions';
import { LineStationStateInterface } from './line-station.model';

const initialState: LineStationStateInterface = {
  lineStationLoading: false,
  lineStationLoaded: false,
  lineStationData: {
    data: [],
    count: 0,
    page: 0,
    total: 0,
    pageCount: 0,
    success: false,
    date: null,
  },
  lineStation: null,
};

export function lineStationReducer(
  state: LineStationStateInterface = initialState,
  action: Actions.LineStationActions,
): LineStationStateInterface {
  switch (action.type) {
    case Actions.LINE_STATION_DATA_LOADING:
      return {
        ...state,
        lineStationLoading: true,
        lineStationLoaded: false,
      };
    case Actions.LINE_STATION_DATA_LOADED:
      return {
        ...state,
        lineStationLoading: false,
        lineStationLoaded: true,
        lineStationData: action.payload,
      };
    case Actions.GET_ONE_LINE_STATION_DATA_LOADING:
      return {
        ...state,
        lineStationLoading: true,
        lineStationLoaded: false,
      };
    case Actions.GET_ONE_LINE_STATION_DATA_LOADED:
      return {
        ...state,
        lineStation: action.payload,
        lineStationLoading: false,
        lineStationLoaded: true,
      };
    case Actions.LINE_STATION_FETCH_ERROR:
      return {
        ...state,
        lineStationLoading: false,
        lineStationLoaded: true,
      };

    default:
      return state;
  }
}
