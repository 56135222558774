<div class="modal-body">
  <div>
    <p class="modal-text">
      {{ overlappingActivityInformationText }}
    </p>
  </div>
  <div class="row">
    <div class="col-12">
      <div class="btn-group" role="group">
        <button type="button" class="btn btn-gray btn-sm " (click)="zoomInAndOut(EZoomEvents.ZOOM_IN)"
                [disabled]="currentZoomLevel >= this.maxZoomLevel">
          <em class="fas fa-search-plus"></em>
        </button>
        <button type="button" class="btn btn-gray btn-sm" (click)="zoomInAndOut(EZoomEvents.ZOOM_OUT)"
                [disabled]="currentZoomLevel <= this.minZoomLevel">
          <em class="fas fa-search-minus"></em>
        </button>
      </div>
    </div>
  </div>
  <div class="bryntum-current-version">
    <p class="mini-title">
      {{ 'overlappingActivity.form.currentVersion' | translate }}
    </p>
    <bry-scheduler
      #scheduler
      class="bry-scheduler"
      [eventRenderer]="eventRenderer"
      [events]="activitiesOfCurrentVersion"
      [minHeight]="100"
      [height]="200"
      [maxHeight]="400"
      [resources]="resources"
      [rowHeight]="40"
      [maxZoomLevel]="maxZoomLevel"
      [minZoomLevel]="minZoomLevel"
      [timeRangesFeature]="timeRanges"
      [timeRanges]="timeRangesObject"
      [startDate]="startDateOverlap"
      [endDate]="endDateOverlap"
      [panFeature]="false"
      [eventTooltipFeature]="false"
      [viewPreset]="viewPreset"
      [readOnly]="true"
      [zoomLevel]="currentZoomLevel"
    ></bry-scheduler>
  </div>
  <div *ngIf="hasFixedVersion" class="bryntum-fixed-version">
    <p class="mini-title">
      {{ 'overlappingActivity.form.fixedVersion' | translate }}
    </p>
    <bry-scheduler
      #scheduler
      class="bry-scheduler"
      [eventRenderer]="eventRenderer"
      [events]="activitiesOfFixedVersion"
      [minHeight]="100"
      [height]="200"
      [maxHeight]="400"
      [resources]="resources"
      [rowHeight]="40"
      [maxZoomLevel]="maxZoomLevel"
      [minZoomLevel]="minZoomLevel"
      [timeRangesFeature]="timeRanges"
      [timeRanges]="timeRangesObject"
      [startDate]="startDateOverlap"
      [endDate]="endDateOverlap"
      [panFeature]="false"
      [eventTooltipFeature]="false"
      [viewPreset]="viewPreset"
      [readOnly]="true"
      [zoomLevel]="currentZoomLevel"
    ></bry-scheduler>
  </div>
</div>
<div class="modal-footer">
  <div class="buttons-left">
    <scw-mat-button type="danger" (onClick)="acceptOverlap(true)" data-automation-id="accept-overlap-btn">
      {{ 'overlappingActivity.form.acceptOverlap' | translate }}
    </scw-mat-button>
  </div>
  <div class="buttons-right">
    <scw-mat-button type="standard" (onClick)="backToActivityEdit.emit()">
      {{ 'general.cancel' | translate }}
    </scw-mat-button>
    <scw-mat-button *ngIf="hasFixedVersion" (onClick)="acceptFixedVersion(true)" data-automation-id="apply-fixed-version-btn">
      {{ 'overlappingActivity.button.applyFixedVersion' | translate }}
    </scw-mat-button>
    <scw-mat-button *ngIf="!hasFixedVersion" (onClick)="backToEdit()" data-automation-id="back-to-edit-btn">
      {{ 'overlappingActivity.form.backToEdit' | translate }}
    </scw-mat-button>
  </div>
</div>
