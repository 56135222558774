import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ScwMatKpiCardWrapperComponent } from './scw-mat-kpi-card-wrapper.component';
import { ScwMatKpiCardModule } from '../scw-mat-kpi-card/scw-mat-kpi-card.module';
import { CallFunctionPipe } from '../../../pipe/call-function.pipe';

@NgModule({
  declarations: [ScwMatKpiCardWrapperComponent],
  imports: [CommonModule, ScwMatKpiCardModule, CallFunctionPipe],
  exports: [ScwMatKpiCardWrapperComponent],
})
export class ScwMatKpiCardWrapperModule {}
