<div class="modal-header scw-modal-header">
  <h3 class="modal-title scw-header">
    {{ 'modal.header.activityHistoryAuditLog' | translate }}
  </h3>
  <button type="button" class="close" onfocus="blur()" aria-label="Close" (click)="closeActivityHistoryAuditLogModal()">
    <span aria-hidden="true">
      <em class="fas fa-times"></em>
    </span>
  </button>
</div>
<div class="modal-body">
  <ng-container *ngIf="noValueText">
    {{ noValueText }}
  </ng-container>
  <ng-container *ngIf="!noValueText">
    <ng-container *ngFor="let log of activityHistoryAuditLog; trackBy: genericTrackByIdFunction; index as index">
      <div class="change-entry">
        <div class="d-flex justify-content-between align-items-center mb-3">
          <span class="user-info"
            >{{ log.after?.afterUpdatedBy ? log.after?.afterUpdatedBy : log.after?.afterEndedBy }}
            <span class="grey-text">
              {{
                log.action === 'update'
                  ? ('activityHistoryAuditLog.changedFields' | translate)
                  : ('activityHistoryAuditLog.finishedActivity' | translate)
              }}
            </span>
          </span>
          <span class="grey-text">
            {{
              log.action === 'update'
                ? (log.after.changedAt | momentDate : dateTimeFormat$ : undefined : undefined : timezone$)
                : (log.after.createdAt | momentDate : dateTimeFormat$ : undefined : undefined : timezone$)
            }}
          </span>
        </div>
        <div *ngIf="log.before?.workOrderId !== log.after?.workOrderId" class="field-change mr-3 mb-3">
          <strong class="mr-3">{{ 'activityHistory.form.workOrderNumber' | translate }}</strong>
          {{ log.before?.beforeWorkOrder ?? 'N/A' }} <span class="fas fa-arrow-right"></span>
          {{ log.after?.afterWorkOrder ?? 'N/A' }}
        </div>
        <div *ngIf="log.before?.beforeTask !== log.after?.afterTask" class="field-change mr-3 mb-3">
          <strong class="mr-3">{{ 'activityHistory.form.selectTask' | translate }}</strong>
          {{ log.before?.beforeTask ?? 'N/A' }} <span class="fas fa-arrow-right"></span>
          {{ log.after?.afterTask ?? 'N/A' }}
        </div>
        <div *ngIf="log.before?.beforeActivity !== log.after?.afterActivity" class="field-change mr-3 mb-3">
          <strong class="mr-3">{{ 'activityHistory.form.activity' | translate }}</strong>
          {{ log.before?.beforeActivity ?? 'N/A' }} <span class="fas fa-arrow-right"></span>
          {{ log.after?.afterActivity ?? 'N/A' }}
        </div>
        <div *ngIf="log.before?.start !== log.after?.start" class="field-change mr-3 mb-3">
          <strong class="mr-3">{{ 'general.start' | translate }}</strong>
          {{ log.before?.start | momentDate : dateTimeFormat$ : undefined : undefined : timezone$ }}
          <span class="fas fa-arrow-right"></span>
          {{ log.after?.start | momentDate : dateTimeFormat$ : undefined : undefined : timezone$ }}
        </div>
        <div *ngIf="log.before?.end !== log.after?.end" class="field-change mr-3 mb-3">
          <strong class="mr-3">{{ 'general.end' | translate }}</strong>
          {{ log.before?.end | momentDate : dateTimeFormat$ : undefined : undefined : timezone$ }}
          <span class="fas fa-arrow-right"></span>
          {{ log.after?.end | momentDate : dateTimeFormat$ : undefined : undefined : timezone$ }}
        </div>
        <div *ngIf="log.before?.beforeSite !== log.after?.afterSite" class="field-change mr-3 mb-3">
          <strong class="mr-3">{{ 'activityHistory.form.site' | translate }}</strong> {{ log.before?.beforeSite }}
          <span class="fas fa-arrow-right"></span> {{ log.after?.afterSite }}
        </div>
        <div *ngIf="log.before?.crewSize !== log.after?.crewSize" class="field-change mr-3 mb-3">
          <strong class="mr-3">{{ 'activityHistory.form.crewSize' | translate }}</strong>
          {{ log.before?.crewSize | formatDecimalToUserScale }} <span class="fas fa-arrow-right"></span>
          {{ log.after?.crewSize | formatDecimalToUserScale }}
        </div>
        <div
          *ngIf="log.key === 'activity_history' && log.before?.beforeLine !== log.after?.afterLine"
          class="field-change mr-3 mb-3"
        >
          <strong class="mr-3">{{ 'activityHistory.form.line' | translate }}</strong> {{ log.before?.beforeLine }}
          <span class="fas fa-arrow-right"></span> {{ log.after?.afterLine }}
        </div>
        <div *ngIf="log.before?.phaseId !== log.after?.phaseId" class="field-change mr-3 mb-3">
          <strong class="mr-3">{{ 'activityHistory.form.phase' | translate }}</strong> {{ log.before?.phaseName }}
          <span class="fas fa-arrow-right"></span> {{ log.after?.phaseName }}
        </div>
      </div>
      <ng-container *ngIf="log.before?.beforeActivity !== log.after?.afterActivity && log.action !== 'create'">
        <div class="change-entry">
          <div class="d-flex justify-content-between align-items-center mb-3">
            <span class="user-info"
              >{{ log.after?.afterUpdatedBy }}
              <span class="grey-text">
                {{ 'activityHistoryAuditLog.startedActivity' | translate }}
              </span>
            </span>
            <span class="grey-text">
              {{ log.after?.changedAt | momentDate : dateTimeFormat$ : undefined : undefined : timezone$ }}
            </span>
          </div>
        </div>
      </ng-container>
    </ng-container>
  </ng-container>
</div>
<div class="modal-footer">
  <div class="buttons-right">
    <scw-mat-button type="standard" (onClick)="closeActivityHistoryAuditLogModal()">
      {{ 'general.close' | translate }}
    </scw-mat-button>
  </div>
</div>
