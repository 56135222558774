import * as Actions from './comment-logs.actions';
import { CommentLogsStateInterface } from './comment-logs.model';
import * as _ from 'lodash';

export const commentLogsState: CommentLogsStateInterface = {
  isCommentLogsDataLoading: false,
  isCommentLogsDataLoaded: false,
  isCommentLogsDeleteRowsLoading: false,
  isCommentLogsDeleteRowsLoaded: false,
  isCommentLogRowForCommentFormLoading: false,
  isCommentLogRowForCommentFormLoaded: false,
  isCommentLogRowEditLoading: false,
  isCommentLogRowEditLoaded: false,
  commentLogsData: null,
  commentLogData: null,
  filters: null,
  tableSettings: [],
};

export function commentLogsReducer(state: any = commentLogsState, action: Actions.CommentLogsActions): any {
  switch (action.type) {
    case Actions.COMMENT_LOGS_DATA_LOADING:
      return {
        ...state,
        isCommentLogsDataLoading: true,
        isCommentLogsDataLoaded: false,
      };
    case Actions.COMMENT_LOGS_DATA_LOADED:
      return {
        ...state,
        isCommentLogsDataLoading: false,
        isCommentLogsDataLoaded: true,
        commentLogsData: action.payload,
      };
    case Actions.COMMENT_LOGS_DELETE_ROWS:
      return {
        ...state,
        isCommentLogsDataLoading: true,
        isCommentLogsDataLoaded: false,
      };
    case Actions.COMMENT_LOGS_DELETE_ROWS_COMPLETED:
      const deletedComments = action.deletedCommentLogs;
      const removedCommentArrayIndex = [];
      const commentLogsData$ = _.cloneDeep(state.commentLogsData);
      const payloadData = _.get(action.payload, 'data', []);

      if (payloadData.length === 0) {
        payloadData.push({
          success: action.payload.success,
          message: action.payload.message,
        });
      }

      deletedComments.forEach((commentId: number, index: number) => {
        const stateIndex: number = _.findIndex(commentLogsData$, { id: commentId });
        if (stateIndex !== -1) {
          if (payloadData[index].success) {
            removedCommentArrayIndex.push(stateIndex);
          } else {
            _.set(commentLogsData$[stateIndex], 'error', payloadData[index].message);
          }
        }
      });

      if (removedCommentArrayIndex.length > 0) {
        _.pullAt(commentLogsData$, removedCommentArrayIndex);
      }

      return {
        ...state,
        isCommentLogsDataLoading: false,
        isCommentLogsDataLoaded: true,
        commentLogsData: commentLogsData$,
      };
    case Actions.COMMENT_LOGS_GET_ROW:
      return {
        ...state,
        isCommentLogRowForCommentFormLoading: true,
        isCommentLogRowForCommentFormLoaded: false,
      };
    case Actions.COMMENT_LOGS_GET_ROW_COMPLETED:
      return {
        ...state,
        isCommentLogRowForCommentFormLoading: false,
        isCommentLogRowForCommentFormLoaded: true,
        commentLogData: action.payload,
      };
    case Actions.COMMENT_LOGS_EDIT_ROW:
      return {
        ...state,
        isCommentLogRowEditLoading: true,
        isCommentLogRowEditLoaded: false,
      };
    case Actions.COMMENT_LOGS_EDIT_ROW_COMPLETED:
      return {
        ...state,
        isCommentLogRowEditLoading: false,
        isCommentLogRowEditLoaded: true,
      };
    case Actions.COMMENT_LOGS_SET_SELECTED_FILTERS:
      return {
        ...state,
        filters: action.filters,
      };
      break;
    case Actions.COMMENT_LOGS_SET_TABLE_SETTINGS:
      return {
        ...state,
        tableSettings: action.payload,
      };
    case Actions.FETCH_ERROR:
      return {
        ...state,
      };
    default:
      return state;
  }
}
