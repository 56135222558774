import { ELoadStatus } from 'src/constants.model';
import { DropdownOptionInterface } from '../../shared/component/scw-mat-ui/scw-mat-select/scw-mat-select.model';
import { TagsObjectTypes } from '../settings/tags/tags.model';
import { ECommentLogsCommentType } from '../reports/comment-logs/comment-logs.model';

export interface ICommentTypesState {
  commentTypesLoadStatus: ELoadStatus;
  commentTypes: readonly ICommentType[];
}

export interface ICommentType {
  id: number;
  category: ECommentTypeCategory;
}

export enum ECommentTypeCategory {
  Activity = 'activity_comment',
  Maintenance = 'maintenance_comments',
  NextShiftGoal = 'next_shift_goal_comments',
  Operator = 'operator_comments',
  ProductionCount = 'production_count_comments',
  ProductionReview = 'production_review_comments',
  ShiftActionItem = 'shift_action_item_comments',
  ShiftDataQuality = 'shift_data_quality',
  ShiftEndStatus = 'shift_end_status_comments',
  ShiftGoal = 'shift_goal_comments',
  ShiftGoalHit = 'shift_goal_hit',
  ShiftQuality = 'shift_quality',
  ShiftSafety = 'shift_safety',
  ShiftTopDownTimeActivity = 'shift_top_down_time_activity',
  Supervisor = 'supervisor_comments',
  ScrapCountComment = 'scrap_count_comment',
}

export const commentTypeTagTypeRelation: { [key in ECommentLogsCommentType]?: TagsObjectTypes } = {
  [ECommentLogsCommentType.activityComment]: TagsObjectTypes.Comment,
  [ECommentLogsCommentType.scrapCountComments]: TagsObjectTypes.ScrapCountComment,
};

export interface ICommentTypeFilterCardOption extends DropdownOptionInterface {
  associatedTagType: TagsObjectTypes | null;
}
