import { Inject, Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, UrlTree } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { catchError, map } from 'rxjs/operators';
import { ScenarioResponseInterface } from '../../../store/scheduler/scheduler.model';
import { Observable, of } from 'rxjs';
import { GenericHelperService } from '../generic.helper.service';

@Injectable({
  providedIn: 'root',
})
export class SchedulerScenarioGuard implements CanActivate {
  private readonly schedulerScenariosUrl: string = '/scheduler/scenarios';

  constructor(
    public router: Router,
    public http: HttpClient,
    @Inject('API_BASE_URL') private readonly baseUrl: string,
  ) {}

  canActivate(next: ActivatedRouteSnapshot): Observable<boolean | UrlTree> {
    const id: number | null = GenericHelperService.tryParseJson<number | null>(next.paramMap.get('id'), null);

    if (!id || typeof id !== 'number' || Number.isNaN(id) || !Number.isInteger(id)) {
      return of(this.router.createUrlTree([this.schedulerScenariosUrl]));
    }

    return this.http
      .get<ScenarioResponseInterface>(`${this.baseUrl}/scheduler/scenarios/${next.paramMap.get('id')}`)
      .pipe(
        map((response: ScenarioResponseInterface) => {
          return (
            (response.success && Boolean(Object.keys(response.data))) ||
            this.router.createUrlTree([this.schedulerScenariosUrl])
          );
        }),
        catchError(() => this.router.navigate([this.schedulerScenariosUrl])),
      );
  }
}
