import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { ITopicSubscriptionResponseData } from './push-notification.model';
import { BaseOneResponseInterface } from '../../shared/model/interface/crud-response-interface.model';
import { ScwFirebaseService } from '../../shared/service/firebase/firebase.service';

@Injectable({
  providedIn: 'root',
})
export class PushNotificationService {

  constructor(private readonly http: HttpClient) {}

  public static isNotificationSupported(): boolean {
    return 'Notification' in window;
  }

  public static isPushManagerSupported(): boolean {
    return 'PushManager' in window;
  }

  public static unsubscribeFromTopics(
    http: HttpClient,
    token: string,
  ): Observable<BaseOneResponseInterface<ITopicSubscriptionResponseData>> {
    return http.post<BaseOneResponseInterface<ITopicSubscriptionResponseData>>(
      `${ScwFirebaseService.pushNotificationsServiceUrl}/topic/unsubscribe`,
      { token },
    );
  }

  public subscribeToTopics(token: string): Observable<BaseOneResponseInterface<ITopicSubscriptionResponseData>> {
    return this.http.post<BaseOneResponseInterface<ITopicSubscriptionResponseData>>(
      `${ScwFirebaseService.pushNotificationsServiceUrl}/topic/subscribe`,
      { token },
    );
  }
}
