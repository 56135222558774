import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, switchMap } from 'rxjs/operators';
import { from, of } from 'rxjs';
import * as ObjectActions from './activity.actions';
import { ActivityCRUDInterface, AlteredActivityCRUDInterface } from '../../../shared/component/filter/filter.class';
import { ActivityService } from '../../../shared/service/filter/activity.service';
import { generateHttpOption } from '../../../shared/helper/app-helper';
import { EntityTranslatorService } from 'src/app/shared/service/entity-translator/entity-translator.service';
@Injectable()
export class FilterActivityEffects {
  loadFilterActivities$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ObjectActions.FilterActivitiesActionTypes.StartLoadFilterActivities),
      switchMap((objectData: ObjectActions.StartLoadFilterActivities) => {
        const params = generateHttpOption(objectData.options);

        return from(this.service.getData(params)).pipe(
          switchMap((response: ActivityCRUDInterface[]) => {
            response.forEach((activity: ActivityCRUDInterface) => this.entityTranslator.translate(activity));

            const alteredResponse: AlteredActivityCRUDInterface[] = response.map((data: ActivityCRUDInterface) => {
              return {
                id: data.id,
                name: data.name,
                activityType: data.activityType,
                activityTypeRelation: data.activityTypeRelation,
                activityTypeText: data.activityTypeRelation.text,
                activitySubtype: data.activitySubtype,
              };
            });

            return of(new ObjectActions.FilterActivitiesLoaded(alteredResponse));
          }),
          catchError((err) => {
            return of(new ObjectActions.FilterActivitiesLoaded([]));
          }),
        );
      }),
    ),
  );

  constructor(
    private readonly actions$: Actions<ObjectActions.FilterActivitiesActions>,
    public service: ActivityService,
    private entityTranslator: EntityTranslatorService,
  ) {}
}
