import * as ObjectActions from './iot-device-report.actions';
import * as AppActions from '../app/actions';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Injectable } from '@angular/core';
import { catchError, switchMap } from 'rxjs/operators';
import { ErrorMessageService } from '../../shared/service/error-message.service';
import { HttpParams } from '@angular/common/http';
import { from, of } from 'rxjs';
import { IotDeviceReportService } from './iot-device-report.service';
import { BaseOneResponseInterface } from '../../shared/model/interface/crud-response-interface.model';
import { Store } from '@ngrx/store';
import * as oeeAppReducer from '../oee.reducer';
import {
  NotificationAppendToInterface,
  NotificationInterface,
  NotificationItemInterface,
} from './iot-device-report.model';

@Injectable()
export class IotDeviceReportEffects {
  constructor(
    private readonly actions$: Actions,
    private readonly service: IotDeviceReportService,
    private readonly store: Store<oeeAppReducer.OeeAppState>,
  ) {}

  getNotificationsForAppendToData = createEffect(() =>
    this.actions$.pipe(
      ofType(ObjectActions.IOT_DEVICE_REPORT_NOTIFICATIONS_DATA_FOR_APPEND_TO_LOADING),
      switchMap((payload: ObjectActions.NotificationsDataForAppendToLoading) => {
        const httpParams: HttpParams = new HttpParams().set('sort', 'name,ASC').set('limit', '1000').set('page', '1');

        return from(this.service.getNotifications(httpParams)).pipe(
          switchMap((response) => {
            return of(new ObjectActions.NotificationsDataForAppendToLoaded(response));
          }),
          catchError((errorRes) => {
            return of(new ObjectActions.FetchError(errorRes), new AppActions.HideLoader());
          }),
        );
      }),
      catchError((errorRes) => {
        return of(new ObjectActions.FetchError(errorRes), new AppActions.HideLoader());
      }),
    ),
  );

  getNotificationsForViewData = createEffect(() =>
    this.actions$.pipe(
      ofType(ObjectActions.IOT_DEVICE_REPORT_NOTIFICATIONS_DATA_FOR_VIEW_LOADING),
      switchMap((payload: ObjectActions.NotificationsDataForViewLoading) => {
        const httpParams: HttpParams = new HttpParams().set('sort', 'name,ASC').set('limit', '1000').set('page', '1');

        return from(this.service.getNotifications(httpParams)).pipe(
          switchMap((response) => {
            return of(new ObjectActions.NotificationsDataForViewLoaded(response));
          }),
          catchError((errorRes) => {
            return of(new ObjectActions.FetchError(errorRes), new AppActions.HideLoader());
          }),
        );
      }),
      catchError((errorRes) => {
        return of(new ObjectActions.FetchError(errorRes), new AppActions.HideLoader());
      }),
    ),
  );

  getNotificationGroupsData = createEffect(() =>
    this.actions$.pipe(
      ofType(ObjectActions.IOT_DEVICE_REPORT_NOTIFICATION_GROUPS_DATA_LOADING),
      switchMap(() => {
        const httpParams: HttpParams = new HttpParams()
          .set('sort', 'title,ASC')
          .set('fields', 'id,title')
          .set('limit', '1000')
          .set('page', '1');

        return from(this.service.getNotificationGroups(httpParams)).pipe(
          switchMap((response) => {
            return of(new ObjectActions.NotificationGroupsDataLoaded(response));
          }),
          catchError((errorRes) => {
            return of(new ObjectActions.FetchError(errorRes), new AppActions.HideLoader());
          }),
        );
      }),
      catchError((errorRes) => {
        return of(new ObjectActions.FetchError(errorRes), new AppActions.HideLoader());
      }),
    ),
  );

  getNotificationTemplatesData = createEffect(() =>
    this.actions$.pipe(
      ofType(ObjectActions.IOT_DEVICE_REPORT_NOTIFICATION_TEMPLATES_DATA_LOADING),
      switchMap(() => {
        const httpParams: HttpParams = new HttpParams()
          .set('s', '{"$and":[{"$or":[{"templateType":{"$eq":1}}]}]}')
          .set('sort', 'templateName,ASC')
          .set('fields', 'id,templateName')
          .set('limit', '1000')
          .set('page', '1');

        return from(this.service.getNotificationTemplates(httpParams)).pipe(
          switchMap((response) => {
            return of(new ObjectActions.NotificationTemplatesDataLoaded(response));
          }),
          catchError((errorRes) => {
            return of(new ObjectActions.FetchError(errorRes), new AppActions.HideLoader());
          }),
        );
      }),
      catchError((errorRes) => {
        return of(new ObjectActions.FetchError(errorRes), new AppActions.HideLoader());
      }),
    ),
  );

  addNotification = createEffect(() =>
    this.actions$.pipe(
      ofType(ObjectActions.IOT_DEVICE_REPORT_ADD_NOTIFICATION),
      switchMap((objectData: ObjectActions.AddNotification) => {
        this.store.dispatch(new AppActions.ShowLoader());

        return this.service.addNotification(objectData.notification).pipe(
          switchMap((response: BaseOneResponseInterface<NotificationInterface>) => {
            return of(new ObjectActions.AddNotificationCompleted(response), new AppActions.HideLoader());
          }),
          catchError((error) => {
            return of(new ObjectActions.FetchError(error), new AppActions.HideLoader());
          }),
        );
      }),
      catchError((error) => {
        return of(new ObjectActions.FetchError(error), new AppActions.HideLoader());
      }),
    ),
  );

  appendToNotification = createEffect(() =>
    this.actions$.pipe(
      ofType(ObjectActions.IOT_DEVICE_REPORT_APPEND_TO_NOTIFICATION),
      switchMap((objectData: ObjectActions.AppendToNotification) => {
        this.store.dispatch(new AppActions.ShowLoader());

        return this.service.appendToNotification(objectData.notification).pipe(
          switchMap((response: BaseOneResponseInterface<NotificationAppendToInterface>) => {
            return of(new ObjectActions.AppendToNotificationCompleted(response), new AppActions.HideLoader());
          }),
          catchError((error) => {
            return of(new ObjectActions.FetchError(error), new AppActions.HideLoader());
          }),
        );
      }),
      catchError((error) => {
        return of(new ObjectActions.FetchError(error), new AppActions.HideLoader());
      }),
    ),
  );

  deleteNotification = createEffect(() =>
    this.actions$.pipe(
      ofType(ObjectActions.IOT_DEVICE_REPORT_DELETE_NOTIFICATION),
      switchMap((objectData: ObjectActions.DeleteNotification) => {
        this.store.dispatch(new AppActions.ShowLoader());

        return this.service.deleteNotification(objectData.notificationId).pipe(
          switchMap((response: BaseOneResponseInterface<NotificationInterface>) => {
            return of(new ObjectActions.DeleteNotificationCompleted(response), new AppActions.HideLoader());
          }),
          catchError((error) => {
            return of(new ObjectActions.FetchError(error), new AppActions.HideLoader());
          }),
        );
      }),
      catchError((error) => {
        return of(new ObjectActions.FetchError(error), new AppActions.HideLoader());
      }),
    ),
  );

  deleteNotificationItem = createEffect(() =>
    this.actions$.pipe(
      ofType(ObjectActions.IOT_DEVICE_REPORT_DELETE_NOTIFICATION_ITEM),
      switchMap((objectData: ObjectActions.DeleteNotificationItem) => {
        this.store.dispatch(new AppActions.ShowLoader());

        return this.service.deleteNotificationItem(objectData.notificationItemId).pipe(
          switchMap((response: BaseOneResponseInterface<NotificationItemInterface>) => {
            return of(
              new ObjectActions.DeleteNotificationItemCompleted(objectData.notificationId, response),
              new AppActions.HideLoader(),
            );
          }),
          catchError((error) => {
            return of(new ObjectActions.FetchError(error), new AppActions.HideLoader());
          }),
        );
      }),
      catchError((error) => {
        return of(new ObjectActions.FetchError(error), new AppActions.HideLoader());
      }),
    ),
  );
}
