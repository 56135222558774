import { FilterProductFamilyActions, FilterProductFamilyActionTypes } from './product-family.actions';
import { ProductFamilyCRUDInterface } from '../../../shared/component/filter/filter.class';

export interface FilterProductFamilyState {
  isLoading: boolean;
  isLoaded: boolean;
  data: ProductFamilyCRUDInterface[];
  totalCount: number;
  allowNewRequest: boolean;
}

export const initialFilterProductFamilyState: FilterProductFamilyState = {
  isLoaded: false,
  isLoading: false,
  data: [],
  totalCount: 0,
  allowNewRequest: false,
};

export function filterProductFamilyReducer(
  state = initialFilterProductFamilyState,
  action: FilterProductFamilyActions,
): FilterProductFamilyState {
  switch (action.type) {
    case FilterProductFamilyActionTypes.StartLoadFilterProductFamily:
      return { ...state, isLoading: true };

    case FilterProductFamilyActionTypes.FilterProductFamilyLoaded:
      return {
        ...state,
        data: action.data,
        allowNewRequest: action.totalCount > 0 && action.data.length > 0 && action.totalCount > action.data.length,
        totalCount: action.totalCount,
        isLoaded: true,
        isLoading: false,
      };

    case FilterProductFamilyActionTypes.ResetProductFamilyStore:
      return {
        ...state,
        isLoaded: false,
        isLoading: false,
        data: [],
      };
    default:
      return state;
  }
}
