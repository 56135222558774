import { Inject, Injectable } from '@angular/core';
import {
  FilterServiceInterface,
  ProductCRUDResponseInterface,
  ProductFamilyCRUDResponseInterface,
} from './service.class';
import { HttpClient, HttpParams } from '@angular/common/http';
import { ProductCRUDInterface } from '../../component/filter/filter.class';
import { Observable } from 'rxjs';
import { IProductVersion } from 'src/app/store/settings/products/products.model';
import { GetManyResponseInterface } from '../../model/interface/crud-response-interface.model';
import { Store } from '@ngrx/store';
import { OeeAppState } from '../../../store/oee.reducer';
import { EProductOrFamilyFilterType } from '../../component/filter/filterable-objects.class';
import { HttpOptionInterface } from '../../../store/filter/filter.model';

type TEndpoint = 'GET_MANY_PRODUCT_VERSIONS' | 'GET_MANY_PRODUCTS';

@Injectable({
  providedIn: 'root',
})
export class ProductService implements FilterServiceInterface {
  private readonly PATHS: Record<TEndpoint, string> = {
    GET_MANY_PRODUCT_VERSIONS: `${this.baseUrl}/product-versions`,
    GET_MANY_PRODUCTS: `${this.baseUrl}/products`,
  };

  constructor(
    private store: Store<OeeAppState>,
    public http: HttpClient,
    @Inject('API_BASE_URL') private baseUrl: string,
  ) {}

  getData(options?: HttpParams): Promise<ProductCRUDInterface[]> {
    return new Promise((resolve, reject) => {
      this.http
        .get(`${this.PATHS.GET_MANY_PRODUCTS}?join=site`, { params: options })
        .subscribe((response: ProductCRUDResponseInterface) => {
          if (response.hasOwnProperty('data')) {
            resolve(response.data);
          } else {
            reject();
          }
        });
    });
  }
  public getProducts(params?: HttpParams): Observable<ProductCRUDInterface> {
    return this.http.get<ProductCRUDInterface>(this.PATHS.GET_MANY_PRODUCTS, {
      params,
    });
  }

  public getProductVersions(
    productId: number,
  ): Observable<GetManyResponseInterface<IProductVersion>> {
    let params: HttpParams = new HttpParams().set('s', JSON.stringify({ productId: { $eq: productId }, active: { $eq: true } }));

    return this.http.get<GetManyResponseInterface<IProductVersion>>(this.PATHS.GET_MANY_PRODUCT_VERSIONS, { params });
  }

  public getProductObjects(params?: HttpParams): Observable<ProductCRUDResponseInterface> {
    return this.http.get<ProductCRUDResponseInterface>(`${this.baseUrl}/products`, {
      params,
    });
  }

  public getProductFamily(params?: HttpParams): Observable<ProductFamilyCRUDResponseInterface> {
    return this.http.get<ProductFamilyCRUDResponseInterface>(`${this.baseUrl}/product-family`, {
      params,
    });
  }

  public getProductOrProductFamilyData(
    params: HttpParams,
    fiterType: string,
  ): Observable<ProductFamilyCRUDResponseInterface | ProductCRUDResponseInterface> {
    if (fiterType === EProductOrFamilyFilterType.Product) {
      return this.getProductObjects(params);
    }
    return this.getProductFamily(params);
  }

  public adjustOptionsJson(options: HttpOptionInterface, filterType: EProductOrFamilyFilterType): HttpOptionInterface {
    try {
      const jsonObject: JSON = JSON.parse(options.s);
      const searchParams: string = jsonObject['$and'][0]['$or'];
      let compareType: string = jsonObject['$and'][0]['compareType'];

      if (compareType) {
        delete jsonObject['$and'][0]['compareType'];
      }

      if (searchParams) {
        compareType = jsonObject['$and'][1]['compareType'];

        if (compareType) {
          delete jsonObject['$and'][1]['compareType'];
        }

        if (filterType === EProductOrFamilyFilterType.Product) {
          delete jsonObject['$and'][0]['$or'][2];
        } else {
          delete jsonObject['$and'][0]['$or'][0];
          delete jsonObject['$and'][0]['$or'][1];
        }
      }

      const result: HttpOptionInterface = {
        ...options,
        s: JSON.stringify(jsonObject),
      };

      return result;
    } catch (jsonParseError) {
      return options;
    }
  }
}
