import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from '../../../../../../shared/shared.module';
import { ITreeNodeMetaColorsData } from '../../../../../../store/settings/tree-nodes/tree-nodes.model';
import { debounceTime, Subject } from 'rxjs';
import { GoodScrapCountQueue } from './good-scrap-count.queue';
import { Store } from '@ngrx/store';
import * as oeeAppReducer from '../../../../../../store/oee.reducer';
import * as SemiManualCountButtonCardComponentActions from '../../../../../../store/station-home/kpi-card/semi-manual-count-button-card/semi-manual-count-button-card.actions';
import { DecimalHelper } from '../../../../../../shared/helper/decimal/decimal-helper';
import { ESensorCountType } from './semi-manual-count-button-card.types';
import * as StationHomeKPIActions from '../../../../../../store/station-home/kpi-card/kpi-card.actions';
import { ToastHelperService } from '../../../../../../shared/service/toast/toast.helper.service';
import { TranslateService } from '@ngx-translate/core';
import { SemiManualCountButtonService } from '../../../../../../shared/service/station-home/station-home-kpi/semi-manual-count-button.service';

@Component({
  selector: 'scw-semi-manual-count-button-card',
  standalone: true,
  imports: [CommonModule, SharedModule],
  templateUrl: './semi-manual-count-button-card.component.html',
})
export class SemiManualCountButtonCardComponent implements OnInit, OnDestroy {
  @Input() public stationID: number;
  @Input() public defaultScrapValuePerClick: string = '1';
  @Input() public defaultGoodValuePerClick: string = '1';
  @Input() public submitCountDelayMS: number = 5000; // 5 seconds by default
  @Input() public hasMissingWorkOrderActivity: boolean = true;

  @Output() public readonly addScrapCountButtonClicked: EventEmitter<string> = new EventEmitter<string>();
  @Output() public readonly addGoodCountButtonClicked: EventEmitter<string> = new EventEmitter<string>();
  @Output() public readonly hasPendingCounts: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() public readonly countsSended: EventEmitter<void> = new EventEmitter<void>();

  public readonly addGoodCountButtonColors: ITreeNodeMetaColorsData = {
    background: '#6fc764',
    hover: '#429A38',
    text: '#ffffff',
    border: '#faf9f9',
  };
  public readonly addScrapCountButtonColors: ITreeNodeMetaColorsData = {
    background: '#ff726b',
    hover: '#FF5047',
    text: '#ffffff',
    border: '#faf9f9',
  };

  private readonly goodScrapCountQueue: GoodScrapCountQueue = new GoodScrapCountQueue();

  private readonly countQueueSubject: Subject<void> = new Subject<void>();

  constructor(
    private readonly store: Store<oeeAppReducer.OeeAppState>,
    private readonly decimalHelper: DecimalHelper,
    private readonly toast: ToastHelperService,
    private readonly translateService: TranslateService,
  ) {}

  public ngOnInit(): void {
    if (!this.stationID) {
      throw new Error('Required input for component "stationID" is missing.');
    }

    this.countQueueSubject.pipe(debounceTime(this.submitCountDelayMS)).subscribe(() => {
      this.sendCounts();
    });

    SemiManualCountButtonService.sendCountsSubject.subscribe((): void => {
      this.sendCounts();
    });
  }

  public onAddGoodCountButtonClick(): void {
    if (this.hasMissingWorkOrderActivity) {
      this.showMissingWorkOrderActivityMessage();
      return;
    }

    this.store.dispatch(StationHomeKPIActions.increaseGoodCount({ count: this.defaultGoodValuePerClick }));
    this.store.dispatch(StationHomeKPIActions.increaseTotalCount({ count: this.defaultGoodValuePerClick }));
    this.goodScrapCountQueue.pushGoodCount(this.defaultGoodValuePerClick);
    this.goodScrapCountQueue.pushScrapCount(this.defaultGoodValuePerClick);
    this.addGoodCountButtonClicked.emit(this.defaultGoodValuePerClick);
    this.countQueueSubject.next();
    this.updateHasPendingCounts();
  }

  public onAddScrapCountButtonClick(): void {
    if (this.hasMissingWorkOrderActivity) {
      this.showMissingWorkOrderActivityMessage();
      return;
    }

    this.store.dispatch(StationHomeKPIActions.increaseTotalCount({ count: this.defaultScrapValuePerClick }));
    this.goodScrapCountQueue.pushScrapCount(this.defaultScrapValuePerClick);
    this.addScrapCountButtonClicked.emit(this.defaultScrapValuePerClick);
    this.countQueueSubject.next();
    this.updateHasPendingCounts();
  }

  private showMissingWorkOrderActivityMessage(): void {
    this.toast.showToastMessage(
      false,
      this.translateService.instant('stationHomeKpiCard.semiManualCountButtons.missingWorkOrderOrActivity.title'),
      this.translateService.instant('stationHomeKpiCard.semiManualCountButtons.missingWorkOrderOrActivity.description'),
    );
  }

  private updateHasPendingCounts(): void {
    this.hasPendingCounts.emit(this.hasPendingCountsToSend());
  }

  private hasPendingCountsToSend(): boolean {
    return this.goodScrapCountQueue.getScrapCountLength() > 0 || this.goodScrapCountQueue.getGoodCountLength() > 0;
  }

  private sendCounts(): void {
    if (this.hasPendingCountsToSend()) {
      const totalGoodCounts = this.goodScrapCountQueue
        .readGoodCounts()
        .reduce((acc, curr) => this.decimalHelper.add(acc, curr), '0');
      const totalScrapCounts = this.goodScrapCountQueue
        .readScrapCounts()
        .reduce((acc, curr) => this.decimalHelper.add(acc, curr), '0');

      this.store.dispatch(
        SemiManualCountButtonCardComponentActions.sendVirtualCounts({
          stationID: this.stationID,
          payload: [
            ...(this.decimalHelper.isGreaterThan(totalGoodCounts, '0')
              ? [{ type: ESensorCountType.GOOD_COUNTER, value: totalGoodCounts }]
              : []),
            ...(this.decimalHelper.isGreaterThan(totalScrapCounts, '0')
              ? [{ type: ESensorCountType.SCRAP_COUNTER, value: totalScrapCounts }]
              : []),
          ],
        }),
      );

      this.countsSended.emit();
      this.updateHasPendingCounts();
    }
  }

  public ngOnDestroy(): void {
    this.countQueueSubject.complete();
  }
}
