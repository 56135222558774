import { Action } from '@ngrx/store';
import { LineCRUDInterface, SiteCRUDInterface } from '../../../shared/component/filter/filter.class';
import {
  AddTaskInterface,
  BulkEditTaskInterface,
  EditTaskInterface,
  ITasksPageData,
  SimplifiedDataInterface,
  TaskCRUDInterface,
  TasksDownloadExcelFiltersInterface,
} from './tasks.model';
import {
  BaseOneResponseInterface,
  GetManyResponseInterface,
} from '../../../shared/model/interface/crud-response-interface.model';
import { TabRowInterface } from '../../../shared/component/side-config-bar/side-config-bar.model';
import { ActivitiesInterface } from '../../../shared/model/interface/activities.model';
import { TaskQueryParams } from '../../../view/settings/tasks/tasks.model';
import { RootCauseGroupGetOneInterface } from '../root-cause-group/root-cause-group.model';
import { EquipmentListInterface } from '../equipment-lists/equipment-lists.model';
import { MergedArrayDataInterface } from '../../../../constants.model';

export const SITES_DATA_LOADING = '[TASKS] SITES DATA LOADING';
export const SITES_DATA_LOADED = '[TASKS] SITES DATA LOADED';
export const TASK_RANGE_DATA_LOADING = '[TASKS] TASK DATA LOADING';
export const TASK_DATA_FOR_DATATABLE_LOADING = '[TASKS] TASK DATA FOR DATATABLE LOADING';
export const TASK_DATA_FOR_DATATABLE_LOADED = '[TASKS] TASK DATA FOR DATATABLE LOADED';
export const TASK_RANGE_DATA_LOADED = '[TASKS] TASK DATA LOADED';
export const GET_LINES = '[TASKS] LINES DATA LOADED';
export const GET_LINES_COMPLETED = '[TASKS] LINES DATA COMPLETED';
export const GET_ACTIVITIES = '[TASKS] ACTIVITIES DATA LOADED';
export const GET_ACTIVITIES_COMPLETED = '[TASKS] ACTIVITIES DATA COMPLETED';
export const GET_EQUIPMENTS = '[TASKS] EQUIPMENT DATA LOADED';
export const GET_EQUIPMENTS_COMPLETED = '[TASKS] EQUIPMENT DATA COMPLETED';
export const GET_ROOT_CAUSE_GROUPS = '[TASKS] ROOT CAUSE GROUPS DATA LOADED';
export const GET_ROOT_CAUSE_GROUPS_COMPLETED = '[TASKS] ROOT CAUSE GROUPS DATA COMPLETED';
export const DOWNLOAD_EXCEL = '[TASKS] DOWNLOAD EXCEL';
export const DOWNLOAD_EXCEL_COMPLETED = '[TASKS] DOWNLOAD EXCEL COMPLETED';
export const UPLOAD_EXCEL = '[TASKS] UPLOAD EXCEL';
export const UPLOAD_EXCEL_COMPLETED = '[TASKS] UPLOAD EXCEL COMPLETED';
export const SET_TABLE_SETTINGS = '[TASKS] TABLE SETTINGS SET';
export const ADD_TASK = '[TASKS] ADD TASK';
export const ADD_TASK_COMPLETED = '[TASKS] ADD TASK COMPLETED';
export const DELETE_TASKS = '[TASKS] DELETE TASKS';
export const DELETE_TASKS_COMPLETED = '[TASKS] DELETE TASKS COMPLETED';
export const EDIT_TASK = '[TASKS] EDIT TASKS ';
export const EDIT_TASK_COMPLETED = '[TASKS] EDIT TASKS COMPLETED';
export const BULK_EDIT_TASK = '[TASKS] BULK EDIT TASKS ';
export const BULK_EDIT_TASK_COMPLETED = '[TASKS] BULK EDIT TASKS COMPLETED';
export const CLEAR_TASK_STATE = '[TASKS] CLEAR TASK STATE';
export const FETCH_ERROR = '[TASKS] FETCH ERROR';

export class TaskRangeDataLoading implements Action {
  readonly type = TASK_RANGE_DATA_LOADING;

  constructor(public params: TaskQueryParams) {}
}

export class TaskRangeDataLoaded implements Action {
  readonly type = TASK_RANGE_DATA_LOADED;

  constructor(public payload: GetManyResponseInterface<TaskCRUDInterface>) {}
}

export class TaskDataForDatatableLoading implements Action {
  readonly type = TASK_DATA_FOR_DATATABLE_LOADING;

  constructor(public params: TaskQueryParams) {}
}

export class TaskDataForDatatableLoaded implements Action {
  readonly type = TASK_DATA_FOR_DATATABLE_LOADED;

  constructor(public payload: GetManyResponseInterface<TaskCRUDInterface>) {}
}

export class SiteDataLoading implements Action {
  readonly type = SITES_DATA_LOADING;

  constructor() {}
}

export class SiteDataLoaded implements Action {
  readonly type = SITES_DATA_LOADED;

  constructor(public payload: (SimplifiedDataInterface & Partial<SiteCRUDInterface>)[]) {}
}

export class DownloadExcel implements Action {
  readonly type = DOWNLOAD_EXCEL;

  constructor(
    public withData: boolean,
    public filters: TasksDownloadExcelFiltersInterface,
    public tableQuery: TaskQueryParams,
  ) {}
}

export class DownloadExcelCompleted implements Action {
  readonly type = DOWNLOAD_EXCEL_COMPLETED;
}

export class UploadExcel implements Action {
  readonly type = UPLOAD_EXCEL;

  constructor(public tasks: ITasksPageData[]) {}
}

export class UploadExcelCompleted implements Action {
  readonly type = UPLOAD_EXCEL_COMPLETED;

  constructor(public payload: (TaskCRUDInterface & { errorMessages?: string })[]) {}
}

export class SetTableSettings implements Action {
  readonly type = SET_TABLE_SETTINGS;
  constructor(public payload: TabRowInterface[], public setAsDefault: boolean) {}
}

export class GetLines implements Action {
  readonly type = GET_LINES;
  constructor(public siteId: number) {}
}

export class GetLinesCompleted implements Action {
  readonly type = GET_LINES_COMPLETED;
  constructor(public payload: GetManyResponseInterface<Partial<LineCRUDInterface>>) {}
}

export class GetActivities implements Action {
  readonly type = GET_ACTIVITIES;
}

export class GetActivitiesCompleted implements Action {
  readonly type = GET_ACTIVITIES_COMPLETED;
  constructor(public payload: GetManyResponseInterface<ActivitiesInterface>) {}
}

export class GetEquipments implements Action {
  readonly type = GET_EQUIPMENTS;
  constructor(public siteId: number, public lineId: number) {}
}

export class GetEquipmentsCompleted implements Action {
  readonly type = GET_EQUIPMENTS_COMPLETED;
  constructor(public payload: GetManyResponseInterface<EquipmentListInterface>) {}
}

export class GetRootCauseGroups implements Action {
  readonly type = GET_ROOT_CAUSE_GROUPS;
  constructor(public siteId: number) {}
}

export class GetRootCauseGroupsCompleted implements Action {
  readonly type = GET_ROOT_CAUSE_GROUPS_COMPLETED;
  constructor(public payload: GetManyResponseInterface<RootCauseGroupGetOneInterface>) {}
}

export class AddTask implements Action {
  readonly type = ADD_TASK;
  constructor(public task: AddTaskInterface) {}
}

export class AddTaskCompleted implements Action {
  readonly type = ADD_TASK_COMPLETED;
  constructor(public payload: BaseOneResponseInterface<AddTaskInterface>) {}
}

export class DeleteTasks implements Action {
  readonly type = DELETE_TASKS;
  constructor(public payload: number[]) {}
}

export class DeleteTasksCompleted implements Action {
  readonly type = DELETE_TASKS_COMPLETED;
  constructor(public isBulk: boolean, public response: MergedArrayDataInterface[]) {}
}

export class EditTask implements Action {
  readonly type = EDIT_TASK;
  constructor(public task: EditTaskInterface, public taskId: number) {}
}

export class EditTaskCompleted implements Action {
  readonly type = EDIT_TASK_COMPLETED;
  constructor(public payload: BaseOneResponseInterface<EditTaskInterface>) {}
}

export class BulkEditTask implements Action {
  readonly type = BULK_EDIT_TASK;
  constructor(public tasks: BulkEditTaskInterface[]) {}
}

export class BulkEditTaskCompleted implements Action {
  readonly type = BULK_EDIT_TASK_COMPLETED;
  constructor(public payload: MergedArrayDataInterface[]) {}
}

export class ClearTaskState implements Action {
  readonly type = CLEAR_TASK_STATE;
}

export class FetchError implements Action {
  readonly type = FETCH_ERROR;

  constructor(public payload: object) {}
}

export type TasksActions =
  | TaskRangeDataLoading
  | TaskRangeDataLoaded
  | TaskDataForDatatableLoading
  | TaskDataForDatatableLoaded
  | GetLines
  | GetLinesCompleted
  | GetActivities
  | GetActivitiesCompleted
  | GetEquipments
  | GetEquipmentsCompleted
  | GetRootCauseGroups
  | GetRootCauseGroupsCompleted
  | SiteDataLoading
  | SiteDataLoaded
  | DownloadExcel
  | DownloadExcelCompleted
  | UploadExcel
  | UploadExcelCompleted
  | SetTableSettings
  | AddTask
  | AddTaskCompleted
  | DeleteTasks
  | DeleteTasksCompleted
  | EditTask
  | EditTaskCompleted
  | BulkEditTask
  | BulkEditTaskCompleted
  | ClearTaskState
  | FetchError;
