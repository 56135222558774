<app-base-modal [modal]="modal">
  <ng-container modal-title>{{ modalHeader }}</ng-container>
  <ng-container modal-body>
    <div class="row">
      <div class="col-sm-8">
        <h5
          *ngIf="staticSelectedItemText || confirmMethod !== EClientSideButtonListConfirmMethods.onListItemClick"
          class="m-b-15"
        >
          {{ staticSelectedItemText ?? selectedItem[this.labelField] }}
        </h5>
      </div>
      <div class="col-sm-12">
        <scw-mat-search
          behavior="live"
          placeholder="{{ searchPlaceholder }}"
          [minLength]="0"
          [maxLength]="255"
          (onSearch)="onSearch($event)"
        ></scw-mat-search>
      </div>
    </div>
    <div>
      <div *ngFor="let section of filteredSections ?? sections">
        <div *ngIf="section.items.length">
          <h4 *ngIf="section.header" class="scw-header department">{{ section.header }}</h4>
          <div class="row">
            <div *ngFor="let item of section.items" class="col-xs-12 col-sm-6 col-md-4 col-lg-3 col-xl-2 m-b-15">
              <scw-mat-button size="three-line-text" type="standard" [block]="true" (onClick)="onClickListItem(item)">
                {{ item[labelField] }}
              </scw-mat-button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </ng-container>
  <ng-container modal-footer>
    <div class="justify-content-between width-100 d-flex">
      <div>
        <ng-container *ngTemplateOutlet="leftFooterContent"></ng-container>
      </div>
      <div>
        <scw-mat-button type="standard" (onClick)="cancel()">{{ 'general.cancel' | translate }}</scw-mat-button>
        <scw-mat-button
          *ngIf="confirmMethod === EClientSideButtonListConfirmMethods.onConfirmButtonClick"
          [type]="'cta'"
          (onClick)="confirm()"
          >{{ 'general.confirm' | translate }}</scw-mat-button
        >
      </div>
    </div>
  </ng-container>
</app-base-modal>
