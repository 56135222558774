import { Injectable } from '@angular/core';
import { EFileType, IFile } from '../../store/file-upload/file-upload.model';
import * as _ from 'lodash';
import { ToastHelperService } from '../service/toast/toast.helper.service';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root',
})
export class FileHelperService {
  constructor(private readonly toast: ToastHelperService, private readonly translate: TranslateService) {}

  public static byteStringToBlobUrl(base64String: string, mimeType: string): string {
    const binaryString: string = atob(base64String);
    const byteArray: Uint8Array = new Uint8Array(binaryString.length);

    for (let i: number = 0; i < binaryString.length; i++) {
      byteArray[i] = binaryString.charCodeAt(i);
    }

    const blob: Blob = new Blob([byteArray], { type: mimeType });
    const blobURL: string = URL.createObjectURL(blob);

    return blobURL;
  }

  public isSameFileExist(existingFiles: IFile[], newFiles: IFile[]): boolean {
    const intersectFileByOriginalName: IFile[] = _.intersectionBy(existingFiles, newFiles, 'fileOriginalName');

    if (intersectFileByOriginalName.length) {
      this.toast.showToastMessage(
        false,
        this.translate.instant('general.failed'),
        this.translate.instant('fileUpload.fileName.error'),
      );
    }

    return !intersectFileByOriginalName.length;
  }

  public getFilesFormData(files: IFile[]): FormData {
    const formData: FormData = new FormData();

    files.forEach((item: IFile, index: number) => {
      if (item.type === EFileType.PDF) {
        const mimeType: string = FileHelperService.base64MimeType(item.original);
        const pdfName: string = encodeURIComponent(item.fileOriginalName);

        const pdfFile: File = new File([FileHelperService.dataURItoBlob(item.original)], pdfName, {
          type: mimeType,
        });

        formData.append('file', pdfFile, pdfFile.name);
      } else {
        const mimeType: string = FileHelperService.base64MimeType(item.original);
        const fileExtension: string = FileHelperService.getMimeTypeExtension(mimeType);
        const imageName: string = `image-upload_${index}.${fileExtension}`;
        const imageFile: File = new File([FileHelperService.dataURItoBlob(item.original)], imageName, {
          type: mimeType,
        });

        const thumbnailFile: File = new File([FileHelperService.dataURItoBlob(item.thumbnail)], imageName, {
          type: FileHelperService.base64MimeType(item.thumbnail),
        });
        formData.append('file', thumbnailFile, thumbnailFile.name);
        formData.append('file', imageFile, imageFile.name);
      }
    });

    return formData;
  }

  public static dataURItoBlob(base64: string): Blob {
    const byteString: string = window.atob(base64.split(',')[1]);
    const mimeString: string = base64.split(',')[0].split(':')[1].split(';')[0];

    const int8Array: Uint8Array = new Uint8Array(new ArrayBuffer(byteString.length));

    for (let i = 0; i < byteString.length; i += 1) {
      int8Array[i] = byteString.charCodeAt(i);
    }

    return new Blob([int8Array], { type: mimeString });
  }

  public static getMimeTypeExtension(mimeType: string): string {
    return mimeType.split('/').slice(-1)[0];
  }

  public static base64MimeType(base64String: string): string | null {
    if (typeof base64String !== 'string') {
      return null;
    }

    const match: RegExpMatchArray = base64String.match(/data:([a-zA-Z0-9]+\/[a-zA-Z0-9-.+]+).*,.*/);

    return match ? match[1] : null;
  }
}
