<ng-content select="scw-mat-button,scw-mat-small-button"></ng-content>

<ng-template #informative_modal let-modal>
  <div class="modal-header scw-modal-header">
    <h3 class="modal-title scw-header">{{ modalHeader }}</h3>
    <button type="button" class="close" onfocus="blur()" aria-label="Close" (click)="modal.dismiss()">
    <span aria-hidden="true">
      <em class="fas fa-times"></em>
    </span>
    </button>
  </div>
  <div class="modal-body">
    <ng-template [ngIf]="modalHasImage">
      <img class="image-size" src='{{ imagePath }}' alt="{{ 'maintenance_performance.header.modal.info' | translate }}"/>
    </ng-template>
    <div *ngFor="let info of data; let last = last">
      <h4 class="scw-header informative-modal-item-header">{{ info.header }}</h4>
      <div class="informative-modal-item-message" *ngFor="let message of info.messages">
        {{ message }}
      </div>
      <scw-mat-modal-separator *ngIf="!last"></scw-mat-modal-separator>
    </div>
  </div>
  <div class="modal-footer">
    <div class="buttons-right">
      <scw-mat-button type="standard" (onClick)="modal.dismiss()">
        {{ 'general.close' | translate }}
      </scw-mat-button>
    </div>
  </div>
</ng-template>
