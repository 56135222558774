import { Action } from '@ngrx/store';
import {
  IMicrostop,
  IMicrostopActiveLinesParams,
  IMicrostopActiveLine,
  IMicrostopRequestParams,
  IMicrostopsAnalysisData,
  IMicrostopsAnalysisTableQuery,
  IMicrostopsDeviceMessage,
  ISensorDataDistributionQuery,
} from './microstops.model';

export const GET_MICROSTOPS_ANALYSIS_DATA = '[MICROSTOPS] GET MICROSTOPS ANALYSIS DATA';
export const GET_MICROSTOPS_ANALYSIS_DATA_COMPLETED = '[MICROSTOPS] GET MICROSTOPS ANALYSIS DATA COMPLETED';
export const GET_MICROSTOPS = '[MICROSTOPS] GET MICROSTOPS';
export const GET_SENSOR_HISTOGRAM_DATA_LOADING = '[HISTOGRAM] GET SENSOR HISTOGRAM DATA LOADING';
export const GET_SENSOR_HISTOGRAM_DATA_LOADED = '[HISTOGRAM] GET SENSOR HISTOGRAM DATA LOADED';
export const GET_MICROSTOPS_COMPLETED = '[MICROSTOPS] GET MICROSTOPS COMPLETED';
export const GET_MICROSTOP_ACTIVE_LINES = '[MICROSTOPS] GET MICROSTOP ACTIVE LINES';
export const GET_MICROSTOP_ACTIVE_LINES_COMPLETED = '[MICROSTOPS] GET MICROSTOP ACTIVE LINES COMPLETED';
export const FETCH_ERROR = '[MICROSTOPS ANALYSIS] FETCH ERROR';

export class GetMicrostopsAnalysisData {
  readonly type = GET_MICROSTOPS_ANALYSIS_DATA;

  constructor(public payload: IMicrostopsAnalysisTableQuery) {}
}

export class GetSensorHistogramDataLoading {
  readonly type = GET_SENSOR_HISTOGRAM_DATA_LOADING;

  constructor(public filters: ISensorDataDistributionQuery) {}
}

export class GetSensorHistogramDataLoaded {
  readonly type = GET_SENSOR_HISTOGRAM_DATA_LOADED;

  constructor(public payload: IMicrostopsDeviceMessage[]) {}
}

export class GetMicrostopsAnalysisDataCompleted {
  readonly type = GET_MICROSTOPS_ANALYSIS_DATA_COMPLETED;

  constructor(public payload: IMicrostopsAnalysisData) {}
}

export class GetMicrostops {
  readonly type = GET_MICROSTOPS;

  constructor(public params: IMicrostopRequestParams, public getWithActivityData: boolean = true) {}
}

export class GetMicrostopsCompleted {
  readonly type = GET_MICROSTOPS_COMPLETED;

  constructor(public payload: IMicrostop[]) {}
}

export class GetMicrostopActiveLines {
  readonly type = GET_MICROSTOP_ACTIVE_LINES;

  constructor(public params: IMicrostopActiveLinesParams) {}
}

export class GetMicrostopActiveLinesCompleted {
  readonly type = GET_MICROSTOP_ACTIVE_LINES_COMPLETED;

  constructor(public payload: IMicrostopActiveLine[]) {}
}

export class FetchError implements Action {
  readonly type = FETCH_ERROR;

  constructor(public payload: object) {}
}

export type MicrostopsActions =
  | GetMicrostopsAnalysisData
  | GetMicrostopsAnalysisDataCompleted
  | GetMicrostops
  | GetMicrostopsCompleted
  | GetMicrostopActiveLines
  | GetMicrostopActiveLinesCompleted
  | GetSensorHistogramDataLoading
  | GetSensorHistogramDataLoaded
  | FetchError;
