import {
  Component,
  ElementRef,
  EmbeddedViewRef,
  Input,
  OnDestroy,
  OnInit,
  TemplateRef,
  ViewChild,
  ViewContainerRef,
} from '@angular/core';
import { ScwMatButtonGroupButtons } from '../../shared/component/scw-mat-ui/scw-mat-button-group/scw-mat-button-group.model';
import * as FloorPlanHelper from '../../view/settings/departments-lines-stations/floor-plans/floor-plan.helper';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import {
  ELinePlanViewTypeEnum,
  FloorPlanConfigurationInterface,
  FloorPlanInterface,
  IArrowPosition,
  IDrawArrowProperties,
  IPositionElement,
  PlanItemFieldGElementDatumInterface,
  Point,
  staticToleranceValues,
  ZoomEvents,
} from '../../store/settings/departments-lines-stations/floor-plans/floor-plans.model';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { IPathNodeBox, ISimplePathNode } from './floor-plan-preview-modal.model';
import * as d3 from 'd3';
import * as _ from 'lodash';
import * as FloorPlanActions from '../../store/settings/departments-lines-stations/floor-plans/floor-plans.actions';
import { ofType } from '@ngrx/effects';
import { ActionsSubject, Store } from '@ngrx/store';
import { Subscription } from 'rxjs';
import { FloorPlanComponent } from '../../view/settings/departments-lines-stations/floor-plans/floor-plan.component';
import { EColorPalette, IColorPaletteOption } from '../../shared/service/color/color.model';
import { HelperService } from '../../shared/service/helper.service';
import { ColorService } from '../../shared/service/color/color.service';
import { IPoint } from '@swimlane/ngx-charts/lib/models/coordinates.model';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { OeeAppState } from '../../store/oee.reducer';
import { ScwMatButtonGroupModule } from '../../shared/component/scw-mat-ui/scw-mat-button-group/scw-mat-button-group.module';
import { NgForOf, NgIf } from '@angular/common';
import { ScwMatButtonModule } from '../../shared/component/scw-mat-ui/scw-mat-button/scw-mat-button.module';

@Component({
  standalone: true,
  selector: 'scw-floor-plan-preview-modal',
  templateUrl: './floor-plan-preview-modal.component.html',
  styleUrls: ['./floor-plan-preview-modal.component.scss'],
  imports: [TranslateModule, ScwMatButtonGroupModule, ScwMatButtonModule, DragDropModule, NgIf, NgForOf],
})
export class FloorPlanPreviewModalComponent implements OnInit, OnDestroy {
  @Input() public floorPlan: FloorPlanInterface;
  @Input() public nodes: ISimplePathNode[];
  @Input() public canChangeModes: boolean = true;
  @Input() public modalTitle: string = this.translate.instant('floorPlan.modal.floorPlanPreview');

  @ViewChild('arrowTemplatePreview', { read: TemplateRef }) arrowTemplatePreview: TemplateRef<ElementRef>;
  @ViewChild('preview_floor_plan_modal', { read: TemplateRef }) previewFloorPlanModal: TemplateRef<ElementRef>;

  public zoomButtonGroup: ScwMatButtonGroupButtons[];
  public floorPlanPreviewModalGroupButtons: ScwMatButtonGroupButtons[] = [];
  public viewType: ELinePlanViewTypeEnum = ELinePlanViewTypeEnum.cardView;
  public ELinePlanViewTypeEnum = ELinePlanViewTypeEnum;

  public selectedFloorPlanImageBase64: string;
  public previewMinZoom: number;
  public previewMaxZoom: number;
  public previewCurrentZoom: number;
  public previewZoom: d3.ZoomBehavior<SVGGElement, any>;
  public isSelectedFloorPlanImageLoaded: boolean = false;

  public modifiedNodes: IPathNodeBox[] = [];
  public drawArrowIds: IDrawArrowProperties[] = [];
  public arrowPositions: IArrowPosition[] = [];
  public selectedArrowElementId: string = '';

  public displayColorScheme: IColorPaletteOption[];
  public colorIds: EColorPalette[] = this.getColorIds();

  private readonly subscriptions: Subscription[] = [];
  private readonly defaultShapeStrokeWidth: number = 4;
  private readonly defaultCircleRadius: number = 12;
  private previousViewType: ELinePlanViewTypeEnum = ELinePlanViewTypeEnum.cardView;

  private zoomLevel: number = 1;
  private previewZoomLevel: number = 1;

  private isFirstLoadOfTheCurrentImage: boolean = true;
  private imageNaturalWidth: number;
  private imageNaturalHeight: number;

  constructor(
    private readonly activeModal: NgbActiveModal,
    private readonly translate: TranslateService,
    private readonly storeActions: ActionsSubject,
    private readonly arrowTemplateRef: ViewContainerRef,
    private readonly store: Store<OeeAppState>,
  ) {
    this.zoomButtonGroup = FloorPlanHelper.getZoomButtonConfiguration();
    this.floorPlanPreviewModalGroupButtons = FloorPlanHelper.getLinePlanViewTypeButtonData(this.translate);
    this.displayColorScheme = ColorService.getColorDataFromColorPalette(this.colorIds);
  }

  public ngOnInit(): void {
    this.isSelectedFloorPlanImageLoaded = false;
    this.floorPlanPreviewModalGroupButtons[1].disabled = !this.floorPlan.imagePath;
    this.viewType = ELinePlanViewTypeEnum.cardView;
    this.previousViewType = this.viewType;
    this.zoomButtonGroup[0].disabled = false;
    this.zoomButtonGroup[1].disabled = false;

    this.initializeFloorPlanCardViewPreview();

    if (this.floorPlan.imagePath) {
      this.store.dispatch(new FloorPlanActions.GetFloorPlanImageLoading(this.floorPlan.imagePath));
    }

    this.subscriptions.push(
      this.storeActions
        .pipe(ofType(FloorPlanActions.FLOOR_PLANS_GET_FLOOR_PLAN_IMAGE_LOADED))
        .subscribe((response: FloorPlanActions.GetFloorPlanImageLoaded): void => {
          this.isSelectedFloorPlanImageLoaded = true;
          this.selectedFloorPlanImageBase64 = `data:image/png;base64,${response.response.data}`;

          this.initializeFloorPlanPreviewSvg();
        }),
    );
  }

  public onPreviewPlanViewTypeChange(): void {
    if (this.previousViewType === this.viewType) {
      return;
    }

    this.previousViewType = this.viewType;
    this.previewZoomLevel = 1;
    this.zoomLevel = 1;
    this.zoomButtonGroup[1].disabled = false;
    this.drawArrowIds = [];

    setTimeout(() => {
      if (this.viewType === ELinePlanViewTypeEnum.mapView && this.isSelectedFloorPlanImageLoaded) {
        this.initializeFloorPlanPreviewSvg();
      } else {
        this.initializeFloorPlanCardViewPreview();
      }
    });
  }

  public previewZoomForCardView($event: ZoomEvents): void {
    this.previewZoomLevel += $event === ZoomEvents.ZOOM_IN ? 0.1 : -0.1;
    this.zoomLevel = this.previewZoomLevel;
    this.modifiedNodes.forEach((item: IPathNodeBox) => {
      document.getElementById(item.id.toString()).style.width = `${100 * this.previewZoomLevel}px`;
      document.getElementById(item.id.toString()).style.height = `${100 * this.previewZoomLevel}px`;
      item.position = {
        x: item.defaultPosition.x * this.previewZoomLevel,
        y: item.defaultPosition.y * this.previewZoomLevel,
      };
      _.find(this.modifiedNodes, { id: item.id }).position = item.position;

      this.selectedArrowElementId = item.id.toString();
      this.changeArrowPositionsAfterChangedBoxPosition(['endBoxId', 'startBoxId'], true);
    });

    this.zoomButtonGroup[1].disabled = this.previewZoomLevel < 0.7;
  }

  public applyZoom(event: ZoomEvents): void {
    if (event === ZoomEvents.ZOOM_IN) {
      this.zoomIn();
    } else {
      this.zoomOut();
    }
  }

  public closeModal(): void {
    this.activeModal.dismiss();
  }

  private initializeFloorPlanPreviewSvg(): void {
    const floorPlanPreviewSvg: d3.Selection<SVGSVGElement, any, HTMLElement, any> =
      FloorPlanComponent.createFloorPlanSvgOnSelection(d3.select('#floorPlanPreviewCard'), true);

    this.previewZoom = this.createZoomObject(floorPlanPreviewSvg);
    floorPlanPreviewSvg.call(this.previewZoom);

    const image: HTMLImageElement = new Image();

    image.onload = (): void => {
      this.isFirstLoadOfTheCurrentImage = true;
      this.imageNaturalWidth = image.naturalWidth;
      this.imageNaturalHeight = image.naturalHeight;

      floorPlanPreviewSvg.append('image').attr('xlink:href', this.selectedFloorPlanImageBase64);

      const { mapViewConfiguration } = this.floorPlan.configuration;

      this.initiateZoom(this.previewZoom);
      this.loadFloorPlan(
        _.orderBy(mapViewConfiguration.slice(), ['itemId']),
        floorPlanPreviewSvg,
        this.previewCurrentZoom,
      );
      this.updateZoomButtonStatuses();
    };
    image.src = this.selectedFloorPlanImageBase64;
  }

  private initiateZoom(zoom: d3.ZoomBehavior<SVGGElement, PlanItemFieldGElementDatumInterface>): void {
    const svgElement: d3.Selection<SVGSVGElement, any, HTMLElement, any> = d3.select('svg#floorPlanSvg');

    if (svgElement.empty()) {
      return;
    }

    const minZoom: number = Math.min(
      (svgElement.node().clientWidth || window.innerWidth) / this.imageNaturalWidth,
      (svgElement.node().clientHeight || window.innerHeight) / this.imageNaturalHeight,
    );
    const maxZoom: number = minZoom * 8;

    zoom.scaleExtent([minZoom, maxZoom]).translateExtent([
      [0, 0],
      [this.imageNaturalWidth, this.imageNaturalHeight],
    ]);

    const middleX: number = (svgElement.node().clientWidth - minZoom * this.imageNaturalWidth) / 2;
    const middleY: number = (svgElement.node().clientHeight - minZoom * this.imageNaturalHeight) / 2;
    const currentTransform: d3.ZoomTransform = d3.zoomTransform(svgElement.node());

    svgElement.style('height', `${minZoom * this.imageNaturalHeight}px`);
    this.previewMinZoom = minZoom;
    this.previewMaxZoom = maxZoom;

    d3.select('#floorPlanPreviewCard').style('height', 'fit-content');

    if (this.isFirstLoadOfTheCurrentImage) {
      this.isFirstLoadOfTheCurrentImage = false;
      zoom.transform(svgElement, d3.zoomIdentity.translate(middleX, middleY).scale(minZoom));
    } else {
      zoom.transform(
        svgElement,
        d3.zoomIdentity
          .translate(currentTransform.x, currentTransform.y)
          .scale(_.clamp(currentTransform.k, minZoom, maxZoom)),
      );
    }

    this.updateZoomButtonStatuses();
  }

  private updateZoomButtonStatuses(): void {
    _.find(this.zoomButtonGroup, {
      value: 'zoomIn',
    }).disabled = this.previewCurrentZoom >= this.previewMaxZoom;

    _.find(this.zoomButtonGroup, {
      value: 'zoomOut',
    }).disabled = this.previewCurrentZoom <= this.previewMinZoom;
  }

  private createZoomObject(
    svgElement: d3.Selection<SVGSVGElement, any, any, any>,
  ): d3.ZoomBehavior<SVGGElement, PlanItemFieldGElementDatumInterface> {
    return d3.zoom<SVGGElement, any>().on('zoom', (event): void => {
      const imageElement: d3.Selection<SVGImageElement, any, HTMLElement, any> = svgElement
        .select<SVGImageElement>('image')
        .attr('transform', event.transform)
        .attr('offsetX', event.transform.x)
        .attr('offsetY', event.transform.y);

      svgElement
        .selectAll<SVGGElement, PlanItemFieldGElementDatumInterface>('g')
        .attr('transform', (d: Point): string => {
          const translateX: number = d.x * event.transform.k + Number(imageElement.attr('offsetX'));
          const translateY: number = d.y * event.transform.k + Number(imageElement.attr('offsetY'));

          return `translate(${translateX},${translateY}) scale(${event.transform.k})`;
        });

      svgElement.selectAll<SVGCircleElement, PlanItemFieldGElementDatumInterface>('circle').attr('r', () => {
        return this.defaultCircleRadius / event.transform.k;
      });

      svgElement
        .selectAll<SVGPolygonElement, PlanItemFieldGElementDatumInterface>('polygon')
        .attr('stroke-width', `${this.defaultShapeStrokeWidth / event.transform.k}px`);

      this.previewCurrentZoom = event.transform.k;

      this.updateZoomButtonStatuses();
    });
  }

  private loadFloorPlan(
    floorPlanConfiguration: FloorPlanConfigurationInterface[],
    svgElement: d3.Selection<SVGSVGElement, any, HTMLElement, any>,
    currentZoom: number,
  ): void {
    floorPlanConfiguration?.forEach((planItemConfig: FloorPlanConfigurationInterface, index: number): void => {
      const svgImageElement: d3.Selection<SVGImageElement, any, any, any> = svgElement.select<SVGImageElement>('image');
      const planItemIndex: number = _.findIndex(
        this.nodes,
        (pathNode: { id: number }): boolean => pathNode.id === planItemConfig.itemId,
      );
      const planItem: ISimplePathNode = this.nodes[planItemIndex];

      if (!planItem) {
        return;
      }

      const planItemAreaIndex: number = index;
      const planItemName: string = planItem.name;

      const newGElement: d3.Selection<SVGGElement, PlanItemFieldGElementDatumInterface, any, any> = svgElement
        .append('g')
        .datum({
          itemId: planItemConfig.itemId,
          itemName: planItemName ?? planItemConfig.itemName,
          x: planItemConfig.xOffset,
          y: planItemConfig.yOffset,
        })
        .attr('transform', (datum: PlanItemFieldGElementDatumInterface): string => {
          const translateX: number = datum.x * currentZoom + Number(svgImageElement.attr('offsetX'));
          const translateY: number = datum.y * currentZoom + Number(svgImageElement.attr('offsetY'));

          return `translate(${translateX},${translateY}) scale(${currentZoom})`;
        });

      this.createPlanItemAreaShape(newGElement, planItemConfig.points, planItemAreaIndex);
      this.createPlanItemAreaText(newGElement);
    });
  }

  private createPlanItemAreaShape(
    planItemFieldGElement: d3.Selection<SVGGElement, any, SVGSVGElement, any>,
    polygonPoints: Point[],
    index?: number,
  ): void {
    const planItemColor: string = this.getColorScheme(index).backgroundColor;

    planItemFieldGElement
      .append('polygon')
      .attr('points', this.convertPointsArrayToPointsString(polygonPoints))
      .attr('width', '100')
      .attr('height', '100')
      .attr('stroke', planItemColor)
      .attr('stroke-width', `${this.defaultShapeStrokeWidth / this.previewCurrentZoom}px`)
      .attr('fill', planItemColor)
      .attr('fill-opacity', '0.6');
  }

  private createPlanItemAreaText(
    planItemFieldGElement: d3.Selection<SVGGElement, PlanItemFieldGElementDatumInterface, any, any>,
  ): void {
    const minZoom: number = this.previewMinZoom;
    const boundingBox: DOMRect = (
      planItemFieldGElement.select<SVGPolygonElement>('polygon').node() as SVGGraphicsElement
    )?.getBBox();

    if (!boundingBox) {
      return;
    }

    planItemFieldGElement
      .append('text')
      .attr('text-anchor', 'middle')
      .attr('dx', boundingBox.width / 2 + boundingBox.x)
      .attr('dy', boundingBox.height / 2 + boundingBox.y)
      .style(
        'font-size',
        `${
          (Math.max(this.imageNaturalWidth, this.imageNaturalHeight) *
            (this.imageNaturalHeight / this.imageNaturalWidth)) /
          60
        }px`,
      )
      .style('pointer-events', 'none')
      .text(planItemFieldGElement.datum().itemName)
      .call(this.getBoundingBox);

    planItemFieldGElement
      .insert('rect', 'text')
      .attr('x', (d: PlanItemFieldGElementDatumInterface) => {
        return d.bbox.x - 6 / minZoom;
      })
      .attr('y', (d: PlanItemFieldGElementDatumInterface) => {
        return d.bbox.y - 2 / minZoom;
      })
      .attr('rx', 4 / minZoom)
      .attr('width', (d: PlanItemFieldGElementDatumInterface) => {
        return d.bbox.width + 12 / minZoom;
      })
      .attr('height', (d: PlanItemFieldGElementDatumInterface) => {
        return d.bbox.height + 4 / minZoom;
      })
      .style('fill', 'white')
      .style('fill-opacity', '0.75');
  }

  private getColorScheme(index: number): IColorPaletteOption {
    return HelperService.cloneDeep(this.displayColorScheme[index % this.displayColorScheme.length]);
  }

  private getBoundingBox(
    selection: d3.Selection<SVGGraphicsElement, PlanItemFieldGElementDatumInterface, any, any>,
  ): void {
    selection.each((d: PlanItemFieldGElementDatumInterface): void => {
      d.bbox = selection.node().getBBox();
    });
  }

  private convertPointsArrayToPointsString(points: Point[]): string {
    return points.map((point: Point): string => `${point.x},${point.y}`).join(' ');
  }

  private initializeFloorPlanCardViewPreview(): void {
    this.arrowPositions = [];
    this.drawArrowIds = this.floorPlan.configuration.cardViewConfiguration?.drawArrowIds;
    this.modifiedNodes = this.nodes.reduce((acc: IPathNodeBox[], item: ISimplePathNode) => {
      const selectedBoxPosition = _.find(this.floorPlan.configuration.cardViewConfiguration.boxPositions, {
        boxId: item.id,
      });

      if (!selectedBoxPosition) {
        return acc;
      }

      acc.push({
        ...item,
        position: selectedBoxPosition?.position,
        defaultPosition: selectedBoxPosition?.position,
      });

      return acc;
    }, []);

    this.drawArrowIds?.forEach((item: IDrawArrowProperties) => {
      if (!_.find(this.nodes, { id: Number(item.startBoxId) }) || !_.find(this.nodes, { id: Number(item.endBoxId) })) {
        return;
      }

      this.arrowPositions.push({
        pathNodeId: `start${item.startBoxId}-end${item.endBoxId}`,
        startX: 0,
        startY: 0,
        endX: 0,
        endY: 0,
      });
      const isNotHasArrow: boolean = !document.getElementById(`start${item.startBoxId}-end${item.endBoxId}`);

      if (isNotHasArrow) {
        setTimeout(() => {
          this.createArrow(item.startBoxId, item.endBoxId);
        });
      }
    });

    setTimeout(() => {
      this.modifiedNodes.forEach((box: IPathNodeBox) => {
        this.selectedArrowElementId = box.id.toString();
        this.changeArrowPositionsAfterChangedBoxPosition(['endBoxId', 'startBoxId'], true);
      });
    });
  }

  private createArrow(startArrowId: string, endArrowId: string = null): void {
    if (this.viewType === ELinePlanViewTypeEnum.mapView) {
      return;
    }

    const arrowId: string = `start${startArrowId}${endArrowId ? `-end${endArrowId}` : ''}`;
    const arrowPathId: string = `arrow${startArrowId}${endArrowId ? `-arrow${endArrowId}` : ''}`;
    const createdNewArrow: EmbeddedViewRef<unknown> = this.arrowTemplateRef?.createEmbeddedView(
      this.arrowTemplatePreview,
    );
    createdNewArrow.rootNodes[0].setAttribute('id', arrowId);
    document.querySelector('.draggable-box')?.appendChild(createdNewArrow.rootNodes[0]);
    createdNewArrow.rootNodes[0].querySelector('marker').setAttribute('id', arrowPathId);
    createdNewArrow.rootNodes[0].querySelector('line').setAttribute('marker-end', `url(#${arrowPathId})`);
  }

  private changeArrowPositionsAfterChangedBoxPosition(filterKeys: string[], isZoomChange: boolean = false): void {
    filterKeys.forEach((key: string) => {
      const draggedItemPositions: IDrawArrowProperties[] = this.drawArrowIds.filter(
        (item: IDrawArrowProperties) => item[key] === this.selectedArrowElementId,
      );
      draggedItemPositions.forEach((item: IDrawArrowProperties) => {
        const selectedArrowIndex: number = _.findIndex(this.arrowPositions, {
          pathNodeId: `start${item.startBoxId}-end${item.endBoxId}`,
        });
        let startRect: DOMRect | IPositionElement;
        let endRect: DOMRect | IPositionElement;

        if (!isZoomChange) {
          startRect = document.getElementById(item.startBoxId).getBoundingClientRect();
          endRect = document.getElementById(item.endBoxId)?.getBoundingClientRect();
          this.setLinePositions(startRect, endRect, selectedArrowIndex);
        } else {
          const startBoxPositions: IPoint = _.find(this.modifiedNodes, {
            id: Number(item.startBoxId),
          })?.position;
          const endBoxPositions: IPoint = _.find(this.modifiedNodes, { id: Number(item.endBoxId) })?.position;
          startRect = { right: startBoxPositions?.x, top: startBoxPositions?.y };
          endRect = { left: endBoxPositions?.x, bottom: endBoxPositions?.y };
          this.setLinePositions(startRect, endRect, selectedArrowIndex, staticToleranceValues.zoomChange, true);
        }
      });
    });
  }

  private setLinePositions(
    startLinePosition: DOMRect | IPositionElement,
    endLinePosition: DOMRect | IPositionElement,
    selectedArrowIndex: number,
    toleranceValues: IArrowPosition = staticToleranceValues.defaultChange,
    isZoomChange: boolean = false,
  ): void {
    const zoomRatio: number = (1 - this.zoomLevel) * 50;

    if (this.arrowPositions[selectedArrowIndex]) {
      this.arrowPositions[selectedArrowIndex].startX =
        startLinePosition.right + toleranceValues.startX + (isZoomChange ? -zoomRatio : zoomRatio);
      this.arrowPositions[selectedArrowIndex].startY = startLinePosition.top + toleranceValues.startY - zoomRatio;
      this.arrowPositions[selectedArrowIndex].endX = endLinePosition.left + toleranceValues.endX - zoomRatio;
      this.arrowPositions[selectedArrowIndex].endY =
        endLinePosition.bottom + toleranceValues.endY + (isZoomChange ? -zoomRatio : zoomRatio);
      const parentArrowElement: HTMLElement = document.getElementById(
        this.arrowPositions[selectedArrowIndex].pathNodeId,
      );
      const arrowElement: SVGLineElement = parentArrowElement?.querySelector('line');
      const isAttributeValid: boolean =
        this.arrowPositions[selectedArrowIndex].endX >= 0 &&
        this.arrowPositions[selectedArrowIndex].endY >= 0 &&
        this.arrowPositions[selectedArrowIndex].startX >= 0 &&
        this.arrowPositions[selectedArrowIndex].startY >= 0;

      if (isAttributeValid && arrowElement) {
        arrowElement.setAttribute('x1', this.arrowPositions[selectedArrowIndex].startX.toString());
        arrowElement.setAttribute('y1', this.arrowPositions[selectedArrowIndex].startY.toString());
        arrowElement.setAttribute('x2', this.arrowPositions[selectedArrowIndex].endX.toString());
        arrowElement.setAttribute('y2', this.arrowPositions[selectedArrowIndex].endY.toString());
        const arrowLength: number = this.calculateArrowLength(selectedArrowIndex);
        parentArrowElement.querySelector('marker').setAttribute('refX', (arrowLength / 5.9).toString());
      }
    }
  }

  private calculateArrowLength(selectedArrowIndex: number): number {
    const arrowWidth: number = Math.abs(
      this.arrowPositions[selectedArrowIndex].startX - this.arrowPositions[selectedArrowIndex].endX,
    );
    const arrowHeight: number = Math.abs(
      this.arrowPositions[selectedArrowIndex].startY - this.arrowPositions[selectedArrowIndex].endY,
    );
    const arrowLength: number = Math.sqrt(arrowWidth * arrowWidth + arrowHeight * arrowHeight);
    this.arrowPositions[selectedArrowIndex].arrowPosition = arrowLength;

    return arrowLength;
  }

  private zoomIn(): void {
    this.zoomBy(1.5);
  }

  private zoomOut(): void {
    this.zoomBy(1 / 1.5);
  }

  private zoomBy(scale: number): void {
    const zoomTarget: d3.Selection<SVGSVGElement, any, HTMLElement, any> = d3.select<SVGSVGElement, any>(
      'svg#floorPlanSvg',
    );
    zoomTarget
      .transition()
      .duration(1000)
      .call(this.previewZoom.scaleBy, scale)
      .on('end', this.updateZoomButtonStatuses.bind(this));
  }

  private getColorIds(): EColorPalette[] {
    return [
      EColorPalette.RED_1,
      EColorPalette.INDIGO_2,
      EColorPalette.ORANGE_1,
      EColorPalette.INDIGO_1,
      EColorPalette.GREY_2,
      EColorPalette.BLUE_3,
      EColorPalette.LIME,
      EColorPalette.VIOLET_1,
      EColorPalette.BLUE_1,
      EColorPalette.VIOLET_2,
      EColorPalette.RED_2,
      EColorPalette.ORANGE_2,
      EColorPalette.YELLOW_2,
      EColorPalette.GREY_3,
      EColorPalette.GREEN_1,
      EColorPalette.RED_3,
      EColorPalette.BLUE_2,
      EColorPalette.YELLOW_1,
      EColorPalette.AQUA_2,
      EColorPalette.GREY_1,
      EColorPalette.GREEN_2,
    ];
  }

  public ngOnDestroy(): void {
    this.subscriptions?.forEach((subscription: Subscription) => subscription?.unsubscribe());
  }
}
