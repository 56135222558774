<ng-container *ngIf="properties.layout === 'percentage'">
  <progress-bar-metric
    [actualValue]="actualValue"
    [actualValueUnit]="actualValueUnit"
    [expectedValue]="expectedValue"
    [expectedValueUnit]="expectedValueUnit"
    [progressBarTitle]="progressBarTitle"
    [progressBarRateCapped]="progressBarRateCapped"
    [progressBarRate]="progressBarRate"
    [titleBracketInfo]="titleBracketInfo"
  >
  </progress-bar-metric>
</ng-container>
<ng-container *ngIf="properties.layout === 'multipleUnits'">
  <app-multiple-units-metric
    [actualValue]="selectedMetric.formula.actualValue"
    [titleBracketInfo]="titleBracketInfo"
    [unitOfCount]="properties.unitOfCount"
  >
  </app-multiple-units-metric>
</ng-container>

