import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TTemperatureMetricReading, TMetricReading } from './mini-metrics.model';
import { DecimalHelper } from '../../../../shared/helper/decimal/decimal-helper';
import { EHomeMiniMetricTemperatureScale } from '../../../settings/home-page-display-settings/home-mini-metrics/home-mini-metrics-settings-container.types';
import { TranslateService } from '@ngx-translate/core';

@Component({
  imports: [CommonModule],
  selector: 'app-mini-metrics',
  standalone: true,
  styleUrls: ['./mini-metrics.component.scss'],
  templateUrl: './mini-metrics.component.html',
})
export class MiniMetricsComponent implements OnInit, OnChanges {
  @Input() public temperatureReadings: TTemperatureMetricReading[] = [];
  @Input() public humidityReadings: TMetricReading[] = [];
  @Input() public size: 'small' | 'medium' | 'large' = 'small';
  @Input() public style: 'flat' | 'shadowed' = 'shadowed';

  constructor(private readonly decimalHelper: DecimalHelper, private readonly translate: TranslateService) {}

  public ngOnInit(): void {
    this.normalizeReadings();

    this.temperatureReadings = this.temperatureReadings.map((reading) => {
      const transformedReading =
        reading.scale === EHomeMiniMetricTemperatureScale.Fahrenheit
          ? this.convertTemperature(reading, EHomeMiniMetricTemperatureScale.Fahrenheit)
          : reading;

      return {
        ...transformedReading,
        value:
          transformedReading.value === null
            ? null
            : this.decimalHelper.formatBySeparator(
                this.decimalHelper.decimalToPercentageFormat(transformedReading.value),
              ),
      };
    });
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes['temperatureReadings'] || changes['humidityReadings']) {
      this.normalizeReadings();

      if (changes['temperatureReadings']) {
        this.temperatureReadings = this.temperatureReadings.map((reading) => {
          const transformedReading =
            reading.scale === EHomeMiniMetricTemperatureScale.Fahrenheit
              ? this.convertTemperature(reading, EHomeMiniMetricTemperatureScale.Fahrenheit)
              : reading;

          return {
            ...transformedReading,
            value:
              transformedReading.value === null
                ? null
                : this.decimalHelper.formatBySeparator(
                    this.decimalHelper.decimalToPercentageFormat(transformedReading.value),
                  ),
          };
        });
      }
    }
  }

  private convertTemperature(
    reading: TTemperatureMetricReading,
    targetScale: EHomeMiniMetricTemperatureScale,
  ): TTemperatureMetricReading {
    if (targetScale === EHomeMiniMetricTemperatureScale.Fahrenheit && reading.value !== null) {
      const c = this.convertTemperatureFromCelsiusToFahrenheit(reading);
      return c;
    }

    return reading;
  }

  private convertTemperatureFromCelsiusToFahrenheit(reading: TTemperatureMetricReading): TTemperatureMetricReading {
    if (reading.transformedScale === EHomeMiniMetricTemperatureScale.Fahrenheit) {
      return reading;
    }

    reading.value = DecimalHelper.add1(DecimalHelper.multiply(reading.value, '1.8'), '32');
    reading.transformedScale = EHomeMiniMetricTemperatureScale.Fahrenheit;

    if (this.decimalHelper.isGreaterThan(reading.value, '999.99')) {
      reading.normalizationMessage = 'MAX';
    }

    return reading;
  }

  private normalizeReadings(): void {
    const normalizer = (reading: TMetricReading): void => {
      if (reading.value === null || reading.value === undefined || reading.isNormalized) {
        return;
      }

      if (!this.decimalHelper.isValid(reading.value)) {
        reading.normalizationMessage = this.translate.instant('general.N/A');
      }

      if (this.decimalHelper.isGreaterThan(reading.value, '999.99')) {
        reading.normalizationMessage = 'MAX';
      }

      reading.isNormalized = true;
    };

    this.temperatureReadings.forEach((reading) => normalizer(reading));
    this.humidityReadings.forEach((reading) => normalizer(reading));
  }
}
