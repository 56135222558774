import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  forwardRef,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewEncapsulation,
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { ScwMatInputRule, ScwMatInputSize, ScwMatInputType } from './scw-mat-input.model';
import { Subscription } from 'rxjs';
import { Store } from '@ngrx/store';
import { OeeAppState } from '../../../../store/oee.reducer';
import * as _ from 'lodash';
import { TranslateService } from '@ngx-translate/core';
import { isEmail } from 'class-validator';
import { DecimalHelper } from '../../../helper/decimal/decimal-helper';
import { NEGATIVE_DECIMAL_MIN_VALUE } from '../../../../../constants';
import { TDecimalSeparator } from '../../../../../constants.model';

@Component({
  selector: 'scw-mat-input',
  templateUrl: './scw-mat-input.component.html',
  styleUrls: ['./scw-mat-input.component.scss'],
  host: { class: 'scw-mat-input-host' },
  encapsulation: ViewEncapsulation.None,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => ScwMatInputComponent),
      multi: true,
    },
  ],
})
export class ScwMatInputComponent implements OnInit, OnDestroy, ControlValueAccessor {
  @Input() inputModel;
  @Input() isValid: boolean = false;
  @Input() label: string = null;
  @Input() type: ScwMatInputType = 'text';
  @Input() size: ScwMatInputSize = 'md';
  @Input() placeholder: string = null;
  @Input() hint: string = null;
  @Input() maxlength: string = null;
  @Input() disabled: boolean = false;
  @Input() hideEyeIcon: boolean = false;
  @Input() block: boolean = false;
  @Input() className: string[] | { [klass: string]: any };
  @Input() hasErrors: boolean = false;
  @Input() errorText: string;
  @Input() rules: ScwMatInputRule[] = [];
  @Input() noPadding: boolean = false;
  @Input() dataPropertyKey?: string;
  @Input() nameAttribute: string;
  @Input() autocompleteAttribute: string;
  @Input() hasValidationControl: boolean = true;
  @Input() isInfoButtonEnabled: boolean = false;
  @Input() popover: string | null = null;

  @Output() inputModelChange: EventEmitter<any> = new EventEmitter<any>();
  @Output() isValidChange: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() onKeyup: EventEmitter<any> = new EventEmitter<any>();
  @Output() onKeyupEnter: EventEmitter<any> = new EventEmitter<any>();
  @Output() informationButtonClick: EventEmitter<void> = new EventEmitter<void>();

  public manageableType: ScwMatInputType = 'text';
  private isAnyError: boolean = false;
  private decimalSeparator: TDecimalSeparator = '.';
  private readonly integerRegex: RegExp = new RegExp(/^[-+]?[0-9]\d*$/);
  private readonly subscriptions: Subscription[] = [];
  private onChange: any = () => {};
  private onTouch: any = () => {};
  private isInputModelTrailingZeroesRemoved: boolean = false;

  constructor(
    private readonly store: Store<OeeAppState>,
    private translate: TranslateService,
    private decimalHelper: DecimalHelper,
    private changeRef: ChangeDetectorRef,
  ) {}

  public registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  public registerOnTouched(fn: any): void {
    this.onTouch = fn;
  }

  public writeValue(input: any): void {
    this.inputModel = input;
  }

  public reset(): void {
    if (this.type === 'password') {
      this.manageableType = 'password';
    }

    this.inputModel = '';
    this.clearErrorMessage();
  }

  private isValidEqualizer(isValid: boolean): void {
    this.isValid = isValid;
    this.isValidChange.emit(this.isValid);
  }

  private showErrorMessage(message: string): void {
    this.isValidEqualizer(false);
    this.isAnyError = true;
    this.hasErrors = true;
    this.errorText = message ? message : '';
  }

  public clearErrorMessage(): void {
    this.isValidEqualizer(true);
    this.isAnyError = false;
    this.hasErrors = false;
    this.errorText = null;
  }

  private requiredRule(rule: ScwMatInputRule): void {
    if (_.isNil(this.inputModel)
      || (typeof this.inputModel === 'string' && this.inputModel.length === 0)
      || (this.inputModel.toString() || '').trim().length === 0) {
      this.showErrorMessage(rule.message ?? this.translate.instant('scwMatForm.validation.required'));
    }
  }

  private minValueRule(rule: ScwMatInputRule): void {
    const inputModelString: string = String(this.inputModel);

    if (
      !_.isNil(this.inputModel) &&
      inputModelString.length > 0 &&
      !_.isNil(rule.minValue) &&
      (!this.integerRegex.test(inputModelString) ||
        this.decimalHelper.isLessThan(inputModelString, String(rule.minValue)))
    ) {
      this.showErrorMessage(
        rule.message ?? this.translate.instant('scwMatForm.validation.minValue', { minValue: rule.minValue }),
      );
    }
  }

  private maxValueRule(rule: ScwMatInputRule): void {
    const inputModelString: string = String(this.inputModel);

    if (
      !_.isNil(this.inputModel) &&
      inputModelString.length > 0 &&
      !_.isNil(rule.maxValue) &&
      (!this.integerRegex.test(inputModelString) ||
        this.decimalHelper.isGreaterThan(inputModelString, String(rule.maxValue)))
    ) {
      this.showErrorMessage(
        rule.message ?? this.translate.instant('scwMatForm.validation.maxValue', { maxValue: rule.maxValue }),
      );
    }
  }

  private minLengthRule(rule: ScwMatInputRule): void {
    if (this.inputModel && typeof this.inputModel === 'string' && this.inputModel.length < rule.minLength) {
      this.showErrorMessage(
        rule.message ?? this.translate.instant('scwMatForm.validation.minLength', { minLength: rule.minLength }),
      );
    }
  }

  private maxLengthRule(rule: ScwMatInputRule): void {
    if (this.inputModel && typeof this.inputModel === 'string' && this.inputModel.length > rule.maxLength) {
      this.showErrorMessage(
        rule.message ?? this.translate.instant('scwMatForm.validation.maxLength', { maxLength: rule.maxLength }),
      );
    }
  }

  private decimalRule(rule: ScwMatInputRule): void {
    if (!this.inputModel) {
      return;
    }

    const separator: TDecimalSeparator = rule.decimal.separator || this.decimalSeparator;
    const min: string = rule.decimal.min || (rule.decimal.allowNegative ? NEGATIVE_DECIMAL_MIN_VALUE : '0');
    const max: string = rule.decimal.max;
    const integerStepMin: number = rule.decimal.integerStep.min || 0;
    const integerStepMax: number = rule.decimal.integerStep.max;
    const decimalStepMin: number = rule.decimal.decimalStep.min || 0;
    const decimalStepMax: number = rule.decimal.decimalStep.max;
    const letterRegExp = /[a-zA-Z\s]/g;

    const positiveNumberRegex = `[0-9]{${integerStepMin},${integerStepMax}}(${_.escapeRegExp(
      separator,
    )}[0-9]{${decimalStepMin},${decimalStepMax}})?`;

    const negativeNumberEdgeCaseRegex = `(?!^-(0(${_.escapeRegExp(separator)}0*)?)?$)(?!^${_.escapeRegExp(
      separator,
    )}$)`;

    const regex = `${rule.decimal.allowNegative ? negativeNumberEdgeCaseRegex : ''}^${
      rule.decimal.allowNegative ? '(-)?' : ''
    }${positiveNumberRegex}$`;

    const decimalRegex: RegExp = new RegExp(regex);

    if (letterRegExp.test(this.inputModel)) {
      this.showErrorMessage(rule.message ?? this.translate.instant('scwMatForm.validation.decimalNumericValidation'));

      return;
    }

    const inputModelClone: string = String(_.cloneDeep(this.inputModel));
    const actualValue: string = separator === '.' ? this.inputModel : inputModelClone.replace(',', '.');

    let isLessThanMin: boolean = true;
    let isGreaterThanMax: boolean = true;

    if (decimalRegex.test(this.inputModel)) {
      isLessThanMin = this.decimalHelper.isLessThan(actualValue, min);
      isGreaterThanMax = this.decimalHelper.isGreaterThan(actualValue, max);
    }

    if (!decimalRegex.test(this.inputModel) || isLessThanMin || isGreaterThanMax) {
      const validationMessages = {
        '.': 'scwMatForm.validation.decimalDot',
        ',': 'scwMatForm.validation.decimalComma',
      };
      const validationMessageKey: string = validationMessages[separator];

      this.showErrorMessage(
        rule.message ??
          this.translate.instant(validationMessageKey, {
            min: separator === '.' ? min : String(min).replace('.', ','),
            max: separator === '.' ? max : String(max).replace('.', ','),
            decimalStepMax: rule.decimal.decimalStep.max,
          }),
      );
    }
  }

  private rangeValueRule(rule: ScwMatInputRule): void {
    if (
      this.inputModel &&
      (!this.integerRegex.test(this.inputModel) ||
        this.inputModel < rule.rangeValue.min ||
        this.inputModel > rule.rangeValue.max)
    ) {
      this.showErrorMessage(
        rule.message ??
          this.translate.instant('scwMatForm.validation.rangeValue', {
            min: rule.rangeValue.min,
            max: rule.rangeValue.max,
          }),
      );
    }
  }

  private rangeLengthRule(rule: ScwMatInputRule): void {
    if (
      this.inputModel &&
      (this.inputModel.length < rule.rangeLength.min || this.inputModel.length > rule.rangeLength.max)
    ) {
      this.showErrorMessage(
        rule.message ??
          this.translate.instant('scwMatForm.validation.rangeLength', {
            min: rule.rangeLength.min,
            max: rule.rangeLength.max,
          }),
      );
    }
  }

  private emailRule(rule: ScwMatInputRule): void {
    if (this.inputModel && !isEmail(this.inputModel)) {
      this.showErrorMessage(rule.message ?? this.translate.instant('scwMatForm.validation.email'));
    }
  }

  private patternRule(rule: ScwMatInputRule): void {
    if (this.inputModel && !rule.pattern.test(this.inputModel)) {
      this.showErrorMessage(rule.message);
    }
  }

  private noMatchPatternRule(rule: ScwMatInputRule): void {
    if (this.inputModel && rule.noMatch.test(this.inputModel)) {
      this.showErrorMessage(rule.message);
    }
  }

  private customRule(rule: ScwMatInputRule): void {
    if ((this.inputModel || rule.validateEmptyForCustom) && rule.custom && !rule.validator(this.inputModel)) {
      this.showErrorMessage(rule.message);
    }
  }

  public checkRules(): void {
    if (this.rules.length === 0) {
      this.isValidEqualizer(true);
      return;
    }

    this.isAnyError = false;

    for (const rule of this.rules) {
      if (this.isAnyError) {
        return;
      }

      switch (true) {
        case 'required' in rule:
          this.requiredRule(rule);
          break;
        case 'minValue' in rule:
          this.minValueRule(rule);
          break;
        case 'maxValue' in rule:
          this.maxValueRule(rule);
          break;
        case 'minLength' in rule:
          this.minLengthRule(rule);
          break;
        case 'maxLength' in rule:
          this.maxLengthRule(rule);
          break;
        case 'decimal' in rule:
          this.decimalRule(rule);
          break;
        case 'rangeValue' in rule:
          this.rangeValueRule(rule);
          break;
        case 'rangeLength' in rule:
          this.rangeLengthRule(rule);
          break;
        case 'email' in rule:
          this.emailRule(rule);
          break;
        case 'noMatch' in rule:
          this.noMatchPatternRule(rule);
          break;
        case 'pattern' in rule:
          this.patternRule(rule);
          break;
        case 'custom' in rule:
          this.customRule(rule);
          break;
        default:
          return;
      }
    }

    if (this.isAnyError) {
      return;
    }

    this.clearErrorMessage();
  }

  public onClickEyeIcon(): void {
    this.manageableType = this.manageableType === 'text' ? 'password' : 'text';
  }

  public onNgModelChange(): void {
    this.inputModelChange.emit(this.inputModel);
    this.checkRules();
  }

  public ngOnDestroy(): void {
    this.subscriptions.forEach((item: Subscription) => {
      item.unsubscribe();
    });
  }

  public ngOnInit(): void {
    this.manageableType = this.type;
    this.subscriptions.push(
      this.store.select('user').subscribe((state) => {
        this.decimalSeparator = state.decimalSeparator;
      }),
    );
  }

  public ngOnChanges(): void {
    const isInputDecimal: boolean = this.rules?.some((rule) => 'decimal' in rule);

    if (typeof this.inputModel === 'string' && isInputDecimal && !this.isInputModelTrailingZeroesRemoved) {
      const trailingZerosRemoved: string = this.decimalHelper.removeTrailingZeros(this.inputModel);

      this.inputModel = null;
      this.changeRef.detectChanges();
      this.inputModel = trailingZerosRemoved;
      this.isInputModelTrailingZeroesRemoved = !_.isNil(this.inputModel);
    }
  }

  public onInformationButtonClick(): void {
    this.informationButtonClick.emit();
  }
}
