import { FilterShiftActions, FilterShiftActionTypes } from './shift.actions';
import { IDateShifts } from '../../production-review/production-review.model';
import { ShiftCRUDInterface } from '../../../shared/component/filter/filter.class';

export interface FilterShiftState {
  isLoading: boolean;
  isLoaded: boolean;
  getShifts: boolean;
  gotShifts: boolean;
  selectedDate: string;
  data: ShiftCRUDInterface[];
  shiftsByDate: IDateShifts[];
  shouldIncludePlanName: boolean;
}

export const initialFilterShiftState: FilterShiftState = {
  isLoaded: false,
  isLoading: false,
  getShifts: false,
  gotShifts: false,
  selectedDate: '',
  data: [],
  shiftsByDate: [],
  shouldIncludePlanName: false,
};

export function filterShiftReducer(
  state: FilterShiftState = initialFilterShiftState,
  action: FilterShiftActions,
): FilterShiftState {
  switch (action.type) {
    case FilterShiftActionTypes.StartLoadFilterShifts:
      return {
        ...state,
        isLoading: true,
        isLoaded: false,
        shouldIncludePlanName: action.shouldIncludePlanName,
      };
    case FilterShiftActionTypes.FilterShiftsLoaded:
      return { ...state, data: action.data, isLoaded: true, isLoading: false };
    case FilterShiftActionTypes.GetShifts:
      return {
        ...state,
        ...{
          getShifts: true,
          gotShifts: false,
          selectedDate: action.payload.selectedDate,
          shiftsByDate: [],
        },
      };
    case FilterShiftActionTypes.GotShifts:
      return {
        ...state,
        ...{
          getShifts: false,
          gotShifts: true,
          shiftsByDate: action.payload,
        },
      };
    case FilterShiftActionTypes.ResetStore:
      return {
        ...initialFilterShiftState,
      };
    default:
      return state;
  }
}
