import { NgModule } from '@angular/core';
import { CICOItemComponent } from './cico-item/cico-item.component';
import { CICOHeadComponent } from './cico-head/cico-head.component';
import { AngularMultiSelectModule } from 'angular2-multiselect-dropdown';
import { CommonModule } from '@angular/common';

@NgModule({
  imports: [
    AngularMultiSelectModule,
    CommonModule,
  ],
  exports: [
    CICOItemComponent,
    CICOHeadComponent,
    AngularMultiSelectModule,
  ],
  declarations: [
    CICOItemComponent,
    CICOHeadComponent,
  ],
})
export class CICOModule {
}
