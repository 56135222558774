export interface IPushNotificationState {
  subscribeToUserUniqueTopic: boolean;
  subscribeToUserUniqueTopicCompleted: boolean;
}

export enum EPushNotificationStatus {
  default = 'default',
  granted = 'granted',
  denied = 'denied',
}

export interface ITopicSubscriptionResponseData {
  successCount: number;
  failureCount: number;
  errors: unknown[];
}
