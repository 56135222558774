import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, switchMap } from 'rxjs/operators';
import { from, of } from 'rxjs';
import * as ObjectActions from './customer.actions';
import { generateHttpOption } from '../../../shared/helper/app-helper';
import { CustomersService } from '../../settings/customers/customers.service';
import { GetManyResponseInterface } from '../../../shared/model/interface/crud-response-interface.model';
import { CustomerInterface } from '../../../view/settings/customer-settings/customers/customers.model';
import { HttpParams } from '@angular/common/http';

@Injectable()
export class FilterCustomerEffects {
  loadFilterCustomers$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ObjectActions.FilterCustomerActionTypes.StartLoadFilterCustomers),
      switchMap((objectData: ObjectActions.StartLoadFilterCustomers) => {
        const params: HttpParams = generateHttpOption(objectData.options).append('join', 'customerSegment');
        return from(this.customersService.getCustomers(params)).pipe(
          switchMap((response: GetManyResponseInterface<CustomerInterface>) => {
            const { data } = response;

            return of(new ObjectActions.FilterCustomersLoaded(data));
          }),
          catchError(() => {
            return of(new ObjectActions.FilterCustomersLoaded([]));
          }),
        );
      }),
      catchError(() => {
        return of(new ObjectActions.FilterCustomersLoaded([]));
      }),
    ),
  );

  constructor(
    private actions$: Actions<ObjectActions.CustomerFilterActions>,
    public customersService: CustomersService,
  ) {}
}
