import { Component, ContentChildren, EventEmitter, Input, Output, QueryList } from '@angular/core';
import { ScwMatButtonComponent } from '../scw-mat-button/scw-mat-button.component';
import { ScwMatDatepickerComponent } from '../scw-mat-datepicker/scw-mat-datepicker.component';
import { ScwMatInputComponent } from '../scw-mat-input/scw-mat-input.component';
import { ScwMatSelectComponent } from '../scw-mat-select/scw-mat-select.component';
import { ScwMatPickerComponent } from '../scw-mat-picker/scw-mat-picker.component';
import { ScwMatCronInputComponent } from '../scw-mat-cron-input/scw-mat-cron-input/scw-mat-cron-input.component';
import { ScwMatRadioGroupComponent } from '../scw-mat-radio-group/scw-mat-radio-group.component';
import { ScwMatTimepickerComponent } from '../scw-mat-timepicker/scw-mat-timepicker.component';
import { ScwMatWeekdayPickerComponent } from '../scw-mat-weekday-picker/scw-mat-weekday-picker.component';

@Component({
  selector: 'scw-mat-modal',
  templateUrl: './scw-mat-modal.component.html',
  styleUrls: ['./scw-mat-modal.component.scss'],
  host: { class: 'scw-mat-modal' },
})
export class ScwMatModalComponent {
  @ContentChildren(ScwMatButtonComponent, { descendants: true }) public buttons: QueryList<ScwMatButtonComponent>;
  @ContentChildren(ScwMatInputComponent, { descendants: true }) public inputs: QueryList<ScwMatInputComponent>;
  @ContentChildren(ScwMatSelectComponent, { descendants: true }) public selects: QueryList<ScwMatSelectComponent>;
  @ContentChildren(ScwMatDatepickerComponent, { descendants: true })
  public datePickers: QueryList<ScwMatDatepickerComponent>;
  @ContentChildren(ScwMatPickerComponent, { descendants: true }) public pickers: QueryList<ScwMatPickerComponent>;
  @ContentChildren(ScwMatCronInputComponent, { descendants: true })
  public cronInputs: QueryList<ScwMatCronInputComponent>;
  @ContentChildren(ScwMatRadioGroupComponent, { descendants: true })
  public radioGroups: QueryList<ScwMatRadioGroupComponent>;
  @ContentChildren(ScwMatTimepickerComponent, { descendants: true })
  public timePickers: QueryList<ScwMatTimepickerComponent>;
  @ContentChildren(ScwMatWeekdayPickerComponent, { descendants: true })
  public weekdayPickers: QueryList<ScwMatWeekdayPickerComponent>;

  @Input() isForm: boolean = false;

  @Output() onClickClose: EventEmitter<void> = new EventEmitter<void>();
  @Output() onFormSubmit: EventEmitter<boolean> = new EventEmitter<boolean>();

  public onClickCloseEmitter(): void {
    this.onClickClose.emit();
  }

  public onFormSubmitEmitter(isValid: boolean): void {
    this.onFormSubmit.emit(isValid);
  }
}
