import { Action } from '@ngrx/store';
import { HttpOptionInterface } from '../filter.model';
import { CustomerInterface } from '../../../view/settings/customer-settings/customers/customers.model';

export enum FilterCustomerActionTypes {
  StartLoadFilterCustomers = '[Filter Customer] Load Filter Customers',
  FilterCustomersLoaded = '[Filter Customer] Filter Customers Loaded',
}

export class StartLoadFilterCustomers implements Action {
  readonly type = FilterCustomerActionTypes.StartLoadFilterCustomers;

  constructor(public options: HttpOptionInterface) {}
}

export class FilterCustomersLoaded implements Action {
  readonly type = FilterCustomerActionTypes.FilterCustomersLoaded;

  constructor(public data: Partial<CustomerInterface>[]) {}
}

export type CustomerFilterActions = StartLoadFilterCustomers | FilterCustomersLoaded;
