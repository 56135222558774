import { NgFor, NgIf } from '@angular/common';
import {
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnDestroy,
  Output,
  QueryList,
  ViewChildren,
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { Subject, takeUntil } from 'rxjs';
import { SelectedDaysSummaryPipe } from 'src/app/standalone/pipes/selected-days-summary.pipe';
import { EWeekday, days } from 'src/app/store/user/model';
import { ShiftWeekdayPipe } from './shift-weekday.pipe';
import { TranslateService } from '@ngx-translate/core';
import { ScwMatInputRule } from '../scw-mat-input/scw-mat-input.model';
import * as _ from 'lodash';
import { MatFormFieldModule } from '@angular/material/form-field';

@Component({
  imports: [NgFor, SelectedDaysSummaryPipe, ShiftWeekdayPipe, NgIf, MatFormFieldModule],
  providers: [{ provide: NG_VALUE_ACCESSOR, multi: true, useExisting: ScwMatWeekdayPickerComponent }, ShiftWeekdayPipe],
  selector: 'scw-mat-weekday-picker',
  standalone: true,
  styleUrls: ['./scw-mat-weekday-picker.component.scss'],
  templateUrl: './scw-mat-weekday-picker.component.html',
})
export class ScwMatWeekdayPickerComponent implements ControlValueAccessor, OnDestroy {
  @Input() firstDay: EWeekday | undefined;

  @Input() value: EWeekday[] = [];
  @Input() rules: ScwMatInputRule[] = [];
  @Input() errorText: string;
  @Input() hasValidationControl: boolean = true;

  @Output() valueChange: EventEmitter<EWeekday[]> = new EventEmitter();

  @ViewChildren('checkbox') checkboxes: QueryList<ElementRef<HTMLInputElement>>;

  public readonly labels: string[] = days.map((day: string) => this.translate.instant(`general.days.${day}.abbrev1`));

  public disabled: boolean = false;
  public isValid: boolean = true;

  private readonly destroyed$: Subject<void> = new Subject();

  private onTouched: (() => void) | undefined;

  constructor(private readonly shiftWeekdayPipe: ShiftWeekdayPipe, private readonly translate: TranslateService) {}

  @HostListener('blur') public onBlur(): void {
    if (this.onTouched) {
      this.onTouched();
    }
  }

  public ngOnDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
  }

  public onChange(): void {
    const selectedDays: EWeekday[] = this.checkboxes.reduce((acc, cur) => {
      if (cur.nativeElement.checked) {
        const day = this.shiftWeekdayPipe.transform(Number(cur.nativeElement.value), this.firstDay);
        acc.push(day);
      }

      return acc;
    }, []);
    this.value = selectedDays;
    this.valueChange.emit(selectedDays);
    this.checkRules();
  }

  public registerOnChange(onChange: unknown): void {
    this.valueChange.pipe(takeUntil(this.destroyed$)).subscribe(onChange);
  }

  public registerOnTouched(onTouched: () => void): void {
    this.onTouched = onTouched;
  }

  public setDisabledState(disabled: boolean): void {
    this.disabled = disabled;
  }

  public writeValue(value: EWeekday[]): void {
    this.value = value;
  }

  public checkRules(): void {
    if (this.rules.length === 0) {
      return;
    }

    this.isValid = true;

    for (const rule of this.rules) {
      if (!this.isValid) {
        return;
      }

      switch (true) {
        case 'required' in rule:
          this.requiredRule(rule);
          break;
        default:
          return;
      }
    }

    if (!this.isValid) {
      return;
    }

    this.clearErrorMessage();
  }

  public reset(): void {
    this.value = null;
    this.clearErrorMessage();
  }

  public onNgModelChange(): void {
    this.valueChange.emit(this.value);
    this.checkRules();
  }

  public clearErrorMessage(): void {
    this.isValid = true;
    this.errorText = null;
  }

  private requiredRule(rule: ScwMatInputRule): void {
    if (_.isNil(this.value) || !this.value.length) {
      this.showErrorMessage(rule.message ?? this.translate.instant('scwMatForm.validation.required'));
    }
  }

  private showErrorMessage(message: string): void {
    this.isValid = false;
    this.errorText = message ?? '';
  }
}
