import { Inject, Injectable } from '@angular/core';
import { FilterServiceInterface } from './service.class';
import { HttpClient, HttpParams } from '@angular/common/http';
import { ActivityCRUDInterface } from '../../component/filter/filter.class';
import { GetManyResponseInterface } from '../../model/interface/crud-response-interface.model';
import { ScwMatPickerReturnInterface } from '../../component/scw-mat-ui/scw-mat-picker/scw-mat-picker.model';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root',
})
export class ActivityService implements FilterServiceInterface {
  constructor(
    public http: HttpClient,
    @Inject('API_BASE_URL') private readonly baseUrl: string,
    private translate: TranslateService,
  ) {}

  public getData(options?: HttpParams): Promise<ActivityCRUDInterface[]> {
    return new Promise((resolve, reject) => {
      this.http
        .get(`${this.baseUrl}/activities?fields=name,activityType,activitySubtype&join=activityTypeRelation`, { params: options })
        .subscribe((response: GetManyResponseInterface<ActivityCRUDInterface>) => {
          if (response.hasOwnProperty('data')) {
            resolve(response.data);
          } else {
            reject();
          }
        });
    });
  }

  public initActivityDefaultStateAndSetType(data: {
    activityId: number;
    activityType: string;
  }): ScwMatPickerReturnInterface {
    const activityNameAndActivityTaskName: ScwMatPickerReturnInterface = {
      text: '',
      type: 'default',
      value: data.activityId,
    };

    switch (data.activityType) {
      case 'runTime':
        activityNameAndActivityTaskName.type = 'run-time';
        break;
      case 'downTime':
        activityNameAndActivityTaskName.type = 'unplanned-down-time';
        break;
      case 'downTimePlanned':
        activityNameAndActivityTaskName.type = 'planned-down-time';
        break;
      case 'idleTime':
        activityNameAndActivityTaskName.type = 'default';
        break;
    }

    return activityNameAndActivityTaskName;
  }

  public setActivityAndTaskName(
    activityAndTaskName: ScwMatPickerReturnInterface,
    activityName: string | null,
    taskName?: string | null,
    equipmentName?: string | null,
  ): void {
    activityAndTaskName.text = activityName ?? this.translate.instant('activityCards.activityPlaceholder');

    if (taskName) {
      activityAndTaskName.text += ` - ${taskName}`;
    }

    if (equipmentName) {
      activityAndTaskName.text += ` - ${equipmentName}`;
    }
  }
}
