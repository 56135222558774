import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ScwMatHintComponent } from './scw-mat-hint.component';

@NgModule({
  declarations: [ScwMatHintComponent],
  imports: [CommonModule],
  exports: [ScwMatHintComponent],
})
export class ScwMatHintModule {}
