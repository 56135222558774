<div class="alert alert-primary" role="alert">
  <div class="row">
    <div class="col-12">
      <div *ngIf="!invalid; else invalidIcon">
        <scw-mat-button
          type="cta"
          (click)="onUpdateClicked()"
          [className]="['float-left update-card-button']"
          [disabled]="isApplyDisabled"
        >
          {{ buttonText }}
        </scw-mat-button>
        <scw-mat-button
          *ngIf="enableSetAsDefault"
          type="standard"
          (onClick)="onSetAsDefaultClicked()"
          [className]="['float-left update-card-button']"
        >
          {{ setAsDefaultButtonText }}
        </scw-mat-button>
      </div>
      <span class="alert-message" [ngClass]="{ 'disabled-text': isApplyDisabled }">
        <ng-template #invalidIcon>
          <em class="fas fa-exclamation-triangle middle"></em>
        </ng-template>
        {{ message }}
      </span>
    </div>
  </div>
</div>
