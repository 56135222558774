import { ISchedulerWorkOrderSetState } from './scheduler-work-order-set.model';
import { ActionReducer, createReducer, on } from '@ngrx/store';
import * as SchedulerWorkOrderSetActions from './scheduler-work-order-set.actions';

const initialState: ISchedulerWorkOrderSetState = {
  isWorkOrdersLoading: false,
  isWorkOrdersLoaded: false,
  workOrders: [],
  workOrdersItemCount: 0,
  workOrdersItemTotal: 0,
  isWorkOrderSetLoading: false,
  isWorkOrderSetLoaded: false,
  workOrderSet: [],
  workOrderSetItemCount: 0,
  workOrderSetItemTotal: 0,
  error: null,
};

export const schedulerWorkOrderSetReducer: ActionReducer<ISchedulerWorkOrderSetState> = createReducer(
  initialState,
  on(SchedulerWorkOrderSetActions.fetchWorkOrdersForSchedulerRules, (state) => ({
    ...state,
    isWorkOrderSetLoading: true,
    isWorkOrderSetLoaded: false,
    workOrderSet: [],
  })),
  on(SchedulerWorkOrderSetActions.fetchWorkOrdersForSchedulerRulesSuccess, (state, { data, count, total }) => ({
    ...state,
    isWorkOrderSetLoading: false,
    isWorkOrderSetLoaded: true,
    workOrderSet: data,
    workOrderSetItemCount: count,
    workOrderSetItemTotal: total,
  })),
  on(SchedulerWorkOrderSetActions.fetchWorkOrderSetError, (state, { error }) => ({
    ...state,
    isWorkOrderSetLoading: false,
    isWorkOrderSetLoaded: false,
    error,
  })),
  on(SchedulerWorkOrderSetActions.fetchWorkOrders, (state) => ({
    ...state,
    isWorkOrdersLoading: true,
    isWorkOrdersLoaded: false,
    workOrders: [],
  })),
  on(SchedulerWorkOrderSetActions.fetchWorkOrdersSuccess, (state, { data, count, total }) => ({
    ...state,
    isWorkOrdersLoading: false,
    isWorkOrdersLoaded: true,
    workOrders: data,
    workOrdersItemCount: count,
    workOrdersItemTotal: total,
  })),
  on(SchedulerWorkOrderSetActions.fetchWorkOrdersError, (state, { error }) => ({
    ...state,
    isWorkOrdersLoading: false,
    isWorkOrdersLoaded: false,
    error,
  })),
);
