import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import * as ObjectActions from './otif-analysis.actions';
import { catchError, switchMap } from 'rxjs/operators';
import * as AppActions from '../../app/actions';
import { of } from 'rxjs';
import { OtifAnalysisService } from './otif-analysis.service';
import { GetManyResponseInterface } from '../../../shared/model/interface/crud-response-interface.model';
import { IOtifAnalysisResponse } from './otif-analysis.model';
import { emptyAction } from '../../../../constants';

@Injectable()
export class OtifAnalysisEffects {
  constructor(private readonly actions$: Actions, private readonly service: OtifAnalysisService) {}

  getOtifAnalysisData = createEffect(() =>
    this.actions$.pipe(
      ofType(ObjectActions.OtifAnalysisActionTypes.OtifAnalysisDataLoading),
      switchMap((payload: ObjectActions.OtifAnalysisDataLoading) => {
        return this.service.getOtifAnalysisData(payload.params).pipe(
          switchMap((response: GetManyResponseInterface<IOtifAnalysisResponse>) => {
            return of(new ObjectActions.OtifAnalysisDataLoaded(response));
          }),
          catchError((errorRes) => {
            return of(new ObjectActions.FetchError(errorRes), new AppActions.HideLoader());
          }),
        );
      }),
      catchError((errorRes) => {
        return of(new ObjectActions.FetchError(errorRes), new AppActions.HideLoader());
      }),
    ),
  );

  downloadOtifAnalysisExcel = createEffect(() =>
    this.actions$.pipe(
      ofType(ObjectActions.OtifAnalysisActionTypes.DownloadOtifAnalysisExcel),
      switchMap((objectData: ObjectActions.DownloadOtifAnalysisExcel) => {
        this.service.downloadExcel(objectData.data, objectData.tableHeaders);
        return emptyAction;
      }),
      catchError((errorRes) => {
        return of(new ObjectActions.FetchError(errorRes), new AppActions.HideLoader());
      }),
    ),
  );
}
