import { AccountSettingsStateInterface } from './account.model';
import * as Actions from './account.actions';

export const accountSettingsState: AccountSettingsStateInterface = {
  accountSettingsData: null,
  accountSettingsDataLoaded: false,
  accountSettingsDataLoading: false,
  accountEditSettingsLoaded: false,
  accountEditSettingsLoading: false,
  siteLoaded: false,
  siteLoading: false,
  languageLoaded: false,
  languageLoading: false,
  linesLoaded: false,
  linesLoading: false,
};

export function accountSettingsReducer(
  state: AccountSettingsStateInterface = accountSettingsState,
  action: Actions.AccountActions,
): AccountSettingsStateInterface {
  switch (action.type) {
    case Actions.GET_ACCOUNT_SETTINGS:
      return {
        ...state,
        ...{
          accountSettingsDataLoading: true,
          accountSettingsDataLoaded: false,
        },
      };
    case Actions.GET_ACCOUNT_SETTINGS_COMPLETED:
      return {
        ...state,
        ...{
          accountSettingsDataLoading: false,
          accountSettingsDataLoaded: true,
        },
      };
    case Actions.EDIT_ACCOUNT_SETTINGS:
      return {
        ...state,
        ...{
          accountEditSettingsLoading: true,
          accountEditSettingsLoaded: false,
        },
      };
    case Actions.EDIT_ACCOUNT_SETTINGS_COMPLETED:
      return {
        ...state,
        ...{
          accountEditSettingsLoading: false,
          accountEditSettingsLoaded: true,
        },
      };
    case Actions.GET_LANGUAGES:
      return {
        ...state,
        ...{
          languageLoading: true,
          languageLoaded: false,
        },
      };
    case Actions.GET_LANGUAGES_COMPLETED:
      return {
        ...state,
        ...{
          languageLoading: false,
          languageLoaded: true,
        },
      };
    case Actions.GET_SITES:
      return {
        ...state,
        ...{
          siteLoading: true,
          siteLoaded: false,
        },
      };
    case Actions.GET_SITES_COMPLETED:
      return {
        ...state,
        ...{
          siteLoading: false,
          siteLoaded: true,
        },
      };
    case Actions.GET_LINES:
      return {
        ...state,
        ...{
          linesLoading: true,
          linesLoaded: false,
        },
      };
    case Actions.GET_LINES_COMPLETED:
      return {
        ...state,
        ...{
          linesLoading: false,
          linesLoaded: true,
          lines: action.response?.data,
        },
      };
    default:
      return state;
  }
}
