import { ResourcesActionTypes, ResourcesActions } from './resources.actions';
import { ResourcesState } from './resources.model';
import * as Actions from './resources.actions';
import { buildNodeTree, resetTreeSelection } from './resources.helpers';
import { HelperService } from '../../../../../shared/service/helper.service';

export const resourcesInitialState: ResourcesState = {
  status: 'initial',
  resourceGroupTree: [],
  getManyProductResourcesDataLoading: false,
  getManyProductResourcesDataLoaded: false,
  product: null,
  productResources: null,
  addProductResourcesLoading: false,
  addProductResourcesLoaded: false,
  addProductResourcesItemLoading: false,
  addProductResourcesItemLoaded: false,
  editProductResourcesLoading: false,
  editProductResourcesLoaded: false,
  deleteProductResourcesLoading: false,
  deleteProductResourcesLoaded: false,
  deleteProductResourcesBulkLoading: false,
  deleteProductResourcesBulkLoaded: false,
};

export function resourcesReducer(
  state: ResourcesState = resourcesInitialState,
  action: ResourcesActions,
): ResourcesState {
  switch (action.type) {
    case ResourcesActionTypes.LOAD_RESOURCE_GROUPS:
      return { ...state, status: 'loading' };
    case ResourcesActionTypes.RESOURCE_GROUPS_LOADED_ERROR:
      return { ...state, status: 'failure' };
    case ResourcesActionTypes.RESOURCE_GROUPS_LOADED_SUCCESS:
      return {
        ...state,
        status: 'success',
        resourceGroupTree: buildNodeTree(HelperService.cloneDeep(action.payload)),
      };
    case ResourcesActionTypes.RESET_RESOURCE_GROUPS_SELECTION:
      return {
        ...state,
        resourceGroupTree: resetTreeSelection(HelperService.cloneDeep(state.resourceGroupTree)),
      };
    case ResourcesActionTypes.GET_MANY_PRODUCT_RESOURCES_LOADING:
      return {
        ...state,
        getManyProductResourcesDataLoading: true,
        getManyProductResourcesDataLoaded: false,
      };
    case ResourcesActionTypes.GET_MANY_PRODUCT_RESOURCES_LOADED:
      return {
        ...state,
        product: action.payload.product.data,
        productResources: action.payload.productResources.data,
        getManyProductResourcesDataLoading: false,
        getManyProductResourcesDataLoaded: true,
      };
    case Actions.ResourcesActionTypes.ADD_PRODUCT_RESOURCE:
      return {
        ...state,
        addProductResourcesLoading: true,
        addProductResourcesLoaded: false,
      };
    case Actions.ResourcesActionTypes.ADD_PRODUCT_RESOURCE_COMPLETED:
      return {
        ...state,
        addProductResourcesLoading: false,
        addProductResourcesLoaded: true,
      };
    case Actions.ResourcesActionTypes.ADD_PRODUCT_RESOURCE_ITEM:
      return {
        ...state,
        addProductResourcesItemLoading: true,
        addProductResourcesItemLoaded: false,
      };
    case Actions.ResourcesActionTypes.ADD_PRODUCT_RESOURCE_ITEM_COMPLETED:
      return {
        ...state,
        addProductResourcesItemLoading: false,
        addProductResourcesItemLoaded: true,
      };
    case Actions.ResourcesActionTypes.EDIT_PRODUCT_RESOURCE:
      return {
        ...state,
        editProductResourcesLoading: true,
        editProductResourcesLoaded: false,
      };
    case Actions.ResourcesActionTypes.EDIT_PRODUCT_RESOURCE_COMPLETED:
      return {
        ...state,
        editProductResourcesLoading: false,
        editProductResourcesLoaded: true,
      };
    case Actions.ResourcesActionTypes.DELETE_PRODUCT_RESOURCE:
      return {
        ...state,
        deleteProductResourcesLoading: true,
        deleteProductResourcesLoaded: false,
      };
    case Actions.ResourcesActionTypes.DELETE_PRODUCT_RESOURCE_COMPLETED:
      return {
        ...state,
        deleteProductResourcesLoading: false,
        deleteProductResourcesLoaded: true,
      };
    case Actions.ResourcesActionTypes.DELETE_PRODUCT_RESOURCES:
      return {
        ...state,
        deleteProductResourcesBulkLoading: true,
        deleteProductResourcesBulkLoaded: false,
      };
    case Actions.ResourcesActionTypes.DELETE_PRODUCT_RESOURCES_COMPLETED:
      return {
        ...state,
        deleteProductResourcesBulkLoading: false,
        deleteProductResourcesBulkLoaded: true,
      };
    case Actions.ResourcesActionTypes.FETCH_ERROR:
      return {
        ...state,
        getManyProductResourcesDataLoading: false,
        getManyProductResourcesDataLoaded: false,
      };
    default:
      return state;
  }
}
