import { NgIf } from '@angular/common';
import {
  AfterContentInit,
  Component,
  ContentChildren,
  EventEmitter,
  HostListener,
  Input,
  OnDestroy,
  Output,
  QueryList,
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { Subject, takeUntil } from 'rxjs';
import { ScwMatCheckboxModule } from '../scw-mat-checkbox/scw-mat-checkbox.module';
import { ScwMatCheckboxComponent } from '../scw-mat-checkbox/scw-mat-checkbox.component';
import { IScwMatFormElement } from '../scw-mat-form/scw-mat-form.model';
import { ScwMatInputRule } from '../scw-mat-input/scw-mat-input.model';

@Component({
  imports: [MatFormFieldModule, NgIf, ScwMatCheckboxModule, TranslateModule],
  providers: [{ provide: NG_VALUE_ACCESSOR, multi: true, useExisting: ScwMatCheckboxGroupComponent }],
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'scw-mat-checkbox-group',
  standalone: true,
  templateUrl: './scw-mat-checkbox-group.component.html',
})
export class ScwMatCheckboxGroupComponent
  implements AfterContentInit, ControlValueAccessor, IScwMatFormElement, OnDestroy
{
  @Input() hint: string | undefined;
  @Input() rules: readonly ScwMatInputRule[] = [];

  @Input() inputModel: readonly unknown[] = [];
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  @Output() inputModelChange: EventEmitter<readonly any[]> = new EventEmitter();

  @ContentChildren(ScwMatCheckboxComponent) private readonly options: QueryList<ScwMatCheckboxComponent>;

  public isValid: boolean = true;

  public get error(): string | undefined {
    return this.internalError;
  }

  public set error(value: string | undefined) {
    this.internalError = value;
    this.isValid = value === undefined;
  }

  private internalError: string | undefined;

  private readonly destroyed$: Subject<void> = new Subject();

  private onTouched: (() => void) | undefined;

  constructor(private readonly translate: TranslateService) {}

  @HostListener('blur') public onBlur(): void {
    if (this.onTouched) {
      this.onTouched();
    }
  }

  public checkRules(): void {
    this.error = undefined;
    for (const rule of this.rules) {
      const error: string | undefined = this.checkRule(rule);

      if (error) {
        this.error = error;
        break;
      }
    }
  }

  public clearErrorMessage(): void {
    this.error = undefined;
  }

  public ngAfterContentInit(): void {
    setTimeout(() => {
      this.options.forEach((item: ScwMatCheckboxComponent) => {
        item.inputModel = this.inputModel.includes(item.value);
        item.inputModelChange.pipe(takeUntil(this.destroyed$)).subscribe(() => this.update());
      });
    });
  }

  public ngOnDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
  }

  public registerOnChange(onChange: unknown): void {
    this.inputModelChange.pipe(takeUntil(this.destroyed$)).subscribe(onChange);
  }

  public registerOnTouched(onTouched: () => void): void {
    this.onTouched = onTouched;
  }

  public reset(): void {
    this.inputModel = [];
    this.clearErrorMessage();
  }

  public setDisabledState(disabled: boolean): void {
    this.options.forEach((item: ScwMatCheckboxComponent) => (item.disabled = disabled));
  }

  public writeValue(value: readonly unknown[]): void {
    this.inputModel = value;
  }

  private checkRequiredRule(rule: ScwMatInputRule): string | undefined {
    if (!this.inputModel.length) {
      return rule.message ?? this.translate.instant('scwMatForm.validation.required');
    }

    return undefined;
  }

  private checkRule(rule: ScwMatInputRule): string | undefined {
    if ('required' in rule) {
      return this.checkRequiredRule(rule);
    }

    return undefined;
  }

  private update(): void {
    const checkedItems: readonly unknown[] = this.options.reduce((acc, cur) => {
      if (cur.inputModel) {
        acc.push(cur.value);
      }

      return acc;
    }, []);

    this.inputModel = checkedItems;
    this.inputModelChange.emit(checkedItems);
    this.checkRules();
  }
}
