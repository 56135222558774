<ng-template #notification_permission_modal>
  <div id="notification-permission-modal">
    <div class="modal-header scw-modal-header">
      <h3 class="modal-title scw-header">{{ modalTitle }}</h3>
      <button type="button" class="close" onfocus="blur()" aria-label="Close" (click)="closeModal()">
        <span aria-hidden="true">
          <em class="fas fa-times"></em>
        </span>
      </button>
    </div>
    <div class="modal-body">
      <div class="row">
        <div class="col-12">
          <h6 class="scw-header"><em class="fas fa-info-circle"></em>&nbsp; {{ modalInfo }}</h6>
        </div>
      </div>
    </div>
    <div class="modal-footer">
      <div class="buttons-right">
        <scw-mat-button type="standard" (onClick)="remindLater()">
          {{ modalRemindLater }}
        </scw-mat-button>
        <scw-mat-button (onClick)="getToken()">
          {{ modalConfigure }}
        </scw-mat-button>
      </div>
    </div>
  </div>
</ng-template>
