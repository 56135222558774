import { Action } from '@ngrx/store';
import { HttpOptionInterface } from '../filter.model';
import { ProductCRUDInterface, ProductFamilyCRUDInterface } from '../../../shared/component/filter/filter.class';
import { EProductOrFamilyFilterType } from '../../../shared/component/filter/filterable-objects.class';

export enum FilterProductOrProductFamilyActionTypes {
  StartLoadFilterProductOrProductFamily = '[Filter Product Or Product Family] Load Filter Product or Product Family',
  FilterProductOrProductFamilyLoaded = '[Filter Product Or Product Family] Filter Product or Product Family Loaded',
  ResetProductOrProductFamilyStore = '[Filter Product Or Product Family] Reset store data for product or product family filter',
}

export class StartLoadFilterProductOrProductFamily implements Action {
  readonly type = FilterProductOrProductFamilyActionTypes.StartLoadFilterProductOrProductFamily;

  constructor(
    public options: HttpOptionInterface,
    public isFetchMoreRequest: boolean = false,
    public filterType?: EProductOrFamilyFilterType,
  ) {}
}

export class FilterProductOrProductFamilyLoaded implements Action {
  readonly type = FilterProductOrProductFamilyActionTypes.FilterProductOrProductFamilyLoaded;

  constructor(
    public data: ProductCRUDInterface[] | ProductFamilyCRUDInterface[],
    public totalCount: number = 0,
    public isFetchMoreRequest: boolean = false,
  ) {}
}

export class ResetProductOrProductFamilyStore implements Action {
  readonly type = FilterProductOrProductFamilyActionTypes.ResetProductOrProductFamilyStore;

  constructor() {}
}

export type FilterProductOrProductFamilyActions =
  | StartLoadFilterProductOrProductFamily
  | FilterProductOrProductFamilyLoaded
  | ResetProductOrProductFamilyStore;
