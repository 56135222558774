import { TreeCheckboxComponentNodeInterface } from 'src/app/shared/component/tree-checkbox/tree-checkbox.component';
import { ResourceGroup } from './resources.model';

export function buildNodeTree(flatNodes: ResourceGroup[]): TreeCheckboxComponentNodeInterface[] {
  const tree: TreeCheckboxComponentNodeInterface[] = [];
  let i = 0;
  let skips = 0;
  while (flatNodes.length) {
    if (skips === flatNodes.length) {
      break;
    }

    i = i % flatNodes.length;
    const node = flatNodes[i];
    const newNode: TreeCheckboxComponentNodeInterface = {
      id: node.id,
      name: node.line?.title ?? node.name,
      checked: false,
    };

    if (node.parent) {
      const parent = findNodeById(tree, node.parent);

      if (!parent) {
        i++;
        skips++;
        continue;
      }

      parent.children = parent.children ?? [];
      parent.children.push(newNode);
    } else {
      tree.push(newNode);
    }

    flatNodes.splice(i, 1);
    skips = 0;
  }
  return tree;
}

export function resetTreeSelection(nodes: TreeCheckboxComponentNodeInterface[]): TreeCheckboxComponentNodeInterface[] {
  return nodes.map((node: TreeCheckboxComponentNodeInterface) => {
    if (node.children?.length) {
      node.children = resetTreeSelection(node.children);
    }

    node.checked = false;
    node.indeterminate = false;

    return node;
  });
}

function findNodeById(nodes: TreeCheckboxComponentNodeInterface[], id: number): TreeCheckboxComponentNodeInterface {
  for (const node of nodes) {
    if (node.id === id) {
      return node;
    }

    if (node.children) {
      const child = findNodeById(node.children, id);

      if (child) {
        return child;
      }
    }
  }
  return undefined;
}
