import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { HelperService } from '../service/helper.service';
import * as _ from 'lodash';

@Pipe({
  name: 'formatDurationFromSecond',
})
export class FormatDurationFromSecondPipe implements PipeTransform {
  constructor(private readonly translate: TranslateService, private readonly helperService: HelperService) {}

  transform(duration: number | string, withSecond: boolean = false, suffix: boolean = false): string {
    const tempDuration = _.isNumber(duration) ? duration : _.toInteger(duration);

    return this.helperService.formatDuration(tempDuration, withSecond, suffix);
  }
}
