import * as Actions from './product-versions.actions';
import { IProductVersionsState } from './product-versions.model';

export const productVersionsState: IProductVersionsState = {
  versionsDataLoading: false,
  versionsDataLoaded: false,
  defaultVersionDataLoading: false,
  defaultVersionDataLoaded: false,
  addVersionLoading: false,
  addVersionLoaded: false,
  editVersionLoading: false,
  editVersionLoaded: false,
  deleteVersionLoading: false,
  deleteVersionLoaded: false,
  deleteVersionsLoading: false,
  deleteVersionsLoaded: false,
  versionsData: null,
  defaultVersionData: null,
};

export function productVersionsReducer(
  state: IProductVersionsState = productVersionsState,
  action: Actions.ProductVersionsActions,
): IProductVersionsState {
  switch (action.type) {
    case Actions.EProductVersionsAction.GET_VERSIONS_DATA:
      return {
        ...state,
        versionsDataLoading: true,
        versionsDataLoaded: false,
      };
    case Actions.EProductVersionsAction.GET_VERSIONS_DATA_COMPLETED:
      return {
        ...state,
        versionsData: action.payload,
        versionsDataLoading: false,
        versionsDataLoaded: true,
      };
    case Actions.EProductVersionsAction.GET_DEFAULT_VERSION_DATA:
      return {
        ...state,
        defaultVersionDataLoading: true,
        defaultVersionDataLoaded: false,
      };
    case Actions.EProductVersionsAction.GET_DEFAULT_VERSION_DATA_COMPLETED:
      return {
        ...state,
        defaultVersionData: action.payload.data[0],
        defaultVersionDataLoading: false,
        defaultVersionDataLoaded: true,
      };
    case Actions.EProductVersionsAction.ADD_VERSION:
      return {
        ...state,
        addVersionLoading: true,
        addVersionLoaded: false,
      };
    case Actions.EProductVersionsAction.ADD_VERSION_COMPLETED:
      return {
        ...state,
        addVersionLoading: false,
        addVersionLoaded: true,
      };
    case Actions.EProductVersionsAction.EDIT_VERSION:
      return {
        ...state,
        editVersionLoading: true,
        editVersionLoaded: false,
      };
    case Actions.EProductVersionsAction.EDIT_VERSION_COMPLETED:
      return {
        ...state,
        editVersionLoading: false,
        editVersionLoaded: true,
      };
    case Actions.EProductVersionsAction.DELETE_VERSION:
      return {
        ...state,
        deleteVersionLoading: true,
        deleteVersionLoaded: false,
      };
    case Actions.EProductVersionsAction.DELETE_VERSION_COMPLETED:
      return {
        ...state,
        deleteVersionLoading: false,
        deleteVersionLoaded: true,
      };
    case Actions.EProductVersionsAction.DELETE_VERSIONS:
      return {
        ...state,
        deleteVersionsLoading: true,
        deleteVersionsLoaded: false,
      };
    case Actions.EProductVersionsAction.DELETE_VERSIONS_COMPLETED:
      return {
        ...state,
        deleteVersionsLoading: false,
        deleteVersionsLoaded: true,
      };
    case Actions.EProductVersionsAction.FETCH_ERROR:
      return {
        ...state,
        versionsDataLoading: false,
        versionsDataLoaded: false,
      };
    default:
      return state;
  }
}
