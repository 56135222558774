import { Inject, Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import {
  IAddScenarioRequest,
  BulkEditScenarioRequestInterface,
  ScenarioInterface,
  IEditScenarioRequest,
} from '../../../store/scheduler-scenario/scheduler-scenario.model';
import * as _ from 'lodash';
import {
  BaseCrudResponse,
  BaseOneResponseInterface,
  BulkResponseDataInterface,
  GetManyResponseInterface,
} from '../../model/interface/crud-response-interface.model';
import { ActiveStatuses } from '../../../../constants';
import { GenericCrudRequestConstructionParameters } from '../../model/interface/generic-api-request.model';
import { HTTPHelperService } from '../http/http.helper.service';

@Injectable({
  providedIn: 'root',
})
export class SchedulerScenarioService {
  private readonly routes = {
    scenarios: '/scheduler/scenarios',
    bulkEdit: '/scheduler/scenarios/bulk/edit',
    bulkDelete: '/scheduler/scenarios/bulk/delete',
  };

  constructor(
    public http: HttpClient,
    @Inject('API_BASE_URL') private readonly api: string,
    private readonly httpHelperService: HTTPHelperService,
  ) {}

  public loadScenarios(
    parameters: GenericCrudRequestConstructionParameters,
  ): Observable<GetManyResponseInterface<ScenarioInterface>> {
    const params: HttpParams = this.httpHelperService.insertGenericCrudRequestParameters(parameters);
    return this.http.get<GetManyResponseInterface<ScenarioInterface>>(`${this.api}${this.routes.scenarios}`, {
      params,
    });
  }

  public createScenario(addScenario: IAddScenarioRequest): Observable<BaseOneResponseInterface<ScenarioInterface>> {
    return this.http.post<BaseOneResponseInterface<ScenarioInterface>>(`${this.api}${this.routes.scenarios}`, {
      ...addScenario,
    });
  }

  public cloneScenario(id: number): Observable<BaseOneResponseInterface<ScenarioInterface>> {
    return this.http.put<BaseOneResponseInterface<ScenarioInterface>>(
      `${this.api}${this.routes.scenarios}/${id}/clone`,
      {},
    );
  }

  public updateScenario(
    id: number,
    scenario: IEditScenarioRequest,
  ): Observable<BaseOneResponseInterface<ScenarioInterface>> {
    const params: HttpParams = new HttpParams()
      .append('join', 'schSetting||name')
      .append('join', 'schSetting.schSettingLine.line')
      .append(
        's',
        JSON.stringify({
          'schSetting.schSettingLine.line.statusId': { $eq: 1 },
        }),
      );

    return this.http.patch<BaseOneResponseInterface<ScenarioInterface>>(
      `${this.api}${this.routes.scenarios}/${id}`,
      {
        ...scenario,
      },
      {
        params,
      },
    );
  }

  public bulkEditScenarios(scenarios: BulkEditScenarioRequestInterface[]): Observable<BulkResponseDataInterface> {
    return this.http.patch<BulkResponseDataInterface>(`${this.api}${this.routes.bulkEdit}`, {
      scenarios,
    });
  }

  public deleteScenario(id: number): Observable<BaseCrudResponse> {
    return this.http.delete<BaseCrudResponse>(`${this.api}${this.routes.scenarios}/${id}`);
  }

  public deleteScenarios(scenarioIds: number[]): Observable<BulkResponseDataInterface> {
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
      body: {
        scenarios: scenarioIds,
      },
    };
    return this.http.delete<BulkResponseDataInterface>(`${this.api}${this.routes.bulkDelete}`, httpOptions);
  }

  public getUserHttpParams(userId: number, searchText: string = null, siteId?: number): HttpParams {
    const orFilter: { [key: string]: unknown[] } = { $or: [] };
    let queryParams: HttpParams = new HttpParams().set('fields', 'id,fullName').set('limit', 20);

    if (searchText === null && userId !== null) {
      orFilter['$or'].push({ id: { $eq: userId } });
    }

    if (searchText !== null) {
      orFilter['$or'].push({ fullName: { $cont: searchText } });
    }

    queryParams = queryParams.set(
      's',
      JSON.stringify({
        $and: [
          orFilter,
          {
            isActive: {
              $eq: ActiveStatuses.ACTIVE,
            },
          },
        ],
      }),
    );

    if (!_.isNil(siteId)) {
      queryParams = queryParams.set('siteId', siteId);
    }

    return queryParams;
  }
}
