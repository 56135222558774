<ul class="d-flex justify-content-between" [class.has-errors]="!isValid">
  <li *ngFor="let _ of [].constructor(labels.length); let i = index">
    <label
      class="align-items-center custom-checkbox d-flex justify-content-center position-relative rounded-circle user-select-none"
    >
      {{ labels[i | shiftWeekday : firstDay] }}
      <input
        #checkbox
        (change)="onChange()"
        [checked]="value.includes(i | shiftWeekday : firstDay)"
        class="position-absolute"
        [disabled]="disabled"
        type="checkbox"
        [value]="i"
      />
    </label>
  </li>
</ul>
<p class="mb-0 text-muted">
  <small>{{ value | selectedDaysSummary : firstDay }}</small>
</p>
<ng-template class="error-message-required" [ngIf]="errorText">
  <mat-hint [class.error]="errorText">
    <em class="sub-text-icon fas" [class.fa-exclamation-circle]="errorText"></em>
    <ng-template [ngIf]="errorText">{{ errorText }}</ng-template>
  </mat-hint>
</ng-template>
