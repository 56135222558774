import { ICheckInTimelineState } from './check-in-timeline.model';
import * as Actions from './check-in-timeline.actions';

const initialState: ICheckInTimelineState = {
  checkInTimelineDataLoading: false,
  checkInTimelineDataLoaded: false,
  checkInTimelineData: [],
};

export function checkInTimeLineReducer(
  state: ICheckInTimelineState = initialState,
  action: Actions.CheckInTimelineActions,
): ICheckInTimelineState {
  switch (action.type) {
    case Actions.CHECK_IN_TIMELINE_DATA_LOADING:
      return {
        ...state,
        checkInTimelineDataLoading: true,
        checkInTimelineDataLoaded: false,
        checkInTimelineData: [],
      };
    case Actions.CHECK_IN_TIMELINE_DATA_LOADED:
      return {
        ...state,
        checkInTimelineDataLoading: false,
        checkInTimelineDataLoaded: true,
        checkInTimelineData: action.data,
      };

    case Actions.FETCH_ERROR:
      return {
        ...initialState,
      };

    default:
      return state;
  }
}
