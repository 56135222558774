<mat-progress-bar mode="indeterminate" [color]="'primary'" [hidden]="!isLoading$"></mat-progress-bar>
<div class="alert-pause" [ngClass]="isLoading$ ? 'cursor-progress' : ''">
  <ng-template [ngIf]="autoPilotIsActive && !showDurationButtonGroup">
    <span>{{ 'modal.header.alerts.pause' | translate }}</span>
    <span class="float-right">
      <em class="far fa-pause-circle btn-color" (click)="showDurationButtons()"></em>
    </span>
  </ng-template>
  <ng-template [ngIf]="autoPilotIsActive && showDurationButtonGroup">
    <div class="row">
      <div class="align-self-center pl-3 txt-max-width">
        <span>{{ 'main.alerts.snooze.pause' | translate }}</span>
      </div>
      <div class="text-right m-auto">
        <div class="btn-group" role="group" aria-label="Snooze Duration">
          <button
            *ngFor="let option of snoozeOptions"
            type="button"
            class="btn btn-outline-secondary btn-pause-color"
            (click)="updateSnoozeUntil('snooze', option.duration)"
          >
            {{ option.text }}
          </button>
          <button type="button"
                  class="btn btn-outline-secondary btn-pause-color"
                  (click)="pauseModal(pause_modal)">
            <i class="fas fa-infinity pl-1"></i>
          </button>
        </div>
      </div>
    </div>
  </ng-template>
  <ng-template [ngIf]="!autoPilotIsActive">
    <div [ngClass]="pausedDurationMessage !== null ? 'paused-duration' : ''">
      <span class="float-right">
        <em class="far fa-play-circle btn-color" (click)="updateSnoozeUntil('stop')"></em>
      </span>
      <span>
        <ng-container *ngIf="pausedDurationMessage === null; else pausedDuration">
          {{ 'main.alerts.snooze.paused' | translate }}
        </ng-container>
        <ng-template #pausedDuration>
          {{ pausedDurationMessage }}
        </ng-template>
      </span>
    </div>
  </ng-template>
</div>

<ng-template #pause_modal let-modal>
  <div class="modal-header scw-modal-header">
    <h3 class="modal-title scw-header">
      {{ 'modal.header.alerts.pause' | translate }}
    </h3>
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss()">
      <span aria-hidden="true">
        <em class="fas fa-times"></em>
      </span>
    </button>
  </div>
  <div class="modal-body">
    <div class="row">
      <div class="col-12">
        <h6 class="scw-header">
          <em class="fas fa-exclamation-circle"></em>&nbsp;
          {{ 'modal.alerts.pauseInfo.label' | translate }}
        </h6>
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <div class="buttons-right">
      <scw-mat-button type="standard" (onClick)="modal.dismiss()">
        {{ 'general.cancel' | translate }}
      </scw-mat-button>
      <scw-mat-button type="cta" (onClick)="updateSnoozeUntil('snooze', -1)">
        {{ 'general.alerts.pause' | translate }}
      </scw-mat-button>
    </div>
  </div>
</ng-template>
