<scw-mat-small-button
  class="centered"
  popoverClass="tooltip-button-popover-class"
  [ngbPopover]="selectedTooltipTemplate"
  [disabled]="list.length === 0"
  [block]="block"
  [container]="container"
  (onClick)="onShowButtonClick()"
  #pop="ngbPopover"
>
  {{ buttonLabel ?? ('tags.show.button' | translate) }} <span *ngIf="showLength">({{ list.length }})</span>
</scw-mat-small-button>

<ng-template #colorTaggedList>
  <color-tagged-list [list]="list" [topLabel]="tooltipTopLabel"></color-tagged-list>
</ng-template>

<ng-template #simpleList>
  <scw-simple-list [list]="list" [topLabel]="tooltipTopLabel"></scw-simple-list>
</ng-template>
