import { Inject, Injectable } from '@angular/core';
import { FilterCardOptionInterface } from '../../../../../shared/component/filter/filter.class';
import { DatatableHeaderInterface } from '../../../../../shared/component/datatable/datatable.model';
import { TranslateService } from '@ngx-translate/core';
import { EColumnWidth } from '../../../../../shared/service/datatable/datatable.model';
import { Observable } from 'rxjs';
import {
  BaseOneResponseInterface,
  BulkResponseDataInterface,
  GetManyResponseInterface,
} from '../../../../../shared/model/interface/crud-response-interface.model';
import { IProductVersion, IProductVersionForm, IProductVersionRequestPayload } from './product-versions.model';
import { HttpClient, HttpParams } from '@angular/common/http';
import { GenericCrudRequestConstructionParameters } from '../../../../../shared/model/interface/generic-api-request.model';
import { FormHelperService } from '../../../../../shared/service/form/form.helper.service';
import { DecimalHelper } from '../../../../../shared/helper/decimal/decimal-helper';

@Injectable({
  providedIn: 'root',
})
export class ProductVersionsService {
  public readonly tableQuery: GenericCrudRequestConstructionParameters = {
    page: 1,
    perPage: 10,
    sort: [
      {
        column: 'id',
        type: 'descending',
      },
    ],
    join: ['product||productId,description', 'productBom||name', 'productResource||name'],
    search: {
      searchText: '',
      searchedFields: ['name'],
    },
  };

  private readonly routes: { versions: string; productVersionsBulkDelete: string } = {
    versions: `${this.baseUrl}/product-versions`,
    productVersionsBulkDelete: `${this.baseUrl}/product-versions/bulk/delete`,
  };

  constructor(@Inject('API_BASE_URL') private readonly baseUrl: string, private readonly http: HttpClient) {}

  public getTableHeaders(translate: TranslateService): DatatableHeaderInterface[] {
    return [
      {
        value: null,
        name: '',
        sortable: false,
        width: EColumnWidth.checkbox,
      },
      {
        value: 'productId',
        name: translate.instant('products.listView.headers.productId'),
        sortable: true,
      },
      {
        value: 'productDescription',
        name: translate.instant('lineView.tableHeaders.productDescription'),
        sortable: true,
      },
      {
        value: 'name',
        name: translate.instant('settings.products.productStructure.version.versionName'),
        sortable: true,
      },
      {
        value: 'bomName',
        name: translate.instant('settings.products.productStructure.version.bom'),
        sortable: true,
      },
      {
        value: 'resourceName',
        name: translate.instant('settings.products.productStructure.version.resource'),
        sortable: true,
      },
      {
        value: 'defaultName',
        name: translate.instant('settings.products.productStructure.version.default'),
        sortable: true,
      },
      {
        value: 'status',
        name: translate.instant('general.dataTable.header.status'),
        sortable: true,
      },
    ];
  }

  public getFilterOptions(translate: TranslateService): FilterCardOptionInterface {
    return {
      rows: [[]],
    };
  }

  public getBaseForm(
    translate: TranslateService,
    formHelperService: FormHelperService,
    decimalHelper: DecimalHelper,
  ): IProductVersionForm {
    return {
      form: {
        name: null,
        billOfMaterial: null,
        resource: null,
        default: null,
        active: null,
      },
      rules: {
        name: [formHelperService.getRequiredFormRule(), formHelperService.getMaxLengthFormRule(255)],
        billOfMaterial: [formHelperService.getRequiredFormRule()],
        resource: [formHelperService.getRequiredFormRule()],
        default: [formHelperService.getRequiredFormRule()],
        active: [formHelperService.getRequiredFormRule()],
      },
    };
  }

  public getVersionsData(params: HttpParams): Observable<GetManyResponseInterface<IProductVersion>> {
    return this.http.get<GetManyResponseInterface<IProductVersion>>(this.routes.versions, {
      params,
    });
  }

  public addProductVersion(
    payload: IProductVersionRequestPayload,
  ): Observable<BaseOneResponseInterface<IProductVersion>> {
    return this.http.post<BaseOneResponseInterface<IProductVersion>>(this.routes.versions, payload);
  }

  public editProductVersion(
    productVersionId: number,
    payload: IProductVersionRequestPayload,
  ): Observable<BaseOneResponseInterface<IProductVersion>> {
    return this.http.patch<BaseOneResponseInterface<IProductVersion>>(
      `${this.routes.versions}/${productVersionId}`,
      payload,
    );
  }

  public deleteProductVersion(productVersionId: number): Observable<BaseOneResponseInterface<IProductVersion>> {
    return this.http.delete<BaseOneResponseInterface<IProductVersion>>(`${this.routes.versions}/${productVersionId}`);
  }

  public deleteProductVersions(productVersionsIdList: number[]): Observable<BulkResponseDataInterface> {
    return this.http.delete<BulkResponseDataInterface>(this.routes.productVersionsBulkDelete, {
      body: { productVersions: productVersionsIdList },
    });
  }
}
