import { IAdvancedFilterOutput } from '../../shared/component/filter/advanced-filter/advanced-filter.model';
import { LineCRUDInterface, SiteCRUDInterface } from '../../shared/component/filter/filter.class';
import { GetManyResponseInterface } from '../../shared/model/interface/crud-response-interface.model';
import { IEquipmentAssignment, EquipmentInterface } from '../settings/equipment-assignment/equipment-assignment.model';
import { ScwMatFormInputInterface } from '../../shared/component/scw-mat-ui/scw-mat-form/scw-mat-form.model';
import { SensorManufacturerEnum, SensorTypeEnum, ECardReaderType } from '../../../constants';
import { LineStationGetOneCRUDDataInterface } from '../line-station/line-station.model';
import { ScwMatDatepickerReturnInterface } from '../../shared/component/scw-mat-ui/scw-mat-datepicker/scw-mat-datepicker.model';
import * as moment from 'moment';
import { IRulesOfForm } from '../../shared/model/interface/generic.model';
import { DropdownOptionInterface } from '../../shared/component/scw-mat-ui/scw-mat-select/scw-mat-select.model';
import { GenericCrudRequestConstructionParameters } from 'src/app/shared/model/interface/generic-api-request.model';

export interface SensorStateInterface {
  sensorsLoading: boolean;
  sensorsLoaded: boolean;
}

export interface SensorCRUDCreateRequestInterface {
  microstopLowerThreshold?: number;
  microstopUpperThreshold?: number;
  cardReaderType?: number | null;
  siteId: number;
  lineId: number;
  lineStationId?: number | null;
  sensorType: SensorTypeEnum;
  sensorId: string;
  sensorName: string | null;
  sensorManufacturer: SensorManufacturerEnum;
  sensorLowerThreshold: number;
  sensorUpperThreshold: number;
  sensorLowerThresholdDecimalScale?: string;
  sensorUpperThresholdDecimalScale?: string;
  equipmentId: number | null;
  startDate: string;
  endDate: string;
  parentSensorId: SensorCRUDCreateRequestInterface;
  cardReaderDisplayType: string;
  sensorCountUpperLimit: string | null;
  setSensorCountValue: string | null;
}

export type SensorCRUDUpdateRequestInterface = Partial<SensorCRUDCreateRequestInterface> & {
  updateChildren: boolean;
  changeParentType: boolean;
};

export type SensorBulkUpdateRequestInterface = Partial<SensorCRUDCreateRequestInterface> & { id: number };
export type SensorBulkUpdateDataInterface = SensorBulkUpdateRequestInterface & {
  startDate?: { startDate: moment.Moment };
  endDate?: { startDate: moment.Moment };
};

export interface SensorCRUDInterface extends SensorCRUDCreateRequestInterface {
  id: number;
  site?: SiteCRUDInterface;
  line?: LineCRUDInterface;
  lineStation?: LineStationGetOneCRUDDataInterface;
  equipment?: EquipmentInterface;
  parentSensor?: SensorCRUDInterface;
  childSensors: SensorCRUDInterface[];
}

export interface SensorManagementPageDataInterface extends SensorCRUDCreateRequestInterface {
  id: number;
  sensorTypeText: string;
  cardReaderTypeText: string;
  cardReaderDisplayTypeText: string;
  site: SitesDropdownOption;
  line: LineDropdownOption;
  lineStation?: DropdownOptionInterface;
  equipment?: DropdownOptionInterface;
  allowsBulkOperations: boolean;
  parentSensor?: SensorCRUDInterface;
  childSensors: SensorCRUDInterface[];
}

export interface SensorFilterSelectionInterface {
  advancedFilter: IAdvancedFilterOutput;
  site: -1 | number[];
  line: -1 | number[];
  isOnlyActiveSensors: boolean;
}

export interface SensorFormDropdownData {
  sites: GetManyResponseInterface<SitesDropdownOption>;
  lines: GetManyResponseInterface<LineDropdownOption>;
  hexboxDataOutputs: GetManyResponseInterface<IHexboxDataOutput>;
}

export interface SensorTypeDropdownOption {
  id: SensorTypeEnum;
  name: SensorTypeEnum;
}

export interface ICardReaderTypeDropdownOption {
  id: ECardReaderType;
  name: ECardReaderType;
}

export interface SitesDropdownOption extends DropdownOptionInterface {
  decimalScaleLimit: number;
}

export interface SensorFormInterface {
  id?: ScwMatFormInputInterface<number>;
  microstopLowerThreshold: ScwMatFormInputInterface<number>;
  microstopUpperThreshold: ScwMatFormInputInterface<number>;
  siteId: ScwMatFormInputInterface<DropdownOptionInterface[]>;
  lineId: ScwMatFormInputInterface<DropdownOptionInterface[]>;
  lineStationId: ScwMatFormInputInterface<DropdownOptionInterface[]>;
  sensorType: ScwMatFormInputInterface<SensorTypeDropdownOption[]>;
  cardReaderType: ScwMatFormInputInterface<ICardReaderTypeDropdownOption[]>;
  sensorId: ScwMatFormInputInterface<string>;
  sensorName: ScwMatFormInputInterface<string>;
  macAddress: ScwMatFormInputInterface<string>;
  hexboxDataOutput: ScwMatFormInputInterface<DropdownOptionInterface[]>;
  sensorManufacturer: ScwMatFormInputInterface<DropdownOptionInterface<SensorManufacturerEnum>[]>;
  sensorLowerThreshold: ScwMatFormInputInterface<number>;
  sensorUpperThreshold: ScwMatFormInputInterface<number>;
  equipmentId: ScwMatFormInputInterface<DropdownOptionInterface[]>;
  startDate: ScwMatFormInputInterface<ScwMatDatepickerReturnInterface>;
  endDate: ScwMatFormInputInterface<ScwMatDatepickerReturnInterface>;
  parentSensorId: ScwMatFormInputInterface<DropdownOptionInterface[]>;
  cardReaderDisplayType: ScwMatFormInputInterface<DropdownOptionInterface[]>;
  sensorCountUpperLimit: ScwMatFormInputInterface<string>;
  setSensorCountValue: ScwMatFormInputInterface<string>;
}

export interface LineDropdownOption extends DropdownOptionInterface {
  siteId: number;
  station: LineStationGetOneCRUDDataInterface[];
  equipmentAssignment: IEquipmentAssignment[];
  title: string;
}

export interface SensorManagementInputFormParameters {
  form: SensorFormInterface;
  rules: IRulesOfForm<SensorFormInterface>;
  areAnyOfTheBulkEditFormSelectBoxesSelected: string;
  isBulk: boolean;
  dropdownOptions: {
    lineId: LineDropdownOption[];
    lineStationId: DropdownOptionInterface[];
    equipmentId: DropdownOptionInterface[];
    sensorType: DropdownOptionInterface[];
    cardReaderType: DropdownOptionInterface[];
    sensorManufacturer: DropdownOptionInterface<SensorManufacturerEnum>[];
    parentSensorId: DropdownOptionInterface[];
    cardReaderDisplayType: DropdownOptionInterface[];
  };
}

export interface CounterSensorDropdownOptionInterface {
  id: number;
  name: string;
  endDate: string;
  startDate: string;
  equipmentId: number;
  sensorName: string;
  sensorManufacturer: SensorManufacturerEnum;
}

export const cardReaderTypeTranslations = {
  [ECardReaderType.BOTH_CHECK_IN_OUT]: 'sensor.management.cardReaderType.bothCheckInOut',
  [ECardReaderType.ONLY_CHECK_IN]: 'sensor.management.cardReaderType.onlyCheckIn',
  [ECardReaderType.ONLY_CHECK_OUT]: 'sensor.management.cardReaderType.onlyCheckOut',
};

export enum ECardReaderDisplayType {
  fullName = 'fullName',
  username = 'username',
  initials = 'initials',
}

export const cardReaderDisplayTypeTranslations = {
  [ECardReaderDisplayType.fullName]: 'sensorManagement.cardReaderDisplayType.label.fullName',
  [ECardReaderDisplayType.username]: 'sensorManagement.cardReaderDisplayType.label.username',
  [ECardReaderDisplayType.initials]: 'sensorManagement.cardReaderDisplayType.label.initials',
};

export enum EReaderType {
  cardReader = 'cardReader',
  counter = 'counter',
  vibration = 'vibration',
  temperature = 'temperature',
  humidity = 'humidity',
}

export interface IHexboxDataOutput {
  id: number;
  readerType: EReaderType;
  extension: string;
}

export interface IHexboxDataOutputDropdownOption extends DropdownOptionInterface {
  readerType: EReaderType;
}

export interface ISensorExcelData {
  id: number;
  siteName: string;
  lineName: string;
  lineStationName: string;
  sensorId: string;
  sensorType: string;
  cardReaderType: string;
  cardReaderDisplayType: string;
  description: string;
  sensorLowerThreshold: string;
  sensorUpperThreshold: string;
  microstopLowerThreshold: string;
  microstopUpperThreshold: string;
  sensorCountUpperLimit: string;
  setSensorCountValue: string;
  startDate: string;
  endDate: string;
  sensorManufacturer: string;
  equipmentName: string;
}
