import { Inject, Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { ExcelHelperService } from '../../../../shared/service/excel/excel.helper.service';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { takeUntil } from 'rxjs/operators';
import { forkJoin, Observable, Subject } from 'rxjs';
import { ECellTypes, EExcelColumnWidth, EExcelSheetType } from '../../../../shared/service/excel/excel.enum';
import { excelDateFormat, excelTimeFormat } from '../../../../shared/model/enum/excel-date-format';
import { ValueType, Workbook, Worksheet } from 'exceljs';
import * as oeeAppReducer from '../../../oee.reducer';
import * as moment from 'moment-timezone';
import * as _ from 'lodash';
import * as ObjectActions from './line-path-product-configuration.actions';
import * as AppActions from '../../../app/actions';
import { SimplifiedDataInterface } from '../../tasks/tasks.model';
import { LineCRUDInterface, ProductCRUDInterface } from '../../../../shared/component/filter/filter.class';
import {
  BulkResponseDataInterface,
  GetManyResponseInterface,
} from '../../../../shared/model/interface/crud-response-interface.model';
import {
  ICreateExcel,
  ICreateExcelSheet,
  IDownloadExcelFilters,
  IExcelColumnKeys,
} from '../../../../shared/service/excel/excel.helper';
import {
  IExcelSiteDataInterface,
  ILinePath,
  ILinePathOrderDetail,
  ILinePathProductConfigurationExcel,
  ILinePathProductConfigurationExcelContent,
  ILinePathProductConfigurations,
} from './line-path-product-configuration.model';
import { CellTypes, ExcelColumnWidthEnum } from '../../../../shared/service/excel/excel-helper.service';

@Injectable({
  providedIn: 'root',
})
export class LinePathProductConfigurationExcelService {
  private readonly urls = {
    SITES_URL: `${this.baseUrl}/sites`,
    PRODUCTS_URL: `${this.baseUrl}/products`,
    LINE_PATHS_URL: `${this.baseUrl}/line-paths`,
    LINE_URL: `${this.baseUrl}/lines`,
    LINE_PATH_PRODUCT_CONFIGURATIONS_URL: `${this.baseUrl}/line-path-product-configurations`,
    BULK_SAVE_URL: `${this.baseUrl}/line-path-product-configurations/bulk/save`,
  };
  private readonly destroySubject: Subject<boolean> = new Subject<boolean>();
  private readonly excelUploadLimit: number = 10000;
  private timezone: string = 'utc';
  private dateFormat$: string;
  private timeFormat$: string;

  constructor(
    @Inject('API_BASE_URL') private readonly baseUrl: string,
    private readonly store: Store<oeeAppReducer.OeeAppState>,
    public readonly http: HttpClient,
    public readonly excelHelper: ExcelHelperService,
    private readonly translate: TranslateService,
  ) {
    this.store
      .select('user')
      .pipe(takeUntil(this.destroySubject))
      .subscribe((state) => {
        if (state.isUserLoaded) {
          this.timezone = state.timezone;

          if (state.locale !== '') {
            this.dateFormat$ = excelDateFormat[state.locale];
            this.timeFormat$ = excelTimeFormat[state.locale];
          }

          this.destroySubject.next(true);
          this.destroySubject.complete();
        }
      });
  }
  public getSite(params: HttpParams, id: number): Observable<{ id: number; name: string }> {
    return this.http.get<{ id: number; name: string }>(`${this.urls.SITES_URL}/${id}`, { params });
  }

  public getProducts(params: HttpParams): Observable<GetManyResponseInterface<ProductCRUDInterface>> {
    return this.http.get<GetManyResponseInterface<ProductCRUDInterface>>(this.urls.PRODUCTS_URL, {
      params,
    });
  }

  public getLinePaths(params: HttpParams): Observable<GetManyResponseInterface<ILinePath>> {
    return this.http.get<GetManyResponseInterface<ILinePath>>(this.urls.LINE_PATHS_URL, {
      params,
    });
  }

  public getLines(params: HttpParams): Observable<GetManyResponseInterface<LineCRUDInterface>> {
    return this.http.get<GetManyResponseInterface<LineCRUDInterface>>(this.urls.LINE_URL, {
      params,
    });
  }

  public getLinePathProductConfigurations(
    params: HttpParams,
  ): Observable<GetManyResponseInterface<ILinePathProductConfigurations>> {
    return this.http.get<GetManyResponseInterface<ILinePathProductConfigurations>>(
      this.urls.LINE_PATH_PRODUCT_CONFIGURATIONS_URL,
      { params },
    );
  }

  private updateDataWithFloorPlan(data: ILinePath[]): ILinePath[] {
    return data.map((linePath: ILinePath) => ({
      ...linePath,
      name: `${linePath.floorPlan.name}-${linePath.name}`,
    }));
  }

  private createLineIdToTitlesMap(linePaths: ILinePath[]): Map<number, string> {
    const map = new Map<number, string>();

    linePaths.forEach((linePath: ILinePath) => {
      linePath.linePathOrderDetails.forEach((linePathOrderDetail: ILinePathOrderDetail) => {
        const existingTitle = map.get(linePathOrderDetail.lineId);

        if (existingTitle && !existingTitle.includes(linePath.name)) {
          const newTitle = `${existingTitle}, ${linePath.name}`;
          map.set(linePathOrderDetail.lineId, newTitle);
        } else if (!existingTitle) {
          map.set(linePathOrderDetail.lineId, linePath.name);
        }
      });
    });

    return map;
  }

  private getFilteredLinesWithLinePathOrderDetails(
    lines: LineCRUDInterface[],
    lineIdToTitlesMap: Map<number, string>,
  ): LineCRUDInterface[] {
    return lines.reduce((acc: LineCRUDInterface[], line: LineCRUDInterface) => {
      const title: string = lineIdToTitlesMap.get(line.id);
      if (title) {
        acc.push({ ...line, title: `${line.title} ( ${title} )` });
      }
      return acc;
    }, [] as LineCRUDInterface[]);
  }

  public async downloadExcel(
    withData: boolean,
    filters: IDownloadExcelFilters,
    withErrorColumn: boolean = false,
    data?: any[],
  ): Promise<void> {
    const httpParams: HttpParams = new HttpParams().set('limit', String(this.excelUploadLimit));
    const siteParams: HttpParams = httpParams.set('fields', 'id,name');
    const productParams: HttpParams = httpParams.set('s', JSON.stringify({ siteId: { $eq: filters.siteId } }));
    const linePathsParams: HttpParams = httpParams
      .set('s', JSON.stringify({ siteId: { $eq: filters.siteId } }))
      .append('join', 'floorPlan')
      .append('join', 'linePathOrderDetails');
    const lineParams: HttpParams = httpParams.set('s', JSON.stringify({ siteId: { $eq: filters.siteId } }));

    const observables: any[] = [
      this.getSite(siteParams, filters.siteId),
      this.getProducts(productParams),
      this.getLinePaths(linePathsParams),
      this.getLines(lineParams),
    ];

    if (withData) {
      const linePathProductConfigurationParams: HttpParams = new HttpParams()
        .set('limit', filters.limit)
        .set('page', filters.selectedDownloadOffset === null ? 1 : filters.selectedDownloadOffset)
        .set('s', JSON.stringify({ siteId: { $eq: filters.siteId } }));

      observables.push(this.getLinePathProductConfigurations(linePathProductConfigurationParams));
    }

    forkJoin(observables).subscribe((responseList) => {
      const site: IExcelSiteDataInterface = _.get(responseList, '0.data', []);
      const products: ProductCRUDInterface[] = _.get(responseList, '1.data', []);
      const linePath: ILinePath[] = _.get(responseList, '2.data', []);
      const lines: LineCRUDInterface[] = _.get(responseList, '3.data', []);
      const linePathProductConfiguration: ILinePathProductConfigurations[] = _.get(responseList, '4.data', []);
      const formattedLinePath: ILinePath[] = this.updateDataWithFloorPlan(linePath);
      const lineIdToTitlesMap: Map<number, string> = this.createLineIdToTitlesMap(formattedLinePath);
      const filteredLines: LineCRUDInterface[] = this.getFilteredLinesWithLinePathOrderDetails(
        lines,
        lineIdToTitlesMap,
      );
      const formattedProducts = products.map((product: ProductCRUDInterface) => {
        return {
          ...product,
          excelLabel: `${product.productId} - ${product.description}`,
        };
      });

      const sheetTitle: string = this.translate.instant('excel.items.linePathProductConfiguration');
      const excelName: string = `${sheetTitle} ${moment().tz(this.timezone).format(this.dateFormat$)}`;
      const content: ILinePathProductConfigurationExcelContent = {
        site,
        products: formattedProducts,
        linePaths: formattedLinePath,
        lines: filteredLines,
      };
      const excelOptions: ICreateExcel = this.getExcelColumns(content, withErrorColumn);

      if (withData) {
        excelOptions.data = (!data ? linePathProductConfiguration : data).map(
          (linePath: ILinePathProductConfigurations) => {
            return this.getExcelFormattedData(linePath, content);
          },
        );
      }

      const worksheets: ICreateExcelSheet[] = [
        {
          withData,
          sheetTitle,
          sheetType: EExcelSheetType.TABLE,
          params: excelOptions,
          isDisabledColumnsFirstLine: true,
          addDateTimeFormula: true,
          excelRowFormatLimit: filters.limit + 1,
        },
      ];

      this.excelHelper
        .createExcel(
          excelName,
          { withData, name: 'linePathProductConfigurations', siteId: filters?.siteId },
          worksheets,
          this.timezone,
          this.dateFormat$,
          this.timeFormat$,
          false,
        )
        .then(
          () => {
            this.store.dispatch(new ObjectActions.DownloadLinePathProductConfigurationExcelCompleted());
            this.store.dispatch(new AppActions.HideLoader());
          },
          () => {
            this.store.dispatch(new ObjectActions.FetchError({}));
            this.store.dispatch(new AppActions.HideLoader());
          },
        );
    });
  }

  private getExcelColumns(content: ILinePathProductConfigurationExcelContent, withErrorColumn: boolean): ICreateExcel {
    const excelColumns: ICreateExcel = {
      columns: [
        {
          header: 'id',
          key: 'id',
          width: ExcelColumnWidthEnum.DEFAULT,
          type: ValueType.Number,
          style: { numFmt: '@' },
          dataValidation: {
            type: CellTypes.CUSTOM,
          },
        },
        {
          header: this.translate.instant('general.excel.column.siteName'),
          key: 'siteId',
          width: EExcelColumnWidth.DEFAULT,
          type: ValueType.String,
          dropdownOptions: {
            data: [content.site],
            prop: 'site.name',
            dataProperty: 'site.name',
            dataId: 'site.id',
          },
          dataValidation: {
            type: ECellTypes.LIST,
          },
          isRequired: true,
        },
        {
          header: this.translate.instant('general.excel.column.product'),
          key: 'productId',
          width: EExcelColumnWidth.DEFAULT,
          type: ValueType.String,
          dropdownOptions: {
            data: content.products,
            prop: 'products.id',
            dataProperty: 'products.excelLabel',
            dataId: 'products.id',
          },
          dataValidation: {
            type: ECellTypes.LIST,
            allowBlank: false,
            formulae: [],
            showErrorMessage: true,
            errorStyle: 'error',
            showInputMessage: true,
          },
          isRequired: true,
        },
        {
          header: this.translate.instant('general.excel.column.path'),
          key: 'linePathId',
          width: EExcelColumnWidth.DEFAULT,
          type: ValueType.String,
          dropdownOptions: {
            data: content.linePaths,
            prop: 'linePaths.name',
            dataProperty: 'linePaths.name',
            dataId: 'linePaths.id',
          },
          dataValidation: {
            type: ECellTypes.LIST,
            allowBlank: false,
            formulae: [],
            showErrorMessage: true,
            errorStyle: 'error',
            showInputMessage: true,
          },
          isRequired: true,
        },
        {
          header: this.translate.instant('general.excel.column.line'),
          key: 'lineId',
          width: EExcelColumnWidth.DEFAULT,
          type: ValueType.String,
          dropdownOptions: {
            data: content.lines,
            prop: 'lines.title',
            dataProperty: 'lines.title',
            dataId: 'lines.id',
          },
          dataValidation: {
            type: ECellTypes.LIST,
            allowBlank: false,
            formulae: [],
            showErrorMessage: true,
            errorStyle: 'error',
            showInputMessage: true,
          },
        },
        {
          header: this.translate.instant('general.excel.column.productPathLineSpeed'),
          key: 'speed',
          width: EExcelColumnWidth.DEFAULT,
          type: ValueType.String,
          dataValidation: {
            type: ECellTypes.CUSTOM,
            allowBlank: false,
            showErrorMessage: true,
            formulae: [],
            errorStyle: 'Error',
            showInputMessage: true,
          },
        },
      ],
    };

    this.excelHelper.prepareExcelColumns(excelColumns.columns, withErrorColumn);

    return excelColumns;
  }

  public async getLinePathProductConfigurationFromExcel(file: File): Promise<any | null> {
    const workbook: Workbook = await ExcelHelperService.getExcelWorkBookFromFile(file);
    const linePathProductConfigurationDataSheet: Worksheet = workbook.getWorksheet(
      this.translate.instant('excel.items.linePathProductConfiguration'),
    );
    const siteIdDataSheet: Worksheet = workbook.getWorksheet('siteIdDataSheet');
    const dataSheetsToBeValidated: Worksheet[] = [
      linePathProductConfigurationDataSheet,
      siteIdDataSheet,
      workbook.getWorksheet('productIdDataSheet'),
      workbook.getWorksheet('linePathIdDataSheet'),
      workbook.getWorksheet('lineIdDataSheet'),
    ];

    if (!dataSheetsToBeValidated.every((dataSheet: Worksheet) => dataSheet)) {
      return null;
    }

    const sites: SimplifiedDataInterface[] = this.excelHelper.getExcelRowsFromWorkSheet<SimplifiedDataInterface>(
      siteIdDataSheet,
      {
        id: {
          key: 'id',
          type: ValueType.String,
          dataValidationType: ECellTypes.CUSTOM,
        },
        name: {
          key: 'name',
          type: ValueType.String,
          dataValidationType: ECellTypes.CUSTOM,
        },
      },
    );

    if (!sites.length) {
      return null;
    }

    const { columns }: ICreateExcel = this.getExcelColumns(
      {
        site: null,
        products: [],
        linePaths: [],
        lines: [],
      },
      false,
    );

    const columnKeys: IExcelColumnKeys = ExcelHelperService.getSheetColumnKeys(columns);
    const excelLinePathProductConfiguration: ILinePathProductConfigurations[] =
      this.excelHelper.getExcelRowsFromWorkSheet<ILinePathProductConfigurations>(
        linePathProductConfigurationDataSheet,
        columnKeys,
        {
          dateFormat: this.dateFormat$,
          timeFormat: this.timeFormat$,
          timezone: this.timezone,
        },
      );

    return {
      linePathConfigurations: excelLinePathProductConfiguration,
      siteData: sites,
    };
  }

  public uploadExcel(
    linePathProductConfigurationExcel: ILinePathProductConfigurationExcel,
  ): Observable<BulkResponseDataInterface> {
    return this.http.post<BulkResponseDataInterface>(this.urls.BULK_SAVE_URL, {
      linePathConfigurations: linePathProductConfigurationExcel.linePathConfigurations,
    });
  }

  private getExcelFormattedData(
    linePathProductConfiguration: ILinePathProductConfigurations,
    content: ILinePathProductConfigurationExcelContent,
  ): ILinePathProductConfigurations {
    return {
      ...linePathProductConfiguration,
      site: linePathProductConfiguration.siteId === null ? null : content.site,
      products: _.find(content.products, { id: linePathProductConfiguration.productId }) ?? [],
      linePaths: _.find(content.linePaths, { id: linePathProductConfiguration.linePathId }) ?? [],
      lines: _.find(content.lines, { id: linePathProductConfiguration.lineId }) ?? [],
    };
  }
}
