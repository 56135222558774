import { Pipe, PipeTransform } from '@angular/core';
import * as _ from 'lodash';
import { DecimalHelper } from '../helper/decimal/decimal-helper';
import { DECIMAL_DEFAULT_SCALE_LIMIT } from '../../../constants';
import { Store } from '@ngrx/store';
import * as oeeAppReducer from '../../store/oee.reducer';
import { take } from 'rxjs/operators';
import { User } from '../../store/user/model';

@Pipe({
  standalone: true,
  name: 'formatDecimalToUserScale',
})
export class FormatDecimalToUserScalePipe implements PipeTransform {
  public userDecimalScale: number = DECIMAL_DEFAULT_SCALE_LIMIT;

  constructor(public decimalHelper: DecimalHelper, public store: Store<oeeAppReducer.OeeAppState>) {
    this.store
      .select('user')
      .pipe(take(1))
      .subscribe((user: User) => {
        this.userDecimalScale = user.decimalScaleLimit;
      });
  }

  transform(value: number | string): string | number {
    if (_.isNil(value)) {
      return value;
    }

    const valueAsString: string = typeof value === 'number' ? value.toString() : value;

    return this.decimalHelper.toFixedValue(valueAsString, this.userDecimalScale);
  }
}
