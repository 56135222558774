<mat-hint *ngIf="hint || error" class="mb-3" [class.error]="error">
  <span
    aria-hidden
    class="fas sub-text-icon"
    [class.fa-exclamation-circle]="error"
    [class.fa-info-circle]="!error"
  ></span>
  {{ error ?? hint }}
</mat-hint>
<ng-content></ng-content>
