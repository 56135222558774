import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  TemplateRef,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { take } from 'rxjs/operators';
import { Line } from '../../../../store/line/model';
import { ActionsSubject, Store } from '@ngrx/store';
import { OeeAppState } from '../../../../store/oee.reducer';
import { InputLimit } from '../../../model/enum/input-limit';
import { PhaseFieldInterface } from './work-order-phase-comment-modal.model';
import { TranslateService } from '@ngx-translate/core';
import { HomeStateInterface, PhaseCommentInterface } from '../../../../store/home/home.model';
import * as HomeActions from '../../../../store/home/home.actions';
import * as FileUploadActions from '../../../../store/file-upload/file-upload.actions';
import { ShiftSummaryCommentObjectPropertyTypes } from '../../../model/enum/shift-summary-comment-object-property-types';
import { Subscription } from 'rxjs';
import { User } from '../../../../store/user/model';
import { DecimalHelper } from '../../../helper/decimal/decimal-helper';
import { OnDestroyDecorator } from '../../../decorator/on-destroy-decorator';
import { ImagePreviewModalComponent } from '../../file-upload/image-preview-modal/image-preview-modal.component';
import { ofType } from '@ngrx/effects';
import { EFileUploadSource } from '../../file-upload/file-upload.model';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { ScwModalSize } from '../../scw-mat-ui/scw-mat-modal/scw-mat-modal.model';
import * as _ from 'lodash';
import { HelperService } from '../../../service/helper.service';
import { EFileType, IFile, IGetFileResponse } from '../../../../store/file-upload/file-upload.model';
import { CameraModalComponent } from '../../file-upload/camera-modal/camera-modal.component';
import { FileHelperService } from '../../../helper/file-helper.service';

@OnDestroyDecorator
@Component({
  selector: 'work-order-phase-comment-modal',
  templateUrl: './work-order-phase-comment-modal.component.html',
  styleUrls: ['./work-order-phase-comment-modal.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class WorkOrderPhaseCommentModalComponent implements OnInit, OnDestroy {
  @ViewChild('image_preview_modal', { static: true }) imagePreviewModalComponent: ImagePreviewModalComponent;

  @Input() phase: PhaseFieldInterface;
  @Input() phaseType: string;
  @Input() phaseComment: PhaseCommentInterface | null = null;

  @Output() cancelButtonClicked: EventEmitter<void> = new EventEmitter<void>();
  @Output() isSubmitFromDeleteEmitter: EventEmitter<boolean> = new EventEmitter<boolean>();

  private readonly successTitleKey: string = this.translate.instant('general.success');
  private readonly successMessageKey: string = this.translate.instant('general.changesSavedSuccessfully');
  private readonly storeSubscriptions: Subscription[] = [];
  protected readonly EFileType = EFileType;
  public InputLimit = InputLimit;
  public phaseFieldClass: string = '';
  private workOrderTableId$: number;
  public siteId$: number;
  public form: {
    workOrder$: string | null;
    phase: string | null;
    comment: string | null;
  } = {
    workOrder$: null,
    phase: null,
    comment: null,
  };
  public phaseFieldText: string = null;
  private homeStoreSubscriptions: Subscription;
  public comment: string | null;
  public isNewPhoto: boolean = false;
  public fileDeleteConfirmationModalRef: NgbModalRef;
  public files: IFile[] = [];
  public deletedFile: IFile = null;
  public isPreviewModalClicked: boolean = false;
  public isUpdatePhaseCommentProcessCompleted: boolean = false;

  constructor(
    private readonly translate: TranslateService,
    private readonly store: Store<OeeAppState>,
    private readonly decimalHelper: DecimalHelper,
    private readonly actions: ActionsSubject,
    private readonly ngbModal: NgbModal,
    private readonly helperService: HelperService,
    private readonly fileHelperService: FileHelperService,
  ) {}

  private updatePhaseText(): void {
    this.form.phase = this.phaseType;

    if (this.phaseComment !== null) {
      this.form.comment = this.phaseComment.message;
    }

    this.homeStoreSubscriptions = this.store
      .select('homeStore')
      .pipe(take(1))
      .subscribe((state: HomeStateInterface) => {
        if (state.phaseDurations === null) {
          this.phase.target = null;
          return;
        }

        switch (this.form.phase) {
          case 'preRun':
            this.phase.target = state.phaseDurations.preRunTarget
              ? this.decimalHelper.toFixedValue(state.phaseDurations.preRunTarget)
              : null;
            break;
          case 'run':
            this.phase.target = state.phaseDurations.runTarget
              ? this.decimalHelper.toFixedValue(state.phaseDurations.runTarget)
              : null;
            break;
          case 'postRun':
            this.phase.target = state.phaseDurations.postRunTarget
              ? this.decimalHelper.toFixedValue(state.phaseDurations.postRunTarget)
              : null;
            break;
        }
      });

    if (
      this.phase.actual !== null &&
      this.phase.target !== null &&
      parseFloat(this.phase.actual) > parseFloat(this.phase.target)
    ) {
      this.phaseFieldClass = 'input-danger';
    }

    const unit = this.translate.instant('general.shortHour');
    const actual = this.phase.actual ? `${this.phase.actual}${unit}` : '--';
    const target = this.phase.target ? `${this.phase.target}${unit}` : '--';

    this.phaseFieldText = this.translate.instant('homeScreenMetrics.phaseComment.phaseText', {
      actual,
      target,
      phase: this.phase.phaseText,
    });
  }

  public submit(isValid: boolean): void {
    if (!isValid) {
      return;
    }

    const newlyAddedFiles: IFile[] = this.files.filter((item: IFile) => _.isNil(item.folderId));

    if (newlyAddedFiles.length) {
      this.store.dispatch(
        new FileUploadActions.BulkUploadFileLoading({
          entityName: EFileUploadSource.COMMENTS,
          siteId: this.siteId$,
          files: newlyAddedFiles,
          folderId: this.files.find((item: IFile) => !_.isNil(item.folderId))?.folderId ?? undefined,
        }),
      );
      return;
    }

    this.operationDecider();
  }

  private operationDecider(): void {
    const commentIdNull: boolean = _.isNil(this.phaseComment?.id);
    const commentNull: boolean = this.form.comment === null;
    this.comment = !commentNull ? this.form.comment.trim() : this.form.comment;

    if (commentIdNull && !commentNull && this.comment.length > 0) {
      this.store.dispatch(
        new HomeActions.CreatePhaseComment(
          this.siteId$,
          this.workOrderTableId$,
          this.comment,
          WorkOrderPhaseCommentModalComponent.formatPhaseType(this.phaseType),
          this.phaseComment?.folderId,
        ),
      );
    } else if (!commentIdNull && !commentNull && this.comment.length > 0) {
      this.isUpdatePhaseCommentProcessCompleted = true;

      this.store.dispatch(
        new HomeActions.UpdatePhaseComment(this.phaseComment.id, this.comment, this.phaseComment.folderId),
      );
    } else if (!commentIdNull) {
      this.store.dispatch(new HomeActions.DeletePhaseComment(this.phaseComment.id));
    }
  }

  private static formatPhaseType(phaseType: string): ShiftSummaryCommentObjectPropertyTypes {
    let formattedPhaseType: ShiftSummaryCommentObjectPropertyTypes;
    switch (phaseType) {
      case 'preRun':
        formattedPhaseType = ShiftSummaryCommentObjectPropertyTypes.PRE;
        break;
      case 'run':
        formattedPhaseType = ShiftSummaryCommentObjectPropertyTypes.RUN;
        break;
      case 'postRun':
        formattedPhaseType = ShiftSummaryCommentObjectPropertyTypes.POST;
        break;
      default:
        break;
    }

    return formattedPhaseType;
  }

  public showDeleteItemModal(content: TemplateRef<any>, file: IFile): void {
    this.deletedFile = this.files.find((item: IFile): boolean => item === file);
    this.fileDeleteConfirmationModalRef = this.ngbModal.open(content, {
      windowClass: ScwModalSize.small,
    });
  }

  public deleteImage(): void {
    if (!_.isNil(this.deletedFile) && this.deletedFile.folderId) {
      this.imagePreviewModalComponent.onDelete(this.deletedFile);
    } else {
      _.remove(this.files, this.deletedFile);
    }

    this.fileDeleteConfirmationModalRef?.close();
  }

  public openPreviewModal(file: IFile, fileNumber: number): void {
    this.isPreviewModalClicked = true;
    this.imagePreviewModalComponent.openImagePreviewModal(
      file.folderId ? file.folderId : undefined,
      this.files,
      fileNumber,
    );
  }

  public ngOnInit(): void {
    this.storeSubscriptions.push(
      CameraModalComponent.filesSubject.subscribe((item: IFile[]) => {
        const isFileNamesValid: boolean = this.fileHelperService.isSameFileExist(
          this.files.filter((file: IFile) => file.type === EFileType.PDF),
          item,
        );

        if (isFileNamesValid) {
          this.files = _.concat(this.files, item);
          this.isNewPhoto = true;
        }
      }),
      this.store
        .select('line')
        .pipe(take(1))
        .subscribe((state: Line) => {
          this.form.workOrder$ = state.workOrderId;
          this.workOrderTableId$ = state.wOTableId;
        }),
      this.store
        .select('user')
        .pipe(take(1))
        .subscribe((state: User) => {
          this.siteId$ = state.siteId;
        }),
      this.actions
        .pipe(ofType(FileUploadActions.GET_FILES_BY_FOLDER_ID_LOADED))
        .subscribe((data: FileUploadActions.GetFilesByFolderIdLoaded) => {
          if (!this.isPreviewModalClicked) {
            this.files = data.response.map((item: IGetFileResponse) => {
              const itemSizeAsMb: number = item.size / (1024 * 1024);

              return {
                ...item,
                original: item.filePath.includes(EFileType.PDF)
                  ? `data:application/pdf;base64,${item.base64Data}`
                  : `data:image/jpeg;base64,${item.base64Data}`,
                type: item.filePath.includes(EFileType.PDF) ? EFileType.PDF : EFileType.IMAGE,
                size: this.decimalHelper.decimalToNumberFormatter(
                  this.decimalHelper.toFixedValue(
                    itemSizeAsMb.toString(),
                    this.decimalHelper.isLessThan(itemSizeAsMb.toString(), '0.01') ? 3 : 2,
                  ),
                ),
              };
            });
          }

          this.isPreviewModalClicked = false;
        }),
      this.actions
        .pipe(ofType(FileUploadActions.DELETE_FILE_LOADED))
        .subscribe((payload: FileUploadActions.DeleteFileLoaded) => {
          if (payload.payload.success) {
            const existingFileCount: number = this.files.filter((file: IFile) => !_.isNil(file.folderId)).length;

            this.helperService.showToastMessage(true, this.successTitleKey, this.successMessageKey);

            if (existingFileCount === 0) {
              this.isSubmitFromDeleteEmitter.emit(true);
              this.isUpdatePhaseCommentProcessCompleted = true;

              this.store.dispatch(new HomeActions.UpdatePhaseComment(this.phaseComment.id, this.comment, null));
            } else {
              this.store.dispatch(
                new FileUploadActions.DeleteManyFileByFilePathLoading({ filePath: this.deletedFile.filePath }),
              );
            }

            this.deletedFile = null;
            this.isPreviewModalClicked = false;
          }
        }),
      this.actions
        .pipe(ofType(FileUploadActions.BULK_UPLOAD_FILE_LOADED))
        .subscribe((payload: FileUploadActions.BulkUploadFileLoaded) => {
          if (payload.response.data[0].folderId) {
            this.phaseComment = { ...this.phaseComment, folderId: payload.response.data[0].folderId };
            this.operationDecider();
          }
        }),
      this.actions
        .pipe(ofType(FileUploadActions.GET_FILE_LOADED))
        .subscribe((payload: FileUploadActions.GetFileLoaded) => {
          const blobURL: string = FileHelperService.byteStringToBlobUrl(payload.response.base64Data, 'application/pdf');
          window.open(blobURL, '_blank');
        }),
      this.actions.pipe(ofType(HomeActions.HomeActionTypes.UpdatePhaseCommentCompleted)).subscribe(() => {
        this.isUpdatePhaseCommentProcessCompleted = false;
      }),
    );

    if (this.phaseComment?.folderId) {
      this.store.dispatch(
        new FileUploadActions.GetFilesByFolderIdLoading({
          folderId: this.phaseComment.folderId,
          isThumbnail: 1,
          entityName: EFileUploadSource.COMMENTS,
          siteId: this.siteId$,
        }),
      );
    }

    this.updatePhaseText();
  }

  public deleteButtonClickedFromPreview(deletedFile: IFile): void {
    this.deletedFile = deletedFile;
    this.isPreviewModalClicked = false;

    _.remove(this.files, (file: IFile): boolean =>
      file.id
        ? file.id === deletedFile.id
        : file.fileOriginalName
        ? file.fileOriginalName === deletedFile.fileOriginalName
        : file.original === deletedFile.original,
    );
  }

  ngOnDestroy(): void {
    this.storeSubscriptions.forEach((subscription: Subscription) => {
      subscription?.unsubscribe();
    });
    if (this.homeStoreSubscriptions) {
      this.homeStoreSubscriptions.unsubscribe();
    }
  }

  public downloadPdf(file: IFile): void {
    if (file.id) {
      this.store.dispatch(
        new FileUploadActions.GetFileLoading({
          folderId: this.phaseComment.folderId,
          isThumbnail: 0,
          entityName: EFileUploadSource.COMMENTS,
          siteId: this.siteId$,
          fileId: file.id,
        }),
      );
      return;
    }

    const base64Part: string = file.original.split(',')[1];
    const blobURL: string = FileHelperService.byteStringToBlobUrl(base64Part, 'application/pdf');
    window.open(blobURL, '_blank');
  }
}
