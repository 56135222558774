<div class="modal-body">
  <app-spinner *ngIf="searchProgress"></app-spinner>
  <div class="row">
    <div class="col-sm-8">
      <h5 *ngIf="workOrderHeader.woNumber" class="scw-header work-order-info-text">
        {{
          workOrderHeader.woNumber +
            ' - ' +
            workOrderHeader.productName +
            ' ' +
            workOrderHeader.productDescription +
            ' - ' +
            ('main.workOrder.qtyOrdered' | translate) +
            ': ' +
            (workOrderHeader.quantityOrdered | formatDecimalToSiteScale)
        }}
        <scw-mat-button
          *ngIf="!isOpenedOnStationHome"type="standard"
          size="xs"
          [iconButton]="true"
          [className]="[workOrderHeader.id === null || workOrderHeader.id === 0 ? 'd-none' : 'd-inline-block mr-2']"
          (onClick)="openEditWorkOrderModal(work_order_edit_form, workOrderHeader.id)"
          data-automation-id="edit-selected-work-order-btn"
        >
          <em class="fas fa-pen"></em>
        </scw-mat-button>
        <scw-mat-button
          class="text-left"
          type="standard"
          size="xs"
          [iconButton]="true"
          [className]="[hasLineRunningWorkOrder && withoutWorkOrder && isOpenedFromHome ? 'd-inline-block mr-2' : 'd-none']"
          (onClick)="confirmationModal(doYouWantToRemoveWorkOrder)"
        >
          <em class="fas fa-minus-circle"></em>
        </scw-mat-button>
      </h5>
    </div>

    <div class="col-sm-4 text-right">
      <scw-mat-button-group
        size="xs"
        [buttons]="workOrderFilterButtonGroup"
        [className]="['d-inline-block']"
        [(inputModel)]="workOrderFilter"
        (inputModelChange)="lineFilterChange($event)"
      ></scw-mat-button-group>
    </div>
  </div>
  <div class="work-order-search-and-add-container mt-3">
    <div class="work-order-search-box">
      <scw-mat-search
        behavior="delayed"
        placeholder="{{ 'main.workOrder.searchWorkOrder.placeholder' | translate }}"
        [minLength]="0"
        [(inputModel)]="workOrderSearchText"
        (onSearch)="searchRequest($event)"
        data-automation-id="search-work-order-field"
      ></scw-mat-search>
    </div>
    <div class="work-order-add-button" *ngIf="!isOpenedOnStationHome">
      <scw-mat-button type="standard" (onClick)="workOrderCreateModal(work_order_add)"
                      data-automation-id="add-new-work-order-btn">
        <em class="fas fa-plus"></em>
        {{ 'main.workOrder.addNewButton' | translate }}
      </scw-mat-button>
    </div>
  </div>
  <div class="row mt-3">
    <div class="col-sm-12">
      <div
        class="row d-block pt-1 lazy-loading-work-order-results"
        infiniteScroll
        [infiniteScrollDistance]="0"
        [infiniteScrollThrottle]="0"
        [scrollWindow]="false"
        (scrolled)="onScroll()"
      >
        <ng-template ngFor let-workOrder let-i="index" [ngForOf]="workOrderList">
          <div class="col-md-12 d-inline-block" *ngIf="i === 0 || workOrderList[i - 1].workOrderGroup !== workOrder.workOrderGroup">
            <h4 class="scw-header work-order-group-header">{{ 'main.workOrder.workOrderGroup.' + workOrder.workOrderGroup | translate }}</h4>
          </div>
          <div class="d-inline-block col-xs-12 col-sm-6 col-md-4 col-lg-3 col-xl-2 m-b-15">
            <scw-mat-button
              type="standard"
              size="xxl"
              [block]="true"
              [badge]="workOrder.badge"
              [className]="[(this.selectedWorkOrder$ | async).woNumber === workOrder.woNumber ? 'active button-group' : 'button-group']"
              (onClick)="changeSelectedWorkOrder(workOrder)"
            >
              <ng-template [ngIf]="workOrderCardLayout && isUsedInSelectWorkOrderCardPropertySelected" [ngIfElse]="workOrderNumberInfo">
                <ng-container *ngIf="workOrder.workOrderCardLayout.textTop">
                  <h5 class="work-order-button-text work-order-button-work-order-text">
                    {{ workOrder.workOrderCardLayout.textTop }}
                  </h5>
                </ng-container>
                <ng-container *ngIf="workOrder.workOrderCardLayout.textMiddle">
                  <h5 class="work-order-button-text work-order-button-work-order-text">
                    {{ workOrder.workOrderCardLayout.textMiddle }}
                  </h5>
                </ng-container>
                <ng-container *ngIf="workOrder.workOrderCardLayout.textBottom">
                  <h5 class="work-order-button-text work-order-button-work-order-text">
                    {{ workOrder.workOrderCardLayout.textBottom }}
                  </h5>
                </ng-container>
              </ng-template>
              <ng-template #workOrderNumberInfo>
                <h5 class="work-order-button-text work-order-button-work-order-text">{{ workOrder.woNumber }}</h5>
              </ng-template>

              <ng-template
                [ngIf]="
                workOrder.scheduledRunDate !== null &&
                workOrder.scheduledEndDate !== null &&
                workOrder.scheduledLine !== null
              "
                [ngIfElse]="notScheduled"
              >
                <div class="work-order-button-text work-order-button-info-text m-t-10">
                  {{ workOrder.scheduledRunDate | momentDate: dateTimeFormat$ }}
                </div>
                <div class="work-order-button-text work-order-button-info-text">
                  {{ workOrder.scheduledEndDate | momentDate: dateTimeFormat$ }}
                </div>
              </ng-template>
              <ng-template #notScheduled>
              <span class="work-order-button-text work-order-button-info-text">
                <ng-template [ngIf]="workOrder.workOrderButton === 'btn-primary'" [ngIfElse]="noScheduledDate">
                  {{ 'main.workOrder.notScheduled' | translate }}
                </ng-template>
                <ng-template #noScheduledDate>
                  {{ 'main.workOrder.noScheduledDate' | translate }}
                </ng-template>
              </span>
              </ng-template>
            </scw-mat-button>
          </div>
        </ng-template>
      </div>
      <div *ngIf="lazyLoadingProgress" class="lazy-loading">
        <svg id="loader2" viewBox="0 0 100 100">
          <circle id="circle-loader2" class="stroke-white loader-primary" cx="50" cy="50" r="45"></circle>
        </svg>
      </div>
    </div>
  </div>
</div>
<div class="modal-footer">
  <div class="buttons-left">
    <scw-mat-button type="standard" [disabled]="workOrderStatusLoading$" (onClick)="this.closeThisModal.emit()"
                    data-automation-id="select-work-order-modal-close-btn">
      {{ 'general.close' | translate }}
    </scw-mat-button>
    <scw-mat-button type="danger" *ngIf="showUnselectButton" [disabled]="workOrderStatusLoading$" (onClick)="unselectButtonClicked()">
      {{ 'main.workOrder.unselectWorkOrder' | translate }}
    </scw-mat-button>
  </div>
  <div class="buttons-right">
    <scw-mat-button
      *ngIf="(isLinePathUtilized$ | async) && isOpenedFromHome && (isSelectPathButtonDisabled$ | async)"
      [disabled]="true"
    >
      {{ 'general.selectPath' | translate }}
    </scw-mat-button>
    <scw-mat-button
      [disabled]="workOrderStatusLoading$ || (this.selectedWorkOrder$ | async).id === null"
      [type]="
        (isLinePathUtilized$ | async) && isOpenedFromHome && (isSelectPathButtonDisabled$ | async) === false
          ? 'standard'
          : 'cta'
      "
      (onClick)="workOrderLineConfirm()"
      data-automation-id="select-work-order-btn"
    >
      {{ 'general.select' | translate }}
    </scw-mat-button>
    <scw-mat-button
      *ngIf="(isLinePathUtilized$ | async) && isOpenedFromHome && (isSelectPathButtonDisabled$ | async) === false"
      (onClick)="openLinePathSelectionModal(selectLinePathModal)"
      data-automation-id="select-path-work-order-btn"
    >
      {{ 'general.selectPath' | translate }}
    </scw-mat-button>
  </div>
</div>

<ng-template #work_order_add let-modal>
  <div class="modal-header scw-modal-header">
    <h3 class="modal-title scw-header">{{ modalTitle }}</h3>
    <button type="button" class="close" onfocus="blur()" aria-label="Close" (click)="addModalClosed()">
        <span aria-hidden="true">
          <em class="fas fa-times"></em>
        </span>
    </button>
  </div>
  <div class="modal-body">
    <div class="row">
      <div class="col-sm-12">
        <aw-wizard #arc_wizard navBarLocation="">
          <aw-wizard-step
            (stepEnter)="enterSecondStep('workOrderInformation')"
            stepTitle="{{ 'main.workOrder.workOrderInformation' | translate }}"
            [navigationSymbol]="{ symbol: '1' }"
          >
            <div class="centered-content">
              <app-work-order-information
                [workOrderNo]="workOrderInformationForm.workOrderNo"
                [lineId]="configuration.lineId"
                [actionType]="actionTypes.CREATE"
                [selectWorkOrderModalConfigurations]="configuration"
                [withoutWorkOrder]="withoutWorkOrder"
                (setWorkOrderInformation)="getWorkOrderInformation($event)"
                (goToNextStepWizard)="goToNextStepWizard(arc_wizard)"
                (closeModal)="addModalClosed()"
                [isOpenedFromHome] = "isOpenedFromHome"
              ></app-work-order-information>
            </div>
          </aw-wizard-step>
          <aw-wizard-step
            (stepEnter)="enterSecondStep('productInformation')"
            stepTitle="{{ 'main.workOrder.productInformation' | translate }}"
            [navigationSymbol]="{ symbol: '2' }"
          >
            <div class="centered-content">
              <div class="m-t-10 m-b-10">
                <h5 class="scw-header">
                  {{ workOrderInformationForm.productName }} - {{ workOrderInformationForm.productDescription }}
                </h5>
              </div>
              <div class="row fixed-modals-long">
                <div class="col-md-6">
                  <div class="card custom-card">
                    <div class="card-block text-center">
                      <h4 class="scw-header">{{ 'main.workOrder.billOfMaterial' | translate }}</h4>
                      <p class="text-muted">
                        {{ 'main.workOrder.baseQuantity' | translate }}
                        : {{ productInformation.billOfMaterial.quantity }} {{ productInformation.productUnitValue }}
                      </p>
                      <div class="table-responsive">
                        <table class="table table-hover table-borderless">
                          <thead>
                            <tr>
                              <th>{{ 'main.workOrder.productMaterial' | translate }}</th>
                              <th>{{ 'main.workOrder.quantity' | translate }}</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr *ngFor="let row of productInformation.billOfMaterial.items">
                              <td>{{ row.componentProductName }}</td>
                              <td>{{ row.componentProductQuantity }} {{ row.itemUnitValue }}</td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="card custom-card">
                    <div class="card-block text-center">
                      <h4 class="scw-header">{{ 'main.workOrder.resourceSet' | translate }}</h4>
                      <p class="text-muted">
                        {{ 'main.workOrder.baseQuantity' | translate }}
                        : {{ productInformation.resources.quantity }} {{ productInformation.productUnitValue }}
                      </p>
                      <div class="table-responsive">
                        <table class="table table-hover table-borderless">
                          <thead>
                            <tr>
                              <th>{{ 'main.workOrder.aMountResource' | translate }}</th>
                              <th>{{ 'main.workOrder.hours' | translate }}</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr *ngFor="let row of productInformation.resources.items">
                              <td>{{ row.lineTitle ? row.lineTitle : row.groupName }}</td>
                              <td>{{ row.hours }} {{ 'main.workOrder.hr' | translate }}</td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="select-wo-modal-footer">
                <div class="col-sm-12 m-0 p-0">
                  <scw-mat-button (onClick)="goToNextStepWizard(arc_wizard)" class="float-right">
                    {{ 'general.next' | translate }}
                  </scw-mat-button>
                </div>
              </div>
            </div>
          </aw-wizard-step>
          <aw-wizard-step
            (stepEnter)="enterSecondStep('productPerformance')"
            stepTitle="{{ 'main.workOrder.productPerformance' | translate }}"
            [navigationSymbol]="{ symbol: '3' }"
          >
            <div class="centered-content">
              <div class="m-t-10 m-b-10">
                <h5 class="scw-header">
                  {{ workOrderInformationForm.productName }} - {{ workOrderInformationForm.productDescription }}
                </h5>
              </div>
              <div class="row fixed-modals-long">
                <div class="col-sm-12">
                  <div class="card custom-card">
                    <div class="card-block text-center">
                      <h4 class="scw-header">{{ 'main.workOrder.last5WorkOrders' | translate }}</h4>
                      <div class="table-responsive">
                        <table class="table table-xs table-hover table-borderless m-b-0">
                          <thead>
                          <tr>
                            <th>{{ 'main.workOrder.workOrderNumber' | translate }}</th>
                            <th>{{ 'filterCard.lastLine.dropdownPlaceHolder' | translate }}</th>
                            <th>{{ 'main.workOrder.workOrderSize' | translate }}</th>
                            <th>{{ 'main.workOrder.oee' | translate }}</th>
                          </tr>
                          </thead>
                          <tbody>
                          <tr *ngFor="let workOrder of oeePerformanceLastFiveWorkOrders">
                            <td>{{ workOrder.name }}</td>
                            <td>{{ workOrder.lineName }}</td>
                            <td>{{ workOrder.size }}</td>
                            <td>{{ workOrder.overallOee }}%</td>
                          </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-xl-6 col-lg-12">
                  <div class="card custom-card">
                    <div class="card-block text-center">
                      <h4 class="scw-header margin-bottom-4">{{ 'main.workOrder.topFailureDownTimes' | translate }}</h4>
                      <p class="text-muted margin-bottom-4">{{ 'main.workOrder.last12Months' | translate }}</p>
                      <div echarts [options]="topFailureDowntimeOptions" class="demo-chart"></div>
                    </div>
                  </div>
                </div>
                <div class="col-xl-6 col-lg-12">
                  <div class="card custom-card">
                    <div class="card-block text-center">
                      <h4 class="scw-header margin-bottom-4">{{ 'main.workOrder.oeePerformanceTrend' | translate }}</h4>
                      <p class="text-muted margin-bottom-4">{{ 'main.workOrder.last12Months' | translate }}</p>
                      <div echarts [options]="oeePerformanceTrendOptions" class="demo-chart"></div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="select-wo-modal-footer">
                <div class="col-sm-12 m-0 p-0">
                  <scw-mat-button
                    [disabled]="createWorkOrderInProgress && !createWorkOrderInProgressCompleted$"
                    (click)="backButtonClicked()"
                    type="standard"
                    awPreviousStep
                  >
                    {{ 'general.back' | translate }}
                  </scw-mat-button>
                  <scw-mat-button class="float-right" (onClick)="modal.dismiss()">
                    {{ 'general.done' | translate }}
                  </scw-mat-button>
                </div>
              </div>
            </div>
          </aw-wizard-step>
        </aw-wizard>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #work_order_edit_form let-modal>
  <div class="modal-header scw-modal-header">
    <h3 class="modal-title scw-header">
      {{ 'main.workOrder.editWorkOrder' | translate }}
    </h3>
    <button type="button" class="close" onfocus="blur()" aria-label="Close" (click)="addModalClosed()">
      <span aria-hidden="true">
        <em class="fas fa-times"></em>
      </span>
    </button>
  </div>
  <div class="modal-body">
    <app-work-order-information
      [lineId]="configuration.lineId"
      [withoutWorkOrder]="withoutWorkOrder"
      [workOrderNo]="workOrderInformationForm.workOrderNo"
      [workOrderDetail]="workOrderInformationForm"
      [actionType]="actionTypes.EDIT"
      [selectWorkOrderModalConfigurations]="configuration"
      [editWorkOrderInProgress]="editWorkOrderInProgress"
      (setWorkOrderInformation)="getWorkOrderInformation($event)"
      (closeModal)="modal.dismiss()"
      [isOpenedFromHome] = "isOpenedFromHome"
    ></app-work-order-information>
  </div>
</ng-template>

<ng-template #work_order_running_another_line let-modal>
  <div class="modal-header scw-modal-header">
    <h3 class="modal-title scw-header">
      {{ (this.selectedWorkOrder$ | async).woNumber }}
    </h3>
    <button type="button" class="close" onfocus="blur()" aria-label="Close" (click)="backToSelection()">
        <span aria-hidden="true">
          <em class="fas fa-times"></em>
        </span>
    </button>
  </div>
  <div class="modal-body">
    <div class="row">
      <div class="col-md-1">
        <em class="fas fa-exclamation-circle"></em>
      </div>
      <div class="col-md-11">
        <h6 class="scw-header">
          <ng-container *ngIf="lineInfoThatSelectedWorkOrderData.isWorkOrderTakeoverBySplitActive; else preventTakeover">
            {{ 'main.workOrder.onMultipleLinesAndTakeoverActive' | translate: { lines: lineInfoThatSelectedWorkOrderData?.runLineName, predictedWorkOrderNumber: lineInfoThatSelectedWorkOrderData?.predictedWorkOrderNumber } }}
          </ng-container>
          <ng-template #preventTakeover>
            {{ 'main.workOrder.onMultipleLinesAndTakeoverInactive' | translate: { lines: lineInfoThatSelectedWorkOrderData?.runLineName } }}
          </ng-template>
        </h6>
      </div>
      <ng-container
        *ngIf="lineInfoThatSelectedWorkOrderData.isWorkOrderTakeoverBySplitActive && showTargetSpeedSelectionCheckboxForSplit">
        <div class="col-12">
          <scw-mat-checkbox
            class="wo-target-speed-padding"
            [(inputModel)]="copyTargetSpeedCheckbox"
            (inputModelChange)="workOrderTargetButtonClicked($event)"
          ><strong>{{ 'workOrderTargetSpeed.split.info' | translate }}</strong></scw-mat-checkbox>
        </div>
      </ng-container>
    </div>
    <div *ngIf="lineInfoThatSelectedWorkOrderData.isWorkOrderTakeoverBySplitActive && isScheduledWorkOrderExist && !isScheduled && !isRecommended" id="error-message-divider">
      <hr />
    </div>
    <div *ngIf="lineInfoThatSelectedWorkOrderData.isWorkOrderTakeoverBySplitActive && isScheduledWorkOrderExist && !isScheduled && !isRecommended" class="row">
      <div class="col-md-1">
        <em class="fas fa-exclamation-circle"></em>
      </div>
      <div class="col-md-11">
        <h6 class="scw-header">
          {{ 'main.workOrder.onScheduled' | translate }}
        </h6>
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <div class="buttons-left">
      <scw-mat-button *ngIf="lineInfoThatSelectedWorkOrderData.isWorkOrderTakeoverBySplitActive" type="standard" (onClick)="onClickSelectAnyway()">{{
        'main.workOrder.selectAnyway' | translate
      }}</scw-mat-button>
    </div>
    <div class="buttons-right">
      <scw-mat-button (onClick)="backToSelection()">{{ 'main.workOrder.backToSelection' | translate }}</scw-mat-button>
    </div>
  </div>
</ng-template>

<ng-template #scheduled_work_order_exist let-modal>
  <div class="modal-header scw-modal-header">
    <h3 class="modal-title scw-header">
      {{ (this.selectedWorkOrder$ | async).woNumber }}
    </h3>
    <button type="button" class="close" onfocus="blur()" aria-label="Close" (click)="backToSelection('scheduledAnotherLineModal')">
      <span aria-hidden="true">
        <em class="fas fa-times"></em>
      </span>
    </button>
  </div>
  <div class="modal-body">
    <div class="row">
      <div class="col-md-1">
        <em class="fas fa-exclamation-circle"></em>
      </div>
      <div class="col-md-11">
        <h6 class="scw-header">
          {{ 'main.workOrder.onScheduled' | translate }}
        </h6>
      </div>
    </div>
    <div class="row">
      <div class="col-md-1"></div>
      <div class="col-md-11">
        <h6 class="scw-header">
          {{ 'main.workOrder.wantToSelectWorkOrder' | translate }}
        </h6>
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <div class="buttons-left">
      <scw-mat-button type="standard" (onClick)="modal.dismiss()">{{
        'main.workOrder.selectAnyway' | translate
      }}</scw-mat-button>
    </div>
    <div class="buttons-right">
      <scw-mat-button (onClick)="backToSelection('scheduledAnotherLineModal')">{{
        'main.workOrder.backToSelection' | translate
      }}</scw-mat-button>
    </div>
  </div>
</ng-template>

<ng-template #work_order_scheduled_another_line let-modal>
  <div class="modal-header scw-modal-header">
    <h3 class="modal-title scw-header">
      {{ (this.selectedWorkOrder$ | async).woNumber }}
    </h3>
    <button type="button" class="close" onfocus="blur()" aria-label="Close" (click)="modal.dismiss()">
        <span aria-hidden="true">
          <em class="fas fa-times"></em>
        </span>
    </button>
  </div>
  <div class="modal-body">
    <h6 class="scw-header">
      <em class="fas fa-exclamation-circle"></em>
      {{ 'main.workOrder.questions.differentLineText' | translate: { differentLine: scheduledLineDetail } }}
    </h6>
  </div>
  <div class="modal-footer">
    <div class="buttons-left">
      <scw-mat-button type="standard" (onClick)="scheduledAnotherLineSelectAnyway()">{{ 'main.workOrder.selectAnyway' | translate }}</scw-mat-button>
    </div>
    <div class="buttons-right">
      <scw-mat-button (onClick)="modal.dismiss()">{{ 'main.workOrder.backToSelection' | translate }}</scw-mat-button>
    </div>
  </div>
</ng-template>

<ng-template #already_completed_work_order_warning let-modal>
  <div class="modal-header scw-modal-header">
    <h3 class="modal-title scw-header">
      {{ checkWorkOrderConfirmModalDetail.woNumber }}
    </h3>
    <button type="button" class="close" onfocus="blur()" aria-label="Close" (click)="modal.dismiss()">
        <span aria-hidden="true">
          <em class="fas fa-times"></em>
        </span>
    </button>
  </div>
  <div class="modal-body">
    <h6 class="scw-header">
      <em class="fas fa-exclamation-circle"></em>
      {{ alreadyCompletedWorkOrderWarningModalText }}
    </h6>
  </div>
  <div class="modal-footer">
    <div class="buttons-left">
      <scw-mat-button type="standard" (onClick)="modal.dismiss()">{{ 'general.cancel' | translate }}</scw-mat-button>
    </div>
    <div class="buttons-right">
      <scw-mat-button (onClick)="alreadyCompletedWorkOrderRerun()">{{ 'general.apply' | translate }}</scw-mat-button>
    </div>
  </div>
</ng-template>

<ng-template #doYouWantToRemoveWorkOrder let-modal>
  <div class="modal-header scw-modal-header">
    <h3 class="modal-title scw-header">
      {{ 'doYouWantToRemoveWorkOrder.title' | translate }}
    </h3>
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss()">
      <span aria-hidden="true">
        <em class="fas fa-times"></em>
      </span>
    </button>
  </div>
  <div class="modal-body">
    <div class="row">
      <div class="col-12">
        <h6 class="scw-header">
          <em class="fas fa-exclamation-circle"></em>&nbsp;
          {{ 'doYouWantToRemoveWorkOrder.description' | translate : {workOrderName: workOrderHeader.woNumber } }}
        </h6>
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <div class="buttons-right">
      <scw-mat-button type="standard" (onClick)="modal.dismiss()">
        {{ 'general.cancel' | translate }}
      </scw-mat-button>
      <scw-mat-button type= "danger" (onClick)="removeWorkOrder()">
        {{ 'general.remove' | translate }}
      </scw-mat-button>
    </div>
  </div>
</ng-template>
<ng-template #change_activity_for_multi_line let-modal>
  <app-multi-line-change-activity
    [modal]="modal"
    [linePathWoSplitResults]="linePathWoSplitResults"
    [linePathId]="selectedLinePath?.id"
    [siteId]="configuration.siteId"
    [lineId]="configuration.lineId"
    (finishWithoutOperation)="closeThisModal.emit()"
  ></app-multi-line-change-activity>
</ng-template>

<ng-template #selectLinePathModal let-modal>
  <client-side-button-list
    [modal]="modal"
    [sections]="linePathOptions"
    [labelField]="'name'"
    [searchPlaceholder]="'main.workOrder.searchWorkOrder.placeholder' | translate"
    [modalHeader]="'general.selectPath' | translate"
    [staticSelectedItemText]="
      workOrderHeader.woNumber + ' - ' + workOrderHeader.productName + ' ' + workOrderHeader.productDescription
    "
    [leftFooterContent]="selectLinePathModalLeftFooterContent"
    (onConfirm)="openSelectLinePathAssignmentMethodModal($event, selectLinePathAssignmentMethodModal)"
    (onCancel)="selectLinePathModalRef?.close()"
  ></client-side-button-list>
</ng-template>

<ng-template #selectLinePathModalLeftFooterContent>
  <div *ngIf="selectedLinePath">
    <p class="m-0">{{ 'main.workOrder.selectedPath' | translate }}:</p>
    <p class="m-0 f-w-600">{{ selectedLinePath.name }}</p>
  </div>
</ng-template>

<ng-template #selectLinePathAssignmentMethodModal let-modal>
  <scw-radio-select-modal
    [modal]="modal"
    [options]="(selectLinePathAssignmentMethodModalData$ | async)?.options"
    [modalHeader]="'main.workOrder.selectLinePathAssignmentMethod.header' | translate"
    [message]="'main.workOrder.selectLinePathAssignmentMethod.message' | translate"
    [confirmButtonConfiguration]="(selectLinePathAssignmentMethodModalData$ | async)?.confirmButtonConfiguration"
    (onConfirm)="assignLinePathOnWorkOrder($event)"
    (onCancel)="selectLinePathAssignmentMethodModalRef?.close()"
  ></scw-radio-select-modal>
</ng-template>
