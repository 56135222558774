import * as Actions from './line-path-product-configuration.actions';
import { ILinePathProductConfigurationState } from './line-path-product-configuration.model';

export const linePathProductConfigurationState: ILinePathProductConfigurationState = {
  linePathProductConfigurationExcelDownloading: false,
  linePathProductConfigurationExcelDownloaded: false,
  linePathProductConfigurationExcelUploading: false,
  linePathProductConfigurationExcelUploaded: false,
  excelUploadResult: [],
  linePathProductConfigurationCountLoaded: false,
  linePathProductConfigurationCountLoading: false,
  linePathProductConfigurationCountForExcel: null,
};

export function linePathProductConfigurationReducer(
  state: ILinePathProductConfigurationState = linePathProductConfigurationState,
  action: Actions.LinePathProductConfigurationActions,
): ILinePathProductConfigurationState {
  switch (action.type) {
    case Actions.DOWNLOAD_LINE_PATH_PRODUCT_CONFIGURATION_EXCEL:
      return {
        ...state,
        ...{
          linePathProductConfigurationExcelDownloading: true,
          linePathProductConfigurationExcelDownloaded: false,
        },
      };
    case Actions.DOWNLOAD_LINE_PATH_PRODUCT_CONFIGURATION_EXCEL_COMPLETED:
      return {
        ...state,
        ...{
          linePathProductConfigurationExcelDownloading: false,
          linePathProductConfigurationExcelDownloaded: true,
        },
      };
    case Actions.UPLOAD_LINE_PATH_PRODUCT_CONFIGURATION_EXCEL:
      return {
        ...state,
        ...{
          linePathProductConfigurationExcelUploading: true,
          linePathProductConfigurationExcelUploaded: false,
        },
      };
    case Actions.UPLOAD_LINE_PATH_PRODUCT_CONFIGURATION_EXCEL_COMPLETED:
      return {
        ...state,
        ...{
          excelUploadResult: action.payload,
          linePathProductConfigurationExcelUploading: false,
          linePathProductConfigurationExcelUploaded: true,
        },
      };
    case Actions.LINE_PATH_PRODUCT_CONFIGURATION_COUNT_LOAD:
      return {
        ...state,
        ...{
          linePathProductConfigurationPathCountLoading: true,
          linePathProductConfigurationPathCountLoaded: false,
        },
      };
    case Actions.LINE_PATH_PRODUCT_CONFIGURATION_COUNT_LOADED:
      return {
        ...state,
        ...{
          linePathProductConfigurationCountForExcel: action.payload.total,
          linePathProductConfigurationCountLoading: false,
          linePathProductConfigurationCountLoaded: true,
        },
      };
    default:
      return state;
  }
}
