<div class="line-station-dropdown-item">
  <div class="activity-color-border" [ngClass]="activityTypeClass">
    <div class="ml-3">
      <div>
        <h6 class="mb-2 font-weight-bold d-inline">{{ data.name }}</h6>
        <div class="float-right">
          <i class="fas fa-user mr-1"></i>
          <span>{{ data.ongoingCheckIns.length }}</span>
        </div>
      </div>
      <p class="mb-0">
        {{ !isWorkOrderEmpty ? data.ongoingStationActivityHistory.workOrder?.woNumber : '&nbsp;' }}
      </p>
      <p class="mb-0">
        {{
          data.ongoingStationActivityHistory
            ? activityName +
              (data.ongoingStationActivityHistory.task
                ? ' - ' + data.ongoingStationActivityHistory.task.title
                : '&nbsp;')
            : ('general.noActivity' | translate)
        }}
      </p>
      <p class="mb-0">{{ timer === '--' ? '&nbsp;' : timer }}</p>
    </div>
  </div>
</div>
