import { ITableHeader, PageConfigurationTypes } from '../../../../constants.model';
import { ScwMatRadioGroupOption } from '../scw-mat-ui/scw-mat-radio-group/scw-mat-radio-group.model';
import { IScwMatKpiCard } from '../scw-mat-ui/scw-mat-kpi-card/scw-mat-kpi-card.model';

export interface PageConfigurationInterface {
  table: TableConfigurationInterface[];
  widget: WidgetConfigurationInterface<EWidgetType.WIDGET>[];
  chart: ChartConfigurationInterface[];
  custom: ICustomSectionConfiguration[];
}

export interface TableConfigurationInterface {
  name: string;
  selected: boolean;
  title: string;
  disabled: boolean;
  sortable?: boolean;
  isTableRow?: boolean;
  linkedTo?: string;
  opposedTo?: string[];
  type?: PageConfigurationTypes;
}

export type Content<T extends EWidgetType> = T extends EWidgetType.WIDGET ? IWidget : T extends EWidgetType.KPI_CARD ? IScwMatKpiCard : never;

export interface IWidget {
  type: EWidgetType.WIDGET;
  pageConfigurationTitle: string;
  icon?: string;
  cardColor?: string;
  cardValue?: string;
  displayPercentage?: boolean;
  componentName?: string;
  tooltipText?: string;
  widgetClass?: string;
}

export interface WidgetConfigurationInterface<T extends EWidgetType> {
  type: PageConfigurationTypes;
  name?: string;
  content: Content<T>;
  selected?: boolean;
  disabled?: boolean;
}

export interface ChartConfigurationInterface {
  name: string;
  disabled: boolean;
  title: string;
  selected: boolean;
  componentName?: string;
  type?: PageConfigurationTypes;
}

export interface IPageConfigurationGroup<T> {
  id: T;
  name: string;
  values: ITableHeader[];
  icon?: string;
  description?: string;
  isVisible?: boolean;
  isSortable?: boolean;
}

export enum SectionTypes {
  Table = 'table',
  Widget = 'widget',
  Custom = 'custom',
}

export interface ICustomSectionRow {
  name: string;
  selected: boolean;
  title: string;
  disabled: boolean;
}

export interface ICustomSectionConfiguration {
  name: string;
  bordered: boolean;
  header: string;
  headerIconClass: string;
  label?: string;
  rows: ICustomSectionRow[];
  sortable: boolean;
  hasRadioButtonBehaviour?: boolean;
  disableDefaultSort?: boolean;
  show?: boolean;
  isRadio?: true;
  radioGroup?: ScwMatRadioGroupOption[];
  defaultRadioButtonValue?: any;
}

export interface ICustomConfigurationDbEntry {
  name: string;
  value: string[];
}

export interface ICustomSectionOrderConfiguration {
  type: PageConfigurationTypes;
  index?: number;
}

export type PageConfigurationDataType =
  | TableConfigurationInterface
  | WidgetConfigurationInterface<EWidgetType.WIDGET>
  | ChartConfigurationInterface;

export enum EWidgetType {
  WIDGET = 'widget',
  KPI_CARD = 'kpiCard',
}
