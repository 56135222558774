import { Inject, Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import {
  ICommentsResponse,
  IManualCountResponse,
  IProductionCountCrudData,
  IWorkOrderManualCountComment,
  IWorkOrderManualCountDeleteMany,
} from '../../../../../view/home/work-order/work-order-manual-count/work-order-manual-count.model';
import { DatatableInterface } from '../../../datatable/datatable.model';
import { BaseOneResponseInterface } from '../../../../model/interface/crud-response-interface.model';

@Injectable()
export class WorkOrderManualCountService {
  private readonly routes = {
    BASE_URL: '/production-counts',
    BULK_DELETE: 'bulk/delete',
    COMMENT_SAVE: 'comments/bulk/save',
  };

  public tableQuery: DatatableInterface = {
    page: 1,
    pageSize: 25,
    orderBy: 'id',
    orderDesc: true,
  };

  constructor(private readonly http: HttpClient, @Inject('API_BASE_URL') private readonly baseUrl: string) {}

  getAll(params: number | string): Promise<IManualCountResponse> {
    return new Promise((resolve) => {
      this.http
        .get<BaseOneResponseInterface<IManualCountResponse>>(`${this.baseUrl}${this.routes.BASE_URL}${params}`)
        .subscribe((response: BaseOneResponseInterface<IManualCountResponse>) => {
          resolve(response.data);
        });
    });
  }

  create(woTableId: number, postData): Promise<BaseOneResponseInterface<IProductionCountCrudData>> {
    return this.http.post<BaseOneResponseInterface<IProductionCountCrudData>>(`${this.baseUrl}${this.routes.BASE_URL}`, postData).toPromise();
  }

  update(woTableId: number, id: number, postData): Promise<any> {
    return this.http.patch(`${this.baseUrl}${this.routes.BASE_URL}/${id}`, postData).toPromise();
  }

  delete(woTableId: number, id: number): Promise<any> {
    return new Promise((resolve) => {
      this.http.delete(`${this.baseUrl}${this.routes.BASE_URL}/${id}/${woTableId}`).subscribe(() => {
        resolve('success');
      });
    });
  }

  public deleteMany(deletedWorkOrderManualCounts: IWorkOrderManualCountDeleteMany): Promise<any> {
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
      body: deletedWorkOrderManualCounts,
    };
    return new Promise((resolve) => {
      this.http
        .delete(`${this.baseUrl}${this.routes.BASE_URL}/${this.routes.BULK_DELETE}`, httpOptions)
        .subscribe(() => {
          resolve('success');
        });
    });
  }

  public saveComments(comments: IWorkOrderManualCountComment[]): Promise<ICommentsResponse> {
    return this.http
      .post<ICommentsResponse>(`${this.baseUrl}${this.routes.COMMENT_SAVE}`, { comments })
      .toPromise();
  }
}
