import { Inject, Injectable } from '@angular/core';
import { FilterServiceInterface, RootCauseGroupCRUDResponseInterface } from './service.class';
import { HttpClient, HttpParams } from '@angular/common/http';
import { RootCauseGroupCRUDInterface } from '../../component/filter/filter.class';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root',
})
export class RootCauseGroupService implements FilterServiceInterface {
  constructor(
    public http: HttpClient,
    @Inject('API_BASE_URL') private readonly baseUrl: string,
    private readonly translate: TranslateService,
  ) {}

  getData(options?: HttpParams): Promise<RootCauseGroupCRUDInterface[]> {
    return new Promise((resolve, reject) => {
      this.http
        .get(`${this.baseUrl}/root-cause-groups`, { params: options })
        .subscribe((response: RootCauseGroupCRUDResponseInterface) => {
          if (response.hasOwnProperty('data')) {
            response.data = this.setChangeoverGroupVirtualFlag(response.data);
            resolve(response.data);
          } else {
            reject();
          }
        });
    });
  }

  private setChangeoverGroupVirtualFlag(data: RootCauseGroupCRUDInterface[]): RootCauseGroupCRUDInterface[] {
    return data.map((item) => {
      return {
        ...item,
        virtualIsChangeoverType:
          item.isChangeover === 1
            ? this.translate.instant('changeover.changeoverGroups.changeoverGroup')
            : this.translate.instant('changeover.changeoverGroups.other'),
      };
    });
  }
}
