import { Action } from '@ngrx/store';

import {
  BaseOneResponseInterface,
  BulkResponseDataInterface,
} from '../../../shared/model/interface/crud-response-interface.model';
import { ResponseArrayInterface } from '../../../shared/model/interface/generic-api-response.model';
import {
  IGetTreeNodes,
  IRootAddress,
  ISkippedTreeNodeResponse,
  ITreeNode
} from './tree-nodes.model';

export const TREE_NODES_DATA_LOAD = '[TREE_NODES] TREE NODE DATA LOAD';
export const TREE_NODES_DATA_LOADED = '[TREE_NODES] TREE NODE DATA LOADED';
export const ADD_TREE_NODE = '[TREE_NODES] ADD TREE NODE';
export const ADD_TREE_NODE_COMPLETED = '[TREE_NODES] ADD TREE NODE COMPLETED';
export const EDIT_TREE_NODE = '[TREE_NODES] EDIT TREE NODE';
export const EDIT_TREE_NODE_COMPLETED = '[TREE_NODES] EDIT TREE NODE COMPLETED';
export const DELETE_TREE_NODE = '[TREE_NODES] DELETE TREE NODE';
export const DELETE_TREE_NODE_COMPLETED = '[TREE_NODES] DELETE TREE NODE COMPLETED';
export const FETCH_ERROR = '[TREE_NODES] FETCH ERROR';

export class TreeNodesDataLoad implements Action {
  readonly type = TREE_NODES_DATA_LOAD;

  constructor(public params: IGetTreeNodes) {}
}

export class TreeNodesDataLoaded implements Action {
  readonly type = TREE_NODES_DATA_LOADED;

  constructor(public payload: ResponseArrayInterface<ITreeNode>) {}
}

export class AddTreeNode implements Action {
  readonly type = ADD_TREE_NODE;

  constructor(public treeNode: Omit<ITreeNode, 'id'>) {}
}

export class AddTreeNodeCompleted implements Action {
  readonly type = ADD_TREE_NODE_COMPLETED;

  constructor(public payload: BaseOneResponseInterface<ITreeNode>) {}
}

export class EditTreeNode implements Action {
  readonly type = EDIT_TREE_NODE;

  constructor(public treeNode: Omit<ITreeNode, 'id'>, public treeNodeId: number) {}
}

export class EditTreeNodeCompleted implements Action {
  readonly type = EDIT_TREE_NODE_COMPLETED;

  constructor(public payload: BaseOneResponseInterface<ITreeNode>) {}
}

export class DeleteTreeNode implements Action {
  readonly type = DELETE_TREE_NODE;

  constructor(public treeNodeId: number) {}
}

export class DeleteTreeNodeCompleted implements Action {
  readonly type = DELETE_TREE_NODE_COMPLETED;

  constructor(public payload: BulkResponseDataInterface) {}
}

export class FetchError implements Action {
  readonly type = FETCH_ERROR;
  constructor(public payload: object) {}
}

export type TreeNodesActions =
  | TreeNodesDataLoad
  | TreeNodesDataLoaded
  | EditTreeNode
  | EditTreeNodeCompleted
  | AddTreeNode
  | AddTreeNodeCompleted
  | DeleteTreeNode
  | DeleteTreeNodeCompleted
  | FetchError;
