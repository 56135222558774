import { Action } from '@ngrx/store';
import { TabRowInterface } from '../../shared/component/side-config-bar/side-config-bar.model';
import {
  BaseOneResponseInterface,
  GetManyResponseInterface,
} from '../../shared/model/interface/crud-response-interface.model';
import {
  NotificationAppendToInterface,
  NotificationGroupInterface,
  NotificationInterface, NotificationItemInterface,
  NotificationTemplateInterface,
} from './iot-device-report.model';
import { IAddNotification, IAppendToNotification } from '../../view/reports/iot-device-report/iot-device-report.model';

export const IOT_DEVICE_PORTAL_SET_TABLE_SETTINGS = '[IOT_DEVICE_PORTAL] SET TABLE SETTINGS';
export const IOT_DEVICE_REPORT_NOTIFICATION_GROUPS_DATA_LOADING =
  '[IOT_DEVICE_REPORT] NOTIFICATION_GROUPS DATA LOADING';
export const IOT_DEVICE_REPORT_NOTIFICATION_GROUPS_DATA_LOADED = '[IOT_DEVICE_REPORT] NOTIFICATION_GROUPS DATA LOADED';
export const IOT_DEVICE_REPORT_NOTIFICATION_TEMPLATES_DATA_LOADING =
  '[IOT_DEVICE_REPORT] NOTIFICATION_TEMPLATES DATA LOADING';
export const IOT_DEVICE_REPORT_NOTIFICATION_TEMPLATES_DATA_LOADED =
  '[IOT_DEVICE_REPORT] NOTIFICATION_TEMPLATES DATA LOADED';
export const IOT_DEVICE_REPORT_ADD_NOTIFICATION = '[IOT_DEVICE_REPORT] ADD NOTIFICATION';
export const IOT_DEVICE_REPORT_ADD_NOTIFICATION_COMPLETED = '[IOT_DEVICE_REPORT] ADD NOTIFICATION COMPLETED';
export const IOT_DEVICE_REPORT_APPEND_TO_NOTIFICATION = '[IOT_DEVICE_REPORT] APPEND_TO NOTIFICATION';
export const IOT_DEVICE_REPORT_APPEND_TO_NOTIFICATION_COMPLETED =
  '[IOT_DEVICE_REPORT] APPEND_TO NOTIFICATION COMPLETED';
export const IOT_DEVICE_REPORT_NOTIFICATIONS_DATA_FOR_APPEND_TO_LOADING =
  '[IOT_DEVICE_REPORT] NOTIFICATIONS_DATA FOR APPEND TO LOADING';
export const IOT_DEVICE_REPORT_NOTIFICATIONS_DATA_FOR_APPEND_TO_LOADED =
  '[IOT_DEVICE_REPORT] NOTIFICATIONS_DATA FOR APPEND TO LOADED';
export const IOT_DEVICE_REPORT_NOTIFICATIONS_DATA_FOR_VIEW_LOADING =
  '[IOT_DEVICE_REPORT] NOTIFICATIONS_DATA FOR VIEW LOADING';
export const IOT_DEVICE_REPORT_NOTIFICATIONS_DATA_FOR_VIEW_LOADED =
  '[IOT_DEVICE_REPORT] NOTIFICATIONS_DATA FOR VIEW LOADED';
export const IOT_DEVICE_REPORT_DELETE_NOTIFICATION = '[IOT_DEVICE_REPORT] DELETE NOTIFICATION';
export const IOT_DEVICE_REPORT_DELETE_NOTIFICATION_COMPLETED = '[IOT_DEVICE_REPORT] DELETE NOTIFICATION COMPLETED';
export const IOT_DEVICE_REPORT_DELETE_NOTIFICATION_ITEM = '[IOT_DEVICE_REPORT] DELETE NOTIFICATION ITEM';
export const IOT_DEVICE_REPORT_DELETE_NOTIFICATION_ITEM_COMPLETED = '[IOT_DEVICE_REPORT] DELETE NOTIFICATION ITEM COMPLETED';

export const IOT_DEVICE_REPORT_FETCH_ERROR = '[IOT_DEVICE_REPORT] IOT_DEVICE_REPORT ACTION FETCH ERROR';

export class SetTableSettings implements Action {
  readonly type = IOT_DEVICE_PORTAL_SET_TABLE_SETTINGS;

  constructor(public payload: TabRowInterface[], public setAsDefault: boolean) {}
}

export class NotificationGroupsDataLoading implements Action {
  readonly type = IOT_DEVICE_REPORT_NOTIFICATION_GROUPS_DATA_LOADING;
  constructor() {}
}

export class NotificationGroupsDataLoaded implements Action {
  readonly type = IOT_DEVICE_REPORT_NOTIFICATION_GROUPS_DATA_LOADED;
  constructor(public payload: GetManyResponseInterface<NotificationGroupInterface>) {}
}

export class NotificationTemplatesDataLoading implements Action {
  readonly type = IOT_DEVICE_REPORT_NOTIFICATION_TEMPLATES_DATA_LOADING;
  constructor() {}
}

export class NotificationTemplatesDataLoaded implements Action {
  readonly type = IOT_DEVICE_REPORT_NOTIFICATION_TEMPLATES_DATA_LOADED;
  constructor(public payload: GetManyResponseInterface<NotificationTemplateInterface>) {}
}

export class AddNotification implements Action {
  readonly type = IOT_DEVICE_REPORT_ADD_NOTIFICATION;
  constructor(public notification: Partial<IAddNotification>) {}
}

export class AddNotificationCompleted implements Action {
  readonly type = IOT_DEVICE_REPORT_ADD_NOTIFICATION_COMPLETED;
  constructor(public payload: BaseOneResponseInterface<NotificationInterface>) {}
}

export class AppendToNotification implements Action {
  readonly type = IOT_DEVICE_REPORT_APPEND_TO_NOTIFICATION;
  constructor(public notification: Partial<IAppendToNotification>) {}
}

export class AppendToNotificationCompleted implements Action {
  readonly type = IOT_DEVICE_REPORT_APPEND_TO_NOTIFICATION_COMPLETED;
  constructor(public payload: BaseOneResponseInterface<NotificationAppendToInterface>) {}
}

export class NotificationsDataForAppendToLoading implements Action {
  readonly type = IOT_DEVICE_REPORT_NOTIFICATIONS_DATA_FOR_APPEND_TO_LOADING;
  constructor() {}
}

export class NotificationsDataForAppendToLoaded implements Action {
  readonly type = IOT_DEVICE_REPORT_NOTIFICATIONS_DATA_FOR_APPEND_TO_LOADED;
  constructor(public payload: GetManyResponseInterface<NotificationInterface>) {}
}

export class NotificationsDataForViewLoading implements Action {
  readonly type = IOT_DEVICE_REPORT_NOTIFICATIONS_DATA_FOR_VIEW_LOADING;
  constructor() {}
}

export class NotificationsDataForViewLoaded implements Action {
  readonly type = IOT_DEVICE_REPORT_NOTIFICATIONS_DATA_FOR_VIEW_LOADED;
  constructor(public payload: GetManyResponseInterface<NotificationInterface>) {}
}

export class DeleteNotification implements Action {
  readonly type = IOT_DEVICE_REPORT_DELETE_NOTIFICATION;
  constructor(public notificationId: number) {}
}

export class DeleteNotificationCompleted implements Action {
  readonly type = IOT_DEVICE_REPORT_DELETE_NOTIFICATION_COMPLETED;
  constructor(public payload: BaseOneResponseInterface<NotificationInterface>) {}
}

export class DeleteNotificationItem implements Action {
  readonly type = IOT_DEVICE_REPORT_DELETE_NOTIFICATION_ITEM;
  constructor(public notificationId: number, public notificationItemId: number) {}
}

export class DeleteNotificationItemCompleted implements Action {
  readonly type = IOT_DEVICE_REPORT_DELETE_NOTIFICATION_ITEM_COMPLETED;
  constructor(public notificationId: number, public payload: BaseOneResponseInterface<NotificationItemInterface>) {}
}

export class FetchError implements Action {
  readonly type = IOT_DEVICE_REPORT_FETCH_ERROR;
  constructor(public payload: object) {}
}

export type IotDeviceReportActions =
  | SetTableSettings
  | NotificationGroupsDataLoading
  | NotificationGroupsDataLoaded
  | NotificationTemplatesDataLoading
  | NotificationTemplatesDataLoaded
  | AddNotification
  | AddNotificationCompleted
  | AppendToNotification
  | AppendToNotificationCompleted
  | NotificationsDataForAppendToLoading
  | NotificationsDataForAppendToLoaded
  | NotificationsDataForViewLoading
  | NotificationsDataForViewLoaded
  | DeleteNotification
  | DeleteNotificationCompleted
  | DeleteNotificationItem
  | DeleteNotificationItemCompleted
  | FetchError;
