import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-count-badges-metric',
  templateUrl: './count-badges-metric.component.html',
  styleUrls: ['./count-badges-metric.component.scss'],
})
export class CountBadgesMetricComponent {
  @Input() tooltipText: string = '';
  @Input() goodCount: string;
  @Input() scrapCount: string;
  @Input() showMetricInfo: boolean = false;
  @Input() goodCountInfoText: string = '';
  @Input() scrapCountInfoText: string = '';
  @Input() goodCountUnit: string = '';
  @Input() scrapCountUnit: string = '';
}
