import * as Actions from './otif-analysis.actions';
import { IOtifAnalysisState } from './otif-analysis.model';

export const capacityInitialState: IOtifAnalysisState = {
  isOtifDataLoading: false,
  isOtifDataLoaded: false,
  otifData: [],
};

export function otifAnalysisReducer(
  state: IOtifAnalysisState = capacityInitialState,
  action: Actions.OtifAnalysisActions,
): IOtifAnalysisState {
  switch (action.type) {
    case Actions.OtifAnalysisActionTypes.OtifAnalysisDataLoading:
      return {
        ...state,
        isOtifDataLoading: true,
        isOtifDataLoaded: false,
      };

    case Actions.OtifAnalysisActionTypes.OtifAnalysisDataLoaded:
      return {
        ...state,
        isOtifDataLoaded: true,
        isOtifDataLoading: false,
        otifData: action.payload.data,
      };

    case Actions.OtifAnalysisActionTypes.FetchError:
      return {
        ...state,
        isOtifDataLoaded: false,
        isOtifDataLoading: false,
      };
    default:
      return state;
  }
}
