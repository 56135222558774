import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import * as AppActions from '../../app/actions';
import * as ObjectActions from './labor-asset-view.actions';
import { of, switchMap } from 'rxjs';
import { LaborAssetViewService } from './labor-asset-view.service';
import { HttpParams } from '@angular/common/http';
import { GetManyResponseInterface } from '../../../shared/model/interface/crud-response-interface.model';
import { ILaborAssetViewData } from './labor-asset-view.model';
import { catchError, map } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { OeeAppState } from '../../oee.reducer';
import * as _ from 'lodash';

@Injectable()
export class LaborAssetViewEffects {
  constructor(
    private readonly store: Store<OeeAppState>,
    private readonly actions$: Actions,
    private readonly service: LaborAssetViewService,
  ) {}

  getLaborAssetViewData = createEffect(() =>
    this.actions$.pipe(
      ofType(ObjectActions.ELaborAssetHoursActionTypes.LABOR_ASSET_VIEW_DATA_LOADING),
      switchMap((objectData: ObjectActions.LaborAssetViewDataLoading) => {
        const httpParams: HttpParams = new HttpParams()
          .set('site', String(objectData.params.site))
          .set('lines', String(objectData.params.lines))
          .set('userIds', String(objectData.params.userIds))
          .set('userTypes', String(objectData.params.userTypes))
          .set('assetIds', String(objectData.params.assetIds))
          .set('queryType', objectData.params.timeToggle)
          .set('dashboardType', objectData.params.typeToggle);

        this.store.dispatch(new AppActions.ShowTopLoader());

        return this.service.getLaborAssetViewData(httpParams).pipe(
          map(
            (
              response: GetManyResponseInterface<ILaborAssetViewData>,
            ): GetManyResponseInterface<ILaborAssetViewData> => {
              response.data = response.data.map((data: ILaborAssetViewData): ILaborAssetViewData => {
                return {
                  ...data,
                  checkIns: _.orderBy(data.checkIns, 'calculatedCheckInTime'),
                };
              });

              return response;
            },
          ),
          switchMap((response: GetManyResponseInterface<ILaborAssetViewData>) => {
            return of(new ObjectActions.LaborAssetViewDataLoaded(response.data), new AppActions.HideTopLoader());
          }),
          catchError((err: unknown) => {
            return of(new ObjectActions.FetchError(err));
          }),
        );
      }),
      catchError((err: unknown) => {
        return of(new ObjectActions.FetchError(err));
      }),
    ),
  );
}
