import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import * as JobActions from './job.action';
import { JobService } from '../../../shared/service/filter/job.service';
import { from, generate, of, switchMap } from 'rxjs';
import { HttpParams } from '@angular/common/http';
import { generateHttpOption } from '../../../shared/helper/app-helper';
import { catchError } from 'rxjs/operators';
import { IJobCRUD } from '../../../shared/component/filter/filter.class';
import { GetManyResponseInterface } from '../../../shared/model/interface/crud-response-interface.model';

@Injectable()
export class FilterJobEffects {
  constructor(private actions$: Actions<JobActions.FilterJobsActions>, public service: JobService) {}

  loadFilterJobs$ = createEffect(() =>
    this.actions$.pipe(
      ofType(JobActions.EFilterJobsActionTypes.FilterJobsLoading),
      switchMap((objectData: JobActions.FilterJobsLoading) => {
        const params: HttpParams = generateHttpOption(objectData.options);

        return from(this.service.getData(params)).pipe(
          switchMap((response: GetManyResponseInterface<IJobCRUD>) => {
            return of(new JobActions.FilterJobsLoaded(response.data));
          }),
          catchError(() => {
            return of(new JobActions.FilterJobsLoaded([]));
          }),
        );
      }),
    ),
  );
}
