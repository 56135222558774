import { createReducer, on } from '@ngrx/store';
import { ELoadStatus } from '../../../constants.model';
import { TBoardMemberResponse, TBoardResponse, TRecommendedBoardResponse } from './action-tracker.types';
import * as ActionTrackerActions from './action-tracker.actions';

export interface IActionTrackerState {
  boardsLoadStatus: ELoadStatus;
  boards: TBoardResponse[];
  boardMembersLoadStatus: ELoadStatus;
  boardMembers: TBoardMemberResponse[];
  createItemStatus: ELoadStatus;
  createdItem: { id: number; boardId: number; url: string; key: string } | null;
  hasActionTrackerUser: boolean;
  hasActionTrackerUserLoadStatus: ELoadStatus;
  recommendedBoard: TRecommendedBoardResponse | null;
  recommendedBoardLoadStatus: ELoadStatus;
}

export const actionTrackerInitialState: IActionTrackerState = {
  boardsLoadStatus: ELoadStatus.Initial,
  boards: [],
  boardMembersLoadStatus: ELoadStatus.Initial,
  boardMembers: [],
  createItemStatus: ELoadStatus.Initial,
  createdItem: null,
  hasActionTrackerUser: false,
  hasActionTrackerUserLoadStatus: ELoadStatus.Initial,
  recommendedBoard: null,
  recommendedBoardLoadStatus: ELoadStatus.Initial,
};

export const actionTrackerReducer = createReducer(
  actionTrackerInitialState,
  on(ActionTrackerActions.getActionTrackerBoards, (state) => ({
    ...state,
    boardsLoadStatus: ELoadStatus.Loading,
  })),
  on(ActionTrackerActions.getActionTrackerBoardsLoaded, (state, { boards }) => ({
    ...state,
    boards,
    boardsLoadStatus: ELoadStatus.Success,
  })),
  on(ActionTrackerActions.getActionTrackerBoardMembers, (state) => ({
    ...state,
    boardMembersLoadStatus: ELoadStatus.Loading,
  })),
  on(ActionTrackerActions.getActionTrackerBoardMembersLoaded, (state, { members }) => ({
    ...state,
    boardMembers: members,
    boardMembersLoadStatus: ELoadStatus.Success,
  })),
  on(ActionTrackerActions.getActionTrackerBoardMembersFailed, (state) => ({
    ...state,
    boardMembersLoadStatus: ELoadStatus.Failure,
  })),
  on(ActionTrackerActions.getActionTrackerBoardsFailed, (state) => ({
    ...state,
    boardsLoadStatus: ELoadStatus.Failure,
  })),
  on(ActionTrackerActions.createActionTrackerItem, (state) => ({
    ...state,
    createItemStatus: ELoadStatus.Loading,
  })),
  on(ActionTrackerActions.createActionTrackerItemLoaded, (state, { createdItem }) => ({
    ...state,
    createItemStatus: ELoadStatus.Success,
    createdItem,
  })),
  on(ActionTrackerActions.createActionTrackerItemFailed, (state) => ({
    ...state,
    createItemStatus: ELoadStatus.Failure,
  })),
  on(ActionTrackerActions.resetState, () => actionTrackerInitialState),
  on(ActionTrackerActions.getActionTrackerCurrentUserLoaded, (state, { isUserExistInActionTracker }) => ({
    ...state,
    hasActionTrackerUser: isUserExistInActionTracker,
    hasActionTrackerUserLoadStatus: ELoadStatus.Success,
  })),
  on(ActionTrackerActions.getActionTrackerCurrentUserFailed, (state) => ({
    ...state,
    hasActionTrackerUser: false,
    hasActionTrackerUserLoadStatus: ELoadStatus.Failure,
  })),
  on(ActionTrackerActions.getActionTrackerRecommendedBoard, (state) => ({
    ...state,
    recommendedBoardLoadStatus: ELoadStatus.Loading,
  })),
  on(ActionTrackerActions.getActionTrackerRecommendedBoardLoaded, (state, { board }) => ({
    ...state,
    recommendedBoard: board,
    recommendedBoardLoadStatus: ELoadStatus.Success,
  })),
);
