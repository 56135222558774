import { Action } from '@ngrx/store';
import {
  IRootCauseAnalysisElasticChartNode,
  IActivityHistoryRequestParameter,
  IElasticActivityLog,
  TableInfoResponseInterface,
  IBucketInterface,
  IComparisonChartDataInterface,
} from './root-cause-analysis-elastic.model';

export const START_ROOT_CAUSE_ANALYSIS_ELASTIC_DATA_LOADING = '[ROOT CAUSE ANALYSIS ELASTIC] START ROOT CAUSE ANALYSIS ELASTIC DATA LOADING';
export const ROOT_CAUSE_ANALYSIS_ELASTIC_DATA_LOADING = '[ROOT CAUSE ANALYSIS ELASTIC] ROOT CAUSE ANALYSIS ELASTIC DATA LOADING';
export const ROOT_CAUSE_ANALYSIS_ELASTIC_DATA_LOADED = '[ROOT CAUSE ANALYSIS ELASTIC] ROOT CAUSE ANALYSIS ELASTIC DATA LOADED';
export const ROOT_CAUSE_ANALYSIS_ELASTIC_FETCH_ERROR = '[ROOT CAUSE ANALYSIS ELASTIC] ROOT CAUSE ANALYSIS ELASTIC DATA FETCH ERROR';
export const ROOT_CAUSE_ANALYSIS_ELASTIC_CHART_ACTIVITY_DATA_LOADING = '[ROOT CAUSE ANALYSIS ELASTIC] START ROOT CAUSE ANALYSIS ELASTIC CHART ACTIVITY DATA LOAD';
export const ROOT_CAUSE_ANALYSIS_ELASTIC_CHART_ACTIVITY_DATA_LOADED =
  '[ROOT CAUSE ANALYSIS ELASTIC] START ROOT CAUSE ANALYSIS ELASTIC CHART ACTIVITY DATA LOADED';
export const ROOT_CAUSE_ANALYSIS_ELASTIC_CHART_ACTIVITY_FETCH_ERROR = '[ROOT CAUSE ANALYSIS ELASTIC] CHART ACTIVITY FETCH ERROR';
export const ROOT_CAUSE_ANALYSIS_ELASTIC_CHART_EQUIPMENT_DATA_LOADING = '[ROOT CAUSE ANALYSIS ELASTIC] START ROOT CAUSE ANALYSIS ELASTIC CHART EQUIPMENT DATA LOAD';
export const ROOT_CAUSE_ANALYSIS_ELASTIC_CHART_EQUIPMENT_DATA_LOADED =
  '[ROOT CAUSE ANALYSIS ELASTIC] START ROOT CAUSE ANALYSIS ELASTIC CHART EQUIPMENT DATA LOADED';
export const ROOT_CAUSE_ANALYSIS_ELASTIC_CHART_EQUIPMENT_FETCH_ERROR = '[ROOT CAUSE ANALYSIS ELASTIC] CHART EQUIPMENT FETCH ERROR';
export const ROOT_CAUSE_ANALYSIS_ELASTIC_CHART_TASK_DATA_LOADING = '[ROOT CAUSE ANALYSIS ELASTIC] START ROOT CAUSE ANALYSIS ELASTIC CHART TASK DATA LOAD';
export const ROOT_CAUSE_ANALYSIS_ELASTIC_CHART_TASK_DATA_LOADED =
  '[ROOT CAUSE ANALYSIS ELASTIC] START ROOT CAUSE ANALYSIS ELASTIC CHART TASK DATA LOADED';
export const ROOT_CAUSE_ANALYSIS_ELASTIC_CHART_TASK_FETCH_ERROR =
  '[ROOT CAUSE ANALYSIS ELASTIC] CHART TASK FETCH ERROR';

export const ROOT_CAUSE_ANALYSIS_ELASTIC_MANY_DATA_WARNING_SHOW =
  '[ROOT CAUSE ANALYSIS ELASTIC] START ROOT CAUSE ANALYSIS ELASTIC MANY DATA WARNING SHOW';
export const ROOT_CAUSE_ANALYSIS_STACK_CHART_DATA_LOADING =
  '[ROOT CAUSE ANALYSIS STACK CHART DATA LOADING] ROOT CAUSE ANALYSIS STACK CHART DATA LOADING';
export const ROOT_CAUSE_ANALYSIS_STACK_CHART_DATA_LOADED =
  '[ROOT CAUSE ANALYSIS STACK CHART DATA LOADED] ROOT CAUSE ANALYSIS STACK CHART DATA LOADED';
export const ROOT_CAUSE_ANALYSIS_STACK_CHART_DATA_ERROR =
  '[ROOT CAUSE ANALYSIS STACK CHART DATA ERROR] ROOT CAUSE ANALYSIS STACK CHART DATA ERROR';
export const ROOT_CAUSE_ANALYSIS_ELASTIC_COMPARISON_MODE_CHART_VISIBILITY =
  '[ROOT CAUSE ANALYSIS] ROOT CAUSE ANALYSIS COMPARISON MODE CHART VISIBILITY';

export const ROOT_CAUSE_ANALYSIS_ELASTIC_COMPARISON_MODE_DATA_LOADING =
  '[ROOT CAUSE ANALYSIS] ROOT CAUSE ANALYSIS COMPARISON MODE DATA LOADING';

export const ROOT_CAUSE_ANALYSIS_ELASTIC_COMPARISON_MODE_DATA_LOADED =
  '[ROOT CAUSE ANALYSIS] ROOT CAUSE ANALYSIS COMPARISON MODE DATA LOADED';

export const ROOT_CAUSE_ANALYSIS_ELASTIC_COMPARISON_MODE_STACK_DATA_LOADING =
  '[ROOT CAUSE ANALYSIS] ROOT CAUSE ANALYSIS COMPARISON MODE STACK DATA LOADING';

export const ROOT_CAUSE_ANALYSIS_ELASTIC_COMPARISON_MODE_STACK_DATA_LOADED =
  '[ROOT CAUSE ANALYSIS] ROOT CAUSE ANALYSIS COMPARISON MODE STACK DATA LOADED';

export const ROOT_CAUSE_ANALYSIS_ELASTIC_LINE_DATA_LOADING =
  '[ROOT CAUSE ANALYSIS] ROOT CAUSE ANALYSIS LINE DATA LOADING';

export const ROOT_CAUSE_ANALYSIS_ELASTIC_LINE_DATA_LOADED =
  '[ROOT CAUSE ANALYSIS] ROOT CAUSE ANALYSIS LINE DATA LOADED';

export const ROOT_CAUSE_ANALYSIS_ELASTIC_SET_SELECTED_CHART_DATA =
  '[ROOT CAUSE ANALYSIS] ROOT CAUSE ANALYSIS ELASTIC SET SELECTED CHART DATA';

export class StartRootCauseAnalysisElasticDataLoading implements Action {
  readonly type = START_ROOT_CAUSE_ANALYSIS_ELASTIC_DATA_LOADING;

  constructor(public params: IActivityHistoryRequestParameter) {}
}

export class RootCauseAnalysisElasticDataLoading implements Action {
  readonly type = ROOT_CAUSE_ANALYSIS_ELASTIC_DATA_LOADING;

  constructor(public params: IActivityHistoryRequestParameter, public dispatchHideLoader: boolean = false) {}
}

export class RootCauseAnalysisElasticDataLoaded implements Action {
  readonly type = ROOT_CAUSE_ANALYSIS_ELASTIC_DATA_LOADED;

  constructor(public data: IElasticActivityLog[], public tableResponseParameter: TableInfoResponseInterface) {}
}

export class RootCauseAnalysisElasticDataError implements Action {
  readonly type = ROOT_CAUSE_ANALYSIS_ELASTIC_FETCH_ERROR;

  constructor(public payload: object) {}
}

export class RootCauseAnalysisElasticStackChartDataLoading implements Action {
  readonly type = ROOT_CAUSE_ANALYSIS_STACK_CHART_DATA_LOADING;

  constructor(public params: IActivityHistoryRequestParameter, public dispatchHideLoader: boolean = false) {
  }
}

export class RootCauseAnalysisElasticStackChartDataLoaded implements Action {
  readonly type = ROOT_CAUSE_ANALYSIS_STACK_CHART_DATA_LOADED;

  constructor(public data: IBucketInterface[]) {}
}

export class RootCauseAnalysisElasticStackChartDataError implements Action {
  readonly type = ROOT_CAUSE_ANALYSIS_STACK_CHART_DATA_ERROR;

  constructor(public payload: object) {}
}

export class RootCauseAnalysisElasticChartActivityDataLoading implements Action {
  readonly type = ROOT_CAUSE_ANALYSIS_ELASTIC_CHART_ACTIVITY_DATA_LOADING;

  constructor(public params: IActivityHistoryRequestParameter) {}
}

export class RootCauseAnalysisElasticChartActivityDataLoaded implements Action {
  readonly type = ROOT_CAUSE_ANALYSIS_ELASTIC_CHART_ACTIVITY_DATA_LOADED;

  constructor(public data: IRootCauseAnalysisElasticChartNode[]) {}
}

export class RootCauseAnalysisElasticChartEquipmentDataLoading implements Action {
  readonly type = ROOT_CAUSE_ANALYSIS_ELASTIC_CHART_EQUIPMENT_DATA_LOADING;

  constructor(public params: IActivityHistoryRequestParameter) {}
}

export class RootCauseAnalysisElasticChartEquipmentDataLoaded implements Action {
  readonly type = ROOT_CAUSE_ANALYSIS_ELASTIC_CHART_EQUIPMENT_DATA_LOADED;

  constructor(public data: IRootCauseAnalysisElasticChartNode[]) {}
}

export class RootCauseAnalysisElasticChartTaskDataLoading implements Action {
  readonly type = ROOT_CAUSE_ANALYSIS_ELASTIC_CHART_TASK_DATA_LOADING;

  constructor(public params: IActivityHistoryRequestParameter) {}
}

export class RootCauseAnalysisElasticChartTaskDataLoaded implements Action {
  readonly type = ROOT_CAUSE_ANALYSIS_ELASTIC_CHART_TASK_DATA_LOADED;

  constructor(public data: IRootCauseAnalysisElasticChartNode[]) {}
}

export class RootCauseAnalysisElasticChartActivityDataError implements Action {
  readonly type = ROOT_CAUSE_ANALYSIS_ELASTIC_CHART_ACTIVITY_FETCH_ERROR;

  constructor(public payload: object) {}
}

export class RootCauseAnalysisElasticChartEquipmentDataError implements Action {
  readonly type = ROOT_CAUSE_ANALYSIS_ELASTIC_CHART_EQUIPMENT_FETCH_ERROR;

  constructor(public payload: object) {}
}

export class RootCauseAnalysisElasticChartTaskDataError implements Action {
  readonly type = ROOT_CAUSE_ANALYSIS_ELASTIC_CHART_TASK_FETCH_ERROR;

  constructor(public payload: object) {}
}

export class ShowManyDataWarning implements Action {
  readonly type = ROOT_CAUSE_ANALYSIS_ELASTIC_MANY_DATA_WARNING_SHOW;
}

export class ComparisonModeChartVisibility implements Action {
  readonly type = ROOT_CAUSE_ANALYSIS_ELASTIC_COMPARISON_MODE_CHART_VISIBILITY;

  constructor(
    public isRootCauseAnalysisEquipmentChartVisible: boolean,
    public isRootCauseAnalysisStackChartVisible: boolean,
  ) {}
}

export class ComparisonModeChartDataLoading implements Action {
  readonly type = ROOT_CAUSE_ANALYSIS_ELASTIC_COMPARISON_MODE_DATA_LOADING;

  constructor(public params: IActivityHistoryRequestParameter, public dispatchHideLoader: boolean = false) {}
}

export class ComparisonModeChartDataLoaded implements Action {
  readonly type = ROOT_CAUSE_ANALYSIS_ELASTIC_COMPARISON_MODE_DATA_LOADED;

  constructor(public data: IComparisonChartDataInterface[]) {}
}

export class ComparisonModeStackChartDataLoading implements Action {
  readonly type = ROOT_CAUSE_ANALYSIS_ELASTIC_COMPARISON_MODE_STACK_DATA_LOADING;

  constructor(public params: IActivityHistoryRequestParameter, public dispatchHideLoader: boolean = false) {}
}

export class ComparisonModeStackChartDataLoaded implements Action {
  readonly type = ROOT_CAUSE_ANALYSIS_ELASTIC_COMPARISON_MODE_STACK_DATA_LOADED;

  constructor(public data: IBucketInterface[]) {}
}

export class RootCauseAnalysisElasticLineDataLoading implements Action {
  readonly type = ROOT_CAUSE_ANALYSIS_ELASTIC_LINE_DATA_LOADING;

  constructor(public params: any) {}
}

export class RootCauseAnalysisElasticLineDataLoaded implements Action {
  readonly type = ROOT_CAUSE_ANALYSIS_ELASTIC_LINE_DATA_LOADED;

  constructor(public data: any[]) {}
}

export class RootCauseAnalysisElasticSetSelectedChartData implements Action {
  readonly type = ROOT_CAUSE_ANALYSIS_ELASTIC_SET_SELECTED_CHART_DATA;

  constructor(public activityName: string, public equipmentName: string, public taskName: string) {}
}

export type RootCauseAnalysisElasticActions =
  | StartRootCauseAnalysisElasticDataLoading
  | RootCauseAnalysisElasticDataLoaded
  | RootCauseAnalysisElasticStackChartDataLoading
  | RootCauseAnalysisElasticStackChartDataLoaded
  | RootCauseAnalysisElasticStackChartDataError
  | RootCauseAnalysisElasticChartActivityDataLoading
  | RootCauseAnalysisElasticChartActivityDataLoaded
  | RootCauseAnalysisElasticChartEquipmentDataLoading
  | RootCauseAnalysisElasticChartEquipmentDataLoaded
  | RootCauseAnalysisElasticChartTaskDataLoading
  | RootCauseAnalysisElasticChartTaskDataLoaded
  | ShowManyDataWarning
  | RootCauseAnalysisElasticDataError
  | RootCauseAnalysisElasticChartActivityDataError
  | RootCauseAnalysisElasticChartEquipmentDataError
  | RootCauseAnalysisElasticChartTaskDataError
  | ComparisonModeChartVisibility
  | ComparisonModeChartDataLoading
  | ComparisonModeChartDataLoaded
  | ComparisonModeStackChartDataLoading
  | ComparisonModeStackChartDataLoaded
  | RootCauseAnalysisElasticDataLoading
  | RootCauseAnalysisElasticLineDataLoading
  | RootCauseAnalysisElasticSetSelectedChartData
  | RootCauseAnalysisElasticLineDataLoaded;
