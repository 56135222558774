import { Action } from '@ngrx/store';
import { ILaborAssetViewData, IPageConfigurationSettings } from './labor-asset-view.model';
import { ILaborAssetViewFilter } from '../../../view/dashboards/labor-asset-view/labor-asset-view.model';
import { TabRowInterface } from '../../../shared/component/side-config-bar/side-config-bar.model';
import { ITableHeader } from '../../../../constants.model';
import { WidgetConfigurationInterface } from '../../../shared/component/page-configuration/page-configuration.model';

export enum ELaborAssetHoursActionTypes {
  LABOR_ASSET_VIEW_DATA_LOADING = '[LABOR_ASSET_VIEW] LABOR ASSET VIEW DATA LOADING',
  LABOR_ASSET_VIEW_DATA_LOADED = '[LABOR_ASSET_VIEW] LABOR ASSET VIEW DATA LOADED',
  SET_TABLE_SETTINGS = '[LABOR_ASSET_VIEW] SET TABLE SETTINGS',
  SET_PAGE_CONFIGURATION = '[LABOR_ASSET_VIEW] SET PAGE CONFIGURATION',
  FETCH_ERROR = '[LABOR_ASSET_VIEW] FETCH ERROR',
}

export class LaborAssetViewDataLoading implements Action {
  public readonly type = ELaborAssetHoursActionTypes.LABOR_ASSET_VIEW_DATA_LOADING;

  constructor(public params: ILaborAssetViewFilter) {}
}

export class LaborAssetViewDataLoaded implements Action {
  public readonly type = ELaborAssetHoursActionTypes.LABOR_ASSET_VIEW_DATA_LOADED;

  constructor(public payload: ILaborAssetViewData[]) {}
}

export class SetTableSettings implements Action {
  readonly type = ELaborAssetHoursActionTypes.SET_TABLE_SETTINGS;

  constructor(public payload: TabRowInterface[], public setAsDefault: boolean) {}
}

export class SetPageConfiguration implements Action {
  readonly type = ELaborAssetHoursActionTypes.SET_PAGE_CONFIGURATION;

  constructor(public pageConfigurationSettings: IPageConfigurationSettings) {}
}

export class FetchError implements Action {
  public readonly type = ELaborAssetHoursActionTypes.FETCH_ERROR;

  constructor(public payload: any) {}
}

export type LaborAssetViewActions =
  | LaborAssetViewDataLoading
  | LaborAssetViewDataLoaded
  | SetPageConfiguration
  | SetTableSettings
  | FetchError;
