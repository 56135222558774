import { Injectable } from '@angular/core';
import { ComponentStore, tapResponse } from '@ngrx/component-store';
import { IActivityHistoryAuditLogData } from './activity-history-audit-log-modal-component.model';
import { distinctUntilChanged, Observable } from 'rxjs';
import { catchError, switchMap, tap } from 'rxjs/operators';
import { EMPTY_ACTION, HideLoader, ShowLoader } from 'src/app/store/app/actions';
import { Store } from '@ngrx/store';
import { OeeAppState } from '../../../store/oee.reducer';
import { ActivityHistoryAuditLogModalComponentService } from './activity-history-audit-log-modal-component.service';
import { HelperService } from '../../../shared/service/helper.service';
import { isEqual } from 'lodash';
import { BaseOneResponseInterface } from '../../../shared/model/interface/crud-response-interface.model';

export interface IActivityHistoryAuditLogState {
  auditLogDataLoading: boolean;
  auditLogDataLoaded: boolean;
  auditLogData: BaseOneResponseInterface<IActivityHistoryAuditLogData>;
}

const defaultState: IActivityHistoryAuditLogState = {
  auditLogDataLoading: false,
  auditLogDataLoaded: false,
  auditLogData: null,
};

@Injectable()
export class ActivityHistoryAuditLogModalComponentStore extends ComponentStore<IActivityHistoryAuditLogState> {
  constructor(
    private readonly store: Store<OeeAppState>,
    private readonly activityHistoryAuditLogModalComponentService: ActivityHistoryAuditLogModalComponentService,
  ) {
    super(HelperService.cloneDeep(defaultState));
  }

  public readonly getActivityHistoryAuditLogs = this.effect((trigger$: Observable<any>) => {
    return trigger$.pipe(
      tap(() => {
        this.store.dispatch(new ShowLoader());
        this.patchState({ auditLogDataLoading: true, auditLogDataLoaded: false, auditLogData: null });
      }),
      switchMap((uuid) => {
        return this.activityHistoryAuditLogModalComponentService.getActivityHistoryAuditLogData(uuid).pipe(
          tapResponse(
            (response: any) =>
              this.patchState({
                auditLogDataLoading: false,
                auditLogDataLoaded: true,
                auditLogData: HelperService.cloneDeep(response),
              }),
            () => {
              this.patchState(HelperService.cloneDeep(defaultState));
            },
            () => {
              this.store.dispatch(new HideLoader());
            },
          ),
          catchError(() => {
            this.store.dispatch(new HideLoader());

            return EMPTY_ACTION;
          }),
        );
      }),
      catchError(() => {
        this.store.dispatch(new HideLoader());

        return EMPTY_ACTION;
      }),
    );
  });

  public readonly auditLogData$ = this.select(
    (state: IActivityHistoryAuditLogState): Partial<IActivityHistoryAuditLogState> => {
      return {
        auditLogDataLoading: state.auditLogDataLoading,
        auditLogDataLoaded: state.auditLogDataLoaded,
        auditLogData: state.auditLogData,
      };
    },
  ).pipe(distinctUntilChanged(isEqual));
}
