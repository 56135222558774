import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, switchMap } from 'rxjs/operators';
import { from, of } from 'rxjs';
import { LineStationService } from '../../../shared/service/filter/line-station.service';
import * as ObjectActions from './line-station.actions';
import { generateHttpOption } from '../../../shared/helper/app-helper';
import { LineStationCRUDInterface } from '../../../shared/component/filter/filter.class';
import { HttpParams } from '@angular/common/http';

@Injectable()
export class FilterLineStationEffects {
  loadFilterLineStations$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ObjectActions.FilterLineStationActionTypes.START_LOAD_FILTER_LINE_STATIONS),
      switchMap((objectData: ObjectActions.StartLoadFilterLineStations) => {
        const params: HttpParams = generateHttpOption(objectData.options);

        return from(this.service.getData(params)).pipe(
          switchMap((response: LineStationCRUDInterface[]) => {
            response.forEach((lineStation: LineStationCRUDInterface): void => {
              if (lineStation.line !== undefined) {
                lineStation.lineTitle = lineStation.line.title;
                lineStation.siteId = lineStation.line.siteId;
                delete lineStation.line;
              }
            });

            return of(new ObjectActions.FilterLineStationsLoaded(response));
          }),
          catchError(() => {
            return of(new ObjectActions.FilterLineStationsLoaded([]));
          }),
        );
      }),
      catchError(() => {
        return of(new ObjectActions.FilterLineStationsLoaded([]));
      }),
    ),
  );

  constructor(private actions$: Actions<ObjectActions.FilterLineStationActions>, public service: LineStationService) {}
}
