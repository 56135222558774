import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { HttpClient } from '@angular/common/http';
import { Store } from '@ngrx/store';
import * as oeeAppReducer from '../../oee.reducer';
import * as ObjectActions from '../../reports/work-order-count-review/work-order-count-review.actions';
import * as AppActions from '../../app/actions';
import { catchError, switchMap } from 'rxjs/operators';
import { of } from 'rxjs';
import { WorkOrderCountReviewChartDataModel } from './work-order-count-review.model';
import { ManualCountReviewService } from './work-order-count-review.service';

@Injectable()
export class ManualCountReviewEffects {
  constructor(
    private readonly actions$: Actions,
    private readonly http: HttpClient,
    private readonly service: ManualCountReviewService,
    private store: Store<oeeAppReducer.OeeAppState>,
  ) {}


  getChartData = createEffect(() => this.actions$.pipe(
    ofType(ObjectActions.WORK_ORDER_COUNT_REVIEW_FETCH_DATA_LOADING),
    switchMap((objectData: ObjectActions.WorkOrderCountReviewFetchDataLoading) => {
      const { periodicOeeCalculationsParams, sensorDataParams, workOrderCountReviewParams, activityLogsBody } =
        this.service.setParams(objectData);

      this.store.dispatch(new AppActions.ShowLoader());
      return this.service
        .getChartData(activityLogsBody, periodicOeeCalculationsParams, sensorDataParams, workOrderCountReviewParams)
        .pipe(
          switchMap((response: WorkOrderCountReviewChartDataModel) => {
            return of(new ObjectActions.WorkOrderCountReviewFetchDataLoaded(response), new AppActions.HideLoader());
          }),
          catchError((err) => {
            return of(new ObjectActions.WorkOrderCountReviewFetchError(err));
          }),
        );
    }),
    catchError((err) => {
      return of(new ObjectActions.WorkOrderCountReviewFetchError(err));
    }),
  ));
}
