import { MaintenancePerformanceService } from './maintenance-performance.service';
import { Injectable } from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import * as ObjectActions from './maintenance-performance.actions';
import { catchError, switchMap } from 'rxjs/operators';
import { of } from 'rxjs';
import * as AppActions from '../../app/actions';
import { Store } from '@ngrx/store';
import * as oeeAppReducer from '../../oee.reducer';
import {
  MaintenancePerformanceFormattedQueryParams,
  MaintenancePerformanceQueryParams,
} from '../../../view/reports/maintenance-performance/maintenance-performance.model';
import { MaintenancePerformanceReportInterface } from './maintenance-performance.model';
import { BaseOneResponseInterface } from '../../../shared/model/interface/crud-response-interface.model';
import { emptyAction } from '../../../../constants';

@Injectable()
export class MaintenancePerformanceEffects {
  constructor(
    private readonly actions$: Actions,
    private readonly service: MaintenancePerformanceService,
    private readonly store: Store<oeeAppReducer.OeeAppState>,
  ) {}

  getMaintenancePerformanceData = createEffect(() =>
    this.actions$.pipe(
      ofType(ObjectActions.MAINTENANCE_PERFORMANCE_DATA_LOADING),
      switchMap((payload: ObjectActions.MaintenancePerformanceDataLoading) => {
        this.store.dispatch(new AppActions.ShowLoader());
        const data: MaintenancePerformanceFormattedQueryParams = this.service.formatMaintenancePerformanceData(
          payload.params,
        );

        return this.service.getMaintenancePerformanceData(data).pipe(
          switchMap((response: BaseOneResponseInterface<MaintenancePerformanceReportInterface>) => {
            return of(new ObjectActions.MaintenancePerformanceDataLoaded(response), new AppActions.HideLoader());
          }),
          catchError((errorRes) => {
            return of(new ObjectActions.FetchError(errorRes), new AppActions.HideLoader());
          }),
        );
      }),
      catchError((errorRes) => {
        return of(new ObjectActions.FetchError(errorRes), new AppActions.HideLoader());
      }),
    ),
  );

  downloadExcel = createEffect(() =>
    this.actions$.pipe(
      ofType(ObjectActions.DOWNLOAD_MAINTENANCE_PERFORMANCE_EXCEL),
      switchMap((objectData: ObjectActions.DownloadMaintenancePerformanceExcel) => {
        const filters: MaintenancePerformanceQueryParams = {
          ...objectData.filters,
          ...{
            page: 1,
            offset: 1000,
            pageSize: 1000,
          },
        };
        this.service.downloadExcel(filters);
        return emptyAction;
      }),
    ),
  );
}
