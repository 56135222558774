import { ActivityTypes } from '../../../../shared/model/enum/activity-types';
import { IActivityLog } from '../../../../store/reports/root-cause-analysis/root-cause-analysis.model';
import moment from 'moment';
import { IChartEventParams } from '../../../../shared/component/tasks-charts/tasks-charts.model';

export enum EActivityEquipmentTaskChart {
  BAR = 'bar',
  PIE = 'pie',
}

export enum EActivitiesEquipmentChartLayout {
  ROW = 'row',
  COLUMN = 'column',
}

export enum ElasticPageTabs {
  ELASTIC = 'elastic',
  LINE = 'line',
}

export enum ElasticStackChartGroupBy {
  SHIFT_DAY = 'shiftDay',
  START = 'start',
  TIME = 'time',
  LOCATION = 'location',
  PRODUCT = 'product',
  LINE = 'line',
  DAY = 'day',
  WEEK = 'week',
  MONTH = 'month',
  DEPARTMENT = 'department',
  PRODUCT_TABLE_ID = 'productTableId',
  ROOT_CAUSE_GROUP = 'rootCauseGroup',
}

export enum ElasticReduceBy {
  ACTIVITY_TYPE = 'activityType',
}

export enum ElasticStackChartGroupByName {
  lineId = 'lineTitle',
  lineTypeId = 'lineType',
  productId = 'productId',
  productTableId = 'productTableId',
}

export type TGroupedDataOptionGroup = {
  [key: string]: IActivityLog;
};

export type TGroupedData = {
  [key in ActivityTypes]?: TGroupedDataOptionGroup;
};

export enum EStackChartDropdown {
  TIME = 'time',
  LOCATION = 'location',
  PRODUCT = 'product',
  LINE = 'line',
  ROOT_CAUSE_GROUP = 'rootCauseGroup',
}

export enum EActivityTypes {
  DOWNTIME = 'downTime',
  DOWNTIME_PLANNED = 'downTimePlanned',
  IDLE_TIME = 'idleTime',
  RUN_TIME = 'runTime',
}

export interface IStackChartDropdownOptions {
  id: ElasticStackChartGroupBy;
  name: string;
}

export interface IStackChartAxisLabelData {
  id?: number | string;
  value?: string;
}

export interface IFilterOptions {
  isBusinessDate: boolean;
  start: moment.Moment;
  end: moment.Moment;
}

export interface IChartLoader {
  type: string;
  options: { text: string };
}

export type TOptionGroupAndDurations = {
  [key: string]: IOptionGroupAndDuration;
};

export interface IOptionGroupAndDuration {
  id: string | number;
  value: string;
  duration: number;
}

export type TActivityTypeOptionGroupDurations = {
  [key in ActivityTypes]?: TActivityTypeOptionGroupDuration;
};

export type TActivityTypeOptionGroupDuration = {
  [key: string]: string | undefined;
};

export interface IStackChartData {
  activityDurations?: IActivityDurations;
  index?: number;
  keyAsString?: string;
  keyAsStringList?: string[];
}

export interface IStackChartDataLabel {
  show: boolean;
  formatter: (params: IChartEventParams) => string;
  offset: [number, number];
}

export interface IActivityDurations {
  downTime: number;
  downTimePlanned: number;
  idleTime: number;
  runTime: number;
}

export interface IActivityTypeTranslations {
  downTime: string;
  downTimePlanned: string;
  idleTime: string;
  runTime: string;
}
