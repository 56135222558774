import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { switchMap, map, catchError, of } from 'rxjs';
import * as HomeMiniKpiSettingsActions from './home-mini-kpi-settings.actions';
import { HomeMiniKpiSettingsService } from '../../../shared/service/settings/home-mini-kpi-settings/home-mini-kpi-settings.service';
import { Store } from '@ngrx/store';
import * as oeeAppReducer from '../../oee.reducer';
import * as AppActions from '../../../store/app/actions';
import { IGetSensorsOfLinesResponse } from '../../../view/settings/home-page-display-settings/home-mini-metrics/home-mini-metrics-settings-container.types';
import { GetManyResponseInterface } from '../../../shared/model/interface/crud-response-interface.model';
import { HomePageDisplaySettingsService } from '../home-page-display-settings/home-page-display-settings.service';
import { HttpParams } from '@angular/common/http';
import { ILayout } from '../home-page-display-settings/home-page-display-settings.model';

@Injectable()
export class HomeMiniKpiSettingsEffects {
  getLineSensors$ = createEffect(() =>
    this.actions$.pipe(
      ofType(HomeMiniKpiSettingsActions.getSensorsOfLines),
      switchMap((payload) => {
        this.store.dispatch(new AppActions.ShowTopLoader());
        return this.homeMiniKpiSettingsService
          .getLineSensors(payload.lineIds, payload.sensorTypes, payload.timezone)
          .pipe(
            map((response: GetManyResponseInterface<IGetSensorsOfLinesResponse>) => {
              this.store.dispatch(new AppActions.HideTopLoader());
              return HomeMiniKpiSettingsActions.getSensorsOfLinesLoaded({ sensors: response.data });
            }),
            catchError((err) =>
              of(HomeMiniKpiSettingsActions.getSensorsOfLinesFailed(err), new AppActions.HideTopLoader()),
            ),
          );
      }),
      catchError((err) => of(HomeMiniKpiSettingsActions.getSensorsOfLinesFailed(err), new AppActions.HideTopLoader())),
    ),
  );

  getLayoutData$ = createEffect(() =>
    this.actions$.pipe(
      ofType(HomeMiniKpiSettingsActions.getLayoutData),
      switchMap((objectData) => {
        this.store.dispatch(new AppActions.ShowLoader());

        const andConditions = [];
        let httpParams: HttpParams = new HttpParams()
          .append('join', 'layoutAssignment')
          .append('join', 'layoutAssignment.line')
          .append('join', 'layoutAssignment.line.status')
          .append('join', 'homeMetricSet')
          .append('join', 'homeMetricSet.homeMetricSetItems')
          .append('join', 'homeMetricSet.homeMetricSetItems.homeMetricSetProperties')
          .append('join', 'layoutCardTemplate')
          .append('page', String(objectData.page))
          .append('limit', String(objectData.pageSize || 1000))
          .append('sort', 'name,ASC')
          .append('sort', 'homeMetricSet.homeMetricSetItems.order,ASC');

        if (objectData.search) {
          andConditions.push({ name: { $cont: objectData.search } });
        }

        if (objectData.siteIds && objectData.siteIds.length > 0) {
          andConditions.push({ 'layoutAssignment.line.siteId': { $in: objectData.siteIds } });
        }

        if (andConditions.length > 0) {
          httpParams = httpParams.set('s', JSON.stringify({ $and: andConditions }));
        }

        return this.homePageDisplaySettingsService.getLayouts(httpParams).pipe(
          switchMap((response: GetManyResponseInterface<ILayout>) => {
            return of(
              HomeMiniKpiSettingsActions.getLayoutDataLoaded({ layoutData: response.data }),
              new AppActions.HideLoader(),
            );
          }),
          catchError((errorRes) => {
            return of(HomeMiniKpiSettingsActions.getLayoutDataFailed(errorRes), new AppActions.HideLoader());
          }),
        );
      }),
      catchError((errorRes) => {
        return of(HomeMiniKpiSettingsActions.getLayoutDataFailed(errorRes), new AppActions.HideLoader());
      }),
    ),
  );

  constructor(
    private readonly actions$: Actions,
    private readonly homeMiniKpiSettingsService: HomeMiniKpiSettingsService,
    private readonly store: Store<oeeAppReducer.OeeAppState>,
    private readonly homePageDisplaySettingsService: HomePageDisplaySettingsService,
  ) {}
}
