import { Action } from '@ngrx/store';
import {
  GroupedByTypes,
  CheckInLogElasticCardDataInterface,
  CheckInLogElasticInterface,
  CheckInTableGroupedByNoneInterface,
} from './check-in-log-elastic.model';
import {
  BaseCrudResponse,
  BaseOneResponseInterface,
  GetManyResponseInterface,
} from '../../../shared/model/interface/crud-response-interface.model';
import { LineCRUDInterface } from '../../../shared/component/filter/filter.class';
import {
  CheckInLogFormRequestPayloadInterface,
  CheckInLogFormTypes,
  CheckInTableQueryParams,
} from '../../../view/reports/check-in-logs-elastic/check-in-logs-elastic.model';
import { TabRowInterface } from '../../../shared/component/side-config-bar/side-config-bar.model';

export const START_CHECK_IN_LOG_ELASTIC_CARDS_DATA_LOADING =
  '[CHECK IN LOG ELASTIC] START CHECK IN LOG ELASTIC CARDS DATA LOADING';
export const CHECK_IN_LOG_ELASTIC_CARDS_DATA_LOADED = '[CHECK IN LOG ELASTIC] CHECK IN LOG ELASTIC CARDS DATA LOADED';
export const START_CHECK_IN_LOG_ELASTIC_TABLE_DATA_LOADING =
  '[CHECK IN LOG ELASTIC] START CHECK IN LOG ELASTIC TABLE DATA LOADING';
export const CHECK_IN_LOG_ELASTIC_TABLE_DATA_LOADED = '[CHECK IN LOG ELASTIC] CHECK IN LOG ELASTIC TABLE DATA LOADED';
export const LOAD_LINES = '[CHECK IN LOG ELASTIC] LOAD LINES';
export const LINES_LOADED = '[CHECK IN LOG ELASTIC] LINES LOADED';
export const SUBMIT_CHECK_IN_LOG_ELASTIC = '[CHECK IN LOG ELASTIC] SUBMIT CHECK IN LOG ELASTIC';
export const SUBMIT_CHECK_IN_LOG_ELASTIC_COMPLETED = '[CHECK IN LOG ELASTIC] SUBMIT CHECK IN LOG ELASTIC COMPLETED';
export const DELETE_CHECK_IN_LOG_ELASTIC = '[CHECK IN LOG ELASTIC] DELETE CHECK IN LOG ELASTIC';
export const DELETE_CHECK_IN_LOG_ELASTIC_COMPLETED = '[CHECK IN LOG ELASTIC] DELETE CHECK IN LOG ELASTIC COMPLETED';
export const BULK_DELETE_CHECK_IN_LOG_ELASTIC = '[CHECK IN LOG ELASTIC] BULK DELETE CHECK IN LOG ELASTIC';
export const BULK_DELETE_CHECK_IN_LOG_ELASTIC_COMPLETED =
  '[CHECK IN LOG ELASTIC] BULK DELETE CHECK IN LOG ELASTIC COMPLETED';
export const DOWNLOAD_CHECK_IN_LOG_ELASTIC_EXCEL = '[CHECK IN LOG ELASTIC] DOWNLOAD CHECKIN LOG EXCEL';
export const DOWNLOAD_CHECK_IN_LOG_ELASTIC_EXCEL_COMPLETED =
  '[CHECK IN LOG ELASTIC] DOWNLOAD CHECKIN LOG EXCEL COMPLETED';
export const SET_TABLE_SETTINGS = '[CHECK IN LOG ELASTIC] SET TABLE SETTINGS';
export const FETCH_DATA_ERROR = '[CHECK IN LOG ELASTIC] FETCH DATA ERROR';

export class StartCheckInLogElasticCardsDataLoading implements Action {
  readonly type = START_CHECK_IN_LOG_ELASTIC_CARDS_DATA_LOADING;

  constructor(
    public query: CheckInTableQueryParams = {
      offset: 0,
      pageSize: 10,
    },
  ) {}
}

export class CheckInLogElasticCardsDataLoaded implements Action {
  readonly type = CHECK_IN_LOG_ELASTIC_CARDS_DATA_LOADED;

  constructor(public payload: CheckInLogElasticCardDataInterface) {}
}

export class StartCheckInLogElasticTableDataLoading implements Action {
  readonly type = START_CHECK_IN_LOG_ELASTIC_TABLE_DATA_LOADING;

  constructor(
    public query: CheckInTableQueryParams = {
      offset: 0,
      pageSize: 10,
    },
    public groupedBy: GroupedByTypes,
  ) {}
}

export class CheckInLogElasticTableDataLoaded implements Action {
  readonly type = CHECK_IN_LOG_ELASTIC_TABLE_DATA_LOADED;

  constructor(public payload: CheckInTableGroupedByNoneInterface[]) {}
}

export class LoadLines implements Action {
  readonly type = LOAD_LINES;
}

export class LinesLoaded implements Action {
  readonly type = LINES_LOADED;

  constructor(public payload: GetManyResponseInterface<LineCRUDInterface>) {}
}

export class SubmitCheckInLogElastic implements Action {
  readonly type = SUBMIT_CHECK_IN_LOG_ELASTIC;

  constructor(
    public formType: CheckInLogFormTypes,
    public checkInLogElasticForm: CheckInLogFormRequestPayloadInterface,
  ) {}
}

export class SubmitCheckInLogElasticCompleted implements Action {
  readonly type = SUBMIT_CHECK_IN_LOG_ELASTIC_COMPLETED;

  constructor(
    public formType: CheckInLogFormTypes,
    public payload: BaseOneResponseInterface<CheckInLogElasticInterface>,
  ) {}
}

export class DeleteCheckInLogElastic implements Action {
  readonly type = DELETE_CHECK_IN_LOG_ELASTIC;

  constructor(public id: number) {}
}

export class DeleteCheckInLogElasticCompleted implements Action {
  readonly type = DELETE_CHECK_IN_LOG_ELASTIC_COMPLETED;

  constructor(public payload: BaseCrudResponse) {}
}

export class BulkDeleteCheckInLogElastic implements Action {
  readonly type = BULK_DELETE_CHECK_IN_LOG_ELASTIC;

  constructor(public checkIns: number[]) {}
}

export class BulkDeleteCheckInLogElasticCompleted implements Action {
  readonly type = BULK_DELETE_CHECK_IN_LOG_ELASTIC_COMPLETED;

  constructor(public response: { id: number; errorMessages?: string }[]) {}
}

export class FetchDataError implements Action {
  readonly type = FETCH_DATA_ERROR;

  constructor(public payload: object) {}
}

export class DownloadCheckInLogsElasticExcel implements Action {
  readonly type = DOWNLOAD_CHECK_IN_LOG_ELASTIC_EXCEL;

  constructor(public filters: CheckInTableQueryParams, public groupedBy: GroupedByTypes) {}
}

export class SetTableSettings implements Action {
  readonly type = SET_TABLE_SETTINGS;

  constructor(public payload: TabRowInterface[], public setAsDefault: boolean) {}
}

export class DownloadExcelCompleted implements Action {
  readonly type = DOWNLOAD_CHECK_IN_LOG_ELASTIC_EXCEL_COMPLETED;
}

export type CheckInLogElasticActions =
  | StartCheckInLogElasticCardsDataLoading
  | CheckInLogElasticCardsDataLoaded
  | StartCheckInLogElasticTableDataLoading
  | CheckInLogElasticTableDataLoaded
  | LoadLines
  | LinesLoaded
  | SubmitCheckInLogElastic
  | SubmitCheckInLogElasticCompleted
  | DeleteCheckInLogElastic
  | DeleteCheckInLogElasticCompleted
  | DownloadCheckInLogsElasticExcel
  | DownloadExcelCompleted
  | SetTableSettings
  | FetchDataError;
