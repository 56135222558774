import { Inject, Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { forkJoin, Observable } from 'rxjs';
import { GenericCrudRequestConstructionParameters } from '../../model/interface/generic-api-request.model';
import { HelperService } from '../helper.service';
import {
  BaseOneResponseInterface,
  BulkResponseDataInterface,
  GetManyResponseInterface,
} from '../../model/interface/crud-response-interface.model';
import {
  NotificationScopeCRUDInterface,
  NotificationScopeCRUDRequestInterface,
  NotificationScopeFormDropdownData,
  SimplifiedLineCRUDInterface,
  SimplifiedSiteCRUDInterface,
} from '../../../store/settings/notification-scope/notification-scope.model';
import { DropdownOptionInterface } from '../../component/scw-mat-ui/scw-mat-select/scw-mat-select.model';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class NotificationScopeService {
  private readonly routes = {
    notificationScopes: '/notifications/notification-reports',
  };

  constructor(
    public http: HttpClient,
    @Inject('API_BASE_URL') private readonly api: string,
    public helperService: HelperService,
  ) {}

  public loadNotificationScopes(
    serviceParameters: GenericCrudRequestConstructionParameters,
  ): Observable<GetManyResponseInterface<NotificationScopeCRUDInterface>> {
    const params: HttpParams = this.helperService.insertGenericCrudRequestParameters(serviceParameters);

    return this.http.get<GetManyResponseInterface<NotificationScopeCRUDInterface>>(
      `${this.api}${this.routes.notificationScopes}`,
      {
        params,
      },
    );
  }

  public loadFormData(): Observable<NotificationScopeFormDropdownData> {
    const siteParams: HttpParams = this.helperService.insertGenericCrudRequestParameters({
      limit: 1000,
      fields: ['name'],
    });

    const activityParams: HttpParams = this.helperService.insertGenericCrudRequestParameters({
      limit: 1000,
      fields: ['name'],
    });

    const lineParams: HttpParams = this.helperService.insertGenericCrudRequestParameters({
      limit: 1000,
      fields: ['title', 'siteId', 'activityIds'],
    });

    const observables: {
      sites: Observable<GetManyResponseInterface<SimplifiedSiteCRUDInterface>>;
      lines: Observable<GetManyResponseInterface<SimplifiedLineCRUDInterface>>;
      activities: Observable<GetManyResponseInterface<DropdownOptionInterface>>;
    } = {
      sites: this.http.get<GetManyResponseInterface<SimplifiedSiteCRUDInterface>>(`${this.api}/sites`, {
        params: siteParams,
      }),
      lines: this.http.get<GetManyResponseInterface<SimplifiedLineCRUDInterface>>(`${this.api}/lines`, {
        params: lineParams,
      }),
      activities: this.http.get<GetManyResponseInterface<DropdownOptionInterface>>(`${this.api}/activities`, {
        params: activityParams,
      }),
    };

    return forkJoin(observables).pipe(
      map((resolvedObservables: NotificationScopeFormDropdownData) => {
        return {
          sites: resolvedObservables.sites,
          lines: resolvedObservables.lines,
          activities: resolvedObservables.activities,
        };
      }),
    );
  }

  public createNotificationScope(
    requestData: Partial<NotificationScopeCRUDRequestInterface>,
  ): Observable<BaseOneResponseInterface<NotificationScopeCRUDInterface>> {
    return this.http.post<BaseOneResponseInterface<NotificationScopeCRUDInterface>>(
      `${this.api}${this.routes.notificationScopes}`,
      requestData,
    );
  }

  public updateNotificationScope(
    id: number,
    requestData: Partial<NotificationScopeCRUDRequestInterface>,
  ): Observable<BaseOneResponseInterface<NotificationScopeCRUDInterface>> {
    return this.http.patch<BaseOneResponseInterface<NotificationScopeCRUDInterface>>(
      `${this.api}${this.routes.notificationScopes}/${id}`,
      requestData,
    );
  }

  public deleteNotificationScope(id: number): Observable<void> {
    return this.http.delete<void>(`${this.api}${this.routes.notificationScopes}/${id}`);
  }

  public bulkDeleteNotificationScope(ids: number[]): Observable<BulkResponseDataInterface> {
    return this.http.delete<BulkResponseDataInterface>(`${this.api}${this.routes.notificationScopes}/bulk/delete`, {
      body: { notificationReports: ids },
      headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
    });
  }

  public getShiftAndTemplateData(
    siteId: number,
    params: HttpParams,
  ): Observable<BaseOneResponseInterface<SimplifiedSiteCRUDInterface>> {
    return this.http.get<BaseOneResponseInterface<SimplifiedSiteCRUDInterface>>(`${this.api}/sites/${siteId}`, {
      params,
    });
  }
}
