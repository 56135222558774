<div class="general-config-container">
  <scw-mat-small-button [iconButton]="true" (onClick)="showModal(config_modal)">
    <em class="fas fa-sliders-h"></em>
  </scw-mat-small-button>
</div>

<ng-template #config_modal let-modal>
  <div class="modal-header scw-modal-header">
    <h3 class="modal-title scw-header">
      {{ 'general.modal.pageConfiguration.header' | translate }}
    </h3>
    <button id="close-button" type="button" class="close" onfocus="blur()" aria-label="Close" (click)="onClickClose()">
      <span aria-hidden="true">
        <em class="fas fa-times"></em>
      </span>
    </button>
  </div>

  <div class="modal-body">
    <div class="modal-section">
      <div class="col-12">
        <div class="row section-list">
          <ng-container *ngFor="let section of lineViewEnhancedConfiguration">
            <div
              [ngSwitch]="section.type"
              [ngClass]="{
                'w-100': section.type == EConfigurationTypes.WIDGET || section.type == EConfigurationTypes.TABLE,
                'w-50':
                  section.type == EConfigurationTypes.COLUMN_SORT_ORDER ||
                  section.type == EConfigurationTypes.TARGET_EXCEEDED_HIGHLIGHT_COLUMNS
              }"
            >
              <ng-container *ngSwitchCase="EConfigurationTypes.WIDGET">
                <ng-container *ngTemplateOutlet="widgetSection; context: { $implicit: section }"></ng-container>
              </ng-container>
              <ng-container *ngSwitchCase="EConfigurationTypes.TABLE">
                <ng-container *ngTemplateOutlet="tableHeaderSection; context: { $implicit: section }"></ng-container>
              </ng-container>
              <ng-container *ngSwitchCase="EConfigurationTypes.COLUMN_SORT_ORDER">
                <ng-container *ngTemplateOutlet="sortOrderSection; context: { $implicit: section }"></ng-container>
              </ng-container>
              <ng-container *ngSwitchCase="EConfigurationTypes.TARGET_EXCEEDED_HIGHLIGHT_COLUMNS">
                <ng-container *ngTemplateOutlet="highlightColumnSection; context: { $implicit: section }"></ng-container>
              </ng-container>
            </div>
          </ng-container>
        </div>
      </div>
    </div>
  </div>

  <div class="modal-footer">
    <div class="buttons-left">
      <scw-mat-button (onClick)="onClickClose()" [type]="'standard'">
        {{ 'general.cancel' | translate }}
      </scw-mat-button>
    </div>
    <div class="buttons-right">
      <scw-mat-button (onClick)="apply(true)" [type]="'standard'">
        {{ 'general.setAsDefault' | translate }}
      </scw-mat-button>
      <scw-mat-button (onClick)="apply(false)">
        {{ 'general.apply' | translate }}
      </scw-mat-button>
    </div>
  </div>
  <div id="spotlight-overlay" [style.display]="spotlightOverlayDisplay"></div>
</ng-template>

<ng-template #unsaved_modal let-modal>
  <div class="modal-header scw-modal-header">
    <h3 class="modal-title scw-header">
      {{ 'general.modal.pageConfiguration.header' | translate }}
    </h3>
    <button type="button" class="close" onfocus="blur()" aria-label="Close" (click)="modal.dismiss()">
      <span aria-hidden="true">
        <em class="fas fa-times"></em>
      </span>
    </button>
  </div>
  <div class="modal-body">
    <div class="modal-section">
      <div>
        <em class="fas fa-exclamation-circle modal-info-icon"></em>
      </div>
      <h6 class="scw-header">
        {{ 'pageConfiguration.unsavedChanges.label' | translate }}
      </h6>
    </div>
  </div>
  <div class="modal-footer">
    <div class="buttons-left">
      <scw-mat-button (onClick)="dontApply()" [type]="'danger'">
        {{ 'general.dontApply' | translate }}
      </scw-mat-button>
    </div>
    <div class="buttons-right">
      <scw-mat-button (onClick)="modal.dismiss()" [type]="'standard'">
        {{ 'general.cancel' | translate }}
      </scw-mat-button>
      <scw-mat-button (onClick)="apply(false)">
        {{ 'general.apply' | translate }}
      </scw-mat-button>
    </div>
  </div>
</ng-template>

<ng-template #widgetSection let-section>
  <div [id]="section.name" class="row" *ngIf="section.items.length">
    <div class="col-12">
      <h6 class="scw-header">
        <em class="fas fa-grip-horizontal"></em>
        {{ section.title }}
      </h6>
    </div>
    <div class="col-12">
      <div class="label-text">
        {{ section.description }}
      </div>
    </div>
    <div
      class="widget-list"
      cdkDropList
      [cdkDropListData]="section.items"
      [cdkDropListOrientation]="section.orientation"
      (cdkDropListDropped)="drop(section.items, $event)"
      [cdkDropListSortingDisabled]="false"
    >
      <div
        class="widget-card"
        *ngFor="let widget of section.items; index as i;"
        cdkDrag
        [cdkDragBoundary]="'#' + section.name"
        [cdkDragData]="widget"
        cdkDragLockAxis="x"
      >
        <scw-mat-kpi-card
        class="scw-mat-kpi-card w-100"
        [configuration]="widget.content | callFunction : updateContent : [widget]"
        (onClick)="widgetSelection($event, widget)"
        ></scw-mat-kpi-card>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #tableHeaderSection let-section>
  <div [id]="section.name" class="row" [style.gap]="'24px'" *ngIf="section.items.length">
    <div class="col-12">
      <h6 class="scw-header m-0">
        <em class="fas fa-table"></em>
        {{ section.title }}
      </h6>
    </div>

    <ng-container *ngIf="section.description">
      <div class="col-12">
        <div class="label-text">
          {{ section.description }}
        </div>
      </div>
    </ng-container>

    <ng-container *ngIf="section.isSearchEnabled">
      <scw-mat-search
        class="col-6"
        [placeholder]="section.searchPlaceholder"
        [(inputModel)]="section.searchInputModel"
        (inputModelChange)="onKeyUpSearchBox($event, section)"
      >
      </scw-mat-search>
    </ng-container>

    <div class="col-12">
      <div class="row">
        <div class="col-6 header-section">
          <ng-container *ngIf="section.availableTitle">
            <div class="column-title available-column-title">
              {{ section.availableTitle | callFunction : uppercase }}
            </div>
          </ng-container>

          <div *ngFor="let headerGroup of section.items">
            <div class="header-group">
              <div class="scw-header header-group-title">{{ headerGroup.title }}</div>

              <ng-container *ngIf="headerGroup.description">
                <div class="label-text">{{ headerGroup.description }}</div>
              </ng-container>

              <div
                class="cdk-drop-list datatable-list"
                [style.border-style]="headerGroup.items.length === 0 ? 'dashed' : 'none'"
                [id]="headerGroup.name"
                cdkDropList
                [cdkDropListData]="headerGroup.items"
                (cdkDropListDropped)="dropHeaderItem(headerGroup.items, $event)"
                [cdkDropListConnectedTo]="section.name + '-drop-list'"
                [cdkDropListEnterPredicate]="selectedOrRequiredPredicate.bind(this)"
                cdkDropListSortingDisabled
              >
                <div
                  [id]="groupItem.name"
                  class="cdk-drag datatable-box light-datatable-box"
                  *ngFor="let groupItem of headerGroup.items"
                  cdkDrag
                  [cdkDragBoundary]="'#' + section.name"
                  (cdkDragStarted)="dragStarted($event)"
                  (cdkDragReleased)="dragReleased($event)"
                  [cdkDragData]="groupItem"
                  [ngClass]="{ 'd-none': section.searchInputModel?.length > 1 && !groupItem.isSearched }"
                >
                  <div class="page_configuration_item-left-title">{{ groupItem.title }}</div>
                  <div class="page_configuration_item-drag_icon"><em class="fas fa-expand-arrows-alt"></em></div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-6">
          <ng-container *ngIf="section.selectedTitle">
            <div class="column-title selected-column-title">
              {{ section.selectedTitle | callFunction : uppercase }}
            </div>
          </ng-container>
          <div class="label-text header-item-drop-area-title">
            <div class="header-item-drop-area-icon"><i class="fas fa-exclamation-circle"></i></div>
            <div class="header-item-drop-area-description">{{ section.selectedColumnsDescription }}</div>
          </div>
          <div
            class="w-100 header-item-drop-area"
            [id]="section.name + '-drop-list'"
            cdkDropList
            (cdkDropListDropped)="dropHeaderItem(section.selectedItems, $event)"
            [cdkDropListData]="section.selectedItems"
            [cdkDropListConnectedTo]="section.headerGroupDomIds"
          >
            <div
              [id]="selectedItem.name"
              class="cdk-drag datatable-box selected-datatable-box"
              *ngFor="let selectedItem of section.selectedItems"
              cdkDrag
              [cdkDragBoundary]="'#' + section.name"
              [cdkDragData]="selectedItem"
              (cdkDragStarted)="dragStarted($event)"
              (cdkDragReleased)="dragReleased($event)"
              [style.background-color]="selectedItem.isSearched ? '#5e5e72' : ''"
            >
              <div class="page_configuration_item-left-title f-w-600">{{ selectedItem.title }}</div>
              <div class="page_configuration_item-drag_icon"><em class="fas fa-expand-arrows-alt"></em></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #sortOrderSection let-section>
  <div [id]="section.name" class="row mr-0" *ngIf="section.items.length">
    <div class="col-12">
      <h6 class="scw-header">
        <em class="fas fa-sort"></em>
        {{ section.title }}
      </h6>
    </div>
    <div class="col-12">
      <div class="label-text">
        {{ section.description }}
      </div>
    </div>
    <div class="col-12">
      <div
        class="cdk-drop-list datatable-list"
        cdkDropList
        [cdkDropListData]="section.items"
        (cdkDropListDropped)="drop(section.items, $event)"
        [cdkDropListAutoScrollDisabled]="false"
      >
        <div
          *ngFor="let orderItem of section.items"
          class="cdk-drag datatable-box light-datatable-box"
          cdkDrag
          [cdkDragData]="orderItem"
          [cdkDragBoundary]="'#' + section.name"
          cdkDragLockAxis="y"
        >
          <div class="page_configuration_item-left-title pl-10">{{ orderItem.title }}</div>
          <div class="page_configuration_item-drag_icon"><em class="fas fa-arrows-alt-v"></em></div>
        </div>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #highlightColumnSection let-section>
  <div [id]="section.name" class="row ml-0" *ngIf="section.items.length">
    <div class="col-12">
      <h6 class="scw-header">
        <em class="fas fa-hourglass-end"></em>
        {{ section.title }}
      </h6>
    </div>
    <div class="col-12">
      <div class="label-text">
        {{ section.description }}
      </div>
    </div>
    <div class="col-12">
      <scw-mat-select
        [data]="section.items"
        [(inputModel)]="section.selectedItems"
        [singleSelection]="false"
      >
      </scw-mat-select>
    </div>
  </div>
</ng-template>
