import { IOpcMessageInspectionState } from './opc-message-inspection.model';
import * as Actions from './opc-message-inspection.actions';

const initialState: IOpcMessageInspectionState = {
  isOpcMessageDataLoading: false,
  isOpcMessageDataLoaded: false,
  isActivityLogsDataLoading: false,
  isActivityLogsDataLoaded: false,
  activityLogs: null,
  opcMessageData: null,
};

export function opcMessageInspectionReducer(
  state: IOpcMessageInspectionState = initialState,
  action: Actions.OpcMessageInspectionActions,
): IOpcMessageInspectionState {
  switch (action.type) {
    case Actions.OPC_MESSAGE_INSPECTION_FETCH_DATA_LOADING:
      return {
        ...state,
        isOpcMessageDataLoading: true,
        isOpcMessageDataLoaded: false,
      };
    case Actions.OPC_MESSAGE_INSPECTION_FETCH_DATA_LOADED:
      return {
        ...state,
        isOpcMessageDataLoading: false,
        isOpcMessageDataLoaded: true,
        opcMessageData: action.payload,
      };
    case Actions.OPC_MESSAGE_INSPECTION_ACTIVITY_LOGS_LOADING:
      return {
        ...state,
        isActivityLogsDataLoading: true,
        isActivityLogsDataLoaded: false,
      };
    case Actions.OPC_MESSAGE_INSPECTION_ACTIVITY_LOGS_LOADED:
      return {
        ...state,
        isActivityLogsDataLoading: false,
        isActivityLogsDataLoaded: true,
        activityLogs: action.payload,
      };
    case Actions.OPC_MESSAGE_INSPECTION_FETCH_ERROR:
      return {
        ...state,
      };
    case Actions.OPC_MESSAGE_INSPECTION_CLEAR_STATE:
      return { ...initialState };
    default:
      return state;
  }
}
