import * as ObjectActions from './jobs.actions';
import * as AppActions from '../../app/actions';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { OeeAppState } from '../../oee.reducer';
import { catchError, switchMap } from 'rxjs/operators';
import { JobsService } from './jobs.service';
import { HTTPHelperService } from '../../../shared/service/http/http.helper.service';
import { of } from 'rxjs';
import {
  BaseCrudResponse,
  BaseOneResponseInterface,
  BulkResponseDataInterface,
  GetManyResponseInterface,
  IErrorMessageWitObjectId,
} from '../../../shared/model/interface/crud-response-interface.model';
import { ErrorMessageService } from '../../../shared/service/error-message.service';
import { ExcelHelperService } from '../../../shared/service/excel/excel.helper.service';
import { IJob } from './jobs.model';
import { emptyAction } from '../../../../constants';
import { WorkOrderInterface } from '../../work-order-schedule/work-order-schedule.model';
import { HttpParams } from '@angular/common/http';
import { ResponseArrayInterface } from '../../../shared/model/interface/generic-api-response.model';
import { ILines } from '../departments-lines-stations/lines/lines.model';
import {JobsCountLoaded} from "./jobs.actions";

@Injectable()
export class JobsEffects {
  constructor(
    private readonly store: Store<OeeAppState>,
    private readonly actions$: Actions,
    private readonly jobsService: JobsService,
    private readonly httpHelperService: HTTPHelperService,
    private readonly errorMessageService: ErrorMessageService,
    private readonly excelHelperService: ExcelHelperService,
  ) {}

  getJobsData = createEffect(() =>
    this.actions$.pipe(
      ofType<ObjectActions.JobsListDataLoading>(ObjectActions.JOBS_LIST_DATA_LOADING),
      switchMap((payload: ObjectActions.JobsListDataLoading) => {
        return this.jobsService.getManyJobsWithDetails(payload.params).pipe(
          switchMap((response: GetManyResponseInterface<IJob>) => {
            return of(new ObjectActions.JobsListDataLoaded(response));
          }),
          catchError((errorRes) => {
            return of(new ObjectActions.FetchError(errorRes), new AppActions.HideLoader());
          }),
        );
      }),
      catchError((errorRes) => {
        return of(new ObjectActions.FetchError(errorRes), new AppActions.HideLoader());
      }),
    ),
  );

  addJob = createEffect(() =>
    this.actions$.pipe(
      ofType<ObjectActions.AddJob>(ObjectActions.ADD_JOB),
      switchMap((payload: ObjectActions.AddJob) => {
        return this.jobsService.createJob(payload.payload.data).pipe(
          switchMap((response: BaseOneResponseInterface<IJob>) => {
            return of(new ObjectActions.AddJobCompleted(response, payload.isAddForDeleteModal));
          }),
          catchError((errorRes) => {
            return of(new ObjectActions.FetchError(errorRes), new AppActions.HideLoader());
          }),
        );
      }),
      catchError((errorRes) => {
        return of(new ObjectActions.FetchError(errorRes), new AppActions.HideLoader());
      }),
    ),
  );

  editJob = createEffect(() =>
    this.actions$.pipe(
      ofType<ObjectActions.EditJob>(ObjectActions.EDIT_JOB),
      switchMap((payload: ObjectActions.EditJob) => {
        return this.jobsService.editJob(payload.payload.data, payload.payload.id).pipe(
          switchMap((response: BaseOneResponseInterface<IJob>) => {
            return of(new ObjectActions.EditJobCompleted(response));
          }),
          catchError((errorRes) => {
            return of(new ObjectActions.FetchError(errorRes), new AppActions.HideLoader());
          }),
        );
      }),
      catchError((errorRes) => {
        return of(new ObjectActions.FetchError(errorRes), new AppActions.HideLoader());
      }),
    ),
  );

  deleteJob = createEffect(() =>
    this.actions$.pipe(
      ofType<ObjectActions.DeleteJob>(ObjectActions.DELETE_JOB),
      switchMap((objectData: ObjectActions.DeleteJob) => {
        this.store.dispatch(new AppActions.ShowLoader());
        return this.jobsService.deleteJob(objectData.jobId, objectData.targetJobId).pipe(
          switchMap((response: BaseCrudResponse) => {
            const responseMessages = [response];
            this.errorMessageService.getTranslatedErrorMessage(responseMessages);
            const mergedArray: IErrorMessageWitObjectId[] = ExcelHelperService.mergeBulkResponseWithRequestData(
              { ...response, data: responseMessages },
              [objectData.jobId].map((item) => {
                return { id: item };
              }),
              true,
            );

            return of(new ObjectActions.DeleteJobCompleted(mergedArray), new AppActions.HideLoader());
          }),
          catchError((error) => {
            return of(new ObjectActions.FetchError(error), new AppActions.HideLoader());
          }),
        );
      }),
      catchError((error) => {
        return of(new ObjectActions.FetchError(error), new AppActions.HideLoader());
      }),
    ),
  );

  downloadJobExcel = createEffect(() =>
    this.actions$.pipe(
      ofType<ObjectActions.DownloadJobExcel>(ObjectActions.DOWNLOAD_JOB_EXCEL),
      switchMap((objectData: ObjectActions.DownloadJobExcel) => {
        this.jobsService.downloadExcel(
          objectData.withData,
          objectData.filters,
          this.httpHelperService.insertGenericCrudRequestParameters(objectData.tableQuery),
        );
        return emptyAction;
      }),
      catchError((error) => {
        return of(new ObjectActions.FetchError(error), new AppActions.HideLoader());
      }),
    ),
  );

  uploadExcel = createEffect(() =>
    this.actions$.pipe(
      ofType(ObjectActions.UPLOAD_JOB_EXCEL),
      switchMap((objectData: ObjectActions.UploadJobExcel) => {
        this.store.dispatch(new AppActions.ShowLoader());

        return this.jobsService.uploadExcel(objectData.payload).pipe(
          switchMap((response: BulkResponseDataInterface) => {
            this.errorMessageService.getTranslatedErrorMessage(response.data);
            const mergedArray = ExcelHelperService.mergeBulkResponseWithRequestData<IJob>(
              response,
              objectData.payload.jobs,
            );
            return of(new ObjectActions.UploadJobExcelCompleted(mergedArray), new AppActions.HideLoader());
          }),
          catchError((error) => {
            return of(new ObjectActions.FetchError(error), new AppActions.HideLoader());
          }),
        );
      }),
    ),
  );

  getWorkOrdersForModal = createEffect(() =>
    this.actions$.pipe(
      ofType<ObjectActions.GetWorkOrdersForModal>(ObjectActions.GET_WORK_ORDERS_FOR_MODAL),
      switchMap((payload: ObjectActions.GetWorkOrdersForModal) => {
        this.store.dispatch(new AppActions.ShowLoader());

        return this.jobsService.getWorkOrdersForModal(payload.params).pipe(
          switchMap((response: GetManyResponseInterface<WorkOrderInterface>) => {
            response.data = response.data.filter((workOrder: WorkOrderInterface) => !workOrder.hasParentWorkOrder);

            return of(new ObjectActions.GetWorkOrdersForModalCompleted(response), new AppActions.HideLoader());
          }),
          catchError((errorRes) => {
            return of(new ObjectActions.FetchError(errorRes), new AppActions.HideLoader());
          }),
        );
      }),
      catchError((errorRes) => {
        return of(new ObjectActions.FetchError(errorRes), new AppActions.HideLoader());
      }),
    ),
  );

  getJobsForModal = createEffect(() =>
    this.actions$.pipe(
      ofType<ObjectActions.GetJobsForModal>(ObjectActions.GET_JOBS_FOR_MODAL),
      switchMap((payload: ObjectActions.GetJobsForModal) => {
        this.store.dispatch(new AppActions.ShowLoader());

        return this.jobsService.getJobsForModal(payload.params).pipe(
          switchMap((response: GetManyResponseInterface<IJob>) => {
            return of(new ObjectActions.GetJobsForModalCompleted(response), new AppActions.HideLoader());
          }),
          catchError((errorRes) => {
            return of(new ObjectActions.FetchError(errorRes), new AppActions.HideLoader());
          }),
        );
      }),
      catchError((errorRes) => {
        return of(new ObjectActions.FetchError(errorRes), new AppActions.HideLoader());
      }),
    ),
  );

  getJobDataForCount = createEffect(() =>
    this.actions$.pipe(
      ofType(ObjectActions.JOBS_COUNT_LOAD),
      switchMap((payload: ObjectActions.JobsCountLoad) => {
        const httpParams: HttpParams = new HttpParams()
          .set('s', JSON.stringify({ siteId: { $eq: payload.siteId } }))
          .append('limit', String(payload.limit || 1000));
        return this.jobsService.getJobs(httpParams).pipe(
          switchMap((response: GetManyResponseInterface<IJob>) => {
            return of(new ObjectActions.JobsCountLoaded(response.data));
          }),
          catchError((errorRes) => {
            return of(new ObjectActions.FetchError(errorRes), new AppActions.HideLoader());
          }),
        );
      }),
      catchError((errorRes) => {
        return of(new ObjectActions.FetchError(errorRes), new AppActions.HideLoader());
      }),
    ),
  );
}
