import { ISemiManualCountButtonCardState } from './semi-manual-count-button-card.model';
import { createReducer, on } from '@ngrx/store';
import * as SemiManualCountButtonCardActions from './semi-manual-count-button-card.actions';

const initialState: ISemiManualCountButtonCardState = {
  isSendVirtualCounts: false,
  isSendVirtualCountsCompleted: false,
};

export const semiManualCountButtonCardReducer = createReducer(
  initialState,
  on(SemiManualCountButtonCardActions.sendVirtualCounts, (state) => ({
    ...state,
    isSendVirtualCounts: true,
    isSendVirtualCountsCompleted: false,
  })),
  on(SemiManualCountButtonCardActions.sendVirtualCountsSuccess, (state) => ({
    ...state,
    isSendVirtualCounts: false,
    isSendVirtualCountsCompleted: true,
  })),
  on(SemiManualCountButtonCardActions.sendVirtualCountsFailure, (state) => ({
    ...state,
    isSendVirtualCounts: false,
    isSendVirtualCountsCompleted: true,
  })),
);
