<div
  class="scw-mat-checkbox-container"
  [class.select-sm]="size === 'sm'"
  [class.select-md]="size === 'md'"
>
  <mat-checkbox
    [(ngModel)]="inputModel"
    [disabled]="disabled"
    [id]="id"
    [labelPosition]="labelPosition"
    [name]="name"
    [required]="required"
    [value]="value"
    (change)="onChangeEmitter($event)"
    (ngModelChange)="onNgModelChange()"
    [class.checkbox-sm]="size === 'sm'"
    [class.checkbox-md]="size === 'md'"
    [class.checkbox-lg]="size === 'lg'"
  >
    <div>
      <ng-content></ng-content>
    </div>
  </mat-checkbox>
  <ng-template [ngIf]="errorText || hint">
    <div class="sub-text" [class.error]="errorText" [class.hint]="!errorText && hint">
      <em
        class="sub-text-icon fas"
        [class.fa-exclamation-circle]="errorText"
        [class.fa-info-circle]="!errorText && hint"
      ></em>
      <ng-template [ngIf]="errorText">{{ errorText }}</ng-template>
      <ng-template [ngIf]="!errorText && hint"><span [innerHTML]="hint"></span></ng-template>
    </div>
  </ng-template>
</div>
