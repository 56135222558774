import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, switchMap } from 'rxjs/operators';
import * as ObjectActions from './customers.actions';
import { SiteService } from '../../../shared/service/filter/site.service';
import { of } from 'rxjs';
import * as AppActions from '../../app/actions';
import { HttpParams } from '@angular/common/http';
import { Store } from '@ngrx/store';
import * as oeeAppReducer from '../../oee.reducer';
import { ErrorMessageService } from '../../../shared/service/error-message.service';
import * as _ from 'lodash';
import { CustomersService } from './customers.service';
import { AddCustomerInterface, CustomerBulkResponse, CustomerDeleteResponseInterface } from './customers.model';
import {
  BaseOneResponseInterface,
  GetManyResponseInterface,
} from '../../../shared/model/interface/crud-response-interface.model';
import { CustomerInterface } from '../../../view/settings/customer-settings/customers/customers.model';

@Injectable()
export class CustomersEffects {
  constructor(
    private readonly actions$: Actions,
    private readonly service: CustomersService,
    private readonly siteService: SiteService,
    private readonly store: Store<oeeAppReducer.OeeAppState>,
    private readonly errorMessageService: ErrorMessageService,
  ) {}

  getCustomerData = createEffect(() =>
    this.actions$.pipe(
      ofType(ObjectActions.CUSTOMERS_DATA_LOADING),
      switchMap((payload: ObjectActions.CustomersDataLoading) => {
        let httpParams: HttpParams = new HttpParams()
          .append('join', 'site||name')
          .append('join', 'customerSegment||name,segmentId')
          .append('page', String(payload.tableQuery.page))
          .append('limit', String(payload.tableQuery.rowsPerPage || 1000))
          .append('sort', 'id,DESC');

        if (
          payload.tableQuery.search !== undefined ||
          (payload.tableQuery.siteIds !== undefined && payload.tableQuery.siteIds !== -1) ||
          (payload.tableQuery.customerSegmentId !== undefined && payload.tableQuery.customerSegmentId !== -1)
        ) {
          httpParams = httpParams.append('s', this.getCustomerSearchParams(payload));
        }

        if (!_.isEmpty(payload.tableQuery.sort.column)) {
          httpParams = httpParams.set('sort', `${payload.tableQuery.sort.column},${payload.tableQuery.sort.type}`);
        }

        return this.service.getCustomers(httpParams).pipe(
          switchMap((response: GetManyResponseInterface<CustomerInterface>) => {
            return of(new ObjectActions.CustomersDataLoaded(response));
          }),
          catchError((errorRes) => {
            return of(new ObjectActions.FetchError(errorRes));
          }),
        );
      }),
      catchError((errorRes) => {
        return of(new ObjectActions.FetchError(errorRes));
      }),
    ),
  );

  addCustomer = createEffect(() =>
    this.actions$.pipe(
      ofType(ObjectActions.ADD_CUSTOMER),
      switchMap((objectData: ObjectActions.AddCustomer) => {
        this.store.dispatch(new AppActions.ShowLoader());

        return this.service.addCustomer(objectData.customer).pipe(
          switchMap((response: BaseOneResponseInterface<AddCustomerInterface>) => {
            return of(new ObjectActions.AddCustomerCompleted(response), new AppActions.HideLoader());
          }),
          catchError((error) => {
            return of(new ObjectActions.FetchError(error), new AppActions.HideLoader());
          }),
        );
      }),
      catchError((errorRes) => {
        return of(new ObjectActions.FetchError(errorRes), new AppActions.HideLoader());
      }),
    ),
  );

  editCustomer = createEffect(() =>
    this.actions$.pipe(
      ofType(ObjectActions.EDIT_CUSTOMER),
      switchMap((objectData: ObjectActions.EditCustomer) => {
        this.store.dispatch(new AppActions.ShowLoader());

        return this.service.editCustomer(objectData.customer, objectData.id).pipe(
          switchMap((response: BaseOneResponseInterface<AddCustomerInterface>) => {
            return of(new ObjectActions.EditCustomerCompleted(response), new AppActions.HideLoader());
          }),
          catchError((error) => {
            return of(new ObjectActions.FetchError(error), new AppActions.HideLoader());
          }),
        );
      }),
    ),
  );

  bulkEditCustomer = createEffect(() =>
    this.actions$.pipe(
      ofType(ObjectActions.BULK_EDIT_CUSTOMER),
      switchMap((objectData: ObjectActions.BulkEditCustomer) => {
        this.store.dispatch(new AppActions.ShowLoader());

        return this.service.bulkEditCustomer(objectData.customers).pipe(
          switchMap((response: BaseOneResponseInterface<CustomerBulkResponse[]>) => {
            return of(new ObjectActions.BulkEditCustomerCompleted(response), new AppActions.HideLoader());
          }),
          catchError((error) => {
            return of(new ObjectActions.FetchError(error), new AppActions.HideLoader());
          }),
        );
      }),
    ),
  );

  deleteCustomers = createEffect(() =>
    this.actions$.pipe(
      ofType(ObjectActions.DELETE_CUSTOMERS),
      switchMap((objectData: ObjectActions.DeleteCustomers) => {
        this.store.dispatch(new AppActions.ShowLoader());
        return this.service.deleteCustomers(objectData.payload).pipe(
          switchMap((response: CustomerDeleteResponseInterface) => {
            this.errorMessageService.getTranslatedErrorMessage(response.data);
            return of(
              new ObjectActions.DeleteCustomersCompleted(objectData.payload.length > 1, response),
              new AppActions.HideLoader(),
            );
          }),
          catchError((error) => {
            return of(new ObjectActions.FetchError(error), new AppActions.HideLoader());
          }),
        );
      }),
    ),
  );

  private getCustomerSearchParams(payload: ObjectActions.CustomersDataLoading): string {
    return JSON.stringify({
      $and: [
        payload.tableQuery.siteIds !== -1
          ? {
              siteId: {
                $in: payload.tableQuery.siteIds,
              },
            }
          : {},
        payload.tableQuery.customerSegmentId !== -1
          ? {
              customerSegmentId: {
                $in: payload.tableQuery.customerSegmentId,
              },
            }
          : {},
        {
          $or: [
            {
              'site.name': {
                $cont: payload.tableQuery.search,
              },
            },
            {
              customerId: {
                $cont: payload.tableQuery.search,
              },
            },
            {
              name: {
                $cont: payload.tableQuery.search,
              },
            },
          ],
        },
      ],
    });
  }
}
