import { createAction, props } from '@ngrx/store';
import {
  TBoardMemberResponse,
  TBoardResponse,
  TCreateIssueItemRequestPayload,
  TRecommendedBoardResponse,
} from './action-tracker.types';

export const getActionTrackerBoards = createAction('[Action Tracker] Get Boards', props<{ searchKeyword?: string }>());
export const getActionTrackerBoardsLoaded = createAction(
  '[Action Tracker] Get Boards Loaded',
  props<{ boards: TBoardResponse[] }>(),
);
export const getActionTrackerBoardsFailed = createAction('[Action Tracker] Get Boards Failed', props<{ error: any }>());

export const getActionTrackerBoardMembers = createAction(
  '[Action Tracker] Get Board Members',
  props<{ id: number; searchKeyword?: string }>(),
);
export const getActionTrackerBoardMembersLoaded = createAction(
  '[Action Tracker] Get Board Members Loaded',
  props<{ members: TBoardMemberResponse[] }>(),
);
export const getActionTrackerBoardMembersFailed = createAction(
  '[Action Tracker] Get Board Members Failed',
  props<{ error: any }>(),
);

export const createActionTrackerItem = createAction(
  '[Action Tracker] Create Item',
  props<{ payload: TCreateIssueItemRequestPayload }>(),
);
export const createActionTrackerItemLoaded = createAction(
  '[Action Tracker] Create Item Loaded',
  props<{ createdItem: { id: number; boardId: number; key: string; url: string } }>(),
);
export const createActionTrackerItemFailed = createAction(
  '[Action Tracker] Create Item Failed',
  props<{ error: any }>(),
);

export const uploadActionTrackerItemAttachments = createAction(
  '[Action Tracker] Upload Item Attachments',
  props<{ itemId: number; boardId: number; files: File[] }>(),
);
export const uploadActionTrackerItemAttachmentsLoaded = createAction('[Action Tracker] Upload Item Attachments Loaded');
export const uploadActionTrackerItemAttachmentsFailed = createAction(
  '[Action Tracker] Upload Item Attachments Failed',
  props<{ error: any }>(),
);

export const getActionTrackerCurrentUser = createAction('[Action Tracker] Get Current User');
export const getActionTrackerCurrentUserLoaded = createAction(
  '[Action Tracker] Get Current User Loaded',
  props<{ isUserExistInActionTracker: boolean }>(),
);
export const getActionTrackerCurrentUserFailed = createAction(
  '[Action Tracker] Get Current User Failed',
  props<{ error: any }>(),
);

export const getActionTrackerRecommendedBoard = createAction('[Action Tracker] Get Recommended Board');
export const getActionTrackerRecommendedBoardLoaded = createAction(
  '[Action Tracker] Get Recommended Board Loaded',
  props<{ board: TRecommendedBoardResponse }>(),
);
export const getActionTrackerRecommendedBoardFailed = createAction(
  '[Action Tracker] Get Recommended Board Failed',
  props<{ error: any }>(),
);

export const resetState = createAction('[Action Tracker] Reset State');
