export const excelDateFormats = {
  startingWithDay: 'DD/MM/YYYY',
  startingWithMonth: 'MM/DD/YYYY',
  startingWithYearJapanll: 'YYYY年MM月DD日',
  startingWithYearJapanL: 'YYYY/MM/DD',
};

export const excelTimeFormats = {
  timeFormatWithMeridian: 'hh:mm A',
  timeFormat: 'HH:mm',
};

export const excelDateFormat = {
  de: excelDateFormats.startingWithDay,
  'en-au': excelDateFormats.startingWithDay,
  'en-ca': excelDateFormats.startingWithMonth,
  'en-nz': excelDateFormats.startingWithDay,
  'en-ie': excelDateFormats.startingWithDay,
  'en-gb': excelDateFormats.startingWithDay,
  en: excelDateFormats.startingWithMonth,
  es: excelDateFormats.startingWithDay,
  fr: excelDateFormats.startingWithDay,
  'fr-ca': excelDateFormats.startingWithDay,
  it: excelDateFormats.startingWithDay,
  pl: excelDateFormats.startingWithDay,
  tr: excelDateFormats.startingWithDay,
  jall: excelDateFormats.startingWithYearJapanll,
  jaL: excelDateFormats.startingWithYearJapanL,
};

export const excelTimeFormat = {
  de: excelTimeFormats.timeFormat,
  'en-au': excelTimeFormats.timeFormatWithMeridian,
  'en-ca': excelTimeFormats.timeFormatWithMeridian,
  'en-nz': excelTimeFormats.timeFormatWithMeridian,
  'en-ie': excelTimeFormats.timeFormat,
  'en-gb': excelTimeFormats.timeFormat,
  en: excelTimeFormats.timeFormatWithMeridian,
  es: excelTimeFormats.timeFormat,
  fr: excelTimeFormats.timeFormat,
  'fr-ca': excelTimeFormats.timeFormat,
  it: excelTimeFormats.timeFormat,
  pl: excelTimeFormats.timeFormat,
  tr: excelTimeFormats.timeFormat,
  ja: excelTimeFormats. timeFormat,
};

export const localeDateObject = {
  en: 'English (United States)',
  'en-au': 'English (Australia)',
  'en-ca': 'English (Canada)',
  'en-nz': 'English (New Zealand)',
  'en-ie': 'English (Ireland)',
  'en-gb': 'English (United Kingdom)',
  tr: 'Türkçe',
  de: 'Deutsch',
  es: 'Español',
  fr: 'Français',
  it: 'Italiano',
  pl: 'Polski',
  'fr-ca': 'Français (Canada)',
  ja: '日本語',
};
