import { ILaborAssetViewData } from '../../../store/dashboards/labor-asset-view/labor-asset-view.model';
import { ECicoAvailability, ECicoType, ICicoData } from '../../../shared/component/cico/cico.model';

export enum ELaborAssetViewType {
  LIST_VIEW = 'list_view',
  CARD_VIEW = 'card_view',
}

export interface IAvailableModule {
  laborTracker: boolean;
  assetTracker: boolean;
}

export enum ELaborAssetViewDataType {
  ALL = 'all',
  LABOR = 'labor',
  ASSET = 'asset',
}

export enum ELaborAssetViewWidget {
  TOTAL_CHECK_IN = 'totalCheckIn',
  AVAILABLE = 'available',
  HOURS = 'hours',
  LABOR = 'labor',
  LABOR_HOURS = 'laborHours',
  ASSET = 'asset',
  ASSET_HOURS = 'asset_hours',
  AVAILABLE_SHORT = 'availableShort',
}

export enum EQueryType {
  ONGOING = 'ongoing',
  LAST_24_HOURS = 'lastDay',
}

export interface ILaborAssetViewFilter {
  site: number[];
  lines: -1 | number[];
  selectedLevelId: -1 | number[];
  userIds: -1 | number[];
  userTypes: -1 | number[];
  assetIds: -1 | number[];
  timeToggle: EQueryType;
  typeToggle: ELaborAssetViewDataType;
  viewTypeToggle: ELaborAssetViewType;
}

export interface ILaborAssetViewTableData extends ILaborAssetViewData {
  id: number;
  checkInTime: string;
  checkOutTime: string | null;
  duration: string;
  level: string;
  status: ECicoAvailability;
  statusName?: string;
  type: ECicoType;
  selected?: boolean;
  disabled?: boolean;
}

export interface IActiveModules {
  laborTracker: boolean;
  assetTracker: boolean;
  oeeTracker: boolean;
}

export enum ECheckInOperationTypes {
  CHECK_OUT = 'checkOut',
  UPDATE_STATUS = 'updateAvailabilityStatus',
}

export enum EGroupBy {
  STATION = 'stationId',
  LINE = 'lineId',
  LINE_PATH = 'linePathId',
  DEPARTMENT = 'lineTypeId',
  WORK_ORDER = 'workOrderId',
  PRODUCT = 'productTableId',
  JOB = 'jobId',
}

export interface GroupByDropdownOption {
  id: EGroupBy;
  name: string;
  titleValue: string;
  link?: string | null;
  linkId?: string | null;
}

export interface IGroupData {
  titleValue: string;
  link: string;
  value: ICicoData[];
  info: IGroupedDataInfo;
}

export interface IGroupedDataInfo {
  totalCheckIns: string;
  availableLaborAsset: string;
  laborAssetHours: string;
}

export interface IGroupedData {
  [key: string]: IGroupData;
}
