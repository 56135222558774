<div [ngClass]="['mini-metrics', size, style]">
  <div *ngFor="let temperatureReading of temperatureReadings" class="metric-details">
    <i class="fas fa-thermometer-half metric-icon"></i>
    <span class="p-l-5">{{ temperatureReading.normalizationMessage ?? temperatureReading.value ?? '--' }}</span>
    <span class="metric-symbol">°{{ temperatureReading.scale }}</span>
  </div>
  <div *ngFor="let humidityReading of humidityReadings" class="metric-details">
    <i class="fas fa-tint metric-icon"></i>
    <span class="p-l-5">{{ humidityReading.normalizationMessage ?? humidityReading.value ?? '--' }}</span>
    <span class="metric-symbol">%RH</span>
  </div>
</div>
