import { Component, EventEmitter, Input, Output } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({ selector: 'app-base-modal', standalone: true, templateUrl: './base-modal.component.html' })
export class BaseModalComponent {
  @Input() modal: NgbActiveModal | undefined;

  @Output() onClose: EventEmitter<void> = new EventEmitter<void>();

  public onCloseButtonClicked(): void {
    this.onClose.emit();
    this.modal?.dismiss();
  }
}
