import { Injectable } from '@angular/core';
import { ComponentStore } from '@ngrx/component-store';
import { Observable, Subscription } from 'rxjs';
import { map } from 'rxjs/operators';
import { TasksChartsConstants } from './tasks-charts.constants';
import { ChartHeightLevel } from './tasks-charts.model';

export interface TasksChartsState {
  activityBarCount: number;
  activityChartHeight: number;
  equipmentBarCount: number;
  equipmentChartHeight: number;
  topChartsHeightLevel: ChartHeightLevel;
  topChartsMaxAllowedHeight: number;
}

@Injectable()
export class TasksChartsStore extends ComponentStore<TasksChartsState> {
  private static readonly topChartsVerticalPadding: number =
    TasksChartsConstants.topChartsTopPadding + TasksChartsConstants.topChartsBottomPadding;
  private static readonly topChartsInitialHeightLevel: ChartHeightLevel = TasksChartsConstants.topChartsMinHeightLevel;

  public readonly activityChartHeight$: Observable<number> = this.select(
    (state: TasksChartsState) => state.activityChartHeight,
  );
  public readonly equipmentChartHeight$: Observable<number> = this.select(
    (state: TasksChartsState) => state.equipmentChartHeight,
  );
  public readonly topChartsMaxAllowedHeight$: Observable<number> = this.select(
    (state: TasksChartsState) => state.topChartsMaxAllowedHeight,
  );
  public readonly allowedActions$: Observable<{ decrement: boolean; increment: boolean }> = this.select(
    (state: TasksChartsState) => ({
      decrement: state.topChartsHeightLevel !== TasksChartsConstants.topChartsMinHeightLevel,
      increment:
        state.topChartsHeightLevel !== TasksChartsConstants.topChartsMaxHeightLevel &&
        Math.max(state.activityChartHeight, state.equipmentChartHeight) > state.topChartsMaxAllowedHeight,
    }),
  );
  public readonly heightLevelsNotNeeded$: Observable<boolean> = this.allowedActions$.pipe(
    map(({ decrement, increment }) => !decrement && !increment),
  );

  public setActivityBarCount = this.updater<number>(
    (state: TasksChartsState, activityBarCount: number): TasksChartsState =>
      TasksChartsStore.reduceState({
        ...state,
        topChartsHeightLevel: TasksChartsStore.topChartsInitialHeightLevel,
        activityBarCount,
      }),
  );

  public setEquipmentBarCount = this.updater<number>(
    (state: TasksChartsState, equipmentBarCount: number): TasksChartsState =>
      TasksChartsStore.reduceState({
        ...state,
        equipmentBarCount,
      }),
  );

  private readonly setTopChartsHeightLevel = this.updater<(current: ChartHeightLevel) => number>(
    (state: TasksChartsState, updateLevel): TasksChartsState =>
      TasksChartsStore.reduceState({
        ...state,
        topChartsHeightLevel: TasksChartsStore.clamp(
          updateLevel(state.topChartsHeightLevel) as ChartHeightLevel,
          TasksChartsConstants.topChartsMinHeightLevel,
          TasksChartsConstants.topChartsMaxHeightLevel,
        ),
      }),
  );

  constructor() {
    super(
      TasksChartsStore.reduceState({
        activityBarCount: TasksChartsConstants.topChartsVisibleBars,
        equipmentBarCount: TasksChartsConstants.topChartsVisibleBars,
        topChartsHeightLevel: TasksChartsStore.topChartsInitialHeightLevel,
      }),
    );
  }

  private static clamp<T extends number>(value: T, min: T, max: T): T {
    return Math.min(Math.max(value, min), max) as T;
  }

  private static getTopChartsHeight(barCount: number): number {
    return barCount * TasksChartsConstants.topChartsBarHeight + TasksChartsStore.topChartsVerticalPadding;
  }

  private static getTopChartsMaxAllowedHeight(heightLevel: ChartHeightLevel): number {
    return this.getTopChartsHeight(
      TasksChartsConstants.topChartsVisibleBars + TasksChartsConstants.topChartsAdditionalBarsPerLevel * heightLevel,
    );
  }

  private static reduceState(
    state: Pick<TasksChartsState, 'activityBarCount' | 'equipmentBarCount' | 'topChartsHeightLevel'>,
  ): TasksChartsState {
    const maxBarCount: number = Math.max(state.activityBarCount, state.equipmentBarCount);
    const maxChartHeight: number = TasksChartsStore.getTopChartsHeight(maxBarCount);
    const topChartsMaxAllowedHeight: number = TasksChartsStore.getTopChartsMaxAllowedHeight(state.topChartsHeightLevel);
    const calculatedActivityChartHeight: number = TasksChartsStore.getTopChartsHeight(state.activityBarCount);
    const calculatedEquipmentChartHeight: number = TasksChartsStore.getTopChartsHeight(state.equipmentBarCount);

    const activityChartHeight: number = TasksChartsStore.getChartHeight(
      calculatedActivityChartHeight,
      topChartsMaxAllowedHeight,
      maxChartHeight,
    );
    const equipmentChartHeight: number = TasksChartsStore.getChartHeight(
      calculatedEquipmentChartHeight,
      topChartsMaxAllowedHeight,
      maxChartHeight,
    );

    return {
      ...state,
      activityChartHeight: Math.max(activityChartHeight, TasksChartsConstants.minChartHeight),
      equipmentChartHeight: Math.max(equipmentChartHeight, TasksChartsConstants.minChartHeight),
      topChartsMaxAllowedHeight,
    };
  }

  private static getChartHeight(
    calculatedChartHeight: number,
    topChartsMaxAllowedHeight: number,
    maxChartHeight: number,
  ): number {
    if (calculatedChartHeight < topChartsMaxAllowedHeight && maxChartHeight > topChartsMaxAllowedHeight) {
      return topChartsMaxAllowedHeight;
    }

    if (calculatedChartHeight >= topChartsMaxAllowedHeight) {
      return calculatedChartHeight;
    }

    return maxChartHeight;
  }

  public decrementTopChartsHeightLevel(): Subscription {
    return this.setTopChartsHeightLevel((current) => current - 1);
  }

  public incrementTopChartsHeightLevel(): Subscription {
    return this.setTopChartsHeightLevel((current) => current + 1);
  }
}
