<div class="modal-header scw-modal-header">
  <h4 class="modal-title scw-header">
    {{ 'productSpeedInfoModal.title' | translate }}
  </h4>
  <button type="button" class="close" aria-label="Close" onfocus="blur()" (click)="closeModal()">
    <i aria-hidden class="fas fa-times"></i>
  </button>
</div>

<div class="modal-body">
  <div class="container p-0">
    <div class="row mb-4">
      <div class="col-12">
        <div class="modal-note">
          <p class="m-0">{{ 'productSpeedInfoModal.info' | translate }}</p>
        </div>
      </div>
    </div>

    <div class="container p-0">
      <div class="row">
        <div class="col-1"><div class="arrow-container"></div></div>
        <div class="col-11">
          <div
            class="row speed-row"
            *ngFor="let speedName of speedNamesInOrder; let isFirst = first; let isLast = last"
          >
            <div class="col-5 speed-name-box" [ngClass]="{ selected: selectedSpeedName === speedName }">
              {{ 'productSpeedInfoModal.' + speedName | translate }}
            </div>
            <div class="col-7 speed-description-box">
              <ng-container *ngIf="isFirst; else notFirst">
                <p
                  [innerHTML]="
                    'productSpeedInfoModal.firstUsedSpeed'
                      | translate : { speedName: 'productSpeedInfoModal.' + speedName | translate }
                  "
                ></p>
              </ng-container>
              <ng-template #notFirst>
                <ng-container *ngIf="isLast; else middle">
                  <p
                    [innerHTML]="
                      'productSpeedInfoModal.finalUsedSpeed'
                        | translate : { speedName: 'productSpeedInfoModal.' + speedName | translate }
                    "
                  ></p>
                </ng-container>
                <ng-template #middle>
                  <p
                    [innerHTML]="
                      'productSpeedInfoModal.usedSpeed'
                        | translate : { speedName: 'productSpeedInfoModal.' + speedName | translate }
                    "
                  ></p>
                </ng-template>
              </ng-template>
              <p class="description-note" *ngIf="!isLast">
                {{ 'productSpeedInfoModal.ifValueNotAvailable' | translate }}
                <i class="fa fa-level-down" aria-hidden="true"></i>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="modal-footer">
  <div class="buttons-right">
    <scw-mat-button type="standard" (onClick)="closeModal()">
      {{ 'general.close' | translate }}
    </scw-mat-button>
  </div>
</div>
