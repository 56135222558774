import { Action } from '@ngrx/store';
import {
  IGroupByWeekResponse,
  IOeeAdherenceFilters,
  IWoAdherenceOeeWithinIntervalResponse,
} from './oee-adherence.model';

export const OEE_ADHERENCE_WEEK_DATA_LOADING = '[OEE ADHERENCE] OEE ADHERENCE WEEK DATA LOADING';
export const OEE_ADHERENCE_WO_DATA_LOADING = '[OEE ADHERENCE] OEE ADHERENCE WO DATA LOADING';
export const OEE_ADHERENCE_WEEK_DATA_LOADED = '[OEE ADHERENCE] OEE ADHERENCE WEEK DATA LOADED';
export const OEE_ADHERENCE_WO_DATA_LOADED = '[OEE ADHERENCE] OEE ADHERENCE WO DATA LOADED';
export const OEE_ADHERENCE_WO_EXCEL_DATA_LOADED = '[OEE ADHERENCE WO EXCEL LOADED]';
export const WEEK_DATA_FETCH_ERROR = '[OEE ADHERENCE WEEK DATA] FETCH ERROR';
export const WO_DATA_FETCH_ERROR = '[OEE ADHERENCE WO DATA] FETCH ERROR';

export class OeeAdherenceWeekDataLoading implements Action {
  public readonly type = OEE_ADHERENCE_WEEK_DATA_LOADING;

  constructor(public params: IOeeAdherenceFilters) {}
}

export class OeeAdherenceWoDataLoading implements Action {
  public readonly type = OEE_ADHERENCE_WO_DATA_LOADING;

  constructor(public params: IOeeAdherenceFilters) {}
}

export class OeeAdherenceWeekDataLoaded implements Action {
  public readonly type = OEE_ADHERENCE_WEEK_DATA_LOADED;

  constructor(public payload: IGroupByWeekResponse) {}
}

export class OeeAdherenceWoDataLoaded implements Action {
  public readonly type = OEE_ADHERENCE_WO_DATA_LOADED;

  constructor(public payload: IWoAdherenceOeeWithinIntervalResponse[]) {}
}

export class OeeAdherenceWoExcelLoaded implements Action {
  public readonly type = OEE_ADHERENCE_WO_EXCEL_DATA_LOADED;
}

export class WeekDataFetchError implements Action {
  public readonly type = WEEK_DATA_FETCH_ERROR;

  constructor(public payload: object) {}
}
export class WoDataFetchError implements Action {
  public readonly type = WO_DATA_FETCH_ERROR;

  constructor(public payload: object) {}
}

export type OeeAdherenceActions =
  | OeeAdherenceWeekDataLoading
  | OeeAdherenceWoDataLoading
  | OeeAdherenceWeekDataLoaded
  | OeeAdherenceWoDataLoaded
  | OeeAdherenceWoExcelLoaded
  | WeekDataFetchError
  | WoDataFetchError;
