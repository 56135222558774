import { Action } from '@ngrx/store';
import { GenericCrudRequestConstructionParameters } from '../../../shared/model/interface/generic-api-request.model';
import {
  BaseOneResponseInterface,
  BulkResponseDataInterface,
  GetManyResponseInterface,
} from '../../../shared/model/interface/crud-response-interface.model';
import {
  NotificationScopeCRUDInterface,
  NotificationScopeCRUDRequestInterface,
  NotificationScopeFormDropdownData,
  SimplifiedSiteCRUDInterface,
} from './notification-scope.model';
import { HttpErrorResponse } from '@angular/common/http';

export enum NotificationScopeActionTypes {
  LoadNotificationScopes = '[NotificationScope] Load NotificationScopes',
  NotificationScopesLoaded = '[NotificationScope] NotificationScopes Loaded',
  LoadFormDropdownData = '[NotificationScope] Load Form Dropdown Data',
  FormDropdownDataLoaded = '[NotificationScope] Form Dropdown Loaded',
  CreateNotificationScope = '[NotificationScope] Create NotificationScope',
  CreateNotificationScopeCompleted = '[NotificationScope] Create NotificationScope Completed',
  UpdateNotificationScope = '[NotificationScope] Update NotificationScope',
  UpdateNotificationScopeCompleted = '[NotificationScope] Update NotificationScope Completed',
  DeleteNotificationScope = '[NotificationScope] Delete NotificationScope',
  DeleteNotificationScopeCompleted = '[NotificationScope] Delete NotificationScope Completed',
  BulkDeleteNotificationScope = '[NotificationScope] Bulk Delete NotificationScope',
  BulkDeleteNotificationScopeCompleted = '[NotificationScope] Bulk Delete NotificationScope Completed',
  FetchError = '[NotificationScope] Fetch Error',
  ShiftAndTemplateDataLoading = '[NotificationScope] Shift and Template Data Loading',
  ShiftAndTemplateDataLoaded = '[NotificationScope] Shift and Template Data Loaded',
}

export class LoadNotificationScopes implements Action {
  readonly type = NotificationScopeActionTypes.LoadNotificationScopes;
  constructor(public parameters: GenericCrudRequestConstructionParameters) {}
}

export class NotificationScopesLoaded implements Action {
  readonly type = NotificationScopeActionTypes.NotificationScopesLoaded;
  constructor(public payload: GetManyResponseInterface<NotificationScopeCRUDInterface>) {}
}

export class LoadFormDropdownData implements Action {
  readonly type = NotificationScopeActionTypes.LoadFormDropdownData;
}

export class FormDropdownDataLoaded implements Action {
  readonly type = NotificationScopeActionTypes.FormDropdownDataLoaded;
  constructor(public payload: NotificationScopeFormDropdownData) {}
}

export class CreateNotificationScope implements Action {
  readonly type = NotificationScopeActionTypes.CreateNotificationScope;
  constructor(public requestParameters: NotificationScopeCRUDRequestInterface) {}
}

export class CreateNotificationScopeCompleted implements Action {
  readonly type = NotificationScopeActionTypes.CreateNotificationScopeCompleted;
  constructor(public payload: BaseOneResponseInterface<NotificationScopeCRUDInterface>) {}
}

export class UpdateNotificationScope implements Action {
  readonly type = NotificationScopeActionTypes.UpdateNotificationScope;
  constructor(public id: number, public requestParameters: NotificationScopeCRUDRequestInterface) {}
}

export class UpdateNotificationScopeCompleted implements Action {
  readonly type = NotificationScopeActionTypes.UpdateNotificationScopeCompleted;
  constructor(public payload: BaseOneResponseInterface<NotificationScopeCRUDInterface>) {}
}

export class DeleteNotificationScope implements Action {
  readonly type = NotificationScopeActionTypes.DeleteNotificationScope;
  constructor(public id: number) {}
}

export class DeleteNotificationScopeCompleted implements Action {
  readonly type = NotificationScopeActionTypes.DeleteNotificationScopeCompleted;
}

export class BulkDeleteNotificationScope implements Action {
  readonly type = NotificationScopeActionTypes.BulkDeleteNotificationScope;
  constructor(public ids: number[]) {}
}

export class BulkDeleteNotificationScopeCompleted implements Action {
  readonly type = NotificationScopeActionTypes.BulkDeleteNotificationScopeCompleted;
  constructor(public payload: BulkResponseDataInterface) {}
}

export class ShiftAndTemplateDataLoading implements Action {
  readonly type = NotificationScopeActionTypes.ShiftAndTemplateDataLoading;
  constructor(public siteId: number) {}
}

export class ShiftAndTemplateDataLoaded implements Action {
  readonly type = NotificationScopeActionTypes.ShiftAndTemplateDataLoaded;
  constructor(public payload: BaseOneResponseInterface<SimplifiedSiteCRUDInterface>) {}
}

export class FetchError implements Action {
  readonly type = NotificationScopeActionTypes.FetchError;

  constructor(public payload: HttpErrorResponse) {}
}

export type NotificationScopeActions =
  | LoadNotificationScopes
  | NotificationScopesLoaded
  | LoadFormDropdownData
  | FormDropdownDataLoaded
  | CreateNotificationScope
  | CreateNotificationScopeCompleted
  | UpdateNotificationScope
  | UpdateNotificationScopeCompleted
  | DeleteNotificationScope
  | DeleteNotificationScopeCompleted
  | BulkDeleteNotificationScope
  | BulkDeleteNotificationScopeCompleted
  | ShiftAndTemplateDataLoading
  | ShiftAndTemplateDataLoaded;
