import { Action } from '@ngrx/store';
import {
  IOpcMessageDataResponseInterface,
  IOpcMessageInspectionActivityLogsInterface,
  IOpcMessageInspectionFilter,
  IOpcMessageInspectionTableData,
} from '../../../view/reports/opc-message-inspection/opc-message-inspection.model';
import { ActivityLogsResponseInterface } from '../root-cause-analysis/root-cause-analysis.model';

export const OPC_MESSAGE_INSPECTION_FETCH_DATA_LOADING =
  '[OPC_MESSAGE_INSPECTION] OPC MESSAGE INSPECTION FETCH DATA LOADING';
export const OPC_MESSAGE_INSPECTION_FETCH_DATA_LOADED =
  '[OPC_MESSAGE_INSPECTION] OPC MESSAGE INSPECTION FETCH DATA LOADED';
export const OPC_MESSAGE_INSPECTION_ACTIVITY_LOGS_LOADING =
  '[OPC_MESSAGE_INSPECTION] OPC MESSAGE INSPECTION ACTIVITY LOGS LOADING';
export const OPC_MESSAGE_INSPECTION_ACTIVITY_LOGS_LOADED =
  '[OPC_MESSAGE_INSPECTION] OPC MESSAGE INSPECTION ACTIVITY LOGS LOADED';
export const OPC_MESSAGE_INSPECTION_FETCH_ERROR = '[OPC_MESSAGE_INSPECTION] OPC MESSAGE INSPECTION FETCH ERROR';
export const OPC_MESSAGE_INSPECTION_CLEAR_STATE = '[OPC_MESSAGE_INSPECTION] OPC MESSAGE INSPECTION CLEAR STATE';
export const OPC_MESSAGE_INSPECTION_DOWNLOAD_EXCEL = '[OPC_MESSAGE_INSPECTION] DOWNLOAD EXCEL';
export const OPC_MESSAGE_INSPECTION_DOWNLOAD_EXCEL_COMPLETED = '[OPC_MESSAGE_INSPECTION] DOWNLOAD EXCEL COMPLETED';

export class OpcMessageInspectionFetchDataLoading implements Action {
  readonly type = OPC_MESSAGE_INSPECTION_FETCH_DATA_LOADING;

  constructor(public tableQuery: IOpcMessageInspectionFilter) {}
}

export class OpcMessageInspectionFetchDataLoaded implements Action {
  readonly type = OPC_MESSAGE_INSPECTION_FETCH_DATA_LOADED;

  constructor(public payload: IOpcMessageDataResponseInterface) {}
}

export class OpcMessageInspectionActivityLogsLoading implements Action {
  readonly type = OPC_MESSAGE_INSPECTION_ACTIVITY_LOGS_LOADING;

  constructor(public params: IOpcMessageInspectionActivityLogsInterface) {}
}

export class OpcMessageInspectionActivityLogsLoaded implements Action {
  readonly type = OPC_MESSAGE_INSPECTION_ACTIVITY_LOGS_LOADED;

  constructor(public payload: ActivityLogsResponseInterface) {}
}

export class OpcMessageInspectionFetchError implements Action {
  readonly type = OPC_MESSAGE_INSPECTION_FETCH_ERROR;

  constructor(public payload: any[]) {}
}

export class OpcMessageInspectionClearState implements Action {
  readonly type = OPC_MESSAGE_INSPECTION_CLEAR_STATE;
}

export class DownloadOpcMessageInspectionExcel implements Action {
  readonly type = OPC_MESSAGE_INSPECTION_DOWNLOAD_EXCEL;

  constructor(public data: IOpcMessageInspectionTableData[]) {}
}

export class DownloadOpcMessageInspectionExcelCompleted implements Action {
  readonly type = OPC_MESSAGE_INSPECTION_DOWNLOAD_EXCEL_COMPLETED;
}

export type OpcMessageInspectionActions =
  | OpcMessageInspectionFetchDataLoading
  | OpcMessageInspectionFetchDataLoaded
  | OpcMessageInspectionActivityLogsLoading
  | OpcMessageInspectionActivityLogsLoaded
  | OpcMessageInspectionFetchError
  | OpcMessageInspectionClearState
  | DownloadOpcMessageInspectionExcel
  | DownloadOpcMessageInspectionExcelCompleted;
