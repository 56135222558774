import { Inject, Injectable } from '@angular/core';
import { FilterServiceInterface, FloorPlanCRUDResponseInterface } from './service.class';
import { HttpClient, HttpParams } from '@angular/common/http';
import { FloorPlanCRUDInterface } from '../../component/filter/filter.class';
import { Observable } from 'rxjs';
import { GetManyResponseInterface } from '../../model/interface/crud-response-interface.model';

@Injectable({
  providedIn: 'root',
})
export class FloorPlanService implements FilterServiceInterface {
  constructor(public http: HttpClient, @Inject('API_BASE_URL') private baseUrl: string) {}

  getData(options?: HttpParams): Promise<FloorPlanCRUDInterface[]> {
    return new Promise((resolve, reject): void => {
      this.http
        .get(`${this.baseUrl}/floor-plans`, { params: options })
        .subscribe((response: FloorPlanCRUDResponseInterface): void => {
          if (response.hasOwnProperty('data')) {
            resolve(response.data);
          } else {
            reject();
          }
        });
    });
  }

  public getFloorPlans(params?: HttpParams): Observable<GetManyResponseInterface<FloorPlanCRUDInterface>> {
    return this.http.get<GetManyResponseInterface<FloorPlanCRUDInterface>>(`${this.baseUrl}/floor-plans`, {
      params,
    });
  }

  public getFloorPlan(id: number): Observable<{ id: number; name: string }> {
    return this.http.get<{ id: number; name: string }>(`${this.baseUrl}/floor-plans/${id}?fields=id,name`);
  }
}
