import { ActivityLogsStateInterface } from './activity-logs.model';
import * as Actions from './activity-logs.actions';

export const activityLogState: ActivityLogsStateInterface = {
  activityLogsActivityLogDataLoading: false,
  activityLogsActivityLogDataLoaded: false,
  activityLogData: null,
  filters: null,
  tableSettings: [],
  fileDownloadStatus: false,
  lineData: null,
  lineDataLoading: false,
  lineDataLoaded: false,
};

export function activityLogsReducer(
  state: ActivityLogsStateInterface = activityLogState,
  action: Actions.ActivityLogsActions,
): ActivityLogsStateInterface {
  switch (action.type) {
    case Actions.ACTIVITY_LOGS_DATA_LOADING:
      return {
        ...state,
        ...{
          activityLogsActivityLogDataLoading: true,
          activityLogsActivityLogDataLoaded: false,
        },
      };
    case Actions.ACTIVITY_LOGS_DATA_LOADED:
      return {
        ...state,
        ...{
          activityLogData: action.payload,
          activityLogsActivityLogDataLoading: false,
          activityLogsActivityLogDataLoaded: true,
        },
      };
    case Actions.SET_TABLE_SETTINGS:
      return {
        ...state,
        tableSettings: action.payload,
      };
    case Actions.SET_SELECTED_FILTERS:
      return {
        ...state,
        filters: action.filters,
      };
    case Actions.ACTIVITY_LOGS_DOWNLOAD_EXCEL:
      return {
        ...state,
        ...{
          fileDownloadStatus: true,
        },
      };
    case Actions.ACTIVITY_LOGS_DOWNLOAD_EXCEL_COMPLETED:
      return {
        ...state,
        ...{
          fileDownloadStatus: false,
        },
      };
    case Actions.GET_LINES:
      return {
        ...state,
        ...{
          lineDataLoading: true,
          lineDataLoaded: false,
        },
      };
    case Actions.GET_LINES_COMPLETED:
      return {
        ...state,
        ...{
          lineData: action.payload,
          lineDataLoading: false,
          lineDataLoaded: true,
        },
      };
    case Actions.FETCH_ERROR:
      return {
        ...state,
        activityLogsActivityLogDataLoaded: false,
        fileDownloadStatus: false,
      };
    default:
      return state;
  }
}
