import { Action } from '@ngrx/store';
import { ITaskTreeNode, ITaskTreeNodeForChart } from './equipment-task.model';
import { IRootAddress } from '../settings/tree-nodes/tree-nodes.model';
import { ETaskListMode } from '../../shared/component/activity-buttons/task-selection/task-selection.model';

export enum EquipmentTaskActionTypes {
  NodeDataLoading = '[EQUIPMENT_TASK] TASK SELECTION DATA LOADING',
  NodeDataLoaded = '[EQUIPMENT_TASK] TASK SELECTION DATA LOADED',
  FetchError = '[EQUIPMENT_TASK] Fetch Error',
}

export class NodeDataLoad implements Action {
  readonly type = EquipmentTaskActionTypes.NodeDataLoading;

  constructor(
    public rootAddress: IRootAddress,
    public mode: ETaskListMode,
    public equipmentId?: number | null,
    public search?: string,
    public taskTreeParentId?: number,
    public skipSingleOption: boolean = false,
    public excludeHiddenFromHome: boolean = false,
  ) {}
}

export class NodeDataLoaded implements Action {
  readonly type = EquipmentTaskActionTypes.NodeDataLoaded;

  constructor(
    public payload: ITaskTreeNodeForChart[],
    public breadcrumb: ITaskTreeNode[],
    public lastLoadedEquipment?: number | null,
  ) {}
}

export class FetchError implements Action {
  readonly type = EquipmentTaskActionTypes.FetchError;

  constructor(public payload: any) {}
}

export type EquipmentTaskActions = NodeDataLoad | NodeDataLoaded | FetchError;
