<div class="page-body" id="checkinTimelineComponentBody">
  <div class="row">
    <div class="col-lg-12">
      <filter-card
        [hidden]="!isFilterBarVisible$"
        [options]="filterOption"
        [mainGridWide]="12"
        [defaultDropdownSelectionSubject]="defaultDropdownSelectionSubject"
        [strictControlForSelected]="true"
        [additionalGridWide]="0"
        [autoApply]="true"
        [enableSetAsDefault]="true"
        [hiddenElementIds]="hiddenElementIds"
        (filterChange)="onFiltersChanged($event)"
        (setAsDefault)="onFilterSetAsDefault()"
        [validUpdateMessage]="filterCardValidUpdateMessage"
      ></filter-card>
    </div>
    <div class="col-lg-12">
      <div class="card">
        <div class="card-block">
          <div class="scheduler-top-bar m-b-20">
            <div class="scheduler-filter row">
              <div class="col-xl-12 col-lg-12 col-md-12">
                <div id="left-button-group" class="d-flex justify-content-between">
                  <scw-mat-button-group
                    *ngIf="selectedLineIds?.length"
                    [buttons]="chartButtonGroup"
                    [(inputModel)]="chartMode"
                    (inputModelChange)="changeButtonGroup($event)"
                  ></scw-mat-button-group>
                  <div class="btn-group ml-auto" role="group">
                    <scw-mat-button [type]="'standard'" class="zoom-left" [iconButton]="true" (click)="zoomIn()">
                      <em class="fa fa-search-plus"></em>
                    </scw-mat-button>
                    <scw-mat-button [type]="'standard'" class="zoom-right" [iconButton]="true" (click)="zoomOut()">
                      <em class="fa fa-search-minus"></em>
                    </scw-mat-button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-12">
              <div class="bryntum-scheduler-with-progress">
                <div #checkinTimelineSchedulerRef class="bry-scheduler"></div>
                <div class="b-float-root"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
