import {
  AfterViewInit,
  Component,
  EventEmitter,
  Input,
  Output,
  SimpleChanges,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { EKpiCardContentType, ETag, IHeader, IScwMatKpiCard, ITagMessage, ITemplates } from './scw-mat-kpi-card.model';
import { ComponentUtilities } from '../../../helper/component-utilities';
import { TranslateService } from '@ngx-translate/core';
import { HelperService } from '../../../service/helper.service';

@Component({
  selector: 'scw-mat-kpi-card',
  templateUrl: './scw-mat-kpi-card.component.html',
  styleUrls: ['./scw-mat-kpi-card.component.scss'],
})
export class ScwMatKpiCardComponent implements AfterViewInit {
  @ViewChild('progressBar') progressBar!: TemplateRef<any>;
  @ViewChild('selectBox') selectBox!: TemplateRef<any>;
  @ViewChild('text') text!: TemplateRef<any>;
  @ViewChild('icon') icon!: TemplateRef<any>;
  @ViewChild('warningMessage') warningMessage!: TemplateRef<any>;
  @Input() configuration: IScwMatKpiCard = null;
  @Input() disabled: boolean = false;
  @Input() loading: boolean = false;
  @Output() inputModelChange: EventEmitter<any> = new EventEmitter<any>();
  @Output() onClick: EventEmitter<any> = new EventEmitter<{ event: Event; widget: IScwMatKpiCard }>();
  private viewInitialized: boolean = false;
  public readonly trackByItem = ComponentUtilities.genericTrackByItemFunction;
  public templates: ITemplates = null;
  public kpiCard: IScwMatKpiCard = null;
  public tagMessage: ITagMessage = {
    soon: this.translate.instant('general.comingSoon'),
    new: this.translate.instant('general.new'),
  };

  constructor(private readonly translate: TranslateService) {}

  public ngAfterViewInit(): void {
    this.templates = {
      progressBar: this.progressBar,
      selectBox: this.selectBox,
      text: this.text,
      icon: this.icon,
      warningMessage: this.warningMessage,
    };

    setTimeout(() => {
      this.updateKpiCard();
      this.viewInitialized = true;
    });
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if (
      this.viewInitialized &&
      'configuration' in changes &&
      changes['configuration'].currentValue !== changes['configuration'].previousValue
    ) {
      this.updateKpiCard();
    }
  }

  public selectBoxInputModelChange(event): void {
    this.inputModelChange.emit(event);
  }

  public onClickItem(event): void {
    this.onClick.emit({ event: event, widget: this.kpiCard });
  }

  private updateKpiCard(): void {
    this.kpiCard = this.setDisplayRenderProperties(this.configuration);
  }

  private setDisplayRenderProperties(configuration: IScwMatKpiCard): IScwMatKpiCard {
    const updatedConfiguration: IScwMatKpiCard = HelperService.cloneDeep(configuration);

    if (configuration?.displayRenderProperties === undefined) {
      return updatedConfiguration;
    }

    if (configuration.displayRenderProperties?.tag) {
      this.setTag(updatedConfiguration);
    }

    if (configuration.displayRenderProperties?.pageConfigurationMode) {
      this.setPageConfigurationMode(updatedConfiguration);
    }

    return updatedConfiguration;
  }

  private setTag(updatedConfiguration: IScwMatKpiCard): void {
    const warningMessage: IHeader = {
      type: EKpiCardContentType.WARNING_MESSAGE,
      value: this.tagMessage[updatedConfiguration?.displayRenderProperties?.tag],
    };

    updatedConfiguration.header.splice(2, 0, warningMessage);
    updatedConfiguration.disabled = updatedConfiguration.displayRenderProperties?.tag === ETag.SOON;
  }

  private setPageConfigurationMode(updatedConfiguration: IScwMatKpiCard): void {
    updatedConfiguration.body = [
      {
        type: EKpiCardContentType.ICON,
        value: updatedConfiguration.disabled ? 'fa-eye-slash fas' : 'fa-eye fas',
        style: {
          color: updatedConfiguration?.body[0]?.style?.color,
        },
      },
    ];
  }
}
