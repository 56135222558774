import { InterfaceCustomMailGroupInterface } from '../../store/main/main.model';
import { SearchOptionInterface } from '../../view/home/cico/cico.model';

export interface CallSupport {
  to: string;
  textSubject: string;
  textBody: string;
  contactType: string;
  mail: boolean;
  sms: boolean;
  user: User[];
  total: number;
  character: number;
  errors: {
    sentType: string,
  };
  additionalData: InterfaceCustomMailGroupInterface | null;
  from: SearchOptionInterface[];
}

interface User {
  details: string;
  fullName: string;
  id: number;
  isActive: number;
  timeZone: string;
  userLanguage: string;
  userName: string;
}

export enum ESensorAndOPCButtonStyle {
  OPERATIONAL = 'status-operational',
  FAILURE = 'status-failure',
  UNKNOWN = 'status-unknown',
}
export const lineVisibleUrls = [
  'activity-history',
  'activity-review',
  'home',
  'home/station/:id',
  'line-observation',
  'production-review',
] as const;

export type TLineVisibleUrls = (typeof lineVisibleUrls)[number];

export interface DropdownOptionsInterface {
  id: string;
  name: string;
}
