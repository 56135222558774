import { Inject, Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { forkJoin, Observable, Subject } from 'rxjs';
import {
  BaseCrudResponse,
  BaseOneResponseInterface,
  BulkResponseDataInterface,
  GetManyResponseInterface,
} from '../../../model/interface/crud-response-interface.model';
import {
  IDownloadExcelOptions,
  IEquipment,
  IEquipmentsExcelData,
} from '../../../../store/settings/equipments/equipments.model';
import {
  CellTypes,
  CreateExcelInterface,
  CreateExcelSheetInterface,
  ExcelColumnWidthEnum,
  ExcelHelperService,
  ExcelSheetTypeEnum,
} from '../../excel/excel-helper.service';
import { Store } from '@ngrx/store';
import * as oeeAppReducer from '../../../../store/oee.reducer';
import { TranslateService } from '@ngx-translate/core';
import { shareReplay, takeUntil } from 'rxjs/operators';
import { excelDateFormat, excelTimeFormat } from '../../../model/enum/excel-date-format';
import * as _ from 'lodash';
import moment from 'moment';
import {
  EquipmentListBulkSaveManyInterface,
  EquipmentListsDownloadExcelFiltersInterface,
} from '../../../../store/settings/equipment-lists/equipment-lists.model';
import * as ObjectActions from '../../../../store/settings/equipments/equipments.actions';
import { ValueType, Workbook, Worksheet } from 'exceljs';
import { SiteService } from '../../filter/site.service';
import { EquipmentTypesService } from '../equipment-types/equipment-types.service';
import { EquipmentBrandsService } from '../equipment-brands/equipment-brands.service';
import { IEquipmentBrand } from '../../../../store/settings/equipment-brands/equipment-brands.model';
import { IEquipmentType } from '../../../../store/settings/equipment-types/equipment-types.model';
import { MultipleCheckInsEnum } from '../../../../../constants';
import { ImageHelperService } from '../../../helper/image-helper.service';
import { EquipmentAvatarCacheService } from '../../../../store/settings/equipments/equipment-avatar-cache.service';
import { TCacheOptions } from '../../cache-service';

@Injectable({
  providedIn: 'root',
})
export class EquipmentsService {
  private EQUIPMENTS_BASE_URL: string = 'equipment-lists';
  private EQUIPMENTS = {
    GET: {
      GET_ALL_EQUIPMENTS: `${this.baseUrl}/${this.EQUIPMENTS_BASE_URL}`,
      GET_AVATAR: `${this.baseUrl}/${this.EQUIPMENTS_BASE_URL}/avatar`,
    },
    DELETE: {
      DELETE_EQUIPMENT: `${this.baseUrl}/${this.EQUIPMENTS_BASE_URL}`,
    },
    POST: {
      CREATE_EQUIPMENT: `${this.baseUrl}/${this.EQUIPMENTS_BASE_URL}`,
    },
    PATCH: {
      EDIT_EQUIPMENT: `${this.baseUrl}/${this.EQUIPMENTS_BASE_URL}`,
      AVATAR: `${this.baseUrl}/${this.EQUIPMENTS_BASE_URL}/avatar`,
    },
    BULK: {
      DELETE: `${this.baseUrl}/${this.EQUIPMENTS_BASE_URL}/bulk/delete`,
      SAVE: `${this.baseUrl}/${this.EQUIPMENTS_BASE_URL}/bulk/save`,
      EDIT: `${this.baseUrl}/${this.EQUIPMENTS_BASE_URL}/bulk/edit`,
    },
  };

  private timezone: string = 'utc';
  private dateFormat$: string;
  private timeFormat$: string;
  private readonly destroySubject: Subject<boolean> = new Subject<boolean>();
  private dateCharacterFormat$: string;
  private readonly shareReplayBufferLifeTime: number = 61000;

  constructor(
    public http: HttpClient,
    @Inject('API_BASE_URL')
    private readonly baseUrl: string,
    private readonly excelHelper: ExcelHelperService,
    private readonly store: Store<oeeAppReducer.OeeAppState>,
    private readonly translate: TranslateService,
    private readonly siteService: SiteService,
    private readonly equipmentTypesService: EquipmentTypesService,
    private readonly equipmentBrandsService: EquipmentBrandsService,
    private readonly imageHelperService: ImageHelperService,
    private readonly equipmentAvatarCacheService: EquipmentAvatarCacheService,
  ) {
    this.store
      .select('user')
      .pipe(takeUntil(this.destroySubject))
      .subscribe((state) => {
        if (state.isUserLoaded) {
          this.timezone = state.timezone;
          if (state.locale !== '') {
            this.dateCharacterFormat$ = state.dateFormat;
            this.dateFormat$ = excelDateFormat[state.locale !== 'ja' ? state.locale : state.locale + state.dateFormat];
            this.timeFormat$ = excelTimeFormat[state.locale];
          }
          this.destroySubject.next(true);
          this.destroySubject.complete();
        }
      });
  }

  public getData(options?: HttpParams): Observable<GetManyResponseInterface<IEquipment>> {
    return this.http.get<GetManyResponseInterface<IEquipment>>(this.EQUIPMENTS.GET.GET_ALL_EQUIPMENTS, {
      params: options,
    });
  }

  public patchEquipmentData(
    siteId: number,
    payload: Partial<IEquipment>,
    params?: HttpParams,
  ): Observable<BaseOneResponseInterface<IEquipment>> {
    return this.http.patch<BaseOneResponseInterface<IEquipment>>(
      `${this.EQUIPMENTS.PATCH.EDIT_EQUIPMENT}/${siteId}`,
      payload,
      { params },
    );
  }

  public patchEquipmentsData(
    payload: Partial<IEquipment>[],
    params?: HttpParams,
  ): Observable<BulkResponseDataInterface> {
    return this.http.patch<BulkResponseDataInterface>(this.EQUIPMENTS.BULK.EDIT, { equipments: payload }, { params });
  }

  public createEquipment(payload: Partial<IEquipment>): Observable<BaseOneResponseInterface<IEquipment>> {
    return this.http.post<BaseOneResponseInterface<IEquipment>>(this.EQUIPMENTS.POST.CREATE_EQUIPMENT, payload);
  }

  public deleteEquipment(equipmentId: number): Observable<GetManyResponseInterface<IEquipment>> {
    return this.http.delete<GetManyResponseInterface<IEquipment>>(
      `${this.EQUIPMENTS.DELETE.DELETE_EQUIPMENT}/${equipmentId}`,
    );
  }

  public deleteEquipments(equipmentIds: number[]): Observable<BulkResponseDataInterface> {
    return this.http.delete<BulkResponseDataInterface>(this.EQUIPMENTS.BULK.DELETE, {
      body: { equipments: equipmentIds },
    });
  }

  public downloadEquipmentExcel(
    filters: EquipmentListsDownloadExcelFiltersInterface,
    data?: IEquipment[],
    options: IDownloadExcelOptions = { withData: false, withErrorColumn: false },
  ): void {
    const httpParams: HttpParams = new HttpParams().set('limit', String(filters.limit));
    const equipmentTypesHttpParams: HttpParams = new HttpParams()
      .set('limit', String(filters.limit))
      .set('s', JSON.stringify({ siteId: { $eq: filters.siteId } }));
    const observables: Observable<any>[] = [
      this.siteService.getSite(filters.siteId),
      this.equipmentBrandsService.getEquipmentBrands(httpParams),
      this.equipmentTypesService.getEquipmentTypes(equipmentTypesHttpParams),
    ];

    if (options.withData && !data) {
      observables.push(
        this.getData(
          httpParams
            .set('s', JSON.stringify({ siteId: { $eq: filters.siteId } }))
            .set('page', filters.selectedDownloadOffset)
            .append('join', 'site||name')
            .append('join', 'equipmentBrandRelation||name')
            .append('join', 'equipmentTypeRelation||description'),
        ),
      );
    }

    forkJoin(observables).subscribe((responseList) => {
      const site = _.get(responseList, '0.data', null);
      let equipmentBrands: IEquipmentBrand[] = _.get(responseList, '1.data', []);
      let equipmentTypes: IEquipmentType[] = _.get(responseList, '2.data', []);
      const sheetTitle = this.translate.instant('excel.items.equipmentLists');
      const excelName: string = `${sheetTitle} ${moment().tz(this.timezone).format(this.dateFormat$)}`;
      let excelData: IEquipment[] = [];
      const booleanDropdownOptions = this.excelHelper.getExcelBooleanDropdownOptions();
      const multipleCheckInsOptions = this.excelHelper.getMultipleCheckInsOptions();

      if (options.withData) {
        excelData = _.get(responseList, '3.data', []);

        if (data) {
          for (const equipment of data) {
            equipment.site = equipment.siteId === site.id ? site : null;
            equipment.equipmentBrandRelation = _.find(equipmentBrands, { id: equipment.equipmentBrand });
            equipment.equipmentTypeRelation = _.find(equipmentTypes, { id: equipment.equipmentType });
          }
          excelData = data;
        }

        equipmentBrands = ExcelHelperService.updateExcelDropdownOptionsWithData<IEquipment, IEquipmentBrand>(
          excelData,
          equipmentBrands,
          'equipmentBrandRelation',
        );
        equipmentTypes = ExcelHelperService.updateExcelDropdownOptionsWithData<IEquipment, IEquipmentType>(
          excelData,
          equipmentTypes,
          'equipmentTypeRelation',
        );
      }

      const excelOptions: CreateExcelInterface = this.getEquipmentListExcelColumns(
        site,
        { equipmentBrands, equipmentTypes },
        options.withErrorColumn,
      );

      if (options.withData) {
        excelOptions.data = excelData.map((item) => {
          return {
            ...item,
            isCheckableOption: !_.isNil(item.isCheckable)
              ? _.find(booleanDropdownOptions, { id: String(item.isCheckable) })
              : null,
            multipleCheckInsOption: !_.isNil(item.multipleCheckIns)
              ? _.find(multipleCheckInsOptions, { id: String(item.multipleCheckIns) })
              : null,
            isCheckable: !_.isNil(item.isCheckable) ? String(item.isCheckable) : null,
            multipleCheckIns: !_.isNil(item.multipleCheckIns) ? String(item.multipleCheckIns) : null,
          };
        });
      }

      const worksheets: CreateExcelSheetInterface[] = [
        {
          sheetTitle,
          sheetType: ExcelSheetTypeEnum.TABLE,
          params: excelOptions,
          withData: options.withData,
          isDisabledColumnsFirstLine: true,
        },
      ];

      this.excelHelper
        .createExcel(excelName, { name: 'equipments', withData: options?.withData, siteId: site?.id }, worksheets, this.timezone, this.dateFormat$, this.timeFormat$, false)
        .then(
          () => {
            this.store.dispatch(new ObjectActions.DownloadEquipmentExcelCompleted());
          },
          () => {
            this.store.dispatch(new ObjectActions.FetchError({}));
          },
        );
    });
  }

  public getEquipmentListExcelColumns(
    site: { id: number; name: string },
    lookups: { equipmentBrands: IEquipmentBrand[]; equipmentTypes: IEquipmentType[] },
    withErrorColumn: boolean,
  ): CreateExcelInterface {
    const dateFormula: string = this.excelHelper.getExcelDateFormula(this.dateFormat$, this.dateCharacterFormat$);
    const booleanDropdownOptions = this.excelHelper.getExcelBooleanDropdownOptions();
    const multipleCheckInsOptions = this.excelHelper.getMultipleCheckInsOptions();
    const excelColumns: CreateExcelInterface = {
      columns: [
        {
          header: this.translate.instant('equipmentLists.excel.siteId.header'),
          key: 'siteId',
          width: ExcelColumnWidthEnum.DEFAULT,
          type: ValueType.String,
          dropdownOptions: {
            data: [site],
            prop: 'name',
            dataProperty: 'site.name',
            dataId: 'site.id',
          },
          dataValidation: {
            type: CellTypes.LIST,
          },
          isRequired: true,
        },
        {
          header: this.translate.instant('equipmentLists.excel.equipmentName.header'),
          key: 'equipmentName',
          width: ExcelColumnWidthEnum.DEFAULT,
          type: ValueType.String,
          style: { numFmt: '@' },
          dataValidation: {
            type: CellTypes.CUSTOM,
          },
          isRequired: true,
        },
        {
          header: 'id',
          key: 'id',
          width: ExcelColumnWidthEnum.DEFAULT,
          type: ValueType.String,
          style: { numFmt: '@' },
          dataValidation: {
            type: CellTypes.CUSTOM,
          },
        },
        {
          header: this.translate.instant('equipmentLists.excel.equipmentShortName.header'),
          key: 'equipmentShortName',
          width: ExcelColumnWidthEnum.DEFAULT,
          type: ValueType.String,
          style: { numFmt: '@' },
          dataValidation: {
            type: CellTypes.CUSTOM,
          },
        },
        {
          header: this.translate.instant('equipmentLists.excel.equipmentType.header'),
          key: 'equipmentType',
          width: ExcelColumnWidthEnum.DEFAULT,
          type: ValueType.String,
          dropdownOptions: {
            data: _.get(lookups, 'equipmentTypes', []),
            prop: 'name',
            dataProperty: 'equipmentTypeRelation.description',
            dataId: 'equipmentTypeRelation.id',
          },
          dataValidation: {
            type: CellTypes.LIST,
          },
        },
        {
          header: this.translate.instant('equipmentLists.excel.equipmentBrand.header'),
          key: 'equipmentBrand',
          width: ExcelColumnWidthEnum.DEFAULT,
          type: ValueType.String,
          dropdownOptions: {
            data: _.get(lookups, 'equipmentBrands', []),
            prop: 'name',
            dataProperty: 'equipmentBrandRelation.name',
            dataId: 'equipmentBrandRelation.id',
          },
          dataValidation: {
            type: CellTypes.LIST,
          },
        },
        {
          header: this.translate.instant('equipmentLists.excel.equipmentModel.header'),
          key: 'equipmentModel',
          width: ExcelColumnWidthEnum.DEFAULT,
          type: ValueType.String,
          style: { numFmt: '@' },
          dataValidation: {
            type: CellTypes.CUSTOM,
          },
        },
        {
          header: this.translate.instant('equipmentLists.excel.validatedSpeed.header'),
          key: 'validatedSpeed',
          width: ExcelColumnWidthEnum.DECIMAL,
          type: ValueType.Number,
          style: { numFmt: '0.000000000000000###############' },
          allowPunctuation: true,
          dataValidation: {
            type: CellTypes.CUSTOM,
          },
          isDecimalNumber: true,
        },
        {
          header: this.translate.instant('equipmentLists.excel.dateOfPurchasing.header'),
          key: 'dateOfPurchasing',
          width: ExcelColumnWidthEnum.DEFAULT,
          type: ValueType.Date,
          style: { numFmt: '@' },
          dataValidation: {
            type: CellTypes.CUSTOM,
            formulae: [dateFormula],
          },
          isRequired: true,
        },
        {
          header: this.translate.instant('equipmentLists.excel.equipmentUsefulLife.header'),
          key: 'equipmentUsefulLife',
          width: ExcelColumnWidthEnum.DEFAULT,
          type: ValueType.Number,
          style: { numFmt: '0' },
          maxLength: 11,
          dataValidation: {
            type: CellTypes.CUSTOM,
          },
        },
        {
          header: this.translate.instant('equipmentLists.excel.equipmentDescription.header'),
          key: 'equipmentDescription',
          width: ExcelColumnWidthEnum.DEFAULT,
          type: ValueType.String,
          style: { numFmt: '@' },
          dataValidation: {
            type: CellTypes.CUSTOM,
          },
        },
        {
          header: this.translate.instant('equipmentLists.excel.isCheckable.header'),
          key: 'isCheckable',
          width: ExcelColumnWidthEnum.DEFAULT,
          type: ValueType.Boolean,
          dropdownOptions: {
            data: booleanDropdownOptions,
            prop: 'name',
            dataProperty: 'isCheckableOption.name',
            dataId: 'isCheckableOption.id',
          },
          dataValidation: {
            type: CellTypes.LIST,
          },
          isRequired: true,
        },
        {
          header: this.translate.instant('equipmentLists.excel.equipmentCheckInCardId.header'),
          key: 'equipmentCheckInCardId',
          width: ExcelColumnWidthEnum.DEFAULT,
          type: ValueType.String,
          style: { numFmt: '@' },
          dataValidation: {
            type: CellTypes.CUSTOM,
          },
        },
        {
          header: this.translate.instant('equipmentLists.excel.checkInsPin.header'),
          key: 'checkInsPin',
          removePropertyIfNull: true,
          width: ExcelColumnWidthEnum.DEFAULT,
          type: ValueType.String,
          style: { numFmt: '@' },
          dataValidation: {
            type: CellTypes.CUSTOM,
          },
          isRequired: true,
        },
        {
          header: this.translate.instant('general.excel.column.multipleCheckIns.name'),
          key: 'multipleCheckIns',
          width: ExcelColumnWidthEnum.DEFAULT,
          type: ValueType.String,
          dropdownOptions: {
            data: multipleCheckInsOptions,
            prop: 'name',
            dataProperty: 'multipleCheckInsOption.name',
            dataId: 'multipleCheckInsOption.id',
          },
          dataValidation: {
            type: CellTypes.LIST,
            prompt: this.translate.instant('general.excel.column.multipleCheckIns.prompt', {
              defaultOption: this.translate.instant('general.siteDefault'),
            }),
          },
        },
      ],
    };

    this.excelHelper.prepareExcelColumns(excelColumns.columns, withErrorColumn);

    return excelColumns;
  }

  public async getEquipmentListsFromExcel(file: File): Promise<null | IEquipmentsExcelData> {
    const workbook: Workbook = await this.excelHelper.getExcelWorkBookFromFile(file);
    const equipmentListSheet: Worksheet = workbook.getWorksheet(this.translate.instant('excel.items.equipmentLists'));
    const siteIdDataSheet: Worksheet = workbook.getWorksheet('siteIdDataSheet');
    const equipmentTypeDataSheet: Worksheet = workbook.getWorksheet('equipmentTypeDataSheet');
    const equipmentBrandDataSheet: Worksheet = workbook.getWorksheet('equipmentBrandDataSheet');

    if (!equipmentListSheet || !siteIdDataSheet || !equipmentTypeDataSheet || !equipmentBrandDataSheet) {
      return null;
    }

    const siteColumns = {
      id: {
        key: 'id',
        type: ValueType.String,
        dataValidationType: CellTypes.CUSTOM,
      },
      name: {
        key: 'name',
        type: ValueType.String,
        dataValidationType: CellTypes.CUSTOM,
      },
    };

    const sites: { id: number; name: string }[] = this.excelHelper.getExcelRowsFromWorkSheet<{
      id: number;
      name: string;
    }>(siteIdDataSheet, siteColumns);

    if (!sites.length) {
      return null;
    }

    const { columns } = this.getEquipmentListExcelColumns(null, null, false);
    const columnKeys = this.excelHelper.getSheetColumnKeys(columns);

    return {
      equipmentListData: {
        equipments: this.excelHelper
          .getExcelRowsFromWorkSheet<IEquipment>(equipmentListSheet, columnKeys, {
            dateFormat: this.dateFormat$,
            timeFormat: this.timeFormat$,
            timezone: this.timezone,
          })
          .map((equipment) => {
            return {
              ...equipment,
              multipleCheckIns: _.isNil(equipment.multipleCheckIns)
                ? Number(MultipleCheckInsEnum.SITE_DEFAULT)
                : Number(equipment.multipleCheckIns),
            };
          }),
      },
      siteData: sites,
    };
  }

  public uploadExcel(equipmentList: EquipmentListBulkSaveManyInterface): Observable<BulkResponseDataInterface> {
    return this.http.post<BulkResponseDataInterface>(this.EQUIPMENTS.BULK.SAVE, equipmentList);
  }

  public getAvatar(avatarPath: string, options?: TCacheOptions): Observable<BaseOneResponseInterface<string>> {
    let avatar: Observable<BaseOneResponseInterface<string>> | undefined =
      this.equipmentAvatarCacheService.getValue(avatarPath);

    if (!avatar) {
      const bufferLifeTime: number = options
        ? moment
            .duration(options.ttl || 1, options.ttlUnit || 'minutes')
            .add(1, 'second')
            .asMilliseconds()
        : this.shareReplayBufferLifeTime;

      avatar = this.http
        .get<BaseOneResponseInterface<string>>(`${this.EQUIPMENTS.GET.GET_AVATAR}/${avatarPath}`)
        .pipe(shareReplay(1, bufferLifeTime));
      this.equipmentAvatarCacheService.setValue(avatarPath, avatar, options);
    }

    return avatar;
  }

  public uploadAvatar(id: number, base64ImageContent: string): Observable<BaseCrudResponse> {
    return this.http.patch<BaseCrudResponse>(
      `${this.EQUIPMENTS.PATCH.AVATAR}/${id}`,
      this.imageHelperService.getImageUploadFormData(base64ImageContent),
    );
  }

  public deleteAvatar(id: number): Observable<BaseCrudResponse> {
    return this.http.delete<BaseCrudResponse>(`${this.EQUIPMENTS.PATCH.AVATAR}/${id}`);
  }
}
