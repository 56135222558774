import { Component, Input, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'scw-mat-missing-data',
  templateUrl: './scw-mat-missing-data.component.html',
  styleUrls: ['./scw-mat-missing-data.component.scss'],
})
export class ScwMatMissingDataComponent {
  @Input() text: string = null;
  @Input() isMissingData: boolean = false;
  @Input() isIdleActivity: boolean = false;
  @Input() isWithoutWorkOrder: boolean = false;
  @Input() containsHyperLink: boolean = false;
  @Input() hyperLink: string[] = [];

  public missingDataTranslation: string = this.translate.instant('general.missingDataText');

  constructor(private readonly translate: TranslateService) {}
}
