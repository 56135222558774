import { Component, EventEmitter, Input, Output, SimpleChanges } from '@angular/core';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { InputModel, ScwMatCheckBoxSize } from './scw-mat-checkbox.model';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'scw-mat-checkbox',
  templateUrl: './scw-mat-checkbox.component.html',
  styleUrls: ['./scw-mat-checkbox.component.scss'],
})
export class ScwMatCheckboxComponent<T extends number | boolean = number | boolean> implements InputModel<T> {
  @Input() inputModel: T = false as unknown as T;
  @Input() disabled: boolean = false;
  @Input() id: string;
  @Input() labelPosition: 'before' | 'after';
  @Input() name: string;
  @Input() required: boolean = false;
  @Input() value: string;
  @Input() size: ScwMatCheckBoxSize = 'md';
  @Input() hint: string = null;
  @Input() errorText: string;
  @Input() dataPropertyKey?: string;
  @Output() onChange: EventEmitter<MatCheckboxChange> = new EventEmitter<MatCheckboxChange>();
  @Output() inputModelChange: EventEmitter<T> = new EventEmitter<T>();

  constructor(private readonly translate: TranslateService) {}

  public onChangeEmitter($event: MatCheckboxChange): void {
    this.onChange.emit($event);
  }

  public onNgModelChange(): void {
    this.setInputModelBasedOnType(this.inputModel);
    this.inputModelChange.emit(this.inputModel);
  }

  public toggle(): void {
    this.setInputModelBasedOnType(!Boolean(this.inputModel));
    this.onNgModelChange();
  }

  protected setInputModelBasedOnType(value: number | boolean): void {
    switch (typeof this.inputModel) {
      case 'number':
        this.inputModel = Number(value) as T;
        break;
      case 'boolean':
        this.inputModel = value as T;
    }
  }

  private showErrorMessage(message: string): void {
    this.errorText = message ? message : '';
  }

  private requiredRule(): void {
    if (!this.inputModel) {
      this.showErrorMessage(this.translate.instant('scwMatForm.validation.required'));
    }
  }

  public checkRules(): void {
    if (this.required) {
      this.requiredRule();
    }
  }

  public reset(): void {
    this.inputModel = null;
    this.errorText = null;
  }
}
