<div class="modal-body">
  <image-cropper
    [roundCropper]="isRoundCropper"
    format="jpeg"
    [autoCrop]="false"
    backgroundColor="white"
    [resizeToWidth]="resizeToWidth"
    [resizeToHeight]="resizeToHeight"
    [onlyScaleDown]="onlyScaleDown"
    [imageQuality]="100"
    [imageFile]="file"
    [imageBase64]="imageBase64"
    (imageCropped)="onImageCropped($event)"
    (imageLoaded)="onImageLoaded()"
    [maintainAspectRatio]="isMaintainingAspectRatio"
    #crop
  >
  </image-cropper>
</div>
<div class="modal-footer">
  <div class="buttons-left">
    <scw-mat-button type="danger" (onClick)="onRemove()">{{ 'general.remove' | translate }}</scw-mat-button>
  </div>
  <div class="buttons-right">
    <scw-mat-button type="standard" (onClick)="onSelectNew()">{{ 'general.selectNew' | translate }}</scw-mat-button>
    <scw-mat-button (onClick)="onSave()">{{ 'general.save' | translate }}</scw-mat-button>
  </div>
</div>
