import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { ImageCroppedEvent, ImageCropperComponent } from 'ngx-image-cropper';
import { TranslateService } from '@ngx-translate/core';
import { Store } from '@ngrx/store';
import { OeeAppState } from '../../../store/oee.reducer';
import * as AppActions from '../../../store/app/actions';

@Component({
  selector: 'app-crop-modal',
  templateUrl: './crop-modal.component.html',
})
export class CropModalComponent implements OnInit {
  @Input() file: File;
  @Input() previouslySelectedImage: string;
  @Input() isRoundCropper: boolean = true;
  @Input() isMaintainingAspectRatio: boolean = true;
  @Input() resizeToWidth: number = 512;
  @Input() resizeToHeight: number = 0;
  @Input() onlyScaleDown: boolean = false;
  @ViewChild('crop') crop: ImageCropperComponent;
  @Output() onSubmit: EventEmitter<string> = new EventEmitter<string>();
  @Output() onSelectNewClicked: EventEmitter<void> = new EventEmitter<void>();
  @Output() onMaxAllowedImageSizeExceeded: EventEmitter<void> = new EventEmitter<void>();

  public imageBase64: string;
  private readonly maxAllowedImageSizeMB = 1000000 * 5;
  private imageQuality: number = 100;

  constructor(public translate: TranslateService, public store: Store<OeeAppState>) {}

  ngOnInit(): void {
    this.store.dispatch(new AppActions.ShowLoader());

    if (this.previouslySelectedImage && !this.file) {
      this.imageBase64 = this.previouslySelectedImage;
    }
  }

  private static base64LengthToFileSizeMB(base64Length: number): number {
    return (base64Length * 3) / 4 - 2;
  }

  public onImageLoaded(): void {
    this.crop.crop();
  }

  public onImageCropped($event: ImageCroppedEvent): void {
    const sizeMB: number = CropModalComponent.base64LengthToFileSizeMB($event.base64.length);
    if (sizeMB < this.maxAllowedImageSizeMB) {
      this.store.dispatch(new AppActions.HideLoader());
      return;
    }

    this.imageQuality = this.imageQuality - 10;

    if (this.imageQuality === 20) {
      this.store.dispatch(new AppActions.HideLoader());
      this.onMaxAllowedImageSizeExceeded.emit();
      return;
    }

    this.crop.ngOnChanges({
      imageQuality: {
        previousValue: null,
        currentValue: this.imageQuality,
        firstChange: true,
        isFirstChange: () => true,
      },
    });
    this.crop.crop();
  }

  public onSave(): void {
    this.onSubmit.emit(this.crop.crop().base64);
  }

  public onRemove(): void {
    this.onSubmit.emit(null);
  }

  public onSelectNew(): void {
    this.onSelectNewClicked.emit();
  }
}
