import { TemplateRef } from '@angular/core';
import { DropdownOptionInterface } from '../scw-mat-select/scw-mat-select.model';
import { EWidgetType } from '../../page-configuration/page-configuration.model';

export interface IScwMatKpiCard {
  type: EWidgetType.KPI_CARD;
  pageConfigurationTitle?: string;
  class?: string;
  disabled?: boolean;
  displayRenderProperties?: IDisplayRenderProperties;
  header?: IHeader[];
  body? : IBodyFooter[];
  footer?: IBodyFooter[];
}

export interface IHeader {
  value: string;
  type: string;
  key?: string;
  unit?: string;
  class?: string;
  contentClass?: string;
  iconAutoClose?: boolean;
  iconTooltipText?: string;
  style?: StyleType;
}

export interface IBodyFooter {
  type: string;
  value?: string;
  key?: string;
  unit?: IUnit;
  class?: string;
  contentClass?: string;
  iconAutoClose?: boolean;
  iconTooltipText?: string;
  progressBarClass?: string;
  selectBoxClass?: string;
  displayPercentage?: boolean;
  style?: StyleType;
  selectBoxData?: DropdownOptionInterface[];
  selectBoxSelection?: DropdownOptionInterface[];
}

export interface IUnit {
  value?: string;
  style?: ITextStyle;
}

export enum EKpiCardContentType {
  PROGRESS_BAR = 'progressBar',
  SELECT_BOX = 'selectBox',
  TEXT = 'text',
  ICON = 'icon',
  EMPTY_SPACE = 'emptySpace',
  WARNING_MESSAGE = 'warningMessage',
}

type StyleType = ITextStyle | IProgressBarStyle;

export interface ITextStyle {
  color?: string;
  float?: 'left' | 'right';
  fontSize?: string;
  fontWeight?: string;
  margin?: string;
  padding?: string;
}

export interface IProgressBarStyle {
  color?: string;
  margin?: string;
  padding?: string;
  width?: string;
}

export interface ITemplates {
  progressBar: TemplateRef<any>;
  selectBox: TemplateRef<any>;
  text: TemplateRef<any>;
  icon: TemplateRef<any>;
  warningMessage: TemplateRef<any>;
}

export interface IDisplayRenderProperties {
  tag?: ETag;
  pageConfigurationMode?: boolean;
}

export enum ETag {
  SOON = 'soon',
  NEW = 'new',
}

export interface ITagMessage {
  soon: string;
  new: string;
}
