import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { from, of } from 'rxjs';
import { catchError, switchMap } from 'rxjs/operators';
import {
  BaseOneResponseInterface,
  BulkResponseDataInterface,
} from '../../../shared/model/interface/crud-response-interface.model';
import { ResponseArrayInterface } from '../../../shared/model/interface/generic-api-response.model';
import * as AppActions from '../../app/actions';
import * as oeeAppReducer from '../../oee.reducer';
import { ITreeNode } from './tree-nodes.model';
import { TreeNodesService } from './tree-nodes.service';
import * as ObjectActions from './tree-nodes.actions';
import { EntityTranslatorService } from 'src/app/shared/service/entity-translator/entity-translator.service';

@Injectable()
export class TreeNodesEffects {
  constructor(
    private readonly actions$: Actions,
    private readonly store: Store<oeeAppReducer.OeeAppState>,
    private readonly treeNodesService: TreeNodesService,
    private translatorService: EntityTranslatorService,
  ) {}

  getTreeNodes = createEffect(() =>
    this.actions$.pipe(
      ofType(ObjectActions.TREE_NODES_DATA_LOAD),
      switchMap((object: ObjectActions.TreeNodesDataLoad) => {
        this.store.dispatch(new AppActions.ShowLoader());
        const andConditions = [];
        let httpParams: HttpParams = new HttpParams().append('rootType', object.params.rootType).append(
          'rootAddress',
          JSON.stringify({
            lineIds: [object.params.rootAddress.lineId],
            activityIds: [object.params.rootAddress.activityId],
          }),
        );

        if (object.params.search) {
          andConditions.push({ name: { $cont: object.params.search } });
        }

        if (andConditions.length > 0) {
          httpParams = httpParams.set('s', JSON.stringify({ $and: andConditions }));
        }

        return this.treeNodesService.getTreeNodes(httpParams).pipe(
          switchMap((response: ResponseArrayInterface<ITreeNode>) => {
            response.data.forEach((treeNode: ITreeNode) => {
              this.translatorService.translate(treeNode);
            });

            return of(new ObjectActions.TreeNodesDataLoaded(response), new AppActions.HideLoader());
          }),
          catchError((error) => {
            return of(new ObjectActions.FetchError(error), new AppActions.HideLoader());
          }),
        );
      }),
      catchError((error) => {
        return of(new ObjectActions.FetchError(error), new AppActions.HideLoader());
      }),
    ),
  );

  addTreeNode = createEffect(() =>
    this.actions$.pipe(
      ofType(ObjectActions.ADD_TREE_NODE),
      switchMap((objectData: ObjectActions.AddTreeNode) => {
        this.store.dispatch(new AppActions.ShowLoader());

        return this.treeNodesService.addTreeNode(objectData.treeNode).pipe(
          switchMap((response: BaseOneResponseInterface<ITreeNode>) => {
            return of(new ObjectActions.AddTreeNodeCompleted(response), new AppActions.HideLoader());
          }),
          catchError((error) => {
            return of(new ObjectActions.FetchError(error), new AppActions.HideLoader());
          }),
        );
      }),
      catchError((error) => {
        return of(new ObjectActions.FetchError(error), new AppActions.HideLoader());
      }),
    ),
  );

  editTreeNode = createEffect(() =>
    this.actions$.pipe(
      ofType(ObjectActions.EDIT_TREE_NODE),
      switchMap((object: ObjectActions.EditTreeNode) => {
        this.store.dispatch(new AppActions.ShowLoader());

        return this.treeNodesService.editTreeNode(object.treeNode, object.treeNodeId).pipe(
          switchMap((response: BaseOneResponseInterface<ITreeNode>) => {
            return of(new ObjectActions.EditTreeNodeCompleted(response), new AppActions.HideLoader());
          }),
          catchError((error) => {
            return of(new ObjectActions.FetchError(error), new AppActions.HideLoader());
          }),
        );
      }),
      catchError((error) => {
        return of(new ObjectActions.FetchError(error), new AppActions.HideLoader());
      }),
    ),
  );

  deleteTreeNode = createEffect(() =>
    this.actions$.pipe(
      ofType(ObjectActions.DELETE_TREE_NODE),
      switchMap((objectData: ObjectActions.DeleteTreeNode) => {
        this.store.dispatch(new AppActions.ShowLoader());

        return from(this.treeNodesService.deleteTreeNode(objectData.treeNodeId)).pipe(
          switchMap((response: BulkResponseDataInterface) => {
            return of(new ObjectActions.DeleteTreeNodeCompleted(response), new AppActions.HideLoader());
          }),
          catchError((error) => {
            return of(new ObjectActions.FetchError(error), new AppActions.HideLoader());
          }),
        );
      }),
      catchError((error) => {
        return of(new ObjectActions.FetchError(error), new AppActions.HideLoader());
      }),
    ),
  );
}
