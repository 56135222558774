<div class="scw-mat-container" [class.no-padding]="noPadding" [ngClass]="containerClass">
    <label class="datepicker-label" *ngIf="label">{{ label }}</label>
    <div
      class="scw-mat-datepicker-container"
      [class.width-auto]="!wide"
      [class.width-absolute-no-ranges]="wide && ranges === null"
    >
      <input
        type="text"
        class="ngx-date-range-picker"
        ngxDaterangepickerMd
        [alwaysShowCalendars]="alwaysShowCalendars"
        [autoApply]="autoApply"
        [closeOnAutoApply]="closeOnAutoApply"
        [customRangeDirection]="customRangeDirection"
        disabled="{{ disabled }}"
        [attr.disabled]="disabled ? '' : null"
        [class.has-errors]="hasErrors"
        [drops]="drops"
        [emptyWeekRowClass]="emptyWeekRowClass"
        [firstDayOfNextMonthClass]="firstDayOfNextMonthClass"
        [id]="id"
        [isCustomDate]="isCustomDate"
        [isInvalidDate]="isInvalidDate"
        [keepCalendarOpeningWithRange]="keepCalendarOpeningWithRange"
        [lastDayOfPreviousMonthClass]="lastDayOfPreviousMonthClass"
        [lastMonthDayClass]="lastMonthDayClass"
        [linkedCalendars]="linkedCalendars"
        [locale]="locale"
        [lockStartDate]="lockStartDate"
        [maxDate]="maxDate"
        [minDate]="minDate"
        [opens]="opens"
        [placeholder]="placeholder"
        [ranges]="ranges"
        [readonly]="readonly"
        [showCancel]="showCancel"
        [showClearButton]="showClearButton"
        [showCustomRangeLabel]="showCustomRangeLabel"
        [showISOWeekNumbers]="showISOWeekNumbers"
        [showRangeLabelOnInput]="showRangeLabelOnInput"
        [showWeekNumbers]="showWeekNumbers"
        [singleDatePicker]="singleDatePicker"
        [timePicker24Hour]="timePicker24Hour"
        [timePicker]="timePicker"
        [timePickerIncrement]="timePickerIncrement"
        [timePickerSeconds]="timePickerSeconds"
        [ngClass]="className"
        [dateLimit]="dateLimit"
        [ngStyle]="customStyle"
        [(ngModel)]="inputModel"
        (ngModelChange)="onNgModelChange()"
        [showDropdowns]="showDropdowns"
      />
      <em
        (click)="onClickIcon()"
        class="fas fa-calendar-alt fa-lg"
        [class.calendar-icon]="!disabled"
        [class.calendar-icon-disabled]="disabled"
        [class.calendar-icon-error]="hasErrors"
        [hidden]="!showCalendarIcon"
      ></em>
      <ng-template
        [ngIf]="
          singleDatePicker === true &&
          inputModel &&
          inputModel.startDate !== null &&
          inputModel.endDate !== null &&
          showClearButton
        "
      >
        <svg
          (click)="onClickClear()"
          width="8px"
          height="8px"
          version="1.1"
          id="Capa_1"
          xmlns="http://www.w3.org/2000/svg"
          xmlns:xlink="http://www.w3.org/1999/xlink"
          x="0px"
          y="0px"
          viewBox="0 0 47.971 47.971"
          xml:space="preserve"
          style="enable-background: new 0 0 47.971 47.971"
          class="times-icon"
        >
          <g>
            <path
              d="M28.228,23.986L47.092,5.122c1.172-1.171,1.172-3.071,0-4.242c-1.172-1.172-3.07-1.172-4.242,0L23.986,19.744L5.121,0.88
          c-1.172-1.172-3.07-1.172-4.242,0c-1.172,1.171-1.172,3.071,0,4.242l18.865,18.864L0.879,42.85c-1.172,1.171-1.172,3.071,0,4.242
          C1.465,47.677,2.233,47.97,3,47.97s1.535-0.293,2.121-0.879l18.865-18.864L42.85,47.091c0.586,0.586,1.354,0.879,2.121,0.879
          s1.535-0.293,2.121-0.879c1.172-1.171,1.172-3.071,0-4.242L28.228,23.986z"
            ></path>
          </g>
        </svg>
      </ng-template>
    </div>
    <ng-template [ngIf]="errorText || hint">
      <mat-hint [class.error]="errorText" [class.hint]="!errorText && hint">
        <em
          class="sub-text-icon fas"
          [class.fa-exclamation-circle]="errorText"
          [class.fa-info-circle]="!errorText && hint"
        ></em>
        <ng-template [ngIf]="errorText">{{ errorText }}</ng-template>
        <ng-template [ngIf]="!errorText && hint">{{ hint }}</ng-template>
      </mat-hint>
    </ng-template>
</div>
