import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { switchMap } from 'rxjs/operators';
import * as ObjectActions from './equipment-task.actions';
import { of } from 'rxjs';
import * as AppActions from '../../store/app/actions';
import { Store } from '@ngrx/store';
import * as oeeAppReducer from '../oee.reducer';
import { EquipmentTaskService } from './equipment-task.service';

@Injectable()
export class EquipmentTaskEffects {
  constructor(
    private actions$: Actions,
    private readonly service: EquipmentTaskService,
    private store: Store<oeeAppReducer.OeeAppState>,
  ) {}

  nodeDataLoad = createEffect(() =>
    this.actions$.pipe(
      ofType(ObjectActions.EquipmentTaskActionTypes.NodeDataLoading),
      switchMap((objectData: ObjectActions.NodeDataLoad) => {
        this.store.dispatch(new AppActions.ShowTopLoader());

        return this.service
          .getEquipmentsAndTasks(objectData)
          .pipe(
            switchMap((response) =>
              of(
                new AppActions.HideTopLoader(),
                new ObjectActions.NodeDataLoaded(response.data.items, response.data.breadcrumb, objectData.equipmentId),
              ),
            ),
          );
      }),
    ),
  );
}
