import { HomeMetricIdTypes } from '../../../view/settings/home-page-display-settings/home-page-display-settings.constants';
import {
  IMeta,
  IOEEMetricMeta,
  IOEEMetricMultiselectMeta,
} from '../../../view/settings/home-page-display-settings/home-metrics/home-metrics.model';

export interface IKpiCardInformation {
  metricKey: string;
  metricName: string;
  actualValue: string;
  actualValueForHourly: string[];
  actualFormula: string;
  expectedValue: string;
  expectedFormula: string;
  actualValueDescription: string;
  expectedValueDescription: string;
  metricType: EMetricType;
  showProgressBar: boolean;
  progressBarRate: string;
  progressBarRateCapped: number;
  actualValueClass: string | null;
  showExpectedValue: boolean;
  formulaValue: string;
  formulaValueDescription: string;
  info: string;
  unit: string;
  icons: string[];
  titleBracketInfo: string;
  unitOfCount: string;
  unitOfTime: string | null;
  metricLayout: IOEEMetricMeta | null;
  metricLayoutMultiSelect: IOEEMetricMultiselectMeta | null;
}

export enum EMetricType {
  PROGRESS_BAR = 'progressBar',
  BADGE = 'badge',
  COUNT = 'count',
  OEE = 'oee',
  HOURLY_OEE = 'hourlyOee',
  ACTIVITIES_TOTAL_DURATION = 'activitiesTotalDuration',
  MULTIPLE_UNITS = 'multipleUnits',
}

export type KpiCardParameterUnit = { [homeMetric in HomeMetricTypes]: { unit: string; metricType: string } };

export interface IKpiCards {
  id: HomeMetricIdTypes;
  name: string;
  homeMetricSetProperties?: IMeta;
  actualDescription?: string;
  expectedDescription?: string;
}

export type HomeMetricTypes =
  | 'workOrderDuration'
  | 'activityDuration'
  | 'sensorBadges'
  | 'preRunPhaseDuration'
  | 'runPhaseDuration'
  | 'postRunPhaseDuration'
  | 'quantityWithComment'
  | 'speed'
  | 'workOrderOee'
  | 'workOrderOeeSixHours'
  | 'workOrderOeeTwelveHours'
  | 'count'
  | 'activitiesTotalDuration'
  | 'shiftOee';
