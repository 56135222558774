import { Action } from '@ngrx/store';
import { HttpOptionInterface } from '../filter.model';
import { HttpErrorResponse } from '@angular/common/http';
import { IJobCRUD, TaskFilterCRUDInterface } from '../../../shared/component/filter/filter.class';
import { EFilterJobsActionTypes } from '../job/job.action';

export enum FilterTaskActionTypes {
  StartLoadFilterTasks = '[Filter Task] Load Filter Tasks',
  FilterTasksLoaded = '[Filter Task] Filter Tasks Loaded',
  ResetTaskStore = '[Filter Task] Reset data stored in task store',
  AppendTaskData = '[Filter Task] Append data stored in task store',
  AppendedTaskData = '[Filter Task] Appended data stored in task store',
  ClearAppendedTaskData = '[Filter Task] Clear Appended data stored in task store',
  FetchError = '[Filter Task] Fetch Error',
}

export class StartLoadFilterTasks implements Action {
  readonly type = FilterTaskActionTypes.StartLoadFilterTasks;

  constructor(public options: HttpOptionInterface, public searchProcess: boolean = false) {}
}

export class FilterTasksLoaded implements Action {
  readonly type = FilterTaskActionTypes.FilterTasksLoaded;

  constructor(public data: TaskFilterCRUDInterface[]) {}
}

export class ResetTaskStore implements Action {
  readonly type = FilterTaskActionTypes.ResetTaskStore;
}

export class AppendTaskData implements Action {
  readonly type = FilterTaskActionTypes.AppendTaskData;

  constructor(public titles: string[]) {}
}

export class AppendedTaskData implements Action {
  readonly type = FilterTaskActionTypes.AppendedTaskData;

  constructor(public data: TaskFilterCRUDInterface[]) {}
}

export class ClearAppendedTaskData implements Action {
  readonly type = FilterTaskActionTypes.ClearAppendedTaskData;
}

export class FetchError implements Action {
  readonly type = FilterTaskActionTypes.FetchError;

  constructor(public payload: HttpErrorResponse) {}
}

export type FilterTaskActions =
  | StartLoadFilterTasks
  | FilterTasksLoaded
  | ResetTaskStore
  | AppendTaskData
  | AppendedTaskData
  | ClearAppendedTaskData
  | FetchError;
