import { HttpClient, HttpParams } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { GetManyResponseInterface } from 'src/app/shared/model/interface/crud-response-interface.model';
import { ICommentType } from './comment-types.model';

@Injectable({ providedIn: 'root' })
export class CommentTypesService {
  constructor(@Inject('API_BASE_URL') private readonly api: string, private readonly http: HttpClient) {}

  public getAll<T extends Partial<ICommentType> = Partial<ICommentType>>(
    params?: HttpParams,
  ): Observable<GetManyResponseInterface<T>> {
    return this.http.get<GetManyResponseInterface<T>>(`${this.api}/comment-types`, { params });
  }
}
