import * as Actions from './jobs.actions';
import { IJobsState } from './jobs.model';

export const jobsState: IJobsState = {
  jobsDataLoading: false,
  jobsDataLoaded: false,
  jobsData: null,
  fileDownloadStatus: false,
  excelUploadResult: [],
  isUploadExcelLoading: false,
  isUploadExcelLoaded: false,
  areModalJobDataLoading: false,
  areModalJobDataLoaded: false,
  modalJobData: null,
  jobsCountLoading: false,
  jobsCountLoaded: false,
  jobsCountForExcel: null,
};

export function jobsReducer(state: IJobsState = jobsState, action: Actions.JobsActions): IJobsState {
  switch (action.type) {
    case Actions.JOBS_LIST_DATA_LOADING:
      return {
        ...state,
        ...{
          jobsDataLoading: true,
          jobsDataLoaded: false,
        },
      };
    case Actions.JOBS_LIST_DATA_LOADED:
      return {
        ...state,
        ...{
          jobsDataLoading: false,
          jobsDataLoaded: true,
          jobsData: action.payload,
        },
      };
    case Actions.DOWNLOAD_JOB_EXCEL:
      return {
        ...state,
        ...{
          fileDownloadStatus: true,
        },
      };
    case Actions.DOWNLOAD_JOB_EXCEL_COMPLETED:
      return {
        ...state,
        ...{
          fileDownloadStatus: false,
        },
      };
    case Actions.UPLOAD_JOB_EXCEL:
      return {
        ...state,
        ...{
          isUploadExcelLoading: true,
          isUploadExcelLoaded: false,
        },
      };
    case Actions.UPLOAD_JOB_EXCEL_COMPLETED:
      return {
        ...state,
        ...{
          excelUploadResult: action.payload,
          isUploadExcelLoading: false,
          isUploadExcelLoaded: true,
        },
      };
    case Actions.GET_JOBS_FOR_MODAL:
      return {
        ...state,
        ...{
          areModalJobDataLoading: true,
          areModalJobDataLoaded: false,
          modalJobData: null,
        }
      }
    case Actions.GET_JOBS_FOR_MODAL_COMPLETED:
      return {
        ...state,
        ...{
          areModalJobDataLoading: false,
          areModalJobDataLoaded: true,
          modalJobData: action.response,
        }
      }
    case Actions.JOBS_COUNT_LOAD:
      return {
        ...state,
        ...{
          jobsCountLoading: true,
          jobsCountLoaded: false,
          modalJobData: null,
        }
      }
    case Actions.JOBS_COUNT_LOADED:
      return {
        ...state,
        ...{
          jobsCountLoading: false,
          jobsCountLoaded: true,
          jobsCountForExcel: action.payload.length,
        }
      }
    default:
      return state;
  }
}
