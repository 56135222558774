import { UserLevels } from '../../../store/user/model';
import {
  ECheckInSourceType,
  ILaborAssetViewCheckIn,
} from '../../../store/dashboards/labor-asset-view/labor-asset-view.model';

export enum ECicoAvailability {
  available = 'available',
  busy = 'busy',
  offline = 'offline',
}

export enum ECicoDestinationType {
  LINE = 'line',
  STATION = 'station',
}

export enum ECicoDestinationTypeId {
  LINE = 1,
  STATION = 2,
}

export enum ECicoType {
  LABOR = 'labor',
  ASSET = 'asset',
}

export interface ICicoDestinationObject {
  lineName?: string;
  objectName: string;
  objectId: number;
}

export interface ECicoDestination {
  type: ECicoDestinationType;
  object: ICicoDestinationObject;
}

export interface ICicoMeta {
  woNumber: string;
  levelId: UserLevels;
}

export interface ICicoData {
  id: number;
  fullName: string;
  status: ECicoAvailability;
  lineId: number;
  lineName: string;
  siteId: number;
  tagId: number | null;
  tagName: string | null;
  tagColor: string | null;
  destination: ECicoDestination;
  checkInTime: string;
  actualCheckInTime: string;
  checkOutTime: string | null;
  actualCheckOutTime: string | null;
  shiftStartDate: string;
  shiftEndDate: string;
  shiftDuration: number;
  type: ECicoType;
  iconCls?: string | null;
  avatar?: string | null;
  avatarPath?: string | null;
  avatarIsLoading?: boolean;
  scheduledCheckInTime?: string;
  scheduledCheckOutTime?: string;
  scheduledDuration?: number;
  scheduledDestination?: ECicoDestination;
  meta: ICicoMeta;
  checkIns: ILaborAssetViewCheckIn[];
  tagIds: number[] | null;
  sourceObjectId: number;
  workOrderId: number;
  woNumber: string;
  lineTypeId: number;
  lineType: string;
  stationName: string | null;
  stationId: number | null;
  productTableId: number | null;
  productId: string | null;
  jobId: number | null;
  jobName: string | null;
  linePathId: number | null;
  linePathName: string | null;
  sourceTypeId: ECheckInSourceType;
  isAvailable: boolean;
  isOffline: boolean;
}

export interface IFormattedCicoData extends ICicoData {
  formattedDuration: string;
  formattedCheckInTime: string;
  formattedCheckOutTime: string | null;
  formattedScheduledCheckInTime: string | null;
  formattedScheduledCheckOutTime: string | null;
}

export enum EProgressBarType {
  GAP = 'gap',
  ENTRY = 'entry',
}

export interface IProgressBar {
  type: EProgressBarType;
  width: number;
  start: string;
  end: string | null;
}

export interface IAvatarToLoad {
  type: ECicoType;
  path: string;
}

export interface IChangeStation {
  id: number;
  type: ECicoType;
  status: ECicoAvailability;
  tagId: number;
  fromType: ECicoDestinationType;
  fromObjectId: number;
  fromObjectName: string;
  toType: ECicoDestinationType;
  toObjectId: number;
  toObjectName: string;
}
