import { Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { WorkOrderCloseResponseInterface } from '../home/home.model';

@Injectable({
  providedIn: 'root',
})
export class WoFinalQuantityFormDataService {
  constructor(public http: HttpClient, @Inject('API_BASE_URL') private readonly api: string) {}

  get(lineId: number): Promise<WorkOrderCloseResponseInterface> {
    return new Promise((resolve, reject) => {
      this.http
        .get(`${this.api}/work-orders/${lineId}/work-order-final-quantity-form`)
        .subscribe((response: WorkOrderCloseResponseInterface) => {
          if (response) {
            resolve(response);
          } else {
            reject();
          }
        });
    });
  }
}
