import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LaborAssetViewComponent } from './labor-asset-view.component';

const routes: Routes = [
  {
    path: '',
    component: LaborAssetViewComponent,
    data: {
      name: 'LaborAssetViewComponent',
      title: 'labor_asset_view',
      icon: 'fas fa-users',
      caption: '',
      status: true,
      setPageHeader: true,
      showPageConfiguration: true,
      showCountDownButton: true,
      showFilterBarVisibilityButton: true,
      showPrintFunctionalityButton: false,
    },
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class LaborAssetViewRoutingModule {}
