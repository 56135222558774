import { AsyncPipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';
import { Store } from '@ngrx/store';
import { Timestamp } from 'firebase/firestore';
import moment from 'moment';
import { combineLatest, map } from 'rxjs';
import { defaultAfter } from 'src/app/shared/helper/rxjs-utils';
import { OeeAppState } from 'src/app/store/oee.reducer';
import { selectUserDateTimeFormat, selectUserTimeFormat, selectUserTimezone } from 'src/app/store/user/user.selectors';
import { MomentDatePipe } from './moment-date.pipe';

@Pipe({
  standalone: true,
  name: 'timestamp',
})
export class TimestampPipe implements PipeTransform {
  private readonly defaultDateTimeFormat: string = 'll LT';
  private readonly defaultTimeFormat: string = 'HH:mm';

  constructor(
    private readonly asyncPipe: AsyncPipe,
    private readonly momentDatePipe: MomentDatePipe,
    private readonly store: Store<OeeAppState>,
  ) {}

  public transform(value: Timestamp, timeOnly: boolean = false): string {
    return this.asyncPipe.transform(
      combineLatest([
        this.store.select(selectUserDateTimeFormat).pipe(defaultAfter(1000, this.defaultDateTimeFormat)),
        this.store.select(selectUserTimeFormat).pipe(defaultAfter(1000, this.defaultTimeFormat)),
        this.store.select(selectUserTimezone),
      ]).pipe(
        map(([dateTimeFormat, timeFormat, tz]) =>
          this.momentDatePipe.transform(
            tz ? moment(value.toDate()).tz(tz) : moment(value.toDate()),
            timeOnly ? timeFormat : dateTimeFormat,
          ),
        ),
      ),
    );
  }
}
