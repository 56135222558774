<div class="page-body">
  <ng-container>
    <filter-card
      [options]="filterOptions"
      (filterChange)="onFiltersChanged($event)"
      [strictControlForSelected]="true"
      [autoApply]="true"
      [mainGridWide]="10"
      [additionalGridWide]="2"
      [hidden]="!isFilterBarVisible$"
      [defaultDropdownSelectionSubject]="defaultDropdownSelectionSubject"
      [hiddenElementIds]="filterCardHiddenIds"
      [enableSetAsDefault]="true"
      [resetHiddenElementsSubject]="resetFilterCardHiddenItems"
      (setAsDefault)="prepareDefaultFilters($event)"
      [triggerShowUpdateCardSubject]="triggerUpdateButtonClickSubject"
    >
      <div class="additional-fields" additionalFields>
        <div class="row">
          <div class="col-md-12 col-xxl-7">
            <div class="m-t-5 m-b-5 flex-grow-1">
              <scw-mat-search
                placeholder="{{ 'general.search' | translate }}"
                behavior="delayed"
                [minLength]="0"
                [block]="true"
                [(inputModel)]="searchBoxText"
                (onSearch)="onKeyUpSearchBox($event)"
              >
              </scw-mat-search>
            </div>
          </div>
        </div>
      </div>
    </filter-card>
  </ng-container>
  <ng-container>
    <scw-labor-asset-view-kpi-card
      [laborAssetViewWidgets]="laborAssetViewWidgets$"
      [laborAssetViewData]="filteredLaborAssetViewData"
    ></scw-labor-asset-view-kpi-card>
  </ng-container>
  <ng-container>
    <app-card>
      <ng-container *ngIf="pageViewTypeValue === laborAssetViewTypes.LIST_VIEW; else cardView">
        <scw-labor-asset-view-list-view
          [laborAssetViewTableHeaders]="laborAssetViewDatatableHeaders"
          [laborAssetViewData]="filteredLaborAssetViewData"
          [isLaborAssetViewTableCheckboxVisible]="isLaborAssetViewTableCheckboxVisible"
        ></scw-labor-asset-view-list-view>
      </ng-container>
      <ng-template #cardView>
        <div class="justify-space-between">
          <div class="d-flex pb-3">
            <div class="pd-r-15">
              <scw-mat-button-group
                size="md"
                [buttons]="expandCollapseButtons"
                [(inputModel)]="isCardViewExpanded"
              ></scw-mat-button-group>
            </div>
            <div>
              <scw-mat-select
                class="group-by-dropdown"
                text="{{ 'laborAssetView.groupBy' | translate : { groupBy: noneTranslation } }}"
                [data]="groupByOptions"
                [(inputModel)]="groupBy"
                (inputModelChange)="onChangeGroupBy()"
              ></scw-mat-select>
            </div>
          </div>
          <div>
            <scw-mat-button
              type="standard"
              size="xs"
              [className]="['w-100']"
              class="collapseButton w-100"
              [disabled]="!groupBy.length"
              (onClick)="collapseButtonClick()"
            >
              {{ (isCollapsedGroups ? 'general.expandAll' : 'general.collapseAll') | translate }}
              <em
                class="fas fa-chevron-down"
                [ngStyle]="{ transform: isCollapsedGroups ? 'rotate(360deg)' : 'rotate(180deg)' }"
              ></em>
            </scw-mat-button>
          </div>
        </div>
        <scw-labor-asset-view-card-view
          [laborAssetViewData]="filteredLaborAssetViewData"
          [timeToggle]="currentFilters?.timeToggle"
          [isExpanded]="isCardViewExpanded"
          [isMatMenuVisible]="false"
          [groupBy]="groupBy"
          [isCollapsedGroups]="isCollapsedGroups"
        ></scw-labor-asset-view-card-view>
      </ng-template>
    </app-card>
  </ng-container>
</div>
