import { ILaborAssetViewState } from './labor-asset-view.model';
import { ELaborAssetHoursActionTypes, LaborAssetViewActions } from './labor-asset-view.actions';

export const initialState: ILaborAssetViewState = {
  laborAssetViewDataLoading: false,
  laborAssetViewDataLoaded: false,
  laborAssetViewData: [],
  tableSettings: [],
  pageConfigurationSettings: {},
};

export function laborAssetViewReducer(
  state: ILaborAssetViewState = initialState,
  action: LaborAssetViewActions,
): ILaborAssetViewState {
  switch (action.type) {
    case ELaborAssetHoursActionTypes.LABOR_ASSET_VIEW_DATA_LOADING:
      return {
        ...state,
        laborAssetViewDataLoading: true,
        laborAssetViewDataLoaded: false,
      };
    case ELaborAssetHoursActionTypes.LABOR_ASSET_VIEW_DATA_LOADED:
      return {
        ...state,
        laborAssetViewDataLoading: false,
        laborAssetViewDataLoaded: true,
        laborAssetViewData: action.payload,
      };
    case ELaborAssetHoursActionTypes.SET_TABLE_SETTINGS:
      return {
        ...state,
        tableSettings: action.payload,
      };
    case ELaborAssetHoursActionTypes.SET_PAGE_CONFIGURATION:
      return {
        ...state,
        pageConfigurationSettings: action.pageConfigurationSettings,
      };
    case ELaborAssetHoursActionTypes.FETCH_ERROR:
      return { ...state, laborAssetViewDataLoading: false, laborAssetViewDataLoaded: false };
    default:
      return state;
  }
}
