import { TScrapGoodCounts } from './semi-manual-count-button-card.types';

export class GoodScrapCountQueue {
  private scrapCounts: string[] = [];
  private goodCounts: string[] = [];

  public pushScrapCount(count: string): void {
    this.scrapCounts.push(count);
  }

  public pushGoodCount(count: string): void {
    this.goodCounts.push(count);
  }

  public readScrapCounts(): string[] {
    const counts = this.scrapCounts;
    this.scrapCounts = [];

    return counts;
  }

  public readGoodCounts(): string[] {
    const counts = this.goodCounts;
    this.goodCounts = [];

    return counts;
  }

  public readCounts(): TScrapGoodCounts {
    return {
      scrapCounts: this.readScrapCounts(),
      goodCounts: this.readGoodCounts(),
    };
  }

  public getScrapCounts(): string[] {
    return this.scrapCounts;
  }

  public getGoodCounts(): string[] {
    return this.goodCounts;
  }

  public getCounts(): TScrapGoodCounts {
    return {
      scrapCounts: this.getScrapCounts(),
      goodCounts: this.getGoodCounts(),
    };
  }

  public getScrapCountLength(): number {
    return this.scrapCounts.length;
  }

  public getGoodCountLength(): number {
    return this.goodCounts.length;
  }
}