import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import * as _ from 'lodash';
import {TranslateService} from "@ngx-translate/core";

@Component({
  selector: 'oee-metric',
  templateUrl: './oee-metric.component.html',
  styleUrls: ['./oee-metric.component.scss'],
})
export class OeeMetricComponent implements OnInit, OnChanges {
  @Input() cardTitleKey: string = '';
  @Input() subtitle: string;
  @Input() oee: string = '0';
  @Input() target: string = '0';
  @Input() color: string;
  @Input() hideTarget: boolean = false;
  @Input() showMetricInfo: boolean = false;
  @Input() oeeInfoText: string = '';
  @Input() targetColor: string = '';

  public cardTitle: string;

  constructor(public translate: TranslateService) {}

  ngOnInit(): void {
    this.cardTitle = this.translate.instant(`kpiCards.metrics.${this.cardTitleKey}`);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if ('progressBarRateCapped' in changes) {
      this.targetColor = this.color === undefined ? '#757575' : '#FFFFFF';
    }

    if ('target' in changes) {
      this.target = _.isNil(this.target) ? this.translate.instant('general.N/A') : `${this.target}%`;
    }
  }
}
