import { CommonModule } from '@angular/common';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { SharedModule } from '../../../../../../shared/shared.module';
import { combineLatest, Subscription, take } from 'rxjs';
import { DecimalHelper } from '../../../../../../shared/helper/decimal/decimal-helper';
import { select, Store } from '@ngrx/store';
import * as oeeAppReducer from '../../../../../../store/oee.reducer';
import { TStationQuantities } from '../../../../../../store/station-home/kpi-card/kpi-card.model';
import { DECIMAL_DEFAULT_SCALE_LIMIT, DECIMAL_NUMERIC_SCALE } from '../../../../../../../constants';
import { User } from '../../../../../../store/user/model';
import { IStationActivityHistoryCrud } from '../../../../../../store/station-activity-history/station-activity-history.model';

@Component({
  selector: 'scw-work-order-duration-metric-card',
  standalone: true,
  imports: [CommonModule, SharedModule],
  templateUrl: './work-order-duration-metric-card.component.html',
  styleUrls: ['./work-order-duration-metric-card.component.scss'],
})
export class WorkOrderDurationMetricCardComponent implements OnInit, OnDestroy {
  public actualDuration: string | null = null;
  public targetDuration: string | null = null;
  public targetRateCapped: number | null = null;
  public targetRate: string | null = null;

  private siteDecimalScaleLimit$: number;

  private subscriptions: Subscription[] = [];

  private stationQuantities$: TStationQuantities | null = null;
  private scheduledDurations$: {
    scheduledPreRun: string | null;
    scheduledRun: string | null;
    scheduledPostRun: string | null;
  };
  private quantityOrdered$: string | null = null;
  private productSpeed$: string | null = null;

  constructor(
    private readonly store: Store<oeeAppReducer.OeeAppState>,
    private readonly decimalHelper: DecimalHelper,
  ) {}

  public ngOnInit(): void {
    const stationHomeKPI$ = this.store.pipe(select('stationHomeKPIStore'));
    const stationActivityHistory$ = this.store.pipe(select('stationActivityHistoryStore'));

    this.store
      .select('user')
      .pipe(take(1))
      .subscribe((state: User) => {
        this.siteDecimalScaleLimit$ = state.siteDecimalScaleLimit;
      });

    this.subscriptions.push(
      combineLatest([stationHomeKPI$, stationActivityHistory$]).subscribe(
        ([stationHomeKPIState, stationActivityHistoryState]) => {
          const ongoingStationActivityHistory: IStationActivityHistoryCrud | null =
            stationActivityHistoryState.ongoingStationActivityHistory;

          if (!ongoingStationActivityHistory) {
            this.actualDuration = null;
            this.targetDuration = null;
            this.targetRate = null;
            this.targetRateCapped = null;

            return;
          }

          this.stationQuantities$ = stationHomeKPIState.stationQuantities;
          this.scheduledDurations$ = {
            scheduledRun: ongoingStationActivityHistory.workOrder?.scheduledRunDuration ?? null,
            scheduledPreRun: ongoingStationActivityHistory.workOrder?.scheduledPreDuration ?? null,
            scheduledPostRun: ongoingStationActivityHistory.workOrder?.scheduledPostDuration ?? null,
          };

          this.quantityOrdered$ = ongoingStationActivityHistory.workOrder?.quantityOrdered ?? '0';
          this.productSpeed$ = this.decimalHelper.convertToDecimal(
            ongoingStationActivityHistory.workOrder?.product?.productSpeed ?? 0,
          );

          this.calculateActualDuration();
          this.calculateTargetDuration();
          this.calculateTargetRates();
        },
      ),
    );
  }

  public calculateActualDuration(): void {
    const actualDuration = [
      this.stationQuantities$.downTimePlannedDuration ?? '0',
      this.stationQuantities$.downTimeUnplannedDuration ?? '0',
      this.stationQuantities$.idleTimeDuration ?? '0',
      this.stationQuantities$.runTimeDuration ?? '0',
    ].reduce((acc, curr) => DecimalHelper.add1(acc, curr), '0');
    const duration: string = this.decimalHelper.toFixedValue(
      this.decimalHelper.divide(actualDuration, '3600'),
      this.siteDecimalScaleLimit$,
    );

    this.actualDuration = !this.decimalHelper.isLessOrEqThan(duration, '0')
    ? duration
    : null;
  }

  public calculateTargetDuration(): void {
    if (
      this.scheduledDurations$.scheduledRun ||
      this.scheduledDurations$.scheduledPreRun ||
      this.scheduledDurations$.scheduledPostRun
    ) {
      const targetDuration = [
        this.scheduledDurations$.scheduledRun ?? '0',
        this.scheduledDurations$.scheduledPreRun ?? '0',
        this.scheduledDurations$.scheduledPostRun ?? '0',
      ].reduce((acc, curr) => DecimalHelper.add1(acc, curr), '0');
      const duration: string = this.decimalHelper.toFixedValue(targetDuration, this.siteDecimalScaleLimit$);

      this.targetDuration = !this.decimalHelper.isLessOrEqThan(duration, '0')
        ? duration
        : null;

      return;
    }

    const duration: string = this.decimalHelper.toFixedValue(
      this.decimalHelper.divide(this.quantityOrdered$, this.productSpeed$),
      this.siteDecimalScaleLimit$,
    );
    this.targetDuration = !this.decimalHelper.isLessOrEqThan(duration, '0')
      ? duration
      : null;
  }

  public calculateTargetRates(): void {
    const fixedActualDuration = this.decimalHelper.toFixedValue(
      this.decimalHelper.removeThousandSeparator(this.actualDuration),
      DECIMAL_NUMERIC_SCALE,
      false,
    );

    const fixedTargetDuration = this.decimalHelper.toFixedValue(
      this.decimalHelper.removeThousandSeparator(this.targetDuration),
      DECIMAL_NUMERIC_SCALE,
      false,
    );

    this.targetRate = this.decimalHelper.calculateRate(
      fixedActualDuration,
      fixedTargetDuration,
      DECIMAL_DEFAULT_SCALE_LIMIT,
    );
    this.targetRateCapped = this.decimalHelper.decimalToNumberFormatter(
      this.decimalHelper.calculateRate(
        fixedActualDuration,
        fixedTargetDuration,
        DECIMAL_DEFAULT_SCALE_LIMIT,
        false,
        true,
        true,
      ),
    );
  }

  public ngOnDestroy(): void {
    this.subscriptions.forEach((subscription) => subscription.unsubscribe());
  }
}
