import { Injectable } from '@angular/core';
import { catchError, switchMap } from 'rxjs/operators';
import * as ChangeoverActions from './changeover.actions';
import * as AppActions from '../../app/actions';
import { ChangeoverService } from './changeover.service';
import { of } from 'rxjs';
import { IChangeoverChartDataResponse, IChangeoverKpiDataResponse } from './changeover.model';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { BaseOneResponseInterface } from '../../../shared/model/interface/crud-response-interface.model';

@Injectable()
export class ChangeoverEffects {
  constructor(private readonly actions$: Actions, private readonly changeoverService: ChangeoverService) {}

  getChangeoverKpiInformation = createEffect(() =>
    this.actions$.pipe(
      ofType(ChangeoverActions.GET_CHANGEOVER_KPI_INFORMATION),
      switchMap((objectData: ChangeoverActions.GetChangeoverKpiInformation) => {
        return this.changeoverService.getChangeoverKpiInformation(objectData.payload).pipe(
          switchMap((response: BaseOneResponseInterface<IChangeoverKpiDataResponse[]>) => {
            return of(
              new ChangeoverActions.GetChangeoverKpiInformationCompleted(response.data),
              new AppActions.HideLoader(),
            );
          }),
          catchError((err) => {
            return of(new ChangeoverActions.FetchError(err));
          }),
        );
      }),
      catchError((err) => {
        return of(new ChangeoverActions.FetchError(err));
      }),
    ),
  );

  getChangeoverChartData = createEffect(() =>
    this.actions$.pipe(
      ofType(ChangeoverActions.GET_CHANGEOVER_CHART_DATA),
      switchMap((objectData: ChangeoverActions.GetChangeoverChartData) => {
        return this.changeoverService.getChangeoverChartData(objectData.payload).pipe(
          switchMap((response: BaseOneResponseInterface<IChangeoverChartDataResponse[]>) => {
            return of(
              new ChangeoverActions.GetChangeoverChartDataCompleted(response.data),
              new AppActions.HideLoader(),
            );
          }),
          catchError((err) => {
            return of(new ChangeoverActions.FetchError(err));
          }),
        );
      }),
      catchError((err) => {
        return of(new ChangeoverActions.FetchError(err));
      }),
    ),
  );
}
