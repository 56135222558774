<ng-template #base_modal>
  <div class="modal-header scw-modal-header">
    <div class="modal-title scw-header">
      <ng-content select="[title]"></ng-content>
    </div>

    <button type="button" class="close" onfocus="blur()" aria-label="Close" (click)="onClickCloseEmitter()">
      <span aria-hidden="true">
        <em class="fas fa-times"></em>
      </span>
    </button>
  </div>

  <div class="modal-body">
    <ng-content select="[body]"></ng-content>
  </div>

  <div class="modal-footer">
    <ng-content select="[footer]"></ng-content>
  </div>
</ng-template>

<scw-mat-form
  *ngIf="isForm === true; else not_form"
  (scwMatFormSubmit)="onFormSubmitEmitter($event)"
  [overrideButtons]="buttons"
  [overrideInputs]="inputs"
  [overrideSelects]="selects"
  [overrideDatePickers]="datePickers"
  [overridePickers]="pickers"
  [overrideCronInputs]="cronInputs"
  [overrideRadioGroups]="radioGroups"
  [overrideTimePickers]="timePickers"
  [overrideWeekdayPickers]="weekdayPickers"
>
  <ng-container *ngTemplateOutlet="base_modal"></ng-container>
</scw-mat-form>
<ng-template #not_form>
  <ng-container *ngTemplateOutlet="base_modal"></ng-container>
</ng-template>
