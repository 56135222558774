import { Inject, Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { IJobCRUD } from '../../component/filter/filter.class';
import { Observable } from 'rxjs';
import { GetManyResponseInterface } from '../../model/interface/crud-response-interface.model';

@Injectable({
  providedIn: 'root',
})
export class JobService {
  constructor(public http: HttpClient, @Inject('API_BASE_URL') private baseUrl: string) {}

  getData(options?: HttpParams): Observable<GetManyResponseInterface<IJobCRUD>> {
    return this.http.get<GetManyResponseInterface<IJobCRUD>>(`${this.baseUrl}/jobs`, { params: options });
  }

  public getJobs(params?: HttpParams): Observable<GetManyResponseInterface<IJobCRUD>> {
    return this.http.get<GetManyResponseInterface<IJobCRUD>>(`${this.baseUrl}/jobs`, {
      params,
    });
  }

  public getJob(id: number): Observable<{ id: number; jobName: string }> {
    return this.http.get<{ id: number; jobName: string }>(`${this.baseUrl}/jobs/${id}?fields=id,jobName`);
  }
}
