import { Actions, createEffect, ofType } from '@ngrx/effects';
import * as ObjectActions from './line-path-product-configuration.actions';
import { catchError, switchMap } from 'rxjs/operators';
import { HttpParams } from '@angular/common/http';
import { of } from 'rxjs';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import * as oeeAppReducer from '../../../oee.reducer';
import * as AppActions from '../../../app/actions';
import {
  BulkResponseDataInterface,
  GetManyResponseInterface,
} from '../../../../shared/model/interface/crud-response-interface.model';
import { ErrorMessageService } from '../../../../shared/service/error-message.service';
import { ExcelHelperService } from '../../../../shared/service/excel/excel-helper.service';
import { emptyAction } from '../../../../../constants';
import { LinePathProductConfigurationExcelService } from './line-path-product-configuration-excel.service';
import { ILinePathProductConfigurations } from './line-path-product-configuration.model';

@Injectable()
export class LinePathProductConfigurationEffects {
  constructor(
    private readonly actions$: Actions,
    private readonly store: Store<oeeAppReducer.OeeAppState>,
    private readonly linePathProductConfigurationExcelService: LinePathProductConfigurationExcelService,
    private readonly errorMessageService: ErrorMessageService,
    private readonly excelHelperService: ExcelHelperService,
  ) {}

  downloadLinePathExcel = createEffect(() =>
    this.actions$.pipe(
      ofType(ObjectActions.DOWNLOAD_LINE_PATH_PRODUCT_CONFIGURATION_EXCEL),
      switchMap((objectData: ObjectActions.DownloadLinePathProductConfigurationExcel) => {
        this.store.dispatch(new AppActions.ShowLoader());
        this.linePathProductConfigurationExcelService.downloadExcel(objectData.withData, objectData.filters);
        return emptyAction;
      }),
      catchError((error) => {
        return of(new ObjectActions.FetchError(error), new AppActions.HideLoader());
      }),
    ),
  );

  uploadLinePathExcel = createEffect(() =>
    this.actions$.pipe(
      ofType(ObjectActions.UPLOAD_LINE_PATH_PRODUCT_CONFIGURATION_EXCEL),
      switchMap((objectData: ObjectActions.UploadLinePathProductConfigurationExcel) => {
        this.store.dispatch(new AppActions.ShowLoader());

        return this.linePathProductConfigurationExcelService.uploadExcel(objectData.linePathProductConfiguration).pipe(
          switchMap((response: BulkResponseDataInterface) => {
            this.errorMessageService.getTranslatedErrorMessage(response.data);
            const mergedArray = this.excelHelperService.mergeBulkResponseWithRequestData<any>(
              response,
              objectData.linePathProductConfiguration.linePathConfigurations,
            );
            return of(
              new ObjectActions.UploadLinePathProductConfigurationExcelCompleted(mergedArray),
              new AppActions.HideLoader(),
            );
          }),
          catchError((error) => {
            return of(new ObjectActions.FetchError(error), new AppActions.HideLoader());
          }),
        );
      }),
      catchError((error) => {
        return of(new ObjectActions.FetchError(error), new AppActions.HideLoader());
      }),
    ),
  );

  getLinePathDataForCount = createEffect(() =>
    this.actions$.pipe(
      ofType(ObjectActions.LINE_PATH_PRODUCT_CONFIGURATION_COUNT_LOAD),
      switchMap((objectData: ObjectActions.LinePathProductConfigurationCountLoad) => {
        this.store.dispatch(new AppActions.ShowLoader());
        const httpParams: HttpParams = new HttpParams()
          .set('s', JSON.stringify({ siteId: { $eq: objectData.siteId } }))
          .append('limit', String(objectData.limit || 5000));

        return this.linePathProductConfigurationExcelService.getLinePathProductConfigurations(httpParams).pipe(
          switchMap((response: GetManyResponseInterface<ILinePathProductConfigurations>) => {
            return of(new ObjectActions.LinePathProductConfigurationCountLoaded(response), new AppActions.HideLoader());
          }),
          catchError((error) => {
            return of(new ObjectActions.FetchError(error), new AppActions.HideLoader());
          }),
        );
      }),
      catchError((error) => {
        return of(new ObjectActions.FetchError(error), new AppActions.HideLoader());
      }),
    ),
  );
}
