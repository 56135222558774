import { MaintenancePerformanceStateInterface } from './maintenance-performance.model';
import * as Actions from './maintenance-performance.actions';

export const maintenancePerformanceState: MaintenancePerformanceStateInterface = {
  maintenancePerformanceDataLoading: false,
  maintenancePerformanceDataLoaded: false,
  maintenancePerformanceData: null,
  tableSettings: [],
  filters: null,
  fileDownloadStatus: false,
};

export function maintenancePerformanceReducer(
  state: MaintenancePerformanceStateInterface = maintenancePerformanceState,
  action: Actions.MaintenancePerformanceActions,
): MaintenancePerformanceStateInterface {
  switch (action.type) {
    case Actions.MAINTENANCE_PERFORMANCE_DATA_LOADING:
      return {
        ...state,
        ...{
          maintenancePerformanceDataLoading: true,
          maintenancePerformanceDataLoaded: false,
        },
      };
    case Actions.MAINTENANCE_PERFORMANCE_DATA_LOADED:
      return {
        ...state,
        ...{
          maintenancePerformanceData: action.payload,
          maintenancePerformanceDataLoading: false,
          maintenancePerformanceDataLoaded: true,
        },
      };
    case Actions.SET_TABLE_SETTINGS:
      return {
        ...state,
        tableSettings: action.payload,
      };
    case Actions.SET_SELECTED_FILTERS:
      return {
        ...state,
        filters: action.filters,
      };
    case Actions.DOWNLOAD_MAINTENANCE_PERFORMANCE_EXCEL:
      return {
        ...state,
        fileDownloadStatus: true,
      };
    case Actions.DOWNLOAD_MAINTENANCE_PERFORMANCE_EXCEL_COMPLETED:
      return {
        ...state,
        fileDownloadStatus: false,
      };
    case Actions.FETCH_ERROR:
      return {
        ...state,
        maintenancePerformanceDataLoaded: false,
      };
    default:
      return state;
  }
}
