export enum EHomeMetrics {
  WORK_ORDER_DURATION = 'work_order_duration',
  ACTIVITY_DURATION = 'activity_duration',
  SENSOR_BADGES = 'sensor_badges',
  PRE_RUN_PHASE_DURATION = 'pre_run_phase_duration',
  RUN_PHASE_DURATION = 'run_phase_duration',
  POST_RUN_PHASE_DURATION = 'post_run_phase_duration',
  QUANTITY_WITH_COMMENT = 'quantity_with_comment',
  SPEED = 'speed',
  WORK_ORDER_OEE = 'work_order_oee',
  WORK_ORDER_OEE_SIX_HOURS = 'work_order_oee_six_hours',
  WORK_ORDER_OEE_TWELVE_HOURS = 'work_order_oee_twelve_hours',
  COUNT = 'count',
  ACTIVITIES_TOTAL_DURATION = 'activities_total_duration',
  SHIFT_OEE = 'shift_oee',
  JOB_OEE = 'job_oee',
  MINI_METRICS = 'mini_metrics',
}

export enum ELayout {
  PERCENTAGE = 'percentage',
  MULTIPLE_UNITS = 'multipleUnits',
}

export enum EDataSource {
  SENSOR = 'sensor',
  HOURLY = 'hourly',
  WORK_ORDER = 'workOrder',
  SHIFT = 'shift',
  JOB = 'job',
}

export enum ECountType {
  INITIAL = 'initial',
  GOOD = 'good',
  SCRAP = 'scrap',
}

export enum ETimeSpan {
  ONE_MINUTE = 'oneMinute',
  TEN_MINUTES = 'tenMinutes',
  CURRENT_ACTIVITY = 'currentActivity',
  LAST_ONE_HOUR = 'lastOneHour',
  LAST_TWO_HOURS = 'lastTwoHours',
  LAST_THREE_HOURS = 'lastThreeHours',
  WORK_ORDER_RUN = 'workOrderRun',
  WORK_ORDER_RUN_DOWN = 'workOrderRunDown',
  WORK_ORDER_RUN_DOWN_IDLE = 'workOrderRunDownIdle',
  CURRENT_SHIFT = 'currentShift',
  WORK_ORDER = 'workOrder',
  JOB = 'job',
}

export enum EUnitOfTime {
  MINUTE = 'minute',
  HOUR = 'hour',
  PERCENTAGE = 'percentage',
}

export enum EActualWorkOrderDuration {
  RUN_DURATION = 'runDuration',
  RUN_DOWN_DURATION = 'runDownDuration',
  RUN_DOWN_IDLE_DURATION = 'runDownIdleDuration',
  RUN_PHASE_DURATION = 'runPhaseDuration',
  PRE_RUN_PHASE_DURATION = 'preRunPhaseDuration',
  POST_RUN_PHASE_DURATION = 'postRunPhaseDuration',
  TOTAL_PHASE_DURATION = 'totalPhaseDuration',
}

export enum ETargetWorkOrderDuration {
  SCHEDULED_RUN_PHASE_DURATION = 'scheduledRunPhaseDuration',
  SCHEDULED_PRE_RUN_AND_RUN_PHASE_DURATION = 'scheduledPreRunAndRunPhaseDuration',
  SCHEDULED_POST_RUN_AND_RUN_PHASE_DURATION = 'scheduledPostRunAndRunPhaseDuration',
  SCHEDULED_TOTAL_PHASE_DURATION = 'scheduledTotalPhaseDuration',
  SCHEDULED_TOTAL_PHASE_DURATION_QUANTITY_BASED = 'scheduledTotalPhaseDurationQuantityBased',
  QUANTITY_ORDERED_PER_SPEED = 'quantityOrderedPerSpeed',
  TASK_TARGET_DURATION = 'taskTargetDuration',
}

export enum ETargetPreRunPhaseDuration {
  TASK_TARGET_DURATION = 'taskTargetDuration',
  SCHEDULED_PRE_RUN_PHASE_DURATION = 'scheduledPreRunPhaseDuration',
}

export enum ETargetPostRunPhaseDuration {
  TASK_TARGET_DURATION = 'taskTargetDuration',
  SCHEDULED_POST_RUN_PHASE_DURATION = 'scheduledPostRunPhaseDuration',
}

export enum ETargetWorkOrderDuration {
  MINUTE = 'minute',
  HOUR = 'hour',
}

export enum EPages {
  HOME = 'home',
  PRODUCTION_REVIEW = 'productionReview',
}

export enum EPropertyType {
  SPEED = 'speed',
  DURATION = 'duration',
  COUNT = 'count',
  ACTIVITIES_TOTAL_DURATION = 'activities_total_duration',
  ACTIVITY_DURATION = 'activity_duration',
  MINI_METRICS = 'mini_metrics',
  WORK_ORDER_OEE = 'work_order_oee',
  WORK_ORDER_OEE_SIX_HOURS = 'work_order_oee_six_hours',
  WORK_ORDER_OEE_TWELVE_HOURS = 'work_order_oee_twelve_hours',
  SHIFT_OEE = 'shift_oee',
  WORK_ORDER_DURATION = 'work_order_duration',
  PRE_RUN_PHASE_DURATION = 'pre_run_phase_duration',
  POST_RUN_PHASE_DURATION = 'post_run_phase_duration',
}

export const DEFAULT_LAYOUT_NAME: string = 'scwDefaultLayout';

export type HomeMetricIdTypes =
  | EHomeMetrics.WORK_ORDER_DURATION
  | EHomeMetrics.ACTIVITY_DURATION
  | EHomeMetrics.SENSOR_BADGES
  | EHomeMetrics.PRE_RUN_PHASE_DURATION
  | EHomeMetrics.RUN_PHASE_DURATION
  | EHomeMetrics.POST_RUN_PHASE_DURATION
  | EHomeMetrics.QUANTITY_WITH_COMMENT
  | EHomeMetrics.SPEED
  | EHomeMetrics.WORK_ORDER_OEE
  | EHomeMetrics.WORK_ORDER_OEE_SIX_HOURS
  | EHomeMetrics.WORK_ORDER_OEE_TWELVE_HOURS
  | EHomeMetrics.COUNT
  | EHomeMetrics.ACTIVITIES_TOTAL_DURATION
  | EHomeMetrics.SHIFT_OEE
  | EHomeMetrics.JOB_OEE
  | EHomeMetrics.MINI_METRICS;

export type LayoutTypes = ELayout.PERCENTAGE | ELayout.MULTIPLE_UNITS;

export type DataSourceTypes =
  | EDataSource.SENSOR
  | EDataSource.HOURLY
  | EDataSource.WORK_ORDER
  | EDataSource.SHIFT
  | EDataSource.JOB;

export type CountTypeTypes = ECountType.INITIAL | ECountType.GOOD | ECountType.SCRAP;

export type UnitOfTimeTypes = EUnitOfTime.MINUTE | EUnitOfTime.HOUR | EUnitOfTime.PERCENTAGE;

export type TimeSpanTypes =
  | ETimeSpan.ONE_MINUTE
  | ETimeSpan.TEN_MINUTES
  | ETimeSpan.CURRENT_ACTIVITY
  | ETimeSpan.LAST_ONE_HOUR
  | ETimeSpan.LAST_TWO_HOURS
  | ETimeSpan.LAST_THREE_HOURS
  | ETimeSpan.WORK_ORDER_RUN
  | ETimeSpan.WORK_ORDER_RUN_DOWN
  | ETimeSpan.WORK_ORDER_RUN_DOWN_IDLE
  | ETimeSpan.CURRENT_SHIFT
  | ETimeSpan.WORK_ORDER
  | ETimeSpan.JOB;

export type ActualWorkOrderDurationTypes =
  | EActualWorkOrderDuration.RUN_DURATION
  | EActualWorkOrderDuration.RUN_DOWN_DURATION
  | EActualWorkOrderDuration.RUN_DOWN_IDLE_DURATION
  | EActualWorkOrderDuration.RUN_PHASE_DURATION
  | EActualWorkOrderDuration.PRE_RUN_PHASE_DURATION
  | EActualWorkOrderDuration.POST_RUN_PHASE_DURATION
  | EActualWorkOrderDuration.TOTAL_PHASE_DURATION;

export type TargetWorkOrderDurationTypes =
  | ETargetWorkOrderDuration.SCHEDULED_RUN_PHASE_DURATION
  | ETargetWorkOrderDuration.SCHEDULED_PRE_RUN_AND_RUN_PHASE_DURATION
  | ETargetWorkOrderDuration.SCHEDULED_POST_RUN_AND_RUN_PHASE_DURATION
  | ETargetWorkOrderDuration.SCHEDULED_TOTAL_PHASE_DURATION
  | ETargetWorkOrderDuration.SCHEDULED_TOTAL_PHASE_DURATION_QUANTITY_BASED
  | ETargetWorkOrderDuration.QUANTITY_ORDERED_PER_SPEED
  | ETargetWorkOrderDuration.TASK_TARGET_DURATION;

export type TargetPreRunPhaseDurationTypes =
  | ETargetPreRunPhaseDuration.SCHEDULED_PRE_RUN_PHASE_DURATION
  | ETargetPreRunPhaseDuration.TASK_TARGET_DURATION;

export type TargetPostRunPhaseDurationTypes =
  | ETargetPostRunPhaseDuration.SCHEDULED_POST_RUN_PHASE_DURATION
  | ETargetPostRunPhaseDuration.TASK_TARGET_DURATION;

export type PageTypes = EPages.HOME | EPages.PRODUCTION_REVIEW;

export enum EShiftSplit {
  YES = 'yes',
  NO = 'no',
}

export type ShiftSplitTypes = EShiftSplit.YES | EShiftSplit.NO;
