import { DateRangeReturnInterface } from '../../shared/component/date-range-picker/date-range-picker.model';
import { WorkOrderScheduleInterface } from '../work-order-schedule/work-order-schedule.model';
import { GanttScenarioItem, GanttTreeEventInterface, TaskType } from '../../view/scheduler/gantt-view/gantt-view.model';
import { PayloadInterface, ResponseInterface } from '../../shared/model/interface/generic-api-response.model';
import { ActivitiesInterface } from '../../shared/model/interface/activities.model';
import { WorkOrdersFilterOutputInterface } from '../../view/work-orders-new-design/work-orders-new-design.model';
import { IFilter } from '../../shared/component/filter/advanced-filter/advanced-filter.model';
import { IObjectWithEntityTranslations } from '../../shared/service/entity-translator/entity-translator.model';
import { AssignmentModelConfig } from '@bryntum/schedulerpro';
import { Subscriber } from 'rxjs';
import {
  BaseOneResponseInterface,
  GetManyResponseInterface,
} from '../../shared/model/interface/crud-response-interface.model';
import { IWorkOrderScheduleWithRules } from '../scheduler-work-order-set/scheduler-work-order-set.model';
import { ISchedulerResourceSetting } from '../../view/scheduler-resource-settings/scheduler-resource-settings.model';

export interface ScheduleStateInterface {
  scenario: ScenarioInterface;
  scenarioItems: ScenarioItemInterface[];
  deployedItems: ScenarioItemInterface[];
  actualItems: ScenarioItemInterface[];
  uncreatedWorkOrders: IScenarioUncreatedWorkOrderResponse[];
  groupedJobsForUncreatedWorkOrders: { [key: number]: string };
  scenarioResources: object;
  plannedDownTimeActivities: object[];
  scenarioLoading: boolean;
  scenarioLoaded: boolean;
  scenarioItemsLoading: boolean;
  scenarioItemsLoaded: boolean;
  isScenarioItemsLoadedToGanttChart: boolean;
  applyData: ApplyDataInterface;
  scenarioItemsSaveLoading: boolean;
  scenarioItemsSaveLoaded: boolean;
  scenarioItemsDeployLoading: boolean;
  scenarioItemsDeployLoaded: boolean;
  fromDate: Date;
  lastFromDate: Date;
  toDate: Date;
  lastToDate: Date;
  showApplyButton: boolean;
  capacityUtilizationKpiLoaded: boolean;
  capacityUtilizationKpiLoading: boolean;
  capacityUtilizationKpi: CapacityUtilizationInterface[];
  scheduledQualityKpiLoaded: boolean;
  scheduledQualityKpiLoading: boolean;
  scheduledQualityKpi: ScheduledQualityInterface[];
  workOrderKpiLoaded: boolean;
  workOrderKpiLoading: boolean;
  workOrderKpi: SchedulerWorkOrderInterface[];
  errorKpiLoaded: boolean;
  errorKpiLoading: boolean;
  errorKpi: ErrorInterface[];
  scenarioGanttData: ScenarioGanttDataInterface;
  unplannedWorkOrders: IWorkOrderScheduleWithRules[];
  downTimeActivities: ActivitiesInterface[];
  unplannedWorkOrdersIsLoading: boolean;
  downTimeActivitiesIsLoading: boolean;
  unplannedWorkOrdersIsLoaded: boolean;
  downTimeActivitiesIsLoaded: boolean;
  scenarioPrepared: boolean;
  ganttFilterInitiated: boolean;
  shouldShowRefreshButton: boolean;
  scenarioPlansLoading: boolean;
  scenarioPlansLoaded: boolean;
  scenarioShiftPlans: ISchedulerShift[];
  scenarioWorkOrderRulesLoading: boolean;
  scenarioWorkOrderRulesLoaded: boolean;
  scenarioAdvancedFilterLoading: boolean;
  scenarioAdvancedFilterLoaded: boolean;
  workOrderModalIsOpen: boolean;
  checkWorkOrderLoading: boolean;
  checkWorkOrderLoaded: boolean;
  kpiMetricInfoLoading: boolean;
  kpiMetricInfoLoaded: boolean;
  kpiMetricInfo: ISchedulerKpiMetricInfo | null;
  shouldSaveKpiCardData: boolean;
  kpiCardData: IKpiCardData;
  schedulerOptimizationsLoading: boolean;
  schedulerOptimizationsLoaded: boolean;
  isShowSchedulerOptimizationMode: boolean;
  schedulerAllOptimizationsData: ISchedulerAllOptimizations;
  isOnHoldSaveKpiCardData: boolean;
  viewStatus: string;
  isSavingSchedulerKpiCardData: boolean;
  optimizationAlgorithmsLoading: boolean;
  optimizationAlgorithmsLoaded: boolean;
  optimizationAlgorithms: IOptimizationAlgorithms[] | null;
}

export interface IKpiCardData {
  components: {
    [key in
      | 'SchedulerKpiWorkOrderComponent'
      | 'SchedulerKpiScheduleQualityComponent'
      | 'SchedulerKpiErrorComponent'
      | 'SchedulerKpiCapacityUtilizationComponent']?: boolean;
  };
  properties: IKpiCardDataProperties;
}

export interface IKpiCardDataProperties {
  ordered?: number;
  woKpiPlanned?: number;
  woKpiDeployed?: number;
  actual?: number;
  plannedOrdered?: string;
  deployedOrdered?: string;
  actualOrdered?: string;
  deployedPlanned?: string;
  actualPlanned?: string;
  actualDeployed?: string;
  totalCapacityUtilization?: string;
  totalCost?: string;
  totalOeePrediction?: string;
  totalAPrediction?: string;
  totalPPrediction?: string;
  totalQPrediction?: string;
  makeSpan?: number;
  countOfViolation?: number;
  countOfWarning?: number;
  expectedFailurePercentage?: string;
  deployed?: number;
  planned?: number;
  totalOtifPrediction?: string;
  totalOnTimePrediction?: string;
  totalInFullPrediction?: string;
  jobOrdered?: number;
  jobPlanned?: number;
  jobDeployed?: number;
  jobActual?: number;
  jobPlannedOrdered?: string;
  jobDeployedOrdered?: string;
  jobActualOrdered?: string;
  jobDeployedPlanned?: string;
  jobActualPlanned?: string;
  jobActualDeployed?: string;
  jobCountOfViolation?: number;
}

export interface ISchedulerEventModel extends GanttScenarioItem {
  jobNumber: number;
}

export interface CapacityUtilizationInterface {
  id: number;
  title: string;
  scheduledHours: number;
}

export interface ScheduledQualityInterface {
  id: number;
  title: string;
  type: string;
  value: string;
}

export interface SchedulerWorkOrderInterface {
  type: string;
  percentage: string;
  value: string;
}

export interface ErrorItemsInterface {
  title: string;
  items: ErrorItem[];
}

export interface ErrorInterface {
  title: string;
  items: ErrorItemsInterface[];
}

export interface ErrorItem {
  workOrder: string;
  lineName: string;
  dateTime: string;
}

export interface ScenarioGanttDataInterface {
  columns: ScenarioGanttColumnsInterface[];
  resources: ScenarioGanttResourcesInterface[];
  events: ScenarioGanttEventsInterface[];
  startDate: Date;
  endDate: Date;
  viewPreset: string;
  groupFeature: string;
  isGanttDataLoading: boolean;
  isGanttDataLoaded: boolean;
}

export interface ScenarioGanttColumnsInterface {
  field: string;
  text: string;
  width?: number;
  hidden?: boolean;
}

export interface ScenarioGanttResourcesInterface {
  id: number;
  lineType: string;
  lineTypeId?: number;
  name: string;
  siteId: number;
}

export interface ScenarioGanttEventsInterface {
  id: number;
  jobId: number; // Not Exist.
  resourceId: number; // Not Exist. Aslında 'lineId' alanı.
  woNumber: string;
  productName: string; // TODO: Add new join connection.
  woColor: string; // Not Exist.
  packageSize?: string | null;
  releaseDate: string;
  woDueDate: string | null;
  dueDate: string; // Not Exist.
  scheduledStartDateFromScheduler: string; // Name change: scheduledRunDate
  scheduledEndDateFromScheduler: string; // Name change: scheduledEndDate
  startDate: string; // Not exist.
  endDate: string; // Not exist.
  scheduledLine: string;
  actualStartDateTime: string; // Not exist
  actualRunDateTime: string; // Name change: actualRunDate
  name: string; // Not exist
  job: string; // Name change: jobNumber
  productId: string; // Interface change: number
  scheduledLineIds: number | number[]; // Name and interface change: scheduledLine | number
  scheduledSiteId: number; // Not exist // TODO Maybe add a new join.
  site: string; // TODO: Add new join connection. Is it siteId?
  notes: string; // Not exist
  quantityOrdered: string; // interface change: number
  actualQuantity: string; // Not exist
}

export interface ListViewHeaderInterface {
  value: string;
  name: string;
}

export interface DropdownInterface<T> {
  id: number;
  itemName: T;
  prop: string;
  selected?: boolean;
  disabled?: boolean;
}

export interface ScenarioInterface {
  endDate: string;
  id: number;
  name: string;
  resourceSettingId: number;
  schSetting: ISchedulerResourceSetting;
  scenarioNumber: number;
  siteId: number;
  startDate: string;
  workOrderFilters: WorkOrdersFilterOutputInterface;
  advancedFilterConfig?: { [key: string]: IFilter[] };
  site?: { name?: string; decimalScaleLimit?: number; preRunPhaseName?: string; postRunPhaseName?: string };
  laborCostSource?: number;
  oeePredictionMethod?: number;
}

export interface ScenarioDeployDataInterface extends IObjectWithEntityTranslations {
  activityId: number;
  endDate: string;
  startDate: string;
  workOrderScheduleId: number;
  parentId?: number | null;
  selectedLinePathId?: number | null;
}

export interface ScenarioBaseItemInterface {
  activity: {
    id: number;
    name: string;
    activityPlannedType?: number;
  };
  id: number | string;
  name: string;
  line: {
    id: number;
    title: string;
  };
  title: string;
  lineId: number | string;
  scenarioId: number;
  workOrderSchedule: Partial<IWorkOrderScheduleForScheduler> & { totalGoodCount?: string };
  type?: TaskType;
}

export interface IWorkOrderScheduleForScheduler extends WorkOrderScheduleInterface {
  job?: {
    id: number;
    jobName: string;
    currentJobName?: string;
    isUncreatedJob?: boolean;
  };
}

export interface ScenarioItemInterface extends ScenarioDeployDataInterface, ScenarioBaseItemInterface {
  linesOrActivityHistoriesRecordId?: number;
  siteId?: number;
  isUncreatedWorkOrder?: boolean;
}

export interface ApplyDataInterface {
  scenarioId?: number;
  horizon?: DateRangeReturnInterface;
  resourceId?: number;
  scenarioItems?: any[];
  workOrderFilters?: any;
}

export interface ScenarioResponseInterface extends ResponseInterface<ScenarioInterface> {}

export interface ScenarioItemsResponseInterface extends ResponseInterface<ScenarioItemInterface[]> {}

export interface ScenarioItemSaveInterface {
  scenarioId?: number;
  lineId?: number;
  startDate: string;
  endDate: string;
  workOrderScheduleId?: number;
  activityId?: number;
  activityPlannedType?: number;
  scheduledLinePathId?: number;
}

export interface SchedulerListViewInterface extends WorkOrderScheduleInterface {
  startDate: string;
  endDate: string;
  plannedDuration: string;
  plannedLine: string;
  averageDuration: number;
  lineTypeName: string;
  actualLineName?: string;
  workOrderSchedule?: IUncreatedWorkOrderScheduler;
}

export interface ScenarioWorkOrderRulesInterface {
  scenarioId: number;
  workOrderId: number;
  isBlack: boolean;
}

export interface ScenarioAdvancedFilterAddRequestInterface {
  scenarioId: number;
  pageName: string;
  filters: IFilter[];
}

export interface ISchedulerKpiMetricOptionalProperties {
  title?: string;
  lineTypeName?: {
    id: number;
    lineType: string;
  };
}

export interface ISchedulerKpiMetricCostInfo extends ISchedulerKpiMetricOptionalProperties {
  lineId: number;
  costOfItems: string;
  regularTimeCost: string;
  overTimeCost: string;
}

export interface ISchedulerKpiMetricOeePredictionTotalInfo extends ISchedulerKpiMetricOptionalProperties {
  availabilityPerInterval: string;
  performancePerInterval: string;
  qualityPerInterval: string;
  overallOee: string;
}

export interface ISchedulerKpiMetricOeePredictionInfo extends ISchedulerKpiMetricOeePredictionTotalInfo {
  lineId: number;
}

export interface IUncreatedJob {
  jobId: number;
  jobName: string;
}

export interface ISchedulerKpiMetricViolationsAndWarningsInfo extends ISchedulerKpiMetricOptionalProperties {
  lineId: number;
  workOrderId: number;
  woNumber: string;
  releaseDate: string | null;
  woDueDate: string | null;
  productId: string;
  releaseDateViolation: boolean;
  dueDateViolation: boolean;
  productFirstTimeOnLineWarning: boolean;
  job: IUncreatedJob;
  isSplit: boolean;
}

export interface ISchedulerKpiMetricCapacityInfo extends ISchedulerKpiMetricOptionalProperties {
  lineId: number | null;
  scheduledDurationHour: string;
  totalAvailableDurationHour: string;
}

export interface ISchedulerKpiMetricFailurePredictionInfo extends ISchedulerKpiMetricOptionalProperties {
  lineId: number;
  totalDuration: string;
  totalFailureDuration: string;
  failureDurationPrediction: string;
}

export enum ESchedulerKpiWorkOrderComponent {
  orderedWorkOrderCount = 'orderedWorkOrderCount',
  plannedWorkOrders = 'plannedWorkOrders',
  deployedWorkOrders = 'deployedWorkOrders',
  actualWorkOrders = 'actualWorkOrders',
  plannedVsOrdered = 'plannedVsOrdered',
  deployedVsOrdered = 'deployedVsOrdered',
  actualVsOrdered = 'actualVsOrdered',
  deployedVsPlanned = 'deployedVsPlanned',
  actualVsPlanned = 'actualVsPlanned',
  actualVsDeployed = 'actualVsDeployed',
}

export interface ISchedulerKpiMetricConfig {
  SchedulerKpiWorkOrderComponent?: ESchedulerKpiWorkOrderComponent[];
  SchedulerKpiCapacityUtilizationComponent?: string;
}

export interface ISchedulerKpiMetricInfo {
  cost: ISchedulerKpiMetricCostInfo[];
  oeePrediction: ISchedulerKpiMetricOeePredictionInfo[];
  violationsAndWarnings: ISchedulerKpiMetricViolationsAndWarningsInfo[];
  capacity: ISchedulerKpiMetricCapacityInfo[];
  oeePredictionTotal: ISchedulerKpiMetricOeePredictionTotalInfo;
  failurePrediction: ISchedulerKpiMetricFailurePredictionInfo[];
  kpiConfig?: ISchedulerKpiMetricConfig;
  inventoryInfo: ISchedulerKpiMetricInventoryInfo[];
  serviceInfo: ISchedulerKpiMetricServiceInfo[];
}

export interface ISchedulerOptimizationResponse {
  id: number;
  optimizations: ISchedulerOptimizationItem[];
  unPlannedWorkOrderIds: number[];
  scenarioId: number;
  status: ESchedulerOptimizationStatus;
  optimizationType: string;
  optimizationKpis: ISchedulerOptimizationKpis;
  createdBy: number | null;
  createdAt: string | null;
}

export interface ISchedulerAllOptimizations {
  completedOptimizations: ISchedulerOptimizationResponse[];
  allOptimizations: ISchedulerOptimizationResponse[];
}

export interface ISchedulerOptimizationItem extends ISchedulerOptimizationBase {
  workOrderId: number | null;
  activityId: number | null;
  lineId: number;
  startDate: string;
  endDate: string;
  jobId: number | null;
  jobName: string | null;
  scheduledLinePathId: number | null;
  childWorkOrders: IOptimizationChildItem[];
}

export interface IOptimizationChildItem extends ISchedulerOptimizationBase {
  workOrderName: string;
  parentWorkOrderId: number;
  sequenceNumber: number | null;
  quantityOrdered?: string | null;
}

export interface ISchedulerOptimizationBase {
  lineId: number;
  startDate: string;
  endDate: string;
}

export interface ISchedulerContent {
  unPlannedWorkOrders: WorkOrderScheduleInterface[];
  orderedWorkOrderCount: number;
  planItems: GanttScenarioItem[];
  optimizationType: string;
  planName: string;
  optimizationId: number | null;
  operationType?: EOptimizationOperationType;
}

export interface IUpdateSchedulerOptimization {
  status?: ESchedulerOptimizationStatus;
  optimizationType?: string;
}

export enum ESchedulerOptimizationStatus {
  PENDING = 0,
  COMPLETED = 1,
  STOPPED = 2,
  SAVED = 3,
  DISCARDED = 4,
  NOT_STARTED = 5,
}

export interface ISchedulerModal {
  algorithm: string;
  status: ESchedulerOptimizationStatus;
  isCustomAlgorithm?: boolean;
  isDisableAlgorithm?: boolean;
}

export enum EOptimizationOperationType {
  APPLY = 'apply',
  REMOVE = 'remove',
}

export interface ISchedulerKpiMetricInventoryInfo {
  productId: string;
  productTableId: number;
  daysOnStart: number;
  daysOnEnd: number;
}

export interface ISchedulerKpiMetricServiceInfo {
  customerId: number;
  customerSegment: string;
  customerName: string;
  productId: string;
  numberOfTargetWorkOrder: number;
  numberOfOnTimeWorkOrder: number;
  numberOfInFullWorkOrder: number;
  numberOfOnTimeInFullWorkOrder: number;
}

export interface ILoadEventData {
  actualEvents?: GanttTreeEventInterface[];
  scheduledEvents?: GanttTreeEventInterface[];
  deployedEvents?: GanttTreeEventInterface[];
}

export interface IUnsavedChangesSubject {
  observable?: Subscriber<boolean>;
  modalResult?: boolean;
  isSaveButtonDisabled?: boolean;
  isWaitingToSave?: boolean;
}

export interface ICheckForDeployItemsResponse {
  workOrderName: string;
  lineName: string;
  startDate: string;
  endDate: string;
  parentId: number | null;
}

export interface IScenarioUncreatedWorkOrder {
  scenarioId: number;
  parentWorkOrderId: number;
  siteId: number;
  lineId: number;
  workOrderIndex: number;
  startDate: string | Date;
  endDate: string | Date;
  jobId: number | null;
  jobName: string | null;
  scheduledLinePathId: number;
  sequenceNumber: number | null;
  id?: number;
}

export interface IScenarioUncreatedWorkOrderResponse extends ScenarioItemInterface {
  workOrderIndex: string;
  jobId: number | null;
  jobName: string | null;
  scheduledLinePathId: number;
  sequenceNumber: number | null;
  quantityOrdered?: string | null;
}

export interface IScenarioGanttAllItemsResponse {
  scenarioItems: ScenarioItemsResponseInterface;
  uncreatedWorkOrders: GetManyResponseInterface<IScenarioUncreatedWorkOrderResponse>;
  groupedJobsForUncreatedWorkOrders: BaseOneResponseInterface<{ [key: number]: string }>;
}

export interface IFormattedAndGroupedGanttItems {
  uncreatedWorkOrders: IScenarioUncreatedWorkOrder[];
  scenarioItems: GanttScenarioItem[];
}

export interface ISchedulerOptimizationKpis {
  workOrdersCardKpi: ISchedulerOptimizationWorkOrdersCardKpi;
  capacityUtilizationCardKpi: TSchedulerOptimizationCapacityUtilizationCardKpi;
  schedulePerformanceCardKpi: ISchedulerOptimizationSchedulePerformanceCardKpi;
  scheduleQualityCardKpi: IScheduleQualityCardKpiData;
}

export interface ISchedulerOptimizationWorkOrdersCardKpi {
  plannedJobCount: number;
  orderedJobCount: number;
  plannedWorkOrderCount: number;
  uncreatedWorkOrderCount: number;
  unplannedWorkOrderCount: number;
}

export type TSchedulerOptimizationCapacityUtilizationCardKpi = ISchedulerOptimizationLineCapacityUtilization[];

export interface ISchedulerOptimizationLineCapacityUtilization {
  lineId: number;
  lineName: string;
  lineType: string;
  lineTypeId: number;
  scheduledDurationHour: string;
  totalAvailableDurationHour: string;
}

export interface ISchedulerOptimizationSchedulePerformanceCardKpi {
  costInfo: ISchedulerKpiMetricCostInfo[];
  oeePrediction: ISchedulerOptimizationOeePrediction[];
  totalOeePrediction: ISchedulerOptimizationTotalOeePrediction;
  inventoryInfo: ISchedulerKpiMetricInventoryInfo[];
  serviceInfo: ISchedulerKpiMetricServiceInfo[];
}

export interface ISchedulerOptimizationOeePrediction {
  lineId: number;
  lineName: string;
  lineTypeName: string;
  availabilityPerInterval: string;
  performancePerInterval: string;
  qualityPerInterval: string;
  overallOee: string;
}

export interface ISchedulerOptimizationTotalOeePrediction {
  averageAvailabilityPerInterval: string;
  averagePerformancePerInterval: string;
  averageQualityPerInterval: string;
  averageOverallOee: string;
}

export type TSchedulerOptimizationOtifCountKey =
  | 'numberOfTargetWorkOrder'
  | 'numberOfOnTimeWorkOrder'
  | 'numberOfInFullWorkOrder'
  | 'numberOfOnTimeInFullWorkOrder';

export enum ESchedulePerformanceKpiTabs {
  SERVICE = 'service',
  INVENTORY = 'inventory',
  COST = 'cost',
  OEE = 'oeePrediction',
}

export interface IScheduleQualityCardKpiData {
  violationsAndWarnings: IScheduleQualityViolationAndWarning[];
  failureDurationPredictions: IScheduleQualityFailureDurationPrediction[];
}

export interface IScheduleQualityViolationAndWarning {
  lineId: number;
  lineName: string;
  lineType: string;
  lineTypeId: number;
  workOrderId: number;
  woNumber: string;
  releaseDate: string | null;
  woDueDate: string | null;
  productId: string;
  releaseDateViolation: boolean;
  dueDateViolation: boolean;
  productFirstTimeOnLineWarning: boolean;
  job: IUncreatedJob;
  isSplit: boolean;
}

export interface IScheduleQualityFailureDurationPrediction {
  lineId: number;
  lineName: string;
  lineType: string;
  lineTypeId: number;
  totalDuration: string;
  totalFailureDuration: string;
  failureDurationPrediction: string;
}

export type TSchedulerOptimizationSummaryTableDataEntry = {
  value: string | number;
  isOptimal?: boolean;
};

export type TFormattedOptimizationKpiSegment<T extends string> = {
  [key in T]: TSchedulerOptimizationSummaryTableDataEntry;
};

export type TFormattedOptimizationWorkOrderCardKpiKeys =
  | 'ordered'
  | 'woKpiPlanned'
  | 'plannedOrdered'
  | 'jobOrdered'
  | 'jobPlanned'
  | 'jobPlannedOrdered';

export type TFormattedOptimizationCapacityUtilizationCardKpiKeys = 'totalCapacityUtilization';

export type TFormattedOptimizationSchedulePerformanceCardKpiKeys =
  | 'totalCost'
  | 'totalOeePrediction'
  | 'totalAPrediction'
  | 'totalPPrediction'
  | 'totalQPrediction'
  | 'totalOtifPrediction'
  | 'totalOnTimePrediction'
  | 'totalInFullPrediction';

export type TFormattedOptimizationScheduleQualityCardKpiKeys =
  | 'countOfViolation'
  | 'countOfWarning'
  | 'expectedFailurePercentage'
  | 'jobCountOfViolation';

export interface IFormattedTotalOeePrediction {
  totalAPrediction: string;
  totalPPrediction: string;
  totalQPrediction: string;
  totalOeePrediction: string;
}

export interface IViolationsGroupedByConditions {
  dueDateViolation: (IScheduleQualityViolationAndWarning & { id: number })[];
  releaseDateViolation: (IScheduleQualityViolationAndWarning & { id: number })[];
  productFirstTimeOnLineWarning: (IScheduleQualityViolationAndWarning & { id: number })[];
}

export type TSchedulerOptimizationSummaryTableDataFields =
  | 'algorithmName'
  | 'calculatedAt'
  | TFormattedOptimizationWorkOrderCardKpiKeys
  | TFormattedOptimizationCapacityUtilizationCardKpiKeys
  | TFormattedOptimizationSchedulePerformanceCardKpiKeys
  | TFormattedOptimizationScheduleQualityCardKpiKeys;

export type TSchedulerOptimizationSummaryTableData = {
  [key in TSchedulerOptimizationSummaryTableDataFields]: TSchedulerOptimizationSummaryTableDataEntry;
};

export interface ILinePathDetailsBySiteId {
  lineId: number;
  linePathId: number;
  linePathName: string;
  productIds: string;
}

export interface IGroupedJobsForUncreatedWorkOrdersRequestParams {
  siteId: number;
  scenarioId: number;
  resourceSettingId: number;
  startDate: string;
  endDate: string;
}

export interface ISchedulerListViewItems {
  id?: number;
  lineId?: number;
  category: ESchedulerListViewCategory;
  eventType: string;
  woNumber?: string;
  jobName?: string;
  productName?: string;
  productDescription?: string;
  activityName?: string;
  activityPhase?: number;
  startDate: string;
  endDate: string;
  plannedDuration?: number;
  woDueDate?: string;
  releaseDate?: string;
  quantityOrdered?: string;
  goodCount?: number;
  actualLineName?: string;
  actualStartDate?: string;
  actualEndDate?: string;
  actualDuration?: number;
  actualDurationWithoutFormat?: number;
  plannedDurationWithoutFormat?: number;
}

export enum ESchedulerListViewCategory {
  Planned = 'planned',
  Actual = 'actual',
  Deployed = 'deployed',
}

export interface IUncreatedWorkOrderScheduler {
  actualLine: number;
  actualRunDate: string;
  actualEndDate: string;
  actualDuration: string;
  totalGoodCount: string;
  quantityOrdered: string;
  job: {
    id: number;
    jobName: string;
  };
  product: {
    productId: string;
    description: string;
  };
  releaseDate: string;
  woDueDate: string;
  woNumber: string;
}

export interface ISchedulerListViewDownloadExcelPayload {
  data: ISchedulerListViewItems[];
  headers: ListViewHeaderInterface[];
}

export enum EWorkOrderSetTabs {
  ITEMS_TO_BE_PLANNED = 'itemsToBePlanned',
  EXCLUDED = 'excluded',
}

export interface IScenarioWorkOrderRulesWithId extends ScenarioWorkOrderRulesInterface {
  id: number;
}

export interface ISaveSchedulerWorkOrderRules extends ScenarioWorkOrderRulesInterface {
  id?: number;
}

export interface ISaveManySchedulerWorkOrderRules {
  workOrderRules: ISaveSchedulerWorkOrderRules[];
  removedWorkOrderRuleIds?: number[] | null;
  scenarioId: number;
}

export interface ISchedulerShift {
  endDate: string;
  isNotAvailable: boolean;
  lineId: number;
  scenarioId: number;
  startDate: string;
}

export interface IOptimizationAlgorithms {
  name: string;
  type: EAlgorithmType;
  disabled: boolean;
}

export enum EAlgorithmType {
  STANDARD = 'standard',
  CUSTOM = 'custom',
}
