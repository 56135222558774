import { Inject, Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { HelperService } from '../helper.service';
import { GenericCrudRequestConstructionParameters } from '../../model/interface/generic-api-request.model';
import { Observable } from 'rxjs';
import {
  BaseOneResponseInterface,
  BulkResponseDataInterface,
  GetManyResponseInterface,
} from '../../model/interface/crud-response-interface.model';
import { HTTPHelperService } from '../http/http.helper.service';
import {
  IProductResourceGroup,
  IProductResourceGroupCreateRequest,
  IProductResourceGroupUpdateRequest,
} from '../../../store/settings/product-resource-groups/product-resource-groups.model';

@Injectable({
  providedIn: 'root',
})
export class ProductResourceGroupsService {
  private readonly baseRoute: string = '/product-resource-groups';

  constructor(
    public http: HttpClient,
    @Inject('API_BASE_URL') private readonly api: string,
    public helperService: HelperService,
    public httpHelperService: HTTPHelperService,
  ) {}

  public getResourceGroups(
    serviceParameters: GenericCrudRequestConstructionParameters,
  ): Observable<GetManyResponseInterface<IProductResourceGroup>> {
    let params: HttpParams = this.httpHelperService.insertGenericCrudRequestParameters(serviceParameters);

    return this.http.get<GetManyResponseInterface<IProductResourceGroup>>(`${this.api}${this.baseRoute}`, {
      params,
    });
  }

  public createResourceGroup(
    requestData: IProductResourceGroupCreateRequest,
  ): Observable<BaseOneResponseInterface<IProductResourceGroup>> {
    return this.http.post<BaseOneResponseInterface<IProductResourceGroup>>(`${this.api}${this.baseRoute}`, requestData);
  }

  public updateResourceGroup(
    id: number,
    requestData: IProductResourceGroupUpdateRequest,
  ): Observable<BaseOneResponseInterface<IProductResourceGroup>> {
    return this.http.patch<BaseOneResponseInterface<IProductResourceGroup>>(`${this.api}${this.baseRoute}/${id}`, requestData);
  }

  public deleteResourceGroup(id: number): Observable<void> {
    return this.http.delete<void>(`${this.api}${this.baseRoute}/${id}`);
  }

  public bulkDeleteResourceGroups(ids: number[]): Observable<BulkResponseDataInterface> {
    return this.http.delete<BulkResponseDataInterface>(`${this.api}${this.baseRoute}/bulk/delete`, {
      body: { productResourceGroups: ids },
      headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
    });
  }
}
