import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, switchMap } from 'rxjs/operators';
import { of } from 'rxjs';
import * as ObjectActions from './iot-device-filter.actions';
import { IotDeviceReportService } from '../../../shared/service/iot-device-report/iot-device-report.service';
import { get, find } from 'lodash';

@Injectable()
export class FilterIotDeviceEffects {
  loadFilterIotDevices$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ObjectActions.FilterIotDeviceActionTypes.StartLoadFilterIotDevice),
      switchMap((objectData: ObjectActions.StartLoadFilterIotDevice) => {
        let siteId: number = null;
        try {
          const parsedS = JSON.parse(objectData.options.s);
          siteId = get(
            find(parsedS.$and, (item) => item.siteId),
            'siteId.$in[0]',
            null,
          );
        } catch (error) {
          siteId = null;
        }
        return this.iotDeviceReportsService.getIotDeviceList(siteId).pipe(
          switchMap((response) => {
            return of(new ObjectActions.FilterIotDeviceLoaded(response));
          }),
          catchError((e) => {
            return of(new ObjectActions.FilterIotDeviceLoaded([]));
          }),
        );
      }),
      catchError(() => {
        return of(new ObjectActions.FilterIotDeviceLoaded([]));
      }),
    ),
  );

  constructor(
    private actions$: Actions<ObjectActions.FilterIotDeviceActions>,
    public iotDeviceReportsService: IotDeviceReportService,
  ) {}
}
