import { AfterViewInit, Component, OnDestroy, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { NgIf } from '@angular/common';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { EPushNotificationStatus } from '../../../store/push-notification/push-notification.model';
import * as PushNotificationActions from '../../../store/push-notification/push-notification.actions';
import * as UserActions from '../../../store/user/actions';
import { PushNotificationService } from '../../../store/push-notification/push-notification.service';
import { combineLatest, from, retry, Subject, Subscription, throwIfEmpty, withLatestFrom } from 'rxjs';
import { getToken, Messaging } from 'firebase/messaging';
import * as moment from 'moment';
import { OeeAppState } from '../../../store/oee.reducer';
import { ActionsSubject, Store } from '@ngrx/store';
import { PUSH_NOTIFICATION_SERVICE_WORKER_URL } from '../../../../constants';
import { ofType } from '@ngrx/effects';
import { take } from 'rxjs/operators';
import { FirebaseCredentials, User } from '../../../store/user/model';
import { mysqlTimestampFormat } from '../../../shared/helper/date';
import { ToastHelperService } from '../../../shared/service/toast/toast.helper.service';
import { TranslateService } from '@ngx-translate/core';
import { ScwMatButtonModule } from '../../../shared/component/scw-mat-ui/scw-mat-button/scw-mat-button.module';
import { selectUserFirebaseConfig } from '../../../store/user/user.selectors';
import { ScwFirebaseService } from '../../../shared/service/firebase/firebase.service';
import { EAccountType } from '../../../store/app/model';

@Component({
  selector: 'app-scw-notification-permission-modal',
  templateUrl: './push-notification-permission-modal.component.html',
  styleUrls: ['./push-notification-permission-modal.component.scss'],
  standalone: true,
  imports: [NgIf, ScwMatButtonModule],
})
export class PushNotificationPermissionModalComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild('notification_permission_modal') notificationPermissionModalRef: TemplateRef<void>;

  public readonly toastPostponeDurationUnit: string = this.translate.instant(
    'pushNotification.permission.toast.postponeDurationUnit',
  );
  public readonly modalTitle: string = this.translate.instant('pushNotification.permission.modal.title');
  public readonly modalInfo: string = this.translate.instant('pushNotification.permission.modal.info');
  public readonly modalRemindLater: string = this.translate.instant('pushNotification.permission.modal.remindLater');
  public readonly modalConfigure: string = this.translate.instant('pushNotification.permission.modal.configure');
  private readonly subscriptions: Subscription[] = [];
  private readonly postponeDuration: number = 8;
  private readonly postponeDurationOneYearInHours: number = 8760;
  private readonly postponeDurationUnit: moment.DurationInputArg2 = 'hours';
  private readonly postponePermissionLocalStorageName: string = 'pushNotificationPermissionPostponedUntil';
  private readonly toastPostponedInfo: string = this.translate.instant(
    'pushNotification.permission.toast.postponedInfo',
    {
      postponeDuration: this.postponeDuration,
      postponeDurationUnit: this.toastPostponeDurationUnit,
    },
  );
  private readonly toastAllowedInfo: string = this.translate.instant('pushNotification.permission.toast.allowedInfo');
  private readonly toastBlockedInfo: string = this.translate.instant('pushNotification.permission.toast.blockedInfo');
  private modalOpenTrigger: Subject<void> = new Subject();
  private modalRef: NgbModalRef;
  private tokenRefreshTrigger: Subject<void> = new Subject();
  private devicePushNotificationToken$: string;
  private timezone$: string;
  private userAccountType: EAccountType;

  constructor(
    private readonly ngbModal: NgbModal,
    private readonly store: Store<OeeAppState>,
    private readonly actionsSubject: ActionsSubject,
    private readonly toastHelperService: ToastHelperService,
    private readonly translate: TranslateService,
    private readonly firebaseService: ScwFirebaseService,
  ) {}

  public ngOnInit(): void {
    this.subscriptions.push(
      this.actionsSubject
        .pipe(ofType(PushNotificationActions.SUBSCRIBE_TO_USER_UNIQUE_TOPIC_COMPLETED))
        .subscribe((response: PushNotificationActions.SubscribeToUserUniqueTopicCompleted): void => {
          this.store.dispatch(new UserActions.SetDevicePushNotificationToken(response.token));
        }),
      this.actionsSubject
        .pipe(ofType(PushNotificationActions.OPEN_PUSH_NOTIFICATION_PERMISSION_MODAL))
        .subscribe((): void => {
          if (
            this.modalRef ||
            !PushNotificationService.isNotificationSupported() ||
            !PushNotificationService.isPushManagerSupported() ||
            (PushNotificationService.isNotificationSupported() &&
              Notification.permission !== EPushNotificationStatus.default)
          ) {
            return;
          }

          this.modalOpenTrigger.next();
        }),
      this.store.select('user').subscribe((state: User): void => {
        this.devicePushNotificationToken$ = state.devicePushNotificationToken;
        this.timezone$ = state.timezone;
        this.userAccountType = state.accountType;
      }),
      combineLatest([this.firebaseService.getMessaging(), this.modalOpenTrigger]).subscribe(([messaging]) => {
        if (messaging) {
          this.openModal();
        }
      }),
      combineLatest([this.firebaseService.getMessaging(), this.tokenRefreshTrigger]).subscribe(() => {
        this.getToken(true);
      }),
    );
  }

  public ngAfterViewInit(): void {
    if (
      !PushNotificationService.isNotificationSupported() ||
      !PushNotificationService.isPushManagerSupported() ||
      Notification?.permission !== EPushNotificationStatus.default ||
      this.ngbModal.hasOpenModals() ||
      !this.isPostponeTimeExpired()
    ) {
      this.tokenRefreshTrigger.next();

      return;
    }

    if (this.userAccountType !== EAccountType.DASHBOARD_USER) {
      this.modalOpenTrigger.next();
    }
  }

  public getToken(isForTokenRefresh: boolean = false): void {
    if (
      !PushNotificationService.isNotificationSupported() ||
      !PushNotificationService.isPushManagerSupported() ||
      (isForTokenRefresh && Notification.permission !== EPushNotificationStatus.granted)
    ) {
      this.closeModal();

      return;
    }

    from(navigator.serviceWorker.getRegistration(PUSH_NOTIFICATION_SERVICE_WORKER_URL))
      .pipe(
        throwIfEmpty(),
        retry({ count: 2, delay: 2000 }),
        withLatestFrom(this.firebaseService.getMessaging(), this.store.select(selectUserFirebaseConfig)),
      )
      .subscribe(
        ([serviceWorkerRegistration, messaging, config]: [
          ServiceWorkerRegistration,
          Messaging,
          FirebaseCredentials,
        ]): void => {
          if (!messaging || !ScwFirebaseService.pushNotificationsServiceUrl) {
            return;
          }

          try {
            getToken(messaging, {
              vapidKey: config.firebase.vapidKey,
              serviceWorkerRegistration,
            })
              .then((currentToken: string): void => {
                localStorage.removeItem(this.postponePermissionLocalStorageName);

                if (!currentToken || currentToken === this.devicePushNotificationToken$) {
                  return;
                }

                if (!isForTokenRefresh) {
                  this.toastHelperService.showToastMessage(true, undefined, this.toastAllowedInfo);
                }

                this.store.dispatch(new PushNotificationActions.SubscribeToUserUniqueTopic(currentToken));
              })
              .catch((): void => {
                if (Notification.permission === EPushNotificationStatus.denied) {
                  this.toastHelperService.showToastMessage(true, undefined, this.toastBlockedInfo);
                }

                localStorage.removeItem(this.postponePermissionLocalStorageName);
              });
          } catch {
            this.remindLater(
              this.translate.instant('pushNotification.permission.toast.postponedInfo', {
                postponeDuration: this.postponeDurationOneYearInHours,
                postponeDurationUnit: this.toastPostponeDurationUnit,
              }),
            );
          }

          this.closeModal();
        },
      );
  }

  public remindLater(message?: string): void {
    this.closeModal();

    this.store
      .select('user')
      .pipe(take(1))
      .subscribe((state: User) => {
        localStorage.setItem(
          this.postponePermissionLocalStorageName,
          moment()
            .tz(state.timezone)
            .utc()
            .add(this.postponeDuration, this.postponeDurationUnit as moment.DurationInputArg2)
            .format(mysqlTimestampFormat),
        );
        this.toastHelperService.showToastMessage(true, undefined, message ?? this.toastPostponedInfo);
      });
  }

  public closeModal(): void {
    this.modalRef?.close();
    this.modalRef = null;
  }

  private isPostponeTimeExpired(): boolean {
    const postponeTime: string = localStorage.getItem(this.postponePermissionLocalStorageName);

    if (!postponeTime) {
      return true;
    }

    return moment(moment().tz(this.timezone$).utc().format(mysqlTimestampFormat)).isAfter(moment(postponeTime));
  }

  private openModal(): void {
    this.modalRef = this.ngbModal.open(this.notificationPermissionModalRef, {
      windowClass: 'scw-modal-sm notification-permission-modal-window',
      backdrop: false,
      keyboard: false,
    });
  }

  public ngOnDestroy(): void {
    this.subscriptions.forEach((subscription: Subscription): void => subscription?.unsubscribe());
  }
}
