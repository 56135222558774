import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, switchMap } from 'rxjs/operators';
import * as ObjectActions from './layout-plan-view.actions';
import {
  FetchError,
  FloorPlanLoaded,
  FloorPlanOeeTargetsLoaded,
  FloorPlanViewDataLoaded,
  JobOeeLoaded,
  WorkOrdersLoaded,
} from './layout-plan-view.actions';
import { Observable, of } from 'rxjs';
import { LayoutPlanViewService } from './layout-plan-view.service';
import {
  BaseOneResponseInterface,
  GetManyResponseInterface,
} from '../../shared/model/interface/crud-response-interface.model';
import { IFloorPlan, IFloorPlanTarget, IJobOeeResult } from './layout-plan-view.model';
import { WorkOrderScheduleInterface } from '../work-order-schedule/work-order-schedule.model';
import { HttpParams } from '@angular/common/http';
import { mysqlDateFormat } from '../../shared/helper/date';
import { SiteViewDataInterface } from '../global-view/site-view/site-view.model';
import { SiteViewService } from '../../shared/service/global-view/site-view/site-view.service';

@Injectable()
export class LayoutPlanViewEffects {
  constructor(
    private readonly actions$: Actions,
    private readonly service: LayoutPlanViewService,
    private readonly siteViewService: SiteViewService,
  ) {}

  getFloorPlan: Observable<FloorPlanLoaded | FetchError> = createEffect(() =>
    this.actions$.pipe(
      ofType(ObjectActions.FLOOR_PLAN_LOADING),
      switchMap((payload: ObjectActions.FloorPlanLoading) => {
        return this.service.getFloorPlan(payload.id, this.service.getDefaultFloorPlanParams()).pipe(
          switchMap((response: BaseOneResponseInterface<IFloorPlan>) => {
            return of(new ObjectActions.FloorPlanLoaded(response.data));
          }),
          catchError((errorRes) => {
            return of(new ObjectActions.FetchError(errorRes));
          }),
        );
      }),
      catchError((errorRes) => {
        return of(new ObjectActions.FetchError(errorRes));
      }),
    ),
  );

  calculateJobOee: Observable<JobOeeLoaded | FetchError> = createEffect(() =>
    this.actions$.pipe(
      ofType(ObjectActions.JOB_OEE_LOADING),
      switchMap((payload: ObjectActions.JobOeeLoading) => {
        return this.service.calculateJobOee(payload.jobId).pipe(
          switchMap((response: IJobOeeResult) => {
            return of(new ObjectActions.JobOeeLoaded(response));
          }),
          catchError((errorRes) => {
            return of(new ObjectActions.FetchError(errorRes));
          }),
        );
      }),
      catchError((errorRes) => {
        return of(new ObjectActions.FetchError(errorRes));
      }),
    ),
  );

  getWorkOrdersData: Observable<WorkOrdersLoaded | FetchError> = createEffect(() =>
    this.actions$.pipe(
      ofType(ObjectActions.WORK_ORDERS_LOADING),
      switchMap((payload: ObjectActions.WorkOrdersLoading) => {
        return this.service.getWorkOrders(payload.jobId).pipe(
          switchMap((response: GetManyResponseInterface<WorkOrderScheduleInterface>) => {
            return of(new ObjectActions.WorkOrdersLoaded(response.data));
          }),
          catchError((errorRes) => {
            return of(new ObjectActions.FetchError(errorRes));
          }),
        );
      }),
      catchError((errorRes) => {
        return of(new ObjectActions.FetchError(errorRes));
      }),
    ),
  );

  getFloorPlanOeeTargets: Observable<FloorPlanOeeTargetsLoaded | FetchError> = createEffect(() =>
    this.actions$.pipe(
      ofType(ObjectActions.FLOOR_PLAN_OEE_TARGETS_LOADING),
      switchMap((payload: ObjectActions.FloorPlanOeeTargetsLoading) => {
        const params: HttpParams = new HttpParams()
          .append(
            's',
            JSON.stringify({
              targetType: 'OEE',
              targetPeriod: {
                $between: [
                  payload.currentDay.clone().startOf('month').clone().format(mysqlDateFormat),
                  payload.currentDay.clone().endOf('month').clone().format(mysqlDateFormat),
                ],
              },
            }),
          )
          .append('limit', '1000');

        return this.service.getFloorPlanOeeTargets(payload.floorPlanId, params).pipe(
          switchMap((response: GetManyResponseInterface<IFloorPlanTarget>) => {
            return of(new ObjectActions.FloorPlanOeeTargetsLoaded(response.data));
          }),
          catchError((errorRes) => {
            return of(new ObjectActions.FetchError(errorRes));
          }),
        );
      }),
      catchError((errorRes) => {
        return of(new ObjectActions.FetchError(errorRes));
      }),
    ),
  );

  getFloorPlanViewData: Observable<FloorPlanViewDataLoaded | FetchError> = createEffect(() =>
    this.actions$.pipe(
      ofType(ObjectActions.FLOOR_PLAN_VIEW_DATA_LOADING),
      switchMap((payload: ObjectActions.FloorPlanViewDataLoading) => {
        return this.siteViewService.getSiteViewLineData(payload.siteId, payload.lineIds).pipe(
          switchMap((response: BaseOneResponseInterface<SiteViewDataInterface>) => {
            return of(new ObjectActions.FloorPlanViewDataLoaded(response.data));
          }),
          catchError((errorRes) => {
            return of(new ObjectActions.FetchError(errorRes));
          }),
        );
      }),
      catchError((errorRes) => {
        return of(new ObjectActions.FetchError(errorRes));
      }),
    ),
  );
}
