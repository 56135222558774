import { Action } from '@ngrx/store';
import { GenericCrudRequestConstructionParameters } from '../../../shared/model/interface/generic-api-request.model';
import {
  BaseOneResponseInterface,
  BulkResponseDataInterface,
  GetManyResponseInterface,
} from '../../../shared/model/interface/crud-response-interface.model';
import {
  IProductResourceGroup,
  IProductResourceGroupCreateRequest,
  IProductResourceGroupUpdateRequest,
  ProductResourceGetManyPurpose,
} from './product-resource-groups.model';

export enum ProductResourceGroupsActionTypes {
  GET_PRODUCT_RESOURCE_GROUPS_LOADING = '[PRODUCT_RESOURCE_GROUPS] GET PRODUCT RESOURCE GROUPS LOADING',
  GET_PRODUCT_RESOURCE_GROUPS_LOADED = '[PRODUCT_RESOURCE_GROUPS] GET PRODUCT RESOURCE GROUPS LOADED',
  CREATE_PRODUCT_RESOURCE_GROUP_LOADING = '[PRODUCT_RESOURCE_GROUPS] CREATE PRODUCT RESOURCE GROUPS LOADING',
  CREATE_PRODUCT_RESOURCE_GROUP_LOADED = '[PRODUCT_RESOURCE_GROUPS] CREATE PRODUCT RESOURCE GROUPS LOADED',
  UPDATE_PRODUCT_RESOURCE_GROUP_LOADING = '[PRODUCT_RESOURCE_GROUPS] UPDATE PRODUCT RESOURCE GROUPS LOADING',
  UPDATE_PRODUCT_RESOURCE_GROUP_LOADED = '[PRODUCT_RESOURCE_GROUPS] UPDATE PRODUCT RESOURCE GROUPS LOADED',
  DELETE_PRODUCT_RESOURCE_GROUP_LOADING = '[PRODUCT_RESOURCE_GROUPS] DELETE PRODUCT RESOURCE GROUPS LOADING',
  DELETE_PRODUCT_RESOURCE_GROUP_LOADED = '[PRODUCT_RESOURCE_GROUPS] DELETE PRODUCT RESOURCE GROUPS LOADED',
  BULK_DELETE_PRODUCT_RESOURCE_GROUPS_LOADING = '[PRODUCT_RESOURCE_GROUPS] PRODUCT BULK DELETE RESOURCE GROUPS LOADING',
  BULK_DELETE_PRODUCT_RESOURCE_GROUPS_LOADED = '[PRODUCT_RESOURCE_GROUPS] PRODUCT BULK DELETE RESOURCE GROUPS LOADED',
  FETCH_ERROR = '[PRODUCT_RESOURCE_GROUPS] FETCH ERROR',
}

export class GetProductResourceGroupsLoading implements Action {
  readonly type = ProductResourceGroupsActionTypes.GET_PRODUCT_RESOURCE_GROUPS_LOADING;

  constructor(public parameters: GenericCrudRequestConstructionParameters, public purpose: ProductResourceGetManyPurpose = ProductResourceGetManyPurpose.pageData) {}
}

export class GetProductResourceGroupsLoaded implements Action {
  readonly type = ProductResourceGroupsActionTypes.GET_PRODUCT_RESOURCE_GROUPS_LOADED;

  constructor(public payload: GetManyResponseInterface<IProductResourceGroup>, public purpose: ProductResourceGetManyPurpose) {}
}

export class CreateProductResourceGroupLoading implements Action {
  readonly type = ProductResourceGroupsActionTypes.CREATE_PRODUCT_RESOURCE_GROUP_LOADING;

  constructor(public parameters: IProductResourceGroupCreateRequest) {}
}

export class CreateProductResourceGroupLoaded implements Action {
  readonly type = ProductResourceGroupsActionTypes.CREATE_PRODUCT_RESOURCE_GROUP_LOADED;

  constructor(public payload: BaseOneResponseInterface<IProductResourceGroup>) {}
}

export class UpdateProductResourceGroupLoading implements Action {
  readonly type = ProductResourceGroupsActionTypes.UPDATE_PRODUCT_RESOURCE_GROUP_LOADING;

  constructor(public id: number, public parameters: IProductResourceGroupUpdateRequest) {}
}

export class UpdateProductResourceGroupLoaded implements Action {
  readonly type = ProductResourceGroupsActionTypes.UPDATE_PRODUCT_RESOURCE_GROUP_LOADED;

  constructor(public payload: BaseOneResponseInterface<IProductResourceGroup>) {}
}

export class DeleteProductResourceGroupLoading implements Action {
  readonly type = ProductResourceGroupsActionTypes.DELETE_PRODUCT_RESOURCE_GROUP_LOADING;

  constructor(public id: number) {}
}

export class DeleteProductResourceGroupLoaded implements Action {
  readonly type = ProductResourceGroupsActionTypes.DELETE_PRODUCT_RESOURCE_GROUP_LOADED;
}

export class BulkDeleteProductResourceGroupsLoading implements Action {
  readonly type = ProductResourceGroupsActionTypes.BULK_DELETE_PRODUCT_RESOURCE_GROUPS_LOADING;
  constructor(public ids: number[]) {}
}

export class BulkDeleteProductResourceGroupsLoaded implements Action {
  readonly type = ProductResourceGroupsActionTypes.BULK_DELETE_PRODUCT_RESOURCE_GROUPS_LOADED;

  constructor(public payload: BulkResponseDataInterface) {}
}

export class FetchError implements Action {
  public readonly type = ProductResourceGroupsActionTypes.FETCH_ERROR;

  constructor(public errorAction: Exclude<ProductResourceGroupsActionTypes, ProductResourceGroupsActionTypes.FETCH_ERROR>) {}
}

export type ResourceGroupActions =
  | GetProductResourceGroupsLoading
  | GetProductResourceGroupsLoaded
  | CreateProductResourceGroupLoading
  | CreateProductResourceGroupLoaded
  | UpdateProductResourceGroupLoading
  | UpdateProductResourceGroupLoaded
  | DeleteProductResourceGroupLoading
  | DeleteProductResourceGroupLoaded
  | BulkDeleteProductResourceGroupsLoading
  | BulkDeleteProductResourceGroupsLoaded
  | FetchError;
