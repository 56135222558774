import { Action } from '@ngrx/store';
import {
  ETableType,
  IExpectedSpeed,
  IExpectedSpeedFilter,
  IOeeCalculationResult,
  IPeriodicOeeCalculationParams,
  IPeriodicOeeCalculationTableRows,
  IWorkOrderComment,
  IWorkOrderLaborAssetInformation,
  IWorkOrderLaborAssetTableInformation,
  IWorkOrderPerformanceFilter,
  IWorkOrderPerformanceWorkOrder,
  IWorkOrderPhaseInformation,
} from './work-order-performance.model';
import { IActivityLog } from '../root-cause-analysis/root-cause-analysis.model';
import { GetManyResponseInterface } from '../../../shared/model/interface/crud-response-interface.model';
import { IPeriodicOeeCalculationData } from '../periodic-oee-calculation-review/periodic-oee-calculation-review.model';
import { ActivityLogsResponseInterface } from '../root-cause-analysis/root-cause-analysis.model';

export const WORK_ORDER_INFORMATION_LOADING = '[WORK ORDER PERFORMANCE] WORK ORDER INFORMATION LOADING';
export const WORK_ORDER_INFORMATION_LOADED = '[WORK ORDER PERFORMANCE] WORK ORDER INFORMATION LOADED';
export const WORK_ORDER_ACTIVITY_LOGS_LOADING = '[WORK ORDER PERFORMANCE] WORK ORDER ACTIVITY LOGS LOADING';
export const WORK_ORDER_ACTIVITY_LOGS_LOADED = '[WORK ORDER PERFORMANCE] WORK ORDER ACTIVITY LOGS LOADED';
export const WORK_ORDER_OEE_RESULT_LOADING = '[WORK ORDER PERFORMANCE] WORK ORDER OEE CALCULATION RESULT LOADING';
export const WORK_ORDER_OEE_RESULT_LOADED = '[WORK ORDER PERFORMANCE] WORK ORDER OEE CALCULATION RESULT LOADED';
export const WORK_ORDER_PHASE_INFORMATION_LOADING = '[WORK ORDER PERFORMANCE] WORK ORDER PHASE INFORMATION LOADING';
export const WORK_ORDER_PHASE_INFORMATION_LOADED = '[WORK ORDER PERFORMANCE] WORK ORDER PHASE INFORMATION LOADED';
export const WORK_ORDER_LABOR_INFORMATION_LOADING = '[WORK ORDER PERFORMANCE] WORK ORDER LABOR INFORMATION LOADING';
export const WORK_ORDER_LABOR_INFORMATION_LOADED = '[WORK ORDER PERFORMANCE] WORK ORDER LABOR INFORMATION LOADED';
export const WORK_ORDER_PERIODIC_OEE_CALCULATIONS_LOADING =
  '[WORK ORDER PERFORMANCE] WORK ORDER PERIODIC OEE CALCULATIONS LOADING';
export const WORK_ORDER_PERIODIC_OEE_CALCULATIONS_LOADED =
  '[WORK ORDER PERFORMANCE] WORK ORDER PERIODIC OEE CALCULATIONS LOADED';
export const SET_EXPECTED_SPEED = '[WORK ORDER PERFORMANCE] SET EXPECTED SPEED';
export const COMMENTS_LOADING = '[WORK ORDER PERFORMANCE] COMMENTS LOADING';
export const COMMENTS_LOADED = '[WORK ORDER PERFORMANCE] COMMENTS LOADED';
export const DOWNLOAD_EXCEL = '[WORK ORDER PERFORMANCE] DOWNLOAD EXCEL';
export const FETCH_DATA_ERROR = '[WORK ORDER PERFORMANCE] FETCH DATA ERROR';

export class WorkOrderInformationLoading implements Action {
  readonly type = WORK_ORDER_INFORMATION_LOADING;

  constructor(public params: IWorkOrderPerformanceFilter) {}
}

export class WorkOrderInformationLoaded implements Action {
  readonly type = WORK_ORDER_INFORMATION_LOADED;

  constructor(public payload: IWorkOrderPerformanceWorkOrder) {}
}

export class WorkOrderActivityLogsLoading implements Action {
  readonly type = WORK_ORDER_ACTIVITY_LOGS_LOADING;

  constructor(public params: IWorkOrderPerformanceFilter) {}
}

export class WorkOrderActivityLogsLoaded implements Action {
  readonly type = WORK_ORDER_ACTIVITY_LOGS_LOADED;

  constructor(public payload: ActivityLogsResponseInterface) {}
}

export class WorkOrderOeeResultLoading implements Action {
  readonly type = WORK_ORDER_OEE_RESULT_LOADING;

  constructor(public params: IWorkOrderPerformanceFilter) {}
}

export class WorkOrderOeeResultLoaded implements Action {
  readonly type = WORK_ORDER_OEE_RESULT_LOADED;

  constructor(public payload: IOeeCalculationResult) {}
}

export class WorkOrderPhaseInformationLoading implements Action {
  readonly type = WORK_ORDER_PHASE_INFORMATION_LOADING;

  constructor(public params: IWorkOrderPerformanceFilter) {}
}

export class WorkOrderPhaseInformationLoaded implements Action {
  readonly type = WORK_ORDER_PHASE_INFORMATION_LOADED;

  constructor(public payload: IWorkOrderPhaseInformation) {}
}

export class WorkOrderLaborInformationLoading implements Action {
  readonly type = WORK_ORDER_LABOR_INFORMATION_LOADING;

  constructor(public params: IWorkOrderPerformanceFilter) {}
}

export class WorkOrderLaborInformationLoaded implements Action {
  readonly type = WORK_ORDER_LABOR_INFORMATION_LOADED;

  constructor(public payload: IWorkOrderLaborAssetInformation[]) {}
}

export class WorkOrderPeriodicOeeCalculationsLoading implements Action {
  readonly type = WORK_ORDER_PERIODIC_OEE_CALCULATIONS_LOADING;

  constructor(public params: IPeriodicOeeCalculationParams) {}
}

export class WorkOrderPeriodicOeeCalculationsLoaded implements Action {
  readonly type = WORK_ORDER_PERIODIC_OEE_CALCULATIONS_LOADED;

  constructor(public payload: GetManyResponseInterface<IPeriodicOeeCalculationData>) {}
}

export class SetExpectedSpeed implements Action {
  readonly type = SET_EXPECTED_SPEED;

  constructor(public speed: string) {}
}

export class CommentsLoading implements Action {
  readonly type = COMMENTS_LOADING;

  constructor(public workOrderIds: number[]) {}
}

export class CommentsLoaded implements Action {
  readonly type = COMMENTS_LOADED;

  constructor(public payload: IWorkOrderComment[]) {}
}

export class DownloadExcel implements Action {
  readonly type = DOWNLOAD_EXCEL;

  constructor(
    public data: IWorkOrderLaborAssetTableInformation[] | IPeriodicOeeCalculationTableRows[],
    public excelType: ETableType,
  ) {}
}

export class FetchDataError implements Action {
  readonly type = FETCH_DATA_ERROR;

  constructor(public payload: object) {}
}

export type WorkOrderPerformanceActions =
  | WorkOrderInformationLoading
  | WorkOrderInformationLoaded
  | WorkOrderActivityLogsLoading
  | WorkOrderActivityLogsLoaded
  | WorkOrderOeeResultLoading
  | WorkOrderOeeResultLoaded
  | WorkOrderPhaseInformationLoading
  | WorkOrderPhaseInformationLoaded
  | WorkOrderLaborInformationLoading
  | WorkOrderLaborInformationLoaded
  | WorkOrderPeriodicOeeCalculationsLoading
  | WorkOrderPeriodicOeeCalculationsLoaded
  | SetExpectedSpeed
  | CommentsLoading
  | CommentsLoaded
  | DownloadExcel
  | FetchDataError;
