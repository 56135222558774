import * as Actions from './oee-waterfall-analysis.actions';
import { IOeeWaterfallAnalysisState } from './oee-waterfall-analysis.model';
export const oeeWaterfallAnalysisInitialState: IOeeWaterfallAnalysisState = {
  isOeeDataLoading: false,
  isOeeDataLoaded: false,
  isRootCauseAnalysisDataLoading: false,
  isRootCauseAnalysisDataLoaded: false,
  isTargetsDataLoading: false,
  isTargetsDataLoaded: false,
  oeeData: null,
  rootCauseAnalysisData: null,
  targetData: null,
};

export function oeeWaterfallAnalysisReducer(
  state: IOeeWaterfallAnalysisState = oeeWaterfallAnalysisInitialState,
  action: Actions.OeeWaterfallAnalysisActions,
): IOeeWaterfallAnalysisState {
  switch (action.type) {
    case Actions.OEE_WATERFALL_ANALYSIS_OEE_DATA_LOADING:
      return {
        ...state,
        isOeeDataLoading: true,
        isOeeDataLoaded: false,
        oeeData: null,
      };

    case Actions.OEE_WATERFALL_ANALYSIS_OEE_DATA_LOADED:
      return {
        ...state,
        isOeeDataLoading: false,
        isOeeDataLoaded: true,
        oeeData: action.payload,
      };

    case Actions.OEE_WATERFALL_ANALYSIS_ROOT_CAUSE_ANALYSIS_DATA_LOADING:
      return {
        ...state,
        isRootCauseAnalysisDataLoading: true,
        isRootCauseAnalysisDataLoaded: false,
        rootCauseAnalysisData: null,
      };

    case Actions.OEE_WATERFALL_ANALYSIS_ROOT_CAUSE_ANALYSIS_DATA_LOADED:
      return {
        ...state,
        isRootCauseAnalysisDataLoading: false,
        isRootCauseAnalysisDataLoaded: true,
        rootCauseAnalysisData: action.payload,
      };

    case Actions.OEE_WATERFALL_ANALYSIS_TARGETS_DATA_LOADING:
      return {
        ...state,
        isTargetsDataLoading: true,
        isTargetsDataLoaded: false,
        targetData: null,
      };

    case Actions.OEE_WATERFALL_ANALYSIS_TARGETS_DATA_LOADED:
      return {
        ...state,
        isTargetsDataLoading: false,
        isTargetsDataLoaded: true,
        targetData: action.payload.data,
      };

    default:
      return state;
  }
}
