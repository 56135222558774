import { Inject, Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import {
  BaseOneResponseInterface,
  BulkResponseDataInterface,
} from '../../../shared/model/interface/crud-response-interface.model';
import { Observable } from 'rxjs';
import {
  IAddEditProductTransitionMatrix,
  IBulkEditProductTransitionMatrix,
  IGetProductTransitionMatrix,
  IGetProductTransitionMatrixParams,
  IProductTransitionMatrixBulkResponse,
  IProductTransitionMatrixParams,
  IProductTransitionMatrixResponse,
} from './product-transition-matrix.model';
import * as _ from 'lodash';
import {
  IProductTransitionPreviewPayload,
  IProductTransitionPreviewResponse,
} from '../../../view/settings/product-transition-matrix/product-transition-visual-matrix/product-transition-visual-matrix.model';

@Injectable({
  providedIn: 'root',
})
export class ProductTransitionMatrixService {
  private readonly routes = {
    sites: `${this.baseUrl}/sites`,
    productTransitionMatrix: `${this.baseUrl}/product-transition-matrix`,
    bulkDelete: `${this.baseUrl}/product-transition-matrix/bulk/delete`,
    bulkSave: `${this.baseUrl}/product-transition-matrix/bulk/save`,
    bulkEdit: `${this.baseUrl}/product-transition-matrix/bulk/edit`,
    previewData: `${this.baseUrl}/product-transition-matrix/preview-product-transition-matrix`,
    getProductTransitionMatrix: `${this.baseUrl}/product-transition-matrix/get-product-matrix`,
  };

  constructor(
    private readonly http: HttpClient,
    @Inject('API_BASE_URL')
    private readonly baseUrl: string,
  ) {}

  public getProductTransitionMatrix(
    body: IProductTransitionMatrixParams,
  ): Observable<BaseOneResponseInterface<IProductTransitionMatrixResponse>> {
    return this.http.post<BaseOneResponseInterface<IProductTransitionMatrixResponse>>(
      `${this.routes.productTransitionMatrix}/data`,
      {
        page: body.page,
        pageSize: body.pageSize,
        orderBy: body.orderBy,
        isOrderDesc: body.isOrderDesc,
        searchValue: body.searchValue,
        fromObjType: body.fromObjType,
        toObjType: body.toObjType,
        siteIds: _.isEmpty(body.selectedSiteIds) ? -1 : body.selectedSiteIds,
        lineIds: _.isEmpty(body.selectedLineIds) ? -1 : body.selectedLineIds,
      },
      {
        headers: new HttpHeaders({ 'X-HTTP-Method': 'GET' }),
      },
    );
  }

  public addProductTransitionMatrix(
    productTransitionMatrix: IAddEditProductTransitionMatrix,
  ): Observable<BaseOneResponseInterface<IAddEditProductTransitionMatrix>> {
    return this.http.post<BaseOneResponseInterface<IAddEditProductTransitionMatrix>>(
      this.routes.productTransitionMatrix,
      productTransitionMatrix,
    );
  }

  public editProductTransitionMatrix(
    productTransitionMatrix: IAddEditProductTransitionMatrix,
    id: number,
  ): Observable<BaseOneResponseInterface<IAddEditProductTransitionMatrix>> {
    return this.http.patch<BaseOneResponseInterface<IAddEditProductTransitionMatrix>>(
      `${this.routes.productTransitionMatrix}/${id}`,
      productTransitionMatrix,
    );
  }

  public deleteProductFamily(productTransitionMatrixId: number): Observable<BulkResponseDataInterface> {
    return this.http.delete<BulkResponseDataInterface>(
      `${this.routes.productTransitionMatrix}/${productTransitionMatrixId}`,
    );
  }

  public bulkDeleteProductTransitionMatrices(
    productTransitionMatrixIds: number[],
  ): Observable<BulkResponseDataInterface> {
    return this.http.delete<BulkResponseDataInterface>(`${this.routes.bulkDelete}`, {
      body: { productTransitionMatrices: productTransitionMatrixIds },
    });
  }

  public bulkEditProductTransitionMatrix(
    productTransitionMatrix: IBulkEditProductTransitionMatrix[],
  ): Observable<BaseOneResponseInterface<IProductTransitionMatrixBulkResponse[]>> {
    return this.http.patch<BaseOneResponseInterface<IProductTransitionMatrixBulkResponse[]>>(
      `${this.routes.bulkEdit}`,
      {
        productTransitionMatrix,
      },
    );
  }

  public getProductTransitionMatrixOfWorkOrder(
    body: IGetProductTransitionMatrixParams,
  ): Observable<BaseOneResponseInterface<IGetProductTransitionMatrix>> {
    return this.http.post<BaseOneResponseInterface<IGetProductTransitionMatrix>>(
      `${this.routes.getProductTransitionMatrix}`,
      body,
      {
        headers: new HttpHeaders({ 'X-HTTP-Method': 'GET' }),
      },
    );
  }

  public preparePreviewDataParams(options: IProductTransitionPreviewPayload): HttpParams {
    const params: HttpParams = new HttpParams()
      .set('siteId', options.siteId[0])
      .set('lineIds', options.lines)
      .set('compareType', options.compareType[0])
      .set(
        'fromObjects',
        options.fromObjects !== -1 && Array.isArray(options.fromObjects) ? options.fromObjects.join(',') : '-1',
      )
      .set(
        'toObjects',
        options.toObjects !== -1 && Array.isArray(options.toObjects) ? options.toObjects.join(',') : '-1',
      )

    return params;
  }

  public getPreviewProductTransitionMatrix(options: HttpParams): Observable<BaseOneResponseInterface<IProductTransitionPreviewResponse[]>> {
    return this.http.get<BaseOneResponseInterface<IProductTransitionPreviewResponse[]>>(
      `${this.routes.previewData}`,
      {
        params: options,
      },
    );
  }
}
