import { Inject, Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { EquipmentListGetManyCRUDDataInterface } from './equipment-list.model';
import { forkJoin, Observable, Subject } from 'rxjs';
import {
  CellTypes,
  CreateExcelInterface,
  CreateExcelSheetInterface,
  ExcelColumnWidthEnum,
  ExcelHelperService,
  ExcelSheetTypeEnum,
} from '../excel/excel-helper.service';
import { Store } from '@ngrx/store';
import * as oeeAppReducer from '../../../store/oee.reducer';
import { TranslateService } from '@ngx-translate/core';
import { excelDateFormat, excelTimeFormat } from '../../model/enum/excel-date-format';
import {
  BulkResponseDataInterface,
  GetManyResponseInterface,
} from '../../model/interface/crud-response-interface.model';
import {
  EquipmentBrandInterface,
  EquipmentListBulkSaveManyInterface,
  EquipmentListDataRetrievalInterface,
  EquipmentListInterface,
  EquipmentListsDownloadExcelFiltersInterface,
  EquipmentTypeInterface,
} from '../../../store/settings/equipment-lists/equipment-lists.model';
import { SiteService } from '../filter/site.service';
import { ValueType, Workbook, Worksheet } from 'exceljs';
import * as _ from 'lodash';
import * as moment from 'moment-timezone';
import * as ObjectActions from '../../../store/settings/equipment-lists/equipment-lists.actions';
import { take, takeUntil } from 'rxjs/operators';
import { User } from '../../../store/user/model';

@Injectable({
  providedIn: 'root',
})
export class EquipmentListService {
  constructor(
    public http: HttpClient,
    @Inject('API_BASE_URL') private readonly baseUrl: string,
    private readonly excelHelper: ExcelHelperService,
    private readonly store: Store<oeeAppReducer.OeeAppState>,
    private readonly translate: TranslateService,
    private readonly siteService: SiteService,
  ) {
    this.store
      .select('user')
      .pipe(takeUntil(this.destroySubject))
      .subscribe((state) => {
        if (state.isUserLoaded) {
          this.timezone = state.timezone;
          if (state.locale !== '') {
            this.dateCharacterFormat$ = state.dateFormat;
            this.dateFormat$ = excelDateFormat[state.locale !== 'ja' ? state.locale : state.locale + state.dateFormat];
            this.timeFormat$ = excelTimeFormat[state.locale];
          }
          this.destroySubject.next(true);
          this.destroySubject.complete();
        }
      });
  }

  private readonly routes: string = 'equipment-lists';
  private readonly equipmentBrandRoutes: string = 'equipment-brands';
  private readonly equipmentTypeRoutes: string = 'equipment-types';
  private timezone: string = 'utc';
  private dateFormat$: string;
  private timeFormat$: string;
  private dateCharacterFormat$: string;
  private readonly destroySubject: Subject<boolean> = new Subject<boolean>();
  private userLanguage$: string = '';

  getEquipmentList(
    siteId: number,
    equipmentId: number,
    searchText?: string,
  ): Promise<EquipmentListGetManyCRUDDataInterface> {
    const queryParams: {
      $and: object[];
    } = {
      $and: [],
    };
    const searchObject: object = {};
    let params: HttpParams = new HttpParams();

    if (searchText) {
      this.store
        .select('user')
        .pipe(take(1))
        .subscribe((state: User) => {
          this.userLanguage$ = state.language;
        });

      const orFilter = [];
      orFilter.push({ equipmentName: { $cont: searchText } }, { equipmentShortName: { $cont: searchText } });
      queryParams.$and.push({ $or: orFilter });
    }

    queryParams.$and.push({ siteId: { $eq: siteId } });
    queryParams.$and.push({ isCheckable: { $eq: true } });

    if (equipmentId !== null) {
      params = params.set('sort', `id=${equipmentId},DESC`);
    }
    params = params.set('s', JSON.stringify(queryParams));

    return new Promise((resolve, reject) => {
      this.http
        .get(`${this.baseUrl}/${this.routes}`, { params })
        .subscribe((response: EquipmentListGetManyCRUDDataInterface) => {
          if (response.hasOwnProperty('data')) {
            resolve(response);
          } else {
            reject();
          }
        });
    });
  }

  public getEquipmentLists(params: HttpParams): Observable<GetManyResponseInterface<EquipmentListInterface>> {
    return this.http.get<GetManyResponseInterface<EquipmentListInterface>>(`${this.baseUrl}/${this.routes}`, {
      params,
    });
  }

  public downloadEquipmentListExcel(
    withData: boolean,
    filters: EquipmentListsDownloadExcelFiltersInterface,
    withErrorColumn: boolean = false,
    data?: EquipmentListInterface[],
  ): void {
    const httpParams: HttpParams = new HttpParams().set('limit', String(filters.limit));
    const observables: any = [
      this.siteService.getSite(filters.siteId),
      this.getEquipmentBrands(httpParams),
      this.getEquipmentTypes(httpParams),
    ];

    if (withData && !data) {
      observables.push(
        this.getEquipmentLists(
          httpParams
            .set('s', JSON.stringify({ siteId: { $eq: filters.siteId } }))
            .set('page', filters.selectedDownloadOffset)
            .append('join', 'site||name')
            .append('join', 'equipmentBrandRelation||name')
            .append('join', 'equipmentTypeRelation||description'),
        ),
      );
    }

    forkJoin(observables).subscribe((responseList) => {
      const site = _.get(responseList, '0.data', null);
      let equipmentBrands: EquipmentBrandInterface[] = _.get(responseList, '1.data', []);
      let equipmentTypes: EquipmentTypeInterface[] = _.get(responseList, '2.data', []);
      const sheetTitle = this.translate.instant('excel.items.equipmentLists');
      const excelName = `${sheetTitle} ${moment().tz(this.timezone).format(this.dateFormat$)}`;
      let excelData: EquipmentListInterface[] = [];
      const booleanDropdownOptions = this.excelHelper.getExcelBooleanDropdownOptions();
      const multipleCheckInsOptions = this.excelHelper.getMultipleCheckInsOptions();

      if (withData) {
        excelData = _.get(responseList, '3.data', []);

        if (data) {
          for (const equipment of data) {
            equipment.site = equipment.siteId === site.id ? site : null;
            equipment.equipmentBrandRelation = _.find(equipmentBrands, { id: equipment.equipmentBrand });
            equipment.equipmentTypeRelation = _.find(equipmentTypes, { id: equipment.equipmentType });
          }
          excelData = data;
        }

        equipmentBrands = ExcelHelperService.updateExcelDropdownOptionsWithData<
          EquipmentListInterface,
          EquipmentBrandInterface
        >(excelData, equipmentBrands, 'equipmentBrandRelation');
        equipmentTypes = ExcelHelperService.updateExcelDropdownOptionsWithData<
          EquipmentListInterface,
          EquipmentTypeInterface
        >(excelData, equipmentTypes, 'equipmentTypeRelation');
      }

      const excelOptions: CreateExcelInterface = this.getEquipmentListExcelColumns(
        site,
        { equipmentBrands, equipmentTypes },
        withErrorColumn,
      );

      if (withData) {
        excelOptions.data = excelData.map((item) => {
          return {
            ...item,
            isCheckableOption: !_.isNil(item.isCheckable)
              ? _.find(booleanDropdownOptions, { id: String(item.isCheckable) })
              : null,
            multipleCheckInsOption: !_.isNil(item.multipleCheckIns)
              ? _.find(multipleCheckInsOptions, { id: String(item.multipleCheckIns) })
              : null,
            isCheckable: !_.isNil(item.isCheckable) ? String(item.isCheckable) : null,
            multipleCheckIns: !_.isNil(item.multipleCheckIns) ? String(item.multipleCheckIns) : null,
          };
        });
      }

      const worksheets: CreateExcelSheetInterface[] = [
        {
          sheetTitle,
          withData,
          sheetType: ExcelSheetTypeEnum.TABLE,
          params: excelOptions,
        },
      ];

      this.excelHelper.createExcel(excelName, { withData, name: 'equipmentList', siteId: site?.id }, worksheets, this.timezone, this.dateFormat$, this.timeFormat$).then(
        () => {
          this.store.dispatch(new ObjectActions.DownloadEquipmentListExcelCompleted());
        },
        () => {
          this.store.dispatch(new ObjectActions.FetchError({}));
        },
      );
    });
  }

  public getEquipmentListExcelColumns(
    site: { id: number; name: string },
    lookups: { equipmentBrands: EquipmentBrandInterface[]; equipmentTypes: EquipmentTypeInterface[] },
    withErrorColumn: boolean,
  ): CreateExcelInterface {
    const dateFormula: string = this.excelHelper.getExcelDateFormula(this.dateFormat$, this.dateCharacterFormat$);
    const booleanDropdownOptions = this.excelHelper.getExcelBooleanDropdownOptions();
    const multipleCheckInsOptions = this.excelHelper.getMultipleCheckInsOptions();
    const excelColumns: CreateExcelInterface = {
      columns: [
        {
          header: this.translate.instant('equipmentLists.excel.siteId.header'),
          key: 'siteId',
          width: ExcelColumnWidthEnum.DEFAULT,
          type: ValueType.String,
          dropdownOptions: {
            data: [site],
            prop: 'name',
            dataProperty: 'site.name',
            dataId: 'site.id',
          },
          dataValidation: {
            type: CellTypes.LIST,
          },
        },
        {
          header: this.translate.instant('equipmentLists.excel.equipmentName.header'),
          key: 'equipmentName',
          width: ExcelColumnWidthEnum.DEFAULT,
          type: ValueType.String,
          style: { numFmt: '@' },
          dataValidation: {
            type: CellTypes.CUSTOM,
          },
        },
        {
          header: 'id',
          key: 'id',
          width: ExcelColumnWidthEnum.DEFAULT,
          type: ValueType.String,
          style: { numFmt: '@' },
          dataValidation: {
            type: CellTypes.CUSTOM,
          },
        },
        {
          header: this.translate.instant('equipmentLists.excel.equipmentShortName.header'),
          key: 'equipmentShortName',
          width: ExcelColumnWidthEnum.DEFAULT,
          type: ValueType.String,
          style: { numFmt: '@' },
          dataValidation: {
            type: CellTypes.CUSTOM,
          },
        },
        {
          header: this.translate.instant('equipmentLists.excel.equipmentType.header'),
          key: 'equipmentType',
          width: ExcelColumnWidthEnum.DEFAULT,
          type: ValueType.String,
          dropdownOptions: {
            data: _.get(lookups, 'equipmentTypes', []),
            prop: 'name',
            dataProperty: 'equipmentTypeRelation.description',
            dataId: 'equipmentTypeRelation.id',
          },
          dataValidation: {
            type: CellTypes.LIST,
          },
        },
        {
          header: this.translate.instant('equipmentLists.excel.equipmentBrand.header'),
          key: 'equipmentBrand',
          width: ExcelColumnWidthEnum.DEFAULT,
          type: ValueType.String,
          dropdownOptions: {
            data: _.get(lookups, 'equipmentBrands', []),
            prop: 'name',
            dataProperty: 'equipmentBrandRelation.name',
            dataId: 'equipmentBrandRelation.id',
          },
          dataValidation: {
            type: CellTypes.LIST,
          },
        },
        {
          header: this.translate.instant('equipmentLists.excel.equipmentModel.header'),
          key: 'equipmentModel',
          width: ExcelColumnWidthEnum.DEFAULT,
          type: ValueType.String,
          style: { numFmt: '@' },
          dataValidation: {
            type: CellTypes.CUSTOM,
          },
        },
        {
          header: this.translate.instant('equipmentLists.excel.validatedSpeed.header'),
          key: 'validatedSpeed',
          width: ExcelColumnWidthEnum.DEFAULT,
          type: ValueType.String,
          style: { numFmt: '@' },
          allowPunctuation: true,
          dataValidation: {
            type: CellTypes.CUSTOM,
          },
        },
        {
          header: this.translate.instant('equipmentLists.excel.dateOfPurchasing.header'),
          key: 'dateOfPurchasing',
          width: ExcelColumnWidthEnum.DEFAULT,
          type: ValueType.Date,
          style: { numFmt: '@' },
          dataValidation: {
            type: CellTypes.CUSTOM,
            formulae: [dateFormula],
          },
        },
        {
          header: this.translate.instant('equipmentLists.excel.equipmentUsefulLife.header'),
          key: 'equipmentUsefulLife',
          width: ExcelColumnWidthEnum.DEFAULT,
          type: ValueType.Number,
          style: { numFmt: '0' },
          maxLength: 11,
          dataValidation: {
            type: CellTypes.CUSTOM,
          },
        },
        {
          header: this.translate.instant('equipmentLists.excel.equipmentDescription.header'),
          key: 'equipmentDescription',
          width: ExcelColumnWidthEnum.DEFAULT,
          type: ValueType.String,
          style: { numFmt: '@' },
          dataValidation: {
            type: CellTypes.CUSTOM,
          },
        },
        {
          header: this.translate.instant('equipmentLists.excel.isCheckable.header'),
          key: 'isCheckable',
          width: ExcelColumnWidthEnum.DEFAULT,
          type: ValueType.Boolean,
          dropdownOptions: {
            data: booleanDropdownOptions,
            prop: 'name',
            dataProperty: 'isCheckableOption.name',
            dataId: 'isCheckableOption.id',
          },
          dataValidation: {
            type: CellTypes.LIST,
          },
        },
        {
          header: this.translate.instant('equipmentLists.excel.equipmentCheckInCardId.header'),
          key: 'equipmentCheckInCardId',
          width: ExcelColumnWidthEnum.DEFAULT,
          type: ValueType.String,
          style: { numFmt: '@' },
          dataValidation: {
            type: CellTypes.CUSTOM,
          },
        },
        {
          header: this.translate.instant('equipmentLists.excel.checkInsPin.header'),
          key: 'checkInsPin',
          removePropertyIfNull: true,
          width: ExcelColumnWidthEnum.DEFAULT,
          type: ValueType.String,
          style: { numFmt: '@' },
          dataValidation: {
            type: CellTypes.CUSTOM,
          },
        },
        {
          header: this.translate.instant('general.excel.column.multipleCheckIns.name'),
          key: 'multipleCheckIns',
          width: ExcelColumnWidthEnum.DEFAULT,
          type: ValueType.String,
          dropdownOptions: {
            data: multipleCheckInsOptions,
            prop: 'name',
            dataProperty: 'multipleCheckInsOption.name',
            dataId: 'multipleCheckInsOption.id',
          },
          dataValidation: {
            type: CellTypes.LIST,
            prompt: this.translate.instant('general.excel.column.multipleCheckIns.prompt', {
              defaultOption: this.translate.instant('general.siteDefault'),
            }),
          },
        },
      ],
    };

    this.excelHelper.prepareExcelColumns(excelColumns.columns, withErrorColumn);

    return excelColumns;
  }

  private getEquipmentBrands(params: HttpParams): Observable<GetManyResponseInterface<EquipmentBrandInterface>> {
    return this.http.get<GetManyResponseInterface<EquipmentBrandInterface>>(
      `${this.baseUrl}/${this.equipmentBrandRoutes}`,
      {
        params,
      },
    );
  }

  private getEquipmentTypes(params: HttpParams): Observable<GetManyResponseInterface<EquipmentTypeInterface>> {
    return this.http.get<GetManyResponseInterface<EquipmentTypeInterface>>(
      `${this.baseUrl}/${this.equipmentTypeRoutes}`,
      {
        params,
      },
    );
  }

  async getEquipmentListsFromExcel(file: File): Promise<null | EquipmentListDataRetrievalInterface> {
    const workbook: Workbook = await this.excelHelper.getExcelWorkBookFromFile(file);
    const equipmentListSheet: Worksheet = workbook.getWorksheet(this.translate.instant('excel.items.equipmentLists'));
    const siteIdDataSheet: Worksheet = workbook.getWorksheet('siteIdDataSheet');
    const equipmentTypeDataSheet: Worksheet = workbook.getWorksheet('equipmentTypeDataSheet');
    const equipmentBrandDataSheet: Worksheet = workbook.getWorksheet('equipmentBrandDataSheet');

    if (!equipmentListSheet || !siteIdDataSheet || !equipmentTypeDataSheet || !equipmentBrandDataSheet) {
      return null;
    }

    const siteColumns = {
      id: {
        key: 'id',
        type: ValueType.String,
        dataValidationType: CellTypes.CUSTOM,
      },
      name: {
        key: 'name',
        type: ValueType.String,
        dataValidationType: CellTypes.CUSTOM,
      },
    };

    const sites: { id: number; name: string }[] = this.excelHelper.getExcelRowsFromWorkSheet<{
      id: number;
      name: string;
    }>(siteIdDataSheet, siteColumns);

    if (!sites.length) {
      return null;
    }

    const { columns } = this.getEquipmentListExcelColumns(null, null, false);
    const columnKeys = this.excelHelper.getSheetColumnKeys(columns);

    return {
      equipmentListData: {
        equipments: this.excelHelper
          .getExcelRowsFromWorkSheet<EquipmentListInterface>(equipmentListSheet, columnKeys, {
            dateFormat: this.dateFormat$,
            timeFormat: this.timeFormat$,
            timezone: this.timezone,
          })
          .map((equipment) => {
            return {
              ...equipment,
              multipleCheckIns: equipment.multipleCheckIns === null ? null : Number(equipment.multipleCheckIns),
            };
          }),
      },
      siteData: sites,
    };
  }

  uploadExcel(equipmentList: EquipmentListBulkSaveManyInterface): Observable<BulkResponseDataInterface> {
    return this.http.post<BulkResponseDataInterface>(`${this.baseUrl}/${this.routes}/bulk/save`, equipmentList);
  }
}
