<div *ngFor="let groupKey of groupedDataKeys; let i = index">
  <div class="title-container" *ngIf="groupKey !== ''">
    <em
      (click)="toggleCollapse(i)"
      class="fas fa-chevron-down"
      [ngStyle]="{ transform: collapsedStates[i] ? 'rotate(180deg)' : 'rotate(0deg)' }"
    ></em>
    <a
      (click)="navigateToPage(groupKey, groupedData[groupKey]?.link)"
      [class]="groupedData[groupKey]?.link ? 'link' : 'un-link'"
      target="_blank"
    >
      {{ groupedData[groupKey].titleValue }}
    </a>
    <span class="group-info" *ngIf="groupedData[groupKey]?.info">
      (
      {{ 'laborAssetView.kpiCard.totalCheckIn' | translate }}: {{ groupedData[groupKey]?.info.totalCheckIns }},
      {{ 'laborAssetView.kpiCard.available.laborAsset' | translate }}: {{ groupedData[groupKey]?.info.availableLaborAsset }},
      {{ 'laborAssetView.kpiCard.hours.laborAsset' | translate }}: {{ groupedData[groupKey]?.info.laborAssetHours }}
      )
    </span>
  </div>
  <scw-cico
    [cicoData]="groupedData[groupKey].value"
    [progressBarText]="progressBarText"
    [isExpanded]="isExpanded"
    [selectMode]="selectMode"
    [showWorkOrderRow]="showWorkOrderRow"
    [isMatMenuVisible]="isMatMenuVisible"
    [isCollapsedGroups]="collapsedStates[i]"
    (selectedItemsChanged)="onCicoItemSelectionChange($event)"
  ></scw-cico>
</div>
