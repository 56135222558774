import { ILaborAssetHomeState } from './labor-asset-home.model';
import { ActionTypes, HomeActions } from './labor-asset-home.actions';

export const initialState: ILaborAssetHomeState = {
  laborAssetHomeDataLoading: false,
  laborAssetHomeDataLoaded: false,
  laborAssetHomeData: [],
};

export function laborAssetHomeReducer(
  state: ILaborAssetHomeState = initialState,
  action: HomeActions,
): ILaborAssetHomeState {
  switch (action.type) {
    case ActionTypes.GET_LABOR_ASSET_HOME_DATA:
      return {
        ...state,
        laborAssetHomeDataLoading: true,
        laborAssetHomeDataLoaded: false,
      };
    case ActionTypes.GET_LABOR_ASSET_HOME_DATA_COMPLETED:
      return {
        ...state,
        laborAssetHomeDataLoading: false,
        laborAssetHomeDataLoaded: true,
        laborAssetHomeData: action.payload,
      };
    default:
      return state;
  }
}
