import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { Store } from '@ngrx/store';
import { OeeAppState } from '../../../store/oee.reducer';
import * as PhaseSelectionActions from '../../../store/phase-selection/phase-selection.actions';
import { PhaseOptionNames, PhaseOptions } from '../../model/enum/phase-options';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import * as _ from 'lodash';
import {
  DropdownOptionInterface,
  ScwMatSelectRule,
  ISelectGeneric,
} from '../scw-mat-ui/scw-mat-select/scw-mat-select.model';
import { ScwMatSelectComponent } from '../scw-mat-ui/scw-mat-select/scw-mat-select.component';

@Component({
  selector: 'app-phase-selection',
  templateUrl: './phase-selection.component.html',
  styleUrls: ['./phase-selection.component.scss'],
})
export class PhaseSelectionComponent implements OnInit, OnDestroy, OnChanges {
  @ViewChild(ScwMatSelectComponent) selectComponent: ScwMatSelectComponent;
  @Input() lineId: number = null;
  @Input() phaseId: string | null = null;
  @Input() disabled: boolean;
  @Input() isRequired: boolean = true;

  @Output() phaseIdChange: EventEmitter<string> = new EventEmitter<string>();

  private phaseOptions = {
    [PhaseOptionNames[PhaseOptions.NO_PHASE]]: this.translate.instant(
      'activityCards.activityEditForm.phaseOptions.noPhase',
    ),
    [PhaseOptionNames[PhaseOptions.PRE_RUN]]: this.translate.instant('activityCards.activityEditForm.phaseOptions.pre'),
    [PhaseOptionNames[PhaseOptions.RUN]]: this.translate.instant('activityCards.activityEditForm.phaseOptions.run'),
    [PhaseOptionNames[PhaseOptions.POST_RUN]]: this.translate.instant(
      'activityCards.activityEditForm.phaseOptions.post',
    ),
  };
  private phaseSelectionSubscription: Subscription;
  public selectedPhase: ISelectGeneric<string, string>[] = [];
  private readonly requiredFormTranslation: string = this.translate.instant('scwMatForm.validation.required');
  public rule: ScwMatSelectRule[] = [
    {
      required: true,
      message: this.requiredFormTranslation,
    },
  ];

  public phases: DropdownOptionInterface[] = [
    {
      id: PhaseOptions.NO_PHASE,
      name: this.phaseOptions[PhaseOptionNames[PhaseOptions.NO_PHASE]],
    },
    { id: PhaseOptions.PRE_RUN, name: this.phaseOptions[PhaseOptionNames[PhaseOptions.PRE_RUN]] },
    { id: PhaseOptions.RUN, name: this.phaseOptions[PhaseOptionNames[PhaseOptions.RUN]] },
    { id: PhaseOptions.POST_RUN, name: this.phaseOptions[PhaseOptionNames[PhaseOptions.POST_RUN]] },
  ];

  constructor(private store: Store<OeeAppState>, public translate: TranslateService) {
    this.setDefaultPhaseOptions();
  }

  ngOnInit(): void {
    this.lineId = +this.lineId;
    this.phaseSelectionSubscription = this.store.select('phaseSelectionStore').subscribe((state) => {
      if (state.lineId !== this.lineId && this.lineId) {
        this.store.dispatch(new PhaseSelectionActions.GetPhases(this.lineId));
      }

      if (state.phasesLoaded) {
        this.phases = this.phases.map((phase: DropdownOptionInterface) => {
          const phaseName: string = PhaseOptionNames[Number(phase?.id)];
          phase.name =
            typeof state.phases[phaseName] === 'string' ? state.phases[phaseName] : this.phaseOptions[phaseName];

          return phase;
        });

        if (this.phaseId && this.phaseId !== 'undefined') {
          this.selectedPhase = [_.find(this.phases, { id: String(this.phaseId) })];
        }
      }
    });
  }

  private setDefaultPhaseOptions(): void {
    this.phases = [
      {
        id: PhaseOptions.NO_PHASE,
        name: this.phaseOptions[PhaseOptionNames[PhaseOptions.NO_PHASE]],
      },
      { id: PhaseOptions.PRE_RUN, name: this.phaseOptions[PhaseOptionNames[PhaseOptions.PRE_RUN]] },
      { id: PhaseOptions.RUN, name: this.phaseOptions[PhaseOptionNames[PhaseOptions.RUN]] },
      { id: PhaseOptions.POST_RUN, name: this.phaseOptions[PhaseOptionNames[PhaseOptions.POST_RUN]] },
    ];
  }

  public onChangePhase(): void {
    if (this.selectedPhase.length) {
      this.phaseIdChange.emit(this.selectedPhase[0]?.id);
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (!this.lineId) {
      this.setDefaultPhaseOptions();
      this.selectedPhase = [];
    }

    if (
      this.lineId &&
      changes['lineId'] &&
      !changes['lineId'].firstChange &&
      changes['lineId'].previousValue !== changes['lineId'].currentValue
    ) {
      this.store.dispatch(new PhaseSelectionActions.GetPhases(this.lineId));
    }

    if (changes['phaseId'] && changes['phaseId'].currentValue === null && changes['phaseId'].previousValue !== null) {
      this.phaseId = changes['phaseId'].previousValue;
    }

    if (
      changes.hasOwnProperty('phaseId') &&
      changes['phaseId'].currentValue !== null &&
      changes['phaseId'].currentValue !== 'undefined'
    ) {
      this.selectedPhase = [_.find(this.phases, { id: changes['phaseId'].currentValue })];
      this.selectComponent?.reset();
    }
  }

  ngOnDestroy(): void {
    this.phaseSelectionSubscription.unsubscribe();
  }
}
