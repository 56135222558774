import { Actions, createEffect, ofType } from '@ngrx/effects';
import * as ObjectActions from '../product-clone/product-clone.actions';
import { catchError, map, switchMap } from 'rxjs/operators';
import { forkJoin, of } from 'rxjs';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import * as oeeAppReducer from '../../oee.reducer';
import { ProductsService } from '../products/products.service';
import { HttpParams } from '@angular/common/http';
import { ProductInterface } from '../products/products.model';
import { GetManyResponseInterface } from '../../../shared/model/interface/crud-response-interface.model';
import * as AppActions from '../../../store/app/actions';
import { EntityTranslatorService } from '../../../shared/service/entity-translator/entity-translator.service';
import { ProductCloneService } from './product-clone.service';
import * as _ from 'lodash';
import { IProductMultipliers } from '../product-multiplier/product-multiplier.model';
import { LookupsService } from '../../lookups/lookups.service';
import { ILookupData } from '../../lookups/lookups.model';
import { TranslateService } from '@ngx-translate/core';
import { EProductScope } from 'src/app/view/settings/product-settings/products-new-design/product-clone/product-clone.model';
import { IProductConfigurationsData } from 'src/app/view/settings/product-configurations/station-product-configurations/product-configurations.model';
import {
  EProductCloneReviewObservableFunction,
  IProductCloneEndpointFunctions,
  TProductCloneReviewObservableList,
} from './product-clone.model';
import {
  EProductCloneStationLocation,
  IProductCloneLineConfigurationData,
  IProductCloneMasterData,
  IProductCloneMultiplierData,
  IProductCloneReviewData,
  IProductCloneSpeedData,
  IProductCloneStationConfigurationData,
  IProductCloneStationSensorMultiplier,
  IProductCloneUnitMultiplierData,
} from 'src/app/view/settings/product-settings/products-new-design/product-clone/product-clone-review/product-clone-review.interface';
import { ProductSpeedInterface } from '../product-speeds/product-speeds.model';
import { IUnitConversion } from '../unit-conversion/unit-conversion.model';
import { ILinePathProductConfigurations } from '../product-configuration/line-path-product-configuration/line-path-product-configuration.model';

@Injectable()
export class ProductCloneEffects {
  getProducts = createEffect(() =>
    this.actions$.pipe(
      ofType(ObjectActions.PRODUCT_CLONE_PRODUCT_DATA_LOADING),
      switchMap((objectData: ObjectActions.ProductCloneProductDataLoading) => {
        this.store.dispatch(new AppActions.ShowLoader());
        let queryParams: HttpParams = this.productsService.prepareProductsHttpParams(objectData.query);
        queryParams = queryParams.set('fields', 'id,productId');

        return this.productsService
          .getProducts<Pick<ProductInterface, 'id' | 'productId' | 'entityTranslations'>>(queryParams)
          .pipe(
            map((response: GetManyResponseInterface<ProductInterface>) => {
              response.data.forEach((product: ProductInterface) => this.translatorService.translate(product));
              return response;
            }),
            switchMap((response) => {
              return of(new ObjectActions.ProductCloneProductDataLoaded(response), new AppActions.HideLoader());
            }),
            catchError((errorRes) => {
              return of(new ObjectActions.FetchError(errorRes), new AppActions.HideLoader());
            }),
          );
      }),
      catchError((errorRes) => {
        return of(new ObjectActions.FetchError(errorRes), new AppActions.HideLoader());
      }),
    ),
  );

  getProductCount = createEffect(() =>
    this.actions$.pipe(
      ofType(ObjectActions.PRODUCT_CLONE_PRODUCT_COUNT_DATA_LOADING),
      switchMap((objectData: ObjectActions.ProductCloneProductCountDataLoading) => {
        this.store.dispatch(new AppActions.ShowLoader());
        let queryParams: HttpParams = this.productsService.prepareProductsHttpParams(objectData.query);
        queryParams = queryParams.set('fields', 'id,productId');

        return this.productsService.getProducts<Pick<ProductInterface, 'id' | 'productId'>>(queryParams).pipe(
          switchMap((response) => {
            return of(
              new ObjectActions.ProductCloneProductCountDataLoaded(response.total),
              new AppActions.HideLoader(),
            );
          }),
          catchError((errorRes) => {
            return of(new ObjectActions.FetchError(errorRes), new AppActions.HideLoader());
          }),
        );
      }),
      catchError((errorRes) => {
        return of(new ObjectActions.FetchError(errorRes), new AppActions.HideLoader());
      }),
    ),
  );

  getSourceProductInformation = createEffect(() =>
    this.actions$.pipe(
      ofType(ObjectActions.PRODUCT_CLONE_PRODUCT_REVIEW_DATA_LOADING),
      switchMap((objectData: ObjectActions.ProductCloneProductReviewDataLoading) => {
        const { requestedScopes, sourceProductId } = objectData;
        const observables = this.getProductCloneReviewObservableList(requestedScopes, { sourceProductId });

        this.store.dispatch(new AppActions.ShowLoader());

        return forkJoin(observables).pipe(
          map((responses) => {
            const [sourceProductInfo, multipliers, unitMultipliers, stationConfigurations, lookups] = responses;

            const productCloneReviewData: IProductCloneReviewData = {
              masterData:
                sourceProductInfo?.data?.[0] && lookups?.data
                  ? this.mapMasterData(sourceProductInfo.data[0], lookups.data)
                  : undefined,
              productSpeed: sourceProductInfo?.data?.[0]?.productSpeedTable
                ? this.mapProductSpeed(sourceProductInfo.data[0].productSpeedTable)
                : undefined,
              productMultiplier: multipliers?.data ? this.mapProductMultiplier(multipliers.data) : undefined,
              unitMultiplier: unitMultipliers?.data ? this.mapProductUnitMultiplier(unitMultipliers.data) : undefined,
              productLineConfiguration: sourceProductInfo?.data?.[0].linePathProductConfigurations
                ? this.mapProductLineConfiguration(sourceProductInfo.data[0].linePathProductConfigurations)
                : undefined,
              productStationConfiguration: stationConfigurations?.data
                ? this.mapProductStationConfiguration(stationConfigurations.data)
                : undefined,
            };

            return new ObjectActions.ProductCloneProductReviewDataLoaded(productCloneReviewData);
          }),
          catchError((error) => of(new ObjectActions.FetchError(error), new AppActions.HideLoader())),
        );
      }),
      switchMap((action) => of(action, new AppActions.HideLoader())),
    ),
  );

  submitProductClone = createEffect(() =>
    this.actions$.pipe(
      ofType(ObjectActions.PRODUCT_CLONE_PRODUCT_SUBMIT_DATA_LOADING),
      switchMap((objectData: ObjectActions.ProductCloneProductSubmitDataLoading) => {
        this.store.dispatch(new AppActions.ShowLoader());

        return this.productCloneService.cloneProduct(objectData.payload).pipe(
          switchMap(() => of(new ObjectActions.ProductCloneProductSubmitDataLoaded(), new AppActions.HideLoader())),
          catchError((error) => of(new ObjectActions.FetchError(error), new AppActions.HideLoader())),
        );
      }),
      catchError((error) => of(new ObjectActions.FetchError(error), new AppActions.HideLoader())),
    ),
  );

  private requiredEndpointsForSource: Map<EProductScope, EProductCloneReviewObservableFunction[]> = new Map<
    EProductScope,
    EProductCloneReviewObservableFunction[]
  >();

  private endpointFunctions: IProductCloneEndpointFunctions = {
    [EProductCloneReviewObservableFunction.GET_SOURCE_PRODUCT_INFORMATION]: (sourceProductId: number) =>
      this.productCloneService.getSourceProductInformation(sourceProductId),

    [EProductCloneReviewObservableFunction.GET_SOURCE_PRODUCT_LINE_MULTIPLIERS]: (sourceProductId: number) =>
      this.productCloneService.getSourceProductLineMultipliers(sourceProductId),

    [EProductCloneReviewObservableFunction.GET_SOURCE_PRODUCT_UNIT_MULTIPLIERS]: (sourceProductId: number) =>
      this.productCloneService.getSourceProductUnitMultipliers(sourceProductId),

    [EProductCloneReviewObservableFunction.GET_SOURCE_PRODUCT_STATION_CONFIGURATIONS]: (sourceProductId: number) =>
      this.productCloneService.getSourceProductStationConfigurations(sourceProductId),

    [EProductCloneReviewObservableFunction.GET_LOOKUPS]: () => this.lookupService.getLookups(new HttpParams()),

    emptyObservableFunc: () => of(null),
  };

  constructor(
    private readonly actions$: Actions,
    private readonly productsService: ProductsService,
    private readonly productCloneService: ProductCloneService,
    private readonly translatorService: EntityTranslatorService,
    private readonly store: Store<oeeAppReducer.OeeAppState>,
    private readonly lookupService: LookupsService,
    private readonly translate: TranslateService,
  ) {
    this.setRequiredEndpointsForSource();
  }

  private setRequiredEndpointsForSource(): void {
    this.requiredEndpointsForSource.set(EProductScope.MASTER_DATA, [
      EProductCloneReviewObservableFunction.GET_SOURCE_PRODUCT_INFORMATION,
      EProductCloneReviewObservableFunction.GET_LOOKUPS,
    ]);

    this.requiredEndpointsForSource.set(EProductScope.SPEED, [
      EProductCloneReviewObservableFunction.GET_SOURCE_PRODUCT_INFORMATION,
    ]);

    this.requiredEndpointsForSource.set(EProductScope.MULTIPLIER, [
      EProductCloneReviewObservableFunction.GET_SOURCE_PRODUCT_LINE_MULTIPLIERS,
    ]);

    this.requiredEndpointsForSource.set(EProductScope.UNIT_CONVERSION, [
      EProductCloneReviewObservableFunction.GET_SOURCE_PRODUCT_UNIT_MULTIPLIERS,
    ]);

    this.requiredEndpointsForSource.set(EProductScope.STATION_CONFIGURATION, [
      EProductCloneReviewObservableFunction.GET_SOURCE_PRODUCT_STATION_CONFIGURATIONS,
    ]);

    this.requiredEndpointsForSource.set(EProductScope.LINE_CONFIGURATION, [
      EProductCloneReviewObservableFunction.GET_SOURCE_PRODUCT_INFORMATION,
    ]);
  }

  private getProductCloneReviewObservableList(
    scopes: EProductScope[],
    params: { sourceProductId: number },
  ): TProductCloneReviewObservableList {
    const observables: TProductCloneReviewObservableList = [of(null), of(null), of(null), of(null), of(null)];

    scopes.forEach((scope: EProductScope) => {
      const requiredObservableIndexes = this.requiredEndpointsForSource.get(scope);

      requiredObservableIndexes?.forEach((observableIndex: EProductCloneReviewObservableFunction) => {
        observables[observableIndex as unknown as number] = this.endpointFunctions[observableIndex](
          params.sourceProductId,
        );
      });
    });

    return observables;
  }

  private mapMasterData(productMasterData: ProductInterface, lookups: ILookupData[]): IProductCloneMasterData {
    const materialTypeName: string | null = _.camelCase(_.find(lookups, { id: productMasterData.materialType })?.name);
    const planningTypeName: string | null = _.camelCase(_.find(lookups, { id: productMasterData.planningType })?.name);

    return {
      packageSize: productMasterData?.packageSize ?? null,
      customerName: productMasterData?.customer?.name ?? null,
      unitName: productMasterData?.unitType?.name
        ? this.translate.instant(`products.lookups.unit.${_.camelCase(productMasterData.unitType.name)}.title`)
        : null,
      productFamilyName: productMasterData?.productFamily?.name ?? null,
      standardSpeed: productMasterData?.productSpeed ?? null,
      materialTypeName: materialTypeName
        ? this.translate.instant(`products.lookups.materialType.${materialTypeName}`)
        : null,
      minWaitingDuration: productMasterData?.minimumWaitingDuration ?? null,
      planningTypeName: planningTypeName
        ? this.translate.instant(`products.lookups.planningType.${planningTypeName}`)
        : null,
      maxWaitingDuration: productMasterData?.maximumWaitingDuration ?? null,
      planningGroupName: productMasterData?.planningGroup ?? null,
    };
  }

  private mapProductSpeed(productSpeedInformation: ProductSpeedInterface[]): IProductCloneSpeedData[] {
    return (productSpeedInformation || []).map((speed) => ({
      lineName: speed.line?.title ?? null,
      productSpeed: speed.speed ?? null,
    }));
  }

  private mapProductMultiplier(productMultiplier: IProductMultipliers[]): IProductCloneMultiplierData[] {
    return (productMultiplier || []).map((multiplier: IProductMultipliers) => ({
      lineName: multiplier.line?.title ?? null,
      sensorTypeName: multiplier.sensorType ?? null,
      multiplierFactor: multiplier.multiplicationFactor ?? null,
    }));
  }

  private mapProductUnitMultiplier(unitMultiplier: IUnitConversion[]): IProductCloneUnitMultiplierData[] {
    return (unitMultiplier || []).map((unit: IUnitConversion) => ({
      baseUnitName: unit.baseUnitLookup?.name ?? null,
      referenceUnitName: unit.referenceUnitLookup?.name ?? null,
      baseUnitRawInput: unit.baseUnitRawInput ?? null,
      referenceUnitRawInput: unit.referenceUnitRawInput ?? null,
    }));
  }

  private mapProductLineConfiguration(
    productLineConfiguration: ILinePathProductConfigurations[],
  ): IProductCloneLineConfigurationData[] {
    return (productLineConfiguration || []).map((line: ILinePathProductConfigurations) => ({
      path: line.linePath?.name ?? null,
      lineName: line.line?.title ?? null,
      pathLineSpeed: line.speed ?? null,
    }));
  }

  private mapProductStationConfiguration(
    productStationConfiguration: IProductConfigurationsData[],
  ): IProductCloneStationConfigurationData[] {
    return (productStationConfiguration || []).map((station: IProductConfigurationsData) => {
      const midStations: IProductCloneStationSensorMultiplier[] =
        station.midStations?.map((midStation) => ({
          id: midStation.id,
          location: EProductCloneStationLocation.MID,
          stationName: (midStation as unknown as { lineStationName?: string })?.lineStationName ?? null,
          sensorTypeName: midStation.sensorType ?? null,
          multiplierFactor: midStation.multiplicationFactor ?? null,
        })) || [];

      const initialStations: IProductCloneStationSensorMultiplier[] = (station.initialStations || [])
        .filter((initialStation) => {
          return !midStations.some((mid) => mid.id === initialStation.id);
        })
        .map((initialStation) => ({
          id: initialStation.id,
          location: EProductCloneStationLocation.START,
          stationName: (initialStation as unknown as { lineStationName?: string })?.lineStationName ?? null,
          sensorTypeName: initialStation.sensorType ?? null,
          multiplierFactor: initialStation.multiplicationFactor ?? null,
        }));

      const goodStations: IProductCloneStationSensorMultiplier[] = (station.goodStations || [])
        .filter((goodStation) => {
          return !midStations.some((mid) => mid.id === goodStation.id);
        })
        .map((goodStation) => ({
          id: goodStation.id,
          location: EProductCloneStationLocation.END,
          stationName: (goodStation as unknown as { lineStationName?: string }).lineStationName ?? null,
          sensorTypeName: goodStation.sensorType ?? null,
          multiplierFactor: goodStation.multiplicationFactor ?? null,
        }));

      const stations: IProductCloneStationSensorMultiplier[] = [...initialStations, ...midStations, ...goodStations];

      return {
        lineName: station.lineTitle ?? null,
        linePath: station.stationPathName ?? null,
        productSpeed: station.productSpeed ?? null,
        stations,
      };
    });
  }
}
