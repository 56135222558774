import { createReducer, on } from '@ngrx/store';
import { ELoadStatus } from 'src/constants.model';
import { ICommentTypesState } from './comment-types.model';
import * as CommentTypesActions from './comment-types.actions';

export const commentTypesInitialState: ICommentTypesState = {
  commentTypesLoadStatus: ELoadStatus.Initial,
  commentTypes: [],
};

export const commentTypesReducer = createReducer(
  commentTypesInitialState,
  on(
    CommentTypesActions.loadIfNotLoaded,
    (state): ICommentTypesState => ({
      ...state,
      commentTypesLoadStatus:
        state.commentTypesLoadStatus === ELoadStatus.Success ? state.commentTypesLoadStatus : ELoadStatus.Loading,
    }),
  ),
  on(
    CommentTypesActions.loaded,
    (state, { commentTypes }): ICommentTypesState => ({
      ...state,
      commentTypesLoadStatus: ELoadStatus.Success,
      commentTypes,
    }),
  ),
  on(
    CommentTypesActions.errored,
    (state): ICommentTypesState => ({ ...state, commentTypesLoadStatus: ELoadStatus.Failure }),
  ),
);
