import { Inject, Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Store } from '@ngrx/store';
import * as oeeAppReducer from '../../oee.reducer';
import { BaseOneResponseInterface } from '../../../shared/model/interface/crud-response-interface.model';
import { ILinePathAssignedWorkOrders } from './multi-line-change-activity.model';
import { map } from 'rxjs/operators';
import { EntityTranslatorService } from '../../../shared/service/entity-translator/entity-translator.service';
import { ETranslatableEntity } from '../../../shared/service/entity-translator/entity-translator.model';
import { HTTPHelperService } from '../../../shared/service/http/http.helper.service';

@Injectable({
  providedIn: 'root',
})
export class MultiLineChangeActivityService {
  constructor(
    public http: HttpClient,
    @Inject('API_BASE_URL') private readonly api: string,
    public store: Store<oeeAppReducer.OeeAppState>,
    private readonly entityTranslatorService: EntityTranslatorService,
    private readonly httpHelper: HTTPHelperService,
  ) {}

  public getOngoingActivitiesOfLinePath(
    linePathWoSplitResults: number[],
    linePathId: number,
  ): Observable<ILinePathAssignedWorkOrders> {
    const httpParams: HttpParams = this.httpHelper.insertGenericCrudRequestParameters({
      join: [
        'assignedWorkOrders',
        'assignedWorkOrders.assignedLineWithinLinePath',
        'assignedWorkOrders.assignedLineWithinLinePath.currentActivity',
        'assignedWorkOrders.assignedLineWithinLinePath.workOrder',
        'assignedWorkOrders.assignedLineWithinLinePath.currentTask',
        'assignedWorkOrders.assignedLineWithinLinePath.currentTask.equipment',
      ],
      filters: [{ field: 'assignedWorkOrders.id', ids: linePathWoSplitResults }],
    });

    return this.http
      .get<BaseOneResponseInterface<ILinePathAssignedWorkOrders>>(`${this.api}/line-paths/${linePathId}`, {
        params: httpParams,
      })
      .pipe(
        map((response: BaseOneResponseInterface<ILinePathAssignedWorkOrders>) => {
          for (const assignedWorkOrder of response.data.assignedWorkOrders) {
            if (assignedWorkOrder.assignedLineWithinLinePath.currentActivity) {
              assignedWorkOrder.assignedLineWithinLinePath.currentActivity.name =
                this.entityTranslatorService.findTranslatedValue(
                  response.data.entityTranslations,
                  ETranslatableEntity.ACTIVITY,
                  'activityName',
                  assignedWorkOrder.assignedLineWithinLinePath.currentActivity.id,
                  assignedWorkOrder.assignedLineWithinLinePath.currentActivity.name,
                );
            }

            if (assignedWorkOrder.assignedLineWithinLinePath.currentTask) {
              assignedWorkOrder.assignedLineWithinLinePath.currentTask.title =
                this.entityTranslatorService.findTranslatedValue(
                  response.data.entityTranslations,
                  ETranslatableEntity.TASK,
                  'taskName',
                  assignedWorkOrder.assignedLineWithinLinePath.currentTask.id,
                  assignedWorkOrder.assignedLineWithinLinePath.currentTask.title,
                );
            }

            if (assignedWorkOrder.assignedLineWithinLinePath.currentTask?.equipment) {
              assignedWorkOrder.assignedLineWithinLinePath.currentTask.equipment.equipmentName =
                this.entityTranslatorService.findTranslatedValue(
                  response.data.entityTranslations,
                  ETranslatableEntity.EQUIPMENT_LIST,
                  'equipmentName',
                  assignedWorkOrder.assignedLineWithinLinePath.currentTask.equipment.id,
                  assignedWorkOrder.assignedLineWithinLinePath.currentTask.equipment.equipmentName,
                );
            }
          }

          return response.data;
        }),
      );
  }
}
