import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { HttpClient } from '@angular/common/http';
import { Store } from '@ngrx/store';
import { OpcMessageInspectionService } from './opc-message-inspection.service';
import * as oeeAppReducer from '../../oee.reducer';
import * as ObjectActions from './opc-message-inspection.actions';
import * as AppActions from '../../app/actions';
import { of, switchMap } from 'rxjs';
import { emptyAction } from '../../../../constants';
import { catchError } from 'rxjs/operators';
import { BaseOneResponseInterface } from '../../../shared/model/interface/crud-response-interface.model';
import { ActivityLogsResponseInterface, IActivityLog } from '../root-cause-analysis/root-cause-analysis.model';
import { EntityTranslatorService } from '../../../shared/service/entity-translator/entity-translator.service';
import { IOpcMessageDataResponseInterface } from '../../../view/reports/opc-message-inspection/opc-message-inspection.model';

@Injectable()
export class OpcMessageInspectionEffects {
  constructor(
    private readonly actions$: Actions,
    private readonly http: HttpClient,
    private readonly opcMessageInspectionService: OpcMessageInspectionService,
    private readonly store: Store<oeeAppReducer.OeeAppState>,
    private readonly service: OpcMessageInspectionService,
    private readonly entityTranslatorService: EntityTranslatorService,
  ) {}

  getOpcActivityLogsData = createEffect(() =>
    this.actions$.pipe(
      ofType(ObjectActions.OPC_MESSAGE_INSPECTION_ACTIVITY_LOGS_LOADING),
      switchMap((objectData: ObjectActions.OpcMessageInspectionActivityLogsLoading) => {
        this.store.dispatch(new AppActions.ShowLoader());
        return this.service.getActivityLogsData(objectData.params).pipe(
          switchMap((response: BaseOneResponseInterface<ActivityLogsResponseInterface>) => {
            response.data.rows.forEach((activityLog: IActivityLog) => {
              this.entityTranslatorService.translate(activityLog);
            });

            return of(new ObjectActions.OpcMessageInspectionActivityLogsLoaded(response.data));
          }),
          catchError((err) => {
            return of(new ObjectActions.OpcMessageInspectionFetchError(err), new AppActions.HideLoader());
          }),
        );
      }),
      catchError((err) => {
        return of(new ObjectActions.OpcMessageInspectionFetchError(err), new AppActions.HideLoader());
      }),
    ),
  );

  getOpcMessageInspectionData = createEffect(() =>
    this.actions$.pipe(
      ofType(ObjectActions.OPC_MESSAGE_INSPECTION_FETCH_DATA_LOADING),
      switchMap((objectData: ObjectActions.OpcMessageInspectionFetchDataLoading) => {
        this.store.dispatch(new AppActions.ShowLoader());
        return this.service.getOpcMessageInspectionData(objectData.tableQuery).pipe(
          switchMap((response: BaseOneResponseInterface<IOpcMessageDataResponseInterface>) => {
            return of(new ObjectActions.OpcMessageInspectionFetchDataLoaded(response.data));
          }),
          catchError((err) => {
            return of(new ObjectActions.OpcMessageInspectionFetchError(err), new AppActions.HideLoader());
          }),
        );
      }),
      catchError((err) => {
        return of(new ObjectActions.OpcMessageInspectionFetchError(err), new AppActions.HideLoader());
      }),
    ),
  );

  downloadOpcMessageInspectionExcel = createEffect(() =>
    this.actions$.pipe(
      ofType(ObjectActions.OPC_MESSAGE_INSPECTION_DOWNLOAD_EXCEL),
      switchMap((objectData: ObjectActions.DownloadOpcMessageInspectionExcel) => {
        this.service.downloadExcel(objectData.data);
        return emptyAction;
      }),
      catchError((err) => {
        return of(new ObjectActions.OpcMessageInspectionFetchError(err), new AppActions.HideLoader());
      }),
    ),
  );
}
