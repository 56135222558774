import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { of, Subject } from 'rxjs';
import { catchError, switchMap, take, takeUntil } from 'rxjs/operators';
import {
  BulkResponseDataInterface,
  GetManyResponseInterface,
} from '../../../shared/model/interface/crud-response-interface.model';
import { ErrorMessageService } from '../../../shared/service/error-message.service';
import { ExcelHelperService } from '../../../shared/service/excel/excel-helper.service';
import { LineService } from '../../../shared/service/filter/line.service';
import { SensorTypesService } from '../../../shared/service/filter/sensor-types.service';
import {
  LineCRUDResponseInterface,
  SensorTypeCRUDResponseInterface,
} from '../../../shared/service/filter/service.class';
import { ProductMultiplierService } from '../../../shared/service/settings/product-multiplier/product-multiplier.service';
import * as AppActions from '../../app/actions';
import * as oeeAppReducer from '../../oee.reducer';
import { ProductInterface } from '../products/products.model';
import { ProductsService } from '../products/products.service';
import * as ObjectActions from './product-multiplier.actions';
import { IProductMultipliers, ProductMultiplierCRUDResponseInterface } from './product-multiplier.model';
import { EntityTranslatorService } from '../../../shared/service/entity-translator/entity-translator.service';
import * as _ from 'lodash';

@Injectable()
export class ProductMultiplierEffects {
  constructor(
    private readonly actions$: Actions,
    private readonly store: Store<oeeAppReducer.OeeAppState>,
    private readonly productMultiplierService: ProductMultiplierService,
    private readonly errorMessageService: ErrorMessageService,
    private readonly excelHelperService: ExcelHelperService,
    private readonly lineService: LineService,
    private readonly productsService: ProductsService,
    private readonly sensorTypesService: SensorTypesService,
    private readonly translatorService: EntityTranslatorService,
  ) {}

  deleteProductMultiplierTableData$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ObjectActions.DELETE_PRODUCT_MULTIPLIER),
      switchMap((objectData: ObjectActions.DeleteProductMultiplier) => {
        this.store.dispatch(new AppActions.ShowLoader());

        return this.productMultiplierService.deleteProductMultiplier(objectData.id).pipe(
          switchMap((response: ProductMultiplierCRUDResponseInterface) => {
            return of(new ObjectActions.ProductMultiplierDeleted(response), new AppActions.HideLoader());
          }),
          catchError((error: unknown[]) => {
            return of(new ObjectActions.FetchError(error), new AppActions.HideLoader());
          }),
        );
      }),
      catchError((error: unknown[]) => {
        return of(new ObjectActions.FetchError(error), new AppActions.HideLoader());
      }),
    ),
  );

  deleteProductMultipliers$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ObjectActions.DELETE_PRODUCT_MULTIPLIERS),
      switchMap((objectData: ObjectActions.DeleteProductMultipliers) => {
        this.store.dispatch(new AppActions.ShowLoader());

        return this.productMultiplierService.deleteProductMultipliers(objectData.ids).pipe(
          switchMap((response) => {
            return of(new ObjectActions.ProductMultipliersDeleted(response), new AppActions.HideLoader());
          }),
          catchError((error: unknown[]) => {
            return of(new ObjectActions.FetchError(error), new AppActions.HideLoader());
          }),
        );
      }),
      catchError((error: unknown[]) => {
        return of(new ObjectActions.FetchError(error), new AppActions.HideLoader());
      }),
    ),
  );

  createProductMultiplierTableData$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ObjectActions.CREATE_PRODUCT_MULTIPLIER),
      switchMap((objectData: ObjectActions.CreateProductMultiplier) => {
        this.store.dispatch(new AppActions.ShowLoader());

        return this.productMultiplierService.createProductMultiplier(objectData.data).pipe(
          switchMap((response: ProductMultiplierCRUDResponseInterface) => {
            return of(new ObjectActions.ProductMultiplierCreated(response), new AppActions.HideLoader());
          }),
          catchError((error: unknown[]) => {
            return of(new ObjectActions.FetchError(error), new AppActions.HideLoader());
          }),
        );
      }),
      catchError((error: unknown[]) => {
        return of(new ObjectActions.FetchError(error), new AppActions.HideLoader());
      }),
    ),
  );

  updateProductMultiplierTableData$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ObjectActions.UPDATE_PRODUCT_MULTIPLIER),
      switchMap((objectData: ObjectActions.UpdateProductMultiplier) => {
        this.store.dispatch(new AppActions.ShowLoader());

        return this.productMultiplierService.updateProductMultiplier(objectData.data.id, objectData.data).pipe(
          switchMap((response: ProductMultiplierCRUDResponseInterface) => {
            return of(new ObjectActions.ProductMultiplierUpdated(response), new AppActions.HideLoader());
          }),
          catchError((error: unknown[]) => {
            return of(new ObjectActions.FetchError(error), new AppActions.HideLoader());
          }),
        );
      }),
      catchError((error: unknown[]) => {
        return of(new ObjectActions.FetchError(error), new AppActions.HideLoader());
      }),
    ),
  );

  updateProductMultipliers$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ObjectActions.UPDATE_PRODUCT_MULTIPLIERS),
      switchMap((objectData: ObjectActions.UpdateProductMultipliers) => {
        this.store.dispatch(new AppActions.ShowLoader());

        return this.productMultiplierService.updateProductMultipliers(objectData.data).pipe(
          switchMap((response: BulkResponseDataInterface) => {
            return of(new ObjectActions.ProductMultipliersUpdated(response), new AppActions.HideLoader());
          }),
          catchError((error: unknown[]) => {
            return of(new ObjectActions.FetchError(error), new AppActions.HideLoader());
          }),
        );
      }),
      catchError((error: unknown[]) => {
        return of(new ObjectActions.FetchError(error), new AppActions.HideLoader());
      }),
    ),
  );

  getProductMultiplierTableDataCount$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ObjectActions.GET_PRODUCT_MULTIPLIER_COUNT),
      switchMap((objectData: ObjectActions.GetProductMultiplierCount) => {
        this.store.dispatch(new AppActions.ShowLoader());

        let params: HttpParams = new HttpParams();
        params = params.set('s', JSON.stringify({ 'product.siteId': { $eq: objectData.siteId } })).set('limit', '1');

        return this.productMultiplierService.getProductMultiplier(params).pipe(
          switchMap((response: GetManyResponseInterface<IProductMultipliers>) => {
            return of(new ObjectActions.GetProductMultiplierCountCompleted(response), new AppActions.HideLoader());
          }),
          catchError((error: unknown[]) => {
            return of(new ObjectActions.FetchError(error), new AppActions.HideLoader());
          }),
        );
      }),
      catchError((error: unknown[]) => {
        return of(new ObjectActions.FetchError(error), new AppActions.HideLoader());
      }),
    ),
  );

  downloadProductMultiplierExcel$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ObjectActions.DOWNLOAD_PRODUCT_MULTIPLIER_EXCEL),
      switchMap((objectData: ObjectActions.DownloadProductMultiplierExcel) => {
        this.store.dispatch(new AppActions.ShowLoader());

        this.productMultiplierService.downloadProductMultiplierExcel(objectData.withData, objectData.filters);
        return of(new ObjectActions.ProductMultiplierExcelDownloaded());
      }),
      catchError((error: unknown[]) => {
        return of(new ObjectActions.FetchError(error), new AppActions.HideLoader());
      }),
    ),
  );

  uploadProductMultiplierExcel$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ObjectActions.UPLOAD_PRODUCT_MULTIPLIER_EXCEL),
      switchMap((objectData: ObjectActions.UploadProductMultiplierExcel) => {
        this.store.dispatch(new AppActions.ShowLoader());

        return this.productMultiplierService.uploadProductMultiplierExcel(objectData.productMultipliers).pipe(
          switchMap((response: BulkResponseDataInterface) => {
            this.errorMessageService.getTranslatedErrorMessage(response.data);
            const mergedArray = this.excelHelperService.mergeBulkResponseWithRequestData<IProductMultipliers>(
              response,
              objectData.productMultipliers.productMultipliers,
            );
            return of(new ObjectActions.ProductMultiplierExcelUploaded(mergedArray), new AppActions.HideLoader());
          }),
          catchError((error: unknown[]) => {
            return of(new ObjectActions.FetchError(error), new AppActions.HideLoader());
          }),
        );
      }),
      catchError((error: unknown[]) => {
        return of(new ObjectActions.FetchError(error), new AppActions.HideLoader());
      }),
    ),
  );

  getProductMultipliers$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ObjectActions.LOAD_PRODUCT_MULTIPLIERS),
      switchMap((objectData: ObjectActions.LoadProductMultipliers) => {
        const { page, pageSize, search, sort, lineId, siteId, productId } = objectData.requestParams;
        const searchParams: { [key: string]: object[] } = { $and: [] };

        let params: HttpParams = new HttpParams()
          .set('limit', pageSize)
          .set('page', page)
          .append('join', 'line')
          .append('join', 'product.site||name,decimalScaleLimit');

        if (search !== '') {
          searchParams['$and'].push({
            $or: [
              { 'product.productId': { $cont: search } },
              { 'line.title': { $cont: search } },
              { multiplicationFactor: { $cont: search.replace(',', '.') } },
            ],
          });
        }

        if (sort) {
          const direction: 'DESC' | 'ASC' = sort.type === 'descending' ? 'DESC' : 'ASC';
          let field: string = sort.column;

          if (sort.column === 'productName') {
            field = 'product.productId';
          }

          params = params.set('sort', `${field},${direction}`);
        }

        if (siteId !== undefined && siteId !== -1) {
          searchParams['$and'].push({ 'line.siteId': { $in: siteId } });
        }

        if (lineId !== undefined && lineId !== -1) {
          searchParams['$and'].push({ lineId: { $in: lineId } });
        }

        if (productId !== undefined && productId !== -1) {
          searchParams['$and'].push({ productId: { $in: productId } });
        }

        if (searchParams['$and'].length === 0) {
          searchParams['$and'] = undefined;
        }

        params = params.set('s', JSON.stringify(searchParams));

        return this.productMultiplierService.getProductMultiplier(params).pipe(
          switchMap((response: GetManyResponseInterface<IProductMultipliers>) => {
            response.data.forEach((productMultipliers: IProductMultipliers) =>
              this.translatorService.translate(productMultipliers),
            );
            return of(new ObjectActions.ProductMultipliersLoaded(response));
          }),
          catchError((error: unknown[]) => {
            return of(new ObjectActions.FetchError(error));
          }),
        );
      }),
      catchError((error: unknown[]) => {
        return of(new ObjectActions.FetchError(error));
      }),
    ),
  );

  getLines = createEffect(() =>
    this.actions$.pipe(
      ofType(ObjectActions.GET_LINES),
      switchMap(() => {
        this.store.dispatch(new AppActions.ShowLoader());
        const httpParams: HttpParams = new HttpParams()
          .set('fields', 'title,siteId,lineTypeName.lineType')
          .set('limit', '500')
          .set('join', 'lineTypeName');

        return this.lineService.getLines(httpParams).pipe(
          switchMap((response: LineCRUDResponseInterface) => {
            return of(new ObjectActions.GetLinesCompleted(response.data), new AppActions.HideLoader());
          }),
          catchError((error) => {
            return of(new ObjectActions.FetchError(error), new AppActions.HideLoader());
          }),
        );
      }),
      catchError((error) => {
        return of(new ObjectActions.FetchError(error), new AppActions.HideLoader());
      }),
    ),
  );

  getProducts = createEffect(() =>
    this.actions$.pipe(
      ofType(ObjectActions.GET_PRODUCTS),
      switchMap((objectData: ObjectActions.GetProducts) => {
        this.store.dispatch(new AppActions.ShowLoader());
        let httpParams: HttpParams = new HttpParams()
          .set('join', 'site')
          .set('fields', 'productId,siteId,description')
          .set('sort', 'id,DESC')
          .set('limit', 500);

        if (objectData.search) {
          httpParams = httpParams.append(
            's',
            JSON.stringify({
              $or: [{ productId: { $cont: objectData.search } }, { description: { $cont: objectData.search } }],
            }),
          );
        }

        if (!_.isNil(objectData.search) && objectData.search !== '') {
          httpParams = httpParams.append('searchText', String(objectData.search));
        }

        return this.productsService.getProducts(httpParams).pipe(
          switchMap((response: GetManyResponseInterface<ProductInterface>) => {
            response.data.forEach((product: ProductInterface) => this.translatorService.translate(product));
            return of(new ObjectActions.GetProductsCompleted(response.data), new AppActions.HideLoader());
          }),
          catchError((error) => {
            return of(new ObjectActions.FetchError(error), new AppActions.HideLoader());
          }),
        );
      }),
      catchError((error) => {
        return of(new ObjectActions.FetchError(error), new AppActions.HideLoader());
      }),
    ),
  );

  getSensorTypes = createEffect(() =>
    this.actions$.pipe(
      ofType(ObjectActions.GET_SENSOR_TYPES),
      switchMap(() => {
        this.store.dispatch(new AppActions.ShowLoader());
        const httpParams: HttpParams = new HttpParams().set(
          's',
          JSON.stringify({ type: { $notin: ['card_reader', 'sub_sensor', 'temperature', 'humidity'] } }),
        );

        return this.sensorTypesService.getSensorType(httpParams).pipe(
          switchMap((response: SensorTypeCRUDResponseInterface) => {
            return of(new ObjectActions.GetSensorTypesCompleted(response.data), new AppActions.HideLoader());
          }),
          catchError((error) => {
            return of(new ObjectActions.FetchError(error), new AppActions.HideLoader());
          }),
        );
      }),
      catchError((error) => {
        return of(new ObjectActions.FetchError(error), new AppActions.HideLoader());
      }),
    ),
  );
}
