import { FilterIotDeviceActions, FilterIotDeviceActionTypes } from './iot-device-filter.actions';
import { IIotDeviceType } from '../../../shared/service/iot-device-report/iot-device-report.model';

export interface FilterIotDeviceState {
  isLoading: boolean;
  isLoaded: boolean;
  data: Partial<IIotDeviceType>[];
}

export const initialFilterIotDeviceState: FilterIotDeviceState = {
  isLoaded: false,
  isLoading: false,
  data: [],
};

export function filterIotDeviceReducer(
  state = initialFilterIotDeviceState,
  action: FilterIotDeviceActions,
): FilterIotDeviceState {
  switch (action.type) {
    case FilterIotDeviceActionTypes.StartLoadFilterIotDevice:
      return { ...state, isLoading: true, isLoaded: false, data: [] };

    case FilterIotDeviceActionTypes.FilterIotDeviceLoaded:
      return { ...state, data: action.data, isLoaded: true, isLoading: false };

    default:
      return state;
  }
}
