import { ScwMatButtonGroupButtons } from '../../../../shared/component/scw-mat-ui/scw-mat-button-group/scw-mat-button-group.model';

export const expandCollapseButtons: ScwMatButtonGroupButtons[] = [
  {
    iconClass: 'fas fa-window-minimize',
    value: false,
  },
  {
    iconClass: 'far fa-window-maximize',
    value: true,
  },
];
