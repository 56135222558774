import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { ResourcesService } from './resources.service';
import * as oeeAppReducer from '../../../../oee.reducer';
import { Store } from '@ngrx/store';
import * as ObjectActions from './resources.actions';
import { catchError, map, switchMap } from 'rxjs/operators';
import { forkJoin, from, Observable, of } from 'rxjs';
import * as AppActions from '../../../../app/actions';
import {
  BaseOneResponseInterface,
  BulkResponseDataInterface,
  GetManyResponseInterface,
} from '../../../../../shared/model/interface/crud-response-interface.model';
import { IProductResourceData, IProductResourceSet, ResourceGroup } from './resources.model';
import { HTTPHelperService } from '../../../../../shared/service/http/http.helper.service';
import { HttpParams } from '@angular/common/http';
import { IProductResource, ProductInterface } from '../../products.model';
import { ResponseArrayInterface } from '../../../../../shared/model/interface/generic-api-response.model';

@Injectable()
export class ResourcesEffects {
  constructor(
    private readonly actions$: Actions<ObjectActions.ResourcesActions>,
    private readonly httpHelperService: HTTPHelperService,
    private readonly store: Store<oeeAppReducer.OeeAppState>,
    private readonly service: ResourcesService,
  ) {}


  getResourceGroups$ = createEffect(() => this.actions$.pipe(
    ofType(ObjectActions.ResourcesActionTypes.LOAD_RESOURCE_GROUPS),
    switchMap((response: ObjectActions.LoadResourceGroups) => {
      return this.service.getResourceGroups(response.payload.siteId).pipe(
        map(
          (result: ResponseArrayInterface<ResourceGroup>) => new ObjectActions.ResourceGroupsLoadedSuccess(result.data),
        ),
        catchError(() => of(new ObjectActions.ResourceGroupsLoadedError())),
      );
    }),
    catchError(() => of(new ObjectActions.ResourceGroupsLoadedError())),
  ));


  getManyProductResources$ = createEffect(() => this.actions$.pipe(
    ofType(ObjectActions.ResourcesActionTypes.GET_MANY_PRODUCT_RESOURCES_LOADING),
    switchMap((objectData: ObjectActions.GetManyProductResourcesLoading) => {
      const productParams: HttpParams = new HttpParams().append('join', 'site||decimalScaleLimit');

      return forkJoin([
        this.service.getManyProductResources(objectData.parameters),
        this.service.getProduct(objectData.productId, productParams),
      ]).pipe(
        switchMap(
          (
            response: (GetManyResponseInterface<IProductResourceSet> | BaseOneResponseInterface<ProductInterface>)[],
          ) => {
            const productResourcesData: IProductResourceData = {
              productResources: response[0] as GetManyResponseInterface<IProductResourceSet>,
              product: response[1] as BaseOneResponseInterface<ProductInterface>,
            };

            return of(new ObjectActions.GetManyProductResourcesLoaded(productResourcesData));
          },
        ),
        catchError((errorRes) => {
          return of(new ObjectActions.FetchError(errorRes), new AppActions.HideLoader());
        }),
      );
    }),
    catchError((errorRes) => {
      return of(new ObjectActions.FetchError(errorRes), new AppActions.HideLoader());
    }),
  ));

  addProductResources = createEffect(() => this.actions$.pipe(
    ofType(ObjectActions.ResourcesActionTypes.ADD_PRODUCT_RESOURCE),
    switchMap((payload: ObjectActions.AddProductResource) => {
      this.store.dispatch(new AppActions.ShowLoader());

      return from(this.service.addProductResource(payload.body)).pipe(
        switchMap((response: BaseOneResponseInterface<IProductResourceSet>) => {
          return of(new ObjectActions.AddProductResourceCompleted(response.data));
        }),
        catchError((errorRes) => {
          return of(new ObjectActions.FetchError(errorRes), new AppActions.HideLoader());
        }),
      );
    }),
    catchError((errorRes) => {
      return of(new ObjectActions.FetchError(errorRes), new AppActions.HideLoader());
    }),
  ));

  addProductResourcesItem = createEffect(() => this.actions$.pipe(
    ofType(ObjectActions.ResourcesActionTypes.ADD_PRODUCT_RESOURCE_ITEM),
    switchMap((payload: ObjectActions.AddProductResourceItem) => {
      return from(this.service.addProductResourceItems(payload.body)).pipe(
        switchMap((response: GetManyResponseInterface<BulkResponseDataInterface>) => {
          return of(new ObjectActions.AddProductResourceItemCompleted(response), new AppActions.HideLoader());
        }),
        catchError((errorRes) => {
          return of(new ObjectActions.FetchError(errorRes), new AppActions.HideLoader());
        }),
      );
    }),
    catchError((errorRes) => {
      return of(new ObjectActions.FetchError(errorRes), new AppActions.HideLoader());
    }),
  ));

  editProductResources = createEffect(() => this.actions$.pipe(
    ofType(ObjectActions.ResourcesActionTypes.EDIT_PRODUCT_RESOURCE),
    switchMap((payload: ObjectActions.EditProductResource) => {
      this.store.dispatch(new AppActions.ShowLoader());

      const observables: Observable<BulkResponseDataInterface | BaseOneResponseInterface<IProductResource>>[] = [
        this.service.editProductResource(payload.params.resourceId, payload.params.resource),
      ];

      if (payload.params.resourceItemsToCreate.length) {
        observables.push(this.service.addProductResourceItems(payload.params.resourceItemsToCreate));
      }

      if (payload.params.resourceItemsToEdit.length) {
        observables.push(this.service.editProductResourceItems(payload.params.resourceItemsToEdit));
      }

      if (payload.params.resourceItemsToDelete.length) {
        observables.push(this.service.deleteProductResourceItems(payload.params.resourceItemsToDelete));
      }

      return forkJoin(observables).pipe(
        switchMap(() => {
          return of(new ObjectActions.EditProductResourceCompleted(), new AppActions.HideLoader());
        }),
        catchError((errorRes) => {
          return of(new ObjectActions.FetchError(errorRes), new AppActions.HideLoader());
        }),
      );
    }),
    catchError((errorRes) => {
      return of(new ObjectActions.FetchError(errorRes), new AppActions.HideLoader());
    }),
  ));

  deleteProductResource = createEffect(() => this.actions$.pipe(
    ofType(ObjectActions.ResourcesActionTypes.DELETE_PRODUCT_RESOURCE),
    switchMap((payload: ObjectActions.DeleteProductResource) => {
      this.store.dispatch(new AppActions.ShowLoader());
      return from(this.service.deleteProductResource(payload.productResourcesId)).pipe(
        switchMap(() => {
          return of(new ObjectActions.DeleteProductResourceCompleted(true), new AppActions.HideLoader());
        }),
        catchError((errorRes) => {
          return of(
            new ObjectActions.FetchError(errorRes),
            new ObjectActions.DeleteProductResourceCompleted(false),
            new AppActions.HideLoader(),
          );
        }),
      );
    }),
    catchError((errorRes) => {
      return of(new ObjectActions.FetchError(errorRes), new AppActions.HideLoader());
    }),
  ));

  deleteProductResources = createEffect(() => this.actions$.pipe(
    ofType(ObjectActions.ResourcesActionTypes.DELETE_PRODUCT_RESOURCES),
    switchMap((payload: ObjectActions.DeleteProductResources) => {
      this.store.dispatch(new AppActions.ShowLoader());

      return from(this.service.deleteProductResources(payload.productResourcesIds)).pipe(
        switchMap((response: BulkResponseDataInterface) => {
          return of(new ObjectActions.DeleteProductResourcesCompleted(response), new AppActions.HideLoader());
        }),
        catchError((errorRes) => {
          return of(new ObjectActions.FetchError(errorRes), new AppActions.HideLoader());
        }),
      );
    }),
    catchError((errorRes) => {
      return of(new ObjectActions.FetchError(errorRes), new AppActions.HideLoader());
    }),
  ));
}
