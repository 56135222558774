<progress-bar-metric
  [actualValue]="workOrderActualDuration"
  [actualValueUnit]="unit"
  [expectedValue]="workOrderTargetDuration"
  [expectedValueUnit]="unit"
  [progressBarTitle]="workOrderDurationText"
  [progressBarRateCapped]="woDurationAndTargetRateCapped"
  [progressBarRate]="woDurationAndTargetRate"
>
</progress-bar-metric>
