import { Inject, Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';
import { GetManyResponseInterface } from '../../../shared/model/interface/crud-response-interface.model';
import { ILaborAssetViewData } from '../../dashboards/labor-asset-view/labor-asset-view.model';

@Injectable({ providedIn: 'root' })
export class LaborAssetHomeService {
  constructor(
    public http: HttpClient,
    @Inject('API_BASE_URL') private readonly baseUrl: string,
    private readonly translate: TranslateService,
  ) {}

  private readonly routes = {
    LINES: 'lines',
    LABOR_ASSET_HOME: 'labor-asset',
  };

  public getLaborAssetHomeData(
    lineId: number,
    params: HttpParams,
  ): Observable<GetManyResponseInterface<ILaborAssetViewData>> {
    return this.http.get<GetManyResponseInterface<ILaborAssetViewData>>(
      `${this.baseUrl}/${this.routes.LINES}/${lineId}/${this.routes.LABOR_ASSET_HOME}`,
      { params },
    );
  }
}
