import * as Actions from './labor-scheduler.actions';
import { LaborSchedulerStateInterface } from './labor-scheduler.model';
import { LaborSchedulerActionTypes } from './labor-scheduler.actions';

const initialState: LaborSchedulerStateInterface = {
  ganttItemsLoading: false,
  ganttItemsLoaded: false,
  ganttItems: null,
  itemsToPlannedAllItemsLoading: false,
  itemsToPlannedAllItemsLoaded: false,
  itemsToPlannedAllItems: null,
};

export function laborSchedulerReducer(
  state: LaborSchedulerStateInterface = initialState,
  action: Actions.LaborSchedulerActions,
): LaborSchedulerStateInterface {
  switch (action.type) {
    case LaborSchedulerActionTypes.LoadLaborSchedulerGanttAllItems:
      return {
        ...state,
        ganttItemsLoading: true,
        ganttItemsLoaded: false,
      };

    case LaborSchedulerActionTypes.LaborSchedulerGanttAllItemsLoaded:
      return {
        ...state,
        ganttItemsLoading: false,
        ganttItemsLoaded: true,
        ganttItems: action.payload,
      };

    case LaborSchedulerActionTypes.LoadItemsToPlannedAllItems:
      return {
        ...state,
        itemsToPlannedAllItemsLoading: true,
        itemsToPlannedAllItemsLoaded: false,
      };

    case LaborSchedulerActionTypes.ItemsToPlannedAllItemsLoaded:
      return {
        ...state,
        itemsToPlannedAllItemsLoading: false,
        itemsToPlannedAllItemsLoaded: true,
        itemsToPlannedAllItems: action.payload,
      };

    default:
      return state;
  }
}
