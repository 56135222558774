export interface IBase64ImageEmitter {
  image: string;
  thumbnail: string;
}

export enum EFileUploadSource {
  COMMENTS = 'comments',
}

export enum EImageMimeType {
  JPG = 'jpg',
  JPEG = 'jpeg',
  PNG = 'png',
  HEIC = 'heic',
}

export enum ECameraErrors {
  PERMISSION_DENIED = 'NotAllowedError',
}
