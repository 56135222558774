import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, TemplateRef, ViewChild } from '@angular/core';
import { EActivityHistoryTableCalledFrom } from '../../../../constants';
import { ECloseDialogMessage } from '../../../view/home/work-order/work-order.model';
import { Subject } from 'rxjs';
import {
  EActivityHistoryTableTypes,
  IActivityHistoryTableConfiguration,
} from '../../../view/activity-history/activity-history.model';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { ScwMatButtonGroupButtons } from '../scw-mat-ui/scw-mat-button-group/scw-mat-button-group.model';
import { TranslateService } from '@ngx-translate/core';
import { OnDestroyDecorator } from '../../decorator/on-destroy-decorator';
import { IWorkOrderActivityHistoryModalData, SelectedGroupButtonOption } from './work-order-activity-history.model';

@OnDestroyDecorator
@Component({
  selector: 'app-work-order-activity-history',
  templateUrl: './work-order-activity-history.component.html',
  styleUrls: ['./work-order-activity-history.component.scss'],
})
export class WorkOrderActivityHistoryComponent implements OnInit, OnDestroy {
  @ViewChild('activity_history_modal') activityHistoryModal: TemplateRef<any>;
  @Input() workOrderActivityHistoryModalData?: IWorkOrderActivityHistoryModalData;
  @Input() showMissingDataButton: boolean = false;
  @Input() isWorkOrderMissing: boolean = false;
  @Output() workOrderActivityHistoryModalClose: EventEmitter<void> = new EventEmitter<void>();

  public workOrderActivityHistoryModalRef: NgbModalRef;
  protected readonly activityHistoryTableCalledFrom = EActivityHistoryTableCalledFrom;
  protected cancelButtonClicked: Subject<ECloseDialogMessage> = new Subject<ECloseDialogMessage>();
  protected activityHistoryTableSettings: IActivityHistoryTableConfiguration = {
    workOrderId: null,
  };
  protected reloadLineViewAndMissingData: EventEmitter<EActivityHistoryTableTypes> =
    new EventEmitter<EActivityHistoryTableTypes>();
  protected isLoading: boolean = false;
  protected selectedGroupButtonOption: boolean = SelectedGroupButtonOption.MISSING_DATA;

  protected buttonGroup: ScwMatButtonGroupButtons[] = [
    {
      value: false,
      text: this.translate.instant('activityHistory.buttonGroup.allActivities'),
    },
    {
      value: true,
      text: this.translate.instant('activityHistory.buttonGroup.missingData'),
    },
  ];

  constructor(private readonly ngbModal: NgbModal, public translate: TranslateService) {}

  ngOnInit(): void {}

  public onClickCancelButton(): void {
    this.selectedGroupButtonOption = SelectedGroupButtonOption.MISSING_DATA;
    this.workOrderActivityHistoryModalClose.emit();

    if (this.workOrderActivityHistoryModalRef) {
      this.workOrderActivityHistoryModalRef.close();
    }
  }

  public onWorkOrderActivityHistoryModalOpen(): void {
    Object.assign(this.activityHistoryTableSettings, {
      workOrderId: this.workOrderActivityHistoryModalData?.id,
      isWorkOrderFinalized: Boolean(this.workOrderActivityHistoryModalData?.completed),
      isWorkOrderCanceled: this.workOrderActivityHistoryModalData?.canceled,
      workOrder: this.workOrderActivityHistoryModalData?.workOrderData,
    });

    this.workOrderActivityHistoryModalRef = this.ngbModal.open(this.activityHistoryModal, {
      windowClass: 'scw-modal-xl',
      keyboard: false,
      backdrop: 'static',
    });
  }

  public onButtonGroupChange(value: boolean): void {
    this.selectedGroupButtonOption = value;
    this.reloadLineViewAndMissingData.emit(
      this.selectedGroupButtonOption ? EActivityHistoryTableTypes.MISSING_DATA : EActivityHistoryTableTypes.LINE_VIEW,
    );
  }

  public ngOnDestroy(): void {}
}
