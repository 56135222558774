<div class="modal-header scw-modal-header">
  <h4 class="modal-title"><ng-content select="[modal-title]"></ng-content></h4>
  <button type="button" class="close" aria-label="Close" onfocus="blur()" (click)="onCloseButtonClicked()">
    <span aria-hidden class="fas fa-times"></span>
  </button>
</div>

<div class="modal-body d-flex flex-column"><ng-content select="[modal-body]"></ng-content></div>

<div class="modal-footer"><ng-content select="[modal-footer]"></ng-content></div>
