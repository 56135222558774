import * as Actions from '../home-page-display-settings/home-page-display-settings.actions';
import { IHomePageDisplaySettingsState, SelectElementOptions } from './home-page-display-settings.model';

export const homePageDisplaySettingsState: IHomePageDisplaySettingsState = {
  isLineDataLoading: false,
  isLineDataLoaded: false,
  isAddLayoutLoading: false,
  isAddLayoutLoaded: false,
  isEditLayoutLoading: false,
  isEditLayoutLoaded: false,
  isGetLayoutDataLoaded: false,
  isGetLayoutDataLoading: false,
  isAddHomeMetricSetLoading: false,
  isAddHomeMetricSetLoaded: false,
  isEditHomeMetricSetLoading: false,
  isEditHomeMetricSetLoaded: false,
  isHomeMetricSetLoading: false,
  isHomeMetricSetLoaded: false,
  lineData: [],
  layout: [],
  isAddActivityOrderItemsLoading: false,
  isAddActivityOrderItemsLoaded: false,
  isEditActivityOrderItemsLoading: false,
  isEditActivityOrderItemsLoaded: false,
  isLayoutActivitiesDataLoading: false,
  isLayoutActivitiesDataLoaded: false,
  layoutActivitiesData: [],
  isAddLayoutCardTemplateDataLoading: false,
  isAddLayoutCardTemplateDataLoaded: false,
  isEditLayoutCardTemplateDataLoading: false,
  isEditLayoutCardTemplateDataLoaded: false,
  isDeleteLayoutLoading: false,
  isDeleteLayoutLoaded: false,
  isLayoutCardTemplateLoading: false,
  isLayoutCardTemplateLoaded: false,
  layoutCardTemplateData: [],
  isProductUnitsLoading: false,
  isProductUnitsLoaded: false,
  productUnits: [],
  isProductUnitConversionsLoading: false,
  isProductUnitConversionsLoaded: false,
  productUnitConversions: [],
};

export function homePageDisplaySettingsReducer(
  state: IHomePageDisplaySettingsState = homePageDisplaySettingsState,
  action: Actions.HomePageLayoutActions,
): IHomePageDisplaySettingsState {
  switch (action.type) {
    case Actions.GET_LINES:
      return {
        ...state,
        isLineDataLoading: true,
        isLineDataLoaded: false,
      };
    case Actions.GET_LINES_COMPLETED:
      const lineDropdownResource: SelectElementOptions[] = [];

      for (const item of action.payload.data) {
        lineDropdownResource.push({
          label: item.title,
          value: String(item.id),
        });
      }

      return {
        ...state,
        lineData: lineDropdownResource,
        isLineDataLoading: false,
        isLineDataLoaded: true,
      };
    case Actions.ADD_LAYOUT:
      return {
        ...state,
        isAddLayoutLoading: true,
        isAddLayoutLoaded: false,
      };
    case Actions.ADD_LAYOUT_COMPLETED:
      return {
        ...state,
        isAddLayoutLoading: false,
        isAddLayoutLoaded: true,
      };
    case Actions.EDIT_LAYOUT:
      return {
        ...state,
        isEditLayoutLoading: true,
        isEditLayoutLoaded: false,
      };
    case Actions.EDIT_LAYOUT_COMPLETED:
      return {
        ...state,
        isEditLayoutLoading: false,
        isEditLayoutLoaded: true,
      };
    case Actions.LAYOUT_DATA_LOADING:
      return {
        ...state,
        isGetLayoutDataLoading: true,
        isGetLayoutDataLoaded: false,
      };
    case Actions.LAYOUT_DATA_LOADED:
      return {
        ...state,
        isGetLayoutDataLoading: false,
        isGetLayoutDataLoaded: true,
        layout: action.payload.data,
      };
    case Actions.ADD_HOME_METRIC_SET_LOADING:
      return {
        ...state,
        isAddHomeMetricSetLoading: true,
        isAddHomeMetricSetLoaded: false,
      };
    case Actions.ADD_HOME_METRIC_SET_LOADED:
      return {
        ...state,
        isAddHomeMetricSetLoading: false,
        isAddHomeMetricSetLoaded: true,
      };
    case Actions.EDIT_HOME_METRIC_SET_LOADING:
      return {
        ...state,
        isEditHomeMetricSetLoading: true,
        isEditHomeMetricSetLoaded: false,
      };
    case Actions.EDIT_HOME_METRIC_SET_LOADED:
      return {
        ...state,
        isEditHomeMetricSetLoading: false,
        isEditHomeMetricSetLoaded: true,
      };
    case Actions.GET_HOME_METRIC_SET:
      return {
        ...state,
        isHomeMetricSetLoading: true,
        isHomeMetricSetLoaded: false,
      };
    case Actions.GET_HOME_METRIC_SET_COMPLETED:
      return {
        ...state,
        isHomeMetricSetLoading: false,
        isHomeMetricSetLoaded: true,
      };
    case Actions.ADD_ACTIVITY_ORDER_ITEMS_DATA:
      return {
        ...state,
        isAddActivityOrderItemsLoading: true,
        isAddActivityOrderItemsLoaded: false,
      };
    case Actions.ADD_ACTIVITY_ORDER_ITEMS_DATA_COMPLETED:
      return {
        ...state,
        isAddActivityOrderItemsLoading: false,
        isAddActivityOrderItemsLoaded: true,
      };
    case Actions.EDIT_ACTIVITY_ORDER_ITEMS_DATA:
      return {
        ...state,
        isEditActivityOrderItemsLoading: true,
        isEditActivityOrderItemsLoaded: false,
      };
    case Actions.EDIT_ACTIVITY_ORDER_ITEMS_DATA_COMPLETED:
      return {
        ...state,
        isEditActivityOrderItemsLoading: false,
        isEditActivityOrderItemsLoaded: true,
      };
    case Actions.LAYOUT_ACTIVITIES_DATA_LOADING:
      return {
        ...state,
        isLayoutActivitiesDataLoading: true,
        isLayoutActivitiesDataLoaded: false,
      };
    case Actions.LAYOUT_ACTIVITIES_DATA_LOADED:
      return {
        ...state,
        isLayoutActivitiesDataLoading: false,
        isLayoutActivitiesDataLoaded: true,
        layoutActivitiesData: action.payload.data,
      };
    case Actions.ADD_LAYOUT_CARD_TEMPLATE_DATA_COMPLETED:
      return {
        ...state,
        isAddLayoutCardTemplateDataLoading: false,
        isAddLayoutCardTemplateDataLoaded: true,
      };
    case Actions.EDIT_LAYOUT_CARD_TEMPLATE_DATA:
      return {
        ...state,
        isEditLayoutCardTemplateDataLoading: true,
        isEditLayoutCardTemplateDataLoaded: false,
      };
    case Actions.EDIT_LAYOUT_CARD_TEMPLATE_DATA:
      return {
        ...state,
        isEditLayoutCardTemplateDataLoading: true,
        isEditLayoutCardTemplateDataLoaded: false,
      };
    case Actions.EDIT_LAYOUT_CARD_TEMPLATE_DATA_COMPLETED:
      return {
        ...state,
        isEditLayoutCardTemplateDataLoading: false,
        isEditLayoutCardTemplateDataLoaded: true,
      };
    case Actions.DELETE_LAYOUT:
      return {
        ...state,
        isDeleteLayoutLoading: true,
        isDeleteLayoutLoaded: false,
      };
    case Actions.DELETE_LAYOUT_COMPLETED:
      return {
        ...state,
        isDeleteLayoutLoading: false,
        isDeleteLayoutLoaded: true,
      };
    case Actions.GET_LAYOUT_CARD_TEMPLATE:
      return {
        ...state,
        isLayoutCardTemplateLoading: true,
        isLayoutCardTemplateLoaded: false,
      };
    case Actions.GET_LAYOUT_CARD_TEMPLATE_COMPLETED:
      return {
        ...state,
        isLayoutCardTemplateLoading: false,
        isLayoutCardTemplateLoaded: true,
        layoutCardTemplateData: action.payload.data,
      };
    case Actions.GET_PRODUCT_UNITS:
      return {
        ...state,
        isProductUnitsLoading: true,
        isProductUnitsLoaded: false,
      };
    case Actions.GET_PRODUCT_UNITS_COMPLETED:
      return {
        ...state,
        isProductUnitsLoading: false,
        isProductUnitsLoaded: true,
        productUnits: action.payload.data,
      };
    case Actions.GET_PRODUCT_UNIT_CONVERSIONS:
      return {
        ...state,
        isProductUnitConversionsLoading: true,
        isProductUnitConversionsLoaded: false,
      };
    case Actions.GET_PRODUCT_UNIT_CONVERSIONS_COMPLETED:
      return {
        ...state,
        isProductUnitConversionsLoading: false,
        isProductUnitConversionsLoaded: true,
        productUnitConversions: action.payload.data,
      };
    default:
      return state;
  }
}
