import { Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import {
  GetNavigationInformationResponseInterface,
  MenuInterface,
  MenuItemInterface,
  MenuTreeInterface,
} from '../../../store/main/main.model';
import { ResponseInterface } from '../../model/interface/generic-api-response.model';
import { TranslateService } from '@ngx-translate/core';
@Injectable({
  providedIn: 'root',
})
export class MenuService {
  constructor(
    public http: HttpClient,
    @Inject('API_BASE_URL') private readonly api: string,
    private translate: TranslateService,
  ) {}

  private readonly routes = {
    getNavigationInformation: '/menus/get-navigation-information',
  };

  public getNavigationInformation(): Observable<ResponseInterface<GetNavigationInformationResponseInterface>> {
    return this.http.get<ResponseInterface<GetNavigationInformationResponseInterface>>(
      `${this.api}${this.routes.getNavigationInformation}`,
    );
  }

  public prepareAngularNavigationStructure(response: GetNavigationInformationResponseInterface): MenuItemInterface[] {
    return this.convertMenuItemsToAbleProMenuStructure(response.menus);
  }

  private convertMenuItemsToAbleProMenuStructure(nodes: MenuInterface[]): MenuItemInterface[] {
    const treeNodes: MenuTreeInterface[] = this.buildTreeMenu(nodes.filter((menu: MenuInterface) => !menu.isHidden));

    for (const node of treeNodes) {
      if (node.parentId !== 0) {
        continue;
      }

      node.main = [
        {
          ...node,
          children: node.children,
        },
      ];

      delete node.children;
    }

    return treeNodes;
  }

  private buildTreeMenu(nodes: MenuTreeInterface[], parentId: number = 0): MenuTreeInterface[] {
    const roots: MenuTreeInterface[] = [];

    for (const node of nodes) {
      if (node.parentId !== parentId) {
        continue;
      }

      const children = this.buildTreeMenu(nodes, node.id);

      if (children) {
        node.children = children;
      }

      let menuName: string;
      let key: string;

      if (node.staticName !== null) {
        menuName = node.staticName;
        key = 'embed_page';
      } else {
        menuName = this.translate.instant(`menus.${node.name}`);
        key = node.name;
      }

      roots.push({
        ...node,
        key,
        name: menuName,
        state: node.link,
        type: node.link === '#' ? 'sub' : 'link',
      });
    }

    return roots;
  }
}
