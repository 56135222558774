<ng-template #camera_modal let-modal>
  <div class="modal-header scw-modal-header">
    <h3 class="modal-title scw-header">
      {{ 'fileUpload.cameraModal.header' | translate }}
    </h3>
    <button type="button" class="close" onfocus="blur()" aria-label="Close" (click)="onCloseModal()">
      <span aria-hidden="true">
        <em class="fas fa-times"></em>
      </span>
    </button>
  </div>
  <div class="modal-body">
    <div *ngIf="!isCapturingMode" class="quality-button-wrapper">
      <scw-mat-button size="sm" [className]="{ active: isOriginalQuality }" type="standard" (onClick)="changeQuality()">
        {{ 'fileUpload.cameraModal.originalQuality' | translate }}
      </scw-mat-button>
      <i [autoClose]="false" [ngbTooltip]="imageQualityTooltip" class="fas fa-info-circle kpi-view-info ml-2"></i>
    </div>
    <div class="container modal-container mt-2">
      <ng-container *ngIf="isCameraAvailable || isFromGallery; else permissionDeniedTemplate">
        <ng-container *ngIf="isCapturingMode; else imageContainer">
          <div class="col-12">
            <webcam
              class="camera-img-container"
              [width]="612"
              [height]="459"
              [trigger]="invokeObservable()"
              (imageCapture)="captureImg($event)"
              (initError)="initError($event)"
            ></webcam>
          </div>
        </ng-container>
      </ng-container>
      <ng-template #imageContainer>
        <ngb-carousel [wrap]="false" [interval]="0" class="custom-carousel">
          <ng-template ngbSlide *ngFor="let file of files; let i = index">
            <div class="img-wrapper">
              <ng-container *ngIf="file.type === EFileType.IMAGE">
                <img [src]="file.original" class="image" />
              </ng-container>
            </div>
          </ng-template>
        </ngb-carousel>
      </ng-template>
      <ng-template #permissionDeniedTemplate>
        <div class="col-12">
          <em class="fas fa-exclamation-circle fa-lg float-left mr-2"></em>
          {{'fileUpload.cameraModal.error.cameraPermission' | translate}}
        </div>
      </ng-template>
    </div>
  </div>

  <div class="modal-footer">
    <div class="buttons-right">
      <scw-mat-button *ngIf="!isCapturingMode && !isFromGallery" type="standard" (onClick)="onChange()">
        {{ 'fileUpload.cameraModal.change' | translate }}
      </scw-mat-button>
      <scw-mat-button type="cta" (onClick)="isCapturingMode ? getSnapshot() : saveImage()" [disabled]="!isCameraAvailable && !isFromGallery">
        {{ saveImageText }}
      </scw-mat-button>
    </div>
  </div>
</ng-template>
