import { Component, OnInit } from '@angular/core';
import { HelperService } from '../../../../shared/service/helper.service';
import { TranslateService } from '@ngx-translate/core';
import { Store } from '@ngrx/store';
import { OeeAppState } from '../../../../store/oee.reducer';
import { Subscription } from 'rxjs';
import { DecimalHelper } from '../../../../shared/helper/decimal/decimal-helper';
import { CountEntriesInterface } from '../home-metrics.model';
import { User } from '../../../../store/user/model';
import { DECIMAL_DEFAULT_SCALE_LIMIT } from '../../../../../constants';
import * as _ from 'lodash';

@Component({
  selector: 'work-order-count-badges',
  templateUrl: './work-order-count-badges.component.html',
})
export class WorkOrderCountBadgesComponent implements OnInit {
  private readonly subscriptions: Subscription[] = [];
  public negativeScrapText: string = '--!';
  public workOrderCountBadgesTooltipText: string = this.translate.instant(
    'homeScreenMetricCard.tooltip.homeScreenMetricTooltipText',
  );
  public workOrderCountBadgesMetrics: CountEntriesInterface = {
    goodCount: '--',
    scrapCount: '--',
  };
  private siteDecimalScaleLimit$: number = DECIMAL_DEFAULT_SCALE_LIMIT;
  public goodCountUnit: string = null;
  public scrapCountUnit: string = null;

  constructor(
    public helperService: HelperService,
    public decimalHelper: DecimalHelper,
    private translate: TranslateService,
    private store: Store<OeeAppState>,
  ) {}

  public ngOnInit(): void {
    this.subscriptions.push(
      this.store
        .select('user')
        .pipe()
        .subscribe((state: User) => {
          this.siteDecimalScaleLimit$ = state.siteDecimalScaleLimit;
        }),
      this.store.select('line').subscribe((state) => {
        this.workOrderCountBadgesMetrics = {
          goodCount: '--',
          scrapCount: '--',
        };

        this.workOrderCountBadgesMetrics.goodCount =
          state.quantities.goodCount === null
            ? this.workOrderCountBadgesMetrics.goodCount
            : this.decimalHelper.toFixedValue(state.quantities.goodCount, this.siteDecimalScaleLimit$);

        this.workOrderCountBadgesMetrics.scrapCount =
          state.quantities.scrapCount === null
            ? this.workOrderCountBadgesMetrics.scrapCount
            : this.decimalHelper.toFixedValue(state.quantities.scrapCount, this.siteDecimalScaleLimit$);

        const unitValue: string = _.camelCase(state.unitName);
        this.goodCountUnit =
          state.quantities.goodCount === null || unitValue === ''
            ? ''
            : this.translate.instant(`homePageDisplaySettings.unit.${unitValue}.formula`);
        this.scrapCountUnit =
          state.quantities.scrapCount === null || unitValue === ''
            ? ''
            : this.translate.instant(`homePageDisplaySettings.unit.${unitValue}.formula`);

        if (state.ignoreSensor) {
          this.workOrderCountBadgesMetrics.goodCount = this.translate.instant('activityCards.sensorIgnoredLabel');
          this.workOrderCountBadgesMetrics.scrapCount = this.translate.instant('activityCards.sensorIgnoredLabel');
          this.goodCountUnit = null;
          this.scrapCountUnit = null;
        }

        this.workOrderCountBadgesTooltipText = '';

        if (this.decimalHelper.isLessThan(state.quantities.initialCount, state.quantities.goodCount)) {
          this.workOrderCountBadgesMetrics.scrapCount = this.negativeScrapText;
          this.workOrderCountBadgesTooltipText = this.translate.instant(
            'homeScreenMetricCard.tooltip.workOrderCountBadgesTooltipText',
            { count: this.decimalHelper.toFixedValue(state.quantities.scrapCount, this.siteDecimalScaleLimit$) },
          );
          this.scrapCountUnit = null;
        }
      }),
    );
  }

  public ngOnDestroy(): void {
    this.subscriptions.forEach((value: Subscription) => {
      value.unsubscribe();
    });
  }
}
