import { Injectable } from '@angular/core';
import { FilterDataObjectTypes } from '../filter.class';
import { IDropdownDepend, IDropdownStaticCondition } from './dropdown.model';
import { SqlOperators } from '../advanced-filter/advanced-filter.model';
import { FilterConditionInterface, IDependSearch } from '../../../../store/filter/filter.model';
import { generateSearchObject } from '../../../helper/app-helper';
import * as _ from 'lodash';

@Injectable({
  providedIn: 'root',
})
export class FilterCardDropdownService {
  public static filterClientSideDropdownAllData(
    data: FilterDataObjectTypes[],
    depend: IDropdownDepend,
    selectedParentIds: number[],
  ): FilterDataObjectTypes[] {
    return data.filter((item: FilterDataObjectTypes) => {
      const itemData: number | string = _.get(item, depend.property);

      if (depend.isDataLocatedAtCurrent && typeof itemData === 'string') {
        const itemIds: number[] = FilterCardDropdownService.stringIdsToArray(itemData);

        return selectedParentIds.some((id: number) => itemIds.includes(id));
      }

      return item.hasOwnProperty(depend.property) && selectedParentIds.includes(itemData as number);
    });
  }

  public static getSelectedParentIds(
    condition: { [key in SqlOperators]: number[] | string[] },
    depend: IDropdownDepend,
  ): number[] {
    let selectedParentIds: number[] = condition[SqlOperators.$in] as number[];

    if (depend.isDataLocatedAtParent && typeof _.get(selectedParentIds, '0') === 'string') {
      selectedParentIds = FilterCardDropdownService.stringIdsToArray(selectedParentIds.join());
    }

    return selectedParentIds;
  }

  public static stringIdsToArray(ids: string): number[] {
    return ids.split(',').map((id: string) => Number(id));
  }

  public static getStaticConditions(staticConditions: IDropdownStaticCondition[]): object[] {
    return (staticConditions || []).map((condition) => {
      return { [condition.property]: { [condition.conditionType]: condition.value } };
    });
  }

  public static generateConditionObject(
    search: string,
    searchProps: FilterConditionInterface[],
  ): FilterConditionInterface[] {
    return generateSearchObject(search, searchProps ?? [{ prop: 'name', condition: '$cont' }]);
  }

  public static setDependedConditions(
    data: number[],
    depend: IDropdownDepend,
    dependedConditions: IDependSearch,
    isParentItemSelected: boolean,
    isAllSelectedAndNoneSelectedDifferent: boolean,
  ): void {
    if (data.length === 0 || (!isParentItemSelected && isAllSelectedAndNoneSelectedDifferent)) {
      delete dependedConditions[depend.property];
    } else {
      Object.assign(dependedConditions, { [depend.property]: { [depend.conditionType]: data } });
    }
  }
}
