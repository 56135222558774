import { EFilterDropdownElements, IDependPropertyValue } from './filter.model';
import { Action } from '@ngrx/store';

export enum FilterActionTypes {
  SetFilter = '[Filter] Set Filter',
  ClearFilter = '[Filter] Clear Filter',
  FilterDependedDropdownData = '[Filter] Filter Depended Dropdown Data',
}

export class SetFilter implements Action {
  readonly type = FilterActionTypes.SetFilter;

  constructor(
    public elementId: EFilterDropdownElements,
    public value: IDependPropertyValue,
    public isItemSelected: boolean,
  ) {}
}

export class ClearFilter implements Action {
  readonly type = FilterActionTypes.ClearFilter;
}

export class FilterDependedDropdownData implements Action {
  readonly type = FilterActionTypes.FilterDependedDropdownData;
}

export type FilterActions = SetFilter | ClearFilter | FilterDependedDropdownData;
