<div class="row m-0 h-100 p-t-5 p-b-5 text-center detail-card" [class.detail-card-border]="hasBorder">
  <div
    *ngFor="let item of content"
    class="col my-auto"
    [ngClass]="cardColumnsCls"
    [class.detail-card-border-right]="item.hasBorderRight"
  >
    <ng-template [ngIf]="item">
      <div
        class="d-flex align-items-center justify-content-center"
        [ngClass]="item?.cls?.value"
        [class.cursor-pointer]="item.isTooltipEnabled && item.tooltipText.length !== 0"
        [style.color]="item?.color?.value"
        [ngbPopover]="
          item.value && item.isTooltipEnabled && item.tooltipText.length !== 0 ? detailCardPopoverContent : null
        "
        placement="top"
        triggers="click"
        popoverClass="detail-popover"
      >
        {{ item.value && item.value !== 'null' ? item.value : nullReplacer }}
        <ng-container *ngFor="let icon of item?.icons">
          <em *ngIf="icon?.value" [ngClass]="icon.value" class="pl-1"></em>
        </ng-container>
        <div class="hide-in-print" [hidden]="!item.isTooltipEnabled || item.tooltipText.length === 0">
          <i class="fas fa-info-circle p-l-5"></i>
          <ng-template #detailCardPopoverContent>
            <div *ngIf="item.isTooltipListStyle; else singleTooltipText">
              <ul class="labors-details-tooltip" *ngFor="let text of item.tooltipText">
                <li>{{ text }}</li>
              </ul>
            </div>
            <ng-template #singleTooltipText>
              {{ item.tooltipText[0] }}
            </ng-template>
          </ng-template>
        </div>
      </div>
      <div class="f-12 detail-card-label" [ngClass]="item?.cls?.label" [style.color]="item?.color?.label">
        {{ item.label }}
        <ng-container *ngFor="let icon of item?.icons">
          <em *ngIf="icon?.label" [ngClass]="icon.label" class="pl-1"></em>
        </ng-container>
      </div>
      <ng-template [ngIf]="item.bottomBracketsLabel">
        <div class="f-12 detail-card-bottom-brackets-label">({{ item.bottomBracketsLabel }})</div>
      </ng-template>
      <ng-template [ngIf]="item.bottomLabel">
        <div
          class="detail-card-bottom-label"
          [ngClass]="item?.cls?.bottomLabel"
          [style.color]="item?.color?.bottomLabel"
        >
          {{ item.bottomLabel }}
        </div>
      </ng-template>
    </ng-template>
  </div>
</div>
<mat-progress-bar
  mode="indeterminate"
  [color]="'primary'"
  [hidden]="!isLoading"
  [ngStyle]="{ height: progressBarHeight + 'px' }"
></mat-progress-bar>
