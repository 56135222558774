import { Inject, Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable, Subject } from 'rxjs';
import { GetManyResponseInterface } from '../../../shared/model/interface/crud-response-interface.model';
import {
  IFormattedExcelHeader,
  IFormattedExcelRow,
  SensorListInterface,
  SensorReportsDataInterface,
} from './sensor-reports.model';
import {
  CellTypes,
  CreateExcelSheetInterface,
  ExcelColumnDefinitionInterface,
  ExcelDateFormatInformationInterface,
  ExcelHelperService,
  ExcelSheetTypeEnum,
} from '../../../shared/service/excel/excel-helper.service';
import { TranslateService } from '@ngx-translate/core';
import * as moment from 'moment-timezone';
import { Store } from '@ngrx/store';
import { OeeAppState } from '../../oee.reducer';
import { takeUntil } from 'rxjs/operators';
import * as AppActions from '../../app/actions';
import * as SensorReportActions from './sensor-reports.actions';
import { ValueType } from 'exceljs';
import { excelDateFormat, excelTimeFormat } from '../../../shared/model/enum/excel-date-format';

@Injectable({
  providedIn: 'root',
})
export class SensorReportsService {
  constructor(
    public http: HttpClient,
    @Inject('API_BASE_URL') private readonly baseUrl: string,
    private readonly translate: TranslateService,
    private readonly store: Store<OeeAppState>,
    private readonly excelHelper: ExcelHelperService,
  ) {
    let timezone: string = 'utc';
    let dateFormat$: string;
    let timeFormat$: string;
    let locale$: string;
    let dateFormatRaw$: string;
    let dateTimeFormatRaw$: string;

    this.store
      .select('user')
      .pipe(takeUntil(this.destroySubject))
      .subscribe((state) => {
        if (state.isUserLoaded) {
          timezone = state.timezone;

          if (state.locale !== '') {
            dateFormat$ = excelDateFormat[state.locale];
            timeFormat$ = excelTimeFormat[state.locale];
          }

          dateFormatRaw$ = state.dateFormat;
          dateTimeFormatRaw$ = state.dateTimeFormatWithSecond;
          locale$ = state.locale;

          this.dateFormatInformation = {
            timezone,
            dateFormat$,
            timeFormat$,
            locale$,
            dateFormatRaw$,
            dateTimeFormatRaw$,
          };

          this.destroySubject.next(true);
          this.destroySubject.complete();
        }
      });
  }

  private readonly routes = {
    sensorList: '/sensor-list',
    sensorReports: '/sensor-list/sensor-reports',
  };
  private readonly destroySubject: Subject<boolean> = new Subject<boolean>();
  private dateFormatInformation: ExcelDateFormatInformationInterface;

  public getSensorList(params: HttpParams): Observable<GetManyResponseInterface<SensorListInterface>> {
    return this.http.get<GetManyResponseInterface<SensorListInterface>>(`${this.baseUrl}${this.routes.sensorList}`, {
      params,
    });
  }

  public getSensorReportsData(params: HttpParams): Observable<GetManyResponseInterface<SensorReportsDataInterface>> {
    return this.http.get<GetManyResponseInterface<SensorReportsDataInterface>>(
      `${this.baseUrl}${this.routes.sensorReports}`,
      {
        params,
      },
    );
  }

  public downloadExcel(siteId: number, headers: IFormattedExcelHeader[], data: IFormattedExcelRow[]): void {
    const sheetTitle: string | any = this.translate.instant('pageTitles.sensor_reports');
    const excelName: string = `${sheetTitle} ${moment()
      .tz(this.dateFormatInformation.timezone)
      .format(this.dateFormatInformation.dateFormat$)}`;
    const worksheetsColumnDefinitions: ExcelColumnDefinitionInterface[] = [];

    headers.forEach((item: IFormattedExcelHeader): void => {
      const excelColumn: ExcelColumnDefinitionInterface = {
        header: item.header,
        key: item.key,
        width: 40,
        type: ValueType.String,
        style: { numFmt: '@' },
        dataValidation: {
          type: CellTypes.CUSTOM,
        },
      };

      worksheetsColumnDefinitions.push(excelColumn);
    });

    const worksheets: CreateExcelSheetInterface[] = [
      {
        sheetTitle: sheetTitle,
        sheetType: ExcelSheetTypeEnum.TABLE,
        params: {
          data,
          columns: worksheetsColumnDefinitions,
        },
        withData: true,
        isDisabledColumnsFirstLine: true,
        addDateTimeFormula: undefined,
        excelRowFormatLimit: data.length + 1,
      },
    ];

    this.excelHelper
      .createExcel(
        excelName,
        { siteId, name: 'sensorReport', withData: true },
        worksheets,
        this.dateFormatInformation.timezone,
        this.dateFormatInformation.dateFormat$,
        this.dateFormatInformation.timeFormat$,
        false,
      )
      .then(
        () => {
          this.store.dispatch(new SensorReportActions.SensorReportDownloadExcelLoaded());
          this.store.dispatch(new AppActions.HideLoader());
        },
        () => {
          this.store.dispatch(new SensorReportActions.FetchError({}));
          this.store.dispatch(new AppActions.HideLoader());
        },
      );
  }
}
