import { Action } from '@ngrx/store';
import { ActivitiesInterface } from '../../shared/model/interface/activities.model';
import { GetManyResponseInterface } from '../../shared/model/interface/crud-response-interface.model';

export enum ActivityButtonActionTypes {
  StartActivityButtonDataLoading = '[ACTIVITY_BUTTON] START ACTIVITY BUTTON DATA LOADING',
  StartActivityButtonDataLoaded = '[ACTIVITY_BUTTON] START ACTIVITY BUTTON DATA LOADED',
  FetchError = '[ACTIVITY_BUTTON] Fetch Error',
  ResetStore = '[ACTIVITY_BUTTON] Reset Store',
}

export class StartActivityButtonDataLoading implements Action {
  readonly type = ActivityButtonActionTypes.StartActivityButtonDataLoading;

  constructor(public lineId: number) {}
}

export class StartActivityButtonDataLoaded implements Action {
  readonly type = ActivityButtonActionTypes.StartActivityButtonDataLoaded;

  constructor(public payload: GetManyResponseInterface<ActivitiesInterface>) {}
}

export class FetchError implements Action {
  readonly type = ActivityButtonActionTypes.FetchError;

  constructor(public payload: any) {}
}

export class ResetStore implements Action {
  readonly type = ActivityButtonActionTypes.ResetStore;
}

export type ActivityButtonActions =
  | StartActivityButtonDataLoading
  | StartActivityButtonDataLoaded
  | FetchError
  | ResetStore;
