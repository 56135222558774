export type TBoardResponse = {
  id: number;
  key: string;
  name: string;
  parentId: number | null;
};

export type TSelectedBoard = {
  id: number;
  name: string;
};

export type TSelectedBoardMember = {
  id: number;
  name: string;
};

export type TRecommendedBoardResponse = {
  boardId: number;
  boardName: string;
};

export type TBoardMemberResponse = {
  id: number;
  name: string;
};

export enum EItemType {
  action = 'action',
  issue = 'issue',
}

export type TCreateIssueItemRequestPayload = {
  name: string;
  description: string;
  boardId: number;
  itemType: EItemType;
  isQuick: boolean;
  assigneeId?: number;
  dueDate?: string;
};
