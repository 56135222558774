import { Action } from '@ngrx/store';
import { IWorkOrderManualCountReviewTableQuery } from '../../../view/reports/work-order-count-review/work-order-count-review.model';
import { WorkOrderCountReviewChartDataModel } from './work-order-count-review.model';

export const WORK_ORDER_COUNT_REVIEW_FETCH_DATA_LOADING =
  '[MANUAL_COUNT_REVIEW] WORK ORDER COUNT REVIEW FETCH DATA LOADING';
export const WORK_ORDER_COUNT_REVIEW_FETCH_DATA_LOADED =
  '[MANUAL_COUNT_REVIEW] WORK ORDER COUNT REVIEW FETCH DATA LOADED';
export const WORK_ORDER_COUNT_REVIEW_FETCH_ERROR = '[MANUAL_COUNT_REVIEW] WORK ORDER COUNT REVIEW FETCH ERROR';
export const WORK_ORDER_COUNT_REVIEW_CLEAR_STATE = '[MANUAL_COUNT_REVIEW] WORK ORDER COUNT REVIEW CLEAR STATE';

export class WorkOrderCountReviewFetchDataLoading implements Action {
  readonly type = WORK_ORDER_COUNT_REVIEW_FETCH_DATA_LOADING;

  constructor(public tableQuery: IWorkOrderManualCountReviewTableQuery) {}
}

export class WorkOrderCountReviewFetchDataLoaded implements Action {
  readonly type = WORK_ORDER_COUNT_REVIEW_FETCH_DATA_LOADED;

  constructor(public payload: WorkOrderCountReviewChartDataModel) {}
}

export class WorkOrderCountReviewFetchError implements Action {
  readonly type = WORK_ORDER_COUNT_REVIEW_FETCH_ERROR;

  constructor(public payload: object) {}
}

export class WorkOrderCountReviewClearState implements Action {
  readonly type = WORK_ORDER_COUNT_REVIEW_CLEAR_STATE;
}

export type ManualCountReviewActions =
  | WorkOrderCountReviewClearState
  | WorkOrderCountReviewFetchDataLoading
  | WorkOrderCountReviewFetchDataLoaded
  | WorkOrderCountReviewFetchError;
