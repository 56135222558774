import { Action } from '@ngrx/store';
import { ILineViewData } from './line-view.model';

export const LINE_VIEW_SELECT_SITE_AND_LINE = '[LINE VIEW] SELECT SITE AND LINE';
export const LINE_VIEW_INITIAL_FILTER_DATA_LOADED = '[LINE VIEW] INITIAL FILTER DATA LOADED';
export const LINE_VIEW_STATION_DATA_LOADING = '[LINE VIEW] STATION DATA LOADING';
export const LINE_VIEW_STATION_DATA_LOADED = '[LINE VIEW] STATION DATA LOADED';
export const LINE_VIEW_RESET_STORE_TO_DEFAULT = '[LINE VIEW] RESET STORE TO DEFAULT';
export const LINE_VIEW_FETCH_DATA_ERROR = '[LINE VIEW] FETCH DATA ERROR';

export class SelectSiteAndLine implements Action {
  readonly type = LINE_VIEW_SELECT_SITE_AND_LINE;

  constructor(public siteId: number, public lineId: number) {}
}

export class InitialFilterDataLoaded implements Action {
  readonly type = LINE_VIEW_INITIAL_FILTER_DATA_LOADED;
}

export class StationDataLoading implements Action {
  readonly type = LINE_VIEW_STATION_DATA_LOADING;

  constructor(public siteId: number, public lineId: number) {}
}

export class StationDataLoaded implements Action {
  readonly type = LINE_VIEW_STATION_DATA_LOADED;

  constructor(public payload: ILineViewData) {}
}

export class ResetStoreToDefault implements Action {
  readonly type = LINE_VIEW_RESET_STORE_TO_DEFAULT;
}

export class FetchDataError implements Action {
  readonly type = LINE_VIEW_FETCH_DATA_ERROR;

  constructor(public payload: object) {}
}

export type LineViewActions =
  | SelectSiteAndLine
  | InitialFilterDataLoaded
  | StationDataLoading
  | StationDataLoaded
  | ResetStoreToDefault
  | FetchDataError;
