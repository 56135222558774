import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import {
  ECheckInSourceType,
  ILaborAssetViewCheckIn,
  ILaborAssetViewData,
  ILaborAssetViewState,
} from '../../../../store/dashboards/labor-asset-view/labor-asset-view.model';
import { ActionsSubject, Store } from '@ngrx/store';
import { OeeAppState } from '../../../../store/oee.reducer';
import { Subscription } from 'rxjs';
import {
  DatatableHeaderInterface,
  DatatableOutputParameterInterface,
  ICustomSort,
} from '../../../../shared/component/datatable/datatable.model';
import { BorderColors } from '../../../../shared/component/scw-mat-ui/scw-mat-border-coloring/scw-mat-border-coloring.model';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { DatatableInterface } from '../../../../shared/service/datatable/datatable.model';
import { ILaborAssetViewTableData } from '../labor-asset-view.model';
import { DateHelperService } from '../../../../shared/service/date/date.helper.service';
import { HelperService } from '../../../../shared/service/helper.service';
import * as moment from 'moment';
import { User, UserLevelInterface } from '../../../../store/user/model';
import { UserSettingsService } from '../../../../store/settings/users/user.service';
import * as _ from 'lodash';
import { getStatusColor, getStatusName } from '../../../../shared/component/cico/cico.utilities';
import { ECicoAvailability, ECicoType } from '../../../../shared/component/cico/cico.model';
import { take } from 'rxjs/operators';
import { ofType } from '@ngrx/effects';
import * as LaborAssetHomeActions from '../../../../store/home/labor-asset-home/labor-asset-home.actions';

@Component({
  selector: 'scw-labor-asset-view-list-view',
  templateUrl: './labor-asset-view-list-view.component.html',
  styleUrls: ['./labor-asset-view-list-view.component.scss'],
})
export class LaborAssetViewListViewComponent implements OnInit, OnChanges, OnDestroy {
  @Input() laborAssetViewData: ILaborAssetViewData[] = [];
  @Input() laborAssetViewTableHeaders: DatatableHeaderInterface[] = [];
  @Input() isLaborAssetViewTableCheckboxVisible: boolean = true;
  @Input() selectedEquipmentCheckInId: number | null = null;
  @Output() onChangeTableQuery: EventEmitter<DatatableInterface> = new EventEmitter<DatatableInterface>();
  @Output() onChangeSelectedItems: EventEmitter<number[]> = new EventEmitter<number[]>();
  @Output() onEquipmentSelection: EventEmitter<[number, number]> = new EventEmitter<[number, number]>();

  private readonly subscriptions: Subscription[] = [];
  private selectedItemIds: number[] = [];
  private userDateTimeFormat$!: string;
  public readonly checkboxIdPrefix: string = 'labor-asset-view-checkbox-';
  public readonly borderColors: typeof BorderColors = BorderColors;
  public readonly getStatusColor = getStatusColor;
  public laborAssetViewTableData: ILaborAssetViewTableData[] = [];
  public tableQuery: DatatableInterface = {
    page: 1,
    pageSize: 10,
  };
  public isTableDataLoading$: boolean = false;
  public customSorts!: ICustomSort;

  constructor(
    private readonly store: Store<OeeAppState>,
    private readonly storeActions: ActionsSubject,
    private readonly helperService: HelperService,
    private readonly dateHelperService: DateHelperService,
    private readonly userSettingsService: UserSettingsService,
    private readonly checkBoxElementRef: ElementRef,
  ) {}

  public ngOnInit(): void {
    this.store
      .select('user')
      .pipe(take(1))
      .subscribe((state: User): void => {
        this.userDateTimeFormat$ = state.dateTimeFormat;

        this.setCustomSorts();
      });

    this.onChangeTableQuery.emit(this.tableQuery);

    this.subscriptions.push(
      this.store.select('laborAssetViewStore').subscribe((state: ILaborAssetViewState): void => {
        this.isTableDataLoading$ = state.laborAssetViewDataLoading;
      }),
    );

    if (this.isLaborAssetViewTableCheckboxVisible) {
      this.subscriptions.push(
        this.storeActions
          .pipe(ofType(LaborAssetHomeActions.ActionTypes.GET_LABOR_ASSET_HOME_DATA_COMPLETED))
          .subscribe((): void => {
            this.selectedItemIds = [];
            this.selectedEquipmentCheckInId = null;

            this.onEquipmentSelection.emit([null, null]);
          }),
      );
    }
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes.hasOwnProperty('laborAssetViewData') && Array.isArray(this.laborAssetViewData)) {
      this.prepareTableData();
    }
  }

  private prepareTableData(): void {
    const levels: UserLevelInterface[] = this.userSettingsService.getLevels();

    this.laborAssetViewTableData = this.laborAssetViewData
      .map((data: ILaborAssetViewData): ILaborAssetViewTableData => {
        return {
          ...data,
          id: (_.find(data.checkIns, { checkOutTime: null }) ?? _.last(data.checkIns)).checkInId,
          type: data.sourceTypeId === ECheckInSourceType.USER ? ECicoType.LABOR : ECicoType.ASSET,
          checkInTime: this.helperService.setUserDateTimeFormat(data.minCheckInTime, true),
          checkOutTime: (data.isOffline && this.helperService.setUserDateTimeFormat(data.maxCheckOutTime, true)) || '-',
          scheduledCheckInTime: this.helperService.setUserDateTimeFormat(data.scheduledCheckInTime, true),
          scheduledCheckOutTime: this.helperService.setUserDateTimeFormat(data.scheduledCheckOutTime, true),
          duration: this.dateHelperService.formatDuration(_.sumBy(data.checkIns, 'duration')),
          status: data.isOffline
            ? ECicoAvailability.offline
            : data.isAvailable
            ? ECicoAvailability.available
            : ECicoAvailability.busy,
          level: _.get(_.find(levels, { id: data.levelId }), 'name', ''),
        };
      })
      .map((data: ILaborAssetViewTableData) => {
        return {
          ...data,
          statusName: getStatusName(data.type, data.status, data.tagName) || 'N/A',
          disabled: !_.isNil(this.selectedEquipmentCheckInId) && this.selectedEquipmentCheckInId !== data.id,
        };
      });
  }

  public onDataRequestHandler(params: DatatableOutputParameterInterface): void {
    this.tableQuery.page = params.page;
    this.tableQuery.pageSize = params.rowsPerPage;
    this.selectedItemIds = [];
    this.selectedEquipmentCheckInId = null;

    this.onChangeTableQuery.emit(this.tableQuery);
    this.onChangeSelectedItems.emit(this.selectedItemIds);
    this.onEquipmentSelection.emit([null, null]);
  }

  public onClickSortHandler(): void {
    this.selectedItemIds = [];
    this.selectedEquipmentCheckInId = null;

    this.onChangeSelectedItems.emit(this.selectedItemIds);
    this.onEquipmentSelection.emit([null, null]);
  }

  public onChangeTableCheckboxes(event: MatCheckboxChange, selectedItem: ILaborAssetViewTableData): void {
    const inputValue: number = selectedItem.id;
    const existingIndex: number = this.selectedItemIds.indexOf(inputValue);

    if (event.checked && existingIndex === -1) {
      this.selectedItemIds.push(inputValue);
    } else {
      this.selectedItemIds.splice(existingIndex, 1);
    }

    if (this.checkBoxElementRef.nativeElement.querySelector(`[id^=${this.checkboxIdPrefix}]`) !== null) {
      this.laborAssetViewTableData.forEach((checkInItem: ILaborAssetViewTableData): void => {
        if (checkInItem.id !== inputValue) {
          const checkbox: HTMLElement = document.getElementById(`${this.checkboxIdPrefix}${checkInItem.id}`);
          const userSelectionExists: boolean = !!this.laborAssetViewTableData.find(
            (row: ILaborAssetViewTableData) =>
              this.selectedItemIds.includes(row.id) && row.sourceTypeId === ECheckInSourceType.USER,
          );

          checkInItem.disabled =
            !_.isNil(checkbox) &&
            ((userSelectionExists && checkInItem.sourceTypeId === ECheckInSourceType.EQUIPMENT) ||
              (event.checked && selectedItem.sourceTypeId === ECheckInSourceType.EQUIPMENT));
        }
      });
    }

    if (selectedItem.sourceTypeId === ECheckInSourceType.EQUIPMENT) {
      this.selectedEquipmentCheckInId = event.checked ? selectedItem.id : null;

      const selectedIdsToEmit: [number, number] = event.checked
        ? [selectedItem.sourceObjectId, this.selectedEquipmentCheckInId]
        : [null, null];

      this.onEquipmentSelection.emit(selectedIdsToEmit);
      return;
    }

    this.onChangeSelectedItems.emit(this.selectedItemIds);
  }

  private static customSortNullsFirst(value: string | number | undefined, dateFormat: string | undefined): Date {
    const convertedDate: moment.Moment = moment(value, dateFormat);

    if (convertedDate.isValid()) {
      return convertedDate.toDate();
    }

    return new Date();
  }

  private static customSortNullsLast(value: string | number | undefined, dateFormat: string | undefined): Date {
    const convertedDate: moment.Moment = moment(value, dateFormat);

    if (convertedDate.isValid()) {
      return convertedDate.toDate();
    }

    return new Date(-1);
  }

  private setCustomSorts(): void {
    this.customSorts = {
      duration: { type: 'duration', sortColumnId: 'duration' },
      checkInTime: {
        type: 'custom',
        customSortFunction: LaborAssetViewListViewComponent.customSortNullsFirst,
        dateFormat: this.userDateTimeFormat$,
      },
      checkOutTime: {
        type: 'custom',
        customSortFunction: LaborAssetViewListViewComponent.customSortNullsFirst,
        dateFormat: this.userDateTimeFormat$,
      },
      scheduledCheckInTime: {
        type: 'custom',
        customSortFunction: LaborAssetViewListViewComponent.customSortNullsLast,
        dateFormat: this.userDateTimeFormat$,
      },
      scheduledCheckOutTime: {
        type: 'custom',
        customSortFunction: LaborAssetViewListViewComponent.customSortNullsLast,
        dateFormat: this.userDateTimeFormat$,
      },
    };
  }

  public ngOnDestroy(): void {
    this.subscriptions.forEach((subscription: Subscription): void => {
      subscription.unsubscribe();
    });
  }
}
