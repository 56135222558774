import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, switchMap } from 'rxjs/operators';
import * as ObjectActions from './product-transition-matrix.actions';
import { of } from 'rxjs';
import * as AppActions from '../../app/actions';
import { HttpParams } from '@angular/common/http';
import { Store } from '@ngrx/store';
import * as oeeAppReducer from '../../oee.reducer';
import { ProductTransitionMatrixService } from './product-transition-matrix.service';
import {
  BaseOneResponseInterface,
  BulkResponseDataInterface,
  GetManyResponseInterface,
} from '../../../shared/model/interface/crud-response-interface.model';
import {
  IAddEditProductTransitionMatrix,
  IProductTransitionMatrixBulkResponse,
  IProductTransitionMatrixResponse,
} from './product-transition-matrix.model';
import { ExcelHelperService } from '../../../shared/service/excel/excel-helper.service';
import { ProductFamilyService } from '../product-family/product-family.service';
import { IProductFamily } from '../product-family/product-family.model';
import { ProductInterface } from '../products/products.model';
import { ProductsService } from '../products/products.service';
import { ErrorMessageService } from '../../../shared/service/error-message.service';
import { emptyAction } from '../../../../constants';
import { ProductTransitionMatrixExcelService } from './product-transition-matrix-excel.service';
import { EntityTranslatorService } from '../../../shared/service/entity-translator/entity-translator.service';
import { IProductTransitionPreviewResponse } from '../../../view/settings/product-transition-matrix/product-transition-visual-matrix/product-transition-visual-matrix.model';

@Injectable()
export class ProductTransitionMatrixEffects {
  constructor(
    private readonly actions$: Actions,
    private readonly service: ProductTransitionMatrixService,
    private readonly excelService: ProductTransitionMatrixExcelService,
    private readonly excelHelperService: ExcelHelperService,
    private readonly store: Store<oeeAppReducer.OeeAppState>,
    private readonly productService: ProductsService,
    private readonly productFamilyService: ProductFamilyService,
    private readonly errorMessageService: ErrorMessageService,
    private readonly translatorService: EntityTranslatorService,
  ) {}

  getProductTransitionMatrixData = createEffect(() =>
    this.actions$.pipe(
      ofType(ObjectActions.PRODUCT_TRANSITION_MATRIX_DATA_LOADING),
      switchMap((payload: ObjectActions.ProductTransitionMatrixDataLoading) => {
        this.store.dispatch(new AppActions.ShowLoader());
        return this.service.getProductTransitionMatrix(payload.params).pipe(
          switchMap((response: BaseOneResponseInterface<IProductTransitionMatrixResponse>) => {
            return of(new ObjectActions.ProductTransitionMatrixDataLoaded(response), new AppActions.HideLoader());
          }),
          catchError((errorRes) => {
            return of(new ObjectActions.FetchError(errorRes), new AppActions.HideLoader());
          }),
        );
      }),
      catchError((errorRes) => {
        return of(new ObjectActions.FetchError(errorRes), new AppActions.HideLoader());
      }),
    ),
  );
  addProductTransitionMatrix = createEffect(() =>
    this.actions$.pipe(
      ofType(ObjectActions.ADD_PRODUCT_TRANSITION_MATRIX),
      switchMap((objectData: ObjectActions.AddProductTransitionMatrix) => {
        this.store.dispatch(new AppActions.ShowLoader());

        return this.service.addProductTransitionMatrix(objectData.productTransitionMatrix).pipe(
          switchMap((response: BaseOneResponseInterface<IAddEditProductTransitionMatrix>) => {
            return of(new ObjectActions.AddProductTransitionMatrixCompleted(response), new AppActions.HideLoader());
          }),
          catchError((error) => {
            return of(new ObjectActions.FetchError(error), new AppActions.HideLoader());
          }),
        );
      }),
      catchError((errorRes) => {
        return of(new ObjectActions.FetchError(errorRes), new AppActions.HideLoader());
      }),
    ),
  );

  getProductTransitionMatrixProductsData = createEffect(() =>
    this.actions$.pipe(
      ofType(ObjectActions.PRODUCT_TRANSITION_MATRIX_PRODUCTS_DATA_LOADING),
      switchMap((objectData: ObjectActions.ProductTransitionMatrixProductDataLoading) => {
        this.store.dispatch(new AppActions.ShowLoader());
        const httpParams: HttpParams = new HttpParams()
          .append('join', 'productFamily')
          .set('limit', '1000')
          .set('s', JSON.stringify({ siteId: { $eq: objectData.siteId } }));

        return this.productService.getProducts(httpParams).pipe(
          switchMap((response: GetManyResponseInterface<ProductInterface>) => {
            response.data.forEach((product: ProductInterface) => this.translatorService.translate(product));
            return of(
              new ObjectActions.ProductTransitionMatrixProductDataLoaded(response),
              new AppActions.HideLoader(),
            );
          }),
          catchError((errorRes) => {
            return of(new ObjectActions.FetchError(errorRes), new AppActions.HideLoader());
          }),
        );
      }),
      catchError((errorRes) => {
        return of(new ObjectActions.FetchError(errorRes), new AppActions.HideLoader());
      }),
    ),
  );

  getProductTransitionMatrixProductFamilyData = createEffect(() =>
    this.actions$.pipe(
      ofType(ObjectActions.PRODUCT_TRANSITION_MATRIX_PRODUCT_FAMILY_DATA_LOADING),
      switchMap((objectData: ObjectActions.ProductTransitionMatrixProductFamilyDataLoading) => {
        this.store.dispatch(new AppActions.ShowLoader());
        const httpParams: HttpParams = new HttpParams()
          .set('limit', '1000')
          .set('s', JSON.stringify({ siteId: { $eq: objectData.siteId } }));

        return this.productFamilyService.getProductFamily(httpParams).pipe(
          switchMap((response: GetManyResponseInterface<IProductFamily>) => {
            return of(
              new ObjectActions.ProductTransitionMatrixProductFamilyDataLoaded(response),
              new AppActions.HideLoader(),
            );
          }),
          catchError((errorRes) => {
            return of(new ObjectActions.FetchError(errorRes), new AppActions.HideLoader());
          }),
        );
      }),
      catchError((errorRes) => {
        return of(new ObjectActions.FetchError(errorRes), new AppActions.HideLoader());
      }),
    ),
  );

  editProductTransitionMatrix = createEffect(() =>
    this.actions$.pipe(
      ofType(ObjectActions.EDIT_PRODUCT_TRANSITION_MATRIX),
      switchMap((objectData: ObjectActions.EditProductTransitionMatrix) => {
        this.store.dispatch(new AppActions.ShowLoader());

        return this.service.editProductTransitionMatrix(objectData.productTransitionMatrix, objectData.id).pipe(
          switchMap((response: BaseOneResponseInterface<IAddEditProductTransitionMatrix>) => {
            return of(new ObjectActions.EditProductTransitionMatrixCompleted(response), new AppActions.HideLoader());
          }),
          catchError((error) => {
            return of(new ObjectActions.FetchError(error), new AppActions.HideLoader());
          }),
        );
      }),
      catchError((error) => {
        return of(new ObjectActions.FetchError(error), new AppActions.HideLoader());
      }),
    ),
  );

  deleteProductTransitionMatrix = createEffect(() =>
    this.actions$.pipe(
      ofType(ObjectActions.DELETE_PRODUCT_TRANSITION_MATRIX),
      switchMap((objectData: ObjectActions.DeleteProductTransitionMatrix) => {
        this.store.dispatch(new AppActions.ShowLoader());
        return this.service.deleteProductFamily(objectData.payload).pipe(
          switchMap((response: BulkResponseDataInterface) => {
            this.errorMessageService.getTranslatedErrorMessage(response.data);
            return of(new ObjectActions.DeleteProductTransitionMatrixCompleted(response), new AppActions.HideLoader());
          }),
          catchError((error) => {
            return of(new ObjectActions.FetchError(error), new AppActions.HideLoader());
          }),
        );
      }),
      catchError((error) => {
        return of(new ObjectActions.FetchError(error), new AppActions.HideLoader());
      }),
    ),
  );

  deleteTransitionMatrices = createEffect(() =>
    this.actions$.pipe(
      ofType(ObjectActions.BULK_DELETE_PRODUCT_TRANSITION_MATRIX),
      switchMap((objectData: ObjectActions.BulkDeleteProductTransitionMatrix) => {
        this.store.dispatch(new AppActions.ShowLoader());

        return this.service.bulkDeleteProductTransitionMatrices(objectData.productTransitionMatrixIds).pipe(
          switchMap((response: BulkResponseDataInterface) => {
            return of(
              new ObjectActions.BulkDeleteProductTransitionMatrixCompleted(response),
              new AppActions.HideLoader(),
            );
          }),
          catchError((error) => {
            return of(new ObjectActions.FetchError(error), new AppActions.HideLoader());
          }),
        );
      }),
      catchError((error) => {
        return of(new ObjectActions.FetchError(error), new AppActions.HideLoader());
      }),
    ),
  );

  bulkEditProductTransitionMatrix = createEffect(() =>
    this.actions$.pipe(
      ofType(ObjectActions.BULK_EDIT_PRODUCT_TRANSITION_MATRIX),
      switchMap((objectData: ObjectActions.BulkEditProductTransitionMatrix) => {
        this.store.dispatch(new AppActions.ShowLoader());

        return this.service.bulkEditProductTransitionMatrix(objectData.productTransitionMatrix).pipe(
          switchMap((response: BaseOneResponseInterface<IProductTransitionMatrixBulkResponse[]>) => {
            return of(
              new ObjectActions.BulkEditProductTransitionMatrixCompleted(response),
              new AppActions.HideLoader(),
            );
          }),
          catchError((error) => {
            return of(new ObjectActions.FetchError(error), new AppActions.HideLoader());
          }),
        );
      }),
      catchError((error) => {
        return of(new ObjectActions.FetchError(error), new AppActions.HideLoader());
      }),
    ),
  );

  downloadProductTransitionMatrixExcel = createEffect(() =>
    this.actions$.pipe(
      ofType(ObjectActions.DOWNLOAD_PRODUCT_TRANSITION_MATRIX_EXCEL),
      switchMap((objectData: ObjectActions.DownloadProductTransitionMatrixExcel) => {
        this.store.dispatch(new AppActions.ShowLoader());
        this.excelService.downloadExcel(objectData.withData, objectData.filters);
        return emptyAction;
      }),
      catchError((error) => {
        return of(new ObjectActions.FetchError(error), new AppActions.HideLoader());
      }),
    ),
  );

  getProductTransitionMatrixDataForCount = createEffect(() =>
    this.actions$.pipe(
      ofType(ObjectActions.PRODUCT_TRANSITION_MATRIX_COUNT_LOAD),
      switchMap((payload: ObjectActions.ProductTransitionMatrixCountLoad) => {
        return this.service.getProductTransitionMatrix(payload.params).pipe(
          switchMap((response: BaseOneResponseInterface<IProductTransitionMatrixResponse>) => {
            return of(new ObjectActions.ProductTransitionMatrixCountLoaded(response));
          }),
          catchError((errorRes) => {
            return of(new ObjectActions.FetchError(errorRes), new AppActions.HideLoader());
          }),
        );
      }),
      catchError((errorRes) => {
        return of(new ObjectActions.FetchError(errorRes), new AppActions.HideLoader());
      }),
    ),
  );

  uploadLineExcel = createEffect(() =>
    this.actions$.pipe(
      ofType(ObjectActions.UPLOAD_PRODUCT_TRANSITION_MATRIX_EXCEL),
      switchMap((objectData: ObjectActions.UploadProductTransitionMatrixExcel) => {
        this.store.dispatch(new AppActions.ShowLoader());

        return this.excelService.uploadExcel(objectData.productTransitionMatrix).pipe(
          switchMap((response: BulkResponseDataInterface) => {
            this.errorMessageService.getTranslatedErrorMessage(response.data);
            const mergedArray = this.excelHelperService.mergeBulkResponseWithRequestData<any>(
              response,
              objectData.productTransitionMatrix,
            );
            return of(
              new ObjectActions.UploadProductTransitionMatrixExcelCompleted(mergedArray),
              new AppActions.HideLoader(),
            );
          }),
          catchError((error) => {
            return of(new ObjectActions.FetchError(error), new AppActions.HideLoader());
          }),
        );
      }),
      catchError((error) => {
        return of(new ObjectActions.FetchError(error), new AppActions.HideLoader());
      }),
    ),
  );

  getProductTransitionMatrixPreviewData = createEffect(() =>
    this.actions$.pipe(
      ofType(ObjectActions.PRODUCT_TRANSITION_MATRIX_PREVIEW_DATA_LOADING),
      switchMap((ObjectData: ObjectActions.ProductTransitionMatrixPreviewDataLoading) => {
        this.store.dispatch(new AppActions.ShowLoader());
        const params: HttpParams = this.service.preparePreviewDataParams(ObjectData.payload);
        return this.service.getPreviewProductTransitionMatrix(params).pipe(
          switchMap((response: BaseOneResponseInterface<IProductTransitionPreviewResponse[]>) => {
            return of(
              new ObjectActions.ProductTransitionMatrixPreviewDataLoaded(response.data),
              new AppActions.HideLoader(),
            );
          }),
          catchError((errorRes) => {
            return of(new ObjectActions.FetchError(errorRes), new AppActions.HideLoader());
          }),
        );
      }),
      catchError((errorRes) => {
        return of(new ObjectActions.FetchError(errorRes), new AppActions.HideLoader());
      }),
    ),
  );
}
