<scw-mat-button
  [className]="{ 'max-width-button': maxWidth !== undefined }"
  [iconButton]="iconButton && text === undefined"
  (onClick)="openModal()"
  [size]="size"
  [disabled]="disabled"
  type="standard"
>
  <span class="fas fa-comment align-baseline"></span>
  <span *ngIf="text !== undefined" class="ml-2">{{ text }}</span>
</scw-mat-button>
