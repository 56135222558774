import { Component, Inject } from '@angular/core';
import { Store } from '@ngrx/store';
import { OeeAppState } from '../../../../store/oee.reducer';
import { combineLatest, Observable, startWith } from 'rxjs';
import { DECIMAL_DEFAULT_SCALE_LIMIT } from '../../../../../constants';
import { DecimalHelper } from '../../../../shared/helper/decimal/decimal-helper';
import { HelperService } from '../../../../shared/service/helper.service';
import * as _ from 'lodash';
import { PROPERTIES } from '../home-metrics.component';
import {
  EOeeMetric,
  IOEEMetricMultiselectMeta,
} from '../../../settings/home-page-display-settings/home-metrics/home-metrics.model';
import { filter, map, withLatestFrom } from 'rxjs/operators';
import { IOeeMetricApq } from '../../../../store/home/home.model';

@Component({
  selector: 'app-work-order-oee-metric',
  templateUrl: './work-order-oee-metric.component.html',
})
export class WorkOrderOeeMetricComponent {
  private readonly siteDecimalScaleLimit$ = this.store
    .select('user', 'siteDecimalScaleLimit')
    .pipe(startWith(DECIMAL_DEFAULT_SCALE_LIMIT));

  public progressBarRateCapped: number = 0;
  public readonly oeeValues$: Observable<IOeeMetricApq> = this.store
    .select('line', 'quantities', 'oeeValueFullScope')
    .pipe(
      map((oeeValue: IOeeMetricApq | null) => {
        return (
          oeeValue && {
            [EOeeMetric.OEE]: this.formatApq(oeeValue, EOeeMetric.OEE),
            [EOeeMetric.A]: this.formatApq(oeeValue, EOeeMetric.A),
            [EOeeMetric.P]: this.formatApq(oeeValue, EOeeMetric.P),
            [EOeeMetric.Q]: this.formatApq(oeeValue, EOeeMetric.Q),
          }
        );
      }),
    );
  public readonly workOrderOeeTarget$: Observable<string> = this.store.select('kpiTargetsStore').pipe(
    filter((state) => !state.bulkEditKpiTargetLoading && state.kpiTargetsLoaded),
    withLatestFrom(this.siteDecimalScaleLimit$),
    map(([state, siteDecimalScaleLimit]) =>
      _.isNil(state.kpiTargetsData?.data[0]?.oeeTarget)
        ? null
        : this.decimalHelper.toFixedValue(state.kpiTargetsData?.data[0]?.oeeTarget, siteDecimalScaleLimit),
    ),
  );

  public readonly color$: Observable<string> = combineLatest({
    target: this.workOrderOeeTarget$,
    oee: this.store.select('line', 'quantities', 'oee'),
  }).pipe(
    map(({ target, oee }) =>
      this.helperService.getBarColor(
        this.decimalHelper.multiply(oee, '100'),
        this.decimalHelper.sanitizeString(target),
      ),
    ),
  );

  constructor(
    private readonly decimalHelper: DecimalHelper,
    private readonly helperService: HelperService,
    private readonly store: Store<OeeAppState>,
    @Inject(PROPERTIES) public readonly properties: IOEEMetricMultiselectMeta,
  ) {}

  private formatApq(oeeValue: IOeeMetricApq | null, target: EOeeMetric): string | null {
    return oeeValue?.[target]
      ? this.decimalHelper.toFixedValue(this.decimalHelper.multiply(oeeValue[target], '100'), 2)
      : null;
  }
}
