import { Action } from '@ngrx/store';
import { HttpOptionInterface } from '../filter.model';
import { ApiServer } from '../../../shared/component/filter/dropdown/dropdown.model';
import { SensorCRUDInterface } from '../../sensor-management/sensor-management.model';

export enum FilterSensorActionTypes {
  StartLoadFilterSensors = '[Filter Sensor] Load Filter Sensors',
  FilterSensorsLoaded = '[Filter Sensor] Filter Sensors Loaded',
}

export class StartLoadFilterSensors implements Action {
  readonly type = FilterSensorActionTypes.StartLoadFilterSensors;
  constructor(
    public options: HttpOptionInterface,
    public server: ApiServer = ApiServer.NestJS,
    public dispatchHideLoader: boolean = true,
  ) {}
}

export class FilterSitesLoaded implements Action {
  readonly type = FilterSensorActionTypes.FilterSensorsLoaded;
  constructor(public payload: SensorCRUDInterface[]) {}
}

export type FilterSensorActions = StartLoadFilterSensors | FilterSitesLoaded;
