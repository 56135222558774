export interface WarningCardInterface {
  icon: string;
  text: string;
  buttons: WarningCardButtonInterface[];
}

interface WarningCardButtonInterface {
  class: string;
  text: string;
  href: string;
  target: TargetType;
}

export enum TargetType {
  Self = '_self',
  Blank = '_blank',
  Parent = '_parent',
  Top = '_top',
}
