export enum BorderColors {
  plannedDownTime = 'planned-down-time',
  unplannedDownTime = 'unplanned-down-time',
  attention = 'attention',
  completed = 'completed',
  runtime = 'runtime',
  available = 'available',
  idleTime = 'idle-time',
  inProgress = 'in-progress',
  active = 'active',
  deactive = 'deactive',
  busy = 'busy',
  canceled = 'canceled',
  draft = 'draft',
}
