<div
  [class.d-none]="(!activityChartAllData || activityChartAllData.length === 0) && rawData.length === 0 && (!chartData || chartData.length === 0)"
  *ngIf="rawData || chartData"
>
  <div class="row">
    <div
      [ngClass]="
        showOnlyActivitiesChart
          ? chartConfiguration.activitiesChartDivClasses + ' col-12'
          : chartConfiguration.activitiesChartDivClasses
      "
    >
      <div
        *ngIf="!isPrinting && !chartConfiguration.showActivitiesChartYAxisName"
        class="top-charts-title f-20"
        [style.margin-left]="chartConfiguration.activitiesChartDivClasses === 'col-12' ? '20%' : '28%'"
      >
        {{ activitiesChartName }} {{ isChartsVertical ? ('(' + ('general.shortHour' | translate) + ')') : '' }}
      </div>
      <div class="chart-frame" [style.maxHeight]="(topChartsMaxAllowedHeight$ | async) + 'px'" #activityChartFrame>
        <div [class.d-none]="rawData.length === 0 && (!chartData || chartData.length === 0)">
          <div
            echarts
            id="activities-bar-chart"
            [autoResize]="true"
            [style.height]="
              chartConfiguration.shouldDisplayInfoMessages ? (activityChartHeight$ | async) + 'px' : undefined
            "
            [options]="activitiesChartOption"
            (chartClick)="onActivitiesChartClick($event)"
            (chartInit)="setActivitiesBarChartRef($event)"
          ></div>
        </div>
      </div>
    </div>
    <ng-template [ngIf]="isEquipmentUsedInTasks && !showOnlyActivitiesChart && !isNoEquipmentOnly">
      <div [ngClass]="chartConfiguration.equipmentChartDivClasses">
        <div
          *ngIf="!isPrinting && !chartConfiguration.showEquipmentChartYAxisName"
          class="top-charts-title f-20"
          [class.m-t-20]="chartConfiguration.equipmentChartDivClasses === 'col-12'"
          [style.margin-left]="chartConfiguration.equipmentChartDivClasses === 'col-12' ? '20%' : '28%'"
        >
          {{ equipmentChartName }}  {{ isChartsVertical ? ('(' + ('general.shortHour' | translate) + ')') : '' }}
        </div>
        <div class="chart-frame" [style.maxHeight]="(topChartsMaxAllowedHeight$ | async) + 'px'" #equipmentChartFrame>
          <div
            echarts
            id="equipments-bar-chart"
            [autoResize]="true"
            [style.height]="
              chartConfiguration.shouldDisplayInfoMessages ? (equipmentChartHeight$ | async) + 'px' : undefined
            "
            [options]="equipmentChartOption"
            (chartClick)="onEquipmentChartClick($event)"
            (chartInit)="renderEquipmentChart($event)"
          ></div>
        </div>
      </div>
    </ng-template>
    <div *ngIf="chartConfiguration.shouldDisplayInfoMessages" class="col-12 mt-3">
      <div class="row" [class.flex-column-reverse]="chartConfiguration.equipmentChartDivClasses === 'col-12'">
        <div class="col-2 offset-5 d-flex justify-content-center height-level-button-container">
          <scw-mat-button-group
            size="sm"
            [buttons]="chartHeightButtonGroup"
            [stateless]="true"
            [hidden]="heightLevelsNotNeeded$ | async"
            (inputModelChange)="setTopChartsHeightLevel($event)"
          ></scw-mat-button-group>
        </div>
        <div
          class="col-5 no-eq-assigned f-12 d-flex"
          *ngIf="
            isNoEquipmentAssignedExists && isEquipmentUsedInTasks && !showOnlyActivitiesChart && !isNoEquipmentOnly
          "
        >
          <scw-mat-checkbox class="float-left m-r-10" [(inputModel)]="showNoEquipment" (onChange)="toggleNoEquipment()">
          </scw-mat-checkbox>
          <div class="no-eq-text">
            <p class="float-left m-0">
              <strong class="f-w-700">
                {{ 'rootCauseAnalysis.chart.checkbox.noEquipmentAssigned.label' | translate }}
              </strong>
              {{ 'rootCauseAnalysis.charts.equipment.noEquipmentAssigned.info' | translate }}
            </p>
          </div>
        </div>
      </div>
    </div>
    <div
      [class.d-none]="rawData.length === 0 && (!chartData || chartData.length === 0)"
      *ngIf="isTaskUsedInActivities && !showOnlyActivitiesChart && chartConfiguration.showTaskTargets"
      class="col-12 show-target-border"
    ></div>
    <div
      [class.d-none]="rawData.length === 0 && (!chartData || chartData.length === 0)"
      *ngIf="isTaskUsedInActivities && !showOnlyActivitiesChart && chartConfiguration.showTaskTargets"
      class="col-12 show-target-row"
    >
      <scw-mat-checkbox
        [class.d-none]="rawData.length === 0 && (!chartData || chartData.length === 0)"
        class="float-left m-x-0 m-y-auto d-flex"
        [(inputModel)]="showTargets"
        (onChange)="toggleTaskChartTargets()"
      >
        <strong>{{ 'reports.rootCauseAnalysis.charts.task.showTargets' | translate }}</strong>
      </scw-mat-checkbox>
      <div class="target-method-row">
        <scw-mat-informative-modal
          *ngIf="showTargets"
          [modalHeader]="modalHeader"
          [data]="modalData"
          class="float-left"
        >
          <scw-mat-button type="ghost" [iconButton]="true">
            <em class="fas fa-lg fa-info-circle"></em>
          </scw-mat-button>
        </scw-mat-informative-modal>

        <scw-mat-select
          *ngIf="showTargets"
          class="target-method-dropdown"
          [clearAll]="false"
          [data]="taskTargetCategoryOptions"
          [(inputModel)]="selectedTaskTargetCategory"
          [noPadding]="true"
          (inputModelChange)="prepareTasksChartOption()"
        ></scw-mat-select>
      </div>
    </div>
    <div
      [class.d-none]="rawData.length === 0 && (!chartData || chartData.length === 0)"
      *ngIf="isTaskUsedInActivities && !showOnlyActivitiesChart"
      [ngClass]="chartConfiguration.tasksChartDivClasses"
    >
      <div
        echarts
        id="tasks-bar-chart"
        class="h-auto"
        [options]="tasksChartOption"
        (chartClick)="onTasksChartClick($event)"
        (chartInit)="setTasksBarChartRef($event)"
      ></div>
      <div
        class="text-center f-12 f-w-600"
        *ngIf="
          (tasksChartOption?.series)[0]?.data?.length > chartConfiguration.tasksChartMaxBarsWithoutOther &&
          chartConfiguration.shouldDisplayInfoMessages
        "
      >
        {{
          'rootCauseAnalysis.charts.tasks.others.info'
            | translate : { others: 'rootCauseAnalysis.charts.tasks.others.label' | translate }
        }}
      </div>
    </div>
  </div>
</div>
