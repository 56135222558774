import { Action } from '@ngrx/store';
import { HttpOptionInterface } from '../filter.model';
import { UserInterface } from '../../settings/users/users.model';

export enum FilterUserActionTypes {
  StartLoadFilterUsers = '[Filter User] Load Filter Users',
  FilterUsersLoaded = '[Filter User] Filter Users Loaded',
  ResetUserStore = '[Filter User] Reset store data for user filter',
}

export class StartLoadFilterUsers implements Action {
  readonly type = FilterUserActionTypes.StartLoadFilterUsers;
  constructor(public options: HttpOptionInterface, public isFetchMoreRequest: boolean = false) {}
}
export class FilterUsersLoaded implements Action {
  readonly type = FilterUserActionTypes.FilterUsersLoaded;
  constructor(
    public data: UserInterface[],
    public totalCount: number = 0,
    public isFetchMoreRequest: boolean = false,
  ) {}
}
export class ResetUserStore implements Action {
  readonly type = FilterUserActionTypes.ResetUserStore;
}

export type FilterUserActions = StartLoadFilterUsers | FilterUsersLoaded | ResetUserStore;
