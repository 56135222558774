<ng-template
  [ngIf]="properties.metricLayout.length === 1 && properties.metricLayout[0] === 'oee'"
  [ngIfElse]="oeeMetricApq"
>
  <oee-metric
    [oee]="(oeeValues$ | async)?.oee"
    [cardTitleKey]="'work_order_oee'"
    [target]="workOrderOeeTarget$ | async"
    [color]="color$ | async"
  ></oee-metric>
</ng-template>
<ng-template #oeeMetricApq>
  <oee-metric-apq
    class="w-100 h-100 position-relative"
    [cardTitleKey]="'work_order_oee'"
    [properties]="properties"
    [oeeValues$]="oeeValues$"
  ></oee-metric-apq>
</ng-template>
