import { Inject, Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Store } from '@ngrx/store';
import * as oeeAppReducer from '../../oee.reducer';
import { BaseOneResponseInterface } from '../../../shared/model/interface/crud-response-interface.model';
import { ActivityLogsResponseInterface, IActivityLogsRequest } from '../root-cause-analysis/root-cause-analysis.model';
import {
  IProductionPerformanceDeepDiveRequest,
  IProductionPerformanceDeepDiveResponse,
  IProductionPerformanceDeepDiveShiftDayRequest,
  IProductionPerformanceDeepDiveShiftDayResponse,
} from './performance-deep-dive.model';

@Injectable({
  providedIn: 'root',
})
export class PerformanceDeepDiveService {
  constructor(
    public http: HttpClient,
    @Inject('API_BASE_URL') private readonly api: string,
    public store: Store<oeeAppReducer.OeeAppState>,
  ) {}

  private readonly routes = {
    activityLogs: '/activity-histories/activity-logs',
    productionPerformanceDeepDive: '/lines/:lineId/production-performance-deep-dive',
    productionPerformanceDeepDiveShiftDay: '/lines/:lineId/production-performance-deep-dive-shift-day',
  };

  public getActivityLogs(
    body: IActivityLogsRequest,
  ): Observable<BaseOneResponseInterface<ActivityLogsResponseInterface>> {
    return this.http.post<BaseOneResponseInterface<ActivityLogsResponseInterface>>(
      `${this.api}${this.routes.activityLogs}`,
      body,
      {
        headers: new HttpHeaders({ 'X-HTTP-Method': 'GET' }),
      },
    );
  }

  public getProductionPerformanceDeepDiveData(
    lineId: number,
    body: IProductionPerformanceDeepDiveRequest,
  ): Observable<BaseOneResponseInterface<IProductionPerformanceDeepDiveResponse>> {
    const params: HttpParams = new HttpParams()
      .set('startDate', body.startDate)
      .set('endDate', body.endDate);
    const url: string = `${this.api}${this.routes.productionPerformanceDeepDive.replace(':lineId', String(lineId))}`;

    return this.http.get<BaseOneResponseInterface<IProductionPerformanceDeepDiveResponse>>(url, { params });
  }

  public getProductionPerformanceDeepDiveShiftDayData(
    lineId: number,
    body: IProductionPerformanceDeepDiveShiftDayRequest,
  ): Observable<BaseOneResponseInterface<IProductionPerformanceDeepDiveShiftDayResponse[]>> {
    const params: HttpParams = new HttpParams()
      .set('startDate', body.startDate)
      .set('endDate', body.endDate)
      .set('groupBy', body.groupBy);
    const url: string = `${this.api}${this.routes.productionPerformanceDeepDiveShiftDay.replace(':lineId', String(lineId))}`;

    return this.http.get<BaseOneResponseInterface<IProductionPerformanceDeepDiveShiftDayResponse[]>>(url, { params });
  }
}
