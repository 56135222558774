import { AfterViewInit, Component, Input } from '@angular/core';
import { EColorPalette, IColorPaletteOption } from '../../../../shared/service/color/color.model';
import { ColorService } from '../../../../shared/service/color/color.service';
import _ from 'lodash';

@Component({
  selector: 'app-simplified-info-card',
  templateUrl: './simplified-info-card.component.html',
  styleUrls: ['./simplified-info-card.component.scss'],
})
export class SimplifiedInfoCardComponent implements AfterViewInit {
  @Input() topText: string = '';
  @Input() bottomText?: string;
  @Input() bottomTextAlt?: string;
  @Input() backgroundColour?: string = '#FAF9F9';
  public textColour: string = '#000000';
  public colourIDs: EColorPalette[] = this.getColourIDs();
  public colorData$: IColorPaletteOption[] = ColorService.getColorDataFromColorPalette(this.colourIDs);

  ngAfterViewInit(): void {
    this.textColour = _.find(this.colorData$, { backgroundColor: this.backgroundColour })?.text ?? '#000000';
  }

  private getColourIDs(): EColorPalette[] {
    return [
      EColorPalette.RED_1,
      EColorPalette.RED_2,
      EColorPalette.RED_3,
      EColorPalette.ORANGE_1,
      EColorPalette.ORANGE_2,
      EColorPalette.YELLOW_2,
      EColorPalette.YELLOW_1,
      EColorPalette.GREEN_1,
      EColorPalette.GREEN_2,
      EColorPalette.LIME,
      EColorPalette.AQUA_2,
      EColorPalette.BLUE_3,
      EColorPalette.BLUE_2,
      EColorPalette.BLUE_1,
      EColorPalette.VIOLET_1,
      EColorPalette.VIOLET_2,
      EColorPalette.INDIGO_2,
      EColorPalette.INDIGO_1,
      EColorPalette.GREY_1,
      EColorPalette.GREY_2,
      EColorPalette.GREY_3,
    ];
  }
}
