import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { BaseOneResponseInterface } from 'src/app/shared/model/interface/crud-response-interface.model';
import { IDoNotDisturbScheduleCrudData } from 'src/app/standalone/do-not-disturb-schedule/do-not-disturb-schedule.model';
import * as UserActions from '../user/actions';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { DoNotDisturbScheduleService } from './do-not-disturb-schedule.service';
import * as oeeAppReducer from '../oee.reducer';
import * as AppActions from '../app/actions';
import * as DoNotDisturbScheduleActions from './do-not-disturb-schedule.actions';
import { of, switchMap } from 'rxjs';
import { HttpParams } from '@angular/common/http';
import { HTTPHelperService } from '../../shared/service/http/http.helper.service';
import { catchError } from 'rxjs/operators';

@Injectable()
export class DoNotDisturbScheduleEffects {
  bulkUpdate$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DoNotDisturbScheduleActions.ActionTypes.AccountSaveClickedAction),
      switchMap((action) =>
        this.service.bulkUpdateDoNotDisturbSchedule(action.schedules).pipe(
          switchMap(() => of(new DoNotDisturbScheduleActions.BulkUpdateDoNotDisturbScheduleSuccess(action.userId))),
          catchError((error) =>
            of(
              new DoNotDisturbScheduleActions.FetchError(
                error,
                DoNotDisturbScheduleActions.ActionTypes.BulkUpdateDoNotDisturbScheduleAction,
              ),
              new AppActions.HideLoader(),
            ),
          ),
        ),
      ),
    ),
  );

  getFirstWeekday$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DoNotDisturbScheduleActions.ActionTypes.DoNotDisturbSettingsInitializedAction),
      switchMap(() => of(new UserActions.GetFirstWeekday())),
    ),
  );

  reload$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        DoNotDisturbScheduleActions.ActionTypes.BulkUpdateDoNotDisturbScheduleSuccessAction,
        DoNotDisturbScheduleActions.ActionTypes.CreateDoNotDisturbScheduleLoaded,
        DoNotDisturbScheduleActions.ActionTypes.DeleteDoNotDisturbScheduleLoaded,
        DoNotDisturbScheduleActions.ActionTypes.UpdateDoNotDisturbScheduleLoaded,
      ),
      switchMap(({ userId }) =>
        of(
          new DoNotDisturbScheduleActions.FetchDoNotDisturbSchedules({
            additionalCustomSearch: [{ userId: { $eq: userId } }],
            limit: 1000,
          }),
        ),
      ),
    ),
  );

  resetActiveState$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        DoNotDisturbScheduleActions.ActionTypes.AccountSaveClickedAction,
        DoNotDisturbScheduleActions.ActionTypes.DoNotDisturbSettingsInitializedAction,
      ),
      switchMap(() => of(new DoNotDisturbScheduleActions.ResetActiveState())),
    ),
  );

  constructor(
    private readonly store: Store<oeeAppReducer.OeeAppState>,
    private readonly actions$: Actions<DoNotDisturbScheduleActions.FetchDoNotDisturbScheduleActions>,
    private readonly service: DoNotDisturbScheduleService,
    private readonly httpHelperService: HTTPHelperService,
  ) {}

  $fetchMany = createEffect(() =>
    this.actions$.pipe(
      ofType(DoNotDisturbScheduleActions.ActionTypes.FetchDoNotDisturbSchedules),
      switchMap((payload: DoNotDisturbScheduleActions.FetchDoNotDisturbSchedules) => {
        this.store.dispatch(new AppActions.ShowLoader());
        const httpParams: HttpParams = this.httpHelperService.insertGenericCrudRequestParameters(
          payload.crudParameters,
        );
        return this.service.getDoNotDisturbSchedules(httpParams).pipe(
          switchMap((response) =>
            of(new AppActions.HideLoader(), new DoNotDisturbScheduleActions.FetchDoNotDisturbSchedulesLoaded(response)),
          ),
          catchError((error) => {
            return of(
              new DoNotDisturbScheduleActions.FetchError(
                error,
                DoNotDisturbScheduleActions.ActionTypes.FetchDoNotDisturbSchedules,
              ),
              new AppActions.HideLoader(),
            );
          }),
        );
      }),
      catchError((error) =>
        of(
          new DoNotDisturbScheduleActions.FetchError(
            error,
            DoNotDisturbScheduleActions.ActionTypes.FetchDoNotDisturbSchedules,
          ),
          new AppActions.HideLoader(),
        ),
      ),
    ),
  );

  $create = createEffect(() =>
    this.actions$.pipe(
      ofType(DoNotDisturbScheduleActions.ActionTypes.CreateDoNotDisturbSchedule),
      switchMap((payload: DoNotDisturbScheduleActions.CreateDoNotDisturbSchedule) => {
        this.store.dispatch(new AppActions.ShowLoader());
        return this.service.createDoNotDisturbSchedule(payload.body).pipe(
          switchMap((response: BaseOneResponseInterface<IDoNotDisturbScheduleCrudData>) =>
            of(new DoNotDisturbScheduleActions.CreateDoNotDisturbScheduleLoaded(response.data, payload.userId)),
          ),
          catchError((error) => {
            return of(
              new DoNotDisturbScheduleActions.FetchError(
                error,
                DoNotDisturbScheduleActions.ActionTypes.CreateDoNotDisturbSchedule,
              ),
              new AppActions.HideLoader(),
            );
          }),
        );
      }),
      catchError((error) =>
        of(
          new DoNotDisturbScheduleActions.FetchError(
            error,
            DoNotDisturbScheduleActions.ActionTypes.CreateDoNotDisturbSchedule,
          ),
          new AppActions.HideLoader(),
        ),
      ),
    ),
  );

  $update = createEffect(() =>
    this.actions$.pipe(
      ofType(DoNotDisturbScheduleActions.ActionTypes.UpdateDoNotDisturbSchedule),
      switchMap((payload: DoNotDisturbScheduleActions.UpdateDoNotDisturbSchedule) => {
        this.store.dispatch(new AppActions.ShowLoader());
        return this.service.updateDoNotDisturbSchedule(payload.id, payload.body).pipe(
          switchMap((response: BaseOneResponseInterface<IDoNotDisturbScheduleCrudData>) =>
            of(new DoNotDisturbScheduleActions.UpdateDoNotDisturbScheduleLoaded(response.data, payload.userId)),
          ),
          catchError((error) =>
            of(
              new DoNotDisturbScheduleActions.FetchError(
                error,
                DoNotDisturbScheduleActions.ActionTypes.UpdateDoNotDisturbSchedule,
              ),
              new AppActions.HideLoader(),
            ),
          ),
        );
      }),
      catchError((error) => {
        return of(
          new DoNotDisturbScheduleActions.FetchError(
            error,
            DoNotDisturbScheduleActions.ActionTypes.UpdateDoNotDisturbSchedule,
          ),
          new AppActions.HideLoader(),
        );
      }),
    ),
  );

  $delete = createEffect(() =>
    this.actions$.pipe(
      ofType(DoNotDisturbScheduleActions.ActionTypes.DeleteDoNotDisturbSchedule),
      switchMap((payload: DoNotDisturbScheduleActions.DeleteDoNotDisturbSchedule) => {
        this.store.dispatch(new AppActions.ShowLoader());
        return this.service.deleteDoNotDisturbSchedule(payload.id).pipe(
          switchMap(() => of(new DoNotDisturbScheduleActions.DeleteDoNotDisturbScheduleLoaded(payload.userId))),
          catchError((error) => {
            return of(
              new DoNotDisturbScheduleActions.FetchError(
                error,
                DoNotDisturbScheduleActions.ActionTypes.DeleteDoNotDisturbSchedule,
              ),
              new AppActions.HideLoader(),
            );
          }),
        );
      }),
      catchError((error) => {
        return of(
          new DoNotDisturbScheduleActions.FetchError(
            error,
            DoNotDisturbScheduleActions.ActionTypes.DeleteDoNotDisturbSchedule,
          ),
          new AppActions.HideLoader(),
        );
      }),
    ),
  );
}
