import { ElementRef, Inject, Injectable, isDevMode, Type } from '@angular/core';
import { Action, Store } from '@ngrx/store';
import { OeeAppState } from '../../store/oee.reducer';
import { WINDOW } from '../../window.providers';
import * as moment from 'moment';
import { Moment } from 'moment';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import * as AppActions from '../../store/app/actions';
import { NgForm } from '@angular/forms';
import { MomentDateFormat, UserDateFormat } from '../helper/date.constants';
import { BulkResponseDataInterface } from '../model/interface/crud-response-interface.model';
import { ErrorMessageService } from './error-message.service';
import { CustomDateFormat, User } from '../../store/user/model';
import {
  PhaseCommentInterface,
  PhaseCommentsEnteredStatusInterface,
  PhaseCommentStoreDataInterface,
} from '../../store/home/home.model';
import { PhaseOptionNames } from '../model/enum/phase-options';
import * as _ from 'lodash';
import { CommentType } from '../../view/shift-summary/shift-summary.model';
import { PhaseFieldInterface } from '../component/progress-bar-metric/work-order-phase-comment-modal/work-order-phase-comment-modal.model';
import { PhaseDurationsInterface } from '../../view/home/home-metrics/common-phase-duration-metric/common-phase-duration-metric.model';
import { CheckInModules } from '../../view/home/cico/cico.model';
import { IRecordRangeOptions } from '../../store/settings/product-speeds/product-speeds.model';
import sweetalert2, { SweetAlertOptions } from 'sweetalert2';
import { HttpParams } from '@angular/common/http';
import {
  GenericCrudRequestConstructionParameterFilters,
  GenericCrudRequestConstructionParameters,
} from '../model/interface/generic-api-request.model';
import { DropdownComponent } from '../component/filter/dropdown/dropdown.component';
import { FilterableObjectTypes } from '../component/filter/filterable-objects.class';
import {
  DropdownItemFilterInterface,
  LineCRUDInterface,
  RowConfigurationInterface,
  SiteCRUDInterface,
} from '../component/filter/filter.class';
import { AdvancedFilterComponent } from '../component/filter/advanced-filter/advanced-filter.component';
import {
  AdvancedFilterClass,
  FieldTypes,
  TargetEndpoints,
} from '../component/filter/advanced-filter/advanced-filter.model';
import { filter, take, of } from 'rxjs';
import { IAdvancedFilterStore } from '../../store/advanced-filter/advanced-filter.model';
import { AdvancedFilterService } from '../component/filter/advanced-filter/advanced-filter.service';
import {
  DropdownOptionInterface,
  IDefaultSelectItem,
  ScwMatMaxLengthRule,
  ScwMatMaxValueRule,
  ScwMatMinValueRule,
  ScwMatRulesInterface,
  ScwMatSelectRule,
} from '../component/scw-mat-ui/scw-mat-select/scw-mat-select.model';
import { ScwMatInput } from '../component/scw-mat-ui/scw-mat-input/scw-mat-input.model';
import { DependencyType, DropdownSettingsInterface } from '../component/filter/dropdown/dropdown.model';
import { IConstantLookUp } from '../../view/settings/users/users.model';
import { localeDateObject } from '../model/enum/excel-date-format';
import { TimeZoneOptions } from '../model/enum/timezone-list';
import {
  DateRanges,
  ELanguages,
  ENumberFormatOption,
  ESeparatorCombination,
  KPI_METRICS_DECIMAL_COUNT,
  StatusesEnum,
  TOtherLanguageOptions,
} from '../../../constants';
import { IGenericObject, NonEmptyArray } from '../model/interface/generic.model';
import { NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import {
  ISeparators,
  ITableHeader,
  PageConfigurationTypes,
  TDecimalSeparator,
  TThousandSeparator,
} from '../../../constants.model';
import { TabRowInterface } from '../component/side-config-bar/side-config-bar.model';
import * as UserConfigurationActions from '../../store/user-configuration/user-configuration.actions';
import {
  ComponentNamesForUserConfiguration,
  IComponentConfiguration,
  IUserConfiguration,
  UserConfigurationStateInterface,
} from '../../store/user-configuration/user-configuration.model';
import {
  EWidgetType,
  ICustomConfigurationDbEntry,
  IWidget,
  WidgetConfigurationInterface,
} from '../component/page-configuration/page-configuration.model';
import { IAllowedDomain } from '../../store/main/main.model';
import { IFilterMap, IFilterMapped } from '../component/file-generator/file-generator.model';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { ScwMatFormComponent } from '../component/scw-mat-ui/scw-mat-form/scw-mat-form.component';
import { ETableModalTypes } from './datatable/datatable.model';
import { EFilterDropdownElements } from '../../store/filter/filter.model';
import { DecimalHelper } from '../helper/decimal/decimal-helper';
import { changeTimezone } from '../helper/date';
import { NavigationEnd, Router } from '@angular/router';
// Alternative Services
import { GenericHelperService } from './generic.helper.service';
import { UserHelperService } from './user/user.helper.service';
import { DateHelperService } from './date/date.helper.service';
import { ToastHelperService } from './toast/toast.helper.service';
import { PhaseHelperService } from '../phase/phase.helper.service';
import { CheckInHelperService } from './check-in/check-in.helper.service';
import { ActivityHelperService } from './activities/activity.helper.service';
import { SweetAlertHelperService } from './sweet-alert/sweet-alert.helper.service';
import { ExcelHelperService } from './excel/excel.helper.service';
import { LocalizationHelperService } from './localization/localization.helper.service';
import { HTTPHelperService } from './http/http.helper.service';
import { FilterHelperService } from './filter/filter.helper.service';
import { FormHelperService } from './form/form.helper.service';
import { ModalHelperService } from './modal/modal.helper.service';
import { LineHelperService } from './line/line.helper.service';
import { KPICardHelperService } from './kpi-card/kpi-card.helper.service';
import { HomeHelperService } from './home/home.helper.service';

import { FilterLineState } from '../../store/filter/line/line.reducer';
import { FilterSiteState } from '../../store/filter/site/site.reducer';
import { DateRangeFilterInterface } from '../../view/reports/check-in-logs/check-in-logs.model';
import { StartLoadFilterShifts } from '../../store/filter/shift/shift.actions';
import {
  DropdownType,
  IActivityHistoryRequestParameter,
} from '../../store/reports/root-cause-analysis-elastic/root-cause-analysis-elastic.model';
import { ScwMatDatepickerReturnInterface } from '../component/scw-mat-ui/scw-mat-datepicker/scw-mat-datepicker.model';

@Injectable({
  providedIn: 'root',
})
export class HelperService {
  /**
   * @deprecated This property will be deleted with future versions, please use
   * {@link GenericHelperService.isTouchDevice} instead!
   */
  public isTouchDevice: boolean;
  private decimalSeparator: TDecimalSeparator;
  private thousandSeparator: TThousandSeparator;
  private userDateFormat$: string;
  private userDateTimeFormat$: string;
  private userDateTimeFormatWithSecond$: string;
  private userLocale$: string;
  public static userDatePref: { dateFormat: string; timeFormat: string; weekFormat: string };
  private timezone$: string;
  /**
   * @deprecated This property will be deleted with future versions, please use
   * {@link GenericHelperService.windowWidth} instead!
   */
  public windowWidth: number;
  private successTitle: string;
  private changesSavedSuccessfullyMessage: string;
  private errorTitle: string;
  private defaultSiteId$: string;

  constructor(
    @Inject(WINDOW) private window: Window,
    private store: Store<OeeAppState>,
    private translate: TranslateService,
    private toast: ToastrService,
    private errorMessageService: ErrorMessageService,
    private readonly advancedFilterService: AdvancedFilterService,
    private readonly decimalHelper: DecimalHelper,
    private readonly router: Router,
  ) {
    this.isTouchDevice = 'ontouchstart' in document.documentElement;
    this.windowWidth = window.innerWidth;

    window.addEventListener('resize', () => {
      this.windowWidth = window.innerWidth;
    });

    this.store.select('user').subscribe((state) => {
      this.decimalSeparator = state.decimalSeparator;
      this.thousandSeparator = HelperService.mapThousandSeparator(state.thousandSeparator);
      this.userDateFormat$ = state.dateFormat;
      this.userDateTimeFormat$ = state.dateTimeFormat;
      this.userDateTimeFormatWithSecond$ = state.dateTimeFormatWithSecond;
      this.userLocale$ = state.locale;
      this.timezone$ = state.timezone;
      this.defaultSiteId$ = state.defaultSite;
      HelperService.getDateTimePref(state.dateFormat, state.dateFormat);
    });

    this.translate
      .stream(['general.success', 'general.changesSavedSuccessfully', 'general.error'])
      .subscribe((data) => {
        this.successTitle = data['general.success'];
        this.changesSavedSuccessfullyMessage = data['general.changesSavedSuccessfully'];
        this.errorTitle = data['general.error'];
      });
  }

  numberToFormattedNumber(number, decimalCount = 1) {
    if (typeof number !== 'number' || !isFinite(number)) {
      return 0;
    }

    const decimal = this.decimalSeparator;
    const thousands = this.thousandSeparator;
    let decimalCountVar = this.decimalHelper.abs(decimalCount.toString());
    decimalCountVar = isNaN(+decimalCountVar) ? '2' : decimalCountVar;

    const negativeSign = number < 0 ? '-' : '';

    let numberVar;

    const i: string = parseInt(
      (numberVar = this.decimalHelper.round(this.decimalHelper.abs(number.toString() || '0'), +decimalCountVar)),
      10,
    ).toString();

    const j = i.length > 3 ? i.length % 3 : 0;

    return (
      negativeSign +
      (j ? i.substr(0, j) + thousands : '') +
      i.substr(j).replace(/(\d{3})(?=\d)/g, `$1${thousands}`) +
      (decimalCount
        ? decimal + Math.abs(+this.decimalHelper.subtract(numberVar.toString(), i)).toFixed(decimalCount).slice(2)
        : '')
    );
  }

  markEmptyField(value) {
    let output = value;
    if (typeof value === 'undefined' || value === null || value === '') {
      output = '--';
    }
    return output;
  }

  getHostname(): string {
    return this.window.location.hostname;
  }

  scrollButton(element, position) {
    switch (position) {
      case 'up': {
        document.querySelector(`.modal.show ${element}`).scroll({
          top: 0,
          left: 0,
          behavior: 'smooth',
        });
        break;
      }
      case 'down': {
        document.querySelector(`.modal.show ${element}`).scroll({
          top: 9999999,
          left: 0,
          behavior: 'smooth',
        });
        break;
      }
      default:
    }
  }

  calculateRate(dividend, denominator, isProgress = false): number | string | any {
    let rate: number;
    let decimalCountVal: number = 1;
    let dividendVal: number = dividend;
    let denominatorVal: number = denominator;

    if (
      typeof dividend === 'undefined' ||
      typeof denominator === 'undefined' ||
      dividend === null ||
      denominator === null ||
      dividend === '' ||
      denominator === ''
    ) {
      return 0;
    }

    if (typeof dividend !== 'number') {
      dividendVal = this.decimalHelper.formattedNumberToNumber(dividend);
    }

    if (typeof denominator !== 'number') {
      denominatorVal = this.decimalHelper.formattedNumberToNumber(denominator);
    }

    if (denominatorVal === 0) {
      return 0;
    }

    rate = (dividendVal / denominatorVal) * 100;

    if (isProgress && rate <= 0) {
      return 0;
    }

    if (isProgress && rate >= 100) {
      return 100;
    }

    if (isProgress) {
      return rate;
    }

    if (!isProgress) {
      decimalCountVal = 0;
    }

    if (rate === 0 || this.decimalHelper.removeTrailingZeros(rate.toString()) === '0') {
      return rate;
    }

    return this.decimalHelper.toFixedValue(rate.toString(), decimalCountVal);
  }

  /**
   * @deprecated This method will be deleted with future versions, please use
   * {@link DateHelperService.checkIsValidDateOrFail()} instead!
   */
  checkIsValidDateOrFail(date: any, format: string): void {
    const momentDate = moment(date, format, true);

    if (!momentDate.isValid()) {
      this.toast.error(this.translate.instant('general.incorrectDateFormat'), this.translate.instant('general.error'), {
        closeButton: true,
        progressBar: true,
        positionClass: 'toast-bottom-right',
      });
      this.store.dispatch(new AppActions.HideTopLoader());
      this.store.dispatch(new AppActions.HideLoader());
      throw new Error('Invalid Date Format');
    }
  }

  /**
   * @deprecated This method will be deleted with future versions, please use
   * {@link FormHelperService.getEditedFields()} instead!
   */
  getEditedFields<T>(form: NgForm): Partial<T> {
    let editedFields: Partial<T> = {};
    for (const [key, value] of Object.entries(form.form.controls)) {
      if (value.dirty) {
        editedFields = Object.assign(editedFields, { [key]: value.value });
      }
    }
    return editedFields;
  }

  /**
   * @deprecated This method will be deleted with future versions, please use
   * {@link DateHelperService.formatDuration()} instead!
   */
  public formatDuration(duration: number, withSecond: boolean = false, suffix: boolean = false) {
    if (duration === null) {
      return '';
    }

    let negative = '';

    if (duration < 0) {
      duration = duration * -1;
      negative = '-';
    }

    const hours: string = Math.floor(duration / 3600)
      .toString()
      .padStart(2, '0');
    const minutes: string = Math.floor((duration / 60) % 60)
      .toString()
      .padStart(2, '0');
    const seconds: string = (duration % 60).toString().padStart(2, '0');

    let shorteningHr = '';
    let shorteningMin = '';
    let shorteningSec = '';
    let separator = ':';

    if (suffix) {
      shorteningHr = this.translate.instant('general.shortHour');
      shorteningMin = this.translate.instant('general.shortMinute');
      shorteningSec = this.translate.instant('general.shortSecond');
      separator = ' ';
    }

    if (withSecond) {
      return `${negative}${hours}${shorteningHr}${separator}${minutes}${shorteningMin}${separator}${seconds}${shorteningSec}`;
    }

    return `${negative}${hours}${shorteningHr}${separator}${minutes}${shorteningMin}`;
  }

  /**
   * @deprecated This method will be deleted with future versions, please use
   * {@link DateHelperService.formattedDurationToSeconds()} instead!
   */
  public formattedDurationToSeconds(formattedDuration: string): number {
    if (typeof formattedDuration !== 'string') {
      return 0;
    }

    const split: string[] = formattedDuration.split(':');

    if (!split || split.length !== 2 || isNaN(Number(split[0])) || isNaN(Number(split[1]))) {
      return 0;
    }

    return Number(split[0]) * 3600 + Number(split[1]) * 60;
  }

  /**
   * @deprecated This method will be deleted with future versions, please use
   * {@link DateHelperService.minutesToFormattedHm()} instead!
   */
  public minutesToFormattedHm(duration: number): string {
    let formattedDuration: string = '0';
    let hours: number = Math.floor(duration / 3600);
    const shortDay: string = this.translate.instant('general.shortDay');
    const shortHour: string = this.translate.instant('general.shortHour');
    const shortMinute: string = this.translate.instant('general.shortMinute');
    const days: number = Math.floor(duration / 86400);
    const minutes: number = Math.floor((duration - hours * 3600) / 60);

    if (days > 0) {
      hours = hours - 24 * days;

      formattedDuration = `${days}${shortDay} ${hours}${shortHour} ${minutes}${shortMinute}`;
    } else if (hours > 0) {
      formattedDuration = `${hours}${shortHour} ${minutes}${shortMinute}`;
    } else if (minutes > 0) {
      formattedDuration = `${minutes}${shortMinute}`;
    }

    return formattedDuration;
  }

  public pageSizeFormatter(pageSize: number) {
    let fixedPageSize = 10;

    if (10 < pageSize && pageSize <= 25) {
      fixedPageSize = 25;
    }

    if (25 < pageSize && pageSize <= 50) {
      fixedPageSize = 50;
    }

    if (50 < pageSize && pageSize <= 100) {
      fixedPageSize = 100;
    }
    return fixedPageSize;
  }

  /**
   * @deprecated This method will be deleted with future versions, please use
   * {@link UserHelperService.getUserMomentTimeFormat()} instead!
   */
  getUserMomentTimeFormat(): MomentDateFormat.TIME_12H | MomentDateFormat.TIME_24H {
    return this.userDateFormat$ === UserDateFormat.DATETIME_12H ? MomentDateFormat.TIME_12H : MomentDateFormat.TIME_24H;
  }

  /**
   * @deprecated This method will be deleted with future versions, please use
   * {@link ToastHelperService.showToastMessage()} instead!
   */
  public showToastMessage(success: boolean, title: string | undefined, message: string): void {
    const defaultTitle: string = success ? this.successTitle : this.errorTitle;
    const type: string = success ? 'success' : 'error';

    this.toast[type](message, title ?? defaultTitle, {
      closeButton: true,
      progressBar: true,
      positionClass: 'toast-bottom-right',
    });
  }

  /**
   * @deprecated This method will be deleted with future versions, please use
   * {@link ToastHelperService.toastBulkErrors()} instead!
   */
  public toastBulkErrors(response: BulkResponseDataInterface): void {
    if (response && response.data) {
      this.errorMessageService.getTranslatedErrorMessage(response.data);

      for (const message of response.data) {
        if (!message.success) {
          this.showToastMessage(
            message.success,
            this.translate.instant('general.failed'),
            (message.message || []).join(', '),
          );
        }
      }
    }
  }

  /**
   * @deprecated This method will be deleted with future versions, please use
   * {@link UserHelperService.setUserDateTimeFormat()} instead!
   */
  public setUserDateTimeFormat(
    date: string | Moment | Date | null,
    showTime: boolean = false,
    showYear: boolean = true,
    showSeconds: boolean = false,
  ): string {
    if (date === null || date === '') {
      return '';
    }

    let dateFormat = this.userDateFormat$;
    let formattedDate: string;

    if (showTime) {
      dateFormat = this.userDateTimeFormat$;

      if (showSeconds) {
        dateFormat = this.userDateTimeFormatWithSecond$;
      }
    }

    if (moment(date).isValid()) {
      formattedDate = moment(date).format(dateFormat);
    } else if (moment(date, dateFormat).isValid()) {
      formattedDate = moment(date, dateFormat).format(dateFormat);
    }

    if (!showYear) {
      formattedDate = this.removeYear(moment(date), formattedDate);
    }

    return formattedDate;
  }

  /**
   * @deprecated This method will be deleted with future versions, please use
   * {@link UserHelperService.getCustomDateFormats()} instead!
   */
  public setCustomDateFormats(): CustomDateFormat {
    return {
      parseInput: 'LL LT',
      fullPickerInput: this.userDateTimeFormatWithSecond$,
      datePickerInput: this.userDateFormat$,
      timePickerInput: 'H:mm',
      monthYearLabel: 'MMM YYYY',
      dateA11yLabel: 'LL',
      monthYearA11yLabel: 'MMMM YYYY',
    };
  }

  /**
   * @deprecated This method will be deleted with future versions, please use
   * {@link UserHelperService.getLocale()} instead!
   */
  public setLocale(): string {
    return this.userLocale$;
  }

  /**
   * @deprecated This method will be deleted with future versions, please use
   * {@link UserHelperService.getPlaceholderForDate()} instead!
   */
  public getPlaceholderForDate(showTime: boolean = false): string {
    const now = showTime
      ? moment().tz(this.timezone$).format(this.userDateTimeFormat$)
      : moment().tz(this.timezone$).format(this.userDateFormat$);
    return this.translate.instant('general.datePlaceholder', {
      datePlaceholder: now,
    });
  }

  /**
   * @deprecated This method will be deleted with future versions, please use
   * {@link PhaseHelperService.isPreviousCommentIsEntered()} instead!
   */
  public isPreviousCommentIsEntered(
    selectedPhase: number,
    phaseCommentsEnteredStatus: PhaseCommentsEnteredStatusInterface,
  ): boolean {
    if (phaseCommentsEnteredStatus === null) {
      return false;
    }

    switch (selectedPhase) {
      case 1:
        return Boolean(phaseCommentsEnteredStatus.isPreRunCommentEntered);
      case 2:
        return Boolean(phaseCommentsEnteredStatus.isPostRunCommentEntered);
      case 3:
        return Boolean(phaseCommentsEnteredStatus.isRunCommentEntered);
      default:
        return false;
    }
  }

  /**
   * @deprecated This method will be deleted with future versions, please use
   * {@link PhaseHelperService.preparePhaseComment()} instead!
   */
  public preparePhaseComment(
    phaseComments: PhaseCommentStoreDataInterface,
    previousPhase: number,
  ): PhaseCommentInterface {
    const defaultPhaseCommentState = { id: null, message: null };
    let phaseComment = _.get(phaseComments, PhaseOptionNames[previousPhase], defaultPhaseCommentState);

    if (phaseComment === null) {
      phaseComment = defaultPhaseCommentState;
    }

    return phaseComment.id === null ? null : phaseComment;
  }

  /**
   * @deprecated This method will be deleted with future versions, please use
   * {@link PhaseHelperService.getPhaseName()} instead!
   */
  private getPhaseName(phaseDurations: PhaseDurationsInterface, previousPhase: number): string {
    const key: string = `${PhaseOptionNames[previousPhase]}Activity`;
    const isCustomName: boolean = phaseDurations.hasOwnProperty(key) && phaseDurations[key] !== null;
    let phaseText: string;

    if (isCustomName) {
      return phaseDurations[key];
    }

    switch (previousPhase) {
      case 1:
        phaseText = this.translate.instant('homeScreenMetrics.activityOrderTypes.pre');
        break;
      case 2:
        phaseText = this.translate.instant('homeScreenMetrics.activityOrderTypes.post');
        break;
      case 3:
        phaseText = this.translate.instant('homeScreenMetrics.activityOrderTypes.run');
        break;
      default:
        phaseText = '';
    }

    return phaseText;
  }

  /**
   * @deprecated This method will be deleted with future versions, please use
   * {@link PhaseHelperService.preparePhaseAndPhaseType()} instead!
   */
  public preparePhaseAndPhaseType(
    phaseDurations: PhaseDurationsInterface,
    previousPhase: number,
  ): { phaseType: CommentType; phase: PhaseFieldInterface } {
    const duration: string = _.get(phaseDurations, `${PhaseOptionNames[previousPhase]}Duration`);
    const targetDuration: string = _.get(phaseDurations, `${PhaseOptionNames[previousPhase]}Target`);
    const phaseType: CommentType = PhaseOptionNames[previousPhase] as CommentType;
    const actual: string = this.calculatePhaseDuration(duration);
    const target: string = this.calculatePhaseDuration(targetDuration);
    const phaseText: string = this.getPhaseName(phaseDurations, previousPhase);

    return {
      phaseType,
      phase: {
        phaseText,
        actual,
        target,
      },
    };
  }

  /**
   * @deprecated This method will be deleted with future versions, please use
   * {@link PhaseHelperService.calculatePhaseDuration()} instead!
   */
  public calculatePhaseDuration(duration: string): string {
    if (duration === null) {
      return null;
    }

    return (Number(duration) / 3600).toFixed(1);
  }

  /**
   * @deprecated This method will be deleted with future versions, please use
   * {@link DateHelperService.removeYear()} instead!
   */
  public removeYear(moment: moment.Moment, dateString: string): string {
    const regex: RegExp = new RegExp(`[^]${moment.format('Y')}`);
    return dateString.replace(regex, '');
  }

  /**
   * @deprecated This method will be deleted with future versions, please use
   * {@link DateHelperService.getDateTimePref()} instead!
   */
  public static getDateTimePref(timeFormat: string, dateFormat: string): void {
    const userDatePref = {
      timeFormat: 'h:mm a',
      dateFormat: 'ddd MM/DD',
      weekFormat: 'w.W MMM/YYYY',
    };

    if (timeFormat === 'MMM D, YYYY H:mm') {
      userDatePref.timeFormat = 'H:mm';
    }

    if (dateFormat === 'MM/DD/YYYY') {
      userDatePref.dateFormat = 'ddd DD/MM';
    }

    HelperService.userDatePref = userDatePref;
  }

  /**
   * @deprecated This method will be deleted with future versions, please use
   * {@link GenericHelperService.mergeArrayObjectsBy()} instead!
   */
  public static mergeArrayObjectsBy(originalArray: any[], updateArray: any[], iterator: string): any[] {
    return HelperService.cloneDeep(originalArray).map((item: any) => {
      const updateArrayIndex = updateArray.find((element: any) => element[iterator] === item[iterator]);
      return updateArrayIndex ? Object.assign({}, item, HelperService.cloneDeep(updateArrayIndex)) : item;
    });
  }

  /**
   * @deprecated This method will be deleted with future versions, please use
   * {@link CheckInHelperService.generateCheckInIcon()} instead!
   */
  public static generateCheckInIcon(name: string, shortName: string, module: CheckInModules): string {
    const formattedName: string = name !== null ? name.trim() : '';
    const formattedShortName: string = shortName !== null ? shortName.trim() : '';
    let icon: string;

    switch (module) {
      case CheckInModules.assetTracker:
        icon = formattedShortName.split(' ').join('').substring(0, 3);
        break;
      default:
        const result: string[] = _.map(formattedName.split(' '), _.head);
        icon = result.length > 1 ? result[0] + result[result.length - 1] : result[0];
    }

    return icon && icon.length > 0 ? icon.toUpperCase() : '';
  }

  /**
   * @deprecated This method will be deleted with future versions, please use
   * {@link GenericHelperService.transformToBoolean()} instead!
   */
  public static transformToBoolean(value: unknown): boolean | unknown {
    if (_.isNil(value) || _.isBoolean(value)) {
      return value;
    }

    try {
      const parsedValue = JSON.parse(String(value));

      return [1, 0, true, false].includes(parsedValue) ? Boolean(parsedValue) : value;
    } catch {
      return value;
    }
  }

  /**
   * @deprecated This method will be deleted with future versions, please use
   * {@link SweetAlertHelperService.showSweetAlertError()} instead!
   */
  public showSweetAlertError(text: string, settings?: SweetAlertOptions): void {
    this.store.dispatch(new AppActions.HideLoader());
    sweetalert2({
      text,
      showCancelButton: false,
      title: this.translate.instant('general.error'),
      confirmButtonText: this.translate.instant('general.ok'),
      type: 'error',
      ...settings,
    }).catch(sweetalert2.noop);
  }

  /**
   * @deprecated This method will be deleted with future versions, please use
   * {@link ExcelHelperService.getRecordRangeOptions()} instead!
   */
  public getRecordRangeOptions(
    totalData: number,
    excelUploadLimit: number = 1000,
    selectedCount: number = null,
    returnAsOffset = false,
  ): IRecordRangeOptions[] {
    const totalExcelData: number = selectedCount ? selectedCount : totalData;

    return Array.from(Array(Math.ceil(totalData / excelUploadLimit))).map((value, index) => {
      const rangeStart = index * excelUploadLimit;
      const rangeEnd = rangeStart + excelUploadLimit;

      return {
        value: returnAsOffset ? String(rangeStart) : String(index + 1),
        label: `${rangeStart + 1} - ${rangeEnd < totalExcelData ? rangeEnd : totalExcelData}`,
      };
    });
  }

  /**
   * @deprecated This method will be deleted with future versions, please use
   * {@link GenericHelperService.enumToArray()} instead!
   */
  public static enumToArray<T extends object>(enumeration: T): Array<T[keyof T]> {
    return Object.keys(enumeration)
      .filter((key) => isNaN(Number(key)))
      .map((key) => enumeration[key]);
  }

  /**
   * @deprecated This method will be deleted with future versions, please use
   * {@link LocalizationHelperService.getLocales()} instead!
   */
  public getLocales(): IConstantLookUp[] {
    const locales: IConstantLookUp[] = [];
    for (const locale in localeDateObject) {
      locales.push({ id: locale, name: localeDateObject[locale] });
    }
    return locales;
  }

  /**
   * @deprecated This method will be deleted with future versions, please use
   * {@link LocalizationHelperService.getYesNoDropdown()} instead!
   */
  public getYesNoDropdown(): IConstantLookUp[] {
    return [
      { id: 0, name: this.translate.instant('general.no') },
      { id: 1, name: this.translate.instant('general.yes') },
    ];
  }

  /**
   * @deprecated This method will be deleted with future versions, please use
   * {@link LocalizationHelperService.getActiveInactive()} instead!
   */
  public getActiveInactive(): IConstantLookUp[] {
    return [
      { id: 0, name: this.translate.instant('general.inactive') },
      { id: 1, name: this.translate.instant('general.active') },
    ];
  }

  /**
   * @deprecated This method will be deleted with future versions, please use
   * {@link LocalizationHelperService.getActiveInactiveOfStatus()} instead!
   */
  public getActiveInactiveOfStatus(): IDefaultSelectItem[] {
    return [
      { id: StatusesEnum.ACTIVE, name: this.translate.instant('general.active') },
      { id: StatusesEnum.INACTIVE, name: this.translate.instant('general.inactive') },
    ];
  }

  /**
   * @deprecated This method will be deleted with future versions, please use
   * {@link LocalizationHelperService.getDateTimeFormats()} instead!
   */
  public getDateTimeFormats(selectedLocale: string): IConstantLookUp[] {
    return [
      {
        id: 'll',
        name: moment().locale(selectedLocale).format('ll LT'),
      },
      {
        id: 'L',
        name: moment().locale(selectedLocale).format('L LT'),
      },
    ];
  }

  /**
   * @deprecated This method will be deleted with future versions, please use
   * {@link LocalizationHelperService.getDateTimeFormatsWithLocales()} instead!
   */
  public getDateTimeFormatsWithLocales(): IConstantLookUp[] {
    const locales: IConstantLookUp[] = this.getLocales();
    const dateTimeFormatsWithLocale: IConstantLookUp[] = [];
    locales.forEach((locale) => {
      dateTimeFormatsWithLocale.push({
        id: `ll|${locale.id}`,
        name: `${locale.name} - ${moment().locale(String(locale.id)).format('ll LT')}`,
      });
      dateTimeFormatsWithLocale.push({
        id: `L|${locale.id}`,
        name: `${locale.name} - ${moment().locale(String(locale.id)).format('L LT')}`,
      });
    });
    return dateTimeFormatsWithLocale;
  }

  /**
   * @deprecated This method will be deleted with future versions, please use
   * {@link DateHelperService.getTimezones()} instead!
   */
  public getTimezones(): IConstantLookUp[] {
    return Object.entries(TimeZoneOptions).map(([key, value]) => ({ id: key, name: value })) as IConstantLookUp[];
  }

  /**
   * @deprecated This method will be deleted with future versions, please use
   * {@link HTTPHelperService.insertGenericCrudRequestParameters()} instead!
   */
  public insertGenericCrudRequestParameters(parameters: GenericCrudRequestConstructionParameters): HttpParams {
    let params: HttpParams = new HttpParams();

    if (parameters.page) {
      params = params.set('page', String(parameters.page));
    }

    if (parameters.perPage) {
      params = params.set('per_page', String(parameters.perPage));
    }

    if (parameters.limit) {
      params = params.set('limit', String(parameters.limit));
    }

    if (parameters.fields?.length) {
      params = params.set('fields', parameters.fields.join(','));
    }

    for (const sort of parameters.sort ?? []) {
      params = params.append('sort', `${sort.column},${sort.type === 'descending' ? 'DESC' : 'ASC'}`);
    }

    if (parameters.join?.length) {
      for (const join of parameters.join) {
        params = params.append('join', join);
      }
    }

    return this.prepareSearchParamsFilter(parameters, params);
  }

  /**
   * @deprecated This method will be deleted with future versions, please use
   * {@link HTTPHelperService.prepareSearchParamsFilter()} instead!
   */
  private prepareSearchParamsFilter(
    parameters: GenericCrudRequestConstructionParameters,
    params: HttpParams,
  ): HttpParams {
    let searchCondition = [];

    if (parameters.additionalCustomSearch) {
      searchCondition = _.cloneDeep(parameters.additionalCustomSearch);
    }

    if (parameters.search?.searchText?.length) {
      searchCondition.push({
        $or: parameters.search.searchedFields.map((field: string) => {
          return {
            [field]: { $cont: parameters.search.searchText },
          };
        }),
      });
    }

    const filterCondition =
      parameters.filters?.map((filter) => {
        return { [filter.field]: { $in: filter.ids } };
      }) ?? [];

    const combinedConditions: { $and: { [key: string]: any }[] } = {
      $and: [...filterCondition, ...searchCondition],
    };

    if (parameters.advancedFilter) {
      const advancedFilter = parameters.advancedFilter.filters;

      for (const filter of advancedFilter) {
        if (filter.type === FieldTypes.predefined) {
          combinedConditions.$and.push(
            this.advancedFilterService.generateQuery(
              filter.path,
              filter.type,
              filter.operator.name,
              filter.operator.type,
              parameters.advancedFilter.target,
              _.get(filter.value, `[0][${filter.searchBy}]`, ''),
            ) as { [key: string]: any },
          );
          continue;
        }

        combinedConditions.$and.push(
          this.advancedFilterService.generateQuery(
            filter.path,
            filter.type,
            filter.operator.name,
            filter.operator.type,
            parameters.advancedFilter.target,
            filter.value,
          ) as { [key: string]: any },
        );
      }
    }

    return combinedConditions.$and.length ? params.set('s', JSON.stringify(combinedConditions)) : params;
  }

  /**
   * @deprecated This method will be deleted with future versions, please use
   * {@link FilterHelperService.getGenericDropdownFilter()} instead!
   */
  public getGenericDropdownFilter(
    entity: {
      type: Type<FilterableObjectTypes>;
      name: string;
      elementId?: EFilterDropdownElements;
    },
    cls: string = 'col-md-3 m-t-5 m-b-5',
    overrideOptions: Partial<DropdownSettingsInterface> = {},
    depends?: DependencyType,
    filter?: DropdownItemFilterInterface,
  ): RowConfigurationInterface {
    return {
      cls,
      elementId: entity.elementId || `${entity.name}Dropdown`,
      type: DropdownComponent,
      object: entity.type,
      outputOptions: {
        filterObjectId: entity.name,
        filterObjectProp: 'id',
        returnFilterObjectAllProp: false,
      },
      options: {
        isRequired: false,
        singleSelection: false,
        badgeShowLimit: 1,
        text: this.translate.instant(`filterCard.${entity.name}.dropdownPlaceHolder`),
        ...overrideOptions,
      },
      ...(depends ? { depends } : {}),
      ...(filter ? { filter } : {}),
    };
  }

  /**
   * @deprecated This method will be deleted with future versions, please use
   * {@link FilterHelperService.getGenericAdvancedFilter()} instead!
   */
  public getGenericAdvancedFilter(
    advancedFilterObject: Type<AdvancedFilterClass>,
    cls: string = 'col-md-4 m-t-5 m-b-5',
    elementId: string = 'advancedFilter',
  ): RowConfigurationInterface {
    return {
      advancedFilterObject,
      cls,
      elementId,
      type: AdvancedFilterComponent,
      outputOptions: {
        filterObjectId: 'advancedFilter',
      },
      options: {
        isRequired: false,
        autoApply: true,
      },
    };
  }

  /**
   * @deprecated This method will be deleted with future versions, please use
   * {@link FilterHelperService.pageFilterChangeOperations()} instead!
   */
  public pageFilterChangeOperations(
    event: any,
    pageData: {
      pageDataCrudParameters: GenericCrudRequestConstructionParameters;
      pageName: string;
      isFirstTimeOpening: boolean[];
      selectedItems: unknown[];
    },
    additionalOperations: Function,
    target: TargetEndpoints = TargetEndpoints.NestJSCrud,
  ): void {
    this.store
      .select('advancedFilterStore')
      .pipe(
        filter((state: IAdvancedFilterStore) => !state.isDefaultFiltersLoading && state.isDefaultFiltersLoaded),
        take(1),
      )
      .subscribe((state: IAdvancedFilterStore) => {
        pageData.pageDataCrudParameters.page = 1;

        if (pageData.isFirstTimeOpening[0]) {
          pageData.pageDataCrudParameters.advancedFilter = {
            target,
            filters: [],
            page: pageData.pageName,
          };

          if (!_.isEmpty(_.get(state.defaultFilters, pageData.pageName, null))) {
            pageData.pageDataCrudParameters.advancedFilterPage = pageData.pageName;
            pageData.pageDataCrudParameters.advancedFilter.filters = this.advancedFilterService.prepareForOutput(
              state.defaultFilters[pageData.pageName],
            );
          }

          pageData.isFirstTimeOpening[0] = false;
        } else if (event.advancedFilter !== null) {
          pageData.pageDataCrudParameters.advancedFilter = _.get(event, 'advancedFilter', null);
          pageData.pageDataCrudParameters.advancedFilterPage = pageData.pageName;
        }

        pageData.selectedItems = [];
        pageData.pageDataCrudParameters.filters = [
          ...(Array.isArray(event.site) ? [{ field: 'siteId', ids: event.site }] : []),
          ...(Array.isArray(event.line) ? [{ field: 'lineId', ids: event.line }] : []),
        ];

        additionalOperations();
      });
  }

  /**
   * @deprecated This method will be deleted with future versions, please use
   * {@link GenericHelperService.selectOrUnselectAllCheckboxes()} instead!
   */
  public selectOrUnselectAllCheckboxes(
    isSelectAll: boolean,
    elementRef: ElementRef,
    componentName: string,
    pageData: { id: number }[],
  ): void {
    if (elementRef.nativeElement.querySelector(`[id^="${componentName}-checkbox-"]`) !== null) {
      pageData.forEach((item) => {
        const checkbox = _.head(
          document.getElementById(`${componentName}-checkbox-${item.id}`)?.getElementsByTagName('input'),
        );

        if (!checkbox) {
          return;
        }

        if ((isSelectAll && !checkbox?.checked) || (!isSelectAll && checkbox?.checked)) {
          checkbox?.click();
        }
      });
    }
  }

  /**
   * @deprecated This method will be deleted with future versions, please use
   * {@link DataTableHelperService.onDatatableRowClicked()} instead!
   */
  public onDatatableRowClicked($event, componentName: string): void {
    const { event, item } = $event;
    const index = Array.from(event.target.parentElement.children).indexOf(event.target);

    if (
      index !== 0 ||
      event.target.classList.contains('mat-checkbox-inner-container') ||
      event.target.tagName !== 'TD' ||
      HelperService.preventDatatableRowClick(event)
    ) {
      return;
    }

    _.head(document.getElementById(`${componentName}-checkbox-${item.id}`)?.getElementsByTagName('input'))?.click();
  }

  private static preventDatatableRowClick(event): boolean {
    let tagName = event.target.tagName;
    let element = event.target;
    let prevent = false;

    while (tagName !== 'TD' && element) {
      tagName = element.parentElement?.tagName;
      element = element.parentElement;

      if (element?.classList.contains('prevent-datatable-row-click')) {
        prevent = true;
        break;
      }
    }

    if (element.firstElementChild?.classList?.contains('prevent-datatable-row-click')) {
      prevent = true;
    }

    return prevent;
  }

  /**
   * @deprecated This method will be deleted with future versions, please use
   * {@link DataTableHelperService.onBulkEditCheckboxChange()} instead!
   */
  public onBulkEditCheckboxChange(
    inputParameters: {
      value: boolean;
      index: string;
      formComponentInstance: ScwMatInput;
      defaultValue?: any;
    },
    inputFormParameters: {
      form: any;
      rules: ScwMatRulesInterface;
      areAnyOfTheBulkEditFormSelectBoxesSelected: string;
      isBulk: boolean;
      dropdownOptions: { [dropdownName: string]: DropdownOptionInterface[] };
    },
    dependentInputParameters?: {
      index: string;
      formComponentInstance: ScwMatInput;
    }[],
  ): void {
    if (inputParameters.value) {
      _.set(
        inputFormParameters.form,
        `${inputParameters.index}.rules`,
        _.get(inputFormParameters.rules, inputParameters.index),
      );

      if (inputParameters.defaultValue !== undefined) {
        _.set(inputFormParameters.form, `${inputParameters.index}.value`, inputParameters.defaultValue);
      }
    } else {
      inputParameters.formComponentInstance.reset();
      _.set(inputFormParameters.form, `${inputParameters.index}.rules`, []);
      _.set(
        inputFormParameters.form,
        `${inputParameters.index}.value`,
        Array.isArray(_.get(inputFormParameters.form, `${inputParameters.index}.value`)) ? [] : null,
      );

      for (const dependentInput of dependentInputParameters ?? []) {
        dependentInput.formComponentInstance.reset();
        _.set(inputFormParameters.form, `${dependentInput.index}.rules`, []);
        _.set(
          inputFormParameters.form,
          `${dependentInput.index}.value`,
          Array.isArray(_.get(inputFormParameters.form, `${dependentInput.index}.value`)) ? [] : null,
        );
        _.set(inputFormParameters.form, `${dependentInput.index}.isEnabled`, false);
        _.set(inputFormParameters.dropdownOptions, dependentInput.index, []);
      }
    }

    inputFormParameters.areAnyOfTheBulkEditFormSelectBoxesSelected = _.findKey(inputFormParameters.form, (item) => {
      return item.isEnabled;
    });
  }

  /**
   * @deprecated This method will be deleted with future versions, please use
   * {@link FormHelperService.getRequiredFormRule()} instead!
   */
  public getRequiredFormRule(): ScwMatSelectRule {
    return {
      required: true,
      message: this.translate.instant('scwMatForm.validation.required'),
    };
  }

  /**
   * @deprecated This method will be deleted with future versions, please use
   * {@link FormHelperService.getMaxLengthFormRule()} instead!
   */
  public getMaxLengthFormRule(maxLength: number): ScwMatMaxLengthRule {
    return {
      maxLength,
      message: this.translate.instant('scwMatForm.validation.maxLength', { maxLength }),
    };
  }

  /**
   * @deprecated This method will be deleted with future versions, please use
   * {@link FormHelperService.getMinValueFormRule()} instead!
   */
  public getMinValueFormRule(minValue: number): ScwMatMinValueRule {
    return {
      minValue,
      message: this.translate.instant('scwMatForm.validation.minValue', { minValue }),
    };
  }

  /**
   * @deprecated This method will be deleted with future versions, please use
   * {@link FormHelperService.getMaxValueFormRule()} instead!
   */
  public getMaxValueFormRule(maxValue: number): ScwMatMaxValueRule {
    return {
      maxValue,
      message: this.translate.instant('scwMatForm.validation.maxValue', { maxValue }),
    };
  }

  /**
   * @deprecated This method will be deleted with future versions, please use
   * {@link ModalHelperService.multipleModalDismiss()} instead!
   */
  public multipleModalDismiss(modals: NgbModalRef[]): void {
    for (const modal of modals) {
      if (modal) {
        modal.dismiss();
      }
    }
  }

  /**
   * @deprecated This method will be deleted with future versions, please use
   * {@link GenericHelperService.nest()} instead!
   */
  public nest<T>(seq: unknown, keys: NonEmptyArray<string>): T {
    if (!keys.length) {
      return seq as T;
    }

    const first: string = keys[0];
    const rest: string[] = keys.slice(1);
    return _.mapValues(_.groupBy(seq, first), (value) => {
      return this.nest(value, rest as NonEmptyArray<string>);
    });
  }

  /**
   * @deprecated This method will be deleted with future versions, please use
   * {@link FilterHelperService.formatDropdownFilterOptionOutput()} instead!
   */
  public static formatDropdownFilterOptionOutput<T>(output: T[] | T): -1 | T[] | T {
    return (output?.[0] ?? -1) === -1 ? -1 : output;
  }

  /**
   * @deprecated This method will be deleted with future versions, please use
   * {@link ToastHelperService.showGenericChangesSavedSuccessfullyToastMessage()} instead!
   */
  public showGenericChangesSavedSuccessfullyToastMessage(message: string = this.changesSavedSuccessfullyMessage): void {
    this.showToastMessage(true, undefined, message);
  }

  /**
   * @deprecated This method will be deleted with future versions, please use
   * {@link HTTPHelperService.getSuccessAndFailedFromBulkResponse()} instead!
   */
  public static getSuccessAndFailedFromBulkResponse<T>(response: T[]): { fail: T[]; success: T[] } {
    return HelperService.cloneDeep(response).reduce(
      (filtered: { fail: T[]; success: T[] }, data) => {
        filtered[_.get(data, 'errorMessages') ? 'fail' : 'success'].push(data);

        return filtered;
      },
      { fail: [], success: [] },
    );
  }

  /**
   * @deprecated This method will be deleted with future versions, please use
   * {@link DateHelperService.getDefaultRanges()} instead!
   */
  public getDefaultRanges(range: DateRanges): DateRanges {
    const temporaryRanges: DateRanges = {};

    Object.entries(_.cloneDeep(range) as DateRanges).forEach(([index, [start, end]]) => {
      const key = this.translate.instant(`dateRangePicker.ranges.${index}`);
      temporaryRanges[key] = [start.startOf('day'), end];
    });

    return temporaryRanges;
  }

  /**
   * @deprecated This method will be deleted with future versions, please use
   * {@link FormHelperService.onApplyClick()} instead!
   */
  public onApplyClick(
    data: { payload: TabRowInterface[] | ITableHeader[]; setAsDefault: boolean },
    defaultHeaderArray: ITableHeader[],
    defaultWidgetArray: WidgetConfigurationInterface<EWidgetType.WIDGET | EWidgetType.KPI_CARD>[],
    insertToFirst: boolean,
    insertToLast: boolean,
    pageStore: keyof OeeAppState,
    componentName: ComponentNamesForUserConfiguration,
    columnKeyPropertyName: string = 'name',
    customSetAsDefault: boolean = false,
    defaultHeaderGroupArray?: ITableHeader[],
  ): {
    headers: ITableHeader[];
    widgets: WidgetConfigurationInterface<EWidgetType.WIDGET | EWidgetType.KPI_CARD>[];
    headerGroups: ITableHeader[];
  } {
    const rows = data.payload;
    let headers = [];
    const widgets = [];
    const headerGroups = [];

    if (insertToFirst) {
      headers = [defaultHeaderArray[0]];
    }

    rows.forEach((item) => {
      if (item && item.selected) {
        if (item.type === PageConfigurationTypes.TABLE) {
          const headerData = defaultHeaderArray.find(
            (value: ITableHeader) => value.value === item[columnKeyPropertyName],
          );

          if (headerData?.value !== null) {
            headers.push({ ...headerData, selected: true });
          }
        } else if (item.type === PageConfigurationTypes.WIDGET) {
          const headerData: WidgetConfigurationInterface<EWidgetType.WIDGET | EWidgetType.KPI_CARD> =
            defaultWidgetArray.find(
              (value: WidgetConfigurationInterface<EWidgetType.WIDGET>) => value.name === item.name,
            );

          widgets.push({ ...headerData, selected: true });
        } else if (item.type === PageConfigurationTypes.HEADER_GROUP) {
          const headerData = defaultHeaderGroupArray.find(
            (headerGroups: ITableHeader) => headerGroups.value === item.value,
          );

          headerGroups.push(headerData);
        }
      }
    });

    if (insertToLast) {
      headers.push(defaultHeaderArray[defaultHeaderArray.length - 1]);
    }

    if (data.setAsDefault && !customSetAsDefault) {
      this.onSetAsDefault(pageStore, componentName, columnKeyPropertyName);
    }

    return { headers, widgets, headerGroups };
  }

  /**
   * @deprecated This method will be deleted with future versions, please use
   * {@link DataTableHelperService.onSetAsDefault()} instead!
   */
  public onSetAsDefault(
    pageStore: keyof OeeAppState,
    componentName: ComponentNamesForUserConfiguration,
    columnKeyPropertyName: string = 'name',
  ): void {
    const dashboardModeAvailablePages = [
      {
        regularName: ComponentNamesForUserConfiguration.WorkOrderSchedule,
        dashboardName: ComponentNamesForUserConfiguration.WorkOrderScheduleDashboardMode,
      },
      {
        regularName: ComponentNamesForUserConfiguration.ProductionReviewRegularMode,
        dashboardName: ComponentNamesForUserConfiguration.ProductionReviewDashboardMode,
      },
      {
        regularName: ComponentNamesForUserConfiguration.KpiViewComponent,
        dashboardName: ComponentNamesForUserConfiguration.KpiViewComponentDashboardMode,
      },
    ];
    const pagesWithGroupedColumns: string[] = [ComponentNamesForUserConfiguration.ShiftSummary];
    let selectedColumns: TabRowInterface[] = [];
    let selectedGroups: TabRowInterface[] = [];
    let customConfiguration: ICustomConfigurationDbEntry[] = [];

    this.store
      .select(pageStore)
      .pipe(take(1))
      .subscribe((state: any) => {
        selectedColumns = state.tableSettings;
        selectedGroups = state.tableGroupSettings;
        customConfiguration = state.customConfiguration ?? [];
      });

    const configuration: IUserConfiguration = {
      [componentName]: [
        {
          name: 'selectedColumns',
          value: selectedColumns.reduce((filtered, column) => {
            if (column.selected) {
              filtered.push({ name: column[columnKeyPropertyName], type: column.type });
            }

            return filtered;
          }, []),
        },
      ],
    };

    if (pagesWithGroupedColumns.includes(componentName)) {
      configuration[componentName].push({
        name: 'selectedGroups',
        value: selectedGroups.reduce((filtered, column) => {
          if (column.selected) {
            filtered.push({ name: column[columnKeyPropertyName], type: column.type });
          }

          return filtered;
        }, []),
      });
    }

    configuration[componentName] = configuration[componentName].concat(customConfiguration);

    for (const page of dashboardModeAvailablePages) {
      if (componentName !== page.regularName && componentName !== page.dashboardName) {
        continue;
      }

      let oldConfiguration: IComponentConfiguration[] = [];
      this.store
        .select('userConfigurationStore')
        .pipe(take(1))
        .subscribe((state: UserConfigurationStateInterface) => {
          oldConfiguration = _.cloneDeep(state.userConfigurationData[page.regularName] ?? []);
          _.remove(oldConfiguration, { name: 'isDashboardModeDefault' });

          if (componentName === page.regularName) {
            oldConfiguration = configuration[page.regularName];
          }
        });

      configuration[page.regularName] = [
        ...oldConfiguration,
        {
          name: 'isDashboardModeDefault',
          value: componentName === page.dashboardName,
        },
      ];

      break;
    }

    this.store.dispatch(new UserConfigurationActions.UpdateUserConfigurationLoading(configuration));
  }

  /**
   * @deprecated This method will be deleted with future versions, please use
   * {@link UserHelperService.setUserConfiguration()} instead!
   */
  public setUserConfiguration(
    stateData,
    defaultHeaderArray: ITableHeader[],
    defaultWidgetArray: WidgetConfigurationInterface<EWidgetType.WIDGET | EWidgetType.KPI_CARD>[],
    insertToFirst: boolean,
    insertToLast: boolean,
    isViewPage: boolean = false,
  ): { headers: ITableHeader[]; widgets: WidgetConfigurationInterface<EWidgetType.WIDGET | EWidgetType.KPI_CARD>[] } {
    const tableSettings$ = stateData;
    const headers = [];
    const widgets = [];

    if (!stateData) {
      defaultHeaderArray.forEach((item) => {
        if (item.selected) {
          headers.push(item);
        }
      });
      defaultWidgetArray.forEach((item) => {
        if (item.selected) {
          widgets.push(item);
        }
      });
    } else if (stateData && !isViewPage) {
      const selectedItems: { name: string; type: string }[] = _.get(
        _.find(tableSettings$, { name: 'selectedColumns' }),
        'value',
        [],
      );

      selectedItems.forEach((item: { name: string; type: string }) => {
        if (item.type === PageConfigurationTypes.TABLE) {
          const headerData: ITableHeader = defaultHeaderArray.find((value: ITableHeader) => value.value === item.name);

          if (headerData !== undefined) {
            headers.push(headerData);
          }
        } else if (item.type === PageConfigurationTypes.WIDGET) {
          const headerData: WidgetConfigurationInterface<EWidgetType.WIDGET> = defaultWidgetArray.find(
            (value: WidgetConfigurationInterface<EWidgetType.WIDGET>) => value.name === item.name,
          );

          if (headerData !== undefined) {
            widgets.push({
              name: headerData.name,
              pageConfigurationTitle: headerData.content.pageConfigurationTitle,
              selected: headerData.selected,
              disabled: headerData.disabled,
              componentName: (headerData.content as IWidget).componentName,
            });
          }
        }
      });

      if (insertToFirst) {
        headers.splice(0, 0, defaultHeaderArray[0]);
      }

      if (insertToLast) {
        headers.splice(defaultHeaderArray.length - 1, 0, defaultHeaderArray[defaultHeaderArray.length - 1]);
      }
    } else if (isViewPage) {
      const selectedTableItems: string[] = _.get(_.find(tableSettings$, { name: 'tableColumns' }), 'value', []);
      const selectedWidgetItems: string[] = _.get(_.find(tableSettings$, { name: 'widgetSettings' }), 'value', []);

      selectedTableItems.forEach((item) => {
        const headerData = defaultHeaderArray.find((value: ITableHeader) => value.value === item);
        if (headerData !== undefined) {
          headers.push(headerData);
        }
      });

      selectedWidgetItems.forEach((item) => {
        const headerData = defaultWidgetArray.find(
          (value: WidgetConfigurationInterface<EWidgetType.WIDGET>) => value.name === item,
        );
        if (headerData !== undefined) {
          widgets.push(headerData);
        }
      });
    }

    return { headers, widgets };
  }

  /**
   * @deprecated This method will be deleted with future versions, please use
   * {@link FormHelperService.emailAllowedDomainMatch()} instead!
   */
  public static emailAllowedDomainMatch(input: string, allowedDomains: IAllowedDomain[]): boolean {
    const emailDomain = input?.split('@')?.[1];

    return emailDomain && allowedDomains.some((domain: IAllowedDomain) => domain.domain === emailDomain);
  }

  /**
   * @deprecated This method will be deleted with future versions, please use
   * {@link FilterHelperService.getMappedFilteredValues()} instead!
   */
  public getMappedFilteredValues(requestModel: IFilterMap): IFilterMapped[] {
    if (!requestModel) {
      return null;
    }

    const mappedValues: IFilterMapped[] = [];
    this.store
      .select(requestModel.storeName)
      .pipe(take(1))
      .subscribe((state: any) => {
        if (!state) {
          return;
        }
        let filter = [];
        const reqIds: number[] | number = requestModel.filteredDataIds as number | number[];

        if (reqIds === -1 || reqIds === undefined) {
          filter = state.data;
        } else {
          filter = state.data.filter((state: string | number) =>
            reqIds.toString().includes(state[requestModel.filterPrefix]),
          );
        }
        filter.map((prop) => {
          mappedValues.push({
            value: prop.hasOwnProperty(requestModel.expectedProperty) ? prop[requestModel.expectedProperty] : null,
            id: prop.hasOwnProperty('id') ? prop['id'] : null,
          });
        });
      });
    return mappedValues;
  }

  /**
   * @deprecated This method will be deleted with future versions, please use
   * {@link ToastHelperService.showWaitMessage()} instead!
   */
  public showWaitMessage(): void {
    this.toast.info(this.translate.instant('general.pleaseWaitForLongProcess'), '', {
      closeButton: false,
      progressBar: false,
      positionClass: 'toast-bottom-right',
    });
  }

  /**
   * @deprecated This method will be deleted with future versions, please use
   * {@link GenericHelperService.formatNumber()} instead!
   */
  public formatNumber(value: number): number | string {
    if (_.isNil(value) || typeof value !== 'number') {
      return null;
    }

    const formatter = new Intl.NumberFormat('en-US', {
      style: 'decimal',
      maximumFractionDigits: 20,
    });

    const [intPart, decimalPart] = formatter.format(value).split('.');
    return [intPart.replace(/[,]/g, this.thousandSeparator), ...(decimalPart ? [decimalPart] : [])].join(
      this.decimalSeparator,
    );
  }

  /**
   * @deprecated This method will be deleted with future versions, please use
   * {@link HTTPHelperService.createHttpParamsWithObject()} instead!
   */
  public static createHttpParamsWithObject(parameters: IGenericObject<any>, arrayJoinSeparator = ','): HttpParams {
    let httpParams: HttpParams = new HttpParams();

    if (typeof parameters === 'object' && !_.isNil(parameters)) {
      for (const [key, value] of Object.entries(parameters)) {
        httpParams = httpParams.append(
          key,
          Array.isArray(value) ? value.join(arrayJoinSeparator) : (value as string | number | boolean),
        );
      }
    }

    return httpParams;
  }

  /**
   * @deprecated This method will be deleted with future versions, please use
   * {@link LineHelperService.lineDropdownOptionCompareFunction()} instead!
   */
  public lineDropdownOptionCompareFunction(
    line1: { name?: string; title?: string; departmentName: string },
    line2: { name?: string; title?: string; departmentName: string },
  ): number {
    return `${line1.departmentName}${line1.name ?? line1.title}`.localeCompare(
      `${line2.departmentName}${line2.name ?? line2.title}`,
      undefined,
      {
        numeric: true,
        sensitivity: 'base',
      },
    );
  }

  /**
   * @deprecated This method will be deleted with future versions, please use
   * {@link GenericHelperService.dropdownOptionCompareFunction()} instead!
   */
  public dropdownOptionCompareFunction(
    item1: { name?: string; title?: string },
    item2: { name?: string; title?: string },
  ): number {
    return `${item1.name ?? item1.title}`.localeCompare(`${item2.name ?? item2.title}`, undefined, {
      numeric: true,
      sensitivity: 'base',
    });
  }

  /**
   * @deprecated This method will be deleted with future versions, please use
   * {@link FormHelperService.getIsNotBlankFormRule()} instead!
   */
  public getIsNotBlankFormRule(): ScwMatSelectRule {
    return {
      custom: true,
      message: this.translate.instant('general.isNotBlankValidationMessage'),
      validator: (value) => {
        return !_.isEmpty(String(value).trim());
      },
    };
  }

  /**
   * @deprecated This method will be deleted with future versions, please use
   * {@link GenericHelperService.numberToBooleanString()} instead!
   */
  public static numberToBooleanString(value: string | number): string {
    return String(Boolean(Number(value)));
  }

  /**
   * Lodash _.get function with stricter truthiness checking.
   *
   * @deprecated This method will be deleted with future versions, please use
   * {@link GenericHelperService.strictGet()} instead!
   */
  public strictGet(
    object: unknown,
    path: string,
    defaultValue: unknown,
    transformer: (value: unknown) => unknown = (value: unknown) => value,
  ): unknown {
    const result: unknown = _.get(object, path, defaultValue);

    if (!_.isNumber(result) && _.isEmpty(result)) {
      return defaultValue;
    }

    return transformer(result);
  }

  /**
   * @deprecated This method will be deleted with future versions, please use
   * {@link DateHelperService.getDateRangesWithTimezone()} instead!
   */
  public getDateRangesWithTimezone(dateRangeOptions: (keyof DateRanges)[]): DateRanges {
    const allRanges: DateRanges = {
      today: [moment().tz(this.timezone$).startOf('day'), moment().tz(this.timezone$)],
      todayWhole: [moment().tz(this.timezone$).startOf('day'), moment().tz(this.timezone$).endOf('day')],
      yesterday: [
        moment().tz(this.timezone$).subtract(1, 'days').startOf('day'),
        moment().tz(this.timezone$).subtract(1, 'days').endOf('day'),
      ],
      thisWeek: [moment().tz(this.timezone$).startOf('week'), moment().tz(this.timezone$).endOf('week')],
      lastWeek: [
        moment().tz(this.timezone$).subtract(1, 'weeks').startOf('week'),
        moment().tz(this.timezone$).subtract(1, 'weeks').endOf('week'),
      ],
      nextSevenDays: [moment().tz(this.timezone$), moment().tz(this.timezone$).add(7, 'day')],
      thisMonth: [moment().tz(this.timezone$).startOf('month'), moment().tz(this.timezone$).endOf('month')],
      lastMonth: [
        moment().tz(this.timezone$).subtract(1, 'month').startOf('month'),
        moment().tz(this.timezone$).subtract(1, 'month').endOf('month'),
      ],
      nextThirtyDays: [moment().tz(this.timezone$), moment().tz(this.timezone$).add(30, 'day')],
      lastThreeMonths: [
        moment().tz(this.timezone$).startOf('month').subtract(3, 'month'),
        moment().tz(this.timezone$).endOf('month').subtract(1, 'month'),
      ],
      thisYear: [moment().tz(this.timezone$).startOf('year'), moment().tz(this.timezone$).endOf('year')],
      lastYear: [
        moment().tz(this.timezone$).subtract(1, 'year').startOf('year'),
        moment().tz(this.timezone$).subtract(1, 'year').endOf('year'),
      ],
    };

    return Object.keys(allRanges)
      .filter((range) => dateRangeOptions.includes(range as keyof DateRanges))
      .reduce((obj, range) => {
        return {
          ...obj,
          [range]: allRanges[range],
        };
      }, {});
  }

  /**
   * @deprecated This method will be deleted with future versions, please use
   * {@link FilterHelperService.getFiltersFromFilterCardEvent()} instead!
   */
  public getFiltersFromFilterCardEvent($event): GenericCrudRequestConstructionParameterFilters[] {
    return _.toPairs($event).reduce(
      (dropdownFilters: GenericCrudRequestConstructionParameterFilters[], [key, value]) => {
        if (!_.isNil(key) && !_.isNil(value) && !_.isNil(_.fromPairs([key, value])) && Array.isArray(value)) {
          const filter: GenericCrudRequestConstructionParameterFilters = {
            field: key,
            ids: value,
          };
          dropdownFilters.push(filter);
        }

        return dropdownFilters;
      },
      [],
    );
  }

  /**
   * @deprecated This method will be deleted with future versions, please use
   * {@link DataTableHelperService.onChangeTableCheckBox()} instead!
   */
  public onChangeTableCheckBox<T, F>(event: MatCheckboxChange, items: number[], pageData: T[]): F {
    const inputValue: number = Number(event?.source?.value);

    if (event.checked) {
      items.push(inputValue);
    } else {
      items.splice(items.indexOf(inputValue), 1);
    }

    return _.find(pageData, { id: items[0] });
  }

  /**
   * @deprecated This method will be deleted with future versions, please use
   * {@link FilterHelperService.filterChangeOperationsWithoutAdvancedFilter()} instead!
   */
  public filterChangeOperationsWithoutAdvancedFilter(
    event: any,
    pageData: {
      pageDataCrudParameters: GenericCrudRequestConstructionParameters;
      pageName: string;
      selectedItems: unknown[];
    },
  ): void {
    pageData.pageDataCrudParameters.page = 1;
    pageData.selectedItems = [];
    pageData.pageDataCrudParameters.filters = this.getFiltersFromFilterCardEvent(event);
  }

  /**
   * @deprecated This method will be deleted with future versions, please use
   * {@link FormHelperService.getScwMatFormValuesWithDataPropertyKey()} instead!
   */
  public static getScwMatFormValuesWithDataPropertyKey(form: ScwMatFormComponent): { [p: string]: any } {
    const data: { [key: string]: any } = {};

    form.inputs?.forEach((input) => {
      data[input.dataPropertyKey] =
        _.isEmpty(input.inputModel) && typeof input.inputModel !== 'number'
          ? null
          : input.type === 'number' && !_.isNaN(Number(input.inputModel))
          ? Number(input.inputModel)
          : input.inputModel;
    });
    form.selects?.forEach((select) => {
      data[select.dataPropertyKey] = _.isEmpty(select.inputModel)
        ? null
        : select.singleSelection
        ? _.first(select.inputModel)[select.primaryKey]
        : select.inputModel?.map((item: any) => item[select.primaryKey]);
    });
    form.cronInputs?.forEach((input) => {
      data[input.dataPropertyKey] = _.isEmpty(input.inputModel) ? null : input.inputModel;
    });

    return data;
  }

  /**
   * @deprecated This method will be deleted with future versions, please use
   * {@link KPICardHelperService.kpiCardMinuteToHour()} instead!
   */
  public kpiCardMinuteToHour(minute: number | string): string {
    let decimalScaleLimit: number = KPI_METRICS_DECIMAL_COUNT;

    this.store
      .select('user')
      .pipe(take(1))
      .subscribe((user) => {
        decimalScaleLimit = user.siteDecimalScaleLimit;
      });

    const input: number = typeof minute === 'string' ? this.decimalHelper.formattedNumberToNumber(minute) : minute;

    return this.decimalHelper.toFixedValue(String(input * 60), decimalScaleLimit);
  }

  /**
   * @deprecated This method will be deleted with future versions, please use
   * {@link GenericHelperService.sumByProperties()} instead!
   */
  public static sumByProperties<T, Y extends Iterable<string>>(
    collection: T[],
    properties: Y,
    sumAsNumber: boolean = false,
  ): // @ts-ignore
  Record<Y[number], any> {
    // @ts-ignore
    let result = _.zipObject(properties) as Record<Y[number], any>;

    result = (collection ?? []).reduce((total, item) => {
      for (const property of properties) {
        if (sumAsNumber) {
          total[property] = (total[property] ?? 0) + Number(item[property] ?? 0);
          continue;
        }

        total[property] = DecimalHelper.add1(
          _.isNil(total[property]) ? '0' : String(total[property]),
          _.isNil(item[property]) ? '0' : String(item[property]),
        );
      }

      return total;
    }, result);

    return result;
  }

  /**
   * @deprecated This method will be deleted with future versions, please use
   * {@link ActivityHelperService.activityTimerFunction()} instead!
   */
  public activityTimerFunction(start: string): string {
    if (_.isNil(start)) {
      return '--';
    }

    const startTime: any = changeTimezone(start, this.timezone$);

    const now: any = new Date();
    const diff = Math.abs(now - startTime);

    const secNum = diff / 1000;
    const days = Math.floor(secNum / 86400);
    let hours = Math.floor(secNum / 3600);
    const minutes = Math.floor((secNum - hours * 3600) / 60);
    const seconds = Math.floor(secNum - hours * 3600 - minutes * 60);

    if (days > 0) {
      hours = hours - 24 * days;
      return `${days}${this.translate.instant('general.shortDay')}
              ${hours}${this.translate.instant('general.shortHour')}
              ${minutes}${this.translate.instant('general.shortMinute')}
              ${seconds}${this.translate.instant('general.shortSecond')}`;
    }

    if (hours > 0) {
      return `${hours}${this.translate.instant('general.shortHour')}
              ${minutes}${this.translate.instant('general.shortMinute')}
              ${seconds}${this.translate.instant('general.shortSecond')}`;
    }

    if (minutes > 0) {
      return `${minutes}${this.translate.instant('general.shortMinute')}
              ${seconds}${this.translate.instant('general.shortSecond')}`;
    }

    if (seconds > 0) {
      return `${seconds}${this.translate.instant('general.shortSecond')}`;
    }

    if (seconds === 0) {
      return `${diff}${this.translate.instant('general.shortMilliSecond')}`;
    }

    return `${days}${this.translate.instant('general.shortDay')}
            ${hours}${this.translate.instant('general.shortHour')}
            ${minutes}${this.translate.instant('general.shortMinute')}
            ${seconds}${this.translate.instant('general.shortSecond')}`;
  }

  /**
   * @deprecated This method will be deleted with future versions, please use
   * {@link HomeHelperService.isHomeRelatedComponentOpenedOnStation()} instead!
   */
  public isHomeRelatedComponentOpenedOnStation(): boolean {
    return this.router.url.includes('home/station');
  }

  public closeModalsOnMissingIdentifier(identifier: unknown, type: ETableModalTypes, modals: NgbModalRef[]): void {
    if (_.isNil(identifier) && type && type !== ETableModalTypes.add) {
      modals.forEach((modal: NgbModalRef) => modal?.close());
    }
  }

  public getAddModalSiteLineSelection(
    filteredSite: -1 | number[] = [],
    filteredLine: -1 | number[] = [],
    isInactiveControl: boolean = false,
  ): { site: IDefaultSelectItem[]; line: IDefaultSelectItem[] } {
    let sites: SiteCRUDInterface[] = [];
    let lines: LineCRUDInterface[] = [];
    let selectedSiteId: number = null;
    let selectedLineId: number = null;

    this.store
      .select('siteFilter')
      .pipe(take(1))
      .subscribe((state: FilterSiteState) => {
        if (!state.isLoading && state.isLoaded) {
          sites = _.cloneDeep(state.data);
        }
      });

    this.store
      .select('lineFilter')
      .pipe(take(1))
      .subscribe((state: FilterLineState) => {
        if (!state.isLoading && state.isLoaded) {
          lines = _.cloneDeep(state.data);
        }
      });

    const selectAllForOneSite = filteredSite === -1 && sites.length === 1;
    const hasFilterForSingleSite = filteredSite !== -1 && filteredSite.length === 1;
    const noFilterButHasDefaultSite =
      (filteredSite === -1 && this.defaultSiteId$) ||
      (filteredSite !== -1 && !filteredSite.length && this.defaultSiteId$);
    const multipleSiteFilterHasDefaultSite =
      !hasFilterForSingleSite &&
      this.defaultSiteId$ &&
      filteredSite !== -1 &&
      filteredSite.includes(Number(this.defaultSiteId$));

    if (sites.length === 1) {
      selectedSiteId = sites[0].id;
    } else if (selectAllForOneSite || hasFilterForSingleSite) {
      selectedSiteId = filteredSite[0];
    } else if (noFilterButHasDefaultSite || multipleSiteFilterHasDefaultSite) {
      selectedSiteId = Number(this.defaultSiteId$);
    }

    lines = lines.filter((line: LineCRUDInterface) =>
      isInactiveControl ? line.siteId === selectedSiteId && line.statusId === 1 : line.siteId === selectedSiteId,
    );

    const selectAllForOneLine = filteredLine === -1 && lines.length === 1;
    const hasFilterForSingleLine = filteredLine !== -1 && filteredLine.length === 1;

    if (selectedSiteId && lines.length === 1) {
      selectedLineId = lines[0].id;
    } else if (selectedSiteId && (selectAllForOneLine || hasFilterForSingleLine)) {
      selectedLineId = filteredLine[0];
    }

    return {
      site: HelperService.getFirstOption([_.find(sites, { id: selectedSiteId })], '0.name'),
      line: HelperService.getFirstOption([_.find(lines, { id: selectedLineId })], '0.title'),
    };
  }

  private static getFirstOption(
    data: SiteCRUDInterface[] | LineCRUDInterface[],
    parameterName: string,
  ): IDefaultSelectItem[] {
    if (data[0] === undefined) {
      return [];
    }

    return [
      {
        id: _.get(data, '0.id', null),
        name: _.get(data, parameterName, null),
      },
    ];
  }

  public static emptyAction = of(new AppActions.EmptyAction());

  public static cloneDeep<T>(item: T): T {
    try {
      return _.cloneDeep<T>(item);
    } catch {
      return JSON.parse(JSON.stringify(item)) as T;
    }
  }

  public static argumentClone<T extends unknown[]>(...arg: T): T {
    return HelperService.cloneDeep(Array.from(arg)) as T;
  }

  public getNumberFormatDropdownItems(): IConstantLookUp[] {
    return [
      { id: ESeparatorCombination.COMMA_DOT, name: ENumberFormatOption.COMMA_DOT },
      { id: ESeparatorCombination.DOT_COMMA, name: ENumberFormatOption.DOT_COMMA },
      { id: ESeparatorCombination.SPACE_COMMA, name: ENumberFormatOption.SPACE_COMMA },
      { id: ESeparatorCombination.SPACE_DOT, name: ENumberFormatOption.SPACE_DOT },
    ];
  }

  public getSeparatorCombination(
    decimalSeparator: TDecimalSeparator,
    thousandSeparator: TThousandSeparator,
  ): ESeparatorCombination {
    if (thousandSeparator === ',' && decimalSeparator === '.') {
      return ESeparatorCombination.COMMA_DOT;
    }

    if (thousandSeparator === '.' && decimalSeparator === ',') {
      return ESeparatorCombination.DOT_COMMA;
    }

    if (thousandSeparator === ' ' && decimalSeparator === ',') {
      return ESeparatorCombination.SPACE_COMMA;
    }

    if (thousandSeparator === ' ' && decimalSeparator === '.') {
      return ESeparatorCombination.SPACE_DOT;
    }

    return ESeparatorCombination.COMMA_DOT;
  }

  public getDecimalAndThousandSeparator(seperatorCombination: ESeparatorCombination): ISeparators {
    switch (seperatorCombination) {
      case ESeparatorCombination.COMMA_DOT:
        return { thousandSeparator: ',', decimalSeparator: '.' };
      case ESeparatorCombination.DOT_COMMA:
        return { thousandSeparator: '.', decimalSeparator: ',' };
      case ESeparatorCombination.SPACE_COMMA:
        return { thousandSeparator: ' ', decimalSeparator: ',' };
      case ESeparatorCombination.SPACE_DOT:
        return { thousandSeparator: ' ', decimalSeparator: '.' };
      default:
        return { thousandSeparator: undefined, decimalSeparator: undefined };
    }
  }

  public static mapThousandSeparator(thousandSeparator: TThousandSeparator): TThousandSeparator {
    switch (thousandSeparator) {
      case '.':
        return '.';
      case ',':
        return ',';
      case ' ':
        return '\u00a0';
      default:
        return ',';
    }
  }

  public getDayOfYear(dateString: string): number {
    const date = new Date(dateString);
    const timestamp1 = Date.UTC(date.getFullYear(), date.getMonth(), date.getDate());
    const timestamp2 = Date.UTC(date.getFullYear(), 0, 0);

    const differenceInMilliseconds = timestamp1 - timestamp2;

    const differenceInDays = differenceInMilliseconds / 1000 / 60 / 60 / 24;

    return differenceInDays;
  }

  public static setDefaultMomentLocaleDayOfWeek(locale: string): void {
    moment.updateLocale(locale, {
      week: {
        dow: 1,
        doy: 7,
      },
    });
  }

  public static setCustomMomentLocaleDayOfWeek(locale: string, dayOfWeek: number): void {
    moment.updateLocale(locale, {
      week: {
        dow: dayOfWeek ?? 1,
        doy: 7,
      },
    });
  }

  public getAuthorizedSitesAndLines(
    userInfo: User,
    filter: IActivityHistoryRequestParameter,
  ): [siteIds: DropdownType, lineIds: DropdownType] {
    const authorizedSites: number[] =
      userInfo.authorizedSites !== '*' ? userInfo.authorizedSites.split(',').map(Number) : [];
    const authorizedLines: number[] =
      userInfo.authorizedLines !== '*' ? userInfo.authorizedLines.split(',').map(Number) : [];
    const siteIds: -1 | number[] =
      filter.siteIds === -1 && authorizedSites.length > 0 ? authorizedSites : filter.siteIds;
    const lineIds: -1 | number[] =
      filter.lineIds === -1 && authorizedLines.length > 0 ? authorizedLines : filter.lineIds;

    return [siteIds, lineIds];
  }

  public findClass(value: string): { color: string; backgroundColor: string } {
    if (this.decimalHelper.isGreaterOrEqThan(value, '80')) {
      return { color: 'c-green', backgroundColor: 'bg-c-green' };
    }

    if (this.decimalHelper.isGreaterThan(value, '20')) {
      return { color: 'c-yellow', backgroundColor: 'bg-c-yellow' };
    }

    return { color: 'c-red', backgroundColor: 'bg-c-red' };
  }

  public getBarColor(data: string, target: string): string {
    if (this.decimalHelper.isEqual(data, '0')) {
      return '#AEAEAE';
    }

    if (_.isNil(target)) {
      return '#757575';
    }

    const eightyPercentOfTheTarget: string = this.decimalHelper.divide(
      this.decimalHelper.multiply('80', target),
      '100',
    );

    const twentyPercentOfTheTarget: string = this.decimalHelper.divide(
      this.decimalHelper.multiply('20', target),
      '100',
    );

    if (this.decimalHelper.isGreaterOrEqThan(data, eightyPercentOfTheTarget)) {
      return '#6FC764';
    }

    if (this.decimalHelper.isLessOrEqThan(data, twentyPercentOfTheTarget)) {
      return '#FF726B';
    }

    if (
      this.decimalHelper.isGreaterThan(data, twentyPercentOfTheTarget) &&
      this.decimalHelper.isLessThan(data, eightyPercentOfTheTarget)
    ) {
      return '#FFD600';
    }

    return '#AEAEAE';
  }

  public adjustTimeSpan(direction: boolean, startDate: Date, endDate: Date): ScwMatDatepickerReturnInterface {
    const hoursInMilliseconds: number = 60 * 60 * 1000;

    if (!direction) {
      const newStartDate: moment.Moment = moment(startDate).clone().add(hoursInMilliseconds);
      const newEndDate: moment.Moment = moment(endDate).clone().add(hoursInMilliseconds);
      return {
        startDate: newStartDate,
        endDate: newEndDate,
      };
    } else {
      const newStartDate: moment.Moment = moment(startDate).clone().subtract(hoursInMilliseconds);
      const newEndDate: moment.Moment = moment(endDate).clone().subtract(hoursInMilliseconds);
      return {
        startDate: newStartDate,
        endDate: newEndDate,
      };
    }
  }

  public updateDateRangeMaxDate(dateRanges: DateRanges, maxDate: moment.Moment): DateRanges {
    const datesToUpdate: (keyof DateRanges)[] = ['thisWeek', 'thisMonth', 'thisYear'];

    for (const dateToUpdate of datesToUpdate) {
      if (dateToUpdate in dateRanges) {
        dateRanges[dateToUpdate] = [dateRanges[dateToUpdate][0], maxDate];
      }
    }

    return dateRanges;
  }

  public getDateRangeNameFromDates(
    dateRangeOptions: (keyof DateRanges)[],
    startDate: moment.Moment,
    endDate: moment.Moment,
    weekStartDay: number,
    maxDate?: moment.Moment,
  ): keyof DateRanges {
    HelperService.setCustomMomentLocaleDayOfWeek(this.userLocale$, weekStartDay);

    let dateRanges: DateRanges = this.getDateRangesWithTimezone(dateRangeOptions);

    if (maxDate) {
      dateRanges = this.updateDateRangeMaxDate(dateRanges, maxDate);
    }

    HelperService.setDefaultMomentLocaleDayOfWeek(this.userLocale$);

    for (const rangeName in dateRanges) {
      const [start, end] = dateRanges[rangeName];

      if (start.isSame(startDate, 'day') && end.isSame(endDate, 'day')) {
        return rangeName as keyof DateRanges;
      }
    }

    return 'custom';
  }

  public showWarningMessage(message: string, title: string = ''): void {
    this.toast.warning(message, title, {
      closeButton: true,
      progressBar: true,
      positionClass: 'toast-bottom-right',
    });
  }
}
