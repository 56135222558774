import { Inject, Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import * as _ from 'lodash';
import { catchError, map, switchMap, withLatestFrom } from 'rxjs/operators';
import { SiteCRUDInterface } from 'src/app/shared/component/filter/filter.class';
import { BaseOneResponseInterface } from 'src/app/shared/model/interface/crud-response-interface.model';
import { SitesService } from 'src/app/shared/service/settings/sites/sites.service';
import { emptyAction } from 'src/constants';
import { selectUserDefaultSiteId } from './user.selectors';
import { of } from 'rxjs';
import * as ObjectActions from './actions';
import { HttpClient } from '@angular/common/http';
import { Store } from '@ngrx/store';
import * as AppActions from '../app/actions';
import { OeeAppState } from '../oee.reducer';
import * as HomeActions from '../home/home.actions';
import {
  GetFeedTokenInterface,
  NotificationList,
  NotificationService,
} from '../../shared/service/notification/notification.service';
import * as ActivityButtonActions from '../activity-button/activity-button.actions';
import { UserService } from '../../shared/service/user/user.service';
import { GetCurrentUserResponseInterface } from '../app/model';
import { User } from './model';

@Injectable()
export class UserEffects {
  getFirstWeekday$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ObjectActions.GET_FIRST_WEEKDAY),
      withLatestFrom(this.store.select(selectUserDefaultSiteId)),
      switchMap(([, siteId]: [unknown, number | undefined]) =>
        !_.isNil(siteId)
          ? this.siteService.getSite(siteId).pipe(
              switchMap((response: BaseOneResponseInterface<SiteCRUDInterface>) =>
                of(new ObjectActions.GetFirstWeekdaySuccess(response.data.weekStartDay)),
              ),
              catchError(() => emptyAction),
            )
          : of(new ObjectActions.GetFirstWeekdaySuccess(undefined)),
      ),
    ),
  );

  constructor(
    private actions$: Actions,
    private http: HttpClient,
    private readonly siteService: SitesService,
    private store: Store<OeeAppState>,
    public notificationService: NotificationService,
    public userService: UserService,
    @Inject('API_BASE_URL') private baseUrl: string,
  ) {}

  private APP_URLS = {
    GET: {
      GET_CURRENT_USER: 'users/get-current-user',
    },
  };

  loadSiteLineSelections$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ObjectActions.UPDATE_CURRENT_USER),
      switchMap((objectData: ObjectActions.UpdateCurrentUser) => {
        this.store.dispatch(new AppActions.ShowLoader());

        return this.http
          .get<GetCurrentUserResponseInterface>(
            `${this.baseUrl}/${this.APP_URLS.GET.GET_CURRENT_USER}`,
            objectData.selectedSiteId && objectData.selectedLineId
              ? { params: { siteId: objectData.selectedSiteId, lineId: objectData.selectedLineId } }
              : {},
          )
          .pipe(
            switchMap((response) => {
              localStorage.setItem(
                'selectedSiteLine',
                JSON.stringify({
                  siteId: JSON.stringify(objectData.selectedSiteId),
                  lineId: JSON.stringify(objectData.selectedLineId),
                }),
              );

              const userState: User = {
                isLaborTrackerActive: response.data.selectedSite.isLaborTrackerActive,
                isAssetTrackerActive: response.data.selectedSite.isAssetTrackerActive,
                isDigitalFormsActive: response.data.selectedSite.isDigitalFormsActive,
                isStationTrackerActive: response.data.selectedSite.isStationTrackerActive,
                isOeeTrackerActive: response.data.selectedSite.isOeeTrackerActive,
                siteId: response.data.selectedSite.siteId,
                siteName: response.data.selectedSite.siteName,
                lineId: response.data.selectedLine.lineId,
                lineName: response.data.selectedLine.lineName,
                authorizedSites: response.data.authorizedSites,
                authorizedLines: response.data.authorizedLines,
                decimalScaleLimit: response.data.decimalScaleLimit,
                siteDecimalScaleLimit:
                  response.data.selectedSite?.siteDecimalScaleLimit || response.data.decimalScaleLimit,
                logbookAccessUrl: response.data.client.logbookAccessUrl,
                logbookAccess: response.data.client.logbookAccess,
                weekStartDay: response.data.selectedSite.weekStartDay,
                appNotificationAccessUrl: response.data?.client?.appNotificationAccessUrl,
                level: response.data.level,
                inactivateUserWithoutValidation: response.data?.client?.inactivateUserWithoutValidation,
                oeeCalculateType: response.data?.client?.oeeCalculateType,
                accountType: response.data?.accountType,
                assignedHexboxId: response.data?.assignedHexboxId,
                isIdpLoginEnforcedEmailControlActive: response.data?.client?.isIdpLoginEnforcedEmailControlActive,
              };

              const actions = [
                new ObjectActions.CurrentUserLoading(),
                new ObjectActions.CurrentUserDataLoaded(userState),
                new ObjectActions.CurrentUserLoaded(),
                new ObjectActions.UpdateCurrentUserLoaded(Boolean(objectData.navigateToHomeAfterwards)),
                new ObjectActions.ClearUserLoadStatus(),
                objectData.hideLoader ? new AppActions.HideLoader() : new AppActions.ShowLoader(),
              ];

              return of(...actions);
            }),
            catchError((error) => {
              return of(new ObjectActions.FetchError(error));
            }),
          );
      }),
    ),
  );

  getUserFeedToken$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ObjectActions.GET_USER_FEED_TOKEN),
      switchMap(() => {
        return this.notificationService.getFeedToken().pipe(
          switchMap((response: GetFeedTokenInterface) => {
            return of(new ObjectActions.GetUserFeedTokenLoaded(response));
          }),
          catchError((errorRes) => {
            return of(new ObjectActions.FetchError(errorRes));
          }),
        );
      }),
    ),
  );

  getUserNotifications = createEffect(() =>
    this.actions$.pipe(
      ofType(ObjectActions.GET_NOTIFICATION_LIST),
      switchMap((objectData: ObjectActions.GetNotificationList) => {
        return this.notificationService.getNotifications(objectData.justCount, objectData.nextId).pipe(
          switchMap((response: NotificationList) => {
            const toBeReturnedAction = [];

            if (objectData.justCount) {
              toBeReturnedAction.push(new ObjectActions.GetNotificationListWithLimitZeroLoaded(response));
            } else {
              toBeReturnedAction.push(new ObjectActions.GetNotificationListLoaded(response));
            }

            return of(...toBeReturnedAction);
          }),
          catchError((errorRes) => {
            return of(new ObjectActions.FetchError(errorRes));
          }),
        );
      }),
    ),
  );

  fetchError$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ObjectActions.FETCH_ERROR),
      map(() => {
        return new AppActions.HideLoader();
      }),
    ),
  );
}
