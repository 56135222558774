import * as Actions from './layout-plan-view.actions';
import { ILayoutPlanViewState } from './layout-plan-view.model';

export const layoutPlanViewState: ILayoutPlanViewState = {
  floorPlanLoading: false,
  floorPlanLoaded: false,
  floorPlan: null,
  jobOeeLoading: false,
  jobOeeLoaded: false,
  jobOee: null,
  jobCount: null,
  workOrdersLoading: false,
  workOrdersLoaded: false,
  workOrders: [],
  floorPlanOeeTargetsLoading: false,
  floorPlanOeeTargetsLoaded: false,
  floorPlanOeeTargets: [],
};

export function layoutPlanViewReducer(
  state: ILayoutPlanViewState = layoutPlanViewState,
  action: Actions.LayoutPlanViewActions,
): ILayoutPlanViewState {
  switch (action.type) {
    case Actions.FLOOR_PLAN_LOADING:
      return {
        ...state,
        floorPlanLoading: true,
        floorPlanLoaded: false,
      };
    case Actions.FLOOR_PLAN_LOADED:
      return {
        ...state,
        floorPlan: action.payload,
        floorPlanLoading: false,
        floorPlanLoaded: true,
      };
    case Actions.JOB_OEE_LOADING:
      return {
        ...state,
        jobOeeLoading: true,
        jobOeeLoaded: false,
      };
    case Actions.JOB_OEE_LOADED:
      return {
        ...state,
        jobOee: action.payload.data.jobOee,
        jobCount: action.payload.data.jobCountOee,
        jobOeeLoading: false,
        jobOeeLoaded: true,
      };
    case Actions.WORK_ORDERS_LOADING:
      return {
        ...state,
        workOrdersLoading: true,
        workOrdersLoaded: false,
      };
    case Actions.WORK_ORDERS_LOADED:
      return {
        ...state,
        workOrdersLoading: false,
        workOrdersLoaded: true,
        workOrders: action.payload,
      };
    case Actions.FLOOR_PLAN_OEE_TARGETS_LOADING:
      return {
        ...state,
        floorPlanOeeTargetsLoading: true,
        floorPlanOeeTargetsLoaded: false,
      };
    case Actions.FLOOR_PLAN_OEE_TARGETS_LOADED:
      return {
        ...state,
        floorPlanOeeTargets: action.payload,
        floorPlanOeeTargetsLoading: false,
        floorPlanOeeTargetsLoaded: true,
      };
    default:
      return state;
  }
}
