import { SchedulerScenarioActions, SchedulerScenarioActionTypes } from './scheduler-scenario.actions';
import { ScenariosStateInterface } from './scheduler-scenario.model';

export const initialState: ScenariosStateInterface = {
  scenarios: null,
  scenariosLoaded: false,
  scenariosLoading: false,
  createScenarioLoading: false,
  createScenarioLoaded: false,
  createScenario: null,
  updateScenarioLoading: false,
  updateScenarioLoaded: false,
  updateScenario: null,
  cloneScenarioLoading: false,
  cloneScenarioLoaded: false,
  cloneScenario: null,
  deleteScenarioLoading: false,
  deleteScenarioLoaded: false,
  deletedScenario: null,
  scenariosSiteDataLoading: false,
  scenariosSiteDataLoaded: false,
  scenariosSiteData: null,
  bulkEditScenarioLoading: false,
  bulkEditScenarioLoaded: false,
  bulkEditScenario: null,
  usersLoaded: false,
  usersLoading: false,
  userData: null,
  resourceSettingsLoading: false,
  resourceSettingsLoaded: false,
  resourceSettingData: null,
  tableSettings: [],
};

export function schedulerScenarioReducer(
  state: ScenariosStateInterface = initialState,
  action: SchedulerScenarioActions,
): ScenariosStateInterface {
  switch (action.type) {
    case SchedulerScenarioActionTypes.LoadSchedulerScenarios:
      return {
        ...state,
        scenariosLoading: true,
        scenariosLoaded: false,
      };

    case SchedulerScenarioActionTypes.SchedulerScenariosLoaded:
      return {
        ...state,
        scenariosLoading: false,
        scenariosLoaded: true,
        scenarios: action.payload,
      };

    case SchedulerScenarioActionTypes.CreateSchedulerScenario:
      return {
        ...state,
        createScenarioLoading: true,
        createScenarioLoaded: false,
      };

    case SchedulerScenarioActionTypes.SchedulerScenarioCreated:
      return {
        ...state,
        createScenarioLoading: false,
        createScenarioLoaded: true,
        createScenario: action.payload,
      };

    case SchedulerScenarioActionTypes.UpdateSchedulerScenario:
      return {
        ...state,
        updateScenarioLoading: true,
        updateScenarioLoaded: false,
      };

    case SchedulerScenarioActionTypes.SchedulerScenarioUpdated:
      return {
        ...state,
        updateScenarioLoading: false,
        updateScenarioLoaded: true,
        updateScenario: action.payload,
      };

    case SchedulerScenarioActionTypes.CloneSchedulerScenario:
      return {
        ...state,
        cloneScenarioLoading: true,
        cloneScenarioLoaded: false,
      };

    case SchedulerScenarioActionTypes.SchedulerScenarioCloned:
      return {
        ...state,
        cloneScenarioLoading: false,
        cloneScenarioLoaded: true,
        cloneScenario: action.payload,
      };

    case SchedulerScenarioActionTypes.DeleteSchedulerScenario:
      return {
        ...state,
        deleteScenarioLoading: true,
        deleteScenarioLoaded: false,
      };

    case SchedulerScenarioActionTypes.DeleteSchedulerScenarioCompleted:
      return {
        ...state,
        deleteScenarioLoading: false,
        deleteScenarioLoaded: true,
        deletedScenario: action.payload,
      };

    case SchedulerScenarioActionTypes.SchedulerSiteDataLoading:
      return {
        ...state,
        ...{
          scenariosSiteDataLoading: true,
          scenariosSiteDataLoaded: false,
        },
      };

    case SchedulerScenarioActionTypes.SchedulerSiteDataLoaded:
      return {
        ...state,
        ...{
          scenariosSiteData: action.payload,
          scenariosSiteDataLoading: false,
          scenariosSiteDataLoaded: true,
        },
      };

    case SchedulerScenarioActionTypes.BulkEditScenario:
      return {
        ...state,
        ...{
          bulkEditScenarioLoading: true,
          bulkEditScenarioLoaded: false,
        },
      };

    case SchedulerScenarioActionTypes.BulkEditScenarioCompleted:
      return {
        ...state,
        ...{
          bulkEditScenarioLoading: false,
          bulkEditScenarioLoaded: true,
          bulkEditScenario: action.payload,
        },
      };

    case SchedulerScenarioActionTypes.LoadScenarioUsers:
      return {
        ...state,
        ...{
          usersLoading: true,
          usersLoaded: false,
        },
      };

    case SchedulerScenarioActionTypes.ScenarioUsersLoaded:
      return {
        ...state,
        ...{
          usersLoading: false,
          usersLoaded: true,
          userData: action.payload,
        },
      };

    case SchedulerScenarioActionTypes.LoadSchedulerResourceSettings:
      return {
        ...state,
        ...{
          resourceSettingsLoading: true,
          resourceSettingsLoaded: false,
        },
      };

    case SchedulerScenarioActionTypes.SchedulerResourceSettingsLoaded:
      return {
        ...state,
        ...{
          resourceSettingsLoading: false,
          resourceSettingsLoaded: true,
          resourceSettingData: action.payload,
        },
      };

    case SchedulerScenarioActionTypes.SetTableSettings:
      return {
        ...state,
        tableSettings: action.payload,
      };

    default:
      return state;
  }
}
