import { Component, Input, OnInit, OnDestroy, ElementRef } from '@angular/core';
import { IStationDropdownResponse } from '../../../store/station-activity-history/station-activity-history.model';
import { HelperService } from '../../../shared/service/helper.service';
import { ActivityButtonStateInterface } from '../../../store/activity-button/activity-button.model';
import { combineLatest, Subscription } from 'rxjs';
import { Store } from '@ngrx/store';
import { OeeAppState } from '../../../store/oee.reducer';
import * as _ from 'lodash';
import { TranslateService } from '@ngx-translate/core';
import { filter } from 'rxjs/operators';
import { ColorService } from '../../../shared/service/color/color.service';
import { ComponentColors } from '../../../shared/service/color/color.model';
import { ActivitiesInterface } from '../../../shared/model/interface/activities.model';
import { activityStyles } from '../../../shared/helper/home-helper';

@Component({
  selector: 'line-station-dropdown-item',
  templateUrl: './line-station-dropdown-item.component.html',
  styleUrls: ['./line-station-dropdown-item.component.scss'],
})
export class LineStationDropdownItemComponent implements OnInit, OnDestroy {
  @Input() data: IStationDropdownResponse;

  public timer: string = '--';
  public subscriptions: Subscription[] = [];
  public activityName: string = '';
  public activityTypeClass:
    | 'activity-down-time-planned'
    | 'activity-down-time'
    | 'activity-run-time'
    | 'activity-idle-time' = 'activity-idle-time';
  public isWorkOrderEmpty: boolean = true;
  public componentColors: ComponentColors;
  public selectedActivity: ActivitiesInterface | undefined;

  constructor(
    private readonly helperService: HelperService,
    private readonly store: Store<OeeAppState>,
    public readonly translate: TranslateService,
    private readonly colorService: ColorService,
    private hostElement: ElementRef,
  ) {}

  ngOnInit(): void {
    this.subscriptions.push(
      combineLatest([
        this.store.select('user').pipe(filter((value) => value.isUserLoaded === true)),
        this.store.select('mainStore').pipe(filter((value) => value.getActiveSitesLoaded)),
      ]).subscribe(([user, main]) => {
        const siteId: number = user.siteId!;

        this.componentColors = this.colorService.pickComponentColors('plannedDownTimeActivityButton', {
          visibleSiteIds: [siteId],
          allSites: main.activeSites,
          client: user.clientInfo,
        });
      }),
    );

    this.subscriptions = [
      this.store.select('activityButton').subscribe((state: ActivityButtonStateInterface) => {
        if (state.isLoading || !state.isLoaded || !this.data.ongoingStationActivityHistory) {
          return;
        }

        this.isWorkOrderEmpty = !Boolean(this.data.ongoingStationActivityHistory.workOrderId);

        if (this.data.ongoingStationActivityHistory.activityId) {
          this.selectedActivity = _.find(state.activities, { id: this.data.ongoingStationActivityHistory.activityId });

          this.timer = this.helperService.activityTimerFunction(this.data.ongoingStationActivityHistory.startedAt);
          this.hostElement.nativeElement.style.setProperty(
            '--planned-down-time-color',
            this.componentColors.background,
          );

          this.activityTypeClass =
            activityStyles(this.selectedActivity?.activityType)?.activityCardClass ?? 'activity-idle-time';
        }

        this.activityName = this.selectedActivity?.name ?? '';
      }),
    ];
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((item: Subscription) => {
      item.unsubscribe();
    });
  }
}
