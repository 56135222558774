import { IDeepDiveFilters } from './deep-dive-analysis.model';
import * as Actions from './deep-dive-analysis.actions';
export const deepDiveAnalysisState: IDeepDiveFilters = {
  start: '',
  end: '',
  sites: [],
  lines: [],
  shifts: [],
  products: [],
  activityType: [],
};
export function deepDiveAnalysisReducer(
  state: IDeepDiveFilters = deepDiveAnalysisState,
  action: Actions.DeepDiveActions,
): IDeepDiveFilters {
  switch (action.type) {
    case Actions.SET_SELECTED_FILTERS:
      return {
        ...state,
        ...{
          sites: this.filter.sites,
          lines: this.filter.lines,
          shifts: this.filter.shifts,
          products: this.filter.products,
          start: this.filter.start,
          end: this.filter.end,
          activityType: this.filter.activityType,
        },
      };
    case Actions.UPDATE_FILTER_STATE:
      return {
        ...state,
        ...action.params,
      };
    default:
      return state;
  }
}
