import { Component, Input, OnInit } from '@angular/core';
import { DetailCardContent } from './detail-card.model';

@Component({
  selector: 'app-detail-card',
  templateUrl: './detail-card.component.html',
  styleUrls: ['./detail-card.component.scss'],
})
export class DetailCardComponent implements OnInit {
  @Input() hasBorder: boolean = true;
  @Input() content: DetailCardContent[];
  @Input() nullReplacer: string = '-';
  @Input() isLoading: boolean = false;
  @Input() cardColumnsCls: string = null;
  @Input() progressBarHeight: number = 1;

  constructor() {}

  public ngOnInit(): void {}
}
