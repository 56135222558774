import { AfterViewInit, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import {
  DefaultSelectionValuesInterface,
  FilterCardOptionInterface,
  LineCRUDInterface,
  RowConfigurationInterface,
  SiteCRUDInterface,
} from '../../../shared/component/filter/filter.class';
import { FilterDateRangePickerComponent } from '../../../shared/component/filter/filter-date-range-picker/filter-date-range-picker.component';
import {
  DateRangeDropsTypes,
  DateRangeOpensTypes,
} from '../../../shared/component/date-range-picker/date-range-picker.model';
import { EFilterDropdownElements } from '../../../store/filter/filter.model';
import { DropdownComponent } from '../../../shared/component/filter/dropdown/dropdown.component';
import {
  DateRange,
  DateRangeTypes,
  Equipment,
  Line,
  Site,
  User,
} from '../../../shared/component/filter/filterable-objects.class';
import { TranslateService } from '@ngx-translate/core';
import { HelperService } from '../../../shared/service/helper.service';
import * as moment from 'moment/moment';
import { Moment } from 'moment/moment';
import { PresetManager, PresetStore, ProjectModel, Scheduler, SchedulerConfig } from '@bryntum/schedulerpro';
import { DateRanges } from '../../../../constants';
import { concat, Subject, Subscription } from 'rxjs';
import { ActionsSubject, Store } from '@ngrx/store';
import { OeeAppState } from '../../../store/oee.reducer';
import { FilterSiteState } from '../../../store/filter/site/site.reducer';
import { FilterLineState } from '../../../store/filter/line/line.reducer';
import { ISelectGeneric } from '../../../shared/component/scw-mat-ui/scw-mat-select/scw-mat-select.model';
import { take, takeUntil, tap } from 'rxjs/operators';
import {
  ComponentNamesForUserConfiguration,
  IComponentConfiguration,
  IUserConfiguration,
  UserConfigurationStateInterface,
} from '../../../store/user-configuration/user-configuration.model';
import * as _ from 'lodash';
import * as PageHeaderActions from '../../../store/page-header/page-header.actions';
import * as CheckInTimelineActions from '../../../store/reports/check-in-timeline/check-in-timeline.actions';
import { ofType } from '@ngrx/effects';
import { mysqlDateFormat, mysqlTimestampFormat } from '../../../shared/helper/date';
import { CustomDependFilterKeysEnum } from '../../../shared/service/work-order-schedule/work-order-schedule.model';
import {
  CheckInTimelineEventTooltipDataInterface,
  CheckInTimelineGanttTreeEventInterface,
  CheckInTimelineGanttTreeResourceAsObjectInterface,
  CheckInTimelineGanttTreeResourceDepartmentAsObjectInterface,
  EChartMode,
  ECheckInSourceType,
  GanttCheckInTimelineItemStore,
  ICheckInTimeline,
  IDateOfFilterRequestByModeParameters,
  IFilterRequestDate,
  IFilterRequestParameter,
  IPresetHeader,
  SourceCheckInTimelineResourceEvent,
  SourceCheckInTimelineResourceEventStore,
} from './check-in-timeline.model';
import { CheckInSourceTypeEnum } from '../../home/cico/cico.model';
import { ICheckInTimelineResponseData } from '../../../store/reports/check-in-timeline/check-in-timeline.model';
import { ScwMatButtonGroupButtons } from '../../../shared/component/scw-mat-ui/scw-mat-button-group/scw-mat-button-group.model';
import { SchedulerHelperService } from '../../../shared/service/scheduler/scheduler-helper.service';
import { workOrderColorsOfSchedulerGantt } from '../../../shared/model/enum/work-order-colors-of-scheduler-gantt';
import * as UserConfigurationActions from '../../../store/user-configuration/user-configuration.actions';
import * as UserActions from '../../../store/settings/users/users.actions';
import * as EquipmentSettingsActions from '../../../store/settings/equipments/equipments.actions';
import { ImageHelperService } from '../../../shared/helper/image-helper.service';
import { SqlOperators } from '../../../shared/component/filter/advanced-filter/advanced-filter.model';

@Component({
  selector: 'app-scw-check-in-timeline',
  templateUrl: './check-in-timeline.component.html',
  styleUrls: ['./check-in-timeline.component.scss'],
})
export class CheckInTimelineComponent implements OnInit, AfterViewInit {
  public static unitOfTime: moment.DurationInputArg2 = 'hours';
  @ViewChild('checkinTimelineSchedulerRef', { static: false }) checkInTimelineSchedulerRef: ElementRef;
  public defaultDropdownSelectionSubject: Subject<DefaultSelectionValuesInterface> = new Subject();
  public hiddenElementIds: string[] = ['dateRange'];
  public isFilterBarVisible$: boolean = true;
  public sourceObjectId: number = null;
  public sourceTypeId: CheckInSourceTypeEnum;
  public readonly filterOptionsCommonClass: string = 'col-xl-4 col-sm-4 m-t-5 m-b-5 filter-input';
  public readonly filterOptionsCommonClassForDashboard: string = 'col-xl-3 col-sm-3 m-t-5 m-b-5 filter-input';
  public readonly maxDate: moment.Moment = moment();
  public datepickerRanges: (keyof DateRanges)[] = ['today', 'yesterday', 'thisWeek', 'lastWeek'];
  public dateRange: keyof DateRanges = 'thisWeek';
  public isDashboardMode$: boolean = false;
  public readonly chartButtonGroup: ScwMatButtonGroupButtons[] = [
    {
      value: EChartMode.SHOW_ALL_LINES,
      text: this.translate.instant('checkInTimeline.chart.mode.showAllLines'),
    },
    {
      value: EChartMode.HIGHLIGHT_FILTERED_LINES,
      text: this.translate.instant('checkInTimeline.chart.mode.highlightFilteredLines'),
    },
    {
      value: EChartMode.SHOW_ONLY_FILTERED_LINES,
      text: this.translate.instant('checkInTimeline.chart.mode.showOnlyFilteredLines'),
    },
  ];
  public chartMode: EChartMode = EChartMode.SHOW_ALL_LINES;
  public filterOption: FilterCardOptionInterface = {
    rows: [
      [
        {
          type: FilterDateRangePickerComponent,
          elementId: 'dateRange',
          cls: this.filterOptionsCommonClass,
          outputOptions: {
            filterObjectId: CustomDependFilterKeysEnum.DATE_RANGE,
          },
          options: {
            isRequired: true,
            showClearButton: false,
            text: this.translate.instant('filterCard.datePicker.name'),
            placeholder: this.translate.instant('filterCard.datePicker.placeholder'),
            initialSelectedDate: this.dateRange,
            drops: DateRangeDropsTypes.down,
            opens: DateRangeOpensTypes.center,
            timePicker: true,
            dateLimit: 7,
            maxDate: this.maxDate,
            ranges: this.datepickerRanges,
            isCustomWeekStart: true,
            weekStartDay: this.getWeekStartDay(),
          },
        },
        this.helperService.getGenericDropdownFilter(
          {
            type: DateRange,
            name: 'timePeriod',
            elementId: EFilterDropdownElements.checkInTimelineTimePeriodSelectDropdown,
          },
          this.filterOptionsCommonClass,
          {
            singleSelection: true,
            isRequired: true,
            isStaticDropdown: true,
          },
          null,
          {
            condition: (
              time: ISelectGeneric<DateRangeTypes, string>,
              index: number | undefined,
              timePeriods: ISelectGeneric<DateRangeTypes, string>[],
            ) => {
              const selectedTimePeriods = timePeriods.filter((value: ISelectGeneric<DateRangeTypes, string>) =>
                [DateRangeTypes.Today, DateRangeTypes.ThisWeek].includes(value.id),
              );

              return selectedTimePeriods.some(
                (selectedTimePeriod: ISelectGeneric<DateRangeTypes, string>) => time.id === selectedTimePeriod.id,
              );
            },
          },
        ),
        {
          elementId: EFilterDropdownElements.siteSingleSelectDropdown,
          cls: this.filterOptionsCommonClass,
          type: DropdownComponent,
          object: Site,
          outputOptions: {
            filterObjectId: 'siteId',
            filterObjectProp: 'id',
            returnFilterObjectAllProp: false,
          },
          options: {
            isRequired: true,
            singleSelection: true,
            text: this.translate.instant('filterCard.site.dropdownPlaceHolder'),
          },
        },
        {
          elementId: EFilterDropdownElements.lineMultiSelectDropdown,
          cls: this.filterOptionsCommonClass,
          type: DropdownComponent,
          object: Line,
          outputOptions: {
            filterObjectId: 'lineIds',
            filterObjectProp: 'id',
            returnFilterObjectAllProp: false,
          },
          options: {
            isRequired: false,
            text: this.translate.instant('filterCard.line.dropdownPlaceHolder'),
            noDataLabel: this.translate.instant('filterCard.dropdown.labelForSiteDepends'),
            getInitData: true,
          },
        },
      ],
    ],
  };
  public timezone$: string = 'utc';
  public filterRequestParams: IFilterRequestParameter = null;
  public eventTooltipFeature;
  public scheduler: Scheduler;
  public weekStartDay: number = null;
  public lineData$: LineCRUDInterface[];
  public siteData$: SiteCRUDInterface[];
  public checkInTimelines: ICheckInTimeline[];
  public filteredCheckInTimelines: ICheckInTimeline[] = [];
  public readonly schedulerDefaultHeight: number = 700;
  public schedulerHeight: number = this.schedulerDefaultHeight;
  public schedulerViewPreset$: string = 'hourAndDay';
  public currentTimelineDateFormat: string = 'YYYY-MM-DDTHH:mm:ss';
  public readonly schedulerMaxZoomLevel: number = 18;
  public readonly schedulerMinZoomLevel: number = 6;
  public schedulerProject: ProjectModel;
  public schedulerResources$: SourceCheckInTimelineResourceEvent[] = [];
  public selectedLineIds: number[] = [];
  public filterCardValidUpdateMessage: string = this.translate.instant(
    'filterSetAsDefault.laborAssetLogsTimeline.info',
  );
  private currentTimeLineRefreshTimeLimitAsOneDay: number = 86400000;
  private readonly chartModeMappers: Record<EChartMode, () => CheckInTimelineGanttTreeEventInterface[]> = {
    [EChartMode.SHOW_ALL_LINES]: () =>
      this.checkInTimelines.map((checkInTimeline: ICheckInTimeline) => {
        const resourceId: string = checkInTimeline.sourceId.toString();
        const name: string = checkInTimeline.barTitle;
        const sourceName: string = checkInTimeline.sourceName;

        const cls: string = '';
        return {
          ...checkInTimeline,
          resourceId,
          name,
          sourceName,
          style: 'color: black',
          cls,
        };
      }),
    [EChartMode.HIGHLIGHT_FILTERED_LINES]: () =>
      this.checkInTimelines.map((checkInTimeline: ICheckInTimeline) => {
        const resourceId: string = checkInTimeline.sourceId.toString();
        const name: string = checkInTimeline.barTitle;
        const sourceName: string = checkInTimeline.sourceName;

        const cls: string = '';
        return {
          ...checkInTimeline,
          resourceId,
          name,
          sourceName,
          style: 'color: black',
          cls,
        };
      }),
    [EChartMode.SHOW_ONLY_FILTERED_LINES]: () =>
      this.filteredCheckInTimelines.map((checkInTimeline: ICheckInTimeline) => {
        const resourceId: string = checkInTimeline.sourceId.toString();
        const name: string = checkInTimeline.barTitle;
        const sourceName: string = checkInTimeline.sourceName;

        const cls: string = '';
        return {
          ...checkInTimeline,
          resourceId,
          name,
          sourceName,
          style: 'color: black',
          cls,
        };
      }),
  };
  private readonly ganttTimeIncrement: number = this.helperService.isTouchDevice ? 5 : 3;
  private readonly weekHeader: IPresetHeader = {
    unit: 'week',
    align: 'center',
    dateFormat: HelperService.userDatePref.weekFormat,
  };
  private readonly dayHeader: IPresetHeader = {
    unit: 'day',
    align: 'start',
    dateFormat: HelperService.userDatePref.dateFormat,
    increment: 1,
  };
  private readonly hourHeader: IPresetHeader = {
    unit: 'hour',
    align: 'start',
    dateFormat: HelperService.userDatePref.timeFormat,
    increment: this.ganttTimeIncrement,
  };
  private selectedStartDateFromDatePicker: Moment = null;
  private selectedEndDateFromDatePicker: Moment = null;
  private timePeriod: string = null;
  private checkInTimelineDataLoaded: boolean = false;
  private userLocale$: string = null;
  private datetimeFormat$: string;
  private readonly storeSubscriptions: Subscription[] = [];
  private readonly iterationLevel: number = 1;
  private readonly eventTooltipHoverDelay = 500;
  private readonly eventToolTipHideDelay = 500;
  private readonly sourceColumnId: string = 'sourceName';
  private colorAssignments = {
    labor: {},
    asset: {},
  };
  private selectedSiteId: number;
  private lineDropdownDestroySubject: Subject<boolean> = new Subject<boolean>();
  private siteDropdownDestroySubject: Subject<boolean> = new Subject<boolean>();
  private defaultSite$: string;
  private schedulerConfig: Partial<SchedulerConfig>;
  private readonly userDefaultIconCls: string = 'fas fa-user';
  private readonly assetDefaultIconCls: string = 'fas fa-cube';
  private isLaborLogsTimelinePage: boolean = false;
  private avatarCount: number = 0;

  constructor(
    private router: Router,
    private readonly store: Store<OeeAppState>,
    private readonly checkInTimelineAction: ActionsSubject,
    private readonly translate: TranslateService,
    private readonly helperService: HelperService,
    private readonly schedulerHelperService: SchedulerHelperService,
  ) {
    this.eventTooltipFeature = {
      hoverDelay: this.eventTooltipHoverDelay,
      hideDelay: this.eventToolTipHideDelay,
      template: (checkInTimelineEventData: CheckInTimelineEventTooltipDataInterface) =>
        this.beforeTooltipTemplate(checkInTimelineEventData).then(
          (checkImTimelineEventData: CheckInTimelineEventTooltipDataInterface) =>
            this.tooltipTemplate(checkImTimelineEventData),
        ),
      onBeforeShow({ source: tooltip }) {
        tooltip.title = _.get(tooltip.eventRecord, 'destinationName', null);

        return true;
      },
    };
    this.schedulerProject = new ProjectModel({
      eventStore: new GanttCheckInTimelineItemStore(),
      resourceStore: new SourceCheckInTimelineResourceEventStore(),
    });
  }

  public beforeTooltipTemplate(
    checkInTimelineEventData: CheckInTimelineEventTooltipDataInterface,
  ): Promise<CheckInTimelineEventTooltipDataInterface> {
    return Promise.resolve(checkInTimelineEventData);
  }

  public tooltipTemplate(data: CheckInTimelineEventTooltipDataInterface): string {
    const endedAtString: string =
      _.get(data.eventRecord, 'originalEndedAt', null) !== null
        ? moment(_.get(data.eventRecord, 'originalEndedAt', null)).format(this.datetimeFormat$)
        : this.translate.instant('general.ongoing');

    return `
        <div>
            <b>${this.getTitleHeader(_.get(data.eventRecord, 'stationLineName', undefined))}:</b>
               ${_.get(data.eventRecord, 'destinationName', null)}
        </div>
        <div>
            <b>${this.translate.instant('checkInTimeline.tooltip.startDate')}:</b>
               ${moment(_.get(data.eventRecord, 'originalStartedAt', null)).format(this.datetimeFormat$)}
        </div>
        <div>
            <b>${this.translate.instant('checkInTimeline.tooltip.endDate')}:</b>
               ${endedAtString}
        </div>
        <div>
          <b>${this.translate.instant('checkInTimeline.tooltip.duration')}:</b>
          ${this.schedulerHelperService.formatWithMomentDuration(
            this.getDurationWithSecond(
              moment(_.get(data.eventRecord, 'originalStartedAt', null)),
              moment(_.get(data.eventRecord, 'endedAt', null)),
            ),
            undefined,
            true,
          )}
        </div>
     `;
  }

  public ngAfterViewInit(): void {
    this.selectSiteAndLineDropdown(this.selectedLineIds, this.selectedSiteId, this.timePeriod);
    this.prepareScheduler();
    this.getCheckInTimelineData();
  }

  public ngOnInit(): void {
    this.isLaborLogsTimelinePage = !!this.router.url.includes('labor');
    this.sourceTypeId = !!this.router.url.includes('labor')
      ? CheckInSourceTypeEnum.SOURCE_TYPE_USER
      : CheckInSourceTypeEnum.SOURCE_TYPE_EQUIPMENT;

    this.store.dispatch(new PageHeaderActions.UpdateFilterBarVisibility(this.isFilterBarVisible$));
    this.prepareFilterCard();
    this.storeSubscriptions.push(
      this.store.select('user').subscribe((state) => {
        if (state.timezone !== null) {
          this.timezone$ = state.timezone;
        }

        this.defaultSite$ = state.defaultSite;
        this.userLocale$ = state.locale;
        this.datetimeFormat$ = state.dateTimeFormat;
      }),
      this.store.select('siteFilter').subscribe((state: FilterSiteState) => {
        if (state.isLoaded && !state.isLoading) {
          this.siteData$ = state.data;
        }
      }),
      this.store.select('lineFilter').subscribe((state: FilterLineState) => {
        if (state.isLoaded && !state.isLoading) {
          this.lineData$ = state.data;
        }
      }),
      this.store.select('userConfigurationStore').subscribe((state: UserConfigurationStateInterface) => {
        this.performUserConfigurationOperations(HelperService.cloneDeep(state));
      }),
    );
    this.checkInTimelineAction
      .pipe(ofType(PageHeaderActions.UPDATE_FILTER_BAR_VISIBILITY))
      .subscribe((response: PageHeaderActions.UpdateFilterBarVisibility): void => {
        this.isFilterBarVisible$ = response.isVisible;
      });
    this.checkInTimelineAction.pipe(ofType(PageHeaderActions.COUNTDOWN_TIMEOUT)).subscribe((): void => {
      if (this.isDashboardMode$) {
        this.store.dispatch(new PageHeaderActions.CountdownPause());
        this.store.dispatch(new PageHeaderActions.CountdownDisable());
      }

      this.getCheckInTimelineData();
    });
    this.checkInTimelineAction
      .pipe(ofType(UserActions.GET_AVATAR_LOADED, EquipmentSettingsActions.GET_AVATAR_LOADED))
      .subscribe((response: UserActions.GetAvatarLoaded | EquipmentSettingsActions.GetAvatarLoaded) => {
        this.checkInTimelines = this.checkInTimelines?.map((timeline: ICheckInTimeline) => {
          const isPathEqual: boolean =
            timeline.avatarPath &&
            ImageHelperService.removeVersionInfoFromPath(timeline.avatarPath) ===
              ImageHelperService.removeVersionInfoFromPath(response.path);

          if (isPathEqual) {
            timeline.avatar = `data:image/png;base64,${response.response.data}`;
          }

          return timeline;
        });
        this.avatarCount = this.avatarCount - 1;

        if (this.avatarCount === 0) {
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          this.scheduler.refresh();
        }
      });
    this.checkInTimelineAction
      .pipe(ofType(CheckInTimelineActions.CHECK_IN_TIMELINE_DATA_LOADED))
      .subscribe((response: CheckInTimelineActions.CheckInTimeLineDataLoaded) => {
        if (this.isDashboardMode$) {
          this.store.dispatch(new PageHeaderActions.CountdownReset());
        }

        this.isLaborLogsTimelinePage = !!this.router.url.includes('labor');
        this.checkInTimelineDataLoaded = true;
        this.checkInTimelines?.forEach((checkInTimeline: ICheckInTimeline) => {
          this.scheduler.project.resourceStore.remove(checkInTimeline.sourceId);
        });
        this.checkInTimelines = response.data.map((item: ICheckInTimelineResponseData) => {
          return {
            ...item,
            startDate: item.startedAt,
            endDate: item.endedAt,
            destinationName: item.stationLineName
              ? `${item.destinationName} (${item.stationLineName})`
              : item.destinationName,
            barTitle: item.stationLineName ? `${item.destinationName} (${item.stationLineName})` : item.destinationName,
            name: item.stationLineName ? `${item.destinationName} (${item.stationLineName})` : item.destinationName,
            draggable: false,
            resizable: false,
          };
        });
        this.checkInTimelines.forEach((checkIn: ICheckInTimeline) => {
          if (checkIn.avatarPath !== null) {
            this.getAvatar(checkIn);
          }
        });

        if (this.checkInTimelines.length) {
          const maxEnd: string = _.maxBy(this.checkInTimelines, 'endedAt')?.endDate;
          this.setViewHorizon(
            moment(this.filterRequestParams.startDate).format(mysqlTimestampFormat),
            moment(maxEnd).format(mysqlTimestampFormat),
          );
        }

        if (this.selectedLineIds?.length) {
          this.chartMode = EChartMode.HIGHLIGHT_FILTERED_LINES;
        } else {
          this.chartMode = EChartMode.SHOW_ALL_LINES;
        }

        this.checkInTimelineDataModified();
      });
    this.checkInTimelineAction
      .pipe(ofType(PageHeaderActions.UPDATE_PAGE_MODE))
      .subscribe((response: PageHeaderActions.UpdatePageMode): void => {
        this.formatFilterCard(response.isDashboardMode);
        this.hiddenElementIds = response.isDashboardMode
          ? ['dateRange']
          : [EFilterDropdownElements.checkInTimelineTimePeriodSelectDropdown];

        if (this.isDashboardMode$ !== response.isDashboardMode) {
          this.isDashboardMode$ = response.isDashboardMode;
          this.setFilterCardVisibility(!response.isDashboardMode);

          this.redrawCheckInTimeLineGraph();
        }

        if (this.isDashboardMode$ && this.checkInTimelineDataLoaded) {
          this.store.dispatch(new PageHeaderActions.CountdownReset());
        }
      });
  }

  public changeButtonGroup($event: any): void {
    this.chartMode = $event;
    switch ($event) {
      case EChartMode.SHOW_ALL_LINES:
      case EChartMode.HIGHLIGHT_FILTERED_LINES:
        this.filteredCheckInTimelines = this.checkInTimelines;
        break;
      case EChartMode.SHOW_ONLY_FILTERED_LINES:
        this.filteredCheckInTimelines = this.checkInTimelines.filter((item: ICheckInTimeline) =>
          this.selectedLineIds.includes(item.lineId),
        );
        break;
    }

    this.checkInTimelineDataModified();
  }

  public onFilterSetAsDefault(): void {
    const component: ComponentNamesForUserConfiguration = this.isLaborLogsTimelinePage
      ? ComponentNamesForUserConfiguration.LaborCheckInTimeline
      : ComponentNamesForUserConfiguration.AssetCheckInTimeline;
    const configuration: IUserConfiguration = {
      [component]: [
        {
          name: 'filters',
          children: [
            {
              name: 'siteId',
              value: this.filterRequestParams.siteId,
            },
            {
              name: 'lineIds',
              value: this.filterRequestParams.lineIds,
            },
            {
              name: 'timePeriod',
              value: this.timePeriod,
            },
            {
              name: 'siteWeekStartDay',
              value: this.weekStartDay,
            },
            {
              name: 'chartMode',
              value: this.chartMode,
            },
            {
              name: 'dateRange',
              value: this.dateRange,
            },
            {
              name: 'sourceObjectIds',
              value: this.filterRequestParams.sourceObjectIds,
            },
          ],
        },
        {
          name: 'isDashboardModeDefault',
          value: this.isDashboardMode$,
        },
      ],
    };

    this.store.dispatch(new UserConfigurationActions.UpdateUserConfigurationLoading(configuration));
  }

  public setViewHorizon(start: string, end: string): void {
    const startDate: moment.Moment = moment(start).startOf(CheckInTimelineComponent.unitOfTime);
    const endDate: moment.Moment = moment(end).endOf(CheckInTimelineComponent.unitOfTime);

    const daysDiff = endDate.diff(startDate, 'days');

    if (daysDiff === 0) {
      this.setPresetAndTimeRange(startDate, endDate, 'hourAndDay', 'hour', [this.dayHeader, this.hourHeader]);
      this.scheduler.zoomLevel = 15;
    }

    if (daysDiff > 0) {
      this.setPresetAndTimeRange(startDate, endDate, 'weekAndDay', 'day', [this.dayHeader, this.weekHeader]);
      this.scheduler.zoomLevel = 13;
    }
  }

  public zoomIn(): void {
    this.clearTimeRanges();
    this.scheduler.zoomIn(this.iterationLevel);
  }

  public zoomOut(): void {
    this.clearTimeRanges();
    this.scheduler.zoomOut(this.iterationLevel);
  }

  public onFiltersChanged($event: any): void {
    this.weekStartDay = _.find(this.siteData$, { id: $event.siteId[0] })?.weekStartDay;
    this.selectedLineIds = $event.lineIds;

    this.dateRange = this.helperService.getDateRangeNameFromDates(
      this.datepickerRanges,
      $event.dateRange.startDate,
      $event.dateRange.endDate,
      this.weekStartDay,
      this.maxDate,
    );

    const setDateOfFilterRequestByModeParams: IDateOfFilterRequestByModeParameters = {
      startDate: $event.dateRange.startDate,
      endDate: $event.dateRange.endDate,
      timePeriod: $event.timePeriod ? $event.timePeriod[0] : null,
    };
    const { startDate, endDate }: IFilterRequestDate = this.setDateOfFilterRequestByMode(
      setDateOfFilterRequestByModeParams,
    );

    this.filterRequestParams = {
      siteId: $event.siteId[0],
      lineIds: this.selectedLineIds,
      sourceTypeId: this.isLaborLogsTimelinePage
        ? CheckInSourceTypeEnum.SOURCE_TYPE_USER
        : CheckInSourceTypeEnum.SOURCE_TYPE_EQUIPMENT,
      startDate,
      endDate,
      sourceObjectIds: $event.sourceObjectIds,
    };

    this.scheduler.features.timeRanges.timeRanges[0].startDate = moment()
      .tz(this.timezone$)
      .format(this.currentTimelineDateFormat);
    this.scheduler.features.timeRanges.timeRanges[0].name = moment().tz(this.timezone$).format('hh:mm');

    this.setViewHorizon(
      moment(this.filterRequestParams.startDate).format(mysqlTimestampFormat),
      moment(this.filterRequestParams.endDate).format(mysqlTimestampFormat),
    );

    this.getCheckInTimelineData();
  }

  private getAvatar(item: ICheckInTimeline): void {
    this.avatarCount = this.avatarCount + 1;

    if (this.isLaborLogsTimelinePage) {
      this.store.dispatch(new UserActions.GetAvatarLoading(item.avatarPath));
    } else {
      this.store.dispatch(new EquipmentSettingsActions.GetAvatarLoading(item.avatarPath));
    }
  }

  private redrawCheckInTimeLineGraph(): void {
    const setDateOfFilterRequestByModeParams: IDateOfFilterRequestByModeParameters = {
      startDate: this.selectedStartDateFromDatePicker,
      endDate: this.selectedEndDateFromDatePicker,
      timePeriod: this.timePeriod,
    };

    const { startDate, endDate }: IFilterRequestDate = this.setDateOfFilterRequestByMode(
      setDateOfFilterRequestByModeParams,
    );

    this.filterRequestParams = {
      ...this.filterRequestParams,
      startDate,
      endDate,
    };

    this.setViewHorizon(moment(startDate).format(mysqlTimestampFormat), moment(endDate).format(mysqlTimestampFormat));
    this.getCheckInTimelineData();
  }

  private getCheckInTimelineData(): void {
    if (
      _.isNil(this.filterRequestParams.sourceTypeId) ||
      _.isNil(this.filterRequestParams.siteId) ||
      _.isNil(this.filterRequestParams.startDate) ||
      _.isNil(this.filterRequestParams.endDate)
    ) {
      return;
    }

    this.checkInTimelineDataLoaded = false;
    this.isLaborLogsTimelinePage = !!this.router.url.includes('labor');
    this.filterRequestParams = {
      ...this.filterRequestParams,
      sourceTypeId: this.isLaborLogsTimelinePage
        ? CheckInSourceTypeEnum.SOURCE_TYPE_USER
        : CheckInSourceTypeEnum.SOURCE_TYPE_EQUIPMENT,
    };

    this.store.dispatch(new CheckInTimelineActions.CheckInTimelineDataLoading(this.filterRequestParams));
  }

  private getDurationWithSecond(startDate: Moment, endDate: Moment): number {
    return moment.duration(endDate.diff(startDate)).asSeconds();
  }

  private getTitleHeader(stationLineName: string): string {
    return stationLineName
      ? this.translate.instant('checkInTimeline.tooltip.stationName')
      : this.translate.instant('checkInTimeline.tooltip.lineName');
  }

  private getOrGenerateColorForItem(id: number): string {
    const type: ECheckInSourceType = this.isLaborLogsTimelinePage ? ECheckInSourceType.LABOR : ECheckInSourceType.ASSET;
    let color: string = this.colorAssignments[type][id];

    if (!color) {
      color = this.getDefinedWorkOrderColor(id);
      this.colorAssignments[type][id] = color;
    }

    return color;
  }

  private getDefinedWorkOrderColor(id: number = 0): string {
    return workOrderColorsOfSchedulerGantt[id % workOrderColorsOfSchedulerGantt.length];
  }

  private checkInTimelineDataModified(): void {
    this.initializeResourceStore();

    const scheduledEvents: CheckInTimelineGanttTreeEventInterface[] = this.chartModeMappers[this.chartMode]();
    this.scheduler.project.eventStore.removeAll(false);
    this.scheduler.project.eventStore.loadDataAsync(scheduledEvents);
  }

  private initializeResourceStore(): void {
    const checkInTimelineObject: CheckInTimelineGanttTreeResourceDepartmentAsObjectInterface = {};

    const checkInTimelineData: ICheckInTimeline[] =
      this.chartMode === EChartMode.SHOW_ONLY_FILTERED_LINES ? this.filteredCheckInTimelines : this.checkInTimelines;

    for (const checkInTimeline of checkInTimelineData) {
      const { sourceId } = checkInTimeline;

      if (!checkInTimelineObject.hasOwnProperty(sourceId)) {
        checkInTimelineObject[sourceId] = {
          id: `${sourceId}`,
          name: checkInTimeline.barTitle,
          sourceName: checkInTimeline.sourceName,
          expanded: false,
          cls: 'timeline-row',
        };
      }
    }

    this.schedulerResources$ = Object.values(checkInTimelineObject).map(
      (source: CheckInTimelineGanttTreeResourceAsObjectInterface) => {
        return new SourceCheckInTimelineResourceEvent({
          id: source.id,
          name: source.name,
          // @ts-ignore
          sourceName: source.sourceName,
          expanded: false,
          cls: source.cls,
          iconClass: this.isLaborLogsTimelinePage ? this.userDefaultIconCls : this.assetDefaultIconCls,
        });
      },
    );
    this.scheduler.project.resourceStore.loadDataAsync(this.schedulerResources$);
  }

  private setPresetAndTimeRange(
    startDate: moment.Moment,
    endDate: moment.Moment,
    presetName: string,
    shiftUnit: string,
    presetHeaders: IPresetHeader[],
  ): void {
    this.clearTimeRanges();

    const presets: PresetStore = this.scheduler.presets as PresetStore;
    presets.add(
      PresetManager.add({
        shiftUnit,
        headers: presetHeaders,
        id: presetName,
        shiftIncrement: 1,
        base: presetName,
        columnLinesFor: 1,
        timeResolution: {
          unit: shiftUnit,
          increment: 1,
        },
      }),
    );
    this.scheduler.viewPreset = presetName;
    this.scheduler.startDate = startDate.toDate();
    this.scheduler.endDate = endDate.toDate();
  }

  private formatFilterCard(isDashboardMode: boolean): void {
    const filterOptionList = document.getElementsByClassName('filter-input');
    const commonClass: string = isDashboardMode
      ? this.filterOptionsCommonClassForDashboard
      : this.filterOptionsCommonClass;

    for (const element of filterOptionList) {
      (element as HTMLElement).className = commonClass;
    }
  }

  private setDateOfFilterRequestByMode(params: IDateOfFilterRequestByModeParameters): IFilterRequestDate {
    const dateNow: string = moment().tz(this.timezone$).format(mysqlTimestampFormat);
    const dateFormat: string = this.isDashboardMode$ ? mysqlDateFormat : mysqlTimestampFormat;

    this.selectedStartDateFromDatePicker = moment(params.startDate);
    this.selectedEndDateFromDatePicker = moment(params.endDate);

    HelperService.setCustomMomentLocaleDayOfWeek(this.userLocale$, this.weekStartDay);

    const rangeTimes: DateRanges = this.helperService.getDateRangesWithTimezone(['today', 'thisWeek']);

    let startDate: string = this.selectedStartDateFromDatePicker.format(dateFormat);
    let endDate: string = this.selectedEndDateFromDatePicker.format(dateFormat);
    this.timePeriod = params.timePeriod ? params.timePeriod : this.timePeriod;

    if (this.isDashboardMode$ && this.timePeriod === DateRangeTypes.ThisWeek) {
      startDate = rangeTimes.thisWeek[0].format(dateFormat);
      endDate = rangeTimes.thisWeek[1].format(dateFormat);
    } else if (this.isDashboardMode$) {
      startDate = rangeTimes.today[0].format(dateFormat);
      endDate = dateNow;
    }

    HelperService.setDefaultMomentLocaleDayOfWeek(this.userLocale$);

    return { startDate, endDate };
  }

  private setFilterCardVisibility(isVisible: boolean): void {
    this.store.dispatch(new PageHeaderActions.UpdateFilterBarVisibility(isVisible));

    this.isFilterBarVisible$ = isVisible;
  }

  private prepareFilterCard(): void {
    const dropdownElement: EFilterDropdownElements = this.isLaborLogsTimelinePage
      ? EFilterDropdownElements.userMultiSelectDropdown
      : EFilterDropdownElements.equipmentMultiSelectDropdown;

    const filterObject = this.isLaborLogsTimelinePage ? User : Equipment;

    const dropdownPlaceholder: string = this.isLaborLogsTimelinePage
      ? 'filterCard.user.dropdownPlaceHolder'
      : 'filterCard.equipment.dropdownPlaceHolder';

    this.filterOption.rows[0] = this.filterOption.rows[0].filter(
      (element: RowConfigurationInterface): boolean =>
        element.elementId !==
        (this.isLaborLogsTimelinePage
          ? EFilterDropdownElements.equipmentMultiSelectDropdown
          : EFilterDropdownElements.userMultiSelectDropdown),
    );

    this.filterOption.rows[0].push({
      elementId: dropdownElement,
      type: DropdownComponent,
      cls: this.filterOptionsCommonClass,
      object: filterObject,
      outputOptions: {
        filterObjectId: 'sourceObjectIds',
        filterObjectProp: 'id',
        returnFilterObjectAllProp: false,
      },
      options: {
        searchProps: this.isLaborLogsTimelinePage
          ? [
              {
                prop: 'username',
                condition: '$cont',
              },
              {
                prop: 'fullName',
                condition: '$cont',
              },
            ]
          : [
              {
                prop: 'equipmentName',
                condition: '$cont',
              },
            ],
        text: this.translate.instant(dropdownPlaceholder),
        isRequired: false,
        defaultSelection: {
          key: 'sourceObjectId',
          values: [this.sourceObjectId],
        },
        enableServerSideSearch: true,
      },
      ...(!this.isLaborLogsTimelinePage && {
        dependProperties: ['id'],
        dropdownDepends: [
          {
            property: 'siteId',
            parentElementId: EFilterDropdownElements.siteSingleSelectDropdown,
            parentProperty: 'id',
            conditionType: SqlOperators.$in,
          },
        ],
      }),
    });
  }

  private selectSiteAndLineDropdown(selectedLineIds: number[], selectedSiteId: number, timePeriod: string): void {
    if (!selectedLineIds?.length) {
      this.setFilterCardVisibility(!this.isDashboardMode$);
    }

    const defaultSelectionValues: DefaultSelectionValuesInterface = {};
    defaultSelectionValues[EFilterDropdownElements.checkInTimelineTimePeriodSelectDropdown] = {
      key: 'id',
      values: [timePeriod],
    };
    this.selectedLineIds = [];

    concat(
      this.store.select('lineFilter').pipe(
        takeUntil(this.lineDropdownDestroySubject),
        tap((state: FilterLineState) => {
          if (state.isLoaded && !state.isLoading) {
            if (state.data.length >= 1 && selectedLineIds !== undefined && Number(selectedLineIds) !== -1) {
              defaultSelectionValues[EFilterDropdownElements.lineMultiSelectDropdown] = {
                key: 'id',
                values: selectedLineIds,
              };
            }

            this.lineData$ = _.cloneDeep(state.data);

            this.lineDropdownDestroySubject.next(true);
          }
        }),
      ),

      this.store.select('siteFilter').pipe(
        takeUntil(this.siteDropdownDestroySubject),
        tap((state: FilterSiteState) => {
          if (state.isLoaded && !state.isLoading) {
            if (state.data.length >= 1) {
              defaultSelectionValues[EFilterDropdownElements.siteSingleSelectDropdown] = {
                key: 'id',
                values: selectedSiteId
                  ? [selectedSiteId]
                  : _.isNil(this.defaultSite$)
                  ? [state.data[0].id as number]
                  : [Number(this.defaultSite$)],
              };

              this.defaultDropdownSelectionSubject.next(defaultSelectionValues);
            }

            this.siteDropdownDestroySubject.next(true);
          }
        }),
      ),
    ).subscribe();
  }

  private getWeekStartDay(): number {
    this.store
      .select('userConfigurationStore')
      .pipe(take(1))
      .subscribe((state: UserConfigurationStateInterface) => {
        this.performUserConfigurationOperations(HelperService.cloneDeep(state));
      });
    return this.weekStartDay;
  }

  private performUserConfigurationOperations(state: UserConfigurationStateInterface): void {
    if (!this.sourceTypeId) {
      return;
    }

    const userConfiguration: IComponentConfiguration[] = this.isLaborLogsTimelinePage
      ? state.userConfigurationData.LaborCheckInTimelineComponent
      : state.userConfigurationData.AssetCheckInTimelineComponent;
    const filterConfiguration: UserConfigurationStateInterface = _.find(userConfiguration, {
      name: 'filters',
    })?.children;

    const siteIdValue: number | undefined = _.find(filterConfiguration, { name: 'siteId' })?.value;
    this.selectedSiteId = siteIdValue ?? Number(this.defaultSite$);

    this.selectedLineIds = _.find(filterConfiguration, { name: 'lineIds' })?.value;
    this.weekStartDay = _.find(filterConfiguration, { name: 'siteWeekStartDay' })?.value;
    this.timePeriod = _.find(filterConfiguration, { name: 'timePeriod' })?.value;

    const filterConfigurationDateRange: undefined | DateRangeTypes = _.find(filterConfiguration, {
      name: 'dateRange',
    })?.value;
    this.dateRange =
      filterConfigurationDateRange && filterConfigurationDateRange !== DateRangeTypes.Custom
        ? filterConfigurationDateRange
        : this.dateRange;

    this.filterRequestParams = {
      ...this.filterRequestParams,
      siteId: this.selectedSiteId,
      lineIds: this.selectedLineIds,
    };

    if (this.filterOption) {
      this.filterOption.rows[0][0].options['initialSelectedDate'] = this.dateRange;
    }

    this.isDashboardMode$ = _.find(userConfiguration, {
      name: 'isDashboardModeDefault',
    })?.value;

    this.hiddenElementIds = this.isDashboardMode$
      ? ['dateRange']
      : [EFilterDropdownElements.checkInTimelineTimePeriodSelectDropdown];

    this.store.dispatch(new PageHeaderActions.UpdatePageMode(this.isDashboardMode$));
  }

  private clearTimeRanges(): void {
    this.scheduler.features.timeRanges.store.removeAll(false);
  }

  private beforeEventEdit(): boolean {
    return false;
  }

  private schedulerRenderer(record: any): string {
    const path: string = this.checkInTimelines.find(
      (item: ICheckInTimeline): boolean => item.sourceId === Number(record.id),
    )?.avatar;

    if (path) {
      return `<img src="${path}" style="width: 35px; height: 35px; margin-right: 5px; border-radius: 17px""/><span>${record.sourceName}</span>`;
    } else {
      return `<em class="${record.iconClass}" style="font-size: 26px; margin-left: 6px; margin-right: 10px"></em><span>${record.sourceName}</span>`;
    }
  }

  private schedulerEventRenderer(eventRecord: any, renderData: any): string {
    const randomColor: string = this.getOrGenerateColorForItem(eventRecord.data.id);
    const isTypeOfNumber: boolean = typeof this.selectedLineIds === 'number';
    renderData.style = `color: black; background-color: ${randomColor}`;

    if (this.chartMode === EChartMode.SHOW_ALL_LINES && !isTypeOfNumber) {
      renderData.style = `color: black; background-color: ${randomColor}`;
    } else if (
      this.chartMode === EChartMode.HIGHLIGHT_FILTERED_LINES &&
      !isTypeOfNumber &&
      !this.selectedLineIds.some((item: number): boolean => item === eventRecord.originalData.lineId)
    ) {
      renderData.style = `opacity:0.3; color: black; background-color: ${randomColor}`;
    }

    return eventRecord.name;
  }

  private prepareScheduler(): void {
    this.schedulerConfig = {
      columns: [
        {
          id: this.sourceColumnId,
          type: 'tree',
          field: this.sourceColumnId,
          sortable: false,
          text: this.isLaborLogsTimelinePage
            ? this.translate.instant('checkInTimeline.header.laborName')
            : this.translate.instant('checkInTimeline.header.equipmentName'),
          width: 250,
          editor: false,
          enableCellContextMenu: false,
          enableHeaderContextMenu: false,
          htmlEncode: false,
          renderer: ({ record }): string => {
            return this.schedulerRenderer(record);
          },
        },
      ],
      emptyText: this.translate.instant('ganttChart.noRecordsToDisplay.message'),
      height: this.schedulerHeight,
      viewPreset: this.schedulerViewPreset$,
      rowHeight: 40,
      eventRenderer: ({ eventRecord, renderData }): string => {
        return this.schedulerEventRenderer(eventRecord, renderData);
      },
      features: {
        eventMenu: {
          items: {
            deleteEvent: false,
            unassignEvent: false,
            splitEvent: false,
            editEvent: false,
            cutEvent: false,
            copyEvent: false,
          },
        },
        scheduleMenu: false,
        eventFilter: true,
        eventTooltip: this.eventTooltipFeature,
        eventDragCreate: false,
        cellEdit: false,
        timeRanges: {
          currentTimeLineUpdateInterval: this.currentTimeLineRefreshTimeLimitAsOneDay,
          showCurrentTimeLine: {
            startDate: new Date(moment().tz(this.timezone$).format(this.currentTimelineDateFormat)),
            name: moment().tz(this.timezone$).format('hh:mm'),
          },
          showHeaderElements: true,
        },
        eventCopyPaste: false,
        rowCopyPaste: false,
        timeAxisHeaderMenu: false,
      },
      maxZoomLevel: this.schedulerMaxZoomLevel,
      project: this.schedulerProject,
      minZoomLevel: this.schedulerMinZoomLevel,
      zoomKeepsOriginalTimespan: true,
      multiEventSelect: true,
      selectionMode: {
        row: false,
        multiSelect: false,
      },
      enableDeleteKey: false,
      createEventOnDblClick: false,
      resourceMargin: 5,
      appendTo: this.checkInTimelineSchedulerRef.nativeElement,
      cls: 'scheduler-home-page-gantt-chart',
      snap: true,
      snapRelativeToEventStartDate: true,
      enableEventAnimations: false,
      zoomOnTimeAxisDoubleClick: false,
    };

    this.scheduler = new Scheduler({ ...this.schedulerConfig });
    this.scheduler.features.timeRanges.timeRanges[0].startDate = moment()
      .tz(this.timezone$)
      .format(this.currentTimelineDateFormat);

    this.scheduler.on('beforeEventEdit', this.beforeEventEdit);
  }
}
