<input
  class="cursor-pointer scw-mat-input w-100"
  readonly
  type="text"
  aria-label="default time"
  [ngxTimepicker]="defaultValue"
  [format]="format"
  [value]="defaultTime"
  [class.has-errors]="!isValid"
/>
<ng-template class="error-message-required" [ngIf]="hint || errorText">
  <mat-hint [class.error]="errorText">
    <em class="sub-text-icon fas" [class.fa-exclamation-circle]="errorText" [class.fa-info-circle]="!errorText"></em>
    {{ errorText ?? hint }}
  </mat-hint>
</ng-template>
<ngx-material-timepicker
  [disableAnimation]="true"
  [theme]="theme"
  [confirmBtnTmpl]="confirmButton"
  [cancelBtnTmpl]="cancelButton"
  [defaultTime]="defaultTime"
  [appendToInput]="appendToInput"
  [enableKeyboardInput]="false"
  (timeSet)="timeSet($event)"
  #defaultValue
></ngx-material-timepicker>
<span aria-hidden class="fa-clock fas icon position-absolute"></span>

<ng-template #confirmButton>
  <scw-mat-button type="dark" size="sm">
    {{ 'general.apply' | translate | uppercase }}
  </scw-mat-button>
</ng-template>

<ng-template #cancelButton>
  <scw-mat-button type="ghost" size="sm">
    {{ 'general.cancel' | translate | uppercase }}
  </scw-mat-button>
</ng-template>
