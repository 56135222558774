import { Action } from '@ngrx/store';
import { ILaborAssetViewData } from '../../dashboards/labor-asset-view/labor-asset-view.model';
import { ILaborAssetHomeFilter } from '../../../view/home/labor-asset-home/labor-asset-home.model';

export enum ActionTypes {
  GET_LABOR_ASSET_HOME_DATA = '[LABOR ASSET HOME] GET LABOR ASSET HOME DATA',
  GET_LABOR_ASSET_HOME_DATA_COMPLETED = '[LABOR ASSET HOME] GET LABOR ASSET HOME DATA COMPLETED',
  FETCH_ERROR = '[LABOR ASSET HOME] FETCH ERROR',
}

export class GetLaborAssetHomeData implements Action {
  public readonly type = ActionTypes.GET_LABOR_ASSET_HOME_DATA;

  constructor(public lineId: number, public params: ILaborAssetHomeFilter, public siteChanged: boolean = false) {}
}
export class GetLaborAssetHomeDataCompleted implements Action {
  public readonly type = ActionTypes.GET_LABOR_ASSET_HOME_DATA_COMPLETED;

  constructor(public payload: ILaborAssetViewData[], public siteChanged: boolean = false) {}
}

export class FetchError implements Action {
  public readonly type = ActionTypes.FETCH_ERROR;

  constructor(public error: unknown) {}
}

export type HomeActions = GetLaborAssetHomeData | GetLaborAssetHomeDataCompleted | FetchError;
