import { Inject, Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { IFilterRequestParameter } from '../../../view/reports/check-in-timeline/check-in-timeline.model';
import { Observable } from 'rxjs';
import { GetManyResponseInterface } from '../../../shared/model/interface/crud-response-interface.model';
import { ICheckInTimelineResponseData } from './check-in-timeline.model';

@Injectable({
  providedIn: 'root',
})
export class CheckInTimelineService {
  private readonly CHECK_IN_TIMELINE: string = '/check-ins/timeline';
  constructor(private readonly http: HttpClient, @Inject('API_BASE_URL') private readonly api: string) {}

  public getCheckInTimeLineData(
    params: IFilterRequestParameter,
  ): Observable<GetManyResponseInterface<ICheckInTimelineResponseData>> {
    let httpParams: HttpParams = new HttpParams().set('sourceTypeId', params.sourceTypeId);

    httpParams = httpParams.set('startDate', params.startDate);
    httpParams = httpParams.set('endDate', params.endDate);

    if (params.siteId) {
      httpParams = httpParams.set('siteId', params.siteId);
    }

    if (params.sourceObjectIds?.length) {
      httpParams = httpParams.set('sourceObjectIds', JSON.stringify(params.sourceObjectIds));
    }

    return this.http.get<GetManyResponseInterface<ICheckInTimelineResponseData>>(
      `${this.api}${this.CHECK_IN_TIMELINE}`,
      { params: httpParams },
    );
  }
}
