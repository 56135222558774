import { Timestamp, UpdateData } from 'firebase/firestore';
import { Moment } from 'moment';

export interface INotificationFeedItem {
  notification: { title: string; body: string; tags?: string[]; url: string | null };
  createdAt: Timestamp;
  docId: string;
  openedAt: Timestamp | null;
  readAt: Timestamp | null;
}

export interface IUpdatedDocument {
  id: string;
  updateData: UpdateData<INotificationFeedItem>;
}

export enum ENotificationSubscriptionType {
  ALL = 'all',
  ONLY_UNREAD = 'only-unread',
}

export interface IFirebaseDateRange {
  startAt: Moment;
  endAt: Moment | null;
}
