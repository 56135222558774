import { Inject, Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { forkJoin, Observable } from 'rxjs';
import {
  BaseOneResponseInterface,
  GetManyResponseInterface,
} from '../../../shared/model/interface/crud-response-interface.model';
import {
  FetchManualCountReviewParams,
  ManualCountsReviewData,
  WorkOrderCountReviewChartDataModel,
  WorkOrderSensor,
} from './work-order-count-review.model';
import { ActivityLogsResponseInterface } from '../root-cause-analysis/root-cause-analysis.model';
import { IPeriodicOeeCalculationData } from '../periodic-oee-calculation-review/periodic-oee-calculation-review.model';
import { map } from 'rxjs/operators';
import { WorkOrderCountReviewFetchDataLoading } from './work-order-count-review.actions';
import { IWorkOrderCountReviewActivityLogBody } from '../../../view/reports/work-order-count-review/work-order-count-review.model';

@Injectable({
  providedIn: 'root',
})
export class ManualCountReviewService {
  private readonly routes = {
    periodicOeeCalculations: '/periodic-oee-calculations',
    activityLogs: '/activity-histories/activity-logs',
    manualCount: '/production-counts',
    sensorData: '/sensor-data',
  };

  constructor(private readonly http: HttpClient, @Inject('API_BASE_URL') private readonly baseUrl: string) {}

  public setParams(objectData: WorkOrderCountReviewFetchDataLoading): FetchManualCountReviewParams {
    let periodicOeeCalculationsParams: HttpParams = new HttpParams()
      .append('join', 'line')
      .append('join', 'line.site')
      .append('join', 'schedulerShift')
      .append('join', 'workOrderSchedule')
      .append('limit', 5000)
      .append('sort', 'id,DESC');

    const periodicOeeCalculationsFilter: object = {};

    if (objectData.tableQuery.workOrderId) {
      periodicOeeCalculationsFilter['work_order_schedule_id'] = { $eq: objectData.tableQuery.workOrderId };
    }

    periodicOeeCalculationsParams = periodicOeeCalculationsParams.append(
      's',
      JSON.stringify(periodicOeeCalculationsFilter),
    );

    const sensorDataParams: HttpParams = new HttpParams().append('workOrderId', objectData.tableQuery.workOrderId[0]);

    let workOrderCountReviewParams: HttpParams = new HttpParams()
      .append('join', 'workOrderSchedule')
      .append('join', 'user')
      .append('limit', 5000)
      .append('sort', 'id,DESC');

    const workOrderCountReviewFilter: object = {};

    if (objectData.tableQuery.workOrderId) {
      workOrderCountReviewFilter['work_order_id'] = { $eq: objectData.tableQuery.workOrderId };
    }

    workOrderCountReviewParams = workOrderCountReviewParams.append('s', JSON.stringify(workOrderCountReviewFilter));

    const activityLogsBody: IWorkOrderCountReviewActivityLogBody = {
      isBusinessDate: true,
      sites: -1,
      lines: -1,
      lineTypes: -1,
      activities: -1,
      products: -1,
      rootCauseGroups: -1,
      shifts: -1,
      advancedFilterPage: 'work-order-performance',
      advancedFilterParams: JSON.stringify({ $and: [] }),
      workOrders: objectData.tableQuery.workOrderId,
    };
    return { periodicOeeCalculationsParams, sensorDataParams, workOrderCountReviewParams, activityLogsBody };
  }

  public getChartData(
    activityLogsBody: IWorkOrderCountReviewActivityLogBody,
    periodicOeeCalculationsParams: HttpParams,
    sensorDataParams: HttpParams,
    workOrderCountReviewParams: HttpParams,
  ): Observable<WorkOrderCountReviewChartDataModel> {
    const observables: {
      activityLogs: Observable<BaseOneResponseInterface<ActivityLogsResponseInterface>>;
      periodicOeeCalculations: Observable<GetManyResponseInterface<IPeriodicOeeCalculationData>>;
      manualCountsSensorData: Observable<GetManyResponseInterface<WorkOrderSensor>>;
      manualCountsData: Observable<GetManyResponseInterface<ManualCountsReviewData>>;
    } = {
      activityLogs: this.http.post<BaseOneResponseInterface<ActivityLogsResponseInterface>>(
        `${this.baseUrl}${this.routes.activityLogs}`,
        activityLogsBody,
        {
          headers: new HttpHeaders({ 'X-HTTP-Method': 'GET' }),
        },
      ),
      periodicOeeCalculations: this.http.get<GetManyResponseInterface<IPeriodicOeeCalculationData>>(
        `${this.baseUrl}${this.routes.periodicOeeCalculations}`,
        { params: periodicOeeCalculationsParams },
      ),
      manualCountsSensorData: this.http.get<GetManyResponseInterface<WorkOrderSensor>>(
        `${this.baseUrl}${this.routes.sensorData}`,
        {
          params: sensorDataParams,
        },
      ),
      manualCountsData: this.http.get<GetManyResponseInterface<ManualCountsReviewData>>(
        `${this.baseUrl}${this.routes.manualCount}`,
        { params: workOrderCountReviewParams },
      ),
    };

    return forkJoin(observables).pipe(
      map((item: WorkOrderCountReviewChartDataModel) => {
        return {
          activityLogs: item.activityLogs,
          periodicOeeCalculations: item.periodicOeeCalculations,
          manualCountsSensorData: item.manualCountsSensorData,
          manualCountsData: item.manualCountsData,
        };
      }),
    );
  }
}
