import { IMicrostopsState } from './microstops.model';
import * as Actions from './microstops.actions';

export const microstopsInitialState: IMicrostopsState = {
  getMicrostopsAnalysisData: false,
  getMicrostopsAnalysisDataCompleted: false,
  getMicrostops: false,
  getMicrostopsCompleted: false,
  getMicrostopActiveLines: false,
  getMicrostopActiveLinesCompleted: false,
  microstopsAnalysisData: null,
  microstops: null,
  microstopActiveLines: null,
  sensorHistogramData: [],
  sensorHistogramDataLoading: false,
  sensorHistogramDataLoaded: false,
};

export function microstopsReducer(state = microstopsInitialState, action: Actions.MicrostopsActions): IMicrostopsState {
  switch (action.type) {
    case Actions.GET_MICROSTOPS_ANALYSIS_DATA:
      return {
        ...state,
        getMicrostopsAnalysisData: true,
        getMicrostopsAnalysisDataCompleted: false,
      };

    case Actions.GET_MICROSTOPS_ANALYSIS_DATA_COMPLETED:
      return {
        ...state,
        getMicrostopsAnalysisData: false,
        getMicrostopsAnalysisDataCompleted: true,
        microstopsAnalysisData: action.payload,
      };

    case Actions.GET_MICROSTOPS:
      return {
        ...state,
        getMicrostops: true,
        getMicrostopsCompleted: false,
      };

    case Actions.GET_MICROSTOPS_COMPLETED:
      return {
        ...state,
        getMicrostops: false,
        getMicrostopsCompleted: true,
        microstops: action.payload,
      };

    case Actions.GET_MICROSTOP_ACTIVE_LINES:
      return {
        ...state,
        getMicrostopActiveLines: true,
        getMicrostopActiveLinesCompleted: false,
      };

    case Actions.GET_MICROSTOP_ACTIVE_LINES_COMPLETED:
      return {
        ...state,
        getMicrostopActiveLines: false,
        getMicrostopActiveLinesCompleted: true,
        microstopActiveLines: action.payload,
      };

    case Actions.GET_SENSOR_HISTOGRAM_DATA_LOADING:
      return {
        ...state,
        sensorHistogramDataLoading: true,
        sensorHistogramDataLoaded: false,
      };

    case Actions.GET_SENSOR_HISTOGRAM_DATA_LOADED:
      return {
        ...state,
        sensorHistogramDataLoading: false,
        sensorHistogramDataLoaded: true,
        sensorHistogramData: action.payload,
      };

    case Actions.FETCH_ERROR:
      return {
        ...state,
        getMicrostopsAnalysisData: false,
        getMicrostopsAnalysisDataCompleted: false,
        getMicrostops: false,
        getMicrostopsCompleted: false,
        getMicrostopActiveLines: false,
        getMicrostopActiveLinesCompleted: false,
        sensorHistogramDataLoading: false,
        sensorHistogramDataLoaded: false,
      };

    default:
      return state;
  }
}
