import { WorkOrderGroupType } from '../../../store/work-order-schedule/work-order-schedule.model';
import { ICheckedInUsers } from './work-order-manual-count/work-order-manual-count.model';

export interface WorkOrder extends WorkOrderHeader {
  scheduledStartDateFromScheduler: any;
  workOrderButton: string;
  scheduledRunDate: string;
  scheduledEndDate: string;
  scheduledLine: number;
  scheduledLinePathId: number | null;
  assignedLinePathId: number | null;
  assignedLineIdWithinLinePath: number | null;
  siteId: number;
  workOrderGroup: WorkOrderGroupType;
  isLate: boolean;
  groupOrder?: number;
  workOrderLayout?: IWorkOrderCardLayout;
  productionStage?: string | number;
  sequenceNumber?: number;
  jobNumber?: string;
  woDueDate?: string;
  releaseDate?: string;
  scheduledPreDuration?: string;
  scheduledPostDuration?: string;
  scheduledRunDuration?: string;
  scheduledPreRunLaborHours?: string;
  scheduledRunLaborHours?: string;
  scheduledPostRunLaborHours?: string;
  workOrderCardLayout: IWorkOrderCardLayoutView;
  operationNumber?: number;
  processOrder?: string;
}

export interface WorkOrderHeader {
  id: number;
  woNumber: string;
  productId: string;
  quantityOrdered: any;
  productDescription: string;
  productName: string;
  predictedWoNumber?: string;
}

export interface ISelectWorkOrderModalConfigurations {
  siteId: number;
  siteName: string;
  lineId: number;
  lineType?: number;
  decimalScale?: number;
}

export interface PhaseTypesInterface {
  preRun: number;
  postRun: number;
  run: number;
}

export interface IManualCountConfiguration {
  workOrder: string;
  workOrderId: number;
  productId: string;
  productDescription: string;
  start: string;
  unitName: string;
  checkedInUsers?: ICheckedInUsers[] | string;
  end?: string;
  shiftId?: number;
  activityEnd?: string;
  isOngoingHour?: boolean;
  isWorkOrderFinalized?: boolean;
  isWorkOrderCanceled?: boolean;
}

export enum ECloseDialogMessage {
  CLOSE_DIALOG = 'CLOSE DIALOG',
  CANCEL_DIALOG = 'CANCEL DIALOG',
}

export interface IWorkOrderCardLayoutView {
  textTop: string;
  textBottom: string;
  textMiddle: string;
}

export interface IWorkOrderCardLayout {
  textTop: IWorkOrderCardLayoutText;
  textBottom: IWorkOrderCardLayoutText;
  textMiddle: IWorkOrderCardLayoutText;
}

export interface IWorkOrderCardLayoutText {
  field: string;
  value: string;
  isUsedInSelectWorkOrderCard?: boolean;
}

export enum ILayoutFieldType {
  DATE_FIELD,
  DATE_FIELD_WITH_TIME,
  DECIMAL_FIELD,
}

export interface IPredictedWorkOrderInfo {
  predictedWorkOrderNumber: string | null;
  parentTargetSpeed: string | null;
}

export interface ISimpleWorkOrderFinalizeData {
  activityId: number;
  taskId?: number | null;
}
