import { ChartHeightLevel } from './tasks-charts.model';

export class TasksChartsConstants {
  public static readonly minChartHeight: number = 400;
  public static readonly topChartsAdditionalBarsPerLevel: number = 10;
  public static readonly topChartsBarHeight: number = 21;
  public static readonly topChartsBottomPadding: number = 40;
  public static readonly topChartsMaxHeightLevel: ChartHeightLevel = 4;
  public static readonly topChartsMinHeightLevel: ChartHeightLevel = 0;
  public static readonly topChartsTopPadding: number = 14;
  public static readonly taskChartTopPadding: number = 45;
  public static readonly topChartsVisibleBars: number = 35;
}
