export interface ScwMatPickerRule {
  required?: boolean;
  custom?: boolean;
  message?: string;
  validator?: (value: any) => boolean;
}

export declare type ScwMatPickerType =
  | 'default'
  | 'planned-down-time'
  | 'busy'
  | 'unplanned-down-time'
  | 'attention'
  | 'missing-data'
  | 'run-time'
  | 'available';

export interface ScwMatPickerReturnInterface {
  text: string;
  type?: ScwMatPickerType;
  value: any;
}

export enum ScwMatPickerTypeEnum {
  DEFAULT = 'default',
  PLANNED_DOWN_TIME = 'planned-down-time',
  BUSY = 'busy',
  UNPLANNED_DOWN_TIME = 'unplanned-down-time',
  ATTENTION = 'attention',
  MISSING_DATA = 'missing-data',
  RUN_TIME = 'run-time',
  AVAILABLE = 'available',
}
