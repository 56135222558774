import { Component, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { ActivatedRoute, Router } from '@angular/router';
import * as _ from 'lodash';

@Component({
  selector: 'app-station-activity-history-button',
  templateUrl: './station-activity-history-button.component.html',
})
export class StationActivityHistoryButtonComponent implements OnInit {
  public buttonTranslation: string;
  private subscriptions: Subscription[] = [];

  constructor(
    private readonly activatedRoute: ActivatedRoute,
    public translate: TranslateService,
    private readonly router: Router,
  ) {}

  ngOnInit(): void {
    this.buttonTranslation = this.translate.instant('general.stationHome.stationActivityHistoryButton');
  }

  public onHistoryButtonClick(): void {
    const url: string[] = this.activatedRoute['_routerState'].snapshot.url.split('/');
    const stationId: string = _.last(url);
    this.router.navigate([`/station-activity-history`, stationId]);
  }

  public ngOnDestroy(): void {
    this.subscriptions.forEach((subscription: Subscription) => {
      subscription.unsubscribe();
    });
  }
}
