import { FilterUserActions, FilterUserActionTypes } from './user.actions';
import { UserInterface } from '../../settings/users/users.model';
import * as _ from 'lodash';

export interface FilterUserState {
  isLoading: boolean;
  isLoaded: boolean;
  data: UserInterface[];
  totalCount: number;
  allowNewRequest: boolean;
}

export const initialFilterUserState: FilterUserState = {
  isLoaded: false,
  isLoading: false,
  data: [],
  totalCount: 0,
  allowNewRequest: false,
};

export function filterUserReducer(state = initialFilterUserState, action: FilterUserActions): FilterUserState {
  switch (action.type) {
    case FilterUserActionTypes.StartLoadFilterUsers:
      return { ...state, isLoading: true };

    case FilterUserActionTypes.FilterUsersLoaded:
      return {
        ...state,
        data: action.isFetchMoreRequest ? _.uniqBy(state.data.concat(action.data), 'id') : action.data,
        allowNewRequest: action.totalCount > 0 && action.data.length > 0 && action.totalCount > action.data.length,
        totalCount: action.totalCount,
        isLoaded: true,
        isLoading: false,
      };

    case FilterUserActionTypes.ResetUserStore:
      return {
        ...state,
        isLoaded: false,
        isLoading: false,
        data: [],
      };
    default:
      return state;
  }
}
