import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { Store } from '@ngrx/store';
import * as oeeAppReducer from '../../../../store/oee.reducer';
import { PageHeaderStateInterface } from '../../../../store/page-header/page-header.model';
import * as PageHeaderActions from '../../../../store/page-header/page-header.actions';
import { IElementVisibilityTranslations } from '../../../service/page-header/page-header.model';

@Component({
  selector: 'app-element-visibility-button',
  templateUrl: './element-visibility-button.component.html',
})
export class ElementVisibilityButtonComponent implements OnInit, OnDestroy {
  @Input() elementVisibilityTranslations: IElementVisibilityTranslations;

  public isButtonDisabled: boolean = false;
  public isElementVisible: boolean = true;
  private readonly subscriptions: Subscription[] = [];

  constructor(private readonly store: Store<oeeAppReducer.OeeAppState>) {}

  public ngOnInit(): void {
    this.subscriptions.push(
      this.store.select('pageHeaderStore').subscribe((state: PageHeaderStateInterface): void => {
        this.isElementVisible = state.isElementVisible;
        this.isButtonDisabled = state.isVisibilityButtonDisabled;
      }),
    );
  }

  public elementVisibilityButtonClicked(): void {
    this.store.dispatch(new PageHeaderActions.UpdateElementVisibility(!this.isElementVisible));
  }

  public ngOnDestroy(): void {
    this.subscriptions.forEach((value: Subscription): void => {
      value.unsubscribe();
    });
  }
}
