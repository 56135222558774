import { Action } from '@ngrx/store';
import {
  DoNotDisturbScheduleCreateRequestBody,
  DoNotDisturbScheduleUpdateRequestBody,
  IDoNotDisturbScheduleCrudData,
  TDoNotDisturbScheduleBulkUpdateEntry,
} from '../../standalone/do-not-disturb-schedule/do-not-disturb-schedule.model';
import { GenericCrudRequestConstructionParameters } from '../../shared/model/interface/generic-api-request.model';
import { GetManyResponseInterface } from '../../shared/model/interface/crud-response-interface.model';
import { HttpErrorResponse } from '@angular/common/http';

export enum ActionTypes {
  AccountSaveClickedAction = '[Account Settings] Save Button Clicked',
  BulkUpdateDoNotDisturbScheduleAction = '[DoNotDisturbSchedule] Bulk Update',
  BulkUpdateDoNotDisturbScheduleSuccessAction = '[DoNotDisturbSchedule] Bulk Update Success',
  DoNotDisturbSettingsInitializedAction = '[DoNotDisturbSchedule] Settings Initialized',
  FetchDoNotDisturbSchedules = '[DoNotDisturbSchedule] Fetch Many',
  FetchDoNotDisturbSchedulesLoaded = '[DoNotDisturbSchedule] Fetch Many Loaded',
  ResetActiveStateAction = '[DoNotDisturbSchedule] Reset Active State',
  SetActiveStateUnsavedAction = '[DoNotDisturbSchedule] Set Active Dirty',
  CreateDoNotDisturbSchedule = '[DoNotDisturbSchedule] Create',
  CreateDoNotDisturbScheduleLoaded = '[DoNotDisturbSchedule] Create Loaded',
  UpdateDoNotDisturbSchedule = '[DoNotDisturbSchedule] Update',
  UpdateDoNotDisturbScheduleLoaded = '[DoNotDisturbSchedule] Update Loaded',
  DeleteDoNotDisturbSchedule = '[DoNotDisturbSchedule] Delete',
  DeleteDoNotDisturbScheduleLoaded = '[DoNotDisturbSchedule] Delete Loaded',
  FetchError = '[DoNotDisturbSchedule] FetchError',
}

interface IDoNotDisturbAction extends Action {
  userId: number;
}

export class AccountSaveClicked implements Action {
  public readonly type = ActionTypes.AccountSaveClickedAction;

  constructor(
    public readonly schedules: readonly TDoNotDisturbScheduleBulkUpdateEntry[],
    public readonly userId: number,
  ) {}
}

export class BulkUpdateDoNotDisturbScheduleSuccess implements IDoNotDisturbAction {
  public readonly type = ActionTypes.BulkUpdateDoNotDisturbScheduleSuccessAction;
  constructor(public readonly userId: number) {}
}

export class DoNotDisturbSettingsInitialized implements Action {
  public readonly type = ActionTypes.DoNotDisturbSettingsInitializedAction;
}

export class FetchDoNotDisturbSchedules implements Action {
  readonly type = ActionTypes.FetchDoNotDisturbSchedules;

  constructor(public crudParameters: GenericCrudRequestConstructionParameters) {}
}

export class FetchDoNotDisturbSchedulesLoaded implements Action {
  readonly type = ActionTypes.FetchDoNotDisturbSchedulesLoaded;

  constructor(public response: GetManyResponseInterface<IDoNotDisturbScheduleCrudData>) {}
}

export class ResetActiveState implements Action {
  public readonly type = ActionTypes.ResetActiveStateAction;
}

export class SetActiveStateUnsaved implements Action {
  public readonly type = ActionTypes.SetActiveStateUnsavedAction;

  constructor(public readonly id: number) {}
}

export class CreateDoNotDisturbSchedule implements Action {
  readonly type = ActionTypes.CreateDoNotDisturbSchedule;

  constructor(public body: DoNotDisturbScheduleCreateRequestBody, public readonly userId: number) {}
}

export class CreateDoNotDisturbScheduleLoaded implements IDoNotDisturbAction {
  readonly type = ActionTypes.CreateDoNotDisturbScheduleLoaded;

  constructor(public readonly schedule: IDoNotDisturbScheduleCrudData, public readonly userId: number) {}
}

export class UpdateDoNotDisturbSchedule implements Action {
  readonly type = ActionTypes.UpdateDoNotDisturbSchedule;

  constructor(public id: number, public body: DoNotDisturbScheduleUpdateRequestBody, public readonly userId: number) {}
}

export class UpdateDoNotDisturbScheduleLoaded implements IDoNotDisturbAction {
  readonly type = ActionTypes.UpdateDoNotDisturbScheduleLoaded;

  constructor(public readonly schedule: IDoNotDisturbScheduleCrudData, public readonly userId: number) {}
}

export class DeleteDoNotDisturbSchedule implements Action {
  readonly type = ActionTypes.DeleteDoNotDisturbSchedule;

  constructor(public id: number, public readonly userId: number) {}
}

export class DeleteDoNotDisturbScheduleLoaded implements IDoNotDisturbAction {
  readonly type = ActionTypes.DeleteDoNotDisturbScheduleLoaded;

  constructor(public readonly userId: number) {}
}

export class FetchError implements Action {
  readonly type = ActionTypes.FetchError;

  constructor(public payload: HttpErrorResponse, actionType: ActionTypes) {}
}

export type FetchDoNotDisturbScheduleActions =
  | AccountSaveClicked
  | BulkUpdateDoNotDisturbScheduleSuccess
  | DoNotDisturbSettingsInitialized
  | FetchDoNotDisturbSchedules
  | FetchDoNotDisturbSchedulesLoaded
  | ResetActiveState
  | SetActiveStateUnsaved
  | CreateDoNotDisturbSchedule
  | CreateDoNotDisturbScheduleLoaded
  | UpdateDoNotDisturbSchedule
  | UpdateDoNotDisturbScheduleLoaded
  | DeleteDoNotDisturbSchedule
  | DeleteDoNotDisturbScheduleLoaded
  | FetchError;
