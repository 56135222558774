import * as Actions from './production-review-list-view.actions';
import { ProductionReviewListViewInterface } from './production-review-list-view.model';
import { IProductionReviewGanttDataInterface } from './production-review.model';
import { ELanguages, TOtherLanguageOptions } from '../../../constants';
import { PhasesInterface } from '../phase-selection/phase-selection.model';

export interface ProductionReviewListViewDataInterface {
  id: number;
  start: string;
  end: string;
  batch_number: string;
  manual_good_count: string;
  cumulative_total: string;
  has_same_start_end: boolean;
  has_same_sensor_data: boolean;
  product_id: string;
  product_description: string;
  unit_name: string;
  work_order_table_id: number;
  editable: number;
  activityStart: string;
  activityEnd: string;
  gantt_data: ProductionReviewGanttDataInterface[] | IProductionReviewGanttDataInterface[];
  actualScrapCount: string;
  switchStatus: boolean;
  actualManualCount?: number | null;
  actualInitialCount?: string;
  targetWorkOrderId: number | null;
  productionReviewTargetId: number | null;
  targetCount: string | null;
  targetWorkOrder: string | null;
  isApproved: number;
  isOngoingHour: number;
  isManualGoodCountClickable?: boolean;
  targetWoClickable?: boolean;
  description?: string | null;
  commentMessage?: string | null;
  commentId?: number | null;
  ongoingActivityDescription?: string | null;
  crewSize?: number | null;
  phaseId?: number | null;
  phaseName?: PhasesInterface;
  canceled: number;
}

export interface ProductionReviewGanttDataInterface {
  hasActivityComments: '0' | '1';
  id: number;
  missingData: boolean;
  batchNumber: string;
  activityStartT1: string;
  activityEndT1: string;
  activityGroupType: string;
  activityIndividualType: string;
  duration: number;
  start: string;
  end: string;
  type: string;
  startDateStr: number;
  endDateStr: number;
  details: string;
  workOrderTableId: string;
  bold: boolean;
  considerAsMissingData: boolean;
  language: ELanguages;
  ongoingActivityDescription: string | null;
  crewSize?: string | null;
  phaseId?: string | null;
  taskName?: string | null;
  taskMeta?: string | null;
  taskNameTranslations?: TOtherLanguageOptions;
  auditLogUuid: string | null;
}

export const productionReviewListViewInitialState: ProductionReviewListViewInterface = {
  isActivityLoading: false,
  isActivityLoaded: false,
  activityData: null,
};

export function productionReviewListViewReducer(
  state: ProductionReviewListViewInterface = productionReviewListViewInitialState,
  action: Actions.ProductionReviewListViewActions,
): ProductionReviewListViewInterface {
  switch (action.type) {
    case Actions.CLEAR_STATE:
      return Object.assign({}, productionReviewListViewInitialState);
    case Actions.START_ACTIVITY_DATA_LOADING:
      return {
        ...state,
        ...{
          isActivityLoading: true,
          isActivityLoaded: false,
        },
      };
    case Actions.SET_ACTIVITY_DATA_LOADED:
      return {
        ...state,
        ...{
          activityData: action.payload,
          isActivityLoading: false,
          isActivityLoaded: true,
        },
      };
    default:
      return state;
  }
}
