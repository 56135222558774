<div class="metric-item d-flex flex-column h-100 p-2 bg-c-lightGrey">
  <div class="nowrap text-center">
    <p class="head-text-2 c-title">{{ title }}</p>
    <div>
      <p class="head-text m-b-5" [ngClass]="">{{ averageOee }}%</p>
      <p class="metric-info" *ngIf="showMetricInfo">{{ averageValueInfoText }}</p>
    </div>
    <div class="d-flex justify-content-center">
      <div echarts [options]="option" class="bar bg-c-lightGrey text-center br-5 chart-option"></div>
    </div>
  </div>
</div>
