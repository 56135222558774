import { createAction, props } from '@ngrx/store';
import { SensorTypeEnum } from '../../../../constants';
import { IGetSensorsOfLinesResponse } from '../../../view/settings/home-page-display-settings/home-mini-metrics/home-mini-metrics-settings-container.types';
import { ITableQuery } from '../../../view/settings/departments-lines-stations/lines/lines.model';
import { ILayout } from '../home-page-display-settings/home-page-display-settings.model';

export const getSensorsOfLines = createAction(
  '[Home Mini KPI Settings] Get Sensors Of Lines',
  props<{ lineIds: number[]; sensorTypes: SensorTypeEnum[]; timezone: string }>(),
);

export const getSensorsOfLinesLoaded = createAction(
  '[Home Mini KPI Settings] Get Sensors Of Lines Loaded',
  props<{ sensors: IGetSensorsOfLinesResponse[] }>(),
);

export const getSensorsOfLinesFailed = createAction(
  '[Home Mini KPI Settings] Get Sensors Of Lines Failed',
  props<{ error: any }>(),
);

export const getLayoutData = createAction('[Home Mini KPI Settings] Get Layout Data', props<ITableQuery>());

export const getLayoutDataLoaded = createAction(
  '[Home Mini KPI Settings] Get Layout Data Loaded',
  props<{ layoutData: ILayout[] }>(),
);

export const getLayoutDataFailed = createAction(
  '[Home Mini KPI Settings] Get Layout Data Failed',
  props<{ error: any }>(),
);

export const resetState = createAction('[Home Mini KPI Settings] Reset State');
