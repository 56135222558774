import { Component, Input, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'color-tagged-list',
  standalone: true,
  imports: [CommonModule, TranslateModule],
  templateUrl: './color-tagged-list.component.html',
  styleUrls: ['./color-tagged-list.component.scss'],
})
export class ColorTaggedListComponent implements OnInit {
  @Input() topLabel: string = '';
  @Input() list: { name: string; color: string }[] = [];

  ngOnInit(): void {}
}
