import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { TSendVirtualCountsPayload } from '../../../../view/home/station-home/station-home-kpi-card/kpi-card-items/semi-manual-count-button-card/semi-manual-count-button-card.types';
import { Observable, Subject } from 'rxjs';
import { BaseOneResponseInterface } from '../../../model/interface/crud-response-interface.model';

@Injectable({ providedIn: 'root' })
export class SemiManualCountButtonService {
  public static sendCountsSubject: Subject<void> = new Subject<void>();

  private readonly routes = {
    sendVirtualCounts: `${this.baseURL}/production-counts/{station_id}/station-sensor-count`,
  };

  constructor(private readonly http: HttpClient, @Inject('API_BASE_URL') private readonly baseURL: string) {}

  public sendVirtualCounts(
    stationID: number,
    sensorReadCounts: TSendVirtualCountsPayload,
  ): Observable<BaseOneResponseInterface<number[]>> {
    const url: string = this.routes.sendVirtualCounts.replace('{station_id}', stationID.toString());

    return this.http.post<BaseOneResponseInterface<number[]>>(url, { data: sensorReadCounts });
  }
}
