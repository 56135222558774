import { Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root',
})
export class ActivityHistoryAuditLogModalComponentService {
  private readonly activityHistoryAuditLogBaseUrl = `${this.baseUrl}/audit-logs/activity-history`;

  constructor(private readonly http: HttpClient, @Inject('API_BASE_URL') private readonly baseUrl: string) {}

  public getActivityHistoryAuditLogData(uuid: string) {
    return this.http.get(`${this.activityHistoryAuditLogBaseUrl}/${uuid}`);
  }
}
