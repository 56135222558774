import { Action } from '@ngrx/store';
import { SiteCRUDInterface } from '../../../shared/component/filter/filter.class';
import {
  BulkResponseDataInterface,
  GetManyResponseInterface,
} from '../../../shared/model/interface/crud-response-interface.model';
import { ISiteRequestParams } from '../../../view/settings/sites/sites.model';
import { ISitesCrudResponse } from './sites.model';

export const LOAD_SITES_DATA = '[SITES] Load sites data';
export const LOADED_SITES_DATA = '[SITES] Loaded sites data';
export const SITES_FETCH_ERROR = '[SITES] Fetch Error';
export const SINGLE_EDIT_SITES_DATA = '[SITES] Request single edit sites data';
export const EDITED_SINGLE_EDIT_SITES_DATA = '[SITES] Edited single edit sites data';
export const EDIT_SITES_DATA = '[SITES] Request edit sites data';
export const EDITED_SITES_DATA = '[SITES] Edited sites data';
export const CREATE_SITE = '[SITES] Create site data';
export const CREATED_SITE = '[SITES] Created site data';
export const DELETE_SITE = '[SITES] Delete site';
export const DELETED_SITE = '[SITES] Deleted site';
export const DELETE_SITES = '[SITES] Delete sites';
export const DELETED_SITES = '[SITES] Deleted sites';

export class LoadSitesData implements Action {
  readonly type = LOAD_SITES_DATA;

  constructor(public requestParams: ISiteRequestParams, public getFileSizeLimit: boolean = false) {}
}

export class LoadedSitesData implements Action {
  readonly type = LOADED_SITES_DATA;

  constructor(public payload: GetManyResponseInterface<SiteCRUDInterface>) {}
}

export class SingleEditSitesData implements Action {
  readonly type = SINGLE_EDIT_SITES_DATA;

  constructor(public siteId: number, public payload: Partial<SiteCRUDInterface>) {}
}

export class EditedSingleEditSitesData implements Action {
  readonly type = EDITED_SINGLE_EDIT_SITES_DATA;

  constructor(public response: ISitesCrudResponse) {}
}

export class EditSitesData implements Action {
  readonly type = EDIT_SITES_DATA;

  constructor(public payload: Partial<SiteCRUDInterface>[]) {}
}

export class EditedSitesData implements Action {
  readonly type = EDITED_SITES_DATA;

  constructor(public response: BulkResponseDataInterface) {}
}

export class CreateSite implements Action {
  readonly type = CREATE_SITE;

  constructor(public payload: Partial<SiteCRUDInterface>) {}
}

export class CreatedSite implements Action {
  readonly type = CREATED_SITE;

  constructor(public response: ISitesCrudResponse) {}
}

export class DeleteSite implements Action {
  readonly type = DELETE_SITE;

  constructor(public siteId: number) {}
}

export class DeletedSite implements Action {
  readonly type = DELETED_SITE;

  constructor(public response: ISitesCrudResponse) {}
}

export class DeleteSites implements Action {
  readonly type = DELETE_SITES;

  constructor(public siteIds: number[]) {}
}

export class DeletedSites implements Action {
  readonly type = DELETED_SITES;

  constructor(public response: BulkResponseDataInterface) {}
}
export class SitesFetchError implements Action {
  readonly type = SITES_FETCH_ERROR;

  constructor(public payload: any[]) {}
}

export type SitesActions = LoadSitesData | LoadedSitesData | SitesFetchError;
