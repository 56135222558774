import { Action } from '@ngrx/store';
import { ActivitiesInterface } from '../../shared/model/interface/activities.model';

export enum ActionTypes {
  FetchActivitiesOfLine = '[ACTIVITY_BUTTON_MULTI_LINE] FETCH ACTIVITIES OF LINE',
  FetchActivitiesOfLineCompleted = '[ACTIVITY_BUTTON_MULTI_LINE] FETCH ACTIVITIES OF LINE COMPLETED',
}

export class FetchActivitiesOfLine implements Action {
  readonly type = ActionTypes.FetchActivitiesOfLine;

  constructor(public lineId: number) {}
}

export class FetchActivitiesOfLineCompleted implements Action {
  readonly type = ActionTypes.FetchActivitiesOfLineCompleted;

  constructor(public lineId: number, public activities: ActivitiesInterface[]) {}
}

export type ActivityButtonMultiLineActions = FetchActivitiesOfLine | FetchActivitiesOfLineCompleted;
