import { IUnitConversion } from '../settings/unit-conversion/unit-conversion.model';

export interface ILookupData {
  id: number;
  createdBy: string;
  createdAt: string;
  changedBy: string;
  type: number;
  name: string;
  value?: string;
  key?: string;
  baseUnitConversions?: IUnitConversion[];
  referenceUnitConversions?: IUnitConversion[];
}

export interface ILookupParameters {
  unit: ILookupData[];
  materialType: ILookupData[];
  planningType: ILookupData[];
}

export interface ILookupState {
  isLookupsDataLoading: boolean;
  isLookupsDataLoaded: boolean;
  lookupData: ILookupData[];
}

export enum ILookupType {
  MATERIAL_TYPE = 1,
  PRODUCT_UNIT = 2,
  PLANNING_TYPE = 3,
}
