import { TranslateService } from '@ngx-translate/core';
import { DatatableHeaderInterface } from '../../../../shared/component/datatable/datatable.model';
import { EColumnWidth } from '../../../../shared/service/datatable/datatable.model';
import { FilterCardOptionInterface } from '../../../../shared/component/filter/filter.class';
import { DropdownComponent } from '../../../../shared/component/filter/dropdown/dropdown.component';
import { Line, Site } from '../../../../shared/component/filter/filterable-objects.class';
import { ScwMatButtonGroupButtons } from '../../../../shared/component/scw-mat-ui/scw-mat-button-group/scw-mat-button-group.model';
import {
  DropdownOptionsInterface,
  ITableQuery,
  FloorPlanFormInterface,
  ELinePlanViewTypeEnum,
} from '../../../../store/settings/departments-lines-stations/floor-plans/floor-plans.model';
import { EFilterDropdownElements } from '../../../../store/filter/filter.model';

export function getTableHeaders(translateService: TranslateService): DatatableHeaderInterface[] {
  return [
    {
      value: null,
      name: '',
      sortable: false,
      width: EColumnWidth.checkbox,
    },
    {
      value: 'planFor',
      name: translateService.instant('general.dataTable.header.planFor'),
      sortable: true,
      selected: true,
    },
    {
      value: 'siteName',
      name: translateService.instant('general.dataTable.header.site'),
      sortable: true,
      selected: true,
    },
    {
      value: 'lineName',
      name: translateService.instant('general.dataTable.header.line'),
      sortable: true,
      selected: true,
    },
    {
      value: 'name',
      name: translateService.instant('general.dataTable.header.floorPlanName'),
      sortable: true,
      selected: true,
    },
    {
      value: 'preview',
      name: translateService.instant('general.dataTable.header.preview'),
      sortable: false,
      selected: true,
      width: '200px',
    },
  ];
}

export function getFilterOptions(translate: TranslateService): FilterCardOptionInterface {
  return {
    rows: [
      [
        {
          elementId: EFilterDropdownElements.siteMultiSelectDropdown,
          type: DropdownComponent,
          cls: 'col-md-3 col-sm-4',
          object: Site,
          outputOptions: {
            filterObjectId: 'siteIds',
            filterObjectProp: 'id',
            returnFilterObjectAllProp: false,
          },
          options: {
            singleSelection: false,
            badgeShowLimit: 1,
            text: translate.instant('filterCard.site.dropdownPlaceHolder'),
            isRequired: false,
            getInitData: true,
          },
        },
        {
          elementId: EFilterDropdownElements.lineMultiSelectDropdown,
          type: DropdownComponent,
          cls: 'col-md-3 col-sm-4',
          object: Line,
          outputOptions: {
            filterObjectId: 'lineIds',
            filterObjectProp: 'id',
            returnFilterObjectAllProp: false,
          },
          options: {
            singleSelection: false,
            badgeShowLimit: 1,
            text: translate.instant('filterCard.line.dropdownPlaceHolder'),
            isRequired: false,
            getInitData: true,
          },
        },
      ],
    ],
  };
}

export function getZoomButtonConfiguration(): ScwMatButtonGroupButtons[] {
  return [
    {
      iconClass: 'fa fa-search-plus',
      value: 'zoomIn',
      disabled: true,
    },
    {
      iconClass: 'fa fa-search-minus',
      value: 'zoomOut',
      disabled: true,
    },
  ];
}

export function getFloorPlanFormConfiguration(translateService: TranslateService): FloorPlanFormInterface {
  const requiredForFormTranslation: string = translateService.instant('scwMatForm.validation.required');
  return {
    floorPlanName: {
      isEnabled: true,
      value: null,
      rules: [
        {
          required: true,
          message: requiredForFormTranslation,
        },
      ],
    },
    floorPlanCategory: {
      isEnabled: true,
      value: null,
      rules: [
        {
          required: true,
          message: requiredForFormTranslation,
        },
      ],
    },
    site: {
      isEnabled: true,
      value: null,
      rules: [
        {
          required: true,
          message: requiredForFormTranslation,
        },
      ],
    },
    image: {
      value: null,
      rules: [
        {
          required: true,
        },
      ],
    },
    line: {
      isEnabled: true,
      value: null,
      rules: [
        {
          required: true,
          message: requiredForFormTranslation,
        },
      ],
    },
    pathName: {
      isEnabled: true,
      value: null,
      rules: [
        {
          required: true,
          message: requiredForFormTranslation,
        },
      ],
    }
  };
}

export function getFloorPlanCategoryData(translateService: TranslateService): DropdownOptionsInterface[] {
  return [
    {
      id: 1,
      name: translateService.instant('floorPlan.modal.site'),
    },
    {
      id: 2,
      name: translateService.instant('floorPlan.modal.line'),
    },
  ];
}

export function getDefaultTableQuery(): ITableQuery {
  return {
    page: 1,
    pageSize: 10,
    orderBy: 'id',
    orderDesc: true,
  };
}

export function getLinePlanViewTypeButtonData(translateService: TranslateService): ScwMatButtonGroupButtons[] {
  return [
    {
      text: translateService.instant('floorPlan.modal.cardView'),
      value: ELinePlanViewTypeEnum.cardView,
    },
    {
      text: translateService.instant('floorPlan.modal.mapView'),
      value: ELinePlanViewTypeEnum.mapView,
    },
  ];
}
