import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { UserSettingsService } from '../../../store/settings/users/user.service';
import { GetManyResponseInterface } from '../../model/interface/crud-response-interface.model';
import { Observable } from 'rxjs';
import { UserInterface } from '../../../store/settings/users/users.model';

@Injectable({
  providedIn: 'root',
})
export class UserService {
  constructor(public http: HttpClient, private userSettingsService: UserSettingsService) {}

  public getUsers(params?: HttpParams): Observable<GetManyResponseInterface<UserInterface>> {
    return this.userSettingsService.getUsersForDatatable(params);
  }
}
