import { ELineViewEnhancedResponseMode, ILineViewEnhanced, ISelectedWidget } from './line-view-enhanced.model';
import * as Actions from './line-view-enhanced.actions';
import {
  EWidgetType,
  WidgetConfigurationInterface
} from '../../../shared/component/page-configuration/page-configuration.model';

export const lineViewEnhancedInitialState: ILineViewEnhanced = {
  downTime: 0,
  idleTime: 0,
  runTime: 0,
  orderBy: '',
  runTimeAndDownTimeOee: '0',
  totalNumberOfUsers: 0,
  totalNumberOfAssets: 0,
  orderDesc: false,
  rows: [],
  sitesFilter: [],
  linesFilter: [],
  isSitesFilterLoading: false,
  isLinesFilterLoading: false,
  isTableColumnFilterLoading: false,
  isSitesFilterLoaded: false,
  isLinesFilterLoaded: false,
  isTableColumnFilterLoaded: false,
  selectedSiteFilters: [],
  selectedLineFilters: [],
  filterDataGenerated: false,
  isSiteFilterDataGenerated: false,
  isLineFilterDataGenerated: false,
  fileDownloadStatus: false,
  tableData: [],
  tableColumns: [],
  widgets: [],
  isTableDataLoading: false,
  isTableDataLoaded: false,
  isLineListVisible: false,
  isCardDataLoading: false,
  isCardDataLoaded: false,
  isWidgetsLoaded: false,
  isWidgetsGenerated: false,
  isFiltersAndSettingSaving: false,
  calculatedOeeDate: null,
  generatedWidgets: [],
  tableSettings: [],
  lineCountOfSite: 0,
  lineCountStatus: false,
  isActivityFilterDataGenerated: false,
  activitiesFilter: [],
  selectedActivityFilters: [],
  isActivityFilterLoading: false,
  isActivityFilterLoaded: false,
  generalConfiguration: [],
  sortOrders: [],
};

export function lineViewEnhancedReducer(
  state: ILineViewEnhanced = lineViewEnhancedInitialState,
  action: Actions.LineViewEnhancedEnhancedActions,
): ILineViewEnhanced {
  switch (action.type) {
    case Actions.LINE_VIEW_ENHANCED_FILTER_DATA_LOADING:
      return {
        ...state,
        isSitesFilterLoading: true,
        isLinesFilterLoading: true,
        isActivityFilterLoading: true,
        filterDataGenerated: false,
        isLineListVisible: false,
      };
    case Actions.LINE_VIEW_ENHANCED_FILTER_DATA_LOADED:
      return {
        ...state,
        sitesFilter: action.payload.data.sites,
        linesFilter: action.payload.data.lines,
        activitiesFilter: action.payload.data.activities,
        tableColumns: action.payload.data.table,
        widgets: action.payload.data.widgets,
        isSitesFilterLoading: false,
        isLinesFilterLoading: false,
        isTableColumnFilterLoading: false,
        isSitesFilterLoaded: true,
        isLinesFilterLoaded: true,
        isTableColumnFilterLoaded: true,
        isWidgetsLoaded: true,
        isWidgetsGenerated: false,
        isActivityFilterLoaded: true,
      };
    case Actions.LINE_VIEW_ENHANCED_SET_SELECTED_TABLE_COLUMN_SETTINGS:
      return {
        ...state,
        tableColumns: action.payload,
      };
    case Actions.SET_SELECTED_WIDGET_SETTINGS:
      const widgets: ISelectedWidget[] = action.payload.map((widget: WidgetConfigurationInterface<EWidgetType.KPI_CARD>): ISelectedWidget => {
        return { name: widget.name, selected: widget.selected };
      });
      return {
        ...state,
        widgets,
        generatedWidgets: action.payload,
        isWidgetsGenerated: true,
      };
    case Actions.LINE_VIEW_ENHANCED_DATA_LOADING:
      return {
        ...state,
        isTableDataLoading: true,
        isCardDataLoading: true,
        isCardDataLoaded: false,
        isTableDataLoaded: false,
      };
    case Actions.LINE_VIEW_ENHANCED_DATA_LOADED:
      let responseData: Partial<ILineViewEnhanced> = {
        isCardDataLoaded: true,
        isCardDataLoading: false,
        isTableDataLoading: false,
        downTime: action.payload.downTime,
        idleTime: action.payload.idleTime,
        runTime: action.payload.runTime,
        runTimeAndDownTimeOee: action.payload.todayOEE,
        calculatedOeeDate: action.payload.calculatedOeeDate,
        totalNumberOfUsers: action.payload.totalNumberOfUsers,
        totalNumberOfAssets: action.payload.totalNumberOfAssets,
      };

      if (action.mode === ELineViewEnhancedResponseMode.ALL) {
        responseData = Object.assign(responseData, {
          isTableDataLoading: false,
          isTableDataLoaded: true,
          isLineListVisible: true,
          isCardDataLoading: false,
          isCardDataLoaded: true,
          downTime: action.payload.downTime,
          idleTime: action.payload.idleTime,
          rows: action.payload.lines,
          runTime: action.payload.runTime,
          runTimeAndDownTimeOee: action.payload.todayOEE,
          tableData: action.payload.lines,
          calculatedOeeDate: action.payload.calculatedOeeDate,
          totalNumberOfUsers: action.payload.totalNumberOfUsers,
          totalNumberOfAssets: action.payload.totalNumberOfAssets,
        });
      }
      return {
        ...state,
        ...responseData,
      };
    case Actions.LINE_VIEW_ENHANCED_FILTERS_AND_SETTINGS_SAVED:
      return {
        ...state,
        isFiltersAndSettingSaving: false,
      };
    case Actions.LINE_VIEW_ENHANCED_FETCH_DATA_ERROR:
      return {
        ...state,
        isTableDataLoading: false,
        isCardDataLoading: false,
        isCardDataLoaded: true,
        isTableDataLoaded: true,
        downTime: 0,
        idleTime: 0,
        runTime: 0,
        totalNumberOfUsers: 0,
        totalNumberOfAssets: 0,
        runTimeAndDownTimeOee: '',
        rows: [],
        lineCountOfSite: 0,
        lineCountStatus: false,
      };
    case Actions.LINE_VIEW_ENHANCED_CANCEL_LOADING:
      return {
        ...state,
        isTableDataLoading: false,
        isCardDataLoading: false,
        isCardDataLoaded: true,
        isTableDataLoaded: false,
      };
    case Actions.SET_TABLE_SETTINGS:
      return {
        ...state,
        tableSettings: action.payload,
      };
    case Actions.LINE_VIEW_ENHANCED_SET_FILTER_SETTINGS:
      return {
        ...state,
        sitesFilter: action.siteIds ?? -1,
        linesFilter: action.lineIds ?? -1,
        activitiesFilter: action.activityIds ?? -1,
      };
    case Actions.LINE_COUNT_OF_SITES_LOADING:
      return {
        ...state,
        lineCountOfSite: 0,
        lineCountStatus: true,
      };
    case Actions.LINE_COUNT_OF_SITES_LOADED:
      return {
        ...state,
        lineCountOfSite: action.payload,
        lineCountStatus: false,
      };
    case Actions.SET_GENERAL_CONFIGURATION:
      return {
        ...state,
        generalConfiguration: action.payload,
      };
    case Actions.SET_SORT_ORDER_SETTINGS:
      return {
        ...state,
        sortOrders: action.payload,
      };
    default:
      return state;
  }
}
