import { Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { concatMap, of, timer } from 'rxjs';
import { catchError, switchMap } from 'rxjs/operators';
import { GetCurrentUserResponseInterface, IMaintenanceModeData } from './model';
import * as ObjectActions from './actions';
import * as UserActions from '../user/actions';
import * as LineActions from '../line/actions';
import * as _ from 'lodash';
import { MonitoringService } from '../../shared/service/error-service/monitoring.service';
import { User } from '../user/model';
import { BaseOneResponseInterface } from '../../shared/model/interface/crud-response-interface.model';
import { Action } from '@ngrx/store';
import { ScwFirebaseService } from '../../shared/service/firebase/firebase.service';

@Injectable()
export class AppEffects {
  constructor(
    private actions$: Actions<ObjectActions.AppActions>,
    private http: HttpClient,
    private monitoringService: MonitoringService,
    @Inject('API_BASE_URL') private baseUrl: string,
  ) {}

  private APP_URLS = {
    GET: {
      GET_CURRENT_USER: 'users/get-current-user',
    },
  };

  private actionsWillBeExecute: Action[] = [];

  getCurrentUser$ = createEffect(() => this.actions$.pipe(
    ofType(ObjectActions.GET_CURRENT_USER),
    switchMap((objectData: ObjectActions.GetCurrentUser) => {
      const selectedSiteLine = JSON.parse(localStorage.getItem('selectedSiteLine'));
      const params = {
        siteId: _.get(selectedSiteLine, 'siteId'),
        lineId: _.get(selectedSiteLine, 'lineId'),
      };

      let userState: User = null;

      return this.http
        .get<GetCurrentUserResponseInterface>(
          `${this.baseUrl}/${this.APP_URLS.GET.GET_CURRENT_USER}`,
          params.siteId && params.lineId ? { params } : {},
        )
        .pipe(
          switchMap((response) => {
            if (response.success) {
              this.actionsWillBeExecute.push(new UserActions.CurrentUserLoading());

              this.monitoringService.setAuthenticatedUser(
                response.data.userId.toString(),
                response.data.client.client,
              );

              MonitoringService.userInfoForPageView = {
                defaultSite: response.data.defaultSite ?? '',
                  userName: response.data.userName ?? '',
                  email: response.data.email ?? '',
                  userLevel: response.data.level.id ? response.data.level.id.toString() : '',
                };

              userState = {
                dateFormat: response.data.dateFormat,
                locale: response.data.locale,
                dateTimeFormat: `${response.data.dateFormat} LT`,
                timeFormat: 'LTS',
                timeFormatHourAndMinute: 'LT',
                language: response.data.language,
                timezone: response.data.timezone,
                email: response.data.email,
                isVerifiedByAdmin: response.data.isVerifiedByAdmin,
                isVerifiedByUser: response.data.isVerifiedByUser,
                siteId: response.data.selectedSite.siteId,
                lineId: response.data.selectedLine.lineId,
                username: response.data.userName,
                userId: response.data.userId.toString(10),
                userLevelId: response.data.levelId.toString(10),
                fullName: response.data.fullName,
                defaultSite: response.data.defaultSite,
                isNonVerifiedUserPassActive: response.data.client.isNonVerifiedUserPassActive,
                isLaborTrackerActive: response.data.selectedSite.isLaborTrackerActive,
                isAssetTrackerActive: response.data.selectedSite.isAssetTrackerActive,
                isDigitalFormsActive: response.data.selectedSite.isDigitalFormsActive,
                isOeeTrackerActive: response.data.selectedSite.isOeeTrackerActive,
                decimalScaleLimit: response.data.decimalScaleLimit,
                siteDecimalScaleLimit: response.data.selectedSite.siteDecimalScaleLimit,
                decimalSeparator: response.data.decimalSeparator,
                thousandSeparator: response.data.thousandSeparator,
                defaultSiteInfo: response.data.defaultSiteInfo,
                menuPermissions: response.data.menuPermissions,
                clientInfo: response.data.client,
                isStationTrackerActive: response.data.selectedSite.isStationTrackerActive,
                logbookAccessUrl: response.data?.client?.logbookAccessUrl,
                logbookAccess: response.data?.client?.logbookAccess,
                dateTimeFormatWithSecond: `${response.data.dateFormat} LTS`,
                weekStartDay: response.data.selectedSite.weekStartDay,
                appNotificationAccessUrl: response.data?.client?.appNotificationAccessUrl,
                level: response.data.level,
                inactivateUserWithoutValidation: response.data?.client?.inactivateUserWithoutValidation,
                oeeCalculateType: response.data?.client?.oeeCalculateType,
                accountType: response.data?.accountType,
                assignedHexboxId: response.data.assignedHexboxId,
                isIdpLoginEnforcedEmailControlActive: response.data?.client?.isIdpLoginEnforcedEmailControlActive,
              };

              ScwFirebaseService.pushNotificationsServiceUrl = response.data?.client?.appNotificationAccessUrl;

              if (response.data.authorizedSites && response.data.authorizedLines) {
                userState = {
                  ...userState,
                  authorizedSites: response.data.authorizedSites,
                  authorizedLines: response.data.authorizedLines,
                };
              }


              if (response.data?.selectedLine?.manualCountEntrySettings) {
                this.actionsWillBeExecute.push(
                  new LineActions.SetManualCountEntrySettings(response.data.selectedLine.manualCountEntrySettings),
                );
              }

              // TODO client's number code is added to the store temporarily
              if (response.data.client.clientCode) {
                userState = {
                  ...userState,
                  clientCode: response.data.client.clientCode,
                  clientNumber: response.data.client.client,
                };
              }

              if (response.data.client.gettingDataInterval) {
                userState = {
                  ...userState,
                  dataInterval: response.data.client.gettingDataInterval,
                };
              }

              if (response.data.client.oeeFormula) {
                userState = {
                  ...userState,
                  clientOeeFormula: response.data.client.oeeFormula,
                };
              }

              if (
                _.get(response.data.selectedSite, 'siteId', false) &&
                _.get(response.data.selectedSite, 'siteName', false) &&
                _.get(response.data.selectedLine, 'lineId', false) &&
                _.get(response.data.selectedLine, 'lineName', false)
              ) {
                userState = {
                  ...userState,
                  siteId: response.data.selectedSite.siteId,
                  siteName: response.data.selectedSite.siteName,
                  lineId: response.data.selectedLine.lineId,
                  lineName: response.data.selectedLine.lineName,
                };
              }

              this.actionsWillBeExecute.push(new UserActions.CurrentUserDataLoaded(userState));
              this.actionsWillBeExecute.push(new UserActions.CurrentUserLoaded());
            }

            return of(...this.actionsWillBeExecute);
          }),
          catchError((error) => {
            if (error?.error?.message?.[0]?.['constraints']?.['unauthorizedSiteLine']) {
              localStorage?.removeItem('selectedSiteLine');
              params.siteId = null;
              params.lineId = null;

              return timer(200).pipe(
                concatMap(() =>
                  of(
                    ...[
                      new ObjectActions.GetCurrentUser(),
                    ],
                  ),
                ),
              );
            }

            return of(new ObjectActions.FetchError(error));
          }),
        );
    }),
  ));


  getGetMaintenanceModeData$ = createEffect(() => this.actions$.pipe(
    ofType(ObjectActions.GET_MAINTENANCE_MODE),
    switchMap(() => {
      return this.http.get<BaseOneResponseInterface<IMaintenanceModeData>>(`${this.baseUrl}/maintenance`).pipe(
        switchMap((response) => {
          return of(new ObjectActions.SetMaintenanceModeData(response.data));
        }),
        catchError((error) => {
          return of(new ObjectActions.FetchError(error));
        }),
      );
    }),
    catchError((error) => {
      return of(new ObjectActions.FetchError(error));
    }),
  ));
}
