import { createReducer, on } from '@ngrx/store';
import { ELoadStatus } from '../../../../constants.model';
import * as HomeMiniKpiSettingsActions from './home-mini-kpi-settings.actions';
import { IGetSensorsOfLinesResponse } from '../../../view/settings/home-page-display-settings/home-mini-metrics/home-mini-metrics-settings-container.types';
import { ILayout } from '../home-page-display-settings/home-page-display-settings.model';

export interface IHomeMiniMetricsKpiSettingsState {
  sensors: IGetSensorsOfLinesResponse[];
  sensorsLoadStatus: ELoadStatus;
  layoutData: ILayout[];
  layoutDataLoadStatus: ELoadStatus;
}

export const homeMiniKpiSettingsInitialState: IHomeMiniMetricsKpiSettingsState = {
  sensors: [],
  sensorsLoadStatus: ELoadStatus.Initial,
  layoutData: [],
  layoutDataLoadStatus: ELoadStatus.Initial,
};

export const homeMiniKpiSettingsReducer = createReducer(
  homeMiniKpiSettingsInitialState,
  on(HomeMiniKpiSettingsActions.getSensorsOfLines, (state) => ({
    ...state,
    sensorsLoadStatus: ELoadStatus.Loading,
  })),
  on(HomeMiniKpiSettingsActions.getSensorsOfLinesLoaded, (state, { sensors }) => ({
    ...state,
    sensors,
    sensorsLoadStatus: ELoadStatus.Success,
  })),
  on(HomeMiniKpiSettingsActions.getLayoutData, (state) => ({
    ...state,
    layoutDataLoadStatus: ELoadStatus.Loading,
  })),
  on(HomeMiniKpiSettingsActions.getLayoutDataLoaded, (state, { layoutData }) => ({
    ...state,
    layoutData,
    layoutDataLoadStatus: ELoadStatus.Success,
  })),
  on(HomeMiniKpiSettingsActions.getLayoutDataFailed, (state) => ({
    ...state,
    layoutDataLoadStatus: ELoadStatus.Failure,
  })),
  on(HomeMiniKpiSettingsActions.getSensorsOfLinesFailed, (state) => ({
    ...state,
    sensorsLoadStatus: ELoadStatus.Failure,
  })),
  on(HomeMiniKpiSettingsActions.resetState, () => homeMiniKpiSettingsInitialState),
);
