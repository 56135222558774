import { Action } from '@ngrx/store';
import { ILinePathAssignedWorkOrders } from './multi-line-change-activity.model';

export enum EMultiLineChangeActivityAction {
  LOAD_ONGOING_ACTIVITIES_OF_LINE_PATH = '[MULTI LINE CHANGE ACTIVITY] Load Ongoing Activities of Line Path',
  LOADED_ONGOING_ACTIVITIES_OF_LINE_PATH = '[MULTI LINE CHANGE ACTIVITY] Loaded Ongoing Activities of Line Path',
  FETCH_ERROR = '[MULTI LINE CHANGE ACTIVITY] Fetch Error',
}

export class LoadOngoingActivitiesOfLinePath implements Action {
  readonly type: EMultiLineChangeActivityAction.LOAD_ONGOING_ACTIVITIES_OF_LINE_PATH =
    EMultiLineChangeActivityAction.LOAD_ONGOING_ACTIVITIES_OF_LINE_PATH;

  constructor(public linePathWoSplitResults: number[], public linePathId: number) {}
}

export class LoadedOngoingActivitiesOfLinePath implements Action {
  readonly type: EMultiLineChangeActivityAction.LOADED_ONGOING_ACTIVITIES_OF_LINE_PATH =
    EMultiLineChangeActivityAction.LOADED_ONGOING_ACTIVITIES_OF_LINE_PATH;

  constructor(public data: ILinePathAssignedWorkOrders) {}
}

export class FetchError implements Action {
  readonly type: EMultiLineChangeActivityAction.FETCH_ERROR = EMultiLineChangeActivityAction.FETCH_ERROR;

  constructor(public payload: any[]) {}
}

export type MultiLineChangeActivityActions =
  | LoadOngoingActivitiesOfLinePath
  | LoadedOngoingActivitiesOfLinePath
  | FetchError;
