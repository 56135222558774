import { EOtherLanguages, TOtherLanguageOptions } from '../../../../constants';

export enum ETranslatableEntity {
  TASK = 'tasks',
  ACTIVITY = 'activities',
  EQUIPMENT_LIST = 'equipment_list',
  TREE_NODE = 'tree_nodes',
  PRODUCT = 'products',
}

export interface IEntityTranslation {
  entityId?: number;
  entityName?: ETranslatableEntity;
  language: EOtherLanguages;
  propertyName: string;
  value: string;
}

export interface IObjectWithEntityTranslations {
  entityTranslations?: IEntityTranslation[];
  originalEntityProperty?: Record<string, string>;
}

export interface IUpdatedEntityTranslation<T> {
  propertyName: Extract<keyof T, string>;
  language: EOtherLanguages;
  value: string;
}

export interface IEntityTranslationsOptions<T> {
  propertyName: Extract<keyof T, string>;
  languageOptions: TOtherLanguageOptions;
}
