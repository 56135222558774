<div [class.is-printable]="isPrintable" class="row d-flex align-items-stretch">
  <ng-container *ngFor="let configuration of configurations; index as i; trackBy: datatableHeaderTrackBy">
    <div
      *ngIf="configuration.content.type === 'kpiCard'"
      [ngClass]="configuration.content.class | callFunction : setKpiCardClasses : [i]"
    >
      <scw-mat-kpi-card
        class="scw-mat-kpi-card"
        [loading]="loading"
        [configuration]="configuration.content | callFunction : updateContent : [configuration.selected]"
        (inputModelChange)="selectBoxInputModelChange($event)"
      ></scw-mat-kpi-card>
    </div>
  </ng-container>
</div>
