import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, switchMap } from 'rxjs/operators';
import { from, of } from 'rxjs';
import { ShiftService } from '../../../shared/service/filter/shift.service';
import { ShiftService as ShiftSummaryShiftService } from '../../../shared/service/filter/shift-summary/shift.service';
import { FilterShiftActions, FilterShiftActionTypes, FilterShiftsLoaded, GotShifts } from './shift.actions';
import { ShiftDataModelInterface } from './shift.model';
import { ApiServer } from '../../../shared/component/filter/dropdown/dropdown.model';
import * as ObjectActions from '../shift/shift.actions';
import { ShiftDetailInterface } from '../../../shared/service/filter/service.class';
import { GetManyResponseInterface } from '../../../shared/model/interface/crud-response-interface.model';
import { IDateShifts } from '../../production-review/production-review.model';
import { ProductionReviewService } from '../../production-review/production-review.service';
import { HttpParams } from '@angular/common/http';
import { Store } from '@ngrx/store';
import * as oeeAppReducer from '../../oee.reducer';
import * as AppActions from '../../app/actions';

@Injectable()
export class FilterShiftEffects {
  loadFilterShifts$ = createEffect(() =>
    this.actions$.pipe(
      ofType(FilterShiftActionTypes.StartLoadFilterShifts),
      switchMap((objectData: ObjectActions.StartLoadFilterShifts) => {
        if (objectData.server === ApiServer.NestJS) {
          let data: Promise<ShiftDetailInterface[]>;

          if (objectData.dateRange === null && objectData.siteIds === null) {
            data = this.shiftService.getData();
          } else {
            data = this.shiftService.getBusinessShiftData(objectData.dateRange, objectData.siteIds, objectData.lineIds);
          }

          return from(data).pipe(
            switchMap((response: ShiftDetailInterface[]) => {
              let formattedResponse: ShiftDetailInterface[] = response;

              if (objectData.shouldIncludePlanName) {
                formattedResponse = formattedResponse.map((shiftData: ShiftDetailInterface) => {
                  return {
                    ...shiftData,
                    name: `${shiftData.shiftPlanName} - ${shiftData.name}`,
                  };
                });
              }

              return of(new FilterShiftsLoaded(formattedResponse));
            }),
            catchError((err) => {
              return of(new FilterShiftsLoaded([]));
            }),
          );
        }

        if (objectData.server === ApiServer.Phreeze) {
          return from(this.shiftSummaryShiftService.getData()).pipe(
            switchMap((response: ShiftDataModelInterface[]) => {
              return of(new FilterShiftsLoaded(response));
            }),
            catchError((err) => {
              return of(new FilterShiftsLoaded([]));
            }),
          );
        }

        return of(new FilterShiftsLoaded([]));
      }),
      catchError((errorRes) => {
        return of(new ObjectActions.FetchError(errorRes), new AppActions.HideLoader());
      }),
    ),
  );

  getShifts = createEffect(() =>
    this.actions$.pipe(
      ofType(FilterShiftActionTypes.GetShifts),
      switchMap((objectData: ObjectActions.GetShifts) => {
        const params = new HttpParams().set('startDate', objectData.payload.selectedDate).set('byBusinessDate', 'true');

        return this.productionReviewService.getShiftsByDate(objectData.payload.lineId, params).pipe(
          switchMap((resData: GetManyResponseInterface<IDateShifts>) => {
            return of(new GotShifts(resData.data));
          }),
          catchError((errorRes) => {
            return of(new ObjectActions.FetchError(errorRes), new AppActions.HideLoader());
          }),
        );
      }),
      catchError((errorRes) => {
        return of(new ObjectActions.FetchError(errorRes), new AppActions.HideLoader());
      }),
    ),
  );

  constructor(
    private actions$: Actions<FilterShiftActions>,
    public shiftService: ShiftService,
    public productionReviewService: ProductionReviewService,
    public shiftSummaryShiftService: ShiftSummaryShiftService,
    private store: Store<oeeAppReducer.OeeAppState>,
  ) {}
}
