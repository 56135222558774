import { TranslateService } from '@ngx-translate/core';
import { DropdownComponent } from '../../../shared/component/filter/dropdown/dropdown.component';
import { Site } from '../../../shared/component/filter/filterable-objects.class';
import { DecimalHelper } from '../../../shared/helper/decimal/decimal-helper';
import {
  CountTypeTypes,
  DataSourceTypes,
  EActualWorkOrderDuration,
  ECountType,
  EDataSource,
  EHomeMetrics,
  ELayout,
  EPropertyType,
  EShiftSplit,
  ETargetPostRunPhaseDuration,
  ETargetPreRunPhaseDuration,
  ETargetWorkOrderDuration,
  ETimeSpan,
  EUnitOfTime,
  LayoutTypes,
  TimeSpanTypes,
  UnitOfTimeTypes,
} from './home-page-display-settings.constants';
import { FilterCardOptionInterface } from '../../../shared/component/filter/filter.class';
import {
  IKpiMetricInformation,
  IMetricsWithCustomPredefined,
  IPreDefinedKPIMetricsWithProperties,
} from '../../home/home-metrics/speed-metric/speed-metric.model';
import {
  IDropdown,
  IDropdownOptions,
  IDropdownOptionsWithStringId,
  ILayoutFormRule,
  IPropertiesDropdowns,
  ITableQuery,
  IWorkOrderDurationData,
} from './home-page-display-settings.model';
import { IWOCardDropdownOptions, IWorkOrderCardEditForm } from './work-order-card/work-order-card.model';
import {
  EOeeMetric,
  IHomeMetricSetFormByPages,
  IHomeMetricSetFormRule,
  IHomeMetricSetPropertiesAndFormValues,
  IMetaDropdown,
  IOeeMetricPreDefined,
  ISpeedMeta,
} from './home-metrics/home-metrics.model';
import { GetQuantityFromSensorDataInterface } from '../../../store/line/model';
import * as _ from 'lodash';
import { HelperService } from '../../../shared/service/helper.service';
import { ScwMatRule } from '../../../shared/component/scw-mat-ui/scw-mat-select/scw-mat-select.model';
import { ICountMetricCalculationValues } from '../../home/home-metrics/count-metric/count-metric.model';
import { ScwMatButtonGroupButtons } from '../../../shared/component/scw-mat-ui/scw-mat-button-group/scw-mat-button-group.model';
import { WorkOrderDurationMetricComponent } from '../../home/home-metrics/work-order-duration-metric/work-order-duration-metric.component';
import { EComponentType, HomeMetrics } from '../../home/home-metrics/home-metrics.model';
import { SpeedMetricComponent } from '../../home/home-metrics/speed-metric/speed-metric.component';
import { ActivityDurationMetricComponent } from '../../home/home-metrics/activity-duration-metric/activity-duration-metric.component';
import { WorkOrderOeeMetricComponent } from '../../home/home-metrics/work-order-oee-metric/work-order-oee-metric.component';
import { WorkOrderCountBadgesComponent } from '../../home/home-metrics/work-order-count-badges/work-order-count-badges.component';
import { CommonPhaseDurationMetricComponent } from '../../home/home-metrics/common-phase-duration-metric/common-phase-duration-metric.component';
import { QuantityWithCommentMetricComponent } from '../../home/home-metrics/quantity-with-comment-metric/quantity-with-comment-metric.component';
import { WorkOrderOeeMetricSixHoursComponent } from '../../home/home-metrics/work-order-oee-metric-six-hours/work-order-oee-metric-six-hours.component';
import { WorkOrderOeeMetricTwelveHoursComponent } from '../../home/home-metrics/work-order-oee-metric-twelve-hours/work-order-oee-metric-twelve-hours.component';
import { CountMetricComponent } from '../../home/home-metrics/count-metric/count-metric.component';
import { ActivitiesTotalDurationMetricComponent } from '../../home/home-metrics/activities-total-duration-metric/activities-total-duration-metric.component';
import { ITableHeader } from '../../../../constants.model';
import { ShiftOeeMetricComponent } from '../../home/home-metrics/shift-oee-metric/shift-oee-metric.component';

export class HomePageDisplaySettingsUtilities {
  public static getWorkOrderCardData(
    translate: TranslateService,
    helperService: HelperService,
  ): IWOCardDropdownOptions[] {
    return [
      {
        id: 'wo_number',
        name: translate.instant('homePageDisplaySettings.workOrderCard.data.woNumber'),
        dummyData: '1',
      },
      {
        id: 'product_id',
        name: translate.instant('homePageDisplaySettings.workOrderCard.data.productId'),
        dummyData: translate.instant('homePageDisplaySettings.workOrderCard.dummyData.productId'),
      },
      {
        id: 'product_description',
        name: translate.instant('homePageDisplaySettings.workOrderCard.data.productDescription'),
        dummyData: translate.instant('homePageDisplaySettings.workOrderCard.dummyData.productDescription'),
      },
      {
        id: 'sequence_number',
        name: translate.instant('homePageDisplaySettings.workOrderCard.data.sequenceNumber'),
        dummyData: '10',
      },
      {
        id: 'job_number',
        name: translate.instant('homePageDisplaySettings.workOrderCard.data.jobNumber'),
        dummyData: translate.instant('homePageDisplaySettings.workOrderCard.dummyData.jobNumber'),
      },
      {
        id: 'wo_due_date',
        name: translate.instant('homePageDisplaySettings.workOrderCard.data.woDueDate'),
        dummyData: helperService.setUserDateTimeFormat('2023-01-01 00:00:00', true),
      },
      {
        id: 'release_date',
        name: translate.instant('homePageDisplaySettings.workOrderCard.data.releaseDate'),
        dummyData: helperService.setUserDateTimeFormat('2023-01-01 00:00:00'),
      },
      {
        id: 'quantity_ordered',
        name: translate.instant('homePageDisplaySettings.workOrderCard.data.quantityOrdered'),
        dummyData: '100',
      },
      {
        id: 'scheduled_run_date',
        name: translate.instant('homePageDisplaySettings.workOrderCard.data.scheduledRunDate'),
        dummyData: helperService.setUserDateTimeFormat('2023-01-01 00:00:00', true),
      },
      {
        id: 'scheduled_end_date',
        name: translate.instant('homePageDisplaySettings.workOrderCard.data.scheduledEndDate'),
        dummyData: helperService.setUserDateTimeFormat('2023-01-01 00:00:00', true),
      },
      {
        id: 'scheduled_pre_duration',
        name: translate.instant('homePageDisplaySettings.workOrderCard.data.scheduledPreDuration'),
        dummyData: '1.0',
      },
      {
        id: 'scheduled_post_duration',
        name: translate.instant('homePageDisplaySettings.workOrderCard.data.scheduledPostDuration'),
        dummyData: '1.0',
      },
      {
        id: 'scheduled_run_duration',
        name: translate.instant('homePageDisplaySettings.workOrderCard.data.scheduledRunDuration'),
        dummyData: '1.0',
      },
      {
        id: 'process_order',
        name: translate.instant('homePageDisplaySettings.workOrderCard.data.processOrder'),
        dummyData: '123Abc',
      },
      {
        id: 'operation_number',
        name: translate.instant('homePageDisplaySettings.workOrderCard.data.operationNumber'),
        dummyData: '123Abc',
      },
      {
        id: 'scheduled_pre_run_labor_hours',
        name: translate.instant('homePageDisplaySettings.workOrderCard.data.scheduledPreRunLaborHours'),
        dummyData: '1.0',
      },
      {
        id: 'scheduled_run_labor_hours',
        name: translate.instant('homePageDisplaySettings.workOrderCard.data.scheduledRunLaborHours'),
        dummyData: '1.0',
      },
      {
        id: 'scheduled_post_run_labor_hours',
        name: translate.instant('homePageDisplaySettings.workOrderCard.data.scheduledPostRunLaborHours'),
        dummyData: '1.0',
      },
    ];
  }

  public static getHomeMetricSetFormRules(translate: TranslateService): IHomeMetricSetFormRule {
    const requiredMessage: string = translate.instant('scwMatForm.validation.required');
    return {
      size: [
        {
          required: true,
          message: requiredMessage,
        },
      ],
      page: [
        {
          required: true,
          message: requiredMessage,
        },
      ],
      metric: [
        {
          required: true,
          message: requiredMessage,
        },
      ],
      homeMetricSetProperties: [
        {
          required: true,
          message: requiredMessage,
        },
      ],
    };
  }

  public static getPreDefinedKPIMetrics(translate: TranslateService): IDropdownOptionsWithStringId[] {
    return [
      {
        name: translate.instant('kpiCards.metrics.activities_total_duration'),
        id: EHomeMetrics.ACTIVITIES_TOTAL_DURATION,
      },
      {
        name: translate.instant('kpiCards.metrics.count'),
        id: EHomeMetrics.COUNT,
      },
      {
        name: translate.instant('kpiCards.metrics.pre_run_phase_duration'),
        id: EHomeMetrics.PRE_RUN_PHASE_DURATION,
      },
      {
        name: translate.instant('kpiCards.metrics.run_phase_duration'),
        id: EHomeMetrics.RUN_PHASE_DURATION,
      },
      {
        name: translate.instant('kpiCards.metrics.post_run_phase_duration'),
        id: EHomeMetrics.POST_RUN_PHASE_DURATION,
      },
      {
        name: translate.instant('kpiCards.metrics.quantity_with_comment'),
        id: EHomeMetrics.QUANTITY_WITH_COMMENT,
      },
      {
        name: translate.instant('kpiCards.metrics.sensor_badges'),
        id: EHomeMetrics.SENSOR_BADGES,
      },
      {
        name: translate.instant('kpiCards.metrics.shift_oee'),
        id: EHomeMetrics.SHIFT_OEE,
      },
      {
        name: translate.instant('kpiCards.metrics.speed'),
        id: EHomeMetrics.SPEED,
      },
      {
        name: translate.instant('kpiCards.metrics.activity_duration'),
        id: EHomeMetrics.ACTIVITY_DURATION,
      },
      {
        name: translate.instant('kpiCards.metrics.work_order_duration'),
        id: EHomeMetrics.WORK_ORDER_DURATION,
      },
      {
        name: translate.instant('kpiCards.metrics.work_order_oee'),
        id: EHomeMetrics.WORK_ORDER_OEE,
      },
      {
        name: translate.instant('kpiCards.metrics.work_order_oee_six_hours'),
        id: EHomeMetrics.WORK_ORDER_OEE_SIX_HOURS,
      },
      {
        name: translate.instant('kpiCards.metrics.work_order_oee_twelve_hours'),
        id: EHomeMetrics.WORK_ORDER_OEE_TWELVE_HOURS,
      },
    ];
  }

  public static getHomeMetricSetProperties(translate: TranslateService): IMetaDropdown {
    const oeeMetricDropdownOptions: IOeeMetricPreDefined = {
      metricLayout: [
        {
          id: EOeeMetric.OEE,
          name: translate.instant('homeScreenMetric.properties.metricLayout.oee'),
        },
        {
          id: EOeeMetric.A,
          name: translate.instant('homeScreenMetric.properties.metricLayout.a'),
        },
        {
          id: EOeeMetric.P,
          name: translate.instant('homeScreenMetric.properties.metricLayout.p'),
        },
        {
          id: EOeeMetric.Q,
          name: translate.instant('homeScreenMetric.properties.metricLayout.q'),
        },
      ],
    };

    return {
      speed: {
        layout: [
          {
            name: translate.instant('homeScreenMetric.properties.layout.percentage'),
            id: ELayout.PERCENTAGE,
          },
        ],
        dataSource: [
          {
            name: translate.instant('homeScreenMetric.properties.dataSource.sensor'),
            id: EDataSource.SENSOR,
          },
          {
            name: translate.instant('homeScreenMetric.properties.dataSource.hourly'),
            id: EDataSource.HOURLY,
          },
          {
            name: translate.instant('homeScreenMetric.properties.dataSource.workOrder'),
            id: EDataSource.WORK_ORDER,
          },
        ],
        countType: [
          {
            name: translate.instant('homeScreenMetric.properties.countType.initial'),
            id: ECountType.INITIAL,
          },
          {
            name: translate.instant('homeScreenMetric.properties.countType.good'),
            id: ECountType.GOOD,
          },
        ],
        unitOfCount: [],
        unitOfTime: [
          {
            name: translate.instant('homeScreenMetric.properties.unitOfTime.minute'),
            id: EUnitOfTime.MINUTE,
          },
          {
            name: translate.instant('homeScreenMetric.properties.unitOfTime.hour'),
            id: EUnitOfTime.HOUR,
          },
        ],
        timeSpan: [
          {
            name: translate.instant('homeScreenMetric.properties.timeSpan.oneMinute'),
            id: ETimeSpan.ONE_MINUTE,
          },
          {
            name: translate.instant('homeScreenMetric.properties.timeSpan.tenMinute'),
            id: ETimeSpan.TEN_MINUTES,
          },
          {
            name: translate.instant('homeScreenMetric.properties.timeSpan.currentActivity'),
            id: ETimeSpan.CURRENT_ACTIVITY,
          },
          {
            name: translate.instant('homeScreenMetric.properties.timeSpan.lastOneHour'),
            id: ETimeSpan.LAST_ONE_HOUR,
          },
          {
            name: translate.instant('homeScreenMetric.properties.timeSpan.lastTwoHours'),
            id: ETimeSpan.LAST_TWO_HOURS,
          },
          {
            name: translate.instant('homeScreenMetric.properties.timeSpan.lastThreeHours'),
            id: ETimeSpan.LAST_THREE_HOURS,
          },
          {
            name: translate.instant('homeScreenMetric.properties.timeSpan.workOrderRun'),
            id: ETimeSpan.LAST_THREE_HOURS,
          },
          {
            name: translate.instant('homeScreenMetric.properties.timeSpan.workOrderRunDown'),
            id: ETimeSpan.LAST_THREE_HOURS,
          },
          {
            name: translate.instant('homeScreenMetric.properties.timeSpan.workOrderRunDownIdle'),
            id: ETimeSpan.LAST_THREE_HOURS,
          },
        ],
      },
      work_order_duration: {
        actualWorkOrderDuration: [
          {
            name: translate.instant('homeScreenMetric.properties.actualWorkOrderDuration.runDuration'),
            id: EActualWorkOrderDuration.RUN_DURATION,
          },
          {
            name: translate.instant('homeScreenMetric.properties.actualWorkOrderDuration.runDownDuration'),
            id: EActualWorkOrderDuration.RUN_DOWN_DURATION,
          },
          {
            name: translate.instant('homeScreenMetric.properties.actualWorkOrderDuration.runDownIdleDuration'),
            id: EActualWorkOrderDuration.RUN_DOWN_IDLE_DURATION,
          },
          {
            name: translate.instant('homeScreenMetric.properties.actualWorkOrderDuration.runPhaseDuration'),
            id: EActualWorkOrderDuration.RUN_PHASE_DURATION,
          },
          {
            name: translate.instant('homeScreenMetric.properties.actualWorkOrderDuration.preRunPhaseDuration'),
            id: EActualWorkOrderDuration.PRE_RUN_PHASE_DURATION,
          },
          {
            name: translate.instant('homeScreenMetric.properties.actualWorkOrderDuration.postRunPhaseDuration'),
            id: EActualWorkOrderDuration.POST_RUN_PHASE_DURATION,
          },
          {
            name: translate.instant('homeScreenMetric.properties.actualWorkOrderDuration.totalPhaseDuration'),
            id: EActualWorkOrderDuration.TOTAL_PHASE_DURATION,
          },
        ],
        targetWorkOrderDuration: [
          {
            name: translate.instant('homeScreenMetric.properties.targetWorkOrderDuration.scheduledRunPhaseDuration'),
            id: ETargetWorkOrderDuration.SCHEDULED_RUN_PHASE_DURATION,
          },
          {
            name: translate.instant(
              'homeScreenMetric.properties.targetWorkOrderDuration.scheduledPreRunAndRunPhaseDuration',
            ),
            id: ETargetWorkOrderDuration.SCHEDULED_PRE_RUN_AND_RUN_PHASE_DURATION,
          },
          {
            name: translate.instant(
              'homeScreenMetric.properties.targetWorkOrderDuration.scheduledPostRunAndRunPhaseDuration',
            ),
            id: ETargetWorkOrderDuration.SCHEDULED_POST_RUN_AND_RUN_PHASE_DURATION,
          },
          {
            name: translate.instant('homeScreenMetric.properties.targetWorkOrderDuration.scheduledTotalPhaseDuration'),
            id: ETargetWorkOrderDuration.SCHEDULED_TOTAL_PHASE_DURATION,
          },
          {
            name: translate.instant(
              'homeScreenMetric.properties.targetWorkOrderDuration.scheduledTotalPhaseDurationQuantityBased',
            ),
            id: ETargetWorkOrderDuration.SCHEDULED_TOTAL_PHASE_DURATION_QUANTITY_BASED,
          },
          {
            name: translate.instant('homeScreenMetric.properties.targetWorkOrderDuration.quantityOrderedPerSpeed'),
            id: ETargetWorkOrderDuration.QUANTITY_ORDERED_PER_SPEED,
          },
          {
            name: translate.instant('homeScreenMetric.properties.targetWorkOrderDuration.taskTargetDuration'),
            id: ETargetWorkOrderDuration.TASK_TARGET_DURATION,
          },
        ],
      },
      count: {
        layout: [
          {
            name: translate.instant('homeScreenMetric.properties.layout.percentage'),
            id: ELayout.PERCENTAGE,
          },
          {
            name: translate.instant('homeScreenMetric.properties.layout.multipleUnits'),
            id: ELayout.MULTIPLE_UNITS,
          },
        ],
        dataSource: [
          {
            name: translate.instant('homeScreenMetric.properties.dataSource.hourly'),
            id: EDataSource.HOURLY,
          },
          {
            name: translate.instant('homeScreenMetric.properties.dataSource.sensor'),
            id: EDataSource.SENSOR,
          },
          {
            name: translate.instant('homeScreenMetric.properties.dataSource.shift'),
            id: EDataSource.SHIFT,
          },
          {
            name: translate.instant('homeScreenMetric.properties.dataSource.workOrder'),
            id: EDataSource.WORK_ORDER,
          },
        ],
        countType: [
          {
            name: translate.instant('homeScreenMetric.properties.countType.initial'),
            id: ECountType.INITIAL,
          },
          {
            name: translate.instant('homeScreenMetric.properties.countType.good'),
            id: ECountType.GOOD,
          },
          {
            name: translate.instant('homeScreenMetric.properties.countType.scrap'),
            id: ECountType.SCRAP,
          },
        ],
        timeSpan: [
          {
            name: translate.instant('homeScreenMetric.properties.timeSpan.currentActivity'),
            id: ETimeSpan.CURRENT_ACTIVITY,
          },
          {
            name: translate.instant('homeScreenMetric.properties.timeSpan.currentShift'),
            id: ETimeSpan.CURRENT_SHIFT,
          },
          {
            name: translate.instant('homeScreenMetric.properties.timeSpan.lastOneHour'),
            id: ETimeSpan.LAST_ONE_HOUR,
          },
          {
            name: translate.instant('homeScreenMetric.properties.timeSpan.lastTwoHours'),
            id: ETimeSpan.LAST_TWO_HOURS,
          },
          {
            name: translate.instant('homeScreenMetric.properties.timeSpan.lastThreeHours'),
            id: ETimeSpan.LAST_THREE_HOURS,
          },
          {
            name: translate.instant('homeScreenMetric.properties.timeSpan.workOrder'),
            id: ETimeSpan.WORK_ORDER,
          },
        ],
        unitOfCount: [],
      },
      activities_total_duration: {
        unitOfTime: [
          {
            name: translate.instant('homeScreenMetric.properties.unitOfTime.minute'),
            id: EUnitOfTime.MINUTE,
          },
          {
            name: translate.instant('homeScreenMetric.properties.unitOfTime.hour'),
            id: EUnitOfTime.HOUR,
          },
          {
            name: translate.instant('homeScreenMetric.properties.unitOfTime.percentage'),
            id: EUnitOfTime.PERCENTAGE,
          },
        ],
      },
      activity_duration: {
        shiftSplit: [
          {
            name: translate.instant('homeScreenMetric.properties.shiftSplit.yes'),
            id: EShiftSplit.YES,
          },
          {
            name: translate.instant('homeScreenMetric.properties.shiftSplit.no'),
            id: EShiftSplit.NO,
          },
        ],
      },
      work_order_oee: _.cloneDeep(oeeMetricDropdownOptions),
      shift_oee: _.cloneDeep(oeeMetricDropdownOptions),
      work_order_oee_six_hours: _.cloneDeep(oeeMetricDropdownOptions),
      work_order_oee_twelve_hours: _.cloneDeep(oeeMetricDropdownOptions),
      pre_run_phase_duration: {
        targetPreRunPhaseDuration: [
          {
            name: translate.instant(
              'homeScreenMetric.properties.targetPreRunPhaseDuration.scheduledPreRunPhaseDuration',
            ),
            id: ETargetPreRunPhaseDuration.SCHEDULED_PRE_RUN_PHASE_DURATION,
          },
          {
            name: translate.instant('homeScreenMetric.properties.targetPreRunPhaseDuration.taskTargetDuration'),
            id: ETargetPreRunPhaseDuration.TASK_TARGET_DURATION,
          },
        ],
      },
      post_run_phase_duration: {
        targetPostRunPhaseDuration: [
          {
            name: translate.instant(
              'homeScreenMetric.properties.targetPostRunPhaseDuration.scheduledPostRunPhaseDuration',
            ),
            id: ETargetPostRunPhaseDuration.SCHEDULED_POST_RUN_PHASE_DURATION,
          },
          {
            name: translate.instant('homeScreenMetric.properties.targetPostRunPhaseDuration.taskTargetDuration'),
            id: ETargetPostRunPhaseDuration.TASK_TARGET_DURATION,
          },
        ],
      },
    };
  }

  public static getHomeMetricWorkOrderTimeSpanProperties(
    translate: TranslateService,
    dataSource: DataSourceTypes,
    metric: EHomeMetrics,
  ): { id: TimeSpanTypes; name: string }[] {
    if (dataSource === EDataSource.SENSOR) {
      if (metric === EHomeMetrics.SPEED) {
        return [
          {
            name: translate.instant('homeScreenMetric.properties.timeSpan.oneMinute'),
            id: ETimeSpan.ONE_MINUTE,
          },
          {
            name: translate.instant('homeScreenMetric.properties.timeSpan.tenMinutes'),
            id: ETimeSpan.TEN_MINUTES,
          },
          {
            name: translate.instant('homeScreenMetric.properties.timeSpan.currentActivity'),
            id: ETimeSpan.CURRENT_ACTIVITY,
          },
        ];
      } else if (metric === EHomeMetrics.COUNT) {
        return [
          {
            name: translate.instant('homeScreenMetric.properties.timeSpan.currentActivity'),
            id: ETimeSpan.CURRENT_ACTIVITY,
          },
        ];
      }
    }

    if (dataSource === EDataSource.HOURLY) {
      if (metric === EHomeMetrics.SPEED || metric === EHomeMetrics.COUNT) {
        return [
          {
            name: translate.instant('homeScreenMetric.properties.timeSpan.lastOneHour'),
            id: ETimeSpan.LAST_ONE_HOUR,
          },
          {
            name: translate.instant('homeScreenMetric.properties.timeSpan.lastTwoHours'),
            id: ETimeSpan.LAST_TWO_HOURS,
          },
          {
            name: translate.instant('homeScreenMetric.properties.timeSpan.lastThreeHours'),
            id: ETimeSpan.LAST_THREE_HOURS,
          },
        ];
      }
    }

    if (dataSource === EDataSource.WORK_ORDER) {
      if (metric === EHomeMetrics.SPEED) {
        return [
          {
            name: translate.instant('homeScreenMetric.properties.timeSpan.workOrderRun'),
            id: ETimeSpan.WORK_ORDER_RUN,
          },
          {
            name: translate.instant('homeScreenMetric.properties.timeSpan.workOrderRunDown'),
            id: ETimeSpan.WORK_ORDER_RUN_DOWN,
          },
          {
            name: translate.instant('homeScreenMetric.properties.timeSpan.workOrderRunDownIdle'),
            id: ETimeSpan.WORK_ORDER_RUN_DOWN_IDLE,
          },
        ];
      } else if (metric === EHomeMetrics.COUNT) {
        return [
          {
            name: translate.instant('homeScreenMetric.properties.timeSpan.workOrder'),
            id: ETimeSpan.WORK_ORDER,
          },
        ];
      }
    }

    if (dataSource === EDataSource.SHIFT && metric === EHomeMetrics.COUNT) {
      return [
        {
          name: translate.instant('homeScreenMetric.properties.timeSpan.currentShift'),
          id: ETimeSpan.CURRENT_SHIFT,
        },
      ];
    }

    return [];
  }

  public static getHomeMetricUnitOfTimeProperties(
    translate: TranslateService,
  ): { id: UnitOfTimeTypes; name: string }[] {
    return [
      {
        name: translate.instant('homeScreenMetric.properties.unitOfTime.minute'),
        id: EUnitOfTime.MINUTE,
      },
      {
        name: translate.instant('homeScreenMetric.properties.unitOfTime.hour'),
        id: EUnitOfTime.HOUR,
      },
    ];
  }

  public static getKpiMetricSizeValues(): IDropdownOptions[] {
    return [
      { id: 2, name: '2' },
      { id: 3, name: '3' },
      { id: 4, name: '4' },
      { id: 6, name: '6' },
    ];
  }

  public static getPreDefinedSpeedMetricsWithProperties(
    translate: TranslateService,
    decimalHelper: DecimalHelper,
    metricValue: IKpiMetricInformation,
    properties: ISpeedMeta = null,
  ): IPreDefinedKPIMetricsWithProperties[] {
    const activitySpeedTitle: string = translate.instant('kpiCards.metrics.sensor_speed');
    const hourlySpeedTitle: string = translate.instant('kpiCards.metrics.hourly_speed');
    const workOrderSpeedTitle: string = translate.instant('kpiCards.metrics.work_order_speed');
    const oneMinute: string = '60';
    const tenMinute: string = '10';
    const expectedSpeed: string = metricValue?.expectedSpeed ? metricValue.expectedSpeed : '0';
    const totalDuration: string = metricValue?.totalDuration ? metricValue.totalDuration : '0';
    const totalDurationAsMinutes: string = decimalHelper.multiply(totalDuration, '60');
    const initialSensorCountInOneMinute: string = metricValue?.initialSensorCountInOneMinute
      ? metricValue.initialSensorCountInOneMinute
      : '0';
    const initialSensorCountInTenMinutes: string = metricValue?.initialSensorCountInTenMinutes
      ? metricValue.initialSensorCountInTenMinutes
      : '0';
    const initialSensorCount: string = metricValue?.initialSensorCount ? metricValue.initialSensorCount : '0';
    const initialCount: string = metricValue?.initialCount ? metricValue.initialCount : '0';
    const goodSensorCountInOneMinute: string = metricValue?.goodSensorCountInOneMinute
      ? metricValue.goodSensorCountInOneMinute
      : '0';
    const goodSensorCountInTenMinutes: string = metricValue?.goodSensorCountInTenMinutes
      ? metricValue.goodSensorCountInTenMinutes
      : '0';
    const goodSensorCount: string = metricValue?.goodSensorCount ? metricValue.goodSensorCount : '0';
    const goodCount: string = metricValue?.goodCount ? metricValue.goodCount : '0';
    const runDuration: string = metricValue?.runDuration ? metricValue.runDuration : '0';
    const runDownDuration: string = metricValue?.runDownDuration ? metricValue.runDownDuration : '0';
    const currentActivityDuration: string = metricValue?.currentActivityDuration
      ? decimalHelper.divide(metricValue.currentActivityDuration, oneMinute)
      : '0';
    const runDownIdleCssClass = [
      'fas fa-running c-green padding-lr',
      'fas fa-calendar c-red padding-lr',
      'fas fa-pause-circle c-grey padding-lr',
    ];
    const rundownCssClass = ['fas fa-running c-green padding-lr', 'fas fa-calendar c-red padding-lr'];
    const runCssClass = ['fas fa-running c-green padding-lr'];

    return [
      {
        name: activitySpeedTitle,
        formula: {
          actualValue: initialSensorCountInOneMinute,
          expectedValue: expectedSpeed,
        },
        meta: {
          layout: ELayout.PERCENTAGE,
          dataSource: EDataSource.SENSOR,
          countType: ECountType.INITIAL,
          timeSpan: ETimeSpan.ONE_MINUTE,
        },
      },
      {
        name: activitySpeedTitle,
        formula: {
          actualValue: decimalHelper.divide(initialSensorCountInTenMinutes, tenMinute),
          expectedValue: expectedSpeed,
        },
        meta: {
          layout: ELayout.PERCENTAGE,
          dataSource: EDataSource.SENSOR,
          countType: ECountType.INITIAL,
          timeSpan: ETimeSpan.TEN_MINUTES,
        },
      },
      {
        name: activitySpeedTitle,
        formula: {
          actualValue: decimalHelper.divide(initialSensorCount, currentActivityDuration),
          expectedValue: expectedSpeed,
        },
        meta: {
          layout: ELayout.PERCENTAGE,
          dataSource: EDataSource.SENSOR,
          countType: ECountType.INITIAL,
          timeSpan: ETimeSpan.CURRENT_ACTIVITY,
        },
      },
      {
        name: activitySpeedTitle,
        formula: {
          actualValue: goodSensorCountInOneMinute,
          expectedValue: expectedSpeed,
        },
        meta: {
          layout: ELayout.PERCENTAGE,
          dataSource: EDataSource.SENSOR,
          countType: ECountType.GOOD,
          timeSpan: ETimeSpan.ONE_MINUTE,
        },
      },
      {
        name: activitySpeedTitle,
        formula: {
          actualValue: decimalHelper.divide(goodSensorCountInTenMinutes, tenMinute),
          expectedValue: expectedSpeed,
        },
        meta: {
          layout: ELayout.PERCENTAGE,
          dataSource: EDataSource.SENSOR,
          countType: ECountType.GOOD,
          timeSpan: ETimeSpan.TEN_MINUTES,
        },
      },
      {
        name: activitySpeedTitle,
        formula: {
          actualValue: decimalHelper.divide(goodSensorCount, currentActivityDuration),
          expectedValue: expectedSpeed,
        },
        meta: {
          layout: ELayout.PERCENTAGE,
          dataSource: EDataSource.SENSOR,
          countType: ECountType.GOOD,
          timeSpan: ETimeSpan.CURRENT_ACTIVITY,
        },
      },
      {
        name: hourlySpeedTitle,
        formula: {
          actualValue: null,
          expectedValue: expectedSpeed,
        },
        meta: {
          layout: ELayout.PERCENTAGE,
          dataSource: EDataSource.HOURLY,
          countType: ECountType.INITIAL,
          timeSpan: ETimeSpan.LAST_ONE_HOUR,
        },
      },
      {
        name: hourlySpeedTitle,
        formula: {
          actualValue: null,
          expectedValue: expectedSpeed,
        },
        meta: {
          layout: ELayout.PERCENTAGE,
          dataSource: EDataSource.HOURLY,
          countType: ECountType.INITIAL,
          timeSpan: ETimeSpan.LAST_TWO_HOURS,
        },
      },
      {
        name: hourlySpeedTitle,
        formula: {
          actualValue: null,
          expectedValue: expectedSpeed,
        },
        meta: {
          layout: ELayout.PERCENTAGE,
          dataSource: EDataSource.HOURLY,
          countType: ECountType.INITIAL,
          timeSpan: ETimeSpan.LAST_THREE_HOURS,
        },
      },
      {
        name: hourlySpeedTitle,
        formula: {
          actualValue: null,
          expectedValue: expectedSpeed,
        },
        meta: {
          layout: ELayout.PERCENTAGE,
          dataSource: EDataSource.HOURLY,
          countType: ECountType.GOOD,
          timeSpan: ETimeSpan.LAST_ONE_HOUR,
        },
      },
      {
        name: hourlySpeedTitle,
        formula: {
          actualValue: null,
          expectedValue: expectedSpeed,
        },
        meta: {
          layout: ELayout.PERCENTAGE,
          dataSource: EDataSource.HOURLY,
          countType: ECountType.GOOD,
          timeSpan: ETimeSpan.LAST_TWO_HOURS,
        },
      },
      {
        name: hourlySpeedTitle,
        formula: {
          actualValue: null,
          expectedValue: expectedSpeed,
        },
        meta: {
          layout: ELayout.PERCENTAGE,
          dataSource: EDataSource.HOURLY,
          countType: ECountType.GOOD,
          timeSpan: ETimeSpan.LAST_THREE_HOURS,
        },
      },
      {
        name: workOrderSpeedTitle,
        formula: {
          actualValue: decimalHelper.divide(initialCount, runDuration),
          expectedValue: expectedSpeed,
        },
        meta: {
          layout: ELayout.PERCENTAGE,
          dataSource: EDataSource.WORK_ORDER,
          countType: ECountType.INITIAL,
          unitOfTime: EUnitOfTime.HOUR,
          timeSpan: ETimeSpan.WORK_ORDER_RUN,
          unitOfCount: properties?.unitOfCount,
        },
        iconClass: runCssClass,
      },
      {
        name: workOrderSpeedTitle,
        formula: {
          actualValue: decimalHelper.divide(initialCount, runDownDuration),
          expectedValue: expectedSpeed,
        },
        meta: {
          layout: ELayout.PERCENTAGE,
          dataSource: EDataSource.WORK_ORDER,
          countType: ECountType.INITIAL,
          unitOfTime: EUnitOfTime.HOUR,
          timeSpan: ETimeSpan.WORK_ORDER_RUN_DOWN,
          unitOfCount: properties?.unitOfCount,
        },
        iconClass: rundownCssClass,
      },
      {
        name: workOrderSpeedTitle,
        formula: {
          actualValue: decimalHelper.divide(initialCount, totalDurationAsMinutes),
          expectedValue: expectedSpeed,
        },
        meta: {
          layout: ELayout.PERCENTAGE,
          dataSource: EDataSource.WORK_ORDER,
          countType: ECountType.INITIAL,
          unitOfTime: EUnitOfTime.HOUR,
          timeSpan: ETimeSpan.WORK_ORDER_RUN_DOWN_IDLE,
          unitOfCount: properties?.unitOfCount,
        },
        iconClass: runDownIdleCssClass,
      },
      {
        name: workOrderSpeedTitle,
        formula: {
          actualValue: decimalHelper.divide(goodCount, runDuration),
          expectedValue: expectedSpeed,
        },
        meta: {
          layout: ELayout.PERCENTAGE,
          dataSource: EDataSource.WORK_ORDER,
          countType: ECountType.GOOD,
          unitOfTime: EUnitOfTime.HOUR,
          timeSpan: ETimeSpan.WORK_ORDER_RUN,
          unitOfCount: properties?.unitOfCount,
        },
        iconClass: runCssClass,
      },
      {
        name: workOrderSpeedTitle,
        formula: {
          actualValue: decimalHelper.divide(goodCount, runDownDuration),
          expectedValue: expectedSpeed,
        },
        meta: {
          layout: ELayout.PERCENTAGE,
          dataSource: EDataSource.WORK_ORDER,
          countType: ECountType.GOOD,
          unitOfTime: EUnitOfTime.HOUR,
          timeSpan: ETimeSpan.WORK_ORDER_RUN_DOWN,
          unitOfCount: properties?.unitOfCount,
        },
        iconClass: rundownCssClass,
      },
      {
        name: workOrderSpeedTitle,
        formula: {
          actualValue: decimalHelper.divide(goodCount, totalDurationAsMinutes),
          expectedValue: expectedSpeed,
        },
        meta: {
          layout: ELayout.PERCENTAGE,
          dataSource: EDataSource.WORK_ORDER,
          countType: ECountType.GOOD,
          unitOfTime: EUnitOfTime.HOUR,
          timeSpan: ETimeSpan.WORK_ORDER_RUN_DOWN_IDLE,
          unitOfCount: properties?.unitOfCount,
        },
        iconClass: runDownIdleCssClass,
      },
      {
        name: workOrderSpeedTitle,
        formula: {
          actualValue: decimalHelper.divide(initialCount, runDuration),
          expectedValue: expectedSpeed,
        },
        meta: {
          layout: ELayout.PERCENTAGE,
          dataSource: EDataSource.WORK_ORDER,
          countType: ECountType.INITIAL,
          unitOfTime: EUnitOfTime.MINUTE,
          timeSpan: ETimeSpan.WORK_ORDER_RUN,
          unitOfCount: properties?.unitOfCount,
        },
        iconClass: runCssClass,
      },
      {
        name: workOrderSpeedTitle,
        formula: {
          actualValue: decimalHelper.divide(initialCount, runDownDuration),
          expectedValue: expectedSpeed,
        },
        meta: {
          layout: ELayout.PERCENTAGE,
          dataSource: EDataSource.WORK_ORDER,
          countType: ECountType.INITIAL,
          unitOfTime: EUnitOfTime.MINUTE,
          timeSpan: ETimeSpan.WORK_ORDER_RUN_DOWN,
          unitOfCount: properties?.unitOfCount,
        },
        iconClass: rundownCssClass,
      },
      {
        name: workOrderSpeedTitle,
        formula: {
          actualValue: decimalHelper.divide(initialCount, totalDurationAsMinutes),
          expectedValue: expectedSpeed,
        },
        meta: {
          layout: ELayout.PERCENTAGE,
          dataSource: EDataSource.WORK_ORDER,
          countType: ECountType.INITIAL,
          unitOfTime: EUnitOfTime.MINUTE,
          timeSpan: ETimeSpan.WORK_ORDER_RUN_DOWN_IDLE,
          unitOfCount: properties?.unitOfCount,
        },
        iconClass: runDownIdleCssClass,
      },
      {
        name: workOrderSpeedTitle,
        formula: {
          actualValue: decimalHelper.divide(goodCount, runDuration),
          expectedValue: expectedSpeed,
        },
        meta: {
          layout: ELayout.PERCENTAGE,
          dataSource: EDataSource.WORK_ORDER,
          countType: ECountType.GOOD,
          unitOfTime: EUnitOfTime.MINUTE,
          timeSpan: ETimeSpan.WORK_ORDER_RUN,
          unitOfCount: properties?.unitOfCount,
        },
        iconClass: runCssClass,
      },
      {
        name: workOrderSpeedTitle,
        formula: {
          actualValue: decimalHelper.divide(goodCount, runDownDuration),
          expectedValue: expectedSpeed,
        },
        meta: {
          layout: ELayout.PERCENTAGE,
          dataSource: EDataSource.WORK_ORDER,
          countType: ECountType.GOOD,
          unitOfTime: EUnitOfTime.MINUTE,
          timeSpan: ETimeSpan.WORK_ORDER_RUN_DOWN,
          unitOfCount: properties?.unitOfCount,
        },
        iconClass: rundownCssClass,
      },
      {
        name: workOrderSpeedTitle,
        formula: {
          actualValue: decimalHelper.divide(goodCount, totalDurationAsMinutes),
          expectedValue: expectedSpeed,
        },
        meta: {
          layout: ELayout.PERCENTAGE,
          dataSource: EDataSource.WORK_ORDER,
          countType: ECountType.GOOD,
          unitOfTime: EUnitOfTime.MINUTE,
          timeSpan: ETimeSpan.WORK_ORDER_RUN_DOWN_IDLE,
          unitOfCount: properties?.unitOfCount,
        },
        iconClass: runDownIdleCssClass,
      },
    ];
  }

  public static getWOLayoutCardTemplateFormRules(translate: TranslateService): IWorkOrderCardEditForm {
    const requiredMessageKey: string = 'scwMatForm.validation.required';

    return {
      textTop: {
        isEnabled: true,
        value: [],
        rules: [
          {
            required: true,
            message: translate.instant(requiredMessageKey),
          },
        ],
      },
      textMiddle: {
        isEnabled: true,
        value: [],
        rules: [
          {
            required: true,
            message: translate.instant(requiredMessageKey),
          },
        ],
      },
      textBottom: {
        isEnabled: true,
        value: [],
        rules: [
          {
            required: true,
            message: translate.instant(requiredMessageKey),
          },
        ],
      },
    };
  }

  public static getDefaultTableQuery(): ITableQuery {
    return {
      page: 1,
      pageSize: 1000,
      orderBy: 'id',
      orderDesc: true,
      siteIds: [],
    };
  }

  public static getWOLayoutFormRules(translate: TranslateService): ILayoutFormRule {
    const requiredMessageKey: string = 'scwMatForm.validation.required';
    const maxLengthMessageKey: string = 'scwMatForm.validation.maxLength';

    return {
      siteIdRules: [
        {
          required: true,
          message: translate.instant(requiredMessageKey),
        },
      ],
      lineIdRules: [
        {
          required: true,
          message: translate.instant(requiredMessageKey),
        },
      ],
      layoutRules: [
        {
          required: true,
          message: translate.instant(requiredMessageKey),
        },
        {
          maxLength: 50,
          message: translate.instant(maxLengthMessageKey, { maxLength: 50 }),
        },
      ],
    };
  }

  static getFilterOptions(translate: TranslateService): FilterCardOptionInterface {
    return {
      rows: [
        [
          {
            elementId: 'siteDropdown',
            type: DropdownComponent,
            cls: 'col-md-3 col-sm-4',
            object: Site,
            outputOptions: {
              filterObjectId: 'siteIds',
              filterObjectProp: 'id',
              returnFilterObjectAllProp: false,
            },
            options: {
              getInitData: true,
              singleSelection: false,
              badgeShowLimit: 1,
              text: translate.instant('filterCard.site.dropdownPlaceHolder'),
              isRequired: false,
            },
          },
        ],
      ],
    };
  }

  public static getHomeMetricSetForm(homeMetricSetFormRules: IHomeMetricSetFormRule): IHomeMetricSetFormByPages {
    return {
      isUsedInProductionReview: { isEnabled: true, value: true },
      home: {
        size: { isEnabled: true, value: [{ id: 6, name: '6' }], rules: homeMetricSetFormRules.size },
        page: { isEnabled: true, value: null },
        metric: [
          {
            isEnabled: true,
            value: [],
            rules: homeMetricSetFormRules.metric,
            homeMetricSetProperties: null,
          },
          {
            isEnabled: true,
            value: [],
            rules: homeMetricSetFormRules.metric,
            homeMetricSetProperties: null,
          },
          {
            isEnabled: true,
            value: [],
            rules: homeMetricSetFormRules.metric,
            homeMetricSetProperties: null,
          },
          {
            isEnabled: true,
            value: [],
            rules: homeMetricSetFormRules.metric,
            homeMetricSetProperties: null,
          },
          {
            isEnabled: true,
            value: [],
            rules: homeMetricSetFormRules.metric,
            homeMetricSetProperties: null,
          },
          {
            isEnabled: true,
            value: [],
            rules: homeMetricSetFormRules.metric,
            homeMetricSetProperties: null,
          },
        ],
      },
      productionReview: {
        size: { isEnabled: true, value: [{ id: 6, name: '6' }], rules: homeMetricSetFormRules.size },
        page: { isEnabled: true, value: null },
        metric: [
          {
            isEnabled: true,
            value: [],
            rules: homeMetricSetFormRules.metric,
            homeMetricSetProperties: null,
          },
          {
            isEnabled: true,
            value: [],
            rules: homeMetricSetFormRules.metric,
            homeMetricSetProperties: null,
          },
          {
            isEnabled: true,
            value: [],
            rules: homeMetricSetFormRules.metric,
            homeMetricSetProperties: null,
          },
          {
            isEnabled: true,
            value: [],
            rules: homeMetricSetFormRules.metric,
            homeMetricSetProperties: null,
          },
          {
            isEnabled: true,
            value: [],
            rules: homeMetricSetFormRules.metric,
            homeMetricSetProperties: null,
          },
          {
            isEnabled: true,
            value: [],
            rules: homeMetricSetFormRules.metric,
            homeMetricSetProperties: null,
          },
        ],
      },
    };
  }

  public static getTargetWorkOrderDurationValues(
    quantities: GetQuantityFromSensorDataInterface,
    quantityOrderedPerSpeed: string,
    decimalHelper: DecimalHelper,
  ): IWorkOrderDurationData[] {
    const scheduledRunDuration: string = _.isNil(quantities.scheduledRunDuration)
      ? quantities.quantityBasedRunDuration
      : quantities.scheduledRunDuration;
    const scheduledPreRunDuration: string = _.isNil(quantities.scheduledPreRunDuration)
      ? quantities.matrixBasedPreRunDuration
      : quantities.scheduledPreRunDuration;
    const scheduledPostRunDuration: string = _.isNil(quantities.scheduledPostRunDuration)
      ? quantities.matrixBasedPostRunDuration
      : quantities.scheduledPostRunDuration;
    const scheduledTotalPhaseDuration: string = decimalHelper.add(
      decimalHelper.add(scheduledRunDuration, scheduledPreRunDuration),
      scheduledPostRunDuration,
    );
    const scheduledTotalPhaseDurationQuantityBased: string = decimalHelper.add(
      decimalHelper.add(scheduledPreRunDuration, scheduledRunDuration),
      scheduledPostRunDuration,
    );

    return [
      {
        name: ETargetWorkOrderDuration.SCHEDULED_RUN_PHASE_DURATION,
        value: scheduledRunDuration,
      },
      {
        name: ETargetWorkOrderDuration.SCHEDULED_PRE_RUN_AND_RUN_PHASE_DURATION,
        value: decimalHelper.add(scheduledRunDuration, scheduledPreRunDuration),
      },
      {
        name: ETargetWorkOrderDuration.SCHEDULED_POST_RUN_AND_RUN_PHASE_DURATION,
        value: decimalHelper.add(scheduledRunDuration, scheduledPostRunDuration),
      },
      {
        name: ETargetWorkOrderDuration.SCHEDULED_TOTAL_PHASE_DURATION,
        value: scheduledTotalPhaseDuration,
      },
      {
        name: ETargetWorkOrderDuration.SCHEDULED_TOTAL_PHASE_DURATION_QUANTITY_BASED,
        value: scheduledTotalPhaseDurationQuantityBased,
      },
      {
        name: ETargetWorkOrderDuration.QUANTITY_ORDERED_PER_SPEED,
        value: quantityOrderedPerSpeed,
      },
      {
        name: ETargetWorkOrderDuration.TASK_TARGET_DURATION,
        value: quantities.taskTarget,
      },
    ];
  }

  public static getActualWorkOrderDurationValues(
    quantities: GetQuantityFromSensorDataInterface,
    decimalHelper: DecimalHelper,
  ): IWorkOrderDurationData[] {
    return [
      {
        name: EActualWorkOrderDuration.RUN_DURATION,
        value: quantities.runDuration,
      },
      {
        name: EActualWorkOrderDuration.RUN_DOWN_DURATION,
        value: decimalHelper.add(quantities.runDuration, quantities.downDuration),
      },
      {
        name: EActualWorkOrderDuration.RUN_DOWN_IDLE_DURATION,
        value: decimalHelper.add(
          decimalHelper.add(quantities.runDuration, quantities.downDuration),
          quantities.idleDuration,
        ),
      },
      {
        name: EActualWorkOrderDuration.RUN_PHASE_DURATION,
        value: quantities.runPhaseDuration,
      },
      {
        name: EActualWorkOrderDuration.PRE_RUN_PHASE_DURATION,
        value: decimalHelper.add(quantities.preRunPhaseDuration, quantities.runPhaseDuration),
      },
      {
        name: EActualWorkOrderDuration.POST_RUN_PHASE_DURATION,
        value: decimalHelper.add(quantities.postRunPhaseDuration, quantities.runPhaseDuration),
      },
      {
        name: EActualWorkOrderDuration.TOTAL_PHASE_DURATION,
        value: decimalHelper.add(
          decimalHelper.add(quantities.runPhaseDuration, quantities.preRunPhaseDuration),
          quantities.postRunPhaseDuration,
        ),
      },
    ];
  }

  public static getPreDefinedCountMetricsWithProperties(
    translate: TranslateService,
    decimalHelper: DecimalHelper,
    metricValue: ICountMetricCalculationValues,
  ): IPreDefinedKPIMetricsWithProperties[] {
    const activitySensorCountTitle: string = translate.instant('kpiCards.metrics.sensor_count');
    const shiftCountTitle: string = translate.instant('kpiCards.metrics.shift_count');
    const hourlyCountTitle: string = translate.instant('kpiCards.metrics.hourly_count');
    const workOrderCountTitle: string = translate.instant('kpiCards.metrics.work_order_count');
    const expectedSpeed: string = metricValue?.expectedSpeed ? metricValue.expectedSpeed : '0';
    const initialSensorCount: string = metricValue?.initialSensorCount ? metricValue.initialSensorCount : '0';
    const initialCount: string = metricValue?.initialCount ? metricValue.initialCount : '0';
    const initialCountForOneHour: string = metricValue?.initialCountForOneHour
      ? metricValue.initialCountForOneHour
      : '0';
    const initialCountForTwoHour: string = metricValue?.initialCountForTwoHour
      ? metricValue.initialCountForTwoHour
      : '0';
    const initialCountForThreeHour: string = metricValue?.initialCountForThreeHour
      ? metricValue.initialCountForThreeHour
      : '0';
    const goodSensorCount: string = metricValue?.goodSensorCount ? metricValue.goodSensorCount : '0';
    const goodCount: string = metricValue?.goodCount ? metricValue.goodCount : '0';
    const goodCountForOneHour: string = metricValue?.goodCountForOneHour ? metricValue.goodCountForOneHour : '0';
    const goodCountForTwoHour: string = metricValue?.goodCountForTwoHour ? metricValue.goodCountForTwoHour : '0';
    const goodCountForThreeHour: string = metricValue?.goodCountForThreeHour ? metricValue.goodCountForThreeHour : '0';
    const scrapCount: string = metricValue?.scrapCount ? metricValue.scrapCount : '0';
    const scrapCountForOneHour: string = metricValue?.scrapCountForOneHour ? metricValue.scrapCountForOneHour : '0';
    const scrapCountForTwoHour: string = metricValue?.scrapCountForTwoHour ? metricValue.scrapCountForTwoHour : '0';
    const scrapCountForThreeHour: string = metricValue?.scrapCountForThreeHour
      ? metricValue.scrapCountForThreeHour
      : '0';
    const quantityOrdered: string = metricValue?.quantityOrdered ? metricValue.quantityOrdered : '0';
    const shiftCount: string = metricValue?.shiftCount ? metricValue.shiftCount.toString() : '0';
    const currentShiftInitialCount: string = metricValue?.currentShiftInitialCount
      ? metricValue.currentShiftInitialCount
      : '0';
    const currentShiftGoodCount: string = metricValue?.currentShiftGoodCount ? metricValue.currentShiftGoodCount : '0';
    const currentActivityDuration: string = metricValue?.currentActivityDuration
      ? metricValue.currentActivityDuration
      : '0';

    return [
      {
        name: activitySensorCountTitle,
        formula: {
          actualValue: initialSensorCount,
          expectedValue: decimalHelper.multiply(expectedSpeed, currentActivityDuration),
        },
        meta: {
          layout: ELayout.PERCENTAGE,
          dataSource: EDataSource.SENSOR,
          countType: ECountType.INITIAL,
          timeSpan: ETimeSpan.CURRENT_ACTIVITY,
        },
      },
      {
        name: activitySensorCountTitle,
        formula: {
          actualValue: goodSensorCount,
          expectedValue: decimalHelper.multiply(expectedSpeed, currentActivityDuration),
        },
        meta: {
          layout: ELayout.PERCENTAGE,
          dataSource: EDataSource.SENSOR,
          countType: ECountType.GOOD,
          timeSpan: ETimeSpan.CURRENT_ACTIVITY,
        },
      },
      {
        name: shiftCountTitle,
        formula: {
          actualValue: currentShiftInitialCount,
          expectedValue: decimalHelper.multiply(decimalHelper.multiply(expectedSpeed, shiftCount), '60'),
        },
        meta: {
          layout: ELayout.PERCENTAGE,
          dataSource: EDataSource.SHIFT,
          countType: ECountType.INITIAL,
          timeSpan: ETimeSpan.CURRENT_SHIFT,
        },
      },
      {
        name: shiftCountTitle,
        formula: {
          actualValue: currentShiftGoodCount,
          expectedValue: decimalHelper.multiply(decimalHelper.multiply(expectedSpeed, shiftCount), '60'),
        },
        meta: {
          layout: ELayout.PERCENTAGE,
          dataSource: EDataSource.SHIFT,
          countType: ECountType.GOOD,
          timeSpan: ETimeSpan.CURRENT_SHIFT,
        },
      },
      {
        name: hourlyCountTitle,
        formula: {
          actualValue: initialCountForOneHour,
          expectedValue: decimalHelper.multiply(expectedSpeed, '60'),
        },
        meta: {
          layout: ELayout.PERCENTAGE,
          dataSource: EDataSource.HOURLY,
          countType: ECountType.INITIAL,
          timeSpan: ETimeSpan.LAST_ONE_HOUR,
        },
      },
      {
        name: hourlyCountTitle,
        formula: {
          actualValue: initialCountForTwoHour,
          expectedValue: decimalHelper.multiply(expectedSpeed, '120'),
        },
        meta: {
          layout: ELayout.PERCENTAGE,
          dataSource: EDataSource.HOURLY,
          countType: ECountType.INITIAL,
          timeSpan: ETimeSpan.LAST_TWO_HOURS,
        },
      },
      {
        name: hourlyCountTitle,
        formula: {
          actualValue: initialCountForThreeHour,
          expectedValue: decimalHelper.multiply(expectedSpeed, '180'),
        },
        meta: {
          layout: ELayout.PERCENTAGE,
          dataSource: EDataSource.HOURLY,
          countType: ECountType.INITIAL,
          timeSpan: ETimeSpan.LAST_THREE_HOURS,
        },
      },
      {
        name: hourlyCountTitle,
        formula: {
          actualValue: goodCountForOneHour,
          expectedValue: decimalHelper.multiply(expectedSpeed, '60'),
        },
        meta: {
          layout: ELayout.PERCENTAGE,
          dataSource: EDataSource.HOURLY,
          countType: ECountType.GOOD,
          timeSpan: ETimeSpan.LAST_ONE_HOUR,
        },
      },
      {
        name: hourlyCountTitle,
        formula: {
          actualValue: goodCountForTwoHour,
          expectedValue: decimalHelper.multiply(expectedSpeed, '120'),
        },
        meta: {
          layout: ELayout.PERCENTAGE,
          dataSource: EDataSource.HOURLY,
          countType: ECountType.GOOD,
          timeSpan: ETimeSpan.LAST_TWO_HOURS,
        },
      },
      {
        name: hourlyCountTitle,
        formula: {
          actualValue: goodCountForThreeHour,
          expectedValue: decimalHelper.multiply(expectedSpeed, '180'),
        },
        meta: {
          layout: ELayout.PERCENTAGE,
          dataSource: EDataSource.HOURLY,
          countType: ECountType.GOOD,
          timeSpan: ETimeSpan.LAST_THREE_HOURS,
        },
      },
      {
        name: hourlyCountTitle,
        formula: {
          actualValue: scrapCountForOneHour,
          expectedValue: decimalHelper.multiply(expectedSpeed, '60'),
        },
        meta: {
          layout: ELayout.PERCENTAGE,
          dataSource: EDataSource.HOURLY,
          countType: ECountType.SCRAP,
          timeSpan: ETimeSpan.LAST_ONE_HOUR,
        },
      },
      {
        name: hourlyCountTitle,
        formula: {
          actualValue: scrapCountForTwoHour,
          expectedValue: decimalHelper.multiply(expectedSpeed, '120'),
        },
        meta: {
          layout: ELayout.PERCENTAGE,
          dataSource: EDataSource.HOURLY,
          countType: ECountType.SCRAP,
          timeSpan: ETimeSpan.LAST_TWO_HOURS,
        },
      },
      {
        name: hourlyCountTitle,
        formula: {
          actualValue: scrapCountForThreeHour,
          expectedValue: decimalHelper.multiply(expectedSpeed, '180'),
        },
        meta: {
          layout: ELayout.PERCENTAGE,
          dataSource: EDataSource.HOURLY,
          countType: ECountType.SCRAP,
          timeSpan: ETimeSpan.LAST_THREE_HOURS,
        },
      },
      {
        name: workOrderCountTitle,
        formula: {
          actualValue: initialCount,
          expectedValue: quantityOrdered,
        },
        meta: {
          layout: ELayout.PERCENTAGE,
          dataSource: EDataSource.WORK_ORDER,
          countType: ECountType.INITIAL,
          timeSpan: ETimeSpan.WORK_ORDER,
        },
      },
      {
        name: workOrderCountTitle,
        formula: {
          actualValue: goodCount,
          expectedValue: quantityOrdered,
        },
        meta: {
          layout: ELayout.PERCENTAGE,
          dataSource: EDataSource.WORK_ORDER,
          countType: ECountType.GOOD,
          timeSpan: ETimeSpan.WORK_ORDER,
        },
      },
      {
        name: workOrderCountTitle,
        formula: {
          actualValue: scrapCount,
          expectedValue: quantityOrdered,
        },
        meta: {
          layout: ELayout.PERCENTAGE,
          dataSource: EDataSource.WORK_ORDER,
          countType: ECountType.SCRAP,
          timeSpan: ETimeSpan.WORK_ORDER,
        },
      },
    ];
  }

  public static getFormValuesOfMetricProperties(
    homeMetricSetPropertiesFormRule: ScwMatRule[],
  ): IHomeMetricSetPropertiesAndFormValues[] {
    return [
      {
        metric: EHomeMetrics.SPEED,
        propertyType: EPropertyType.SPEED,
        properties: ['layout', 'dataSource', 'countType', 'unitOfCount', 'unitOfTime', 'timeSpan'],
        formValue: {
          layout: { isEnabled: true, value: [], rules: homeMetricSetPropertiesFormRule },
          dataSource: { isEnabled: true, value: [], rules: homeMetricSetPropertiesFormRule },
          countType: { isEnabled: true, value: [], rules: homeMetricSetPropertiesFormRule },
          unitOfCount: { isEnabled: false, value: [], rules: homeMetricSetPropertiesFormRule },
          unitOfTime: { isEnabled: false, value: [], rules: homeMetricSetPropertiesFormRule },
          timeSpan: { isEnabled: false, value: [], rules: homeMetricSetPropertiesFormRule },
        },
      },
      {
        metric: EHomeMetrics.WORK_ORDER_DURATION,
        propertyType: EPropertyType.DURATION,
        properties: ['actualWorkOrderDuration', 'targetWorkOrderDuration'],
        formValue: {
          actualWorkOrderDuration: { isEnabled: true, value: [], rules: homeMetricSetPropertiesFormRule },
          targetWorkOrderDuration: { isEnabled: true, value: [], rules: homeMetricSetPropertiesFormRule },
        },
      },
      {
        metric: EHomeMetrics.COUNT,
        propertyType: EPropertyType.COUNT,
        properties: ['layout', 'dataSource', 'countType', 'timeSpan', 'unitOfCount'],
        formValue: {
          layout: { isEnabled: true, value: [], rules: homeMetricSetPropertiesFormRule },
          dataSource: { isEnabled: true, value: [], rules: homeMetricSetPropertiesFormRule },
          countType: { isEnabled: false, value: [], rules: homeMetricSetPropertiesFormRule },
          timeSpan: { isEnabled: false, value: [], rules: homeMetricSetPropertiesFormRule },
          unitOfCount: { isEnabled: false, value: [], rules: homeMetricSetPropertiesFormRule },
        },
      },
      {
        metric: EHomeMetrics.ACTIVITIES_TOTAL_DURATION,
        propertyType: EPropertyType.ACTIVITIES_TOTAL_DURATION,
        properties: ['unitOfTime'],
        formValue: {
          unitOfTime: { isEnabled: true, value: [], rules: homeMetricSetPropertiesFormRule },
        },
      },
      {
        metric: EHomeMetrics.ACTIVITY_DURATION,
        propertyType: EPropertyType.ACTIVITY_DURATION,
        properties: ['shiftSplit'],
        formValue: {
          shiftSplit: { isEnabled: true, value: [], rules: homeMetricSetPropertiesFormRule },
        },
      },
      {
        metric: EHomeMetrics.WORK_ORDER_OEE,
        propertyType: EPropertyType.WORK_ORDER_OEE,
        properties: ['metricLayout'],
        formValue: {
          metricLayout: { isEnabled: true, value: [], rules: homeMetricSetPropertiesFormRule },
        },
      },
      {
        metric: EHomeMetrics.WORK_ORDER_OEE_SIX_HOURS,
        propertyType: EPropertyType.WORK_ORDER_OEE_SIX_HOURS,
        properties: ['metricLayout'],
        formValue: {
          metricLayout: { isEnabled: true, value: [], rules: homeMetricSetPropertiesFormRule },
        },
      },
      {
        metric: EHomeMetrics.WORK_ORDER_OEE_TWELVE_HOURS,
        propertyType: EPropertyType.WORK_ORDER_OEE_TWELVE_HOURS,
        properties: ['metricLayout'],
        formValue: {
          metricLayout: { isEnabled: true, value: [], rules: homeMetricSetPropertiesFormRule },
        },
      },
      {
        metric: EHomeMetrics.SHIFT_OEE,
        propertyType: EPropertyType.SHIFT_OEE,
        properties: ['metricLayout'],
        formValue: {
          metricLayout: { isEnabled: true, value: [], rules: homeMetricSetPropertiesFormRule },
        },
      },
      {
        metric: EHomeMetrics.PRE_RUN_PHASE_DURATION,
        propertyType: EPropertyType.DURATION,
        properties: ['targetPreRunPhaseDuration'],
        formValue: {
          targetPreRunPhaseDuration: { isEnabled: true, value: [], rules: homeMetricSetPropertiesFormRule },
        },
      },
      {
        metric: EHomeMetrics.POST_RUN_PHASE_DURATION,
        propertyType: EPropertyType.DURATION,
        properties: ['targetPostRunPhaseDuration'],
        formValue: {
          targetPostRunPhaseDuration: { isEnabled: true, value: [], rules: homeMetricSetPropertiesFormRule },
        },
      },
    ];
  }

  public static getCountTypeProperties(
    translate: TranslateService,
    dataSource: DataSourceTypes,
    metric: EHomeMetrics,
  ): { id: CountTypeTypes; name: string }[] {
    if (metric === EHomeMetrics.COUNT && (dataSource === EDataSource.HOURLY || dataSource === EDataSource.WORK_ORDER)) {
      return [
        {
          name: translate.instant('homeScreenMetric.properties.countType.initial'),
          id: ECountType.INITIAL,
        },
        {
          name: translate.instant('homeScreenMetric.properties.countType.good'),
          id: ECountType.GOOD,
        },
        {
          name: translate.instant('homeScreenMetric.properties.countType.scrap'),
          id: ECountType.SCRAP,
        },
      ];
    }

    if (
      dataSource === EDataSource.SHIFT ||
      dataSource === EDataSource.SENSOR ||
      dataSource === EDataSource.HOURLY ||
      dataSource === EDataSource.WORK_ORDER
    ) {
      return [
        {
          name: translate.instant('homeScreenMetric.properties.countType.initial'),
          id: ECountType.INITIAL,
        },
        {
          name: translate.instant('homeScreenMetric.properties.countType.good'),
          id: ECountType.GOOD,
        },
      ];
    }

    return [];
  }

  public static setTitleBracketInfo(
    translate: TranslateService,
    dataSource: string,
    countType: string,
    timeSpan: string,
  ): string {
    let countTypeTranslation: string = '';
    let timeSpanTranslation: string = '';

    if (!_.isNil(countType)) {
      countTypeTranslation = translate.instant(`homeScreenMetric.properties.countType.${countType}`);
    }

    if (!_.isNil(timeSpan) && dataSource === EDataSource.HOURLY) {
      timeSpanTranslation = translate.instant(`homeScreenMetric.properties.timeSpan.${timeSpan}`);
    }

    return `${countTypeTranslation}${countTypeTranslation && timeSpanTranslation ? ' - ' : ''}${timeSpanTranslation}`;
  }

  public static getPhaseButtonsData(translate: TranslateService): ScwMatButtonGroupButtons[] {
    return [
      { text: translate.instant('homeScreenMetrics.activityOrderTypes.pre'), value: null },
      { text: translate.instant('homeScreenMetrics.activityOrderTypes.run'), value: null },
      { text: translate.instant('homeScreenMetrics.activityOrderTypes.post'), value: null },
    ];
  }

  public static getComponentsOfHomeScreenMetrics(dashboardMode: boolean): HomeMetrics {
    return {
      workOrderDuration: {
        key: EHomeMetrics.WORK_ORDER_DURATION,
        component: WorkOrderDurationMetricComponent,
        componentType: EComponentType.DURATION,
        dashboardMode: undefined,
        homeMetricSetProperties: {
          actualWorkOrderDuration: EActualWorkOrderDuration.RUN_DOWN_IDLE_DURATION,
          targetWorkOrderDuration: ETargetWorkOrderDuration.QUANTITY_ORDERED_PER_SPEED,
        },
      },
      speed: {
        key: EHomeMetrics.SPEED,
        component: SpeedMetricComponent,
        componentType: EComponentType.SPEED,
        dashboardMode: dashboardMode,
        homeMetricSetProperties: {
          layout: ELayout.PERCENTAGE,
          dataSource: EDataSource.WORK_ORDER,
          countType: ECountType.INITIAL,
          unitOfTime: EUnitOfTime.HOUR,
          timeSpan: ETimeSpan.WORK_ORDER_RUN_DOWN_IDLE,
        },
      },
      activityDuration: {
        key: EHomeMetrics.ACTIVITY_DURATION,
        component: ActivityDurationMetricComponent,
        componentType: null,
        dashboardMode: undefined,
        homeMetricSetProperties: {
          shiftSplit: EShiftSplit.YES,
        },
      },
      workOrderOee: {
        key: EHomeMetrics.WORK_ORDER_OEE,
        component: WorkOrderOeeMetricComponent,
        componentType: null,
        dashboardMode: undefined,
        homeMetricSetProperties: {},
      },
      sensorBadges: {
        key: EHomeMetrics.SENSOR_BADGES,
        component: WorkOrderCountBadgesComponent,
        componentType: null,
        dashboardMode: undefined,
        homeMetricSetProperties: {},
      },
      preRunPhaseDuration: {
        key: EHomeMetrics.PRE_RUN_PHASE_DURATION,
        component: CommonPhaseDurationMetricComponent,
        componentType: EComponentType.PRE_RUN,
        dashboardMode: dashboardMode,
        homeMetricSetProperties: {},
      },
      runPhaseDuration: {
        key: EHomeMetrics.RUN_PHASE_DURATION,
        component: CommonPhaseDurationMetricComponent,
        componentType: EComponentType.RUN,
        dashboardMode: dashboardMode,
        homeMetricSetProperties: {},
      },
      postRunPhaseDuration: {
        key: EHomeMetrics.POST_RUN_PHASE_DURATION,
        component: CommonPhaseDurationMetricComponent,
        componentType: EComponentType.POST_RUN,
        dashboardMode: dashboardMode,
        homeMetricSetProperties: {},
      },
      quantityWithComment: {
        key: EHomeMetrics.QUANTITY_WITH_COMMENT,
        component: QuantityWithCommentMetricComponent,
        componentType: EComponentType.TOTAL,
        dashboardMode: dashboardMode,
        homeMetricSetProperties: {},
      },
      workOrderOeeSixHours: {
        key: EHomeMetrics.WORK_ORDER_OEE_SIX_HOURS,
        component: WorkOrderOeeMetricSixHoursComponent,
        componentType: null,
        dashboardMode: undefined,
        homeMetricSetProperties: {},
      },
      workOrderOeeTwelveHours: {
        key: EHomeMetrics.WORK_ORDER_OEE_TWELVE_HOURS,
        component: WorkOrderOeeMetricTwelveHoursComponent,
        componentType: null,
        dashboardMode: undefined,
        homeMetricSetProperties: {},
      },
      count: {
        key: EHomeMetrics.COUNT,
        component: CountMetricComponent,
        componentType: EComponentType.COUNT,
        dashboardMode: undefined,
        homeMetricSetProperties: {
          layout: ELayout.PERCENTAGE,
          dataSource: EDataSource.WORK_ORDER,
          countType: ECountType.INITIAL,
          unitOfTime: EUnitOfTime.HOUR,
          timeSpan: ETimeSpan.WORK_ORDER_RUN_DOWN_IDLE,
        },
      },
      activitiesTotalDuration: {
        key: EHomeMetrics.ACTIVITIES_TOTAL_DURATION,
        component: ActivitiesTotalDurationMetricComponent,
        componentType: null,
        dashboardMode: undefined,
        homeMetricSetProperties: {
          unitOfTime: EUnitOfTime.HOUR,
        },
      },
      shiftOee: {
        key: EHomeMetrics.SHIFT_OEE,
        component: ShiftOeeMetricComponent,
        componentType: null,
        dashboardMode: undefined,
        homeMetricSetProperties: {},
      },
    };
  }

  public static getActivitiesTotalDurationTableHeaders(translate: TranslateService): ITableHeader[] {
    return [
      {
        value: 'workOrder.woNumber',
        name: translate.instant('activityHistory.form.workOrderNumber'),
        sortable: false,
      },
      {
        value: 'activity.name',
        name: translate.instant('activityHistory.listView.headers.activity'),
        sortable: false,
      },
      {
        value: 'task.title',
        name: translate.instant('activityHistory.listView.headers.task'),
        sortable: false,
      },
      {
        value: 'start',
        name: translate.instant('activityHistory.listView.headers.start'),
      },
      {
        value: '',
        name: translate.instant('general.duration', {
          durationType: translate.instant('durationType.hoursAndMinutesAndSeconds'),
        }),
        sortable: false,
      },
    ];
  }

  public static getHomeMetricDataSourceProperties(
    translate: TranslateService,
    layout: LayoutTypes,
    metric: EHomeMetrics,
  ): { id: DataSourceTypes; name: string }[] {
    if (metric === EHomeMetrics.COUNT && layout === ELayout.PERCENTAGE) {
      return [
        {
          name: translate.instant('homeScreenMetric.properties.dataSource.hourly'),
          id: EDataSource.HOURLY,
        },
        {
          name: translate.instant('homeScreenMetric.properties.dataSource.sensor'),
          id: EDataSource.SENSOR,
        },
        {
          name: translate.instant('homeScreenMetric.properties.dataSource.shift'),
          id: EDataSource.SHIFT,
        },
        {
          name: translate.instant('homeScreenMetric.properties.dataSource.workOrder'),
          id: EDataSource.WORK_ORDER,
        },
      ];
    } else if (metric === EHomeMetrics.COUNT && layout === ELayout.MULTIPLE_UNITS) {
      return [
        {
          name: translate.instant('homeScreenMetric.properties.dataSource.workOrder'),
          id: EDataSource.WORK_ORDER,
        },
      ];
    }

    return [];
  }

  public static getSingleSelectDropdowns(translate: TranslateService): IPropertiesDropdowns {
    const dropdownSingleSelect: IDropdown = {
      singleSelection: true,
      limitSelection: 0,
    };
    const dropdownMultiselect: IDropdown = {
      singleSelection: false,
      limitSelection: null,
    };

    return {
      speed: {
        layout: dropdownSingleSelect,
        dataSource: dropdownSingleSelect,
        countType: dropdownSingleSelect,
        unitOfCount: dropdownSingleSelect,
        unitOfTime: dropdownSingleSelect,
        timeSpan: dropdownSingleSelect,
      },
      work_order_duration: {
        actualWorkOrderDuration: dropdownSingleSelect,
        targetWorkOrderDuration: {
          singleSelection: true,
          limitSelection: 0,
          hint: translate.instant('kpiCardInformation.workOrderDuration.taskTargetDuration.expectedDescription'),
        },
      },
      count: {
        layout: dropdownSingleSelect,
        dataSource: dropdownSingleSelect,
        countType: dropdownSingleSelect,
        timeSpan: dropdownSingleSelect,
        unitOfCount: {
          singleSelection: false,
          limitSelection: 4,
        },
      },
      activities_total_duration: {
        unitOfTime: dropdownSingleSelect,
      },
      activity_duration: {
        shiftSplit: dropdownSingleSelect,
      },
      work_order_oee: {
        metricLayout: dropdownMultiselect,
      },
      work_order_oee_twelve_hours: {
        metricLayout: dropdownSingleSelect,
      },
      work_order_oee_six_hours: {
        metricLayout: dropdownSingleSelect,
      },
      shift_oee: {
        metricLayout: dropdownMultiselect,
      },
      pre_run_phase_duration: {
        targetPreRunPhaseDuration: {
          singleSelection: true,
          limitSelection: 0,
          hint: translate.instant('kpiCardInformation.preRunPhaseDuration.taskTargetDuration.expectedDescription'),
        },
      },
      post_run_phase_duration: {
        targetPostRunPhaseDuration: {
          singleSelection: true,
          limitSelection: 0,
          hint: translate.instant('kpiCardInformation.postRunPhaseDuration.taskTargetDuration.expectedDescription'),
        },
      },
    };
  }

  public static getPreDefinedMetricsForInfoModal(): IMetricsWithCustomPredefined {
    return {
      [EHomeMetrics.ACTIVITIES_TOTAL_DURATION]: [
        {
          id: EHomeMetrics.ACTIVITIES_TOTAL_DURATION,
          name: EHomeMetrics.ACTIVITIES_TOTAL_DURATION,
          homeMetricSetProperties: { unitOfTime: EUnitOfTime.PERCENTAGE },
        },
        {
          id: EHomeMetrics.ACTIVITIES_TOTAL_DURATION,
          name: EHomeMetrics.ACTIVITIES_TOTAL_DURATION,
          homeMetricSetProperties: { unitOfTime: EUnitOfTime.MINUTE },
        },
        {
          id: EHomeMetrics.ACTIVITIES_TOTAL_DURATION,
          name: EHomeMetrics.ACTIVITIES_TOTAL_DURATION,
          homeMetricSetProperties: { unitOfTime: EUnitOfTime.HOUR },
        },
      ],
      [EHomeMetrics.PRE_RUN_PHASE_DURATION]: [
        {
          id: EHomeMetrics.PRE_RUN_PHASE_DURATION,
          name: EHomeMetrics.PRE_RUN_PHASE_DURATION,
          homeMetricSetProperties: {
            targetPreRunPhaseDuration: ETargetPreRunPhaseDuration.SCHEDULED_PRE_RUN_PHASE_DURATION,
          },
        },
        {
          id: EHomeMetrics.PRE_RUN_PHASE_DURATION,
          name: EHomeMetrics.PRE_RUN_PHASE_DURATION,
          homeMetricSetProperties: {
            targetPreRunPhaseDuration: ETargetPreRunPhaseDuration.TASK_TARGET_DURATION,
          },
        },
      ],
      [EHomeMetrics.POST_RUN_PHASE_DURATION]: [
        {
          id: EHomeMetrics.POST_RUN_PHASE_DURATION,
          name: EHomeMetrics.POST_RUN_PHASE_DURATION,
          homeMetricSetProperties: {
            targetPostRunPhaseDuration: ETargetPostRunPhaseDuration.SCHEDULED_POST_RUN_PHASE_DURATION,
          },
        },
        {
          id: EHomeMetrics.POST_RUN_PHASE_DURATION,
          name: EHomeMetrics.POST_RUN_PHASE_DURATION,
          homeMetricSetProperties: {
            targetPostRunPhaseDuration: ETargetPostRunPhaseDuration.TASK_TARGET_DURATION,
          },
        },
      ],
    };
  }
}
