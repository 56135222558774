import { NgModule } from '@angular/core';
import { ScwMatInputModule } from './scw-mat-input/scw-mat-input.module';
import { ScwMatButtonModule } from './scw-mat-button/scw-mat-button.module';
import { ScwMatPickerModule } from './scw-mat-picker/scw-mat-picker.module';
import { ScwMatSearchModule } from './scw-mat-search/scw-mat-search.module';
import { ScwMatSmallButtonModule } from './scw-mat-small-button/scw-mat-small-button.module';
import { ScwMatDropdownModule } from './scw-mat-dropdown/scw-mat-dropdown.module';
import { ScwMatSelectModule } from './scw-mat-select/scw-mat-select.module';
import { ScwMatCheckboxModule } from './scw-mat-checkbox/scw-mat-checkbox.module';
import { ScwMatFormModule } from './scw-mat-form/scw-mat-form.module';
import { ScwMatDatepickerModule } from './scw-mat-datepicker/scw-mat-datepicker.module';
import { ScwMatBorderColoringModule } from './scw-mat-border-coloring/scw-mat-border-coloring.module';
import { ScwMatTextareaModule } from './scw-mat-textarea/scw-mat-textarea.module';
import { ScwMatButtonGroupModule } from './scw-mat-button-group/scw-mat-button-group.module';
import { ScwMatModalModule } from './scw-mat-modal/scw-mat-modal.module';
import { ScwMatMissingDataModule } from './scw-mat-missing-data/scw-mat-missing-data.module';
import { ScwMatRadioGroupModule } from './scw-mat-radio-group/scw-mat-radio-group.module';
import { ScwMatRatingModule } from './scw-mat-rating/scw-mat-rating.module';
import { ScwMatCronInputModule } from './scw-mat-cron-input/scw-mat-cron-input.module';
import { ScwMatWizardStepModule } from './scw-mat-wizard-step/scw-mat-wizard-step.module';
import { ScwMatWizardModule } from './scw-mat-wizard/scw-mat-wizard.module';
import { ScwMatHintModule } from './scw-mat-hint/scw-mat-hint.module';
import { ScwMatCarouselPickerModule } from './scw-mat-carousel-picker/scw-mat-carousel-picker.module';
import { ScwMatKpiCardWrapperModule } from './scw-mat-kpi-card-wrapper/scw-mat-kpi-card-wrapper.module';
import { ScwMatKpiCardModule } from './scw-mat-kpi-card/scw-mat-kpi-card.module';

@NgModule({
  imports: [
    ScwMatInputModule,
    ScwMatButtonModule,
    ScwMatPickerModule,
    ScwMatSearchModule,
    ScwMatSmallButtonModule,
    ScwMatDropdownModule,
    ScwMatSelectModule,
    ScwMatCheckboxModule,
    ScwMatFormModule,
    ScwMatDatepickerModule,
    ScwMatBorderColoringModule,
    ScwMatTextareaModule,
    ScwMatButtonGroupModule,
    ScwMatModalModule,
    ScwMatMissingDataModule,
    ScwMatRadioGroupModule,
    ScwMatRatingModule,
    ScwMatCronInputModule,
    ScwMatWizardStepModule,
    ScwMatWizardModule,
    ScwMatHintModule,
    ScwMatCarouselPickerModule,
    ScwMatKpiCardWrapperModule,
    ScwMatKpiCardModule,
  ],
  exports: [
    ScwMatInputModule,
    ScwMatButtonModule,
    ScwMatPickerModule,
    ScwMatSearchModule,
    ScwMatSmallButtonModule,
    ScwMatDropdownModule,
    ScwMatSelectModule,
    ScwMatCheckboxModule,
    ScwMatFormModule,
    ScwMatDatepickerModule,
    ScwMatBorderColoringModule,
    ScwMatTextareaModule,
    ScwMatButtonGroupModule,
    ScwMatModalModule,
    ScwMatMissingDataModule,
    ScwMatRadioGroupModule,
    ScwMatRatingModule,
    ScwMatCronInputModule,
    ScwMatWizardStepModule,
    ScwMatWizardModule,
    ScwMatHintModule,
    ScwMatCarouselPickerModule,
    ScwMatKpiCardWrapperModule,
    ScwMatKpiCardModule,
  ],
})
export class ScwMatUiModule {}
