import { NgModule } from '@angular/core';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { SharedModule } from '../../shared/shared.module';
import { AlertPauseComponent } from './alert-pause/alert-pause.component';
import { SensorStatusIndicatorComponent } from './sensor-status-indicator/sensor-status-indicator.component';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { LineStationDropdownItemComponent } from './line-station-dropdown-item/line-station-dropdown-item.component';
import { CreateQuickIssueActionModalComponent } from '../../shared/component/create-quick-issue-action-modal/create-quick-issue-action-modal.component';

@NgModule({
  imports: [NgbModule, SharedModule, ScrollingModule, CreateQuickIssueActionModalComponent],
  declarations: [AlertPauseComponent, SensorStatusIndicatorComponent, LineStationDropdownItemComponent],
  exports: [AlertPauseComponent, SensorStatusIndicatorComponent, LineStationDropdownItemComponent, CreateQuickIssueActionModalComponent],
})
export class MainModule {}
