import { Action } from '@ngrx/store';
import {
  INotificationLogsDatatableRow,
  INotificationLogsFilterParams,
  TNotificationDataOption,
} from './notification-logs.model';
import { GetManyResponseInterface } from '../../../shared/model/interface/crud-response-interface.model';

export enum NotificationLogsActionTypes {
  GetNotificationLogsData = '[Notification Logs] Get Notification Logs Data',
  GetNotificationLogsDataCompleted = '[Notification Logs] Get Notification Logs Data Completed',
  GetNotificationLogsPreview = '[Notification Logs] Get Notification Logs Preview',
  GetNotificationLogsPreviewCompleted = '[Notification Logs] Get Notification Logs Preview Completed',
  FetchError = '[Notification Logs] Fetch Error',
}

export class GetNotificationLogsData implements Action {
  readonly type = NotificationLogsActionTypes.GetNotificationLogsData;

  constructor(public params: INotificationLogsFilterParams) {}
}

export class GetNotificationLogsDataCompleted implements Action {
  readonly type = NotificationLogsActionTypes.GetNotificationLogsDataCompleted;

  constructor(public payload: GetManyResponseInterface<INotificationLogsDatatableRow>) {}
}

export class GetNotificationLogsPreview implements Action {
  readonly type = NotificationLogsActionTypes.GetNotificationLogsPreview;

  constructor(public id: string) {}
}

export class GetNotificationLogsPreviewCompleted implements Action {
  readonly type = NotificationLogsActionTypes.GetNotificationLogsPreviewCompleted;

  constructor(public payload: TNotificationDataOption) {}
}

export class FetchError implements Action {
  readonly type = NotificationLogsActionTypes.FetchError;

  constructor(public payload: object) {}
}

export type NotificationLogsActions =
  | GetNotificationLogsData
  | GetNotificationLogsDataCompleted
  | GetNotificationLogsPreview
  | GetNotificationLogsPreviewCompleted
  | FetchError;
