import { Pipe, PipeTransform } from '@angular/core';
import { DECIMAL_DEFAULT_SCALE_LIMIT } from 'src/constants';
import { DecimalHelper } from '../../helper/decimal/decimal-helper';
import * as _ from 'lodash';

@Pipe({
  name: 'toFixed',
})
export class ToFixedPipe implements PipeTransform {
  constructor(private readonly decimalHelper: DecimalHelper) {}

  transform(
    value: string | number,
    precision: number = DECIMAL_DEFAULT_SCALE_LIMIT,
    isString: boolean = true,
  ): string | number {
    if (_.isNil(value)) {
      return value;
    }

    const fixedValue: string = this.decimalHelper.toFixedValue(value.toString(), precision);
    return isString ? fixedValue : this.decimalHelper.formattedNumberToNumber(fixedValue);
  }
}
