<div
  class="border-coloring-container"
  [ngClass]="
    ((!isWithoutWorkOrder && text === null) || text === '' || isMissingData) && !isIdleActivity
      ? 'missing-data-cell'
      : ''
  "
>
  <ng-template
    [ngIf]="!((!isWithoutWorkOrder && text === null) || text === '') || isIdleActivity"
    [ngIfElse]="missingDataText"
  >
    <div class="nowrap" [ngClass]="isMissingData ? 'sub-text-icon' : ''">
      <ng-template [ngIf]="isMissingData">
        <em class="missing-data-sub-text fas fa-exclamation-circle"></em>
      </ng-template>
      <ng-container *ngIf="containsHyperLink; else noLink">
        <a class="cursor-pointer link-style"
           [routerLink]="hyperLink"
           target="_blank">{{ text }}</a>
      </ng-container>
      <ng-template #noLink>{{ text }}</ng-template>
    </div>
  </ng-template>
  <ng-template #missingDataText>
    <div class="nowrap">
      <em class="sub-text-icon fas fa-exclamation-circle"></em>
      {{ missingDataTranslation }}
    </div>
  </ng-template>
</div>
