import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { DecimalHelper } from '../../helper/decimal/decimal-helper';
import { User } from '../../../store/user/model';
import { Store } from '@ngrx/store';
import { OeeAppState } from '../../../store/oee.reducer';
import * as echarts from 'echarts';
import { Subscription } from 'rxjs';
import { HelperService } from '../../service/helper.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-chart-metric',
  templateUrl: './chart-metric.component.html',
  styleUrls: ['./chart-metric.component.scss'],
})
export class ChartMetricComponent implements OnChanges {
  @Input() title: string = null;
  @Input() averageOee: string = null;
  @Input() data: string[] = [];
  @Input() percentageData: string[] = [];
  @Input() dataLength: number = null;
  @Input() target: string = null;
  @Input() showMetricInfo: boolean = false;
  @Input() averageValueInfoText: string = '';
  @Input() reverse: boolean = false;

  public option: echarts.EChartsOption = {};

  constructor(
    private readonly store: Store<OeeAppState>,
    private readonly decimalHelper: DecimalHelper,
    private readonly helperService: HelperService,
    private readonly translate: TranslateService,
  ) {}

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes.hasOwnProperty('data')) {
      if (this.dataLength === 6) {
        this.data = this.data.slice(0, this.dataLength);
      }

      if (this.reverse) {
        this.data = this.data.reverse();
        this.percentageData = this.percentageData.reverse();
      }

      this.option = {
        xAxis: {
          type: 'category',
          show: false,
        },
        yAxis: {
          type: 'value',
          show: false,
        },
        series: [
          {
            data: this.setBarColor(),
            type: 'bar',
          },
        ],
        tooltip: {
          trigger: 'item',
          confine: true,
          formatter: (items): string => this.getHourlyOee(items),
        },
      };
    }
  }

  public setBarColor(): { value: string; itemStyle: { color: string } }[] {
    const bar: { originalValue: string; value: string; itemStyle: { color: string } }[] = [];

    this.data.forEach((data: string, index: number) => {
      bar.push({
        originalValue: this.data[index],
        value: this.decimalHelper.add(this.percentageData[index], '10'),
        itemStyle: {
          color: this.helperService.getBarColor(data, this.target?.toString()),
        },
      });
    });

    return bar;
  }

  private getHourlyOee(items): string {
    return `
        <div>
            <span class="tooltip-icon" style="background-color:${items.color};"> </span>
            <strong>${this.translate.instant('general.OEE')}: </strong>${this.decimalHelper.toFixedValue(items.data.originalValue)}%
        </div>`;
  }
}
