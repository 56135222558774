import { Component, OnDestroy, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { ScwMatFormModule } from '../../shared/component/scw-mat-ui/scw-mat-form/scw-mat-form.module';
import { ScwMatButtonModule } from '../../shared/component/scw-mat-ui/scw-mat-button/scw-mat-button.module';
import { ScwMatModalSeparatorModule } from '../../shared/component/scw-mat-ui/scw-mat-modal/scw-mat-modal-separator/scw-mat-modal-separator.module';
import { IWorkOrderCardData } from './select-work-order-line-modal.model';
import { HttpClientModule } from '@angular/common/http';
import {
  ECardAssignmentObjectTypeEnum,
  IRfidLine,
  IRfidWorkOrder,
  IRfidWorkOrderMapped,
} from '../../shared/service/rfid-card-reader/rfid-card-reader.model';
import { ActionsSubject, Store } from '@ngrx/store';
import { OeeAppState } from '../../store/oee.reducer';
import * as HomeActions from '../../store/home/home.actions';
import { Subscription } from 'rxjs';
import { ofType } from '@ngrx/effects';
import { RfidCardReaderService } from '../../shared/service/rfid-card-reader/rfid-card-reader.service';
import { ToastrService } from 'ngx-toastr';
import { ToastHelperService } from '../../shared/service/toast/toast.helper.service';
import * as AppActions from '../../store/app/actions';
import { ActivityTypes } from '../../shared/model/enum/activity-types';

@Component({
  selector: 'scw-select-work-order-line-modal',
  standalone: true,
  imports: [
    CommonModule,
    TranslateModule,
    ScwMatButtonModule,
    ScwMatFormModule,
    ScwMatModalSeparatorModule,
    HttpClientModule,
  ],
  templateUrl: './select-work-order-line-modal.component.html',
  styleUrls: ['./select-work-order-line-modal.component.scss'],
})
export class SelectWorkOrderLineModalComponent implements OnInit, OnDestroy {
  public modalData: IWorkOrderCardData;
  public possibleWorkOrders: IRfidWorkOrderMapped[] = [];
  public selectedWorkOrderId: number;
  public selectedLineId: number;
  public singleWorkOrder: IRfidWorkOrder = null;
  public isLoading: boolean = false;

  protected readonly cardAssignmentObjectTypeEnum = ECardAssignmentObjectTypeEnum;

  private readonly subscriptions: Subscription[] = [];

  constructor(
    private readonly activeModal: NgbActiveModal,
    private readonly store: Store<OeeAppState>,
    private readonly actionsSubject: ActionsSubject,
    private readonly rfidCardReaderService: RfidCardReaderService,
    private readonly toast: ToastrService,
    private readonly toastHelperService: ToastHelperService,
    private readonly translate: TranslateService,
  ) {}

  public ngOnInit(): void {
    const pseudoParentWoNumber: string = this.modalData.workOrders[0]?.woNumber;
    this.possibleWorkOrders = this.modalData.workOrders.map((workOrder: IRfidWorkOrder, index: number) => ({
      ...workOrder,
      pseudoParentWoNumber,
      selectable: index === 0,
    }));

    this.subscriptions.push(
      this.actionsSubject.pipe(ofType(HomeActions.HomeActionTypes.ChangeActivityCompleted)).subscribe(() => {
        this.isLoading = true;
        this.store.dispatch(new AppActions.ShowLoader());

        const [{ siteId }] = this.modalData.lines;
        const lineId: number = this.selectedLineId;

        this.rfidCardReaderService.goToHome(siteId, lineId);
      }),
    );

    this.setWorkOrderAsSelectedWorkOrderIfAvailable();
  }

  public onFormSubmit(): void {
    const [{ siteId }] = this.modalData.lines;
    const lineId: number = this.selectedLineId;
    const activityId: number | null = this.getSelectedLinesFirstRunActivity();
    const workOrderId: number = this.selectedWorkOrderId;

    if (!activityId) {
      this.toastHelperService.showToastMessage(
        false,
        this.translate.instant('general.failed'),
        this.translate.instant('modal.selectWorkOrderLine.toast.noRunTimeActivity'),
      );

      return;
    }

    this.store.dispatch(
      new HomeActions.ChangeActivity(
        {
          siteId,
          lineId,
          activityId,
          workOrderId,
        },
        false,
      ),
    );
  }

  public closeModal(): void {
    this.activeModal.close();
  }

  public onClickWorkOrder(workOrder: IRfidWorkOrderMapped): void {
    if (!workOrder.selectable) {
      this.toast.warning(
        this.translate.instant('modal.selectWorkOrderLine.toast.cannotSelectBeforeWorkOrder', {
          firstWoNumber: workOrder.woNumber,
          secondWoNumber: workOrder.pseudoParentWoNumber,
        }),
        this.translate.instant('general.warning'),
        {
          closeButton: true,
          progressBar: true,
          positionClass: 'toast-bottom-right',
        },
      );

      return;
    }

    this.selectedWorkOrderId = workOrder.id;
  }

  public onClickLine(line: IRfidLine): void {
    this.selectedLineId = line.id;
  }

  public ngOnDestroy(): void {
    this.subscriptions.forEach((subscription) => subscription?.unsubscribe());
  }

  private setWorkOrderAsSelectedWorkOrderIfAvailable(): void {
    this.singleWorkOrder =
      [ECardAssignmentObjectTypeEnum.JOB, ECardAssignmentObjectTypeEnum.WORK_ORDER].includes(
        this.modalData.cardAssignmentObjectType,
      ) && this.possibleWorkOrders?.length === 1
        ? this.possibleWorkOrders[0]
        : null;

    if (this.singleWorkOrder) {
      this.onClickWorkOrder(this.singleWorkOrder as IRfidWorkOrderMapped);
    }
  }

  private getSelectedLinesFirstRunActivity(): number | null {
    const selectedLine: IRfidLine = this.modalData.lines.find((line) => line.id === this.selectedLineId);
    const linesActivities: string[] = selectedLine.activityIds.split(',');

    let firstRunActivityId: number | null = null;

    for (const lineActivity of linesActivities) {
      firstRunActivityId =
        this.modalData.activities.find(
          (activity) => activity.id.toString() === lineActivity && activity.activityType === ActivityTypes.RUN_TIME,
        )?.id ?? null;

      if (firstRunActivityId) {
        break;
      }
    }

    return firstRunActivityId;
  }
}
