import { createAction, props } from '@ngrx/store';
import { ICommentType } from './comment-types.model';

export const loadIfNotLoaded = createAction('[Main Component] Load Comment Types If Not Loaded');

export const loaded = createAction(
  '[Main Component] Comment Types Loaded Successfully',
  props<{ commentTypes: readonly ICommentType[] }>(),
);

export const errored = createAction('[Main Component] Comment Types Loaded With Error');
