import { HttpClient, HttpParams } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { IEquipmentType } from '../../../../store/settings/equipment-types/equipment-types.model';
import {
  BulkResponseDataInterface,
  GetManyResponseInterface,
} from '../../../model/interface/crud-response-interface.model';
import { SiteCRUDInterface } from '../../../component/filter/filter.class';
import { HelperService } from '../../helper.service';
import { TagInterface, TagsObjectTypes } from '../../../../store/settings/tags/tags.model';
import * as _ from 'lodash';

@Injectable({
  providedIn: 'root',
})
export class EquipmentTypesService {
  private readonly EQUIPMENT_TYPES_BASE_URL: string = 'equipment-types';
  private readonly SITE_URL: string = `${this.baseUrl}/sites`;
  private readonly TAG_URL: string = `${this.baseUrl}/tags`;
  private readonly EQUIPMENT_TYPES = {
    GET: {
      GET_ALL_EQUIPMENT_TYPES: `${this.baseUrl}/${this.EQUIPMENT_TYPES_BASE_URL}`,
    },
    DELETE: {
      DELETE_EQUIPMENT_TYPE: `${this.baseUrl}/${this.EQUIPMENT_TYPES_BASE_URL}`,
    },
    POST: {
      CREATE_EQUIPMENT_TYPE: `${this.baseUrl}/${this.EQUIPMENT_TYPES_BASE_URL}`,
    },
    PATCH: {
      EDIT_EQUIPMENT_TYPE: `${this.baseUrl}/${this.EQUIPMENT_TYPES_BASE_URL}`,
    },
    BULK: {
      DELETE: `${this.baseUrl}/${this.EQUIPMENT_TYPES_BASE_URL}/bulk/delete`,
      SAVE: `${this.baseUrl}/${this.EQUIPMENT_TYPES_BASE_URL}/bulk/save`,
      EDIT: `${this.baseUrl}/${this.EQUIPMENT_TYPES_BASE_URL}/bulk/edit`,
    },
  };

  constructor(
    public http: HttpClient,
    @Inject('API_BASE_URL')
    private readonly baseUrl: string,
    public helperService: HelperService,
  ) {}

  public getEquipmentTypes(options?: HttpParams): Observable<GetManyResponseInterface<IEquipmentType>> {
    return this.http.get<GetManyResponseInterface<IEquipmentType>>(this.EQUIPMENT_TYPES.GET.GET_ALL_EQUIPMENT_TYPES, {
      params: options,
    });
  }

  public loadSiteData(): Observable<GetManyResponseInterface<SiteCRUDInterface>> {
    const params: HttpParams = this.helperService.insertGenericCrudRequestParameters({
      limit: 1000,
      fields: ['name'],
      sort: [{ column: 'name', type: 'ascending' }],
    });

    return this.http.get<GetManyResponseInterface<SiteCRUDInterface>>(`${this.SITE_URL}`, { params });
  }

  public loadTagsData(
    siteId?: number,
    searchText?: string,
    tagIds?: number[],
  ): Observable<GetManyResponseInterface<TagInterface>> {
    let params: HttpParams = this.helperService.insertGenericCrudRequestParameters({
      limit: 1000,
    });

    const andCondition: { $and: [Record<string, any>] } = {
      $and: [{ objectType: { $eq: TagsObjectTypes.Equipment } }],
    };

    if (!_.isNil(siteId)) {
      andCondition.$and.push({ siteId: { $eq: siteId } });
    }

    if (!_.isNil(tagIds) && tagIds.length) {
      andCondition.$and.push({ id: { in: tagIds } });
    }

    if (!_.isNil(searchText)) {
      andCondition.$and.push({ name: { $cont: searchText } });
    }

    params = params.set('s', JSON.stringify(andCondition));
    return this.http.get<GetManyResponseInterface<TagInterface>>(`${this.TAG_URL}`, { params });
  }

  public patchEquipmentTypeData(
    id: number,
    payload: Partial<IEquipmentType>,
    params?: HttpParams,
  ): Observable<GetManyResponseInterface<IEquipmentType>> {
    return this.http.patch<GetManyResponseInterface<IEquipmentType>>(
      `${this.EQUIPMENT_TYPES.PATCH.EDIT_EQUIPMENT_TYPE}/${id}`,
      payload,
      { params },
    );
  }

  public patchEquipmentTypesData(
    payload: Partial<IEquipmentType>[],
    params?: HttpParams,
  ): Observable<BulkResponseDataInterface> {
    return this.http.patch<BulkResponseDataInterface>(
      this.EQUIPMENT_TYPES.BULK.EDIT,
      { equipmentTypes: payload },
      { params },
    );
  }

  public createEquipmentType(payload: Partial<IEquipmentType>): Observable<GetManyResponseInterface<IEquipmentType>> {
    return this.http.post<GetManyResponseInterface<IEquipmentType>>(
      this.EQUIPMENT_TYPES.POST.CREATE_EQUIPMENT_TYPE,
      payload,
    );
  }

  public deleteEquipmentType(id: number): Observable<GetManyResponseInterface<IEquipmentType>> {
    return this.http.delete<GetManyResponseInterface<IEquipmentType>>(
      `${this.EQUIPMENT_TYPES.DELETE.DELETE_EQUIPMENT_TYPE}/${id}`,
    );
  }

  public deleteEquipmentTypes(ids: number[]): Observable<BulkResponseDataInterface> {
    return this.http.delete<BulkResponseDataInterface>(this.EQUIPMENT_TYPES.BULK.DELETE, {
      body: { equipmentTypes: ids },
    });
  }
}
