import { Action } from '@ngrx/store';
import { SiteCRUDInterface } from '../../../shared/component/filter/filter.class';
import { HttpOptionInterface } from '../filter.model';
import { ApiServer } from '../../../shared/component/filter/dropdown/dropdown.model';

export enum FilterSiteActionTypes {
  StartLoadFilterSites = '[Filter Site] Load Filter Sites',
  FilterSitesLoaded = '[Filter Site] Filter Sites Loaded',
  AppendSite = '[Filter Site] Append Site',
  ResetSiteStore = '[Filter Site] Reset data stored in site store',
}

export class StartLoadFilterSites implements Action {
  readonly type = FilterSiteActionTypes.StartLoadFilterSites;
  constructor(public options: HttpOptionInterface, public server: ApiServer = ApiServer.NestJS) {}
}

export class FilterSitesLoaded implements Action {
  readonly type = FilterSiteActionTypes.FilterSitesLoaded;
  constructor(public data: SiteCRUDInterface[]) {}
}

export class AppendSite implements Action {
  readonly type = FilterSiteActionTypes.AppendSite;
  constructor(public site: Partial<SiteCRUDInterface>) {}
}

export class ResetSiteStore implements Action {
  readonly type = FilterSiteActionTypes.ResetSiteStore;

  constructor() {}
}

export type FilterSiteActions = StartLoadFilterSites | FilterSitesLoaded | AppendSite | ResetSiteStore;
