import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, switchMap } from 'rxjs/operators';
import * as ObjectActions from './push-notification.actions';
import { of } from 'rxjs';
import { PushNotificationService } from './push-notification.service';
import { BaseOneResponseInterface } from '../../shared/model/interface/crud-response-interface.model';
import { ITopicSubscriptionResponseData } from './push-notification.model';

@Injectable()
export class PushNotificationEffects {
  constructor(private readonly actions$: Actions, private readonly service: PushNotificationService) {}

  subscribeToTopics = createEffect(() =>
    this.actions$.pipe(
      ofType(ObjectActions.SUBSCRIBE_TO_USER_UNIQUE_TOPIC),
      switchMap((objectData: ObjectActions.SubscribeToUserUniqueTopic) => {
        return this.service.subscribeToTopics(objectData.token).pipe(
          switchMap((response: BaseOneResponseInterface<ITopicSubscriptionResponseData>) => {
            return of(new ObjectActions.SubscribeToUserUniqueTopicCompleted(response, objectData.token));
          }),
          catchError((error) => {
            return of(new ObjectActions.FetchError(error));
          }),
        );
      }),
      catchError((errorRes) => {
        return of(new ObjectActions.FetchError(errorRes));
      }),
    ),
  );
}
