import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { HttpClient } from '@angular/common/http';
import * as oeeAppReducer from '../../oee.reducer';
import { Store } from '@ngrx/store';
import * as ObjectActions from './schedule-adherence.actions';
import * as AppActions from '../../app/actions';
import { of, switchMap } from 'rxjs';
import { ScheduleAdherenceService } from './schedule-adherence.service';
import { HelperService } from '../../../shared/service/helper.service';
import { ResponseArrayInterface } from '../../../shared/model/interface/generic-api-response.model';
import {
  IScheduleAdherenceData,
  IScheduleAdherenceResponseData,
  IScheduleAdherenceJobData,
  IScheduleAdherenceJobResponseData,
} from './schedule-adherence.model';
import { catchError } from 'rxjs/operators';
import { emptyAction } from '../../../../constants';

@Injectable()
export class ScheduleAdherenceEffects {
  constructor(
    private readonly actions$: Actions,
    private readonly http: HttpClient,
    private readonly store: Store<oeeAppReducer.OeeAppState>,
    private readonly scheduleAdherenceService: ScheduleAdherenceService,
  ) {}

  getScheduleAdherenceData = createEffect(() =>
    this.actions$.pipe(
      ofType(ObjectActions.SCHEDULE_ADHERENCE_DATA_LOADING),
      switchMap((objectData: ObjectActions.ScheduleAdherenceDataLoading) => {
        return this.scheduleAdherenceService
          .getScheduleAdherenceData(...HelperService.argumentClone(objectData.filters))
          .pipe(
            switchMap((response: ResponseArrayInterface<IScheduleAdherenceResponseData>) => {
              const formattedData: IScheduleAdherenceData = this.scheduleAdherenceService.formatScheduleAdherenceData(
                response.data,
              );
              return of(new ObjectActions.ScheduleAdherenceDataLoaded(formattedData));
            }),
            catchError((error) => {
              return of(new ObjectActions.ScheduleAdherenceFetchError(error), new AppActions.HideLoader());
            }),
          );
      }),
      catchError((error) => {
        return of(new ObjectActions.ScheduleAdherenceFetchError(error), new AppActions.HideLoader());
      }),
    ),
  );

  downloadScheduleAdherenceExcel = createEffect(() =>
    this.actions$.pipe(
      ofType(ObjectActions.SCHEDULE_ADHERENCE_DOWNLOAD_EXCEL),
      switchMap((objectData: ObjectActions.ScheduleAdherenceDownloadExcel) => {
        this.scheduleAdherenceService.downloadExcel(objectData.data, objectData.headers);
        return emptyAction;
      }),
      catchError((errorRes) => {
        return of(new ObjectActions.ScheduleAdherenceFetchError(errorRes), new AppActions.HideLoader());
      }),
    ),
  );

  getScheduleAdherenceJobData = createEffect(() =>
    this.actions$.pipe(
      ofType(ObjectActions.SCHEDULE_ADHERENCE_JOB_DATA_LOADING),
      switchMap((objectData: ObjectActions.ScheduleAdherenceJobsDataLoading) => {
        return this.scheduleAdherenceService
          .getScheduleAdherenceJobData(...HelperService.argumentClone(objectData.filters))
          .pipe(
            switchMap((response: ResponseArrayInterface<IScheduleAdherenceJobResponseData>) => {
              const formattedData: IScheduleAdherenceJobData =
                this.scheduleAdherenceService.formatScheduleAdherenceJobData(response.data);
              return of(new ObjectActions.ScheduleAdherenceJobsDataLoaded(formattedData));
            }),
            catchError((error) => {
              return of(new ObjectActions.ScheduleAdherenceFetchError(error), new AppActions.HideLoader());
            }),
          );
      }),
      catchError((error) => {
        return of(new ObjectActions.ScheduleAdherenceFetchError(error), new AppActions.HideLoader());
      }),
    ),
  );

  downloadScheduleAdherenceJobExcel = createEffect(() =>
    this.actions$.pipe(
      ofType(ObjectActions.SCHEDULE_ADHERENCE_JOB_DOWNLOAD_EXCEL),
      switchMap((objectData: ObjectActions.ScheduleAdherenceJobDownloadExcel) => {
        this.scheduleAdherenceService.downloadJobExcel(
          objectData.filterData,
          objectData.jobData,
          objectData.jobHeaders,
          objectData.workOrdersTableDataForExcel,
          objectData.workOrderHeaders,
        );
        return emptyAction;
      }),
      catchError((errorRes) => {
        return of(new ObjectActions.ScheduleAdherenceFetchError(errorRes), new AppActions.HideLoader());
      }),
    ),
  );
}
