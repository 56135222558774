import { Action } from '@ngrx/store';
import { PhasesInterfaceResponseInterface } from './phase-selection.model';

export const GET_PHASES = '[PhaseSelection] Get Phases';
export const GET_PHASES_COMPLETED = '[PhaseSelection] Get Phases Completed';
export const FETCH_ERROR = '[PhaseSelection] Fetch Error';

export class GetPhases implements Action {
  readonly type = GET_PHASES;

  constructor(public lineId: number, public hideLoader: boolean = true) {}
}

export class GetPhasesCompleted implements Action {
  readonly type = GET_PHASES_COMPLETED;

  constructor(public response: PhasesInterfaceResponseInterface) {}
}

export class FetchError implements Action {
  readonly type = FETCH_ERROR;

  constructor(public payload: any[]) {}
}

export type PhaseSelectionActions = GetPhases | GetPhasesCompleted | FetchError;
