import { Inject, Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import {
  BaseOneResponseInterface,
  BulkResponseDataInterface,
  GetManyResponseInterface,
} from '../../../shared/model/interface/crud-response-interface.model';

import { forkJoin, Observable, Subject } from 'rxjs';
import {
  IAddEditProductFamily,
  IProductFamily,
  IProductFamilyExcel,
  IProductFamilyExcelDropdownContent,
  IProductsReadExcelResponse,
} from './product-family.model';
import {
  CellTypes,
  CreateExcelInterface,
  CreateExcelSheetInterface,
  DownloadExcelFiltersInterface,
  ExcelColumnWidthEnum,
  ExcelHelperService,
  ExcelSheetTypeEnum,
} from '../../../shared/service/excel/excel-helper.service';
import { SimplifiedDataInterface } from '../tasks/tasks.model';
import * as moment from 'moment-timezone';
import * as ObjectActions from './product-family.actions';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { takeUntil } from 'rxjs/operators';
import { excelDateFormat, excelTimeFormat } from '../../../shared/model/enum/excel-date-format';
import * as AppActions from '../../app/actions';
import * as oeeAppReducer from '../../oee.reducer';
import { ValueType, Workbook, Worksheet } from 'exceljs';
import * as _ from 'lodash';

@Injectable({
  providedIn: 'root',
})
export class ProductFamilyService {
  private readonly destroySubject: Subject<boolean> = new Subject<boolean>();
  private readonly excelUploadLimit: number = 1000;
  private timezone: string = 'utc';
  private dateFormat$: string;
  private timeFormat$: string;

  private readonly routes = {
    sites: `${this.baseUrl}/sites`,
    productFamily: `${this.baseUrl}/product-family`,
    bulkDelete: `${this.baseUrl}/product-family/bulk/delete`,
    bulkSave: `${this.baseUrl}/product-family/bulk/save`,
  };

  constructor(
    public readonly excelHelper: ExcelHelperService,
    private readonly http: HttpClient,
    @Inject('API_BASE_URL')
    private readonly baseUrl: string,
    private readonly store: Store<oeeAppReducer.OeeAppState>,
    private readonly translate: TranslateService,
  ) {
    this.store
      .select('user')
      .pipe(takeUntil(this.destroySubject))
      .subscribe((state) => {
        if (state.isUserLoaded) {
          this.timezone = state.timezone;

          if (state.locale !== '') {
            this.dateFormat$ = excelDateFormat[state.locale];
            this.timeFormat$ = excelTimeFormat[state.locale];
          }

          this.destroySubject.next(true);
          this.destroySubject.complete();
        }
      });
  }

  public getProductFamily(params: HttpParams): Observable<GetManyResponseInterface<IProductFamily>> {
    return this.http.get<GetManyResponseInterface<IProductFamily>>(this.routes.productFamily, {
      params,
    });
  }

  public addProductFamily(
    productFamily: IAddEditProductFamily,
  ): Observable<BaseOneResponseInterface<IAddEditProductFamily>> {
    return this.http.post<BaseOneResponseInterface<IAddEditProductFamily>>(this.routes.productFamily, productFamily);
  }

  public editProductFamily(
    productFamily: IAddEditProductFamily,
    id: number,
  ): Observable<BaseOneResponseInterface<IAddEditProductFamily>> {
    return this.http.patch<BaseOneResponseInterface<IAddEditProductFamily>>(
      `${this.routes.productFamily}/${id}`,
      productFamily,
    );
  }

  public deleteProductFamily(productFamilyId: number): Observable<BulkResponseDataInterface> {
    return this.http.delete<BulkResponseDataInterface>(`${this.routes.productFamily}/${productFamilyId}`);
  }

  public bulkDeleteProductFamily(productFamilyIds: number[]): Observable<BulkResponseDataInterface> {
    return this.http.delete<BulkResponseDataInterface>(`${this.routes.bulkDelete}`, {
      body: { productFamilies: productFamilyIds },
    });
  }

  public getSite(params: HttpParams, id: number): Observable<{ id: number; name: string }> {
    return this.http.get<{ id: number; name: string }>(`${this.routes.sites}/${id}`, { params });
  }

  public async downloadExcel(
    withData: boolean,
    filters: DownloadExcelFiltersInterface,
    withErrorColumn: boolean = false,
    data?: any[],
  ): Promise<void> {
    const httpParams: HttpParams = new HttpParams().set('limit', String(this.excelUploadLimit));
    const siteParams: HttpParams = httpParams.set('fields', 'id,name');
    const productFamilyParams: HttpParams = httpParams
      .set('s', JSON.stringify({ siteId: { $eq: filters.siteId } }))
      .set('page', filters.selectedDownloadOffset);

    const observables: any = [this.getSite(siteParams, filters.siteId)];

    if (withData && !data) {
      observables.push(this.getProductFamily(productFamilyParams));
    }

    forkJoin(observables).subscribe((responseList) => {
      const site: SimplifiedDataInterface = _.get(responseList, '0.data', []);

      const sheetTitle: string = this.translate.instant('excel.items.productFamily');
      const excelName: string = `${sheetTitle} ${moment().tz(this.timezone).format(this.dateFormat$)}`;

      const content: IProductFamilyExcelDropdownContent = {
        site,
      };

      const excelOptions: CreateExcelInterface = this.getExcelColumns(content, withErrorColumn);

      if (withData) {
        excelOptions.data = (!data ? _.get(responseList, '1.data', []) : data).map(
          (productFamily: IProductFamilyExcel) => {
            return {
              ...productFamily,
              siteDropdown: content.site,
            };
          },
        );
      }

      const worksheets: CreateExcelSheetInterface[] = [
        {
          withData,
          sheetTitle,
          sheetType: ExcelSheetTypeEnum.TABLE,
          params: excelOptions,
          isDisabledColumnsFirstLine: true,
          addDateTimeFormula: true,
          excelRowFormatLimit: filters.limit + 1,
        },
      ];

      this.excelHelper
        .createExcel(
          excelName,
          { withData, name: 'productFamily', siteId: filters.siteId },
          worksheets,
          this.timezone,
          this.dateFormat$,
          this.timeFormat$,
          false,
        )
        .then(
          () => {
            this.store.dispatch(new ObjectActions.DownloadProductFamilyExcelCompleted());
            this.store.dispatch(new AppActions.HideLoader());
          },
          () => {
            this.store.dispatch(new ObjectActions.FetchError({}));
            this.store.dispatch(new AppActions.HideLoader());
          },
        );
    });
  }

  private getExcelColumns(content: IProductFamilyExcelDropdownContent, withErrorColumn: boolean): CreateExcelInterface {
    const excelColumns: CreateExcelInterface = {
      columns: [
        {
          header: this.translate.instant('general.excel.column.siteName'),
          key: 'siteId',
          width: ExcelColumnWidthEnum.DEFAULT,
          type: ValueType.String,
          dropdownOptions: {
            data: [content.site],
            prop: 'siteDropdown.name',
            dataProperty: 'siteDropdown.name',
            dataId: 'siteDropdown.id',
          },
          dataValidation: {
            type: CellTypes.LIST,
          },
          isRequired: true,
        },
        {
          header: 'id',
          key: 'id',
          width: ExcelColumnWidthEnum.DEFAULT,
          type: ValueType.String,
          style: { numFmt: '@' },
          dataValidation: {
            type: CellTypes.CUSTOM,
          },
        },
        {
          header: this.translate.instant('general.excel.column.productFamily.name'),
          key: 'name',
          width: ExcelColumnWidthEnum.DEFAULT,
          type: ValueType.String,
          dataValidation: {
            type: CellTypes.CUSTOM,
            allowBlank: false,
            showErrorMessage: true,
            formulae: [],
            errorStyle: 'Error',
            showInputMessage: true,
          },
          isRequired: true,
        },
      ],
    };

    this.excelHelper.prepareExcelColumns(excelColumns.columns, withErrorColumn);

    return excelColumns;
  }

  public uploadExcel(productFamilies: IProductFamily[]): Observable<BulkResponseDataInterface> {
    return this.http.post<BulkResponseDataInterface>(this.routes.bulkSave, {
      productFamily: productFamilies,
    });
  }

  public async getProductFamilyFromExcel(file: File): Promise<IProductsReadExcelResponse | null> {
    const workbook: Workbook = await this.excelHelper.getExcelWorkBookFromFile(file);
    const productFamilySheet: Worksheet = workbook.getWorksheet(this.translate.instant('excel.items.productFamily'));
    const siteIdDataSheet: Worksheet = workbook.getWorksheet('siteIdDataSheet');

    if (!productFamilySheet || !siteIdDataSheet) {
      return null;
    }

    const siteColumns = {
      id: {
        key: 'id',
        type: ValueType.String,
        dataValidationType: CellTypes.CUSTOM,
      },
      name: {
        key: 'name',
        type: ValueType.String,
        dataValidationType: CellTypes.CUSTOM,
      },
    };

    const sites: { id: number; name: string }[] = this.excelHelper.getExcelRowsFromWorkSheet<{
      id: number;
      name: string;
    }>(siteIdDataSheet, siteColumns);

    if (!sites.length) {
      return null;
    }

    const { columns } = this.getExcelColumns({ site: null }, false);
    const columnKeys = this.excelHelper.getSheetColumnKeys(columns);

    return {
      productFamilyData: {
        productFamily: this.excelHelper.getExcelRowsFromWorkSheet<IProductFamily>(productFamilySheet, columnKeys, {
          dateFormat: this.dateFormat$,
          timeFormat: this.timeFormat$,
          timezone: this.timezone,
        }),
      },
      siteData: sites,
    };
  }
}
