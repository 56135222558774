import { Component, Input, ViewChild } from '@angular/core';
import { animate, style, transition, trigger } from '@angular/animations';
import { CameraModalComponent } from '../camera-modal/camera-modal.component';
import * as AppActions from '../../../../store/app/actions';
import { Store } from '@ngrx/store';
import { OeeAppState } from '../../../../store/oee.reducer';
import { ToastHelperService } from '../../../service/toast/toast.helper.service';
import { TranslateService } from '@ngx-translate/core';
import { EFileType, IFile } from '../../../../store/file-upload/file-upload.model';
import { ImageHelperService } from '../../../helper/image-helper.service';
import { HelperService } from '../../../service/helper.service';
import { DecimalHelper } from '../../../helper/decimal/decimal-helper';
import { SIZE_LIMIT_PER_FILE_AS_MB } from '../../../../../constants';

@Component({
  selector: 'scw-image-upload-button',
  templateUrl: './image-upload-button.component.html',
  styleUrls: ['./image-upload-button.component.scss'],
  animations: [
    trigger('inOutAnimation', [
      transition(':enter', [style({ height: 0, opacity: 0 }), animate('0.5s ease-out')]),
      transition(':leave', [style({ height: 0, opacity: 1 }), animate('0.1s ease-in')]),
    ]),
  ],
})
export class ImageUploadButtonComponent {
  constructor(
    private readonly store: Store<OeeAppState>,
    private readonly translate: TranslateService,
    private readonly toastHelperService: ToastHelperService,
    private readonly helperService: HelperService,
    private readonly decimalHelperService: DecimalHelper,
  ) {}

  @ViewChild('camera_modal', { static: true }) cameraModalComponent: CameraModalComponent;

  @Input() disabled: boolean = false;
  @Input() fileCount: number;

  @Input() sizeLimitPerFileMB: number = SIZE_LIMIT_PER_FILE_AS_MB;
  @Input() isOriginalQualityByDefault: boolean = false;

  private static readonly ALLOWED_IMAGE_MIME_TYPES: string[] = ['image/png', 'image/jpeg', 'image/heic', 'image/jpg'];
  private static readonly ALLOWED_DOCUMENTS_MIME_TYPES: string[] = ['application/pdf'];

  public isOverlayVisible: boolean = false;
  private imagesFromGallery: string[];
  public documentFromGalleryArray: IFile[];
  private readonly maxFileLimit: number = 5;

  public onCameraClicked(): void {
    this.isOverlayVisible = false;
    this.cameraModalComponent.openCameraModal(true);
  }

  public async onGalleryClicked(event): Promise<void> {
    if (this.fileCount + event.target.files.length > this.maxFileLimit) {
      this.throwFileLimitError();
      return;
    }

    const arrayFromObject = Object.values(event.target.files);
    const selectedFiles: File[] = arrayFromObject.map((item) => item) as File[];

    const promises: Promise<any>[] = [];

    let isProvidedImageTypeIsValid: boolean = true;
    this.store.dispatch(new AppActions.ShowLoader());

    for (const file of selectedFiles) {
      isProvidedImageTypeIsValid = this.controlImageMimeType(file as File);

      if (file.type === 'image/heic') {
        const convertedFile: File = await ImageHelperService.convertHeicToJpeg(file as File);
        promises.push(this.readFileAsDataURL(convertedFile as File));
      } else {
        promises.push(this.readFileAsDataURL(file as File));
      }
    }

    if (!isProvidedImageTypeIsValid) {
      this.showMimeTypeError();
      this.store.dispatch(new AppActions.HideLoader());

      return;
    }

    this.imagesFromGallery = await Promise.all(promises);

    await this.cameraModalComponent.openCameraModal(false, this.imagesFromGallery);
    this.store.dispatch(new AppActions.HideLoader());

    this.isOverlayVisible = false;
    event.target.value = '';
  }

  private throwFileLimitError(): void {
    this.helperService.showToastMessage(
      false,
      this.translate.instant('general.failed'),
      this.translate.instant('fileUpload.fileLength.error'),
    );
  }

  public async onDocumentsClicked(event): Promise<void> {
    if (this.fileCount + event.target.files.length > this.maxFileLimit) {
      this.throwFileLimitError();
      return;
    }

    const filesObject = Object.values(event.target.files);
    const selectedFiles: File[] = filesObject.map((item) => item) as File[];
    let isProvidedImageTypeIsValid: boolean = true;

    if (selectedFiles?.length) {
      const promises: Promise<any>[] = [];
      selectedFiles.forEach((file: File) => {
        isProvidedImageTypeIsValid = this.controlDocumentsMimeType(file as File);

        promises.push(this.readFileAsDataURL(file as File));
      });

      if (!isProvidedImageTypeIsValid) {
        this.showMimeTypeError();

        return;
      } else {
        const base64Documents: string[] = await Promise.all(promises);

        this.documentFromGalleryArray = base64Documents.map((item: string, index: number): IFile => {
          return {
            original: item,
            fileOriginalName: selectedFiles[index].name,
            type: EFileType.PDF,
            size: this.decimalHelperService.decimalToNumberFormatter(
              this.decimalHelperService.toFixedValue(
                ImageHelperService.getBase64Size(item).toString(),
                this.decimalHelperService.isLessThan(ImageHelperService.getBase64Size(item).toString(), '0.01') ? 3 : 2,
              ),
            ),
          };
        });
      }
    }

    const fileLimitValidation: boolean = !!this.documentFromGalleryArray.find(
      (item: IFile) => item.size > this.sizeLimitPerFileMB,
    );

    if (fileLimitValidation) {
      this.toastHelperService.showToastMessage(
        false,
        this.translate.instant('general.failed'),
        this.translate.instant('fileUpload.phaseComment.sizeFileLimit'),
      );
      return;
    }

    CameraModalComponent.filesSubject.next(this.documentFromGalleryArray);
    this.isOverlayVisible = false;
    event.target.value = '';
  }

  public readFileAsDataURL(file: File): Promise<string> {
    return new Promise((resolve, reject): void => {
      const reader: FileReader = new FileReader();

      reader.onload = (event: ProgressEvent<FileReader>): void => {
        const base64String: string = event.target.result as string;
        resolve(base64String);
      };

      reader.onerror = (error: ProgressEvent<FileReader>): void => {
        reject(error);
        this.store.dispatch(new AppActions.HideLoader());
      };

      reader.readAsDataURL(file);
    });
  }

  private controlImageMimeType(file: File): boolean {
    if (!file.type) {
      return false;
    }

    return ImageUploadButtonComponent.ALLOWED_IMAGE_MIME_TYPES.includes(file.type);
  }

  private controlDocumentsMimeType(file: File): boolean {
    if (!file.type) {
      return false;
    }

    return ImageUploadButtonComponent.ALLOWED_DOCUMENTS_MIME_TYPES.includes(file.type);
  }

  private showMimeTypeError(): void {
    this.toastHelperService.showToastMessage(
      false,
      this.translate.instant('general.error'),
      this.translate.instant('apiErrorMessages.providedFileInvalidMimetype'),
    );

    return;
  }
}
