export const workOrderColorsOfSchedulerGantt = [
  '#E4BFBF',
  '#F1ADAA',
  '#FDBFAD',
  '#FFD4B5',
  '#EDE1D1',
  '#9AD0B7',
  '#C5E9C1',
  '#DEF3D5',
  '#99F1EB',
  '#C7E2FB',
  '#B4D0FF',
  '#9999EB',
  '#DFDBF5',
  '#FBDFEA',
  '#F5B6CF',
  '#E5E5E5',
  '#C0C0C4',
  '#ADADAD',
];

export enum warningStatusColorsSchedulerGantt {
  RED = '#DD332B',
  GREEN = '#6FC764',
  YELLOW = '#FFD600',
  DARK_GREEN = '#088C4C',
}
