import { IJobCRUD } from '../../../shared/component/filter/filter.class';
import { EFilterJobsActionTypes, FilterJobsActions } from './job.action';

export interface FilterJobState {
  isLoading: boolean;
  isLoaded: boolean;
  data: IJobCRUD[];
}

export const initialFilterJobState: FilterJobState = {
  isLoaded: false,
  isLoading: false,
  data: [],
}

export function filterJobReducer(state: FilterJobState = initialFilterJobState, action: FilterJobsActions): FilterJobState {
  switch (action.type) {
    case EFilterJobsActionTypes.FilterJobsLoading:
      return {...state, isLoading: true};
    case EFilterJobsActionTypes.FilterJobsLoaded:
      return {...state, data: action.data, isLoaded: true, isLoading: false};
    case EFilterJobsActionTypes.AppendJobData:
      return {...state, data: [...action.data, ...state.data]}
    case EFilterJobsActionTypes.ResetJobsStore:
      return { ...initialFilterJobState };
    default:
      return state;
  }
}
