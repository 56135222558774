<div class="border-container">
  <div
    class="border-color"
    [class.border-color-select-mode]="selectMode"
    [style.background-color]="selectMode && !isSelected ? idleTimeColor : statusColor"
  ></div>
</div>
<div class="cico-content" [class.pe-none]="selectMode">
  <div class="cico-header d-flex">
    <span
      class="cico-item-image"
      [class.avatar-is-loading]="!cicoData.avatar && cicoData.avatarIsLoading"
      [ngStyle]="{ 'background-image': cicoData.avatar ? 'url(' + cicoData.avatar + ')' : null }"
    >
      <em [class]="cicoData.iconCls" *ngIf="!cicoData.avatar || cicoData.avatarIsLoading"></em>
    </span>
    <div class="cico-item-text nowrap">
      <h4 class="cico-text cico-text-dark m-0 f-19 nowrap d-flex align-items-center mt-1">
        <span [ngClass]="{ clickable: clickable }" (click)="redirectToLaborOrAssetLogs()" [@.disabled]="selectMode">
          {{ cicoData.fullName }}
          <em *ngIf="clickable" class="fas fa-external-link-alt fa-xs p-l-5 icon-align"></em>
        </span>
      </h4>
      <button *ngIf="isMatMenuVisible" mat-icon-button [matMenuTriggerFor]="menu" class="more-icon">
        <em class="fas fa-ellipsis-v fa-lg"></em>
      </button>

      <mat-menu #menu="matMenu" xPosition="after" yPosition="below">
        <button mat-menu-item [disabled]="cicoData.status === cicoAvailability.offline" (click)="onClickCheckOut()">
          <em class="fas fa-sign-out-alt fa-lg pr-2"></em>
          <span>{{ 'cico.checkOut' | translate }}</span>
        </button>
        <ng-container *ngIf="cicoData.type !== cicoType.ASSET; else assetStatus">
          <button
            mat-menu-item
            [disabled]="cicoData.status === cicoAvailability.offline"
            (click)="
              onClickChangeStatus(
                cicoData.status === cicoAvailability.busy ? cicoAvailability.available : cicoAvailability.busy
              )
            "
          >
            <em class="fas fa-circle pr-2" [style.color]="statusColor"></em>
            <span
              >{{ 'cico.menu.changeStatus' | translate }}
              <em class="fas fa-arrow-right pr-2"></em>
              <em class="fas fa-circle" [style.color]="statusOppositeColor"></em>
              {{ (cicoData.status === cicoAvailability.busy ? 'general.available' : 'general.busy') | translate }}
            </span>
          </button>
        </ng-container>
        <ng-template #assetStatus>
          <button
            mat-menu-item
            [matMenuTriggerFor]="tagMenu"
            [disabled]="cicoData.status === cicoAvailability.offline || !tags.length"
          >
            <span
              ><em class="fas fa-circle pr-2" [style.color]="statusColor"></em>
              <span>{{ 'cico.menu.changeTag' | translate }}</span>
            </span>
          </button>
        </ng-template>
        <button
          mat-menu-item
          [matMenuTriggerFor]="stationMenu"
          [disabled]="cicoData.status === cicoAvailability.offline || !lineStations.length"
        >
          <em class="fa fa-th-large fa-lg pr-2"></em>
          <span>{{ 'cico.menu.changeLocation' | translate }}</span>
        </button>
      </mat-menu>

      <mat-menu #tagMenu>
        <button mat-menu-item *ngFor="let tag of tags" (click)="onClickChangeStatus(null, tag.id)">
          <em class="fas fa-circle pr-2" [style.color]="tag.color"></em>
          <span>{{ tag.name }}</span>
        </button>
      </mat-menu>

      <mat-menu #stationMenu>
        <button
          mat-menu-item
          *ngFor="let station of lineStations"
          (click)="onClickChangeStation(station.id, station.name)"
        >
          <span>{{ station.name }}</span>
        </button>
      </mat-menu>
      <span
        class="cico-item-status"
        [style.background-color]="selectMode && !isSelected ? idleTimeColor : statusColor"
        *ngIf="getStatusName(cicoData.type, cicoData.status, cicoData.tagName)"
        >{{ getStatusName(cicoData.type, cicoData.status, cicoData.tagName) | translate }}</span
      >
      <span class="cico-item-level cico-text cico-text-grey text-uppercase ml-2" *ngIf="level">
        <em class="fas fa-user mr-1"></em>{{ level.name }}
      </span>
      <span class="cico-text-grey cico-text ml-2 nowrap" *ngIf="cicoData.destination !== null">
        <em class="fas fa-location-arrow text-muted cico-text mr-1"></em>
        <em
          class="fas fa-info-circle cursor-pointer fa-sm cico-warning cico-text pl-1 mr-1"
          *ngIf="
            cicoData.scheduledDestination?.object?.objectName &&
            cicoData.destination.object.objectName !== cicoData.scheduledDestination.object.objectName
          "
          [ngbTooltip]="destinationTooltipContent"
          triggers="click:blur"
          tooltipClass="standard-popover detail-popover"
        ></em>
        <span
          class="cico-text nowrap"
          [class.cico-warning]="
            cicoData.scheduledDestination?.object?.objectName &&
            cicoData.destination.object.objectName !== cicoData.scheduledDestination.object.objectName
          "
          >{{
            cicoData.destination.type === cicoDestinationType.STATION
              ? cicoData.destination.object.lineName + ' - '
              : ''
          }}{{ cicoData.destination.object.objectName }}
        </span>
      </span>
    </div>
  </div>

  <div [@expandableState] *ngIf="isExpanded">
    <hr class="cico-separator" />
    <div class="cico-body">
      <div *ngIf="showWorkOrderRow" class="d-flex justify-content-between cico-body-item">
        <em class="fas fa-list-alt text-muted cico-text pr-1"></em>
        <div class="d-flex nowrap">
          <span class="cico-text cico-text-dark f-w-500 nowrap">{{ cicoData.meta.woNumber || '-' }}</span>
        </div>
      </div>
    </div>

    <hr class="cico-separator" />
    <div class="cico-footer pt-1 pb-3">
      <em class="fas fa-sign-in-alt pr-1" [class.cico-warning]="isUserLate"></em>
      <span class="text-muted" [class.cico-warning]="isUserLate">
        {{ cicoData.formattedCheckInTime }}
        <em
          *ngIf="isUserLate"
          class="fas fa-info-circle cursor-pointer fa-sm pl-1 cico-warning"
          [ngbTooltip]="timeTooltipContent"
          triggers="click:blur"
          tooltipClass="standard-popover detail-popover"
        ></em>
      </span>
      <div class="float-right" *ngIf="cicoData.formattedCheckOutTime">
        <span class="text-muted" [class.cico-warning]="isUserLate">
          {{ cicoData.formattedCheckOutTime }}
        </span>
        <em class="fas fa-sign-out-alt pl-1"></em>
      </div>

      <div class="progress-box">
        <div class="progress progress-lg d-flex">
          <div
            *ngFor="let bar of progressBars; let isFirst = first; let isLast = last"
            class="progress-bar mw-100"
            role="progressbar"
            [class.progress-bar-start]="isFirst"
            [class.progress-bar-end]="isLast"
            [class.progress-bar-white]="bar.type === progressBarType.GAP"
            [class.progress-bar-select-mode]="selectMode && !isSelected"
            [style.width.%]="bar.width"
            [style.cursor]="bar.type === progressBarType.ENTRY ? 'pointer' : 'default'"
            [disableTooltip]="bar.type === progressBarType.GAP"
            (click)="clickedBarContext = bar"
            [ngbTooltip]="checkInInfoTooltipContent"
            triggers="click:blur"
            tooltipClass="standard-popover"
          ></div>
        </div>
        <p class="cico-text cico-text-grey f-w-500 m-b-0">
          {{ progressBarText }}
          <span class="float-right"
            >{{ cicoData.formattedDuration }} /
            {{ (cicoData.scheduledDuration || cicoData.shiftDuration) / 3600 | toFixed : DECIMAL_DEFAULT_SCALE_LIMIT }}
            {{ shortHour }}</span
          >
        </p>
      </div>
    </div>
  </div>
</div>

<ng-template #destinationTooltipContent>
  <div class="d-flex">
    <em class="fas fa-info-circle cursor-pointer cico-warning m-auto pr-2"></em>
    <span>{{ 'cico.warning.checkedInDifferentDestination' | translate : { property: cicoData.fullName } }}</span>
  </div>

  <hr class="my-1" />
  <div class="d-flex justify-content-between align-items-center nowrap">
    <span class="cico-text cico-text-grey f-w-500 lh-none">
      {{ 'workOrderSchedule.listView.headers.scheduledLine' | translate }}:</span
    >
    <span class="cico-text cico-text-dark f-w-500 lh-none cico-warning nowrap">{{
      cicoData.scheduledDestination.object.objectName
    }}</span>
  </div>
  <div
    class="d-flex justify-content-between align-items-center nowrap"
    *ngIf="cicoData.scheduledDestination?.object?.objectName"
  >
    <span class="cico-text cico-text-grey f-w-500 lh-none">
      {{ 'workOrderSchedule.listView.headers.actualLine' | translate }}:</span
    >
    <span class="cico-text cico-text-dark f-w-500 lh-none nowrap"
      >{{ cicoData.destination.type === cicoDestinationType.STATION ? cicoData.destination.object.lineName + ' - ' : ''
      }}{{ cicoData.destination.object.objectName }}</span
    >
  </div>
</ng-template>

<ng-template #timeTooltipContent>
  <div class="d-flex">
    <em class="fas fa-info-circle cursor-pointer cico-warning m-auto pr-2"></em>
    <span>{{ 'cico.warning.checkedInDifferentTime' | translate : { property: cicoData.fullName } }}</span>
  </div>

  <hr class="my-1" />
  <div class="d-flex justify-content-between align-items-center nowrap">
    <span class="cico-text cico-text-grey f-w-500 lh-none">
      {{ 'laborAssetView.tableHeaders.scheduledCheckInTime' | translate }}:</span
    >
    <span class="cico-text cico-text-dark f-w-500 lh-none nowrap">{{ cicoData.formattedScheduledCheckInTime }}</span>
  </div>
  <div class="d-flex justify-content-between align-items-center nowrap">
    <span class="cico-text cico-text-grey f-w-500 lh-none">
      {{ 'laborAssetView.tableHeaders.checkInTime' | translate }}:</span
    >
    <span class="cico-text cico-text-dark f-w-500 lh-none cico-warning nowrap">{{
      cicoData.formattedCheckInTime
    }}</span>
  </div>
  <div class="d-flex justify-content-between align-items-center nowrap">
    <span class="cico-text cico-text-grey f-w-500 lh-none"> {{ 'cico.delayWithTime' | translate }}:</span>
    <span class="cico-text cico-text-dark f-w-500 lh-none nowrap">{{
      scheduledVsActualDiff | formatDurationFromSecond
    }}</span>
  </div>
</ng-template>

<ng-template #checkInInfoTooltipContent>
  <div class="d-flex justify-content-between align-items-center nowrap">
    <em class="fas fa-sign-in-alt pr-2"></em>
    <span class="cico-text cico-text-dark f-w-500 lh-none nowrap">{{ clickedBarContext.start }}</span>
  </div>
  <div class="d-flex justify-content-between align-items-center nowrap" *ngIf="clickedBarContext.end">
    <em class="fas fa-sign-out-alt pr-2"></em>
    <span class="cico-text cico-text-dark f-w-500 lh-none nowrap">{{ clickedBarContext.end }}</span>
  </div>
</ng-template>
