import { Action } from '@ngrx/store';
import { ProductFamilyCRUDInterface } from '../../../shared/component/filter/filter.class';
import { HttpOptionInterface } from '../filter.model';

export enum FilterProductFamilyActionTypes {
  StartLoadFilterProductFamily = '[Filter ProductFamily] Load Filter ProductFamily',
  FilterProductFamilyLoaded = '[Filter ProductFamily] Filter ProductFamily Loaded',
  ResetProductFamilyStore = '[Filter ProductFamily] Reset store data for ProductFamily filter',
}

export class StartLoadFilterProductFamily implements Action {
  readonly type = FilterProductFamilyActionTypes.StartLoadFilterProductFamily;
  constructor(public options: HttpOptionInterface, public isFetchMoreRequest: boolean = false) {}
}
export class FilterProductFamilyLoaded implements Action {
  readonly type = FilterProductFamilyActionTypes.FilterProductFamilyLoaded;
  constructor(
    public data: ProductFamilyCRUDInterface[],
    public totalCount: number = 0,
    public isFetchMoreRequest: boolean = false,
  ) {}
}
export class ResetProductFamilyStore implements Action {
  readonly type = FilterProductFamilyActionTypes.ResetProductFamilyStore;
}

export type FilterProductFamilyActions =
  | StartLoadFilterProductFamily
  | FilterProductFamilyLoaded
  | ResetProductFamilyStore;
