import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, switchMap } from 'rxjs/operators';
import { from, of } from 'rxjs';
import * as AppActions from '../../../app/actions';
import * as ObjectActions from './departments.actions';
import * as oeeAppReducer from '../../../oee.reducer';
import { Injectable } from '@angular/core';
import { HttpParams } from '@angular/common/http';
import { Store } from '@ngrx/store';
import { DepartmentsService } from './departments.service';
import { ResponseArrayInterface } from '../../../../shared/model/interface/generic-api-response.model';
import { DepartmentBulkResponseInterface, IDepartment, IProductionUnit } from './departments.model';
import {
  BaseOneResponseInterface,
  BulkResponseDataInterface,
  GetManyResponseInterface,
} from '../../../../shared/model/interface/crud-response-interface.model';

@Injectable()
export class DepartmentsEffects {
  constructor(
    private readonly actions$: Actions,
    private readonly store: Store<oeeAppReducer.OeeAppState>,
    private readonly departmentsService: DepartmentsService,
  ) {}

  getDepartments = createEffect(() =>
    this.actions$.pipe(
      ofType(ObjectActions.DEPARTMENTS_DATA_LOAD),
      switchMap((object: ObjectActions.DepartmentsDataLoad) => {
        const andConditions = [];
        let httpParams = new HttpParams()
          .append('join', 'lineTypeProductionUnit')
          .append('page', String(object.tableQuery.page))
          .append('limit', String(object.tableQuery.pageSize || 1000));

        if (object.tableQuery.orderBy && object.tableQuery.orderDesc !== undefined) {
          httpParams = httpParams.set(
            'sort',
            `${object.tableQuery.orderBy},${object.tableQuery.orderDesc ? 'DESC' : 'ASC'}`,
          );
        }

        if (object.tableQuery.search) {
          andConditions.push({ lineType: { $cont: object.tableQuery.search } });
        }

        if (object.tableQuery.status && object.tableQuery.status !== -1) {
          andConditions.push({ status: { $in: object.tableQuery.status } });
        }

        if (andConditions.length > 0) {
          httpParams = httpParams.set('s', JSON.stringify({ $and: andConditions }));
        }

        return this.departmentsService.getDepartments(httpParams).pipe(
          switchMap((response: ResponseArrayInterface<IDepartment>) => {
            return of(new ObjectActions.DepartmentsDataLoaded(response));
          }),
          catchError((error) => {
            return of(new ObjectActions.FetchError(error));
          }),
        );
      }),
      catchError((error) => {
        return of(new ObjectActions.FetchError(error));
      }),
    ),
  );

  getProductionUnits = createEffect(() =>
    this.actions$.pipe(
      ofType(ObjectActions.PRODUCTION_UNIT_DATA_LOAD),
      switchMap((object: ObjectActions.ProductionUnitDataLoad) => {
        const httpParams = new HttpParams()
          .append('page', String(object.tableQuery.page))
          .append('limit', String(object.tableQuery.pageSize || 1000));

        return this.departmentsService.getProductionUnits(httpParams).pipe(
          switchMap((response: ResponseArrayInterface<IProductionUnit>) => {
            return of(new ObjectActions.ProductionUnitDataLoaded(response));
          }),
          catchError((error) => {
            return of(new ObjectActions.FetchError(error));
          }),
        );
      }),
      catchError((error) => {
        return of(new ObjectActions.FetchError(error), new AppActions.HideLoader());
      }),
    ),
  );

  addDepartment = createEffect(() =>
    this.actions$.pipe(
      ofType(ObjectActions.ADD_DEPARTMENT),
      switchMap((objectData: ObjectActions.AddDepartment) => {
        this.store.dispatch(new AppActions.ShowLoader());

        return this.departmentsService.addDepartment(objectData.station).pipe(
          switchMap((response: BaseOneResponseInterface<IDepartment>) => {
            return of(new ObjectActions.AddDepartmentCompleted(response), new AppActions.HideLoader());
          }),
          catchError((error) => {
            return of(new ObjectActions.FetchError(error), new AppActions.HideLoader());
          }),
        );
      }),
      catchError((error) => {
        return of(new ObjectActions.FetchError(error), new AppActions.HideLoader());
      }),
    ),
  );

  editDepartment = createEffect(() =>
    this.actions$.pipe(
      ofType(ObjectActions.EDIT_DEPARTMENT),
      switchMap((object: ObjectActions.EditDepartment) => {
        this.store.dispatch(new AppActions.ShowLoader());

        return this.departmentsService.editDepartments(object.station, object.departmentId).pipe(
          switchMap((response: BaseOneResponseInterface<IDepartment>) => {
            return of(new ObjectActions.EditDepartmentCompleted(response), new AppActions.HideLoader());
          }),
          catchError((error) => {
            return of(new ObjectActions.FetchError(error), new AppActions.HideLoader());
          }),
        );
      }),
      catchError((error) => {
        return of(new ObjectActions.FetchError(error), new AppActions.HideLoader());
      }),
    ),
  );

  bulkEditDepartments = createEffect(() =>
    this.actions$.pipe(
      ofType(ObjectActions.BULK_EDIT_DEPARTMENTS),
      switchMap((objectData: ObjectActions.BulkEditDepartments) => {
        this.store.dispatch(new AppActions.ShowLoader());

        return this.departmentsService.bulkEditDepartments(objectData.departments).pipe(
          switchMap((response: GetManyResponseInterface<DepartmentBulkResponseInterface>) => {
            return of(new ObjectActions.BulkEditDepartmentsCompleted(response), new AppActions.HideLoader());
          }),
          catchError((error) => {
            return of(new ObjectActions.FetchError(error), new AppActions.HideLoader());
          }),
        );
      }),
      catchError((error) => {
        return of(new ObjectActions.FetchError(error), new AppActions.HideLoader());
      }),
    ),
  );

  deleteDepartment = createEffect(() =>
    this.actions$.pipe(
      ofType(ObjectActions.DELETE_DEPARTMENT),
      switchMap((objectData: ObjectActions.DeleteDepartment) => {
        this.store.dispatch(new AppActions.ShowLoader());

        return from(this.departmentsService.deleteDepartment(objectData.departmentId)).pipe(
          switchMap((response: BulkResponseDataInterface) => {
            return of(new ObjectActions.DeleteDepartmentCompleted(response), new AppActions.HideLoader());
          }),
          catchError((err) => {
            return of(new ObjectActions.FetchError(err), new AppActions.HideLoader());
          }),
        );
      }),
      catchError((error) => {
        return of(new ObjectActions.FetchError(error), new AppActions.HideLoader());
      }),
    ),
  );

  deleteDepartments = createEffect(() =>
    this.actions$.pipe(
      ofType(ObjectActions.BULK_DELETE_DEPARTMENTS),
      switchMap((objectData: ObjectActions.BulkDeleteDepartments) => {
        this.store.dispatch(new AppActions.ShowLoader());

        return from(this.departmentsService.bulkDeleteDepartments(objectData.departmentIds)).pipe(
          switchMap((response: BulkResponseDataInterface) => {
            return of(new ObjectActions.BulkDeleteDepartmentsCompleted(response), new AppActions.HideLoader());
          }),
          catchError((err) => {
            return of(new ObjectActions.FetchError(err), new AppActions.HideLoader());
          }),
        );
      }),
      catchError((error) => {
        return of(new ObjectActions.FetchError(error), new AppActions.HideLoader());
      }),
    ),
  );
}
