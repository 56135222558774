<app-base-modal [modal]="modal" (onClose)="cancel()">
  <ng-container modal-title>{{ modalHeader }}</ng-container>
  <ng-container modal-body>
    <p *ngIf="message" class="fw-600 h6 mb-3">
      <span aria-hidden class="fa-exclamation-circle fas mr-2"></span>{{ message }}
    </p>
    <scw-mat-radio-group
      [(inputModel)]="selectedOption"
      [options]="options"
      [alignment]="'vertical'"
    ></scw-mat-radio-group>
  </ng-container>
  <ng-container modal-footer>
    <scw-mat-button type="standard" (onClick)="cancel()">{{ 'general.cancel' | translate }}</scw-mat-button>
    <scw-mat-button
      [disabled]="!selectedOption"
      [type]="confirmButtonConfiguration.type"
      [className]="confirmButtonConfiguration.className"
      (onClick)="confirm()"
      >{{ confirmButtonConfiguration.text }}</scw-mat-button
    >
  </ng-container>
</app-base-modal>
