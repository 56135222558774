import { Inject, Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import {
  BaseOneResponseInterface,
  BulkResponseDataInterface,
  GetManyResponseInterface,
} from '../../../model/interface/crud-response-interface.model';
import { ICustomerSegment } from './customer-segments.model';

@Injectable({
  providedIn: 'root',
})
export class CustomerSegmentsService {
  private readonly customerSegmentsBaseUrl = `${this.baseUrl}/customer-segments`;
  constructor(private readonly http: HttpClient, @Inject('API_BASE_URL') private readonly baseUrl: string) {}

  public getCustomerSegments(params: HttpParams): Observable<GetManyResponseInterface<ICustomerSegment>> {
    return this.http.get<GetManyResponseInterface<ICustomerSegment>>(this.customerSegmentsBaseUrl, { params });
  }

  public getCustomerSegment(id: number, params: HttpParams): Observable<BaseOneResponseInterface<ICustomerSegment>> {
    return this.http.get<BaseOneResponseInterface<ICustomerSegment>>(`${this.customerSegmentsBaseUrl}/${id}`, {
      params,
    });
  }

  public createCustomerSegment(payload: ICustomerSegment) {
    return this.http.post<BaseOneResponseInterface<ICustomerSegment>>(`${this.customerSegmentsBaseUrl}`, payload);
  }

  public updateCustomerSegment(id: number, payload: ICustomerSegment) {
    return this.http.patch<BaseOneResponseInterface<ICustomerSegment>>(
      `${this.customerSegmentsBaseUrl}/${id}`,
      payload,
    );
  }

  public deleteCustomerSegment(id: number) {
    return this.http.delete<BulkResponseDataInterface>(`${this.customerSegmentsBaseUrl}/${id}`);
  }
}
