import * as Actions from './production-review.actions';
import { IShift, ProductionReviewInterface } from './production-review.model';
import * as moment from 'moment';
import { iosTimestampFormat } from '../../shared/helper/date';

export const productionReviewInitialState: ProductionReviewInterface = {
  shiftsByDate: [],
  selectedDate: '',
  selectedShift: null,
  isGetShiftByDateLoading: false,
  isGetShiftByDateLoaded: false,
  isCurrentPreviousShiftLoading: false,
  isCurrentPreviousShiftLoaded: false,
  selectedStartTime: '',
  selectedEndTime: '',
  currentShift: null,
  previousShift: null,
  selectedShiftChanged: false,
  initialLoaded: true,
  initialLoadedShiftByDate: true,
  selectedShiftId: -1,
  isNextShiftLoaded: false,
  nextShift: null,
  allowLoadNextShift: false,
  loadingHeartBeat: false,
  continueHeartBeat: false,
  sessionTimer: 1200,
  reloadPage: false,
  nextShiftStartTimeLoaded: false,
  tableSettings: [],
};

export function productionReviewReducer(
  state: ProductionReviewInterface = productionReviewInitialState,
  action: Actions.ProductionReviewActions,
) {
  const emptyShift: IShift = {
    id: null,
    shiftId: null,
    shiftInfo: '',
    shiftName: '',
    weekDayEnd: -1,
    weekDayStart: -1,
    formattedStartDate: '',
    formattedEndDate: '',
    shiftDay: '',
  };
  let previous = emptyShift;
  let current: IShift = emptyShift;
  let selected: IShift = null;
  let selectedId = -1;
  const emptyNextShift = null;

  switch (action.type) {
    case Actions.START_PRODUCTION_REVIEW_DATA_LOADING:
      previous = emptyShift;
      current = emptyShift;
      return {
        ...state,
        ...{
          initialLoaded: true,
          isCurrentPreviousShiftLoading: true,
          isCurrentPreviousShiftLoaded: false,
          initialLoadedShiftByDate: true,
          selectedShiftChanged: false,
          isGetShiftByDateLoaded: false,
          currentShift: current,
          previousShift: previous,
          continueHeartBeat: false,
          reloadPage: false,
        },
      };
    case Actions.PRODUCTION_REVIEW_DATA_LOADED:
      if (action.payload.previousShift !== undefined) {
        previous = action.payload.previousShift;
      }
      if (action.payload.currentShift !== undefined) {
        current = action.payload.currentShift;
        selected = action.payload.currentShift;
        selectedId = action.payload.currentShift.shiftId;
      }
      return {
        ...state,
        ...{
          isNextShiftLoaded: false,
          isCurrentPreviousShiftLoaded: true,
          isCurrentPreviousShiftLoading: false,
          allowLoadNextShift: true,
          selectedShiftChanged: true,
          startHeartBeat: false,
          currentShift: current,
          previousShift: previous,
          selectedShift: selected,
          selectedShiftId: selectedId,
        },
      };
    case Actions.SET_SELECTED_SHIFT:
      selected = action.payload;
      selectedId = selected.shiftId;
      return {
        ...state,
        ...{
          selectedShiftChanged: true,
          initialLoaded: false,
          selectedShift: selected,
          selectedShiftId: selectedId,
        },
      };
    case Actions.GET_SHIFTS_BY_DATE:
      return {
        ...state,
        ...{
          isGetShiftByDateLoading: true,
          isGetShiftByDateLoaded: false,
          initialLoadedShiftByDate: false,
          selectedShiftChanged: false,
          initialLoaded: false,
          selectedDate: action.payload.selectedDate,
          shiftsByDate: [],
        },
      };
    case Actions.SHIFTS_LOADED_BY_DATE:
      return {
        ...state,
        ...{
          isGetShiftByDateLoading: false,
          isGetShiftByDateLoaded: true,
          selectedShiftChanged: false,
          shiftsByDate: action.payload,
        },
      };
    case Actions.SET_SELECTED_SHIFT_BY_DATE:
      return {
        ...state,
        ...{
          selectedShiftChanged: true,
          selectedShift: state.currentShift,
          selectedShiftId: state.currentShift.shiftId,
        },
      };
    case Actions.START_NEXT_SHIFT_LOADING:
      return {
        ...state,
        ...{
          isNextShiftLoaded: false,
          allowLoadNextShift: false,
          loadingHeartBeat: false,
          continueHeartBeat: false,
          nextShiftStartTimeLoaded: false,
        },
      };
    case Actions.NEXT_SHIFT_LOADED:
      let nextShiftData = emptyNextShift;
      if (action.payload) {
        nextShiftData = {
          shift_id: action.payload['shift_id'],
          shift_name: action.payload['shift_name'],
          formatted_end_date: action.payload['formatted_end_date'],
          formatted_start_date: action.payload['formatted_start_date'],
        };
      }
      return {
        ...state,
        ...{
          nextShift: nextShiftData,
          isNextShiftLoaded: true,
          allowLoadNextShift: false,
        },
      };
    case Actions.IS_NEXT_SHIFT_STARTED:
      let nextShiftStartTime: number;
      let sessionTime: number = state.sessionTimer;
      let stopHeartBeat: boolean = false;
      let reload: boolean = false;
      let formattedNextShiftStartTime: string | Date;
      if (Object.keys(state.nextShift).length) {
        formattedNextShiftStartTime = moment(state.nextShift.formattedStartDate).format(iosTimestampFormat);
        nextShiftStartTime = (new Date(formattedNextShiftStartTime).getTime() - new Date().getTime()) / 1000;

        if (nextShiftStartTime < state.sessionTimer && Math.sign(nextShiftStartTime)) {
          sessionTime = Math.floor(nextShiftStartTime);
        }
        if (Math.sign(nextShiftStartTime) <= 0) {
          stopHeartBeat = true;
          reload = true;
        }
      }
      return {
        ...state,
        ...{
          continueHeartBeat: !stopHeartBeat,
          sessionTimer: sessionTime,
          reloadPage: reload,
          nextShiftStartTimeLoaded: true,
        },
      };
    case Actions.CLEAR_TO_STATE:
      return Object.assign({}, productionReviewInitialState);
    case Actions.SET_TABLE_SETTINGS:
      return {
        ...state,
        tableSettings: action.payload,
      };
    default:
      return state;
  }
}
