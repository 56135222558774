import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import * as ObjectActions from './bill-of-materials.actions';
import { catchError, switchMap } from 'rxjs/operators';
import { forkJoin, from, Observable, of } from 'rxjs';
import * as AppActions from '../../../../app/actions';
import { HttpParams } from '@angular/common/http';
import { Store } from '@ngrx/store';
import * as oeeAppReducer from '../../../../oee.reducer';
import {
  BaseOneResponseInterface,
  BulkResponseDataInterface,
  GetManyResponseInterface,
} from '../../../../../shared/model/interface/crud-response-interface.model';
import { BillOfMaterialsService } from './bill-of-materials.service';
import { IBillOfMaterials, IBillOfMaterialsData } from './bill-of-materials.model';
import { HTTPHelperService } from '../../../../../shared/service/http/http.helper.service';
import { ProductCRUDInterface } from '../../../../../shared/component/filter/filter.class';
import { ProductInterface } from '../../products.model';

@Injectable()
export class BillOfMaterialsEffects {
  constructor(
    private readonly actions$: Actions,
    private readonly service: BillOfMaterialsService,
    private readonly httpHelperService: HTTPHelperService,
    private readonly store: Store<oeeAppReducer.OeeAppState>,
  ) {}


  getBillOfMaterialsData = createEffect(() => this.actions$.pipe(
    ofType(ObjectActions.EBillOfMaterialsAction.GET_BILL_OF_MATERIALS_DATA),
    switchMap((payload: ObjectActions.GetBillOfMaterialsData) => {
      const bomParams: HttpParams = this.httpHelperService.insertGenericCrudRequestParameters(payload.params);
      const productParams: HttpParams = new HttpParams().append('join', 'site||decimalScaleLimit');

      return forkJoin([
        this.service.getBillOfMaterialsData(bomParams),
        this.service.getProduct(payload.productId, productParams),
      ]).pipe(
        switchMap(
          (response: (GetManyResponseInterface<IBillOfMaterials> | BaseOneResponseInterface<ProductInterface>)[]) => {
            const billOfMaterialsData: IBillOfMaterialsData = {
              billOfMaterials: response[0] as GetManyResponseInterface<IBillOfMaterials>,
              product: response[1] as BaseOneResponseInterface<ProductInterface>,
            };

            return of(new ObjectActions.GetBillOfMaterialsDataCompleted(billOfMaterialsData));
          },
        ),
        catchError((errorRes) => {
          return of(new ObjectActions.FetchError(errorRes), new AppActions.HideLoader());
        }),
      );
    }),
    catchError((errorRes) => {
      return of(new ObjectActions.FetchError(errorRes), new AppActions.HideLoader());
    }),
  ));


  getProducts = createEffect(() => this.actions$.pipe(
    ofType(ObjectActions.EBillOfMaterialsAction.GET_PRODUCTS),
    switchMap((payload: ObjectActions.GetProducts) => {
      let params: HttpParams = this.httpHelperService.insertGenericCrudRequestParameters(payload.params);
      const searchObject = JSON.parse(params.get('s'));

      if (payload.excludedProductIds.length) {
        searchObject.$and.push({ id: { $notin: payload.excludedProductIds } });
      }

      params = params.set('s', JSON.stringify(searchObject));

      return from(this.service.getProducts(params)).pipe(
        switchMap((response: GetManyResponseInterface<ProductCRUDInterface>) => {
          return of(new ObjectActions.GetProductsCompleted(response.data));
        }),
        catchError((errorRes) => {
          return of(new ObjectActions.FetchError(errorRes), new AppActions.HideLoader());
        }),
      );
    }),
    catchError((errorRes) => {
      return of(new ObjectActions.FetchError(errorRes), new AppActions.HideLoader());
    }),
  ));


  addBillOfMaterials = createEffect(() => this.actions$.pipe(
    ofType(ObjectActions.EBillOfMaterialsAction.ADD_BILL_OF_MATERIALS),
    switchMap((payload: ObjectActions.AddBillOfMaterials) => {
      this.store.dispatch(new AppActions.ShowLoader());

      return from(this.service.addBillOfMaterials(payload.body)).pipe(
        switchMap((response: BaseOneResponseInterface<IBillOfMaterials>) => {
          return of(new ObjectActions.AddBillOfMaterialsCompleted(response.data));
        }),
        catchError((errorRes) => {
          return of(new ObjectActions.FetchError(errorRes), new AppActions.HideLoader());
        }),
      );
    }),
    catchError((errorRes) => {
      return of(new ObjectActions.FetchError(errorRes), new AppActions.HideLoader());
    }),
  ));


  addBillOfMaterialsItem = createEffect(() => this.actions$.pipe(
    ofType(ObjectActions.EBillOfMaterialsAction.ADD_BILL_OF_MATERIALS_ITEM),
    switchMap((payload: ObjectActions.AddBillOfMaterialsItem) => {
      return from(this.service.addBillOfMaterialsItem(payload.body)).pipe(
        switchMap((response: BulkResponseDataInterface) => {
          return of(new ObjectActions.AddBillOfMaterialsItemCompleted(response), new AppActions.HideLoader());
        }),
        catchError((errorRes) => {
          return of(new ObjectActions.FetchError(errorRes), new AppActions.HideLoader());
        }),
      );
    }),
    catchError((errorRes) => {
      return of(new ObjectActions.FetchError(errorRes), new AppActions.HideLoader());
    }),
  ));


  editBillOfMaterials = createEffect(() => this.actions$.pipe(
    ofType(ObjectActions.EBillOfMaterialsAction.EDIT_BILL_OF_MATERIALS),
    switchMap((payload: ObjectActions.EditBillOfMaterials) => {
      this.store.dispatch(new AppActions.ShowLoader());

      const observables: Observable<BulkResponseDataInterface | BaseOneResponseInterface<IBillOfMaterials>>[] = [
        this.service.editBillOfMaterials(payload.params.billOfMaterialsId, payload.params.billOfMaterials),
      ];

      if (payload.params.billOfMaterialsItemToCreate.length) {
        observables.push(this.service.addBillOfMaterialsItem(payload.params.billOfMaterialsItemToCreate));
      }

      if (payload.params.billOfMaterialsItemToEdit.length) {
        observables.push(this.service.editBillOfMaterialsItem(payload.params.billOfMaterialsItemToEdit));
      }

      if (payload.params.billOfMaterialsItemToDelete.length) {
        observables.push(this.service.deleteBillOfMaterialsItem(payload.params.billOfMaterialsItemToDelete));
      }

      return forkJoin(observables).pipe(
        switchMap(() => {
          return of(new ObjectActions.EditBillOfMaterialsCompleted(), new AppActions.HideLoader());
        }),
        catchError((errorRes) => {
          return of(new ObjectActions.FetchError(errorRes), new AppActions.HideLoader());
        }),
      );
    }),
    catchError((errorRes) => {
      return of(new ObjectActions.FetchError(errorRes), new AppActions.HideLoader());
    }),
  ));


  deleteBillOfMaterials = createEffect(() => this.actions$.pipe(
    ofType(ObjectActions.EBillOfMaterialsAction.DELETE_BILL_OF_MATERIALS),
    switchMap((payload: ObjectActions.DeleteBillOfMaterials) => {
      this.store.dispatch(new AppActions.ShowLoader());

      return from(this.service.deleteBillOfMaterials(payload.billOfMaterialsId)).pipe(
        switchMap(() => {
          return of(new ObjectActions.DeleteBillOfMaterialsCompleted(true), new AppActions.HideLoader());
        }),
        catchError((errorRes) => {
          return of(
            new ObjectActions.FetchError(errorRes),
            new ObjectActions.DeleteBillOfMaterialsCompleted(false),
            new AppActions.HideLoader(),
          );
        }),
      );
    }),
    catchError((errorRes) => {
      return of(new ObjectActions.FetchError(errorRes), new AppActions.HideLoader());
    }),
  ));

  deleteBillOfMaterialsBulk = createEffect(() => this.actions$.pipe(
    ofType(ObjectActions.EBillOfMaterialsAction.DELETE_BILL_OF_MATERIALS_BULK),
    switchMap((payload: ObjectActions.DeleteBillOfMaterialsBulk) => {
      this.store.dispatch(new AppActions.ShowLoader());

      return from(this.service.deleteBillOfMaterialsBulk(payload.billOfMaterialsIds)).pipe(
        switchMap((response: BulkResponseDataInterface) => {
          return of(new ObjectActions.DeleteBillOfMaterialsBulkCompleted(response), new AppActions.HideLoader());
        }),
        catchError((errorRes) => {
          return of(new ObjectActions.FetchError(errorRes), new AppActions.HideLoader());
        }),
      );
    }),
    catchError((errorRes) => {
      return of(new ObjectActions.FetchError(errorRes), new AppActions.HideLoader());
    }),
  ));
}
