import { Component, Inject, InjectionToken, OnDestroy, OnInit } from '@angular/core';
import { interval, Subscription } from 'rxjs';
import { HomeActivityTreeChartService } from '../../../../store/home/home-activity-tree-chart.service';
import { HelperService } from '../../../../shared/service/helper.service';
import { TranslateService } from '@ngx-translate/core';
import { Store } from '@ngrx/store';
import { OeeAppState } from '../../../../store/oee.reducer';
import { User } from '../../../../store/user/model';
import { Line } from '../../../../store/line/model';
import { HomeStateInterface } from '../../../../store/home/home.model';
import * as HomeActions from '../../../../store/home/home.actions';
import * as _ from 'lodash';
import {
  PhaseDurationInterface,
  PhaseDurationMetricInterface,
  PhaseDurationsInterface,
  PhaseDurationTypeEnum,
} from './common-phase-duration-metric.model';
import { PhaseOptionNamesAsString } from '../../../../shared/model/enum/phase-options';
import { PROPERTIES } from '../home-metrics.component';

export const COMPONENT_TYPE_TOKEN = new InjectionToken('componentType');
export const DASHBOARD_MODE = new InjectionToken('dashboardMode');

@Component({
  selector: 'app-common-phase-duration-metric',
  templateUrl: './common-phase-duration-metric.component.html',
})
export class CommonPhaseDurationMetricComponent implements OnInit, OnDestroy {
  public phaseDuration$: PhaseDurationInterface;

  public phaseDurationMetric: PhaseDurationMetricInterface = {
    phaseDurationTextClass: null,
    progressBarClass: null,
    phaseTimer: null,
    phaseText: '',
    phaseDurationText: '',
    phaseDurationRateCapped: 0,
    phaseDurationRate: '0',
    roundedTarget: null,
    unit: '',
  };
  public phaseDataLoaded: boolean = false;

  public phaseTimerInterval: Subscription;

  private userTimezone$: string | null;
  private lineTimer$: string;
  private storeSubscriptions: Subscription[] = [];
  public readonly phaseDurationType: string;
  public mode: boolean;
  private selectedPhaseObject = {
    1: 'pre-run',
    2: 'post-run',
    3: 'run',
  };
  private shouldIgnorePhaseTimer: boolean;
  private workOrderId$: string = null;
  private woTableId$: number;
  private matrixBasedPostRunDuration: string | null = null;
  private quantityBasedRunDuration: string | null = null;
  private matrixBasedPreRunDuration: string | null = null;
  public properties: any = null;
  public preRunTaskTargetDuration: string | null = null;
  public postRunTaskTargetDuration: string | null = null;

  constructor(
    public homeService: HomeActivityTreeChartService,
    public helperService: HelperService,
    private translate: TranslateService,
    private store: Store<OeeAppState>,
    @Inject(COMPONENT_TYPE_TOKEN) private componentType: string,
    @Inject(DASHBOARD_MODE) private dashboardMode: boolean,
    @Inject(PROPERTIES) private readonly metricProperties: any,
  ) {
    this.phaseDurationType = componentType;
    this.mode = dashboardMode;

    if (componentType === 'pre-run') {
      this.phaseDurationType = 'preRun';
    } else if (componentType === 'post-run') {
      this.phaseDurationType = 'postRun';
    }
    this.properties = metricProperties;
  }

  public setPhaseDuration(): void {
    this.phaseDurationMetric = this.homeService.getPhaseDurationInfo(
      this.phaseDuration$,
      this.userTimezone$,
      this.lineTimer$,
    );

    if (this.phaseTimerInterval === undefined && !this.shouldIgnorePhaseTimer) {
      const aMinuteInMs = 60000;
      this.phaseTimerInterval = interval(aMinuteInMs).subscribe(() => {
        this.store.dispatch(new HomeActions.PhaseDurationIntervalTick(this.phaseDuration$.phaseDurationType));
      });
    }
  }

  public setTargetDuration(phaseDurations: PhaseDurationsInterface): string | null {
    const hasProperty = (obj: object): boolean => Object.keys(obj).length !== 0;
    const scheduleDuration: string | null =
      _.get(phaseDurations, [`${this.phaseDurationType}Target`], null) !== null
        ? phaseDurations[`${this.phaseDurationType}Target`]
        : null;

    switch (this.phaseDurationType) {
      case PhaseOptionNamesAsString.PRE_RUN:
        return hasProperty(this.properties) && this.properties.targetPreRunPhaseDuration === 'taskTargetDuration'
          ? this.preRunTaskTargetDuration
          : scheduleDuration !== null
          ? scheduleDuration
          : this.matrixBasedPreRunDuration;
      case PhaseOptionNamesAsString.RUN:
        return scheduleDuration !== null ? scheduleDuration : this.quantityBasedRunDuration;
      case PhaseOptionNamesAsString.POST_RUN:
        return hasProperty(this.properties) && this.properties.targetPostRunPhaseDuration === 'taskTargetDuration'
          ? this.postRunTaskTargetDuration
          : scheduleDuration !== null
          ? scheduleDuration
          : this.matrixBasedPostRunDuration;
      default: return null;
    }
  }

  public ngOnInit(): void {
    this.storeSubscriptions.push(
      this.store.select('user').subscribe((state: User) => {
        this.userTimezone$ = state.timezone;
      }),
    );
    this.storeSubscriptions.push(
      this.store.select('line').subscribe((state: Line) => {
        this.lineTimer$ = state.activityStart;
        this.workOrderId$ = state.workOrderId;
        this.woTableId$ = state.wOTableId;
        this.matrixBasedPostRunDuration = HelperService.cloneDeep(state.quantities.matrixBasedPostRunDuration);
        this.quantityBasedRunDuration = HelperService.cloneDeep(state.quantities.quantityBasedRunDuration);
        this.matrixBasedPreRunDuration = HelperService.cloneDeep(state.quantities.matrixBasedPreRunDuration);
        this.preRunTaskTargetDuration = HelperService.cloneDeep(state.quantities.preRunTaskTargetDuration);
        this.postRunTaskTargetDuration = HelperService.cloneDeep(state.quantities.postRunTaskTargetDuration);
      }),
    );
    this.storeSubscriptions.push(
      this.store.select('homeStore').subscribe((state: HomeStateInterface) => {
        if (state.phaseCommentsLoading) {
          this.phaseDataLoaded = false;
        }

        if (state.phaseDurationLoaded && state.line !== undefined) {
          this.phaseDuration$ = {
            phaseName: this.componentType,
            phaseDurationType: PhaseDurationTypeEnum[`${this.phaseDurationType}Duration`],
            phaseDuration:
              _.get(state.phaseDurations, [`${this.phaseDurationType}Duration`], null) !== null
                ? state.phaseDurations[`${this.phaseDurationType}Duration`]
                : null,
            phaseTargetDuration: this.setTargetDuration(state.phaseDurations),
            isCurrent:
              this.workOrderId$ &&
              this.componentType !== 'total' &&
              this.selectedPhaseObject[state.line.activityCard.selectedPhase] === this.componentType,
            activityName:
              this.componentType !== 'total'
                ? _.get(state.phaseDurations, [`${this.phaseDurationType}Activity`], null) !== null
                  ? state.phaseDurations[`${this.phaseDurationType}Activity`]
                  : null
                : null,
          };

          this.shouldIgnorePhaseTimer = state.line.activityCard.ignorePhase || !this.phaseDuration$.isCurrent;

          this.setPhaseDuration();
          this.phaseDataLoaded = this.componentType !== 'total' && this.workOrderId$ !== null;
        }

        if (state.phaseDurationLoading && this.phaseTimerInterval) {
          this.phaseTimerInterval.unsubscribe();
          this.phaseTimerInterval = undefined;
        }
      }),
    );
  }

  public ngOnDestroy(): void {
    this.storeSubscriptions.forEach((subscription: Subscription) => {
      subscription.unsubscribe();
    });

    if (this.phaseTimerInterval) {
      this.phaseTimerInterval.unsubscribe();
    }
  }
}
