import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import * as SemiManualCountButtonCardActions from './semi-manual-count-button-card.actions';
import { SemiManualCountButtonService } from '../../../../shared/service/station-home/station-home-kpi/semi-manual-count-button.service';
import { catchError, map, mergeMap, of, tap } from 'rxjs';
import { Store } from '@ngrx/store';
import * as AppActions from '../../../app/actions';
import * as oeeAppReducer from '../../../oee.reducer';

@Injectable()
export class SemiManualCountButtonCardEffects {
  constructor(
    private readonly actions$: Actions,
    private readonly store: Store<oeeAppReducer.OeeAppState>,
    private readonly stationHomeKPISemiManualCountButtonService: SemiManualCountButtonService,
  ) {}
  sendVirtualCounts$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SemiManualCountButtonCardActions.sendVirtualCounts),
      tap(() => this.store.dispatch(new AppActions.ShowTopLoader())),
      mergeMap((data) =>
        this.stationHomeKPISemiManualCountButtonService.sendVirtualCounts(data.stationID, data.payload).pipe(
          tap(() => this.store.dispatch(new AppActions.HideTopLoader())),
          map((_) => SemiManualCountButtonCardActions.sendVirtualCountsSuccess()),
          catchError((error) => of(SemiManualCountButtonCardActions.sendVirtualCountsFailure({ error }))),
        ),
      ),
    ),
  );
}
