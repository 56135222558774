import { NotificationRecipientUserCRUDInterface } from '../../../shared/component/filter/filter.class';
import {
  FilterNotificationRecipientUserActions,
  FilterNotificationRecipientUserActionTypes,
} from './notification-recipient-user.actions';

export interface FilterNotificationRecipientUserState {
  isLoading: boolean;
  isLoaded: boolean;
  data: NotificationRecipientUserCRUDInterface[];
}

export const initialFilterNotificationRecipientUserState: FilterNotificationRecipientUserState = {
  isLoaded: false,
  isLoading: false,
  data: [],
};

export function filterNotificationRecipientUserReducer(
  state: FilterNotificationRecipientUserState = initialFilterNotificationRecipientUserState,
  action: FilterNotificationRecipientUserActions,
): FilterNotificationRecipientUserState {
  switch (action.type) {
    case FilterNotificationRecipientUserActionTypes.FilterNotificationRecipientUsersLoading:
      return { ...state, isLoading: true };
    case FilterNotificationRecipientUserActionTypes.FilterNotificationRecipientUsersLoaded:
      return { ...state, data: action.data, isLoaded: true, isLoading: false };
    case FilterNotificationRecipientUserActionTypes.ResetNotificationRecipientUserStore:
      return initialFilterNotificationRecipientUserState;
    default:
      return state;
  }
}
