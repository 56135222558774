import { Inject, Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ILineViewGlobalServiceRoutes } from '../../../../../store/global-view/site-view/line-view/line-view.model';

@Injectable({
  providedIn: 'root',
})
export class LineViewGlobalService {
  private readonly routes: ILineViewGlobalServiceRoutes = {
    lineViewData: `${this.baseUrl}/lines/line-view-data`,
  };

  constructor(public http: HttpClient, @Inject('API_BASE_URL') private readonly baseUrl: string) {}

  public getLineViewStationData(siteId: number, lineId: number) {
    return this.http.post(
      this.routes.lineViewData,
      { siteId, lineId },
      {
        headers: new HttpHeaders({ 'X-HTTP-Method': 'GET' }),
      },
    );
  }
}
