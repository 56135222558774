import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NgxMaterialTimepickerModule } from 'ngx-material-timepicker';
import { Store } from '@ngrx/store';
import { OeeAppState } from '../../../../store/oee.reducer';
import { User } from '../../../../store/user/model';
import * as moment from 'moment';
import * as _ from 'lodash';
import { NgxMaterialTimepickerTheme } from 'ngx-material-timepicker/src/app/material-timepicker/models/ngx-material-timepicker-theme.interface';
import { ScwMatButtonModule } from '../scw-mat-button/scw-mat-button.module';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { take } from 'rxjs/operators';
import { NgIf, UpperCasePipe } from '@angular/common';
import { ControlValueAccessor } from '@angular/forms';
import { ScwMatInputRule } from '../scw-mat-input/scw-mat-input.model';
import { MatFormFieldModule } from '@angular/material/form-field';

@Component({
  selector: 'scw-mat-timepicker',
  standalone: true,
  styleUrls: ['./scw-mat-timepicker.component.scss'],
  templateUrl: './scw-mat-timepicker.component.html',
  imports: [NgxMaterialTimepickerModule, ScwMatButtonModule, TranslateModule, UpperCasePipe, MatFormFieldModule, NgIf],
})
export class ScwMatTimepickerComponent implements OnInit, ControlValueAccessor {
  @Input() hint: string | undefined;
  @Input() inputModel: string = '';
  @Input() appendToInput: boolean = true;
  @Input() rules: ScwMatInputRule[] = [];
  @Input() errorText: string;
  @Input() hasValidationControl: boolean = true;

  @Output() inputModelChange: EventEmitter<string> = new EventEmitter<string>();

  public format: 12 | 24;
  public defaultTime: string | null = null;
  public theme: NgxMaterialTimepickerTheme = {
    container: {
      buttonColor: '#53585f',
    },
    dial: {
      dialBackgroundColor: '#53585f',
    },
    clockFace: {
      clockHandColor: '#53585f',
    },
  };
  public isValid: boolean = true;

  constructor(private readonly store: Store<OeeAppState>, private readonly translate: TranslateService) {}

  public ngOnInit(): void {
    this.store
      .select('user')
      .pipe(take(1))
      .subscribe((state: User) => {
        this.format = ['AM', 'PM'].some((hourExt: string) =>
          moment().locale(state.locale).format('LT').includes(hourExt),
        )
          ? 12
          : 24;

        if (this.inputModel) {
          this.defaultTime = this.format === 24 ? this.inputModel.slice(0, 5) : this.convertTime24to12(this.inputModel);
        }
      });
  }

  public registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  public registerOnTouched(fn: any): void {
    this.onTouch = fn;
  }

  public writeValue(input: any): void {
    this.inputModel = input;
  }

  public checkRules(): void {
    if (this.rules.length === 0) {
      return;
    }

    this.isValid = true;

    for (const rule of this.rules) {
      if (!this.isValid) {
        return;
      }

      switch (true) {
        case 'required' in rule:
          this.requiredRule(rule);
          break;
        case 'custom' in rule:
          this.customRule(rule);
          break;
        default:
          return;
      }
    }

    if (!this.isValid) {
      return;
    }

    this.clearErrorMessage();
  }

  public reset(): void {
    this.inputModel = null;
    this.clearErrorMessage();
  }

  public clearErrorMessage(): void {
    this.isValid = true;
    this.errorText = null;
  }

  public timeSet(event: string): void {
    this.inputModel = this.format === 12 ? this.convertTime12to24(event) : `${event}:00`;
    this.inputModelChange.emit(this.inputModel);
    this.checkRules();
  }

  private onChange: any = () => {};
  private onTouch: any = () => {};

  private requiredRule(rule: ScwMatInputRule): void {
    if (
      _.isNil(this.inputModel) ||
      (typeof this.inputModel === 'string' && this.inputModel.length === 0) ||
      (this.inputModel.toString() || '').trim().length === 0
    ) {
      this.showErrorMessage(rule.message ?? this.translate.instant('scwMatForm.validation.required'));
    }
  }

  private customRule(rule: ScwMatInputRule): void {
    if ((this.inputModel || rule.validateEmptyForCustom) && rule.custom && !rule.validator(this.inputModel)) {
      this.showErrorMessage(rule.message);
    }
  }

  private showErrorMessage(message: string): void {
    this.isValid = false;
    this.errorText = message ?? '';
  }

  private convertTime12to24(time12h: string): string {
    const [time, modifier] = time12h.split(' ');

    let [hours, minutes] = time.split(':');

    if (hours === '12') {
      hours = '0';
    }

    return `${modifier === 'PM' ? Number(hours) + 12 : Number(hours) < 10 ? `0${hours}` : hours}:${minutes}:00`;
  }

  private convertTime24to12(time24h: string): string {
    const [hours, minutes] = time24h.split(':');

    let hoursAsNumber: number = Number(hours);
    let modifier: 'AM' | 'PM' = 'AM';

    if (hoursAsNumber >= 12) {
      hoursAsNumber = hoursAsNumber - 12 || 12;
      modifier = 'PM';
    }

    return `${hoursAsNumber}:${minutes} ${modifier}`;
  }
}
