<ng-container *ngIf="cicoData.length; else noData">
  <div class="row">
    <div class="col-xl-3 col-lg-4" *ngFor="let item of formattedCicoData | slice: 0:isCollapsedGroups ? 0 : formattedCicoData.length">
      <div [class.select-mode]="selectMode" (click)="onClickCicoItem(item)">
        <app-card blockClass="p-0">
          <div class="select-mode-selected-filter" *ngIf="selectMode && isItemSelected(item.id)"></div>
          <div class="select-mode-checkbox pe-none" *ngIf="selectMode">
            <scw-mat-checkbox [inputModel]="isItemSelected(item.id)" (inputModelChange)="onClickCicoItem(item)"></scw-mat-checkbox>
          </div>
          <div
            [class.select-mode-border]="selectMode && !isItemSelected(item.id)"
            [class.select-mode-selected-border]="selectMode && isItemSelected(item.id)"
          >
            <scw-cico-item
              [cicoData]="item"
              [progressBarText]="progressBarText"
              [isExpanded]="isExpanded"
              [selectMode]="selectMode"
              [isSelected]="isItemSelected(item.id)"
              [showWorkOrderRow]="showWorkOrderRow"
              [isMatMenuVisible]="isMatMenuVisible && !selectMode"
              (changeStationEmitter)="onChangeStationClick(pinModal, $event)"
            ></scw-cico-item>
          </div>
        </app-card>
      </div>
    </div>
  </div>
</ng-container>
<ng-template #noData>
  <span>{{ 'datatable.noData' | translate }}</span>
</ng-template>

<ng-template #pinModal let-modal>
  <scw-mat-form (scwMatFormSubmit)="changeStationFormSubmit($event)">
    <div class="modal-header scw-modal-header">
      <h3 class="modal-title scw-header">
        {{
          'cico.checkInTitle'
            | translate
              : { name: changeStationData.type === ECicoType.LABOR ? laborModuleTranslation : assetModuleTranslation }
        }}
      </h3>
      <button type="button" class="close" onfocus="blur()" aria-label="Close" (click)="modal.dismiss()">
        <span aria-hidden="true">
          <em class="fas fa-times"></em>
        </span>
      </button>
    </div>
    <div class="modal-body">
      <div class="d-flex justify-content-around align-items-center">
        <scw-mat-input
          type="text"
          label="{{ 'general.from' | translate }}"
          [(inputModel)]="changeStationData.fromObjectName"
          [disabled]="true"
          data-automation-id="edit-pin-field"
        ></scw-mat-input>
        <em class="fas fa-arrow-right px-2 m-auto"></em>
        <scw-mat-input
          type="text"
          label="{{ 'general.to' | translate }}"
          [(inputModel)]="changeStationData.toObjectName"
          [disabled]="true"
          data-automation-id="edit-pin-field"
        ></scw-mat-input>
      </div>
      <div class="row">
        <div class="col-12">
          <scw-mat-input
            type="password"
            label="{{ 'cico.checkInForm.checkInPin.label' | translate }}*"
            [rules]="[{ required: true }, { maxLength: InputLimit.CHECK_IN_CHECK_OUT_PIN_MAX_LENGTH }]"
            [(inputModel)]="checkInPin"
            data-automation-id="edit-pin-field"
          ></scw-mat-input>
        </div>
      </div>
    </div>
    <div class="modal-footer">
      <div class="buttons-right">
        <scw-mat-button type="standard" (onClick)="modal.dismiss()" data-automation-id="cancel-btn">
          {{ 'general.cancel' | translate }}
        </scw-mat-button>
        <scw-mat-button [isSubmitButton]="true" data-automation-id="save-btn">{{
          'general.save' | translate
        }}</scw-mat-button>
      </div>
    </div>
  </scw-mat-form>
</ng-template>
