import { CustomerInterface } from '../../../view/settings/customer-settings/customers/customers.model';
import { CustomerFilterActions, FilterCustomerActionTypes } from './customer.actions';

export interface FilterCustomerState {
  isLoading: boolean;
  isLoaded: boolean;
  data: Partial<CustomerInterface>[];
}

export const initialFilterCustomerState: FilterCustomerState = {
  isLoaded: false,
  isLoading: false,
  data: [],
};

export function filterCustomerReducer(
  state: FilterCustomerState = initialFilterCustomerState,
  action: CustomerFilterActions,
): FilterCustomerState {
  switch (action.type) {
    case FilterCustomerActionTypes.StartLoadFilterCustomers:
      return { ...state, isLoading: true, isLoaded: false, data: [] };

    case FilterCustomerActionTypes.FilterCustomersLoaded:
      return { ...state, data: action.data, isLoaded: true, isLoading: false };

    default:
      return state;
  }
}
