import { Injectable } from '@angular/core';
import * as heic2any from 'heic2any';
import { FileHelperService } from './file-helper.service';

@Injectable({
  providedIn: 'root',
})
export class ImageHelperService {
  constructor() {}

  public getImageUploadFormData(base64ImageContent: string): FormData {
    const mimeType: string = FileHelperService.base64MimeType(base64ImageContent);
    const fileExtension: string = FileHelperService.getMimeTypeExtension(mimeType);
    const imageName: string = `image-upload.${fileExtension}`;
    const imageFile = new File([FileHelperService.dataURItoBlob(base64ImageContent)], imageName, {
      type: mimeType,
    });

    const formData = new FormData();
    formData.append('file', imageFile, imageFile.name);

    return formData;
  }

  public static removeVersionInfoFromPath(path: string): string {
    const pathParts = path.split('?');
    return pathParts[0];
  }

  public static getBase64Size(base64Image: string): number {
    const base64WithoutPrefix: string = base64Image.replace(/^data:[^;]+;base64,/, '');
    const binaryImage: string = atob(base64WithoutPrefix);

    return binaryImage.length / (1024 * 1024);
  }

  public static async convertHeicToJpeg(heicFile: File): Promise<File> {
    return new Promise(async (resolve, reject): Promise<void> => {
      try {
        const arrayBuffer: ArrayBuffer = await heicFile.arrayBuffer();
        const jpegBlob: BlobPart = (await heic2any.default({
          blob: new Blob([arrayBuffer]),
          toType: 'image/jpeg',
        })) as BlobPart;
        const jpegFile: File = new File([jpegBlob], heicFile.name.replace(/\.heic$/, '.jpg'), {
          type: 'image/jpeg',
          lastModified: Date.now(),
        });

        resolve(jpegFile);
      } catch (error) {
        reject(error);
      }
    });
  }
}
