import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import * as ObjectActions from './sensor-statuses.actions';
import { catchError, switchMap } from 'rxjs/operators';
import * as AppActions from '../app/actions';
import { of } from 'rxjs';
import { IDeviceAndSensors } from './sensor-statuses.model';
import { SensorStatusesService } from './sensor-statuses.service';
import { GetManyResponseInterface } from '../../shared/model/interface/crud-response-interface.model';

@Injectable()
export class SensorStatusesEffects {
  constructor(private actions$: Actions, public service: SensorStatusesService) {}

  getSensorStatusesData = createEffect(() =>
    this.actions$.pipe(
      ofType(ObjectActions.GET_SENSOR_STATUSES),
      switchMap((objectData: ObjectActions.GetSensorStatuses) => {
        return this.service.getSensorStatuses(objectData.lineId).pipe(
          switchMap((resData: GetManyResponseInterface<IDeviceAndSensors>) => {
            return of(new ObjectActions.GetSensorStatusesCompleted(resData.data));
          }),
          catchError((errorRes) => {
            return of(new ObjectActions.FetchError(errorRes), new AppActions.HideLoader());
          }),
        );
      }),
      catchError((errorRes) => {
        return of(new ObjectActions.FetchError(errorRes), new AppActions.HideLoader());
      }),
    ),
  );
}
