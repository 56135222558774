<div class="bg-c-lightGrey w-100 h-50 mb-1 text-center p-2 br-5">
  <p class="head-text-2 three-dot c-title m-0">{{ 'activityCards.goodCountSensorLabel' | translate }}</p>
  <p class="head-text three-dot c-green m-0">
    {{ goodCount }}
    <span *ngIf="goodCountUnit">{{ goodCountUnit }}</span>
  </p>
  <p class="metric-info" *ngIf="showMetricInfo">{{ goodCountInfoText }}</p>
</div>
<div class="bg-c-lightGrey w-100 h-50 mb-1 mt-1 text-center p-2 br-5">
  <p class="head-text-2 three-dot c-title m-0">{{ 'activityCards.scrapCountSensorLabel' | translate }}</p>
  <p [attr.title]="tooltipText" [title]="tooltipText" class="head-text three-dot c-red m-0">
    {{ scrapCount }}
    <span *ngIf="scrapCountUnit">{{ scrapCountUnit }}</span>
  </p>
  <p class="metric-info" *ngIf="showMetricInfo">{{ scrapCountInfoText }}</p>
</div>
