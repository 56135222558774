import {
  CountEntriesInterface,
  IncrementDistributionMethod,
  RegularOrHourlyManualCount,
} from '../../../view/home/work-order/work-order-manual-count/work-order-manual-count.model';
import { DecimalHelper } from './decimal-helper';
import { ScwMatInputRule } from '../../component/scw-mat-ui/scw-mat-input/scw-mat-input.model';
import { DECIMAL_MAX_VALUE_AS_INT, ECountEntryMethods } from '../../../../constants';
import { ManualCountTypes } from '../../../view/home/models/line.model';
import { TranslateService } from '@ngx-translate/core';
import { Injectable } from '@angular/core';
import { getCurrentDateTimeAsMoment, mysqlTimestampFormat } from '../date';
import { Store } from '@ngrx/store';
import { OeeAppState } from '../../../store/oee.reducer';
import { take } from 'rxjs/operators';
import * as ManualCountActions from '../../../store/work-order-manual-count/actions';
import * as AppActions from '../../../store/app/actions';
import * as moment from 'moment/moment';
import { maxBy } from 'lodash';

export type TFlexibleCount =
  | { goodCount: string; scrapCount: string }
  | { initialCount: string; goodCount: string }
  | { initialCount: string; scrapCount: string };

export type TAllCounts = { goodCount: string; scrapCount: string; initialCount: string };

export enum ECountTypes {
  goodCount = 'goodCount',
  scrapCount = 'scrapCount',
  initialCount = 'initialCount',
}

export interface IManageCountOutput {
  previousCounts: TAllCounts;
  increments: TAllCounts;
  totalCounts: TAllCounts;
}

@Injectable()
export class CountHelper {
  private readonly goodCountErrorMessage: string = this.translate.instant(
    'main.workOrder.workOrderManuelCountForm.goodCount.errors.goodCountIsLessThanZero',
  );
  private readonly initialCountErrorMessage: string = this.translate.instant(
    'main.workOrder.workOrderManuelCountForm.initialCount.errors.initialCountIsLessThanZero',
  );

  constructor(
    private readonly decimalHelper: DecimalHelper,
    private readonly translate: TranslateService,
    private readonly store: Store<OeeAppState>,
  ) {}

  public getIsRelationalCountEntryAllowedRule(
    errorTarget: 'goodCount' | 'initialCount',
    inputCounts: TAllCounts,
    previousCounts: TAllCounts,
    countEntryMethod: ECountEntryMethods.REDUCIBLE_CUMULATIVE | ECountEntryMethods.REDUCIBLE_INCREMENTAL,
  ): [ScwMatInputRule] | never[] {
    const isNegative =
      countEntryMethod === ECountEntryMethods.REDUCIBLE_INCREMENTAL
        ? this.decimalHelper.isLessThan(
            this.decimalHelper.add(inputCounts[errorTarget], previousCounts[errorTarget]),
            '0',
          )
        : this.decimalHelper.isLessThan(inputCounts[errorTarget], '0');

    if (!isNegative) {
      return [];
    }

    const error = errorTarget === 'goodCount' ? this.goodCountErrorMessage : this.initialCountErrorMessage;
    return [
      {
        minValue: DECIMAL_MAX_VALUE_AS_INT,
        message: error,
      },
    ];
  }

  public convertFlexibleCountToAllCounts(value: TFlexibleCount): TAllCounts {
    if ('goodCount' in value && 'scrapCount' in value) {
      return {
        ...value,
        initialCount: this.decimalHelper.add(value.goodCount, value.scrapCount),
      };
    }

    if ('initialCount' in value && 'goodCount' in value) {
      return {
        ...value,
        scrapCount: this.decimalHelper.subtract(value.initialCount, value.goodCount),
      };
    }

    return {
      ...value,
      goodCount: this.decimalHelper.add(value.initialCount, value.scrapCount),
    };
  }

  public convertInputCountsIntoAllCounts(values: CountEntriesInterface, manualCountType: ManualCountTypes): TAllCounts {
    const entry1 = this.decimalHelper.sanitizeString(values.entry1);
    const entry2 = this.decimalHelper.sanitizeString(values.entry2);

    switch (manualCountType) {
      case ManualCountTypes.YIELD_AND_SCRAP:
        const goodAndScrap = {
          goodCount: entry1,
          scrapCount: entry2,
        };

        return {
          ...goodAndScrap,
          initialCount: this.extractTargetCount(goodAndScrap, ECountTypes.initialCount),
        };
      case ManualCountTypes.INITIAL_AND_YIELD:
        const initialAndGood = {
          initialCount: entry1,
          goodCount: entry2,
        };

        return {
          ...initialAndGood,
          scrapCount: this.extractTargetCount(initialAndGood, ECountTypes.scrapCount),
        };
      case ManualCountTypes.INITIAL_AND_SCRAP:
        const initialAndScrap = {
          initialCount: entry1,
          scrapCount: entry2,
        };

        return {
          ...initialAndScrap,
          goodCount: this.extractTargetCount(initialAndScrap, ECountTypes.goodCount),
        };
    }
  }

  public convertFlexibleCountsIntoInputCounts(
    previousCounts: TAllCounts,
    manualCountType: ManualCountTypes,
  ): CountEntriesInterface {
    const formattedGoodCount = this.decimalHelper.formatBySeparator(previousCounts.goodCount, false);
    const formattedScrapCount = this.decimalHelper.formatBySeparator(previousCounts.scrapCount, false);
    const formattedInitialCount = this.decimalHelper.formatBySeparator(previousCounts.initialCount, false);

    switch (manualCountType) {
      case ManualCountTypes.YIELD_AND_SCRAP:
        return { entry1: formattedGoodCount, entry2: formattedScrapCount };
      case ManualCountTypes.INITIAL_AND_YIELD:
        return { entry1: formattedInitialCount, entry2: formattedGoodCount };
      case ManualCountTypes.INITIAL_AND_SCRAP:
        return { entry1: formattedInitialCount, entry2: formattedScrapCount };
    }
  }

  public submitReducibleCountDiff(
    counts: IManageCountOutput,
    workOrderId: number,
    lineId: number,
    productionCounts: RegularOrHourlyManualCount[],
  ): void {
    this.store.dispatch(new AppActions.ShowLoader());
    const isInitialLessThanZero = this.decimalHelper.isLessThan(counts.increments.initialCount, '0');
    const isGoodLessThanZero = this.decimalHelper.isLessThan(counts.increments.goodCount, '0');

    if (!isInitialLessThanZero && !isGoodLessThanZero) {
      this.store
        .select('user', 'timezone')
        .pipe(take(1))
        .subscribe((timezone) => {
          const submitTimestamp = getCurrentDateTimeAsMoment(timezone).format(mysqlTimestampFormat);
          const systemCountAddedCounts = this.getOverriddenSystemCountCompensatedTotals(
            counts,
            productionCounts,
            submitTimestamp,
          );
          this.store.dispatch(
            new ManualCountActions.CreateManualCount({
              workOrderId,
              lineId,
              goodCount: systemCountAddedCounts.increments.goodCount,
              scrapCount: systemCountAddedCounts.increments.scrapCount,
              timestamp: submitTimestamp,
            }),
          );
        });

      return;
    }

    const isScrapLessThanZero = this.decimalHelper.isLessThan(counts.increments.scrapCount, '0');

    this.store.dispatch(
      new ManualCountActions.ManualCountSetDistributionLoading(
        {
          workOrderId,
          goodCount: counts.totalCounts.goodCount,
          scrapCount: counts.totalCounts.scrapCount,
          doApproveOngoingShiftHour: false,
          ...(isScrapLessThanZero && isGoodLessThanZero
            ? {}
            : { incrementDistributionMethod: IncrementDistributionMethod.lastHour }),
        },
        true,
      ),
    );
  }

  public extractIncrementsFromTotalCounts(previousCounts: TAllCounts, totalCounts: TAllCounts): TAllCounts {
    return {
      initialCount: this.decimalHelper.subtract(totalCounts.initialCount, previousCounts.initialCount),
      goodCount: this.decimalHelper.subtract(totalCounts.goodCount, previousCounts.goodCount),
      scrapCount: this.decimalHelper.subtract(totalCounts.scrapCount, previousCounts.scrapCount),
    };
  }

  private getOverriddenSystemCountCompensatedTotals(
    counts: IManageCountOutput,
    productionCounts: RegularOrHourlyManualCount[],
    submitTimestamp: string,
  ): IManageCountOutput {
    const sameHourManualCounts: RegularOrHourlyManualCount[] = productionCounts.filter(
      (tableData: RegularOrHourlyManualCount) =>
        !tableData.isSystem && moment(tableData.timestamp).endOf('h').isSame(moment(submitTimestamp).endOf('h')),
    );

    const maxManualCountTimestampWithinPostDataHour: string | undefined = maxBy(
      sameHourManualCounts,
      'timestamp',
    )?.timestamp;

    if (
      maxManualCountTimestampWithinPostDataHour !== undefined &&
      submitTimestamp <= maxManualCountTimestampWithinPostDataHour
    ) {
      return counts;
    }

    const sameHourSystemCount: RegularOrHourlyManualCount | undefined = productionCounts.find(
      (tableData: RegularOrHourlyManualCount) =>
        tableData.isSystem && moment(tableData.timestamp).endOf('h').isSame(moment(submitTimestamp).endOf('h')),
    );

    return sameHourSystemCount
      ? {
          ...counts,
          increments: {
            initialCount: this.decimalHelper.add(
              counts.increments.initialCount,
              this.decimalHelper.add(
                this.decimalHelper.sanitizeString(sameHourSystemCount.goodCount || '0'),
                this.decimalHelper.sanitizeString(sameHourSystemCount.scrapCount || '0'),
              ),
            ),
            goodCount: this.decimalHelper.add(
              counts.increments.goodCount,
              this.decimalHelper.sanitizeString(sameHourSystemCount.goodCount || '0'),
            ),
            scrapCount: this.decimalHelper.add(
              counts.increments.scrapCount,
              this.decimalHelper.sanitizeString(sameHourSystemCount.scrapCount || '0'),
            ),
          },
          totalCounts: {
            initialCount: this.decimalHelper.add(
              counts.totalCounts.initialCount,
              this.decimalHelper.add(
                this.decimalHelper.sanitizeString(sameHourSystemCount.goodCount || '0'),
                this.decimalHelper.sanitizeString(sameHourSystemCount.scrapCount || '0'),
              ),
            ),
            goodCount: this.decimalHelper.add(
              counts.totalCounts.goodCount,
              this.decimalHelper.sanitizeString(sameHourSystemCount.goodCount || '0'),
            ),
            scrapCount: this.decimalHelper.add(
              counts.totalCounts.scrapCount,
              this.decimalHelper.sanitizeString(sameHourSystemCount.scrapCount || '0'),
            ),
          },
        }
      : counts;
  }

  private extractTargetCount(value: TFlexibleCount, target: ECountTypes): string {
    switch (target) {
      case ECountTypes.goodCount:
        return 'goodCount' in value
          ? value.goodCount
          : this.decimalHelper.subtract(value.initialCount, value.scrapCount);
      case ECountTypes.scrapCount:
        return 'scrapCount' in value
          ? value.scrapCount
          : this.decimalHelper.subtract(value.initialCount, value.goodCount);
      case ECountTypes.initialCount:
        return 'initialCount' in value ? value.initialCount : this.decimalHelper.add(value.goodCount, value.scrapCount);
    }
  }
}
