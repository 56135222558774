import { Injectable } from '@angular/core';
import * as AppActions from '../../app/actions';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import * as ObjectActions from '../home-page-display-settings/home-page-display-settings.actions';
import { catchError, switchMap } from 'rxjs/operators';
import { HttpParams } from '@angular/common/http';
import { of } from 'rxjs';
import {
  BaseOneResponseInterface,
  GetManyResponseInterface,
} from '../../../shared/model/interface/crud-response-interface.model';
import { LineCRUDInterface } from '../../../shared/component/filter/filter.class';
import { Store } from '@ngrx/store';
import * as oeeAppReducer from '../../oee.reducer';
import { LineService } from '../../../shared/service/line/line.service';
import { HomePageDisplaySettingsService } from './home-page-display-settings.service';
import {
  IAddActivityOrderResponseInterface,
  IAddLayoutResponse,
  IHomeMetricSetDataResponseInterface,
  ILayout,
  ILayoutCardTemplateDataResponseInterface,
  ILayoutCardTemplateResponse,
  ILookup,
} from './home-page-display-settings.model';
import { ActivitiesService } from '../../../shared/service/activities/activities.service';
import { ActivitiesInterface } from '../../../shared/model/interface/activities.model';
import { LookupsService } from '../../lookups/lookups.service';
import { TUnitConversionResponse } from '../unit-conversion/unit-conversion.model';
import { UnitConversionService } from '../unit-conversion/unit-conversion.service';

@Injectable()
export class HomePageDisplaySettingsEffects {
  constructor(
    private readonly actions$: Actions,
    private readonly store: Store<oeeAppReducer.OeeAppState>,
    private readonly lineService: LineService,
    private readonly service: HomePageDisplaySettingsService,
    private readonly activitiesService: ActivitiesService,
    private readonly lookupsService: LookupsService,
    private readonly unitConversionService: UnitConversionService,
  ) {}

  getLines = createEffect(() =>
    this.actions$.pipe(
      ofType(ObjectActions.GET_LINES),
      switchMap((objectData: ObjectActions.GetLines) => {
        this.store.dispatch(new AppActions.ShowLoader());
        const search = {
          statusId: 1,
          siteId: objectData.siteId,
        };
        const httpParams: HttpParams = new HttpParams().set('s', JSON.stringify(search)).set('limit', 1000);

        return this.lineService.getLines(httpParams).pipe(
          switchMap((response: GetManyResponseInterface<LineCRUDInterface>) => {
            return of(new ObjectActions.GetLinesCompleted(response), new AppActions.HideLoader());
          }),
          catchError((error) => {
            return of(new ObjectActions.FetchError(error), new AppActions.HideLoader());
          }),
        );
      }),
      catchError((error) => {
        return of(new ObjectActions.FetchError(error), new AppActions.HideLoader());
      }),
    ),
  );

  addLayout = createEffect(() =>
    this.actions$.pipe(
      ofType(ObjectActions.ADD_LAYOUT),
      switchMap((objectData: ObjectActions.AddLayout) => {
        this.store.dispatch(new AppActions.ShowLoader());

        return this.service.addLayouts(objectData.layout).pipe(
          switchMap((response: IAddLayoutResponse) => {
            return of(new ObjectActions.AddLayoutCompleted(response), new AppActions.HideLoader());
          }),
          catchError((error) => {
            return of(new ObjectActions.FetchError(error), new AppActions.HideLoader());
          }),
        );
      }),
      catchError((error) => {
        return of(new ObjectActions.FetchError(error), new AppActions.HideLoader());
      }),
    ),
  );

  editLayout = createEffect(() =>
    this.actions$.pipe(
      ofType(ObjectActions.EDIT_LAYOUT),
      switchMap((objectData: ObjectActions.EditLayout) => {
        this.store.dispatch(new AppActions.ShowLoader());

        return this.service.editLayout(objectData.layout, objectData.id).pipe(
          switchMap((response: IAddLayoutResponse) => {
            return of(new ObjectActions.EditLayoutCompleted(response), new AppActions.HideLoader());
          }),
          catchError((error) => {
            return of(new ObjectActions.FetchError(error), new AppActions.HideLoader());
          }),
        );
      }),
      catchError((error) => {
        return of(new ObjectActions.FetchError(error), new AppActions.HideLoader());
      }),
    ),
  );

  getLayoutData = createEffect(() =>
    this.actions$.pipe(
      ofType(ObjectActions.LAYOUT_DATA_LOADING),
      switchMap((objectData: ObjectActions.LayoutDataLoading) => {
        this.store.dispatch(new AppActions.ShowLoader());

        const andConditions = [];
        let httpParams: HttpParams = new HttpParams()
          .append('join', 'layoutAssignment')
          .append('join', 'layoutAssignment.line')
          .append('join', 'layoutAssignment.line.status')
          .append('join', 'homeMetricSet')
          .append('join', 'homeMetricSet.homeMetricSetItems')
          .append('join', 'homeMetricSet.homeMetricSetItems.homeMetricSetProperties')
          .append('join', 'layoutCardTemplate')
          .append('page', String(objectData.tableQuery.page))
          .append('limit', String(objectData.tableQuery.pageSize || 1000))
          .append('sort', 'name,ASC')
          .append('sort', 'homeMetricSet.homeMetricSetItems.order,ASC');

        if (objectData.tableQuery.search) {
          andConditions.push({ name: { $cont: objectData.tableQuery.search } });
        }

        if (objectData.tableQuery.siteIds && objectData.tableQuery.siteIds.length > 0) {
          andConditions.push({ 'layoutAssignment.line.siteId': { $in: objectData.tableQuery.siteIds } });
        }

        if (andConditions.length > 0) {
          httpParams = httpParams.set('s', JSON.stringify({ $and: andConditions }));
        }

        return this.service.getLayouts(httpParams).pipe(
          switchMap((response: GetManyResponseInterface<ILayout>) => {
            return of(new ObjectActions.LayoutDataLoaded(response), new AppActions.HideLoader());
          }),
          catchError((errorRes) => {
            return of(new ObjectActions.FetchError(errorRes), new AppActions.HideLoader());
          }),
        );
      }),
      catchError((errorRes) => {
        return of(new ObjectActions.FetchError(errorRes), new AppActions.HideLoader());
      }),
    ),
  );

  addActivityOrderItemsData = createEffect(() =>
    this.actions$.pipe(
      ofType(ObjectActions.ADD_ACTIVITY_ORDER_ITEMS_DATA),
      switchMap((objectData: ObjectActions.AddActivityOrderItemsData) => {
        this.store.dispatch(new AppActions.ShowLoader());

        return this.service.addActivityOrder(objectData.activityOrderData).pipe(
          switchMap((response: IAddActivityOrderResponseInterface) => {
            return of(new ObjectActions.AddActivityOrderItemsDataCompleted(response), new AppActions.HideLoader());
          }),
          catchError((error) => {
            return of(new ObjectActions.FetchError(error), new AppActions.HideLoader());
          }),
        );
      }),
      catchError((error) => {
        return of(new ObjectActions.FetchError(error), new AppActions.HideLoader());
      }),
    ),
  );

  editActivityOrderItemsData = createEffect(() =>
    this.actions$.pipe(
      ofType(ObjectActions.EDIT_ACTIVITY_ORDER_ITEMS_DATA),
      switchMap((objectData: ObjectActions.EditActivityOrderItemsData) => {
        this.store.dispatch(new AppActions.ShowLoader());

        return this.service.editActivityOrder(objectData.activityOrderData, objectData.layoutId).pipe(
          switchMap((response: IAddActivityOrderResponseInterface) => {
            return of(new ObjectActions.EditActivityOrderItemsDataCompleted(response), new AppActions.HideLoader());
          }),
          catchError((error) => {
            return of(new ObjectActions.FetchError(error), new AppActions.HideLoader());
          }),
        );
      }),
      catchError((error) => {
        return of(new ObjectActions.FetchError(error), new AppActions.HideLoader());
      }),
    ),
  );

  getActivitiesData = createEffect(() =>
    this.actions$.pipe(
      ofType(ObjectActions.LAYOUT_ACTIVITIES_DATA_LOADING),
      switchMap((objectData: ObjectActions.LayoutActivitiesDataLoading) => {
        this.store.dispatch(new AppActions.ShowTopLoader());

        return this.activitiesService.getMultipleLineActivities(objectData.lineIds, objectData.layoutId).pipe(
          switchMap((response: GetManyResponseInterface<ActivitiesInterface>) => {
            return of(new ObjectActions.LayoutActivitiesDataLoaded(response), new AppActions.HideTopLoader());
          }),
          catchError((errorRes) => {
            return of(new ObjectActions.FetchError(errorRes), new AppActions.HideTopLoader());
          }),
        );
      }),
      catchError((errorRes) => {
        return of(new ObjectActions.FetchError(errorRes), new AppActions.HideTopLoader());
      }),
    ),
  );

  addLayoutCardTemplateData = createEffect(() =>
    this.actions$.pipe(
      ofType(ObjectActions.ADD_LAYOUT_CARD_TEMPLATE_DATA),
      switchMap((objectData: ObjectActions.AddLayoutCardTemplateData) => {
        this.store.dispatch(new AppActions.ShowLoader());

        return this.service.addLayoutCardTemplate(objectData.layoutCardTemplateData).pipe(
          switchMap((response: ILayoutCardTemplateDataResponseInterface) => {
            return of(new ObjectActions.AddLayoutCardTemplateDataCompleted(response), new AppActions.HideLoader());
          }),
          catchError((error) => {
            return of(new ObjectActions.FetchError(error), new AppActions.HideLoader());
          }),
        );
      }),
      catchError((error) => {
        return of(new ObjectActions.FetchError(error), new AppActions.HideLoader());
      }),
    ),
  );

  editLayoutCardTemplateData = createEffect(() =>
    this.actions$.pipe(
      ofType(ObjectActions.EDIT_LAYOUT_CARD_TEMPLATE_DATA),
      switchMap((objectData: ObjectActions.EditLayoutCardTemplateData) => {
        this.store.dispatch(new AppActions.ShowLoader());

        return this.service.editLayoutCardTemplate(objectData.id, objectData.layoutCardTemplateData).pipe(
          switchMap((response: ILayoutCardTemplateDataResponseInterface) => {
            return of(new ObjectActions.EditLayoutCardTemplateDataCompleted(response), new AppActions.HideLoader());
          }),
          catchError((error) => {
            return of(new ObjectActions.FetchError(error), new AppActions.HideLoader());
          }),
        );
      }),
      catchError((error) => {
        return of(new ObjectActions.FetchError(error), new AppActions.HideLoader());
      }),
    ),
  );

  deleteLayout = createEffect(() =>
    this.actions$.pipe(
      ofType(ObjectActions.DELETE_LAYOUT),
      switchMap((objectData: ObjectActions.DeleteLayout) => {
        this.store.dispatch(new AppActions.ShowLoader());

        return this.service.deleteLayout(objectData.layoutId).pipe(
          switchMap((response: IAddLayoutResponse) => {
            return of(new ObjectActions.DeleteLayoutCompleted(response), new AppActions.HideLoader());
          }),
          catchError((error) => {
            return of(new ObjectActions.FetchError(error), new AppActions.HideLoader());
          }),
        );
      }),
      catchError((error) => {
        return of(new ObjectActions.FetchError(error), new AppActions.HideLoader());
      }),
    ),
  );

  addHomeMetricSetData = createEffect(() =>
    this.actions$.pipe(
      ofType(ObjectActions.ADD_HOME_METRIC_SET_LOADING),
      switchMap((objectData: ObjectActions.AddHomeMetricSet) => {
        this.store.dispatch(new AppActions.ShowLoader());

        return this.service.addHomeMetricSet(objectData.homeMetricSet).pipe(
          switchMap((response: IHomeMetricSetDataResponseInterface) => {
            return of(new ObjectActions.AddHomeMetricSetLoaded(response), new AppActions.HideLoader());
          }),
          catchError((error) => {
            return of(new ObjectActions.FetchError(error), new AppActions.HideLoader());
          }),
        );
      }),
      catchError((error) => {
        return of(new ObjectActions.FetchError(error), new AppActions.HideLoader());
      }),
    ),
  );

  editHomeMetricSetData = createEffect(() =>
    this.actions$.pipe(
      ofType(ObjectActions.EDIT_HOME_METRIC_SET_LOADING),
      switchMap((objectData: ObjectActions.EditHomeMetricSet) => {
        this.store.dispatch(new AppActions.ShowLoader());

        return this.service.editHomeMetricSet(objectData.id, objectData.homeMetricSets).pipe(
          switchMap((response: IHomeMetricSetDataResponseInterface) => {
            return of(new ObjectActions.EditHomeMetricSetLoaded(response), new AppActions.HideLoader());
          }),
          catchError((error) => {
            return of(new ObjectActions.FetchError(error), new AppActions.HideLoader());
          }),
        );
      }),
      catchError((error) => {
        return of(new ObjectActions.FetchError(error), new AppActions.HideLoader());
      }),
    ),
  );

  getHomeMetricSetData = createEffect(() =>
    this.actions$.pipe(
      ofType(ObjectActions.GET_HOME_METRIC_SET),
      switchMap((objectData: ObjectActions.GetHomeMetricSet) => {
        this.store.dispatch(new AppActions.ShowLoader());

        const httpParams: HttpParams = new HttpParams()
          .append('join', 'homeMetricSetItems')
          .append('join', 'homeMetricSetItems.homeMetricSetProperties')
          .set('s', JSON.stringify({ layoutId: { $eq: objectData.layoutId } }))
          .set('sort', 'homeMetricSet.homeMetricSetItems.order,ASC');

        return this.service.getHomeMetricSet(httpParams).pipe(
          switchMap((response: IHomeMetricSetDataResponseInterface) => {
            return of(new ObjectActions.GetHomeMetricSetCompleted(response), new AppActions.HideLoader());
          }),
          catchError((error) => {
            return of(new ObjectActions.FetchError(error), new AppActions.HideLoader());
          }),
        );
      }),
      catchError((error) => {
        return of(new ObjectActions.FetchError(error), new AppActions.HideLoader());
      }),
    ),
  );

  getLayoutCardTemplateData = createEffect(() =>
    this.actions$.pipe(
      ofType(ObjectActions.GET_LAYOUT_CARD_TEMPLATE),
      switchMap((objectData: ObjectActions.GetLayoutCardTemplate) => {
        this.store.dispatch(new AppActions.ShowLoader());
        const httpParams: HttpParams = new HttpParams()
          .append('join', 'layout')
          .append('join', 'layout.layoutAssignment')
          .set('s', JSON.stringify({ 'layout.layoutAssignment.lineId': { $eq: objectData.lineId } }));

        return this.service.getLayoutCardTemplates(httpParams).pipe(
          switchMap((response: BaseOneResponseInterface<ILayoutCardTemplateResponse[]>) => {
            return of(new ObjectActions.GetLayoutCardTemplateCompleted(response), new AppActions.HideLoader());
          }),
          catchError((error) => {
            return of(new ObjectActions.FetchError(error), new AppActions.HideLoader());
          }),
        );
      }),
      catchError((error) => {
        return of(new ObjectActions.FetchError(error), new AppActions.HideLoader());
      }),
    ),
  );

  getProductUnits = createEffect(() =>
    this.actions$.pipe(
      ofType(ObjectActions.GET_PRODUCT_UNITS),
      switchMap((objectData: ObjectActions.GetProductUnits) => {
        this.store.dispatch(new AppActions.ShowLoader());
        let httpParams: HttpParams = new HttpParams();
        httpParams = httpParams.set('s', JSON.stringify({ type: 2 }));

        return this.lookupsService.getLookups(httpParams).pipe(
          switchMap((response: GetManyResponseInterface<ILookup>) => {
            return of(new ObjectActions.GetProductUnitsCompleted(response), new AppActions.HideLoader());
          }),
          catchError((error) => {
            return of(new ObjectActions.FetchError(error), new AppActions.HideLoader());
          }),
        );
      }),
      catchError((error) => {
        return of(new ObjectActions.FetchError(error), new AppActions.HideLoader());
      }),
    ),
  );

  getProductUnitConversions = createEffect(() =>
    this.actions$.pipe(
      ofType(ObjectActions.GET_PRODUCT_UNIT_CONVERSIONS),
      switchMap((objectData: ObjectActions.GetProductUnitConversions) => {
        this.store.dispatch(new AppActions.ShowLoader());

        return this.unitConversionService
          .getUnitConversion(objectData.productTableId, false, objectData.baseUnitId)
          .pipe(
            switchMap((response: GetManyResponseInterface<TUnitConversionResponse>) => {
              return of(new ObjectActions.GetProductUnitConversionsCompleted(response), new AppActions.HideLoader());
            }),
            catchError((error) => {
              return of(new ObjectActions.FetchError(error), new AppActions.HideLoader());
            }),
          );
      }),
      catchError((error) => {
        return of(new ObjectActions.FetchError(error), new AppActions.HideLoader());
      }),
    ),
  );
}
