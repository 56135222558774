import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Store } from '@ngrx/store';
import { ActivitiesService } from '../../shared/service/activities/activities.service';
import * as ObjectActions from './activity-button-multi-line.actions';
import { catchError, switchMap } from 'rxjs/operators';
import * as AppActions from '../../store/app/actions';
import * as oeeAppReducer from '../oee.reducer';
import { GetManyResponseInterface } from '../../shared/model/interface/crud-response-interface.model';
import { ActivitiesInterface } from '../../shared/model/interface/activities.model';
import { of } from 'rxjs';
import { EntityTranslatorService } from '../../shared/service/entity-translator/entity-translator.service';

@Injectable()
export class ActivityButtonMultiLineEffects {
  constructor(
    private actions$: Actions,
    private store: Store<oeeAppReducer.OeeAppState>,
    private readonly activitiesService: ActivitiesService,
    private readonly entityTranslatorService: EntityTranslatorService,
  ) {}

  $fetchMany = createEffect(() =>
    this.actions$.pipe(
      ofType(ObjectActions.ActionTypes.FetchActivitiesOfLine),
      switchMap(({ lineId }: ObjectActions.FetchActivitiesOfLine) => {
        this.store.dispatch(new AppActions.ShowTopLoader());

        const searchObject = {
          active: { $eq: true },
        };

        const httpParams: HttpParams = new HttpParams()
          .append('join', 'activityTypeRelation')
          .append('join', 'activitySubTypeRelation')
          .append('lineId', lineId.toString())
          .append('sort', 'activityTypeRelation.orders,ASC')
          .append('sort', 'id,ASC')
          .set('page', '1')
          .set('limit', '500')
          .set('s', JSON.stringify(searchObject));

        return this.activitiesService.getActivities(httpParams).pipe(
          switchMap((response: GetManyResponseInterface<ActivitiesInterface>) => {
            response.data.forEach((activities: ActivitiesInterface) => {
              this.entityTranslatorService.translate(activities);
            });

            return of(
              new ObjectActions.FetchActivitiesOfLineCompleted(lineId, response.data),
              new AppActions.HideTopLoader(),
            );
          }),
          catchError(() => {
            return of(new AppActions.EmptyAction(), new AppActions.HideTopLoader());
          }),
        );
      }),
    ),
  );
}
