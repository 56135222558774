import { Action } from '@ngrx/store';
import {
  BaseOneResponseInterface,
  GetManyResponseInterface,
} from '../../../shared/model/interface/crud-response-interface.model';
import { IWorkOrder, IWorkOrderLaborAssetInformation } from './job-performance.model';
import { ActivityLogsResponseInterface } from '../root-cause-analysis/root-cause-analysis.model';
import { DatatableInterface } from '../../../shared/service/datatable/datatable.model';
import { IOeeCalculationResultFormatted } from '../../../shared/model/interface/generic-api-response.model';
import { IFormattedOeeResult } from '../deep-dive-analysis/deep-dive-analysis.model';
import { IOeeFilter } from '../../../view/reports/job-performance/job-performance.model';
import { ITableHeader } from '../../../../constants.model';
import { IJobOeeResult } from '../../layout-plan-view/layout-plan-view.model';

export const GET_WORK_ORDERS_OF_JOB = '[JOB_PERFORMANCE] GET WORK ORDERS OF JOB';
export const GET_WORK_ORDERS_OF_JOB_COMPLETED = '[JOB_PERFORMANCE] GET WORK ORDERS OF JOB COMPLETED';
export const SET_SELECTED_WORK_ORDERS = '[JOB_PERFORMANCE] SET SELECTED WORK ORDERS';
export const GET_TABLE_DATA = '[JOB_PERFORMANCE] GET TABLE DATA';
export const GET_TABLE_DATA_COMPLETED = '[JOB_PERFORMANCE] GET TABLE DATA COMPLETED';
export const GET_OEE_SELECTED_WORK_ORDERS = '[JOB_PERFORMANCE] GET OEE SELECTED WORK ORDERS';
export const GET_OEE_SELECTED_WORK_ORDERS_COMPLETED = '[JOB_PERFORMANCE] GET OEE SELECTED WORK ORDERS COMPLETED';
export const GET_OEE_OF_JOB = '[JOB_PERFORMANCE] GET OEE OF JOB';
export const GET_OEE_OF_JOB_COMPLETED = '[JOB_PERFORMANCE] GET OEE OF JOB COMPLETED';
export const GET_LABOR_INFORMATION = '[JOB_PERFORMANCE] GET LABOR INFORMATION';
export const GET_LABOR_INFORMATION_COMPLETED = '[JOB_PERFORMANCE] GET LABOR INFORMATION COMPLETED';
export const GET_OEE_BY_SQL_MODE = '[JOB_PERFORMANCE] GET OEE BY SQL MODE';
export const GET_OEE_BY_SQL_MODE_COMPLETED = '[JOB_PERFORMANCE] GET OEE BY SQL MODE COMPLETED';
export const GET_ACTIVITY_LOG = '[JOB_PERFORMANCE] GET ACTIVITY LOG';
export const GET_ACTIVITY_LOG_COMPLETED = '[JOB_PERFORMANCE] GET ACTIVITY LOG COMPLETED';
export const DOWNLOAD_JOB_PERFORMANCE_TABLE_EXCEL = '[JOB_PERFORMANCE] JOB PERFORMANCE TABLE DOWNLOAD EXCEL';
export const DOWNLOAD_JOB_PERFORMANCE_TABLE_EXCEL_COMPLETED =
  '[JOB_PERFORMANCE] JOB PERFORMANCE TABLE DOWNLOAD EXCEL COMPLETED';
export const CLEAR_STATE = '[JOB_PERFORMANCE] CLEAR STATE';
export const FETCH_ERROR = '[JOB_PERFORMANCE] JOB PERFORMANCE Fetch error';

export class GetWorkOrdersOfJob implements Action {
  readonly type = GET_WORK_ORDERS_OF_JOB;

  constructor(public jobId: number) {}
}

export class GetWorkOrdersOfJobCompleted implements Action {
  readonly type = GET_WORK_ORDERS_OF_JOB_COMPLETED;

  constructor(public payload: GetManyResponseInterface<IWorkOrder>) {}
}

export class GetTableData implements Action {
  readonly type = GET_TABLE_DATA;

  constructor(public workOrderIds: number[], public tableQuery: DatatableInterface) {}
}

export class SetSelectedWorkOrder implements Action {
  readonly type = SET_SELECTED_WORK_ORDERS;

  constructor(public workOrderIds: number[] = [], public workOrders: IWorkOrder[] = []) {}
}

export class GetTableDataCompleted implements Action {
  readonly type = GET_TABLE_DATA_COMPLETED;

  constructor(public payload: GetManyResponseInterface<IWorkOrder>) {}
}

export class GetOeeSelectedWorkOrders implements Action {
  readonly type = GET_OEE_SELECTED_WORK_ORDERS;

  constructor(public workOrderIds: number[] = [], public siteId: number) {}
}

export class GetOeeSelectedWorkOrdersCompleted implements Action {
  readonly type = GET_OEE_SELECTED_WORK_ORDERS_COMPLETED;

  constructor(public payload: GetManyResponseInterface<IOeeCalculationResultFormatted>) {}
}

export class GetOeeOfJob implements Action {
  readonly type = GET_OEE_OF_JOB;

  constructor(public jobId: number) {}
}

export class GetOeeOfJobCompleted implements Action {
  readonly type = GET_OEE_OF_JOB_COMPLETED;

  constructor(public payload: IJobOeeResult) {}
}
export class GetLaborInformation implements Action {
  readonly type = GET_LABOR_INFORMATION;

  constructor(public workOrderIds: number[]) {}
}

export class GetLaborInformationCompleted implements Action {
  readonly type = GET_LABOR_INFORMATION_COMPLETED;

  constructor(public payload: IWorkOrderLaborAssetInformation[]) {}
}

export class GetOeeBySqlMode implements Action {
  readonly type = GET_OEE_BY_SQL_MODE;

  constructor(public params: IOeeFilter) {}
}

export class GetOeeBySqlModeCompleted implements Action {
  readonly type = GET_OEE_BY_SQL_MODE_COMPLETED;

  constructor(public payload: IFormattedOeeResult[]) {}
}

export class GetActivityLog implements Action {
  readonly type = GET_ACTIVITY_LOG;

  constructor(public workOrderIds: number[] = []) {}
}

export class GetActivityLogCompleted implements Action {
  readonly type = GET_ACTIVITY_LOG_COMPLETED;

  constructor(public payload: BaseOneResponseInterface<ActivityLogsResponseInterface>) {}
}

export class DownloadJobPerformanceTableExcel implements Action {
  readonly type = DOWNLOAD_JOB_PERFORMANCE_TABLE_EXCEL;

  constructor(public headers: ITableHeader[], public workOrdersIds: number[]) {}
}

export class DownloadJobPerformanceTableExcelCompleted implements Action {
  readonly type = DOWNLOAD_JOB_PERFORMANCE_TABLE_EXCEL_COMPLETED;
}

export class ClearState implements Action {
  readonly type = CLEAR_STATE;
}

export class FetchError implements Action {
  readonly type = FETCH_ERROR;

  constructor(public payload: object) {}
}

export type JobPerformanceActions =
  | GetWorkOrdersOfJob
  | GetWorkOrdersOfJobCompleted
  | SetSelectedWorkOrder
  | GetTableData
  | GetTableDataCompleted
  | GetOeeSelectedWorkOrders
  | GetOeeSelectedWorkOrdersCompleted
  | GetOeeOfJob
  | GetOeeOfJobCompleted
  | GetLaborInformation
  | GetLaborInformationCompleted
  | GetOeeBySqlMode
  | GetOeeBySqlModeCompleted
  | GetActivityLog
  | GetActivityLogCompleted
  | DownloadJobPerformanceTableExcel
  | DownloadJobPerformanceTableExcelCompleted
  | ClearState
  | FetchError;
