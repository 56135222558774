import { IDoNotDisturbScheduleState } from '../../standalone/do-not-disturb-schedule/do-not-disturb-schedule.model';
import { ELoadStatus } from '../../../constants.model';
import * as DoNotDisturbScheduleActions from './do-not-disturb-schedule.actions';

const doNotDisturbScheduleInitialState: IDoNotDisturbScheduleState = {
  cardsWithUnsavedActiveState: new Set(),
  dataLoadStatus: ELoadStatus.Initial,
  pageData: [],
};

export function doNotDisturbScheduleReducer(
  state: IDoNotDisturbScheduleState = doNotDisturbScheduleInitialState,
  action: DoNotDisturbScheduleActions.FetchDoNotDisturbScheduleActions,
): IDoNotDisturbScheduleState {
  switch (action.type) {
    case DoNotDisturbScheduleActions.ActionTypes.ResetActiveStateAction:
      state.cardsWithUnsavedActiveState.clear();
      return state;
    case DoNotDisturbScheduleActions.ActionTypes.SetActiveStateUnsavedAction:
      state.cardsWithUnsavedActiveState.add(action.id);
      return state;
    case DoNotDisturbScheduleActions.ActionTypes.UpdateDoNotDisturbSchedule:
    case DoNotDisturbScheduleActions.ActionTypes.FetchDoNotDisturbSchedules:
    case DoNotDisturbScheduleActions.ActionTypes.DeleteDoNotDisturbSchedule:
    case DoNotDisturbScheduleActions.ActionTypes.CreateDoNotDisturbSchedule:
      return {
        ...state,
        dataLoadStatus: ELoadStatus.Loading,
      };
    case DoNotDisturbScheduleActions.ActionTypes.UpdateDoNotDisturbScheduleLoaded:
    case DoNotDisturbScheduleActions.ActionTypes.DeleteDoNotDisturbScheduleLoaded:
    case DoNotDisturbScheduleActions.ActionTypes.CreateDoNotDisturbScheduleLoaded:
      return {
        ...state,
        dataLoadStatus: ELoadStatus.Success,
      };
    case DoNotDisturbScheduleActions.ActionTypes.FetchDoNotDisturbSchedulesLoaded:
      return {
        ...state,
        pageData: action.response.data,
        dataLoadStatus: ELoadStatus.Success,
      };
    case DoNotDisturbScheduleActions.ActionTypes.FetchError:
      return {
        ...state,
        dataLoadStatus: ELoadStatus.Failure,
      };
    default:
      return state;
  }
}
