import { Action } from '@ngrx/store';
import { TagInterface } from '../../settings/tags/tags.model';
import { GetManyResponseInterface } from '../../../shared/model/interface/crud-response-interface.model';
import { HttpOptionInterface } from '../filter.model';

export enum ActionTypes {
  GetTags = '[Tag Filter] Get Tags',
  GetTagsLoaded = '[Tag Filter] Get Tags Loaded',
  FetchError = '[Tag Filter] Fetch Error',
  ResetStore = '[Tag Filter] Reset Store',
}

export class GetTags implements Action {
  readonly type = ActionTypes.GetTags;

  constructor(public params: HttpOptionInterface) {}
}

export class GetTagsLoaded implements Action {
  readonly type = ActionTypes.GetTagsLoaded;

  constructor(public response: GetManyResponseInterface<TagInterface>) {}
}

export class FetchError implements Action {
  readonly type = ActionTypes.FetchError;

  constructor(public actionType: ActionTypes) {}
}

export class ResetStore implements Action {
  readonly type = ActionTypes.ResetStore;
}

export type TagActions = GetTags | GetTagsLoaded | FetchError | ResetStore;
