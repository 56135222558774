import { Action } from '@ngrx/store';
import { SiteFilterInterface, SiteViewDataInterface } from './site-view.model';
import { BaseOneResponseInterface } from '../../../shared/model/interface/crud-response-interface.model';

export const SITE_VIEW_FILTER_DATA_LOADING = '[SITE VIEW] FILTER DATA LOADING';
export const SITE_VIEW_FILTER_DATA_LOADED = '[SITE VIEW] FILTER DATA LOADED';
export const SITE_VIEW_FILTER_DATA_GENERATED = '[SITE VIEW] FILTER DATA GENERATED';
export const SITE_VIEW_SET_FILTER_SETTINGS = '[SITE VIEW] SET FILTER SETTINGS';
export const SITE_VIEW_LINE_DATA_LOADING = '[SITE VIEW] LINE DATA LOADING';
export const SITE_VIEW_LINE_DATA_LOADED = '[SITE VIEW] LINE DATA LOADED';
export const SITE_VIEW_SELECT_SITE_AND_LINE = '[SITE VIEW] SELECT SITE AND LINE';
export const SITE_AND_LINE_VIEW_GET_FLOOR_PLAN_IMAGE_LOADING = '[SITE VIEW AND LINE VIEW] GET FLOOR PLAN IMAGE LOADING';
export const SITE_AND_LINE_VIEW_GET_FLOOR_PLAN_IMAGE_LOADED = '[SITE VIEW AND LINE VIEW] GET FLOOR PLAN IMAGE LOADED';
export const SITE_VIEW_INITIAL_FILTER_DATA_LOADED = '[SITE VIEW] INITIAL FILTER DATA LOADED';
export const SITE_VIEW_RESET_STORE_TO_DEFAULT = '[SITE VIEW] RESET STORE TO DEFAULT';
export const SITE_VIEW_FETCH_DATA_ERROR = '[SITE VIEW] FETCH DATA ERROR';

export class FilterDataLoading implements Action {
  readonly type = SITE_VIEW_FILTER_DATA_LOADING;

  constructor(public userId: number) {}
}

export class FilterDataLoaded implements Action {
  readonly type = SITE_VIEW_FILTER_DATA_LOADED;

  constructor(public payload: SiteFilterInterface) {}
}

export class FilterDataGenerated implements Action {
  readonly type = SITE_VIEW_FILTER_DATA_GENERATED;

  constructor(public payload: SiteFilterInterface) {}
}

export class SetFilterSettings implements Action {
  readonly type = SITE_VIEW_SET_FILTER_SETTINGS;

  constructor(public siteIds: number[], public setAsDefault: boolean) {}
}

export class LineDataLoading implements Action {
  readonly type = SITE_VIEW_LINE_DATA_LOADING;

  constructor(public siteId: number, public lineIds: number[]) {}
}

export class LineDataLoaded implements Action {
  readonly type = SITE_VIEW_LINE_DATA_LOADED;

  constructor(public payload: SiteViewDataInterface) {}
}

export class SelectSiteAndLine implements Action {
  readonly type = SITE_VIEW_SELECT_SITE_AND_LINE;

  constructor(public siteId: number, public lineIds: number[]) {}
}

export class GetFloorPlanImageLoading implements Action {
  readonly type = SITE_AND_LINE_VIEW_GET_FLOOR_PLAN_IMAGE_LOADING;

  constructor(public imagePath: string) {}
}

export class GetFloorPlanImageLoaded implements Action {
  readonly type = SITE_AND_LINE_VIEW_GET_FLOOR_PLAN_IMAGE_LOADED;

  constructor(public response: BaseOneResponseInterface<string>, public path: string) {}
}

export class InitialFilterDataLoaded implements Action {
  readonly type = SITE_VIEW_INITIAL_FILTER_DATA_LOADED;
}

export class ResetStoreToDefault implements Action {
  readonly type = SITE_VIEW_RESET_STORE_TO_DEFAULT;
}

export class FetchDataError implements Action {
  readonly type = SITE_VIEW_FETCH_DATA_ERROR;

  constructor(public payload: object) {}
}

export type SiteViewActions =
  | FilterDataLoading
  | FilterDataLoaded
  | FilterDataGenerated
  | SetFilterSettings
  | LineDataLoading
  | LineDataLoaded
  | SelectSiteAndLine
  | GetFloorPlanImageLoading
  | GetFloorPlanImageLoaded
  | ResetStoreToDefault
  | InitialFilterDataLoaded
  | FetchDataError;
