import { BehaviorSubject, Observable, Subject, Subscription } from 'rxjs';
import {
  ApiServer,
  CommonSettingsInterface,
  DefaultSelectionInterface,
  DependencyType,
  DropdownFilterConfiguration,
  DropdownSettingsInterface,
  IDropdownDepend,
  IDropdownStaticCondition,
} from './dropdown/dropdown.model';
import { TypeFilterableObjects, FilterableObjectTypes } from './filterable-objects.class';
import { FilterDateRangePickerInterface } from './filter-date-range-picker/filter-date-range-picker.model';
import { Type } from '@angular/core';
import { DropdownComponent } from './dropdown/dropdown.component';
import { FilterDateRangePickerComponent } from './filter-date-range-picker/filter-date-range-picker.component';
import { SearchBoxComponent } from './search-box/search-box.component';
import { HttpOptionInterface } from '../../../store/filter/filter.model';
import { UserGetOneCRUDDataInterface } from '../../service/user/user.model';
import { GetDistinctCheckInObjectsInterfaceData } from '../../../store/check-in/check-in.model';
import { AdvancedFilterComponent } from './advanced-filter/advanced-filter.component';
import { AdvancedFilterObjects } from './advanced-filter/advanced-filter.model';
import { IConstantLookUp } from '../../../view/settings/users/users.model';
import { ButtonGroupComponent } from './button-group/button-group.component';
import { ScwMatButtonGroupButtons } from '../scw-mat-ui/scw-mat-button-group/scw-mat-button-group.model';
import { IEquipmentAssignment } from '../../../store/settings/equipment-assignment/equipment-assignment.model';
import { LineStationGetOneCRUDDataInterface } from '../../../store/line-station/line-station.model';
import { ActivityTypeInterface } from '../../model/interface/activities.model';
import { DropdownOptionInterface } from '../scw-mat-ui/scw-mat-select/scw-mat-select.model';
import { SensorCRUDRequestInterface } from '../../../store/filter/sensor/sensor.model';
import { IShift } from '../../../store/production-review/production-review.model';
import { EquipmentListInterface } from '../../../store/settings/equipment-lists/equipment-lists.model';
import { IMenuPermissions } from '../../../../constants';
import { TDecimalSeparator, TThousandSeparator } from '../../../../constants.model';
import { GetCurrentUserClientConfigurationDto } from 'src/app/store/app/model';
import { IProductVersion } from 'src/app/store/settings/products/products.model';
import { IObjectWithEntityTranslations } from '../../service/entity-translator/entity-translator.model';
import { ActivityInterface } from '../../../store/settings/activities/activities.model';
import { UserInterface } from '../../../store/settings/users/users.model';
import { ILinePath } from '../../../view/settings/product-configurations/line-path-product-configurations/line-path-product-configurations.model';
import { CurrentWorkOrderModel } from '../../../store/home/home.model';

export type FilterDataObjectTypes =
  | SiteCRUDInterface
  | LineCRUDInterface
  | ProductDropdownItemInterface
  | ShiftCRUDInterface
  | KeyValueDropdownInterface
  | SensorTypeDataInterface
  | DepartmentCRUDInterface
  | ObservationCRUDInterface
  | LineTypeNameInterface
  | RootCauseTypeCRUDInterface
  | UserGetOneCRUDDataInterface
  | IUserObject
  | IEquipmentObject
  | GetDistinctCheckInObjectsInterfaceData
  | AlteredActivityCRUDInterface
  | IConstantLookUp
  | ActivityTypeInterface
  | DropdownOptionInterface
  | SensorCRUDRequestInterface
  | UsersCRUDInterface
  | ProductCRUDInterface
  | IShift
  | WorkOrderCRUDInterface
  | EquipmentListInterface
  | TaskFilterCRUDInterface
  | ProductFamilyCRUDInterface
  | IOpcTagList
  | UserInterface
  | NotificationRecipientUserCRUDInterface;

export type FilterDataServiceCRUDTypes =
  | SiteCRUDInterface
  | LineCRUDInterface
  | ProductCRUDInterface
  | ShiftCRUDInterface
  | SensorTypeCRUDInterface
  | DepartmentCRUDInterface
  | ObservationCRUDInterface
  | LineTypeNameInterface
  | RootCauseTypeCRUDInterface
  | ActivityTypeInterface
  | SensorCRUDRequestInterface
  | UsersCRUDInterface
  | WorkOrderCRUDInterface
  | TaskFilterCRUDInterface
  | ProductFamilyCRUDInterface
  | NotificationRecipientUserCRUDInterface;

export interface DefaultSelectionValuesInterface {
  [key: string]: DefaultSelectionInterface;
}

export interface FilterClassInterface {
  objectNameProp: string;
  data: FilterDataObjectTypes[];
  dataSubject: Subject<FilterDataObjectTypes[]> | BehaviorSubject<FilterDataObjectTypes[]>;
  apiServer: ApiServer;

  setApiServer(server: ApiServer);

  getDataObservable(): Observable<FilterDataObjectTypes[]>;

  getObjectNameProp(): string;

  getInitialData(): FilterDataObjectTypes[];

  getFilterData(options: HttpOptionInterface): void;

  getAllData(options?: HttpOptionInterface): void;

  getDataWithInitialData(initialData: FilterDataObjectTypes[]): FilterDataObjectTypes[];

  init(): void;
}

export interface FilterComponentInterface {
  elementID: string;
  outputOptions: OutputOptionsInterface | SimpleOutputOptionsInterface;
  filterListenerConfiguration?: DropdownFilterConfiguration[];
  disabledSubject: BehaviorSubject<boolean>;
  submit?: boolean;
  defaultSettings?: DropdownSettingsInterface | FilterDateRangePickerInterface | any;
  dropdownObjectClass?: TypeFilterableObjects;
  depends?: DependencyType;
  dropdownSettings?: DropdownSettingsInterface;
  dependedOptionListenerConfiguration?: any[];

  subscribeFilterListener?(config: DropdownFilterConfiguration): void;

  filterData?(data: any): void;

  publishSelectedItems?(): void;

  getFiltersOutputs(): any;

  subscribeDependedOptionListener?(config: any): void;
}

export interface KeyValueDropdownInterface {
  key: string;
  name?: string;
}

export interface IdValueDropdownInterface {
  id: string;
  name?: string;
}

export interface FilterCardOptionInterface {
  rows: FilterCardOptionRowInterface;
}

export interface IStaticDropdownParameters {
  depends: IDropdownDepend[];
  options: Partial<DropdownSettingsInterface>;
  dependProperties: string[];
}

export interface FilterCardOptionRowInterface {
  [index: number]: RowConfigurationInterface[];
}

export interface OutputOptionsInterface {
  filterObjectId: string;
  filterObjectProp: string;
  returnFilterObjectAllProp?: boolean;
}

export interface SimpleOutputOptionsInterface {
  filterObjectId: string;
}

export interface DisableComponentInterface {
  elementId: string;
}

export interface RowConfigurationInterface {
  type: Type<
    | DropdownComponent
    | FilterDateRangePickerComponent
    | SearchBoxComponent
    | AdvancedFilterComponent
    | ButtonGroupComponent
  >;
  object?: Type<FilterableObjectTypes>;
  advancedFilterObject?: Type<AdvancedFilterObjects>;
  cls?: string;
  outputOptions: OutputOptionsInterface | SimpleOutputOptionsInterface;
  options?:
    | DropdownSettingsInterface
    | SearchBoxSettingsInterface
    | FilterDateRangePickerInterface
    | ButtonGroupSettingsInterface;
  elementId?: string;
  depends?: DependencyType;
  disableComponent?: DisableComponentInterface;
  filter?: DropdownItemFilterInterface;
  dependedOptions?: any[];
  dependProperties?: string[];
  dropdownDepends?: IDropdownDepend[];
  staticConditions?: IDropdownStaticCondition[];
}

export interface DropdownItemFilterInterface {
  /**
   * preCondition output determinates to execute condition or not.
   *
   * For example: You don't want to filter items if all items has the date field null.
   * You can check this on filter condition, but it will be expensive and unnecessary.
   * This preCondition function solves that.
   *
   * @param subject Usually the main array or main object being processed in the component.
   *
   */
  preCondition?: (subject: any) => boolean;

  /**
   * Condition to be used in Array.prototype.filter()
   *
   * @param element The current element being processed in the array.
   *
   * @param index The index of the current element being processed in the array.
   *
   * @param array The array filter was called upon.
   *
   * */
  condition: (element: unknown, index?: number, array?: unknown[]) => boolean;
}

export interface SearchBoxSettingsInterface extends CommonSettingsInterface {
  maxLength?: number;
  placeHolder?: string;
  cls?: string;
}

export interface ButtonGroupSettingsInterface extends CommonSettingsInterface {
  buttons: ScwMatButtonGroupButtons[];
  value?: any;
}

export interface SiteCRUDInterface {
  id: number;
  client: string;
  name: string;
  timezone: string;
  contactPerson: string;
  email: string;
  address: string;
  location: string;
  coordinates: SiteCoordinatesInterface;
  web: string;
  tel: string;
  statusId: number;
  description: string;
  menuPermissions: IMenuPermissions;
  multipleCheckIns: boolean;
  preRunPhaseName: string;
  runPhaseName: string;
  postRunPhaseName: string;
  line?: LineCRUDInterface[];
  linePaths?: ILinePath[];
  createdBy?: number;
  createdAt?: string;
  changedBy?: number;
  changedAt?: string;
  weeklyWorkingHours: number;
  decimalScaleLimit: number;
  decimalSeparator: TDecimalSeparator;
  thousandSeparator: TThousandSeparator;
  gmpDisclaimer?: boolean;
  numberFormat?: string;
  configuration?: GetCurrentUserClientConfigurationDto;
  isIpRestrictionEnabled: boolean;
  allowedIpBlocks: string;
  isExcelDateTimeFormat: boolean;
  woDueDateExcelFormat: boolean;
  weekStartDay: number;
  isNewShiftMechanismActive?: boolean;
  usedTotalSiteFileSize: number;
  siteSizeLimit: number | null;
  formattedFileSizeLimit?: string;
  uploadSizeLimit?: number;
  lineLimit: number;
}

export interface ISiteCrudJoins {
  linePaths: ILinePath[];
}

export interface LineCRUDInterface extends IObjectWithEntityTranslations {
  distributeOrderedQuantity: boolean;
  microstopSensorType: string;
  id: number;
  client: string;
  createdBy: number;
  createdAt: Date;
  changedBy: number;
  changedAt: Date;
  title: string;
  siteId: number;
  activityIds: string;
  statusId: number;
  description: string;
  lineType: number;
  unit: number;
  unitName?: string;
  standardSpeed: string;
  timer: Date;
  selectedActivity: number;
  selectedTaskId: number;
  selectedDescription: string;
  activeUser: number;
  selectedCrewSize: string;
  scheduledHourMonday: string;
  scheduledHourTuesday: string;
  scheduledHourWednesday: string;
  scheduledHourThursday: string;
  scheduledHourFriday: string;
  scheduledHourSaturday: string;
  scheduledHourSunday: string;
  snoozeUntil: string;
  stationLimit: number;
  shiftPlanId?: number;
  lineTypeName?: LineTypeNameInterface;
  station?: LineStationGetOneCRUDDataInterface[];
  equipmentAssignment?: IEquipmentAssignment[];
  isWorkOrderSplitActive: boolean;
  isWorkOrderTakeoverBySplitActive: boolean;
  typeName?: LineTypeNameInterface;
  laborCapacity?: number;
  cameraList?: any[];
  isAdvancedLine?: boolean;
  isOpcIntegrationInterrupted: boolean;
  distributionMethodOfOrderedQuantity?: number | null;
  isAutoCheckOutActiveOnLine?: number;
  bufferTimeForAutoCheckOut?: number | null;
  excludeTimeForAutoCheckOut?: number | null;
  activityIdAfterWoFinalize?: number | null;
  taskIdAfterWoFinalize?: number | null;
  currentWorkOrder?: CurrentWorkOrderModel;
}

export interface SensorTypeCRUDInterface {
  id: number;
  client: number;
  type: string;
  title: string;
}

export interface SensorTypeDataInterface extends SensorTypeCRUDInterface {
  translatedTitle: string;
}

export interface CreatedChangedCRUDInterface {
  createdBy: number;
  createdAt: Date;
  changedBy: number;
  changedAt: Date;
}

export interface LineTypeNameInterface extends CreatedChangedCRUDInterface {
  siteId: number;
  id: number;
  order: number;
  lineType: string;
  productionUnit: number;
  description: string;
  status: number;
}

export interface ShiftCRUDInterface {
  id: number;
  client: string;
  name: string;
  costFactorId: number;
}

export interface ProductCRUDInterface extends IObjectWithEntityTranslations {
  id: number;
  createdBy: number;
  createdAt: string;
  changedBy: number;
  changedAt: string;
  productId: string;
  siteId: number;
  description: string;
  packageSize?: number | null;
  productSpeed: string;
  customerId: number;
  productFamily: ObservationCRUDInterface;
  colorCode: string;
  materialType: number;
  unit: number;
  planningType: number;
  planningGroup: string;
  site: SiteCRUDInterface;
  productVersion: IProductVersion[];
  minimumWaitingDuration: string;
  maximumWaitingDuration: string;
  productFamilyName: string;
  productFamilyId: number;
}

export interface ProductDropdownItemInterface extends ProductCRUDInterface {
  productIdDescriptionInfo: string;
}

export interface ObservationCRUDInterface {
  name: string;
  id: number;
}

export interface ServiceResponseInterface<T> {
  data: T[];
  success: boolean;
}

export interface DepartmentCRUDInterface extends ObservationCRUDInterface {
  siteId: number;
}

export interface ProductResourceCRUDInterface extends IObjectWithEntityTranslations {
  id: number;
  name: string;
  productId: number;
  product?: ProductCRUDInterface;
  quantity: number;
  createdBy: number;
  createdAt: string;
  changedBy: number;
  changedAt: string;
}

export interface ActivityCRUDInterface extends IObjectWithEntityTranslations {
  id: number;
  name: string;
  activityType: string;
  activitySubtype: number;
  activityTypeRelation: ActivityTypeRelationInterface;
}

export interface ActivityTypeRelationInterface {
  order: number;
  text: string;
  type: string;
}

export interface AlteredActivityCRUDInterface extends ActivityCRUDInterface {
  activityTypeText: string;
}

export interface BomCRUDInterface extends ProductResourceCRUDInterface {}

export interface SchedulerPlanCRUDInterface extends CreatedChangedCRUDInterface {
  id: number;
  name: string;
  isOfficial: 1 | 0;
}

export interface RootCauseTypeCRUDInterface extends CreatedChangedCRUDInterface {
  id: number;
  name: string;
}

export interface RootCauseGroupCRUDInterface extends CreatedChangedCRUDInterface {
  id: number;
  name: string;
  siteId: number;
  isChangeover: number;
  virtualIsChangeoverType?: string;
}

export interface WorkOrderCRUDInterface {
  id: number;
  woNumber: string;
  site?: {
    id: number;
    decimalScaleLimit: number;
  };
}

export interface IEquipmentAssignmentFilter {
  id: number;
  lineId: number;
}

export interface IEquipmentList extends EquipmentListInterface {
  equipmentAssignment?: IEquipmentAssignmentFilter[];
  lineId?: string;
}

export interface TaskFilterCRUDInterface extends IObjectWithEntityTranslations {
  id: number;
  title: string;
  originalTitle?: string;
  activityId?: number;
  activity?: ActivityInterface;
  activityName?: string;
  equipment?: IEquipmentList;
}

export interface LineStationCRUDInterface extends LineStationGetOneCRUDDataInterface {
  line?: LineCRUDInterface;
  lineTitle?: string;
  siteId?: number;
};

export enum SourceObjectTypes {
  user = 1,
  equipment = 2,
}

export class FilterClass implements Partial<FilterClassInterface>, Partial<FilterClassInterface> {
  public objectNameProp: string = 'name';
  public subscribe: Subscription = null;
  public data: FilterDataObjectTypes[];
  public dataSubject: Subject<any> = new Subject<object>();
  public apiServer = ApiServer.NestJS;

  public setApiServer(server: ApiServer) {
    this.apiServer = server;
  }

  public getDataObservable(): Observable<FilterDataObjectTypes[]> {
    return this.dataSubject.asObservable();
  }

  public getObjectNameProp(): string {
    return this.objectNameProp;
  }

  public getDataWithInitialData(initialData: FilterDataObjectTypes[]): FilterDataObjectTypes[] {
    return this.data;
  }

  getFilterData(options: HttpOptionInterface, isFetchMoreRequest?: boolean, searchInput?: string): void {
    console.warn('Your getFilterData method not found in your filter class.');
  }

  getAllData(options?: HttpOptionInterface): void {
    console.warn('Your getAllData method not found in your filter class.');
  }

  init(): void {}

  destroy(): void {}
}

export type FilterConditionTypes =
  | '$eq'
  | '$ne'
  | '$gt'
  | '$lt'
  | '$gte'
  | '$lte'
  | '$starts'
  | '$ends'
  | '$cont'
  | '$excl'
  | '$in'
  | '$notin'
  | '$isnull'
  | '$notnull'
  | '$between'
  | '$eqL'
  | '$neL'
  | '$startsL'
  | '$endsL'
  | '$contL'
  | '$exclL'
  | '$inL'
  | '$notinL';

export interface ISourceObject {
  sourceObjectId: number;
  destinationTypeId: number;
  destinationObjectId: number;
}

export interface IUserObject extends ISourceObject {
  username: string;
}

export interface IEquipmentObject extends ISourceObject {
  equipment: string;
}

export interface UsersCRUDInterface {
  id: number;
  userName: string;
  fullName: string;
  email: string;
  password: string;
  currentLine: string;
  currentSite: string;
  checkInCardId: string;
  checkInsPin: string;
  details: string;
  multipleCheckIns: number;
  phone: string;
  defaultSite: number;
  levelId: number;
  isActive: number;
  isAutoCheckOutActive: number;
  userLanguage: string;
  timeZone: string;
  locale: string;
  dateFormat: string;
  dateTimeFormat: string;
  contactIt: boolean;
  contactSupervisor: boolean;
  contactMaintenance: boolean;
  contactQuality: boolean;
  contactScw: boolean;
  contactWarehouse: boolean;
  contactType: string;
  site?: SiteCRUDInterface;
  hasBroaderSiteLineAuth?: boolean;
}

export interface SiteCoordinatesInterface {
  latitude: number;
  longitude: number;
}

export interface FloorPlanCRUDInterface {
  id: number;
  siteId: number;
  lineId: number;
  name: string;
}

export interface ProductFamilyCRUDInterface {
  id: number;
  siteId: number;
  name: string;
}

export interface IOpcTagList {
  client: string;
  id: number;
  siteId: number;
  lineId: number;
  siteCode: string;
  lineCode: string;
  tagName: string;
  colorCode: string | null;
  isActive: boolean | null;
  validFrom: string;
  validUntil: string;
}

export interface NotificationRecipientUserCRUDInterface {
  id: number;
  fullName: string;
  username: string;
  email: string;
}

export interface IJobCRUD {
  id: number;
  name: string;
  jobName: string;
  siteId?: number;
}
