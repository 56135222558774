<div
  [class.page-configuration-mode]="kpiCard?.displayRenderProperties?.pageConfigurationMode"
  class="card scw-mat-kpi-card"
>
  <ng-container *ngIf="kpiCard !== null && !loading; else loadingTemplate">
    <div class="card-header" *ngIf="kpiCard.header !== undefined && kpiCard.header.length">
      <ng-container *ngFor="let data of kpiCard?.header; trackBy: trackByItem">
        <ng-container *ngIf="data.type; else dynamicHeader">
          <ng-container
            *ngTemplateOutlet="templates[data.type]; context: { $implicit: data, kpiCard: kpiCard }"
          ></ng-container>
        </ng-container>
        <ng-template #dynamicHeader>
          <ng-content select="[header]"></ng-content>
        </ng-template>
      </ng-container>
    </div>
    <div class="card-body" *ngIf="kpiCard.body !== undefined && kpiCard.body.length">
      <div class="row align-items-end m-0 h-100">
        <ng-container *ngFor="let data of kpiCard?.body; trackBy: trackByItem">
          <div [ngClass]="data.class ? data.class + ' p-0' : 'col-12 p-0'">
            <ng-container *ngIf="data; else dynamicBody">
              <ng-container
                *ngTemplateOutlet="templates[data.type]; context: { $implicit: data, kpiCard: kpiCard }"
              ></ng-container>
            </ng-container>
            <ng-template #dynamicBody>
              <ng-content select="[body]"></ng-content>
            </ng-template>
          </div>
        </ng-container>
      </div>
    </div>
    <div class="card-footer" *ngIf="kpiCard.footer !== undefined && kpiCard.footer.length">
      <div class="row align-items-center m-0">
        <ng-container *ngFor="let data of kpiCard?.footer; trackBy: trackByItem">
          <div [ngClass]="data.class ? data.class + ' p-0' : 'col-12 p-0'">
            <ng-container *ngIf="data.type; else dynamicFooter">
              <ng-container
                *ngTemplateOutlet="templates[data.type]; context: { $implicit: data, kpiCard: kpiCard }"
              ></ng-container>
            </ng-container>
            <ng-template #dynamicFooter>
              <ng-content select="[footer]"></ng-content>
            </ng-template>
          </div>
        </ng-container>
      </div>
    </div>
  </ng-container>
</div>

<ng-template #loadingTemplate>
  <div class="card-body">
    <div class="card-block d-flex align-items-center justify-content-center h-100 m-0 p-0">
      <div class="loader-block">
        <svg id="loader2" viewBox="0 0 100 100">
          <circle id="circle-loader2" cx="50" cy="50" r="45"></circle>
        </svg>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #progressBar let-data let-kpiCard="kpiCard">
  <div [ngClass]="data.contentClass" class="progress-box d-flex flex-row justify-content-center align-items-center">
    <div class="progress d-inline-block flex-grow-1 ml-0 d-flex align-items-center">
      <div class="progress-bar-text-block position-absolute f-w-500 d-flex justify-content-end w-100">
        <p class="progress-bar-text nowrap">&nbsp;{{ data.value }}<span *ngIf="data.value !== 'N/A'"> %</span></p>
      </div>
      <div
        class="progress-bar"
        [style.background-color]="kpiCard.disabled ? '#AEB0B2' : data?.style?.color || '#aeaeaf'"
        [style.width.%]="data?.value || 0 | sanitizeString : true"
      ></div>
    </div>
  </div>
</ng-template>

<ng-template #selectBox let-data let-kpiCard="kpiCard">
  <scw-mat-select
    [ngClass]="data.contentClass"
    [clearAll]="false"
    [data]="data.selectBoxData"
    [(inputModel)]="data.selectBoxSelection"
    (inputModelChange)="selectBoxInputModelChange($event)"
    [autoPosition]="false"
    [size]="'sm'"
  ></scw-mat-select>
</ng-template>

<ng-template #text let-data let-kpiCard="kpiCard">
  <div class="text-container">
    <p
      [ngClass]="[data?.contentClass || '']"
      [style.color]="kpiCard.disabled ? '#AEB0B2' : data?.style?.color || ''"
      [style.float]="data?.style?.float || ''"
      [style.font-weight]="data?.style?.fontWeight || ''"
      [style.font-size]="data?.style?.fontSize || ''"
      [style.margin]="data?.style?.margin || ''"
      [style.padding]="data?.style?.padding || ''"
    >
      {{ data?.value }}
      <span
        [style.color]="kpiCard.disabled ? '#AEB0B2' : data?.unit?.style?.color || ''"
        [style.float]="data?.unit?.style?.float || ''"
        [style.font-weight]="data?.unit?.style?.fontWeight || ''"
        [style.font-size]="data?.unit?.style?.fontSize || ''"
        [style.margin]="data?.unit?.style?.margin || ''"
        [style.padding]="data?.unit?.style?.padding || ''"
        *ngIf="data?.unit"
        >&nbsp;{{ data?.unit?.value }}</span
      >
      <span
        [style.color]="kpiCard.disabled ? '#AEB0B2' : data?.style?.color || ''"
        [style.font-weight]="data?.style?.fontWeight || ''"
        [style.font-size]="data?.style?.fontSize || ''"
        *ngIf="data?.displayPercentage"
        >%</span
      >
    </p>
  </div>
</ng-template>

<ng-template #icon let-data let-kpiCard="kpiCard">
  <i
    [autoClose]="data?.iconAutoClose"
    [ngbTooltip]="data?.iconTooltipText"
    [tooltipClass]="data?.tooltipClass || 'custom-tooltip'"
    [ngClass]="[data.value, data.contentClass || '']"
    [style.color]="kpiCard.disabled ? '#AEB0B2' : data?.style?.color || ''"
    [style.float]="data?.style?.float || ''"
    [style.font-weight]="data?.style?.fontWeight || ''"
    [style.font-size]="data?.style?.fontSize || ''"
    [style.margin]="data?.style?.margin || ''"
    [style.padding]="data?.style?.padding || ''"
    (click)="onClickItem($event)"
  ></i>
</ng-template>

<ng-template #warningMessage let-data let-kpiCard="kpiCard">
  <div class="warning-message">
    <p>{{ data?.value }}</p>
  </div>
</ng-template>
