import { FilterLineStationActions, FilterLineStationActionTypes } from './line-station.actions';
import { LineStationCRUDInterface } from '../../../shared/component/filter/filter.class';

export interface FilterLineStationState {
  isLoading: boolean;
  isLoaded: boolean;
  data: LineStationCRUDInterface[];
}

export const initialFilterLineStationState: FilterLineStationState = {
  isLoaded: false,
  isLoading: false,
  data: [],
};

export function filterLineStationReducer(
  state: FilterLineStationState = initialFilterLineStationState,
  action: FilterLineStationActions,
): FilterLineStationState {
  switch (action.type) {
    case FilterLineStationActionTypes.START_LOAD_FILTER_LINE_STATIONS:
      return { ...state, isLoading: true };
    case FilterLineStationActionTypes.FILTER_LINE_STATIONS_LOADED:
      return { ...state, data: action.data, isLoaded: true, isLoading: false };
    case FilterLineStationActionTypes.RESET_LINE_STATION_STORE:
      return { ...state, data: [], isLoaded: false, isLoading: false };
    default:
      return state;
  }
}
