import { Injectable } from '@angular/core';
import { TranslateLoader } from '@ngx-translate/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, of, forkJoin } from 'rxjs';
import { catchError, switchMap } from 'rxjs/operators';
import { getApiUrl } from './utilities';
import * as _ from 'lodash';
import { ELanguages } from '../constants';

interface TranslationResponseInterface {
  data: {
    [key: string]: string;
  };
  success: boolean;
  date: string;
}

@Injectable()
export class CustomTranslateLoader implements TranslateLoader {
  private readonly baseURL: string = getApiUrl();
  private readonly solution: string = 'df-an';

  constructor(private http: HttpClient) {}

  getTranslation(language: string): Observable<any> {
    const APIAddress = `${this.baseURL}/translations`;
    const params = {
      language,
      solution: this.solution,
    };
    const pageTitleParams = {
      language: ELanguages.EN,
      solution: this.solution,
      getPageTitles: true,
    };
    const headers = new HttpHeaders().set('scw-skip-version-check', 'true');

    return forkJoin({
      translations: this.http.get<TranslationResponseInterface>(APIAddress, { params, headers }),
      pageTitleTranslationsEn: this.http.get<TranslationResponseInterface>(APIAddress, {
        params: pageTitleParams,
        headers,
      }),
    }).pipe(
      switchMap((response) => {
        const { data, success } = response.translations;
        const { data: pageTitleData, success: pageTitleSuccess } = response.pageTitleTranslationsEn;

        const translations = {};

        if (pageTitleSuccess) {
          for (const key in pageTitleData) {
            _.set(translations, `pageTitleTranslateEN.${key}`, _.get(pageTitleData, key, null));
          }
        }

        if (success) {
          for (const key in data) {
            _.set(translations, key, _.get(data, key, null));
          }

          return of(translations);
        } else {
          return of({});
        }
      }),
      catchError(() => {
        return of({});
      }),
    );
  }
}
