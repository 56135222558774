import { Action } from '@ngrx/store';
import { ChangeActivityRequestInterface } from '../home/home.model';

export enum WorkOrderFinalizeActionTypes {
  SetWorkOrderFinalizeInProgress = '[Work Order Finalize] Set Work Order Finalize in Progress',
  SetSelectedActivityAndTask = '[Work Order Finalize] Set Set Selected Activity and Task',
  SimpleWOFinalizeIsObsolete = '[Work Order Finalize] Simple WO Finalize Is Obsolete',
}

export class SetWorkOrderFinalizeInProgress implements Action {
  readonly type = WorkOrderFinalizeActionTypes.SetWorkOrderFinalizeInProgress;

  constructor(public inProgress: boolean) {}
}

export class SetSelectedActivityAndTask implements Action {
  readonly type = WorkOrderFinalizeActionTypes.SetSelectedActivityAndTask;

  constructor(public newActivityAndTask: ChangeActivityRequestInterface) {}
}

export class SimpleWOFinalizeIsObsolete implements Action {
  readonly type = WorkOrderFinalizeActionTypes.SimpleWOFinalizeIsObsolete;
}

export type WorkOrderFinalizeActions =
  | SetWorkOrderFinalizeInProgress
  | SetSelectedActivityAndTask
  | SimpleWOFinalizeIsObsolete;
