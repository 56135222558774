import { Injectable } from '@angular/core';
import { GenericHelperService } from '../generic.helper.service';
import { cloneDeep, uniq } from 'lodash';
import { ILinePath } from '../../../view/settings/product-configurations/line-path-product-configurations/line-path-product-configurations.model';

@Injectable({
  providedIn: 'root',
})
export class LinePathService {
  public getFormattedAndSortedLinePathOptions<
    LinePath extends
      | Pick<ILinePath, 'id' | 'name' | 'floorPlan'>
      | (Pick<ILinePath, 'id' | 'name'> & { floorPlanName: string }),
  >(linePaths: LinePath[]): LinePath[] {
    let clonedLinePaths: LinePath[] = cloneDeep(linePaths);
    const isThereMultipleFloorPlans: boolean = Boolean(
      uniq(
        clonedLinePaths.map((linePath) =>
          'floorPlanName' in linePath ? linePath.floorPlanName : linePath.floorPlan.name,
        ),
      )?.length > 1,
    );

    if (isThereMultipleFloorPlans) {
      clonedLinePaths = clonedLinePaths.map(
        (linePath: LinePath): LinePath => ({
          ...linePath,
          name: `${'floorPlanName' in linePath ? linePath.floorPlanName : linePath.floorPlan.name} - ${linePath.name}`,
        }),
      );
    }

    clonedLinePaths.sort(GenericHelperService.dropdownOptionCompareFunction);
    return clonedLinePaths;
  }
}
