import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, TemplateRef } from '@angular/core';
import { ITableHeader } from '../../../../../constants.model';
import { ComponentUtilities } from '../../../helper/component-utilities';
import * as MultiLineChangeActivityActions from '../../../../store/home/multi-line-change-activity/multi-line-change-activity.actions';
import {
  EMultiLineChangeActivityAction,
  LoadedOngoingActivitiesOfLinePath,
} from '../../../../store/home/multi-line-change-activity/multi-line-change-activity.actions';
import { ActionsSubject, Store } from '@ngrx/store';
import { OeeAppState } from '../../../../store/oee.reducer';
import { combineLatest, Observable, Subscription } from 'rxjs';
import { ofType } from '@ngrx/effects';
import { IChangeActivityTable } from './multi-line-change-activity.model';
import { ComponentColors } from '../../../service/color/color.model';
import { filter, map } from 'rxjs/operators';
import { ColorService } from '../../../service/color/color.service';
import { ActivityService } from '../../../service/filter/activity.service';
import { NgbActiveModal, NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { xlModal } from '../../../../../constants';
import { ActivityTaskUpdateInterface } from '../../../../store/activity-button/activity-button.model';
import { SiteInterface } from '../../../../store/site-line-selection/site-line-selection.model';
import { IAssignedWorkOrder } from '../../../../store/home/multi-line-change-activity/multi-line-change-activity.model';
import { IChangeActivity } from '../../../../store/home/home.model';
import { HomeActionTypes, MultipleChangeActivity } from '../../../../store/home/home.actions';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-multi-line-change-activity',
  templateUrl: './multi-line-change-activity.component.html',
})
export class MultiLineChangeActivityComponent implements OnInit, OnDestroy {
  @Input() modal: NgbActiveModal;
  @Input() linePathWoSplitResults: number[];
  @Input() linePathId: number;
  @Input() siteId: number;
  @Input() lineId: number;

  @Output() finishWithoutOperation: EventEmitter<void> = new EventEmitter<void>();

  public readonly datatableHeaderTrackBy = ComponentUtilities.datatableHeaderTrackBy;
  public plannedDownTimeActivityInputColors$: Observable<ComponentColors> = combineLatest([
    this.store.select('user').pipe(filter((value) => value.isUserLoaded)),
    this.store.select('mainStore').pipe(filter((value) => value.getActiveSitesLoaded)),
  ]).pipe(
    map(([user, main]) => {
      const customColorParams = {
        allSites: main.activeSites,
        client: user.clientInfo,
        visibleSiteIds: [user.siteId],
      };

      return this.colorService.pickComponentColors('plannedDownTimeActivityInput', customColorParams);
    }),
  );
  public allSites$: Observable<SiteInterface[]> = this.store
    .select('mainStore')
    .pipe(filter((value) => value.getActiveSitesLoaded))
    .pipe(map((state) => state.activeSites));
  public $tableItems: IChangeActivityTable[] = [];

  public readonly tableHeaders: ITableHeader[] = [
    {
      value: 'lineName',
      name: this.translate.instant('general.dataTable.header.lineTitle'),
      sortable: false,
    },
    {
      value: 'currentWorkOrder',
      name: this.translate.instant('general.dataTable.header.currentWorkOrder'),
      sortable: false,
    },
    {
      value: 'finalizeCurrentWorkOrder',
      name: this.translate.instant('general.dataTable.header.finalizeCurrentWorkOrder'),
      sortable: false,
    },
    {
      value: 'nextWorkOrder',
      name: this.translate.instant('general.dataTable.header.nextWorkOrder'),
      sortable: false,
    },
    {
      value: 'activity',
      name: this.translate.instant('general.dataTable.header.activityName'),
      sortable: false,
    },
  ];
  public activitySelectionLineId: number;
  public isFormSubmitted: boolean = false;

  private readonly subscriptions: Subscription[] = [];
  private activitySelectionModalRef: NgbModalRef;

  constructor(
    private readonly store: Store<OeeAppState>,
    private readonly actionsSubject: ActionsSubject,
    private readonly colorService: ColorService,
    private readonly activityService: ActivityService,
    private readonly ngbModal: NgbModal,
    private readonly translate: TranslateService,
  ) {}

  public ngOnInit(): void {
    this.store.dispatch(
      new MultiLineChangeActivityActions.LoadOngoingActivitiesOfLinePath(this.linePathWoSplitResults, this.linePathId),
    );

    this.subscriptions.push(
      this.actionsSubject
        .pipe(ofType(EMultiLineChangeActivityAction.LOADED_ONGOING_ACTIVITIES_OF_LINE_PATH))
        .subscribe((response: LoadedOngoingActivitiesOfLinePath): void => {
          this.$tableItems = (response?.data?.assignedWorkOrders || []).map((workOrder: IAssignedWorkOrder) => {
            const activityNameAndActivityTaskName = this.activityService.initActivityDefaultStateAndSetType({
              activityId: workOrder.assignedLineWithinLinePath.currentActivity?.id,
              activityType: workOrder.assignedLineWithinLinePath.currentActivity?.activityType,
            });
            this.activityService.setActivityAndTaskName(
              activityNameAndActivityTaskName,
              workOrder.assignedLineWithinLinePath.currentActivity?.name,
              workOrder.assignedLineWithinLinePath.currentTask?.title,
              workOrder.assignedLineWithinLinePath.currentTask?.equipment?.equipmentName,
            );

            const currentWorkOrderId = workOrder.assignedLineWithinLinePath?.workOrder?.id;
            const isChangeActivitySkipped = workOrder.assignedLineWithinLinePath?.workOrder?.id === workOrder.id;

            return {
              activityNameAndActivityTaskName,
              isChangeActivitySkipped,
              lineId: workOrder.assignedLineWithinLinePath.id,
              lineName: workOrder.assignedLineWithinLinePath.title,
              nextWorkOrder: workOrder.woNumber,
              nextWorkOrderId: workOrder.id,
              currentWorkOrder: workOrder.assignedLineWithinLinePath?.workOrder?.woNumber,
              finalizeCurrentWorkOrder: !isChangeActivitySkipped && Boolean(currentWorkOrderId),
              activityId: workOrder.assignedLineWithinLinePath.currentActivity?.id ?? null,
              taskId: workOrder.assignedLineWithinLinePath.currentTask?.id ?? null,
              isActivityChanged: false,
            };
          });
        }),
      this.actionsSubject.pipe(ofType(HomeActionTypes.MultipleChangeActivityDone)).subscribe(() => {
        this.activitySelectionModalRef.dismiss();
      }),
    );
  }

  public openActivitySelectionModal(selectActivityModal: TemplateRef<any>, lineId: number): void {
    this.activitySelectionLineId = lineId;
    this.activitySelectionModalRef = this.ngbModal.open(selectActivityModal, xlModal);
  }

  public editedActivityButton(data: ActivityTaskUpdateInterface): void {
    const rowToUpdate = this.$tableItems.find((tableItem) => tableItem.lineId === this.activitySelectionLineId);
    rowToUpdate.activityId = data.activityId ?? null;
    rowToUpdate.taskId = data.taskId ?? null;
    rowToUpdate.activityNameAndActivityTaskName = this.activityService.initActivityDefaultStateAndSetType({
      activityId: data.activityId,
      activityType: data.activityType,
    });
    rowToUpdate.isActivityChanged = true;
    this.activityService.setActivityAndTaskName(
      rowToUpdate.activityNameAndActivityTaskName,
      data.activityName,
      data.taskName,
      data.equipmentName,
    );

    this.activitySelectionModalRef.dismiss();
  }

  public onSubmit(): void {
    this.isFormSubmitted = true;
    const rowsToSubmit: IChangeActivityTable[] = this.$tableItems.filter(
      (tableItem) => !tableItem.isChangeActivitySkipped,
    );

    if (!rowsToSubmit.length) {
      this.finishWithoutOperation.emit();
      return;
    }

    const isFormValid = rowsToSubmit.every((tableItem) => tableItem.activityId);

    if (!isFormValid) {
      return;
    }

    const changeActivityRequestData: IChangeActivity[] = rowsToSubmit
      .map(
        (tableItem): IChangeActivity => ({
          siteId: this.siteId,
          lineId: tableItem.lineId,
          activityId: tableItem.activityId,
          taskId: tableItem.taskId,
          workOrderId: tableItem.nextWorkOrderId,
          finalizeWorkOrder: tableItem.finalizeCurrentWorkOrder,
        }),
      )
      .sort((a) => Number(a.lineId === this.lineId));

    this.store.dispatch(new MultipleChangeActivity(changeActivityRequestData));
  }

  public ngOnDestroy(): void {
    this.subscriptions.forEach((item: Subscription) => {
      item.unsubscribe();
    });
  }
}
