import { ProductInterface, ProductTableQueryParams } from '../../../../../store/settings/products/products.model';
import { ISelectForm } from '../../../../../shared/model/interface/scw-form.model';
import { DropdownOptionInterface } from '../../../../../shared/component/scw-mat-ui/scw-mat-select/scw-mat-select.model';

export interface ITargetProduct {
  totalProductCount?: number;
  targetProductInfo?: ProductInterface[];
  queryParameters?: Pick<
    ProductTableQueryParams,
    'siteIds' | 'advancedFilter' | 'page' | 'pageSize' | 'search' | 'excludedProductIds'
  >;
}

export enum ETargetProduct {
  SELECTED_PRODUCTS = 0,
  ALL_PRODUCTS = 1,
}

export enum EProductScope {
  MASTER_DATA = 'masterData',
  SPEED = 'speed',
  MULTIPLIER = 'multiplier',
  UNIT_CONVERSION = 'unitConversion',
  STATION_CONFIGURATION = 'stationConfiguration',
  LINE_CONFIGURATION = 'lineConfiguration',
}

export interface IProductCloneFormInputs {
  products: ISelectForm<DropdownOptionInterface<number>> & {
    defaultValue?: DropdownOptionInterface<number>[] | null;
  };
  scope: ISelectForm<DropdownOptionInterface<number | string>> & {
    defaultValue?: DropdownOptionInterface<number>[] | null;
  };
}

export interface IProductCloneSettings {
  sourceProduct: IDropdownOptionValue;
  sourceProductUnits?: { key: string; value: string }[];
  isSelectAllProduct: boolean;
  targetProducts: ITargetProduct;
  selectedScopes: EProductScope[];
  siteId: number;
}

export interface IDropdownOptionValue {
  id: number;
  name: string;
}

export interface IProductCountInfo {
  productIds: string[];
  remainingCount: number;
}
