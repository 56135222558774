import { Action } from '@ngrx/store';
import { ProductInterface, ProductTableQueryParams } from '../products/products.model';
import { GetManyResponseInterface } from '../../../shared/model/interface/crud-response-interface.model';
import { IProductCloneReviewData } from '../../../view/settings/product-settings/products-new-design/product-clone/product-clone-review/product-clone-review.interface';
import { EProductScope } from 'src/app/view/settings/product-settings/products-new-design/product-clone/product-clone.model';
import { IProductClonePayload } from './product-clone.model';

export const PRODUCT_CLONE_PRODUCT_DATA_LOADING = '[PRODUCT CLONE] PRODUCT CLONE PRODUCT DATA LOADING';
export const PRODUCT_CLONE_PRODUCT_DATA_LOADED = '[PRODUCT CLONE] PRODUCT CLONE PRODUCT DATA LOADED';
export const PRODUCT_CLONE_PRODUCT_REVIEW_DATA_LOADING = '[PRODUCT CLONE] PRODUCT CLONE PRODUCT REVIEW DATA LOADING';
export const PRODUCT_CLONE_PRODUCT_REVIEW_DATA_LOADED = '[PRODUCT CLONE] PRODUCT CLONE PRODUCT REVIEW DATA LOADED';
export const PRODUCT_CLONE_PRODUCT_SUBMIT_DATA_LOADING = '[PRODUCT CLONE] PRODUCT CLONE PRODUCT SUBMIT DATA LOADING';
export const PRODUCT_CLONE_PRODUCT_SUBMIT_DATA_LOADED = '[PRODUCT CLONE] PRODUCT CLONE PRODUCT SUBMIT DATA LOADED';
export const PRODUCT_CLONE_FETCH_ERROR = '[PRODUCT CLONE] PRODUCT CLONE FETCH ERROR';
export const PRODUCT_CLONE_PRODUCT_COUNT_DATA_LOADING = '[PRODUCT CLONE] PRODUCT CLONE PRODUCT COUNT DATA LOADING';
export const PRODUCT_CLONE_PRODUCT_COUNT_DATA_LOADED = '[PRODUCT CLONE] PRODUCT CLONE PRODUCT COUNT DATA LOADED';

export class ProductCloneProductDataLoading implements Action {
  readonly type = PRODUCT_CLONE_PRODUCT_DATA_LOADING;

  constructor(
    public query: Pick<ProductTableQueryParams, 'siteIds' | 'advancedFilter' | 'page' | 'pageSize'> = {
      page: 1,
      pageSize: 50,
    },
  ) {}
}

export class ProductCloneProductDataLoaded implements Action {
  readonly type = PRODUCT_CLONE_PRODUCT_DATA_LOADED;

  constructor(public payload: GetManyResponseInterface<ProductInterface>) {}
}

export class ProductCloneProductCountDataLoading implements Action {
  readonly type = PRODUCT_CLONE_PRODUCT_COUNT_DATA_LOADING;

  constructor(
    public query: Pick<ProductTableQueryParams, 'siteIds' | 'advancedFilter' | 'page' | 'pageSize'> = {
      page: 1,
      pageSize: 1,
    },
  ) {}
}

export class ProductCloneProductCountDataLoaded implements Action {
  readonly type = PRODUCT_CLONE_PRODUCT_COUNT_DATA_LOADED;

  constructor(public productCount: number) {}
}

export class ProductCloneProductReviewDataLoading implements Action {
  readonly type = PRODUCT_CLONE_PRODUCT_REVIEW_DATA_LOADING;

  constructor(public sourceProductId: number, public requestedScopes: EProductScope[]) {}
}

export class ProductCloneProductReviewDataLoaded implements Action {
  readonly type = PRODUCT_CLONE_PRODUCT_REVIEW_DATA_LOADED;

  constructor(public data: IProductCloneReviewData) {}
}

export class ProductCloneProductSubmitDataLoading implements Action {
  readonly type = PRODUCT_CLONE_PRODUCT_SUBMIT_DATA_LOADING;

  constructor(public payload: IProductClonePayload) {}
}

export class ProductCloneProductSubmitDataLoaded implements Action {
  readonly type = PRODUCT_CLONE_PRODUCT_SUBMIT_DATA_LOADED;
}

export class FetchError implements Action {
  readonly type = PRODUCT_CLONE_FETCH_ERROR;

  constructor(public payload: any) {}
}

export type ProductCloneActions =
  | ProductCloneProductDataLoading
  | ProductCloneProductDataLoaded
  | ProductCloneProductSubmitDataLoading
  | ProductCloneProductSubmitDataLoaded
  | FetchError;
