import { PageConfigurationTypes } from '../../../constants.model';
import {
  EWidgetType,
  WidgetConfigurationInterface,
} from '../../shared/component/page-configuration/page-configuration.model';
import { TranslateService } from '@ngx-translate/core';
import { EKpiCardContentType } from '../../shared/component/scw-mat-ui/scw-mat-kpi-card/scw-mat-kpi-card.model';

export class AssetViewUtilities {
  public static getKpiCardData(translate: TranslateService): WidgetConfigurationInterface<EWidgetType.KPI_CARD>[] {
    return [
      {
        name: 'totalUniqueCount',
        type: PageConfigurationTypes.WIDGET,
        selected: true,
        disabled: true,
        content: {
          type: EWidgetType.KPI_CARD,
          pageConfigurationTitle: translate.instant('assetView.widgets.totalUniqueCount'),
          header: [
            {
              type: EKpiCardContentType.TEXT,
              value: translate.instant('assetView.widgets.totalUniqueCount'),
            },
            {
              type: EKpiCardContentType.ICON,
              value: 'fas fa-cubes',
            },
          ],
          body: [
            {
              key: 'totalUniqueCount',
              type: EKpiCardContentType.TEXT,
              value: '0',
            },
          ],
        },
      },
      {
        name: 'checkInAssetCount',
        type: PageConfigurationTypes.WIDGET,
        selected: true,
        disabled: true,
        content: {
          type: EWidgetType.KPI_CARD,
          pageConfigurationTitle: translate.instant('assetView.widgets.checkInAssetCount'),
          header: [
            {
              type: EKpiCardContentType.TEXT,
              value: translate.instant('assetView.widgets.checkInAssetCount'),
            },
            {
              type: EKpiCardContentType.ICON,
              value: 'fas fa-cube',
            },
          ],
          body: [
            {
              key: 'checkInAssetCount',
              type: EKpiCardContentType.TEXT,
              value: '0',
            },
          ],
        },
      },
      {
        name: 'checkOutAssetCount',
        type: PageConfigurationTypes.WIDGET,
        selected: true,
        disabled: true,
        content: {
          type: EWidgetType.KPI_CARD,
          pageConfigurationTitle: translate.instant('assetView.widgets.checkOutAssetCount'),
          header: [
            {
              type: EKpiCardContentType.TEXT,
              value: translate.instant('assetView.widgets.checkOutAssetCount'),
            },
            {
              type: EKpiCardContentType.ICON,
              value: 'fas fa-cube',
            },
          ],
          body: [
            {
              key: 'checkOutAssetCount',
              type: EKpiCardContentType.TEXT,
              value: '0',
            },
          ],
        },
      },
    ];
  }
}
