import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { HttpClient } from '@angular/common/http';
import { Store } from '@ngrx/store';
import * as LineViewActions from './line-view.actions';
import * as AppActions from '../../../app/actions';
import * as oeeAppReducer from '../../../oee.reducer';
import { LineViewGlobalService } from '../../../../shared/service/global-view/site-view/line-view/line-view.service';
import { catchError, switchMap } from 'rxjs/operators';
import { of } from 'rxjs';
import { ILineViewData } from './line-view.model';
import { ResponseInterface } from '../../../../shared/model/interface/generic-api-response.model';

@Injectable()
export class LineViewEffects {
  constructor(
    private readonly actions$: Actions,
    private readonly http: HttpClient,
    private readonly store: Store<oeeAppReducer.OeeAppState>,
    private readonly service: LineViewGlobalService,
  ) {}

  getLineViewStationData = createEffect(() =>
    this.actions$.pipe(
      ofType(LineViewActions.LINE_VIEW_STATION_DATA_LOADING),
      switchMap((objectData: LineViewActions.StationDataLoading) => {
        this.store.dispatch(new AppActions.ShowLoader());

        return this.service.getLineViewStationData(objectData.siteId, objectData.lineId).pipe(
          switchMap((response: ResponseInterface<ILineViewData>) => {
            return of(new LineViewActions.StationDataLoaded(response.data), new AppActions.HideLoader());
          }),
          catchError((error) => {
            return of(new LineViewActions.FetchDataError(error), new AppActions.HideLoader());
          }),
        );
      }),
      catchError((error) => {
        return of(new LineViewActions.FetchDataError(error), new AppActions.HideLoader());
      }),
    ),
  );
}
