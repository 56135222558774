import { Inject, Injectable } from '@angular/core';
import { FilterServiceInterface } from './service.class';
import { HttpClient, HttpParams } from '@angular/common/http';
import { NotificationRecipientUserCRUDInterface, UsersCRUDInterface } from '../../component/filter/filter.class';
import { forkJoin, Observable } from 'rxjs';
import { GetManyResponseInterface } from '../../model/interface/crud-response-interface.model';
import { map } from 'rxjs/operators';
import { uniqBy, find, get, omitBy, set, sortBy } from 'lodash';
import {
  NotificationGroupInterface,
  NotificationMailRecipient,
} from '../../../store/settings/nofication-center/notification-groups/notification-groups.model';
import { IGenericObject } from '../../model/interface/generic.model';

@Injectable({
  providedIn: 'root',
})
export class NotificationRecipientUserService implements FilterServiceInterface {
  constructor(public http: HttpClient, @Inject('API_BASE_URL') private baseUrl: string) {}

  getData(options?: HttpParams): Promise<NotificationRecipientUserCRUDInterface[]> {
    return new Promise((resolve, reject): void => {
      this.getNotificationRecipientUsers(options).subscribe(
        (response: NotificationRecipientUserCRUDInterface[]): void => {
          if (response.length) {
            resolve(response);
          } else {
            reject();
          }
        },
      );
    });
  }

  public getNotificationRecipientUsers(params?: HttpParams): Observable<NotificationRecipientUserCRUDInterface[]> {
    const searchString: string = find(get(params, 'updates'), { param: 's' }, '')?.value ?? '';
    const searchObject: IGenericObject<any> | undefined = searchString ? JSON.parse(searchString) : undefined;
    set(params, 'updates', Object.values(omitBy(get(params, 'updates'), { param: 's' })));
    let userParams: HttpParams = params.append('fields', 'email,fullName').set('sort', 'fullName,ASC');
    let mailRecipientParams: HttpParams = params
      .append('fields', 'id')
      .append('join', 'notificationMailRecipient')
      .set('sort', 'notificationMailRecipient.recipientName,ASC');

    if (searchObject && get(searchObject, '$and')?.length && get(searchObject, '$and.0.$or')?.length) {
      userParams = userParams.set(
        's',
        JSON.stringify({
          $and: [
            {
              $or: [
                ...get(searchObject, '$and.0.$or')?.filter((value: object) => {
                  return ['email', 'fullName'].includes(Object.keys(value)[0] as string);
                }),
              ],
            },
          ],
        }),
      );
      mailRecipientParams = mailRecipientParams.set(
        's',
        JSON.stringify({
          $and: [
            {
              $or: [
                ...get(searchObject, '$and.0.$or')?.filter((value: object) => {
                  return [
                    'notificationMailRecipient.recipientmailaddress',
                    'notificationMailRecipient.recipientname',
                  ].includes(Object.keys(value)[0] as string);
                }),
              ],
            },
            { 'notificationMailRecipient.userId': { $eq: null } },
          ],
        }),
      );
    }

    return forkJoin([this.getUsers(userParams), this.getMailGroupUsers(mailRecipientParams)]).pipe(
      map(([users, mailGroups]) => {
        return sortBy(
          uniqBy(
            [
              ...users.data.map((user: UsersCRUDInterface) => ({ ...user, name: user.fullName })),
              ...mailGroups.data.reduce((acc: NotificationMailRecipient[], mailGroup: NotificationGroupInterface) => {
                return [
                  ...acc,
                  ...mailGroup.notificationMailRecipient.map((mailRecipient: NotificationMailRecipient) => ({
                    ...mailRecipient,
                    email: mailRecipient.recipientMailAddress,
                    name: mailRecipient.recipientName,
                  })),
                ];
              }, []),
            ],
            'email',
          ),
          ['name'],
          ['asc'],
        );
      }),
    );
  }

  public getUsers(params?: HttpParams): Observable<GetManyResponseInterface<UsersCRUDInterface>> {
    return this.http.get<GetManyResponseInterface<UsersCRUDInterface>>(`${this.baseUrl}/users`, {
      params,
    });
  }

  public getMailGroupUsers(params?: HttpParams): Observable<GetManyResponseInterface<NotificationGroupInterface>> {
    return this.http.get<GetManyResponseInterface<NotificationGroupInterface>>(
      `${this.baseUrl}/notifications/mail-groups`,
      {
        params,
      },
    );
  }
}
