import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Output } from '@angular/core';
import { ScwMatButtonModule } from '../../shared/component/scw-mat-ui/scw-mat-button/scw-mat-button.module';

@Component({
  selector: 'app-label-info-button',
  standalone: true,
  template: '<em (click)="onButtonClick()" class="fas fa-md fa-info-circle info-button"></em>',
  styleUrls: ['./label-info-button-component.scss'],
  imports: [CommonModule, ScwMatButtonModule],
})
export class LabelInfoButtonComponent {
  @Output() public buttonClick: EventEmitter<void> = new EventEmitter<void>();

  public onButtonClick(): void {
    this.buttonClick.emit();
  }
}
