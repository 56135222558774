import { Action } from '@ngrx/store';
import { IDeepDiveAnalysisData, IDeepDiveFilters, IFormattedOeeResult } from './deep-dive-analysis.model';

export const DEEP_DIVE_DATA_LOADING = '[DEEP_DIVE] DEEP DIVE DATA LOADING';
export const DEEP_DIVE_DATA_LOADED = '[DEEP_DIVE] DEEP DIVE DATA LOADED';
export const MONTHLY_OEE_DATA_LOADING = '[MONTHLY_OEE] MONTHLY DATA LOADING';
export const MONTHLY_OEE_DATA_LOADED = '[MONTHLY_OEE] MONTHLY DATA LOADED';
export const PRODUCT_GROUP_OEE_DATA_LOADING = '[PRODUCT_GROUP_OEE] PRODUCT GROUP DATA LOADING';
export const PRODUCT_GROUP_OEE_DATA_LOADED = '[PRODUCT_GROUP_OEE] PRODUCT GROUP DATA LOADED';
export const FETCH_ERROR = '[DEEP_DIVE] FETCH ERROR';
export const SET_SELECTED_FILTERS = '[DEEP_DIVE] SET SELECTED FILTERS';
export const SET_TABLE_SETTINGS = '[DEEP_DIVE] SET TABLE SETTINGS';
export const DEEP_DIVE_DOWNLOAD_EXCEL_COMPLETED = '[DEEP_DIVE] DEEP DIVE DOWNLOAD EXCEL COMPLETED';
export const DAILY_OEE_DATA_LOADING = '[DAILY_OEE] DAILY DATA LOADING';
export const DAILY_OEE_DATA_LOADED = '[DAILY_OEE] DAILY DATA LOADED';
export const UPDATE_FILTER_STATE = '[FILTER_STATE] FILTER STATE UPDATED';

export class DeepDiveDataLoading implements Action {
  readonly type = DEEP_DIVE_DATA_LOADING;

  constructor(public params: IDeepDiveFilters, public isShowLoader: boolean = true) {}
}

export class DeepDiveDataLoaded implements Action {
  readonly type = DEEP_DIVE_DATA_LOADED;

  constructor(public payload: IDeepDiveAnalysisData | null) {}
}

export class MonthlyOeeDataLoading implements Action {
  readonly type = MONTHLY_OEE_DATA_LOADING;

  constructor(public params: IDeepDiveFilters, public isShowLoader: boolean = true) {}
}

export class MonthlyOeeDataLoaded implements Action {
  readonly type = MONTHLY_OEE_DATA_LOADED;

  constructor(public payload: IFormattedOeeResult[]) {}
}

export class ProductGroupOeeDataLoading implements Action {
  readonly type = PRODUCT_GROUP_OEE_DATA_LOADING;

  constructor(public params: IDeepDiveFilters, public isShowLoader: boolean = true) {}
}

export class ProductGroupOeeDataLoaded implements Action {
  readonly type = PRODUCT_GROUP_OEE_DATA_LOADED;

  constructor(public payload: IFormattedOeeResult[]) {}
}

export class SetSelectedFilters implements Action {
  readonly type = SET_SELECTED_FILTERS;
  constructor(public filters: IDeepDiveFilters) {}
}

export class DeepDiveDownloadExcelCompleted implements Action {
  readonly type = DEEP_DIVE_DOWNLOAD_EXCEL_COMPLETED;
}

export class FetchError implements Action {
  readonly type = FETCH_ERROR;

  constructor(public payload: object) {}
}

export class DailyOeeDataLoading implements Action {
  readonly type = DAILY_OEE_DATA_LOADING;

  constructor(public params: IDeepDiveFilters) {}
}

export class DailyOeeDataLoaded implements Action {
  readonly type = DAILY_OEE_DATA_LOADED;

  constructor(public payload: IFormattedOeeResult[]) {}
}

export class updateFilterState implements Action {
  readonly type = UPDATE_FILTER_STATE;

  constructor(public params: IDeepDiveFilters) {}
}

export type DeepDiveActions = updateFilterState | SetSelectedFilters;
