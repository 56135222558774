import { Action } from '@ngrx/store';
import { HttpOptionInterface } from '../filter.model';
import { ApiServer } from '../../../shared/component/filter/dropdown/dropdown.model';
import { ICustomerSegment } from '../../../shared/service/settings/customer-settings/customer-segments.model';

export enum FilterCustomerSegmentActionTypes {
  StartLoadFilterCustomerSegments = '[Filter CustomerSegment] Load Filter CustomerSegments',
  FilterCustomerSegmentsLoaded = '[Filter CustomerSegment] Filter CustomerSegments Loaded',
}

export class StartLoadFilterCustomerSegments implements Action {
  readonly type = FilterCustomerSegmentActionTypes.StartLoadFilterCustomerSegments;
  constructor(public options: HttpOptionInterface, public server: ApiServer = ApiServer.NestJS) {}
}

export class FilterCustomerSegmentsLoaded implements Action {
  readonly type = FilterCustomerSegmentActionTypes.FilterCustomerSegmentsLoaded;
  constructor(public data: Partial<ICustomerSegment>[]) {}
}

export type FilterCustomerSegmentActions = StartLoadFilterCustomerSegments | FilterCustomerSegmentsLoaded;
