import { Injectable, OnDestroy } from '@angular/core';
import { Store } from '@ngrx/store';
import {
  collection,
  doc,
  Firestore,
  FirestoreError,
  limit,
  onSnapshot,
  orderBy,
  Query,
  query,
  QueryDocumentSnapshot,
  QuerySnapshot,
  Timestamp,
  UpdateData,
  updateDoc,
  where,
  WriteBatch,
  writeBatch,
} from 'firebase/firestore';
import moment from 'moment';
import { BehaviorSubject, combineLatest, Observable, Subject, Subscriber, Subscription } from 'rxjs';
import { filter, map, switchMap, take, takeUntil } from 'rxjs/operators';
import {
  ENotificationSubscriptionType,
  IFirebaseDateRange,
  INotificationFeedItem,
  IUpdatedDocument,
} from './notification-feed.model';
import { OeeAppState } from 'src/app/store/oee.reducer';
import { selectUserFirebaseConfig } from 'src/app/store/user/user.selectors';
import { ScwFirebaseService } from '../firebase/firebase.service';
import * as PushNotificationActions from '../../../store/push-notification/push-notification.actions';
import { FirebaseCredentials } from '../../../store/user/model';
import * as _ from 'lodash';

@Injectable({
  providedIn: 'root',
})
export class NotificationFeedService implements OnDestroy {
  public readonly numberOfDaysOfHistory: number = 30;
  public currentIndexOfDateScope: number = 0;
  public readonly notificationLimit: { [key in ENotificationSubscriptionType]: number } = {
    [ENotificationSubscriptionType.ALL]: 50,
    [ENotificationSubscriptionType.ONLY_UNREAD]: 10,
  };
  private subscriptions: Subscription[] = [];

  private readonly dateRanges: IFirebaseDateRange[] = [
    { startAt: moment.utc().subtract(7, 'd'), endAt: null },
    {
      startAt: moment.utc().subtract(14, 'd'),
      endAt: moment.utc().subtract(7, 'd'),
    },
    {
      startAt: moment.utc().subtract(21, 'd'),
      endAt: moment.utc().subtract(14, 'd'),
    },
    {
      startAt: moment.utc().subtract(30, 'd'),
      endAt: moment.utc().subtract(21, 'd'),
    },
  ];

  private readonly collectionName$: Observable<string> = combineLatest([
    this.firebaseService.getUid(),
    this.store
      .select(selectUserFirebaseConfig)
      .pipe(filter((config: FirebaseCredentials | undefined): config is FirebaseCredentials => config !== undefined)),
  ]).pipe(map(([uid, config]: [string, FirebaseCredentials]) => config.collectionPath.replace('{{uid}}', uid)));

  private readonly firebaseParameters$: Observable<[Firestore, string]> = combineLatest([
    this.firebaseService.getFirestore(),
    this.collectionName$,
  ]);

  private destroy$: Subject<void> = new Subject();

  private notifications$: BehaviorSubject<Subject<INotificationFeedItem[]>[]> = new BehaviorSubject([]);

  constructor(private readonly firebaseService: ScwFirebaseService, private readonly store: Store<OeeAppState>) {
    this.subscribeToNotifications(ENotificationSubscriptionType.ONLY_UNREAD);
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();

    this.subscriptions.forEach((subscription: Subscription) => {
      subscription.unsubscribe();
    });
  }

  public getNotifications(): Observable<readonly INotificationFeedItem[]> {
    return this.notifications$.pipe(
      switchMap((notificationSubjects) => {
        return combineLatest(notificationSubjects).pipe(
          map((notifications) => {
            return _.flatten(notifications);
          }),
        );
      }),
    );
  }

  public update(id: string, data: UpdateData<INotificationFeedItem>): void {
    this.firebaseParameters$
      .pipe(take(1))
      .subscribe(([firestore, collectionName]: [Firestore, string]) =>
        updateDoc(doc(firestore, `${collectionName}/${id}`), data),
      );
  }

  public bulkUpdate(docs: readonly IUpdatedDocument[]): void {
    this.firebaseParameters$.pipe(take(1)).subscribe(([firestore, collectionName]: [Firestore, string]) => {
      const batch: WriteBatch = writeBatch(firestore);
      docs.forEach((document: IUpdatedDocument) => {
        batch.update(doc(firestore, `${collectionName}/${document.id}`), document.updateData);
      });
      batch.commit();
    });
  }

  public openPushNotificationPermissionModal(): void {
    this.store.dispatch(new PushNotificationActions.OpenPushNotificationPermissionModal());
  }

  public loadMoreNotifications(): void {
    this.subscribeToNotifications(ENotificationSubscriptionType.ALL);
  }

  public getDateRange(index?: number): IFirebaseDateRange | undefined {
    return _.get(this.dateRanges, index || this.currentIndexOfDateScope);
  }

  private subscribeToNotifications(subscriptionType: ENotificationSubscriptionType): void {
    const subject = new BehaviorSubject([]);
    this.notifications$.next([...this.notifications$.value, subject]);

    this.subscriptions.push(
      this.firebaseParameters$
        .pipe(
          switchMap(
            ([firestore, collectionName]: [Firestore, string]) =>
              new Observable<INotificationFeedItem[]>((subscriber: Subscriber<INotificationFeedItem[]>) => {
                this.observeNotifications(firestore, collectionName, subscriptionType, subscriber);
              }),
          ),
          takeUntil(this.destroy$),
        )
        .subscribe(subject),
    );
  }

  private createNotificationQuery(
    firestore: Firestore,
    collectionName: string,
    subscriptionType: ENotificationSubscriptionType,
    dateRange: IFirebaseDateRange,
  ): Query {
    const conditions = [
      where('createdAt', '>', Timestamp.fromDate(moment.utc().subtract(this.numberOfDaysOfHistory, 'd').toDate())),
      orderBy('createdAt', 'desc'),
      limit(this.notificationLimit[subscriptionType] || 10),
    ];

    switch (subscriptionType) {
      case ENotificationSubscriptionType.ONLY_UNREAD:
        conditions.push(where('readAt', '==', null));
        break;
      case ENotificationSubscriptionType.ALL:
        conditions.push(where('createdAt', '>=', Timestamp.fromDate(dateRange.startAt.toDate())));
        this.currentIndexOfDateScope += 1;

        if (dateRange.endAt) {
          conditions.push(where('createdAt', '<', Timestamp.fromDate(dateRange.endAt.toDate())));
        }

        break;
    }

    return query(collection(firestore, collectionName), ...conditions);
  }

  private observeNotifications(
    firestore: Firestore,
    collectionName: string,
    subscriptionType: ENotificationSubscriptionType,
    subscriber: Subscriber<INotificationFeedItem[]>,
  ): void {
    const dateRange: IFirebaseDateRange | undefined = this.getDateRange();

    if (!dateRange) {
      return;
    }

    onSnapshot(this.createNotificationQuery(firestore, collectionName, subscriptionType, dateRange), {
      next(snapshot: QuerySnapshot) {
        const notifications: INotificationFeedItem[] = snapshot.docs.map((d: QueryDocumentSnapshot) => ({
          docId: d.id,
          notification: d.get('notification'),
          openedAt: d.get('openedAt'),
          readAt: d.get('readAt'),
          createdAt: d.get('createdAt'),
        }));

        subscriber.next(notifications);
      },
      error(error: FirestoreError) {
        subscriber.error(error);
      },
    });
  }
}
