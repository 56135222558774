import { ProductCRUDInterface, ProductFamilyCRUDInterface } from '../../../shared/component/filter/filter.class';
import {
  FilterProductOrProductFamilyActions,
  FilterProductOrProductFamilyActionTypes,
} from './product-or-product-family.actions';
import {EProductOrFamilyCompareOptions} from "../../../shared/component/filter/filterable-objects.class";

export interface FilterProductOrProductFamilyState {
  isLoading: boolean;
  isLoaded: boolean;
  data: ProductCRUDInterface[] | ProductFamilyCRUDInterface[];
  totalCount: number;
  allowNewRequest: boolean;
  dataType?: EProductOrFamilyCompareOptions;
}

export const initialFilterProductOrProductFamilyState: FilterProductOrProductFamilyState = {
  isLoaded: false,
  isLoading: false,
  data: [],
  totalCount: 0,
  allowNewRequest: false,
};

export function filterProductOrProductFamilyReducer(
  state = initialFilterProductOrProductFamilyState,
  action: FilterProductOrProductFamilyActions,
): FilterProductOrProductFamilyState {
  switch (action.type) {
    case FilterProductOrProductFamilyActionTypes.StartLoadFilterProductOrProductFamily:
      return { ...state, isLoading: true };

    case FilterProductOrProductFamilyActionTypes.FilterProductOrProductFamilyLoaded:
      return {
        ...state,
        data: action.data,
        allowNewRequest: action.totalCount > 0 && action.data.length > 0 && action.totalCount > action.data.length,
        totalCount: action.totalCount,
        isLoaded: true,
        isLoading: false,
      };

    case FilterProductOrProductFamilyActionTypes.ResetProductOrProductFamilyStore:
      return {
        ...state,
        isLoaded: false,
        isLoading: false,
        data: [],
      };
    default:
      return state;
  }
}
