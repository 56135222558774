<scw-mat-form (scwMatFormSubmit)="onFormSubmit($event)">
  <div class="modal-header scw-modal-header">
    <h4 class="modal-title scw-header">
      {{ 'actionTracker.addIssueAction' | translate }}
    </h4>
    <button type="button" class="close" aria-label="Close" onfocus="blur()" (click)="closeModal()">
      <i aria-hidden class="fas fa-times"></i>
    </button>
  </div>

  <div class="modal-body">
    <div class="container p-0">
      <div class="row">
        <div class="col-12" *ngIf="isUserExistInActionTracker === false">
          <div class="alert alert-warning">
            <p class="m-0">{{ 'actionTracker.noUserWarning' | translate }}</p>
          </div>
        </div>
        <div class="col">
          <scw-mat-input
            label="{{ 'actionTracker.title' | translate }}*"
            [(inputModel)]="name"
            [rules]="formRules.name"
            [disabled]="!isUserExistInActionTracker"
            [block]="true"
          ></scw-mat-input>
        </div>
      </div>
      <div class="row">
        <div class="col">
          <scw-mat-textarea
            label="{{ 'general.description' | translate }}"
            [rows]="5"
            [block]="true"
            [(inputModel)]="description"
            [rules]="formRules.description"
            [disabled]="!isUserExistInActionTracker"
          ></scw-mat-textarea>
        </div>
      </div>
      <div class="row mb-2">
        <div class="col">
          <scw-mat-button *ngIf="!isPageUrlVisible" type="standard" size="sm" (onClick)="onAddLinkClick()">
            <em class="fas fa-link"></em>
            {{ 'actionTracker.addLink' | translate }}
          </scw-mat-button>
          <div *ngIf="isPageUrlVisible" class="link-container">
            <em class="fas fa-link mr-1 url-icon"></em>
            <a href="{{ pageUrl }}" [title]="pageUrl" target="_blank">
              {{ pageTitle }}
            </a>
            <span class="close-btn" (click)="hiddenPageLink()">✖</span>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-6">
          <scw-mat-select
            label="{{ 'actionTracker.board' | translate }}*"
            [data]="boards$"
            (onSelect)="onSelectedBoardChange()"
            (onDeSelectAll)="onBoardDropdownDeselectAll()"
            (onDeSelect)="onSelectedBoardChange()"
            [(inputModel)]="selectedBoards"
            [rules]="formRules.board"
            [disabled]="!isUserExistInActionTracker"
            searchBehavior="delayed"
            (onSearch)="onBoardSearch($event)"
            [enableSearchFilter]="true"
            [isLoading]="isBoardsLoading"
          ></scw-mat-select>
        </div>
        <div class="col-6">
          <scw-mat-select
            label="{{ 'actionTracker.itemType' | translate }}*"
            [data]="itemTypes"
            [(inputModel)]="selectedItemTypes"
            [rules]="formRules.itemType"
            [disabled]="!isUserExistInActionTracker"
            searchBehavior="delayed"
          ></scw-mat-select>
        </div>
      </div>
      <div class="row">
        <div class="col-6">
          <scw-mat-select
            label="{{ 'actionTracker.assignee' | translate }}"
            [data]="boardMembers$"
            [(inputModel)]="selectedAssignees"
            [rules]="formRules.assignee"
            [disabled]="!isUserExistInActionTracker"
            searchBehavior="delayed"
            (onSearch)="onAssigneeSearch($event)"
            [enableSearchFilter]="true"
            [isLoading]="isBoardMembersLoading"
          ></scw-mat-select>
        </div>
        <div class="col-6">
          <scw-mat-datepicker
            label="{{ 'actionTracker.dueDate' | translate }}"
            class="scw_mat-add_edit_modal-item-input"
            [singleDatePicker]="true"
            [autoApply]="true"
            [showCustomRangeLabel]="false"
            [ranges]="null"
            [showClearButton]="false"
            [timePicker]="true"
            [drops]="'up'"
            [rules]="formRules.dueDate"
            [disabled]="!isUserExistInActionTracker"
            [(inputModel)]="selectedDueDate"
          ></scw-mat-datepicker>
        </div>
      </div>
      <div class="row">
        <div class="col">
          <scw-image-upload-button
            [disabled]="files?.length >= 5 || !isUserExistInActionTracker"
            [fileCount]="files.length"
            [sizeLimitPerFileMB]="10"
            [isOriginalQualityByDefault]="true"
          ></scw-image-upload-button>
        </div>
      </div>
    </div>
    <div class="images-container">
      <div *ngFor="let image of files; let i = index">
        <div *ngIf="image.original && image.type === EFileType.IMAGE">
          <div class="display-image-container">
            <div class="image-wrapper ml-1">
              <img [src]="image.thumbnail" alt="Base64 Image" class="fit-image" (click)="openPreviewModal(image, i)" />
              <button class="delete-button" (click)="showDeleteItemModal(file_delete_confirmation_modal, image)">
                <i class="fas fa-trash" style="color: #ffffff"></i>
              </button>
            </div>
          </div>
          <scw-image-preview-modal (deletedFile)="deleteButtonClickedFromPreview($event)" #image_preview_modal>
          </scw-image-preview-modal>
        </div>
      </div>
    </div>
  </div>
  <div class="col-12 p-1 ml-3 mb-1">
    <div class="row mr-1">
      <ng-container *ngFor="let file of files">
        <div class="col-md-6 w-100 p-0" *ngIf="file.original && file.type === EFileType.PDF">
          <div class="d-flex border rounded mt-2 mr-2">
            <div class="d-flex align-items-center m-1">
              <i class="fas fa-file-pdf fa-lg mx-2"></i>
              <div class="row">
                <span class="col-12 truncate-span filename-text"> {{ file.fileOriginalName }}</span>
                <span class="col-12 size-text"> {{ file.size }} MB</span>
              </div>
              <button (click)="showDeleteItemModal(file_delete_confirmation_modal, file)" class="delete-button-file">
                <i class="fas fa-trash" style="color: #2a2828"></i>
              </button>
            </div>
          </div>
        </div>
      </ng-container>
    </div>
  </div>

  <div class="modal-footer">
    <div class="buttons-right">
      <scw-mat-button [isSubmitButton]="true" [disabled]="!isUserExistInActionTracker">
        {{ 'actionTracker.addItem' | translate }}
      </scw-mat-button>
    </div>
  </div>
</scw-mat-form>

<ng-template #file_delete_confirmation_modal let-modal>
  <div class="modal-header scw-modal-header">
    <h3 class="modal-title scw-header">
      {{ 'fileUpload.delete.header' | translate }}
    </h3>
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss()">
      <span aria-hidden="true">
        <em class="fas fa-times"></em>
      </span>
    </button>
  </div>
  <div class="modal-body">
    <div class="row">
      <div class="col-12 information-text">
        <h6 class="scw-header mt-3">
          <em class="fas fa-exclamation-circle"></em>&nbsp;
          {{ 'fileUpload.delete.info' | translate }}
        </h6>
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <div class="buttons-right">
      <scw-mat-button type="standard" (onClick)="modal.dismiss()">
        {{ 'general.cancel' | translate }}
      </scw-mat-button>
      <scw-mat-button type="danger" (onClick)="deleteImage()">
        {{ 'general.delete' | translate }}
      </scw-mat-button>
    </div>
  </div>
</ng-template>
