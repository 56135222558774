import { TabRowInterface } from '../../../shared/component/side-config-bar/side-config-bar.model';
import { ICustomConfigurationDbEntry } from '../../../shared/component/page-configuration/page-configuration.model';

export interface ScheduleAdherenceStateInterface {
  scheduleAdherenceDataLoading: boolean;
  scheduleAdherenceDataLoaded: boolean;
  scheduleAdherenceJobDataLoading: boolean;
  scheduleAdherenceJobDataLoaded: boolean;
  currentData: IScheduleAdherenceData | null;
  filters: IScheduleAdherenceFilters;
  tableSettings: TabRowInterface[];
  customConfiguration: ICustomConfigurationDbEntry[];
  schedulerJobData: IScheduleAdherenceJobData | null;
}

export interface IScheduleAdherenceFilters {
  intervalStartDate: string;
  intervalEndDate: string;
  siteId: number;
  lineIds: number[] | -1;
  includeAllActivities: number;
}

export interface IScheduleAdherenceResponseData {
  client: string;
  siteId: number;
  actualLineId: number;
  actualLineName: string;
  scheduledLineId: number;
  scheduledLineName: string;
  workOrderId: number;
  woNumber: string;
  completed: boolean;
  woDueDate: string | null;
  actualMinStart: string | null;
  actualMaxEnd: string | null;
  scheduledRunDate: string | null;
  scheduledEndDate: string | null;
  scheduledPreDuration: number | null;
  scheduledPostDuration: number | null;
  scheduledRunDuration: number | null;
  actualNoPhaseDuration: number;
  actualPreRunPhaseDuration: number;
  actualPostRunPhaseDuration: number;
  actualRunPhaseDuration: number;
  calcScheduledEndDate: string | null;
  varianceOfPreRunDuration: number | null;
  varianceOfPostRunDuration: number | null;
  varianceOfRunDuration: number | null;
  isOnTimeCompletion: boolean;
  runType: string;
  overdue: boolean;
  behindScheduleWorkOrder: boolean;
  compliancePercentage: number | null;
  preRunPhaseName: string | null;
  runPhaseName: string;
  postRunPhaseName: string;
  totalScrapCount: string | null;
  totalGoodCount: string | null;
  quantityOrdered: string;
  scheduledLinePathId?: number | null;
  assignedLinePathId?: number | null;
  assignedLineIdWithinLinePath?: number | null;
  scheduledLinePathName?: string | null;
  assignedLinePathName?: string | null;
  assignedLineWithinLinePathName?: string | null;
  productName?: string | null;
  productDescription?: string | null;
  productFamilyName?: string | null;
  productId?: string | null;
  productFamilyId?: string | null;
}

export interface IScheduleAdherenceData {
  kpiCardsData: ICommonScheduleAdherenceKpiCardData;
  chartNodes: ICommonScheduleAdherenceChartNode[];
  rawData: IScheduleAdherenceResponseData[];
  tableData: IScheduleAdherenceTableRow[];
  numberChartData: ICommonNumberChart[];
}

export interface IWoDurationsChartNode {
  title: string;
  phases: IPhases;
}

export interface IPhases {
  preRun: IPhaseData;
  run: IPhaseData;
  postRun: IPhaseData;
  noPhase: IPhaseData;
}

export interface IPhaseData {
  title: string;
  scheduledDuration: number;
  actualDuration: number;
}

export interface IScheduleAdherenceTableRow {
  actualLine: string;
  scheduledLine: string;
  workOrder: string;
  scheduledPreRun: string | null;
  actualPreRun: string;
  varianceOfPreRun: string | null;
  scheduledRun: string | null;
  actualRun: string;
  varianceOfRun: string | null;
  scheduledPostRun: string;
  actualPostRun: string;
  varianceOfPostRun: string | null;
  scheduledStartDate: string | null;
  actualStartDate: string | null;
  scheduledEndDate: string | null;
  actualEndDate: string | null;
  dueDate: string | null;
  varianceStatus: IDurationVarianceResults;
  isOverdue: boolean;
  goodCount: string | null;
  scrapCount: string | null;
  quantityOrdered: string;
  workOrderId?: number;
  scheduledLinePathName?: string | null;
  assignedLinePathName?: string | null;
  assignedLineWithinLinePathName?: string | null;
  productName?: string | null;
  productDescription?: string | null;
  productFamilyName?: string | null;
}

export interface IDurationVarianceResults {
  varianceOfPreRun: DurationVarianceType;
  varianceOfRun: DurationVarianceType;
  varianceOfPostRun: DurationVarianceType;
}

export enum DurationVarianceType {
  NEGATIVE = 0,
  POSITIVE = 1,
  ZERO = 2,
}

export enum WorkOrderRunType {
  PLANNED_AND_STARTED = 'planned_and_started',
  PLANNED_AND_NOT_STARTED = 'planned_and_not_started',
  UNPLANNED_AND_STARTED = 'unplanned_and_started',
}

export type TGroupedData = {
  [key in WorkOrderRunType]?: number[];
};

export enum EScheduleAdherenceAnalysisMode {
  WORK_ORDER_BASED = 'workOrderBased',
  JOB_BASED = 'jobBased',
}

export interface IScheduleAdherenceJobTableRow {
  jobName: string;
  scheduledPreRun: string | null;
  actualPreRun: string;
  varianceOfPreRun: string | null;
  scheduledRun: string | null;
  actualRun: string;
  varianceOfRun: string | null;
  scheduledPostRun: string;
  actualPostRun: string;
  varianceOfPostRun: string | null;
  scheduledStartDate: string | null;
  actualStartDate: string | null;
  scheduledEndDate: string | null;
  actualEndDate: string | null;
  dueDate: string | null;
  workOrdersTableData: IScheduleAdherenceData;
  varianceStatus: IDurationVarianceResults;
  isOverdue: boolean;
  jobCoverage: string;
  jobId?: number;
}

export interface IScheduleAdherenceJobResponseData {
  client: string;
  siteId: number;
  jobId: number;
  jobName: string;
  jobDueDate: string | null;
  jobScheduledStartDate: string | null;
  jobScheduledEndDate: string | null;
  jobActualStartTime: string | null;
  jobActualEndTime: string | null;
  jobScheduledPreRunDuration: number | null;
  jobScheduledRunDuration: number | null;
  jobScheduledPostDuration: number | null;
  jobActualPreRunDuration: number | null;
  jobActualRunDuration: number | null;
  jobActualPostDuration: number | null;
  jobActualNoPhaseDuration: number | null;
  jobVarianceOfPreRunDuration: number | null;
  jobVarianceOfPostRunDuration: number | null;
  jobVarianceOfRunDuration: number | null;
  jobOverdue: boolean;
  jobsPlanType: string | null;
  jobIsOnTimeCompletion: boolean;
  jobBehindSchedule: boolean;
  jobCoverage: boolean;
  preRunPhaseName: string | null;
  runPhaseName: string;
  postRunPhaseName: string;
  workOrders: IScheduleAdherenceResponseData[];
}

export interface IScheduleAdherenceJobData {
  kpiCardsData: ICommonScheduleAdherenceKpiCardData;
  chartNodes: ICommonScheduleAdherenceChartNode[];
  rawData: IScheduleAdherenceJobResponseData[];
  tableData: IScheduleAdherenceJobTableRow[];
  numberChartData: ICommonNumberChart[];
}

export enum jobsPlanType {
  PLANNED_AND_STARTED = 'planned_and_started',
  PLANNED_AND_NOT_STARTED = 'planned_and_not_started',
  UNPLANNED_AND_STARTED = 'unplanned_and_started',
}

export interface ICommonScheduleAdherenceChartNode {
  title: string;
  phases: IPhases;
}

export interface ICommonScheduleAdherenceKpiCardData {
  overDue: number;
  behindSchedule: number;
  scheduleCompliance: number;
  scheduleAdherence: number;
  scheduleAttainment: number;
  onTimeCompletion: number;
  plannedAndStarted: number;
  unplannedAndStarted: number;
  plannedAndNotStarted: number;
}

export interface ICommonNumberChart {
  scheduledStart: string | null;
  scheduledEnd: string | null;
  actualStart: string | null;
  actualEnd: string | null;
}
