import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import * as ObjectActions from './product-resource-groups.actions';
import { catchError, switchMap } from 'rxjs/operators';
import {
  BaseOneResponseInterface,
  BulkResponseDataInterface,
  GetManyResponseInterface,
} from '../../../shared/model/interface/crud-response-interface.model';
import { of } from 'rxjs';
import * as oeeAppReducer from '../../oee.reducer';
import * as AppActions from '../../app/actions';
import { ProductResourceGroupsService } from '../../../shared/service/product-resource-groups/product-resource-groups.service';
import { IProductResourceGroup } from './product-resource-groups.model';

@Injectable()
export class ProductResourceGroupsEffects {
  constructor(
    private readonly store: Store<oeeAppReducer.OeeAppState>,
    private readonly actions$: Actions<ObjectActions.ResourceGroupActions>,
    private readonly service: ProductResourceGroupsService,
  ) {}


  $getResourceGroups = createEffect(() => this.actions$.pipe(
    ofType(ObjectActions.ProductResourceGroupsActionTypes.GET_PRODUCT_RESOURCE_GROUPS_LOADING),
    switchMap((objectData: ObjectActions.GetProductResourceGroupsLoading) => {
      this.store.dispatch(new AppActions.ShowLoader());
      return this.service.getResourceGroups(objectData.parameters).pipe(
        switchMap((response: GetManyResponseInterface<IProductResourceGroup>) => {
          return of(new ObjectActions.GetProductResourceGroupsLoaded(response, objectData.purpose), new AppActions.HideLoader());
        }),
        catchError(() => {
          return of(new AppActions.HideLoader());
        }),
      );
    }),
    catchError(() => {
      return of(new AppActions.HideLoader());
    }),
  ));


  $createResourceGroup = createEffect(() => this.actions$.pipe(
    ofType(ObjectActions.ProductResourceGroupsActionTypes.CREATE_PRODUCT_RESOURCE_GROUP_LOADING),
    switchMap((objectData: ObjectActions.CreateProductResourceGroupLoading) => {
      this.store.dispatch(new AppActions.ShowLoader());
      return this.service.createResourceGroup(objectData.parameters).pipe(
        switchMap((response: BaseOneResponseInterface<IProductResourceGroup>) => {
          return of(new ObjectActions.CreateProductResourceGroupLoaded(response));
        }),
        catchError(() => {
          return of(new AppActions.HideLoader());
        }),
      );
    }),
    catchError(() => {
      return of(new AppActions.HideLoader());
    }),
  ));


  $updateResourceGroup = createEffect(() => this.actions$.pipe(
    ofType(ObjectActions.ProductResourceGroupsActionTypes.UPDATE_PRODUCT_RESOURCE_GROUP_LOADING),
    switchMap((objectData: ObjectActions.UpdateProductResourceGroupLoading) => {
      this.store.dispatch(new AppActions.ShowLoader());
      return this.service.updateResourceGroup(objectData.id, objectData.parameters).pipe(
        switchMap((response: BaseOneResponseInterface<IProductResourceGroup>) => {
          return of(new ObjectActions.UpdateProductResourceGroupLoaded(response));
        }),
        catchError(() => {
          return of(new AppActions.HideLoader());
        }),
      );
    }),
    catchError(() => {
      return of(new AppActions.HideLoader());
    }),
  ));


  $deleteResourceGroup = createEffect(() => this.actions$.pipe(
    ofType(ObjectActions.ProductResourceGroupsActionTypes.DELETE_PRODUCT_RESOURCE_GROUP_LOADING),
    switchMap((objectData: ObjectActions.DeleteProductResourceGroupLoading) => {
      this.store.dispatch(new AppActions.ShowLoader());
      return this.service.deleteResourceGroup(objectData.id).pipe(
        switchMap(() => {
          return of(new ObjectActions.DeleteProductResourceGroupLoaded());
        }),
        catchError(() => {
          return of(new AppActions.HideLoader());
        }),
      );
    }),
    catchError(() => {
      return of(new AppActions.HideLoader());
    }),
  ));


  $bulkDeleteResourceGroups = createEffect(() => this.actions$.pipe(
    ofType(ObjectActions.ProductResourceGroupsActionTypes.BULK_DELETE_PRODUCT_RESOURCE_GROUPS_LOADING),
    switchMap((objectData: ObjectActions.BulkDeleteProductResourceGroupsLoading) => {
      this.store.dispatch(new AppActions.ShowLoader());
      return this.service.bulkDeleteResourceGroups(objectData.ids).pipe(
        switchMap((response: BulkResponseDataInterface) => {
          return of(new ObjectActions.BulkDeleteProductResourceGroupsLoaded(response));
        }),
        catchError(() => {
          return of(new AppActions.HideLoader());
        }),
      );
    }),
    catchError(() => {
      return of(new AppActions.HideLoader());
    }),
  ));
}
