import { IActivityHistoryWorkOrderData } from '../../../view/activity-history/activity-history.model';

export const SelectedGroupButtonOption = {
  ALL_ACTIVITIES: false,
  MISSING_DATA: true,
};

export interface IWorkOrderActivityHistoryModalData {
  id: number;
  completed: number | null | boolean;
  canceled: number | boolean;
  workOrderData?: IActivityHistoryWorkOrderData;
}
