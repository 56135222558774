<progress-bar-metric
  [actualValue]="
    quantityWithCommentMetric.quantityWithCommentCount === null
      ? '0'
      : quantityWithCommentMetric.quantityWithCommentCount.toString()
  "
  [progressBarTitle]="quantityWithCommentTitle"
  [showProgressBar]="false"
  [showExpectedValue]="false"
>
</progress-bar-metric>
