import { Injectable } from '@angular/core';
import { HttpParams } from '@angular/common/http';
import { Store } from '@ngrx/store';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, switchMap } from 'rxjs/operators';
import * as moment from 'moment';
import * as ObjectActions from './kpi-targets.actions';
import * as AppActions from '../../app/actions';
import { KpiTargetsService } from './kpi-targets.service';
import * as oeeAppReducer from '../../oee.reducer';
import { ErrorMessageService } from '../../../shared/service/error-message.service';
import { EditKpiTargetInterface, KpiTargetBulkResponse } from './kpi-targets.model';
import {
  BaseOneResponseInterface,
  GetManyResponseInterface,
} from '../../../shared/model/interface/crud-response-interface.model';
import { mysqlDateFormat } from '../../../shared/helper/date';
import {HelperService} from "../../../shared/service/helper.service";

@Injectable()
export class KpiTargetsEffects {
  constructor(
    private readonly actions$: Actions,
    private readonly service: KpiTargetsService,
    private readonly store: Store<oeeAppReducer.OeeAppState>,
    private readonly errorMessageService: ErrorMessageService,
  ) {}

  getKpiTargets = createEffect(() =>
    this.actions$.pipe(
      ofType(ObjectActions.KPI_TARGETS_DATA_LOADING),
      switchMap((payload: ObjectActions.KpiTargetsDataLoading) => {
        let params = new HttpParams();
        params = params
          .append('join', 'line')
          .append('join', 'line.site')
          .append('page', 1)
          .append('limit', 1000)
          .append('sort', 'id,DESC');

        const filter: any = {};
        let search: any[] = [];

        if (payload.query.search) {
          search = [
            { oeeTarget: { $cont: payload.query.search } },
            { scheduleTarget: { $cont: payload.query.search } },
            { batchSizeTarget: { $cont: payload.query.search } },
            { laborCostTarget: { $cont: payload.query.search } },
          ];
        }

        if (payload.query.siteId !== -1 && payload.query.siteId !== undefined) {
          filter['line.siteId'] = { $in: payload.query.siteId };
        }

        if (payload.query.lineId !== -1 && payload.query.lineId !== undefined) {
          filter['line_id'] = { $in: payload.query.lineId };
        }

        if (
          payload.query.targetPeriod !== null &&
          payload.query.targetPeriod.startDate !== null &&
          payload.query.targetPeriod.endDate !== null
        ) {
          const startDate: string = moment(payload.query.targetPeriod.startDate).startOf('month').format(mysqlDateFormat);
          const endDate: string = moment(payload.query.targetPeriod.endDate).endOf('month').format(mysqlDateFormat);
          filter['target_period'] = { $between: [startDate, endDate] };
        }

        if (payload.query.search) {
          filter.$or = search;
        }

        params = params.append('s', JSON.stringify(filter));

        if (payload.query.orderDesc !== undefined) {
          const direction = payload.query.orderDesc ? 'DESC' : 'ASC';
          params = params.set('sort', `${payload.query.orderBy},${direction}`);
        }

        return this.service.getKpiTargets(params).pipe(
          switchMap((response) => {
            return of(
              new ObjectActions.KpiTargetsDataLoaded(
                payload.query.search || payload.useOtherPage
                  ? response
                  : this.service.generateMissingKpiTargets(
                      response,
                      HelperService.cloneDeep(payload.query.targetPeriod.startDate),
                      HelperService.cloneDeep(payload.query.targetPeriod.endDate),
                      payload.query.lineId,
                      payload.query.siteId,
                    ),
              ),
            );
          }),
          catchError((errorRes) => {
            return of(new ObjectActions.FetchError(errorRes));
          }),
        );
      }),
      catchError((errorRes) => {
        return of(new ObjectActions.FetchError(errorRes));
      }),
    ),
  );

  deleteKpiTargets = createEffect(() =>
    this.actions$.pipe(
      ofType(ObjectActions.DELETE_KPI_TARGETS),
      switchMap((objectData: ObjectActions.DeleteKpiTargets) => {
        this.store.dispatch(new AppActions.ShowLoader());
        return this.service.bulkEditKpiTarget(objectData.payload).pipe(
          switchMap((response: GetManyResponseInterface<KpiTargetBulkResponse>) => {
            this.errorMessageService.getTranslatedErrorMessage(response.data);
            return of(
              new ObjectActions.DeleteKpiTargetsCompleted(objectData.payload.length > 1, response),
              new AppActions.HideLoader(),
            );
          }),
          catchError((error) => {
            return of(new ObjectActions.FetchError(error), new AppActions.HideLoader());
          }),
        );
      }),
      catchError((error) => {
        return of(new ObjectActions.FetchError(error), new AppActions.HideLoader());
      }),
    ),
  );

  editKpiTarget = createEffect(() =>
    this.actions$.pipe(
      ofType(ObjectActions.EDIT_KPI_TARGET),
      switchMap((objectData: ObjectActions.EditKpiTarget) => {
        this.store.dispatch(new AppActions.ShowLoader());

        return this.service.editKpiTarget(objectData.lineId, objectData.kpiTarget).pipe(
          switchMap((response: BaseOneResponseInterface<EditKpiTargetInterface>) => {
            return of(new ObjectActions.EditKpiTargetCompleted(response), new AppActions.HideLoader());
          }),
          catchError((error) => {
            return of(new ObjectActions.FetchError(error), new AppActions.HideLoader());
          }),
        );
      }),
      catchError((error) => {
        return of(new ObjectActions.FetchError(error), new AppActions.HideLoader());
      }),
    ),
  );

  bulkEditKpiTarget = createEffect(() =>
    this.actions$.pipe(
      ofType(ObjectActions.BULK_EDIT_KPI_TARGET),
      switchMap((objectData: ObjectActions.BulkEditKpiTargets) => {
        this.store.dispatch(new AppActions.ShowLoader());

        return this.service.bulkEditKpiTarget(objectData.kpiTargets).pipe(
          switchMap((response: GetManyResponseInterface<KpiTargetBulkResponse>) => {
            return of(new ObjectActions.BulkEditKpiTargetsCompleted(response), new AppActions.HideLoader());
          }),
          catchError((error) => {
            return of(new ObjectActions.FetchError(error), new AppActions.HideLoader());
          }),
        );
      }),
      catchError((error) => {
        return of(new ObjectActions.FetchError(error), new AppActions.HideLoader());
      }),
    ),
  );
}
