import { Injectable } from '@angular/core';
import {
  BaseOneResponseInterface,
  GetManyResponseInterface,
} from '../../shared/model/interface/crud-response-interface.model';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, filter, switchMap, take } from 'rxjs/operators';
import { Action, Store } from '@ngrx/store';
import { combineLatest, from, of } from 'rxjs';
import { HttpParams } from '@angular/common/http';
import * as AppActions from '../app/actions';
import * as oeeAppReducer from '../oee.reducer';
import * as ObjectActions from './actions';
import {
  ICommentsResponse,
  IDistributionResponse,
  IManualCount,
} from '../../view/home/work-order/work-order-manual-count/work-order-manual-count.model';
import { ManualCountService } from './service';
import { BulkResponse, ResponseInterface } from '../../shared/model/interface/generic-api-response.model';
import { HTTPHelperService } from '../../shared/service/http/http.helper.service';
import { PeriodicOeeCalculationReviewService } from '../reports/periodic-oee-calculation-review/periodic-oee-calculation-review.service';
import { IPeriodicOeeCalculationData } from '../reports/periodic-oee-calculation-review/periodic-oee-calculation-review.model';
import { ECommentLogsObjectType, ICommentLogsRawData } from '../reports/comment-logs/comment-logs.model';
import { ELoadStatus } from '../../../constants.model';
import { ECommentTypeCategory, ICommentType, ICommentTypesState } from '../comment-types/comment-types.model';
import { CommentLogsService } from '../reports/comment-logs/comment-logs.service';

@Injectable()
export class ManualCountEffects {
  constructor(
    private readonly actions$: Actions,
    private readonly service: ManualCountService,
    private readonly httpHelperService: HTTPHelperService,
    private readonly periodicOeeCalculationService: PeriodicOeeCalculationReviewService,
    private readonly commentLogsService: CommentLogsService,
    private store: Store<oeeAppReducer.OeeAppState>,
  ) {}

  saveManualCountComments = createEffect(() => this.actions$.pipe(
    ofType(ObjectActions.SAVE_MANUAL_COUNT_COMMENTS),
    switchMap((objectData: ObjectActions.SaveManualCountComments) => {
      return this.service.saveComments(objectData.comments).pipe(
        switchMap((response: GetManyResponseInterface<ICommentsResponse>) => {
          return of(new ObjectActions.ManualCountCommentsSaved(response));
        }),
        catchError((err) => {
          return of(new ObjectActions.FetchDataError(err));
        }),
      );
    }),
    catchError((err) => {
      return of(new ObjectActions.FetchDataError(err));
    }),
  ));

  saveScrapCountComments = createEffect(() =>
    combineLatest([
      this.actions$.pipe(ofType(ObjectActions.SAVE_SCRAP_COUNT_COMMENT)),
      this.store.select('commentTypes').pipe(
        filter((state) => state.commentTypesLoadStatus === ELoadStatus.Success),
        take(1),
      ),
    ]).pipe(
      switchMap(([objectData, commentTypeState]: [ObjectActions.SaveScrapCountComment, ICommentTypesState]) => {
        return this.service
          .saveComments([
            {
              ...objectData.comment,
              commentTypeId: commentTypeState.commentTypes.find(
                (commentType: ICommentType) => commentType.category === ECommentTypeCategory.ScrapCountComment,
              ).id,
            },
          ])
          .pipe(
            switchMap((response: GetManyResponseInterface<ICommentsResponse>) => {
              return of(new ObjectActions.ScrapCountCommentSaved(response));
            }),
            catchError((err) => {
              return of(new ObjectActions.FetchDataError(err));
            }),
          );
      }),
      catchError((err) => {
        return of(new ObjectActions.FetchDataError(err));
      }),
    ),
  );

  setHourlyCount = createEffect(() => this.actions$.pipe(
    ofType(ObjectActions.SET_HOURLY_COUNT),
    switchMap((objectData: ObjectActions.SetHourlyCount) => {
      this.store.dispatch(new AppActions.ShowLoader());

      return from(this.service.setHourlyCount(objectData.manualCount)).pipe(
        switchMap((response: BaseOneResponseInterface<IManualCount>) => {
          return of(
            new ObjectActions.SetHourlyCountCompleted(response.data, objectData.duringOngoingDistribution),
            new AppActions.HideLoader(),
          );
        }),
        catchError((err) => {
          return of(new ObjectActions.SetHourlyCountFailed(err, objectData.duringOngoingDistribution), new AppActions.HideLoader());
        }),
      );
    }),
    catchError((err) => {
      return of(new ObjectActions.SetHourlyCountFailed(err), new AppActions.HideLoader());
    }),
  ));


  setBulkHourlyCount = createEffect(() => this.actions$.pipe(
    ofType(ObjectActions.SET_BULK_HOURLY_COUNT),
    switchMap((objectData: ObjectActions.SetBulkHourlyCount) => {
      this.store.dispatch(new AppActions.ShowLoader());

      return from(this.service.setBulkHourlyCount(objectData.manualCounts)).pipe(
        switchMap((response: ResponseInterface<BulkResponse[]>) => {
          return of(new ObjectActions.SetBulkHourlyCountCompleted(response.data, objectData.duringOngoingDistribution));
        }),
        catchError((err) => {
          return of(new ObjectActions.SetBulkHourlyCountFailed(err, objectData.duringOngoingDistribution));
        }),
      );
    }),
    catchError((err) => {
      return of(new ObjectActions.SetHourlyCountFailed(err));
    }),
  ));


  createManualCount = createEffect(() => this.actions$.pipe(
    ofType(ObjectActions.CREATE_MANUAL_COUNT),
    switchMap((objectData: ObjectActions.CreateManualCount) => {
      this.store.dispatch(new AppActions.ShowLoader());

      return from(this.service.createManualCount(objectData.data)).pipe(
        switchMap((response: BaseOneResponseInterface<IManualCount>) => {
          if (objectData.isCalledFromSetHourlyCount) {
            return of(new ObjectActions.SetHourlyCountCompleted(response.data));
          }

          return of(new ObjectActions.CreateManualCountCompleted(response.data));
        }),
        catchError((err) => {
          if (objectData.isCalledFromSetHourlyCount) {
            return of(new ObjectActions.SetHourlyCountFailed(err));
          }

          return of(new ObjectActions.CreateManualCountFailed(err));
        }),
      );
    }),
    catchError((err) => {
      return of(new ObjectActions.CreateManualCountFailed(err));
    }),
  ));


  getDistribution = createEffect(() =>
    this.actions$.pipe(
      ofType(ObjectActions.MANUAL_COUNT_GET_DISTRIBUTION_LOADING),
      switchMap((objectData: ObjectActions.ManualCountGetDistributionLoading) => {
        this.store.dispatch(new AppActions.ShowLoader());

        let httpParams: HttpParams = new HttpParams()
          .append('goodCount', objectData.params.goodCount)
          .append('scrapCount', objectData.params.scrapCount)
          .append('workOrderId', objectData.params.workOrderId);

        if (objectData.params.incrementDistributionMethod) {
          httpParams = httpParams.append('incrementDistributionMethod', objectData.params.incrementDistributionMethod);
        }

        return from(this.service.getDistribution(httpParams)).pipe(
          switchMap((response: GetManyResponseInterface<IDistributionResponse>) => {
            return of(new ObjectActions.ManualCountGetDistributionLoaded(response.data), new AppActions.HideLoader());
          }),
          catchError((err) => {
            return of(new ObjectActions.FetchDataError(err), new AppActions.HideLoader());
          }),
        );
      }),
      catchError((err) => {
        return of(new ObjectActions.FetchDataError(err), new AppActions.HideLoader());
      }),
    ),
  );

  setDistribution = createEffect(() => this.actions$.pipe(
    ofType(ObjectActions.MANUAL_COUNT_SET_DISTRIBUTION_LOADING),
    switchMap((objectData: ObjectActions.ManualCountSetDistributionLoading) => {
      this.store.dispatch(new AppActions.ShowLoader());
      return from(this.service.setDistribution(objectData.params)).pipe(
        switchMap(() => {
          const actions: Action[] = [
            new ObjectActions.ManualCountSetDistributionLoaded(),
            ...(objectData.hideLoaderAfterSuccess ? [new AppActions.HideLoader()] : []),
          ];
          return of(...actions);
        }),
        catchError((err) => {
          return of(new ObjectActions.FetchDataError(err), new AppActions.HideLoader());
        }),
      );
    }),
    catchError((err) => {
      return of(new ObjectActions.FetchDataError(err), new AppActions.HideLoader());
    }),
  ));

  getOeeForDistributionValidationLoading = createEffect(() =>
    this.actions$.pipe(
      ofType(ObjectActions.GET_OEE_FOR_DISTRIBUTION_VALIDATION_LOADING),
      switchMap((objectData: ObjectActions.GetOeeForDistributionValidationLoading) => {
        this.store.dispatch(new AppActions.ShowLoader());
        const params = this.httpHelperService.insertGenericCrudRequestParameters({
          additionalCustomSearch: [{ workOrderScheduleId: { $eq: objectData.workOrderId } }],
          perPage: 100000,
        });

        return this.periodicOeeCalculationService.getPeriodicOeeCalculationData(params).pipe(
          switchMap((response: GetManyResponseInterface<IPeriodicOeeCalculationData>) => {
            return of(new ObjectActions.GetOeeForDistributionValidationLoaded(response));
          }),
          catchError((err) => {
            return of(new ObjectActions.FetchDataError(err), new AppActions.HideLoader());
          }),
        );
      }),
      catchError((err) => {
        return of(new ObjectActions.FetchDataError(err), new AppActions.HideLoader());
      }),
    ),
  );

  getScrapCountCommentOfProductionCount = createEffect(() =>
    combineLatest([
      this.actions$.pipe(ofType(ObjectActions.GET_SCRAP_COUNT_COMMENT_OF_PRODUCTION_COUNT)),
      this.store.select('commentTypes').pipe(
        filter((state) => state.commentTypesLoadStatus === ELoadStatus.Success),
        take(1),
      ),
    ]).pipe(
      switchMap(
        ([objectData, commentTypeState]: [ObjectActions.GetScrapCountCommentOfProductionCount, ICommentTypesState]) => {
          this.store.dispatch(new AppActions.ShowLoader());
          const params = this.httpHelperService.insertGenericCrudRequestParameters({
            additionalCustomSearch: [
              {
                commentTypeId: commentTypeState.commentTypes.find(
                  (commentType: ICommentType) => commentType.category === ECommentTypeCategory.ScrapCountComment,
                ).id,
              },
              { objectType: { $eq: ECommentLogsObjectType.productionCounts } },
              { objectId: { $eq: objectData.productionCountId } },
            ],
            perPage: 100000,
          });

          return this.commentLogsService.getCommentLogsData(params).pipe(
            switchMap((response: GetManyResponseInterface<ICommentLogsRawData>) => {
              return of(new ObjectActions.GetScrapCountCommentOfProductionCountLoaded(response));
            }),
            catchError((err) => {
              return of(new ObjectActions.FetchDataError(err), new AppActions.HideLoader());
            }),
          );
        },
      ),
      catchError((err) => {
        return of(new ObjectActions.FetchDataError(err), new AppActions.HideLoader());
      }),
    ),
  );

  deleteScrapCountComment = createEffect(() =>
    this.actions$.pipe(
      ofType(ObjectActions.DELETE_SCRAP_COUNT_COMMENT),
      switchMap((objectData: ObjectActions.DeleteScrapCountComment) => {
        this.store.dispatch(new AppActions.ShowLoader());

        return this.commentLogsService.deleteCommentLogsRows(objectData.commentId).pipe(
          switchMap(() => {
            return of(new ObjectActions.DeleteScrapCountCommentDone());
          }),
          catchError((err) => {
            return of(new ObjectActions.FetchDataError(err), new AppActions.HideLoader());
          }),
        );
      }),
      catchError((err) => {
        return of(new ObjectActions.FetchDataError(err), new AppActions.HideLoader());
      }),
    ),
  );

  getHourlyCounts = createEffect(() =>
    this.actions$.pipe(
      ofType(ObjectActions.GET_HOURLY_COUNTS),
      switchMap((objectData: ObjectActions.GetHourlyCounts) => {
        this.store.dispatch(new AppActions.ShowLoader());

        return this.service.getHourlyCounts(objectData.workOrderId, objectData.lineId).pipe(
          switchMap((response) => {
            return of(new ObjectActions.GetHourlyCountsDone(response.data), new AppActions.HideLoader());
          }),
          catchError((err) => {
            return of(new ObjectActions.FetchDataError(err), new AppActions.HideLoader());
          }),
        );
      }),
      catchError((err) => {
        return of(new ObjectActions.FetchDataError(err), new AppActions.HideLoader());
      }),
    ),
  );
}
