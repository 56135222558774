import { EWidgetType, WidgetConfigurationInterface } from '../page-configuration/page-configuration.model';

export type TGeneralConfigurationSection =
  | IGeneralConfigurationWidgetSection
  | IGeneralConfigurationTableHeaderSection
  | IGeneralConfigurationColumnSortOrderSection
  | IGeneralConfigurationTargetExceededHighlightColumnsSection;

export interface IStandardConfigurationProperties {
  name: string;
  type: EConfigurationTypes;
  items: any[];
}

export enum EConfigurationTypes {
  WIDGET = 'widget',
  TABLE = 'table',
  COLUMN_SORT_ORDER = 'columnSortOrder',
  TARGET_EXCEEDED_HIGHLIGHT_COLUMNS = 'targetExceededHighlightColumns',
}

export interface IGeneralConfigurationWidgetSection extends IStandardConfigurationProperties {
  type: EConfigurationTypes.WIDGET;
  title: string;
  orientation: 'vertical' | 'horizontal';
  description?: string;
  selectionLimit?: number;
  items: IWidgetConfiguration[];
}

export interface IWidgetConfiguration extends WidgetConfigurationInterface<EWidgetType.KPI_CARD> {}

export interface IGeneralConfigurationTableHeaderSection extends IStandardConfigurationProperties {
  type: EConfigurationTypes.TABLE;
  title: string;
  availableTitle?: string;
  selectedTitle?: string;
  description?: string;
  isSearchEnabled?: boolean;
  searchPlaceholder?: string;
  searchInputModel?: string;
  items: ITableHeaderGroup[];
  selectedColumnsDescription: string;
  selectedItems: ITableHeaderGroupItem[];
  headerGroupDomIds: string[];
}

export interface ITableHeaderGroup {
  name: string;
  title: string;
  description?: string;
  items: ITableHeaderGroupItem[];
}

export interface ITableHeaderGroupItem {
  name: string;
  title: string;
  groupName: string;
  selected: boolean;
  isSearched?: boolean;
  isRequired?: boolean;
}

export interface IGeneralConfigurationColumnSortOrderSection extends IStandardConfigurationProperties {
  type: EConfigurationTypes.COLUMN_SORT_ORDER;
  title: string;
  description?: string;
  items: IColumnSortOrderItem[];
}

export interface IColumnSortOrderConfiguration {
  name: string;
  items: IColumnSortOrderItem[];
}

export interface IColumnSortOrderItem {
  name: string;
  title: string;
}

export interface IUserConfigurationFromGeneralConfigurationApply {
  headers: ITableHeaderGroupItem[];
  sortOrders: IColumnSortOrderConfiguration[];
  widgets: IWidgetConfiguration[];
  highlightColumns: IDropdownOptions[];
}

export interface IGeneralConfigurationTargetExceededHighlightColumnsSection extends IStandardConfigurationProperties {
  type: EConfigurationTypes.TARGET_EXCEEDED_HIGHLIGHT_COLUMNS;
  title: string;
  description: string;
  items: IDropdownOptions[];
  selectedItems: IDropdownOptions[];
}

export interface IDropdownOptions {
  id: string;
  name: string;
}

export interface IDefaultColumnFields {
  field: string;
  flex: number;
  autoWidth?: number[];
  width?: number;
  text: string;
  cls: string;
  sortable: boolean;
  align: string;
}
