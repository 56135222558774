import { Action } from '@ngrx/store';
import { MaintenancePerformanceQueryParams } from '../../../view/reports/maintenance-performance/maintenance-performance.model';
import { BaseOneResponseInterface } from '../../../shared/model/interface/crud-response-interface.model';
import { IMaintenancePerformanceFilters, MaintenancePerformanceReportInterface } from './maintenance-performance.model';
import { TabRowInterface } from '../../../shared/component/side-config-bar/side-config-bar.model';

export const MAINTENANCE_PERFORMANCE_DATA_LOADING = '[MAINTENANCE_PERFORMANCE] MAINTENANCE PERFORMANCE DATA LOADING';
export const MAINTENANCE_PERFORMANCE_DATA_LOADED = '[MAINTENANCE_PERFORMANCE] MAINTENANCE PERFORMANCE DATA LOADED';
export const DOWNLOAD_MAINTENANCE_PERFORMANCE_EXCEL =
  '[MAINTENANCE_PERFORMANCE] DOWNLOAD MAINTENANCE PERFORMANCE EXCEL';
export const DOWNLOAD_MAINTENANCE_PERFORMANCE_EXCEL_COMPLETED =
  '[MAINTENANCE_PERFORMANCE] DOWNLOAD MAINTENANCE PERFORMANCE EXCEL COMPLETED';
export const SET_TABLE_SETTINGS = '[MAINTENANCE_PERFORMANCE] SET TABLE SETTINGS';
export const SET_SELECTED_FILTERS = '[MAINTENANCE_PERFORMANCE] SET SELECTED FILTERS';
export const FETCH_ERROR = '[MAINTENANCE_PERFORMANCE] FETCH ERROR';

export class MaintenancePerformanceDataLoading implements Action {
  readonly type = MAINTENANCE_PERFORMANCE_DATA_LOADING;

  constructor(public params: MaintenancePerformanceQueryParams) {}
}

export class MaintenancePerformanceDataLoaded implements Action {
  readonly type = MAINTENANCE_PERFORMANCE_DATA_LOADED;

  constructor(public payload: BaseOneResponseInterface<MaintenancePerformanceReportInterface>) {}
}

export class DownloadMaintenancePerformanceExcel implements Action {
  readonly type = DOWNLOAD_MAINTENANCE_PERFORMANCE_EXCEL;

  constructor(public filters: MaintenancePerformanceQueryParams) {}
}

export class DownloadExcelCompleted implements Action {
  readonly type = DOWNLOAD_MAINTENANCE_PERFORMANCE_EXCEL_COMPLETED;
}

export class SetSelectedFilters implements Action {
  readonly type = SET_SELECTED_FILTERS;

  constructor(public filters: IMaintenancePerformanceFilters) {}
}

export class SetTableSettings implements Action {
  readonly type = SET_TABLE_SETTINGS;

  constructor(public payload: TabRowInterface[], public setAsDefault: boolean) {}
}

export class FetchError implements Action {
  readonly type = FETCH_ERROR;

  constructor(public payload: object) {}
}

export type MaintenancePerformanceActions =
  | MaintenancePerformanceDataLoading
  | MaintenancePerformanceDataLoaded
  | DownloadMaintenancePerformanceExcel
  | DownloadExcelCompleted
  | SetSelectedFilters
  | SetTableSettings
  | FetchError;
