import { Inject, Injectable } from '@angular/core';
import {
  IAddLayout,
  IAddLayoutResponse,
  IAddActivityOrderResponseInterface,
  ILayoutCardTemplateDataResponseInterface,
  IHomeMetricSet,
  IHomeMetricSetDataResponseInterface,
  ILayout,
  ILayoutCardTemplateResponse, ILayoutCardTemplateData,
} from './home-page-display-settings.model';
import { Observable } from 'rxjs';
import { HttpClient, HttpParams } from '@angular/common/http';
import {
  BaseCrudResponse,
  BaseOneResponseInterface,
  GetManyResponseInterface,
} from '../../../shared/model/interface/crud-response-interface.model';
import { IAddActivityOrderItemsData } from '../../../view/settings/home-page-display-settings/activity-order-card/activity-order-card.model';

@Injectable({
  providedIn: 'root',
})
export class HomePageDisplaySettingsService {
  private readonly routes = {
    LAYOUTS_URL: `${this.baseUrl}/layouts`,
    KPI_METRICS_URL: `${this.baseUrl}/home-metric-sets`,
    KPI_METRICS_BULK_UPDATE_URL: `${this.baseUrl}/home-metric-sets/bulk/edit`,
    KPI_METRICS_BULK_CREATE_URL: `${this.baseUrl}/home-metric-sets/bulk/create`,
    ACTIVITY_GROUP_ITEMS_URL: `${this.baseUrl}/activity-group-items`,
    LAYOUT_CARD_TEMPLATE_URL: `${this.baseUrl}/layout-card-templates`,
  };

  constructor(
    public readonly http: HttpClient,
    @Inject('API_BASE_URL')
    private readonly baseUrl: string,
  ) {}

  public addLayouts(layout: IAddLayout): Observable<IAddLayoutResponse> {
    return this.http.post<IAddLayoutResponse>(this.routes.LAYOUTS_URL, layout);
  }

  public editLayout(layout: IAddLayout, id: number): Observable<IAddLayoutResponse> {
    return this.http.patch<IAddLayoutResponse>(`${this.routes.LAYOUTS_URL}/${id}`, layout);
  }

  public getLayouts(options: HttpParams): Observable<GetManyResponseInterface<ILayout>> {
    return this.http.get<GetManyResponseInterface<ILayout>>(this.routes.LAYOUTS_URL, {
      params: options,
    });
  }

  public addActivityOrder(
    activityOrders: IAddActivityOrderItemsData[],
  ): Observable<IAddActivityOrderResponseInterface> {
    return this.http.post<IAddActivityOrderResponseInterface>(`${this.routes.ACTIVITY_GROUP_ITEMS_URL}/bulk/create`, {
      activityGroupItems: activityOrders,
    });
  }

  public editActivityOrder(
    activityOrders: IAddActivityOrderItemsData[],
    layoutId: number,
  ): Observable<IAddActivityOrderResponseInterface> {
    return this.http.patch<IAddActivityOrderResponseInterface>(`${this.routes.ACTIVITY_GROUP_ITEMS_URL}/${layoutId}`, {
      layoutId,
      activityGroupItems: activityOrders,
    });
  }

  public addLayoutCardTemplate(
    layoutCardTemplateDto: ILayoutCardTemplateData,
  ): Observable<ILayoutCardTemplateDataResponseInterface> {
    return this.http.post<ILayoutCardTemplateDataResponseInterface>(
      this.routes.LAYOUT_CARD_TEMPLATE_URL,
      layoutCardTemplateDto,
    );
  }

  public editLayoutCardTemplate(
    id: number,
    layoutCardTemplateDto: ILayoutCardTemplateData,
  ): Observable<ILayoutCardTemplateDataResponseInterface> {
    return this.http.patch<ILayoutCardTemplateDataResponseInterface>(
      `${this.routes.LAYOUT_CARD_TEMPLATE_URL}/${id}`,
      layoutCardTemplateDto,
    );
  }

  public deleteLayout(id: number): Observable<BaseCrudResponse> {
    return this.http.delete<BaseCrudResponse>(`${this.routes.LAYOUTS_URL}/${id}`);
  }

  public addHomeMetricSet(homeMetricSet: IHomeMetricSet[]): Observable<IHomeMetricSetDataResponseInterface> {
    return this.http.post<IHomeMetricSetDataResponseInterface>(this.routes.KPI_METRICS_BULK_CREATE_URL, {
      homeMetricSets: homeMetricSet,
    });
  }

  public editHomeMetricSet(
    layoutId: number,
    homeMetricSet: IHomeMetricSet[],
  ): Observable<IHomeMetricSetDataResponseInterface> {
    return this.http.patch<IHomeMetricSetDataResponseInterface>(this.routes.KPI_METRICS_BULK_UPDATE_URL, {
      layoutId,
      homeMetricSets: homeMetricSet,
    });
  }

  public getHomeMetricSet(params: HttpParams): Observable<IHomeMetricSetDataResponseInterface> {
    return this.http.get<IHomeMetricSetDataResponseInterface>(`${this.routes.KPI_METRICS_URL}`, { params });
  }

  public getLayoutCardTemplates(
    params: HttpParams,
  ): Observable<BaseOneResponseInterface<ILayoutCardTemplateResponse[]>> {
    return this.http.get<BaseOneResponseInterface<ILayoutCardTemplateResponse[]>>(
      `${this.routes.LAYOUT_CARD_TEMPLATE_URL}`,
      { params },
    );
  }
}
