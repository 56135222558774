import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AsyncPipe, CommonModule, NgOptimizedImage } from '@angular/common';
import { NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { TranslateModule } from '@ngx-translate/core';
import {
  ENotificationSubscriptionType,
  INotificationFeedItem,
} from 'src/app/shared/service/notification-feed/notification-feed.model';
import { Router } from '@angular/router';
import { TimestampPipe } from '../pipes/timestamp.pipe';

@Component({
  selector: 'app-notification-feed-item',
  standalone: true,
  imports: [CommonModule, NgbTooltipModule, TimestampPipe, TranslateModule, NgOptimizedImage],
  providers: [AsyncPipe],
  templateUrl: './notification-feed-item.component.html',
  styleUrls: ['./notification-feed-item.component.scss'],
})
export class NotificationFeedItemComponent implements OnInit {
  @Input() item: INotificationFeedItem | undefined;
  @Input() timeOnly: boolean = false;
  @Output() setRead: EventEmitter<boolean> = new EventEmitter();

  public iconUrl: string;

  public ngOnInit(): void {
    this.iconUrl = this.getIconUrl(this.item);
  }

  public onNotificationClick(): void {
    if (!this.item) {
      return;
    }

    if (!this.item.readAt) {
      this.setRead.emit(true);
    }

    if (this.item.notification.url) {
      setTimeout(() => {
        window.location.assign(this.item.notification.url);
      }, 1000);
    }
  }

  public onReadButtonClick(event: MouseEvent): void {
    if (!this.item || this.item.readAt) {
      return;
    }

    this.setRead.emit(this.item.readAt === null);
    event.stopPropagation();
  }

  private getIconUrl(item: INotificationFeedItem): string {
    if (item?.notification?.tags) {
      const icons: { [key: string]: string } = {
        ['aihub-logo']: '/assets/images/aihublogo.svg',
        ['action-tracker-logo']: '/assets/images/actiontrackerlogo.svg',
      };

      for (const tag of item.notification.tags) {
        if (icons[tag]) {
          return icons[tag];
        }
      }
    }

    return '/assets/images/digitalfactorylogo.svg';
  }
}
