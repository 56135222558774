import { Action } from '@ngrx/store';
import {
  BulkResponseDataInterface,
  GetManyResponseInterface,
} from '../../../shared/model/interface/crud-response-interface.model';
import { IUnitConversion, IUnitConversionBulk } from './unit-conversion.model';

export const UNIT_CONVERSION_DATA_LOADING = '[UNIT_CONVERSION] UNIT CONVERSION DATA LOADING';
export const UNIT_CONVERSION_DATA_LOADED = '[UNIT_CONVERSION] UNIT CONVERSION DATA LOADED';
export const ADD_UNIT_CONVERSION_LOADING = '[UNIT_CONVERSION] ADD UNIT CONVERSION LOADING';
export const ADD_UNIT_CONVERSION_LOADED = '[UNIT_CONVERSION] ADD UNIT CONVERSION LOADED';
export const EDIT_UNIT_CONVERSION_LOADING = '[UNIT_CONVERSION] EDIT UNIT CONVERSION LOADING';
export const EDIT_UNIT_CONVERSION_LOADED = '[UNIT_CONVERSION] EDIT UNIT CONVERSION LOADED';
export const FETCH_ERROR = '[UNIT_CONVERSION] FETCH ERROR';

export class UnitConversionDataLoading implements Action {
  readonly type = UNIT_CONVERSION_DATA_LOADING;

  constructor(public id: number, public fromLine: boolean = false) {}
}

export class UnitConversionDataLoaded implements Action {
  readonly type = UNIT_CONVERSION_DATA_LOADED;

  constructor(public data: IUnitConversion[]) {}
}

export class AddUnitConversionLoading implements Action {
  readonly type = ADD_UNIT_CONVERSION_LOADING;

  constructor(public payload: IUnitConversion[]) {}
}

export class AddUnitConversionLoaded implements Action {
  readonly type = ADD_UNIT_CONVERSION_LOADED;

  constructor(public response: BulkResponseDataInterface) {}
}

export class EditUnitConversionLoading implements Action {
  readonly type = EDIT_UNIT_CONVERSION_LOADING;

  constructor(public payload: IUnitConversionBulk[]) {}
}

export class EditUnitConversionLoaded implements Action {
  readonly type = EDIT_UNIT_CONVERSION_LOADED;

  constructor(public response: GetManyResponseInterface<any>) {}
}

export class FetchError implements Action {
  readonly type = FETCH_ERROR;

  constructor(public payload: object) {}
}

export type UnitConversionActions =
  | UnitConversionDataLoading
  | UnitConversionDataLoaded
  | AddUnitConversionLoading
  | AddUnitConversionLoaded
  | EditUnitConversionLoading
  | EditUnitConversionLoaded
  | FetchError;
