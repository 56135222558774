import { OnDestroyDecorator } from '../../../../shared/decorator/on-destroy-decorator';
import { AfterViewInit, Component, ElementRef, HostListener, OnDestroy, ViewChild } from '@angular/core';
import {
  DefaultSelectionValuesInterface,
  FilterCardOptionInterface,
  FloorPlanCRUDInterface,
  LineCRUDInterface,
  SiteCRUDInterface,
} from '../../../../shared/component/filter/filter.class';
import { EFilterDropdownElements } from '../../../../store/filter/filter.model';
import { DropdownComponent } from '../../../../shared/component/filter/dropdown/dropdown.component';
import { FloorPlanLine, Line, Site } from '../../../../shared/component/filter/filterable-objects.class';
import { SqlOperators } from '../../../../shared/component/filter/advanced-filter/advanced-filter.model';
import { ActionsSubject, Store } from '@ngrx/store';
import { OeeAppState } from '../../../../store/oee.reducer';
import { TranslateService } from '@ngx-translate/core';
import { HelperService } from '../../../../shared/service/helper.service';
import { Router } from '@angular/router';
import { ColorService } from '../../../../shared/service/color/color.service';
import { combineLatest, Subject, Subscription } from 'rxjs';
import { ofType } from '@ngrx/effects';
import * as PageHeaderActions from '../../../../store/page-header/page-header.actions';
import { debounceTime, filter, takeUntil } from 'rxjs/operators';
import { User } from '../../../../store/user/model';
import {
  ILineViewData,
  ILineViewState,
  ILineViewStationData,
} from '../../../../store/global-view/site-view/line-view/line-view.model';
import { GetCurrentUserResponseClientInterface } from '../../../../store/app/model';
import { FilterSiteState } from '../../../../store/filter/site/site.reducer';
import { FilterLineState } from '../../../../store/filter/line/line.reducer';
import { FilterFloorPlanState } from '../../../../store/filter/floor-plan/floor-plan.reducer';
import * as LineMapActions from '../../../../store/global-view/site-view//line-view/line-view.actions';
import * as _ from 'lodash';
import { ComponentColors } from '../../../../shared/service/color/color.model';
import {
  IComponentConfiguration,
  IUserConfiguration,
  UserConfigurationStateInterface,
} from '../../../../store/user-configuration/user-configuration.model';
import { SelectSiteAndLine } from '../../../../store/global-view/site-view/site-view.actions';
import { EPages } from '../../../settings/home-page-display-settings/home-page-display-settings.constants';
import {
  EFloorPlanCategoryForId,
  FloorPlanInterface,
  ELinePlanViewTypeEnum,
  ILineViewConfiguration,
} from '../../../../store/settings/departments-lines-stations/floor-plans/floor-plans.model';
import * as UserConfigurationActions from '../../../../store/user-configuration/user-configuration.actions';
import { IHomeMetricSiteLineInfo } from '../../../home/home-metrics/home-metrics.model';
import { ICardCarouselConfiguration } from '../../../../shared/component/card-carousel/card-carousel.model';
import { CardHoverEventInterface } from '../../global-view-info-card/global-view-info-card.component';
import * as HomeActions from '../../../../store/home/home.actions';
import { CardCarouselComponent } from '../../../../shared/component/card-carousel/card-carousel.component';
import { ButtonGroupComponent } from '../../../../shared/component/filter/button-group/button-group.component';
import { HomeStateInterface } from '../../../../store/home/home.model';
import { TargetType, WarningCardInterface } from '../../../../shared/component/warning-card/warning-card.model';
import { environment } from '../../../../../environments/environment';
import { SignalRService } from '../../../../shared/service/signalr/signalr.service';
import { SignalrBroadcastInterface } from '../../../../shared/service/signalr/signalr.model';
import { MessageAction } from '../../../../shared/model/enum/message-action';

@OnDestroyDecorator
@Component({
  selector: 'app-line-view',
  templateUrl: './line-view.component.html',
  styleUrls: ['./line-view.component.scss'],
})
export class LineViewComponent implements AfterViewInit, OnDestroy {
  @ViewChild('pageBody') pageBody: ElementRef;
  @ViewChild(CardCarouselComponent) cardCarouselComponent: CardCarouselComponent;

  public isAdvancedLine: boolean = false;
  public warningCardConfiguration: WarningCardInterface = {
    icon: 'fas fa-exclamation-circle',
    text: `${this.translate.instant('general.subscriptionDoesNotSupportPage')} \n${this.translate.instant(
      'general.pleaseContactUs',
    )}`,
    buttons: [
      {
        text: this.translate.instant('general.bookDemo'),
        class: 'btn btn-primary text-white',
        target: TargetType.Blank,
        href: environment.bookDemoUrl,
      },
      {
        text: this.translate.instant('general.requestInfo'),
        class: 'btn btn-primary text-white',
        target: TargetType.Blank,
        href: environment.requestInfoUrl,
      },
    ],
  };

  private readonly subscriptions: Subscription[] = [];
  private clientInfo: GetCurrentUserResponseClientInterface;
  protected userLocale: string;
  protected userDecimalSeparator: string;
  protected userDecimalScaleLimit: number;
  protected siteDecimalScaleLimit: number;
  public plannedDownTimeActivityColors: ComponentColors;

  protected lineViewState$: ILineViewState;

  private readonly filterDestroySubject: Subject<void> = new Subject<void>();
  private readonly filterCardScss: string = 'col-md-3 m-t-3 m-b-4';
  private hasConfig: boolean = false;
  private isConfigSet: boolean = false;
  private selectedSiteIdForFilter: number;
  private selectedLineIdForFilter: number;
  private selectedFloorPlanIdForFilter: number;
  private filterSiteState$: FilterSiteState;
  private filterLineState$: FilterLineState;
  private filterFloorPlanState$: FilterFloorPlanState;
  private userConfigurationStore$: UserConfigurationStateInterface;
  protected readonly filterCardMainGridWidth: number = 12;
  protected readonly filterCardAdditionalGridWidth: number = 0;
  protected readonly eLinePlanViewTypeEnum = ELinePlanViewTypeEnum;
  public selectedViewTypeForFilter: ELinePlanViewTypeEnum;
  public viewHeight: string = '70vh';
  public filterDefaultDropdownSelectionSubject: Subject<DefaultSelectionValuesInterface> = new Subject();
  public filterDefaultSelectionValues: DefaultSelectionValuesInterface = {};
  public isFilterUpdateCardEnabled: boolean = false;
  public isFilterCardVisible: boolean = false;
  public filterOptions: FilterCardOptionInterface = {
    rows: [
      [
        {
          elementId: EFilterDropdownElements.siteSingleSelectDropdown,
          type: DropdownComponent,
          cls: this.filterCardScss,
          object: Site,
          outputOptions: {
            filterObjectId: 'siteId',
            filterObjectProp: 'id',
            returnFilterObjectAllProp: true,
          },
          options: {
            singleSelection: true,
            isRequired: true,
            badgeShowLimit: 1,
            text: this.translate.instant('filterCard.site.dropdownPlaceHolder'),
            getInitData: true,
          },
        },
        {
          elementId: EFilterDropdownElements.lineSingleSelectDropdown,
          type: DropdownComponent,
          cls: this.filterCardScss,
          object: Line,
          outputOptions: {
            filterObjectId: 'lineId',
            filterObjectProp: 'id',
            returnFilterObjectAllProp: true,
          },
          options: {
            badgeShowLimit: 1,
            text: this.translate.instant('filterCard.line.dropdownPlaceHolder'),
            isRequired: true,
            singleSelection: true,
            getInitData: true,
          },
          dropdownDepends: [
            {
              property: 'siteId',
              parentProperty: 'id',
              parentElementId: EFilterDropdownElements.siteSingleSelectDropdown,
              conditionType: SqlOperators.$in,
            },
          ],
        },
        {
          elementId: EFilterDropdownElements.floorPlanSingleSelectDropdown,
          type: DropdownComponent,
          cls: this.filterCardScss,
          object: FloorPlanLine,
          outputOptions: {
            filterObjectId: 'floorPlanId',
            filterObjectProp: 'id',
            returnFilterObjectAllProp: true,
          },
          options: {
            badgeShowLimit: 1,
            text: this.translate.instant('filter.floorPlan.dropdownPlaceholder'),
            isRequired: true,
            singleSelection: true,
            getInitData: true,
          },
          dropdownDepends: [
            {
              property: 'lineId',
              parentProperty: 'id',
              parentElementId: EFilterDropdownElements.lineSingleSelectDropdown,
              conditionType: SqlOperators.$eq,
            },
          ],
        },
        {
          type: ButtonGroupComponent,
          cls: this.filterCardScss.concat(' button-group-flex'),
          elementId: 'viewType',
          outputOptions: {
            filterObjectId: 'viewType',
            returnFilterObjectAllProp: false,
          },
          options: {
            isRequired: false,
            buttons: [
              {
                text: this.translate.instant('floorPlan.modal.cardView'),
                value: ELinePlanViewTypeEnum.cardView,
              },
              {
                text: this.translate.instant('floorPlan.modal.mapView'),
                value: ELinePlanViewTypeEnum.mapView,
              },
            ],
            value: ELinePlanViewTypeEnum.cardView,
          },
        },
      ],
    ],
  };

  private readonly stationLineUpdateSubject: Subject<boolean> = new Subject<boolean>();

  private allSites: SiteCRUDInterface[] = [];
  private allLines: LineCRUDInterface[] = [];
  private selectedSiteId: number;
  private selectedLineId: number;
  private selectedSiteName: string;
  private selectedLineTitle: string;
  public selectedViewType: ELinePlanViewTypeEnum = ELinePlanViewTypeEnum.cardView;
  protected anyStationUsedInProduction: boolean = false;
  public lineViewData$!: ILineViewData;

  private subscribedLineGroup: number;

  public readonly cardCarouselConfiguration: ICardCarouselConfiguration = {
    orientation: 'vertical',
    scrollDirection: 'upToDown',
    scrollMovementBehavior: 'smooth',
    scrollTransitionBehavior: 'snap',
    scrollEndBehaviour: 'returnToStart',
    scrollPeriod: 5000,
  };
  public static planItemAreaClickedSubject: Subject<string> = new Subject<string>();
  public static siteLineChangeSubject: Subject<boolean> = new Subject<boolean>();
  public static cardHoveredSubject: Subject<CardHoverEventInterface> = new Subject<CardHoverEventInterface>();
  public floorPlanConfiguration: FloorPlanInterface;
  protected readonly FloorPlanCategoryForIdEnum: typeof EFloorPlanCategoryForId = EFloorPlanCategoryForId;

  protected homeMetricSetItemsLoading: boolean = true;
  public homeMetricSiteLineInfo: IHomeMetricSiteLineInfo = null;

  protected noFloorPlan: boolean = false;
  protected noMapFloorPlan: boolean = false;

  constructor(
    private store: Store<OeeAppState>,
    private translate: TranslateService,
    public readonly router: Router,
    private readonly actionsSubject: ActionsSubject,
    private colorService: ColorService,
    private readonly signalRService: SignalRService,
  ) {}

  @HostListener('window:resize')
  public onResize(): void {
    this.calculateViewHeight();
  }

  private calculateViewHeight(): void {
    this.viewHeight = `${window.innerHeight - (this.pageBody.nativeElement.offsetTop + 72) ?? 0}px` || '70vh';
  }

  public ngAfterViewInit(): void {
    this.calculateViewHeight();

    this.subscriptions.push(
      combineLatest([
        this.store.select('user').pipe(filter((value: User): boolean => value.isUserLoaded === true)),
        this.store.select('lineViewStore'),
        this.store.select('userConfigurationStore'),
      ]).subscribe(
        ([userState, lineViewState, userConfigurationState]: [
          User,
          ILineViewState,
          UserConfigurationStateInterface,
        ]): void => {
          if (!lineViewState.isStationDataLoading && lineViewState.isStationDataLoaded && this.isAdvancedLine) {
            this.lineViewData$ = HelperService.cloneDeep(lineViewState.lineViewData);
            this.anyStationUsedInProduction = this.lineViewData$.lineViewStationData.some(
              (station: ILineViewStationData): boolean => station.isUsedInProduction,
            );
            this.store.dispatch(new PageHeaderActions.UpdatePageMode(true));
            this.store.dispatch(new PageHeaderActions.CountdownReset());
          }

          this.userConfigurationStore$ = HelperService.cloneDeep(userConfigurationState);
          this.lineViewState$ = HelperService.cloneDeep(lineViewState);
          this.clientInfo = HelperService.cloneDeep(userState.clientInfo);
          this.userLocale = HelperService.cloneDeep(userState.locale);
          this.userDecimalSeparator = HelperService.cloneDeep(userState.decimalSeparator);
          this.userDecimalScaleLimit = HelperService.cloneDeep(userState.decimalScaleLimit);
        },
      ),
      this.actionsSubject
        .pipe(ofType(PageHeaderActions.UPDATE_FILTER_BAR_VISIBILITY))
        .subscribe((state: PageHeaderActions.UpdateFilterBarVisibility): void => {
          this.isFilterCardVisible = state.isVisible;
        }),
      this.actionsSubject.pipe(ofType(PageHeaderActions.COUNTDOWN_TIMEOUT)).subscribe((): void => {
        this.refreshPage();
      }),
      this.store.select('homeStore').subscribe((state: HomeStateInterface): void => {
        this.homeMetricSetItemsLoading = state.homeInformationLoading || state.homeMetricSetItemsLoading;

        if (!state.homeMetricSetItemsLoading && state.homeMetricSetItemsLoaded) {
          this.calculateViewHeight();
        }
      }),
      LineViewComponent.planItemAreaClickedSubject.subscribe((lineId: string): void => {
        this.cardCarouselComponent.scrollToElementWithId(lineId);
      }),
      this.store.select('siteFilter').subscribe((filterSiteState: FilterSiteState): void => {
        if (!filterSiteState.isLoading && filterSiteState.isLoaded) {
          this.filterSiteState$ = HelperService.cloneDeep(filterSiteState);
          this.allSites = HelperService.cloneDeep(this.filterSiteState$.data);

          if (!this.lineViewState$.isInitialFilterDataLoaded) {
            this.initializeFilterOptions(this.lineViewState$);
          }
        }
      }),
      this.store.select('lineFilter').subscribe((filterLineState: FilterLineState): void => {
        if (!filterLineState.isLoading && filterLineState.isLoaded) {
          this.filterLineState$ = HelperService.cloneDeep(filterLineState);
          this.allLines = HelperService.cloneDeep(this.filterLineState$.data);

          if (!this.lineViewState$.isInitialFilterDataLoaded) {
            this.initializeFilterOptions(this.lineViewState$);
          }
        }
      }),
      this.store.select('floorPlanFilter').subscribe((filterFloorPlanState: FilterFloorPlanState): void => {
        if (!filterFloorPlanState.isLoading && filterFloorPlanState.isLoaded) {
          this.filterFloorPlanState$ = HelperService.cloneDeep(filterFloorPlanState);

          if (!this.lineViewState$.isInitialFilterDataLoaded) {
            this.initializeFilterOptions(this.lineViewState$);
          }
        }
      }),
      this.stationLineUpdateSubject.pipe(debounceTime(1000)).subscribe((value: boolean): void => this.refreshPage()),
    );
  }

  private initializeFilterOptions(lineViewState: ILineViewState): void {
    if (
      this.filterSiteState$?.isLoading ||
      !this.filterSiteState$?.isLoaded ||
      this.filterLineState$?.isLoading ||
      !this.filterLineState$?.isLoaded ||
      this.filterFloorPlanState$?.isLoading ||
      !this.filterFloorPlanState$?.isLoaded
    ) {
      return;
    }

    if (lineViewState.selectedSiteId && lineViewState.selectedLineId) {
      this.initializeFilterFromSiteViewSelection(lineViewState);
    } else {
      this.initializeFilterFromUserConfiguration();
    }

    this.checkPermission();
  }

  private initializeFilterFromSiteViewSelection(lineViewState: ILineViewState): void {
    if (this.filterLineState$.data.length > 0 && this.filterFloorPlanState$.data.length > 0) {
      const floorPlanId: number = _.last(
        this.filterFloorPlanState$.data.filter(
          (floorPlanData: FloorPlanCRUDInterface): boolean =>
            floorPlanData.siteId === lineViewState.selectedSiteId &&
            floorPlanData.lineId === lineViewState.selectedLineId,
        ),
      )?.id;

      if (_.isNil(floorPlanId)) {
        this.store.dispatch(new LineMapActions.InitialFilterDataLoaded());
        this.noFloorPlan = true;
        return;
      }

      const typeFromConfiguration = _.find(this.userConfigurationStore$.userConfigurationData.LineViewGlobalComponent, {
        name: 'setAsDefaultConfig',
      });
      const selectedConfiguration: IComponentConfiguration = _.find(typeFromConfiguration?.children, {
        name: String(lineViewState.selectedLineId),
      });
      const viewType: ELinePlanViewTypeEnum = selectedConfiguration
        ? selectedConfiguration.value === 0
          ? ELinePlanViewTypeEnum.cardView
          : ELinePlanViewTypeEnum.mapView
        : ELinePlanViewTypeEnum.cardView;

      this.filterDefaultSelectionValues[EFilterDropdownElements.siteSingleSelectDropdown] = {
        key: 'id',
        values: [lineViewState.selectedSiteId],
      };
      this.filterDefaultSelectionValues[EFilterDropdownElements.lineSingleSelectDropdown] = {
        key: 'id',
        values: [lineViewState.selectedLineId],
      };
      this.filterDefaultSelectionValues[EFilterDropdownElements.floorPlanSingleSelectDropdown] = {
        key: 'id',
        values: [floorPlanId],
      };
      this.filterDefaultSelectionValues['viewType'] = {
        key: 'viewType',
        values: [viewType],
      };
      this.filterDefaultDropdownSelectionSubject.next(this.filterDefaultSelectionValues);
      this.isConfigSet = true;

      if (!_.isEmpty(this.allSites)) {
        this.getSelectedSiteInfo(lineViewState.selectedSiteId);
      }
    }

    this.store.dispatch(new LineMapActions.InitialFilterDataLoaded());
  }

  private initializeFilterFromUserConfiguration(): void {
    if (
      this.userConfigurationStore$.userConfigurationDataLoading ||
      !this.userConfigurationStore$.userConfigurationDataLoaded
    ) {
      return;
    }

    if (
      this.userConfigurationStore$.userConfigurationData.LineViewGlobalComponent?.some(
        (config: IComponentConfiguration): boolean => config.name === 'filters',
      )
    ) {
      const lineViewFilters = _.find(this.userConfigurationStore$.userConfigurationData.LineViewGlobalComponent, {
        name: 'filters',
      })?.children;

      this.hasConfig = true;
      this.selectedSiteIdForFilter = _.find(lineViewFilters, { name: 'siteId' })?.value;
      this.selectedLineIdForFilter = _.find(lineViewFilters, { name: 'lineId' })?.value;
      this.selectedFloorPlanIdForFilter = _.find(lineViewFilters, { name: 'floorPlanId' })?.value;
      this.selectedViewTypeForFilter = _.find(lineViewFilters, { name: 'viewType' })?.value;
      this.selectedViewType = this.selectedViewTypeForFilter;

      const floorPlanExists: boolean = !_.isNil(
        _.find(this.filterFloorPlanState$.data, { id: this.selectedFloorPlanIdForFilter }),
      );

      if (!floorPlanExists) {
        this.noFloorPlan = true;
      } else if (this.hasConfig && !this.isConfigSet) {
        this.filterDefaultSelectionValues[EFilterDropdownElements.siteSingleSelectDropdown] = {
          key: 'id',
          values: [this.selectedSiteIdForFilter],
        };
        this.filterDefaultSelectionValues[EFilterDropdownElements.lineSingleSelectDropdown] = {
          key: 'id',
          values: [this.selectedLineIdForFilter],
        };
        this.filterDefaultSelectionValues[EFilterDropdownElements.floorPlanSingleSelectDropdown] = {
          key: 'id',
          values: [this.selectedFloorPlanIdForFilter],
        };
        this.filterDefaultSelectionValues['viewType'] = {
          key: 'viewType',
          values: [this.selectedViewTypeForFilter],
        };
        this.filterDefaultDropdownSelectionSubject.next(this.filterDefaultSelectionValues);
        this.isConfigSet = true;

        if (!_.isEmpty(this.allSites)) {
          this.getSelectedSiteInfo(this.selectedSiteIdForFilter);
        }
      }

      this.filterDestroySubject.next();
      this.filterDestroySubject.complete();
      this.store.dispatch(new LineMapActions.InitialFilterDataLoaded());
    } else {
      this.store.dispatch(
        new SelectSiteAndLine(this.lineViewState$.selectedSiteId, [this.lineViewState$.selectedLineId]),
      );
      this.router.navigate(['dashboards/global-view/site-view']).then((): null => null);
    }
  }

  public setSelectedFilters(event, setAsDefault: boolean): void {
    this.noFloorPlan = false;
    this.floorPlanConfiguration = event.floorPlanId[0];
    this.noMapFloorPlan =
      !(this.floorPlanConfiguration.configuration as ILineViewConfiguration).mapViewConfiguration.length &&
      _.isNil(this.floorPlanConfiguration.imagePath);
    this.selectedSiteId = event.siteId[0].id;
    this.selectedSiteName = event.siteId[0].name;
    this.selectedLineId = event.lineId[0].id;
    this.selectedLineTitle = event.lineId[0].title;
    this.selectedViewType = event.viewType;
    this.checkPermission();
    this.store.dispatch(new LineMapActions.SelectSiteAndLine(this.selectedSiteId, this.selectedLineId));
    this.filterChangeToHomeMetrics();

    if (this.isAdvancedLine) {
      this.store.dispatch(new LineMapActions.StationDataLoading(this.selectedSiteId, this.selectedLineId));
      this.getHomeInformation();
    }

    if (setAsDefault) {
      this.setAsDefault();
    }

    if (!_.isEmpty(this.allSites)) {
      this.getSelectedSiteInfo(this.selectedSiteId);
    }

    this.initializeSignalRSubscription();
  }

  public filterChange(event): void {
    if (this.isFilterUpdateCardEnabled) {
      this.setSelectedFilters(event, false);
    }
  }

  public onFilterSetAsDefault(event): void {
    this.setSelectedFilters(event, true);
  }

  public filterEventListener(): void {
    if (!this.isFilterUpdateCardEnabled) {
      this.isFilterUpdateCardEnabled = true;
    }
  }

  public stationCardTrackByFunction(index, data): string {
    return data.id;
  }

  private setAsDefault(): void {
    const userConfigurationDestroySubject: Subject<void> = new Subject<void>();

    this.store
      .select('userConfigurationStore')
      .pipe(takeUntil(userConfigurationDestroySubject))
      .subscribe((userConfigurationState: UserConfigurationStateInterface): void => {
        let newUserConfiguration: IUserConfiguration;

        if (userConfigurationState.userConfigurationData.LineViewGlobalComponent) {
          const existingDefaultConfigurations: IComponentConfiguration[] =
            HelperService.cloneDeep(
              _.find(userConfigurationState.userConfigurationData.LineViewGlobalComponent, {
                name: 'setAsDefaultConfig',
              })?.children,
            ) ?? [];

          const matchedConfiguration: IComponentConfiguration = _.find(existingDefaultConfigurations, {
            name: String(this.selectedLineId),
          });

          if (matchedConfiguration) {
            matchedConfiguration.value = this.selectedViewType;
          } else {
            existingDefaultConfigurations.push({
              name: String(this.selectedLineId),
              value: this.selectedViewType,
            });
          }

          newUserConfiguration = {
            LineViewGlobalComponent: [
              {
                name: 'filters',
                children: [
                  {
                    name: 'siteId',
                    value: this.selectedSiteId,
                  },
                  {
                    name: 'lineId',
                    value: this.selectedLineId,
                  },
                  {
                    name: 'floorPlanId',
                    value: this.floorPlanConfiguration.id,
                  },
                  {
                    name: 'viewType',
                    value: this.selectedViewType,
                  },
                ],
              },
              _.find(userConfigurationState.userConfigurationData.LineViewGlobalComponent, {
                name: 'floorPlanZoomSettings',
              }),
              {
                name: 'setAsDefaultConfig',
                children: existingDefaultConfigurations,
              },
            ],
          };
          newUserConfiguration.LineViewGlobalComponent = newUserConfiguration.LineViewGlobalComponent.filter(
            (configuration: IComponentConfiguration) => !_.isNil(configuration),
          );
        } else {
          newUserConfiguration = {
            LineViewGlobalComponent: [
              {
                name: 'filters',
                children: [
                  {
                    name: 'siteId',
                    value: this.selectedSiteId,
                  },
                  {
                    name: 'lineId',
                    value: this.selectedLineId,
                  },
                  {
                    name: 'floorPlanId',
                    value: this.floorPlanConfiguration.id,
                  },
                  {
                    name: 'viewType',
                    value: this.selectedViewType,
                  },
                ],
              },
              {
                name: 'setAsDefaultConfig',
                children: [
                  {
                    name: String(this.selectedLineId),
                    value: this.selectedViewType,
                  },
                ],
              },
            ],
          };
        }

        this.store.dispatch(new UserConfigurationActions.UpdateUserConfigurationLoading(newUserConfiguration));
        userConfigurationDestroySubject.next();
        userConfigurationDestroySubject.complete();
      });
  }

  private getHomeInformation(): void {
    if (this.selectedLineId) {
      this.store.dispatch(new HomeActions.StartLoadHomeInformation(EPages.HOME, this.selectedLineId));
    }
  }

  private getPlannedDownTimeActivityColors(selectedSite: SiteCRUDInterface, siteId: number): void {
    this.plannedDownTimeActivityColors = this.colorService.pickComponentColors(
      'plannedDownTimeActivityGlobalReviewCard',
      {
        allSites: [selectedSite],
        client: this.clientInfo,
        visibleSiteIds: [siteId],
      },
    );
  }

  private getSelectedSiteInfo(siteId: number): void {
    const selectedSite: SiteCRUDInterface = _.find(
      this.allSites,
      (site: SiteCRUDInterface): boolean => site.id === siteId,
    );
    this.siteDecimalScaleLimit = selectedSite.decimalScaleLimit;
    this.getPlannedDownTimeActivityColors(selectedSite, siteId);
  }

  private filterChangeToHomeMetrics(): void {
    this.homeMetricSiteLineInfo = {
      lineID: this.selectedLineId,
      siteID: this.selectedSiteId,
      lineTitle: this.selectedLineTitle,
      siteName: this.selectedSiteName,
    };

    LineViewComponent.siteLineChangeSubject.next(true);
  }

  private refreshPage(): void {
    this.getHomeInformation();
    this.store.dispatch(new LineMapActions.StationDataLoading(this.selectedSiteId, this.selectedLineId));
    this.store.dispatch(new PageHeaderActions.CountdownPause());
    this.store.dispatch(new PageHeaderActions.CountdownDisable());
  }

  private checkPermission(): void {
    const selectedLine: LineCRUDInterface = this.allLines.find(
      (line: LineCRUDInterface): boolean => line.id === this.selectedLineId,
    );

    this.isAdvancedLine = selectedLine?.isAdvancedLine ?? false;

    if (!this.isAdvancedLine) {
      this.store.dispatch(new PageHeaderActions.CountdownPause());
      this.store.dispatch(new PageHeaderActions.CountdownDisable());
      this.store.dispatch(new PageHeaderActions.UpdatePageMode(false));
    }
  }

  public ngOnDestroy(): void {
    for (const subscription of this.subscriptions) {
      subscription.unsubscribe();
    }

    this.store.dispatch(new LineMapActions.ResetStoreToDefault());
    this.store.dispatch(new PageHeaderActions.ResetStoreToDefault());
  }

  private initializeSignalRSubscription(): void {
    const signalrSubscription = this.signalRService.broadcastMessage.subscribe(
      (broadcastData: SignalrBroadcastInterface) => {
        this.handleSignalRMessages(broadcastData);
      },
    );
    this.subscriptions.push(signalrSubscription);

    this.addToSignalRGroup();
  }

  private async addToSignalRGroup(): Promise<void> {
    if (this.selectedLineId && this.selectedLineId !== this.subscribedLineGroup) {
      try {
        if (
          !this.signalRService.hubConnection.connectionId &&
          // @ts-ignore
          this.signalRService.hubConnection.connectionState !== signalR.HubConnectionState.Connecting
        ) {
          await this.signalRService.startConnection();
        }

        this.subscribedLineGroup = this.selectedLineId;
        this.signalRService.addToGroup('line', this.selectedLineId);
      } catch (error) {
        const retryDelayMs: number = 3000;
        setTimeout(() => this.addToSignalRGroup(), retryDelayMs);
      }
    }
  }

  private handleSignalRMessages(broadcastData: SignalrBroadcastInterface): void {
    if (
      [MessageAction.GET_HOME_INFORMATION, MessageAction.LINE_STATION_UPDATE].includes(broadcastData.message) &&
      broadcastData.additionalData.lineId === this.selectedLineId
    ) {
      this.stationLineUpdateSubject.next(true);
    }
  }
}
