import { Action } from '@ngrx/store';
import { FilterCardComponent } from '../../shared/component/filter/filter-card/filter-card.component';
import { IDefaultFilters, IFilter } from '../../shared/component/filter/advanced-filter/advanced-filter.model';

export const LOAD_DEFAULT_FILTERS = '[ADVANCED_FILTER] LOAD DEFAULT FILTERS';
export const DEFAULT_FILTERS_LOADED = '[ADVANCED_FILTER] DEFAULT FILTERS LOADED';
export const DISTRIBUTE_FILTERS = '[ADVANCED_FILTER] DISTRIBUTE FILTERS';

export class LoadDefaultFilters implements Action {
  public readonly type = LOAD_DEFAULT_FILTERS;

  constructor() {}
}

export class DefaultFiltersLoaded implements Action {
  public readonly type = DEFAULT_FILTERS_LOADED;

  constructor(public payload: IDefaultFilters) {}
}

export class DistributeFilters implements Action {
  public readonly type = DISTRIBUTE_FILTERS;

  constructor(public filters: IFilter[]) {}
}
export type AdvancedFilterActions =
  | LoadDefaultFilters
  | DefaultFiltersLoaded
  | DistributeFilters;
