import { Action } from '@ngrx/store';
import { IHeaderExceptionIndicator, PageHeaderStateInterface } from './page-header.model';
import { IOEEExportLog } from '../../shared/service/excel/excel.helper';

export const UPDATE_PAGE_MODE = '[PAGE HEADER] Update page mode';
export const COUNTDOWN_TIMEOUT = '[PAGE HEADER] Countdown timeout';
export const UPDATE_FILTER_BAR_VISIBILITY = '[PAGE HEADER] Update filter bar visibility';
export const RUN_PRINT_FUNCTIONALITY = '[PAGE HEADER] Run print functionality';
export const CHANGE_PRINT_BUTTON_STATUS = '[PAGE HEADER] Change print button status';
export const UPDATE_PAGE_HEADER_BUTTONS_VISIBILITY = '[PAGE HEADER] Update page header buttons visibility';
export const FETCH_PAGE_DATA = '[WORK_ORDER_SCHEDULE] FETCH PAGE DATA';
export const SET_PAGE_TO_EDIT_OR_VIEW_MODE = '[PAGE HEADER] Set Page To Edit Or View Mode';
export const RESET_STORE_TO_DEFAULT = '[PAGE HEADER] Reset Store To Default';
export const CHANGE_ROOT_CAUSE_ANALYSIS_MODE = '[PAGE HEADER] Change root cause analysis mode';
export const PRINT_COMPLETED = '[PAGE HEADER] Print Completed';
export const COUNTDOWN_RESET = '[PAGE HEADER] Countdown reset';
export const COUNTDOWN_START = '[PAGE HEADER] Countdown start';
export const COUNTDOWN_PAUSE = '[PAGE HEADER] Countdown pause';
export const COUNTDOWN_DISABLE = '[PAGE HEADER] Countdown disable';
export const COUNTDOWN_ENABLE = '[PAGE HEADER] Countdown enable';
export const SET_HEADER_EXCEPTION_INDICATOR = '[PAGE HEADER] Set header exception indicator';
export const UPDATE_ELEMENT_VISIBILITY = '[PAGE HEADER] Update element visibility';

export class UpdatePageMode implements Action {
  readonly type = UPDATE_PAGE_MODE;

  constructor(public isDashboardMode: boolean) {}
}

export class CountdownTimeout implements Action {
  readonly type = COUNTDOWN_TIMEOUT;
}

export class UpdateFilterBarVisibility implements Action {
  readonly type = UPDATE_FILTER_BAR_VISIBILITY;

  constructor(public isVisible: boolean) {}
}

export class RunPrintFunctionality implements Action {
  readonly type = RUN_PRINT_FUNCTIONALITY;

  constructor(public isPrintAllowed: boolean, public logInformation: IOEEExportLog) {}
}

export class PrintCompleted implements Action {
  readonly type = PRINT_COMPLETED;

  constructor(public success: boolean) {}
}

export class ChangePrintButtonStatus implements Action {
  readonly type = CHANGE_PRINT_BUTTON_STATUS;

  constructor(public isPrintButtonDisabled: boolean) {}
}

export class UpdatePageHeaderButtonsVisibility implements Action {
  readonly type = UPDATE_PAGE_HEADER_BUTTONS_VISIBILITY;

  constructor(public isPageHeaderButtonsVisible: Partial<PageHeaderStateInterface>) {}
}

export class FetchPageData implements Action {
  readonly type = FETCH_PAGE_DATA;
}

export class SetPageToEditOrViewMode implements Action {
  readonly type = SET_PAGE_TO_EDIT_OR_VIEW_MODE;

  constructor(public isEditMode: boolean, public isSaveAction: boolean) {}
}

export class ResetStoreToDefault implements Action {
  readonly type = RESET_STORE_TO_DEFAULT;
}

export class ChangeRootCauseAnalysisMode implements Action {
  readonly type = CHANGE_ROOT_CAUSE_ANALYSIS_MODE;

  constructor(public isRootCauseAnalysisComparisonMode: boolean) {}
}

export class CountdownReset implements Action {
  readonly type = COUNTDOWN_RESET;

  constructor(
    public isCountdownWaitingToReset: boolean = true,
    public isCountdownExpectedToStartWithReset: boolean = true,
  ) {}
}

export class CountdownStart implements Action {
  readonly type = COUNTDOWN_START;

  constructor(public isCountdownWaitingToStart: boolean = true) {}
}

export class CountdownPause implements Action {
  readonly type = COUNTDOWN_PAUSE;

  constructor(public isCountdownWaitingToPause: boolean = true) {}
}

export class CountdownDisable implements Action {
  readonly type = COUNTDOWN_DISABLE;

  constructor(public isCountdownWaitingToDisable: boolean = true) {}
}

export class CountdownEnable implements Action {
  readonly type = COUNTDOWN_ENABLE;

  constructor(public isCountdownWaitingToEnable: boolean = true) {}
}

export class SetHeaderExceptionIndicator implements Action {
  readonly type = SET_HEADER_EXCEPTION_INDICATOR;

  constructor(public headerExceptionIndicator: IHeaderExceptionIndicator) {}
}

export class UpdateElementVisibility implements Action {
  readonly type = UPDATE_ELEMENT_VISIBILITY;

  constructor(public isElementVisible: boolean, public isVisibilityButtonDisabled: boolean = false) {}
}

export type PageHeaderActions =
  | UpdatePageMode
  | CountdownTimeout
  | UpdateFilterBarVisibility
  | RunPrintFunctionality
  | ChangePrintButtonStatus
  | ResetStoreToDefault
  | FetchPageData
  | UpdatePageHeaderButtonsVisibility
  | ChangeRootCauseAnalysisMode
  | SetPageToEditOrViewMode
  | CountdownReset
  | CountdownStart
  | CountdownPause
  | CountdownDisable
  | CountdownEnable
  | SetHeaderExceptionIndicator
  | UpdateElementVisibility;
