export interface IScheduleAdherenceKpiCards {
  overdueWorkOrdersJobs: string;
  behindScheduleWorkOrdersOrJobs: string;
  scheduleCompliance: string;
  scheduleAdherence: string;
  scheduleAttainment: string;
  workOrderOrJobOnTimeCompletion: string;
  plannedVsUnplannedStartedWorkOrderComparison: string;
  plannedAndNotStartedWorkOrdersOrJobs: string;
}

export enum EScheduleAdherenceKpiNames {
  OVERDUE_WORK_ORDERS_JOBS = 'overdueWorkOrdersJobs',
  BEHIND_SCHEDULE_WORK_ORDERS_JOBS = 'behindScheduleWorkOrdersOrJobs',
  SCHEDULE_COMPLIANCE = 'scheduleCompliance',
  SCHEDULE_ADHERENCE = 'scheduleAdherence',
  SCHEDULE_ATTAINMENT = 'scheduleAttainment',
  WORK_ORDER_OR_JOB_ON_TIME_COMPLETION = 'workOrderOrJobOnTimeCompletion',
  PLANNED_VS_UNPLANNED = 'plannedVsUnplannedStartedWorkOrderComparison',
  PLANNED_AND_NOT_STARTED = 'plannedAndNotStartedWorkOrdersOrJobs',
}

export type TScheduleAdherenceKpiTooltipMessage = {
  [key in EScheduleAdherenceKpiNames]: string;
}

export type TScheduleAdherenceKpiIcons = {
  [key in EScheduleAdherenceKpiNames]: string;
}

export type TScheduleAdherenceKpiTitle = {
  [key in EScheduleAdherenceKpiNames]: string;
}
