import * as Actions from './global-view.actions';
import { SiteDataInterface } from './global-view.model';

export interface GlobalViewInterface {
  sitesFilterIsLoading: boolean;
  sitesFilterIsLoaded: boolean;
  linesFilterIsLoading: boolean;
  linesFilterIsLoaded: boolean;
  filterDataGenerated: boolean;
  siteFilterDataGenerated: boolean;
  lineFilterDataGenerated: boolean;
  sitesFilter: number[];
  linesFilter: number[];
  selectedSiteFilters: number[];
  selectedLineFilters: number[];
  isSiteDataLoading: boolean;
  isSiteDataLoaded: boolean;
  siteData: SiteDataInterface[];
}

export const globalViewInitialState: GlobalViewInterface = {
  sitesFilterIsLoading: false,
  sitesFilterIsLoaded: false,
  linesFilterIsLoading: false,
  linesFilterIsLoaded: false,
  filterDataGenerated: false,
  siteFilterDataGenerated: false,
  lineFilterDataGenerated: false,
  sitesFilter: [],
  linesFilter: [],
  selectedSiteFilters: [],
  selectedLineFilters: [],
  isSiteDataLoading: false,
  isSiteDataLoaded: false,
  siteData: [],
};

export function globalViewReducer(
  state: GlobalViewInterface = globalViewInitialState,
  action: Actions.GlobalViewActions,
): GlobalViewInterface {
  switch (action.type) {
    case Actions.GLOBAL_VIEW_FILTER_DATA_LOADING:
      return {
        ...state,
        sitesFilterIsLoading: true,
        linesFilterIsLoading: true,
        filterDataGenerated: false,
      };
    case Actions.GLOBAL_VIEW_FILTER_DATA_LOADED:
      return {
        ...state,
        sitesFilter: action.payload.siteIds,
        linesFilter: action.payload.lineIds,
      };
    case Actions.GLOBAL_VIEW_FILTER_DATA_GENERATED:
      return {
        ...state,
        filterDataGenerated: true,
      };
    case Actions.GLOBAL_VIEW_SET_SELECTED_SITE_FILTERS:
      return {
        ...state,
        sitesFilterIsLoading: false,
        sitesFilterIsLoaded: false,
        selectedSiteFilters: action.payload,
        sitesFilter: action.payload,
        siteFilterDataGenerated: true,
      };
    case Actions.GLOBAL_VIEW_SET_SELECTED_LINE_FILTERS:
      return {
        ...state,
        linesFilterIsLoading: false,
        linesFilterIsLoaded: false,
        selectedLineFilters: action.payload,
        linesFilter: action.payload,
        lineFilterDataGenerated: true,
      };
    case Actions.GLOBAL_VIEW_SET_FILTER_SETTINGS:
      return {
        ...state,
        sitesFilter: action.siteIds,
        linesFilter: action.lineIds,
      };
    case Actions.GLOBAL_VIEW_SITE_DATA_LOADING:
      return {
        ...state,
        isSiteDataLoading: true,
        isSiteDataLoaded: false,
      };
    case Actions.GLOBAL_VIEW_SITE_DATA_LOADED:
      return {
        ...state,
        isSiteDataLoading: false,
        isSiteDataLoaded: true,
        siteData: action.payload,
      };
    case Actions.GLOBAL_VIEW_RESET_STORE_TO_INITIAL_STATE:
      return {
        ...globalViewInitialState,
      };
    default:
      return state;
  }
}
