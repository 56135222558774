import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ResponseArrayInterface } from 'src/app/shared/model/interface/generic-api-response.model';
import {
  IProductResourceRequestPayload,
  IResourceForm,
  IResourceItemRequestPayload,
  ResourceGroup,
} from './resources.model';
import { HttpClient, HttpParams } from '@angular/common/http';
import { GenericCrudRequestConstructionParameters } from '../../../../../shared/model/interface/generic-api-request.model';
import {
  BaseOneResponseInterface,
  BulkResponseDataInterface,
  GetManyResponseInterface,
} from '../../../../../shared/model/interface/crud-response-interface.model';
import { HTTPHelperService } from '../../../../../shared/service/http/http.helper.service';
import { TranslateService } from '@ngx-translate/core';
import { DatatableHeaderInterface } from '../../../../../shared/component/datatable/datatable.model';
import { EColumnWidth } from '../../../../../shared/service/datatable/datatable.model';
import { FormHelperService } from '../../../../../shared/service/form/form.helper.service';
import { DecimalHelper } from '../../../../../shared/helper/decimal/decimal-helper';
import { defaultPageSort } from '../../../../../../constants';
import { ProductCRUDInterface } from '../../../../../shared/component/filter/filter.class';
import { IProductResource, ProductInterface } from '../../products.model';

@Injectable({ providedIn: 'root' })
export class ResourcesService {
  public tableQuery: GenericCrudRequestConstructionParameters = {
    page: 1,
    perPage: 10,
    search: {
      searchedFields: ['name'],
      searchText: '',
    },
    sort: [defaultPageSort],
    join: [
      'productVersion||id',
      'productResourceItem',
      'productResourceItem.productResourceGroup',
      'productResourceItem.productResourceGroup.line||title',
    ],
  };

  private readonly baseRoute: string = '/product-resources';
  private readonly routes: {
    productResources: string;
    productResourcesBulkDelete: string;
    productResourcesItemBulkCreate: string;
    productResourcesItemBulkEdit: string;
    productResourcesItemBulkDelete: string;
    products: string;
  } = {
    productResources: `${this.baseUrl}/product-resources`,
    productResourcesBulkDelete: `${this.baseUrl}/product-resources/bulk/delete`,
    productResourcesItemBulkCreate: `${this.baseUrl}/product-resource-items/bulk/create`,
    productResourcesItemBulkEdit: `${this.baseUrl}/product-resource-items/bulk/edit`,
    productResourcesItemBulkDelete: `${this.baseUrl}/product-resource-items/bulk/delete`,
    products: `${this.baseUrl}/products`,
  };

  constructor(
    @Inject('API_BASE_URL') private baseUrl: string,
    @Inject('API_BASE_URL') private readonly api: string,
    private readonly http: HttpClient,
    public httpHelperService: HTTPHelperService,
  ) {}

  public getTableHeaders(translate: TranslateService): DatatableHeaderInterface[] {
    return [
      {
        value: null,
        name: '',
        sortable: false,
        width: EColumnWidth.checkbox,
      },
      {
        value: 'productId',
        name: translate.instant('products.listView.headers.productId'),
      },
      {
        value: 'productDescription',
        name: translate.instant('lineView.tableHeaders.productDescription'),
      },
      {
        value: 'name',
        name: translate.instant('settings.products.productStructure.resource.name'),
      },
      {
        value: 'quantity',
        name: translate.instant('settings.products.productStructure.resource.quantity'),
      },
      {
        value: 'unit',
        name: translate.instant('products.listView.headers.unit'),
        sortable: false,
      },
      {
        value: 'resources',
        name: translate.instant('settings.products.productStructure.resource.resources'),
        sortable: false,
      },
    ];
  }

  public getBaseForm(formHelperService: FormHelperService, decimalHelper: DecimalHelper): IResourceForm {
    return {
      form: {
        name: null,
        quantity: null,
        resource: [],
        hours: null,
      },
      rules: {
        name: [formHelperService.getRequiredFormRule(), formHelperService.getMaxLengthFormRule(255)],
        quantity: [formHelperService.getRequiredFormRule(), decimalHelper.getDecimalNonZeroInputRule()],
        resource: [formHelperService.getRequiredFormRule()],
        hours: [formHelperService.getRequiredFormRule(), decimalHelper.getDecimalNonZeroInputRule()],
      },
    };
  }

  public getResourceGroups(siteId: number): Observable<ResponseArrayInterface<ResourceGroup>> {
    let params: HttpParams = new HttpParams();
    params = params.set('join', 'line||title');
    params = params.set('limit', 500);

    if (siteId) {
      params = params.set('s', JSON.stringify({ siteId: { $eq: siteId } }));
    }

    return this.http.get<ResponseArrayInterface<ResourceGroup>>(`${this.baseUrl}/product-resource-groups`, { params });
  }

  public getManyProductResources(
    serviceParameters: GenericCrudRequestConstructionParameters,
  ): Observable<GetManyResponseInterface<IProductResource>> {
    const params: HttpParams = this.httpHelperService.insertGenericCrudRequestParameters(serviceParameters);

    return this.http.get<GetManyResponseInterface<IProductResource>>(`${this.api}${this.baseRoute}`, {
      params,
    });
  }

  public getProduct(productId: number, params: HttpParams): Observable<BaseOneResponseInterface<ProductInterface>> {
    return this.http.get<BaseOneResponseInterface<ProductInterface>>(`${this.routes.products}/${productId}`, {
      params,
    });
  }

  public addProductResource(
    body: IProductResourceRequestPayload,
  ): Observable<BaseOneResponseInterface<IProductResource>> {
    return this.http.post<BaseOneResponseInterface<IProductResource>>(this.routes.productResources, body);
  }

  public editProductResource(
    productResourcesId: number,
    body: IProductResourceRequestPayload,
  ): Observable<BaseOneResponseInterface<IProductResource>> {
    return this.http.patch<BaseOneResponseInterface<IProductResource>>(
      `${this.routes.productResources}/${productResourcesId}`,
      body,
    );
  }

  public deleteProductResource(productResourcesId: number): Observable<BaseOneResponseInterface<IProductResource>> {
    return this.http.delete<BaseOneResponseInterface<IProductResource>>(
      `${this.routes.productResources}/${productResourcesId}`,
    );
  }

  public deleteProductResources(productResourcesIds: number[]): Observable<BulkResponseDataInterface> {
    return this.http.delete<BulkResponseDataInterface>(this.routes.productResourcesBulkDelete, {
      body: { productResources: productResourcesIds },
    });
  }

  public addProductResourceItems(body: IResourceItemRequestPayload[]): Observable<BulkResponseDataInterface> {
    return this.http.post<BulkResponseDataInterface>(this.routes.productResourcesItemBulkCreate, {
      productResourceItems: body,
    });
  }

  public editProductResourceItems(body: IResourceItemRequestPayload[]): Observable<BulkResponseDataInterface> {
    return this.http.patch<BulkResponseDataInterface>(this.routes.productResourcesItemBulkEdit, {
      productResourceItems: body,
    });
  }

  public deleteProductResourceItems(productResourcesItemIds: number[]): Observable<BulkResponseDataInterface> {
    return this.http.delete<BulkResponseDataInterface>(this.routes.productResourcesItemBulkDelete, {
      body: { productResourceItems: productResourcesItemIds },
    });
  }

  public getProducts(params: HttpParams): Observable<GetManyResponseInterface<ProductCRUDInterface>> {
    return this.http.get<GetManyResponseInterface<ProductCRUDInterface>>(this.routes.products, {
      params,
    });
  }
}
