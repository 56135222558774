import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ImageUploadButtonComponent } from './image-upload-button/image-upload-button.component';
import { CameraModalComponent } from './camera-modal/camera-modal.component';
import { MatButtonModule } from '@angular/material/button';
import { WebcamModule } from 'ngx-webcam';
import { ScwMatButtonModule } from '../scw-mat-ui/scw-mat-button/scw-mat-button.module';
import { ImagePreviewModalComponent } from './image-preview-modal/image-preview-modal.component';
import { TranslateModule } from '@ngx-translate/core';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

@NgModule({
  declarations: [ImageUploadButtonComponent, CameraModalComponent, ImagePreviewModalComponent],
  imports: [
    CommonModule,
    MatButtonModule,
    WebcamModule,
    ScwMatButtonModule,
    TranslateModule,
    NgbModule,
  ],
  exports: [ImageUploadButtonComponent, CameraModalComponent, ImagePreviewModalComponent],
})
export class FileUploadModule {}
