import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import {catchError, switchMap, take, tap} from 'rxjs/operators';
import * as ObjectActions from './root-cause-analysis-elastic.actions';
import {forkJoin, from, Observable, of} from 'rxjs';
import { RootCauseAnalysisElasticService } from './root-cause-analysis-elastic.service';
import {
  ElasticStackChartResponseInterface,
  IBucketInterface,
  IElasticActivityLog,
  IRootCauseAnalysisElasticChartNode,
} from './root-cause-analysis-elastic.model';
import * as AppActions from '../../app/actions';
import { Store } from '@ngrx/store';
import * as oeeAppReducer from '../../oee.reducer';
import { ElasticActivityLogResponseInterface } from './root-cause-analysis-elastic.model';
import { IActivityLog, ILineData } from '../root-cause-analysis/root-cause-analysis.model';
import { ResponseArrayInterface } from '../../../shared/model/interface/generic-api-response.model';
import { ICurrentShift } from '../../../shared/service/filter/service.class';
import { GetManyResponseInterface } from '../../../shared/model/interface/crud-response-interface.model';
import { SiteCRUDInterface } from '../../../shared/component/filter/filter.class';
import { ActivityLogInterface } from '../activity-logs/activity-logs.model';
import { RootCauseAnalysisService } from '../root-cause-analysis/root-cause-analysis.service';
import { User } from '../../user/model';
import { ActivityLogsService } from '../activity-logs/activity-logs.service';

@Injectable()
export class RootCauseAnalysisElasticEffects {
  constructor(
    private readonly actions$: Actions,
    public service: RootCauseAnalysisElasticService,
    public rcaService: RootCauseAnalysisService,
    private readonly store: Store<oeeAppReducer.OeeAppState>,
    public activityLogsService: ActivityLogsService,
  ) {}

  public timezone$: string = '';


  loadRootCauseAnalysisElasticChartActivityData = createEffect(() => this.actions$.pipe(
    ofType(ObjectActions.ROOT_CAUSE_ANALYSIS_ELASTIC_CHART_ACTIVITY_DATA_LOADING),
    switchMap((objectData: ObjectActions.RootCauseAnalysisElasticChartActivityDataLoading) => {
      this.store.dispatch(new AppActions.ShowLoader());
      return from(this.service.getRootCauseAnalysisElasticChartData(objectData.params)).pipe(
        switchMap((response: any) => {
          const formattedActivityChartData: IRootCauseAnalysisElasticChartNode[] = this.service.formatChartData(
            response.data.aggregations.activity, true, false, false);

          return of(
            new ObjectActions.RootCauseAnalysisElasticChartActivityDataLoaded(formattedActivityChartData),
          );
        }),
        catchError((err) => {
          return of(new ObjectActions.RootCauseAnalysisElasticChartActivityDataError(err), new AppActions.HideLoader());
        }),
      );
    }),
    catchError((err) => {
      return of(new ObjectActions.RootCauseAnalysisElasticChartActivityDataError(err), new AppActions.HideLoader());
    }),
  ));

  loadRootCauseAnalysisElasticChartEquipmentData = createEffect(() => this.actions$.pipe(
    ofType(ObjectActions.ROOT_CAUSE_ANALYSIS_ELASTIC_CHART_EQUIPMENT_DATA_LOADING),
    switchMap((objectData: ObjectActions.RootCauseAnalysisElasticChartEquipmentDataLoading) => {
      this.store.dispatch(new AppActions.ShowLoader());
      return from(this.service.getRootCauseAnalysisElasticChartData(objectData.params)).pipe(
        switchMap((response: any) => {
          const formattedEquipmentChartData: IRootCauseAnalysisElasticChartNode[] = this.service.formatChartData(
            response.data.aggregations.equipment, false, true, false);
          return of(
            new ObjectActions.RootCauseAnalysisElasticChartEquipmentDataLoaded(formattedEquipmentChartData),
          );
        }),
        catchError((err) => {
          return of(new ObjectActions.RootCauseAnalysisElasticChartEquipmentDataError(err));
        }),
      );
    }),
    catchError((err) => {
      return of(new ObjectActions.RootCauseAnalysisElasticChartEquipmentDataError(err), new AppActions.HideLoader());
    }),
  ));


  loadRootCauseAnalysisElasticChartTaskData = createEffect(() => this.actions$.pipe(
    ofType(ObjectActions.ROOT_CAUSE_ANALYSIS_ELASTIC_CHART_TASK_DATA_LOADING),
    switchMap((objectData: ObjectActions.RootCauseAnalysisElasticChartTaskDataLoading) => {
      this.store.dispatch(new AppActions.ShowLoader());
      return from(this.service.getRootCauseAnalysisElasticChartData(objectData.params)).pipe(
        switchMap((response: any) => {
          const formattedTaskChartData: IRootCauseAnalysisElasticChartNode[] = this.service.formatChartData(
            response.data.aggregations.task, false, false, true);
          return of(
            new ObjectActions.RootCauseAnalysisElasticChartTaskDataLoaded(formattedTaskChartData),
          );
        }),
        catchError((err) => {
          return of(new ObjectActions.RootCauseAnalysisElasticChartTaskDataError(err),
            new AppActions.HideLoader());
        }),
      );
    }),
    catchError((err) => {
      return of(new ObjectActions.RootCauseAnalysisElasticChartTaskDataError(err), new AppActions.HideLoader());
    }),
  ));


  loadActivityLogElasticData = createEffect(() => this.actions$.pipe(
    ofType(ObjectActions.ROOT_CAUSE_ANALYSIS_ELASTIC_DATA_LOADING),
    switchMap((objectData: ObjectActions.RootCauseAnalysisElasticDataLoading) => {
      this.store.dispatch(new AppActions.ShowLoader());
      return this.service.loadActivityLogElasticData(objectData.params).pipe(
        switchMap((response: ElasticActivityLogResponseInterface) => {
          const formattedActivityHistoryData: IElasticActivityLog[] = this.service.formatActivityHistoryData(
            response.data.hits,
          );
          return of(new ObjectActions.RootCauseAnalysisElasticDataLoaded(formattedActivityHistoryData, {
            count: response.count,
            page: response.page,
            pageCount: response.pageCount,
            total: response.total,
            totalDuration: response.data.aggregations.total_duration.value
          }), objectData.dispatchHideLoader ? new AppActions.HideLoader() : new AppActions.EmptyAction());
        }),
        catchError((error) => {
          return of(new ObjectActions.RootCauseAnalysisElasticDataError(error), new AppActions.HideLoader());
        }),
      );
    }),
  ));


  loadElasticStackChartData = createEffect(() => this.actions$.pipe(
    ofType(ObjectActions.ROOT_CAUSE_ANALYSIS_STACK_CHART_DATA_LOADING),
    switchMap((objectData: ObjectActions.RootCauseAnalysisElasticStackChartDataLoading) => {
      this.store.dispatch(new AppActions.ShowLoader());
      return this.service.loadElasticStackChartData(objectData.params).pipe(
        switchMap((response: ElasticStackChartResponseInterface) => {
          const formattedStackChartData: IBucketInterface[] = this.service.formatStackChartData(
            response.data.aggregations,
            objectData.params.groupBy,
          );
          return of(new ObjectActions.RootCauseAnalysisElasticStackChartDataLoaded(formattedStackChartData),
            objectData.dispatchHideLoader ? new AppActions.HideLoader() : new AppActions.EmptyAction());
        }),
        catchError((error) => {
          return of(new ObjectActions.RootCauseAnalysisElasticDataError(error), new AppActions.HideLoader());
        }),
      );
    }),
  ));


  loadElasticComparisonData = createEffect(() => this.actions$.pipe(
    ofType(ObjectActions.ROOT_CAUSE_ANALYSIS_ELASTIC_COMPARISON_MODE_DATA_LOADING),
    switchMap((objectData: ObjectActions.ComparisonModeChartDataLoading) => {
      this.store.dispatch(new AppActions.ShowLoader());
      return this.service.loadElasticComparisonData(objectData.params).pipe(
        switchMap((response: any) => {
          const formattedStackChartData: any[] =
            this.service.formatComparisonData(response.data.aggregations, objectData.params);

          return of(new ObjectActions.ComparisonModeChartDataLoaded(formattedStackChartData),
            objectData.dispatchHideLoader ?
              new AppActions.HideLoader() : new AppActions.EmptyAction());
        }),
        catchError((error) => {
          return of(new ObjectActions.RootCauseAnalysisElasticDataError(error), new AppActions.HideLoader());
        }),
      );
    }),
  ));


  loadElasticComparisonStackData = createEffect(() => this.actions$.pipe(
    ofType(ObjectActions.ROOT_CAUSE_ANALYSIS_ELASTIC_COMPARISON_MODE_STACK_DATA_LOADING),
    switchMap((objectData: ObjectActions.ComparisonModeStackChartDataLoading) => {
      this.store.dispatch(new AppActions.ShowLoader());
      return this.service.loadElasticStackChartData(objectData.params).pipe(
        switchMap((response: any) => {
          const formattedStackChartData: any[] =
            this.service.formatElasticStackChartData(response.data.aggregations, objectData.params);

          return of(new ObjectActions.ComparisonModeStackChartDataLoaded(formattedStackChartData),
            objectData.dispatchHideLoader ?
              new AppActions.HideLoader() : new AppActions.EmptyAction());
        }),
        catchError((error) => {
          return of(new ObjectActions.RootCauseAnalysisElasticDataError(error), new AppActions.HideLoader());
        }),
      );
    }),
  ));


  loadElasticLineData = createEffect(() => this.actions$.pipe(
    ofType(ObjectActions.ROOT_CAUSE_ANALYSIS_ELASTIC_LINE_DATA_LOADING),
    switchMap((objectData: ObjectActions.RootCauseAnalysisElasticLineDataLoading) => {
      this.store
        .select('user')
        .pipe(take(1))
        .subscribe((user: User) => {
          this.timezone$ = user.timezone;
        });
      const observables: Observable<
        | ResponseArrayInterface<ILineData>
        | ResponseArrayInterface<ICurrentShift>
        | GetManyResponseInterface<SiteCRUDInterface>>[] =
        this.service.getRootCauseAnalysisElasticObservables(objectData.params);

      return forkJoin(
        observables.map(
          (
            o: Observable<
              | ResponseArrayInterface<ILineData>
              | ResponseArrayInterface<ICurrentShift>
              | GetManyResponseInterface<SiteCRUDInterface>>,
          ) =>
            o.pipe(
              tap(() => {
              }),
            ),
        ),
      ).pipe(
        switchMap(
          (
            response: (
              | ResponseArrayInterface<ILineData>
              | ResponseArrayInterface<ICurrentShift>
              | GetManyResponseInterface<SiteCRUDInterface>
              )[],
          ) => {
            let lines: ILineData[] = [];
            let sites: SiteCRUDInterface[] = [];
            let currentShifts: ICurrentShift[] = [];

            response.map((value, index) => {
              switch (index) {
                case 0:
                  currentShifts = value.data as ICurrentShift[];
                  break;
                case 1:
                  lines = value.data as ILineData[];
                  break;
                case 2:
                  sites = value.data as SiteCRUDInterface[];
                  break;
                default:
                  break;
              }
            });

            const formattedLineData: IActivityLog[] = this.rcaService.formatLineData(
              lines,
              currentShifts,
              objectData.params,
              this.timezone$,
            );
            const data: IActivityLog[] = formattedLineData.map((row) => {
              const { preRunPhaseName, postRunPhaseName, runPhaseName } = sites.find((site) => site.id === row.siteId);

              return {
                ...row,
                preRunPhaseName,
                postRunPhaseName,
                runPhaseName,
                phaseName: this.activityLogsService.getPhaseName({
                  preRunPhaseName,
                  postRunPhaseName,
                  runPhaseName,
                  phaseId: row.phaseId,
                } as ActivityLogInterface),
              };
            });

            return of(new ObjectActions.RootCauseAnalysisElasticLineDataLoaded(data));
          },
        ),
        catchError((err) => {
          return of(new ObjectActions.RootCauseAnalysisElasticDataError(err));
        }),
      );
    }),
    catchError((errorRes) => {
      return of(new ObjectActions.RootCauseAnalysisElasticDataError(errorRes));
    }),
  ));
}
