import { Component, OnDestroy, OnInit } from '@angular/core';
import * as PageHeaderActions from '../../../../store/page-header/page-header.actions';
import { Store } from '@ngrx/store';
import * as oeeAppReducer from '../../../../store/oee.reducer';
import { PageHeaderStateInterface } from '../../../../store/page-header/page-header.model';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-dashboard-mode-button',
  templateUrl: './dashboard-mode-button.component.html',
})
export class DashboardModeButtonComponent implements OnInit, OnDestroy {
  public dashboardMode = true;
  private readonly subscriptions: Subscription[] = [];

  constructor(private readonly store: Store<oeeAppReducer.OeeAppState>) {}

  public ngOnInit(): void {
    this.subscriptions.push(
      this.store.select('pageHeaderStore').subscribe((state: PageHeaderStateInterface) => {
        this.dashboardMode = state.isDashboardMode;
      }),
    );
  }

  public changeMode(): void {
    this.store.dispatch(new PageHeaderActions.UpdatePageMode(!this.dashboardMode));
  }

  public ngOnDestroy(): void {
    this.subscriptions.forEach((value: Subscription) => {
      value.unsubscribe();
    });
  }
}
