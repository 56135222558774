import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, switchMap, take } from 'rxjs/operators';
import { HttpClient, HttpErrorResponse, HttpParams } from '@angular/common/http';
import { Store } from '@ngrx/store';
import * as ObjectActions from './shift-summary.actions';
import * as oeeAppReducer from '../oee.reducer';
import { from, of } from 'rxjs';
import { GetShiftService } from '../../shared/service/shift-summary/getShift.service';
import {
  IErpModalDropdownOptionResponse,
  ISummaryOfWorkOrderInParticularShiftResponse,
  SelectedFilters,
  ShiftInformation,
  ShiftInformationInterface,
  ShiftSummaryDataInterface,
  ShiftSummaryResponseInterface,
  ShiftSummaryShiftResponseDataInterface,
  ShiftSummaryStateInterface,
} from './shift-summary.model';
import { GetShiftSummaryService } from '../../shared/service/shift-summary/getShiftSummary.service';
import { PageFiltersAndSettingsService } from '../../shared/service/page-filters-and-settings/page-filters-and-settings.service';

import * as _ from 'lodash';
import {
  BaseOneResponseInterface,
  GetManyResponseInterface,
} from '../../shared/model/interface/crud-response-interface.model';
import { IUserConfiguration, IUserConfigurationResponse } from '../user-configuration/user-configuration.model';
import { ResponseInterface } from '../../shared/model/interface/generic-api-response.model';
import { EntityTranslatorService } from '../../shared/service/entity-translator/entity-translator.service';
import { HelperService } from '../../shared/service/helper.service';
import { ETableType } from '../../view/shift-summary/shift-summary.model';

@Injectable()
export class ShiftSummaryEffects {
  constructor(
    private readonly actions$: Actions,
    private readonly http: HttpClient,
    private readonly store: Store<oeeAppReducer.OeeAppState>,
    private readonly getShiftService: GetShiftService,
    private readonly getShiftSummaryService: GetShiftSummaryService,
    private readonly pageFiltersAndSettings: PageFiltersAndSettingsService,
    private readonly entityTranslation: EntityTranslatorService,
  ) {}

  fetchShiftSummaryData = createEffect(() =>
    this.actions$.pipe(
      ofType(ObjectActions.ShiftSummaryActionTypes.StartShiftSummaryDataLoading),
      switchMap((objectData: ObjectActions.StartShiftSummaryDataLoading) => {
        return from(this.getShiftService.getShifts(HelperService.cloneDeep(objectData.payload))).pipe(
          switchMap((response: ShiftSummaryShiftResponseDataInterface) => {
            return of(new ObjectActions.ShiftSummaryDataLoaded(response));
          }),
          catchError((err: HttpErrorResponse) => {
            return of(new ObjectActions.FetchError(err));
          }),
        );
      }),
      catchError((err: HttpErrorResponse) => {
        return of(new ObjectActions.FetchError(err));
      }),
    ),
  );

  getShiftSummaryDataAndTaskGroups = createEffect(() =>
    this.actions$.pipe(
      ofType(ObjectActions.ShiftSummaryActionTypes.GetShiftDataAndGroups),
      switchMap((objectData: ObjectActions.GetShiftDataAndGroups) => {
        let param = new HttpParams()
          .set('siteId', encodeURIComponent(String(objectData.siteId)))
          .set('tableType', encodeURIComponent(objectData.tableType))
          .set('lineIds', objectData.lineIds.join(','))
          .set('shiftInformation', encodeURIComponent(String(objectData.shiftInformation.shiftInformation)));

        if (objectData.shiftInformation.shiftInformation === ShiftInformation.other) {
          param = param.set('shiftDate', encodeURIComponent(objectData.shiftInformation.shiftDate));
          param = param.set('shiftId', encodeURIComponent(String(objectData.shiftInformation.shiftId)));
        }

        return from(this.getShiftSummaryService.getShiftSummaryAndTaskGroups(param)).pipe(
          switchMap((response: ShiftSummaryResponseInterface) => {
            response.data.forEach((shiftSummaryData: ShiftSummaryDataInterface) => {
              this.entityTranslation.translate(shiftSummaryData);
            });

            return of(new ObjectActions.GetShiftDataAndGroupsLoaded(response));
          }),
          catchError((err: HttpErrorResponse) => {
            return of(new ObjectActions.FetchError(err));
          }),
        );
      }),
      catchError((err: HttpErrorResponse) => {
        return of(new ObjectActions.FetchError(err));
      }),
    ),
  );

  startFiltersSaving = createEffect(() =>
    this.actions$.pipe(
      ofType(ObjectActions.ShiftSummaryActionTypes.StartFiltersSaving),
      switchMap((objectData: ObjectActions.StartFiltersSaving) => {
        const siteIds: number[] = objectData.payload.site;
        const lineIds: number[] = objectData.payload.lines;
        const tableType: ETableType = objectData.payload.tableType;

        const configuration: IUserConfiguration = {
          ShiftSummaryComponentFilters: [
            {
              name: 'filters',
              children: [
                {
                  name: 'siteIds',
                  value: siteIds,
                },
                {
                  name: 'lineIds',
                  value: lineIds,
                },
                {
                  name: 'tableType',
                  value: tableType,
                },
              ],
            },
          ],
        };

        return this.pageFiltersAndSettings.updateUserConfigurationData(configuration).pipe(
          switchMap((response: ResponseInterface<IUserConfigurationResponse>) => {
            return of(new ObjectActions.FiltersSaved(response.data.configuration));
          }),
          catchError((err) => {
            return of(new ObjectActions.FetchError(err));
          }),
        );
      }),
      catchError((err: HttpErrorResponse) => {
        return of(new ObjectActions.FetchError(err));
      }),
    ),
  );

  startShiftSummaryCommentSave = createEffect(() =>
    this.actions$.pipe(
      ofType(ObjectActions.ShiftSummaryActionTypes.StartShiftSummaryCommentSave),
      switchMap((objectData: ObjectActions.StartShiftSummaryCommentSave) => {
        return from(this.getShiftSummaryService.saveComments(objectData.comments)).pipe(
          switchMap(() => {
            const returnActions = [];
            returnActions.push(new ObjectActions.StartShiftSummaryCommentSaved());

            let selectedFilters: SelectedFilters;

            this.store
              .select('shiftSummaryStore')
              .pipe(take(1))
              .subscribe((shiftSummaryState: ShiftSummaryStateInterface) => {
                selectedFilters = shiftSummaryState.selectedFilters;
              });

            const site: string[] | number[] = _.get(selectedFilters, 'filters.site', null);
            const lines: number[] = _.get(selectedFilters, 'filters.lineIds', null);
            const shiftInformation: ShiftInformationInterface = _.get(selectedFilters, 'shiftInformation', null);

            if (site !== null && lines !== null && objectData.tableType !== null && shiftInformation !== null) {
              returnActions.push(
                new ObjectActions.GetShiftDataAndGroups(
                  _.nth(site, 0),
                  lines,
                  objectData.tableType,
                  shiftInformation,
                ),
              );
            }

            return of(...returnActions);
          }),
          catchError((err: HttpErrorResponse) => {
            return of(new ObjectActions.FetchError(err));
          }),
        );
      }),
      catchError((err: HttpErrorResponse) => {
        return of(new ObjectActions.FetchError(err));
      }),
    ),
  );

  setFiltersAndSettingsLoading = createEffect(() =>
    this.actions$.pipe(
      ofType(ObjectActions.ShiftSummaryActionTypes.SetSelectedFilters),
      switchMap(() => {
        return of(new ObjectActions.SetSelectedFiltersLoaded());
      }),
      catchError((err: HttpErrorResponse) => {
        return of(new ObjectActions.FetchError(err));
      }),
    ),
  );

  fetchErpModalData = createEffect(() =>
    this.actions$.pipe(
      ofType(ObjectActions.ShiftSummaryActionTypes.GetErpModalDataLoading),
      switchMap((objectData: ObjectActions.GetErpModalDataLoading) => {
        return this.getShiftSummaryService.getErpModalData(objectData.erpModalDataFetchParams).pipe(
          switchMap((response: BaseOneResponseInterface<ISummaryOfWorkOrderInParticularShiftResponse>) => {
            return of(new ObjectActions.GetErpModalDataLoaded(response.data));
          }),
          catchError((err: HttpErrorResponse) => {
            return of(new ObjectActions.FetchError(err));
          }),
        );
      }),
      catchError((err: HttpErrorResponse) => {
        return of(new ObjectActions.FetchError(err));
      }),
    ),
  );

  getErpModalDropdownOptions = createEffect(() =>
    this.actions$.pipe(
      ofType(ObjectActions.ShiftSummaryActionTypes.GetErpModalDropdownOptionsLoading),
      switchMap((objectData: ObjectActions.GetErpModalDropdownOptionsLoading) => {
        return this.getShiftSummaryService.getErpModalDropdownOptions(objectData.workOrderId).pipe(
          switchMap((response: GetManyResponseInterface<IErpModalDropdownOptionResponse>) => {
            return of(new ObjectActions.GetErpModalDropdownOptionsLoaded(response.data));
          }),
          catchError((err: HttpErrorResponse) => {
            return of(new ObjectActions.FetchError(err));
          }),
        );
      }),
      catchError((err: HttpErrorResponse) => {
        return of(new ObjectActions.FetchError(err));
      }),
    ),
  );

  createShiftSummaryErpIntegration = createEffect(() =>
    this.actions$.pipe(
      ofType(ObjectActions.ShiftSummaryActionTypes.CreateShiftSummaryErpIntegrationLoading),
      switchMap((objectData: ObjectActions.CreateShiftSummaryErpIntegrationLoading) => {
        return this.getShiftSummaryService.createShiftSummaryErpIntegration(objectData.dto).pipe(
          switchMap(() => {
            return of(new ObjectActions.CreateShiftSummaryErpIntegrationLoaded());
          }),
          catchError((err: HttpErrorResponse) => {
            return of(new ObjectActions.FetchError(err));
          }),
        );
      }),
      catchError((err: HttpErrorResponse) => {
        return of(new ObjectActions.FetchError(err));
      }),
    ),
  );

  updateShiftSummaryErpIntegration = createEffect(() =>
    this.actions$.pipe(
      ofType(ObjectActions.ShiftSummaryActionTypes.UpdateShiftSummaryErpIntegrationLoading),
      switchMap((objectData: ObjectActions.UpdateShiftSummaryErpIntegrationLoading) => {
        return this.getShiftSummaryService.updateShiftSummaryErpIntegration(objectData.id, objectData.dto).pipe(
          switchMap(() => {
            return of(new ObjectActions.UpdateShiftSummaryErpIntegrationLoaded());
          }),
          catchError((err: HttpErrorResponse) => {
            return of(new ObjectActions.FetchError(err));
          }),
        );
      }),
      catchError((err: HttpErrorResponse) => {
        return of(new ObjectActions.FetchError(err));
      }),
    ),
  );
}
