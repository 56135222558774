import { Inject, Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import {
  BaseCrudResponse,
  BaseOneResponseInterface,
  BulkResponseDataInterface,
  GetManyResponseInterface,
} from '../../model/interface/crud-response-interface.model';
import { ILevelPermissions, IRole } from './roles.model';
import { UserSettingsService } from '../../../store/settings/users/user.service';

@Injectable({
  providedIn: 'root',
})
export class RolesService {
  private readonly rolesBaseUrl = `${this.baseUrl}/roles`;

  constructor(
    private readonly http: HttpClient,
    @Inject('API_BASE_URL') private readonly baseUrl: string,
    private readonly userSettingsService: UserSettingsService,
  ) {}

  public getRoles(params: HttpParams): Observable<GetManyResponseInterface<IRole>> {
    return this.http.get<GetManyResponseInterface<IRole>>(this.rolesBaseUrl, { params });
  }

  public createRole(payload: Partial<IRole>) {
    return this.http.post<BaseOneResponseInterface<IRole>>(`${this.rolesBaseUrl}`, payload);
  }

  public updateRole(id: number, payload: Partial<IRole>) {
    return this.http.patch<BaseOneResponseInterface<IRole>>(`${this.rolesBaseUrl}/${id}`, payload);
  }

  public deleteRole(id: number) {
    return this.http.delete<BaseCrudResponse>(`${this.rolesBaseUrl}/${id}`);
  }

  public deleteManyRole(ids: number[]) {
    return this.http.delete<BulkResponseDataInterface>(`${this.rolesBaseUrl}/bulk/delete`, {
      body: { levelIds: ids },
      headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
    });
  }

  public getRolePermissions(id: number): Observable<BaseOneResponseInterface<ILevelPermissions>> {
    return this.http.get<BaseOneResponseInterface<ILevelPermissions>>(`${this.rolesBaseUrl}/${id}/permissions`);
  }
}
