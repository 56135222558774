import { Inject, Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import {
  BaseOneResponseInterface,
  GetManyResponseInterface,
} from '../../../shared/model/interface/crud-response-interface.model';
import { IWoOeeWithinIntervalResponse } from '../../../standalone/wo-oee-within-interval-datatable/wo-oee-within-interval-datatable.model';
import {
  IGroupByWeekResponse,
  IGroupByWeekTableResult,
  IWoAdherenceOeeWithinIntervalResponse,
} from './oee-adherence.model';
import * as AppActions from '../../app/actions';
import { Store } from '@ngrx/store';
import * as oeeAppReducer from '../../oee.reducer';
import { TranslateService } from '@ngx-translate/core';
import { DatatableHeaderInterface } from '../../../shared/component/datatable/datatable.model';
import {
  ICreateExcel,
  ICreateExcelSheet,
  IExcelColumnDefinition,
  IExcelDateFormatInformation,
} from '../../../shared/service/excel/excel.helper';
import { ECellTypes, EExcelColumnWidth, EExcelSheetType } from '../../../shared/service/excel/excel.enum';
import { ValueType } from 'exceljs';
import _ from 'lodash';
import * as Actions from '../oee-adherence/oee-adherence.actions';
import { filter, take } from 'rxjs/operators';
import { excelDateFormat, excelTimeFormat } from '../../../shared/model/enum/excel-date-format';
import { DECIMAL_DEFAULT_SCALE_LIMIT } from '../../../../constants';
import { DecimalHelper } from '../../../shared/helper/decimal/decimal-helper';
import { ExcelHelperService } from '../../../shared/service/excel/excel.helper.service';
import * as moment from 'moment';
import { User } from '../../user/model';
import { MomentDatePipe } from '../../../standalone/pipes/moment-date.pipe';

@Injectable({
  providedIn: 'root',
})
export class OeeAdherenceService {
  public decimalScale$: number = DECIMAL_DEFAULT_SCALE_LIMIT;
  public timezone: string;
  private readonly routes = {
    OEE_ADHERENCE_REPORT: 'lines/oee-adherence',
  };
  private dateFormatInformation: IExcelDateFormatInformation;

  constructor(
    @Inject('API_BASE_URL') private readonly apiUrl: string,
    private readonly http: HttpClient,
    private readonly store: Store<oeeAppReducer.OeeAppState>,
    private readonly translate: TranslateService,
    private readonly excelHelper: ExcelHelperService,
    private readonly decimalHelper: DecimalHelper,
    private readonly momentDatePipe: MomentDatePipe,
  ) {
    this.store
      .select('user')
      .pipe(
        filter((state: User) => !state.isUserLoading && state.isUserLoaded),
        take(1),
      )
      .subscribe((state: User) => {
        let dateFormat$: string;
        let timeFormat$: string;

        if (state.locale !== '') {
          dateFormat$ = excelDateFormat[state.locale];
          timeFormat$ = excelTimeFormat[state.locale];
        }

        this.decimalScale$ = state.decimalScaleLimit;
        this.dateFormatInformation = {
          timezone: state.timezone,
          dateFormat$,
          timeFormat$,
          locale$: state.locale,
          dateFormatRaw$: state.dateFormat,
          dateTimeFormatRaw$: state.dateTimeFormat,
        };
      });
  }

  public getOeeAdherenceWeekData(params: HttpParams): Observable<BaseOneResponseInterface<IGroupByWeekResponse>> {
    return this.http.get<BaseOneResponseInterface<IGroupByWeekResponse>>(
      `${this.apiUrl}/${this.routes.OEE_ADHERENCE_REPORT}`,
      {
        params,
      },
    );
  }

  public getOeeAdherenceWoData(params: HttpParams): Observable<GetManyResponseInterface<IWoOeeWithinIntervalResponse>> {
    return this.http.get<GetManyResponseInterface<IWoOeeWithinIntervalResponse>>(
      `${this.apiUrl}/${this.routes.OEE_ADHERENCE_REPORT}`,
      {
        params,
      },
    );
  }

  public handleDecimalNumbers(decimalNumber: string, scale: number = DECIMAL_DEFAULT_SCALE_LIMIT): string {
    return this.decimalHelper.toFixedValue(decimalNumber ?? '0', scale);
  }

  public downloadExcel(
    siteId: number,
    data: IGroupByWeekTableResult[] | IWoAdherenceOeeWithinIntervalResponse[],
    headers: DatatableHeaderInterface[],
    isWoData?: boolean,
  ): void {
    this.store.dispatch(new AppActions.ShowLoader());
    const oeeAdherence: string = this.translate.instant('pageTitles.oee_adherence');
    const week: string = this.translate.instant('oeeAdherence.dropdownOptions.week');
    const workOrder: string = this.translate.instant('general.workOrder');
    const dateFormat: string = 'DD_MM_YYYY';
    const excelOptions: ICreateExcel = { data, columns: this.getExcelColumnsFromTableHeaders(headers) };
    const sheetTitleName: string = `${oeeAdherence} ${isWoData ? workOrder : week}`;
    const title: string = `${sheetTitleName} ${moment().tz(this.dateFormatInformation.timezone).format(dateFormat)}`;
    const worksheet: ICreateExcelSheet[] = [
      {
        sheetTitle: title,
        withData: true,
        sheetType: EExcelSheetType.TABLE,
        params: excelOptions,
        isDisabledColumnsFirstLine: true,
        addDateTimeFormula: undefined,
      },
    ];

    this.excelHelper
      .createExcel(
        title,
        { siteId, name: 'oeeAdherence', type: isWoData ? 'workOrder' : 'week', withData: true },
        worksheet,
        this.dateFormatInformation.timezone,
        this.dateFormatInformation.dateFormat$,
        this.dateFormatInformation.timeFormat$,
        false,
      )
      .then(
        () => {
          this.store.dispatch(new Actions.OeeAdherenceWoExcelLoaded());
          this.store.dispatch(new AppActions.HideLoader());
        },
        () => {
          this.store.dispatch(new Actions.WeekDataFetchError({}));
          this.store.dispatch(new Actions.WoDataFetchError({}));
          this.store.dispatch(new AppActions.HideLoader());
        },
      );
  }

  public formatOeeAdherenceWeekData(weeklyResult: IGroupByWeekTableResult): IGroupByWeekTableResult {
    const userScaleLimit: number = this.decimalScale$ ?? DECIMAL_DEFAULT_SCALE_LIMIT;

    const minuteToHourDivider: string = '60';
    const apqPercentage: string = '100';

    return {
      ...weeklyResult,
      targetRun: this.handleDecimalNumbers(
        weeklyResult.targetRun,
        userScaleLimit,
      ),
      totalGoodCount: this.handleDecimalNumbers(
        this.decimalHelper.convertToDecimal(weeklyResult.totalGoodCount),
        DECIMAL_DEFAULT_SCALE_LIMIT,
      ),
      totalScrapCount: this.handleDecimalNumbers(
        this.decimalHelper.convertToDecimal(weeklyResult.totalScrapCount),
        DECIMAL_DEFAULT_SCALE_LIMIT,
      ),
      totalInitialCount: this.handleDecimalNumbers(
        this.decimalHelper.convertToDecimal(weeklyResult.totalInitialCount),
        DECIMAL_DEFAULT_SCALE_LIMIT,
      ),
      staffedTime: this.handleDecimalNumbers(
        this.decimalHelper.divide(weeklyResult.staffedTime?.toString() ?? '0', minuteToHourDivider),
        userScaleLimit,
      ),
      targetOperation: this.handleDecimalNumbers(weeklyResult.targetOperation, userScaleLimit),
      scheduledPreRunDuration: this.handleDecimalNumbers(weeklyResult.scheduledPreRunDuration, userScaleLimit),
      scheduledPostRunDuration: this.handleDecimalNumbers(weeklyResult.scheduledPostRunDuration, userScaleLimit),
      scheduledRunDuration: this.handleDecimalNumbers(weeklyResult.scheduledRunDuration, userScaleLimit),
      potentialQuantityBasedOnProductSpeedxActualRunTime: this.handleDecimalNumbers(
        this.decimalHelper.convertToDecimal(weeklyResult.potentialQuantityBasedOnProductSpeedxActualRunTime),
        userScaleLimit,
      ),
      totalGoodCountEffectiveDuration: this.handleDecimalNumbers(
        this.decimalHelper.divide(weeklyResult.totalGoodCountEffectiveDuration, minuteToHourDivider),
        userScaleLimit,
      ),
      totalInitialCountEffectiveDuration: this.handleDecimalNumbers(
        this.decimalHelper.divide(weeklyResult.totalInitialCountEffectiveDuration, minuteToHourDivider),
        userScaleLimit,
      ),
      availabilityPerInterval: this.handleDecimalNumbers(
        this.decimalHelper.multiply(weeklyResult.availabilityPerInterval.toString() ?? '0', apqPercentage),
        DECIMAL_DEFAULT_SCALE_LIMIT,
      ),
      qualityPerInterval: this.handleDecimalNumbers(
        this.decimalHelper.multiply(weeklyResult.qualityPerInterval?.toString() ?? '0', apqPercentage),
        DECIMAL_DEFAULT_SCALE_LIMIT,
      ),
      performancePerInterval: this.handleDecimalNumbers(
        this.decimalHelper.multiply(weeklyResult.performancePerInterval?.toString() ?? '0', apqPercentage),
        DECIMAL_DEFAULT_SCALE_LIMIT,
      ),
      overallOEE: this.handleDecimalNumbers(
        this.decimalHelper.multiply(weeklyResult.overallOEE?.toString() ?? '0', apqPercentage),
        DECIMAL_DEFAULT_SCALE_LIMIT,
      ),
      expectedOEE: this.handleDecimalNumbers(
        this.decimalHelper.multiply(
          this.decimalHelper.convertToDecimal(weeklyResult.expectedOEE?.toString() ?? '0'),
          apqPercentage,
        ),
        DECIMAL_DEFAULT_SCALE_LIMIT,
      ),
      totalRunTimeDuration: this.handleDecimalNumbers(
        this.decimalHelper.divide(weeklyResult.totalRunTimeDuration?.toString() ?? '0', minuteToHourDivider),
        DECIMAL_DEFAULT_SCALE_LIMIT,
      ),
      plannedDownTimeDuration: this.handleDecimalNumbers(
        this.decimalHelper.divide(
          this.decimalHelper.subtract(
            weeklyResult.totalDownTimeDuration,
            this.decimalHelper.divide(weeklyResult.unplannedDownTimeDuration?.toString() ?? '0', minuteToHourDivider),
          ),
          minuteToHourDivider,
        ),

        DECIMAL_DEFAULT_SCALE_LIMIT,
      ),
      comparisonOEE: this.handleDecimalNumbers(
        this.decimalHelper.multiply(
          this.decimalHelper.divide(
            this.decimalHelper.convertToDecimal(weeklyResult.overallOEE),
            this.decimalHelper.convertToDecimal(weeklyResult.expectedOEE),
          ),
          apqPercentage,
        ),
        DECIMAL_DEFAULT_SCALE_LIMIT,
      ),
      rawData: {
        expectedOEE: this.decimalHelper.multiply(weeklyResult.expectedOEE?.toString() ?? '0', apqPercentage),
        overallOEE: this.decimalHelper.multiply(weeklyResult.overallOEE?.toString() ?? '0', apqPercentage),
        totalDownTimeDuration: this.decimalHelper.divide(
          weeklyResult.totalDownTimeDuration?.toString() ?? '0',
          minuteToHourDivider,
        ),
        totalRunTimeDuration: this.decimalHelper.divide(
          weeklyResult.totalRunTimeDuration?.toString() ?? '0',
          minuteToHourDivider,
        ),
      },
    };
  }

  public formatOeeAdherenceWoData(row: IWoAdherenceOeeWithinIntervalResponse): IWoAdherenceOeeWithinIntervalResponse {
    const userScaleLimit: number = this.decimalScale$ ?? DECIMAL_DEFAULT_SCALE_LIMIT;
    const minuteToHourDivider: string = '60';

    return {
      ...row,
      oeeStartDate: this.momentDatePipe.transform(row.oeeStartDate, this.dateFormatInformation.dateTimeFormatRaw$),
      oeeEndDate: this.momentDatePipe.transform(row.oeeEndDate, this.dateFormatInformation.dateTimeFormatRaw$),
      availability: this.decimalHelper.toFixedValue(row.availability, DECIMAL_DEFAULT_SCALE_LIMIT),
      quality: this.decimalHelper.toFixedValue(row.quality, DECIMAL_DEFAULT_SCALE_LIMIT),
      performance: this.decimalHelper.toFixedValue(row.performance, DECIMAL_DEFAULT_SCALE_LIMIT),
      overallOee: this.decimalHelper.toFixedValue(row.overallOee, DECIMAL_DEFAULT_SCALE_LIMIT),
      expectedOEE: this.handleDecimalNumbers(
        this.decimalHelper.multiply(this.decimalHelper.convertToDecimal(row.expectedOEE?.toString() ?? '0'), '100'),
        DECIMAL_DEFAULT_SCALE_LIMIT,
      ),
      expectedSpeed: this.decimalHelper.removeThousandSeparator(row.expectedSpeed),
      staffTime: this.handleDecimalNumbers(
        this.decimalHelper.divide(row.staffTime, minuteToHourDivider),
        userScaleLimit,
      ),
      totalRunTimeDuration: this.handleDecimalNumbers(
        this.decimalHelper.divide(row.totalRunTimeDuration, minuteToHourDivider),
        userScaleLimit,
      ),
      totalDownTimeDuration: this.handleDecimalNumbers(
        this.decimalHelper.divide(row.totalDownTimeDuration, minuteToHourDivider),
        userScaleLimit,
      ),
      unplannedDownTimeDuration: this.handleDecimalNumbers(
        this.decimalHelper.divide(row.unplannedDownTimeDuration, minuteToHourDivider),
        userScaleLimit,
      ),
      plannedDownTimeDuration: this.handleDecimalNumbers(
        this.decimalHelper.divide(row.plannedDownTimeDuration, minuteToHourDivider),
        userScaleLimit,
      ),
      targetOperation: this.handleDecimalNumbers(row.targetOperation, userScaleLimit),
      targetRun: this.handleDecimalNumbers(row.targetRun, userScaleLimit),
      netRunTime: this.handleDecimalNumbers(
        this.decimalHelper.divide(row.netRunTime, minuteToHourDivider),
        userScaleLimit,
      ),
      productiveTime: this.handleDecimalNumbers(
        this.decimalHelper.divide(row.productiveTime, minuteToHourDivider),
        userScaleLimit,
      ),
      totalGoodCount: this.decimalHelper.toFixedValue(row.totalGoodCount, DECIMAL_DEFAULT_SCALE_LIMIT),
      totalScrapCount: this.decimalHelper.toFixedValue(row.totalScrapCount, DECIMAL_DEFAULT_SCALE_LIMIT),
      expectedOutput: this.decimalHelper.toFixedValue(row.expectedOutput, DECIMAL_DEFAULT_SCALE_LIMIT),
      totalInitialCount: this.decimalHelper.toFixedValue(row.totalInitialCount, DECIMAL_DEFAULT_SCALE_LIMIT),
      rawData: {
        expectedOEE: this.decimalHelper.multiply(
          this.decimalHelper.convertToDecimal(row.expectedOEE?.toString() ?? '0'),
          '100',
        ),
        overallOee: row.overallOee,
        totalDownTimeDuration: this.decimalHelper.divide(row.totalDownTimeDuration, minuteToHourDivider),
        totalRunTimeDuration: this.decimalHelper.divide(row.totalRunTimeDuration, minuteToHourDivider),
      },
    };
  }

  public getWeeklyDatatableHeaders(): DatatableHeaderInterface[] {
    return [
      {
        value: 'weekNumber',
        name: this.translate.instant('oeeAdherence.weeklyTableView.headers.weekNo'),
        sortable: false,
      },
      {
        value: 'plannedDownTimeDuration',
        name: this.translate.instant('deepDiveAnalysis.headers.downTimePlanned'),
        sortable: false,
      },
      {
        value: 'targetOperation',
        name: this.translate.instant('oeeAdherence.weeklyTableView.headers.targetOperationalTime'),
        sortable: false,
      },
      {
        value: 'targetRun',
        name: this.translate.instant('oeeAdherence.weeklyTableView.headers.targetRunTime'),
        sortable: false,
      },
      {
        value: 'scheduledPreRunDuration',
        name: this.translate.instant('oeeAdherence.weeklyTableView.headers.scheduledPreRun'),
        sortable: false,
      },
      {
        value: 'scheduledPostRunDuration',
        name: this.translate.instant('oeeAdherence.weeklyTableView.headers.scheduledPostRun'),
        sortable: false,
      },
      {
        value: 'potentialQuantityBasedOnProductSpeedxActualRunTime',
        name: this.translate.instant('oeeAdherence.weeklyTableView.headers.expectedOutput'),
        sortable: false,
      },
      {
        value: 'totalInitialCount',
        name: this.translate.instant('oeeAdherence.weeklyTableView.headers.actualOutput'),
        sortable: false,
      },
      {
        value: 'totalGoodCount',
        name: this.translate.instant('oeeAdherence.weeklyTableView.headers.goodOutput'),
        sortable: false,
      },
      {
        value: 'totalScrapCount',
        name: this.translate.instant('oeeAdherence.weeklyTableView.headers.scrapOutput'),
        sortable: false,
      },
      {
        value: 'totalInitialCountEffectiveDuration',
        name: this.translate.instant('oeeAdherence.weeklyTableView.headers.netRunTime'),
        sortable: false,
      },
      {
        value: 'totalGoodCountEffectiveDuration',
        name: this.translate.instant('oeeAdherence.weeklyTableView.headers.productiveTime'),
        sortable: false,
      },
      {
        value: 'availabilityPerInterval',
        name: this.translate.instant('general.availability') + ' (%)',
        sortable: false,
      },
      {
        value: 'performancePerInterval',
        name: this.translate.instant('general.performance') + ' (%)',
        sortable: false,
      },
      {
        value: 'qualityPerInterval',
        name: this.translate.instant('general.quality') + ' (%)',
        sortable: false,
      },
      {
        value: 'overallOEE',
        name: this.translate.instant('oeeAdherence.weeklyTableView.headers.actualOEE'),
        sortable: false,
      },
      {
        value: 'expectedOEE',
        name: this.translate.instant('oeeAdherence.weeklyTableView.headers.expectedOEE'),
        sortable: false,
      },
      {
        value: 'comparisonOEE',
        name: this.translate.instant('oeeAdherence.weeklyTableView.headers.comparisonOEE'),
        sortable: false,
      },
    ];
  }

  public getWorkOrderTableHeaders(): DatatableHeaderInterface[] {
    return [
      {
        value: 'lineName',
        name: this.translate.instant('general.line'),
        sortable: false,
      },
      {
        value: 'productName',
        name: this.translate.instant('general.product'),
        sortable: false,
      },
      {
        value: 'woNumber',
        name: this.translate.instant('general.workOrder'),
        sortable: false,
      },
      {
        value: 'jobName',
        name: this.translate.instant('general.job'),
        sortable: false,
      },
      {
        value: 'oeeStartDate',
        name: this.translate.instant('oeeAdherence.woTableView.headers.workOrderStart'),
        sortable: false,
      },
      {
        value: 'oeeEndDate',
        name: this.translate.instant('oeeAdherence.woTableView.headers.workOrderEnd'),
        sortable: false,
      },
      {
        value: 'staffTime',
        name: this.translate.instant('oeeAdherence.woTableView.headers.staffTime'),
        sortable: false,
      },
      {
        value: 'totalRunTimeDuration',
        name: this.translate.instant('deepDiveAnalysis.headers.runTime'),
        sortable: false,
      },
      {
        value: 'totalDownTimeDuration',
        name: this.translate.instant('oeeAdherence.woTableView.headers.totalDownTime'),
        sortable: false,
      },
      {
        value: 'unplannedDownTimeDuration',
        name: this.translate.instant('oeeAdherence.woTableView.headers.unplannedDownTime'),
        sortable: false,
      },
      {
        value: 'plannedDownTimeDuration',
        name: this.translate.instant('deepDiveAnalysis.headers.downTimePlanned'),
        sortable: false,
      },
      {
        value: 'targetOperation',
        name: this.translate.instant('oeeAdherence.weeklyTableView.headers.targetOperationalTime'),
        sortable: false,
      },
      {
        value: 'targetRun',
        name: this.translate.instant('oeeAdherence.weeklyTableView.headers.targetRunTime'),
        sortable: false,
      },
      {
        value: 'overallOee',
        name: this.translate.instant('oeeAdherence.weeklyTableView.headers.actualOEE'),
        sortable: false,
      },
      {
        value: 'expectedOEE',
        name: this.translate.instant('oeeAdherence.weeklyTableView.headers.expectedOEE'),
        sortable: false,
      },
      {
        value: 'expectedOutput',
        name: this.translate.instant('oeeAdherence.weeklyTableView.headers.expectedOutput'),
        sortable: false,
      },
      {
        value: 'totalInitialCount',
        name: this.translate.instant('oeeAdherence.weeklyTableView.headers.actualOutput'),
        sortable: false,
      },
      {
        value: 'totalGoodCount',
        name: this.translate.instant('oeeAdherence.weeklyTableView.headers.goodOutput'),
        sortable: false,
      },
      {
        value: 'totalScrapCount',
        name: this.translate.instant('oeeAdherence.weeklyTableView.headers.scrapOutput'),
        sortable: false,
      },
      {
        value: 'netRunTime',
        name: this.translate.instant('oeeAdherence.weeklyTableView.headers.netRunTime'),
        sortable: false,
      },
      {
        value: 'productiveTime',
        name: this.translate.instant('oeeAdherence.weeklyTableView.headers.productiveTime'),
        sortable: false,
      },
    ];
  }

  private getExcelColumnsFromTableHeaders(headers: DatatableHeaderInterface[]): IExcelColumnDefinition[] {
    const excelColumnInfoConfiguration = _.map(headers, 'value');
    return headers.reduce((excelColumns: any[], column: DatatableHeaderInterface) => {
      excelColumns.push({
        header: column.name,
        key: column.value,
        width: EExcelColumnWidth.DEFAULT,
        type: ValueType.String,
        style: { numFmt: '@' },
        value: this.excelHelper.getExcelColumnInfo(column.value, excelColumnInfoConfiguration),
        dataValidation: {
          type: ECellTypes.CUSTOM,
          formulae: [],
          showErrorMessage: false,
          showInputMessage: false,
        },
      });
      return excelColumns;
    }, []);
  }
}
