import { Inject, Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { GetManyResponseInterface } from '../../../shared/model/interface/crud-response-interface.model';
import {
  IFormattedOtifAnalysisTableData,
  IOtifAnalysisFilters,
  IOtifAnalysisResponse,
  WorkOrder,
} from './otif-analysis.model';
import { HelperService } from '../../../shared/service/helper.service';
import { DatatableHeaderInterface } from '../../../shared/component/datatable/datatable.model';
import {
  CellTypes,
  CreateExcelInterface,
  CreateExcelSheetInterface,
  ExcelColumnDefinitionInterface,
  ExcelColumnWidthEnum,
  ExcelHelperService,
  ExcelSheetTypeEnum,
} from '../../../shared/service/excel/excel-helper.service';
import { ValueType } from 'exceljs';
import * as moment from 'moment/moment';
import * as ObjectActions from './otif-analysis.actions';
import * as AppActions from '../../app/actions';
import { TranslateService } from '@ngx-translate/core';
import { Store } from '@ngrx/store';
import { takeUntil } from 'rxjs/operators';
import { DECIMAL_DEFAULT_SCALE_LIMIT } from '../../../../constants';
import * as oeeAppReducer from '../../oee.reducer';

@Injectable({
  providedIn: 'root',
})
export class OtifAnalysisService {
  private readonly destroySubject: Subject<boolean> = new Subject<boolean>();
  private timezone: string = 'utc';
  private dateFormat$: string;
  private timeFormat$: string;
  private decimalScale$: number = DECIMAL_DEFAULT_SCALE_LIMIT;

  private readonly routes = {
    otifAnalysis: `${this.baseUrl}/work-orders/otif-analysis`,
  };

  constructor(
    private readonly store: Store<oeeAppReducer.OeeAppState>,
    public http: HttpClient,
    @Inject('API_BASE_URL') private readonly baseUrl: string,
    private readonly translate: TranslateService,
    private readonly excelHelper: ExcelHelperService,
  ) {
    this.store
      .select('user')
      .pipe(takeUntil(this.destroySubject))
      .subscribe((state) => {
        if (state.isUserLoaded) {
          this.timezone = state.timezone;
          this.dateFormat$ = state.dateFormat;
          this.timeFormat$ = state.dateTimeFormat;
          this.decimalScale$ = state.decimalScaleLimit;

          this.destroySubject.next(true);
          this.destroySubject.complete();
        }
      });
  }

  public getOtifAnalysisData(body: IOtifAnalysisFilters): Observable<GetManyResponseInterface<IOtifAnalysisResponse>> {
    return this.http.post<GetManyResponseInterface<IOtifAnalysisResponse>>(`${this.routes.otifAnalysis}`, body, {
      headers: new HttpHeaders({ 'X-HTTP-Method': 'GET' }),
    });
  }

  public downloadExcel(otifData: IFormattedOtifAnalysisTableData[], tableHeaders: DatatableHeaderInterface[]): void {
    this.store.dispatch(new AppActions.ShowLoader());

    const sheetTitle: string = this.translate.instant('excel.items.otifAnalysisReport');
    const excelName: string = `${sheetTitle} ${moment().tz(this.timezone).format(this.dateFormat$)}`;
    const data: IFormattedOtifAnalysisTableData[] = this.getExcelFormattedData(otifData);

    const excelTemplateFormatOptions: CreateExcelInterface = {
      data,
      columns: this.getExcelColumnsFromTableHeaders(tableHeaders),
    };
    const excelDataAnalysisFormatOptions: CreateExcelInterface = {
      data,
      columns: this.getExcelColumnsFromTableHeaders(tableHeaders, true),
    };

    const worksheets: CreateExcelSheetInterface[] = [
      {
        sheetTitle: this.translate.instant('activityLogs.excel.readme.worksheetName'),
        sheetType: ExcelSheetTypeEnum.README,
      },
      {
        sheetTitle: this.translate.instant('activityLogs.excel.readme.templateFormatTitle'),
        sheetType: ExcelSheetTypeEnum.TABLE,
        params: excelTemplateFormatOptions,
        withData: true,
        addDateTimeFormula: undefined,
      },
      {
        sheetTitle: this.translate.instant('activityLogs.excel.readme.dataAnalysisFormatTitle'),
        sheetType: ExcelSheetTypeEnum.TABLE,
        params: excelDataAnalysisFormatOptions,
        withData: true,
        addDateTimeFormula: undefined,
      },
    ];

    this.excelHelper.createExcel(excelName, { name: 'otifAnalysis', withData: true }, worksheets, this.timezone, this.dateFormat$, this.timeFormat$).then(
      () => {
        this.store.dispatch(new ObjectActions.DownloadOtifAnalysisExcelCompleted());
        this.store.dispatch(new AppActions.HideLoader());
      },
      () => {
        this.store.dispatch(new ObjectActions.FetchError({}));
        this.store.dispatch(new AppActions.HideLoader());
      },
    );
  }

  private getExcelFormattedData(data: IFormattedOtifAnalysisTableData[]): IFormattedOtifAnalysisTableData[] {
    return HelperService.cloneDeep(data)
      .slice(0, 999)
      .map((item: IFormattedOtifAnalysisTableData) => {
        return {
          ...item,
          workOrders: item.workOrders.map((workOrder: WorkOrder) => workOrder.workOrderNumber).join(', '),
        };
      }) as unknown as IFormattedOtifAnalysisTableData[];
  }

  private getExcelColumnsFromTableHeaders(
    headers: DatatableHeaderInterface[],
    isDataAnalysisFormat: boolean = false,
  ): ExcelColumnDefinitionInterface[] {
    return headers.reduce((excelColumns: any[], column: DatatableHeaderInterface) => {
      excelColumns.push({
        header: column.name,
        key: column.value,
        width: ExcelColumnWidthEnum.DEFAULT,
        type: ValueType.String,
        style: { numFmt: '@' },
        dataValidation: {
          type: CellTypes.CUSTOM,
          formulae: [],
          showErrorMessage: false,
          showInputMessage: false,
        },
        ...(isDataAnalysisFormat ? this.excelHelper.getExcelColumnInfo(column.value, { decimalFields: [] }) : {}),
      });
      return excelColumns;
    }, []);
  }
}
