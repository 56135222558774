<div class="bg-c-lightGrey metric-item d-flex flex-column h-100 p-2 multiple-units-metric">
  <div class="row h-100 m-0">
    <div class="col-12 d-flex justify-content-center">
      <div class="nowrap text-center">
        <p class="head-text-2 three-dot c-title m-b-0">
          {{ 'kpiCards.metrics.work_order_count' | translate }}
        </p>
        <br *ngIf="!titleBracketInfo" />
        <p *ngIf="titleBracketInfo" class="head-text-2 three-dot c-title m-b-0">({{ titleBracketInfo }})</p>
      </div>
    </div>
    <ng-container *ngFor="let unit of unitsAndMultipliers; index as i">
      <div
        [ngClass]="unitsAndMultipliers.length > 3 || (unitsAndMultipliers.length > 2 && i < 2) ? 'col-6' : 'col-12'"
        class="unit-card"
      >
        <div class="w-100 h-100 d-grid">
          <p class="head-text-3 c-title m-0 align-self-center text-truncate">{{ unit.name }}</p>
          <p class="head-text m-0 align-self-center text-truncate">
            {{ fromMetricInfo ? 5 : (unit.value === null ? '--' : unit.value | toFixed : 2) }}
          </p>
        </div>
      </div>
    </ng-container>
  </div>
</div>
