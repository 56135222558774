import { ApplicationInsights, IEventTelemetry } from '@microsoft/applicationinsights-web';
import { Injectable } from '@angular/core';
import { environment } from '../../../../environments/environment';
import { EApplicationInsights, IUserInfoForPageView } from '../../../store/app/model';
import { lastNavigationInformation } from '../../../../constants';
import * as _ from 'lodash';
import { TranslateService } from '@ngx-translate/core';
import { ICustomProperties } from '@microsoft/applicationinsights-core-js';

@Injectable({
  providedIn: 'root',
})
export class MonitoringService {
  public static userInfoForPageView: IUserInfoForPageView;
  private appInsights: ApplicationInsights;

  constructor(private readonly translate: TranslateService) {
    if (environment.appInsights.instrumentationKey !== null) {
      this.appInsightsInitializer();
    }
  }

  public logException(exception: Error, severityLevel?: number): void {
    if (this.appInsights === undefined) {
      console.error(exception);
      return;
    }

    this.appInsights.trackException({ exception, severityLevel });
  }
  public logEvent(event: IEventTelemetry, customProperties?: ICustomProperties): void {
    if (this.appInsights === undefined) {
      return;
    }

    this.appInsights.trackEvent(event, customProperties);
  }

  public setAuthenticatedUser(userId: string, accountId: string): void {
    if (this.appInsights === undefined) {
      return;
    }

    this.appInsights.setAuthenticatedUserContext(userId, accountId);
  }

  public logoutAuthorizedUser(): void {
    if (this.appInsights === undefined) {
      return;
    }

    this.appInsights.clearAuthenticatedUserContext();
  }

  private appInsightsInitializer(): void {
    this.appInsights = new ApplicationInsights({
      config: {
        instrumentationKey: environment.appInsights.instrumentationKey,
        enableAutoRouteTracking: true,
        cookieDomain: 'scm3d.com',
        autoTrackPageVisitTime: true,
        enableAjaxErrorStatusText: true,
        enableAjaxPerfTracking: true,
        enableUnhandledPromiseRejectionTracking: true,
        enableCorsCorrelation: true,
        enableRequestHeaderTracking: true,
        enableResponseHeaderTracking: true,
        correlationHeaderExcludedDomains: ['*.queue.core.windows.net'],
      },
    });
    this.appInsights.loadAppInsights();

    const telemetryInitializer = (envelope) => {
      envelope.tags['ai.cloud.role'] = environment.appInsights.cloudRole;
      const lastNavigationValueExist: boolean =
        !_.isNil(lastNavigationInformation) && !_.isNil(lastNavigationInformation?.value);

      if (envelope.name === EApplicationInsights.PAGE_VIEW && lastNavigationValueExist) {
        const titleKey = `pageTitleTranslateEN.pageTitles.${_.get(
          lastNavigationInformation?.value?.routeConfig,
          'title',
        )}`;
        const pageTitle: string = this.translate.instant(titleKey);
        let telemetryItem = envelope.baseData;
        telemetryItem.properties = telemetryItem.properties || {};
        telemetryItem.properties['traceProperties'] = {
          pageTitle: pageTitle ?? '',
          defaultSite: MonitoringService.userInfoForPageView.defaultSite ?? '',
          userName: MonitoringService.userInfoForPageView.userName ?? '',
          email: MonitoringService.userInfoForPageView.email ?? '',
          userLevel: MonitoringService.userInfoForPageView.userLevel ?? '',
        };
      }
    };

    this.appInsights.addTelemetryInitializer(telemetryInitializer);
  }
}
