import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, switchMap } from 'rxjs/operators';
import { forkJoin, of } from 'rxjs';
import * as ObjectActions from './site-line-selection.actions';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Store } from '@ngrx/store';
import * as AppActions from '../app/actions';
import { OeeAppState } from '../oee.reducer';
import { SiteLineSelectionService } from './site-line-selection.service';
import {
  GetManyLineCrudResponseInterface,
  GetManySiteCrudResponseInterface,
  LineInterface,
} from './site-line-selection.model';
import { StatusesEnum } from '../../../constants';
import {
  EFloorPlanCategoryForId,
  FloorPlanInterface,
} from '../settings/departments-lines-stations/floor-plans/floor-plans.model';
import { GetManyResponseInterface } from '../../shared/model/interface/crud-response-interface.model';

@Injectable()
export class SiteLineSelectionEffects {
  constructor(
    private actions$: Actions<ObjectActions.SiteLineSelectionActions>,
    private http: HttpClient,
    private store: Store<OeeAppState>,
    public service: SiteLineSelectionService,
  ) {}

  loadSiteLineSelections$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ObjectActions.SiteLineSelectionActionTypes.LOAD_SITES_LINES),
      switchMap(() => {
        this.store.dispatch(new AppActions.ShowLoader());
        let params: HttpParams = new HttpParams();
        params = params
          .append('s', JSON.stringify({ status: StatusesEnum.ACTIVE }))
          .append('sort', 'name,ASC')
          .append('limit', '1000');
        return this.service.getSitesOfSiteLineSelection(params).pipe(
          switchMap((resData: GetManySiteCrudResponseInterface) => {
            return of(new ObjectActions.LoadLinesOfSiteLineSelection(resData.data));
          }),
          catchError((errorRes) => {
            return of(new ObjectActions.FetchDataError(errorRes));
          }),
        );
      }),
    ),
  );

  loadLinesOfSiteLineSelections$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ObjectActions.SiteLineSelectionActionTypes.LOAD_LINES_OF_SITE_LINE_SELECTION),
      switchMap(() => {
        const lineTypeFilter: string = JSON.stringify({
          $and: [
            {
              statusId: { $eq: StatusesEnum.ACTIVE },
            },
            {
              'lineTypeName.status': { $eq: StatusesEnum.ACTIVE },
            },
          ],
        });
        const floorPlanFilter: string = JSON.stringify({
          categoryForId: { $eq: EFloorPlanCategoryForId.FLOOR_PLAN_FOR_SITE },
        });

        const lineParams: HttpParams = new HttpParams()
          .append('fields', 'title,siteId,statusId,lineType')
          .append('join', 'lineTypeName||order,lineType,status')
          .append('s', lineTypeFilter)
          .append('limit', '1000')
          .append('sort', 'title,ASC');
        const floorPlanParams: HttpParams = new HttpParams()
          .append('s', floorPlanFilter)
          .append('limit', '1000')
          .append('sort', 'name,ASC');

        return forkJoin([
          this.service.getLinesOfSiteLineSelection(lineParams),
          this.service.getFloorPlansOfSiteLineSelection(floorPlanParams),
        ]).pipe(
          switchMap((resData: (GetManyLineCrudResponseInterface | GetManyResponseInterface<FloorPlanInterface>)[]) => {
            return of(
              new ObjectActions.SitesLinesLoaded({
                linesAndLineTypes: resData?.[0]?.data as LineInterface[],
                floorPlans: ((resData?.[1]?.data ?? []) as FloorPlanInterface[]).filter(
                  (floorPlan: FloorPlanInterface) => floorPlan.floorPlanLines?.length,
                ),
              }),
              new AppActions.HideLoader(),
            );
          }),
          catchError((errorRes) => {
            return of(new ObjectActions.FetchDataError(errorRes));
          }),
        );
      }),
    ),
  );

  fetchError$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ObjectActions.SiteLineSelectionActionTypes.FETCH_ERROR),
      map(() => {
        return new AppActions.HideLoader();
      }),
    ),
  );
}
