import { Action } from '@ngrx/store';
import { HttpOptionInterface } from '../filter.model';
import { NotificationRecipientUserCRUDInterface } from '../../../shared/component/filter/filter.class';

export enum FilterNotificationRecipientUserActionTypes {
  FilterNotificationRecipientUsersLoading = '[Filter Notification Recipient User] Filter Notification Recipient Users Loading',
  FilterNotificationRecipientUsersLoaded = '[Filter Notification Recipient User] Filter Notification Recipient Users Loaded',
  ResetNotificationRecipientUserStore = '[Filter Notification Recipient User] Reset Notification Recipient User Store',
}

export class FilterNotificationRecipientUsersLoading implements Action {
  readonly type = FilterNotificationRecipientUserActionTypes.FilterNotificationRecipientUsersLoading;

  constructor(public options: HttpOptionInterface) {}
}

export class FilterNotificationRecipientUsersLoaded implements Action {
  readonly type = FilterNotificationRecipientUserActionTypes.FilterNotificationRecipientUsersLoaded;

  constructor(public data: NotificationRecipientUserCRUDInterface[]) {}
}

export class ResetNotificationRecipientUserStore implements Action {
  readonly type = FilterNotificationRecipientUserActionTypes.ResetNotificationRecipientUserStore;
}

export type FilterNotificationRecipientUserActions =
  | FilterNotificationRecipientUsersLoading
  | FilterNotificationRecipientUsersLoaded
  | ResetNotificationRecipientUserStore;
