import { Inject, Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { GetManyLineCrudResponseInterface, GetManySiteCrudResponseInterface } from './site-line-selection.model';
import { GetManyResponseInterface } from '../../shared/model/interface/crud-response-interface.model';
import { FloorPlanInterface } from '../settings/departments-lines-stations/floor-plans/floor-plans.model';

@Injectable({
  providedIn: 'root',
})
export class SiteLineSelectionService {
  private readonly SITE_LINE_SELECTION = {
    GET: {
      SITES: {
        URL: `${this.baseUrl}/sites`,
      },
      LINES: {
        URL: `${this.baseUrl}/lines`,
      },
      FLOOR_PLANS: {
        URL: `${this.baseUrl}/floor-plans`,
      },
    },
  };
  constructor(public http: HttpClient, @Inject('API_BASE_URL') private readonly baseUrl: string) {}

  public getSitesOfSiteLineSelection(httpParams: HttpParams): Observable<GetManySiteCrudResponseInterface> {
    return this.http.get<GetManySiteCrudResponseInterface>(this.SITE_LINE_SELECTION.GET.SITES.URL, {
      params: httpParams,
    });
  }

  public getLinesOfSiteLineSelection(httpParams: HttpParams): Observable<GetManyLineCrudResponseInterface> {
    return this.http.get<GetManyLineCrudResponseInterface>(this.SITE_LINE_SELECTION.GET.LINES.URL, {
      params: httpParams,
    });
  }

  public getFloorPlansOfSiteLineSelection(
    httpParams: HttpParams,
  ): Observable<GetManyResponseInterface<FloorPlanInterface>> {
    return this.http.get<GetManyResponseInterface<FloorPlanInterface>>(this.SITE_LINE_SELECTION.GET.FLOOR_PLANS.URL, {
      params: httpParams,
    });
  }
}
