import { Action } from '@ngrx/store';
import {
  IDeleteFileByFilePathParams,
  IDeleteFileParams,
  IDeleteFileResponse,
  IGetFileParams,
  IGetFileResponse,
  IUploadFileParams,
  IUploadFileResponse,
} from './file-upload.model';
import {
  BaseOneResponseInterface,
  BulkResponseDataInterface,
} from '../../shared/model/interface/crud-response-interface.model';

export const GET_FILE_LOADING = '[FILE_UPLOAD] GET FILE LOADING';
export const GET_FILE_LOADED = '[FILE_UPLOAD] GET FILE LOADED';
export const GET_FILES_BY_FOLDER_ID_LOADING = '[FILE_UPLOAD] GET FILES BY FOLDER ID LOADING';
export const GET_FILES_BY_FOLDER_ID_LOADED = '[FILE_UPLOAD] GET FILES BY FOLDER ID LOADED';
export const GET_FILES_LOADING = '[FILE_UPLOAD] GET FILES LOADING';
export const GET_FILES_LOADED = '[FILE_UPLOAD] GET FILES LOADED';
export const BULK_UPLOAD_FILE_LOADING = '[FILE_UPLOAD] BULK UPLOAD FILE LOADING';
export const BULK_UPLOAD_FILE_LOADED = '[FILE_UPLOAD] BULK UPLOAD FILE LOADED';
export const DELETE_FILE_LOADING = '[FILE_UPLOAD] DELETE FILE LOADING';
export const DELETE_FILE_LOADED = '[FILE_UPLOAD] DELETE FILE LOADED';
export const DELETE_MANY_FILE_LOADING = '[FILE_UPLOAD] DELETE MANY FILE LOADING';
export const DELETE_MANY_FILE_LOADED = '[FILE_UPLOAD] DELETE MANY FILE LOADED';
export const DELETE_MANY_FILE_BY_FILE_PATH_LOADING = '[FILE_UPLOAD] DELETE MANY FILE BY FILE PATH LOADING';
export const DELETE_MANY_FILE_BY_FILE_PATH_LOADED = '[FILE_UPLOAD] DELETE MANY FILE BY FILE PATH LOADED';
export const CLEAR_STATE = '[FILE_UPLOAD] CLEAR STATE';
export const FETCH_ERROR = '[FILE_UPLOAD] FETCH ERROR';

export class GetFileLoading implements Action {
  readonly type = GET_FILE_LOADING;

  constructor(public params: IGetFileParams) {}
}

export class GetFileLoaded implements Action {
  readonly type = GET_FILE_LOADED;

  constructor(public response: IGetFileResponse) {}
}

export class GetFilesByFolderIdLoading implements Action {
  readonly type = GET_FILES_BY_FOLDER_ID_LOADING;

  constructor(public params: IGetFileParams) {}
}

export class GetFilesByFolderIdLoaded implements Action {
  readonly type = GET_FILES_BY_FOLDER_ID_LOADED;

  constructor(public response: IGetFileResponse[]) {}
}

export class GetFilesLoading implements Action {
  readonly type = GET_FILES_BY_FOLDER_ID_LOADING;

  constructor(public params: IGetFileParams[]) {}
}

export class GetFilesLoaded implements Action {
  readonly type = GET_FILES_LOADED;

  constructor(public response: IGetFileResponse[]) {}
}

export class BulkUploadFileLoading implements Action {
  readonly type = BULK_UPLOAD_FILE_LOADING;

  constructor(public params: IUploadFileParams) {}
}

export class BulkUploadFileLoaded implements Action {
  readonly type = BULK_UPLOAD_FILE_LOADED;

  constructor(public response: BaseOneResponseInterface<IUploadFileResponse[]>) {}
}

export class DeleteFileLoading implements Action {
  readonly type = DELETE_FILE_LOADING;

  constructor(public params: IDeleteFileParams) {}
}

export class DeleteFileLoaded implements Action {
  readonly type = DELETE_FILE_LOADED;

  constructor(public payload: IDeleteFileResponse) {}
}

export class DeleteManyFileByFilePathLoading implements Action {
  readonly type = DELETE_MANY_FILE_BY_FILE_PATH_LOADING;

  constructor(public params: IDeleteFileByFilePathParams) {}
}

export class DeleteManyFileByFilePathLoaded implements Action {
  readonly type = DELETE_MANY_FILE_BY_FILE_PATH_LOADED;

  constructor(public payload: BulkResponseDataInterface) {}
}

export class DeleteManyFileLoading implements Action {
  readonly type = DELETE_MANY_FILE_LOADING;

  constructor(public params: IDeleteFileParams[]) {}
}

export class DeleteManyFileLoaded implements Action {
  readonly type = DELETE_MANY_FILE_LOADED;

  constructor(public success: boolean) {}
}

export class FetchError implements Action {
  readonly type = FETCH_ERROR;

  constructor(public payload: object) {}
}
export class ClearState implements Action {
  readonly type = CLEAR_STATE;
}

export type FileUploadActions =
  | GetFileLoading
  | GetFileLoaded
  | GetFilesByFolderIdLoading
  | GetFilesByFolderIdLoaded
  | BulkUploadFileLoading
  | BulkUploadFileLoaded
  | DeleteFileLoading
  | DeleteFileLoaded
  | DeleteManyFileLoading
  | DeleteManyFileLoaded
  | DeleteManyFileByFilePathLoading
  | DeleteManyFileByFilePathLoaded
  | ClearState
  | FetchError;
