import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import * as FloorPlanActions from './floor-plan.actions';
import { catchError, switchMap } from 'rxjs/operators';
import { generateHttpOption } from '../../../shared/helper/app-helper';
import { HttpParams } from '@angular/common/http';
import { from, of } from 'rxjs';
import { FloorPlanService } from '../../../shared/service/filter/floor-plan.service';
import { FloorPlanCRUDInterface } from '../../../shared/component/filter/filter.class';
import { get } from 'lodash';
import {
  EFloorPlanCategoryForId,
  FloorPlanInterface,
} from '../../settings/departments-lines-stations/floor-plans/floor-plans.model';

@Injectable()
export class FilterFloorPlanEffects {
  constructor(
    private actions$: Actions<FloorPlanActions.FilterFloorPlanActions>,
    public floorPlanService: FloorPlanService,
  ) {}

  loadFilterFloorPlans$ = createEffect(() =>
    this.actions$.pipe(
      ofType(FloorPlanActions.FilterFloorPlanActionTypes.FilterFloorPlansLoading),
      switchMap((objectData: FloorPlanActions.FilterFloorPlansLoading) => {
        const params: HttpParams = generateHttpOption(objectData.options);
        const isPlanForSite: boolean =
          get(JSON.parse(objectData.options.s), 'categoryForId.$eq') === EFloorPlanCategoryForId.FLOOR_PLAN_FOR_SITE;

        return from(this.floorPlanService.getData(params)).pipe(
          switchMap((response: FloorPlanCRUDInterface[]) => {
            let floorPlans: FloorPlanInterface[] = response as FloorPlanInterface[];

            if (isPlanForSite) {
              floorPlans = floorPlans.filter((floorPlan: FloorPlanInterface) => {
                return floorPlan.configuration.mapViewConfiguration?.length;
              });
            }

            return of(new FloorPlanActions.FilterFloorPlansLoaded(floorPlans as FloorPlanCRUDInterface[]));
          }),
          catchError(() => {
            return of(new FloorPlanActions.FilterFloorPlansLoaded([]));
          }),
        );
      }),
    ),
  );
}
