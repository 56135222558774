import { Inject, Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { ResponseArrayInterface } from '../../model/interface/generic-api-response.model';
import { ILineStationQuantitiesResponse, LineStationGetOneCRUDDataInterface } from '../../../store/line-station/line-station.model';
import { STATIC_MAX_LIMIT_OF_CRUD } from '../../../../constants';
import * as _ from 'lodash';
import { BaseOneResponseInterface } from '../../model/interface/crud-response-interface.model';

@Injectable({
  providedIn: 'root',
})
export class LineStationService {
  private readonly BASE_URL: string = '/line-stations';

  constructor(private readonly http: HttpClient, @Inject('API_BASE_URL') private readonly api: string) {}

  public getLineStationData(
    lineId: number | number[],
  ): Observable<ResponseArrayInterface<LineStationGetOneCRUDDataInterface>> {
    const searchObject = {};

    if (Array.isArray(lineId)) {
      _.set(searchObject, 'lineId.$in', lineId);
    } else {
      _.set(searchObject, 'lineId.$eq', lineId);
    }

    const httpParams: HttpParams = new HttpParams()
      .set('limit', String(STATIC_MAX_LIMIT_OF_CRUD))
      .set('s', JSON.stringify(searchObject));

    return this.http.get<ResponseArrayInterface<LineStationGetOneCRUDDataInterface>>(`${this.api}${this.BASE_URL}`, {
      params: httpParams,
    });
  }

  public getLineStation(stationId: number, params?: HttpParams): Observable<BaseOneResponseInterface<LineStationGetOneCRUDDataInterface>> {
    return this.http.get<BaseOneResponseInterface<LineStationGetOneCRUDDataInterface>>(
      `${this.api}${this.BASE_URL}/${stationId}`,
      { params },
    );
  }

  public getLineStationQuantities(stationID: number):
  Observable<BaseOneResponseInterface<ILineStationQuantitiesResponse>> {
    return this.http.get<BaseOneResponseInterface<ILineStationQuantitiesResponse>>(
      `${this.api}${this.BASE_URL}/${stationID}/quantities`,
    );
  }
}
