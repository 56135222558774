import {
  ComponentRef,
  Directive,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
  ViewContainerRef,
} from '@angular/core';
import * as _ from 'lodash';
import { IDynamicComponentDirectiveContext } from '../../model/interface/generic.model';
import { BehaviorSubject, Subscription } from 'rxjs';

@Directive({
  selector: '[scwDynamicComponent]',
})
export class DynamicComponentDirective<T> implements OnChanges {
  @Input() context!: IDynamicComponentDirectiveContext;

  @Output() componentInstance: BehaviorSubject<ComponentRef<unknown>['instance'] | undefined> = new BehaviorSubject<
    ComponentRef<unknown>['instance'] | undefined
  >(undefined);

  constructor(public viewContainerRef: ViewContainerRef) {}

  private component!: ComponentRef<unknown>;

  public ngOnChanges(changes: SimpleChanges): void {
    if (
      'context' in changes &&
      !_.isEqual(changes['context'].currentValue, changes['context'].previousValue)
    ) {
      this.component?.destroy();
      this.viewContainerRef.clear();

      this.component = this.viewContainerRef.createComponent(this.context.customElement.type);

      for (const [inputKey, inputValue] of Object.entries(this.context.customElement.inputValues)) {
        this.component.setInput(inputKey, inputValue);
      }

      if (this.context.customElement.outputHandlers) {
        const subscriptions: Subscription[] = [];

        for (const [outputKey, outputHandler] of Object.entries(this.context.customElement.outputHandlers)) {
          subscriptions.push(this.component.instance[outputKey].subscribe(outputHandler));
        }

        this.component.onDestroy(() =>
          subscriptions.forEach((subscription: Subscription) => subscription.unsubscribe()),
        );
      }

      this.componentInstance.next(this.component.instance);
    }
  }
}
