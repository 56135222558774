<progress-bar-metric
  [actualValue]="activityTimer"
  [expectedValue]="expectedValue"
  [expectedValueUnit]="unit"
  [progressBarTitle]="activityDurationText"
  [progressBarRateCapped]="activityDurationRateCapped"
  [progressBarRate]="activityDurationRate"
  [showProgressBar]="showProgressBar"
  [actualValueClass]="activityDurationTextClass"
  [actualValueColor]="activityDurationTextStyle"
  [showExpectedValue]="showExpectedValue"
>
</progress-bar-metric>
