import { Action } from '@ngrx/store';
import { IChangeoverPayload, IChangeoverKpiDataResponse, IChangeoverChartDataResponse } from "./changeover.model";

export const GET_CHANGEOVER_KPI_INFORMATION = '[CHANGEOVER] GET CHANGEOVER KPI INFORMATION';
export const GET_CHANGEOVER_KPI_INFORMATION_COMPLETED = '[CHANGEOVER] GET CHANGEOVER KPI INFORMATION COMPLETED';
export const GET_CHANGEOVER_CHART_DATA = '[CHANGEOVER] GET CHANGEOVER CHART DATA';
export const GET_CHANGEOVER_CHART_DATA_COMPLETED = '[CHANGEOVER] GET CHANGEOVER CHART DATA COMPLETED';
export const FETCH_ERROR = '[CHANGEOVER] FETCH ERROR';

export class GetChangeoverKpiInformation implements Action {
  readonly type = GET_CHANGEOVER_KPI_INFORMATION;

  constructor(public payload: IChangeoverPayload) {}
}

export class GetChangeoverKpiInformationCompleted implements Action {
  readonly type = GET_CHANGEOVER_KPI_INFORMATION_COMPLETED;

  constructor(public data: IChangeoverKpiDataResponse[]) {}
}

export class GetChangeoverChartData implements Action {
  readonly type = GET_CHANGEOVER_CHART_DATA;

  constructor(public payload: IChangeoverPayload) {}
}

export class GetChangeoverChartDataCompleted implements Action {
  readonly type = GET_CHANGEOVER_CHART_DATA_COMPLETED;

  constructor(public data: IChangeoverChartDataResponse[]) {}
}

export class FetchError implements Action {
  readonly type = FETCH_ERROR;

  constructor(public payload: object) {}
}

export type ChangeoverActions =
  | GetChangeoverKpiInformation
  | GetChangeoverKpiInformationCompleted
  | GetChangeoverChartData
  | GetChangeoverChartDataCompleted
  | FetchError;
