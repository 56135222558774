import * as Actions from './iot-device-report.actions';
import { IIotDeviceReportState } from './iot-device-report.model';

export const iotDeviceReportState: IIotDeviceReportState = {
  notificationGroupsDataLoading: false,
  notificationGroupsDataLoaded: false,
  notificationGroupsData: [],
  notificationTemplatesDataLoading: false,
  notificationTemplatesDataLoaded: false,
  notificationTemplatesData: [],
  addNotificationLoading: false,
  addNotificationLoaded: false,
  appendToNotificationLoading: false,
  appendToNotificationLoaded: false,
  notificationsDataLoading: false,
  notificationsDataLoaded: false,
  notificationsData: [],
  deleteNotificationLoading: false,
  deleteNotificationLoaded: false,
  deleteNotificationItemLoading: false,
  deleteNotificationItemLoaded: false,
  tableSettings: [],
};

export function iotDeviceReportReducer(state: any = iotDeviceReportState, action: Actions.IotDeviceReportActions): any {
  switch (action.type) {
    case Actions.IOT_DEVICE_PORTAL_SET_TABLE_SETTINGS:
      return {
        ...state,
        tableSettings: action.payload,
      };
    case Actions.IOT_DEVICE_REPORT_NOTIFICATION_GROUPS_DATA_LOADING:
      return {
        ...state,
        ...{
          notificationGroupsDataLoading: true,
          notificationGroupsDataLoaded: false,
        },
      };
    case Actions.IOT_DEVICE_REPORT_NOTIFICATION_GROUPS_DATA_LOADED:
      return {
        ...state,
        ...{
          notificationGroupsData: action.payload,
          notificationGroupsDataLoading: false,
          notificationGroupsDataLoaded: true,
        },
      };
    case Actions.IOT_DEVICE_REPORT_NOTIFICATION_TEMPLATES_DATA_LOADING:
      return {
        ...state,
        ...{
          notificationTemplatesDataLoading: true,
          notificationTemplatesDataLoaded: false,
        },
      };
    case Actions.IOT_DEVICE_REPORT_NOTIFICATION_TEMPLATES_DATA_LOADED:
      return {
        ...state,
        ...{
          notificationTemplatesData: action.payload,
          notificationTemplatesDataLoading: false,
          notificationTemplatesDataLoaded: true,
        },
      };
    case Actions.IOT_DEVICE_REPORT_ADD_NOTIFICATION:
      return {
        ...state,
        addNotificationLoading: true,
        addNotificationLoaded: false,
      };
    case Actions.IOT_DEVICE_REPORT_ADD_NOTIFICATION_COMPLETED:
      return {
        ...state,
        addNotificationLoading: false,
        addNotificationLoaded: true,
      };
    case Actions.IOT_DEVICE_REPORT_APPEND_TO_NOTIFICATION:
      return {
        ...state,
        appendToNotificationLoading: true,
        appendToNotificationLoaded: false,
      };
    case Actions.IOT_DEVICE_REPORT_APPEND_TO_NOTIFICATION_COMPLETED:
      return {
        ...state,
        appendToNotificationLoading: false,
        appendToNotificationLoaded: true,
      };

   case Actions.IOT_DEVICE_REPORT_NOTIFICATIONS_DATA_FOR_APPEND_TO_LOADING:
   case Actions.IOT_DEVICE_REPORT_NOTIFICATIONS_DATA_FOR_VIEW_LOADING:
      return {
        ...state,
        ...{
          notificationsDataLoading: true,
          notificationsDataLoaded: false,
        },
      };
    case Actions.IOT_DEVICE_REPORT_NOTIFICATIONS_DATA_FOR_APPEND_TO_LOADED:
    case Actions.IOT_DEVICE_REPORT_NOTIFICATIONS_DATA_FOR_VIEW_LOADED:
      return {
        ...state,
        ...{
          notificationsData: action.payload,
          notificationsDataLoading: false,
          notificationsDataLoaded: true,
        },
      };

   case Actions.IOT_DEVICE_REPORT_DELETE_NOTIFICATION:
      return {
        ...state,
        deleteNotificationLoading: true,
        deleteNotificationLoaded: false,
      };

  case Actions.IOT_DEVICE_REPORT_DELETE_NOTIFICATION_COMPLETED:
      return {
        ...state,
        deleteNotificationLoading: false,
        deleteNotificationLoaded: true,
      };

   case Actions.IOT_DEVICE_REPORT_DELETE_NOTIFICATION_ITEM:
      return {
        ...state,
        deleteNotificationItemLoading: true,
        deleteNotificationItemLoaded: false,
      };

  case Actions.IOT_DEVICE_REPORT_DELETE_NOTIFICATION_ITEM_COMPLETED:
      return {
        ...state,
        deleteNotificationItemLoading: false,
        deleteNotificationItemLoaded: true,
      };

   case Actions.IOT_DEVICE_REPORT_FETCH_ERROR:
      return {
        ...state,
        notificationGroupsDataLoading: false,
        notificationGroupsDataLoaded: false,
        notificationGroupsData: [],
        notificationTemplatesDataLoading: false,
        notificationTemplatesDataLoaded: false,
        notificationTemplatesData: [],
        addNotificationLoading: false,
        addNotificationLoaded: false,
        appendToNotificationLoading: false,
        appendToNotificationLoaded: false,
        notificationsDataLoading: false,
        notificationsDataLoaded: false,
        notificationsData: [],
        deleteNotificationLoading: false,
        deleteNotificationLoaded: false,
        deleteNotificationItemLoading: false,
        deleteNotificationItemLoaded: false,
        tableSettings: [],
      };
    default:
      return state;
  }
}
