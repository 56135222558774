import { Inject, Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import {
  DeletePlanCRUDResponseInterface,
  IPlanUsageControlResponse,
  LineAvailabilityPlanDataInterface,
  LineAvailabilityResponseInterface,
  PlanInterface,
  SchedulerPlanCRUDResponseInterface,
  TableQueryParams,
} from '../../../store/line-availability/line-availability.model';
import { Observable } from 'rxjs';
import { BulkResponseDataInterface } from '../../model/interface/crud-response-interface.model';
import { HelperService } from '../helper.service';
import { createQueryParams } from '../../helper/app-helper';

@Injectable({
  providedIn: 'root',
})
export class PlanDataService {
  private readonly SCHEDULER_PLAN_BASE_URL = '/scheduler/shift-plans/';
  private readonly BULK_DELETE_URL = 'bulk/delete';
  private readonly SITES = {
    GET: `${this.baseUrl}/sites`,
  };
  private readonly SCHEDULER_PLAN = {
    GET: {
      GET_ALL_PLANS: `${this.baseUrl}${this.SCHEDULER_PLAN_BASE_URL}`,
    },
    DELETE: {
      DELETE_PLAN: `${this.baseUrl}${this.SCHEDULER_PLAN_BASE_URL}`,
    },
    BULK_DELETE: {
      DELETE_PLAN: `${this.baseUrl}${this.SCHEDULER_PLAN_BASE_URL}${this.BULK_DELETE_URL}`,
    },
    POST: {
      CREATE_PLAN: `${this.baseUrl}${this.SCHEDULER_PLAN_BASE_URL}`,
    },
    PATCH: {
      EDIT_PLAN: `${this.baseUrl}${this.SCHEDULER_PLAN_BASE_URL}`,
    },
  };

  constructor(
    public http: HttpClient,
    @Inject('API_BASE_URL') private readonly baseUrl: string,
    private readonly helperService: HelperService,
  ) {}

  public getData(
    query: TableQueryParams = {
      page: 1,
      pageSize: 10,
    },
  ): Promise<LineAvailabilityResponseInterface<LineAvailabilityPlanDataInterface>> {
    const options: { params: HttpParams } = createQueryParams(query.page, query.pageSize, query.query, query.search);
    if (query.hasOwnProperty('orderDesc') && query.hasOwnProperty('orderBy')) {
      options.params = options.params.set('sort', `${query.orderBy},${query.orderDesc ? 'DESC' : 'ASC'}`);
    }
    return new Promise((resolve, reject) => {
      this.http.get(this.SCHEDULER_PLAN.GET.GET_ALL_PLANS, { ...options }).subscribe(
        (response: LineAvailabilityResponseInterface<LineAvailabilityPlanDataInterface>) => {
          resolve(response);
        },
        (error) => {
          reject(error);
        },
      );
    });
  }

  public deleteData(id: number): Promise<DeletePlanCRUDResponseInterface<LineAvailabilityPlanDataInterface>> {
    return new Promise((resolve, reject) => {
      this.http.delete(`${this.SCHEDULER_PLAN.DELETE.DELETE_PLAN}${id}`).subscribe(
        (response: DeletePlanCRUDResponseInterface<LineAvailabilityPlanDataInterface>) => {
          resolve(response);
        },
        (error) => {
          reject(error);
        },
      );
    });
  }

  public deletePlans(ids: number[]): Observable<BulkResponseDataInterface> {
    return this.http.delete<BulkResponseDataInterface>(`${this.SCHEDULER_PLAN.BULK_DELETE.DELETE_PLAN}`, {
      body: { shiftPlans: ids },
    });
  }

  public createData(payload: PlanInterface): Promise<any> {
    const { name, isOfficial, siteIds } = payload;
    const schedulerShiftPlansSiteAssignments = [];

    for (const siteId of siteIds) {
      schedulerShiftPlansSiteAssignments.push({ siteId: Number(siteId) });
    }

    const requestParameters = {
      name,
      isOfficial,
      schedulerShiftPlansSiteAssignments,
    };

    return new Promise((resolve, reject) => {
      this.http.post(`${this.SCHEDULER_PLAN.POST.CREATE_PLAN}`, requestParameters).subscribe(
        (response: DeletePlanCRUDResponseInterface<LineAvailabilityPlanDataInterface>) => {
          resolve(response);
        },
        (error) => {
          reject(error);
        },
      );
    });
  }

  public editData(id: number, payload): Promise<SchedulerPlanCRUDResponseInterface<LineAvailabilityPlanDataInterface>> {
    return new Promise((resolve, reject) => {
      this.http.patch(`${this.SCHEDULER_PLAN.PATCH.EDIT_PLAN}${id}`, payload).subscribe(
        (response: SchedulerPlanCRUDResponseInterface<LineAvailabilityPlanDataInterface>) => {
          const errorResponse: BulkResponseDataInterface = {
            success: response.success,
            data: [],
          };

          for (const item of response.data.schedulerShiftPlansSiteAssignment) {
            const { success, message } = item;

            if (success !== undefined && success === false) {
              errorResponse.data.push({
                success,
                message,
              });
            }
          }

          this.helperService.toastBulkErrors(errorResponse);
          resolve(response);
        },
        (error) => {
          reject(error);
        },
      );
    });
  }

  public getCheckPlanUsageControl(
    planId: number,
  ): Observable<LineAvailabilityResponseInterface<IPlanUsageControlResponse>> {
    return this.http.get<LineAvailabilityResponseInterface<IPlanUsageControlResponse>>(
      `${this.SCHEDULER_PLAN.GET.GET_ALL_PLANS}${planId}/plan-usage-control`,
    );
  }
}
