import { ELaborAssetViewDataType, EQueryType } from '../../dashboards/labor-asset-view/labor-asset-view.model';
import { TagInterface } from '../../../store/settings/tags/tags.model';
import { CheckInFormInterface, ColorDropdownOptionInterface } from '../cico/cico.model';
import { ScwMatDatepickerRule } from '../../../shared/component/scw-mat-ui/scw-mat-datepicker/scw-mat-datepicker.model';
import { ScwMatSelectRule } from '../../../shared/component/scw-mat-ui/scw-mat-select/scw-mat-select.model';
import { ScwMatInputRule } from '../../../shared/component/scw-mat-ui/scw-mat-input/scw-mat-input.model';

export interface ILaborAssetHomeFilter {
  userTypes: -1 | number[];
  timeToggle: EQueryType;
  typeToggle: ELaborAssetViewDataType;
  stationId?: string;
}

export interface IBulkEditProperty {
  isCheckInTimeChecked: boolean;
  isCheckOutTimeChecked: boolean;
  isBulkEditAllUsers: boolean;
}

export interface IUserEquipmentDropdown {
  id: number;
  shortName: string;
  name: string;
  optionText?: string;
  status?: ColorDropdownOptionInterface;
  tagList?: TagInterface[];
  typeTagList?: TagInterface[];
}

export enum ECheckInBulkOperation {
  CHECK_OUT = 'check-out',
  EDIT = 'edit',
}

export enum ELaborAssetHomeCalledFrom {
  HOME = 'home',
  CICO_MODAL = 'cico',
}

export interface ICheckInFormRules {
  checkInUserRules: ScwMatSelectRule[];
  checkInTimeRules: ScwMatDatepickerRule[];
  checkOutTimeRules: ScwMatDatepickerRule[];
  authorizedUserRules: ScwMatSelectRule[];
  authorizedUserPINRules: ScwMatInputRule[];
}

export interface ICheckInFieldOptions {
  enableSearchFilter: boolean;
  enableCheckAll: boolean;
  labelKey: string;
  label: string;
  searchPlaceholderText: string;
  singleSelection: boolean;
  noDataLabel: string;
  noResultsLabel: string;
  badgeShowLimit: number;
  minimumSearchLength: number;
}

export interface IBulkCheckInPayloadDataInfo {
  name: string;
  sourceObjectId: number;
}

type TEditEquipmentCommonProperties = 'checkInDateTime' | 'checkInDateTimeForNative' | 'lineStation' | 'tag';

export interface IEditEquipmentCheckInForm extends Pick<CheckInFormInterface, TEditEquipmentCommonProperties> {
  name: string;
}
