import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError } from 'rxjs/operators';
import * as ObjectActions from './multi-line-change-activity.actions';
import { from, of, switchMap } from 'rxjs';
import { MultiLineChangeActivityService } from './multi-line-change-activity.service';
import * as AppActions from '../../app/actions';
import { ILinePathAssignedWorkOrders } from './multi-line-change-activity.model';
import { BaseOneResponseInterface } from '../../../shared/model/interface/crud-response-interface.model';
import { Store } from '@ngrx/store';
import { OeeAppState } from '../../oee.reducer';

@Injectable()
export class MultiLineChangeActivityEffects {
  constructor(
    private readonly actions$: Actions,
    private readonly service: MultiLineChangeActivityService,
    private store: Store<OeeAppState>,
  ) {}

  getOngoingActivitiesOfLines = createEffect(() =>
    this.actions$.pipe(
      ofType(ObjectActions.EMultiLineChangeActivityAction.LOAD_ONGOING_ACTIVITIES_OF_LINE_PATH),
      switchMap((objectData: ObjectActions.LoadOngoingActivitiesOfLinePath) => {
        this.store.dispatch(new AppActions.ShowLoader());
        return from(this.service.getOngoingActivitiesOfLinePath(objectData.linePathWoSplitResults, objectData.linePathId)).pipe(
          switchMap((data: ILinePathAssignedWorkOrders) => {
            return of(new ObjectActions.LoadedOngoingActivitiesOfLinePath(data), new AppActions.HideLoader());
          }),
          catchError((err) => {
            return of(new ObjectActions.FetchError(err), new AppActions.HideLoader());
          }),
        );
      }),
    ),
  );
}
