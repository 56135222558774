import { HttpClient, HttpParams } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { GetManyResponseInterface } from '../../../shared/model/interface/crud-response-interface.model';
import { ILaborAssetViewData } from './labor-asset-view.model';
import { BehaviorSubject, Observable } from 'rxjs';
import { ITableHeader, PageConfigurationTypes } from '../../../../constants.model';
import {
  EWidgetType,
  WidgetConfigurationInterface,
} from '../../../shared/component/page-configuration/page-configuration.model';
import { TranslateService } from '@ngx-translate/core';
import {
  ELaborAssetViewDataType,
  ELaborAssetViewWidget,
} from '../../../view/dashboards/labor-asset-view/labor-asset-view.model';
import { ELaborAssetHomeCalledFrom } from '../../../view/home/labor-asset-home/labor-asset-home.model';
import { EKpiCardContentType } from '../../../shared/component/scw-mat-ui/scw-mat-kpi-card/scw-mat-kpi-card.model';

@Injectable({
  providedIn: 'root',
})
export class LaborAssetViewService {
  constructor(
    public http: HttpClient,
    @Inject('API_BASE_URL') private readonly baseUrl: string,
    private readonly translate: TranslateService,
  ) {}

  public static pageModeSubject: BehaviorSubject<ELaborAssetViewDataType> =
    new BehaviorSubject<ELaborAssetViewDataType>(ELaborAssetViewDataType.ALL);

  private readonly routes = {
    LABOR_ASSET_VIEW: '/check-ins/labor-asset-view-dashboard',
  };

  public getLaborAssetViewData(params: HttpParams): Observable<GetManyResponseInterface<ILaborAssetViewData>> {
    return this.http.get<GetManyResponseInterface<ILaborAssetViewData>>(
      `${this.baseUrl}${this.routes.LABOR_ASSET_VIEW}`,
      { params },
    );
  }

  public getLaborAssetViewHeaders(type: ELaborAssetViewDataType, checkboxVisible: boolean = false): ITableHeader[] {
    const laborAssetViewTableHeaderDefaults: ITableHeader[] = [
      ...(checkboxVisible
        ? [
            {
              value: null,
              name: '',
              sortable: false,
              width: '50px',
            },
          ]
        : []),
      {
        value: 'fullName',
        name: this.translate.instant('general.dataTable.header.name'),
        selected: true,
        disabled: true,
        type: PageConfigurationTypes.TABLE,
      },
      {
        value: 'lineName',
        name: this.translate.instant('general.line'),
        selected: true,
        type: PageConfigurationTypes.TABLE,
      },
      {
        value: 'stationName',
        name: this.translate.instant('laborAssetView.tableHeaders.lineStationName'),
        selected: true,
        type: PageConfigurationTypes.TABLE,
      },
      {
        value: 'statusName',
        name: this.translate.instant('general.dataTable.header.status'),
        selected: true,
        type: PageConfigurationTypes.TABLE,
      },
      {
        value: 'checkInTime',
        name: this.translate.instant('laborAssetView.tableHeaders.checkInTime'),
        selected: true,
        type: PageConfigurationTypes.TABLE,
      },
      {
        value: 'checkOutTime',
        name: this.translate.instant('laborAssetView.tableHeaders.checkOutTime'),
        selected: true,
        type: PageConfigurationTypes.TABLE,
      },
      {
        value: 'duration',
        name: this.translate.instant('laborAssetView.tableHeaders.actualDuration', {
          durationType: this.translate.instant('durationType.hoursAndMinutes'),
        }),
        selected: true,
        type: PageConfigurationTypes.TABLE,
      },
    ];

    switch (type) {
      case ELaborAssetViewDataType.ALL:
      case ELaborAssetViewDataType.LABOR:
        return [
          ...laborAssetViewTableHeaderDefaults,
          {
            value: 'level',
            name: this.translate.instant('general.dataTable.header.level'),
            selected: true,
            type: PageConfigurationTypes.TABLE,
          },
          {
            value: 'scheduledCheckInTime',
            name: this.translate.instant('laborAssetView.tableHeaders.scheduledCheckInTime'),
            selected: true,
            type: PageConfigurationTypes.TABLE,
          },
          {
            value: 'scheduledCheckOutTime',
            name: this.translate.instant('laborAssetView.tableHeaders.scheduledCheckOutTime'),
            selected: true,
            type: PageConfigurationTypes.TABLE,
          },
        ];
      case ELaborAssetViewDataType.ASSET:
        return laborAssetViewTableHeaderDefaults;
    }
  }

  public getLaborAssetViewWidgets(
    type: ELaborAssetViewDataType,
    calledFrom?: ELaborAssetHomeCalledFrom,
  ): WidgetConfigurationInterface<EWidgetType.KPI_CARD>[] {
    if (calledFrom === ELaborAssetHomeCalledFrom.CICO_MODAL) {
      return this.getLaborAssetViewModalWidgets(type);
    }

    let availableTranslate: string;
    let hoursTranslate: string;

    switch (type) {
      case ELaborAssetViewDataType.ALL:
        availableTranslate = this.translate.instant('laborAssetView.kpiCard.available.laborAsset');
        hoursTranslate = this.translate.instant('laborAssetView.kpiCard.hours.laborAsset');
        break;
      case ELaborAssetViewDataType.ASSET:
        availableTranslate = this.translate.instant('laborAssetView.kpiCard.available.asset');
        hoursTranslate = this.translate.instant('laborAssetView.kpiCard.hours.asset');
        break;
      case ELaborAssetViewDataType.LABOR:
        availableTranslate = this.translate.instant('laborAssetView.kpiCard.available.labor');
        hoursTranslate = this.translate.instant('laborAssetView.kpiCard.hours.labor');
        break;
    }

    return [
      {
        name: ELaborAssetViewWidget.TOTAL_CHECK_IN,
        type: PageConfigurationTypes.WIDGET,
        selected: true,
        disabled: true,
        content: {
          type: EWidgetType.KPI_CARD,
          pageConfigurationTitle: this.translate.instant('laborAssetView.kpiCard.totalCheckIn'),
          header: [
            {
              type: EKpiCardContentType.TEXT,
              value: this.translate.instant('laborAssetView.kpiCard.totalCheckIn'),
            },
            {
              type: EKpiCardContentType.ICON,
              value: 'fas fa-check-square',
            },
          ],
          body: [
            {
              type: EKpiCardContentType.TEXT,
              value: this.translate.instant('general.N/A'),
            },
          ],
        },
      },
      {
        name: ELaborAssetViewWidget.AVAILABLE,
        type: PageConfigurationTypes.WIDGET,
        selected: true,
        disabled: true,
        content: {
          type: EWidgetType.KPI_CARD,
          pageConfigurationTitle: availableTranslate,
          header: [
            {
              type: EKpiCardContentType.TEXT,
              value: availableTranslate,
            },
            {
              type: EKpiCardContentType.ICON,
              value: 'fas fa-users-cog',
            },
          ],
          body: [
            {
              type: EKpiCardContentType.TEXT,
              value: this.translate.instant('general.N/A'),
            },
          ],
        },
      },
      {
        name: ELaborAssetViewWidget.HOURS,
        type: PageConfigurationTypes.WIDGET,
        selected: true,
        disabled: true,
        content: {
          type: EWidgetType.KPI_CARD,
          pageConfigurationTitle: hoursTranslate,
          header: [
            {
              type: EKpiCardContentType.TEXT,
              value: hoursTranslate,
            },
            {
              type: EKpiCardContentType.ICON,
              value: 'fas fa-user-clock',
            },
          ],
          body: [
            {
              type: EKpiCardContentType.TEXT,
              value: this.translate.instant('general.N/A'),
            },
          ],
        },
      },
    ];
  }

  private getLaborAssetViewModalWidgets(type: ELaborAssetViewDataType): WidgetConfigurationInterface<EWidgetType.KPI_CARD>[] {
    const laborTrackerName: string = this.translate.instant('cico.modules.laborTracker.name');
    const assetTrackerName: string = this.translate.instant('cico.modules.assetTracker.name');
    const shortHour: string = this.translate.instant('general.shortHour');
    const commonWidgetProperties: Pick<WidgetConfigurationInterface<EWidgetType.KPI_CARD>, 'selected' | 'disabled' | 'type'> = {
      selected: true,
      disabled: true,
      type: PageConfigurationTypes.WIDGET,
    };

    const laborAssetViewWidgetDefaults: WidgetConfigurationInterface<EWidgetType.KPI_CARD>[] = [
      {
        ...commonWidgetProperties,
        name: ELaborAssetViewWidget.LABOR,
        content: {
          pageConfigurationTitle: laborTrackerName,
          type: EWidgetType.KPI_CARD,
          header: [
            {
              type: EKpiCardContentType.TEXT,
              value: laborTrackerName,
            },
            {
              type: EKpiCardContentType.ICON,
              value: 'fas fa-users',
            },
          ],
          body: [
            {
              type: EKpiCardContentType.TEXT,
              value: this.translate.instant('general.N/A'),
            },
          ],
        },
      },
      {
        ...commonWidgetProperties,
        name: ELaborAssetViewWidget.LABOR_HOURS,
        content: {
          pageConfigurationTitle: `${laborTrackerName} (${shortHour})`,
          type: EWidgetType.KPI_CARD,
          header: [
            {
              type: EKpiCardContentType.TEXT,
              value: `${laborTrackerName} (${shortHour})`,
            },
            {
              type: EKpiCardContentType.ICON,
              value: 'fas fa-clock',
            },
          ],
          body: [
            {
              type: EKpiCardContentType.TEXT,
              value: this.translate.instant('general.N/A'),
            },
          ],
        },
      },
      {
        ...commonWidgetProperties,
        name: ELaborAssetViewWidget.ASSET,
        content: {
          pageConfigurationTitle: ELaborAssetViewWidget.ASSET,
          type: EWidgetType.KPI_CARD,
          header: [
            {
              type: EKpiCardContentType.TEXT,
              value: assetTrackerName,
            },
            {
              type: EKpiCardContentType.ICON,
              value: 'fas fa-cubes',
            },
          ],
          body: [
            {
              type: EKpiCardContentType.TEXT,
              value: this.translate.instant('general.N/A'),
            },
          ],
        },
      },
      {
        ...commonWidgetProperties,
        name: ELaborAssetViewWidget.ASSET_HOURS,
        content: {
          pageConfigurationTitle: ELaborAssetViewWidget.ASSET_HOURS,
          type: EWidgetType.KPI_CARD,
          header: [
            {
              type: EKpiCardContentType.TEXT,
              value: `${assetTrackerName} (${shortHour})`,
            },
            {
              type: EKpiCardContentType.ICON,
              value: 'fas fa-clock',
            },
          ],
          body: [
            {
              type: EKpiCardContentType.TEXT,
              value: this.translate.instant('general.N/A'),
            },
          ],
        },
      },
      {
        ...commonWidgetProperties,
        name: ELaborAssetViewWidget.AVAILABLE_SHORT,
        content: {
          pageConfigurationTitle: ELaborAssetViewWidget.AVAILABLE_SHORT,
          type: EWidgetType.KPI_CARD,
          header: [
            {
              type: EKpiCardContentType.TEXT,
              value: this.translate.instant('general.available'),
            },
            {
              type: EKpiCardContentType.ICON,
              value: 'fas fa-check-circle',
            },
          ],
          body: [
            {
              type: EKpiCardContentType.TEXT,
              value: this.translate.instant('general.N/A'),
            },
          ],
        },
      },
    ];

    switch (type) {
      case ELaborAssetViewDataType.ALL:
        return laborAssetViewWidgetDefaults;
      case ELaborAssetViewDataType.LABOR:
        return laborAssetViewWidgetDefaults.filter(
          (widget: WidgetConfigurationInterface<EWidgetType.KPI_CARD>): boolean =>
            ![ELaborAssetViewWidget.ASSET, ELaborAssetViewWidget.ASSET_HOURS].includes(
              widget.name as ELaborAssetViewWidget,
            ),
        );
      case ELaborAssetViewDataType.ASSET:
        return laborAssetViewWidgetDefaults.filter(
          (widget: WidgetConfigurationInterface<EWidgetType.KPI_CARD>): boolean =>
            ![ELaborAssetViewWidget.LABOR, ELaborAssetViewWidget.LABOR_HOURS].includes(
              widget.name as ELaborAssetViewWidget,
            ),
        );
    }
  }
}
