import { Action } from '@ngrx/store';
import { HttpOptionInterface } from '../filter.model';
import { ApiServer } from '../../../shared/component/filter/dropdown/dropdown.model';
import { IIotDeviceType } from '../../../shared/service/iot-device-report/iot-device-report.model';

export enum FilterIotDeviceActionTypes {
  StartLoadFilterIotDevice = '[Filter IotDeviceReportDeviceType] Load Filter IotDevice',
  FilterIotDeviceLoaded = '[Filter IotDeviceReportDeviceType] Filter IotDevice Loaded',
}

export class StartLoadFilterIotDevice implements Action {
  readonly type = FilterIotDeviceActionTypes.StartLoadFilterIotDevice;

  constructor(public options: HttpOptionInterface, public server: ApiServer = ApiServer.NestJS) {}
}

export class FilterIotDeviceLoaded implements Action {
  readonly type = FilterIotDeviceActionTypes.FilterIotDeviceLoaded;

  constructor(public data: Partial<IIotDeviceType>[]) {}
}

export type FilterIotDeviceActions = StartLoadFilterIotDevice | FilterIotDeviceLoaded;
