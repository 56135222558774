import { CicoDataInterface } from '../../../store/home/home.model';
import { ScwMatDatepickerReturnInterface } from '../../../shared/component/scw-mat-ui/scw-mat-datepicker/scw-mat-datepicker.model';
import { TagInterface } from '../../../store/settings/tags/tags.model';

export interface ColorDropdownOptionInterface {
  id: number;
  name: string;
  color: string;
}

export interface CheckInFormInterface {
  checkInDateTime: ScwMatDatepickerReturnInterface;
  checkInDateTimeForNative: string;
  checkInObject: SearchOptionInterface[];
  authorizedUser?: SearchOptionInterface[];
  checkInPin: string;
  lineStation?: ILineStationOption[];
  tag?: ColorDropdownOptionInterface [];
}

export interface SearchOptionInterface {
  id: number;
  shortName: string;
  name: string;
  optionText?: string;
}

export interface AssetDropdownOptionInterface {
  id: number;
  shortName: string;
  name: string;
  optionText?: string;
  status?: ColorDropdownOptionInterface;
  tagList?: TagInterface[];
  typeTagList?: TagInterface[];
}

export interface CheckInModuleInterface {
  moduleName: CheckInModules;
  name: string;
  icon: string;
  unavailableText: string;
  noItemTextForOngoing: string;
  noItemTextForLast24Hours: string;
  addIconClass: string;
  homeItems$: CicoDataInterface[];
  homeCount$: number;
  ongoingItems$: CicoDataInterface[];
  last24HourItems$: CicoDataInterface[];
  logItems: CicoDataInterface[];
  searchDropdown: SearchDropdownInterface;
  headerInformation: {
    uniqueCount$: number;
    availableCount: number;
    formattedTotalHour: string;
  };
  statusName?: string;
  statusColor?: string;
}

export interface SearchDropdownInterface {
  label: string;
  placeholder: string;
  noResultMessage: string;
  searchInput: {
    information: string;
    placeholder: string;
  };
}

export interface AvailableCheckInModuleInterface {
  laborTracker: CheckInModuleInterface;
  assetTracker: CheckInModuleInterface;
}

export interface CheckOutFormInterface {
  id?: number;
  name: string;
  status: number;
  checkInTime: string;
  unavailableText: string;
  lineStation: ILineStationOption[];
  sourceTypeId: number;
  sourceObjectId: number;
  checkInPin?: string;
  tag?: ColorDropdownOptionInterface [];
}

export enum CheckInModules {
  laborTracker = 'check-in-users',
  assetTracker = 'check-in-assets',
}

export enum CheckInModuleNames {
  user = 'User',
  asset = 'Asset',
}

export enum CheckInListTypes {
  ongoing = 'ongoingItems$',
  last24Hour = 'last24HourItems$',
}

export enum CheckInSourceTypeEnum {
  SOURCE_TYPE_USER = '1',
  SOURCE_TYPE_EQUIPMENT = '2',
}

export enum CheckInDestinationTypeEnum {
  DESTINATION_TYPE_LINE = 1,
  DESTINATION_TYPE_LINE_STATION = 2,
}

export interface CheckInOngoingUpdateInterface {
  status: number;
  checkInPin?: string;
  destinationTypeId?: number;
  destinationObjectId?: number;
}

export interface DestinationObjectInterface {
  typeId: CheckInDestinationTypeEnum;
  objectId: number;
}

export enum CheckInOperationTypes {
  CheckIn = 'checkIn',
  CheckOut = 'checkOut',
  CheckOutAll = 'checkOutAll',
  Save = 'save',
  CheckInBulk = 'checkInBulk',
  BulkEdit = 'bulkEdit',
  UsersAreAutomaticallyCheckOut = 'usersAreAutomaticallyCheckOut',
}

export interface CheckInSignalrDataInterface {
  id: number;
  name: string;
  operationType: CheckInOperationTypes;
  targetLine: number;
  count?: number;
  isHaveFailedCheckIns?: boolean;
}

export interface IEditUserCheckInForm extends CheckInFormInterface {
  checkOutDateTime: ScwMatDatepickerReturnInterface;
  checkOutDateTimeForNative: string;
}

export interface IBulkEditModal {
  isBulkEditModalOpenButtonDisabled: boolean;
  isBulkEditSubmitButtonClicked: boolean;
  isCheckInTimeChecked: boolean;
  isCheckOutTimeChecked: boolean;
  isBulkOperation: boolean;
  selectedItemCount: number;
  searchBoxLabel: string;
  requestedCheckInIds: number[];
}

export interface ICicoBulkEdit {
  isSelected: boolean;
  isDisabled: boolean;
}

export interface ILineStationOption {
  id: number;
  name: string;
  optionText?: string;
  label?: string;
  value?: string;
}

export interface IFailedAndSuccessfulUsers {
  failedUserNames: string[];
  successfulUserNames: string[];
}

export interface IFormattedBulkEditResponse {
  successfulCheckIns: CicoDataInterface[];
  failedCheckIns: CicoDataInterface[];
}
