import { Inject, Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import {
  BaseOneResponseInterface,
  GetManyResponseInterface,
} from '../../../shared/model/interface/crud-response-interface.model';
import {
  INotificationLogsDatatableRow,
  INotificationLogsFilterParams,
  INotificationLogsQueryParams,
  TNotificationDataOption,
} from './notification-logs.model';
import { mysqlTimestampFormat } from '../../../shared/helper/date';

@Injectable({
  providedIn: 'root',
})
export class NotificationLogsService {
  private readonly routes = {
    getMany: `${this.baseUrl}/notification-logs`,
    preview: `${this.baseUrl}/notification-logs/preview/:id`,
  };
  constructor(@Inject('API_BASE_URL') private readonly baseUrl: string, private readonly http: HttpClient) {}

  public getNotificationLogsData(
    params: HttpParams,
  ): Observable<GetManyResponseInterface<INotificationLogsDatatableRow>> {
    return this.http.get<GetManyResponseInterface<INotificationLogsDatatableRow>>(this.routes.getMany, {
      params,
    });
  }

  public getNotificationLogsPreview(id: string): Observable<BaseOneResponseInterface<TNotificationDataOption>> {
    return this.http.get<BaseOneResponseInterface<TNotificationDataOption>>(this.routes.preview.replace(':id', id));
  }

  public formatNotificationLogsRequestParams(params: INotificationLogsFilterParams): INotificationLogsQueryParams {
    return {
      start: params.dateRange.startDate.format(mysqlTimestampFormat),
      end: params.dateRange.endDate.format(mysqlTimestampFormat),
      ...(params.search ? { search: params.search } : {}),
      ...(params.types && Array.isArray(params.types) ? { types: params.types } : {}),
      ...(params.status && params.status !== -1 ? { status: params.status } : {}),
      ...(params.recipients && Array.isArray(params.recipients) ? { recipients: params.recipients.join(',') } : {}),
    };
  }
}
