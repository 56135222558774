import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, switchMap } from 'rxjs/operators';
import { GetManyResponseInterface } from '../../../shared/model/interface/crud-response-interface.model';
import { of } from 'rxjs';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { OeeAppState } from '../../oee.reducer';
import * as AppActions from '../../app/actions';
import * as ObjectActions from './check-in-timeline.actions';
import { ICheckInTimelineResponseData } from './check-in-timeline.model';
import { CheckInTimelineService } from './check-in-timeline.service';

@Injectable()
export class CheckInTimelineEffects {
  constructor(
    private readonly actions$: Actions,
    private readonly store: Store<OeeAppState>,
    private readonly service: CheckInTimelineService,
  ) {}

  checkInTimelineData = createEffect(() =>
    this.actions$.pipe(
      ofType(ObjectActions.CHECK_IN_TIMELINE_DATA_LOADING),
      switchMap((objectData: ObjectActions.CheckInTimelineDataLoading) => {
        this.store.dispatch(new AppActions.ShowLoader());
        return this.service.getCheckInTimeLineData(objectData.filter).pipe(
          switchMap((response: GetManyResponseInterface<ICheckInTimelineResponseData>) => {
            return of(new ObjectActions.CheckInTimeLineDataLoaded(response.data), new AppActions.HideLoader());
          }),
          catchError((err) => {
            return of(new ObjectActions.FetchError(err), new AppActions.HideLoader());
          }),
        );
      }),
    ),
  );
}
