<app-base-modal [modal]="modal" (onClose)="finishWithoutOperation.emit()">
  <ng-container modal-title>{{ 'main.workOrder.pathOverviewAndModification.header' | translate }}</ng-container>
  <ng-container modal-body>
    <div class="row">
      <div class="col-12 text-left align-items-center m-b-15">
        <em class="fas fa-info-circle p-r-5"></em>
        <label class="m-b-0 f-12">
          {{ 'multiLineActivityChangeModal.informationText' | translate }}
        </label>
      </div>
      <div class="col-12">
        <datatable
          heightMode="fill"
          theme="gray"
          noDataText="{{ 'datatable.noData' | translate }}"
          [clientSide]="true"
          [headers]="tableHeaders"
          [items]="$tableItems"
          [itemsCount]="$tableItems.length"
          [currentPage]="1"
          [rowsPerPage]="10"
          [shouldPaginate]="false"
        >
          <ng-template let-item let-i="index">
            <td *ngFor="let header of tableHeaders; index as i; trackBy: datatableHeaderTrackBy">
              <ng-container *ngIf="header.value === 'finalizeCurrentWorkOrder'; else else1">
                <scw-mat-checkbox
                  [(inputModel)]="item.finalizeCurrentWorkOrder"
                  [disabled]="item.isChangeActivitySkipped || !item.currentWorkOrder"
                ></scw-mat-checkbox>
              </ng-container>
              <ng-template #else1>
                <ng-container *ngIf="header.value === 'activity'; else defaultTemplate">
                  <div class="d-flex">
                    <scw-mat-picker
                      class="flex-grow-1 pt-3"
                      label="{{
                        item.isActivityChanged
                          ? ('multiLineChangeActivity.modal.form.label.activityWillBeChangedTo' | translate)
                          : ('multiLineChangeActivity.modal.form.label.currentActivity' | translate)
                      }}:"
                      [errorText]="
                        isFormSubmitted && !item.activityId ? ('scwMatForm.validation.required' | translate) : ''
                      "
                      [hasErrors]="isFormSubmitted && !item.activityId"
                      [disabled]="item.isChangeActivitySkipped"
                      [plannedDownTimeActivityColors]="plannedDownTimeActivityInputColors$ | async"
                      [(inputModel)]="item.activityNameAndActivityTaskName"
                      (onClick)="openActivitySelectionModal(activity_selection_modal, item.lineId)"
                    ></scw-mat-picker>
                  </div>
                </ng-container>
              </ng-template>
              <ng-template #defaultTemplate>
                {{ item[header.value] }}
              </ng-template>
            </td>
          </ng-template>
        </datatable>
      </div>
    </div>
  </ng-container>
  <ng-container modal-footer>
    <div class="buttons-right">
      <scw-mat-button type="standard" (onClick)="finishWithoutOperation.emit()">
        {{ 'general.cancel' | translate }}
      </scw-mat-button>
      <scw-mat-button (onClick)="onSubmit()">
        {{ 'general.confirm' | translate }}
      </scw-mat-button>
    </div>
  </ng-container>
</app-base-modal>

<ng-template #activity_selection_modal let-modal>
  <div class="modal-header scw-modal-header">
    <h3 class="modal-title scw-header">{{ 'activityCards.selectActivity' | translate }}</h3>
    <button type="button" class="close" onfocus="blur()" aria-label="Close" (click)="modal.dismiss()">
      <span aria-hidden="true">
        <em class="fas fa-times"></em>
      </span>
    </button>
  </div>
  <div class="modal-body">
    <app-activity-selection-button
      [lineId]="activitySelectionLineId"
      (editedActivityButtonEvent)="editedActivityButton($event)"
      [isCalledFromActivityHistory]="true"
      [action]="'edit'"
      [allSites]="allSites$ | async"
      [useMultiLineStore]="true"
    >
    </app-activity-selection-button>
  </div>
  <div class="modal-footer">
    <div class="buttons-left">
      <scw-mat-button type="standard" (onClick)="modal.close()">
        {{ 'general.back' | translate }}
      </scw-mat-button>
    </div>
  </div>
</ng-template>
