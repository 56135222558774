import { Observable } from 'rxjs';
import {
  ProductsAdvancedFilter,
  ScenarioWorkOrdersAdvancedFilter,
  WorkOrdersAdvancedFilter,
} from './advanced-filter.class';
import * as oeeAppReducer from '../../../../store/oee.reducer';
import { Store } from '@ngrx/store';
import { IConstantLookUp } from '../../../../view/settings/users/users.model';
import { ScwMatSearchBehavior } from '../../scw-mat-ui/scw-mat-search/scw-mat-search.model';
import { ISelectGeneric } from '../../scw-mat-ui/scw-mat-select/scw-mat-select.model';
import { inject } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ScwMatDatepickerReturnInterface } from '../../scw-mat-ui/scw-mat-datepicker/scw-mat-datepicker.model';

export type AdvancedFilterObjects =
  | WorkOrdersAdvancedFilter
  | ProductsAdvancedFilter
  | ScenarioWorkOrdersAdvancedFilter;

export abstract class AdvancedFilterClass {
  public readonly store: Store<oeeAppReducer.OeeAppState>;
  public readonly translate: TranslateService;
  /**
   * Target end point's type.
   * This property is needed as custom strategy will be used
   * depending on the end-point type.
   */
  public readonly targetEndpoint: TargetEndpoints;

  /**
   * Config key for page's config on `users`.advanced_filter_config JSON field.
   */
  public readonly pageName: string;

  /**
   * Config for fields.
   *
   * Extended config applies for predefined values.
   */
  public readonly fields: IField[];
}

export class AdvancedFilterBaseClass implements Partial<AdvancedFilterClass> {
  public readonly store: Store<oeeAppReducer.OeeAppState> = inject(Store<oeeAppReducer.OeeAppState>);
  public readonly translate: TranslateService = inject(TranslateService);
}

export interface IFilterSetAsDefaultData {
  isActive: boolean;
  operators: IOperatorSelect[];
  selectedOperator: IOperatorSelect[];
  selectedField: (IFieldSelect | IPredefinedFieldSelect)[];
  selectedFieldType: InputTypes;
  value: any | null;
  showSearchBox?: ISearchDropdown;
  isDbPath?: boolean;
  isHidden?: boolean;
}

export interface IFilter extends IFilterSetAsDefaultData {
  isFieldToFieldComparisonEnabled: boolean;
  isCompareMethodChangeButtonVisible: boolean;
  sameGroupFieldDropdownItems: ISelectGeneric<string, string>[];
}

export enum SelectAllTypes {
  select = 'select',
  deSelect = 'de-select',
}

export interface IOperator {
  name: string;
  sql: SqlOperators;
  type: OperatorTypes;
  showSearchBox?: ISearchDropdown;
}

export interface IOperatorsFor extends IFieldToFieldCompareOperators {
  string: IOperator[];
  boolean: IOperator[];
  predefined: IOperator[];
  dateFormat: IOperator[];
}

export interface IFieldToFieldCompareOperators {
  number: IOperator[];
  decimal: IOperator[];
  date: IOperator[];
  duration: IOperator[];
  datetime: IOperator[];
}

export enum Operator {
  isBlank = 'isBlank',
  isNotBlank = 'isNotBlank',
  equals = 'equals',
  notEqual = 'notEquals',
  greaterThan = 'greaterThan',
  greaterThanOrEqual = 'greaterThanOrEqual',
  lessThan = 'lessThan',
  lessThanOrEqual = 'lessThanOrEqual',
  between = 'between',
  contains = 'contains',
  doesNotContain = 'doesNotContain',
}

export interface IPredefinedSelect {
  id: number | string;
  key: string;
  name?: string;
  value?: string;
}

export interface IOperatorSelect {
  id: number;
  operator: string;
  translatedOperator: string;
  type: OperatorTypes;
  sql: SqlOperators;
  showSearchBox?: ISearchDropdown;
}

export interface IFieldSelect {
  id: number;
  field: string;
  label: string;
  type: FieldTypes;
  queryType: QueryTypes;
  path: string;
  excludeOperators?: SqlOperators[];
  showSearchBox?: ISearchDropdown;
  isDbPath?: boolean;
  defaultStringValue?: string;
  defaultDateRangeValue?: ScwMatDatepickerReturnInterface;
}

export interface IPredefinedFieldSelect extends IFieldSelect {
  type: FieldTypes.predefined;
  predefinedValues?: IPredefinedSelect[];
  predefinedValuesObservable?: Observable<unknown>;
  observableIdProperty?: string;
  observableNameProperty?: string;
  observableKeyProperty?: string;
  searchBy?: string;
  showSearchBox?: ISearchDropdown;
}

export interface IHiddenField {
  atIndex: number;
  field: IFieldSelect | IPredefinedFieldSelect;
}
export interface IField {
  /**
   * Translation key for field.
   */
  key: string;

  /**
   * Field's path on entity.
   */
  path: string;

  /**
   * Field type. Add all of optional properties if using 'predefined' value.
   */
  type: FieldTypes;

  /**
   *
   */
  queryType: QueryTypes;

  /**
   * Predefined values of field type 'predefined'. \
   * Either accepts IPredefinedSelect array interface or any store select function.
   */
  predefinedValues?: IPredefinedSelect[] | IConstantLookUp[] | Observable<unknown>;

  /**
   * Id property of returned object item.
   *
   * Only necessary if an observable passed to predefinedValues param.
   */
  observableIdProperty?: string;

  /**
   * Name property of returned object item.
   *
   * Only necessary if an observable passed to predefinedValues param.
   */
  observableNameProperty?: string;

  /**
   * Translation key property of returned object item.
   *
   * Only necessary if an observable passed to predefinedValues param.
   */
  observableKeyProperty?: string;

  /**
   * Sometimes you might need to send a request with \
   * predefined value's id or name or some other property.
   *
   * This property sets which property to send a request with.
   */
  searchBy?: string;

  /**
   * Excludes sql operators.
   */
  excludeOperators?: SqlOperators[];
  showSearchBox?: ISearchDropdown;

  /**
   * Uses path parameter directly in the raw query if this parameter is true.
   */
  isDbPath?: boolean;
  groupId?: WorkOrderFieldToFieldCompareGroups;
  defaultStringValue?: string;
  defaultDateRangeValue?: ScwMatDatepickerReturnInterface;
}

export enum WorkOrderFieldToFieldCompareGroups {
  Date = 'Date',
  Count = 'Count',
  Apq = 'Apq',
  Duration = 'Duration',
}

export enum QueryTypes {
  withinAdvancedFilterParams = 0,
  withinRequestQueryString = 1,
}

export enum FieldTypes {
  string = 'string',
  number = 'number',
  decimal = 'decimal',
  date = 'date',
  dateTime = 'datetime',
  predefined = 'predefined',
  boolean = 'boolean',
  duration = 'duration',
  dateFormat = 'dateFormat',
}

export enum InputTypes {
  text = 'text',
  number = 'number',
  decimal = 'decimal',
  date = 'date',
  dateTime = 'date-time',
  select = 'select',
  multiSelect = 'multi-select',
  checkbox = 'checkbox',
  duration = 'duration',
  dateFormat = 'dateFormat',
}

export enum OperatorTypes {
  string = 'string',
  boolean = 'boolean',
  range = 'range',
}

export interface IFilterOutput {
  path: string;
  type: FieldTypes;
  searchBy?: string;
  operator: { name: SqlOperators; type: OperatorTypes };
  queryType: QueryTypes;
  value: any;
  isFieldToFieldComparisonEnabled?: boolean;
  isDbPath?: boolean;
}

export enum SqlOperators {
  $eq = '$eq',
  $ne = '$ne',
  $gt = '$gt',
  $lt = '$lt',
  $gte = '$gte',
  $lte = '$lte',
  $cont = '$cont',
  $excl = '$excl',
  $isnull = '$isnull',
  $notnull = '$notnull',
  $between = '$between',
  $in = '$in',
}

export enum RawSqlOperators {
  EQUALS = '=',
  NOT_EQUAL = '<>',
  AND = 'AND',
  GREATER_THAN = '>',
  GREATER_THAN_OR_EQUAL = '>=',
  LESS_THAN = '<',
  LESS_THAN_OR_EQUAL = '<=',
  BETWEEN = 'BETWEEN',
  IN = 'IN(:values)',
  IS_NOT_NULL = 'IS NOT NULL',
  IS_NULL = 'IS NULL',
  LIKE = 'LIKE',
  NOT_LIKE = 'NOT LIKE',
}

export interface IDefaultFilters {
  [page: string]: IFilter[];
}

export interface IAdvancedFilterDefaultsResponse {
  advancedFilterConfig: IDefaultFilters;
}

export enum TargetEndpoints {
  NestJSCrud = 'nestjs-crud',
  Custom = 'custom',
}

export interface IAdvancedFilterOutput {
  filters: IFilterOutput[];
  rawFilters?: IFilter[];
  target: TargetEndpoints;
  page: string;
}

export enum SubmitTypes {
  setAsDefault = 0,
  apply = 1,
}

export interface IAddFilterOptions {
  isActive: boolean;
}

export interface ISearchDropdown {
  showSearchBox: boolean;
  searchBehaviour: ScwMatSearchBehavior;
}

export interface IAdvancedFilterParam {
  path: string;
  fieldType: FieldTypes;
  operator: RawSqlOperators;
  operatorType: OperatorTypes;
  value: any;
}

export interface ICustomEndpointAdvancedFilterParams {
  $and: IAdvancedFilterParam[];
}
