import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, switchMap } from 'rxjs/operators';
import { from, of } from 'rxjs';
import * as ObjectActions from './site.actions';
import { SiteService } from '../../../shared/service/filter/site.service';
import { SiteCRUDInterface } from '../../../shared/component/filter/filter.class';
import { generateHttpOption } from '../../../shared/helper/app-helper';
import { GenericHelperService } from '../../../shared/service/generic.helper.service';

@Injectable()
export class FilterSiteEffects {
  loadFilterSites$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ObjectActions.FilterSiteActionTypes.StartLoadFilterSites),
      switchMap((objectData: ObjectActions.StartLoadFilterSites) => {
        const params = generateHttpOption(objectData.options);
        return from(this.siteService.getData(params)).pipe(
          switchMap((response: SiteCRUDInterface[]) => {
            const data: SiteCRUDInterface[] = response.sort(GenericHelperService.dropdownOptionCompareFunction);

            return of(new ObjectActions.FilterSitesLoaded(data));
          }),
          catchError(() => {
            return of(new ObjectActions.FilterSitesLoaded([]));
          }),
        );
      }),
    ),
  );

  constructor(private actions$: Actions<ObjectActions.FilterSiteActions>, public siteService: SiteService) {}
}
