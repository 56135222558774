import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { OeeAppState } from '../../oee.reducer';
import * as AppActions from '../../app/actions';
import * as OeeWaterfallAnalysisActions from './oee-waterfall-analysis.actions';
import { from, Observable, of } from 'rxjs';
import { GetManyResponseInterface } from '../../../shared/model/interface/crud-response-interface.model';
import {
  IOeeCalculationResultFormatted,
  ResponseInterface,
} from '../../../shared/model/interface/generic-api-response.model';
import { catchError, filter, switchMap } from 'rxjs/operators';
import { OeeWaterfallAnalysisService } from './oee-waterfall-analysis.service';
import { IRootCauseAnalysisChartNode } from '../root-cause-analysis/root-cause-analysis.model';
import { EntityTranslatorService } from '../../../shared/service/entity-translator/entity-translator.service';
import { ITargetDatum } from './oee-waterfall-analysis.model';
import { emptyAction } from '../../../../constants';
import { HelperService } from '../../../shared/service/helper.service';
import { ActivityTypes } from '../../../shared/model/enum/activity-types';

@Injectable()
export class OeeWaterfallAnalysisEffects {
  constructor(
    private readonly actions$: Actions,
    public service: OeeWaterfallAnalysisService,
    private readonly store: Store<OeeAppState>,
    private readonly entityTranslatorService: EntityTranslatorService,
  ) {}

  getOeeData: Observable<
    OeeWaterfallAnalysisActions.FetchError | AppActions.HideLoader | OeeWaterfallAnalysisActions.OeeDataLoaded
  > = createEffect(() =>
    this.actions$.pipe(
      ofType(OeeWaterfallAnalysisActions.OEE_WATERFALL_ANALYSIS_OEE_DATA_LOADING),
      switchMap((objectData: OeeWaterfallAnalysisActions.OeeDataLoading) => {
        this.store.dispatch(new AppActions.ShowLoader());

        return this.service.getOeeData(objectData.params).pipe(
          switchMap((response: GetManyResponseInterface<IOeeCalculationResultFormatted>) => {
            return of(new OeeWaterfallAnalysisActions.OeeDataLoaded(response.data?.[0]), new AppActions.HideLoader());
          }),
          catchError((errorResponse) => {
            return of(new OeeWaterfallAnalysisActions.FetchError(errorResponse), new AppActions.HideLoader());
          }),
        );
      }),
      catchError((errorResponse) => {
        return of(new OeeWaterfallAnalysisActions.FetchError(errorResponse), new AppActions.HideLoader());
      }),
    ),
  );

  getRootCauseAnalysisData: Observable<
    | OeeWaterfallAnalysisActions.FetchError
    | AppActions.HideLoader
    | OeeWaterfallAnalysisActions.RootCauseAnalysisDataLoaded
  > = createEffect(() =>
    this.actions$.pipe(
      ofType(OeeWaterfallAnalysisActions.OEE_WATERFALL_ANALYSIS_ROOT_CAUSE_ANALYSIS_DATA_LOADING),
      switchMap((objectData: OeeWaterfallAnalysisActions.RootCauseAnalysisDataLoading) => {
        this.store.dispatch(new AppActions.ShowLoader());

        return from(this.service.getRootCauseAnalysisData(objectData.params)).pipe(
          switchMap((response: ResponseInterface<IRootCauseAnalysisChartNode[]>) => {
            response.data = response.data.filter(node => node.activityType !== ActivityTypes.RUN_TIME);
            response.data.forEach((rootCauseAnalysisChartNode: IRootCauseAnalysisChartNode) => {
              this.entityTranslatorService.translate(rootCauseAnalysisChartNode);
            });
            return of(
              new OeeWaterfallAnalysisActions.RootCauseAnalysisDataLoaded(response.data),
              new AppActions.HideLoader(),
            );
          }),
          catchError((errorResponse) => {
            return of(new OeeWaterfallAnalysisActions.FetchError(errorResponse), new AppActions.HideLoader());
          }),
        );
      }),
      catchError((errorResponse) => {
        return of(new OeeWaterfallAnalysisActions.FetchError(errorResponse), new AppActions.HideLoader());
      }),
    ),
  );

  getTargetData: Observable<
    OeeWaterfallAnalysisActions.FetchError | AppActions.HideLoader | OeeWaterfallAnalysisActions.TargetsDataLoaded
  > = createEffect(() =>
    this.actions$.pipe(
      ofType(OeeWaterfallAnalysisActions.OEE_WATERFALL_ANALYSIS_TARGETS_DATA_LOADING),
      switchMap((objectData: OeeWaterfallAnalysisActions.TargetsDataLoading) => {
        this.store.dispatch(new AppActions.ShowLoader());

        return this.service.getTargetData(objectData.queryParameters).pipe(
          switchMap((response: GetManyResponseInterface<ITargetDatum>) => {
            return of(new OeeWaterfallAnalysisActions.TargetsDataLoaded(response), new AppActions.HideLoader());
          }),
          catchError((errorResponse) => {
            return of(new OeeWaterfallAnalysisActions.FetchError(errorResponse), new AppActions.HideLoader());
          }),
        );
      }),
      catchError((errorResponse) => {
        return of(new OeeWaterfallAnalysisActions.FetchError(errorResponse), new AppActions.HideLoader());
      }),
    ),
  );

  downloadExcel: Observable<OeeWaterfallAnalysisActions.FetchError | AppActions.EmptyAction> = createEffect(() =>
    this.actions$.pipe(
      ofType(OeeWaterfallAnalysisActions.OEE_WATERFALL_ANALYSIS_DOWNLOAD_EXCEL),
      switchMap((objectData: OeeWaterfallAnalysisActions.DownloadExcel) => {
        this.service.downloadExcel(
          ...HelperService.argumentClone(objectData.filterData, objectData.activityData, objectData.taskData),
        );
        return emptyAction;
      }),
      catchError((errorResponse) => {
        return of(new OeeWaterfallAnalysisActions.FetchError(errorResponse));
      }),
    ),
  );
}
