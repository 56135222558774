import { IStationActivityHistoryState } from './station-activity-history.model';
import * as StationActivityHistoryActions from './station-activity-history.actions';

export const stationActivityHistoryInitialState: IStationActivityHistoryState = {
  tempPartialHomeStation: undefined,
  ongoingStationActivityHistory: undefined,
  noActivityStation: undefined,
  isChangeStationActivityLoading: false,
  filters: null,
};

export function stationActivityHistoryReducer(
  state: IStationActivityHistoryState = stationActivityHistoryInitialState,
  action: StationActivityHistoryActions.StationActivityHistoryActions,
): IStationActivityHistoryState {
  switch (action.type) {
    case StationActivityHistoryActions.ActionTypes.GET_NO_ACTIVITY_STATION_LOADED:
      return {
        ...state,
        ongoingStationActivityHistory: undefined,
        noActivityStation: action.response.data,
      };
    case StationActivityHistoryActions.ActionTypes.GET_ACTIVITY_AND_LINE_LOADED:
      return {
        ...state,
        noActivityStation: undefined,
        ongoingStationActivityHistory: { ...state.tempPartialHomeStation, ...action.activityAndLine },
      };
    case StationActivityHistoryActions.ActionTypes.GET_ONGOING_STATION_ACTIVITY_HISTORY_LOADED:
      return action.response.data?.length
        ? { ...state, tempPartialHomeStation: action.response.data[0] }
        : { ...state };
    case StationActivityHistoryActions.ActionTypes.STATION_ACTIVITY_HISTORY_ACTIVITY_CHANGE_LOADING:
      return {
        ...state,
        isChangeStationActivityLoading: true,
      };
    case StationActivityHistoryActions.ActionTypes.STATION_ACTIVITY_HISTORY_ACTIVITY_CHANGE_LOADED:
      return {
        ...state,
        isChangeStationActivityLoading: false,
      };
    case StationActivityHistoryActions.ActionTypes.CHANGE_STATION_ACTIVITY_ERROR:
      return {
        ...state,
        isChangeStationActivityLoading: false,
      };
    case StationActivityHistoryActions.ActionTypes.SET_SELECTED_FILTERS:
      return {
        ...state,
        filters: action.filters,
      };
    case StationActivityHistoryActions.ActionTypes.RESET_STORE:
      return {
        ...stationActivityHistoryInitialState,
      };
    default:
      return state;
  }
}
