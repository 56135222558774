import { Inject, Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, switchMap, take } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import * as ObjectActions from './check-in.actions';
import { of } from 'rxjs';
import { CICOService } from '../../shared/service/cico/cico.service';
import {
  CheckInGetOneCRUDDataInterface,
  CheckInGetOneInterface,
  CheckInsResponseInterface,
  IBulkCheckInResponseData,
} from './check-in.model';
import * as AppActions from '../app/actions';
import {
  BaseOneResponseInterface,
  BulkResponseDataInterface,
} from '../../shared/model/interface/crud-response-interface.model';
import { User } from '../user/model';
import { Store } from '@ngrx/store';
import { OeeAppState } from '../oee.reducer';
import { EntityTranslatorService } from '../../shared/service/entity-translator/entity-translator.service';

@Injectable()
export class CheckInEffects {
  private timezone$: string;
  constructor(
    @Inject('API_BASE_URL') private readonly baseUrl: string,
    private readonly actions$: Actions,
    private readonly http: HttpClient,
    private readonly service: CICOService,
    private readonly store: Store<OeeAppState>,
    private readonly entityTranslatorService: EntityTranslatorService,
  ) {}

  getCheckInHomeData = createEffect(() =>
    this.actions$.pipe(
      ofType(ObjectActions.CHECK_IN_HOME_DATA_LOADING),
      switchMap((objectData: ObjectActions.CheckInHomeDataLoading) => {
        return this.service.getCheckInHomeData(objectData.lineId, objectData.stationId).pipe(
          switchMap((response: CheckInsResponseInterface) => {
            this.bindEquipmentTranslations(response);

            return of(new ObjectActions.CheckInHomeDataLoaded(response, objectData.additionalData));
          }),
          catchError((err) => {
            return of(new ObjectActions.CheckInHomeFetchError(err));
          }),
        );
      }),
    ),
  );

  getCheckInLogData = createEffect(() =>
    this.actions$.pipe(
      ofType(ObjectActions.CHECK_IN_LOG_DATA_LOADING),
      switchMap((objectData: ObjectActions.CheckInLogDataLoading) => {
        this.store
          .select('user')
          .pipe(take(1))
          .subscribe((state: User) => {
            this.timezone$ = state.timezone;
          });
        return this.service
          .getCheckInLogData(objectData.lineId, this.timezone$, objectData.isLast24HoursSelected, objectData.stationId)
          .pipe(
            switchMap((response: CheckInsResponseInterface) => {
              this.bindEquipmentTranslations(response);

              return of(new ObjectActions.CheckInLogDataLoaded(response, objectData.additionalData));
            }),
            catchError((err) => {
              return of(new ObjectActions.CheckInLogFetchError(err));
            }),
          );
      }),
    ),
  );

  checkIn = createEffect(() =>
    this.actions$.pipe(
      ofType(ObjectActions.CHECK_IN_DATA_LOADING),
      switchMap((objectData: ObjectActions.CheckInLoading) => {
        return this.service.checkIn(objectData.data).pipe(
          switchMap((response: BaseOneResponseInterface<CheckInGetOneInterface>) => {
            return of(new ObjectActions.CheckInLoaded(response), new AppActions.HideLoader());
          }),
          catchError((err) => {
            return of(new ObjectActions.CheckInOperationFetchError(err));
          }),
        );
      }),
    ),
  );

  checkInBulk = createEffect(() =>
    this.actions$.pipe(
      ofType(ObjectActions.CHECK_IN_BULK_DATA_LOADING),
      switchMap((objectData: ObjectActions.CheckInBulkLoading) => {
        return this.service.checkInBulk(objectData.data).pipe(
          switchMap((response: IBulkCheckInResponseData) => {
            return of(new ObjectActions.CheckInBulkLoaded(response, objectData.forMobileComponent), new AppActions.HideLoader());
          }),
          catchError((err) => {
            return of(new ObjectActions.CheckInOperationFetchError(err));
          }),
        );
      }),
      catchError((errorRes) => {
        return of(new ObjectActions.CheckInOperationFetchError(errorRes));
      }),
    ),
  );

  checkOut = createEffect(() =>
    this.actions$.pipe(
      ofType(ObjectActions.CHECK_OUT_DATA_LOADING),
      switchMap((objectData: ObjectActions.CheckOutLoading) => {
        return this.service.checkOut(objectData.id, objectData.data).pipe(
          switchMap((response: BaseOneResponseInterface<CheckInGetOneInterface>) => {
            return of(new ObjectActions.CheckOutLoaded(response));
          }),
          catchError((err) => {
            return of(new ObjectActions.CheckInOperationFetchError(err));
          }),
        );
      }),
    ),
  );

  updateOngoingCheckIn = createEffect(() =>
    this.actions$.pipe(
      ofType(ObjectActions.CHECK_OUT_UPDATE_ONGOING_LOADING),
      switchMap((objectData: ObjectActions.CheckOutUpdateOngoingLoading) => {
        return this.service.updateOngoingCheckIn(objectData.id, objectData.data).pipe(
          switchMap((response: BaseOneResponseInterface<CheckInGetOneInterface>) => {
            return of(new ObjectActions.CheckOutUpdateOngoingLoaded(response));
          }),
          catchError((err) => {
            return of(new ObjectActions.CheckInOperationFetchError(err));
          }),
        );
      }),
    ),
  );

  updateBulkOngoingCheckIns = createEffect(() =>
    this.actions$.pipe(
      ofType(ObjectActions.CHECK_IN_BULK_EDIT_CHECK_IN_LOADING),
      switchMap((objectData: ObjectActions.BulkEditOngoingCheckInLoading) => {
        this.store.dispatch(new AppActions.ShowLoader());
        return this.service.updateBulkOngoingCheckIns(objectData.body).pipe(
          switchMap((response: BulkResponseDataInterface) => {
            return of(new ObjectActions.BulkEditOngoingCheckInLoaded(response), new AppActions.HideLoader());
          }),
          catchError((err) => {
            return of(new ObjectActions.CheckInOperationFetchError(err), new AppActions.HideLoader());
          }),
        );
      }),
    ),
  );

  checkOutBulkOngoingCheckIns = createEffect(() =>
    this.actions$.pipe(
      ofType(ObjectActions.CHECK_IN_BULK_CHECK_OUT_LOADING),
      switchMap((objectData: ObjectActions.BulkEditOngoingCheckOutLoading) => {
        this.store.dispatch(new AppActions.ShowLoader());
        return this.service.checkOutBulkOngoingCheckIns(objectData.body).pipe(
          switchMap((response: BulkResponseDataInterface) => {
            return of(new ObjectActions.BulkEditOngoingCheckOutLoaded(response), new AppActions.HideLoader());
          }),
          catchError((err) => {
            return of(new ObjectActions.CheckInOperationFetchError(err), new AppActions.HideLoader());
          }),
        );
      }),
    ),
  );

  private bindEquipmentTranslations(response: CheckInsResponseInterface): void {
    const equipmentCheckIns: CheckInGetOneCRUDDataInterface[] = response.data?.equipmentCheckIns?.data ?? [];

    equipmentCheckIns.forEach((checkIn: CheckInGetOneCRUDDataInterface) =>
      this.entityTranslatorService.translate(checkIn),
    );
  }
}
