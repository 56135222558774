<scw-mat-form (scwMatFormSubmit)="submit($event)">
  <div class="modal-body phase-comment-modal-body">
    <div class="row">
      <div class="col-12">
        <scw-mat-input
          [label]="'homeScreenMetrics.phaseComment.workOrder' | translate"
          [rules]="[{ required: true }]"
          [disabled]="true"
          [(inputModel)]="form.workOrder$"
        ></scw-mat-input>
      </div>
      <div class="col-12">
        <scw-mat-input
          [label]="'homeScreenMetrics.phaseComment.phase' | translate"
          [disabled]="true"
          [ngClass]="phaseFieldClass"
          [(inputModel)]="phaseFieldText"
        ></scw-mat-input>
      </div>
      <div class="col-12">
        <scw-mat-textarea
          label="{{ 'homeScreenMetrics.phaseComment.comment' | translate }}*"
          [rows]="5"
          [block]="true"
          [rules]="[{ required: true }, { maxLength: 65535 }]"
          [(inputModel)]="form.comment"
          [className]="['phase-comment']"
          data-automation-id="phase-comment-field"
        ></scw-mat-textarea>
      </div>
      <p class="file-length-warning ml-3 mb-1" *ngIf="files?.length >= 5">
        <em class="fas fa-info-circle fa-lg float-left mr-1"></em> {{ 'fileUpload.fileLength.warning' | translate }}
      </p>

      <div class="col-12 display-image-container">
        <ng-container>
          <scw-image-upload-button [disabled]="files?.length >= 5" [fileCount]="files.length"></scw-image-upload-button>
        </ng-container>
        <div class="images-container">
          <div *ngFor="let image of files; let i = index">
            <div *ngIf="image.original && image.type === EFileType.IMAGE">
              <div class="display-image-container">
                <div class="image-wrapper">
                  <img
                    [src]="image.original"
                    alt="Base64 Image"
                    class="fit-image"
                    (click)="openPreviewModal(image, i)"
                  />
                  <button class="delete-button" (click)="showDeleteItemModal(file_delete_confirmation_modal, image)">
                    <i class="fas fa-trash" style="color: #ffffff"></i>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-12 ml-3">
        <div class="row mr-1">
          <ng-container *ngFor="let file of files">
            <div class="col-md-6 w-100 p-0" *ngIf="file.original && file.type === EFileType.PDF">
              <div class="d-flex border rounded mt-2 mr-2">
                <div class="d-flex align-items-center m-1">
                  <i class="fas fa-file-pdf fa-lg mx-2"></i>
                  <div class="row">
                    <span class="col-12 truncate-span filename-text" (click)="downloadPdf(file)">
                      {{ file.fileOriginalName }}</span
                    >
                    <span class="col-12 size-text"> {{ file.size }} MB</span>
                  </div>
                  <button
                    (click)="showDeleteItemModal(file_delete_confirmation_modal, file)"
                    class="delete-button-file"
                  >
                    <i class="fas fa-trash" style="color: #2a2828"></i>
                  </button>
                </div>
              </div>
            </div>
          </ng-container>
        </div>
      </div>
      <scw-image-preview-modal
        [siteId]="siteId$"
        (deletedFile)="deleteButtonClickedFromPreview($event)"
        #image_preview_modal
      >
      </scw-image-preview-modal>
    </div>
  </div>
  <div class="modal-footer phase-comment-footer">
    <div class="buttons-right">
      <scw-mat-button type="standard" (onClick)="cancelButtonClicked.emit()" data-automation-id="cancel-btn">
        {{ 'general.cancel' | translate }}
      </scw-mat-button>
      <scw-mat-button
        [disabled]="isUpdatePhaseCommentProcessCompleted"
        [isSubmitButton]="true"
        data-automation-id="save-comment-btn"
      >
        {{ 'homeScreenMetrics.phaseComment.saveComment' | translate }}
      </scw-mat-button>
    </div>
  </div>
</scw-mat-form>

<ng-template #file_delete_confirmation_modal let-modal>
  <div class="modal-header scw-modal-header">
    <h3 class="modal-title scw-header">
      {{ 'fileUpload.delete.header' | translate }}
    </h3>
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss()">
      <span aria-hidden="true">
        <em class="fas fa-times"></em>
      </span>
    </button>
  </div>
  <div class="modal-body">
    <div class="row">
      <div class="col-12 information-text">
        <h6 class="scw-header mt-3">
          <em class="fas fa-exclamation-circle"></em>&nbsp;
          {{ 'fileUpload.delete.info' | translate }}
        </h6>
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <div class="buttons-right">
      <scw-mat-button type="standard" (onClick)="modal.dismiss()">
        {{ 'general.cancel' | translate }}
      </scw-mat-button>
      <scw-mat-button type="danger" (onClick)="deleteImage()">
        {{ 'general.delete' | translate }}
      </scw-mat-button>
    </div>
  </div>
</ng-template>
