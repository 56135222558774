<div
  class="d-flex pt-1 px-3"
  [style.cursor]="item.notification.url ? 'pointer' : undefined"
  (click)="onNotificationClick()"
>
  <img [ngSrc]="iconUrl" width="35" height="35" class="pr-2" />
  <div class="flex-grow-1 pt-1">
    <article>
      <h6 class="mb-1">
        {{ item.notification.title }}
        <span *ngIf="item.notification.url" class="fas fa-link ml-1 text-muted" aria-hidden></span>
      </h6>
      <p class="mb-1">{{ item.notification.body }}</p>
      <p>
        <small class="text-muted">{{ item.createdAt | timestamp : timeOnly }}</small>
      </p>
    </article>
  </div>
  <button class="bg-transparent border-0 small-square pt-1" (click)="onReadButtonClick($event)">
    <div
      [hidden]="item.readAt !== null"
      class="circle active-orange"
      ngbTooltip="{{ 'notificationFeed.action.markAsRead' | translate }}"
      [placement]="['left', 'right']"
      container="body"
    ></div>
  </button>
</div>
