import { Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { GetManyResponseInterface } from '../../shared/model/interface/crud-response-interface.model';
import { IDeviceAndSensors } from './sensor-statuses.model';

@Injectable({
  providedIn: 'root',
})
export class SensorStatusesService {
  constructor(public http: HttpClient, @Inject('API_BASE_URL') private baseUrl: string) {}

  public getSensorStatuses(lineId: number): Observable<GetManyResponseInterface<IDeviceAndSensors>> {
    return this.http.get<GetManyResponseInterface<IDeviceAndSensors>>(
      `${this.baseUrl}/lines/${lineId}/sensor-statuses`,
    );
  }
}
