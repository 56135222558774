import { Component, Input } from '@angular/core';

@Component({
  selector: 'scw-mat-modal-separator',
  templateUrl: './scw-mat-modal-separator.component.html',
  styleUrls: ['./scw-mat-modal-separator.component.scss'],
})
export class ScwMatModalSeparatorComponent {
  @Input() separatorClass?: string;
}
