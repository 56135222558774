import { createAction, props } from '@ngrx/store';
import {
  TFetchStationQuantitiesPayload,
  TFetchStationQuantitiesSuccessData,
  TIncreaseGoodTotalCountPayload,
  TSetCountsPayload,
} from './kpi-card.model';

export const increaseGoodCount = createAction(
  '[Station Home KPI] Increase Good Count',
  props<TIncreaseGoodTotalCountPayload>(),
);

export const increaseTotalCount = createAction(
  '[Station Home KPI] Increase Total Count',
  props<TIncreaseGoodTotalCountPayload>(),
);

export const setCounts = createAction('[Station Home KPI] Set Counts', props<TSetCountsPayload>());

export const fetchStationQuantities = createAction(
  '[Station Home KPI] Fetch Station Quantities',
  props<TFetchStationQuantitiesPayload>(),
);

export const fetchStationQuantitiesSuccess = createAction(
  '[Station Home KPI] Fetch Station Quantities Success',
  props<TFetchStationQuantitiesSuccessData>(),
);

export const fetchStationQuantitiesError = createAction(
  '[Station Home KPI] Fetch Station Quantities Error',
  props<{ error: any }>(),
);
