import { Action } from '@ngrx/store';
import { ActivityLogsResponseInterface, IActivityLogsRequest } from '../root-cause-analysis/root-cause-analysis.model';
import {
  IProductionPerformanceDeepDiveRequest,
  IProductionPerformanceDeepDiveResponse,
  IProductionPerformanceDeepDiveShiftDayRequest,
  IProductionPerformanceDeepDiveShiftDayResponse,
} from './performance-deep-dive.model';

export enum PerformanceDeepDiveActionTypes {
  PerformanceDeepDiveActivityLogsLoading = '[PerformanceDeepDive] Activity Logs Loading',
  PerformanceDeepDiveActivityLogsLoaded = '[PerformanceDeepDive] Activity Logs Loaded',
  ProductionPerformanceDeepDiveDataLoading = '[PerformanceDeepDive] Production Performance Deep Dive Data Loading',
  ProductionPerformanceDeepDiveDataLoaded = '[PerformanceDeepDive] Production Performance Deep Dive Data Loaded',
  ProductionPerformanceDeepDiveShiftDayDataLoading = '[PerformanceDeepDive] Production Performance Deep Dive Shift Day Data Loading',
  ProductionPerformanceDeepDiveShiftDayDataLoaded = '[PerformanceDeepDive] Production Performance Deep Dive Shift Day Data Loaded',
  FetchError = '[PerformanceDeepDive] Fetch Error',
}

export class PerformanceDeepDiveActivityLogsLoading implements Action {
  readonly type = PerformanceDeepDiveActionTypes.PerformanceDeepDiveActivityLogsLoading;

  constructor(public params: IActivityLogsRequest) {}
}

export class PerformanceDeepDiveActivityLogsLoaded implements Action {
  readonly type = PerformanceDeepDiveActionTypes.PerformanceDeepDiveActivityLogsLoaded;

  constructor(public payload: ActivityLogsResponseInterface) {}
}

export class ProductionPerformanceDeepDiveDataLoading implements Action {
  readonly type = PerformanceDeepDiveActionTypes.ProductionPerformanceDeepDiveDataLoading;

  constructor(public lineId: number, public params: IProductionPerformanceDeepDiveRequest) {}
}

export class ProductionPerformanceDeepDiveDataLoaded implements Action {
  readonly type = PerformanceDeepDiveActionTypes.ProductionPerformanceDeepDiveDataLoaded;

  constructor(public payload: IProductionPerformanceDeepDiveResponse) {}
}

export class ProductionPerformanceDeepDiveShiftDayDataLoading implements Action {
  readonly type = PerformanceDeepDiveActionTypes.ProductionPerformanceDeepDiveShiftDayDataLoading;

  constructor(public lineId: number, public params: IProductionPerformanceDeepDiveShiftDayRequest) {}
}

export class ProductionPerformanceDeepDiveShiftDayDataLoaded implements Action {
  readonly type = PerformanceDeepDiveActionTypes.ProductionPerformanceDeepDiveShiftDayDataLoaded;

  constructor(public payload: IProductionPerformanceDeepDiveShiftDayResponse[]) {}
}

export class FetchError implements Action {
  readonly type = PerformanceDeepDiveActionTypes.FetchError;

  constructor(public payload: object) {}
}

export type PerformanceDeepDiveActions =
  | PerformanceDeepDiveActivityLogsLoading
  | PerformanceDeepDiveActivityLogsLoaded
  | ProductionPerformanceDeepDiveDataLoading
  | ProductionPerformanceDeepDiveDataLoaded
  | ProductionPerformanceDeepDiveShiftDayDataLoading
  | ProductionPerformanceDeepDiveShiftDayDataLoaded
  | FetchError;
