import { Inject, Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { ResponseInterface } from '../../model/interface/generic-api-response.model';
import { ICurrentLine, IOngoingActivityTotalDuration } from '../../../store/line/model';
import {
  BaseOneResponseInterface,
  BulkResponseDataInterface,
  GetManyResponseInterface,
} from '../../model/interface/crud-response-interface.model';
import { LineCRUDInterface } from '../../component/filter/filter.class';
import { IRemoveWorkOrderRequestBody } from '../../../store/home/home.model';

@Injectable({
  providedIn: 'root',
})
export class LineService {
  constructor(public readonly http: HttpClient, @Inject('API_BASE_URL') private readonly api: string) {}

  private readonly routes = {
    line: `${this.api}/lines`,
    ongoingActivityTotalDuration: `ongoing-activity-total-duration`,
  };

  public getLines(httpParams?: HttpParams): Observable<GetManyResponseInterface<LineCRUDInterface>> {
    return this.http.get<GetManyResponseInterface<LineCRUDInterface>>(this.routes.line, {
      params: httpParams,
    });
  }

  public updateLine(id: number, body: IRemoveWorkOrderRequestBody): Observable<BulkResponseDataInterface> {
    return this.http.patch<BulkResponseDataInterface>(`${this.routes.line}/${id}`, body);
  }

  public getOngoingActivityTotalDuration(
    lineId: number,
    httpParams?: HttpParams,
  ): Observable<BaseOneResponseInterface<IOngoingActivityTotalDuration>> {
    return this.http.get<BaseOneResponseInterface<IOngoingActivityTotalDuration>>(
      `${this.routes.line}/${lineId}/${this.routes.ongoingActivityTotalDuration}`,
      { params: httpParams },
    );
  }

  public getOngoingActivityOfLine(lineId: number): Observable<ResponseInterface<ICurrentLine>> {
    const params: HttpParams = new HttpParams()
      .append('join', 'currentActivity')
      .append('join', 'currentTask')
      .append('join', 'currentTask.equipment')
      .append('join', 'currentWorkOrder')
      .append('join', 'currentWorkOrder.product')
      .set('fields', 'title,timer,selectedDescription,selectedPhaseId');
    return this.http.get<ResponseInterface<ICurrentLine>>(`${this.routes.line}/${lineId}`, {
      params,
    });
  }
}
