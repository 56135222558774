import { Component, OnDestroy, OnInit } from '@angular/core';
import { HelperService } from '../../../../shared/service/helper.service';
import { Store } from '@ngrx/store';
import { OeeAppState } from '../../../../store/oee.reducer';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { Line } from '../../../../store/line/model';
import { IQuantityWithCommentMetric } from './quantity-with-comment-metric.model';

@Component({
  selector: 'app-quantity-with-comment-metric',
  templateUrl: './quantity-with-comment-metric.component.html',
})
export class QuantityWithCommentMetricComponent implements OnInit, OnDestroy {
  private lineStoreSubscription: Subscription;
  public quantityWithCommentMetric: IQuantityWithCommentMetric = {
    quantityWithCommentCount: null,
  };
  public quantityWithCommentTitle: string;

  constructor(
    public helperService: HelperService,
    private store: Store<OeeAppState>,
    private translate: TranslateService,
  ) {}

  public ngOnInit(): void {
    this.quantityWithCommentTitle = this.translate.instant('main.workOrder.quantityWithComment');
    this.lineStoreSubscription = this.store.select('line').subscribe((state: Line) => {
      this.quantityWithCommentMetric.quantityWithCommentCount = state.quantities.quantityWithCommentCount;
    });
  }

  public ngOnDestroy(): void {
    if (this.lineStoreSubscription) {
      this.lineStoreSubscription.unsubscribe();
    }
  }
}
