import * as Actions from './product-transition-matrix.actions';
import { IProductTransitionMatrixState } from './product-transition-matrix.model';

export const productTransitionMatrixState: IProductTransitionMatrixState = {
  productTransitionMatrixDataLoading: false,
  productTransitionMatrixDataLoaded: false,
  addProductTransitionMatrixLoading: false,
  addProductTransitionMatrixLoaded: false,
  editProductTransitionMatrixLoading: false,
  editProductTransitionMatrixLoaded: false,
  bulkEditProductTransitionMatrixLoading: false,
  bulkEditProductTransitionMatrixLoaded: false,
  productTransitionMatrixDeleteLoading: false,
  productTransitionMatrixDeleteLoaded: false,
  productTransitionMatrixBulkDeleteLoading: false,
  productTransitionMatrixBulkDeleteLoaded: false,
  productTransitionMatrixExcelDownloading: false,
  productTransitionMatrixExcelDownloaded: false,
  productTransitionMatrixExcelUploading: false,
  productTransitionMatrixExcelUploaded: false,
  productTransitionMatrixCountLoading: false,
  productTransitionMatrixCountLoaded: false,
  productTransitionMatrixCountForExcel: null,
  productTransitionMatrixProductDataLoading: false,
  productTransitionMatrixProductDataLoaded: false,
  productTransitionMatrixProductFamilyDataLoading: false,
  productTransitionMatrixProductFamilyDataLoaded: false,
  productTransitionMatrixPreviewDataLoading: false,
  productTransitionMatrixPreviewDataLoaded: false,
  productTransitionMatrixData: null,
  productFamilyData: null,
  productData: null,
  excelUploadResult: [],
  previewData: null,
  previewFilters: null,
};

export function productTransitionMatrixReducer(
  state: IProductTransitionMatrixState = productTransitionMatrixState,
  action: Actions.ProductTransitionMatrixActions,
): IProductTransitionMatrixState {
  switch (action.type) {
    case Actions.PRODUCT_TRANSITION_MATRIX_DATA_LOADING:
      return {
        ...state,
        productTransitionMatrixDataLoading: true,
        productTransitionMatrixDataLoaded: false,
      };
    case Actions.PRODUCT_TRANSITION_MATRIX_DATA_LOADED:
      return {
        ...state,
        productTransitionMatrixData: action.payload,
        productTransitionMatrixDataLoading: false,
        productTransitionMatrixDataLoaded: true,
      };
    case Actions.ADD_PRODUCT_TRANSITION_MATRIX:
      return {
        ...state,
        addProductTransitionMatrixLoading: true,
        addProductTransitionMatrixLoaded: false,
      };
    case Actions.ADD_PRODUCT_TRANSITION_MATRIX_COMPLETED:
      return {
        ...state,
        addProductTransitionMatrixLoading: false,
        addProductTransitionMatrixLoaded: true,
      };
    case Actions.EDIT_PRODUCT_TRANSITION_MATRIX:
      return {
        ...state,
        editProductTransitionMatrixLoading: true,
        editProductTransitionMatrixLoaded: false,
      };
    case Actions.EDIT_PRODUCT_TRANSITION_MATRIX_COMPLETED:
      return {
        ...state,
        editProductTransitionMatrixLoading: false,
        editProductTransitionMatrixLoaded: true,
      };
    case Actions.BULK_EDIT_PRODUCT_TRANSITION_MATRIX:
      return {
        ...state,
        bulkEditProductTransitionMatrixLoading: true,
        bulkEditProductTransitionMatrixLoaded: false,
      };
    case Actions.BULK_EDIT_PRODUCT_TRANSITION_MATRIX_COMPLETED:
      return {
        ...state,
        bulkEditProductTransitionMatrixLoading: false,
        bulkEditProductTransitionMatrixLoaded: true,
      };
    case Actions.DELETE_PRODUCT_TRANSITION_MATRIX:
      return {
        ...state,
        productTransitionMatrixDeleteLoading: true,
        productTransitionMatrixDeleteLoaded: false,
      };
    case Actions.DELETE_PRODUCT_TRANSITION_MATRIX_COMPLETED:
      return {
        ...state,
        productTransitionMatrixDeleteLoading: false,
        productTransitionMatrixDeleteLoaded: true,
      };
    case Actions.BULK_DELETE_PRODUCT_TRANSITION_MATRIX:
      return {
        ...state,
        productTransitionMatrixBulkDeleteLoading: true,
        productTransitionMatrixBulkDeleteLoaded: false,
      };
    case Actions.BULK_DELETE_PRODUCT_TRANSITION_MATRIX_COMPLETED:
      return {
        ...state,
        productTransitionMatrixBulkDeleteLoading: false,
        productTransitionMatrixBulkDeleteLoaded: true,
      };
    case Actions.DOWNLOAD_PRODUCT_TRANSITION_MATRIX_EXCEL:
      return {
        ...state,
        productTransitionMatrixExcelDownloading: true,
        productTransitionMatrixExcelDownloaded: false,
      };
    case Actions.DOWNLOAD_PRODUCT_TRANSITION_MATRIX_EXCEL_COMPLETED:
      return {
        ...state,
        productTransitionMatrixExcelDownloading: false,
        productTransitionMatrixExcelDownloaded: true,
      };
    case Actions.UPLOAD_PRODUCT_TRANSITION_MATRIX_EXCEL:
      return {
        ...state,
        productTransitionMatrixExcelUploading: true,
        productTransitionMatrixExcelUploaded: false,
      };
    case Actions.UPLOAD_PRODUCT_TRANSITION_MATRIX_EXCEL_COMPLETED:
      return {
        ...state,
        excelUploadResult: action.payload,
        productTransitionMatrixExcelUploading: false,
        productTransitionMatrixExcelUploaded: true,
      };
    case Actions.PRODUCT_TRANSITION_MATRIX_COUNT_LOAD:
      return {
        ...state,
        productTransitionMatrixCountLoading: true,
        productTransitionMatrixCountLoaded: false,
      };
    case Actions.PRODUCT_TRANSITION_MATRIX_COUNT_LOADED:
      return {
        ...state,
        productTransitionMatrixCountForExcel: action.payload.data.totalLength,
        productTransitionMatrixCountLoading: false,
        productTransitionMatrixCountLoaded: true,
      };
    case Actions.PRODUCT_TRANSITION_MATRIX_PRODUCTS_DATA_LOADING:
      return {
        ...state,
        productTransitionMatrixProductDataLoading: true,
        productTransitionMatrixProductDataLoaded: false,
      };
    case Actions.PRODUCT_TRANSITION_MATRIX_PRODUCTS_DATA_LOADED:
      return {
        ...state,
        productData: action.payload,
        productTransitionMatrixProductDataLoading: false,
        productTransitionMatrixProductDataLoaded: true,
      };
    case Actions.PRODUCT_TRANSITION_MATRIX_PRODUCT_FAMILY_DATA_LOADING:
      return {
        ...state,
        productTransitionMatrixProductFamilyDataLoading: true,
        productTransitionMatrixProductFamilyDataLoaded: false,
      };
    case Actions.PRODUCT_TRANSITION_MATRIX_PRODUCT_FAMILY_DATA_LOADED:
      return {
        ...state,
        productFamilyData: action.payload,
        productTransitionMatrixProductFamilyDataLoading: false,
        productTransitionMatrixProductFamilyDataLoaded: true,
      };
    case Actions.PRODUCT_TRANSITION_MATRIX_PREVIEW_DATA_LOADING:
      return {
        ...state,
        productTransitionMatrixPreviewDataLoading: true,
        productTransitionMatrixPreviewDataLoaded: false,
      };
    case Actions.PRODUCT_TRANSITION_MATRIX_PREVIEW_DATA_LOADED:
      return {
        ...state,
        previewData: action.response,
        productTransitionMatrixPreviewDataLoading: false,
        productTransitionMatrixPreviewDataLoaded: true,
      };
    case Actions.PRODUCT_TRANSITION_MATRIX_PREVIEW_SET_FILTER:
      return {
        ...state,
        previewFilters: action.filters,
      };
    case '[PRODUCT_TRANSITION_MATRIX] PRODUCT_TRANSITION_MATRIX ACTION RESET PREVIEW DATA':
      return {
        ...state,
        previewData: [],
        productTransitionMatrixPreviewDataLoaded: false,
      }
    default:
      return state;
  }
}
