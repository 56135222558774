import { Action } from '@ngrx/store';
import { IFilterRequestParameter } from '../../../view/reports/check-in-timeline/check-in-timeline.model';
import { ICheckInTimelineResponseData } from './check-in-timeline.model';

export const CHECK_IN_TIMELINE_DATA_LOADING = '[CHECK IN TIMELINE] DATA LOADING';
export const CHECK_IN_TIMELINE_DATA_LOADED = '[CHECK IN TIMELINE] DATA LOADED';
export const FETCH_ERROR = '[CHECK IN TIMELINE] FETCH ERROR';
export class CheckInTimelineDataLoading implements Action {
  readonly type = CHECK_IN_TIMELINE_DATA_LOADING;

  constructor(public filter: IFilterRequestParameter) {}
}

export class CheckInTimeLineDataLoaded implements Action {
  readonly type = CHECK_IN_TIMELINE_DATA_LOADED;

  constructor(public data: ICheckInTimelineResponseData[]) {}
}

export class FetchError implements Action {
  readonly type = FETCH_ERROR;
  constructor(public payload: any) {}
}

export type CheckInTimelineActions = CheckInTimelineDataLoading | CheckInTimeLineDataLoaded | FetchError;
