import moment from 'moment';

export type ChartHeightLevel = 0 | 1 | 2 | 3 | 4;

export enum EBarChartGroupedDataLabel {
  NO_EQUIPMENT_ASSIGNED = '{{noEquipmentAssignedLabel}}',
  OTHERS = '{{othersLabel}}',
}

export interface IGenericChartData {
  value: string | number;
  name?: string;
  itemStyle?: IBarChartDataItemStyle;
  children?: IGenericChartData;
  data?: IGenericChartData;
  activityId?: number;
  activityName?: string;
  equipmentId?: number;
  equipmentName?: string;
  taskId?: number;
  targetDuration?: string;
  label?: IBarChartTextStyle;
  isOtherTasks?: boolean;
  originalName?: string;
  activityType?: string;
  activityIds?: number[];
  equipmentIds?: number[];
  isOthers?: boolean;
  isNoEquipment?: number;
  targetDurationFormula?: string;
  isMissingData?: boolean;
  totalDuration?: string;
  taskTarget?: string;
  id?: number;
  tagIds?: number[];
}

export interface IChartEventParams {
  data: IGenericChartData;
  dataIndex: number;
  color: string;
  borderColor?: string;
  name?: string;
  seriesName?: string;
  value?: number | string;
}

export interface IBarChartDataItemStyle {
  color?: string;
  borderColor?: string;
  borderWidth?: number;
  shadowColor?: string;
  shadowBlur?: number;
}

export interface IBarChartConfiguration {
  activitiesChartTitleStyle?: IBarChartTitleStyle;
  equipmentChartTitleStyle?: IBarChartTitleStyle;
  tasksChartTitleStyle?: IBarChartTitleStyle;
  highlightStyle?: IBarChartDataItemStyle;
  maxBarWidth?: number;
  taskChartBarWidth?: number;
  activitiesChartMaxBarsWithoutOther?: number;
  equipmentChartMaxBarsWithoutOther?: number;
  tasksChartMaxBarsWithoutOther?: number;
  shouldResizeTasksChart?: boolean;
  activitiesChartDivClasses?: string;
  equipmentChartDivClasses?: string;
  tasksChartDivClasses?: string;
  shouldDisplayInfoMessages?: boolean;
  barLabelPositionThreshold?: number;
  leftPercentageActivitiesChart?: string;
  leftPercentageEquipmentChart?: string;
  leftPercentageTasksChart?: string;
  rightPercentage?: string;
  showNamesInBars?: boolean;
  noEquipmentAssignedLabelHasStar?: boolean;
  yAxisStyle?: IBarChartYAxisStyle;
  xAxisStyle?: IBarChartXAxisStyle;
  showTaskTargets?: boolean;
  showActivitiesChartYAxisName?: boolean;
  showEquipmentChartYAxisName?: boolean;
}

export interface IDateRangeOptions {
  start: moment.Moment | null;
  end: moment.Moment | null;
}

export interface IChartLoader {
  type: string;
  options: { text: string };
}

export interface IBarChartXAxisStyle {
  nameFontSize?: number;
  nameLocation?: EAxisNameLocation;
  namePadding?: number[];
  showLabels?: boolean;
  showName?: boolean;
}

export interface IBarChartYAxisStyle extends IBarChartXAxisStyle {
  labelMaxLength?: number;
}

export interface IBarChartTitleStyle {
  left: string;
  padding: number[];
  textStyle: IBarChartTextStyle;
}

export interface IBarChartTextStyle {
  fontWeight?: string | number;
  position?: string;
  fontSize?: number;
}

export enum EAxisNameLocation {
  START = 'start',
  MIDDLE = 'middle',
  END = 'end',
}

export interface IPreparedData {
  data: IGenericChartData[];
  maxDuration: number;
}

export enum TargetCategory {
  none = 'none',
  workOrder = 'workOrder',
  dayAndShift = 'dayAndShift',
  businessDay = 'businessDay',
}

export const ROOT_CAUSE_ANALYSIS_TASKS_CHART_MAX_BAR_WITHOUT_OTHER: number = 20;
