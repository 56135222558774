import { Action } from '@ngrx/store';
import { HomeActionTypes } from '../home/home.actions';
import { IDeviceAndSensors } from './sensor-statuses.model';

export const GET_SENSOR_STATUSES = '[SensorStatuses] Get sensor statuses';
export const GET_SENSOR_STATUSES_COMPLETED = '[SensorStatuses] Get sensor statuses completed';

export class GetSensorStatuses implements Action {
  readonly type = GET_SENSOR_STATUSES;

  constructor(public lineId: number) {}
}

export class GetSensorStatusesCompleted implements Action {
  readonly type = GET_SENSOR_STATUSES_COMPLETED;

  constructor(public payload: IDeviceAndSensors[]) {}
}

export class FetchError implements Action {
  readonly type = HomeActionTypes.FetchError;

  constructor(public payload: any[]) {}
}

export type SensorStatusesActions = GetSensorStatuses | GetSensorStatusesCompleted | FetchError;
