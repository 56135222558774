import { Component, ElementRef, Input, NgZone, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { interval, Observable, Subject, Subscription } from 'rxjs';
import { Store } from '@ngrx/store';
import * as oeeAppReducer from '../../../../store/oee.reducer';
import * as PageHeaderActions from '../../../../store/page-header/page-header.actions';
import { PageHeaderStateInterface } from '../../../../store/page-header/page-header.model';
import { takeUntil, takeWhile } from 'rxjs/operators';
import { PageHeaderInterface } from '../../../service/page-header/page-header.model';
import { PageRefreshIntervals } from '../../../../../constants.model';

@Component({
  selector: 'app-dashboard-mode-countdown',
  templateUrl: './dashboard-mode-countdown.component.html',
  styleUrls: ['./dashboard-mode-countdown.component.scss'],
})
export class DashboardModeCountdownComponent implements OnInit, OnDestroy {
  @ViewChild('countdownText') countdownTextElement: ElementRef;

  @Input() pageHeader: PageHeaderInterface = {
    title: null,
    titleKey: null,
    icon: null,
    fullScreenButton: false,
    fullScreenTargetElementId: null,
  };

  private readonly subscriptions: Subscription[] = [];
  private countdownInitial: number = 120;
  private isDashboardMode: boolean = false;
  private destroySubject: Subject<void> = new Subject<void>();
  public countdown: number = 120;
  public isDisabled: boolean = true;
  public isAlive: boolean = false;

  constructor(private readonly store: Store<oeeAppReducer.OeeAppState>, public zone: NgZone) {}

  public ngOnInit(): void {
    this.countdown = PageRefreshIntervals[this.pageHeader.titleKey];
    this.countdownInitial = PageRefreshIntervals[this.pageHeader.titleKey];
    this.subscriptions.push(
      this.store.select('pageHeaderStore').subscribe((state: PageHeaderStateInterface) => {
        this.isDashboardMode = state.isDashboardMode;

        if (state.isDashboardMode) {
          if (state.isCountdownWaitingToDisable) {
            this.store.dispatch(new PageHeaderActions.CountdownDisable(false));
            this.isDisabled = true;
          }

          if (state.isCountdownWaitingToPause) {
            this.store.dispatch(new PageHeaderActions.CountdownPause(false));
            this.isAlive = false;
          }

          if (state.isCountdownWaitingToReset) {
            this.store.dispatch(new PageHeaderActions.CountdownReset(false));
            this.destroySubject.next();
            this.resetCountdown(state.isCountdownExpectedToStartWithReset);
          }

          if (state.isCountdownWaitingToStart) {
            this.store.dispatch(new PageHeaderActions.CountdownStart(false));
            this.isAlive = true;
          }

          if (state.isCountdownWaitingToEnable) {
            this.store.dispatch(new PageHeaderActions.CountdownEnable(false));
            this.isDisabled = false;
          }

          return;
        }

        this.isAlive = false;
        this.destroySubject.next();
      }),
    );
  }

  public onRefreshPageClicked(): void {
    this.countdown = this.countdownInitial;
    this.store.dispatch(new PageHeaderActions.CountdownTimeout());
  }

  public ngOnDestroy(): void {
    this.subscriptions.forEach((value: Subscription): void => {
      value.unsubscribe();
    });

    this.isAlive = false;

    this.destroySubject.next();
  }

  private resetCountdown(isExpectedToStart: boolean): void {
    this.countdown = this.countdownInitial;

    this.zone.runOutsideAngular((): void => {
      const pageRefreshInterval: Observable<number> = interval(1000);

      if (this.countdownTextElement?.nativeElement) {
        this.countdownTextElement.nativeElement.innerHTML = this.countdown
      }

      pageRefreshInterval
        .pipe(
          takeWhile(() => this.isAlive),
          takeUntil(this.destroySubject),
        )
        .subscribe(() => {
          this.countdown -= 1;
          this.countdown = this.countdown <= 0 ? this.countdownInitial : this.countdown;

          this.countdownTextElement.nativeElement.innerHTML = this.countdown;

          if (this.countdown === this.countdownInitial) {
            this.store.dispatch(new PageHeaderActions.CountdownTimeout());
          }
        });
    });

    if (isExpectedToStart) {
      this.store.dispatch(new PageHeaderActions.CountdownEnable());
      this.store.dispatch(new PageHeaderActions.CountdownStart());
    }
  }
}
