import { Pipe, PipeTransform } from '@angular/core';
import { IGenericObject } from '../model/interface/generic.model';

@Pipe({
  name: 'callFunction',
  standalone: true,
})
export class CallFunctionPipe<X, Y extends IGenericObject<any>, Z extends (...args) => any> implements PipeTransform {
  transform(value: X, functionToCall: Z, argArray?: any, scope?: any): ReturnType<Z> {
    return scope
      ? functionToCall.call(scope, [value, ...(argArray ?? [])])
      : functionToCall(...[value, ...(argArray ?? [])]);
  }
}
