import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import * as ActionTrackerActions from './action-tracker.actions';
import { ActionTrackerService } from '../../shared/service/action-tracker/action-tracker.service';
import { catchError, map, of, switchMap } from 'rxjs';
import * as oeeAppReducer from '../oee.reducer';
import { Store } from '@ngrx/store';
import * as AppActions from '../../store/app/actions';

@Injectable()
export class ActionTrackerEffects {
  loadBoards$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ActionTrackerActions.getActionTrackerBoards),
      switchMap(({ searchKeyword }) =>
        this.actionTrackerService.getBoards(searchKeyword).pipe(
          map((response) => ActionTrackerActions.getActionTrackerBoardsLoaded({ boards: response.data })),
          catchError((err) => of(ActionTrackerActions.getActionTrackerBoardsFailed(err))),
        ),
      ),
      catchError((err) => of(ActionTrackerActions.getActionTrackerBoardsFailed(err))),
    ),
  );

  loadBoardMembers$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ActionTrackerActions.getActionTrackerBoardMembers),
      switchMap(({ id, searchKeyword }) =>
        this.actionTrackerService.getBoardMembers(id, searchKeyword).pipe(
          map((response) => ActionTrackerActions.getActionTrackerBoardMembersLoaded({ members: response.data })),
          catchError((err) => of(ActionTrackerActions.getActionTrackerBoardMembersFailed(err))),
        ),
      ),
      catchError((err) => of(ActionTrackerActions.getActionTrackerBoardMembersFailed(err))),
    ),
  );

  createItem$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(ActionTrackerActions.createActionTrackerItem),
        switchMap((data) => {
          this.store.dispatch(new AppActions.ShowTopLoader());

          return this.actionTrackerService.createOneItem(data.payload).pipe(
            map((response) => {
              this.store.dispatch(new AppActions.HideTopLoader());

              return ActionTrackerActions.createActionTrackerItemLoaded({
                createdItem: {
                  id: response.data.id,
                  boardId: response.data.boardId,
                  key: response.data.key,
                  url: response.data.url,
                },
              });
            }),
            catchError((err) =>
              of(ActionTrackerActions.createActionTrackerItemFailed(err), new AppActions.HideTopLoader()),
            ),
          );
        }),
        catchError((err) =>
          of(ActionTrackerActions.createActionTrackerItemFailed(err), new AppActions.HideTopLoader()),
        ),
      ),
    { dispatch: true },
  );

  uploadAttachments$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(ActionTrackerActions.uploadActionTrackerItemAttachments),
        switchMap((data) => {
          this.store.dispatch(new AppActions.ShowTopLoader());

          return this.actionTrackerService.uploadBulkAttachments(data.boardId, data.itemId, data.files).pipe(
            map(() => {
              this.store.dispatch(new AppActions.HideTopLoader());

              return ActionTrackerActions.uploadActionTrackerItemAttachmentsLoaded();
            }),
            catchError((err) =>
              of(ActionTrackerActions.uploadActionTrackerItemAttachmentsFailed(err), new AppActions.HideTopLoader()),
            ),
          );
        }),
        catchError((err) =>
          of(ActionTrackerActions.uploadActionTrackerItemAttachmentsFailed(err), new AppActions.HideLoader()),
        ),
      ),
    { dispatch: true },
  );

  getCurrentActionTrackerUser$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ActionTrackerActions.getActionTrackerCurrentUser),
      switchMap(() => {
        this.store.dispatch(new AppActions.ShowTopLoader());

        return this.actionTrackerService.getCurrentActionTrackerUser().pipe(
          map((response) => {
            this.store.dispatch(new AppActions.HideTopLoader());

            return ActionTrackerActions.getActionTrackerCurrentUserLoaded({
              isUserExistInActionTracker: response.data.isUserExistInActionTracker,
            });
          }),
          catchError((err) =>
            of(ActionTrackerActions.getActionTrackerCurrentUserFailed(err), new AppActions.HideTopLoader()),
          ),
        );
      }),
      catchError((err) =>
        of(ActionTrackerActions.getActionTrackerCurrentUserFailed(err), new AppActions.HideTopLoader()),
      ),
    ),
  );

  getRecommendedBoard$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ActionTrackerActions.getActionTrackerRecommendedBoard),
      switchMap(() => {
        this.store.dispatch(new AppActions.ShowTopLoader());

        return this.actionTrackerService.getRecommendedBoard().pipe(
          map((response) => {
            this.store.dispatch(new AppActions.HideTopLoader());

            return ActionTrackerActions.getActionTrackerRecommendedBoardLoaded({ board: response.data });
          }),
          catchError((err) =>
            of(ActionTrackerActions.getActionTrackerRecommendedBoardFailed(err), new AppActions.HideTopLoader()),
          ),
        );
      }),
      catchError((err) =>
        of(ActionTrackerActions.getActionTrackerRecommendedBoardFailed(err), new AppActions.HideTopLoader()),
      ),
    ),
  );

  constructor(
    private readonly actions$: Actions,
    private readonly actionTrackerService: ActionTrackerService,
    private readonly store: Store<oeeAppReducer.OeeAppState>,
  ) {}
}
