export function activityStyles(activityType, activitySubType = null, isZeroCuntReceived: boolean = false) {
  if (typeof activityType === 'undefined' || activityType === null) {
    return false;
  }

  const activityTypes = {
    runTime: {
      activityCardClass: 'activity-run-time',
      activityCardIconClass: 'fas fa-running',
      activityDurationTextClass: 'green-text',
      activityDurationProgressClass: 'green-bg',
      ...(isZeroCuntReceived && { activityCardClass: 'activity-run-time is-zero-count-received' }),
    },
    idleTime: {
      activityCardClass: 'activity-idle-time',
      activityCardIconClass: 'fas fa-pause-circle',
      activityDurationTextClass: 'gray-text',
      activityDurationProgressClass: 'gray-bg',
    },
    downTime: {
      activityCardClass: 'activity-down-time',
      activityCardIconClass: 'fas fa-hourglass',
      activityDurationTextClass: 'unplanned-down-text',
      activityDurationProgressClass: 'unplanned-down-bg',
      failure: {
        activityCardClass: 'activity-down-time',
        activityCardIconClass: 'fa fa-wrench',
        activityDurationTextClass: 'unplanned-down-text',
        activityDurationProgressClass: 'unplanned-down-bg',
      },
    },
    downTimePlanned: {
      activityCardClass: 'activity-down-time-planned',
      activityCardIconClass: 'fas fa-calendar',
      activityDurationTextClass: 'red-text',
      activityDurationProgressClass: 'red-bg',
    },
    default: {
      activityCardClass: 'activity-blank-state',
      activityCardIconClass: 'fas fa-pause-circle',
      activityDurationTextClass: 'black-text',
      activityDurationProgressClass: 'gray-bg',
    },
    isMissingData: {
      activityCardClass: 'is-missing-data',
      activityCardIconClass: 'fas fa-exclamation-circle',
      activityDurationTextClass: 'orange-text',
      activityDurationProgressClass: 'orange-bg',
    },
  };

  return activitySubType === null
    ? activityTypes[activityType]
    : activityTypes[activityType][activitySubType];
}
