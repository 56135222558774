import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, switchMap } from 'rxjs/operators';
import { HttpClient, HttpParams } from '@angular/common/http';
import * as ObjectActions from './activity-button.actions';
import { of } from 'rxjs';
import * as AppActions from '../../store/app/actions';
import { Store } from '@ngrx/store';
import * as oeeAppReducer from '../oee.reducer';
import { ActivitiesInterface } from '../../shared/model/interface/activities.model';
import { ActivitiesService } from '../../shared/service/activities/activities.service';
import { GetManyResponseInterface } from '../../shared/model/interface/crud-response-interface.model';
import { EntityTranslatorService } from '../../shared/service/entity-translator/entity-translator.service';
import { ActivityTypes } from '../../shared/model/enum/activity-types';

@Injectable()
export class ActivityButtonEffects {
  constructor(
    private actions$: Actions,
    private http: HttpClient,
    private store: Store<oeeAppReducer.OeeAppState>,
    private readonly activitiesService: ActivitiesService,
    private readonly entityTranslatorService: EntityTranslatorService,
  ) {}

  startActivityButtonDataLoading = createEffect(() =>
    this.actions$.pipe(
      ofType(ObjectActions.ActivityButtonActionTypes.StartActivityButtonDataLoading),
      switchMap((objectData: ObjectActions.StartActivityButtonDataLoading) => {
        this.store.dispatch(new AppActions.ShowTopLoader());

        const searchObject = {
          active: { $eq: true },
        };

        let httpParams: HttpParams = new HttpParams()
          .append('join', 'activityTypeRelation')
          .append('join', 'activitySubTypeRelation')
          .append('lineId', objectData.lineId.toString())
          .append('sort', 'activityTypeRelation.orders,ASC')
          .append('sort', 'id,ASC')
          .set('page', '1')
          .set('limit', '500')
          .set('s', JSON.stringify(searchObject));

        return this.activitiesService.getActivities(httpParams).pipe(
          switchMap((response: GetManyResponseInterface<ActivitiesInterface>) => {
            response.data.forEach((activities: ActivitiesInterface) => {
              this.entityTranslatorService.translate(activities);
            });

            return of(new ObjectActions.StartActivityButtonDataLoaded(response), new AppActions.HideTopLoader());
          }),
          catchError((errorRes) => {
            return of(new ObjectActions.FetchError(errorRes), new AppActions.HideTopLoader());
          }),
        );
      }),
    ),
  );

  startActivityButtonDataLoaded = createEffect(() =>
    this.actions$.pipe(
      ofType(ObjectActions.ActivityButtonActionTypes.StartActivityButtonDataLoaded),
      switchMap(() => {
        return of(new AppActions.HideTopLoader());
      }),
    ),
  );
}
