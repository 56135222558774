import { Action } from '@ngrx/store';
import { HttpOptionInterface } from '../filter.model';
import { IJobCRUD } from '../../../shared/component/filter/filter.class';
import { GetManyResponseInterface } from '../../../shared/model/interface/crud-response-interface.model';

export enum EFilterJobsActionTypes {
  FilterJobsLoading = '[Filter Job] Load Filter Job',
  FilterJobsLoaded = '[Filter Job] Filter Job Loaded',
  ResetJobsStore = '[Filter Job] Reset data stored in Job store',
  AppendJobData = '[Filter Job] Append Job Data',
}

export class FilterJobsLoading implements Action {
  readonly type = EFilterJobsActionTypes.FilterJobsLoading;

  constructor(public options: HttpOptionInterface) {}
}

export class FilterJobsLoaded implements Action {
  readonly type = EFilterJobsActionTypes.FilterJobsLoaded;

  constructor(public data: IJobCRUD[]) {}
}

export class AppendJobData implements Action {
  readonly type = EFilterJobsActionTypes.AppendJobData;

  constructor(public data: IJobCRUD[]) {}
}

export class ResetJobsStore implements Action {
  readonly type = EFilterJobsActionTypes.ResetJobsStore;
}

export type FilterJobsActions = FilterJobsLoading | FilterJobsLoaded | AppendJobData | ResetJobsStore;
