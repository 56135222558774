import { Component, Input, OnInit, TemplateRef } from '@angular/core';
import { ScwModalSize } from '../scw-mat-ui/scw-mat-modal/scw-mat-modal.model';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { HomePageDisplaySettingsUtilities } from '../../../view/settings/home-page-display-settings/home-page-display-settings.utilities';
import { ComponentUtilities } from '../../helper/component-utilities';
import { DatatableService } from '../../service/datatable/datatable.service';
import { TranslateService } from '@ngx-translate/core';
import { HelperService } from '../../service/helper.service';
import { ColorService } from '../../service/color/color.service';
import { Store } from '@ngrx/store';
import { OeeAppState } from '../../../store/oee.reducer';
import { HomeStateInterface } from '../../../store/home/home.model';
import { IActivityHistory } from '../../service/activity-history/activity-history.model';
import { User } from '../../../store/user/model';
import { ITableHeader } from '../../../../constants.model';
import { DatatableOutputParameterInterface } from '../datatable/datatable.model';
import { DatatableInterface } from '../../service/datatable/datatable.model';
import { MainStateInterface } from '../../../store/main/main.model';
import { CustomColors } from '../../service/color/color.model';
import { mysqlTimestampFormat } from '../../helper/date';
import * as HomeAction from '../../../store/home/home.actions';
import * as _ from 'lodash';
import moment from 'moment';
import {
  IActivitiesTotalDurationMetricColor
} from "../../../view/settings/home-page-display-settings/home-page-display-settings.model";
import { combineLatest } from 'rxjs';
import { ILineViewState } from '../../../store/global-view/site-view/line-view/line-view.model';

@Component({
  selector: 'app-activities-total-duration-metric',
  templateUrl: './activities-total-duration-metric.component.html',
  styleUrls: ['./activities-total-duration-metric.component.scss'],
})
export class ActivitiesTotalDurationMetricComponent implements OnInit {
  @Input() runTimeDuration: string = '--';
  @Input() downTimeUnplannedDuration: string = '--';
  @Input() downTimePlannedDuration: string = '--';
  @Input() idleTimeDuration: string = '--';
  @Input() unit: string = null;
  @Input() clickable: boolean = false;
  @Input() applyPlannedDownTimeColor: boolean = false;
  public activitiesTotalDurationTableHeaders: ITableHeader[] =
    HomePageDisplaySettingsUtilities.getActivitiesTotalDurationTableHeaders(this.translate);
  public readonly datatableHeaderTrackBy = ComponentUtilities.datatableHeaderTrackBy;
  private clientColors: CustomColors | undefined;
  public activitiesTotalDurationModalRef: NgbModalRef;
  public activitiesTotalDurationData: IActivityHistory[] = [];
  public activitiesTotalDurationDataLoading: boolean = true;
  public activitiesTotalDurationDataCount: number;
  public siteId: number = null;
  public lineId: number = null;
  public workOrderId: number = null;
  public selectedActivityType: string = null;
  public datetimeFormatWithSecond$: string;
  public tableQuery: DatatableInterface = {
    page: 1,
    pageSize: 10,
    orderBy: 'start',
    orderDesc: true,
  };
  public colors: IActivitiesTotalDurationMetricColor = {
    runTimeDuration: { background: '#6fc764', hover: '#429A38', text: '#ffffff', border: '#faf9f9' },
    idleTimeDuration: { background: '#aeaeae', hover: '#979797', text: '#ffffff', border: '#faf9f9' },
    downTimePlannedDuration: { background: '#ff726b', hover: '#FF5047', text: '#ffffff', border: '#faf9f9' },
    downTimeUnplannedDuration: { background: '#dd332b', hover: '#9F201A', text: '#ffffff', border: '#faf9f9' },
  };

  constructor(
    private readonly activityLogsModal: NgbModal,
    private readonly store: Store<OeeAppState>,
    private readonly translate: TranslateService,
    private readonly datatableService: DatatableService,
    private readonly colorService: ColorService,
  ) {}

  public onClick(content: TemplateRef<any>, activityType: string): void {
    this.selectedActivityType = activityType;
    this.loadData();

    this.activitiesTotalDurationModalRef = this.activityLogsModal.open(content, {
      keyboard: false,
      backdrop: 'static',
      windowClass: ScwModalSize.large,
    });
  }

  public ngOnInit(): void {
    combineLatest([
        this.store.select('user'),
        this.store.select('lineViewStore'),
      ]).subscribe(([userState, lineViewState]: [User, ILineViewState]): void => {
      this.clientColors = userState.clientInfo?.configuration?.colors;
      this.datetimeFormatWithSecond$ = userState.dateTimeFormatWithSecond;
      this.siteId = lineViewState.selectedSiteId ?? userState.siteId;
      this.lineId = lineViewState.selectedLineId ?? userState.lineId;
    });
    this.store.select('homeStore').subscribe((state: HomeStateInterface) => {
      this.workOrderId = state.line?.workOrderCard?.wOTableId;
      this.activitiesTotalDurationDataLoading = state.activityHistoryDataLoading && !state.activityHistoryDataLoaded;

      if (!state.activityHistoryDataLoading && state.activityHistoryDataLoaded) {
        this.activitiesTotalDurationData = HelperService.cloneDeep(state.activityHistoryData.data).map(
          (item: IActivityHistory) => ({
            ...item,
            batchNumber: _.get(item, 'workOrder.woNumber', null),
            isMissingData: ActivitiesTotalDurationMetricComponent.isMissingData(item),
            duration: ActivitiesTotalDurationMetricComponent.getFormattedDuration(item.start, item.end),
            taskName: ActivitiesTotalDurationMetricComponent.getActivityTaskName(item),
            withoutWorkOrder: item.task?.meta?.withoutWorkOrder === 1,
          }),
        );

        this.activitiesTotalDurationDataCount = state.activityHistoryData?.total;
      }
    });
    this.store.select('mainStore').subscribe((state: MainStateInterface) => {
      const sitesColors: Record<number, CustomColors | undefined> = _.fromPairs(
        state.activeSites.map((site) => [site.id, site.configuration?.colors]),
      );
      const customColors$: CustomColors = this.colorService.pickCustomColors(
        [this.siteId],
        sitesColors,
        this.clientColors,
      );

      if ((this.clickable || this.applyPlannedDownTimeColor) && customColors$ !== undefined) {
        this.colors.downTimePlannedDuration = {
          background: customColors$.activity.plannedDownTime.card,
          hover: customColors$.activity.plannedDownTime.card === '#ff726b' ? '#FF5047' : '#E6C100',
          text: customColors$.activity.plannedDownTime.cardText,
          border: '#faf9f9',
        };
      }
    });
  }

  private static getFormattedDuration(startDate: string, endDate: string): string {
    const start: moment.Moment = moment(startDate, mysqlTimestampFormat);
    const end: moment.Moment = moment(endDate, mysqlTimestampFormat);
    const duration: moment.Duration = moment.duration(end.diff(start));

    return Math.floor(duration.asHours()) + moment.utc(duration.asMilliseconds()).format(':mm:ss');
  }

  private static getActivityTaskName(item: IActivityHistory): string {
    if (item.activity.activityType === 'runTime') {
      return _.get(item, 'workOrder.product.description', '');
    }

    return _.get(item, 'task.title', '');
  }

  private static isMissingData(item: IActivityHistory): boolean {
    return (
      (item.activity.activityType !== 'runTime' && (item.taskId === null || item.task.isMissingData === 1)) ||
      item.activityId === null ||
      item.start === null ||
      item.start === '' ||
      item.end === null ||
      item.end === '' ||
      item.activity.activityType !== 'idleTime'
    );
  }

  public onDataRequestHandler(event: DatatableOutputParameterInterface): void {
    const tableQuery: DatatableInterface = this.datatableService.formatOutputParameters(event);
    this.tableQuery = {
      ...this.tableQuery,
      ...tableQuery,
    };

    if (!this.tableQuery.orderBy || (event.sort && event.sort.type === 'none')) {
      this.tableQuery.orderBy = 'start';
      this.tableQuery.orderDesc = true;
    }

    this.loadData();
  }

  private loadData(): void {
    let searchObject: any = {
      $and: [
        { lineId: { $eq: Number(this.lineId) } },
        { siteId: { $eq: Number(this.siteId) } },
        { workOrderId: { $eq: Number(this.workOrderId) } },
        { 'activity.activityType': { $eq: this.selectedActivityType } },
      ],
    };

    this.store.dispatch(new HomeAction.StartActivityHistoryDataLoading(searchObject, this.tableQuery));
  }
}
