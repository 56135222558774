import { defaultEnvironmentConfigs } from './environment.defaults';

export const environment = {
  ...defaultEnvironmentConfigs,
  ssoEnv: 'PROD',
  appInsights: {
    instrumentationKey: '004838ad-5c0d-4b32-9dba-05cb5976120a',
    cloudRole: 'df-angular-prod',
  },
  fileUploadUrl: 'https://file-manager.scm3d.com/',
};
