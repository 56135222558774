import { Injectable } from '@angular/core';
import { CanActivate, Router, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { OeeAppState } from '../../../store/oee.reducer';
import { Store } from '@ngrx/store';
import { map, take } from 'rxjs/operators';
import { User, UserLevels } from '../../../store/user/model';

@Injectable({
  providedIn: 'root',
})
export class HiddenSettingsGuard implements CanActivate {
  constructor(public router: Router, private store: Store<OeeAppState>) {}

  public canActivate(): Observable<boolean | UrlTree> {
    const scwEmailAddresses: string[] = ['@scw.ai', '@supplychainwizard.com'];

    return new Observable((observer) => {
      this.store
        .select('user')
        .pipe(
          take(1),
          map((user: User) => {
            return (
              scwEmailAddresses.some((email: string) => user.email.includes(email)) &&
              user.level.id === UserLevels.ADMIN
            );
          }),
        )
        .subscribe((canAccess) => {
          if (canAccess) {
            observer.next(true);
          } else {
            observer.next(this.router.createUrlTree(['/error/forbidden']));
          }

          observer.complete();
        });
    });
  }
}
