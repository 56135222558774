import { Action } from '@ngrx/store';
import { IOpcTagList } from '../../../shared/component/filter/filter.class';
import { HttpOptionInterface } from '../filter.model';

export enum FilterOpcTagActionTypes {
  StartLoadFilterOpcTags = '[Filter Opc Tag] Load Filter Opc Tags',
  FilterOpcTagsLoaded = '[Filter Opc Tag] Filter Opc Tags Loaded',
  ResetOpcTagStore = '[Filter Opc Tag] Reset data stored in opc tag store',
}

export class StartLoadFilterOpcTag implements Action {
  readonly type = FilterOpcTagActionTypes.StartLoadFilterOpcTags;

  constructor(public options: HttpOptionInterface, public isFetchMoreRequest: boolean = false) {}
}

export class FilterOpcTagsLoaded implements Action {
  readonly type = FilterOpcTagActionTypes.FilterOpcTagsLoaded;

  constructor(public data: IOpcTagList[]) {}
}

export class ResetOpcTagStore implements Action {
  readonly type = FilterOpcTagActionTypes.ResetOpcTagStore;

  constructor() {}
}

export type FilterOpcTagActions = StartLoadFilterOpcTag | FilterOpcTagsLoaded | ResetOpcTagStore;
