import { AfterContentInit, Component, ContentChild, Input, OnDestroy, TemplateRef, ViewChild, } from '@angular/core';
import { NgbModal, NgbModalOptions, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { smallModal } from '../../../../constants';
import { OnDestroyDecorator } from '../../decorator/on-destroy-decorator';
import { ScwMatButtonComponent } from '../scw-mat-ui/scw-mat-button/scw-mat-button.component';
import { ScwMatSmallButtonComponent } from '../scw-mat-ui/scw-mat-small-button/scw-mat-small-button.component';
import { Subscription } from 'rxjs';

@OnDestroyDecorator
@Component({
  selector: 'scw-mat-informative-modal',
  templateUrl: './informative-modal.component.html',
  styleUrls: ['./informative-modal.component.scss'],
})
export class InformativeModalComponent implements OnDestroy, AfterContentInit {
  @Input() modalHeader: string;
  @Input() modalHasImage: boolean = false;
  @Input() imagePath: string;
  @Input() data: { header: string; messages: string[] }[];
  @Input() modalOptions: NgbModalOptions = smallModal;

  @ContentChild(ScwMatButtonComponent) scwMatButtonComponent: ScwMatButtonComponent;
  @ContentChild(ScwMatSmallButtonComponent) scwMatSmallButtonComponent: ScwMatSmallButtonComponent;

  @ViewChild('informative_modal') informativeModalTemplateRef: TemplateRef<any>;

  private readonly subscriptions: Subscription[] = [];
  public informativeModalRef: NgbModalRef;

  constructor(private readonly modal: NgbModal) {}

  public ngAfterContentInit(): void {
    this.subscriptions.push(
      this.scwMatButtonComponent?.onClick.subscribe(() => {
        this.openInformativeModal(this.informativeModalTemplateRef);
      }),
      this.scwMatSmallButtonComponent?.onClick.subscribe(() => {
        this.openInformativeModal(this.informativeModalTemplateRef);
      }),
    );
  }

  public openInformativeModal(content: TemplateRef<any>): void {
    this.informativeModalRef?.close();

    this.informativeModalRef = this.modal.open(content, { ...this.modalOptions, centered: true });
  }

  public ngOnDestroy(): void {
    for (const subscription of this.subscriptions) {
      subscription?.unsubscribe();
    }
  }
}
