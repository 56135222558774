import { Component, Input, OnChanges, OnDestroy, OnInit } from '@angular/core';
import {
  IHomePageDisplaySettingsState,
  ILookup,
} from '../../../store/settings/home-page-display-settings/home-page-display-settings.model';
import { Subscription } from 'rxjs';
import { ActionsSubject, Store } from '@ngrx/store';
import { OeeAppState } from '../../../store/oee.reducer';
import { TUnitConversionResponse } from '../../../store/settings/unit-conversion/unit-conversion.model';
import { camelCase } from '@swimlane/ngx-datatable';
import { TranslateService } from '@ngx-translate/core';
import { DecimalHelper } from '../../helper/decimal/decimal-helper';
import { HomeStateInterface } from '../../../store/home/home.model';
import { IUnitsAndMultipliers } from './multiple-units-metric.model';
import { HelperService } from '../../service/helper.service';

@Component({
  selector: 'app-multiple-units-metric',
  templateUrl: './multiple-units-metric.component.html',
  styleUrls: ['./multiple-units-metric.component.scss'],
})
export class MultipleUnitsMetricComponent implements OnInit, OnChanges, OnDestroy {
  @Input() actualValue: string = '--';
  @Input() titleBracketInfo: string = '--';
  @Input() unitOfCount: string = '--';
  @Input() fromMetricInfo: boolean = false;
  public baseUnitId: number = null;
  private readonly subscriptions: Subscription[] = [];
  public unitsAndMultipliers: IUnitsAndMultipliers[] = [];
  public unitConversions: TUnitConversionResponse[] = [];
  public productUnits: ILookup[] = [];

  constructor(
    private readonly store: Store<OeeAppState>,
    private readonly actions: ActionsSubject,
    private readonly translate: TranslateService,
    private readonly decimalHelper: DecimalHelper,
  ) {}

  public ngOnInit(): void {
    this.subscriptions.push(
      this.store.select('homeStore').subscribe((state: HomeStateInterface) => {
        if (!state.homeInformationLoading && state.homeMetricSetItemsLoaded) {
          this.baseUnitId = HelperService.cloneDeep(state.line.workOrderCard.unitId);
        }
      }),
      this.store.select('homePageDisplaySettingsStore').subscribe((state: IHomePageDisplaySettingsState) => {
        if (!state.isProductUnitsLoading && state.isProductUnitsLoaded) {
          this.productUnits = state.productUnits;
          this.unitsAndMultipliers = this.setValue();
        }

        if (!state.isProductUnitConversionsLoading && state.isProductUnitConversionsLoaded) {
          this.unitConversions = state.productUnitConversions;
          this.unitsAndMultipliers = this.setValue();
        }
      }),
    );
  }

  public ngOnChanges(): void {
    this.unitsAndMultipliers = this.setValue();
  }

  public stringIdsToArray(ids: string): number[] {
    return ids?.split(',').map((id: string) => Number(id));
  }

  public setValue(): IUnitsAndMultipliers[] {
    if (!this.productUnits?.length) {
      return null;
    }

    const selectedUnitIds: number[] = this.stringIdsToArray(this.unitOfCount);
    const unitsWithOrder: ILookup[] = [];

    selectedUnitIds?.forEach((id: number) => {
      const unit = this.productUnits.find((unit: ILookup) => unit.id === id);
      if (unit) {
        unitsWithOrder.push(unit);
      }
    });

    return unitsWithOrder.map((item: ILookup) => {
      const conversionMultiplier: string =
        item.id === this.baseUnitId
          ? '1'
          : this.unitConversions.find(
              (unitConversion: TUnitConversionResponse) => unitConversion.referenceUnitId === item.id,
            )?.multiplier ?? null;

      return {
        name: this.translate.instant('products.lookups.unit.' + camelCase(item.name)).title,
        value:
          this.actualValue &&
          conversionMultiplier &&
          this.decimalHelper.multiply(this.actualValue, conversionMultiplier),
      };
    });
  }

  public ngOnDestroy(): void {
    this.subscriptions.forEach((item: Subscription) => {
      item.unsubscribe();
    });
  }
}
