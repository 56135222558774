import { FilterConditionInterface, HttpOptionInterface } from '../../store/filter/filter.model';
import { HttpParams } from '@angular/common/http';
import { ApiServer } from '../component/filter/dropdown/dropdown.model';
import { SortOrderType } from '../component/datatable/datatable.model';
import { ActivityTypes } from '../model/enum/activity-types';

type ActivityBackgroundColors = Record<ActivityTypes, string>;

export function activityColors(
  activityType: string,
  custom?: Partial<ActivityBackgroundColors>,
  isMissingData?: boolean,
): string {
  const activities = {
    runTime: custom?.runTime ?? '#6FC764',
    downTime: custom?.downTime ?? '#DD332B',
    downTimePlanned: custom?.downTimePlanned ?? '#FF726B',
    idleTime: custom?.idleTime ?? '#BDBDBD',
    missingData: '#ff9347',
    default: '#448aff',
  };
  const color: string = isMissingData ? activities.missingData : activities[activityType];

  return typeof activities[activityType] !== 'undefined' ? color : activities.default;
}

export function generateHttpOption(option: HttpOptionInterface, server: ApiServer = ApiServer.NestJS): HttpParams {
  const objectKeys: string[] = Object.keys(option);
  let param = new HttpParams();
  if (server === ApiServer.NestJS) {
    const filterKey: string = 'filter';
    const separator: string = '||';
    let condition: string = '';
    const orConditions: string[] = [];

    objectKeys.forEach((key: string) => {
      const value = typeof option[key] === 'number' ? JSON.stringify(option[key]) : option[key];

      if (value === null || value === undefined || value === '') {
        return;
      }

      if (typeof value === 'string' && key !== filterKey) {
        param = param.set(key, value);
        return;
      }

      value.forEach((filter: FilterConditionInterface) => {
        if (filter.query === undefined || filter.query === null || filter.query === '') {
          return;
        }

        condition = filter.prop + separator + filter.condition + separator + filter.query;

        if (filter.isNotOrOperand) {
          param = param.append(filterKey, condition);
        } else {
          orConditions.push(condition);
        }
      });
    });

    if (orConditions.length > 0) {
      param = param.append(filterKey, orConditions[0]);
      delete orConditions[0];

      orConditions.forEach((filter: string) => {
        param = param.append('or', filter);
      });
    }
  }

  if (server === ApiServer.Phreeze) {
    option.filter.forEach((filter) => {
      try {
        param = param.set(filter.prop, filter.query);
      } catch (e) {
        return;
      }
    });
  }

  return param;
}

export function generateSearchObject(
  search: string,
  searchProps: FilterConditionInterface[],
): FilterConditionInterface[] {
  const filterArray = [];
  searchProps.forEach((filter: FilterConditionInterface) => {
    filterArray.push({
      ...filter,
      query: filter.query !== undefined ? filter.query : search,
    });
  });

  return filterArray;
}

export function isMobile(): boolean {
  return !!navigator.userAgent.match(/(iPhone|iPod|iPad|Android|webOS|BlackBerry|IEMobile|Opera Mini)/i);
}

export function createQueryParams(page, limit, filter, search): { params: HttpParams } {
  let queryParams = new HttpParams();

  queryParams = queryParams.set('page', encodeURI(String(page)));
  queryParams = queryParams.set('limit', encodeURI(String(limit)));

  if (filter !== undefined) {
    queryParams = queryParams.set('filter', filter);
  }

  if (search !== undefined) {
    queryParams = queryParams.set('s', search);
  }

  return {
    params: queryParams,
  };
}
