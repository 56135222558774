import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'app-opc-status-indicator',
  templateUrl: './opc-status-indicator.component.html',
  styleUrls: ['./opc-status-indicator.component.scss'],
  standalone: true,
  imports: [CommonModule, TranslateModule],
})
export class OPCStatusIndicatorComponent implements OnInit, OnDestroy {
  @Input() isHaveOPCFailure: boolean | null = null;

  public statusIndicatorClasses: string[] = [];

  public ngOnInit(): void {
    if (this.isHaveOPCFailure === null) {
      return;
    }

    if (this.isHaveOPCFailure) {
      this.statusIndicatorClasses.push('status-failure');
    } else {
      this.statusIndicatorClasses.push('status-operational');
    }
  }

  public ngOnDestroy(): void {}
}
