import { Component, EventEmitter, Input, Output, TemplateRef } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BaseModalComponent } from '../base-modal/base-modal.component';
import { ScwMatButtonModule } from '../../../shared/component/scw-mat-ui/scw-mat-button/scw-mat-button.module';
import { TranslateModule } from '@ngx-translate/core';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { MomentDatePipe } from '../../pipes/moment-date.pipe';
import {
  EClientSideButtonListConfirmMethods,
  IClientSiteButtonListSection,
  KeysWithTypeOfString,
} from './client-side-button-list.model';
import { ScwMatSearchModule } from '../../../shared/component/scw-mat-ui/scw-mat-search/scw-mat-search.module';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'client-side-button-list',
  standalone: true,
  imports: [
    CommonModule,
    BaseModalComponent,
    ScwMatButtonModule,
    TranslateModule,
    InfiniteScrollModule,
    MomentDatePipe,
    ScwMatSearchModule,
  ],
  templateUrl: './client-side-button-list.component.html',
  styleUrls: ['./client-side-button-list.component.scss'],
})
export class ClientSideButtonListComponent<ListItem extends Record<string, unknown>> {
  public readonly EClientSideButtonListConfirmMethods = EClientSideButtonListConfirmMethods;

  @Input() modal: NgbActiveModal;
  @Input() labelField: KeysWithTypeOfString<ListItem>;
  @Input() confirmMethod: EClientSideButtonListConfirmMethods = EClientSideButtonListConfirmMethods.onListItemClick;
  @Input() sections: IClientSiteButtonListSection<ListItem>[] = [];
  @Input() searchPlaceholder: string;
  @Input() modalHeader: string;
  @Input() staticSelectedItemText: string;
  @Input() leftFooterContent: TemplateRef<unknown>;

  @Output() onCancel: EventEmitter<void> = new EventEmitter();
  @Output() onConfirm: EventEmitter<ListItem> = new EventEmitter();

  public filteredSections: IClientSiteButtonListSection<ListItem>[];
  public selectedItem: ListItem | null = null;

  public onSearch(searchText: string): void {
    this.filteredSections = this.sections.map(
      (section): IClientSiteButtonListSection<ListItem> => ({
        ...section,
        items: section.items.filter(
          (item) => !searchText || (item[this.labelField] as string).toLowerCase().includes(searchText.toLowerCase()),
        ),
      }),
    );
  }

  public onClickListItem(section: ListItem): void {
    this.selectedItem = section;

    if (this.confirmMethod === EClientSideButtonListConfirmMethods.onListItemClick) {
      this.onConfirm.emit(section);
    }
  }

  public cancel(): void {
    this.onCancel.emit();
  }

  public confirm(): void {
    this.onConfirm.emit(this.selectedItem);
  }
}
