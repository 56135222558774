import { Action } from '@ngrx/store';
import { HttpOptionInterface } from '../filter.model';
import { FloorPlanCRUDInterface } from '../../../shared/component/filter/filter.class';

export enum FilterFloorPlanActionTypes {
  FilterFloorPlansLoading = '[Filter Floor Plan] Filter Floor Plans Loading',
  FilterFloorPlansLoaded = '[Filter Floor Plan] Filter Floor Plans Loaded',
  ResetFloorPlanStore = '[Filter Floor Plan] Reset data stored in floor plan store',
}

export class FilterFloorPlansLoading implements Action {
  readonly type = FilterFloorPlanActionTypes.FilterFloorPlansLoading;

  constructor(public options: HttpOptionInterface) {}
}

export class FilterFloorPlansLoaded implements Action {
  readonly type = FilterFloorPlanActionTypes.FilterFloorPlansLoaded;

  constructor(public data: FloorPlanCRUDInterface[]) {}
}

export class ResetFloorPlanStore implements Action {
  readonly type = FilterFloorPlanActionTypes.ResetFloorPlanStore;
}

export type FilterFloorPlanActions = FilterFloorPlansLoading | FilterFloorPlansLoaded | ResetFloorPlanStore;
