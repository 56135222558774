import { IActivityCommentFeedParams } from 'src/app/standalone/comment-feed/comment-feed-service/comment-feed.model';
import { DetailCardContent } from './detail-card/detail-card.model';
import { EventModelConfig, ResourceModel } from '@bryntum/schedulerpro';
import { PhaseOptions } from '../../../shared/model/enum/phase-options';

export enum EWorkOrderStatusKey {
  NOT_STARTED = 'notStarted',
  IN_PROGRESS = 'inProgress',
  COMPLETED = 'completed',
  CANCELED = 'canceled',
}

export enum EWorkOrderStatusIcon {
  notStarted = 'fas fa-genderless fa-lg',
  inProgress = 'fas fa-clock',
  completed = 'fas fa-check-circle',
  canceled = 'fas fa-times-circle',
}

export enum EWorkOrderPerformanceColor {
  GREEN = '#6FC764',
  RED = '#DD332B',
  GRAY = '#BDBDBD',
}

export interface DetailCardOptions {
  lines: DetailCardContent[];
  actualDates: DetailCardContent[];
  laborHoursAndNames: DetailCardContent[];
  scheduledDates: DetailCardContent[];
  counts: DetailCardContent[];
  workOrderSpeedMetrics: DetailCardContent[];
  releaseAndDueDates: DetailCardContent[];
  actualPhaseDurations: DetailCardContent[];
  scheduledPhaseDurations: DetailCardContent[];
}

export interface IShiftColor {
  shiftId: number;
  color: string;
}

export interface IGanttTooltip {
  eventRecord: {
    data: Partial<IGanttChartEvent>;
  };
}

export interface IGanttChartColumn {
  text: string;
  field: string;
  width?: number;
  type?: string;
  leafIconCls?: string;
  expandIconCls?: string;
  collapseIconCls?: string;
}

export interface IGanttChartEvent extends EventModelConfig {
  activityCommentParams?: IActivityCommentFeedParams;
  taskName: string;
  equipmentName: string;
  eventDuration: number;
  shiftDay: string;
  commentTitle: string;
  stationName?: string;
  workOrderNumber?: string;
  activityTypeText?: string;
  productId?: string;
  isCommentWithAttachment?: boolean;
}

export interface IGanttChartResource extends ResourceModel {
  columnType?: string;
}

export interface IFormattedWorkOrderComment {
  activityCommentParams?: IActivityCommentFeedParams;
  commentDate: string;
  commentType: string;
  commentMessage?: string;
  commentTitle?: string;
  hasAttachment?: boolean;
}

export enum PhaseNames {
  'pre' = Number(PhaseOptions.PRE_RUN),
  'run' = Number(PhaseOptions.RUN),
  'post' = Number(PhaseOptions.POST_RUN),
}

export enum ECommentType {
  'shiftSummary.table.headers.supervisorComment' = 1,
  'shiftSummary.table.headers.maintenanceComment' = 2,
}

export interface ILaborIdAndName {
  id: number;
  name: string;
}

export interface IGanttResource {
  id: string;
  name: string;
}

export interface ICountInformation {
  scrapCount: string;
  goodCount: string;
  unit?: string;
}
