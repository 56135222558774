import { Action } from '@ngrx/store';
import { ResponseInterface } from '../../../shared/model/interface/generic-api-response.model';
import { AccountSettingsInterface, LanguageInterface, SitesInterface } from './account.model';
import { LineCRUDInterface } from '../../../shared/component/filter/filter.class';
import { GetManyResponseInterface } from '../../../shared/model/interface/crud-response-interface.model';

export const GET_ACCOUNT_SETTINGS = '[ACCOUNT] Get account settings';
export const GET_ACCOUNT_SETTINGS_COMPLETED = '[ACCOUNT] Get account settings completed';
export const EDIT_ACCOUNT_SETTINGS = '[ACCOUNT] Edit account settings';
export const EDIT_ACCOUNT_SETTINGS_COMPLETED = '[ACCOUNT] Edit account settings completed';
export const GET_LANGUAGES = '[ACCOUNT] Get languages';
export const GET_LANGUAGES_COMPLETED = '[ACCOUNT] Get languages completed';
export const GET_SITES = '[ACCOUNT] Get sites';
export const GET_SITES_COMPLETED = '[ACCOUNT] Get sites completed';
export const GET_LINES = '[ACCOUNT] Get lines';
export const GET_LINES_COMPLETED = '[ACCOUNT] Get lines completed';
export const FETCH_ERROR = '[ACCOUNT] Fetch Error';

export class GetAccountSettings implements Action {
  readonly type = GET_ACCOUNT_SETTINGS;
}

export class GetAccountSettingsCompleted implements Action {
  readonly type = GET_ACCOUNT_SETTINGS_COMPLETED;
  constructor(public payload: ResponseInterface<AccountSettingsInterface>) {}
}

export class EditAccountSettings implements Action {
  readonly type = EDIT_ACCOUNT_SETTINGS;

  constructor(public payload: AccountSettingsInterface, public hideLoaderAfterSuccess: boolean = true) {}
}

export class EditAccountSettingsCompleted implements Action {
  readonly type = EDIT_ACCOUNT_SETTINGS_COMPLETED;
  constructor(public response: ResponseInterface<AccountSettingsInterface>) {}
}

export class GetLanguages implements Action {
  readonly type = GET_LANGUAGES;
}

export class GetLanguagesCompleted implements Action {
  readonly type = GET_LANGUAGES_COMPLETED;
  constructor(public response: ResponseInterface<LanguageInterface[]>) {}
}

export class GetSites implements Action {
  readonly type = GET_SITES;
}

export class GetSitesCompleted implements Action {
  readonly type = GET_SITES_COMPLETED;
  constructor(public response: ResponseInterface<SitesInterface[]>) {}
}
export class GetLines implements Action {
  readonly type = GET_LINES;
}

export class GetLinesCompleted implements Action {
  readonly type = GET_LINES_COMPLETED;
  constructor(public response: GetManyResponseInterface<LineCRUDInterface>) {}
}

export class FetchError implements Action {
  readonly type = FETCH_ERROR;
  constructor(public payload: any) {}
}

export type AccountActions =
  | EditAccountSettings
  | EditAccountSettingsCompleted
  | GetAccountSettings
  | GetAccountSettingsCompleted
  | GetLanguages
  | GetLanguagesCompleted
  | GetSites
  | GetSitesCompleted
  | GetLines
  | GetLinesCompleted
  | FetchError;
