export enum PhaseOptions {
  NO_PHASE = '0',
  PRE_RUN = '1',
  RUN = '3',
  POST_RUN = '2',
}

export enum PhaseOptionNames {
  'noPhase' = Number(PhaseOptions.NO_PHASE),
  'preRun' = Number(PhaseOptions.PRE_RUN),
  'run' = Number(PhaseOptions.RUN),
  'postRun' = Number(PhaseOptions.POST_RUN),
}

export enum PhaseOptionNamesAsString {
  NO_PHASE = 'noPhase',
  PRE_RUN = 'preRun',
  RUN = 'run',
  POST_RUN = 'postRun',
}
