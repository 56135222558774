import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import * as ObjectActions from './activities.actions';
import { catchError, switchMap, take } from 'rxjs/operators';
import { from, of } from 'rxjs';
import * as AppActions from '../../app/actions';
import { HttpParams } from '@angular/common/http';
import { Store } from '@ngrx/store';
import * as oeeAppReducer from '../../oee.reducer';
import { ErrorMessageService } from '../../../shared/service/error-message.service';
import {
  BulkResponseDataInterface,
  GetManyResponseInterface,
} from '../../../shared/model/interface/crud-response-interface.model';
import { ExcelHelperService } from '../../../shared/service/excel/excel-helper.service';
import { ActivitiesService } from '../../../shared/service/activities/activities.service';
import { ActivityInterface } from './activities.model';
import { EntityTranslatorService } from '../../../shared/service/entity-translator/entity-translator.service';
import { User } from '../../user/model';
import { ActivitiesInterface, ActivitySubTypeInterface } from '../../../shared/model/interface/activities.model';
import { emptyAction } from '../../../../constants';

@Injectable()
export class ActivitiesEffects {
  constructor(
    private readonly actions$: Actions,
    private readonly service: ActivitiesService,
    private readonly store: Store<oeeAppReducer.OeeAppState>,
    private readonly errorMessageService: ErrorMessageService,
    private readonly excelHelperService: ExcelHelperService,
    private readonly entityTranslatorService: EntityTranslatorService,
  ) {}

  getTotalDataCount = createEffect(() =>
    this.actions$.pipe(
      ofType(ObjectActions.ACTIVITIES_GET_DATA_COUNT),
      switchMap(() => {
        const httpParams: HttpParams = new HttpParams().set('limit', 1);

        return this.service.getActivities(httpParams).pipe(
          switchMap((response) => {
            return of(new ObjectActions.GotExcelDataCount(response));
          }),
          catchError((errorRes) => {
            return of(new ObjectActions.FetchError(errorRes));
          }),
        );
      }),
      catchError((errorRes) => {
        return of(new ObjectActions.FetchError(errorRes));
      }),
    ),
  );

  getOneActivity = createEffect(() =>
    this.actions$.pipe(
      ofType(ObjectActions.GET_ONE_ACTIVITY),
      switchMap((objectData: ObjectActions.GetOneActivity) => {
        return this.service.getOneActivity(objectData.id).pipe(
          switchMap((response) => {
            return of(new ObjectActions.GetOneActivityCompleted(response));
          }),
          catchError((errorRes) => {
            return of(new ObjectActions.FetchError(errorRes));
          }),
        );
      }),
      catchError((errorRes) => {
        return of(new ObjectActions.FetchError(errorRes));
      }),
    ),
  );

  getActivityData = createEffect(() =>
    this.actions$.pipe(
      ofType(ObjectActions.LOAD_ACTIVITIES_DATA),
      switchMap((payload: ObjectActions.LoadActivitiesData) => {
        let userLanguage: string;
        this.store
          .select('user')
          .pipe(take(1))
          .subscribe((state: User) => {
            userLanguage = state.language;
          });

        const searchParams: { $and?: Record<string, object>[]; $or?: Record<string, object>[] } = {};

        if (payload.requestParams.selectedActivityTypes !== -1) {
          searchParams.$and = searchParams.$and || [];
          searchParams.$and.push({ activityType: { $in: payload.requestParams.selectedActivityTypes } });
        }

        if (payload.requestParams.selectedStatus !== -1) {
          searchParams.$and = searchParams.$and || [];
          searchParams.$and.push({ active: { $in: payload.requestParams.selectedStatus } });
        }

        if (payload.requestParams.search !== '') {
          searchParams.$and = searchParams.$and || [];
          searchParams.$and.push({ name: { $cont: payload.requestParams.search } });
        }

        let httpParams: HttpParams = new HttpParams()
          .set('page', String(payload.requestParams.page))
          .set('limit', String(payload.requestParams.pageSize))
          .set('s', JSON.stringify(searchParams));

        if (payload.requestParams.sort) {
          const direction: 'DESC' | 'ASC' = payload.requestParams.sort.type === 'descending' ? 'DESC' : 'ASC';
          httpParams = httpParams.set('sort', `${payload.requestParams.sort.column},${direction}`);
        }

        return this.service.getActivities(httpParams).pipe(
          switchMap((response: GetManyResponseInterface<ActivitiesInterface>) => {
            response.data.forEach((activities: ActivityInterface) =>
              this.entityTranslatorService.translate(activities),
            );

            return of(new ObjectActions.LoadedActivitiesData(response));
          }),
          catchError((errorRes) => {
            return of(new ObjectActions.FetchError(errorRes));
          }),
        );
      }),
      catchError((errorRes) => {
        return of(new ObjectActions.FetchError(errorRes));
      }),
    ),
  );

  editSingleActivityData = createEffect(() =>
    this.actions$.pipe(
      ofType(ObjectActions.SINGLE_EDIT_ACTIVITIES_DATA),
      switchMap((objectData: ObjectActions.SingleEditActivitiesData) => {
        this.store.dispatch(new AppActions.ShowLoader());

        return from(this.service.patchActivityData(objectData.activityId, objectData.payload)).pipe(
          switchMap((response) => {
            return of(new ObjectActions.EditedSingleEditActivitiesData(response), new AppActions.HideLoader());
          }),
          catchError((err) => {
            return of(new ObjectActions.FetchError(err), new AppActions.HideLoader());
          }),
        );
      }),
      catchError((errorRes) => {
        return of(new ObjectActions.FetchError(errorRes), new AppActions.HideLoader());
      }),
    ),
  );

  inactiveActivityData = createEffect(() =>
    this.actions$.pipe(
      ofType(ObjectActions.LOAD_USE_ACTIVITY_IN_ALERTS_AND_LINES_DATA),
      switchMap((objectData: ObjectActions.LoadUseActivityInAlertsAndLinesData) => {
        this.store.dispatch(new AppActions.ShowLoader());

        return from(this.service.useActivityInAlertsAndLines(objectData.activityId)).pipe(
          switchMap((response) => {
            return of(new ObjectActions.LoadedUseActivityInAlertsAndLinesData(response), new AppActions.HideLoader());
          }),
          catchError((err) => {
            return of(new ObjectActions.FetchError(err), new AppActions.HideLoader());
          }),
        );
      }),
      catchError((errorRes) => {
        return of(new ObjectActions.FetchError(errorRes), new AppActions.HideLoader());
      }),
    ),
  );

  editActivitiesData = createEffect(() =>
    this.actions$.pipe(
      ofType(ObjectActions.EDIT_ACTIVITIES_DATA),
      switchMap((objectData: ObjectActions.EditActivitiesData) => {
        this.store.dispatch(new AppActions.ShowLoader());

        return from(this.service.patchActivitiesData(objectData.payload)).pipe(
          switchMap((response) => {
            return of(new ObjectActions.EditedActivitiesData(response), new AppActions.HideLoader());
          }),
          catchError((err) => {
            return of(new ObjectActions.FetchError(err), new AppActions.HideLoader());
          }),
        );
      }),
      catchError((errorRes) => {
        return of(new ObjectActions.FetchError(errorRes), new AppActions.HideLoader());
      }),
    ),
  );

  createActivity = createEffect(() =>
    this.actions$.pipe(
      ofType(ObjectActions.CREATE_ACTIVITY),
      switchMap((objectData: ObjectActions.CreateActivity) => {
        this.store.dispatch(new AppActions.ShowLoader());

        return from(this.service.createActivity(objectData.payload)).pipe(
          switchMap((response) => {
            return of(new ObjectActions.CreatedActivity(response), new AppActions.HideLoader());
          }),
          catchError((err) => {
            return of(new ObjectActions.FetchError(err), new AppActions.HideLoader());
          }),
        );
      }),
      catchError((errorRes) => {
        return of(new ObjectActions.FetchError(errorRes), new AppActions.HideLoader());
      }),
    ),
  );

  deleteActivity = createEffect(() =>
    this.actions$.pipe(
      ofType(ObjectActions.DELETE_ACTIVITY),
      switchMap((objectData: ObjectActions.DeleteActivity) => {
        this.store.dispatch(new AppActions.ShowLoader());

        return from(this.service.deleteActivity(objectData.activityId)).pipe(
          switchMap((response) => {
            return of(new ObjectActions.DeletedActivity(response), new AppActions.HideLoader());
          }),
          catchError((err) => {
            return of(new ObjectActions.FetchError(err), new AppActions.HideLoader());
          }),
        );
      }),
      catchError((errorRes) => {
        return of(new ObjectActions.FetchError(errorRes), new AppActions.HideLoader());
      }),
    ),
  );

  deleteActivities = createEffect(() =>
    this.actions$.pipe(
      ofType(ObjectActions.DELETE_ACTIVITIES),
      switchMap((objectData: ObjectActions.DeleteActivities) => {
        this.store.dispatch(new AppActions.ShowLoader());

        return from(this.service.deleteActivities(objectData.activityIds)).pipe(
          switchMap((response) => {
            return of(new ObjectActions.DeletedActivities(response), new AppActions.HideLoader());
          }),
          catchError((err) => {
            return of(new ObjectActions.FetchError(err), new AppActions.HideLoader());
          }),
        );
      }),
      catchError((errorRes) => {
        return of(new ObjectActions.FetchError(errorRes), new AppActions.HideLoader());
      }),
    ),
  );

  getActivityTypes = createEffect(() =>
    this.actions$.pipe(
      ofType(ObjectActions.LOAD_ACTIVITY_TYPES),
      switchMap(() => {
        this.store.dispatch(new AppActions.ShowLoader());

        return this.service.getActivityTypes().pipe(
          switchMap((response) => {
            return of(new ObjectActions.LoadedActivityTypes(response), new AppActions.HideLoader());
          }),
          catchError((errorRes) => {
            return of(new ObjectActions.FetchError(errorRes), new AppActions.HideLoader());
          }),
        );
      }),
      catchError((errorRes) => {
        return of(new ObjectActions.FetchError(errorRes), new AppActions.HideLoader());
      }),
    ),
  );

  getActivitySubTypes = createEffect(() =>
    this.actions$.pipe(
      ofType(ObjectActions.LOAD_ACTIVITY_SUBTYPES),
      switchMap(() => {
        this.store.dispatch(new AppActions.ShowLoader());

        return this.service.getActivitySubTypes().pipe(
          switchMap((response: GetManyResponseInterface<ActivitySubTypeInterface>) => {
            response.data.forEach((activitySubType: ActivitySubTypeInterface) =>
              this.entityTranslatorService.translate(activitySubType),
            );

            return of(new ObjectActions.LoadedActivitySubTypes(response), new AppActions.HideLoader());
          }),
          catchError((errorRes) => {
            return of(new ObjectActions.FetchError(errorRes), new AppActions.HideLoader());
          }),
        );
      }),
      catchError((errorRes) => {
        return of(new ObjectActions.FetchError(errorRes), new AppActions.HideLoader());
      }),
    ),
  );

  downloadActivityExcel = createEffect(() =>
    this.actions$.pipe(
      ofType(ObjectActions.DOWNLOAD_ACTIVITY_EXCEL),
      switchMap((objectData: ObjectActions.DownloadActivityExcel) => {
        this.store.dispatch(new AppActions.ShowLoader());
        this.service.downloadActivityExcel(objectData.withData, objectData.filters);
        return emptyAction;
      }),
      catchError((errorRes) => {
        return of(new ObjectActions.FetchError(errorRes), new AppActions.HideLoader());
      }),
    ),
  );

  downloadActivityExcelCompleted = createEffect(() =>
    this.actions$.pipe(
      ofType(ObjectActions.DOWNLOAD_ACTIVITY_EXCEL_COMPLETED),
      switchMap(() => {
        return of(new AppActions.HideLoader());
      }),
      catchError((errorRes) => {
        return of(new ObjectActions.FetchError(errorRes), new AppActions.HideLoader());
      }),
    ),
  );

  uploadExcel = createEffect(() =>
    this.actions$.pipe(
      ofType(ObjectActions.UPLOAD_ACTIVITY_EXCEL),
      switchMap((objectData: ObjectActions.UploadActivityExcel) => {
        this.store.dispatch(new AppActions.ShowLoader());

        return this.service.uploadExcel(objectData.activities).pipe(
          switchMap((response: BulkResponseDataInterface) => {
            this.errorMessageService.getTranslatedErrorMessage(response.data);
            const mergedArray = this.excelHelperService.mergeBulkResponseWithRequestData<ActivityInterface>(
              response,
              objectData.activities.activities,
            );
            return of(new ObjectActions.UploadActivityExcelCompleted(mergedArray), new AppActions.HideLoader());
          }),
          catchError((error) => {
            return of(new ObjectActions.FetchError(error), new AppActions.HideLoader());
          }),
        );
      }),
      catchError((errorRes) => {
        return of(new ObjectActions.FetchError(errorRes), new AppActions.HideLoader());
      }),
    ),
  );
}
