import { Component, Input } from '@angular/core';

@Component({
  selector: 'scw-mat-modal-text-with-icon',
  templateUrl: './scw-mat-modal-text-with-icon.component.html',
  styleUrls: ['./scw-mat-modal-text-with-icon.component.scss'],
  host: { class: 'scw-mat-modal-text-with-icon' },
})
export class ScwMatModalTextWithIconComponent {
  @Input() icon: string;
  @Input() text: string;
  @Input() hasPadding: boolean = false;
}
