import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ScwMatKpiCardComponent } from './scw-mat-kpi-card.component';
import { ScwMatSelectModule } from '../scw-mat-select/scw-mat-select.module';
import { NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { SanitizeStringPipe } from '../../../pipe/decimal/decimal-sanitize-string.pipe';

@NgModule({
  declarations: [ScwMatKpiCardComponent],
  imports: [CommonModule, NgbTooltipModule, ScwMatSelectModule, SanitizeStringPipe],
  exports: [ScwMatKpiCardComponent],
})
export class ScwMatKpiCardModule {}
