import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ScwMatBorderColoringComponent } from './scw-mat-border-coloring.component';
import { RouterModule } from '@angular/router';

@NgModule({
  imports: [CommonModule, RouterModule],
  exports: [ScwMatBorderColoringComponent],
  declarations: [ScwMatBorderColoringComponent],
})
export class ScwMatBorderColoringModule {}
