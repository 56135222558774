import { Observable, OperatorFunction, delay, of, pipe, raceWith, throwError, timer } from 'rxjs';
import { mergeMap, finalize, withLatestFrom, filter, map } from 'rxjs/operators';

export function defaultAfter<T>(due: number, defaultValue: T): OperatorFunction<T, T> {
  return pipe(raceWith(of(defaultValue).pipe(delay(due))));
}

export const genericRetryStrategy = (
  {
    maxRetryAttempts = 3,
    scalingDuration = 1000,
    excludedStatusCodes = [],
  }: {
    maxRetryAttempts?: number;
    scalingDuration?: number;
    excludedStatusCodes?: number[];
  } = {},
) => (attempts: Observable<any>) => {
  return attempts.pipe(
    mergeMap((error, i) => {
      const retryAttempt = i + 1;
      if (
        !String(error.status).startsWith('5')
        || retryAttempt > maxRetryAttempts
        || excludedStatusCodes.find(e => e === error.status)
      ) {
        return throwError(error);
      }
      return timer(retryAttempt * retryAttempt * scalingDuration);
    }),
    finalize(() => {}),
  );
};

export function takeIf<T>(predicate: Observable<boolean>): OperatorFunction<T, T> {
  return pipe(
    withLatestFrom(predicate),
    filter(([, bool]) => bool),
    map(([value]) => value),
  );
}
