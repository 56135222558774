<div class="">
  <div class="overlay" *ngIf="isOverlayVisible" [@inOutAnimation]>
    <div class="overlay-wrapper">
      <button (click)="onCameraClicked()" class="overlay-button" mat-button>
        <i class="fas fa-camera button-icon"></i>
        {{ 'fileUpload.imageUploadButton.camera' | translate }}
      </button>
      <button (click)="fileInput.click()" class="overlay-button" mat-button>
        <i class="fas fa-image button-icon"></i>
        {{ 'fileUpload.imageUploadButton.gallery' | translate }}
      </button>
      <button (click)="documentInput.click()" class="overlay-button" mat-button>
        <i class="fas fa-file button-icon"></i>
        {{ 'fileUpload.imageUploadButton.documents' | translate }}
      </button>
      <input
        hidden
        type="file"
        #fileInput
        accept=".png, .jpg, .jpeg, .heic"
        (change)="onGalleryClicked($event)"
        multiple
      />
      <input hidden type="file" #documentInput accept=".pdf" (change)="onDocumentsClicked($event)" multiple />
    </div>
  </div>
  <button (click)="isOverlayVisible = !isOverlayVisible" class="add-button" mat-button [disabled]="disabled">
    <div class="button-content">
      <i class="fas fa-paperclip add-icon"></i>
      <span> {{ 'fileUpload.imageUploadButton.attachFile' | translate }} </span>
    </div>
  </button>
</div>
<scw-camera-modal
  #camera_modal
  [sizeLimitPerFileAsMB]="sizeLimitPerFileMB"
  [isOriginalQuality]="isOriginalQualityByDefault"
></scw-camera-modal>
