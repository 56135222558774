import {
  FilterIotDeviceReportDeviceTypeActions,
  FilterIotDeviceReportDeviceTypeActionTypes,
} from './iot-device-report-device-type.actions';
import { IIotDeviceType } from '../../../shared/service/iot-device-report/iot-device-report.model';

export interface FilterIotDeviceReportDeviceTypeState {
  isLoading: boolean;
  isLoaded: boolean;
  data: Partial<IIotDeviceType>[];
}

export const initialFilterIotDeviceReportDeviceTypeState: FilterIotDeviceReportDeviceTypeState = {
  isLoaded: false,
  isLoading: false,
  data: [],
};

export function filterIotDeviceReportDeviceTypeReducer(
  state = initialFilterIotDeviceReportDeviceTypeState,
  action: FilterIotDeviceReportDeviceTypeActions,
): FilterIotDeviceReportDeviceTypeState {
  switch (action.type) {
    case FilterIotDeviceReportDeviceTypeActionTypes.StartLoadFilterIotDeviceReportDeviceTypes:
      return { ...state, isLoading: true, isLoaded: false, data: [] };

    case FilterIotDeviceReportDeviceTypeActionTypes.FilterIotDeviceReportDeviceTypesLoaded:
      return { ...state, data: action.data, isLoaded: true, isLoading: false };

    default:
      return state;
  }
}
