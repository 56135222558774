import { Action } from '@ngrx/store';
import { BaseOneResponseInterface } from '../../shared/model/interface/crud-response-interface.model';
import { ITopicSubscriptionResponseData } from './push-notification.model';

export const SUBSCRIBE_TO_USER_UNIQUE_TOPIC: string = '[PUSH NOTIFICATION] SUBSCRIBE TO USER UNIQUE TOPIC';
export const SUBSCRIBE_TO_USER_UNIQUE_TOPIC_COMPLETED: string =
  '[PUSH NOTIFICATION] SUBSCRIBE TO USER UNIQUE TOPIC COMPLETED';
export const OPEN_PUSH_NOTIFICATION_PERMISSION_MODAL: string =
  '[PUSH NOTIFICATION] OPEN PUSH NOTIFICATION PERMISSION MODAL';
export const FETCH_ERROR: string = '[PUSH NOTIFICATION] FETCH ERROR';

export class SubscribeToUserUniqueTopic implements Action {
  readonly type = SUBSCRIBE_TO_USER_UNIQUE_TOPIC;

  constructor(public token: string) {}
}

export class SubscribeToUserUniqueTopicCompleted implements Action {
  readonly type = SUBSCRIBE_TO_USER_UNIQUE_TOPIC_COMPLETED;

  constructor(public payload: BaseOneResponseInterface<ITopicSubscriptionResponseData>, public token: string) {}
}

export class OpenPushNotificationPermissionModal implements Action {
  readonly type = OPEN_PUSH_NOTIFICATION_PERMISSION_MODAL;
}

export class FetchError implements Action {
  readonly type = FETCH_ERROR;

  constructor(public payload: object) {}
}

export type PushNotificationActions = SubscribeToUserUniqueTopic | SubscribeToUserUniqueTopicCompleted | FetchError;
