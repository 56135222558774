import { ECicoAvailability, ECicoType } from './cico.model';
import { activityColors } from '../../helper/app-helper';
import * as _ from 'lodash';

export function getStatusColor(type: ECicoType, status: ECicoAvailability, tagColor?: string): string {
  if (type === ECicoType.ASSET) {
    return status === ECicoAvailability.offline || _.isNil(tagColor) ? activityColors('idleTime') : tagColor;
  }

  switch (status) {
    case ECicoAvailability.available:
      return activityColors('runTime');
    case ECicoAvailability.busy:
      return activityColors('downTimePlanned');
    case ECicoAvailability.offline:
      return activityColors('idleTime');
  }
}

export function getStatusName(type: ECicoType, status: ECicoAvailability, tagName?: string): string | null {
  if (type === ECicoType.LABOR) {
    return `general.${status}`;
  }

  if (status === ECicoAvailability.offline) {
    return null;
  }

  return tagName || 'general.noStatus';
}
