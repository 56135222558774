import * as Actions from './unit-conversion.actions';
import { IUnitConversionState } from './unit-conversion.model';

export const unitConversionState: IUnitConversionState = {
  addUnitConversionLoaded: false,
  addUnitConversionLoading: false,
  editUnitConversionLoaded: false,
  editUnitConversionLoading: false,
  unitConversionLoaded: false,
  unitConversionLoading: false,
  unitConversionData: [],
};

export function unitConversionReducer(state: any = unitConversionState, action: Actions.UnitConversionActions): any {
  switch (action.type) {
    case Actions.UNIT_CONVERSION_DATA_LOADING:
      return {
        ...state,
        addUnitConversionLoaded: false,
        addUnitConversionLoading: true,
        unitConversionLoaded: false,
        unitConversionLoading: true,
      };
    case Actions.UNIT_CONVERSION_DATA_LOADED:
      return {
        ...state,
        addUnitConversionLoaded: true,
        addUnitConversionLoading: false,
        unitConversionLoaded: true,
        unitConversionLoading: false,
        unitConversionData: action.data,
      };
    case Actions.ADD_UNIT_CONVERSION_LOADING:
      return {
        ...state,
        addUnitConversionLoaded: false,
        addUnitConversionLoading: true,
      };
    case Actions.ADD_UNIT_CONVERSION_LOADED:
      return {
        ...state,
        addUnitConversionLoaded: true,
        addUnitConversionLoading: false,
      };
    case Actions.EDIT_UNIT_CONVERSION_LOADING:
      return {
        ...state,
        editUnitConversionLoaded: false,
        editUnitConversionLoading: true,
      };
    case Actions.EDIT_UNIT_CONVERSION_LOADED:
      return {
        ...state,
        editUnitConversionLoaded: true,
        editUnitConversionLoading: false,
      };
    default:
      return state;
  }
}
